import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AdminPage from 'scenes/adminPage';
import HomePage from 'scenes/homePage';
import LoginPage from 'scenes/loginPage';
import ProfilePage from 'scenes/profilePage';

import VerifyEmailPage from 'scenes/verifyEmailPage';

import { useAppSelector } from 'hooks/useRedux';
import AboutUsPage from 'scenes/aboutusPage';
import BusinessPage from 'scenes/busnessPage';
import ChatPage from 'scenes/chatPage';
import ContactUs from 'scenes/contactusPage';
import EventsPage from 'scenes/eventPage';
import FeaturePage from 'scenes/featurePage';
import FundingPage from 'scenes/fundingPage';
import GroupPage from 'scenes/groupPage';
import GroupsPage from 'scenes/groupsPage';
import JobsPage from 'scenes/jobPage';
import JoinPage from 'scenes/joinPage';
import NotFound from 'scenes/notFoundPage';
import RegisterPage from 'scenes/registerPage';
import SearchPage from 'scenes/searchPage';
import SettingPage from 'scenes/settingPage';
import SupportPage from 'scenes/supportPage';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { themeSettings } from './theme';

function App() {
  const { mode, token, user } = useAppSelector(state => state.auth);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const dispatch = useDispatch();
  const isAuth = Boolean(token);
  const persistRoot = JSON.parse(localStorage.getItem('persist:root'));

  const getCountries = async () => {
    const res = await fetch('https://restcountries.com/v3.1/all');
    const data = await res.json();
  };

  const loadUser = async () => {
    const res = await axiosHelper(API_METHOD.POST, 'auth', 'load-user', {
      token
    });
    if (res.isSuccess) {
      // dispatch(loadUser(res.user));

    }
  };

  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, [token]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className='app'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path='/'
              element={isAuth ? <Navigate to='/home' /> : <LoginPage />}
            />

            <Route path='/about-us' element={<AboutUsPage />} />
            <Route path='/verify-email' element={<VerifyEmailPage />} />
            <Route
              path='/business'
              element={isAuth ? <BusinessPage /> : <LoginPage />}
            />
            <Route path='/feature' element={<FeaturePage />} />
            <Route path='/event' element={isAuth ? <EventsPage /> : <LoginPage />} />
            <Route path='/support' element={<SupportPage />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route
              path='/funding'
              element={isAuth ? <FundingPage /> : <LoginPage />}
            />
            <Route
              path='/group/:id'
              element={isAuth ? <GroupPage /> : <LoginPage />}
            />
            <Route
              path='/groups'
              element={isAuth ? <GroupsPage /> : <LoginPage />}
            />
            <Route
              path='/chat'
              element={isAuth ? <ChatPage /> : <LoginPage />}
            />
            <Route
              path='/settings'
              element={isAuth ? <SettingPage /> : <LoginPage />}
            />

            <Route path='/join' element={<JoinPage />} />
            <Route
              path='/chat/:id'
              element={isAuth ? <ChatPage /> : <LoginPage />}
            />
            <Route
              path='/admin'
              element={
                isAuth && user.email === process.env.REACT_APP_ADMIN_EMAIL ? (
                  <AdminPage />
                ) : (
                  <Navigate to='/home' />
                )
              }
            />
            <Route
              path="/search"
              element={isAuth ? <SearchPage /> : <Navigate to="/" />}
            />
            <Route
              path='/home'
              element={isAuth ? <HomePage /> : <Navigate to='/' />}
            />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='jobs' element={<JobsPage />} />

            <Route
              path='/profile/:userId'
              element={isAuth ? <ProfilePage /> : <Navigate to='/' />}
            />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
