// NotFound.js
import React from 'react';
import { Container, Typography, Link, Grid, Button } from '@mui/material';

const NotFound = () => {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1" color="textSecondary" gutterBottom>
            404 - Not Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textSecondary" paragraph>
            Oops! The page you are looking for might be in another castle.
          </Typography>
        </Grid>
        <Grid item>
          <img
            src="https://media.giphy.com/media/14uQ3cOFteDaU/giphy.gif"
            alt="Mario saying Sorry, but your princess is in another castle"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary">
          <Link href="/" color="white" underline="hover">
            Take me home
          </Link>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
