import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import { setBusinesses, setMyBusinesses } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';

const MyBusinessCardWidget = ({ business, setselectedBusiness }) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { myBusinesses, businesses } = useAppSelector(state => state.auth);

  const deleteBusiness = async () => {
    const res = await axiosHelper(
      API_METHOD.DELETE,
      'businesses',
      business._id
    );
    if (res.isSuccess) {
      dispatch(
        setMyBusinesses({
          myBusinesses: myBusinesses.filter(b => b._id !== business._id)
        })
      );
      dispatch(
        setBusinesses({
          businesses: businesses.filter(b => b._id !== business._id)
        })
      );
    }
  };
  if (!business) return null;
  return (
    <Box
      border='1px solid #E0E0E0'
      borderRadius='10px'
      height={80}
      my={2}
      px={1}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={9}>
          <Typography
            variant='h6'
            fontWeight='500'
            color={palette.neutral.dark}
          >
            {business.name.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack direction='column' display='flex' justifyContent='flex-end'>
            <IconButton onClick={() => setselectedBusiness(business)}>
              <Edit />
            </IconButton>
            <IconButton onClick={deleteBusiness}>
              <Delete />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyBusinessCardWidget;
