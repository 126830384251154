import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import EventCardWidget from './EventCardWidget';

const RenderEventWidget = ({ isMyEvents, isInterestedEvents }) => {
  const [events, setEvents] = useState([]);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    getEvents();
  }, []); // Including this line as it seems to be an oversight in your previous component setup
  console.log(isInterestedEvents)

  const getEvents = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'events', 'get-public-events');
      console.log(response.events)
      if (response.isSuccess) {
        // filter the events with no userId
        const updatedEvents = response.events.filter(event => event.userId);
        if (isMyEvents) {
          const myJobs = updatedEvents.filter(
            event => event.userId && event.userId._id === user._id && !event.groupId
          );
          setEvents(myJobs);
          return;
        }
        else if (isInterestedEvents) {
          console.log('interested events', updatedEvents);
          const myJobs = updatedEvents.filter(
            event => event.registrants.some(
              u => u.userId._id === user._id
            )
          );
          setEvents(myJobs);
          return;
        }
        const myEvents = updatedEvents.filter(
          event => !event.groupId
        );
        setEvents(myEvents);
        // dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {events.length > 0 ? (
        events.map((event) => (
          <EventCardWidget
          getEvents={getEvents}
            {...event}
          />
        ))
      ) : (
        <p>No events found.</p> // Displaying a message if no jobs are found
      )}
    </>
  );
};

export default RenderEventWidget;
