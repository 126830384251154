import { Typography, useTheme } from '@mui/material';
import WidgetWrapper from 'components/WidgetWrapper';
import MyBusinessCardWidget from './MyBusinessCardWidget';

const MyBusinessWidget = ({ myBusinesses, setselectedBusiness }) => {
  const { palette } = useTheme();

  if (!myBusinesses) return null;
  return (
    <WidgetWrapper sx={{
      height: 'calc(100vh - 200px)',
      overflowY: 'scroll'
    }}>
      <Typography
        color={palette.neutral.dark}
        variant='h5'
        fontWeight='500'
        sx={{ mb: '1.5rem' }}
      >
        My Business
      </Typography>
      {myBusinesses.length === 0 ? (
        <Typography
          color={palette.neutral.dark}
          variant='h6'
          fontWeight='500'
          sx={{ mb: '1.5rem' }}
        >
          You have no business yet.
        </Typography>
      ) : (
        myBusinesses.map(business => (
          <MyBusinessCardWidget
            key={business._id}
            business={business}
            setselectedBusiness={setselectedBusiness}
          />
        ))
      )}
    </WidgetWrapper>
  );
};

export default MyBusinessWidget;
