import { createStyles, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import SearchBox from 'components/SearchBox';
import socket from 'configs/socket';
import { useChat } from 'hooks/useChat';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getInitials } from 'utils/helperFunction';
import ChatBoxWidget from './ChatBoxWidget';

const styles = createStyles({
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

const Chat = () => {
  const classes = styles;
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const { openConversation, getAllConversation, conversations } = useChat();
  const { id } = useParams();
  const { user } = useAppSelector(state => state.auth);
  const { _id, picturePath, elum } = useAppSelector((state) => state.auth.user);
  const {firstName, lastName} = user;

  const selfId = user._id;
  const friendId = id;

  useEffect(() => {
    getAllConversation(selfId);
  }, []);

  useEffect(() => {
    if (conversations) {
      conversations.forEach(el => {
        socket.emit('user-join-room', { roomId: el._id });
      });
    }
  }, [conversations.length]);

  return (
    <Grid
      component={Paper}
      style={{
        // height: '80vh',
        width: '100%',
        // overflowY: 'hidden',
        backgroundColor: theme.palette.background.paper
      }}
    >
      <Grid container className={classes.chatSection}>
        <Grid item xs={3} className={classes.borderRight500}>
          <List>
            <ListItem button key={`${firstName} ${lastName}`}>
              <ListItemAvatar>
                <Avatar
                  alt={`${firstName} ${lastName}`}
                  src={picturePath}
                />
              </ListItemAvatar>
              <ListItemText primary={`${firstName} ${lastName}`} />
            </ListItem>
          </List>
          <Divider />
          <Grid item xs={12} style={{ padding: '10px' }}>
            <SearchBox
              neutralLight={neutralLight}
              isMessage
              onClick={otherId => openConversation(selfId, otherId)}
            />
          </Grid>
          <Divider />
          <List
            style={{
              overflowY: 'auto',
              height: '70vh'
            }}
          >
            {conversations &&
              conversations.map(
                ({ firstId, firstUserName, secondId, secondUserName, _id }) => (
                  <ListItem
                    button
                    sx={{
                      backgroundColor: id === _id ? neutralLight : 'inherit'
                    }}
                    key={_id}
                    onClick={() =>
                      openConversation(
                        selfId,
                        firstId === selfId ? secondId : firstId
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={
                          firstId === selfId ? secondUserName : firstUserName
                        }
                      >
                        {getInitials(
                          firstId === selfId ? secondUserName : firstUserName
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        firstId === selfId ? secondUserName : firstUserName
                      }
                    />
                  </ListItem>
                )
              )}

            {/* <ListItem button key='Alice'>
              <ListItemAvatar>
                <Avatar
                  alt='Alice'
                  src='https://mui.com/static/images/avatar/3.jpg'
                />
              </ListItemAvatar>
              <ListItemText primary='Alice' />
            </ListItem>
            <ListItem button key='CindyBaker'>
              <ListItemAvatar>
                <Avatar
                  alt='Cindy Baker'
                  src='https://mui.com/static/images/avatar/2.jpg'
                />
              </ListItemAvatar>
              <ListItemText primary='Cindy Baker' />
            </ListItem> */}
          </List>
        </Grid>
        <Grid item xs={9}>
          {id && (
            <ChatBoxWidget
              chatId={id}
              selfId={selfId}
              friendId={friendId}
              user={user}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chat;
