import { Box, Button, Stack, Typography } from '@mui/material';
import BusinessForm from 'components/BusinessForm';
import WidgetWrapper from 'components/WidgetWrapper';

const AddBusinessWidget = ({ selectedBusiness, setselectedBusiness }) => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <WidgetWrapper>
        <Stack direction='column' spacing={2}>
          <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='h5' fontWeight='500'>
              {selectedBusiness ? 'Edit' : 'Add'} Business
            </Typography>
            {selectedBusiness && (
              <Button
                variant='outlined'
                onClick={() => setselectedBusiness(null)}
              >
                Add New
              </Button>
            )}
          </Stack>
          <BusinessForm
            selectedBusiness={selectedBusiness}
            setselectedBusiness={setselectedBusiness}
          />
        </Stack>
      </WidgetWrapper>
    </Box>
  );
};

export default AddBusinessWidget;
