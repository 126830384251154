import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import CreateGroupWidget from './CreateGroupWidget';
import MyGroupsWidget from './MyGroupsWidget';
import AdminGroupCrud from './AdminGroupCrud';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GroupWidget = () => {
  const { elum } = useAppSelector(state => state.auth.user);
  const { user } = useAppSelector(state => state.auth);
  const [value, setValue] = useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let groupTabs = ['Create Group', 'My Groups', 'All Groups'];
  const adminGroupTabs = ['Create Group', 'My Groups', 'All Groups', 'Admin'];
  const isAdmin = process.env.REACT_APP_ADMIN_EMAIL === user.email;
  isAdmin && (groupTabs = adminGroupTabs);
  !elum && groupTabs.splice(0, 2);

  return (
    <Box
    // sx={{
    //   height: 'calc(100vh - 200px)',
    //   overflowY: 'scroll'
    // }}
    >
      <Typography
        variant='h4'
        fontWeight='700'
        sx={{ mb: '1.5rem', textAlign: 'center' }}
      >
        Groups
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: theme.palette.background.paper
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {/* <Tab label='Groups' {...a11yProps(0)} /> */}
          {groupTabs.map((item, index) => (
            <Tab label={item} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <GetFundingWidget />
      </TabPanel> */}
      {elum ? (
        <>
          <TabPanel value={value} index={0}>
            <CreateGroupWidget />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MyGroupsWidget userGroups />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MyGroupsWidget />
          </TabPanel>
          {isAdmin && (
            <TabPanel value={value} index={3}>
              <AdminGroupCrud isAdmin={isAdmin} />
            </TabPanel>
          )}
        </>
      ) : (
        <TabPanel value={value} index={0}>
          <MyGroupsWidget />
        </TabPanel>
      )}
    </Box>
  );
};

export default GroupWidget;
