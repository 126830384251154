import SendIcon from "@mui/icons-material/Send";
import { createStyles, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import socket from "configs/socket";
import { useChat } from "hooks/useChat";
import { useEffect, useRef, useState } from "react";
import { getDisplayNames } from "utils/helperFunction";
const styles = createStyles({
  messageArea: {
    maxHeight: "70vh",
    backgroundColor: "red",
    overflowY: "auto",
  },
});

const ChatBoxWidget = ({ chatId, selfId, friendId, user }) => {
  const classes = styles;
  const {
    getConversationFromState,
    sendMessage,
    updateConversationFromState,
    addConversationToState,
  } = useChat();
  const [content, setContent] = useState("");
  const convo = getConversationFromState(chatId);
  const theme = useTheme();
  const listRef = useRef(null);

  useEffect(() => {
    socket.on("receive-message", ({ conversation, newMessage }) => {
      console.log("receive-message");
      const cvs = getConversationFromState(conversation._id);
      console.log(conversation);
      if (cvs) {
        if (localStorage.username === conversation.lastSender) return;
        console.log("receive-message", conversation, newMessage);
        updateConversationFromState(conversation);
        // updateConversation(conversation);
        // addNewMessage({ conversation: conversation, message: newMessage });
      } else {
        addConversationToState(conversation);
        // addConversation(conversation);
      }
    });
  }, []);

  // scroll to botton of chat box on page load
  useEffect(() => {
    scrollToBottom();
  }, [convo]);

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  if (!convo) return null;
  return (
    <>
      <List
        style={{
          height: "80vh",
          overflowY: "auto",
          width: "100%",
        }}
        ref={listRef}
      >
        {convo.messages &&
          convo.messages.map(({ content, ofUser }, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                justifyContent: ofUser === selfId ? "flex-end" : "flex-start",
                justifySelf: ofUser === selfId ? "flex-end" : "flex-start",
                alignSelf: ofUser === selfId ? "flex-end" : "flex-start",
                width: "100%",
              }}
            >
              <ListItem
                key={index}
                sx={{
                  bgcolor:
                    ofUser === selfId
                      ? theme.palette.primary.light
                      : theme.palette.secondary.light,
                  borderRadius: theme.shape.borderRadius,
                  margin: theme.spacing(1),
                  padding: theme.spacing(1),
                  paddingX: theme.spacing(2),
                  width: "fit-content",
                  display: "flex",
                  color:
                    ofUser === selfId
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                }}
              >
                <ListItemText
                  align={ofUser === selfId ? "right" : "left"}
                  primary={content}
                  sx={{
                    color:
                      ofUser === selfId
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.contrastText,
                    "&:hover": {
                      color:
                        ofUser === selfId
                          ? theme.palette.primary.contrastText
                          : theme.palette.secondary.contrastText,
                    },
                  }}
                />
              </ListItem>
            </ListItem>
          ))}
      </List>
      <Divider />
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={11}>
          <TextField
            id="outlined-basic-email"
            label="Type Something"
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (!content) return;
                sendMessage(
                  chatId,
                  selfId,
                  friendId,
                  content,
                  getDisplayNames(user.firstName, user.lastName)
                );
                setContent("");
              }
            }}
          />
        </Grid>
        <Grid xs={1} align="right">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              if (!content) return;
              sendMessage(
                chatId,
                selfId,
                friendId,
                content,
                getDisplayNames(user.firstName, user.lastName)
              );
              setContent("");
            }}
          >
            <SendIcon />
          </Fab>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatBoxWidget;
