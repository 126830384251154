import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const BannerContainer = styled(Box)({
  background: 'gold',  // Changed background color to golden
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',  // Center the content vertically
  alignItems: 'center',
  '@media (max-width: 600px)': {
    alignItems: 'center',
  },
});

const BannerText = styled(Box)({
  color: '#333',
  marginBottom: '16px',
  textAlign: 'center',  // Center the text
  '&:last-child': {
    marginBottom: 0,
  },
});

const BoldTypography = styled(Typography)({
  fontWeight: 'bold',
  textAlign: 'center',  // Center the text
});

const FlashyButton = styled(Button)({
  background: '#6c757d',
  borderRadius: 3,
  border: 0,
  color: 'white',
  height: 48,
  padding: '0 30px',
  boxShadow: '0 3px 5px 2px rgba(108, 117, 125, 0.3)',
  '&:hover': {
    background: '#495057',
  },
  display: 'none',  // Hide the register button
});

const Banner = () => {
  return (
    <BannerContainer>
      <Box>
        <BoldTypography variant="h4" gutterBottom>Meet Other SBS Alums - Different Years and Cohorts</BoldTypography>
        <BannerText>Date: Sunday, July 21th, 2024</BannerText>
        <BannerText>Time:  1:00 PM & 8:00 PM London, UK Time</BannerText>
        <BannerText>Location: Virtual Event</BannerText>
        <BannerText>Description: Meet n Greet on Zoom with 2 Timezones! Come and meet other years</BannerText> 
        <BannerText>Other Event: ** Coming Soon! Small SBS Business / Start up Expo ** </BannerText>  
        <BannerText>Website Update: ** Coming Soon! Brand New Website Design and Functionality Redo ** </BannerText>  
      </Box>
      <FlashyButton variant="contained">Register</FlashyButton>
    </BannerContainer>
  );
};

export default Banner;

