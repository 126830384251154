import Navbar from "scenes/navbar";
import { useAppSelector } from "hooks/useRedux";
import { Box, useMediaQuery } from "@mui/material";
import UserWidget from "scenes/widgets/UserWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import Banner from "components/Banner";
import Footer from "components/Footer";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import { useDispatch } from "react-redux";
import { setAllUsers } from "state/auth";
import { useEffect } from "react";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, elum } = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  

  const getAllUsers = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, "users");
      dispatch(setAllUsers({ allUsers: response }));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllUsers();
  }
  , []);



  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={_id} picturePath={picturePath} />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyPostWidget picturePath={picturePath} />
          <PostsWidget userId={_id} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            {/* <AdvertWidget /> */}
            <Box m="2rem 0" />
            {elum && <FriendListWidget userId={_id} />}
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default HomePage;
