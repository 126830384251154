import { Box, useMediaQuery } from '@mui/material';
import Footer from 'components/Footer';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from 'scenes/navbar';
import JobsWidget from 'scenes/widgets/JobsWidget';
import { setBusinesses, setJobs } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';

const JobsPage = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id, } = useAppSelector(state => state.auth);
  
  const dispatch = useDispatch();

  const getJobs = async () => {
    const res = await axiosHelper(API_METHOD.GET, 'jobs');
    if (res.isSuccess) {
      dispatch(
        setJobs({
          jobs: res.jobs
        })
      );
    }
  };

    useEffect(() => {
    getBusinessess();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBusinessess = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'businesses', '');

      if (response.isSuccess) {
        dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <Box pb={10} height='100vh'>
      <Navbar />
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap='0.5rem'
        justifyContent='center'
      >
        <Box
          // flexBasis={isNonMobileScreens ? '42%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
          width="100%"
        >
          <JobsWidget />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default JobsPage;
