import {
  ChatBubbleOutlineOutlined,
  DeleteOutline,
  FavoriteBorderOutlined,
  FavoriteOutlined
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import Friend from 'components/Friend';
import WidgetWrapper from 'components/WidgetWrapper';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removePost, setPost } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { getDisplayNames } from 'utils/helperFunction';

const PostWidget = ({
  postId,
  postUserId,
  description,
  picturePath,
  likes,
  comments
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});
    const { palette } = useTheme();


  const loggedInUserId = useAppSelector(state => state.auth.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
    const { allUsers } = useAppSelector(
    state => state.auth
  );
  if (allUsers.length === 0) return null;
  const user = allUsers.find(user => user._id === postUserId);
  if(!user) return null;
  const name = getDisplayNames(user.firstName, user.lastName);
  const userPicturePath = user.picturePath;
  const location = user.location;

  const likeCount = Object.keys(likes).length;

  const main = palette.neutral.main;
  const primary = palette.primary.main;


  const patchLike = async () => {
    // const response = await fetch(`http://localhost:3001/posts/${postId}/like`, {
    //   method: 'PATCH',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ userId: loggedInUserId })
    // });
    // const updatedPost = await response.json();
    // dispatch(setPost({ post: updatedPost }));
    const response = await axiosHelper(
      API_METHOD.PUT,
      `posts`,
      `${postId}/like`,
      {
        userId: loggedInUserId
      }
    );
    dispatch(setPost({ post: response }));
  };

  const patchComment = async () => {
    if (!text) return;
    const response = await axiosHelper(
      API_METHOD.PUT,
      `posts`,
      `${postId}/comment`,
      {
        userId: loggedInUserId,
        text
      }
    );
    setText('');
    dispatch(setPost({ post: response }));
  };

  const truncateText = (text, maxLength = 150) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const descriptionToShow = showFullDescription
    ? description
    : truncateText(description);

  const toggleComment = commentId => {
    setExpandedComments(prev => ({
      ...prev,
      [commentId]: !prev[commentId]
    }));
  };

  const renderCommentText = comment => {
    const isExpanded = expandedComments[comment._id];
    const shouldTruncate = comment.text.length > 100 && !isExpanded;
    const textToShow = shouldTruncate
      ? `${comment.text.substring(0, 100)}...`
      : comment.text;
    return (
      <Typography sx={{ color: main, m: '0.5rem 0', pl: '1rem' }}>
        {textToShow}
        {comment.text.length > 100 && (
          <Button
            color='primary'
            size='small'
            onClick={() => toggleComment(comment._id)}
          >
            {isExpanded ? 'See Less' : 'See More'}
          </Button>
        )}
      </Typography>
    );
  };

   const deletePost = async () => {
    try {
      const response = await axiosHelper(
        API_METHOD.DELETE,
        `posts`,
        `${postId}`,
      );
      if(response.isSuccess){
         dispatch(removePost({ postId })); // Dispatch action to remove post from state

      }
     
    } catch (error) {
      console.error('Delete post error:', error);
    }
  };



  return (
    <WidgetWrapper m='2rem 0'>
      <Friend
        friendId={postUserId}
        name={name}
        subtitle={location}
        userPicturePath={userPicturePath}
      />
        
      <Typography color={main} sx={{ mt: '1rem', wordBreak: 'break-all' }}>
        {descriptionToShow}
        {description.length > 150 && (
          <Button color='primary' onClick={toggleDescription}>
            {showFullDescription ? 'See Less' : 'See More'}
          </Button>
        )}
      </Typography>
      {picturePath && (
        <img
          width='100%'
          height='auto'
          alt='post'
          style={{ borderRadius: '0.75rem', marginTop: '0.75rem' }}
          src={picturePath}
        />
      )}
      <FlexBetween mt='0.25rem'>
        <FlexBetween gap='1rem'>
          <FlexBetween gap='0.3rem'>
            <IconButton onClick={patchLike}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Typography>{likeCount}</Typography>
          </FlexBetween>

          <FlexBetween gap='0.3rem'>
            <IconButton onClick={() => setIsComments(!isComments)}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments.length}</Typography>
          </FlexBetween>
        </FlexBetween>

    {loggedInUserId === postUserId && (
        <IconButton onClick={deletePost} >
          <DeleteOutline />
        </IconButton>
      )}
      </FlexBetween>
      <TextField
        sx={{ mt: '0.5rem' }}
        fullWidth
        multiline
        value={text}
        onChange={e => setText(e.target.value)}
        variant='outlined'
        rows={3}
        placeholder='Write a comment...'
      />
      <Button
        sx={{ mt: '0.5rem' }}
        fullWidth
        disabled={!text}
        variant='contained'
        onClick={() => {
          patchComment();
        }}
      >
        Post
      </Button>

      {isComments && (
        <Box mt='0.5rem'>
          {comments.map(comment => (
            <Box
              key={comment._id}
              sx={{
                border: '1px solid #e0e0e0',
                margin: '0.5rem 0',
                borderRadius: '0.75rem',
                padding: '0.5rem',
                position: 'relative'
              }}
            >
              <Stack direction='row' alignItems='center' sx={{ p: '0.5rem 0' }}>
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src={comment.user.picturePath}
                >
                  {comment.user.firstName[0]}
                </Avatar>
                <Typography sx={{ pl: '0.5rem' }}>
                  {comment.user.firstName} {comment.user.lastName}
                </Typography>
              </Stack>
              {renderCommentText(comment)}
            </Box>
          ))}
          <Divider />
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default PostWidget;
