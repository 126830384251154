import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import Form from "../../components/Form";
import Footer from "components/Footer";

const JoinPage = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [elum, setElum] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  // get the query params
  const { search } = useLocation();
  // if(!user) return <div>loading...</div>
  // if(user.email !== process.env.REACT_APP_ADMIN_EMAIL) return <div>you are not admin</div>

  useEffect(() => {
    // if (search) {
      decodeToken();
    // }
  }, []);

  const decodeToken = async () => {
    const token = search.split("=")[1];
    if (!token) {
      navigate("/");
    }
    const response = await axiosHelper(
      API_METHOD.POST,
      "auth",
      "decode-token",
      {
        token,
      }
    );
    if (response && response.isSuccess) {
      setEmail(response.email);
    } else {
      navigate("/");
    }
  };

  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography fontWeight="bold" fontSize="32px" color="primary">
          {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Welcome to {process.env.REACT_APP_ORGANIZATION_NAME}, the Social Media for Oxford Alumns!
        </Typography>
        <Form elum={elum} isLogin={false} email={email} />
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Already have an account?{" "}
          <Button onClick={() => navigate("/")}>Login?</Button>
        </Typography>
        <Button onClick={() => setElum(!elum)}>
          {elum ? "Sign Up as a Guest?" : "Sign Up as a User?"}
        </Button>
      </Box>
      <Footer />
    </Box>
  );
};

export default JoinPage;
