import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  capitalize
} from '@mui/material';
import { Formik, isString } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { OCCUPATION_DATA } from 'utils/data';
import { geoData } from 'utils/geoData';
import * as yup from 'yup';

const initialValuesRegister = {
  jobTitle: '',
  country: '',
  city: '',
  salary: '',
  description: '',
  businessId: '',
  occupation: '',
  subOccupation: ''
};

const JobForm = () => {
  const [loading, setLoading] = useState({
    register: false,
    page: false
  });
  const [cities, setCities] = useState([]);
  const { user, businesses } = useAppSelector(state => state.auth);
  const wordCount = str => (str ? str.split(/\s+/).length : 0);
  const navigate = useNavigate();

  const registerSchema = yup.object().shape({
    jobTitle: yup
      .string()
      .required('Job title is required')
      .max(100, 'Job title cannot exceed 100 characters'),
    salary: yup.string().required('Salary is required'),
    description: yup
      .string()
      .required('Description is required')
      .max(500, 'Description cannot exceed 500 words')
      .test(
        'wordCount',
        'Description must be below 500 words',
        value => {
          const words = wordCount(value);
          return  words <= 500;
        }
      ),
    country: yup.string().required('Country is required'),
    city: yup.string().required('City is required'),
    occupation: yup.string().required('Occupation is required'),
    subOccupation: yup.string().required('Sub Occupation is required'),
    businessId: yup.string().required('Business name is required')
  });

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      // setLoading({ ...loading, register: true });
      const res = await axiosHelper(API_METHOD.POST, 'jobs', '', {
        ...values,
        userId: user._id,
        businessId: isBusinessNameExist(values.businessId)._id
      });
      onSubmitProps.resetForm();
      if (res.isSuccess) {
      }
      // setLoading({ ...loading, register: false });

      // await register(values, onSubmitProps);
    } catch (error) {
      console.log(error);
    }
  };

  const isBusinessNameExist = name => {
    if (!name) return true;
    return businesses.find(
      business => business.name.name.toLowerCase() === name.toLowerCase()
    );
  };

  //  populate cities based on the selected country
  const populateCities = country => {
    if (!country) return;
    // check if country is in the geoData
    if (!geoData[country]) return;

    setCities(geoData[country]);
  };

  if (loading.page) return <div>Loading...</div>;

  return (
    businesses.length > 0 ? (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesRegister}
      validationSchema={registerSchema}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label='Job Title'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.jobTitle}
              name='jobTitle'
              helperText={touched.jobTitle ? errors.jobTitle : ''}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
            />
            <TextField
              label='Description'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              name='description'
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
              multiline
              rows={4}
            />

            <Autocomplete
              name='country'
              value={values.country}
              isOptionEqualToValue={(option, value) => option === value}
              // onChange={(event, newValue) => {
              //   console.log('newValue', newValue);
              //   setFieldValue('country', newValue);
              //   populateCities(newValue);
              //   setFieldValue('city', '');
              // }}
              onInputChange={(event, newInputValue) => {
                setFieldValue('country', newInputValue);
                populateCities(newInputValue);
                setFieldValue('city', '');
              }}
              // freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={Object.keys(geoData)
                .map(option => option)
                .sort((a, b) => a.localeCompare(b))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Country'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  name='country'
                  helperText={touched.country ? errors.country : ''}
                  error={touched.country && Boolean(errors.country)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />

            <Autocomplete
              name='city'
              value={values.city}
              onChange={(event, newValue) => {
                setFieldValue('city', newValue);
              }}
              // freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={cities
                .map(option => option)
                .sort((a, b) => a.localeCompare(b))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='City'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  name='city'
                  helperText={touched.city ? errors.city : ''}
                  error={touched.city && Boolean(errors.city)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />

            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='occupation'>Occupation</InputLabel>
              <Select
                label='Occupation'
                name='occupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.occupation}
                onChange={
                  (handleChange,
                  e => {
                    setFieldValue('occupation', e.target.value);
                    setFieldValue('subOccupation', '');
                  })
                }
                onBlur={handleBlur}
                error={
                  Boolean(touched.occupation) && Boolean(errors.occupation)
                }
                helperText={touched.occupation && errors.occupation}
              >
                {OCCUPATION_DATA.sort((a, b) =>
                  a.name.localeCompare(b.name)
                ).map((occupation, index) => {
                  return (
                    <MenuItem key={index} value={occupation.name}>
                      {capitalize(occupation.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {touched.occupation && errors.occupation}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='subOccupation'>Sub Occupation</InputLabel>
              <Select
                disabled={!values.occupation}
                label='Sub Occupation'
                name='subOccupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.subOccupation}
                onChange={handleChange}
                onBlur={handleBlur}
                // error={
                //   Boolean(touched.subOccupation) &&
                //   Boolean(errors.subOccupation)
                // }
                // helperText={touched.subOccupation && errors.subOccupation}
              >
                {values.occupation &&
                  isString(values.occupation) &&
                  OCCUPATION_DATA.find(
                    occupation =>
                      occupation.name.toLowerCase() ===
                      values.occupation.toLowerCase()
                  )
                    .sublist.sort((a, b) => a.name.localeCompare(b.name))
                    .map((subOccupation, index) => {
                      return (
                        <MenuItem key={index} value={subOccupation.name}>
                          {capitalize(subOccupation.name)}
                        </MenuItem>
                      );
                    })}
              </Select>
              <FormHelperText error>
                {touched.subOccupation && errors.subOccupation}
              </FormHelperText>
            </FormControl>

            <TextField
              label='Salary'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.salary}
              name='salary'
              helperText={touched.salary ? errors.salary : ''}
              error={touched.salary && Boolean(errors.salary)}
            />

            <Autocomplete
              name='businessId'
              value={values.name}
              onChange={(event, newValue) => {
                setFieldValue('businessId', newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setFieldValue('businessId', newInputValue);
              }}
              // freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={businesses
                .map(option => option.name.name)
                .sort((a, b) => b.localeCompare(a))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Business Name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name='businessId'
                  helperText={touched.businessId ? errors.businessId : ''}
                  error={touched.businessId && Boolean(errors.businessId)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />

            <Button
              variant='contained'
              type='submit'
              disabled={loading.register}
            >
              {loading.register ? 'Loading...' : 'Submit'}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
    ): (
      <Grid container justifyContent='center'>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <h2>You don't have a business set up yet. To post a job, please create a business first by clicking the button below.</h2>
          <Button variant='contained' onClick={() => navigate('/business')}>Create Business</Button>
        </Grid>

      </Grid>
    )
  );
};

export default JobForm;
