import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { loadUser, setLogin } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import {
  COLLEGE_DATA,
  MATRICULATION_YEAR_DATA,
  OCCUPATION_DATA
} from 'utils/data';
import { geoData } from 'utils/geoData';
import { capitalize } from 'utils/helperFunction';
import * as yup from 'yup';

const NonElumForm = props => {
  const {
    email,
    errors,
    values,
    isLogin,
    touched,
    palette,
    isAdmin,
    resetForm,
    handleBlur,
    isNonMobile,
    setPageType,
    handleChange,
    setFieldValue,
    isUpdate
  } = props;

  return (
    <>
      <Box
        display='grid'
        gap='30px'
        gridTemplateColumns='repeat(4, minmax(0, 1fr))'
        sx={{
          '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' }
        }}
      >
        {!isLogin && (
          <>
            <TextField
              label='First Name'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name='firstName'
              error={Boolean(touched.firstName) && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              label='Last Name'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name='lastName'
              error={Boolean(touched.lastName) && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              sx={{ gridColumn: 'span 2' }}
            />

            {!isAdmin && (
              <Box
                gridColumn='span 4'
                border={`1px solid ${palette.neutral.medium}`}
                borderRadius='5px'
                p='1rem'
              >
                <Dropzone
                  acceptedFiles='.jpg,.jpeg,.png'
                  multiple={false}
                  onDrop={acceptedFiles =>
                    setFieldValue('picture', acceptedFiles[0])
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      border={`2px dashed ${palette.primary.main}`}
                      p='1rem'
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    >
                      <input {...getInputProps()} />
                      {!values.picture ? (
                        <p>Add Picture Here</p>
                      ) : (
                        <FlexBetween>
                          <Typography>{values.picture.name}</Typography>
                          <EditOutlinedIcon />
                        </FlexBetween>
                      )}
                    </Box>
                  )}
                </Dropzone>
              </Box>
            )}
          </>
        )}
        <TextField
          label='Email'
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={email}
          value={email ? email : values.email}
          name='email'
          error={Boolean(touched.email) && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          sx={{ gridColumn: 'span 4' }}
        />
    
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type='submit'
          sx={{
            m: '2rem 0',
            p: '1rem',
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            '&:hover': { color: palette.primary.main }
          }}
        >
          {isLogin ? (isUpdate ? 'UPDATE' : 'LOGIN') : isUpdate ? 'UPDATE' : 'REGISTER'}
        </Button>
        <Typography
          onClick={() => {
            setPageType(isLogin ? 'register' : 'login');
            resetForm();
          }}
          sx={{
            textDecoration: 'underline',
            color: palette.primary.main,
            '&:hover': {
              cursor: 'pointer',
              color: palette.primary.light
            }
          }}
        >
          {/* {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."} */}
        </Typography>
      </Box>
    </>
  );
};

const ElumForm = props => {
  const [cities, setCities] = useState([]);
  const {
    email,
    errors,
    values,
    isLogin,
    touched,
    palette,
    isAdmin,
    resetForm,
    handleBlur,
    isNonMobile,
    setPageType,
    handleChange,
    setFieldValue,
    isUpdate
  } = props;

  const populateCities = country => {
    if (!country) return;
    // check if country is in the geoData
    if (!geoData[country]) return;

    setCities(geoData[country]);
  };


  return (
    <>
      <Box
        display='grid'
        gap='30px'
        gridTemplateColumns='repeat(4, minmax(0, 1fr))'
        sx={{
          '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' }
        }}
      >
        {!isLogin && (
          <>
            <TextField
              label='First Name'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name='firstName'
              error={Boolean(touched.firstName) && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              label='Last Name'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name='lastName'
              error={Boolean(touched.lastName) && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              sx={{ gridColumn: 'span 2' }}
            />

            <FormControl sx={{ gridColumn: 'span 2' }}>
              <InputLabel id='college'>College</InputLabel>
              <Select
                label='College'
                name='college'
                id='college'
                sx={{ width: '100%' }} // Adjust width for half the grid
                value={values.college}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.college) && Boolean(errors.college)}
                helperText={touched.college && errors.college}
              >
                {COLLEGE_DATA.sort((a, b) => a.name.localeCompare(b.name)).map(
                  (college, index) => {
                    return (
                      <MenuItem key={index} value={college.name}>
                        {capitalize(college.name)}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
              <FormHelperText error>
                {touched.college && errors.college}
              </FormHelperText>
            </FormControl>

            <FormControl sx={{ gridColumn: 'span 2' }}>
              <InputLabel id='matriculationYear'>Matriculation Year</InputLabel>
              <Select
                label='Matriculation Year'
                name='matriculationYear'
                id='matriculationYear'
                sx={{ width: '100%' }} // Adjust width for half the grid
                value={values.matriculationYear}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  Boolean(touched.matriculationYear) &&
                  Boolean(errors.matriculationYear)
                }
                helperText={
                  touched.matriculationYear && errors.matriculationYear
                }
              >
                {MATRICULATION_YEAR_DATA.map((year, index) => {
                  return (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {touched.matriculationYear && errors.matriculationYear}
              </FormHelperText>
            </FormControl>

            <Autocomplete
              name='location'
              sx={{ gridColumn: 'span 2' }}
              value={values.location}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, newValue) => {
                setFieldValue('location', newValue || {});
                populateCities(newValue ? newValue : '');
                setFieldValue('city', ''); // Clear dependent city field if location changes
              }}
              onInputChange={(event, newInputValue) => {
                // setFieldValue('location', newInputValue);
                populateCities(newInputValue);
                // setFieldValue('city', '');
              }}
              // freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={Object.keys(geoData)
                .map(option => option)
                .sort((a, b) => a.localeCompare(b))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Country'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  name='location'
                  helperText={touched.location ? errors.location : ''}
                  error={touched.location && Boolean(errors.location)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />

            <Autocomplete
              name='city'
              sx={{ gridColumn: 'span 2' }}
              value={values.city}
              onChange={(event, newValue) => {
                setFieldValue('city', newValue);
              }}
              // freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={cities
                .map(option => option)
                .sort((a, b) => a.localeCompare(b))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='City'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  name='city'
                  helperText={touched.city ? errors.city : ''}
                  error={touched.city && Boolean(errors.city)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />

            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='occupation'>Occupation</InputLabel>
              <Select
                label='Occupation'
                name='occupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.occupation}
                onChange={
                  (handleChange,
                  e => {
                    setFieldValue('occupation', e.target.value);
                    setFieldValue('subOccupation', '');
                  })
                }
                onBlur={handleBlur}
                error={
                  Boolean(touched.occupation) && Boolean(errors.occupation)
                }
                helperText={touched.occupation && errors.occupation}
              >
                {OCCUPATION_DATA.sort((a, b) =>
                  a.name.localeCompare(b.name)
                ).map((occupation, index) => {
                  return (
                    <MenuItem key={index} value={occupation.name}>
                      {capitalize(occupation.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {touched.occupation && errors.occupation}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='subOccupation'>Sub Occupation</InputLabel>
              <Select
                disabled={!values.occupation}
                label='Sub Occupation'
                name='subOccupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.subOccupation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  Boolean(touched.subOccupation) &&
                  Boolean(errors.subOccupation)
                }
                helperText={touched.subOccupation && errors.subOccupation}
              >
                {values.occupation &&
                  OCCUPATION_DATA.find(
                    occupation =>
                      occupation.name.toLowerCase() ===
                      values.occupation.toLowerCase()
                  )
                    .sublist.sort((a, b) => a.name.localeCompare(b.name))
                    .map((subOccupation, index) => {
                      return (
                        <MenuItem key={index} value={subOccupation.name}>
                          {capitalize(subOccupation.name)}
                        </MenuItem>
                      );
                    })}
              </Select>
              <FormHelperText error>
                {touched.subOccupation && errors.subOccupation}
              </FormHelperText>
            </FormControl>

            {!isAdmin && (
              <Box
                gridColumn='span 4'
                border={`1px solid ${palette.neutral.medium}`}
                borderRadius='5px'
                p='1rem'
              >
                <Dropzone
                  acceptedFiles='.jpg,.jpeg,.png'
                  multiple={false}
                  onDrop={acceptedFiles =>
                    setFieldValue('picture', acceptedFiles[0])
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      border={`2px dashed ${palette.primary.main}`}
                      p='1rem'
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    >
                      <input {...getInputProps()} />
                      {!values.picture ? (
                        <p>Add Picture Here</p>
                      ) : (
                        <FlexBetween>
                          <Typography>{values.picture.name}</Typography>
                          <EditOutlinedIcon />
                        </FlexBetween>
                      )}
                    </Box>
                  )}
                </Dropzone>
                {values.picture && (
                  <Box>
                    <Typography>Preview</Typography>
                    {typeof values.picture === 'string' ? (
                      <img
                        src={values.picture}
                        alt='preview'
                        style={{ width: '100px', height: '100px' }}
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(values.picture)}
                        alt='preview'
                        style={{ width: '100px', height: '100px' }}
                      />
                    )}
                  </Box>
                )}
                {/* error message if image not uploaded */}
                {Boolean(touched.picture) && Boolean(errors.picture) && (
                  <Box sx={{ color: 'red' }}>{errors.picture}</Box>
                )}
              </Box>
            )}
          </>
        )}
        <TextField
          label='Email'
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={email}
          disabled={email || isUpdate}
          value={email ? email : values.email}
          name='email'
          error={Boolean(touched.email) && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          sx={{ gridColumn: 'span 4' }}
        />
        {!isUpdate && (
          <TextField
            label='Password'
            type='password'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            name='password'
            error={Boolean(touched.password) && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            sx={{ gridColumn: 'span 4' }}
          />
        )}

   
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type='submit'
          sx={{
            m: '2rem 0',
            p: '1rem',
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            '&:hover': { color: palette.primary.main }
          }}
        >
          {isLogin ? (isUpdate ? 'UPDATE' : 'LOGIN') : isUpdate ? 'UPDATE' : 'REGISTER'}
        </Button>
      </Box>
    </>
  );
};

const Form = ({
  elum = true,
  isLogin = true,
  isAdmin = false,
  closeCreateModal,
  isUpdate = false,
  email,
  user
}) => {
  const formikRef = useRef();
  const [pageType, setPageType] = useState('login');
  const [errorMessage, setErrorMessage] = useState('');
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const registerSchema = yup.object().shape({
    elum: yup.boolean().required('required'),
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    // email: yup.string().email("invalid email").required("required"),
    // password should be minimum 8 characters long
    ...(elum && {password: yup.string().required('required').min(8, 'password must be at least 8 characters long')}),
    // location can either be string or object
    ...(elum && { location: yup
      .mixed()
      .required('required')
      .test('is-string-or-object', 'required', value => {
        if (typeof value === 'string') return true;
        if (typeof value === 'object') return true;
        return false;
      }) }),
    ...(elum && { city: yup.string().required('required') }),
    ...(elum && { occupation: yup.string().required('required') }),
    ...(elum && { subOccupation: yup.string().required('required') }),
    ...(elum && {
      picture: !isAdmin ? yup.string().required('required') : yup.string()
    }),
    ...(elum && { college: yup.string().required('required') }),
    ...(elum && { matriculationYear: yup.string().required('required') })
  });

  const updateSchma = yup.object().shape({
    elum: yup.boolean().required('required'),
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    location: yup.string().required('required'),
    city: yup.string().required('required'),
    occupation: yup.string().required('required'),
    subOccupation: yup.string().required('required'),
    picture: yup.string().required('required'),
    college: yup.string().required('required'),
    matriculationYear: yup.string().required('required')
  });

  const initialValuesRegister = {
    elum: elum,
    firstName: '',
    lastName: '',
    email: email,
    password: '',
    location: '',
    occupation: '',
    subOccupation: '',
    picture: '',
    college: '',
    matriculationYear: ''
  };

  const initialValuesUpdate = {
    elum: elum,
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    location: user ? user.location : '',
    city: user ? user.city : '',
    occupation: user ? user.occupation : '',
    subOccupation: user ? user.subOccupation : '',
    picture: user ? user.picturePath : '',
    college: user && user.college ? user.college : '',
    matriculationYear:
      user && user.matriculationYear ? user.matriculationYear : ''
  };

  const initialValuesLogin = {
    email: '',
    password: ''
  };

  const loginSchema = yup.object().shape({
    email: yup.string().email('invalid email').required('required'),
    ...(elum && { password: yup.string().required('required') })
  });

  const update = async (values, onSubmitProps) => {
    let response = null;
    if (elum) {
      values.email = email ? email : values.email;
      const formData = new FormData();
      for (let value in values) {
        if (value === 'picture' && typeof values.picture === 'string') {
          continue;
        }

        formData.append(value, values[value]);
      }
      formData.append('picturePath', values.picture.name);
      response = await axiosHelper(API_METHOD.PUT, 'auth', 'update', formData);

      if (response && response.isSuccess) {
        dispatch(
          loadUser({
            user: response.user
          })
        );
        navigate('/home');
      }
    }
  };

  const register = async (values, onSubmitProps) => {
    let response = null;
    if (elum) {
      values.email = email ? email : values.email;
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      formData.append('picturePath', values.picture.name);
      response = await axiosHelper(
        API_METHOD.POST,
        'auth',
        'register',
        formData
      );

      if (response && response.isSuccess) {
        dispatch(
          setLogin({
            user: response.user,
            token: response.token
          })
        );
        navigate('/home');
      }
    } else {
      response = await axiosHelper(
        API_METHOD.POST,
        'auth',
        'registerGuest',
        values
      );
    }
    if (response && response.isSuccess) {
      dispatch(
        setLogin({
          user: response.user,
          token: response.token
        })
      );
      // navigate('/home');
    }

    // if (savedUser && !isAdmin) {
    //   setPageType('login');
    // }
    // else if (savedUser && isAdmin) {
    //   closeCreateModal()
    // }
  };

  const login = async (values, onSubmitProps) => {
    const res = elum
      ? await axiosHelper(API_METHOD.POST, 'auth', 'login', values)
      : await axiosHelper(API_METHOD.POST, 'auth', 'guestLogin', values);

    onSubmitProps.resetForm();

    if (res && res.isSuccess) {
      dispatch(
        setLogin({
          user: res.user,
          token: res.token
        })
      );
      navigate('/home');
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await login(values, onSubmitProps);
    else {
  
      try {
        if (isUpdate) {
          await update(values, onSubmitProps);
        } else {
          await register(values, onSubmitProps);
        }
        // if (isAdmin) closeCreateModal();
        // else closeCreateModal();
      } catch (error) {}
    }
  };

  // useEffect(() => {
  //   // formikRef.current.setFieldValue('elum', elum);
  // }, [elum]);


  return (
    <Formik
      innerRef={formikRef}
      onSubmit={handleFormSubmit}
      initialValues={
        isLogin
          ? initialValuesLogin
          : isUpdate
          ? {
              ...initialValuesUpdate
            }
          : {
              ...initialValuesRegister,
              email
            }
      }
      validationSchema={
        isLogin ? loginSchema : isUpdate ? updateSchma : registerSchema
      }
    >
      {({
        values,
        errors,
        touched,
        resetForm,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          {elum ? (
            <ElumForm
              email={email}
              errors={errors}
              values={values}
              isLogin={isLogin}
              touched={touched}
              palette={palette}
              isAdmin={isAdmin}
              resetForm={resetForm}
              handleBlur={handleBlur}
              isNonMobile={isNonMobile}
              setPageType={setPageType}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              isUpdate={isUpdate}
            />
          ) : (
            <NonElumForm
              email={email}
              errors={errors}
              values={values}
              isLogin={isLogin}
              touched={touched}
              palette={palette}
              isAdmin={isAdmin}
              resetForm={resetForm}
              handleBlur={handleBlur}
              isNonMobile={isNonMobile}
              setPageType={setPageType}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              isUpdate={isUpdate}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default Form;
