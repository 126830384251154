import {
  GroupRemove,
  PersonAddOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "hooks/useRedux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state/auth";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";

const Friend = ({
  name,
  groupId,
  isAdmin,
  subtitle,
  friendId,
  isShow = true,
  userPicturePath,
  removeFromGroup,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useAppSelector((state) => state.auth);
  const { _id, elum } = user;
  const friends = user.friends;

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isFriend =
    friends &&
    friends.length > 0 &&
    friends.find((friend) => friend._id === friendId);
  const notCurrentUser = _id !== friendId;

  const patchFriend = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/${_id}/${friendId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };

  return (
    <FlexBetween>
      {isShow && (
        <FlexBetween gap="1rem">
          <UserImage image={userPicturePath} size="55px" />
          <Box
            onClick={() => {
              navigate(`/profile/${friendId}`);
              navigate(0);
            }}
          >
            <Typography
              color={main}
              variant="h5"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {name}
            </Typography>
            <Typography color={medium} fontSize="0.75rem">
              {subtitle}
            </Typography>
          </Box>
        </FlexBetween>
      )}
      <Box>
        {elum && groupId && (
          <IconButton>
            <GroupRemove
              sx={{ color: primaryDark }}
              onClick={() => removeFromGroup(friendId)}
            />
          </IconButton>
        )}

        {elum && notCurrentUser && (
          <IconButton
            onClick={() => patchFriend()}
            // sx={{ backgroundColor: primaryLight, p: '0.6rem' }}
          >
            {isFriend ? (
              <PersonRemoveOutlined sx={{ color: primaryDark }} />
            ) : (
              <PersonAddOutlined sx={{ color: primaryDark }} />
            )}
          </IconButton>
        )}
      </Box>
    </FlexBetween>
  );
};

export default Friend;
