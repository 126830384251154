import {
  Business,
  Close,
  CurrencyExchange,
  DarkMode,
  Event,
  Groups,
  Home,
  LightMode,
  Message,
  SearchOffOutlined,
  WorkHistory
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLogout, setMode } from 'state/auth';

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.auth.user);
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <FlexBetween
      padding='1rem 6%'
      backgroundColor={alt}
      sx={{
        position: 'sticky',
        top: '0',
        zIndex: '1000'
      }}
    >
      <FlexBetween gap='1.75rem'>
        <Typography
          fontWeight='bold'
          fontSize='clamp(1rem, 2rem, 2.25rem)'
          color='primary'
          onClick={() => navigate('/home')}
          sx={{
            '&:hover': {
              color: primaryLight,
              cursor: 'pointer'
            }
          }}
        >
          {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
        {/* {isNonMobileScreens && user && (
          // <FlexBetween
          //   backgroundColor={neutralLight}
          //   borderRadius="9px"
          //   gap="3rem"
          //   padding="0.1rem 1.5rem"
          // >
          //   <InputBase placeholder="Search..." />
          //   <IconButton>
          //     <Search />
          //   </IconButton>
          // </FlexBetween>
          <SearchBox neutralLight={neutralLight} />
        )} */}
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap='2rem'>
          <Tooltip
            title={theme.palette.mode === 'dark' ? 'Light mode' : 'Dark mode'}
          >
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === 'dark' ? (
                <DarkMode sx={{ fontSize: '25px' }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: '25px' }} />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title='Home'>
            <IconButton
              onClick={() => navigate('/home')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Home sx={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>

          <IconButton
            aria-controls='simple-menu'
            onClick={() => navigate('/search')}
            sx={{
              color: dark,
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            <Tooltip title='Search'>
              <SearchOffOutlined sx={{ fontSize: '25px' }} />
            </Tooltip>
          </IconButton>

          <Tooltip title='Business'>
            <IconButton
              onClick={() => navigate('/business')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Business sx={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title='Jobs'>
            <IconButton
              onClick={() => navigate('/jobs')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <WorkHistory sx={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>

          <IconButton
            aria-controls='simple-menu'
            onClick={() => navigate('/funding')}
            sx={{
              color: dark,
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            <Tooltip title='Funding'>
              <CurrencyExchange sx={{ fontSize: '25px' }} />
            </Tooltip>
          </IconButton>
          <IconButton
            aria-controls='simple-menu'
            onClick={() => navigate('/event')}
            sx={{
              color: dark,
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            <Tooltip title='Events'>
              <Event sx={{ fontSize: '25px' }} />
            </Tooltip>
          </IconButton>

          <Tooltip title='Groups'>
            <IconButton
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={() => navigate('/groups')}
              aria-label='Open to show more'
              title='Open to show more'
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Groups sx={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title='Messages'>
            <IconButton
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={() => navigate('/chat')}
              aria-label='Open to show more'
              title='Open to show more'
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Message sx={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>

          <FormControl variant='standard' value={fullName}>
            <Select
              value={fullName}
              sx={{
                backgroundColor: neutralLight,
                width: '150px',
                borderRadius: '0.25rem',
                p: '0.25rem 1rem',
                '& .MuiSvgIcon-root': {
                  pr: '0.25rem',
                  width: '3rem'
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: neutralLight
                }
              }}
              input={<InputBase />}
            >
              <MenuItem
                value={fullName}
                onClick={() => navigate(`/profile/${user._id}`)}
              >
                <Typography>{fullName}</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate(`/settings`)}>
                <Typography>Profile & Settings</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/contact-us')}>
                Contact Us
              </MenuItem>
              <MenuItem onClick={() => dispatch(setLogout())}>Log Out</MenuItem>
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position='fixed'
          right='0'
          bottom='0'
          height='100%'
          zIndex='10'
          maxWidth='500px'
          minWidth='300px'
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display='flex' justifyContent='flex-end' p='1rem'>
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            gap='3rem'
          >
            <IconButton
              onClick={() => dispatch(setMode())}
              sx={{ fontSize: '25px' }}
            >
              {theme.palette.mode === 'dark' ? (
                <DarkMode sx={{ fontSize: '25px' }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: '25px' }} />
              )}
            </IconButton>
            <IconButton
              onClick={() => navigate('/home')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Home sx={{ fontSize: '25px' }} />
            </IconButton>
            <IconButton
              onClick={() => navigate('/business')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Business sx={{ fontSize: '25px' }} />
            </IconButton>
            <IconButton
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={() => navigate('/funding')}
              aria-label='Open to show more'
              title='Open to show more'
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <CurrencyExchange sx={{ fontSize: '25px' }} />
            </IconButton>

            <IconButton
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={() => navigate('/groups')}
              aria-label='Open to show more'
              title='Open to show more'
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Groups sx={{ fontSize: '25px' }} />
            </IconButton>
            <IconButton
              aria-controls='simple-menu'
              onClick={() => navigate('/event')}
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Tooltip title='Events'>
                <Event sx={{ fontSize: '25px' }} />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={() => navigate('/chat')}
              aria-label='Open to show more'
              title='Open to show more'
              sx={{
                color: dark,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Message sx={{ fontSize: '25px' }} />
            </IconButton>
            <FormControl variant='standard' value={fullName}>
              <Select
                value={fullName}
                sx={{
                  backgroundColor: neutralLight,
                  width: '150px',
                  borderRadius: '0.25rem',
                  p: '0.25rem 1rem',
                  '& .MuiSvgIcon-root': {
                    pr: '0.25rem',
                    width: '3rem'
                  },
                  '& .MuiSelect-select:focus': {
                    backgroundColor: neutralLight
                  }
                }}
                input={<InputBase />}
              >
                <MenuItem
                  // crop the f
                  value={fullName}
                  onClick={() => navigate(`/profile/${user._id}`)}
                >
                  <Typography>{fullName}</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate(`/settings`)}>
                  Profile 
                </MenuItem>
                <MenuItem onClick={() => navigate('/contact-us')}>
                  Contact Us
                </MenuItem>
                <MenuItem onClick={() => dispatch(setLogout())}>
                  Log Out
                </MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Navbar;

