import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyEmailPage = () => {
  // get the token from the url
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = location.search.split("=")[1];
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/verify-email`, { token })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 
  return (
    <div>VerifyEmailPage</div>
  )
}

export default VerifyEmailPage