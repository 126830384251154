import { Avatar, Box, TextField, Typography } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from 'hooks/useRedux';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDisplayNames } from 'utils/helperFunction';

export default function SearchBox({
  neutralLight,
  isMessage = false,
  onClick
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const { token, user } = useAppSelector(state => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(null);

  const navigate = useNavigate();

  const searchByKeyword = keyword => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_BASE_URL}/users/search/${keyword}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => res.json())
      .then(data => {
        const filteredData = data.filter(item => item._id !== user._id);
        setOptions(filteredData);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Autocomplete
      id='asynchronous-demo'
      sx={{ width: isMessage ? '100%' : 300 }}
      open={open}
      onInputChange={(event, newInputValue) => {
        setKeyword(newInputValue);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={x => x}
      onSubmit={e => {
        e.preventDefault();
      }}
      inputValue={keyword}
      onChange={(event, newValue) => {
        if (!newValue) return;
        if (!isMessage) navigate(`/profile/${newValue._id}`);
        else onClick(newValue._id);

        setValue(newValue);
      }}
      value={value}
      getOptionLabel={option => `${option.firstName} ${option.lastName}`}
      renderOption={(props, option) => (
        <Box
          component='li'
          id={option._id}
          sx={{
            '& > img': { mr: 2, flexShrink: 0 },
            cursor: 'pointer',
            pointerEvents: 'all'
          }}
          onClick={() => {
            if (!isMessage) navigate(`/profile/${option._id}`);
            else onClick(option._id);
          }}
          {...props}
        >
          <Avatar src={option.picturePath || ''}>
            {option && option.firstName &&  option.firstName[0].toUpperCase() +
             option && option.lastName &&  option.lastName[0].toUpperCase() }
          </Avatar>
          <Typography variant='body2' color='text.primary' pl={2}>
            {getDisplayNames(option.firstName, option.lastName)}
          </Typography>
        </Box>
      )}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label='Search Connection by Name or Email'
          variant='outlined'
          onChange={e => {
            setKeyword(e.target.value);
            searchByKeyword(e.target.value);
          }}
        />
      )}
    />
  );
}
