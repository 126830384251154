export const getDisplayNames = (firstName, lastName) => {
  if (!firstName || !lastName) return;
  // make the first letter of the first name uppercase
  const firstNameCapitalized = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  // make the first letter of the last name uppercase
  const lastNameCapitalized = lastName.charAt(0).toUpperCase() + lastName.slice(1);
  // return the first and last name
  return `${firstNameCapitalized} ${lastNameCapitalized}`;
}

export const capitalize = (string) => {
  if (!string) return;
  // create an array of articles, prepositions, and conjunctions
  const articlesPrepositionsConjunctions = ['a', 'an', 'the', 'at', 'by', 'for', 'in', 'of', 'on', 'to', 'up', 'and', 'as', 'but', 'it', 'or', 'nor'];
  // create an array of words from the string
  const words = string.split(' ');
  // create an array of capitalized words
  const capitalizedWords = words.map((word) => {
    // if the word is not an article, preposition, or conjunction
    if (!articlesPrepositionsConjunctions.includes(word)) {
      // return the word with the first letter capitalized
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // otherwise, return the word
    return word;
  });
  // return the capitalized words joined together into a string
  return capitalizedWords.join(' ');
}

// get first character of of each word in a string
export const getInitials = (string) => {
  if (!string) return;
  // create an array of words from the string
  const words = string.split(' ');
  // create an array of the first characters of each word
  const initials = words.map((word) => {
    // return the first character of the word
    return word.charAt(0);
  });
  // return the init
  return initials.join('');
}


// utils/validation.js or directly in your component fileexpor
export const isValidEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};
