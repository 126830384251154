import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

const CustomModal = (props) => {
  const {
    open,
    setOpen,
    children,
    size = "sm",
    handleSubmit,
    displayButtons,
    isSubmitDisabled,
  } = props;

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: size === "sm" ? 400 : size === "md" ? 600 : 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Box sx={style}>
          {children}
          {Boolean(displayButtons) && (
            <Stack spacing={2} direction="row">
              <Button
                disabled={isSubmitDisabled}
                variant="outlined"
                color="success"
                onClick={handleSubmit}
              >
                {displayButtons?.submit || "Submit"}
              </Button>
              <Button variant="outlined" onClick={handleClose} color="error">
                {displayButtons?.cancel || "Close"}
              </Button>
            </Stack>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CustomModal;
