// SupportPage.js
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import Footer from 'components/Footer';
import SubscriptionBox from 'components/SubscriptionBox';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SupportPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const subscriptionData = [
    {
      title: 'Support',
      description: '$5/month',
      buttonText: 'Subscribe',
      planId: 'P-6H3082955J434192DMYUX7CA' // Replace with your plan_id
    },
    {
      title: 'Support',
      description: '$15/month',
      buttonText: 'Subscribe',
      planId: 'P-18S912782D2452621MYUYAMA' // Replace with your plan_id
    },
    {
      title: 'Support',
      description: '$50/month',
      buttonText: 'Subscribe',
      planId: 'P-9XP40353DS640943CMYUYBHQ' // Replace with your plan_id
    }
  ];

  useEffect(() => {
    // Load PayPal SDK script
    const script = document.createElement('script');
    script.src =
      'https://www.paypal.com/sdk/js?client-id=sb&vault=true&intent=subscription';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Render PayPal subscription buttons for each subscription box
      subscriptionData.forEach(({ planId }) => {
        // eslint-disable-next-line no-undef
        paypal
          .Buttons({
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: planId
              });
            },
            onApprove: function (data, actions) {
              alert(data.subscriptionID); // You can add optional success message for the subscriber here
            }
          })
          .render(`#paypal-button-container-${planId}`);
      });
    };

    return () => {
      // Clean up the script tag
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
      <Box
        width='100%'
        backgroundColor={theme.palette.background.alt}
        p='1rem 6%'
        textAlign='center'
        onClick={() => navigate('/')}
        style={{ cursor: 'pointer' }}
      >
        <Typography fontWeight='bold' fontSize='32px' color='primary'>
          {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ maxWidth: '100%', m: '2rem auto' }}>
        <Grid item xs={12} md={12}>
          <Box
            p='2rem'
            borderRadius='1.5rem'
            backgroundColor={theme.palette.background.alt}
          >
            <Typography
              variant='h4'
              color='primary'
              textAlign='center'
              mb='1rem'
            >
              Support fearless, independent journalism
            </Typography>
            <Typography
              variant='body1'
              color='textPrimary'
              textAlign='center'
              mb='1rem'
            >
              We're not owned by a billionaire or shareholders - our readers
              support us. Choose to join with one of the options below. Cancel
              anytime.
            </Typography>
          </Box>
        </Grid>
        {subscriptionData.map((data, index) => (
          <Grid item xs={12} md={4} key={index}>
            <SubscriptionBox {...data} />
          </Grid>
        ))}
      </Grid>
      <Footer />
    </Box>
  );
};

export default SupportPage;
