import socket from "configs/socket";
import { useAppSelector } from "hooks/useRedux";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { Typography, createStyles, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { getDisplayNames } from "utils/helperFunction";
import { useChat } from "hooks/useChat";

const styles = createStyles({
  messageArea: {
    maxHeight: "70vh",
    backgroundColor: "red",
    overflowY: "auto",
  },
});

const GroupChats = () => {
  const [messages, setMessages] = React.useState([]);
  const messageRef = React.useRef();
  const [userId, setUserId] = React.useState("");
  const theme = useTheme();
  const listRef = useRef(null);
  const { getGroupChats } = useChat();

  const { _id, email } = useAppSelector((state) => state.auth.user);
  const { id } = useParams();

  const [userTypedMessage, setuserTypedMessage] = useState("");

  const sendMessage = () => {
    socket.emit("groupMessage", {
      user: _id,
      groupId: id,
      message: userTypedMessage,
      addedAt: Date.now(),
      email,
    });
    setuserTypedMessage("");
  };

  const groupChatsData = async () => {
    const res = await getGroupChats(id);
    setMessages(res);
  };

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    groupChatsData();
  }, []);

  React.useEffect(() => {
    socket.on("newMessage", (message) => {
      setMessages([...messages, message]);
    });
    //eslint-disable-next-line
  }, [messages]);

  React.useEffect(() => {
    socket.emit("joinGroup", {
      groupId: id,
    });

    return () => {
      //Component Unmount
      socket.emit("leaveGroup", {
        groupId: id,
      });
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <List
        style={{
          height: "50vh",
          overflowY: "auto",
          width: "100%",
        }}
        ref={listRef}
      >
        {messages &&
          messages?.map(({ name, addedAt, message, user }, index) => {
            let isSelfUser = user === _id;
            return (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: isSelfUser ? "flex-end" : "flex-start",
                  justifySelf: isSelfUser ? "flex-end" : "flex-start",
                  alignSelf: isSelfUser ? "flex-end" : "flex-start",
                  width: "100%",
                }}
              >
                <ListItem
                  key={index}
                  sx={{
                    bgcolor: isSelfUser
                      ? theme.palette.primary.light
                      : theme.palette.secondary.light,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                    padding: theme.spacing(1),
                    paddingX: theme.spacing(2),
                    width: "fit-content",
                    display: "flex",
                    color: isSelfUser
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                  }}
                >
                  <ListItemText
                    align={isSelfUser ? "right" : "left"}
                    primary={message}
                    sx={{
                      color: isSelfUser
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.contrastText,
                      "&:hover": {
                        color: isSelfUser
                          ? theme.palette.primary.contrastText
                          : theme.palette.secondary.contrastText,
                      },
                    }}
                  />
                </ListItem>
                <div>
                  <Typography noWrap fontSize={11}>
                    {name}
                  </Typography>
                  <Typography color={"gray"} fontSize={10} noWrap>
                    {new Date(parseInt(addedAt)).toDateString()}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
      </List>
      <Divider />
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={11}>
          <TextField
            id="outlined-basic-email"
            label="Type Something"
            fullWidth
            value={userTypedMessage}
            onChange={(e) => setuserTypedMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
        </Grid>
        <Grid xs={1} align="right">
          <Fab color="primary" aria-label="add" onClick={sendMessage}>
            <SendIcon />
          </Fab>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupChats;
