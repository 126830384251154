import { Button, Grid, TextField } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import * as yup from 'yup';

const ChangePasswordForm = () => {
  const { user } = useSelector(state => state.auth);

  const handleSubmit = async (values, { setSubmitting }) => {
    const res = axiosHelper(API_METHOD.POST, 'auth', 'change-password', {
      email: user.email,
      password: values.currentPassword,
      newPassword: values.newPassword
    });
    if (res.isSuccess) {
      console.log('Password changed');
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }}
      validationSchema={yup.object({
        currentPassword: yup.string().required('Required'),
        newPassword: yup
          .string()
          .required('Required')
          .min(8, 'New password must be at least 8 characters long'),
        confirmPassword: yup
          .string()
          .oneOf(
            [yup.ref('newPassword'), null],
            'New passwod and confirm password must match'
          )
      })}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='currentPassword'
                label='Current Password'
                type='password'
                fullWidth
                error={Boolean(errors.currentPassword)}
                helperText={<ErrorMessage name='currentPassword' />}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='newPassword'
                label='New Password'
                type='password'
                fullWidth
                error={Boolean(errors.newPassword)}
                helperText={<ErrorMessage name='newPassword' />}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='confirmPassword'
                label='Confirm Password'
                type='password'
                fullWidth
                error={Boolean(errors.confirmPassword)}
                helperText={<ErrorMessage name='confirmPassword' />}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' variant='contained' color='primary'>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
