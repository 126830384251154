import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const QuickSearch = ({ title, keyword, setKeyword }) => {
  return (
    <>
      <Typography variant="h4" fontWeight="700">
        {title}
      </Typography>
      <Box sx={{ my: 2 }} />
      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
      />
      <br />
      <br />
    </>
  );
};

export default QuickSearch;
