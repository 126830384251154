import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import Footer from 'components/Footer';
import { Field, Form, Formik } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import Navbar from 'scenes/navbar';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { getDisplayNames } from 'utils/helperFunction';
import * as Yup from 'yup';

const ContactUs = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { user } = useAppSelector(state => state.auth);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Send the form data to your backend or email service
    // For example, using fetch or emailjs
    console.log('Form data:', values);
    try {
      const res = await axiosHelper(API_METHOD.POST, 'users', 'contact', {
        ...values,
        email: user.email,
        name: getDisplayNames(user.firstName, user.lastName)
      });
      if (res.isSuccess) {
        resetForm();
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitting(false);
    }
  };

  return (
    <Box pb={10} height='100vh'>
      <Navbar />
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap='0.5rem'
        justifyContent='center'
      >
        <Box mt={isNonMobileScreens ? undefined : '2rem'} width='100%'>
          <Typography variant='h5' gutterBottom>
            Contact Us
          </Typography>
          <Formik
            initialValues={{ reason: '', message: '' }}
            validationSchema={Yup.object({
              reason: Yup.string().required('Please select a reason'),
              message: Yup.string().required('Please enter a message')
            })}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  as={TextField}
                  select
                  label='Select Reason'
                  name='reason'
                  fullWidth
                  margin='normal'
                  required
                >
                  <MenuItem value='help'>Help</MenuItem>
                  <MenuItem value='suggestion'>Suggestion</MenuItem>
                </Field>
                <Field
                  as={TextField}
                  multiline
                  rows={4}
                  label='Message'
                  name='message'
                  fullWidth
                  margin='normal'
                  required
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ContactUs;
