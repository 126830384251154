import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import {
  COLLEGE_DATA,
  MATRICULATION_YEAR_DATA,
  OCCUPATION_DATA
} from 'utils/data';
import { getDisplayNames } from 'utils/helperFunction';

const SearchPeople = () => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [college, setCollege] = useState('');
  const [matriculationYear, setMatriculationYear] = useState('');
  const [industry, setIndustry] = useState('');
  const navigate = useNavigate();

  const { token, user } = useAppSelector(state => state.auth);

  useEffect(() => {
    // Search functionality
    const searchByKeyword = async () => {
      setLoading(true);
      const query = `college=${college}&matriculationYear=${matriculationYear}&occupation=${industry}&search=${
        search ? search : 'all'
      }`;

      try {
        const response = await axiosHelper(
          API_METHOD.GET,
          `users/query/${query}`,
          ''
        );
        if (response) {
          const filteredData = response.filter(item => item._id !== user._id);
          setOptions(filteredData);
        }
      } catch (error) {
        console.error('Search error:', error);
      }
      setLoading(false);
    };

    searchByKeyword();
  }, [search, token, user._id, college, matriculationYear, industry]);

  const resetFilters = () => {
    setSearch('');
    setCollege('');
    setMatriculationYear('');
    setIndustry('');
    setOptions([]);
  };

  return (
    <Grid container spacing={2} style={{ width: '100%', padding: '20px' }}>
      {/* Search Input */}
      <Grid item xs={12} sm={6} md={3}>
        <Input
          placeholder='Search'
          fullWidth
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </Grid>
      {/* Filter for College */}
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <InputLabel>College</InputLabel>
          <Select
            value={college}
            label='College'
            onChange={e => setCollege(e.target.value)}
          >
            {COLLEGE_DATA.map(option => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* Filter for Matriculation Year */}
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <InputLabel>Matriculation Year</InputLabel>
          <Select
            value={matriculationYear}
            label='Matriculation Year'
            onChange={e => setMatriculationYear(e.target.value)}
          >
            {MATRICULATION_YEAR_DATA.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* Filter for Industry */}
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <InputLabel>Industry</InputLabel>
          <Select
            value={industry}
            label='Industry'
            onChange={e => setIndustry(e.target.value)}
          >
            {OCCUPATION_DATA.map(option => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={1}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={resetFilters}
        >
          Reset Filters
        </Button>
      </Grid>

      {/* Display the search results */}
      <Grid item xs={12}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          px={2}
          py={2}
          bgcolor='background.paper'
          boxShadow={1}
          style={{ fontWeight: 'bold', borderBottom: '1px solid #ccc' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div>Avatar</div>
            </Grid>
            <Grid item xs={2}>
              <div>Name</div>
            </Grid>
            <Grid item xs={2}>
              <div>Email</div>
            </Grid>
            <Grid item xs={2}>
              <div>College</div>
            </Grid>
            <Grid item xs={2}>
              <div>Matriculation Year</div>
            </Grid>
            <Grid item xs={2}>
              <div>Occupation</div>
            </Grid>
            <Grid item xs={1}>
              <div>Action</div>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {options.map((option, index) => (
        <Grid item xs={12} key={index}>
          <Box
            display='flex'
            alignItems='center'
            // justifyContent='space-between'
            gap={2}
            px={2}
            py={2}
            bgcolor='background.paper'
            boxShadow={1}
            style={{ borderBottom: '1px solid #ccc' }}
          >
            <Grid container spacing={2} display='flex' alignItems='center'>
              <Grid item xs={1}>
                {/* Avatar */}
                <div style={{ marginRight: '10px' }}>
                  <Avatar
                    alt={getDisplayNames(option.firstName, option.lastName)}
                    src={option.picturePath}
                  />
                </div>
              </Grid>

              <Grid item xs={2}>
                {/* Name */}
                <div>
                  <Typography>
                    {getDisplayNames(option.firstName, option.lastName)}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={2}>
                {/* Email */}
                <div>
	      {/*<Typography>{option.email || 'N/A'}</Typography>*/}
                  <Typography>Contact Form (tbd)</Typography>
                </div>
              </Grid>

              <Grid item xs={2}>
                {/* College */}
                <div>
                  <Typography>{option.college || 'N/A'}</Typography>
                </div>
              </Grid>

              <Grid item xs={2}>
                {/* Matriculation Year */}
                <div>
                  <Typography>{option.matriculationYear || 'N/A'}</Typography>
                </div>
              </Grid>

              <Grid item xs={2}>
                {/* Occupation */}
                <div>
                  <Typography>{option.occupation || 'N/A'}</Typography>
                </div>
              </Grid>
              <Grid item xs={1}>
                {/* Action */}
                <div>
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => {
                      navigate(`/profile/${option._id}`);
                    }}
                  >
                    View Profile
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default SearchPeople;
