import { Box, Stack, Typography } from '@mui/material';
import BusinessForm from 'components/BusinessForm';
import GroupForm from 'components/GroupForm';
import WidgetWrapper from 'components/WidgetWrapper';

const CreateGroupWidget = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <WidgetWrapper>
        <Stack direction='column' spacing={2}>
          <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='h5' fontWeight='500'>
              Create Group
            </Typography>
          </Stack>
          <GroupForm />
        </Stack>
      </WidgetWrapper>
    </Box>
  );
};

export default CreateGroupWidget;
