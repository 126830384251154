import { Tab, Tabs, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { TabPanel } from 'scenes/widgets/FundingWidget';
import { a11yProps } from 'scenes/widgets/eventsWidget';
import EventsForm from './EventForm';
import EventParticipants from './EventParticipants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Set the width of the modal
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function MyEventModal({ open, setOpen, event, getEvents }) {
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant='h4'
          fontWeight='700'
          sx={{ mb: '1.5rem', textAlign: 'center' }}
        >
          Events
        </Typography>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Edit Event' {...a11yProps(0)} />
            <Tab label='Event Details' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <EventsForm isEdit event={event} getEvents={getEvents} setOpen={setOpen} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EventParticipants event={event} />
        </TabPanel>
      </Box>
    </Modal>
  );
}
