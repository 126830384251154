import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversations: [],
  ready: false,
  sessionValid: true,
  refresh: false,
  newMessage: { cid: '', message: {} },
  isEmojiShow: false,
  inputEvent: {}
};

export const authSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    }
  }
});

export const { setConversations, setNewMessage } = authSlice.actions;
export default authSlice.reducer;
