import { Box, Grid } from "@mui/material";
import { useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import DiscussionWidget from "./DiscussionWidget";
import { TabPanel } from "./FundingWidget";
import GroupHeaderWidget from "./GroupHeaderWidget";
import GroupListWidget from "./GroupListWidget";
import GroupMemberWidget from "./GroupMemberWidget";
import GroupChats from "./GroupChats";
import EventsPage from "scenes/eventPage";
import EventsWidget from "./eventsWidget";
import GroupEventsWidget from "./GroupEventsWidget";

const SingleGroupWidget = (props) => {
  const { group, pageLoading, removeFromGroup, addMembersToGroup } = props;
  const [value, setValue] = useState(0);
  const [groups, setGroups] = useState([]);
  const [events, setEvents] = useState([]);
  const { _id, elum } = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setEvents(group?.events);
  // }, [group?.events]);

  const getGroups = async () => {
    try {
      setIsLoading(true);
      const response = await axiosHelper(API_METHOD.GET, "groups", `${_id}`);
      setIsLoading(false);
      if (response.isSuccess) {
        setGroups(response.group);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  if (!group) return <div>loading...</div>;

  const isAdmin = group.adminMembers.find((admin) => admin._id === _id);

  console.log("group", group);
  return (
    <Box
      sx={{
        height: "100vh",
        // overflowY: 'scroll',
        // background: 'red'
      }}
    >
      <Grid container spacing={2}>
        {elum && (
          <Grid item xs={12} sm={4} md={3}>
            <GroupListWidget isLoading={isLoading} groups={groups} />
          </Grid>
        )}
        <Grid item xs={12} sm={elum ? 8 : 11} md={elum ? 9 : 12}>
          {pageLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <GroupHeaderWidget
                group={group}
                value={value}
                handleChange={handleChange}
              />

              <TabPanel value={value} index={0}>
                <DiscussionWidget group={group} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <GroupMemberWidget
                  group={group}
                  removeFromGroup={removeFromGroup}
                  addMembersToGroup={addMembersToGroup}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <GroupChats />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <GroupEventsWidget
                  group={group}
                  isAdmin={isAdmin}
                  
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleGroupWidget;
