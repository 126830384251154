import { Box } from '@mui/material';
import AdminTable from 'components/AdminTable';
import Navbar from 'scenes/navbar';

const AdminPage = () => {
  return (
    <Box>
      <Navbar/>
      <AdminTable />
    </Box>
  );
};

export default AdminPage;
