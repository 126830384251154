export const geoData = {
  China: [
    'Guangzhou',
    'Fuzhou',
    'Beijing',
    'Baotou',
    'Hohhot',
    'Guiyang',
    'Yinchuan',
    'Nanjing',
    'Changzhou',
    'Chuzhou',
    'Hefei',
    'Jinan',
    'Qingdao',
    'Harbin',
    'Zhaodong',
    'Taiyuan',
    "Xi'an",
    'Xianyang',
    'Shenzhen',
    'Nanning',
    'Zhengzhou',
    'Xinxiang',
    'Luohe',
    'Luoyang',
    'Chaoyang',
    'Xingyi',
    'Foshan',
    'Haikou',
    'Chengdu',
    'Dongguan',
    'Mingzhou',
    'Chongqing',
    'Zhuhai',
    'Kunming',
    'Wuhan',
    'Xiling',
    'Huizhou',
    'Jiangmen',
    'Shantou',
    'Changxiacun',
    'Zhongshan',
    'Lhasa',
    'Nanchang',
    'Tianjin',
    'Shanghai',
    'Hebei',
    'Shijiazhuang',
    'Quanzhou',
    'Putian',
    'Xiamen',
    'Chengyang',
    'Zhangzhou',
    'Sanming',
    'Nanping',
    'Baoding',
    'Langfang',
    'Yantai',
    'Binzhou',
    'Lanzhou',
    'Yueqing',
    'Zhongxin',
    'Zhoushan',
    'Hangzhou',
    'Ningbo',
    'Wenzhou',
    'Changchun',
    'Fuyang',
    'Jieshou',
    'Anqing',
    'Wuhu',
    'Shishi',
    'Shishi',
    'Weitang',
    'Shenyang',
    'Changsha',
    'Yongjiawan',
    'Lengshuijiang',
    'Shijiazhuang',
    'Xuchang',
    'Suzhou',
    'Xuzhou',
    'Taizhou',
    'Nanyang',
    'Xinhua',
    'Ürümqi',
    "Yan'an Beilu",
    'Baotao',
    'Macao',
    'Wuxi',
    'Yangzhou',
    'Baiyin',
    'Tongren',
    'Kunshan',
    'Zhangjiagang',
    'Jiangyin',
    'Zhenjiang',
    'Zhoukou',
    'Anyang',
    'Dalian',
    'Tongcun',
    'Shihezi',
    'Xining',
    'Zhangye',
    'Qingyang',
    'Wangsu',
    'Anshun',
    'Kaili',
    'Bijie',
    'Shuigang',
    'Jianyuan',
    'Jinlong',
    'Jingdezhen',
    'Yichang',
    'Xiangfan',
    'Jiayuan',
    'Shashi',
    'Yingjie',
    'Huangshi',
    'Jinhua',
    'Zhengyuan',
    'Langli',
    'Hengyang',
    'Jianning',
    'Xiangtan',
    'Rongxing',
    'Xincao',
    'Jinda',
    'Nanlong',
    'Xiangcheng',
    'Nanma',
    'Zhongxiang',
    'Pudong',
    'Shuitou',
    'Zhenxing',
    'Jinjiang',
    'Longxun',
    'Guanqiao',
    'Jingfeng',
    'Shijing',
    'Tangbian',
    'Jiangchuanlu',
    'Jiaoyun',
    'Guizhoumanzuxiang',
    'Qingzhen',
    'Changde',
    'Xianning',
    'Jiaozhou',
    'Weifang',
    "Tai'an",
    'Luoxi',
    'Guoji',
    'Guangdong',
    'Sijiqing',
    'Huzhou',
    'Panjin Shi',
    'Daqing',
    'Jilin City',
    'Lianyungang',
    'Yancheng',
    'Yuehu',
    'Kunshan',
    'Taicang',
    'Lianshui',
    'Nantong',
    'Jiaotong',
    'Changshu City',
    'Xingxiangcun',
    'Jinsha',
    'Jiangyan',
    'Chaigoubu',
    "Ma'anshan",
    'Huainan',
    'Haibei',
    'Shenlong',
    'Nangxian',
    'Rongsheng',
    'Changfeng',
    'Chengqiao',
    'Jiafu',
    'Shenzhou',
    'Shantou',
    'Qingyuan',
    'Gyari',
    'Xinshijie',
    'Zhaoqing',
    'Zhanjiang',
    'Kuicheng',
    'Taoyuan',
    'Jincheng',
    'Caishen',
    'Shiyan',
    'Liaoyang',
    'Xingtai',
    'Wenchang',
    'Wanning',
    'Qionghai',
    'Huilongba',
    'Dingcheng',
    'Baodian',
    'Wuzhishan',
    'Chengmai',
    'Yinggen',
    'Ledong',
    'Lincheng',
    'Baisha',
    'Changjiang',
    'Dongfang',
    'Changjian',
    'Jinmao',
    'Yangpu',
    'Baipo',
    'Jiefang',
    'Danzhou',
    'Lingshui',
    'Haidian',
    'Sanya',
    'Rongjiang',
    'Longyan',
    'Jinghe',
    'Zhangjiakou',
    'Renqiu',
    'Yaocheng',
    'Kaifeng',
    'Hebi',
    'Jiaozuo',
    'Pingdingshan',
    'Anshan',
    'Dandong',
    'Haitang',
    'Tongchuan',
    'Ankang',
    'Guozhen',
    'Shangluo',
    'Xijing',
    'Weinan',
    'Yulin',
    'Yining',
    'Dingxi',
    'Wuwei',
    'Dawukou',
    'Lishui',
    'Quzhou',
    'Hejiang',
    'Handan',
    'Qinhuangdao',
    'Hengshui',
    'Longxin',
    "Wen'an",
    'Yichun',
    'Heihe',
    'Jiaxing',
    'Korla',
    'Kuytun',
    "Di'an",
    "Yu'an",
    'Mengzhou',
    'Hulu',
    'Yizhou',
    'Shuliang',
    'Shancheng',
    'Fushun',
    'Dashiqiao',
    'Laonian',
    'Shengli',
    'Wenquan',
    'Zhiye',
    'Lingzhi',
    'Zhongtang',
    'Gucheng',
    'Xinhua',
    'Ninghe',
    'Dangyang',
    'Yizhong',
    'Jizhou',
    'Tianbao',
    'Jinghai',
    'Julong',
    'Jiaqin',
    'Jiayue',
    'Dabaizhuang',
    'Juchuan',
    'Hexi',
    'Jinnan',
    'Hangu',
    'Nankai',
    'Hedong',
    'Yanglou',
    'Huawei',
    'Hanting',
    'Tianshi',
    'Baiyu',
    'Bohai',
    'Rujia',
    'Tongwang',
    'Meijiang',
    'Dagang',
    'Baodi',
    'Daqiuzhuang',
    'Yuxi',
    'Zicheng',
    'Shaoxing',
    'Zhoushan',
    'Xiaoshan',
    'Linhai',
    'Cixi',
    'Jinchuan',
    'Zhaobaoshan',
    'Tiangang',
    'Beilun',
    'Zhangqi',
    'Zhenghai',
    'Cicheng',
    'Lishu',
    'Chengbei',
    'Heyi',
    'Xikou',
    'Jiangkou',
    'Shunshui',
    'Simen',
    'Yuyao',
    'Lanjiang',
    'Jiangdong',
    'Gaotang',
    'Xiangshan',
    'Shipu',
    'Jinyi',
    'Chengzhong',
    'Yinzhou',
    'Luoyang',
    'Mapai',
    'Cangnan',
    'Jinxiangzhen',
    'Yingjia',
    'Pingyang',
    "Rui'an",
    'Tianfu',
    'Shangtang',
    'Yongjia',
    'Tiancheng',
    'Hongqiao',
    'Furong',
    'Wenxing',
    'Mingxi',
    'Jinshan',
    'Changtu',
    'Anzi',
    'Xianren',
    'Zhongxing',
    'Guanli',
    'Yucai',
    'Xianjiang',
    'Aojiang',
    'Dongtou',
    'Rongjiang',
    'Jinmen',
    'Qiantang',
    'Baojiang',
    'Huling',
    'Liushi',
    'Yuecheng',
    'Hongyun',
    'Longhua',
    'Yajin',
    'Simcun',
    'Longgang',
    'Yingdu',
    'Wansong',
    'Yuele',
    'Nanjiang',
    'Longhu',
    'Ningyi',
    'Fengling',
    'Wuzhou',
    'Xinchen',
    'Jinghu',
    'Fangzhuang',
    'Yinfang',
    'Cili',
    'Angu',
    'Feiyun',
    'Wanquan',
    'Kunyang',
    'Shibei',
    'Jiangnan',
    'Yujing',
    'Yishan',
    'Xuefeng',
    'Feilong',
    'Shangrao',
    'Xuexiao',
    'Yuzhen',
    'Huangbao',
    'Longquan',
    'Pizhou',
    'Songyang',
    'Qingtian',
    'Chenguang',
    'Kaiyuan',
    'Dongsheng',
    'Jinyun',
    'Zhongshan',
    'Miaogao',
    'Yuanli',
    'Shinian',
    'Qingfeng Chengguanzhen',
    'Liu`an',
    'Yulong',
    'Haixing',
    'Sanjiaocheng',
    'Pinghu',
    'Jinling',
    'Fengming',
    'Tongxiang',
    'Puyuan',
    'Dingqiao',
    'Yanjiang',
    'Wutong',
    'Pingchuan',
    'Dushu',
    'Nanxun',
    'Wuxing',
    'Yangzhou',
    'Hongyuan',
    'Anji',
    'Shangying',
    'Deqing',
    'Digang',
    'Sanguan',
    'Yuantong',
    'Changxin',
    'Huating',
    'Putuoshan',
    'Jinyuan',
    'Dinghai',
    'Xiangnan',
    'Putuo',
    'Xintian',
    'Donghuxu',
    'Zhuji',
    'Jingcheng',
    'Jiangtian',
    'Xingchang',
    'Jindou',
    'Xinchang',
    'Baiyun',
    'Qianqing',
    'Tianchang',
    'Tianchi',
    'Luzhou',
    'Qinjiang',
    'Tianzhu',
    'Chengguan',
    'Jinhong',
    'Huaqiao',
    'Maotai',
    'Hezhu',
    'Dahai',
    'Shanhu',
    'Changle',
    'Guoshang',
    'Dongshen',
    'Shangbu',
    'Zhedong',
    'Boxing',
    'Tianyuan',
    'Guodian',
    'Linping',
    'Meicheng',
    'Jiyang',
    'Tonglu',
    'Fuchunjiang',
    'Qiandaohu',
    'Yuhang',
    'Changsheng',
    'Honglin',
    'Xiaoheshan',
    'Binjiang',
    'Yijin',
    'Xunxian',
    'Qianshan',
    'Zhongzhou',
    'Chongxian',
    'Gongchang',
    'Huangyan',
    'Jiaojiang',
    'Wenling',
    'Xindu',
    'Sili',
    'Luqiao',
    'Baoshan',
    'Yanjing',
    'Jinqingzhen',
    'Gamlung',
    'Yiwu',
    'Dongyang',
    'Yongkang',
    'Lanxi',
    'Wuyi',
    'Wanjia',
    'Fotang',
    'Yuhai',
    'Yiting',
    'Puyang',
    'Longfeng',
    'Yueliangwan',
    'Renhe',
    'Yangfen',
    'Youjia',
    'Hanshang',
    'Jindu',
    'Junping',
    'Aoma',
    'Yinliang',
    'Lijing',
    'Renhou',
    'Wangshang',
    "Pan'an",
    'Longchuan',
    'Hengzhou',
    'Lianyuan',
    'Jinlun',
    'Qiaodou',
    'Shizhu',
    'Huajie',
    'Xixi',
    'Hengdian',
    'Dongcheng',
    'Dongdu',
    'Fusheng',
    'Yongjin',
    'Youyi',
    'Yuchi',
    'Haiyang',
    'Tashi',
    'Jiya',
    'Zhangqiu',
    'Shangdong',
    'Zoucheng',
    'Jining',
    'Linyi',
    'Feixian',
    'Yishui',
    'Zaozhuang',
    'Zibo',
    'Laiwu',
    'Jiyang',
    'Yayu',
    'Zhenzhuquan',
    'Changzhi',
    'Changping',
    'Daxing',
    'Fuling',
    'Xiangyuan',
    'Shiji',
    'Changshan',
    'Shangzhou',
    'Kaihua',
    'Jiangshan',
    'Longzhou',
    'Citai',
    'Jinyang',
    'Yanhai',
    'Xintai',
    'Yinjiang',
    'Guxiang',
    'Yindian',
    'Yiwu',
    'Qujiang',
    'Juhua',
    'Zhicheng',
    'Ningde',
    'Meizhou',
    'Shaowu',
    'Zhanghou',
    "Nan'an Qu",
    'Xuexi',
    'Hanjiang',
    'Huaihe',
    'Bengbu',
    'Dangshan',
    'Shecheng',
    'Huangshan',
    'Huaibei',
    'Suixi',
    'Tongling',
    'Xuancheng',
    'Huoshan',
    'Chizhou',
    'Bozhou',
    'Boshan',
    'Weihai',
    'Quanzhou',
    "Qi'ao",
    'Lingcheng',
    'Xinhuang',
    'Gaiyu',
    'Hanzhong',
    "Yan'an",
    'Fugu',
    'Longnan',
    'Xiushui',
    'Puyang',
    'Xiaogan',
    'Qiqihar',
    'Lung',
    'Sichuan',
    'Leshan',
    'Bazhou',
    'Mianyang',
    'Meishan',
    'Suining',
    'Youshi',
    'Baoxing',
    'Rongshan',
    'Tangtou',
    'Nanhai',
    'Nanzhuang',
    'Danzao',
    'Chaozhou',
    'Nanfang',
    'Jinli',
    'Heping',
    'Lantang',
    'Lianping',
    'Puning',
    'Puning',
    'Jiexi',
    'Huicheng',
    'Fengshun',
    'Pingyuan',
    'Shaoguan',
    'Jiangwan',
    'Licheng',
    'Xindi',
    'Xiangxi',
    'Luohu',
    'Renmin',
    'Dawang',
    'Luofang',
    'Liannan',
    'Jieyang',
    'Xunyi Chengguanzhen',
    'Shunde',
    'Yangjiang',
    'Nanzhao',
    'Chaohu',
    'Zijin',
    'Heyuan',
    'Gaozhou',
    'Maoming',
    'Gaoji',
    'Suqian',
    "Sha'an",
    'Xiaojiang',
    'Ruili',
    'Tangxia',
    'Baixiang',
    'Huangdu',
    'Daishan',
    'Daidong',
    'Daju',
    'Maoba',
    'Qushan',
    'Caiyuan',
    "Zhang'ancun",
    'Cezi',
    'Zhanmao',
    'Luomen',
    'Luhua',
    'Huanghua',
    'Baixin',
    'Ximeng',
    'Suzhuang',
    'Jiguang',
    'Zhenhua',
    'Jingning Chengguanzhen',
    'Jinhe',
    'Lake',
    'Huafeng',
    'Haijing',
    'Ninghai',
    'Xianrenqiao',
    'Junpu',
    'Nanxi',
    'Dongli',
    'Zhili',
    'Yalan',
    'Sanmen',
    'Tiantai Chengguanzhen',
    'Yuhuan',
    'Qingma',
    'Gucheng',
    'Puqing',
    'Shashan',
    'Donghai',
    'Xiasha',
    'Beichan',
    'Wanbao',
    'Shangye',
    'Penglai',
    'Gaoting',
    'Fudao',
    'Daixi',
    'Pengshan',
    'Shilou',
    'Xinjian',
    'Panxi',
    'Xiandu',
    'Qingquan',
    'Huimin',
    'Zhapu',
    'Maodun',
    'Qiaoting',
    'Ziyang',
    'Qingshan',
    'Guangming',
    'Fangxian Chengguanzhen',
    'Gushi',
    'Huzhen',
    'Shuhong',
    'Wuyang',
    'Hushan',
    'Deyang',
    'Neijiang',
    'Nanchong',
    'Yucheng',
    'Dazhou',
    'Xichang',
    'Taoshan',
    'Xiabancheng',
    'Dazu',
    'Gunan',
    'Heliang',
    'Futian',
    'Liangping',
    'Daotou',
    'Wuxia',
    'Wushan',
    'Houxiangcheng',
    'Xihu',
    'Lushang',
    'Xiaogang',
    'Weibo',
    'Daqiao',
    'Yinxin',
    'Kangda',
    'Andong',
    'Guancheng',
    'Moushan',
    'Qitai',
    'Jinghua',
    'Huayuan',
    'Hongtang',
    'Qiaoxia',
    'Shangwu',
    'Shengping',
    'Jinhu',
    'Shangwang',
    'Taoshan',
    'Baotian',
    "Hai'an",
    'Xinqiao',
    'Yongqiang',
    'Mayu',
    'Wenhou',
    'Tengjiao',
    'Xiaozheng',
    'Meiyu',
    'Yingqian',
    'Shangma',
    'Qiaozhen',
    'Hongshun',
    'Ruifeng',
    'Xianqiao',
    'Dungdo',
    'Gushan',
    'Changqing',
    'Oujiang',
    'Baoyu',
    'Guantian',
    'Caozhai',
    'Xiaoshun',
    'Tongbai',
    'Lijiang',
    'Yuxi',
    'Chengjian',
    'Chuxiong',
    'Lanping',
    'Zhaotong',
    'Gejiu',
    'Yisa',
    'Simao',
    'Jingdong',
    'Wenshang',
    'Wenshan',
    'Dali',
    'Qujing',
    'Xinning',
    'Dujiacun',
    'Yanta',
    'Tianhai',
    'Zhixing',
    'Henghe',
    'Ruixing',
    'Meixi',
    'Gongli',
    'Tongyu',
    'Sanjiang',
    'Tingpang',
    'Sanyou',
    'Aizhou',
    'Jundu',
    'Dipu',
    'Shuangtang',
    'Taimen',
    'Jiaotou',
    'Xiazhi',
    'Zhujiajian',
    'Taohua',
    'Liuheng',
    'Mingdu',
    'Shenjiamen',
    'Longshan',
    'Dengbu',
    'Xiushan',
    'Daizhong',
    'Longteng',
    'Beiyuan',
    'Taili',
    'Choucheng',
    'Lucheng',
    'Chengxiang',
    'Dongying',
    'Gangcun',
    'Sailing',
    'Shifan',
    'Yaohua',
    'Jingyuan',
    'Fengtai',
    'Gaocheng',
    "Gu'an",
    'Shuangshipu',
    "Li'ao",
    "Xi'ao",
    'Tang Xian',
    'Tara',
    "Li'an",
    "Hu'an",
    'Datang',
    'Tumu',
    'Shuyi',
    'Gaoxin',
    "Bo'ao",
    'Baocheng',
    'Haifu',
    'Xiuying',
    'Xuanzhou',
    'Gongjiang',
    'Huayuji',
    "Qu'an",
    'Fuhao',
    'Weiyang',
    'Laohekou',
    'Nanshan',
    'Chen Xian',
    'Siting',
    'Wuhou',
    "Ji'an",
    'Fuxin',
    'Xiqu',
    'Wangluo',
    'Erqi',
    'Donghong',
    'Gaobeidian',
    'Lianhua',
    'Chenzhou',
    'Dajie',
    'Chenghua',
    'Dongfeng',
    "Zhu'ang",
    'Maji',
    'Songling',
    'Chum',
    'Cenyang',
    'Hanshan',
    'Xiaomi',
    'Luohu',
    'Hainan',
    'Guangxi',
    'Changle',
    'Linfen',
    'Yuanping',
    'Jiazhuang',
    'Heibei',
    'Liaocheng',
    'Huaihua',
    'Rizhao',
    'Fuzhou',
    'Duyun',
    'Tianshui',
    'Guli',
    'Jinhai',
    'Chaoyang',
    'Taixing',
    'Fengxiang',
    'Jinpan',
    'Yizheng',
    'Yunfu',
    'Xinhui',
    'Wenlan',
    'Tieli',
    'Taihe',
    'Liuzhou',
    'Hechi',
    'Yunyang',
    'Zhuxi Chengguanzhen',
    'Heze',
    'Shiqiaopu',
    'Jiangbei',
    'Shanzhuang',
    'Danyang',
    "Huai'an",
    'Yantan',
    'Guangchang',
    'Xiguan',
    'Xigu',
    'Anying',
    'Xiangtong',
    'Guixi',
    'Xidian',
    'Pingliang',
    'Pinghu',
    'Changhe',
    'Yangshan',
    'Suji',
    'Baizhang',
    'Shuangxi',
    'Yanming',
    'Yiqiao',
    'Xinxing',
    'Dabao',
    'Sanyuanpu',
    'Gaobu',
    'Linghu',
    'Qingtong',
    'Shuanglin',
    'Dampu',
    "Qi'an",
    'Gouzhuang',
    'Changsheng',
    'Qianyu',
    'Datong',
    'Sandu',
    'Anjiang',
    'Huangjue',
    'Name',
    'Linqi',
    'Zhanghe',
    'Zheshang',
    'Huadian',
    'Kangcun',
    'Zhangshan',
    'Shouchang',
    'Qiantan',
    'Hongpan',
    "Lin'an",
    'Dingzhou',
    'Jiuzong',
    'Yuting',
    'Guali',
    'Jianci',
    'Kaiyun',
    'Chunhua',
    'Hongwei',
    'Shuguang',
    'Diankou',
    'Lidong',
    'Fengqing',
    'Fengqiao',
    'Fengqi',
    'Rongle',
    'Huangzhai',
    'Wuning',
    'Yahao',
    'Jifeng',
    'Ancheng',
    'Shangfang',
    'Jiahu',
    'Jinpu',
    'Potang',
    'Shangjiang',
    'Xingqiao',
    'Doujiang',
    'Nyingchi County',
    'Yangxunqiao',
    'Tongmin',
    'Lianghu',
    'Sundun',
    'Santiaolou',
    'Guilin',
    'Guigang',
    'Qinzhou',
    'Laibin',
    'Shenwan',
    'Zhenzhou',
    'Zhongdian',
    'Yidong',
    'Minhang',
    'Longmao',
    'Linjiang',
    'Taiping',
    'Mingde',
    'Xingchen',
    'Jiading',
    'Zhujiajiao',
    'Zhima',
    'Zhongnan',
    'Tongji',
    'Xinzha',
    'Enshi',
    'Taxi',
    'Zhengda',
    'Xuhui',
    'Xiangru',
    'Longjin',
    'Changzhong',
    'Ejia',
    'Huadong',
    'Donghe',
    'Dazhong',
    'Taipingyan',
    'Jintan',
    'Licheng',
    'Miyun',
    'Lingcheng',
    'Jingmen',
    'Kashgar',
    'Tianzhen',
    'Huquan',
    'Wuling',
    'Chengdong',
    'Xinrong',
    'Yungang',
    'Pingwang',
    'Niunaichang',
    'Shouyang',
    'Xiyang',
    'Heshun',
    'Liaoyang',
    'Jicheng',
    'Nandu',
    'Jiexiu',
    'Zhaoyu',
    'Pingyao County',
    'Mingxing',
    'Lingshi',
    'Linjiang',
    'Gaoping',
    'Bagong',
    'Chongwen',
    'Gongyi',
    'Fangshan',
    'Dongcun',
    'Xingxian',
    'Zhongyang',
    'Jiaokou',
    'Liulin',
    'Linzhou',
    'Fenyang',
    'Fengcheng',
    'Xiezhou',
    'Hejin',
    'Pinglu',
    'Ruicheng',
    'Xiedian',
    'Wenxi',
    'Yaofeng',
    'Kouqian',
    'Wutai',
    'Yangquan',
    'Xiaoqu',
    'Yijing',
    'Xinghua',
    'Haozhuang',
    'Loufan',
    'Yangqu',
    'Gujiao',
    'Qingyuan',
    'Luzhou',
    'Laiyi',
    'Jiamusi',
    'Qinghai',
    'Tongliao',
    'Sanhe',
    'Tangdukou',
    "Wu'an",
    'Xianlin',
    'Jingzhong',
    'Baishan',
    'Qingnian',
    'Yintian',
    'Huangshi',
    'Huludao',
    'Fenggang',
    "Sui'an",
    'Putian',
    'Shima',
    'Jiangyou',
    'Yangling',
    'Xinjiang',
    'Zhumadian',
    'Meizhou',
    'Pingxiang',
    'Suzhou',
    'Xucheng',
    'Linbei',
    'Jiedong',
    'Hangong',
    'Jiehe',
    'Ansu',
    'Huge',
    'Gujiang',
    "Lin'e",
    'Mingzhu',
    'Youjiang',
    'Tianli',
    'Baise City',
    'Longlin',
    'Jingxi',
    'Napo',
    'Tianyang',
    'Tiandong',
    'Tieshan',
    'Beihai',
    'Qianling',
    "Guang'an",
    'Yibin',
    'Lincang',
    'Zunyi',
    'Zhongxiang',
    'Yanjiao',
    'Yanji',
    'Qike',
    'Nenjiang',
    'Huanggang',
    'Hupi',
    'Xinyang',
    'Minjiang',
    'Sanmao',
    'Yanchang',
    'Jiahe Chengguanzhen',
    'Baile',
    'Xibu',
    'Doumen',
    'Huangze',
    'Keqiao',
    'Linnan',
    'Xingwang',
    'Liangzhu',
    'Yunting',
    'Meiyuan',
    'Lishang',
    'Shangsi',
    'Lida',
    'Jiangnan',
    'Xingguang',
    'Jingdu',
    'Nanyuan',
    'Shuozhou',
    'Xieqiao',
    "Chang'an",
    'Xucun',
    'Zhouwangmiao',
    'Youchegang',
    'Chengdong',
    'Donglin',
    'Weitang',
    'Fengnan',
    'Lize',
    'Xitang',
    'Xiadianmiao',
    'Dashun',
    'Ganyao',
    'Fanjing',
    'Huimin',
    'Yaozhuang',
    'Taozhuang',
    'Yifu',
    'Yuhui',
    'Tianning',
    'Yangmiao',
    'Hongxi',
    'Dayun',
    'Dingzha',
    'Sizhou',
    'Yucheng',
    'Gaozhao',
    'Xinao',
    'Lanhai',
    'Jinde',
    'Ruiting',
    'Anke',
    'Haizhou',
    'Guangtai',
    'Jielong',
    'Hualong',
    'Huayang',
    'Xiashi',
    'Ziyu',
    'Jinlu',
    'Tianhong',
    'Mingjia',
    'Jingtian',
    'Yunling',
    'Xiangsheng',
    'Cengang',
    'Dongchang',
    'Yungcun',
    'Zhongtian',
    'Longhua',
    'Jiadi',
    'Haicheng',
    'Fengshang',
    'Huangxuan',
    'Hexun',
    'Baziqiang',
    'Baiyi',
    'Yuehua',
    'Fengjiang',
    'Zhiyuan',
    'Jinma',
    'Chengfeng',
    'Anzhou',
    'Shangzhang',
    'Aishang',
    'Huangjia',
    'Oucheng',
    'Wangfu',
    'Pengyu',
    'Zhengjiang',
    'Guangtong',
    'Dengzhou',
    'Dehui',
    'Yinhai',
    'Libang',
    'Anbang',
    'Jinwo',
    'Weidun',
    "Shi'er",
    'Lincheng',
    'Yuanheng',
    'Defeng',
    'Jielong',
    'Tianma',
    'Guangyuan',
    'Caiyuan',
    'Suxi',
    'Yuyuan',
    'Yongchuan',
    'Mingjing',
    'Zhongqiao',
    'Xiangyi',
    'Shuangcheng',
    'Deyuan',
    'Maodian',
    'Bangke',
    'Jinding',
    'Yifeng',
    'Jianyang',
    'Shengdu',
    'Lingdong',
    "Shu'an",
    'Boli',
    'Yutou',
    'Xiakou',
    'Qinghu',
    'Tanshi',
    'Fenglin',
    'Siduxu',
    'Dachen',
    "Bao'an",
    'Xiangjiang',
    'Bole',
    'Qingxin',
    'Longtai',
    'Cuntou',
    'Majin',
    'Xintangbian',
    'Hecun',
    'Kecheng',
    'Jiangwang',
    'Xiaosi',
    'Qingshuiwan',
    'Zhonggongmiao',
    'Jinshadi',
    'Chunjiang',
    'Baidu',
    'Fangqiao',
    'Dongxin',
    'Qijiashan',
    'Yuanyang',
    'Zhouxiang',
    'Shengshan',
    'Xingfeng',
    'Jiuba',
    'Lianshan',
    'Xinzhou',
    'Banxi',
    'Dayan',
    'Daxie',
    'Xizhou',
    'Dancheng',
    'Shiqi',
    'Kandun',
    'Kuangyan',
    'Lingxi',
    'Baishi',
    'Feihong',
    'Yangda',
    'Jiahao',
    'Daxing',
    'Huatan',
    'Fuyang',
    'Xushan',
    'Kang Xian',
    'Qiaoyuan',
    'Zhongyin',
    'Yuetai',
    'Qingtian',
    'Kuishan',
    'Mudanjiang',
    'Xigazê',
    'Shangnan',
    'Chamdo',
    'Nagqu',
    'Dawa',
    'Baoluo',
    'Shanghu',
    'Yanggu',
    'Kumul',
    'Turpan Diqu',
    'Yili',
    'Bole',
    'Aksu',
    'Hetian',
    'Bamao',
    'Jimo',
    'Beijie',
    'Jindi',
    'Zhizhong',
    'Zhongwei',
    'Wuzhong',
    'Guyuan',
    'Chendu',
    'Xiangfeng',
    'Bailicun',
    'Huazhou',
    'Ruihu',
    'Qiaolian',
    'Huiyang',
    'Hechi',
    'Shennan',
    'Shuangqiao',
    'Shangqiu',
    'Sanmenxia',
    'Xiantao',
    'Jingzhou',
    'Ezhou',
    'Jinzhou',
    'Suihua',
    'Danjiang',
    'Santai',
    'Taiping',
    'Yongzhou',
    'Taocheng',
    'Sanpailou',
    'Yangzhong',
    'Yicheng',
    'Fengxian',
    'Yuhuayuan',
    "Jing'an",
    'Zhengdi',
    'Haihong',
    'Luwan',
    'Huashan',
    'Zhongtu',
    'Dongxi',
    'Dongcheng',
    'Lidu',
    'Shanrui',
    'Yuanpu',
    'Huajiashan',
    'Tianyi',
    'Tianchang',
    'Beiying',
    'Nanhan',
    'Jinci',
    'Chaicun',
    'Huagong',
    'Dahuaishu',
    'Huozhou',
    'Xincheng',
    'Yicheng',
    'Changning',
    'Qingyang',
    'Pucheng',
    'Kouquan',
    'Beixiao',
    'Yuanqu',
    'Yuncheng',
    'Siyang',
    'Benxi',
    'Wuzhen',
    'Tieling',
    'Langzhong',
    'Yuzi',
    'Jishu',
    'Nanda',
    'Huamei',
    'Yanta',
    'Tangxi',
    'Huaxing',
    'Tancheng',
    'Xiazhuang',
    "Liu'an",
    'Liucheng',
    'Guigang',
    'Bobai',
    'Yongning',
    'Hezhou',
    'Hecheng',
    'Zhongzhou',
    'Jinyin',
    'Niulanshan',
    'Huairou',
    'Qiandao',
    'Huangyuan Chengguanzhen',
    'Xishan',
    'Beiyuan',
    'Beixi',
    'Xingcheng',
    'Zhongyuan',
    'Pingyi',
    'Shijingxiang',
    'Guili',
    "Huai'an",
    'Shenyan',
    'Dianzi',
    'Lintong',
    'Hebei',
    'Huangdao',
    'Baotou',
    'Hailar',
    'Fendou',
    'Edong',
    'Xingye',
    'Hezuo',
    'Yingchuan',
    'Zhangshu',
    'Nanjin',
    'Qiongshan',
    'Jiaying',
    'Mengzi',
    'Panzhihua',
    'Zigong',
    'Danjiangkou',
    'Anda',
    'Hulan',
    'Hekou',
    'Xuanwu',
    'Shengfang',
    'Huabei',
    'Huangzhou',
    'Zhangjiagang',
    'Wanxi',
    'Cangzhou',
    'Zhengding',
    'Luocheng',
    'Yunnan',
    'Baliqiao',
    'Jiaxin',
    'Shangxi',
    'Xiuyu',
    'Chengxiang',
    'Xianyou',
    'Guyong',
    'Qidu',
    'Shuangcheng',
    'Xiapu',
    'Fuding',
    "Ping'an",
    'Jin Jiang',
    'Luqiao',
    'Anxi',
    'Longmen',
    'Hongshan',
    'Taoxi',
    'Changting',
    'Lianjiang',
    'Yanshi',
    'Dayang',
    'Dongxiao',
    'Xinmin',
    'Zhangjiajie',
    'Side',
    'Liaoyuan',
    'Dongliu',
    'Shifang',
    'Caoxi',
    "Xin'an",
    'Longdong',
    'Shangbao',
    'Gaoqiao',
    'Hongyangshu',
    'Lugouqiao',
    'Tianshan',
    'Jinqiao',
    'Yongjiu',
    'Huaxia',
    'Xingyuan',
    'Kunlun',
    'Shangzhi',
    'Keshan',
    'Tailai',
    'Longjiang',
    'Gannan',
    'Hailin',
    'Dongning',
    'Muling',
    'Linkou',
    "Ning'an",
    'Pengcheng',
    'Tangyuan',
    'Huanan',
    'Daqing',
    'Qinggang',
    'Mishan',
    'Huixin',
    'Shuangyashan',
    'Baoqing',
    'Hegang',
    'Mingshui',
    'Beishan',
    'Liuhe',
    'Jinshi',
    'Xinyi',
    'Fengzhou',
    'Chengnan',
    'Renrong',
    'Heyuan',
    'Longgong',
    'Shancheng',
    'Buxin',
    'Jingbei',
    'Bibo',
    'Jintian',
    'Huali',
    'Luoling',
    'Cuiyuan',
    'Beidou',
    'Cuizhu',
    'Cuibei',
    'Shuitian',
    'Shuiku',
    'Sungang',
    'Liyuan',
    'Caopu',
    'Yuanling',
    'Hongling',
    'Baihua',
    'Huaxin',
    'Guiyuan',
    'Binhe',
    'Nanhu',
    'Xingang',
    'Shangbu',
    'Tongyuan',
    'Fumin',
    'Shixia',
    'Xinsha',
    'Shangsha',
    'Zhongfu',
    'Yitian',
    'Fuqiang',
    'Jinglong',
    'Huaqiang',
    'Meilian',
    'Jinglian',
    'Shiling',
    'Meilin',
    'Yaoluoping',
    'Sanjiu',
    'Zhuxiang',
    'Zhulin',
    'Zhuyuan',
    'Yunfeng',
    'Shizheng',
    'Jinzhong',
    'Shuiwu',
    'Zongxoi',
    'Dianzhong',
    'Shizhong',
    'Dezhou',
    'Baiyashi',
    'Minzhu',
    'Xiping',
    'Danfeng',
    'Ganzhou',
    'Yangcun',
    'Minzu',
    'Junlin',
    'Xinguang',
    'Jinxing',
    'Ganjiang',
    'Liudao',
    'Shamen',
    'Huanzhen',
    'Shuanglong',
    'Wenlin',
    'Biyang',
    'Nantang',
    'Lecheng',
    'Yandang',
    "Xin'anjiang",
    'Huanghu',
    'Jiuguan',
    'Deyue',
    'Yingtai',
    'Chengxi',
    'Luobu',
    'Houzhai',
    'Eshan',
    'Huayue',
    'Xiyue',
    'Nanmen',
    'Fenshang',
    'Jishou',
    'Xiatangyunxiang',
    'Yalongwan',
    'Yangce',
    'Longxing',
    'Liujian',
    'Tangchang',
    'Gaiwei',
    'Xinyou',
    'Bantou',
    'Daji',
    'Duwei',
    'Jiaowei',
    'Chenyang',
    'Bangtou',
    'Fengshan',
    'Baitang',
    'Sanjiangkou',
    'Lihou',
    'Fengting',
    'Fengcheng',
    'Rongjin',
    'Luoyuan',
    'Minjie',
    'Tangcheng',
    'Jinying',
    "Su'ao",
    'Tancheng',
    'Changzheng',
    'Shule',
    'Qianjiang',
    'Xinzheng',
    'Daye',
    'Jiaoyuan',
    'Jiangjin Qu',
    "Du'an",
    'Tengzhou',
    'Karamay',
    'Wuyishan',
    'Shejiazhuang',
    'Shaoguang',
    'Jinbi',
    'Shanwei',
    'Jingshan',
    'Zhancheng',
    'Anbu',
    'Huigang',
    'Tianle',
    'Xibei',
    'Beilin',
    'Lingnga',
    'Jixi',
    'Caijing',
    'Boji',
    'Baicheng',
    'Wuhai',
    'Niaojiang',
    'Taishan',
    'Yuzhong Chengguanzhen',
    'Dachuan',
    'Zhongfang',
    'Nanning',
    'Haocheng',
    'Goushan',
    'Lujiazhi',
    'Fuchun',
    'Jiangjia',
    'Yangqitan',
    'Mingzhou',
    'Daoxu',
    'Jielong',
    'Qianjin',
    'Changchao',
    'Hengjie',
    'Mayao',
    'Sanzhang',
    'Jinming',
    'Lianshi',
    'Sanlian',
    'Hualin',
    'Zhenxi',
    'Yuxiang',
    'Hechun',
    'Ruiying',
    'Xianchang',
    'Zhongji',
    'Fanzhong',
    'Longxiang',
    'Yanguan',
    'Zhijiang',
    'Yiyang',
    'Huazhong',
    'Yinshan',
    'Cangshang',
    'Banyou',
    'Jindian',
    'Mawei',
    'Anqiu',
    'Shouguang',
    'Linqu',
    'Kuiju',
    'Dingtao',
    'Xiaoxi',
    'Luozhuang',
    'Xuezhuang',
    'Mengyin',
    'Mengshan',
    'Zhangzhuang',
    "Yin'an",
    'Bianzhuang',
    'Zhangzhai',
    'Jiangguantun',
    'Linqing',
    "Dong'e",
    'Chiping',
    'Nanguo',
    'Baying',
    'Linxia Chengguanzhen',
    'Luorong',
    'Liujiang',
    'Daojiang',
    'Liudong',
    'Jinguang',
    'Anhu',
    'Lingang',
    'Jiaoyu',
    'Zuxi',
    'Xiangsi',
    'Jianzhong',
    'Jinzao',
    "Nan'an",
    'Xizhang',
    'Jondag',
    'Xiaojiahe',
    'Yandao',
    'Chadianzi',
    'Zhongyicun',
    'Jialing',
    'Jianxing',
    'Xichong',
    'Sanyi',
    'Hongyan',
    'Base',
    'Yongxing',
    'Nanhe',
    'Naxi Yaozuxiang',
    'Dongxing',
    'Rongxian',
    'Nanying',
    'Huaying',
    'Huagongchang',
    'Gongjiao',
    'Huanghou',
    'Zhongjian',
    'Xingxing',
    'Shanhe',
    'Liwu',
    'Shanshui',
    "Shizhu'an",
    'Anguo',
    'Yanjiao',
    'Linze',
    'Duoba',
    'Yingcai',
    'Hongchang',
    'Jingjiang',
    'Taikang',
    'Zhaozhou',
    'Lindian',
    'Zhonglin',
    'Pingyin',
    'Nanxianglong',
    'Chengxian Chengguanzhen',
    'Yuhong',
    'Yuhan',
    'Xiayuan',
    'Dongjiaying',
    'Songyuan',
    'Dasha',
    'Guohe',
    'Yuanchang',
    'Hangbu',
    'Zhangjiang',
    'Longjian',
    'Zaoyang',
    'Shensha',
    'Xijiao',
    'Xijiao',
    'Chuansha',
    'Gongyuan',
    'Fendou',
    'Dongshi',
    'Nongkeng',
    'Huacheng',
    'Qingzhou',
    'Fangzi',
    'Taishan',
    'Haiyu',
    'Shouchun',
    'Wuhai',
    'Chifeng',
    'Hetao',
    'Shekou',
    'Qianhai',
    "Lu'an",
    'Yancheng',
    'Jinghong',
    "Ning'er",
    'Zhuozhou',
    'Luquan',
    "Li'an",
    'Suqian',
    'Dabu',
    'Luoyang',
    'Feiyun'
  ],
  Japan: [
    'Tokyo',
    'Hiroshima',
    'Saitama',
    "Nihon'odori",
    'Ibaraki',
    'Urayasu',
    'Suita',
    'Funabashi',
    'Nagareyama',
    'Ichikawa',
    'Isesaki',
    'Koga',
    'Ichihara',
    'Koshigaya',
    'Shibukawa',
    'Aoicho',
    'Yamakita',
    'Gotemba',
    'Nisshin',
    'Nishinomiya',
    "Den'en-chofu",
    'Kawasaki',
    'Toyama-shi',
    'Moriguchi',
    'Chita',
    'Sano',
    'Nagoya-shi',
    'Kyoto',
    'Hamamatsu',
    'Shimotoda',
    'Hachiōji',
    'Tsuchiura',
    'Wakamatsucho',
    'Yachiyo',
    'Tsukuba',
    'Chiba',
    'Yokohama',
    'Yamanashi',
    'Ashihara',
    'Kawaguchi',
    'Kasukabe',
    'Shizuoka',
    'Kawanishi',
    'Itami',
    'Kobe',
    'Nara',
    'Yao',
    'Osaka',
    'Handa',
    'Yakuin-Horibata',
    'Toyonaka',
    'Honjocho',
    'Kishiwada',
    'Susono',
    'Nagasaki',
    'Setagaya-ku',
    'Zushi',
    'Sugito',
    'Yabasehoncho',
    'Yamaguchi',
    'Kanazawa',
    'Maruyama',
    'Tahara',
    'Obu',
    'Nishio',
    'Ichinomiya',
    'Kasugai',
    'Inazawa',
    'Kariya',
    'Toyohashi',
    'Okazaki',
    'Seto',
    'Konan',
    'Nasa',
    'Odawara',
    'Minamiashigara',
    'Hiratsuka',
    'Kuroiwa',
    'Kagoshima',
    'Yamashitacho',
    'Fukuoka',
    'Kasuga',
    'Kitakyushu',
    'Kurume',
    'Amagasaki',
    'Ochikawa',
    'Kagawa',
    'Fussa',
    'Inzai',
    'Sakai',
    'Iwakura',
    'Kamagaya-shi',
    'Tokorozawa',
    'Machida',
    'Tomakomai',
    'Shiogama',
    'Shimizukoji',
    'Hanawadamachi',
    'Utsunomiya',
    'Sendai',
    'Ōtsu',
    'Matsudo',
    'Hino',
    'Sagamihara-shi',
    'Tokushima',
    'Morowa',
    'Sapporo',
    'Numazu',
    'Takajomachi',
    'Mishima',
    'Fujieda',
    'Ashikaga',
    'Matsuyama',
    'Saijo',
    'Okayama',
    'Akita',
    'Minami',
    'Komatsubara',
    'Kameyama',
    'Taiji',
    'Machi',
    'Soka',
    'Niiza',
    'Kawagoe',
    'Shichibancho',
    'Ōita',
    'Kumagaya',
    'Ageo',
    'Yorii',
    'Kukichuo',
    'Fujimino',
    'Yashio-shi',
    'Wakayama',
    'Hita',
    'Kashiwa',
    'Fuchu-machi',
    'Yasu-shi',
    'Shiraoka',
    'Hamura',
    'Hitachi',
    'Shinshuku',
    'Shinsuka',
    'Ogaki',
    'Anjo',
    'Bandaicho',
    'Hanabatacho',
    'Sakurakabu',
    'Obihiro',
    'Bakurocho',
    'Machi',
    'Shirayamamachi',
    'Hakui',
    'Shiojiri',
    'Ueda, Nagano',
    'Yokotemachi',
    'Toyohira-ku',
    'Kakegawa',
    'Noda',
    'Yotsukaido',
    'Abiko',
    'Tsurugashima',
    'Gyoda',
    'Yokosuka',
    'Yamatomachi-mitsuai',
    'Niigata',
    'Machi',
    'Chino',
    'Yamagata-shi',
    'Hanamaki',
    'Hashimoto',
    'Uchimaru',
    'Gamagori',
    'Mihama',
    'Higashiosaka',
    'Fujisawa',
    'Koto',
    'Kamirenjaku',
    'Ishioka',
    'Ryugasaki',
    'Toride',
    'Zama',
    'Chigasaki',
    'Kamakura',
    'Kimitsu',
    'Nerima-ku',
    'Nobitome',
    'Sumidacho',
    'Otemachi',
    'Isehara',
    'Hadano',
    'Komae-shi',
    'Asaka',
    'Ushiku',
    'Kodaira',
    'None-mura',
    'Kunitachi',
    'Kokubunji',
    'Miyazaki-shi',
    'Atsugi',
    'Yugawara',
    'Higashimurayama-shi',
    'Kasama',
    'Takahama',
    'Kiyosu',
    'Kanagawa',
    'Sayama',
    'Okegawa',
    'Ebina',
    'Tsubame',
    'Higashiboridori',
    'Ota',
    'Takasaki',
    'Naganohara',
    'Shiki',
    'Mizunami',
    'Nago',
    'Sakuracho',
    'Iida',
    'Moriya',
    'Kami-nagarekawacho',
    'Fukuyama',
    'Aioi',
    'Ashiya',
    'Shibata',
    'Fukayacho',
    'Kanie',
    'Yoshikawa',
    'Tatebayashi',
    'Nakagawa',
    'Edogawa-ku',
    'Toyokawa',
    'Mizuko',
    'Tajimi',
    'Nabari',
    'Miyakonojo',
    'Shirokane',
    'Shibadaimon',
    'Shimizumachi',
    'Fuji',
    'Fujinomiya',
    'Yaizu',
    'Shimoda',
    'Shimada',
    'Fukuroi',
    'Makinohara',
    'Matsuyama',
    'Koganei',
    'Musashino',
    'Fujimi',
    'Wako',
    'Miura',
    'Ayase',
    'Ishinomaki',
    'Otawara',
    'Takatsuki',
    'Nishikicho',
    'Tochigi',
    'Hirakata',
    'Kiryu',
    'Nakatsugawa',
    'Takayama',
    'Beppu',
    'Tendo',
    'Noshiro',
    'Sakado',
    'Kazo',
    'Kitahiroshima',
    'Choshi',
    'Iizuka',
    'Suzuka',
    'Hekinan',
    'Usa',
    'Okinawa',
    'Urasoe',
    'Naha',
    'Chichibu',
    'Asahi',
    'Kita-sannomaru',
    'Hirokawa',
    'Ishigaki',
    'Higashine',
    'Tsuruoka',
    'Asahikawa',
    'Minatomachi',
    'Sannohe',
    'Tottori-shi',
    'Higashiasahimachi',
    'Iwata',
    'Koriyama',
    'Hanno',
    'Takarazuka',
    'Kuwana-shi',
    'Kakogawa',
    'Komaki',
    'Mitake',
    'Tondabayashi',
    'Matsumoto',
    'Kakamigahara',
    'Onomichi',
    'Kure',
    'Maebaru',
    'Tokai',
    'Tosu',
    'Kumamoto',
    'Tobata',
    'Saga-shi',
    'Tamana',
    'Iwamizawa',
    'Narashino-shi',
    'Nakanoshima',
    'Himeji',
    'Kashiwara',
    'Sandacho',
    'Uji',
    'Ikeda',
    'Sasayama',
    'Koka',
    'Hino',
    'Izumi',
    'Matsubara',
    'Kashihara',
    'Iwade',
    'Neyagawa',
    'Kadoma',
    'Tsuruga',
    'Kasumigaura',
    'Nagaoka',
    'Toyota',
    'Fukui-shi',
    'Itō',
    'Obanazawa',
    'Masaki',
    'Iwaki',
    'Higashiyama',
    'Waseda',
    'Takaoka',
    'Namerikawa',
    'Narita',
    'Ishikawa',
    'Annaka',
    'Ureshino',
    'Tsushima',
    'Tokoname',
    'Midori',
    'Kitakami',
    'Bibai',
    'Kaigandori',
    'Hitoyoshi',
    'Yamaga',
    'Saku',
    'Minato',
    'Masuda',
    'Honjo',
    'Yokkaichi',
    'Ebisu',
    'Tainoura',
    'Mine',
    'Hatsukaichi',
    'Mino',
    'Akashi',
    'Kasai',
    'Miyazu',
    'Maizuru',
    'Nishiwaki',
    'Sumoto',
    'Yawata',
    'Miki',
    'Ono',
    'Toyooka',
    'Maibara',
    'Ikoma',
    'Hyogo',
    'Taga',
    'Kainan',
    'Kaizuka',
    'Hannan',
    'Sennan',
    'Fukuchiyama',
    'Tatsunocho-tominaga',
    'Shuchi',
    'Tama',
    'Oyama',
    'Nikkō',
    'Sakura',
    'Hakodate',
    'Kushiro',
    'Katori-shi',
    'Kasumicho',
    'Aomori',
    'Kurihashi',
    'Mito-shi',
    'Suginomecho',
    'Naka',
    'Niihama',
    'Ube',
    'Tagajo-shi',
    'Kameoka',
    'Morioka',
    'Kuroishi',
    'Kofu',
    'Nagano-shi',
    'Warabi-shi',
    'Fukushima-shi',
    'Hinode',
    'Omihachiman',
    'Hikone',
    'Muko',
    'Hachiman-cho',
    'Koyama',
    'Musa',
    'Takanawa',
    'Hasuda',
    'Honden',
    'Nomura',
    'Hashimoto',
    'Ohara',
    'Date',
    'Horyuji',
    'Takamatsu',
    'Anabuki',
    'Hachinohe',
    'Misawa',
    'Suwa',
    'Kashiwazaki',
    'Daido',
    'Koyasan',
    'Iwakuni',
    'Kanaya',
    'Tsu',
    'Matsusaka',
    'Misato',
    'Ginowan',
    'Iwanuma',
    'Izu',
    'Kamo',
    'Hirosaki',
    'Nomimachi',
    'Nita',
    'Miyagi',
    'Maebashi',
    'Toyo',
    'Nishi-Tokyo-shi',
    'Yasugicho',
    'Mobara',
    'Tonami',
    'Uozu',
    'Kamigori',
    'Nanao',
    'Yamato-Takada',
    'Osaka',
    "Kan'onjicho",
    'Kobe',
    'Moriyama',
    'Mino',
    'Shiroi',
    'Komoro',
    'Aizu-Wakamatsu',
    'Mibu',
    'Chuo',
    'Kanuma',
    'Futtsu',
    'Jogo',
    'Natori-shi',
    'Gifu',
    'Sasaguri',
    'Imari',
    'Asahimachi',
    'Karatsu',
    'Takeo',
    'Kashima',
    'Shiroishi',
    'Kitsuki',
    'Ichinomotocho',
    'Sawara',
    'Isoya',
    'Tsukubamirai',
    'Ami',
    'Shimonoseki',
    'Kisarazu',
    'Omiya-ku',
    'Tone',
    'Mitsucho-iwami',
    'Hagi',
    'Yonezawa',
    'Nanae',
    'Isahaya',
    'Oizu',
    'Tenri',
    'Gojo',
    'Gujo',
    'Oyamato',
    'Kashiba',
    'Akasaka',
    'Yoshino',
    'Tono',
    'Hatogaya-honcho',
    'Daitocho',
    'Ueno-ebisumachi',
    'Kaizu',
    'Yabu',
    'Yatsushiro',
    'Kokura',
    'Hakata',
    'Kyoritsu',
    'Konosu',
    'Okuura',
    'Sanno',
    'Suigetsu',
    'Makuhari',
    'Noguchi',
    'Hanyu',
    'Omagari',
    'Kurogi',
    'Sasebo',
    'Uenohara',
    'Yashima',
    'Kyowa',
    'Showa',
    'Takedamachi',
    'Sendai',
    'Innoshima Hara-cho',
    'Nakatsu',
    'Nakada',
    'Ichinomiya',
    'Inagi-shi',
    'Aikawa',
    'Koga',
    'Nose',
    'Izumo',
    'Gundo',
    'Hidaka',
    'Shoinmachi-koji',
    'Nada',
    'Nakamura',
    'Akao',
    'Hokuto-shi',
    'Ueno',
    'Ida',
    'Nakano',
    'Takashima',
    'Tanushimarumachi-sugawara',
    'Azumino',
    'Kurashiki',
    'Chiryu',
    'Komatsu',
    'Yamawaki',
    'Otake',
    'Kumano',
    'Wajima',
    'Tsubata',
    'Hachimori',
    'Honcho',
    'Katta',
    'Akeno',
    'Chiran',
    'Matsue-shi',
    'Nagahama',
    'Kosai',
    'Yoshida-kasugacho',
    'Shisui',
    'Agano',
    'Tomo',
    'Tsudanuma',
    'Kaita',
    'Minamata',
    'Hojo',
    'Nakano',
    'Kameoka',
    'Miemachi',
    'Shinmachi',
    'Tobo',
    'Ritto',
    'Ikaruga',
    'Omuta',
    'Yachimata',
    'Ebetsu',
    'Kiyose',
    'Imagami',
    'Komatsu',
    'Kinokawa',
    'Tanabe',
    'Susami',
    'Kushimoto',
    'Koza',
    'Shingu',
    'Sonobe',
    'Kaseda-shirakame',
    'Kokawa',
    'Koyo',
    'Raiba',
    'Tachibana',
    'Minoshima',
    'Gobo',
    'Arita',
    'Minabe',
    'Ise',
    'Anan',
    'Hommachi',
    'Shimotsucho-kominami',
    'Muroto-misakicho',
    'Kochi-shi',
    'Motomiya',
    'Okaya',
    'Inuyama',
    'Inami',
    'Takasagocho-takasemachi',
    'Takaishi',
    'Takashima',
    'Kusatsu',
    'Higashiomi-shi',
    'Sakurai',
    'Nishihara',
    'Ginza',
    'Kojima',
    'Kocho',
    'Roppongi',
    'Kashiwagimachi',
    'Hiranuma',
    'Tajiri',
    'Sarugakucho',
    'Tamatsukuri',
    'Yoshii',
    'Komagome',
    'Sakaidecho',
    'Kokubu-matsuki',
    'Yodogawa',
    'Shimomeguro',
    'Uwayama',
    'Ichinogo',
    'Kinoshita',
    'Kita',
    'Higashiiya-takano',
    'Obayashi',
    'Natsumi',
    'Senju',
    'Takata',
    'Chiji',
    'Daimon',
    'Sumiyoshi',
    'Edagawa',
    'Toranomon',
    'Gotanda',
    'Higashiiya-wakabayashi',
    'Nishi',
    'Meguro',
    'Honcho',
    'Misakicho',
    'Nakahata',
    'Yamatomachi',
    'Shobusawa',
    'Imabari-shi',
    'Ikejiri',
    'Kiyokawa',
    'Komagawa',
    'Okubo',
    'Suda',
    'Omiya',
    'Minato',
    'Nakazawa',
    'Tamagawa',
    'Ichibanhoridoricho',
    'Shibaura',
    'Taniyama-chuo',
    'Fukuda',
    'Minamiaoyama',
    'Yamada',
    'Onna',
    'Hiki',
    'Hodogayacho',
    'Kashima-shi',
    'Minami',
    'Echizen',
    'Kaminoyama',
    'Suzaka',
    'Kodamacho-kodamaminami',
    'Nakaichi',
    'Omachi',
    'Itoi',
    'Ninomiya',
    'Chikusei',
    'Hinata',
    'Gosen',
    'Mooka',
    'Akishima',
    'Arida',
    'Marugame',
    'Nobeoka',
    'Chitose',
    'Ome',
    'Higashiyamato',
    'Akiruno-shi',
    'Toyoake',
    'Sanmu',
    'Heiwajima',
    'Mutsu',
    'Iruma',
    'Takasu',
    'Kamitosa',
    'Kii-nagashimaku-nagashima',
    'Nakama',
    'Kesennuma',
    'Sumiyoshi',
    'Bando',
    'Imizucho',
    'Usuki',
    'Tagawa',
    'Shobara',
    'Yunoshima',
    'Ishikari',
    'Tomiya',
    'Mizugama',
    'Uruma',
    'Kano',
    'Tomioka',
    'Inashiki',
    'Yubari',
    'Yomitan Son',
    'Tobe',
    'Taga',
    'Kanzaki',
    'Mihara',
    'Yahagi',
    'Satte',
    'Hokota',
    'Matsukawamachi',
    'Nonoichi',
    'Kaga',
    'Goshogawara',
    'Obama',
    'Izumiotsu',
    'Otaru',
    'Keta',
    'Tsurumi',
    'Yokoo',
    'Ina',
    'Mitsuke',
    'Nagawado',
    'Akasaka',
    'Miyama',
    'Kurobeshin',
    'Wakasa',
    'Abira',
    'Togane',
    'Mizusawa',
    'Yufutsu',
    'Ichinoseki',
    'Sanjo',
    'Takikawa',
    'Otsuki',
    'Murakami',
    'Sakata',
    'Iwatsuki',
    'Tomisato',
    'Hiyoshi',
    'Shizukuishi',
    'Kudamatsu',
    'Shitama',
    'Uwajima',
    'Kitanagoya',
    'Kani',
    'Haibara-akanedai',
    'Kanzaki',
    'Kikuchi',
    'Nogata',
    'Yukuhashi',
    'Yahata',
    'Sayo',
    'Hayama',
    'Rankoshi-cho',
    'Izunokuni',
    'Harigaya',
    'Kitakata',
    'Gannosu',
    'Wadayama',
    'Hofu',
    'Ibara',
    'Fukai',
    'Narutocho-mitsuishi',
    'Mihara',
    'Yaita',
    'Hidakacho-ebara',
    'Takahashi',
    'Otsuku-kibi',
    'Oiwake',
    'Ama-higashimachi',
    'owariasahi',
    'Inabe',
    'Okawa',
    'Ogori-shimogo',
    'Itabashi',
    'Goya',
    'Kaminokawa',
    'Miyoshi',
    'Marunouchi',
    'Kitamoto',
    'Kahoku',
    'Miyako',
    'Settsu',
    'Shingu',
    'Ikuno',
    'Ryuo',
    'Sayama',
    'Habikino',
    'Hashima',
    'Yonago',
    'Hachimantai',
    'Yuzawa',
    'Yamanashi',
    'Sado',
    'Koriyama',
    'Yamazakicho-nakabirose',
    'Togitsu',
    'Ojiya',
    'Joetsu',
    'Miyoshi',
    'Fujiidera',
    'Toki',
    'Takemiya',
    'Kitami',
    'Kasaoka',
    'Kazuno',
    'Itako',
    'Nasukarasuyama',
    'Ninohe',
    'Tsuyama',
    'Sukagawa',
    'Yurihonjo',
    'Eniwa-shi',
    'Nihommatsu',
    'Meiji',
    'Abashiri',
    'Godo',
    'Gushikawa',
    'Shinjuku',
    'Obari',
    'Gotanda',
    'Higashikawa',
    'Isomura',
    'Tamashima',
    'Tamano',
    'Soja',
    'Wakicho',
    'Niimi',
    'Yakage',
    'Ochiai',
    'Kuse',
    'Katsumada',
    'Hayashino',
    'Yuge',
    'Hayashima',
    'Mori',
    'Mizushima-nishichidoricho',
    'Katsuyama',
    'Saidaiji-naka',
    'Kamogatacho-kamogata',
    'Toba',
    'Minobu',
    'Kitaozuma',
    'Nagase',
    'Murata',
    'Tennojicho-kita',
    'Omori',
    'Yanocho-futatsugi',
    'Yasuda',
    'Sanuki',
    'Ena',
    'Minokamo',
    'Kasamatsucho',
    'Mihama',
    'Taketoyo',
    'Saikai',
    'Umeda',
    'Kamisu',
    'Ogawa',
    'Tateyama',
    'Kyodo',
    'Shiroishi',
    'Mashiko',
    'Matsuyama',
    'Tsuchiara',
    'Towada',
    'Yakumo',
    'Awara',
    'Kikugawa',
    'Atami',
    'Katsuura',
    'Toshima Mura',
    'Yaese',
    'Sagae',
    'Fujioka',
    'Ueki',
    'Haijima',
    'Budo',
    'Mitsui',
    'Ishikawa',
    'Utazu',
    'Rifu',
    'Ichinomiyacho',
    'Uki',
    'Monzenmachi-igisu',
    'Tadotsu',
    'Tanaka',
    'Muroran',
    'Ishida',
    'Otsukacho',
    'Okuma',
    'Yokogawa',
    'Hayashi',
    'Taiki',
    'Omura',
    'Mitsukaido',
    'Minowa',
    'Komagane',
    'Nichinan',
    'Nikaho',
    'Senboku',
    'Totsukacho',
    'Nanto',
    'Fukui',
    'Kayano',
    'Aisai',
    'Miwa',
    'Higashicho',
    'Shioya',
    'Echizen',
    'Shijonawate',
    'Higashi-takadama',
    'Azuchi',
    'Higashiura',
    'Nakanoshima',
    'Nakahai',
    'Tamachi',
    'Nara',
    'Oshu',
    'Naitobokujo',
    'Sodegaura',
    'Kitagata',
    'Shimabara',
    'Shinshiro',
    'Haebaru',
    'Kurayoshi',
    'Nirasaki',
    'Ikedacho-itano',
    'Sukumo',
    'Nodani',
    'Wakkanai',
    'Hakozaki',
    'Hiji',
    'Mochida',
    'Nakanishi',
    'Otani',
    'Kose',
    'Sugiyama',
    'Tashiro',
    'Fumoto',
    'Yanagawa',
    'Kurihara',
    'Kokufu',
    'Nishiko',
    'Nakano-kami',
    'Nagato',
    'Tatsuno',
    'Takamori',
    'Hikari',
    'Sabae',
    'Omaezaki',
    'Osaki',
    'Himi',
    'Kamikawa',
    'Tokuyama',
    'Kotoku',
    'Aso',
    'Senzoku',
    'Higashi-iwashiro',
    'Fushiki-minatomachi',
    'Tsukayama',
    'Kahoku',
    'Inami',
    'Temma',
    'Tokiwamachi-tokiwa',
    'Ariake',
    'Yamano',
    'Yamauchi',
    'Matsuto',
    'Shiretoko',
    'Agena',
    'Shimo-iino',
    'Machi',
    'Nakagomi',
    'Murayama',
    'Odaira',
    'Komono',
    'Fujita',
    'Arai',
    'Chatan',
    'Shin-shigai',
    'Oarai',
    'Shin',
    'Tsuru',
    'Oshima',
    'Aitsu',
    'Jissen',
    'Niitsu',
    'Ogura',
    'Shikamaku-miya',
    'Hakone',
    'Mima',
    'Koori',
    'Mineyama',
    'Nishimachi',
    'Kataoka',
    'Kikyo',
    'Koryocho-itazu',
    'Seta',
    'Kagoya',
    'Sakurajima-koikecho',
    'Murai',
    'Iju',
    'Nishime',
    'Daisen',
    'Sakaiminato',
    'Shinagawa',
    'Tashima',
    'Yomogita',
    'Ichimura',
    'Fuchucho',
    'Tamura',
    'Takaiwa',
    'Kure',
    'Imabuku',
    'Nyuzen',
    'Kariya',
    'Jumonji',
    'Mitai',
    'Ichi',
    'Kasumigaseki',
    'Shonaicho',
    'Komatsushimacho',
    'Susaki',
    'Kawahara',
    'Kurami',
    'Tatsumi',
    'Musashimurayama',
    'Yoneyamacho-nishino',
    'Anesaki',
    'Nemuro',
    'Kiyama',
    'Yamazaki',
    'Kamo',
    'Kanda',
    'Urayama',
    'Hamada',
    'Kakuda',
    'Oiso',
    'Akashi',
    'Tokiwa',
    'Tsukumiura',
    'Kanayama',
    'Ujiie',
    'Hotei',
    'Kochino',
    'Nayoro',
    'Suijo',
    'Sone',
    'Kamimura',
    'Saiki',
    'Yabuki',
    'Machiya',
    'Oguni',
    'Sekicho-furumaya',
    'Muikamachi',
    'Hikawa',
    'Ishii',
    'Onojo',
    'Tsuhako',
    'Takase',
    'Fukiage-fujimi',
    'Oyabe',
    'Shinjo',
    'Toyoda',
    'Ishiki',
    'Yuki',
    'Kugayama',
    'Arakawa',
    'Akasaki',
    'Momoishi',
    'Tomobe',
    'Joshi',
    'Kamaishi',
    'Onuma',
    'Yamadayu',
    'Mimasaka',
    'Shimotsuke',
    'Konancho-fukawa',
    'Kabe',
    'Shirakawa-machi',
    'Komaba',
    'Hongo',
    'Shimogo',
    'Isawa',
    'Seiro',
    'Minamiuonuma',
    'Kanada',
    'Sumiyocho-kawauchi',
    'Sogabe-minamijo',
    'Fukuchi',
    'Mutsumi',
    'Fujisato',
    'Miyata',
    'Kusai',
    'Senriyama',
    'Kusunoki',
    'Hama',
    'Shima',
    'Kokuryo',
    'Naebocho',
    'Kubo',
    'Misakimachi-konami',
    'Morodomitsu',
    'Joso',
    'Hosobara',
    'Shizunai-misono',
    'Geinocho-mukumoto',
    'Yoshida',
    'Isumi',
    'Takinogawa',
    'Oyama',
    'Tsukawaki',
    'Okawachi',
    'Mombetsu',
    'Yorishimacho',
    'Minatomachi',
    'Esashi',
    'Honjo',
    'Okuchi',
    'Imaicho',
    'Yano',
    'Minatogawa',
    'Subashiri',
    'Kichijoji-honcho',
    'Takicho-oda',
    'Miyakojima',
    'Odori',
    'Nagashima',
    'Kotohira',
    'Daigo',
    'Kanomata',
    'Inage',
    'Morohongo',
    'Toge',
    'Itoigawa',
    'Maki',
    'Yanagi',
    'Kasai',
    'Nishiyama',
    'Youkaichi',
    'Hatoyama',
    'Nagao',
    'Osawa',
    'Kanegasaki',
    'Yutaka',
    'Mitsushimacho-osaki',
    'Toyonomachi-toyono',
    'Nakayama',
    'Wakimachi',
    'Oyama',
    'Kitsuregawa',
    'Ishige',
    'Kaisei',
    'Sakae',
    'Satocho-sato',
    'Kami-kawakami',
    'Teine-ku',
    'Onogawa',
    'Futoo',
    'Katsura',
    'Shinkawa',
    'Yokaichiba',
    'Moji',
    'Kamoi',
    'Aboshiku-okinohama',
    'Sango',
    'Tawaramoto',
    'Yoichi',
    'Hamana',
    'Fuefuki-shi',
    'Kameda-honcho',
    'Gotsucho',
    'Ogi',
    'Iiyama',
    'Obuse',
    'Ochiai',
    'Yoshimi',
    'Uonuma',
    'Fushimi',
    'Kitaibaraki',
    'Onoda',
    'Iwanai',
    'Kutchan',
    'Noboribetsu',
    'Yanai',
    'Iwakiri',
    'Motegi',
    'Nakijin',
    'Ino',
    'Itoman',
    'Nakagusuku',
    'Nagaoka',
    'Takara',
    'Hagi',
    'Kamikita-kita',
    'Aki',
    'Nishikawa',
    'Shiobaru',
    'Kazuka',
    'Toyota',
    'Takajo',
    'Ozu',
    'Horinouchi',
    'Yabase',
    'Hirakawa',
    'Kasumicho',
    'Watari',
    'Sakura',
    'Asahi',
    'Bakurocho',
    'Kamibun',
    'Shiba-Kaigandori',
    'Tokamachi'
  ],
  Thailand: [
    'Bangkok',
    'Chumphon',
    'Kathu',
    'Phang Khon',
    'Sakon Nakhon',
    'Mueang Nonthaburi',
    'Kalasin',
    'Chon Buri',
    'Loei',
    'Khon Kaen',
    'Nong Bua Lamphu',
    'Roi Et',
    'Udon Thani',
    'Kumphawapi',
    'Kanchanaburi',
    'Nong Khai',
    'Ayutthaya',
    'Chiang Mai',
    'Songkhla',
    'Chiang Rai',
    'Surin',
    'Thanyaburi',
    'Wiphawadi',
    'Phuket',
    'Sing Buri',
    'Satun',
    'Prachin Buri',
    'Ubon Ratchathani',
    'Pattaya',
    'Yala',
    'Bang Na',
    'Samut Songkhram',
    'Phetchabun',
    'Min Buri',
    'Ratchaburi',
    'Lampang',
    'Narathiwat',
    'New Sukhothai',
    'Lopburi',
    'Uttaradit',
    'Maha Sarakham',
    'Mae Hong Son',
    'Suphan Buri',
    'Chachoengsao',
    'Samut Sakhon',
    'Phrae',
    'Din Daeng',
    'Pathum Wan',
    'Phayao',
    'Trang',
    'Mukdahan',
    'Phetchaburi',
    'Uthai Thani',
    'Krabi',
    'Phichit',
    'Phitsanulok',
    'Ban Pat Mon',
    'Prachuap Khiri Khan',
    'Ban Khlong Prasong',
    'Yasothon',
    'Ranong',
    'Lamphun',
    'Nong Bua',
    'Amnat Charoen',
    'Ban Phichit',
    'Bang Khae',
    'Thon Buri',
    'Ban Tham',
    'Sam Sen',
    'Ang Thong',
    'Mueang Samut Prakan',
    'Sa Kaeo',
    'Pathum Thani',
    'Chanthaburi',
    'Huai Khwang',
    'Rayong',
    'Sattahip',
    'Phan',
    'Si Racha',
    'Phatthalung',
    'Rawai',
    'Buriram',
    'Dusit',
    'Khlong Luang',
    'Trat',
    'Ban Bueng',
    'Sung Noen',
    'Manorom',
    'Ban Bang Plong',
    'Tak',
    'Ban Tha Duea',
    'Amphawa',
    'Ban Pong Lang',
    'Phaya Thai',
    'Si Sa Ket',
    'Nakhon Ratchasima',
    'Bangkok',
    'Bang Phlat',
    'Ban Bang Phli Nakhon',
    'Salaya',
    'Krathum Baen',
    'Hua Hin',
    'Ban Talat Rangsit',
    'Ban Khlong Ngae',
    'Nong Prue',
    'Wang Thonglang',
    'Samphanthawong',
    'Bang Khun Thian',
    'Chatuchak',
    'Chaiyaphum',
    'Nakhon Pathom',
    'Nan',
    'Bang Kruai',
    'Sathon',
    'Suan Luang',
    'Ban Wang Yai',
    'Ban Khlong Prawet',
    'Khlong San',
    'Watthana',
    'Lat Krabang',
    'Muak Lek',
    'Kosum Phisai',
    'Ban Phlam',
    'Non Thai',
    'Photharam',
    'Thalang',
    'Bang Kapi',
    'Long',
    'Ka Bang',
    'Pattani',
    'Nakhon Si Thammarat',
    'Khlong Toei',
    'Cha-am',
    'Amphoe Aranyaprathet',
    'Phang Nga',
    'Ban Tha Ruea',
    'Chiang Muan',
    'Ban Ang Thong',
    'Ban Khlong Takhian',
    'Khan Na Yao',
    'Bang Sue',
    'Sam Khok',
    'Don Mueang',
    'Phachi',
    'Ban Pratunam Tha Khai',
    'Sena',
    'Prakanong',
    'Ban Tha Pai',
    'Bang Lamung',
    'Nakhon Sawan',
    'San Sai',
    'Kamphaeng Phet',
    'Pak Kret',
    'Hat Yai',
    'Ban Nam Hak',
    'Khlung',
    'Makkasan',
    'Bang Sao Thong',
    'Ban Hua Thale',
    'Klaeng',
    'Chulabhorn',
    'Ban Don Sak',
    'Phanna Nikhom',
    'Ban Na',
    'Ban Ko Pao',
    'Mae Sot'
  ],
  'United States': [
    "'Ele'ele",
    "'Ewa Beach",
    'Abbeville',
    'Abbotsford',
    'Abbott',
    'Abbottstown',
    'Abercrombie',
    'Aberdeen',
    'Aberdeen Proving Ground',
    'Abernathy',
    'Abilene',
    'Abingdon',
    'Abington',
    'Abington Township',
    'Abiquiu',
    'Abita Springs',
    'Abrams',
    'Absarokee',
    'Absecon',
    'Acampo',
    'Access',
    'Accident',
    'Accokeek',
    'Accomac',
    'Accord',
    'Accoville',
    'Achille',
    'Achilles',
    'Ackerly',
    'Ackerman',
    'Ackley',
    'Acme',
    'Acton',
    'Acushnet',
    'Acworth',
    'Ada',
    'Adah',
    'Adair',
    'Adairsville',
    'Adairville',
    'Adak',
    'Adams',
    'Adams Center',
    'Adams Run',
    'Adamstown',
    'Adamsville',
    'Addieville',
    'Addis',
    'Addison',
    'Addyston',
    'Adel',
    'Adelanto',
    'Adell',
    'Adena',
    'Adger',
    'Adin',
    'Adkins',
    'Admire',
    'Adolphus',
    'Adona',
    'Adrian',
    'Advance',
    'Afton',
    'Agar',
    'Agate',
    'Agawam',
    'Agency',
    'Agoura Hills',
    'Agra',
    'Aguanga',
    'Aguila',
    'Ahoskie',
    'Ahwahnee',
    'Aiea',
    'Aiken',
    'Ailey',
    'Ainsworth',
    'Air Force Academy',
    'Airville',
    'Airway Heights',
    'Aitkin',
    'Ajo',
    'Akaska',
    'Akeley',
    'Akhiok',
    'Akiachak',
    'Akiak',
    'Akron',
    'Akutan',
    'Alabaster',
    'Alachua',
    'Alakanuk',
    'Alamance',
    'Alameda',
    'Alamo',
    'Alamogordo',
    'Alamosa',
    'Alanson',
    'Alapaha',
    'Alba',
    'Albany',
    'Albemarle',
    'Albers',
    'Albert City',
    'Albert Lea',
    'Alberton',
    'Albertson',
    'Albertville',
    'Albia',
    'Albin',
    'Albion',
    'Alborn',
    'Albright',
    'Albrightsville',
    'Albuquerque',
    'Alburg',
    'Alburnett',
    'Alburtis',
    'Alcalde',
    'Alcester',
    'Alcoa',
    'Alcolu',
    'Alda',
    'Alden',
    'Alderpoint',
    'Alderson',
    'Aldie',
    'Aldrich',
    'Aledo',
    'Alex',
    'Alexander',
    'Alexander City',
    'Alexandria',
    'Alexandria Bay',
    'Alexis',
    'Alford',
    'Alfred',
    'Alfred Station',
    'Alger',
    'Algodones',
    'Algoma',
    'Algona',
    'Algonac',
    'Algonquin',
    'Alhambra',
    'Alice',
    'Aliceville',
    'Aline',
    'Aliquippa',
    'Aliso Viejo',
    'Alkol',
    'Allamuchy',
    'Allardt',
    'Allegan',
    'Allegany',
    'Allen',
    'Allen Park',
    'Allendale',
    'Allenhurst',
    'Allenport',
    'Allenspark',
    'Allenton',
    'Allentown',
    'Allenwood',
    'Allerton',
    'Alliance',
    'Allison',
    'Allison Park',
    'Allons',
    'Alloway',
    'Allston',
    'Allyn',
    'Alma',
    'Alma Center',
    'Almena',
    'Almira',
    'Almo',
    'Almond',
    'Almont',
    'Alna',
    'Alpaugh',
    'Alpena',
    'Alpha',
    'Alpharetta',
    'Alpine',
    'Alplaus',
    'Alsea',
    'Alsey',
    'Alsip',
    'Alstead',
    'Alta',
    'Alta Vista',
    'Altadena',
    'Altamont',
    'Altamonte Springs',
    'Altaville',
    'Altavista',
    'Altenburg',
    'Altha',
    'Altmar',
    'Alto',
    'Alton',
    'Alton Bay',
    'Altona',
    'Altoona',
    'Altura',
    'Alturas',
    'Altus',
    'Alum Bank',
    'Alum Creek',
    'Alva',
    'Alvada',
    'Alvarado',
    'Alvaton',
    'Alverda',
    'Alverton',
    'Alvin',
    'Alviso',
    'Alvo',
    'Alvord',
    'Alvordton',
    'Ama',
    'Amagansett',
    'Amana',
    'Amanda',
    'Amargosa Valley',
    'Amarillo',
    'Amawalk',
    'Amazonia',
    'Amberg',
    'Ambler',
    'Amboy',
    'Ambridge',
    'Ambrose',
    'Amelia',
    'Amelia Court House',
    'Amenia',
    'America',
    'American Canyon',
    'American Falls',
    'American Fork',
    'Americus',
    'Amery',
    'Ames',
    'Amesbury',
    'Amesville',
    'Amherst',
    'Amherst Junction',
    'Amherstdale',
    'Amidon',
    'Amissville',
    'Amite',
    'Amity',
    'Amityville',
    'Ammon',
    'Amo',
    'Amoret',
    'Amory',
    'Amsterdam',
    'Amston',
    'Anacoco',
    'Anaconda',
    'Anacortes',
    'Anadarko',
    'Anaheim',
    'Anahola',
    'Anahuac',
    'Anamosa',
    'Anchor Point',
    'Anchorage',
    'Ancona',
    'Andale',
    'Andalusia',
    'Anderson',
    'Andersonville',
    'Andes',
    'Andover',
    'Andreas',
    'Andrew',
    'Andrews',
    'Andrews Air Force Base',
    'Angel Fire',
    'Angelica',
    'Angie',
    'Angier',
    'Angle Inlet',
    'Angleton',
    'Angola',
    'Angoon',
    'Angora',
    'Anguilla',
    'Angwin',
    'Animas',
    'Anita',
    'Aniwa',
    'Ankeny',
    'Anmoore',
    'Ann Arbor',
    'Anna',
    'Annabella',
    'Annandale',
    'Annandale-on-Hudson',
    'Annapolis',
    'Annawan',
    'Anniston',
    'Annona',
    'Annville',
    'Anoka',
    'Anselmo',
    'Ansley',
    'Anson',
    'Ansonia',
    'Ansted',
    'Antelope',
    'Anthem',
    'Anthon',
    'Anthony',
    'Antigo',
    'Antioch',
    'Antlers',
    'Antrim',
    'Antwerp',
    'Anza',
    'Apache',
    'Apache Junction',
    'Apalachicola',
    'Apalachin',
    'Apex',
    'Apison',
    'Aplington',
    'Apollo',
    'Apollo Beach',
    'Apopka',
    'Appalachia',
    'Apple Creek',
    'Apple River',
    'Apple Valley',
    'Applegate',
    'Appleton',
    'Appleton City',
    'Appling',
    'Appomattox',
    'Aptos',
    'Aquasco',
    'Aquashicola',
    'Aquebogue',
    'Aquilla',
    'Arab',
    'Arabi',
    'Aragon',
    'Aransas Pass',
    'Arapaho',
    'Arapahoe',
    'Ararat',
    'Arbela',
    'Arbon',
    'Arbovale',
    'Arbuckle',
    'Arbyrd',
    'Arcade',
    'Arcadia',
    'Arcanum',
    'Arcata',
    'Archbald',
    'Archbold',
    'Archer',
    'Archer City',
    'Archie',
    'Arco',
    'Arcola',
    'Arden',
    'Ardmore',
    'Ardsley',
    'Aredale',
    'Arena',
    'Arenas Valley',
    'Arendtsville',
    'Arenzville',
    'Argenta',
    'Argillite',
    'Argonia',
    'Argonne',
    'Argos',
    'Argusville',
    'Argyle',
    'Ariel',
    'Arimo',
    'Arispe',
    'Ariton',
    'Arivaca',
    'Arizona City',
    'Arkadelphia',
    'Arkansas City',
    'Arkdale',
    'Arkoma',
    'Arkville',
    'Arlee',
    'Arley',
    'Arlington',
    'Arlington Heights',
    'Arma',
    'Armada',
    'Armagh',
    'Armington',
    'Armona',
    'Armonk',
    'Armour',
    'Armstrong',
    'Armuchee',
    'Arnaudville',
    'Arnegard',
    'Arnett',
    'Arnold',
    'Arnoldsburg',
    'Arnoldsville',
    'Aroma Park',
    'Aromas',
    'Aroostook Band of Micmac Trust Land',
    'Arp',
    'Arpin',
    'Arriba',
    'Arrington',
    'Arrowsmith',
    'Arroyo Grande',
    'Arroyo Hondo',
    'Arroyo Seco',
    'Artesia',
    'Artesian',
    'Arthur',
    'Arthur City',
    'Arvada',
    'Arverne',
    'Arvin',
    'Arvonia',
    'Ary',
    'Asbury',
    'Asbury Park',
    'Ascutney',
    'Ash',
    'Ash Flat',
    'Ash Fork',
    'Ash Grove',
    'Ash Hill',
    'Ashaway',
    'Ashburn',
    'Ashburnham',
    'Ashby',
    'Ashcamp',
    'Ashdown',
    'Asheboro',
    'Asherton',
    'Asheville',
    'Ashfield',
    'Ashford',
    'Ashkum',
    'Ashland',
    'Ashland City',
    'Ashley',
    'Ashmore',
    'Ashtabula',
    'Ashton',
    'Ashville',
    'Askov',
    'Asotin',
    'Aspen',
    'Aspermont',
    'Aspers',
    'Assaria',
    'Assonet',
    'Assumption',
    'Astatula',
    'Aston Arms',
    'Astor',
    'Astoria',
    'Atalissa',
    'Atascadero',
    'Atascosa',
    'Atchison',
    'Atco',
    'Atglen',
    'Athelstane',
    'Athena',
    'Athens',
    'Atherton',
    'Athol',
    'Atkins',
    'Atkinson',
    'Atlanta',
    'Atlantic',
    'Atlantic Beach',
    'Atlantic City',
    'Atlantic Highlands',
    'Atlantic Mine',
    'Atlasburg',
    'Atmore',
    'Atoka',
    'Atqasuk',
    'Attalla',
    'Attapulgus',
    'Attica',
    'Attleboro',
    'Attleboro Falls',
    'Atwater',
    'Atwood',
    'Au Gres',
    'Au Sable Forks',
    'Au Train',
    'Auberry',
    'Aubrey',
    'Auburn',
    'Auburn Hills',
    'Auburndale',
    'Audubon',
    'Augusta',
    'Aulander',
    'Ault',
    'Aumsville',
    'Aurelia',
    'Aurora',
    'Austell',
    'Austerlitz',
    'Austin',
    'Austinburg',
    'Austinville',
    'Autaugaville',
    'Autryville',
    'Auxier',
    'Auxvasse',
    'Ava',
    'Avalon',
    'Avella',
    'Avenal',
    'Avenel',
    'Aventura',
    'Averill Park',
    'Avery',
    'Avila Beach',
    'Avilla',
    'Avinger',
    'Aviston',
    'Avoca',
    'Avon',
    'Avon Lake',
    'Avon Park',
    'Avon-by-the-Sea',
    'Avondale',
    'Avondale Estates',
    'Avonmore',
    'Awendaw',
    'Axis',
    'Axtell',
    'Axton',
    'Ayden',
    'Ayer',
    'Aylett',
    'Aynor',
    'Azalea',
    'Azle',
    'Aztec',
    'Azusa',
    'Babb',
    'Babbitt',
    'Babcock',
    'Babson Park',
    'Babylon',
    'Backus',
    'Bacliff',
    'Bad Axe',
    'Baden',
    'Badger',
    'Badin',
    'Bagdad',
    'Baggs',
    'Bagley',
    'Bagwell',
    'Bahama',
    'Bailey',
    'Bailey Island',
    'Baileys Harbor',
    'Baileyton',
    'Baileyville',
    'Bainbridge',
    'Bainbridge Island',
    'Bainville',
    'Baird',
    'Bairdford',
    'Baisden',
    'Baker',
    'Baker City',
    'Bakersfield',
    'Bakersville',
    'Bakewell',
    'Bala-Cynwyd',
    'Balaton',
    'Bald Knob',
    'Baldwin',
    'Baldwin City',
    'Baldwin Park',
    'Baldwin Place',
    'Baldwinsville',
    'Baldwinville',
    'Baldwyn',
    'Ball',
    'Ball Ground',
    'Ballantine',
    'Ballico',
    'Ballinger',
    'Ballston Lake',
    'Ballston Spa',
    'Ballwin',
    'Bally',
    'Balsam Lake',
    'Baltic',
    'Baltimore',
    'Bamberg',
    'Bancroft',
    'Bandera',
    'Bandon',
    'Bangor',
    'Bangs',
    'Banks',
    'Bankston',
    'Banner',
    'Banner Elk',
    'Banning',
    'Bannister',
    'Banquete',
    'Bantam',
    'Bapchule',
    'Baptistown',
    'Bar Harbor',
    'Baraboo',
    'Baraga',
    'Barbeau',
    'Barberton',
    'Barboursville',
    'Barbourville',
    'Barclay',
    'Bardstown',
    'Bardwell',
    'Bargersville',
    'Baring',
    'Bark River',
    'Barkhamsted',
    'Barling',
    'Barlow',
    'Barnard',
    'Barnardsville',
    'Barnegat',
    'Barnes',
    'Barnes City',
    'Barnesville',
    'Barnet',
    'Barneveld',
    'Barney',
    'Barnhart',
    'Barnsdall',
    'Barnstable',
    'Barnstead',
    'Barnum',
    'Barnwell',
    'Baroda',
    'Barre',
    'Barren Springs',
    'Barrett',
    'Barrington',
    'Barron',
    'Barronett',
    'Barrow',
    'Barry',
    'Barryton',
    'Barryville',
    'Barstow',
    'Bart Township',
    'Bartelso',
    'Bartlesville',
    'Bartlett',
    'Bartley',
    'Barto',
    'Barton',
    'Bartonsville',
    'Bartow',
    'Basalt',
    'Bascom',
    'Basehor',
    'Basile',
    'Basin',
    'Basking Ridge',
    'Bassett',
    'Bassfield',
    'Bastrop',
    'Basye',
    'Batavia',
    'Batchelor',
    'Batchtown',
    'Bates City',
    'Batesburg',
    'Batesville',
    'Bath',
    'Bathgate',
    'Baton Rouge',
    'Batson',
    'Battle Creek',
    'Battle Ground',
    'Battle Lake',
    'Battle Mountain',
    'Battleboro',
    'Baudette',
    'Bausman',
    'Bauxite',
    'Baxley',
    'Baxter',
    'Baxter Springs',
    'Bay City',
    'Bay Minette',
    'Bay Port',
    'Bay Saint Louis',
    'Bay Shore',
    'Bay Springs',
    'Bay Village',
    'Bayard',
    'Bayboro',
    'Bayfield',
    'Bayonne',
    'Bayport',
    'Bayside',
    'Baytown',
    'Bayview',
    'Bayville',
    'Bazine',
    'Beach',
    'Beach City',
    'Beach Haven',
    'Beach Lake',
    'Beachwood',
    'Beacon',
    'Beacon Falls',
    'Bealeton',
    'Beaman',
    'Bean Station',
    'Beans Purchase',
    'Bear',
    'Bear Creek',
    'Bear Lake',
    'Bear River City',
    'Bearden',
    'Beardstown',
    'Bearsville',
    'Beasley',
    'Beatrice',
    'Beattie',
    'Beatty',
    'Beattyville',
    'Beaufort',
    'Beaumont',
    'Beauty',
    'Beaver',
    'Beaver Bay',
    'Beaver Creek',
    'Beaver Crossing',
    'Beaver Dam',
    'Beaver Falls',
    'Beaver Meadows',
    'Beaver Springs',
    'Beaverdam',
    'Beaverton',
    'Beavertown',
    'Bechtelsville',
    'Beckemeyer',
    'Becker',
    'Becket',
    'Beckley',
    'Beckville',
    'Bedford',
    'Bedford Hills',
    'Bedford Park',
    'Bedias',
    'Bedminster',
    'Bee Branch',
    'Bee Spring',
    'Beebe',
    'Beech Bluff',
    'Beech Bottom',
    'Beech Creek',
    'Beech Grove',
    'Beech Island',
    'Beecher',
    'Beecher City',
    'Beechgrove',
    'Beechmont',
    'Beeville',
    'Beggs',
    'Bel Air',
    'Bel Alton',
    'Belcamp',
    'Belchertown',
    'Belcourt',
    'Belden',
    'Beldenville',
    'Belding',
    'Belen',
    'Belfair',
    'Belfast',
    'Belfield',
    'Belford',
    'Belfry',
    'Belgium',
    'Belgrade',
    'Belhaven',
    'Belington',
    'Belknap',
    'Bell',
    'Bell Buckle',
    'Bell City',
    'Bell Island Hot Springs',
    'Bella Vista',
    'Bellaire',
    'Bellbrook',
    'Belle',
    'Belle Center',
    'Belle Chasse',
    'Belle Fourche',
    'Belle Glade',
    'Belle Haven',
    'Belle Mead',
    'Belle Plaine',
    'Belle Rose',
    'Belle Valley',
    'Belle Vernon',
    'Belleair Beach',
    'Bellefontaine',
    'Bellefonte',
    'Bellemont',
    'Bellerose',
    'Belleview',
    'Belleville',
    'Bellevue',
    'Bellflower',
    'Bellingham',
    'Bellmawr',
    'Bellmore',
    'Bellows Falls',
    'Bellport',
    'Bells',
    'Bellville',
    'Bellvue',
    'Bellwood',
    'Belmar',
    'Belmond',
    'Belmont',
    'Beloit',
    'Belpre',
    'Belt',
    'Belton',
    'Beltrami',
    'Beltsville',
    'Belvidere',
    'Belvidere Center',
    'Belview',
    'Belvue',
    'Belzoni',
    'Bement',
    'Bemidji',
    'Bemus Point',
    'Ben Lomond',
    'Ben Wheeler',
    'Bena',
    'Benavides',
    'Bend',
    'Bendersville',
    'Benedict',
    'Benham',
    'Benicia',
    'Benkelman',
    'Benld',
    'Bennet',
    'Bennett',
    'Bennettsville',
    'Bennington',
    'Benoit',
    'Bensalem',
    'Bensenville',
    'Benson',
    'Bent',
    'Bent Mountain',
    'Bentleyville',
    'Benton',
    'Benton City',
    'Benton Harbor',
    'Benton Ridge',
    'Bentonia',
    'Bentonville',
    'Benwood',
    'Benzonia',
    'Berea',
    'Beresford',
    'Bergen',
    'Bergenfield',
    'Berger',
    'Bergheim',
    'Berkeley',
    'Berkeley Heights',
    'Berkeley Springs',
    'Berkley',
    'Berkshire',
    'Berlin',
    'Berlin Center',
    'Berlin Heights',
    'Bern',
    'Bernalillo',
    'Bernard',
    'Bernardston',
    'Bernardsville',
    'Berne',
    'Bernice',
    'Bernie',
    'Bernville',
    'Berrien Center',
    'Berrien Springs',
    'Berry',
    'Berry Creek',
    'Berrysburg',
    'Berryton',
    'Berryville',
    'Bertha',
    'Berthoud',
    'Bertram',
    'Bertrand',
    'Berwick',
    'Berwind',
    'Berwyn',
    'Beryl',
    'Bessemer',
    'Bessemer City',
    'Bethalto',
    'Bethania',
    'Bethany',
    'Bethany Beach',
    'Bethel',
    'Bethel Island',
    'Bethel Park',
    'Bethel Springs',
    'Bethesda',
    'Bethlehem',
    'Bethpage',
    'Bethune',
    'Betsy Layne',
    'Bettendorf',
    'Beulah',
    'Beulaville',
    'Beverly',
    'Beverly Hills',
    'Bevington',
    'Bickmore',
    'Bicknell',
    'Biddeford',
    'Bidwell',
    'Bieber',
    'Big Bay',
    'Big Bear',
    'Big Bear Lake',
    'Big Bend',
    'Big Cabin',
    'Big Cove Tannery',
    'Big Creek',
    'Big Falls',
    'Big Flats',
    'Big Horn',
    'Big Lake',
    'Big Pine',
    'Big Pine Key',
    'Big Piney',
    'Big Pool',
    'Big Rapids',
    'Big Rock',
    'Big Sandy',
    'Big Sky',
    'Big Spring',
    'Big Springs',
    'Big Stone City',
    'Big Stone Gap',
    'Big Sur',
    'Big Timber',
    'Big Wells',
    'Bigbend',
    'Bigelow',
    'Bigfoot',
    'Bigfork',
    'Biggs',
    'Biggsville',
    'Biglerville',
    'Bill',
    'Billerica',
    'Billings',
    'Biloxi',
    'Bim',
    'Binford',
    'Bingen',
    'Binger',
    'Bingham Lake',
    'Binghamton',
    'Biola',
    'Bippus',
    'Birch River',
    'Birch Run',
    'Birch Tree',
    'Birchleaf',
    'Birchwood',
    'Bird City',
    'Bird Island',
    'Bird in Hand',
    'Birdsboro',
    'Birdseye',
    'Birdsnest',
    'Birmingham',
    'Birnamwood',
    'Bisbee',
    'Biscoe',
    'Bishop',
    'Bishopville',
    'Bismarck',
    'Bison',
    'Bitely',
    'Bivalve',
    'Bivins',
    'Biwabik',
    'Bixby',
    'Blachly',
    'Black Canyon City',
    'Black Creek',
    'Black Diamond',
    'Black Eagle',
    'Black Earth',
    'Black Hawk',
    'Black Mountain',
    'Black River',
    'Black River Falls',
    'Black Rock',
    'Blackduck',
    'Blackfoot',
    'Blackhawk',
    'Blacklick',
    'Blacksburg',
    'Blackshear',
    'Blackstock',
    'Blackstone',
    'Blacksville',
    'Blackville',
    'Blackwater',
    'Blackwell',
    'Blackwood',
    'Bladenboro',
    'Bladensburg',
    'Blain',
    'Blaine',
    'Blair',
    'Blairs',
    'Blairs Mills',
    'Blairsburg',
    'Blairstown',
    'Blairsville',
    'Blakely',
    'Blakesburg',
    'Blakeslee',
    'Blanch',
    'Blanchard',
    'Blanchardville',
    'Blanchester',
    'Blanco',
    'Bland',
    'Blandburg',
    'Blandford',
    'Blanding',
    'Blandinsville',
    'Blandon',
    'Blauvelt',
    'Bledsoe',
    'Blencoe',
    'Blenheim',
    'Blenker',
    'Blessing',
    'Bliss',
    'Blissfield',
    'Block Island',
    'Blockton',
    'Blodgett',
    'Blodgett Mills',
    'Bloomburg',
    'Bloomdale',
    'Bloomer',
    'Bloomery',
    'Bloomfield',
    'Bloomfield Hills',
    'Blooming Glen',
    'Blooming Grove',
    'Blooming Prairie',
    'Bloomingburg',
    'Bloomingdale',
    'Bloomington',
    'Bloomington Springs',
    'Bloomsburg',
    'Bloomsbury',
    'Bloomsdale',
    'Bloomville',
    'Blossburg',
    'Blossom',
    'Blossvale',
    'Blountstown',
    'Blountsville',
    'Blountville',
    'Blowing Rock',
    'Blue',
    'Blue Ball',
    'Blue Bell',
    'Blue Creek',
    'Blue Earth',
    'Blue Eye',
    'Blue Grass',
    'Blue Hill',
    'Blue Island',
    'Blue Jay',
    'Blue Lake',
    'Blue Mound',
    'Blue Mounds',
    'Blue Mountain',
    'Blue Mountain Lake',
    'Blue Point',
    'Blue Rapids',
    'Blue Ridge',
    'Blue Ridge Summit',
    'Blue River',
    'Blue Springs',
    'Bluebell',
    'Bluefield',
    'Bluejacket',
    'Bluemont',
    'Bluewater',
    'Bluff',
    'Bluff City',
    'Bluff Dale',
    'Bluffs',
    'Bluffton',
    'Bluford',
    'Blum',
    'Bly',
    'Blythe',
    'Blytheville',
    'Blythewood',
    'Boalsburg',
    'Boardman',
    'Boaz',
    'Bob White',
    'Bobtown',
    'Boca Grande',
    'Boca Raton',
    'Bode',
    'Bodega Bay',
    'Bodfish',
    'Boelus',
    'Boerne',
    'Bogalusa',
    'Bogard',
    'Bogart',
    'Bogata',
    'Boggstown',
    'Bogota',
    'Bogue Chitto',
    'Bohannon',
    'Bohemia',
    'Boiceville',
    'Boiling Springs',
    'Boise',
    'Boise City',
    'Boissevain',
    'Bokchito',
    'Bokeelia',
    'Bolckow',
    'Boligee',
    'Bolinas',
    'Boling',
    'Bolingbroke',
    'Bolingbrook',
    'Bolivar',
    'Bolivia',
    'Bolt',
    'Bolton',
    'Bolton Landing',
    'Bomont',
    'Bon Aqua',
    'Bonaire',
    'Bonaparte',
    'Bondsville',
    'Bonduel',
    'Bondurant',
    'Bondville',
    'Bone Gap',
    'Bonfield',
    'Bonham',
    'Bonifay',
    'Bonita',
    'Bonita Springs',
    'Bonlee',
    'Bonne Terre',
    'Bonneau',
    'Bonner Springs',
    'Bonnerdale',
    'Bonners Ferry',
    'Bonney Lake',
    'Bonnie',
    'Bonnieville',
    'Bonnots Mill',
    'Bonnyman',
    'Bono',
    'Bonsall',
    'Boody',
    'Booker',
    'Boomer',
    'Boone',
    'Boones Mill',
    'Booneville',
    'Boonsboro',
    'Boonton',
    'Boonville',
    'Boothbay',
    'Boothbay Harbor',
    'Borden',
    'Bordentown',
    'Borger',
    'Boring',
    'Boron',
    'Borrego Springs',
    'Boscobel',
    'Bosque Farms',
    'Bossier City',
    'Bostic',
    'Boston',
    'Bostwick',
    'Boswell',
    'Bosworth',
    'Bothell',
    'Botkins',
    'Bottineau',
    'Boulder',
    'Boulder City',
    'Boulder Creek',
    'Boulder Town',
    'Boulevard',
    'Bound Brook',
    'Bountiful',
    'Bourbon',
    'Bourbonnais',
    'Bourg',
    'Boutte',
    'Bovard',
    'Bovey',
    'Bovina',
    'Bovina Center',
    'Bow',
    'Bow Center',
    'Bowdoin Center',
    'Bowdoinham',
    'Bowdon',
    'Bowerston',
    'Bowersville',
    'Bowie',
    'Bowler',
    'Bowling Green',
    'Bowlus',
    'Bowman',
    'Bowmanstown',
    'Bowmansville',
    'Box Elder',
    'Box Springs',
    'Boxford',
    'Boxholm',
    'Boyce',
    'Boyceville',
    'Boyd',
    'Boyden',
    'Boyds',
    'Boydton',
    'Boyers',
    'Boyertown',
    'Boyle',
    'Boylston',
    'Boyne City',
    'Boyne Falls',
    'Boynton',
    'Boynton Beach',
    'Bozeman',
    'Braceville',
    'Bracey',
    'Brackenridge',
    'Brackettville',
    'Brackney',
    'Braddock',
    'Bradenton',
    'Bradenton Beach',
    'Bradenville',
    'Bradford',
    'Bradley',
    'Bradley Beach',
    'Bradley Junction',
    'Bradner',
    'Bradshaw',
    'Brady',
    'Bradyville',
    'Bragg City',
    'Braham',
    'Braidwood',
    'Brainerd',
    'Braintree',
    'Braithwaite',
    'Braman',
    'Bramwell',
    'Branch',
    'Branch Township',
    'Branchland',
    'Branchville',
    'Brandamore',
    'Brandenburg',
    'Brandon',
    'Brandt',
    'Brandy Station',
    'Brandywine',
    'Branford',
    'Branson',
    'Brant',
    'Brant Lake',
    'Brantingham',
    'Braselton',
    'Brashear',
    'Brasher Falls',
    'Brasstown',
    'Brattleboro',
    'Brave',
    'Brawley',
    'Braxton',
    'Brayton',
    'Brazil',
    'Brazoria',
    'Brea',
    'Breaux Bridge',
    'Breckenridge',
    'Brecksville',
    'Breda',
    'Breese',
    'Breezewood',
    'Breezy Point',
    'Breinigsville',
    'Bremen',
    'Bremerton',
    'Bremo Bluff',
    'Brenham',
    'Brent',
    'Brentwood',
    'Bretton Woods',
    'Brevard',
    'Brewer',
    'Brewerton',
    'Brewster',
    'Brewton',
    'Brian Head',
    'Briarcliff Manor',
    'Bricelyn',
    'Briceville',
    'Brick Township',
    'Bridge City',
    'Bridgehampton',
    'Bridgeport',
    'Bridger',
    'Bridgeton',
    'Bridgeview',
    'Bridgeville',
    'Bridgewater',
    'Bridgewater Corners',
    'Bridgman',
    'Bridgton',
    'Brielle',
    'Brier Hill',
    'Brierfield',
    'Brigantine',
    'Briggs',
    'Briggsdale',
    'Brigham City',
    'Brighton',
    'Brightwaters',
    'Brightwood',
    'Brilliant',
    'Brillion',
    'Brimfield',
    'Brimley',
    'Bringhurst',
    'Brinkley',
    'Brisbane',
    'Briscoe',
    'Bristol',
    'Bristolville',
    'Bristow',
    'Britt',
    'Brittany',
    'Britton',
    'Broad Brook',
    'Broad Run',
    'Broadalbin',
    'Broaddus',
    'Broadlands',
    'Broadus',
    'Broadview',
    'Broadview Heights',
    'Broadway',
    'Brockport',
    'Brockton',
    'Brockway',
    'Brocton',
    'Brodhead',
    'Brodheadsville',
    'Brodnax',
    'Brogue',
    'Brohard',
    'Broken Arrow',
    'Broken Bow',
    'Bronaugh',
    'Bronson',
    'Bronston',
    'Bronte',
    'Bronwood',
    'Bronxville',
    'Brook',
    'Brook Park',
    'Brookdale',
    'Brookeland',
    'Brooker',
    'Brookeville',
    'Brookfield',
    'Brookhaven',
    'Brookings',
    'Brookland',
    'Brooklandville',
    'Brooklet',
    'Brooklin',
    'Brookline',
    'Brooklyn',
    'Brookport',
    'Brooks',
    'Brookshire',
    'Brookston',
    'Brooksville',
    'Brookton',
    'Brooktondale',
    'Brookville',
    'Brookwood',
    'Broomall',
    'Broomfield',
    'Brooten',
    'Broseley',
    'Broughton',
    'Broussard',
    'Browder',
    'Brown City',
    'Brownell',
    'Brownfield',
    'Browning',
    'Browns Mills',
    'Browns Summit',
    'Browns Valley',
    'Brownsboro',
    'Brownsburg',
    'Brownsdale',
    'Brownstown',
    'Brownsville',
    'Browntown',
    'Brownville',
    'Brownwood',
    'Broxton',
    'Bruce',
    'Bruceton',
    'Bruceton Mills',
    'Bruceville',
    'Bruin',
    'Brule',
    'Brundidge',
    'Bruner',
    'Bruni',
    'Bruning',
    'Bruno',
    'Brunsville',
    'Brunswick',
    'Brush',
    'Brush Prairie',
    'Brushton',
    'Brusly',
    'Brussels',
    'Bryan',
    'Bryans Road',
    'Bryant',
    'Bryant Pond',
    'Bryantville',
    'Bryce Canyon',
    'Bryce Canyon City',
    'Bryceville',
    'Bryn Athyn',
    'Bryn Mawr',
    'Bryson',
    'Bryson City',
    'Buchanan',
    'Buchanan Dam',
    'Buckatunna',
    'Buckeye',
    'Buckeystown',
    'Buckfield',
    'Buckhannon',
    'Buckhead',
    'Buckholts',
    'Buckingham',
    'Buckingham Township',
    'Buckland',
    'Buckley',
    'Bucklin',
    'Buckner',
    'Bucksport',
    'Bucyrus',
    'Bud',
    'Buda',
    'Budd Lake',
    'Bude',
    'Buellton',
    'Buena',
    'Buena Park',
    'Buena Vista',
    'Buffalo',
    'Buffalo Center',
    'Buffalo Gap',
    'Buffalo Grove',
    'Buffalo Lake',
    'Buffalo Valley',
    'Buford',
    'Buhl',
    'Buhler',
    'Buies Creek',
    'Bulan',
    'Bulger',
    'Bull Shoals',
    'Bullard',
    'Bullhead',
    'Bullhead City',
    'Bullock',
    'Bulls Gap',
    'Bullville',
    'Bulpitt',
    'Bulverde',
    'Bumpass',
    'Bumpus Mills',
    'Buna',
    'Bunceton',
    'Bunch',
    'Buncombe',
    'Bunker Hill',
    'Bunkie',
    'Bunn',
    'Bunnell',
    'Bunnlevel',
    'Bunola',
    'Buras',
    'Burbank',
    'Burden',
    'Burdett',
    'Burdick',
    'Bureau',
    'Burgaw',
    'Burgess',
    'Burgettstown',
    'Burgin',
    'Burkburnett',
    'Burke',
    'Burkesville',
    'Burket',
    'Burkeville',
    'Burleson',
    'Burley',
    'Burlingame',
    'Burlington',
    'Burlington Flats',
    'Burlington Junction',
    'Burlison',
    'Burnet',
    'Burnettsville',
    'Burney',
    'Burneyville',
    'Burnham',
    'Burns',
    'Burns Flat',
    'Burnside',
    'Burnsville',
    'Burnt Hills',
    'Burr',
    'Burr Hill',
    'Burrton',
    'Burt',
    'Burton',
    'Burtonsville',
    'Burtrum',
    'Burwell',
    'Busby',
    'Bush',
    'Bushkill',
    'Bushland',
    'Bushnell',
    'Bushton',
    'Buskirk',
    'Busy',
    'Butler',
    'Butlerville',
    'Butner',
    'Butte',
    'Butte des Morts',
    'Butterfield',
    'Butternut',
    'Buttonwillow',
    'Buxton',
    'Buzzards Bay',
    'Byers',
    'Byesville',
    'Byfield',
    'Byhalia',
    'Bylas',
    'Bynum',
    'Byram',
    'Byrdstown',
    'Byron',
    'Byron Center',
    'Cabazon',
    'Cabery',
    'Cabin Creek',
    'Cabin John',
    'Cable',
    'Cabool',
    'Cabot',
    'Cache',
    'Cactus',
    'Caddo',
    'Caddo Gap',
    'Caddo Mills',
    'Cade',
    'Cades',
    'Cadet',
    'Cadillac',
    'Cadiz',
    'Cadott',
    'Cadyville',
    'Cainsville',
    'Cairnbrook',
    'Cairo',
    'Calabasas',
    'Calabash',
    'Calais',
    'Calamus',
    'Calder',
    'Caldwell',
    'Caledonia',
    'Calera',
    'Calexico',
    'Calhan',
    'Calhoun',
    'Calhoun City',
    'Calhoun Falls',
    'Calico Rock',
    'Caliente',
    'Califon',
    'California',
    'California City',
    'Calimesa',
    'Calipatria',
    'Calistoga',
    'Callahan',
    'Callao',
    'Callaway',
    'Callicoon',
    'Calmar',
    'Calpella',
    'Calpine',
    'Calumet',
    'Calumet City',
    'Calvert',
    'Calvert City',
    'Calverton',
    'Calypso',
    'Camanche',
    'Camano',
    'Camarillo',
    'Camas',
    'Cambria',
    'Cambria Heights',
    'Cambridge',
    'Cambridge City',
    'Cambridge Springs',
    'Cambridgeport',
    'Camby',
    'Camden',
    'Camden Point',
    'Camdenton',
    'Cameron',
    'Cameron Mills',
    'Camilla',
    'Camillus',
    'Camino',
    'Camp Creek',
    'Camp Dennison',
    'Camp Douglas',
    'Camp H M Smith',
    'Camp Hill',
    'Camp Meeker',
    'Camp Point',
    'Camp Verde',
    'Camp Wood',
    'Campbell',
    'Campbell Hall',
    'Campbell Hill',
    'Campbellsburg',
    'Campbellsport',
    'Campbellsville',
    'Campbelltown',
    'Campo',
    'Campobello',
    'Campti',
    'Campton',
    'Campton Upper Village',
    'Campus',
    'Cana',
    'Canaan',
    'Canada',
    'Canadensis',
    'Canadian',
    'Canajoharie',
    'Canal Fulton',
    'Canal Point',
    'Canal Winchester',
    'Canalou',
    'Canandaigua',
    'Canastota',
    'Canby',
    'Candia',
    'Candler',
    'Cando',
    'Candor',
    'Canehill',
    'Caney',
    'Caneyville',
    'Canfield',
    'Canisteo',
    'Canmer',
    'Cannel City',
    'Cannelton',
    'Cannon',
    'Cannon Falls',
    'Cannonville',
    'Canoga Park',
    'Canon',
    'Canon City',
    'Canonsburg',
    'Canterbury',
    'Canton',
    'Cantonment',
    'Cantrall',
    'Canute',
    'Canutillo',
    'Canvas',
    'Canyon',
    'Canyon City',
    'Canyon Country',
    'Canyon Lake',
    'Canyonville',
    'Capac',
    'Cape Canaveral',
    'Cape Charles',
    'Cape Coral',
    'Cape Elizabeth',
    'Cape Fair',
    'Cape Girardeau',
    'Cape May',
    'Cape May Court House',
    'Cape May Point',
    'Cape Neddick',
    'Cape Porpoise',
    'Cape Vincent',
    'Capeville',
    'Capistrano Beach',
    'Capitan',
    'Capitola',
    'Capon Bridge',
    'Capron',
    'Captain Cook',
    'Captiva',
    'Caputa',
    'Caraway',
    'Carbon',
    'Carbon Hill',
    'Carbonado',
    'Carbondale',
    'Cardale',
    'Cardington',
    'Carefree',
    'Carencro',
    'Carey',
    'Careywood',
    'Caribou',
    'Carl Junction',
    'Carle Place',
    'Carleton',
    'Carlin',
    'Carlinville',
    'Carlisle',
    'Carlock',
    'Carlos',
    'Carlotta',
    'Carlsbad',
    'Carlsborg',
    'Carlstadt',
    'Carlton',
    'Carlyle',
    'Carman',
    'Carmel',
    'Carmel Valley',
    'Carmen',
    'Carmi',
    'Carmichael',
    'Carmichaels',
    'Carmine',
    'Carnation',
    'Carnegie',
    'Carnesville',
    'Carney',
    'Caro',
    'Caroga Lake',
    'Carol Stream',
    'Carolina',
    'Carolina Beach',
    'Carp Lake',
    'Carpentersville',
    'Carpinteria',
    'Carr',
    'Carrabelle',
    'Carrboro',
    'Carrie',
    'Carrier',
    'Carrier Mills',
    'Carriere',
    'Carrington',
    'Carrizo Springs',
    'Carrizozo',
    'Carroll',
    'Carrollton',
    'Carrolltown',
    'Carrsville',
    'Carson',
    'Carson City',
    'Carsonville',
    'Carter Lake',
    'Carteret',
    'Cartersville',
    'Carterville',
    'Carthage',
    'Cartwright',
    'Caruthers',
    'Caruthersville',
    'Carver',
    'Cary',
    'Caryville',
    'Casa Grande',
    'Casanova',
    'Casar',
    'Cascade',
    'Cascade Locks',
    'Cascilla',
    'Casco',
    'Caseville',
    'Casey',
    'Caseyville',
    'Cashiers',
    'Cashion',
    'Cashmere',
    'Cashton',
    'Cashtown',
    'Casnovia',
    'Casper',
    'Caspian',
    'Cass City',
    'Cass Lake',
    'Cassadaga',
    'Cassatt',
    'Cassel',
    'Casselberry',
    'Casselton',
    'Cassoday',
    'Cassopolis',
    'Casstown',
    'Cassville',
    'Castaic',
    'Castalia',
    'Castalian Springs',
    'Castile',
    'Castine',
    'Castle Dale',
    'Castle Hayne',
    'Castle Point',
    'Castle Rock',
    'Castleberry',
    'Castleton',
    'Castleton-on-Hudson',
    'Castlewood',
    'Castor',
    'Castorland',
    'Castro Valley',
    'Castroville',
    'Cat Spring',
    'Cataldo',
    'Catasauqua',
    'Cataula',
    'Cataumet',
    'Catawba',
    'Catawissa',
    'Catharpin',
    'Cathedral City',
    'Catherine',
    'Catheys Valley',
    'Cathlamet',
    'Catlett',
    'Catlettsburg',
    'Catlin',
    'Cato',
    'Catonsville',
    'Catoosa',
    'Catron',
    'Catskill',
    'Cattaraugus',
    'Caulfield',
    'Cavalier',
    'Cave City',
    'Cave Creek',
    'Cave Junction',
    'Cave Spring',
    'Cave Springs',
    'Cave-in-Rock',
    'Cavendish',
    'Cavour',
    'Cawood',
    'Cayce',
    'Cayucos',
    'Cayuga',
    'Cayuta',
    'Cazadero',
    'Cazenovia',
    'Cañon City',
    'Cecil',
    'Cecilia',
    'Cecilton',
    'Cedar',
    'Cedar Bluff',
    'Cedar Bluffs',
    'Cedar Brook',
    'Cedar City',
    'Cedar Creek',
    'Cedar Crest',
    'Cedar Falls',
    'Cedar Glen',
    'Cedar Grove',
    'Cedar Hill',
    'Cedar Key',
    'Cedar Knolls',
    'Cedar Lake',
    'Cedar Park',
    'Cedar Point',
    'Cedar Rapids',
    'Cedar Ridge',
    'Cedar Springs',
    'Cedar Vale',
    'Cedar Valley',
    'Cedarburg',
    'Cedaredge',
    'Cedarhurst',
    'Cedartown',
    'Cedarville',
    'Celeste',
    'Celina',
    'Celoron',
    'Cement',
    'Cement City',
    'Centenary',
    'Center',
    'Center Barnstead',
    'Center City',
    'Center Conway',
    'Center Cross',
    'Center Harbor',
    'Center Hill',
    'Center Junction',
    'Center Line',
    'Center Moriches',
    'Center Ossipee',
    'Center Point',
    'Center Ridge',
    'Center Rutland',
    'Center Sandwich',
    'Center Tuftonboro',
    'Center Valley',
    'Centerbrook',
    'Centerburg',
    'Centereach',
    'Centerfield',
    'Centerport',
    'Centerton',
    'Centertown',
    'Centerview',
    'Centerville',
    'Centrahoma',
    'Central',
    'Central Bridge',
    'Central City',
    'Central Falls',
    'Central Islip',
    'Central Lake',
    'Central Point',
    'Central Square',
    'Central Valley',
    'Central Village',
    'Centralia',
    'Centre',
    'Centre Hall',
    'Centreville',
    'Centuria',
    'Century',
    'Ceres',
    'Ceresco',
    'Cerrillos',
    'Cerritos',
    'Cerro',
    'Cerro Gordo',
    'Cerulean',
    'Chadbourn',
    'Chadds Ford',
    'Chadron',
    'Chadwick',
    'Chaffee',
    'Chagrin Falls',
    'Chalfont',
    'Challis',
    'Chalmette',
    'Chama',
    'Chamberino',
    'Chamberlain',
    'Chambers',
    'Chambersburg',
    'Champaign',
    'Champion',
    'Champlain',
    'Champlin',
    'Chana',
    'Chancellor',
    'Chandler',
    'Chandlersville',
    'Chandlerville',
    'Chanhassen',
    'Channahon',
    'Channelview',
    'Channing',
    'Chantilly',
    'Chanute',
    'Chaparral',
    'Chapel Hill',
    'Chapin',
    'Chaplin',
    'Chapman',
    'Chapmansboro',
    'Chapmanville',
    'Chappaqua',
    'Chappell Hill',
    'Chardon',
    'Charenton',
    'Chariton',
    'Charlemont',
    'Charleroi',
    'Charles City',
    'Charles Town',
    'Charleston',
    'Charlestown',
    'Charlevoix',
    'Charlotte',
    'Charlotte Court House',
    'Charlotte Hall',
    'Charlottesville',
    'Charlotteville',
    'Charlton',
    'Charter Oak',
    'Charter Township of Clinton',
    'Chartley',
    'Chase',
    'Chase City',
    'Chaseburg',
    'Chaseley',
    'Chaska',
    'Chassell',
    'Chataignier',
    'Chatfield',
    'Chatham',
    'Chatom',
    'Chatsworth',
    'Chattahoochee',
    'Chattanooga',
    'Chattaroy',
    'Chaumont',
    'Chauncey',
    'Chautauqua',
    'Chauvin',
    'Chavies',
    'Chazy',
    'Chebanse',
    'Chebeague Island',
    'Cheboygan',
    'Checotah',
    'Chefornak',
    'Chehalis',
    'Chelan',
    'Chelan Falls',
    'Chelmsford',
    'Chelsea',
    'Cheltenham',
    'Chemung',
    'Chenango Forks',
    'Cheney',
    'Cheneyville',
    'Chenoa',
    'Chepachet',
    'Cheraw',
    'Cheriton',
    'Cherokee',
    'Cherokee Village',
    'Cherry Hill',
    'Cherry Log',
    'Cherry Point Marine Base Mobile Home Park',
    'Cherry Tree',
    'Cherry Valley',
    'Cherryfield',
    'Cherryvale',
    'Cherryville',
    'Chesaning',
    'Chesapeake',
    'Chesapeake Beach',
    'Chesapeake City',
    'Cheshire',
    'Chesnee',
    'Chester',
    'Chester Heights',
    'Chester Springs',
    'Chesterfield',
    'Chesterhill',
    'Chesterland',
    'Chesterton',
    'Chestertown',
    'Chestnut',
    'Chestnut Hill',
    'Chestnut Mountain',
    'Chestnut Ridge',
    'Cheswick',
    'Chetek',
    'Chetopa',
    'Chevak',
    'Chevy Chase',
    'Chewelah',
    'Cheyenne',
    'Cheyenne Wells',
    'Cheyney',
    'Chicago',
    'Chicago Heights',
    'Chicago Ridge',
    'Chichester',
    'Chickamauga',
    'Chickasha',
    'Chicken',
    'Chico',
    'Chicopee',
    'Chicora',
    'Chiefland',
    'Childersburg',
    'Childress',
    'Childs',
    'Chilhowee',
    'Chilhowie',
    'Chili',
    'Chillicothe',
    'Chilo',
    'Chilton',
    'Chimacum',
    'China Grove',
    'China Springs',
    'Chinchilla',
    'Chincoteague Island',
    'Chinese Camp',
    'Chinle',
    'Chino',
    'Chino Hills',
    'Chino Valley',
    'Chinook',
    'Chipley',
    'Chippewa Falls',
    'Chippewa Lake',
    'Chireno',
    'Chisago City',
    'Chisholm',
    'Chittenango',
    'Chittenden',
    'Chloride',
    'Chocorua',
    'Chocowinity',
    'Choctaw',
    'Chokio',
    'Choteau',
    'Choudrant',
    'Chouteau',
    'Chowchilla',
    'Chrisman',
    'Christchurch',
    'Christiana',
    'Christiansburg',
    'Christine',
    'Christmas',
    'Christmas Valley',
    'Christopher',
    'Christoval',
    'Chualar',
    'Chuckey',
    'Chugiak',
    'Chugwater',
    'Chula',
    'Chula Vista',
    'Chunchula',
    'Chunky',
    'Church Hill',
    'Church Point',
    'Church Rock',
    'Churchton',
    'Churchville',
    'Churdan',
    'Churubusco',
    'Cibola',
    'Cibolo',
    'Cicero',
    'Cimarron',
    'Cincinnati',
    'Cincinnatus',
    'Cinebar',
    'Circle',
    'Circle Pines',
    'Circleville',
    'Cisco',
    'Cisne',
    'Cissna Park',
    'Citra',
    'Citronelle',
    'Citrus Heights',
    'City of Angels',
    'City of Industry',
    'City of Picher (historical)',
    'City of Saint Marys',
    'City of Saint Peters',
    'Clackamas',
    'Claflin',
    'Claire City',
    'Clairton',
    'Clam Lake',
    'Clancy',
    'Clanton',
    'Clara City',
    'Clare',
    'Claremont',
    'Claremore',
    'Clarence',
    'Clarendon',
    'Clarendon Hills',
    'Claridge',
    'Clarinda',
    'Clarington',
    'Clarion',
    'Clarissa',
    'Clark',
    'Clark Fork',
    'Clark Mills',
    'Clarkesville',
    'Clarkfield',
    'Clarkia',
    'Clarklake',
    'Clarkrange',
    'Clarks',
    'Clarks Grove',
    'Clarks Hill',
    'Clarks Summit',
    'Clarksboro',
    'Clarksburg',
    'Clarksdale',
    'Clarkson',
    'Clarkston',
    'Clarksville',
    'Clarkton',
    'Claryville',
    'Clatonia',
    'Clatskanie',
    'Claude',
    'Claudville',
    'Claverack',
    'Clawson',
    'Claxton',
    'Clay',
    'Clay Center',
    'Clay City',
    'Claymont',
    'Claypool',
    'Claysburg',
    'Claysville',
    'Clayton',
    'Cle Elum',
    'Clear Brook',
    'Clear Fork',
    'Clear Lake',
    'Clear Spring',
    'Clearbrook',
    'Clearfield',
    'Clearlake',
    'Clearlake Oaks',
    'Clearmont',
    'Clearville',
    'Clearwater',
    'Clearwater Beach',
    'Cleburne',
    'Cleghorn',
    'Clementon',
    'Clements',
    'Clemmons',
    'Clemons',
    'Clemson',
    'Clendenin',
    'Clermont',
    'Cleveland',
    'Clever',
    'Cleves',
    'Clewiston',
    'Clifford',
    'Cliffside Park',
    'Cliffwood',
    'Clifton',
    'Clifton Forge',
    'Clifton Heights',
    'Clifton Park',
    'Clifton Springs',
    'Climax',
    'Climax Springs',
    'Clinchco',
    'Clint',
    'Clinton',
    'Clinton Corners',
    'Clintondale',
    'Clintonville',
    'Clintwood',
    'Clio',
    'Clive',
    'Clontarf',
    'Clopton',
    'Cloquet',
    'Closplint',
    'Closter',
    'Cloud',
    'Cloudcroft',
    'Clover',
    'Cloverdale',
    'Cloverport',
    'Clovis',
    'Clute',
    'Clyde',
    'Clyde Park',
    'Clyman',
    'Clymer',
    'Clyo',
    'Coachella',
    'Coahoma',
    'Coal Center',
    'Coal City',
    'Coal Hill',
    'Coal Mountain',
    'Coal Township',
    'Coal Valley',
    'Coaldale',
    'Coalfield',
    'Coalgate',
    'Coalinga',
    'Coalmont',
    'Coalport',
    'Coalville',
    'Coarsegold',
    'Coatesville',
    'Coats',
    'Coatsburg',
    'Cobalt',
    'Cobb',
    'Cobbs Creek',
    'Cobden',
    'Cobleskill',
    'Coburn',
    'Cochecton',
    'Cochise',
    'Cochiti Lake',
    'Cochiti Pueblo',
    'Cochran',
    'Cochrane',
    'Cochranton',
    'Cochranville',
    'Cockeysville',
    'Cocoa',
    'Cocoa Beach',
    'Cocolamus',
    'Coden',
    'Cody',
    'Coeburn',
    "Coeur d'Alene",
    'Coeymans',
    'Coeymans Hollow',
    'Coffee Springs',
    'Coffeen',
    'Coffeeville',
    'Coffey',
    'Coffeyville',
    'Coffman Cove',
    'Coggon',
    'Cogswell',
    'Cohasset',
    'Cohocton',
    'Cohoes',
    'Cohutta',
    'Coin',
    'Coinjock',
    'Cokato',
    'Cokeburg',
    'Coker',
    'Cokeville',
    'Colbert',
    'Colby',
    'Colchester',
    'Colcord',
    'Cold Brook',
    'Cold Spring',
    'Cold Spring Harbor',
    'Cold Springs',
    'Colden',
    'Coldspring',
    'Coldwater',
    'Cole Camp',
    'Colebrook',
    'Coleman',
    'Colerain',
    'Coleraine',
    'Coleridge',
    'Colesburg',
    'Coleville',
    'Colfax',
    'Colgate',
    'College Corner',
    'College Grove',
    'College Park',
    'College Place',
    'College Point',
    'College Station',
    'Collegedale',
    'Collegeport',
    'Collegeville',
    'Colleyville',
    'Colliers',
    'Collierville',
    'Collingswood',
    'Collins',
    'Collinston',
    'Collinsville',
    'Collinwood',
    'Collison',
    'Colman',
    'Colmar',
    'Colmesneil',
    'Colo',
    'Cologne',
    'Coloma',
    'Colon',
    'Colona',
    'Colonia',
    'Colonial Beach',
    'Colonial Heights',
    'Colony',
    'Colora',
    'Colorado City',
    'Colorado Springs',
    'Colquitt',
    'Colstrip',
    'Colt',
    'Colton',
    'Colts Neck',
    'Columbia',
    'Columbia City',
    'Columbia Cross Roads',
    'Columbia Falls',
    'Columbia Station',
    'Columbiana',
    'Columbiaville',
    'Columbus',
    'Columbus Grove',
    'Columbus Junction',
    'Colusa',
    'Colver',
    'Colville',
    'Colwich',
    'Comanche',
    'Combined Locks',
    'Combs',
    'Comer',
    'Comfort',
    'Comfrey',
    'Commack',
    'Commerce',
    'Commerce City',
    'Commercial Point',
    'Commiskey',
    'Como',
    'Comptche',
    'Compton',
    'Comstock',
    'Comstock Park',
    'Conception Junction',
    'Concho',
    'Concord',
    'Concordia',
    'Concordville',
    'Concrete',
    'Condon',
    'Conestoga',
    'Conesus',
    'Conesville',
    'Confluence',
    'Conger',
    'Congers',
    'Congerville',
    'Congress',
    'Conifer',
    'Conklin',
    'Conley',
    'Conneaut',
    'Conneaut Lake',
    'Conneautville',
    'Connell',
    'Connellsville',
    'Connelly Springs',
    'Connersville',
    'Conover',
    'Conowingo',
    'Conrad',
    'Conrath',
    'Conroe',
    'Conroy',
    'Conshohocken',
    'Constableville',
    'Constantia',
    'Constantine',
    'Contact',
    'Content',
    'Continental',
    'Contoocook',
    'Convent',
    'Converse',
    'Convoy',
    'Conway',
    'Conway Springs',
    'Conyers',
    'Conyngham',
    'Cook',
    'Cookeville',
    'Cookstown',
    'Cooksville',
    'Coolidge',
    'Coolin',
    'Coolville',
    'Coon Rapids',
    'Coon Valley',
    'Cooper',
    'Cooper Landing',
    'Coopers Plains',
    'Coopersburg',
    'Cooperstown',
    'Coopersville',
    'Coos Bay',
    'Copake',
    'Copake Falls',
    'Copalis Beach',
    'Copan',
    'Cope',
    'Copeland',
    'Copenhagen',
    'Copeville',
    'Copiague',
    'Coplay',
    'Coppell',
    'Copper Center',
    'Copper Harbor',
    'Copperas Cove',
    'Copperhill',
    'Copperopolis',
    'Coquille',
    'Coral',
    'Coral Gables',
    'Coralville',
    'Coram',
    'Coraopolis',
    'Corbett',
    'Corbin',
    'Corcoran',
    'Cordele',
    'Cordell',
    'Cordesville',
    'Cordova',
    'Corfu',
    'Corinna',
    'Corinne',
    'Corinth',
    'Corn',
    'Cornelia',
    'Cornelius',
    'Cornell',
    'Cornersville',
    'Corning',
    'Cornish',
    'Cornish Flat',
    'Cornville',
    'Cornwall',
    'Cornwall Bridge',
    'Cornwall-on-Hudson',
    'Cornwallville',
    'Corolla',
    'Corona',
    'Corona del Mar',
    'Coronado',
    'Corpus Christi',
    'Corrales',
    'Correctionville',
    'Corrigan',
    'Corry',
    'Corryton',
    'Corsica',
    'Corsicana',
    'Cortaro',
    'Corte Madera',
    'Cortez',
    'Cortland',
    'Corunna',
    'Corvallis',
    'Corwith',
    'Cory',
    'Corydon',
    "Coryell's Ferry",
    'Cos Cob',
    'Cosby',
    'Coshocton',
    'Cosmopolis',
    'Cosmos',
    'Costa Mesa',
    'Cotati',
    'Cottage Grove',
    'Cottage Hills',
    'Cottageville',
    'Cottekill',
    'Cotton',
    'Cotton Valley',
    'Cottondale',
    'Cottonport',
    'Cottontown',
    'Cottonwood',
    'Cotuit',
    'Cotulla',
    'Coudersport',
    'Coulee City',
    'Coulee Dam',
    'Coulterville',
    'Counce',
    'Council',
    'Council Bluffs',
    'Council Grove',
    'Council Hill',
    'Country Club Hills',
    'Coupeville',
    'Coupland',
    'Courtland',
    'Coushatta',
    'Cove',
    'Covelo',
    'Coventry',
    'Covert',
    'Covina',
    'Covington',
    'Cowan',
    'Cowansville',
    'Coward',
    'Cowden',
    'Cowen',
    'Coweta',
    'Cowiche',
    'Cowlesville',
    'Cowley',
    'Cowpens',
    'Coxs Creek',
    'Coxsackie',
    'Coyle',
    'Coyote',
    'Cozad',
    'Crab Orchard',
    'Crabtree',
    'Craftsbury',
    'Craig',
    'Craigmont',
    'Craigsville',
    'Craigville',
    'Cramerton',
    'Cranberry Township',
    'Cranbury',
    'Crandall',
    'Crandon',
    'Crane',
    'Crane Hill',
    'Crane Lake',
    'Cranesville',
    'Cranfills Gap',
    'Cranford',
    'Cranks',
    'Cranston',
    'Craryville',
    'Crawford',
    'Crawfordsville',
    'Crawfordville',
    'Crawley',
    'Creal Springs',
    'Cream Ridge',
    'Creede',
    'Creedmoor',
    'Creekside',
    'Creighton',
    'Creola',
    'Creole',
    'Cresbard',
    'Crescent',
    'Crescent City',
    'Crescent Hills',
    'Crescent Mills',
    'Crescent Valley',
    'Cresco',
    'Cresskill',
    'Cresson',
    'Crest Hill',
    'Crested Butte',
    'Crestline',
    'Creston',
    'Crestone',
    'Crestview',
    'Crestwood',
    'Creswell',
    'Crete',
    'Creve Coeur',
    'Crewe',
    'Crimora',
    'Cripple Creek',
    'Crisfield',
    'Crittenden',
    'Critz',
    'Crivitz',
    'Crocker',
    'Crockett',
    'Crofton',
    'Croghan',
    'Crompond',
    'Cromwell',
    'Crooks',
    'Crookston',
    'Crooksville',
    'Cropseyville',
    'Crosby',
    'Crosbyton',
    'Cross',
    'Cross City',
    'Cross Junction',
    'Cross Lake',
    'Cross Plains',
    'Cross River',
    'Crossett',
    'Crossville',
    'Crosswicks',
    'Croswell',
    'Crothersville',
    'Croton',
    'Croton Falls',
    'Croton-on-Hudson',
    'Crow Agency',
    'Crowder',
    'Crowell',
    'Crowheart',
    'Crowley',
    'Crown City',
    'Crown Point',
    'Crownpoint',
    'Crownsville',
    'Crows Landing',
    'Croydon',
    'Crozet',
    'Crucible',
    'Cruger',
    'Crum',
    'Crum Lynne',
    'Crump',
    'Crumpler',
    'Crystal',
    'Crystal City',
    'Crystal Falls',
    'Crystal Lake',
    'Crystal River',
    'Crystal Springs',
    'Cub Run',
    'Cuba',
    'Cuba City',
    'Cudahy',
    'Cuddebackville',
    'Cuddy Hill',
    'Cuero',
    'Culbertson',
    'Culdesac',
    'Cullen',
    'Culleoka',
    'Cullman',
    'Culloden',
    'Cullom',
    'Cullowhee',
    'Culpeper',
    'Culver',
    'Culver City',
    'Cumberland',
    'Cumberland Center',
    'Cumberland City',
    'Cumberland Foreside',
    'Cumberland Furnace',
    'Cumberland Gap',
    'Cumby',
    'Cumming',
    'Cummings',
    'Cummington',
    'Cunningham',
    'Cupertino',
    'Currie',
    'Currituck',
    'Curryville',
    'Curtice',
    'Curtis',
    'Curtis Bay',
    'Curtiss',
    'Curwensville',
    'Cushing',
    'Cusick',
    'Cusseta',
    'Custar',
    'Custer',
    'Custer City',
    'Cut Bank',
    'Cut Off',
    'Cutchogue',
    'Cuthbert',
    'Cutler',
    'Cuttingsville',
    'Cuyahoga Falls',
    'Cyclone',
    'Cygnet',
    'Cylinder',
    'Cynthiana',
    'Cypress',
    'Cypress Inn',
    'Cyril',
    "D'Iberville",
    'Dacoma',
    'Dacono',
    'Dacula',
    'Dade City',
    'Dadeville',
    'Daggett',
    'Dagsboro',
    'Dahlgren',
    'Dahlonega',
    'Daingerfield',
    'Daisetta',
    'Daisytown',
    'Dakota',
    'Dakota City',
    'Dale',
    'Daleville',
    'Dalhart',
    'Dallas',
    'Dallas Center',
    'Dallas City',
    'Dallastown',
    'Dalmatia',
    'Dalton',
    'Dalton City',
    'Daly City',
    'Dalzell',
    'Damariscotta',
    'Damascus',
    'Damon',
    'Dana',
    'Dana Point',
    'Danbury',
    'Danby',
    'Dandridge',
    'Dane',
    'Danese',
    'Danforth',
    'Dania Beach',
    'Daniels',
    'Danielson',
    'Danielsville',
    'Dannemora',
    'Dansville',
    'Dante',
    'Danube',
    'Danvers',
    'Danville',
    'Daphne',
    'Darby',
    'Dardanelle',
    'Darden',
    'Darien',
    'Darlington',
    'Darmstadt',
    'Darrington',
    'Darrouzett',
    'Darrow',
    'Darwin',
    'Dassel',
    'Dateland',
    'Datil',
    'Dauphin',
    'Dauphin Island',
    'Davenport',
    'David City',
    'Davidson',
    'Davidsonville',
    'Davis',
    'Davis City',
    'Davis Junction',
    'Davisboro',
    'Davisburg',
    'Davison',
    'Daviston',
    'Davisville',
    'Davy',
    'Dawes',
    'Dawn',
    'Dawson',
    'Dawson Springs',
    'Dawsonville',
    'Days Creek',
    'Dayton',
    'Daytona Beach',
    'Dayville',
    'Dazey',
    'De Beque',
    'De Borgia',
    'De Forest',
    'De Graff',
    'De Kalb',
    'De Kalb Junction',
    'De Leon',
    'De Leon Springs',
    'De Queen',
    'De Smet',
    'De Soto',
    'De Valls Bluff',
    'De Witt',
    'DeBary',
    'DeFuniak Springs',
    'DeKalb',
    'DeLand',
    'DeMossville',
    'DeMotte',
    'DeQuincy',
    'DeRidder',
    'DeRuyter',
    'DeSoto',
    'DeWitt',
    'Deadwood',
    'Deal',
    'Deal Island',
    'Deale',
    'Deansboro',
    'Deanville',
    'Dearborn',
    'Dearborn Heights',
    'Dearing',
    'Death Valley Junction',
    'Deatsville',
    'Deaver',
    'Debord',
    'Decatur',
    'Decaturville',
    'Decherd',
    'Decker',
    'Deckerville',
    'Declo',
    'Decorah',
    'Dedham',
    'Deep River',
    'Deep Run',
    'Deep Water',
    'Deepwater',
    'Deer',
    'Deer Island',
    'Deer Isle',
    'Deer Lodge',
    'Deer Park',
    'Deer River',
    'Deer Trail',
    'Deerfield',
    'Deerfield Beach',
    'Deering',
    'Deerwood',
    'Defiance',
    'Deford',
    'Del Mar',
    'Del Norte',
    'Del Rey',
    'Del Rio',
    'Del Valle',
    'Delafield',
    'Delano',
    'Delanson',
    'Delaplaine',
    'Delavan',
    'Delaware',
    'Delaware City',
    'Delbarton',
    'Delcambre',
    'Delco',
    'Delevan',
    'Delhi',
    'Delight',
    'Dell',
    'Dell Rapids',
    'Delmar',
    'Delmont',
    'Deloit',
    'Delphi',
    'Delphos',
    'Delray',
    'Delray Beach',
    'Delta',
    'Deltana',
    'Deltaville',
    'Delton',
    'Deltona',
    'Dema',
    'Demarest',
    'Deming',
    'Demopolis',
    'Demorest',
    'Denair',
    'Dendron',
    'Denham Springs',
    'Denison',
    'Denmark',
    'Dennis',
    'Dennis Port',
    'Dennison',
    'Denniston',
    'Dennysville',
    'Dent',
    'Denton',
    'Denver',
    'Denver City',
    'Denville',
    'Depauw',
    'Depere',
    'Depew',
    'Depoe Bay',
    'Deposit',
    'Depue',
    'Deputy',
    'Derby',
    'Derby Center',
    'Derby Line',
    'Derma',
    'Dermott',
    'Derry',
    'Derwood',
    'Des Allemands',
    'Des Arc',
    'Des Moines',
    'Des Plaines',
    'Descanso',
    'Desert Hot Springs',
    'Deshler',
    'Destin',
    'Destrehan',
    'Detroit',
    'Detroit Lakes',
    'Devens',
    'Deville',
    'Devils Lake',
    'Devine',
    'Devon',
    'Dewar',
    'Dewey',
    'Dewy Rose',
    'Dexter',
    'Dexter City',
    'Diablo',
    'Diagonal',
    'Diamond',
    'Diamond Bar',
    'Diamond Point',
    'Diamond Springs',
    'Diamondhead',
    'Diamondville',
    'Diana',
    'Diboll',
    'Dickerson',
    'Dickeyville',
    'Dickinson',
    'Dickinson Center',
    'Dickson',
    'Dickson City',
    'Dierks',
    'Dieterich',
    'Dietrich',
    'Diggs',
    'Dighton',
    'Dill City',
    'Dillard',
    'Diller',
    'Dilley',
    'Dillingham',
    'Dillon',
    'Dillon Beach',
    'Dillonvale',
    'Dillsboro',
    'Dillsburg',
    'Dillwyn',
    'Dime Box',
    'Dimmitt',
    'Dimock',
    'Dimondale',
    'Dingess',
    'Dingmans Ferry',
    'Dinuba',
    'Dinwiddie',
    'Discovery Bay',
    'Disputanta',
    'Distant',
    'District Heights',
    'Dittmer',
    'Divernon',
    'Divide',
    'Dividing Creek',
    'Dix',
    'Dixfield',
    'Dixie',
    'Dixmont',
    'Dixon',
    'Dixon Springs',
    'Dixons Mills',
    'Dixonville',
    'Dobbs Ferry',
    'Dobson',
    'Docena',
    'Dodd City',
    'Doddridge',
    'Dodge',
    'Dodge Center',
    'Dodge City',
    'Dodgeville',
    'Dodgewood',
    'Doerun',
    'Dolan Springs',
    'Doland',
    'Dolgeville',
    'Dollar Bay',
    'Dolliver',
    'Dolomite',
    'Dolores',
    'Dolphin',
    'Dolton',
    'Donald',
    'Donalds',
    'Donaldson',
    'Donaldsonville',
    'Donalsonville',
    'Donegal',
    'Dongola',
    'Donie',
    'Doniphan',
    'Donna',
    'Donnellson',
    'Donnelly',
    'Donnelsville',
    'Donner',
    'Donora',
    'Donovan',
    'Doon',
    'Dora',
    'Dorchester',
    'Dorena',
    'Dornsife',
    'Dorothy',
    'Dorr',
    'Dorrance',
    'Dorris',
    'Dorset',
    'Dorsey',
    'Dos Palos',
    'Doss',
    'Doswell',
    'Dothan',
    'Double Springs',
    'Dougherty',
    'Douglas',
    'Douglas City',
    'Douglass',
    'Douglassville',
    'Douglasville',
    'Dousman',
    'Dover',
    'Dover Plains',
    'Dover-Foxcroft',
    'Dow',
    'Dowagiac',
    'Dowell',
    'Dowelltown',
    'Dowling',
    'Downers Grove',
    'Downey',
    'Downieville',
    'Downingtown',
    'Downs',
    'Downsville',
    'Dows',
    'Doyle',
    'Doylestown',
    'Doyline',
    'Dozier',
    'Dracut',
    'Dragoon',
    'Drain',
    'Drakes Branch',
    'Drakesboro',
    'Draper',
    'Drasco',
    'Dravosburg',
    'Drayden',
    'Dresden',
    'Dresher',
    'Dresser',
    'Drewryville',
    'Drexel',
    'Drexel Hill',
    'Drift',
    'Drifting',
    'Driftwood',
    'Driggs',
    'Dripping Springs',
    'Driscoll',
    'Drummond',
    'Drummonds',
    'Drumore',
    'Drumright',
    'Drums',
    'Dry Branch',
    'Dry Creek',
    'Dry Fork',
    'Dry Prong',
    'Dry Ridge',
    'Dry Run',
    'Drybranch',
    'Dryden',
    'Dryfork',
    'Du Bois',
    'Du Quoin',
    'DuBois',
    'DuPont',
    'Duanesburg',
    'Duarte',
    'Dubach',
    'Dublin',
    'Dubois',
    'Dubuque',
    'Duchesne',
    'Duck',
    'Duck Creek Village',
    'Duck Hill',
    'Ducktown',
    'Dudley',
    'Due West',
    'Duff',
    'Duffield',
    'Dufur',
    'Dugger',
    'Dugway',
    'Duke',
    'Duke Center',
    'Dukedom',
    'Dulac',
    'Dulce',
    'Dulles',
    'Duluth',
    'Dulzura',
    'Dumas',
    'Dumfries',
    'Dumont',
    'Dunbar',
    'Dunbarton Center',
    'Duncan',
    'Duncannon',
    'Duncans Mills',
    'Duncansville',
    'Duncanville',
    'Duncombe',
    'Dundalk',
    'Dundas',
    'Dundee',
    'Dunedin',
    'Dunellen',
    'Dungannon',
    'Dunkerton',
    'Dunkirk',
    'Dunlap',
    'Dunlow',
    'Dunmor',
    'Dunmore',
    'Dunn',
    'Dunn Loring',
    'Dunnell',
    'Dunnellon',
    'Dunnsville',
    'Dunnville',
    'Dunseith',
    'Dunsmuir',
    'Dunstable',
    'Duplessis',
    'Dupo',
    'Dupont',
    'Dupuyer',
    'Duquesne',
    'Durand',
    'Durango',
    'Durant',
    'Durbin',
    'Durham',
    'Durhamville',
    'Duryea',
    'Dushore',
    'Duson',
    'Dustin',
    'Dutch Flat',
    'Dutch Harbor',
    'Dutch John',
    'Dutton',
    'Duvall',
    'Duxbury',
    'Dwale',
    'Dwight',
    'Dyer',
    'Dyersburg',
    'Dyersville',
    'Dysart',
    'Eads',
    'Eagar',
    'Eagle',
    'Eagle Bend',
    'Eagle Bridge',
    'Eagle Butte',
    'Eagle Creek',
    'Eagle Grove',
    'Eagle Lake',
    'Eagle Mountain',
    'Eagle Nest',
    'Eagle Pass',
    'Eagle Point',
    'Eagle River',
    'Eagle Rock',
    'Eagle Springs',
    'Eagleville',
    'Earl Park',
    'Earle',
    'Earleville',
    'Earlham',
    'Earlimart',
    'Earling',
    'Earlsboro',
    'Earlton',
    'Earlville',
    'Early',
    'Earlysville',
    'Earth',
    'Earth City',
    'Easley',
    'East Alton',
    'East Amherst',
    'East Andover',
    'East Aurora',
    'East Bank',
    'East Barre',
    'East Bend',
    'East Berkshire',
    'East Berlin',
    'East Bernard',
    'East Berne',
    'East Bernstadt',
    'East Blue Hill',
    'East Boothbay',
    'East Brady',
    'East Branch',
    'East Bridgewater',
    'East Brookfield',
    'East Brunswick',
    'East Burke',
    'East Butler',
    'East Calais',
    'East Canaan',
    'East Candia',
    'East Carbon City',
    'East Carondelet',
    'East Chatham',
    'East Chicago',
    'East China Township',
    'East Claridon',
    'East Corinth',
    'East Dennis',
    'East Dover',
    'East Dublin',
    'East Dubuque',
    'East Durham',
    'East Earl',
    'East Elmhurst',
    'East Fairfield',
    'East Fairview',
    'East Falmouth',
    'East Flat Rock',
    'East Freedom',
    'East Freetown',
    'East Galesburg',
    'East Granby',
    'East Grand Forks',
    'East Greenbush',
    'East Greenville',
    'East Greenwich',
    'East Haddam',
    'East Hampstead',
    'East Hampton',
    'East Hanover',
    'East Hardwick',
    'East Hartford',
    'East Hartland',
    'East Haven',
    'East Helena',
    'East Irvine',
    'East Islip',
    'East Jordan',
    'East Kingston',
    'East Lake',
    'East Lansing',
    'East Leroy',
    'East Liberty',
    'East Liverpool',
    'East Longmeadow',
    'East Lynn',
    'East Machias',
    'East Marion',
    'East McKeesport',
    'East Meadow',
    'East Meredith',
    'East Middlebury',
    'East Millinocket',
    'East Millsboro',
    'East Moline',
    'East Montpelier',
    'East Moriches',
    'East New Market',
    'East Northport',
    'East Norwich',
    'East Orange',
    'East Orleans',
    'East Otis',
    'East Palatka',
    'East Palestine',
    'East Peoria',
    'East Petersburg',
    'East Pharsalia',
    'East Pittsburgh',
    'East Point',
    'East Prairie',
    'East Prospect',
    'East Providence',
    'East Quogue',
    'East Randolph',
    'East Rochester',
    'East Rockaway',
    'East Rutherford',
    'East Saint Louis',
    'East Sandwich',
    'East Setauket',
    'East Sparta',
    'East Stone Gap',
    'East Stoneham',
    'East Stroudsburg',
    'East Syracuse',
    'East Taunton',
    'East Tawas',
    'East Thetford',
    'East Troy',
    'East Wakefield',
    'East Walpole',
    'East Wareham',
    'East Waterboro',
    'East Wenatchee',
    'East Weymouth',
    'East Windsor Hill',
    'Eastaboga',
    'Eastanollee',
    'Eastchester',
    'Eastern',
    'Eastford',
    'Eastham',
    'Easthampton',
    'Eastlake',
    'Eastland',
    'Eastman',
    'Easton',
    'Eastover',
    'Eastpoint',
    'Eastpointe',
    'Eastport',
    'Eastsound',
    'Eastview',
    'Eastville',
    'Eaton',
    'Eaton Rapids',
    'Eatonton',
    'Eatontown',
    'Eatonville',
    'Eau Claire',
    'Eau Galle',
    'Ebensburg',
    'Echo',
    'Eckert',
    'Eckerty',
    'Eclectic',
    'Economy',
    'Ecorse',
    'Ecru',
    'Ector',
    'Edcouch',
    'Eddington',
    'Eddy',
    'Eddyville',
    'Edelstein',
    'Eden',
    'Eden Prairie',
    'Eden Valley',
    'Edenton',
    'Edgar',
    'Edgard',
    'Edgartown',
    'Edgecomb',
    'Edgefield',
    'Edgeley',
    'Edgemont',
    'Edgemoor',
    'Edgerton',
    'Edgewater',
    'Edgewood',
    'Edina',
    'Edinboro',
    'Edinburg',
    'Edinburgh',
    'Edison',
    'Edisto Island',
    'Edmeston',
    'Edmond',
    'Edmonds',
    'Edmonton',
    'Edmore',
    'Edna',
    'Edneyville',
    'Edon',
    'Edson',
    'Edwall',
    'Edwards',
    'Edwards Air Force Base',
    'Edwardsburg',
    'Edwardsport',
    'Edwardsville',
    'Effie',
    'Effingham',
    'Effort',
    'Efland',
    'Egan',
    'Egg Harbor',
    'Egg Harbor City',
    'Eglin',
    'Eglon',
    'Egypt',
    'Ehrenberg',
    'Eielson Air Force Base',
    'Eight Mile',
    'Eighty Four',
    'Ekalaka',
    'Ekron',
    'El Cajon',
    'El Campo',
    'El Centro',
    'El Cerrito',
    'El Dorado',
    'El Dorado Hills',
    'El Dorado Springs',
    'El Granada',
    'El Macero',
    'El Mirage',
    'El Monte',
    'El Paso',
    'El Prado',
    'El Reno',
    'El Segundo',
    'El Sobrante',
    'Elba',
    'Elberon',
    'Elbert',
    'Elberta',
    'Elberton',
    'Elbow Lake',
    'Elbridge',
    'Elburn',
    'Elcho',
    'Elco',
    'Elderton',
    'Eldon',
    'Eldora',
    'Eldorado',
    'Eldred',
    'Eldridge',
    'Eleanor',
    'Electra',
    'Elephant Butte',
    'Eleva',
    'Elfrida',
    'Elgin',
    'Eliot',
    'Elizabeth',
    'Elizabeth City',
    'Elizabethton',
    'Elizabethtown',
    'Elizabethville',
    'Elizaville',
    'Elk',
    'Elk City',
    'Elk Falls',
    'Elk Garden',
    'Elk Grove',
    'Elk Grove Village',
    'Elk Horn',
    'Elk Mills',
    'Elk Mound',
    'Elk Park',
    'Elk Point',
    'Elk Rapids',
    'Elk River',
    'Elkader',
    'Elkfork',
    'Elkhart',
    'Elkhart Lake',
    'Elkhorn',
    'Elkhorn City',
    'Elkin',
    'Elkins',
    'Elkins Park',
    'Elkland',
    'Elkmont',
    'Elko',
    'Elkridge',
    'Elkton',
    'Elkview',
    'Elkville',
    'Elkwood',
    'Ellabell',
    'Ellaville',
    'Ellenboro',
    'Ellenburg Center',
    'Ellenburg Depot',
    'Ellendale',
    'Ellensburg',
    'Ellenton',
    'Ellenville',
    'Ellenwood',
    'Ellerbe',
    'Ellerslie',
    'Ellettsville',
    'Ellicott City',
    'Ellicottville',
    'Ellijay',
    'Ellington',
    'Ellinwood',
    'Elliott',
    'Elliottsburg',
    'Ellis',
    'Ellis Grove',
    'Elliston',
    'Ellisville',
    'Elloree',
    'Ellsinore',
    'Ellsworth',
    'Ellwood City',
    'Elm City',
    'Elm Creek',
    'Elm Grove',
    'Elma',
    'Elmdale',
    'Elmendorf',
    'Elmer',
    'Elmer City',
    'Elmhurst',
    'Elmira',
    'Elmo',
    'Elmont',
    'Elmora',
    'Elmore',
    'Elmore City',
    'Elmsford',
    'Elmwood',
    'Elmwood Park',
    'Elnora',
    'Elon',
    'Elora',
    'Eloy',
    'Elroy',
    'Elsa',
    'Elsah',
    'Elsberry',
    'Elsie',
    'Elsinore',
    'Elton',
    'Eltopia',
    'Elvaston',
    'Elverson',
    'Elverta',
    'Elwell',
    'Elwood',
    'Ely',
    'Elyria',
    'Elysburg',
    'Elysian',
    'Elysian Fields',
    'Embarrass',
    'Embudo',
    'Emden',
    'Emerado',
    'Emerald Isle',
    'Emerson',
    'Emery',
    'Emeryville',
    'Emigrant',
    'Emily',
    'Eminence',
    'Emlenton',
    'Emmaus',
    'Emmet',
    'Emmetsburg',
    'Emmett',
    'Emmitsburg',
    'Emmons',
    'Emory',
    'Empire',
    'Emporia',
    'Emporium',
    'Encampment',
    'Encinitas',
    'Encino',
    'Endeavor',
    'Enderlin',
    'Endicott',
    'Energy',
    'Enfield',
    'Engelhard',
    'England',
    'Englewood',
    'Englewood Cliffs',
    'Englishtown',
    'Enid',
    'Enigma',
    'Enka',
    'Ennice',
    'Ennis',
    'Enola',
    'Enon',
    'Enoree',
    'Enosburg Falls',
    'Enterprise',
    'Entiat',
    'Enumclaw',
    'Eolia',
    'Epes',
    'Ephraim',
    'Ephrata',
    'Epping',
    'Epps',
    'Epsom',
    'Epworth',
    'Equality',
    'Equinunk',
    'Erath',
    'Erbacon',
    'Erhard',
    'Erick',
    'Erie',
    'Erieville',
    'Erin',
    'Erlanger',
    'Eros',
    'Errol',
    'Erskine',
    'Erving',
    'Ervings Location',
    'Erwin',
    'Erwinna',
    'Erwinville',
    'Esbon',
    'Escalante',
    'Escalon',
    'Escanaba',
    'Escondido',
    'Esko',
    'Eskridge',
    'Esmond',
    'Esmont',
    'Espanola',
    'Esparto',
    'Esperance',
    'Essex',
    'Essex Fells',
    'Essex Junction',
    'Essexville',
    'Essie',
    'Essington',
    'Estacada',
    'Estancia',
    'Estell Manor',
    'Estelline',
    'Estero',
    'Estes Park',
    'Estherville',
    'Estherwood',
    'Estill',
    'Estill Springs',
    'Estillfork',
    'Ethel',
    'Ethelsville',
    'Ethridge',
    'Etna',
    'Etna Green',
    'Etoile',
    'Eton',
    'Etowah',
    'Etta',
    'Ettrick',
    'Eubank',
    'Eucha',
    'Euclid',
    'Eudora',
    'Eufaula',
    'Eugene',
    'Euless',
    'Eunice',
    'Eupora',
    'Eureka',
    'Eureka Springs',
    'Eustace',
    'Eustis',
    'Eutaw',
    'Eutawville',
    'Eva',
    'Evangeline',
    'Evans',
    'Evans City',
    'Evans Mills',
    'Evansdale',
    'Evanston',
    'Evansville',
    'Evant',
    'Evart',
    'Evarts',
    'Eveleth',
    'Evensville',
    'Everest',
    'Everett',
    'Everetts',
    'Everglades City',
    'Evergreen',
    'Evergreen Park',
    'Everly',
    'Everson',
    'Everton',
    'Evington',
    'Ewan',
    'Ewen',
    'Ewing',
    'Excel',
    'Excello',
    'Excelsior',
    'Excelsior Springs',
    'Exeland',
    'Exeter',
    'Exira',
    'Exline',
    'Exmore',
    'Experiment',
    'Export',
    'Exton',
    'Eyota',
    'Ezel',
    'Fabens',
    'Faber',
    'Fabius',
    'Fackler',
    'Factoryville',
    'Fair Bluff',
    'Fair Grove',
    'Fair Haven',
    'Fair Lawn',
    'Fair Oaks',
    'Fair Play',
    'Fairacres',
    'Fairbank',
    'Fairbanks',
    'Fairbanks North Star Borough',
    'Fairborn',
    'Fairburn',
    'Fairbury',
    'Fairchance',
    'Fairchild',
    'Fairchild Air Force Base',
    'Fairdale',
    'Fairdealing',
    'Fairfax',
    'Fairfax Station',
    'Fairfield',
    'Fairfield Bay',
    'Fairgrove',
    'Fairhaven',
    'Fairhope',
    'Fairland',
    'Fairlee',
    'Fairless Hills',
    'Fairmont',
    'Fairmont City',
    'Fairmount',
    'Fairplay',
    'Fairport',
    'Fairton',
    'Fairview',
    'Fairview Heights',
    'Fairview Village',
    'Faith',
    'Falcon',
    'Falconer',
    'Falfurrias',
    'Falkland',
    'Falkner',
    'Falkville',
    'Fall Branch',
    'Fall City',
    'Fall Creek',
    'Fall River',
    'Fall River Mills',
    'Fallbrook',
    'Falling Rock',
    'Falling Waters',
    'Fallon',
    'Falls',
    'Falls Church',
    'Falls City',
    'Falls Creek',
    'Falls Mills',
    'Falls Village',
    'Falls of Rough',
    'Fallsburg',
    'Fallston',
    'Falmouth',
    'Fancy Farm',
    'Fannettsburg',
    'Fanwood',
    'Far Hills',
    'Far Rockaway',
    'Farber',
    'Fargo',
    'Faribault',
    'Farina',
    'Farley',
    'Farlington',
    'Farmdale',
    'Farmer City',
    'Farmersburg',
    'Farmersville',
    'Farmerville',
    'Farmingdale',
    'Farmington',
    'Farmington Hills',
    'Farmingville',
    'Farmland',
    'Farmville',
    'Farnham',
    'Farnhamville',
    'Farragut',
    'Farrell',
    'Farson',
    'Farwell',
    'Faulkner',
    'Faulkton',
    'Fawn Grove',
    'Fawnskin',
    'Faxon',
    'Fayette',
    'Fayette City',
    'Fayetteville',
    'Fayville',
    'Feasterville-Trevose',
    'Federal Way',
    'Federalsburg',
    'Feeding Hills',
    'Felda',
    'Felicity',
    'Fellsmere',
    'Felton',
    'Fenelton',
    'Fennimore',
    'Fennville',
    'Fenton',
    'Fentress',
    'Fenwick',
    'Fenwick Island',
    'Ferdinand',
    'Fergus Falls',
    'Ferguson',
    'Fernandina Beach',
    'Ferndale',
    'Fernley',
    'Fernwood',
    'Ferriday',
    'Ferris',
    'Ferrisburgh',
    'Ferron',
    'Ferrum',
    'Ferrysburg',
    'Fertile',
    'Fessenden',
    'Festus',
    'Feura Bush',
    'Fiddletown',
    'Fidelity',
    'Fieldale',
    'Fielding',
    'Fieldon',
    'Fife Lake',
    'Fifty Lakes',
    'Filer',
    'Filley',
    'Fillmore',
    'Fincastle',
    'Finchville',
    'Findlay',
    'Finger',
    'Fingerville',
    'Finksburg',
    'Finlayson',
    'Finley',
    'Finleyville',
    'Firebaugh',
    'Firestone',
    'Firth',
    'Fischer',
    'Fish Camp',
    'Fish Creek',
    'Fish Haven',
    'Fishel',
    'Fisher',
    'Fishers',
    'Fishers Island',
    'Fishersville',
    'Fisherville',
    'Fishkill',
    'Fishtail',
    'Fisk',
    'Fiskdale',
    'Fitchburg',
    'Fitzgerald',
    'Fitzhugh',
    'Fitzpatrick',
    'Fitzwilliam',
    'Five Points',
    'Flag Pond',
    'Flagler',
    'Flagler Beach',
    'Flagstaff',
    'Flanagan',
    'Flanders',
    'Flandreau',
    'Flasher',
    'Flat Lick',
    'Flat Rock',
    'Flatgap',
    'Flatwoods',
    'Flaxton',
    'Flaxville',
    'Fleetwood',
    'Fleischmanns',
    'Fleming',
    'Flemingsburg',
    'Flemington',
    'Flensburg',
    'Fletcher',
    'Flint',
    'Flinton',
    'Flintstone',
    'Flintville',
    'Flippin',
    'Flomaton',
    'Floodwood',
    'Flora',
    'Flora Vista',
    'Florahome',
    'Floral City',
    'Floral Park',
    'Florala',
    'Florence',
    'Floresville',
    'Florham Park',
    'Florida',
    'Florien',
    'Florissant',
    'Flossmoor',
    'Flourtown',
    'Flovilla',
    'Flower Mound',
    'Floweree',
    'Flowery Branch',
    'Flowood',
    'Floyd',
    'Floydada',
    'Floyds Knobs',
    'Fluker',
    'Flushing',
    'Fly Creek',
    'Fogelsville',
    'Folcroft',
    'Foley',
    'Folkston',
    'Follansbee',
    'Follett',
    'Folly Beach',
    'Folsom',
    'Fombell',
    'Fond du Lac',
    'Fonda',
    'Fontana',
    'Fontanelle',
    'Foothill Ranch',
    'Forbes',
    'Forbes Road',
    'Ford',
    'Ford City',
    'Fordland',
    'Fordoche',
    'Fords',
    'Fordsville',
    'Fordville',
    'Fordyce',
    'Foreman',
    'Forest',
    'Forest City',
    'Forest Falls',
    'Forest Grove',
    'Forest Hill',
    'Forest Hills',
    'Forest Home',
    'Forest Junction',
    'Forest Knolls',
    'Forest Lake',
    'Forest Park',
    'Forest River',
    'Forestdale',
    'Foresthill',
    'Foreston',
    'Forestport',
    'Forestville',
    'Forgan',
    'Foristell',
    'Fork Union',
    'Forked River',
    'Forkland',
    'Forks',
    'Forksville',
    'Forman',
    'Formoso',
    'Forney',
    'Forrest',
    'Forrest City',
    'Forreston',
    'Forsyth',
    'Fort Ann',
    'Fort Apache',
    'Fort Ashby',
    'Fort Atkinson',
    'Fort Belvoir',
    'Fort Benning',
    'Fort Benton',
    'Fort Blackmore',
    'Fort Bragg',
    'Fort Branch',
    'Fort Bridger',
    'Fort Calhoun',
    'Fort Campbell North',
    'Fort Cobb',
    'Fort Collins',
    'Fort Davis',
    'Fort Defiance',
    'Fort Deposit',
    'Fort Dodge',
    'Fort Drum',
    'Fort Duchesne',
    'Fort Edward',
    'Fort Fairfield',
    'Fort Gaines',
    'Fort Garland',
    'Fort Gay',
    'Fort George Meade',
    'Fort Gibson',
    'Fort Gratiot Township',
    'Fort Greely',
    'Fort Hall',
    'Fort Irwin',
    'Fort Jennings',
    'Fort Jones',
    'Fort Kent',
    'Fort Knox',
    'Fort Lauderdale',
    'Fort Lee',
    'Fort Leonard Wood',
    'Fort Loramie',
    'Fort Loudon',
    'Fort Lupton',
    'Fort Madison',
    'Fort McCoy',
    'Fort McDowell',
    'Fort Meade',
    'Fort Mill',
    'Fort Mitchell',
    'Fort Montgomery',
    'Fort Morgan',
    'Fort Myers',
    'Fort Myers Beach',
    'Fort Oglethorpe',
    'Fort Payne',
    'Fort Pierre',
    'Fort Plain',
    'Fort Polk',
    'Fort Ransom',
    'Fort Recovery',
    'Fort Richardson',
    'Fort Riley-Camp Whiteside',
    'Fort Ripley',
    'Fort Rucker',
    'Fort Scott',
    'Fort Sill Indian School Reservation (historical)',
    'Fort Smith',
    'Fort Stewart',
    'Fort Stockton',
    'Fort Sumner',
    'Fort Thomas',
    'Fort Thompson',
    'Fort Totten',
    'Fort Valley',
    'Fort Walton Beach',
    'Fort Washakie',
    'Fort Washington',
    'Fort Wayne',
    'Fort White',
    'Fort Worth',
    'Fort Yates',
    'Fortine',
    'Fortson',
    'Fortuna',
    'Fortville',
    'Foss',
    'Fossil',
    'Fosston',
    'Foster',
    'Foster City',
    'Fosters',
    'Fostoria',
    'Fouke',
    'Fountain',
    'Fountain City',
    'Fountain Green',
    'Fountain Hills',
    'Fountain Inn',
    'Fountain Run',
    'Fountain Valley',
    'Fountaintown',
    'Fountainville',
    'Four Oaks',
    'Fowler',
    'Fowlerville',
    'Fox Island',
    'Fox Lake',
    'Fox River Grove',
    'Foxborough',
    'Foxburg',
    'Foxhome',
    'Foxworth',
    'Frackville',
    'Framingham',
    'Francestown',
    'Francesville',
    'Francisco',
    'Franconia',
    'Frankenmuth',
    'Frankewing',
    'Frankford',
    'Frankfort',
    'Franklin',
    'Franklin Grove',
    'Franklin Lakes',
    'Franklin Park',
    'Franklin Springs',
    'Franklin Square',
    'Franklinton',
    'Franklinville',
    'Frankston',
    'Franksville',
    'Frankton',
    'Franktown',
    'Frannie',
    'Fraser',
    'Frazee',
    'Frazer',
    'Frazeysburg',
    'Frazier Park',
    'Fraziers Bottom',
    'Fred',
    'Frederic',
    'Frederica',
    'Frederick',
    'Fredericksburg',
    'Fredericktown',
    'Fredonia',
    'Free Soil',
    'Free Union',
    'Freeburg',
    'Freeburn',
    'Freedom',
    'Freehold',
    'Freehold Borough',
    'Freeland',
    'Freeman',
    'Freeport',
    'Freer',
    'Freetown',
    'Freeville',
    'Fremont',
    'French Camp',
    'French Lick',
    'French Settlement',
    'French Village',
    'Frenchburg',
    'Frenchtown',
    'Frenchville',
    'Fresh Meadows',
    'Fresno',
    'Frewsburg',
    'Friant',
    'Friars Point',
    'Friday Harbor',
    'Friedens',
    'Friend',
    'Friendly',
    'Friendship',
    'Friendsville',
    'Friendswood',
    'Fries',
    'Friona',
    'Frisco',
    'Frisco City',
    'Fritch',
    'Frohna',
    'Froid',
    'Front Royal',
    'Frontenac',
    'Frost',
    'Frostburg',
    'Frostproof',
    'Fruita',
    'Fruitdale',
    'Fruitland',
    'Fruitland Park',
    'Fruitport',
    'Fruitvale',
    'Fryburg',
    'Fryeburg',
    'Ft. Pierce',
    'Ft. Washington',
    'Fulda',
    'Fullerton',
    'Fulshear',
    'Fulton',
    'Fultondale',
    'Fultonham',
    'Fultonville',
    'Fults',
    'Funkstown',
    'Fuquay-Varina',
    'Furlong',
    'Fyffe',
    'Gable',
    'Gackle',
    'Gadsden',
    'Gaffney',
    'Gagetown',
    'Gail',
    'Gaines',
    'Gainesboro',
    'Gainestown',
    'Gainesville',
    'Gaithersburg',
    'Galatia',
    'Galax',
    'Galena',
    'Galena Park',
    'Gales Ferry',
    'Galesburg',
    'Galesville',
    'Galien',
    'Galion',
    'Galivants Ferry',
    'Gallatin',
    'Gallatin Gateway',
    'Galliano',
    'Gallion',
    'Gallipolis',
    'Gallipolis Ferry',
    'Gallitzin',
    'Galloway',
    'Gallup',
    'Galt',
    'Galva',
    'Galveston',
    'Galway',
    'Gambier',
    'Gambrills',
    'Ganado',
    'Gans',
    'Gansevoort',
    'Gap',
    'Gap Mills',
    'Garards Fort',
    'Garber',
    'Garberville',
    'Garciasville',
    'Garden City',
    'Garden Grove',
    'Garden Plain',
    'Garden Prairie',
    'Garden Valley',
    'Gardena',
    'Gardendale',
    'Gardiner',
    'Gardner',
    'Gardners',
    'Gardnerville',
    'Garfield',
    'Garland',
    'Garnavillo',
    'Garner',
    'Garnerville',
    'Garnet Valley',
    'Garnett',
    'Garrard',
    'Garretson',
    'Garrett',
    'Garrettsville',
    'Garrison',
    'Garryowen',
    'Garvin',
    'Garwin',
    'Garwood',
    'Gary',
    'Garysburg',
    'Garyville',
    'Gas',
    'Gas City',
    'Gasport',
    'Gasquet',
    'Gassaway',
    'Gassville',
    'Gaston',
    'Gastonia',
    'Gastonville',
    'Gate City',
    'Gates',
    'Gates Mills',
    'Gatesville',
    'Gateway',
    'Gatlinburg',
    'Gatzke',
    'Gauley Bridge',
    'Gause',
    'Gautier',
    'Gay',
    'Gaylesville',
    'Gaylord',
    'Gaylordsville',
    'Gays Creek',
    'Gays Mills',
    'Gazelle',
    'Geary',
    'Geff',
    'Geigertown',
    'Geismar',
    'Genesee',
    'Geneseo',
    'Geneva',
    'Genoa',
    'Genoa City',
    'Gentry',
    'Gentryville',
    'George',
    'George West',
    'Georgetown',
    'Georgiana',
    'Gerald',
    'Geraldine',
    'Gerber',
    'Gering',
    'Gerlach',
    'German Valley',
    'Germansville',
    'Germanton',
    'Germantown',
    'Geronimo',
    'Gerrardstown',
    'Gerry',
    'Gervais',
    'Gettysburg',
    'Getzville',
    'Geuda Springs',
    'Geyser',
    'Geyserville',
    'Gheens',
    'Ghent',
    'Gibbon',
    'Gibbsboro',
    'Gibbstown',
    'Gibsland',
    'Gibson',
    'Gibson City',
    'Gibsonburg',
    'Gibsonia',
    'Gibsonton',
    'Gibsonville',
    'Giddings',
    'Gideon',
    'Gifford',
    'Gig Harbor',
    'Gila',
    'Gila Bend',
    'Gilbert',
    'Gilbertown',
    'Gilberts',
    'Gilbertsville',
    'Gilbertville',
    'Gilboa',
    'Gilby',
    'Gilcrest',
    'Gilford',
    'Gill',
    'Gillespie',
    'Gillett',
    'Gillett Grove',
    'Gillette',
    'Gillham',
    'Gillsville',
    'Gilman',
    'Gilman City',
    'Gilmanton',
    'Gilmanton Ironworks',
    'Gilmer',
    'Gilmore City',
    'Gilroy',
    'Gilson',
    'Gilsum',
    'Giltner',
    'Girard',
    'Girardville',
    'Girdwood',
    'Girvin',
    'Gladbrook',
    'Glade Spring',
    'Glade Valley',
    'Gladehill',
    'Gladewater',
    'Gladstone',
    'Gladwin',
    'Gladwyne',
    'Gladys',
    'Glasco',
    'Glasford',
    'Glasgow',
    'Glassboro',
    'Glassport',
    'Glastonbury',
    'Gleason',
    'Glen Allan',
    'Glen Allen',
    'Glen Arbor',
    'Glen Arm',
    'Glen Burnie',
    'Glen Campbell',
    'Glen Carbon',
    'Glen Cove',
    'Glen Daniel',
    'Glen Easton',
    'Glen Elder',
    'Glen Ellen',
    'Glen Ellyn',
    'Glen Flora',
    'Glen Fork',
    'Glen Gardner',
    'Glen Head',
    'Glen Hope',
    'Glen Lyon',
    'Glen Mills',
    'Glen Oaks',
    'Glen Ridge',
    'Glen Rock',
    'Glen Rose',
    'Glen Saint Mary',
    'Glen Spey',
    'Glen Ullin',
    'Glenallen',
    'Glenbeulah',
    'Glenbrook',
    'Glenburn',
    'Glencoe',
    'Glendale',
    'Glendale Heights',
    'Glendive',
    'Glendo',
    'Glendora',
    'Glenelg',
    'Glenfield',
    'Glenford',
    'Glengary',
    'Glenham',
    'Glenmont',
    'Glenmoore',
    'Glenmora',
    'Glenn',
    'Glenn Dale',
    'Glenns Ferry',
    'Glennville',
    'Glenolden',
    'Glenpool',
    'Glenrock',
    'Glens',
    'Glens Falls',
    'Glens Fork',
    'Glenshaw',
    'Glenside',
    'Glenview',
    'Glenvil',
    'Glenville',
    'Glenwood',
    'Glenwood City',
    'Glenwood Landing',
    'Glenwood Springs',
    'Glidden',
    'Globe',
    'Glorieta',
    'Gloster',
    'Gloucester',
    'Gloucester City',
    'Gloucester Point',
    'Glouster',
    'Glover',
    'Gloversville',
    'Glyndon',
    'Gobles',
    'Goddard',
    'Godfrey',
    'Godley',
    'Godwin',
    'Goehner',
    'Goessel',
    'Goff',
    'Goffstown',
    'Golconda',
    'Gold Bar',
    'Gold Beach',
    'Gold Canyon',
    'Gold Hill',
    'Golden',
    'Golden City',
    'Golden Eagle',
    'Golden Meadow',
    'Golden Valley',
    'Goldendale',
    'Goldenrod',
    'Goldens Bridge',
    'Goldfield',
    'Goldonna',
    'Goldsboro',
    'Goldsmith',
    'Goldston',
    'Goldthwaite',
    'Goldvein',
    'Goleta',
    'Goliad',
    'Goltry',
    'Gonvick',
    'Gonzales',
    'Goochland',
    'Good Hope',
    'Good Thunder',
    'Goode',
    'Goodells',
    'Goodfield',
    'Goodhue',
    'Gooding',
    'Goodland',
    'Goodlettsville',
    'Goodman',
    'Goodnews Bay',
    'Goodrich',
    'Goodridge',
    'Goodview',
    'Goodwater',
    'Goodwell',
    'Goodyear',
    'Goose Creek',
    'Gordo',
    'Gordon',
    'Gordonsville',
    'Gordonville',
    'Gore',
    'Gore Springs',
    'Goreville',
    'Gorham',
    'Gorin',
    'Gorman',
    'Goshen',
    'Gosport',
    'Gotha',
    'Gotham',
    'Gothenburg',
    'Gould',
    'Gouldsboro',
    'Gouverneur',
    'Gowanda',
    'Gowen',
    'Gower',
    'Gowrie',
    'Grabill',
    'Grace',
    'Grace City',
    'Gracemont',
    'Graceville',
    'Grady',
    'Gradyville',
    'Graettinger',
    'Graford',
    'Grafton',
    'Graham',
    'Grain Valley',
    'Grambling',
    'Gramercy',
    'Grammer',
    'Grampian',
    'Granada',
    'Granada Hills',
    'Granbury',
    'Granby',
    'Grand Bay',
    'Grand Blanc',
    'Grand Cane',
    'Grand Canyon',
    'Grand Coulee',
    'Grand Forks',
    'Grand Haven',
    'Grand Island',
    'Grand Isle',
    'Grand Junction',
    'Grand Lake',
    'Grand Ledge',
    'Grand Marais',
    'Grand Marsh',
    'Grand Meadow',
    'Grand Mound',
    'Grand Portage',
    'Grand Prairie',
    'Grand Rapids',
    'Grand Ridge',
    'Grand River',
    'Grand Rivers',
    'Grand Ronde',
    'Grand Saline',
    'Grand Terrace',
    'Grand Tower',
    'Grand View',
    'Grandfalls',
    'Grandfield',
    'Grandin',
    'Grandview',
    'Grandville',
    'Grandy',
    'Granger',
    'Grangeville',
    'Granite',
    'Granite Bay',
    'Granite City',
    'Granite Falls',
    'Granite Quarry',
    'Graniteville',
    'Grant',
    'Grant City',
    'Grant Park',
    'Grantham',
    'Granton',
    'Grants',
    'Grants Pass',
    'Grantsboro',
    'Grantsburg',
    'Grantsville',
    'Grantville',
    'Granville',
    'Granville Summit',
    'Grapeland',
    'Grapeview',
    'Grapevine',
    'Grasonville',
    'Grass Lake',
    'Grass Valley',
    'Grassflat',
    'Grassy Creek',
    'Grassy Meadows',
    'Gratiot',
    'Gratis',
    'Graton',
    'Gratz',
    'Gravel Switch',
    'Gravelly',
    'Gravette',
    'Gravity',
    'Gravois Mills',
    'Grawn',
    'Gray',
    'Gray Court',
    'Gray Summit',
    'Grayland',
    'Grayling',
    'Grayslake',
    'Grayson',
    'Graysville',
    'Graytown',
    'Grayville',
    'Great Barrington',
    'Great Bend',
    'Great Cacapon',
    'Great Falls',
    'Great Lakes',
    'Great Meadows',
    'Great Mills',
    'Great Neck',
    'Great River',
    'Greater Capitol Heights',
    'Greeley',
    'Greeleyville',
    'Green',
    'Green Bank',
    'Green Bay',
    'Green Castle',
    'Green City',
    'Green Cove Springs',
    'Green Forest',
    'Green Isle',
    'Green Lake',
    'Green Lane',
    'Green Mountain',
    'Green Mountain Falls',
    'Green Pond',
    'Green River',
    'Green Sea',
    'Green Springs',
    'Green Valley',
    'Green Village',
    'Greenacres',
    'Greenback',
    'Greenbackville',
    'Greenbank',
    'Greenbelt',
    'Greenbrae',
    'Greenbrier',
    'Greenbush',
    'Greencastle',
    'Greendale',
    'Greene',
    'Greeneville',
    'Greenfield',
    'Greenland',
    'Greenlawn',
    'Greenleaf',
    'Greenock',
    'Greenport',
    'Greens Farms',
    'Greensboro',
    'Greensburg',
    'Greentop',
    'Greentown',
    'Greenup',
    'Greenvale',
    'Greenview',
    'Greenville',
    'Greenway Downs',
    'Greenwell Springs',
    'Greenwich',
    'Greenwood',
    'Greenwood Lake',
    'Greenwood Springs',
    'Greenwood Village',
    'Greer',
    'Gregory',
    'Grelton',
    'Grenada',
    'Grenloch',
    'Grenville',
    'Gresham',
    'Grethel',
    'Gretna',
    'Grey Eagle',
    'Greybull',
    'Greycliff',
    'Gridley',
    'Griffin',
    'Griffith',
    'Griffithsville',
    'Griffithville',
    'Grifton',
    'Griggsville',
    'Grimes',
    'Grimesland',
    'Grimsley',
    'Grindstone',
    'Grinnell',
    'Griswold',
    'Groesbeck',
    'Groom',
    'Grosse Ile',
    'Grosse Pointe',
    'Grosse Tete',
    'Grosvenor Dale',
    'Groton',
    'Grottoes',
    'Grouse Creek',
    'Grove',
    'Grove City',
    'Grove Hill',
    'Grove Oak',
    'Groveland',
    'Groveport',
    'Grover',
    'Grover Beach',
    'Grover Hill',
    'Grovertown',
    'Groves',
    'Groveton',
    'Grovetown',
    'Gruetli-Laager',
    'Grundy',
    'Grundy Center',
    'Gruver',
    'Grygla',
    'Guadalupe',
    'Gualala',
    'Guatay',
    'Guerneville',
    'Guernsey',
    'Gueydan',
    'Guffey',
    'Guide Rock',
    'Guilderland',
    'Guilford',
    'Guin',
    'Guinda',
    'Gulf Breeze',
    'Gulf Shores',
    'Gulfport',
    'Gully',
    'Gum Spring',
    'Gunlock',
    'Gunnison',
    'Gunpowder',
    'Gunter',
    'Guntersville',
    'Guntown',
    'Gurley',
    'Gurnee',
    'Gustine',
    'Guston',
    'Guthrie',
    'Guthrie Center',
    'Guttenberg',
    'Guy',
    'Guymon',
    'Guys',
    'Guys Mills',
    'Guysville',
    'Guyton',
    'Gwinn',
    'Gwinner',
    'Gwynedd',
    'Gwynedd Valley',
    'Gwynn Oak',
    'Gypsum',
    'Gypsy',
    "Ha'iku",
    'Hacienda Heights',
    'Hackberry',
    'Hackensack',
    'Hackett',
    'Hackettstown',
    'Haddam',
    'Haddock',
    'Haddon Heights',
    'Haddonfield',
    'Hadley',
    'Hadlyme',
    'Hagan',
    'Hager City',
    'Hagerhill',
    'Hagerman',
    'Hagerstown',
    'Hahira',
    'Hahnville',
    'Haigler',
    'Hailey',
    'Haines',
    'Haines City',
    'Hainesport',
    'Hakalau',
    'Halbur',
    'Hale',
    "Hale'iwa",
    'Haledon',
    'Hales Corners',
    'Halethorpe',
    'Haleyville',
    'Half Moon Bay',
    'Halfway',
    'Halifax',
    'Hall',
    'Hallam',
    'Hallandale',
    'Hallett',
    'Hallettsville',
    'Halliday',
    'Hallie',
    'Hallieford',
    'Hallock',
    'Hallowell',
    'Halls',
    'Hallstead',
    'Hallsville',
    'Halltown',
    'Hallwood',
    'Halma',
    'Halsey',
    'Halstad',
    'Halstead',
    'Haltom City',
    'Hambleton',
    'Hamburg',
    'Hamden',
    'Hamel',
    'Hamer',
    'Hamersville',
    'Hamilton',
    'Hamler',
    'Hamlet',
    'Hamlin',
    'Hammon',
    'Hammond',
    'Hammondsport',
    'Hammonton',
    'Hampden',
    'Hampden Sydney',
    'Hampshire',
    'Hampstead',
    'Hampton',
    'Hampton Bays',
    'Hampton Falls',
    'Hamptonville',
    'Hamtramck',
    'Hana',
    'Hanalei',
    'Hanapepe',
    'Hanceville',
    'Hancock',
    'Hancocks Bridge',
    'Hanford',
    'Hankamer',
    'Hankinson',
    'Hanksville',
    'Hanley Falls',
    'Hanlontown',
    'Hanna',
    'Hanna City',
    'Hannacroix',
    'Hannah',
    'Hannibal',
    'Hanover',
    'Hanover Park',
    'Hanoverton',
    'Hanska',
    'Hanson',
    'Hansville',
    'Happy',
    'Happy Camp',
    'Happy Jack',
    'Happy Valley',
    'Harbeson',
    'Harbor Beach',
    'Harbor City',
    'Harbor Springs',
    'Harborcreek',
    'Harborside',
    'Harborton',
    'Hardeeville',
    'Hardin',
    'Hardinsburg',
    'Hardtner',
    'Hardwick',
    'Hardy',
    'Hardyville',
    'Harford',
    'Harker Heights',
    'Harlan',
    'Harlem',
    'Harleton',
    'Harleysville',
    'Harlingen',
    'Harlowton',
    'Harmans',
    'Harmon',
    'Harmonsburg',
    'Harmony',
    'Harned',
    'Harold',
    'Harper',
    'Harper Woods',
    'Harpers Ferry',
    'Harpersville',
    'Harpster',
    'Harpswell',
    'Harpursville',
    'Harrah',
    'Harrells',
    'Harriet',
    'Harrietta',
    'Harriman',
    'Harrington',
    'Harrington Park',
    'Harris',
    'Harrisburg',
    'Harrison',
    'Harrison City',
    'Harrison Valley',
    'Harrisonburg',
    'Harrisonville',
    'Harrisville',
    'Harrod',
    'Harrodsburg',
    'Harrogate',
    'Harrold',
    'Harshaw',
    'Hart',
    'Hartfield',
    'Hartford',
    'Hartford City',
    'Hartington',
    'Hartland',
    'Hartley',
    'Hartline',
    'Hartly',
    'Hartman',
    'Harts',
    'Hartsburg',
    'Hartsdale',
    'Hartsel',
    'Hartselle',
    'Hartsfield',
    'Hartshorne',
    'Hartstown',
    'Hartsville',
    'Hartville',
    'Hartwell',
    'Hartwick',
    'Hartwood',
    'Harvard',
    'Harvest',
    'Harvey',
    'Harveys Lake',
    'Harveyville',
    'Harviell',
    'Harwich',
    'Harwich Port',
    'Harwick',
    'Harwinton',
    'Harwood',
    'Harwood Heights',
    'Hasbrouck Heights',
    'Haskell',
    'Haskins',
    'Haslet',
    'Haslett',
    'Hastings',
    'Hastings-on-Hudson',
    'Hasty',
    'Hat Creek',
    'Hatboro',
    'Hatch',
    'Hatfield',
    'Hathaway Pines',
    'Hathorne',
    'Hatley',
    'Hattiesburg',
    'Hattieville',
    "Hau'ula",
    'Haubstadt',
    'Haughton',
    'Hauppauge',
    'Havana',
    'Havelock',
    'Haven',
    'Havensville',
    'Haverford',
    'Haverhill',
    'Haverstraw',
    'Havertown',
    'Haviland',
    'Havre',
    'Havre de Grace',
    'Haw River',
    'Hawaiian Gardens',
    'Hawaiian Ocean View',
    'Hawarden',
    'Hawesville',
    'Hawkeye',
    'Hawkins',
    'Hawkinsville',
    'Hawley',
    'Haworth',
    'Hawthorn',
    'Hawthorne',
    'Haxtun',
    'Hay',
    'Hay Springs',
    'Hayden',
    'Haydenville',
    'Hayes',
    'Hayesville',
    'Hayfield',
    'Hayfork',
    'Haymarket',
    'Haynesville',
    'Hayneville',
    'Hays',
    'Haysi',
    'Haysville',
    'Hayti',
    'Hayward',
    'Hazard',
    'Hazel',
    'Hazel Crest',
    'Hazel Green',
    'Hazel Park',
    'Hazelhurst',
    'Hazelton',
    'Hazelwood',
    'Hazen',
    'Hazlehurst',
    'Hazlet',
    'Hazleton',
    'Head of Westport',
    'Headland',
    'Healdsburg',
    'Healdton',
    'Healy',
    'Hearne',
    'Heath',
    'Heath Springs',
    'Heathsville',
    'Heavener',
    'Hebbronville',
    'Heber',
    'Heber Springs',
    'Hebron',
    'Hector',
    'Hedgesville',
    'Hedrick',
    'Heflin',
    'Hegins',
    'Heidelberg',
    'Heidrick',
    'Heiskell',
    'Helen',
    'Helena',
    'Helendale',
    'Helenwood',
    'Helix',
    'Hellertown',
    'Helmetta',
    'Helotes',
    'Helper',
    'Helvetia',
    'Hematite',
    'Hemet',
    'Hemingford',
    'Hemingway',
    'Hemlock',
    'Hemphill',
    'Hempstead',
    'Henagar',
    'Henderson',
    'Hendersonville',
    'Hendricks',
    'Hendrix',
    'Henefer',
    'Hennepin',
    'Hennessey',
    'Henniker',
    'Henning',
    'Henrico',
    'Henrietta',
    'Henry',
    'Henryetta',
    'Henryville',
    'Hensley',
    'Hephzibah',
    'Hepler',
    'Heppner',
    'Hepzibah',
    'Herald',
    'Herculaneum',
    'Hercules',
    'Hereford',
    'Herington',
    'Herkimer',
    'Herlong',
    'Herman',
    'Hermann',
    'Hermansville',
    'Hermanville',
    'Herminie',
    'Hermiston',
    'Hermitage',
    'Hermon',
    'Hermosa',
    'Hermosa Beach',
    'Hernando',
    'Herndon',
    'Hernshaw',
    'Herod',
    'Heron',
    'Heron Lake',
    'Herrick',
    'Herriman',
    'Herrin',
    'Hershey',
    'Hertford',
    'Hesperia',
    'Hesperus',
    'Hessmer',
    'Hesston',
    'Hettick',
    'Hettinger',
    'Heuvelton',
    'Hewett',
    'Hewitt',
    'Hewlett',
    'Heyburn',
    'Heyworth',
    'Hialeah',
    'Hiawassee',
    'Hiawatha',
    'Hibbing',
    'Hibbs',
    'Hickman',
    'Hickory',
    'Hickory Corners',
    'Hickory Flat',
    'Hickory Hills',
    'Hickory Valley',
    'Hicksville',
    'Hico',
    'Hidalgo',
    'Hidden Valley Lake',
    'Hiddenite',
    'Higden',
    'Higdon',
    'Higganum',
    'Higgins',
    'Higginsville',
    'High Bridge',
    'High Falls',
    'High Island',
    'High Point',
    'High Ridge',
    'High Springs',
    'High View',
    'Highland',
    'Highland Falls',
    'Highland Home',
    'Highland Lakes',
    'Highland Mills',
    'Highland Park',
    'Highland Springs',
    'Highlands',
    'Highlandville',
    'Highmore',
    'Highspire',
    'Hightstown',
    'Highwood',
    'Higley',
    'Hiko',
    'Hilbert',
    'Hildale',
    'Hildebran',
    'Hildreth',
    'Hilham',
    'Hill',
    'Hill City',
    'Hillburn',
    'Hilliard',
    'Hillister',
    'Hillman',
    'Hills',
    'Hillsboro',
    'Hillsborough',
    'Hillsdale',
    'Hillside',
    'Hillsville',
    'Hilltop',
    'Hillview',
    'Hilmar',
    'Hilo',
    'Hilton',
    'Hilton Head Island',
    'Himrod',
    'Hinckley',
    'Hindman',
    'Hindsboro',
    'Hindsville',
    'Hines',
    'Hinesburg',
    'Hinesville',
    'Hingham',
    'Hinkley',
    'Hinsdale',
    'Hinton',
    'Hiram',
    'Hitchcock',
    'Hitterdal',
    'Hiwasse',
    'Hixson',
    'Hixton',
    "Ho'olehua",
    'Ho-Ho-Kus',
    'Hoagland',
    'Hobart',
    'Hobbs',
    'Hobbsville',
    'Hobe Sound',
    'Hoboken',
    'Hobson',
    'Hockessin',
    'Hockley',
    'Hodge',
    'Hodgen',
    'Hodgenville',
    'Hodges',
    'Hoffman',
    'Hoffman Estates',
    'Hogansburg',
    'Hogansville',
    'Hohenwald',
    'Hoisington',
    'Hokah',
    'Holabird',
    'Holbrook',
    'Holcomb',
    'Holcombe',
    'Holden',
    'Holdenville',
    'Holderness',
    'Holdingford',
    'Holdrege',
    'Holgate',
    'Holiday',
    'Holland',
    'Holland Patent',
    'Hollandale',
    'Holley',
    'Holliday',
    'Hollidaysburg',
    'Hollis',
    'Hollis Center',
    'Hollister',
    'Holliston',
    'Holloman Air Force Base',
    'Hollow Rock',
    'Holloway',
    'Hollsopple',
    'Holly',
    'Holly Grove',
    'Holly Hill',
    'Holly Pond',
    'Holly Ridge',
    'Holly Springs',
    'Hollywood',
    'Holmdel',
    'Holmen',
    'Holmes',
    'Holmesville',
    'Holstein',
    'Holt',
    'Holton',
    'Holts Summit',
    'Holtsville',
    'Holtville',
    'Holtwood',
    'Holualoa',
    'Holy Cross',
    'Holyoke',
    'Holyrood',
    'Home',
    'Homedale',
    'Homeland',
    'Homer',
    'Homer City',
    'Homer Glen',
    'Homerville',
    'Homestead',
    'Hometown',
    'Homewood',
    'Homeworth',
    'Hominy',
    'Homosassa',
    'Honaker',
    'Honaunau',
    'Hondo',
    'Honea Path',
    'Honeoye',
    'Honeoye Falls',
    'Honesdale',
    'Honey Brook',
    'Honey Creek',
    'Honey Grove',
    'Honeydew',
    'Honeyville',
    "Honoka'a",
    'Honolulu',
    'Honor',
    'Hood River',
    'Hoodsport',
    'Hooker',
    'Hooks',
    'Hooksett',
    'Hookstown',
    'Hooper',
    'Hooper Bay',
    'Hoopeston',
    'Hoople',
    'Hoosick',
    'Hoosick Falls',
    'Hooversville',
    'Hop Bottom',
    'Hopatcong',
    'Hope',
    'Hope Hull',
    'Hope Mills',
    'Hope Valley',
    'Hopedale',
    'Hopewell',
    'Hopewell Junction',
    'Hopkins',
    'Hopkinsville',
    'Hopkinton',
    'Hopland',
    'Hopwood',
    'Hoquiam',
    'Horatio',
    'Horicon',
    'Horn Lake',
    'Hornbeak',
    'Hornbeck',
    'Hornell',
    'Horner',
    'Hornick',
    'Horntown',
    'Horse Branch',
    'Horse Cave',
    'Horse Shoe',
    'Horseheads',
    'Horseshoe Bay',
    'Horseshoe Bend',
    'Horsham',
    'Hortense',
    'Horton',
    'Hortonville',
    'Hoschton',
    'Hosford',
    'Hoskins',
    'Hoskinston',
    'Hosmer',
    'Hospers',
    'Hosston',
    'Hostetter',
    'Hot Springs',
    'Hot Springs National Park',
    'Hot Springs Village',
    'Hotchkiss',
    'Houghton',
    'Houghton Lake',
    'Houlka',
    'Houlton',
    'Houma',
    'House Springs',
    'Houston',
    'Houtzdale',
    'Hoven',
    'Hovland',
    'Howard',
    'Howard Beach',
    'Howard City',
    'Howard Lake',
    'Howardsville',
    'Howe',
    'Howell',
    'Howes Cave',
    'Howie In The Hills',
    'Howland',
    'Hoxie',
    'Hoyleton',
    'Hoyt',
    'Hoyt Lakes',
    'Huachuca City',
    'Hubbard',
    'Hubbard Lake',
    'Hubbardston',
    'Hubbardsville',
    'Hubbell',
    'Hubert',
    'Hubertus',
    'Huddleston',
    'Huddy',
    'Hudson',
    'Hudson Falls',
    'Hudsonville',
    'Huffman',
    'Huger',
    'Hughes',
    'Hughes Springs',
    'Hugheston',
    'Hughesville',
    'Hughson',
    'Hugo',
    'Hugoton',
    'Hulbert',
    'Hulett',
    'Hull',
    'Humansville',
    'Humarock',
    'Humbird',
    'Humble',
    'Humboldt',
    'Hume',
    'Humeston',
    'Hummelstown',
    'Humphrey',
    'Hundred',
    'Hungerford',
    'Hunker',
    'Hunlock Creek',
    'Hunt',
    'Hunt Valley',
    'Hunter',
    'Huntersville',
    'Huntertown',
    'Huntingburg',
    'Huntingdon',
    'Huntingdon Valley',
    'Huntington',
    'Huntington Beach',
    'Huntington Mills',
    'Huntington Park',
    'Huntington Station',
    'Huntington Woods',
    'Huntingtown',
    'Huntland',
    'Huntley',
    'Huntsburg',
    'Huntsville',
    'Hurdland',
    'Hurdle Mills',
    'Hurley',
    'Hurleyville',
    'Hurlock',
    'Huron',
    'Hurricane',
    'Hurst',
    'Hurt',
    'Hurtsboro',
    'Huson',
    'Hustisford',
    'Hustler',
    'Hustontown',
    'Hustonville',
    'Husum',
    'Hutchins',
    'Hutchinson',
    'Huttig',
    'Hutto',
    'Huttonsville',
    'Huxley',
    'Hyampom',
    'Hyannis',
    'Hyattsville',
    'Hyde',
    'Hyde Park',
    'Hyden',
    'Hydes',
    'Hydesville',
    'Hydeville',
    'Hydro',
    'Hygiene',
    'Hymera',
    'Hyndman',
    'Hyrum',
    'Hysham',
    'Iaeger',
    'Iberia',
    'Ickesburg',
    'Ida',
    'Ida Grove',
    'Idabel',
    'Idaho Falls',
    'Idaho Springs',
    'Idalia',
    'Idalou',
    'Idaville',
    'Ider',
    'Idledale',
    'Idyllwild',
    'Ignacio',
    'Igo',
    'Ijamsville',
    'Iliff',
    'Ilion',
    'Imlay City',
    'Imler',
    'Immaculata',
    'Immokalee',
    'Imogene',
    'Imperial',
    'Imperial Beach',
    'Ina',
    'Inchelium',
    'Incline Village',
    'Independence',
    'India',
    'Indialantic',
    'Indian Head',
    'Indian Hills',
    'Indian Lake',
    'Indian Mound',
    'Indian Orchard',
    'Indian River',
    'Indian Rocks Beach',
    'Indian Trail',
    'Indian Wells',
    'Indiana',
    'Indianapolis',
    'Indianola',
    'Indiantown',
    'Indio',
    'Industry',
    'Inez',
    'Ingalls',
    'Ingleside',
    'Inglewood',
    'Inglis',
    'Ingomar',
    'Ingraham',
    'Ingram',
    'Inkom',
    'Inkster',
    'Inland',
    'Inman',
    'Inola',
    'Institute',
    'Intercession City',
    'Intercourse',
    'Interlachen',
    'Interlaken',
    'Interlochen',
    'International Falls',
    'Inver Grove Heights',
    'Inverness',
    'Inwood',
    'Inyokern',
    'Iola',
    'Ione',
    'Ionia',
    'Iota',
    'Iowa',
    'Iowa City',
    'Iowa Falls',
    'Iowa Park',
    'Ipswich',
    'Iraan',
    'Irasburg',
    'Iredell',
    'Ireland',
    'Irene',
    'Ireton',
    'Irma',
    'Irmo',
    'Iron City',
    'Iron Junction',
    'Iron Mountain',
    'Iron Ridge',
    'Iron River',
    'Iron Station',
    'Irons',
    'Ironton',
    'Ironwood',
    'Iroquois',
    'Irrigon',
    'Irvine',
    'Irving',
    'Irvington',
    'Irwin',
    'Irwinton',
    'Isabel',
    'Isabella',
    'Isanti',
    'Iselin',
    'Ishpeming',
    'Islamorada',
    'Island',
    'Island Falls',
    'Island Heights',
    'Island Lake',
    'Island Park',
    'Islandia',
    'Islandton',
    'Isle',
    'Isle of Palms',
    'Isle of Wight',
    'Islesboro',
    'Islesford',
    'Isleta',
    'Isleton',
    'Islip',
    'Islip Terrace',
    'Ismay',
    'Issaquah',
    'Italia',
    'Italy',
    'Itasca',
    'Ithaca',
    'Itmann',
    'Itta Bena',
    'Iuka',
    'Iva',
    'Ivanhoe',
    'Ivel',
    'Ivins',
    'Ivor',
    'Ivoryton',
    'Ivydale',
    'Ixonia',
    'Jack',
    'Jackpot',
    'Jacks Creek',
    'Jacksboro',
    'Jackson',
    'Jackson Center',
    'Jackson Heights',
    'Jackson Township',
    'Jacksonboro',
    'Jacksonburg',
    'Jacksons Gap',
    'Jacksonville',
    'Jacksonville Beach',
    'Jacobs Creek',
    'Jacobsburg',
    'Jacobson',
    'Jacumba Hot Springs',
    'Jaffrey',
    'Jal',
    'Jamaica',
    'Jamaica Plain',
    'Jameson',
    'Jamesport',
    'Jamestown',
    'Jamesville',
    'Jamison',
    'Jamul',
    'Jane Lew',
    'Janesville',
    'Jaroso',
    'Jarreau',
    'Jarrell',
    'Jarrettsville',
    'Jarvisburg',
    'Jasonville',
    'Jasper',
    'Jay',
    'Jayess',
    'Jayton',
    'Jean',
    'Jeanerette',
    'Jeannette',
    'Jeddo',
    'Jeff',
    'Jeffers',
    'Jefferson',
    'Jefferson City',
    'Jefferson Valley',
    'Jeffersonton',
    'Jeffersonville',
    'Jeffrey',
    'Jekyll Island',
    'Jellico',
    'Jemez Pueblo',
    'Jemez Springs',
    'Jemison',
    'Jena',
    'Jenison',
    'Jenkins',
    'Jenkinsburg',
    'Jenkinsville',
    'Jenkintown',
    'Jenks',
    'Jenner',
    'Jennerstown',
    'Jennings',
    'Jensen Beach',
    'Jeremiah',
    'Jericho',
    'Jerico Springs',
    'Jermyn',
    'Jerome',
    'Jersey',
    'Jersey City',
    'Jersey Shore',
    'Jerseyville',
    'Jerusalem',
    'Jessup',
    'Jesup',
    'Jet',
    'Jetersville',
    'Jetmore',
    'Jewell',
    'Jewett',
    'Jewett City',
    'Jim Falls',
    'Jim Thorpe',
    'Joanna',
    'Joaquin',
    'Jobstown',
    'Joelton',
    'Joes',
    'Johannesburg',
    'John Day',
    'Johns Island',
    'Johnson',
    'Johnson City',
    'Johnson Creek',
    'Johnsonburg',
    'Johnsonville',
    'Johnston',
    'Johnston City',
    'Johnstown',
    'Joice',
    'Joiner',
    'Joliet',
    'Jolon',
    'Jones',
    'Jonesboro',
    'Jonesborough',
    'Jonesburg',
    'Jonesport',
    'Jonestown',
    'Jonesville',
    'Joplin',
    'Joppa',
    'Jordan',
    'Jordan Valley',
    'Joseph',
    'Josephine',
    'Joshua',
    'Joshua Tree',
    'Jourdanton',
    'Joy',
    'Jud',
    'Juda',
    'Judith Gap',
    'Judsonia',
    'Julesburg',
    'Juliaetta',
    'Julian',
    'Juliette',
    'Jumping Branch',
    'Junction',
    'Junction City',
    'June Lake',
    'Juneau',
    'Juniata',
    'Junior',
    'Juno Beach',
    'Jupiter',
    'Justice',
    'Justin',
    "Ka'a'awa",
    'Kadoka',
    'Kahoka',
    'Kahuku',
    'Kahului',
    'Kailua',
    'Kaiser',
    'Kake',
    'Kaktovik',
    'Kalaheo',
    'Kalama',
    'Kalamazoo',
    'Kaleva',
    'Kalida',
    'Kalispell',
    'Kalkaska',
    'Kalona',
    'Kalskag',
    'Kamas',
    'Kamiah',
    'Kampsville',
    'Kanab',
    'Kanawha',
    'Kandiyohi',
    'Kane',
    "Kane'ohe",
    'Kaneville',
    'Kankakee',
    'Kannapolis',
    'Kanopolis',
    'Kansas',
    'Kansas City',
    'Kansasville',
    "Kapa'a",
    'Kapaau',
    'Kaplan',
    'Kapolei',
    'Karlstad',
    'Karnack',
    'Karnak',
    'Karnes City',
    'Karns City',
    'Karthaus',
    'Karval',
    'Kasota',
    'Kasson',
    'Kathleen',
    'Katonah',
    'Katy',
    'Kaufman',
    'Kaukauna',
    'Kaumakani',
    'Kaunakakai',
    'Kauneonga Lake',
    'Kaw City',
    'Kaweah',
    'Kawkawlin',
    'Kaycee',
    'Kayenta',
    'Kaysville',
    "Kea'au",
    'Kealakekua',
    'Keams Canyon',
    'Keansburg',
    'Kearney',
    'Kearneysville',
    'Kearny',
    'Keasbey',
    'Keatchie',
    'Keauhou',
    'Keavy',
    'Kechi',
    'Keedysville',
    'Keego Harbor',
    'Keene',
    'Keene Valley',
    'Keenesburg',
    'Keeseville',
    'Keewatin',
    'Keezletown',
    'Kegley',
    'Keithville',
    'Keizer',
    'Kekaha',
    'Kelayres',
    'Kelford',
    'Keller',
    'Kellerton',
    'Kelley',
    'Kelliher',
    'Kellogg',
    'Kelly',
    'Kellyton',
    'Kellyville',
    'Kelseyville',
    'Kelso',
    'Kemah',
    'Kemmerer',
    'Kemp',
    'Kempner',
    'Kempton',
    'Kenai',
    'Kenansville',
    'Kenbridge',
    'Kendalia',
    'Kendall',
    'Kendall Park',
    'Kendallville',
    'Kendrick',
    'Kenduskeag',
    'Kenedy',
    'Kenesaw',
    'Kenilworth',
    'Kenly',
    'Kenmare',
    'Kenmore',
    'Kenna',
    'Kennan',
    'Kennard',
    'Kennebec',
    'Kennebunk',
    'Kennebunkport',
    'Kennedale',
    'Kennedy',
    'Kennedyville',
    'Kenner',
    'Kennerdell',
    'Kennesaw',
    'Kennett',
    'Kennett Square',
    'Kennewick',
    'Kenney',
    'Keno',
    'Kenosha',
    'Kenova',
    'Kensett',
    'Kensington',
    'Kent',
    'Kent City',
    'Kentfield',
    'Kentland',
    'Kenton',
    'Kents Hill',
    'Kents Store',
    'Kentwood',
    'Kenvil',
    'Kenyon',
    'Keokuk',
    'Keosauqua',
    'Keota',
    'Kerby',
    'Kerens',
    'Kerhonkson',
    'Kerkhoven',
    'Kerman',
    'Kermit',
    'Kernersville',
    'Kernville',
    'Kerrick',
    'Kerrville',
    'Kersey',
    'Kershaw',
    'Keshena',
    'Keswick',
    'Ketchikan',
    'Ketchum',
    'Kettle Falls',
    'Kettle Island',
    'Kettleman City',
    'Keuka Park',
    'Kevil',
    'Kevin',
    'Kew Gardens',
    'Kewadin',
    'Kewanee',
    'Kewanna',
    'Kewaskum',
    'Kewaunee',
    'Key Biscayne',
    'Key Largo',
    'Key West',
    'Keyes',
    'Keymar',
    'Keyport',
    'Keyser',
    'Keystone',
    'Keystone Heights',
    'Keysville',
    'Keytesville',
    'Kiamesha Lake',
    'Kiana',
    'Kiefer',
    'Kiel',
    'Kieler',
    'Kiester',
    'Kihei',
    'Kila',
    'Kilauea',
    'Kilbourne',
    'Kilgore',
    'Kilkenny',
    'Kilkenny Township',
    'Kill Devil Hills',
    'Killbuck',
    'Killdeer',
    'Killeen',
    'Killen',
    'Killington',
    'Killingworth',
    'Kilmarnock',
    'Kilmichael',
    'Kiln',
    'Kimball',
    'Kimballton',
    'Kimberling City',
    'Kimberly',
    'Kimberton',
    'Kimbolton',
    'Kimmell',
    'Kimper',
    'Kincaid',
    'Kinde',
    'Kinder',
    'Kinderhook',
    'Kindred',
    'King',
    'King City',
    'King Cove',
    'King George',
    'King Salmon',
    'King William',
    'King and Queen Court House',
    'King of Prussia',
    'Kingdom City',
    'Kingfield',
    'Kingfisher',
    'Kingman',
    'Kings Bay Base',
    'Kings Beach',
    'Kings Mills',
    'Kings Mountain',
    'Kings Park',
    'Kingsburg',
    'Kingsbury',
    'Kingsford',
    'Kingsland',
    'Kingsley',
    'Kingsport',
    'Kingston',
    'Kingston Springs',
    'Kingstree',
    'Kingsville',
    'Kingwood',
    'Kinmundy',
    'Kinnear',
    'Kinsley',
    'Kinsman',
    'Kinston',
    'Kinta',
    'Kintnersville',
    'Kintyre',
    'Kinzers',
    'Kiowa',
    'Kipling',
    'Kipnuk',
    'Kirby',
    'Kirbyville',
    'Kirk',
    'Kirkland',
    'Kirklin',
    'Kirksey',
    'Kirksville',
    'Kirkville',
    'Kirkwood',
    'Kiron',
    'Kirtland',
    'Kirwin',
    'Kismet',
    'Kissee Mills',
    'Kissimmee',
    'Kite',
    'Kittanning',
    'Kittery',
    'Kittery Point',
    'Kitts Hill',
    'Kitty Hawk',
    'Klamath',
    'Klamath Falls',
    'Klamath River',
    'Klawock',
    'Klemme',
    'Knapp',
    'Knife River',
    'Knightdale',
    'Knights Landing',
    'Knightsen',
    'Knightstown',
    'Knightsville',
    'Knippa',
    'Knob Lick',
    'Knob Noster',
    'Knott',
    'Knox',
    'Knox City',
    'Knox Dale',
    'Knoxville',
    'Kodak',
    'Kodiak',
    'Kohler',
    'Kokomo',
    'Koloa',
    'Konawa',
    'Kongiganak',
    'Kooskia',
    'Koppel',
    'Kopperl',
    'Kosciusko',
    'Kotlik',
    'Kountze',
    'Kouts',
    'Koyuk',
    'Krakow',
    'Kramer',
    'Krebs',
    'Kremmling',
    'Krum',
    'Kula',
    'Kulm',
    'Kulpmont',
    'Kuna',
    'Kunkletown',
    'Kure Beach',
    'Kurtistown',
    'Kuttawa',
    'Kutztown',
    'Kwethluk',
    'Kykotsmovi Village',
    'Kyle',
    'Kyles Ford',
    "L'Anse",
    'La Barge',
    'La Belle',
    'La Blanca',
    'La Canada Flintridge',
    'La Center',
    'La Conner',
    'La Crescent',
    'La Crescenta',
    'La Crosse',
    'La Cygne',
    'La Farge',
    'La Fayette',
    'La Feria',
    'La Fontaine',
    'La Fox',
    'La Grande',
    'La Grange',
    'La Grange Park',
    'La Habra',
    'La Harpe',
    'La Honda',
    'La Jara',
    'La Jolla',
    'La Joya',
    'La Junta',
    'La Luz',
    'La Marque',
    'La Mesa',
    'La Mirada',
    'La Moille',
    'La Motte',
    'La Palma',
    'La Pine',
    'La Plata',
    'La Pointe',
    'La Porte',
    'La Porte City',
    'La Prairie',
    'La Pryor',
    'La Puente',
    'La Quinta',
    'La Rue',
    'La Sal',
    'La Salle',
    'La Valle',
    'La Vergne',
    'La Verne',
    'La Vernia',
    'La Veta',
    'La Vista',
    "La'ie",
    'LaBelle',
    'LaBolt',
    'LaFayette',
    'LaFollette',
    'LaMoure',
    'LaSalle',
    'LaVerkin',
    'Labadie',
    'Labadieville',
    'Lac du Flambeau',
    'Lacey',
    'Laceys Spring',
    'Laceyville',
    'Lachine',
    'Lackawaxen',
    'Lackey',
    'Lacombe',
    'Lacon',
    'Lacona',
    'Laconia',
    'Ladd',
    'Laddonia',
    'Ladera Ranch',
    'Ladoga',
    'Ladonia',
    'Ladora',
    'Ladson',
    'Lady Lake',
    'Ladysmith',
    'Lafayette',
    'Lafferty',
    'Lafitte',
    'Lagrange',
    'Lagrangeville',
    'Lagro',
    'Laguna',
    'Laguna Beach',
    'Laguna Hills',
    'Laguna Niguel',
    'Laguna Woods',
    'Lagunitas',
    'Lahaina',
    'Lahaska',
    'Laingsburg',
    'Lake',
    'Lake Alfred',
    'Lake Andes',
    'Lake Ann',
    'Lake Ariel',
    'Lake Arrowhead',
    'Lake Arthur',
    'Lake Benton',
    'Lake Bluff',
    'Lake Bronson',
    'Lake Buena Vista',
    'Lake Butler',
    'Lake Charles',
    'Lake City',
    'Lake Cormorant',
    'Lake Crystal',
    'Lake Dallas',
    'Lake Delton',
    'Lake Elmo',
    'Lake Elsinore',
    'Lake Forest',
    'Lake Geneva',
    'Lake George',
    'Lake Grove',
    'Lake Hamilton',
    'Lake Harmony',
    'Lake Havasu City',
    'Lake Helen',
    'Lake Hiawatha',
    'Lake Hopatcong',
    'Lake Hughes',
    'Lake Isabella',
    'Lake Jackson',
    'Lake Junaluska',
    'Lake Katrine',
    'Lake Lillian',
    'Lake Linden',
    'Lake Lure',
    'Lake Luzerne',
    'Lake Lynn',
    'Lake Mary',
    'Lake Mills',
    'Lake Monroe',
    'Lake Nebagamon',
    'Lake Norden',
    'Lake Odessa',
    'Lake Orion',
    'Lake Oswego',
    'Lake Ozark',
    'Lake Panasoffkee',
    'Lake Park',
    'Lake Peekskill',
    'Lake Placid',
    'Lake Pleasant',
    'Lake Preston',
    'Lake Providence',
    'Lake Saint Louis',
    'Lake Shasta',
    'Lake Station',
    'Lake Stevens',
    'Lake Tomahawk',
    'Lake Toxaway',
    'Lake View',
    'Lake Villa',
    'Lake Village',
    'Lake Waccamaw',
    'Lake Wales',
    'Lake Wilson',
    'Lake Winola',
    'Lake Worth',
    'Lake Zurich',
    'Lake in the Hills',
    'Lakebay',
    'Lakefield',
    'Lakehead',
    'Lakehurst',
    'Lakeland',
    'Lakemont',
    'Lakemore',
    'Lakeport',
    'Lakeside',
    'Laketown',
    'Lakeview',
    'Lakeville',
    'Lakewood',
    'Lakin',
    'Lakota',
    'Lamar',
    'Lambert',
    'Lamberton',
    'Lambertville',
    'Lambsburg',
    'Lame Deer',
    'Lamesa',
    'Lamoni',
    'Lamont',
    'Lampasas',
    'Lamy',
    'Lanai',
    'Lanark',
    'Lancaster',
    'Lancing',
    "Land O' Lakes",
    'Landenberg',
    'Lander',
    'Landing',
    'Landis',
    'Landisburg',
    'Landisville',
    'Landrum',
    'Lanesboro',
    'Lanesville',
    'Lanett',
    'Laneville',
    'Lanexa',
    'Langdon',
    'Langeloth',
    'Langford',
    'Langhorne',
    'Langley',
    'Langston',
    'Langsville',
    'Lanham',
    'Lanoka Harbor',
    'Lansdale',
    'Lansdowne',
    'Lanse',
    'Lansford',
    'Lansing',
    'Lantry',
    'Laona',
    'Laotto',
    'Lapeer',
    'Lapel',
    'Lapine',
    'Laplace',
    'Laporte',
    'Lapwai',
    'Laquey',
    'Laramie',
    'Larchmont',
    'Larchwood',
    'Laredo',
    'Largo',
    'Larimore',
    'Larkspur',
    'Larned',
    'Larose',
    'Las Animas',
    'Las Cruces',
    'Las Vegas',
    'Lascassas',
    'Latexo',
    'Latham',
    'Lathrop',
    'Latimer',
    'Laton',
    'Latonia',
    'Latrobe',
    'Latta',
    'Lattimer',
    'Lattimore',
    'Lauderdale',
    'Laughlin',
    'Laughlintown',
    'Laura',
    'Laurel',
    'Laurel Hill',
    'Laurel Springs',
    'Laurelton',
    'Laurelville',
    'Laurens',
    'Laurinburg',
    'Laurys Station',
    'Lavaca',
    'Lavalette',
    'Lavallette',
    'Laveen',
    'Laverne',
    'Lavon',
    'Lavonia',
    'Lawler',
    'Lawn',
    'Lawndale',
    'Lawnside',
    'Lawrence',
    'Lawrenceburg',
    'Lawrenceville',
    'Lawson',
    'Lawton',
    'Layton',
    'Laytonville',
    'Le Center',
    'Le Grand',
    'Le Mars',
    'Le Raysville',
    'Le Roy',
    'Le Sueur',
    'LeClaire',
    'LeRoy',
    'Lead',
    'Lead Hill',
    'Leadville',
    'Leadwood',
    'Leaf River',
    'League City',
    'Leakesville',
    'Leakey',
    'Leander',
    'Leary',
    'Leavenworth',
    'Leavittsburg',
    'Leawood',
    'Lebanon',
    'Lebanon Junction',
    'Lebec',
    'Lebo',
    'Leburn',
    'Lecanto',
    'Lecompte',
    'Lecompton',
    'Ledbetter',
    'Lederach',
    'Ledgewood',
    'Ledyard',
    'Ledyard Center',
    'Lee',
    'Lee Center',
    "Lee's Summit",
    'Leechburg',
    'Leedey',
    'Leeds',
    'Leeper',
    'Leesburg',
    'Leesport',
    'Leesville',
    'Leeton',
    'Leetonia',
    'Leetsdale',
    'Lefor',
    'Lefors',
    'Lehi',
    'Lehigh',
    'Lehigh Acres',
    'Lehighton',
    'Lehman',
    'Lehr',
    'Leicester',
    'Leigh',
    'Leighton',
    'Leipsic',
    'Leitchfield',
    'Leland',
    'Lemasters',
    'Lemmon',
    'Lemon Grove',
    'Lemont',
    'Lemont Furnace',
    'Lemoore',
    'Lemoyne',
    'Lempster',
    'Lena',
    'Lenapah',
    'Lenexa',
    'Lengby',
    'Lenhartsville',
    'Lenni',
    'Lennon',
    'Lennox',
    'Lenoir',
    'Lenoir City',
    'Lenora',
    'Lenore',
    'Lenox',
    'Leo',
    'Leola',
    'Leoma',
    'Leominster',
    'Leon',
    'Leona',
    'Leonard',
    'Leonardo',
    'Leonardsville',
    'Leonardtown',
    'Leonardville',
    'Leonia',
    'Leonidas',
    'Leonore',
    'Leonville',
    'Leopold',
    'Leopolis',
    'Leoti',
    'Lepanto',
    'Lerna',
    'Leroy',
    'Lesage',
    'Leslie',
    'Lester',
    'Lester Prairie',
    'Lesterville',
    'Letart',
    'Letohatchee',
    'Letts',
    'Levan',
    'Levant',
    'Levelland',
    'Levelock',
    'Levels',
    'Leverett',
    'Levering',
    'Levittown',
    'Lewellen',
    'Lewes',
    'Lewis',
    'Lewis Center',
    'Lewis Run',
    'Lewisberry',
    'Lewisburg',
    'Lewisport',
    'Lewiston',
    'Lewiston Woodville',
    'Lewistown',
    'Lewisville',
    'Lexa',
    'Lexington',
    'Lexington Park',
    'Libby',
    'Liberal',
    'Liberty',
    'Liberty Center',
    'Liberty Corner',
    'Liberty Hill',
    'Liberty Lake',
    'Libertytown',
    'Libertyville',
    'Lick Creek',
    'Licking',
    'Lidgerwood',
    'Liebenthal',
    'Lignum',
    'Ligonier',
    'Lihue',
    'Likely',
    'Lilburn',
    'Lilesville',
    'Lillian',
    'Lillington',
    'Lilliwaup',
    'Lilly',
    'Lily Dale',
    'Lima',
    'Lime Ridge',
    'Lime Springs',
    'Limerick',
    'Limestone',
    'Limington',
    'Limon',
    'Lincoln',
    'Lincoln City',
    'Lincoln Park',
    'Lincolndale',
    'Lincolnshire',
    'Lincolnton',
    'Lincolnville',
    'Lincolnville Center',
    'Lincolnwood',
    'Lincroft',
    'Lindale',
    'Linden',
    'Lindenhurst',
    'Lindley',
    'Lindon',
    'Lindsay',
    'Lindsborg',
    'Lindside',
    'Lindstrom',
    'Linesville',
    'Lineville',
    'Linkwood',
    'Linn',
    'Linn Creek',
    'Linneus',
    'Linthicum Heights',
    'Linton',
    'Linville',
    'Linwood',
    'Lipan',
    'Lisbon',
    'Lisbon Falls',
    'Liscomb',
    'Lisle',
    'Lismore',
    'Listie',
    'Litchfield',
    'Litchfield Park',
    'Litchfield Plains',
    'Lithia',
    'Lithia Springs',
    'Lithonia',
    'Lithopolis',
    'Lititz',
    'Little America',
    'Little Chute',
    'Little Compton',
    'Little Elm',
    'Little Falls',
    'Little Ferry',
    'Little Hocking',
    'Little Meadows',
    'Little Mountain',
    'Little Neck',
    'Little River',
    'Little River-Academy',
    'Little Rock',
    'Little Rock Air Force Base',
    'Little Silver',
    'Little Suamico',
    'Little Valley',
    'Littlefield',
    'Littlefork',
    'Littlerock',
    'Littlestown',
    'Littleton',
    'Live Oak',
    'Livermore',
    'Livermore Falls',
    'Liverpool',
    'Livingston',
    'Livingston Manor',
    'Livonia',
    'Lizella',
    'Lizton',
    'Llano',
    'Loa',
    'Loachapoka',
    'Loami',
    'Lobelville',
    'Local',
    'Loch Sheldrake',
    'Lock Haven',
    'Lockbourne',
    'Locke',
    'Lockeford',
    'Lockesburg',
    'Lockhart',
    'Lockney',
    'Lockport',
    'Lockwood',
    'Loco',
    'Locust',
    'Locust Fork',
    'Locust Gap',
    'Locust Grove',
    'Locust Valley',
    'Loda',
    'Lodge',
    'Lodge Grass',
    'Lodi',
    'Log Lane Village',
    'Logan',
    'Logandale',
    'Logansport',
    'Loganton',
    'Loganville',
    'Lohman',
    'Lohn',
    'Lolita',
    'Lolo',
    'Loma',
    'Loma Linda',
    'Lomax',
    'Lombard',
    'Lomira',
    'Lomita',
    'Lompoc',
    'Lonaconing',
    'London',
    'London Mills',
    'Londonderry',
    'Lone Grove',
    'Lone Jack',
    'Lone Oak',
    'Lone Pine',
    'Lone Rock',
    'Lone Tree',
    'Lone Wolf',
    'Lonedell',
    'Long Beach',
    'Long Branch',
    'Long Grove',
    'Long Island',
    'Long Island City',
    'Long Lake',
    'Long Lane',
    'Long Pine',
    'Long Pond',
    'Long Prairie',
    'Long Valley',
    'Longboat Key',
    'Longbranch',
    'Longdale',
    'Longmeadow',
    'Longmont',
    'Longport',
    'Longs',
    'Longton',
    'Longview',
    'Longville',
    'Longwood',
    'Lonoke',
    'Lonsdale',
    'Loogootee',
    'Lookout',
    'Lookout Mountain',
    'Loomis',
    'Loon Lake',
    'Loop',
    'Loose Creek',
    'Lopez',
    'Lorain',
    'Loraine',
    'Loranger',
    'Lordsburg',
    'Lore City',
    'Lorena',
    'Lorenzo',
    'Loretto',
    'Lorimor',
    'Loris',
    'Lorman',
    'Lorraine',
    'Lorton',
    'Los Alamitos',
    'Los Alamos',
    'Los Altos',
    'Los Altos Hills',
    'Los Angeles',
    'Los Banos',
    'Los Fresnos',
    'Los Gatos',
    'Los Lunas',
    'Los Molinos',
    'Los Olivos',
    'Los Osos',
    'Losantville',
    'Lost City',
    'Lost Creek',
    'Lost Nation',
    'Lost Springs',
    'Lostant',
    'Lostine',
    'Lothian',
    'Lott',
    'Lottie',
    'Lottsburg',
    'Lotus',
    'Loudon',
    'Loudonville',
    'Louin',
    'Louisa',
    'Louisburg',
    'Louise',
    'Louisiana',
    'Louisville',
    'Loup City',
    'Lovejoy',
    'Lovelady',
    'Loveland',
    'Lovell',
    'Lovelock',
    'Lovely',
    'Loves Park',
    'Lovettsville',
    'Loveville',
    'Lovilia',
    'Loving',
    'Lovingston',
    'Lovington',
    'Low Moor',
    'Low and Burbanks Grant',
    'Lowber',
    'Lowden',
    'Lowell',
    'Lowellville',
    'Lower Brule',
    'Lower Lake',
    'Lower Salem',
    'Lowgap',
    'Lowman',
    'Lowmansville',
    'Lowndes',
    'Lowndesboro',
    'Lowry',
    'Lowry City',
    'Lowville',
    'Loxahatchee Groves',
    'Loxley',
    'Loyal',
    'Loyalhanna',
    'Loyall',
    'Loysville',
    'Lu Verne',
    'Lubbock',
    'Lubec',
    'Lucama',
    'Lucan',
    'Lucas',
    'Lucasville',
    'Lucedale',
    'Lucerne',
    'Lucerne Valley',
    'Lucinda',
    'Luck',
    'Luckey',
    'Ludington',
    'Ludlow',
    'Ludlow Falls',
    'Ludowici',
    'Lueders',
    'Lufkin',
    'Lugoff',
    'Lula',
    'Luling',
    'Lumberport',
    'Lumberton',
    'Lumberville',
    'Lummi Island',
    'Lumpkin',
    'Luna Pier',
    'Lund',
    'Lunenburg',
    'Lupton City',
    'Luray',
    'Lusby',
    'Lusk',
    'Lutcher',
    'Luther',
    'Luthersburg',
    'Luthersville',
    'Lutherville-Timonium',
    'Lutsen',
    'Luttrell',
    'Lutz',
    'Luverne',
    'Luxemburg',
    'Luxor',
    'Luxora',
    'Luzerne',
    'Lydia',
    'Lyerly',
    'Lykens',
    'Lyle',
    'Lyles',
    'Lyman',
    'Lyme',
    'Lynbrook',
    'Lynch',
    'Lynch Station',
    'Lynchburg',
    'Lynco',
    'Lynd',
    'Lyndeborough',
    'Lynden',
    'Lyndhurst',
    'Lyndon',
    'Lyndon Center',
    'Lyndon Station',
    'Lyndonville',
    'Lynn',
    'Lynn Center',
    'Lynn Haven',
    'Lynnfield',
    'Lynnville',
    'Lynnwood',
    'Lynwood',
    'Lyon',
    'Lyons',
    'Lytle',
    'Lytle Creek',
    'Mabank',
    'Mabel',
    'Mabelvale',
    'Maben',
    'Mableton',
    'Mabton',
    'Macclenny',
    'Macclesfield',
    'Macdoel',
    'Macedon',
    'Macedonia',
    'Macfarlan',
    'Machesney Park',
    'Machias',
    'Machiasport',
    'Mackay',
    'Mackinac Island',
    'Mackinaw',
    'Mackinaw City',
    'Macksburg',
    'Macomb',
    'Macon',
    'Macungie',
    'Mad River',
    'Madawaska',
    'Madbury',
    'Maddock',
    'Madelia',
    'Madera',
    'Madill',
    'Madison',
    'Madison Heights',
    'Madison Lake',
    'Madisonville',
    'Madras',
    'Madrid',
    'Magalia',
    'Magazine',
    'Magdalena',
    'Magee',
    'Maggie Valley',
    'Magna',
    'Magnolia',
    'Magnolia Springs',
    'Mahaffey',
    'Mahanoy City',
    'Mahaska',
    'Mahnomen',
    'Mahomet',
    'Mahopac',
    'Mahwah',
    'Maiden',
    'Maiden Rock',
    'Maidens',
    'Maidsville',
    'Mainesburg',
    'Maineville',
    'Maitland',
    'Maize',
    'Makanda',
    'Makawao',
    'Makinen',
    'Malabar',
    'Malad City',
    'Malaga',
    'Malakoff',
    'Malcom',
    'Malden',
    'Malibu',
    'Malin',
    'Malinta',
    'Mallory',
    'Malmo',
    'Malone',
    'Malott',
    'Malta',
    'Malvern',
    'Malverne',
    'Mamaroneck',
    'Mammoth',
    'Mammoth Lakes',
    'Mamou',
    'Man',
    'Manahawkin',
    'Manasquan',
    'Manassas',
    'Manassas Park',
    'Manawa',
    'Mancelona',
    'Manchaca',
    'Manchaug',
    'Manchester',
    'Manchester Center',
    'Manchester Township',
    'Manchester-by-the-Sea',
    'Mancos',
    'Mandan',
    'Mandaree',
    'Manderson',
    'Mandeville',
    'Mangum',
    'Manhasset',
    'Manhattan',
    'Manhattan Beach',
    'Manheim',
    'Manila',
    'Manistee',
    'Manistique',
    'Manito',
    'Manitou',
    'Manitou Springs',
    'Manitowoc',
    'Mankato',
    'Manlius',
    'Manly',
    'Mannford',
    'Manning',
    'Mannington',
    'Manns Choice',
    'Mannsville',
    'Manokotak',
    'Manomet',
    'Manor',
    'Manorville',
    'Manquin',
    'Mansfield',
    'Mansfield Center',
    'Mansfield Depot',
    'Manson',
    'Mansura',
    'Mantachie',
    'Manteca',
    'Mantee',
    'Manteno',
    'Manteo',
    'Manti',
    'Mantoloking',
    'Manton',
    'Mantorville',
    'Mantua',
    'Manvel',
    'Manville',
    'Many',
    'Many Farms',
    'Manzanita',
    'Manzanola',
    'Maple City',
    'Maple Falls',
    'Maple Grove',
    'Maple Heights',
    'Maple Hill',
    'Maple Lake',
    'Maple Park',
    'Maple Plain',
    'Maple Rapids',
    'Maple Shade',
    'Maple Valley',
    'Maplesville',
    'Mapleton',
    'Mapleville',
    'Maplewood',
    'Mappsville',
    'Maquoketa',
    'Marana',
    'Marathon',
    'Marble',
    'Marble Falls',
    'Marble Hill',
    'Marble Rock',
    'Marblehead',
    'Marblehill',
    'Marblemount',
    'Marbury',
    'Marceline',
    'Marcell',
    'Marcellus',
    'March Air Force Base',
    'Marco Island',
    'Marcus',
    'Marcus Hook',
    'Marcy',
    'Mardela Springs',
    'Marengo',
    'Marenisco',
    'Marfa',
    'Margaret',
    'Margaretville',
    'Margate City',
    'Maria Stein',
    'Marianna',
    'Maribel',
    'Maricopa',
    'Marienthal',
    'Marienville',
    'Marietta',
    'Marina',
    'Marina del Rey',
    'Marine',
    'Marine City',
    'Marine on Saint Croix',
    'Marinette',
    'Maringouin',
    'Marion',
    'Marion Center',
    'Marion Heights',
    'Marion Junction',
    'Marionville',
    'Mariposa',
    'Marissa',
    'Mark Center',
    'Marked Tree',
    'Markesan',
    'Markham',
    'Markle',
    'Markleeville',
    'Markleville',
    'Markleysburg',
    'Marks',
    'Marksville',
    'Marlboro',
    'Marlborough',
    'Marlette',
    'Marlin',
    'Marlinton',
    'Marlow',
    'Marlton',
    'Marmora',
    'Marne',
    'Maroa',
    'Marquand',
    'Marquette',
    'Marquez',
    'Marrero',
    'Marriottsville',
    'Mars',
    'Mars Hill',
    'Marseilles',
    'Marshall',
    'Marshalls Creek',
    'Marshalltown',
    'Marshallville',
    'Marshfield',
    'Marshfield Hills',
    'Marshville',
    'Marsing',
    'Marsteller',
    'Marstons Mills',
    'Mart',
    'Martell',
    'Martelle',
    'Martha',
    'Marthasville',
    'Marthaville',
    'Martin',
    'Martindale',
    'Martinez',
    'Martins Creek',
    'Martins Ferry',
    'Martinsburg',
    'Martinsdale',
    'Martinsville',
    'Martville',
    'Mary Esther',
    'Marydel',
    'Maryknoll',
    'Maryland',
    'Maryland Heights',
    'Marylhurst',
    'Maryneal',
    'Marysvale',
    'Marysville',
    'Maryville',
    'Mascot',
    'Mascotte',
    'Mascoutah',
    'Mashantucket',
    'Mashpee',
    'Maskell',
    'Mason',
    'Mason City',
    'Masontown',
    'Masonville',
    'Maspeth',
    'Mass City',
    'Massapequa',
    'Massapequa Park',
    'Massena',
    'Massey',
    'Massillon',
    'Mastic',
    'Mastic Beach',
    'Masury',
    'Matamoras',
    'Matawan',
    'Matewan',
    'Matfield Green',
    'Mather Field',
    'Mathews',
    'Mathias',
    'Mathis',
    'Mathiston',
    'Matinicus',
    'Matoaka',
    'Mattapan',
    'Mattapoisett',
    'Mattaponi',
    'Mattawa',
    'Mattawamkeag',
    'Mattawan',
    'Mattawana',
    'Matteson',
    'Matthews',
    'Mattituck',
    'Mattoon',
    'Mauckport',
    'Maud',
    'Mauk',
    'Mauldin',
    'Maumee',
    'Maumelle',
    'Maunaloa',
    'Maupin',
    'Maurepas',
    'Maurertown',
    'Maurice',
    'Mauriceville',
    'Maury',
    'Maury City',
    'Mauston',
    'Max',
    'Max Meadows',
    'Maxatawny',
    'Maxbass',
    'Maxton',
    'Maxwell',
    'May',
    'May Creek',
    'Maybee',
    'Maybrook',
    'Mayer',
    'Mayesville',
    'Mayetta',
    'Mayfield',
    'Mayflower',
    'Maylene',
    'Maynard',
    'Maynardville',
    'Mayo',
    'Mayodan',
    'Maypearl',
    'Mayport',
    'Mays Landing',
    'Maysel',
    'Maysville',
    'Mayville',
    'Maywood',
    'Mazeppa',
    'Mazomanie',
    'Mazon',
    'McAdoo',
    'McAlester',
    'McAlister',
    'McAlisterville',
    'McAllen',
    'McAlpin',
    'McArthur',
    'McBain',
    'McBee',
    'McCall',
    'McCalla',
    'McCamey',
    'McCammon',
    'McCaysville',
    'McCleary',
    'McClellan Park Census Designated Place',
    'McClelland',
    'McClellandtown',
    'McClellanville',
    'McCloud',
    'McClure',
    'McClusky',
    'McColl',
    'McComb',
    'McConnells',
    'McConnellsburg',
    'McConnelsville',
    'McCook',
    'McCool',
    'McCool Junction',
    'McCordsville',
    'McCormick',
    'McCoy',
    'McCune',
    'McCurtain',
    'McCutchenville',
    'McDaniels',
    'McDavid',
    'McDermitt',
    'McDermott',
    'McDonald',
    'McDonough',
    'McDowell',
    'McElhattan',
    'McEwen',
    'McFall',
    'McFarland',
    'McGaheysville',
    'McGehee',
    'McGraw',
    'McGregor',
    'McHenry',
    'McIntosh',
    'McKean Township',
    'McKee',
    'McKees Rocks',
    'McKeesport',
    'McKenna',
    'McKenzie',
    'McKinleyville',
    'McKinney',
    'McLain',
    'McLaughlin',
    'McLean',
    'McLeansboro',
    'McLeansville',
    'McLoud',
    'McMechen',
    'McMillan',
    'McMinnville',
    'McNabb',
    'McNary',
    'McNeal',
    'McPherson',
    'McQueeney',
    'McRae',
    'McSherrystown',
    'McVeytown',
    'McVille',
    'Meacham',
    'Mead',
    'Meade',
    'Meadow',
    'Meadow Bridge',
    'Meadow Grove',
    'Meadow Lands',
    'Meadow Valley',
    'Meadow Vista',
    'Meadowlands',
    'Meadows of Dan',
    'Meadowview',
    'Meadview',
    'Meadville',
    'Meally',
    'Meansville',
    'Mears',
    'Mebane',
    'Mecca',
    'Mechanic Falls',
    'Mechanicsburg',
    'Mechanicsville',
    'Mechanicville',
    'Mecosta',
    'Medanales',
    'Medaryville',
    'Medfield',
    'Medford',
    'Media',
    'Mediapolis',
    'Medical Lake',
    'Medicine Lake',
    'Medicine Lodge',
    'Medicine Park',
    'Medina',
    'Medinah',
    'Medon',
    'Medora',
    'Medway',
    'Meeker',
    'Meeteetse',
    'Meherrin',
    'Mehoopany',
    'Mekoryuk',
    'Melba',
    'Melbourne',
    'Melbourne Beach',
    'Melcher-Dallas',
    'Melcroft',
    'Melfa',
    'Melissa',
    'Mellen',
    'Mellenville',
    'Melrose',
    'Melrose Park',
    'Melstone',
    'Melville',
    'Melvin',
    'Melvindale',
    'Memphis',
    'Mena',
    'Menahga',
    'Menan',
    'Menard',
    'Menasha',
    'Mendenhall',
    'Mendham',
    'Mendocino',
    'Mendon',
    'Mendota',
    'Menifee',
    'Menlo',
    'Menlo Park',
    'Menno',
    'Meno',
    'Menominee',
    'Menomonee Falls',
    'Mentone',
    'Mentor',
    'Mequon',
    'Mer Rouge',
    'Meraux',
    'Merced',
    'Mercedes',
    'Mercer',
    'Mercer Island',
    'Mercersburg',
    'Merchantville',
    'Meredith',
    'Mereta',
    'Meriden',
    'Meridian',
    'Meridianville',
    'Merino',
    'Merion',
    'Merit',
    'Merkel',
    'Merlin',
    'Mermentau',
    'Merna',
    'Merrick',
    'Merrifield',
    'Merrill',
    'Merrillan',
    'Merrillville',
    'Merrimac',
    'Merrimack',
    'Merriman',
    'Merritt',
    'Merritt Island',
    'Merry Hill',
    'Merry Point',
    'Merryville',
    'Merton',
    'Mertzon',
    'Mertztown',
    'Mesa',
    'Mescalero',
    'Meshoppen',
    'Mesick',
    'Mesilla',
    'Mesilla Park',
    'Mesquite',
    'Metairie',
    'Metaline Falls',
    'Metamora',
    'Metcalf',
    'Methow',
    'Methuen',
    'Metlakatla',
    'Metropolis',
    'Metropolitan',
    'Metter',
    'Metuchen',
    'Metz',
    'Mexia',
    'Mexico',
    'Meyersdale',
    'Mi-Wuk Village',
    'Miami',
    'Miami Beach',
    'Miamisburg',
    'Miamitown',
    'Miamiville',
    'Micanopy',
    'Michie',
    'Michigan Center',
    'Michigan City',
    'Michigantown',
    'Mickleton',
    'Mico',
    'Micro',
    'Middle Bass',
    'Middle Granville',
    'Middle Grove',
    'Middle Haddam',
    'Middle Island',
    'Middle Point',
    'Middle River',
    'Middle Village',
    'Middleboro',
    'Middlebourne',
    'Middleburg',
    'Middleburgh',
    'Middlebury',
    'Middlefield',
    'Middleport',
    'Middlesboro',
    'Middlesex',
    'Middleton',
    'Middletown',
    'Middletown Springs',
    'Middleville',
    'Midfield',
    'Midkiff',
    'Midland',
    'Midland City',
    'Midland Park',
    'Midlothian',
    'Midvale',
    'Midville',
    'Midway',
    'Midway City',
    'Midway Park',
    'Midwest',
    'Mifflin',
    'Mifflinburg',
    'Mifflintown',
    'Mifflinville',
    'Mikado',
    'Milaca',
    'Milam',
    'Milan',
    'Milano',
    'Milbank',
    'Milburn',
    'Miles',
    'Miles City',
    'Milesburg',
    'Milford',
    'Milford Center',
    'Mililani Town',
    'Mill City',
    'Mill Creek',
    'Mill Hall',
    'Mill Neck',
    'Mill River',
    'Mill Shoals',
    'Mill Spring',
    'Mill Valley',
    'Milladore',
    'Millboro',
    'Millbrae',
    'Millbrook',
    'Millburn',
    'Millbury',
    'Milldale',
    'Milledgeville',
    'Millen',
    'Miller',
    'Miller Place',
    'Millers Creek',
    'Millersburg',
    'Millersport',
    'Millerstown',
    'Millersview',
    'Millersville',
    'Millerton',
    'Millfield',
    'Millheim',
    'Millican',
    'Milligan',
    'Milliken',
    'Millington',
    'Millinocket',
    'Millis',
    'Millmont',
    'Millport',
    'Millry',
    'Mills',
    'Mills River',
    'Millsap',
    'Millsboro',
    'Millstadt',
    'Millstone',
    'Milltown',
    'Millville',
    'Millwood',
    'Milmay',
    'Milner',
    'Milnesville',
    'Milnor',
    'Milo',
    'Milpitas',
    'Milroy',
    'Milton',
    'Milton Mills',
    'Milton-Freewater',
    'Miltona',
    'Miltonvale',
    'Milwaukee',
    'Milwaukie',
    'Mimbres',
    'Mims',
    'Minburn',
    'Minco',
    'Minden',
    'Minden City',
    'Mine Hill',
    'Mineola',
    'Mineral',
    'Mineral Bluff',
    'Mineral City',
    'Mineral Point',
    'Mineral Ridge',
    'Mineral Springs',
    'Mineral Wells',
    'Minersville',
    'Minerva',
    'Minford',
    'Mingo Junction',
    'Minier',
    'Minneapolis',
    'Minneola',
    'Minneota',
    'Minnesota Lake',
    'Minnetonka',
    'Minnewaukan',
    'Minnie',
    'Minoa',
    'Minocqua',
    'Minonk',
    'Minooka',
    'Minot',
    'Minotola',
    'Minster',
    'Minto',
    'Minturn',
    'Mio',
    'Mira Loma',
    'Miramar',
    'Miramar Beach',
    'Miranda',
    'Mishawaka',
    'Mishicot',
    'Mission',
    'Mission Hill',
    'Mission Hills',
    'Mission Viejo',
    'Missoula',
    'Missouri City',
    'Missouri Valley',
    'Mitchell',
    'Mitchellville',
    'Mize',
    'Moab',
    'Moapa',
    'Moatsville',
    'Moberly',
    'Mobile',
    'Mobridge',
    'Moccasin',
    'Mocksville',
    'Modale',
    'Modena',
    'Modesto',
    'Modoc',
    'Mogadore',
    'Mohave Valley',
    'Mohawk',
    'Mohegan Lake',
    'Mohnton',
    'Mohrsville',
    'Mojave',
    'Mokelumne Hill',
    'Mokena',
    'Molalla',
    'Molena',
    'Moline',
    'Molino',
    'Mollusk',
    'Molt',
    'Momence',
    'Mona',
    'Monaca',
    'Monahans',
    'Moncks Corner',
    'Moncure',
    'Mondamin',
    'Mondovi',
    'Monee',
    'Monessen',
    'Moneta',
    'Monett',
    'Monette',
    'Monkton',
    'Monmouth',
    'Monmouth Beach',
    'Monmouth Junction',
    'Monon',
    'Monona',
    'Monongahela',
    'Monponsett',
    'Monroe',
    'Monroe Center',
    'Monroe City',
    'Monroe Township',
    'Monroeton',
    'Monroeville',
    'Monrovia',
    'Monsey',
    'Monson',
    'Mont Belvieu',
    'Mont Clare',
    'Mont Vernon',
    'Montague',
    'Montandon',
    'Montara',
    'Montauk',
    'Montclair',
    'Monte Rio',
    'Monte Vista',
    'Monteagle',
    'Montebello',
    'Montegut',
    'Montello',
    'Monterey',
    'Monterey County',
    'Monterey Park',
    'Monterville',
    'Montesano',
    'Montevallo',
    'Montevideo',
    'Monteview',
    'Montezuma',
    'Montfort',
    'Montgomery',
    'Montgomery Center',
    'Montgomery City',
    'Montgomery Creek',
    'Montgomery Village',
    'Montgomeryville',
    'Monticello',
    'Montour',
    'Montour Falls',
    'Montoursville',
    'Montpelier',
    'Montreal',
    'Montreat',
    'Montrose',
    'Montross',
    'Montvale',
    'Montverde',
    'Montville',
    'Monument',
    'Monument Beach',
    'Moodus',
    'Moody',
    'Mooers',
    'Mooers Forks',
    'Moon',
    'Moonachie',
    'Moorcroft',
    'Moore',
    'Moore Haven',
    'Moorefield',
    'Mooreland',
    'Moores Hill',
    'Mooresboro',
    'Mooresburg',
    'Moorestown Township',
    'Mooresville',
    'Mooreton',
    'Mooreville',
    'Moorhead',
    'Mooringsport',
    'Moorland',
    'Moorpark',
    'Moose',
    'Moose Lake',
    'Moosic',
    'Moosup',
    'Mora',
    'Moraga',
    'Moran',
    'Moravia',
    'Moravian Falls',
    'Moreauville',
    'Morehead',
    'Morehead City',
    'Moreland',
    'Morenci',
    'Moreno Valley',
    'Moretown',
    'Morgan',
    'Morgan City',
    'Morgan Hill',
    'Morganfield',
    'Morganton',
    'Morgantown',
    'Morganville',
    'Moriah',
    'Moriarty',
    'Moriches',
    'Morley',
    'Morning Sun',
    'Morning View',
    'Moro',
    'Morocco',
    'Morongo Valley',
    'Moroni',
    'Morral',
    'Morrice',
    'Morrill',
    'Morrilton',
    'Morris',
    'Morris Chapel',
    'Morris Plains',
    'Morrisdale',
    'Morrison',
    'Morrisonville',
    'Morriston',
    'Morristown',
    'Morrisville',
    'Morro Bay',
    'Morrow',
    'Morse',
    'Morse Bluff',
    'Morton',
    'Morton Grove',
    'Mortons Gap',
    'Morven',
    'Mosca',
    'Moscow',
    'Moscow Mills',
    'Moseley',
    'Moses Lake',
    'Mosheim',
    'Mosier',
    'Mosinee',
    'Moss',
    'Moss Beach',
    'Moss Landing',
    'Moss Point',
    'Mossyrock',
    'Motley',
    'Mott',
    'Moulton',
    'Moultonborough',
    'Moultrie',
    'Mound',
    'Mound Bayou',
    'Mound City',
    'Moundridge',
    'Mounds',
    'Moundsville',
    'Moundville',
    'Mount Airy',
    'Mount Angel',
    'Mount Arlington',
    'Mount Auburn',
    'Mount Ayr',
    'Mount Berry',
    'Mount Bethel',
    'Mount Blanchard',
    'Mount Calvary',
    'Mount Carbon',
    'Mount Carmel',
    'Mount Carroll',
    'Mount Clemens',
    'Mount Cory',
    'Mount Crawford',
    'Mount Croghan',
    'Mount Eaton',
    'Mount Eden',
    'Mount Enterprise',
    'Mount Ephraim',
    'Mount Erie',
    'Mount Gay',
    'Mount Gilead',
    'Mount Gretna',
    'Mount Hermon',
    'Mount Holly',
    'Mount Holly Springs',
    'Mount Hope',
    'Mount Horeb',
    'Mount Ida',
    'Mount Jackson',
    'Mount Jewett',
    'Mount Joy',
    'Mount Juliet',
    'Mount Kisco',
    'Mount Laurel',
    'Mount Lookout',
    'Mount Morris',
    'Mount Nebo',
    'Mount Olive',
    'Mount Olivet',
    'Mount Orab',
    'Mount Pleasant',
    'Mount Pleasant Mills',
    'Mount Pocono',
    'Mount Prospect',
    'Mount Pulaski',
    'Mount Rainier',
    'Mount Royal',
    'Mount Saint Joseph',
    'Mount Savage',
    'Mount Shasta',
    'Mount Sidney',
    'Mount Sinai',
    'Mount Sterling',
    'Mount Storm',
    'Mount Tremper',
    'Mount Ulla',
    'Mount Union',
    'Mount Upton',
    'Mount Vernon',
    'Mount Victoria',
    'Mount Victory',
    'Mount Vision',
    'Mount Washington',
    'Mount Wilson',
    'Mount Wolf',
    'Mount Zion',
    'Mountain',
    'Mountain City',
    'Mountain Grove',
    'Mountain Home',
    'Mountain Iron',
    'Mountain Lake',
    'Mountain Lakes',
    'Mountain Pine',
    'Mountain Ranch',
    'Mountain Top',
    'Mountain View',
    'Mountain Village',
    'Mountainair',
    'Mountainburg',
    'Mountainside',
    'Mountainville',
    'Mountlake Terrace',
    'Mountville',
    'Mousie',
    'Mouth of Wilson',
    'Mouthcard',
    'Moville',
    'Moweaqua',
    'Moxee City',
    'Moyie Springs',
    'Moyock',
    'Mt Pleasant',
    'Mt. Dora',
    'Mt. Morris',
    'Mt. Pleasant',
    'Mt. Vernon',
    'Muenster',
    'Muir',
    'Mukilteo',
    'Mukwonago',
    'Mulberry',
    'Mulberry Grove',
    'Muldoon',
    'Muldraugh',
    'Muldrow',
    'Muleshoe',
    'Mulga',
    'Mulino',
    'Mulkeytown',
    'Mullan',
    'Mullen',
    'Mullica Hill',
    'Mulliken',
    'Mullin',
    'Mullins',
    'Mullinville',
    'Mulvane',
    'Muncie',
    'Muncy',
    'Munday',
    'Mundelein',
    'Munford',
    'Munfordville',
    'Munger',
    'Munich',
    'Munising',
    'Munith',
    'Munnsville',
    'Munroe Falls',
    'Munster',
    'Murchison',
    'Murdock',
    'Murfreesboro',
    'Murphy',
    'Murphys',
    'Murphysboro',
    'Murray',
    'Murrayville',
    'Murrells Inlet',
    'Murrieta',
    'Murrysville',
    'Muscatine',
    'Muscle Shoals',
    'Muscoda',
    'Muse',
    'Musella',
    'Muskego',
    'Muskegon',
    'Muskogee',
    'Mustang',
    'Mutual',
    'Myakka City',
    'Myerstown',
    'Myersville',
    'Mylo',
    'Myrtle',
    'Myrtle Beach',
    'Myrtle Creek',
    'Myrtle Point',
    'Mystic',
    'Myton',
    'N. Lawrence',
    "Na'alehu",
    'Nabb',
    'Nabesna',
    'Naco',
    'Nacogdoches',
    'Nacoochee',
    'Nageezi',
    'Nags Head',
    'Nahant',
    'Nahunta',
    'Naknek',
    'Nampa',
    'Nancy',
    'Nanjemoy',
    'Nanticoke',
    'Nantucket',
    'Nanty Glo',
    'Nanuet',
    'Naoma',
    'Napa',
    'Napavine',
    'Naper',
    'Naperville',
    'Napier',
    'Naples',
    'Napoleon',
    'Napoleonville',
    'Nappanee',
    'Nara Visa',
    'Narberth',
    'Narka',
    'Narragansett',
    'Narrows',
    'Narrowsburg',
    'Narvon',
    'Naselle',
    'Nash',
    'Nashoba',
    'Nashotah',
    'Nashport',
    'Nashua',
    'Nashville',
    'Nashwauk',
    'Nassau',
    'Nassawadox',
    'Natalbany',
    'Natalia',
    'Natchez',
    'Natchitoches',
    'Natick',
    'National City',
    'National Park',
    'Natrona Heights',
    'Natural Bridge',
    'Natural Bridge Station',
    'Naturita',
    'Naubinway',
    'Naugatuck',
    'Nauvoo',
    'Navajo',
    'Navarre',
    'Navasota',
    'Naylor',
    'Nazan Village',
    'Nazareth',
    'Neah Bay',
    'Nebo',
    'Nebraska City',
    'Necedah',
    'Neche',
    'Nederland',
    'Nedrow',
    'Needham',
    'Needham Heights',
    'Needles',
    'Needmore',
    'Needville',
    'Neely',
    'Neelyville',
    'Neenah',
    'Neeses',
    'Neffs',
    'Negaunee',
    'Negley',
    'Nehalem',
    'Neillsville',
    'Nekoosa',
    'Neligh',
    'Nellis',
    'Nellysford',
    'Nelson',
    'Nelsonville',
    'Nemacolin',
    'Nemaha',
    'Nemo',
    'Nenana',
    'Neodesha',
    'Neoga',
    'Neola',
    'Neosho',
    'Neosho Falls',
    'Neosho Rapids',
    'Neotsu',
    'Nephi',
    'Neponset',
    'Neptune Beach',
    'Neptune City',
    'Nerstrand',
    'Nesbit',
    'Nesconset',
    'Nescopeck',
    'Neshanic Station',
    'Neshkoro',
    'Nesquehoning',
    'Ness City',
    'Nester',
    'Netawaka',
    'Netcong',
    'Nettleton',
    'Nevada',
    'Nevada City',
    'Neversink',
    'Nevis',
    'New Albany',
    'New Albin',
    'New Alexandria',
    'New Almaden',
    'New Athens',
    'New Auburn',
    'New Baden',
    'New Baltimore',
    'New Bavaria',
    'New Bedford',
    'New Berlin',
    'New Berlinville',
    'New Bern',
    'New Bethlehem',
    'New Blaine',
    'New Bloomfield',
    'New Boston',
    'New Braintree',
    'New Braunfels',
    'New Bremen',
    'New Brighton',
    'New Britain',
    'New Brockton',
    'New Brunswick',
    'New Buffalo',
    'New Burnside',
    'New Cambria',
    'New Canaan',
    'New Caney',
    'New Canton',
    'New Carlisle',
    'New Castle',
    'New Church',
    'New City',
    'New Columbia',
    'New Concord',
    'New Creek',
    'New Cumberland',
    'New Cuyama',
    'New Deal',
    'New Derry',
    'New Douglas',
    'New Durham',
    'New Eagle',
    'New Effington',
    'New Egypt',
    'New England',
    'New Enterprise',
    'New Era',
    'New Fairfield',
    'New Florence',
    'New Franken',
    'New Franklin',
    'New Freedom',
    'New Galilee',
    'New Germany',
    'New Glarus',
    'New Gloucester',
    'New Goshen',
    'New Hampton',
    'New Harbor',
    'New Harmony',
    'New Hartford',
    'New Haven',
    'New Hebron',
    'New Hill',
    'New Holland',
    'New Holstein',
    'New Hope',
    'New Hudson',
    'New Hyde Park',
    'New Iberia',
    'New Ipswich',
    'New Johnsonville',
    'New Kensington',
    'New Kent',
    'New Knoxville',
    'New Laguna',
    'New Lebanon',
    'New Lenox',
    'New Lexington',
    'New Liberty',
    'New Lisbon',
    'New Llano',
    'New London',
    'New Lothrop',
    'New Madrid',
    'New Market',
    'New Marshfield',
    'New Martinsville',
    'New Meadows',
    'New Memphis',
    'New Middletown',
    'New Milford',
    'New Orleans',
    'New Oxford',
    'New Palestine',
    'New Paltz',
    'New Paris',
    'New Park',
    'New Philadelphia',
    'New Plymouth',
    'New Point',
    'New Port Richey',
    'New Portland',
    'New Prague',
    'New Preston',
    'New Providence',
    'New Raymer',
    'New Richland',
    'New Richmond',
    'New Riegel',
    'New Ringgold',
    'New River',
    'New Roads',
    'New Rochelle',
    'New Rockford',
    'New Ross',
    'New Salem',
    'New Salisbury',
    'New Sharon',
    'New Smyrna Beach',
    'New Springfield',
    'New Stanton',
    'New Straitsville',
    'New Stuyahok',
    'New Tazewell',
    'New Town',
    'New Tripoli',
    'New Troy',
    'New Ulm',
    'New Underwood',
    'New Vernon',
    'New Vienna',
    'New Vineyard',
    'New Virginia',
    'New Washington',
    'New Waterford',
    'New Waverly',
    'New Weston',
    'New Wilmington',
    'New Windsor',
    'New Woodstock',
    'New York',
    'New York Mills',
    'Newalla',
    'Newark',
    'Newark Valley',
    'Newaygo',
    'Newberg',
    'Newbern',
    'Newberry',
    'Newberry Springs',
    'Newborn',
    'Newburg',
    'Newburgh',
    'Newbury',
    'Newbury Center',
    'Newbury Old Town',
    'Newbury Park',
    'Newburyport',
    'Newcastle',
    'Newcomb',
    'Newcomerstown',
    'Newell',
    'Newellton',
    'Newfane',
    'Newfield',
    'Newfields',
    'Newfolden',
    'Newfoundland',
    'Newhall',
    'Newhope',
    'Newington',
    'Newkirk',
    'Newland',
    'Newman',
    'Newman Grove',
    'Newman Lake',
    'Newmanstown',
    'Newmarket',
    'Newnan',
    'Newport',
    'Newport Beach',
    'Newport Center',
    'Newport Coast',
    'Newport News',
    'Newry',
    'Newsoms',
    'Newton',
    'Newton Center',
    'Newton Falls',
    'Newton Grove',
    'Newton Highlands',
    'Newton Lower Falls',
    'Newton Upper Falls',
    'Newtonsville',
    'Newtonville',
    'Newtown',
    'Newtown Square',
    'Newville',
    'Nezperce',
    'Niagara',
    'Niagara Falls',
    'Niangua',
    'Niantic',
    'Nicasio',
    'Niceville',
    'Nicholasville',
    'Nicholls',
    'Nichols',
    'Nicholson',
    'Nicholville',
    'Nickelsville',
    'Nickerson',
    'Nicktown',
    'Nicollet',
    'Nicoma Park',
    'Nielsville',
    'Nikiski',
    'Niland',
    'Niles',
    'Nilwood',
    'Nine Mile Falls',
    'Ninety Six',
    'Nineveh',
    'Ninilchik',
    'Ninnekah',
    'Ninole',
    'Niobe',
    'Niobrara',
    'Niota',
    'Nipomo',
    'Nisswa',
    'Nitro',
    'Niwot',
    'Nixa',
    'Nixon',
    'Noatak',
    'Noble',
    'Nobleboro',
    'Noblesville',
    'Nocona',
    'Noel',
    'Nogal',
    'Nogales',
    'Nokesville',
    'Nokomis',
    'Nolanville',
    'Nolensville',
    'Nome',
    'Nondalton',
    'Nooksack',
    'Noonan',
    'Noorvik',
    'Nora Springs',
    'Norborne',
    'Norco',
    'Norcross',
    'Nordland',
    'Norfolk',
    'Norlina',
    'Norma',
    'Normal',
    'Normalville',
    'Norman',
    'Norman Park',
    'Normandy',
    'Normandy Beach',
    'Normangee',
    'Normantown',
    'Norridgewock',
    'Norris',
    'Norris City',
    'Norristown',
    'North',
    'North Adams',
    'North Amherst',
    'North Andover',
    'North Anson',
    'North Apollo',
    'North Arlington',
    'North Attleboro',
    'North Augusta',
    'North Aurora',
    'North Babylon',
    'North Baltimore',
    'North Bangor',
    'North Beach',
    'North Bend',
    'North Bennington',
    'North Bergen',
    'North Berwick',
    'North Billerica',
    'North Bloomfield',
    'North Bonneville',
    'North Branch',
    'North Branford',
    'North Bridgton',
    'North Brookfield',
    'North Brunswick',
    'North Carrollton',
    'North Carver',
    'North Charleston',
    'North Chatham',
    'North Chelmsford',
    'North Chicago',
    'North Chili',
    'North Clarendon',
    'North Collins',
    'North Conway',
    'North Creek',
    'North Dartmouth',
    'North Dighton',
    'North East',
    'North Eastham',
    'North Easton',
    'North English',
    'North Fairfield',
    'North Falmouth',
    'North Fork',
    'North Fort Myers',
    'North Franklin',
    'North Freedom',
    'North Garden',
    'North Grafton',
    'North Granby',
    'North Greece',
    'North Grosvenor Dale',
    'North Hampton',
    'North Hatfield',
    'North Haven',
    'North Haverhill',
    'North Henderson',
    'North Hero',
    'North Highlands',
    'North Hills',
    'North Hollywood',
    'North Jackson',
    'North Judson',
    'North Kingstown',
    'North Kingsville',
    'North Las Vegas',
    'North Lemmon',
    'North Lewisburg',
    'North Liberty',
    'North Lima',
    'North Little Rock',
    'North Loup',
    'North Manchester',
    'North Matewan',
    'North Miami Beach',
    'North Monmouth',
    'North Myrtle Beach',
    'North Newton',
    'North Olmsted',
    'North Oxford',
    'North Palm Beach',
    'North Pembroke',
    'North Plains',
    'North Platte',
    'North Point',
    'North Pole',
    'North Pomfret',
    'North Port',
    'North Powder',
    'North Prairie',
    'North Providence',
    'North Reading',
    'North Richland Hills',
    'North Ridgeville',
    'North Rose',
    'North Royalton',
    'North Salem',
    'North Salt Lake',
    'North San Juan',
    'North Sandwich',
    'North Scituate',
    'North Sioux City',
    'North Smithfield',
    'North Springfield',
    'North Stonington',
    'North Sutton',
    'North Tazewell',
    'North Tonawanda',
    'North Troy',
    'North Truro',
    'North Vernon',
    'North Versailles',
    'North Wales',
    'North Walpole',
    'North Waterboro',
    'North Webster',
    'North Weymouth',
    'North Wilkesboro',
    'North Windham',
    'North Woodstock',
    'North Zulch',
    'Northampton',
    'Northboro',
    'Northborough',
    'Northbridge',
    'Northbrook',
    'Northeast Harbor',
    'Northern Cambria',
    'Northfield',
    'Northfield Falls',
    'Northford',
    'Northfork',
    'Northome',
    'Northport',
    'Northridge',
    "Northrup's of West Bloomfield Mobile Home Park",
    'Northumberland',
    'Northvale',
    'Northville',
    'Northway',
    'Northwood',
    'Norton',
    'Nortonville',
    'Norwalk',
    'Norway',
    'Norwell',
    'Norwich',
    'Norwood',
    'Norwood Young America',
    'Notasulga',
    'Notre Dame',
    'Nottingham',
    'Nova',
    'Novato',
    'Novelty',
    'Novi',
    'Novinger',
    'Nowata',
    'Noxapater',
    'Noxen',
    'Noxon',
    'NuMine',
    'Nucla',
    'Nuevo',
    'Nunda',
    'Nunica',
    'Nunn',
    'Nunnelly',
    'Nuremberg',
    'Nutley',
    'Nutrioso',
    'Nyack',
    'Nye',
    'Nyssa',
    "O'Brien",
    "O'Fallon",
    "O'Neals",
    "O'Neill",
    'Oacoma',
    'Oak Bluffs',
    'Oak Brook',
    'Oak City',
    'Oak Creek',
    'Oak Forest',
    'Oak Grove',
    'Oak Harbor',
    'Oak Hill',
    'Oak Island',
    'Oak Lawn',
    'Oak Park',
    'Oak Ridge',
    'Oak Run',
    'Oak View',
    'Oakboro',
    'Oakdale',
    'Oakes',
    'Oakesdale',
    'Oakfield',
    'Oakham',
    'Oakhurst',
    'Oakland',
    'Oakland City',
    'Oakland Estates Mobile Home Park',
    'Oakland Gardens',
    'Oakley',
    'Oaklyn',
    'Oakman',
    'Oakmont',
    'Oaks',
    'Oakton',
    'Oakvale',
    'Oakville',
    'Oakwood',
    'Oberlin',
    'Obion',
    'Oblong',
    'Ocala',
    'Ocate',
    'Occidental',
    'Occoquan',
    'Ocean City',
    'Ocean Gate',
    'Ocean Grove',
    'Ocean Isle Beach',
    'Ocean Park',
    'Ocean Shores',
    'Ocean Springs',
    'Ocean View',
    'Oceana',
    'Oceano',
    'Oceanport',
    'Oceanside',
    'Oceanville',
    'Ochelata',
    'Ocheyedan',
    'Ochlocknee',
    'Ocilla',
    'Ocklawaha',
    'Ocoee',
    'Oconomowoc',
    'Oconto',
    'Oconto Falls',
    'Ocotillo',
    'Odanah',
    'Odebolt',
    'Odell',
    'Odem',
    'Oden',
    'Odenton',
    'Odenville',
    'Odessa',
    'Odin',
    'Odon',
    'Odum',
    'Oelrichs',
    'Oelwein',
    'Ogallah',
    'Ogallala',
    'Ogden',
    'Ogdensburg',
    'Ogema',
    'Ogilvie',
    'Oglesby',
    'Oglethorpe',
    'Ogunquit',
    'Ohatchee',
    'Ohio',
    'Ohio City',
    'Ohiopyle',
    'Oil City',
    'Oil Springs',
    'Oilton',
    'Ojai',
    'Ojo Caliente',
    'Okahumpka',
    'Okanogan',
    'Okarche',
    'Okatie',
    'Okay',
    'Okeana',
    'Okeechobee',
    'Okeene',
    'Okemah',
    'Okemos',
    'Oketo',
    'Oklahoma City',
    'Oklaunion',
    'Oklee',
    'Okmulgee',
    'Okoboji',
    'Okolona',
    'Oktaha',
    'Ola',
    'Olalla',
    'Olancha',
    'Olanta',
    'Olathe',
    'Olaton',
    'Old Bethpage',
    'Old Bridge',
    'Old Chatham',
    'Old Fields',
    'Old Forge',
    'Old Fort',
    'Old Greenwich',
    'Old Hickory',
    'Old Kotzebue',
    'Old Lyme',
    'Old Ocean',
    'Old Orchard Beach',
    'Old Pekin',
    'Old Saybrook',
    'Old Town',
    'Old Westbury',
    'Oldfort',
    'Oldsmar',
    'Oldtown',
    'Oldwick',
    'Olean',
    'Oley',
    'Olin',
    'Olive Branch',
    'Olive Hill',
    'Olivebridge',
    'Oliveburg',
    'Olivehurst',
    'Oliver Springs',
    'Olivet',
    'Olivette',
    'Olivia',
    'Olla',
    'Ollie',
    'Olmito',
    'Olmsted',
    'Olmsted Falls',
    'Olmstedville',
    'Olney',
    'Olney Springs',
    'Olpe',
    'Olsburg',
    'Olton',
    'Olympia',
    'Olympia Fields',
    'Olyphant',
    'Omaha',
    'Omak',
    'Omega',
    'Omena',
    'Omro',
    'Ona',
    'Onaga',
    'Onalaska',
    'Onamia',
    'Onancock',
    'Onarga',
    'Onawa',
    'Onaway',
    'Oneco',
    'Oneida',
    'Onekama',
    'Oneonta',
    'Onia',
    'Onley',
    'Onondaga',
    'Onset',
    'Onslow',
    'Onsted',
    'Ontario',
    'Ontonagon',
    'Onyx',
    'Oologah',
    'Ooltewah',
    'Oostburg',
    'Opa-locka',
    'Opdyke',
    'Opelika',
    'Opelousas',
    'Opheim',
    'Ophiem',
    'Opolis',
    'Opp',
    'Oquawka',
    'Oracle',
    'Oradell',
    'Oran',
    'Orange',
    'Orange Beach',
    'Orange City',
    'Orange Cove',
    'Orange Grove',
    'Orange Park',
    'Orange Springs',
    'Orangeburg',
    'Orangevale',
    'Orangeville',
    'Orchard',
    'Orchard Hill',
    'Orchard Park',
    'Ord',
    'Orderville',
    'Ordway',
    'Ore City',
    'Oreana',
    'Orefield',
    'Oregon',
    'Oregon City',
    'Oregon House',
    'Oregonia',
    'Oreland',
    'Orem',
    'Orford',
    'Orfordville',
    'Orient',
    'Oriental',
    'Orinda',
    'Orion',
    'Oriska',
    'Oriskany',
    'Oriskany Falls',
    'Orland',
    'Orland Park',
    'Orlando',
    'Orleans',
    'Orlinda',
    'Orma',
    'Ormond Beach',
    'Orofino',
    'Orondo',
    'Orono',
    'Oronoco',
    'Oronogo',
    'Orosi',
    'Oroville',
    'Orrington',
    'Orrs Island',
    'Orrstown',
    'Orrtanna',
    'Orrville',
    'Orting',
    'Ortonville',
    'Orwell',
    'Orwigsburg',
    'Osage',
    'Osage Beach',
    'Osage City',
    'Osakis',
    'Osawatomie',
    'Osborn',
    'Osborne',
    'Osburn',
    'Osceola',
    'Osceola Mills',
    'Osco',
    'Oscoda',
    'Osgood',
    'Oshkosh',
    'Oshtemo',
    'Oskaloosa',
    'Oslo',
    'Osmond',
    'Osprey',
    'Osseo',
    'Ossian',
    'Ossineke',
    'Ossining',
    'Ossipee',
    'Osteen',
    'Osterville',
    'Ostrander',
    'Oswego',
    'Osyka',
    'Otego',
    'Othello',
    'Otho',
    'Otis',
    'Otis Orchards',
    'Otisco',
    'Otisville',
    'Otoe',
    'Otsego',
    'Ottawa',
    'Otter',
    'Otter Lake',
    'Otterbein',
    'Ottertail',
    'Otto',
    'Ottosen',
    'Ottoville',
    'Ottsville',
    'Ottumwa',
    'Otway',
    'Ouray',
    'Outing',
    'Outlook',
    'Overbrook',
    'Overgaard',
    'Overland Park',
    'Overpeck',
    'Overton',
    'Ovid',
    'Oviedo',
    'Owaneco',
    'Owasso',
    'Owatonna',
    'Owego',
    'Owen',
    'Owens Cross Roads',
    'Owensboro',
    'Owensburg',
    'Owensville',
    'Owenton',
    'Owings',
    'Owings Mills',
    'Owingsville',
    'Owosso',
    'Oxford',
    'Oxford Junction',
    'Oxnard',
    'Oxon Hill',
    'Oyster Bay',
    'Ozan',
    'Ozark',
    'Ozawkie',
    'Ozona',
    'Ozone',
    'Ozone Park',
    "Pa'auilo",
    'Pablo',
    'Pacific',
    'Pacific City',
    'Pacific Grove',
    'Pacific Junction',
    'Pacific Palisades',
    'Pacifica',
    'Packwood',
    'Pacoima',
    'Pacolet',
    'Paden',
    'Paden City',
    'Paducah',
    'Paeonian Springs',
    'Page',
    'Pageland',
    'Pageton',
    'Pagosa Springs',
    'Pahala',
    'Pahoa',
    'Pahokee',
    'Pahrump',
    'Paia',
    'Paige',
    'Painesdale',
    'Painesville',
    'Paint Lick',
    'Paint Rock',
    'Painted Post',
    'Painter',
    'Paintsville',
    'Pala',
    'Palacios',
    'Palatine',
    'Palatka',
    'Palermo',
    'Palestine',
    'Palisade',
    'Palisades',
    'Palisades Park',
    'Pall Mall',
    'Palm',
    'Palm Bay',
    'Palm Beach',
    'Palm Beach Gardens',
    'Palm City',
    'Palm Coast',
    'Palm Desert',
    'Palm Harbor',
    'Palm Springs',
    'Palmdale',
    'Palmer',
    'Palmer Lake',
    'Palmerdale',
    'Palmersville',
    'Palmerton',
    'Palmetto',
    'Palmyra',
    'Palo',
    'Palo Alto',
    'Palo Cedro',
    'Palo Verde',
    'Paloma',
    'Palos Heights',
    'Palos Hills',
    'Palos Park',
    'Palos Verdes Peninsula',
    'Palouse',
    'Pampa',
    'Pamplico',
    'Pamplin',
    'Pana',
    'Panaca',
    'Panacea',
    'Panama',
    'Panama City',
    'Panama City Beach',
    'Pandora',
    'Pangburn',
    'Panguitch',
    'Panhandle',
    'Panola',
    'Panora',
    'Panorama City',
    'Pansey',
    'Pantego',
    'Paola',
    'Paoli',
    'Paonia',
    'Papillion',
    'Parachute',
    'Paradis',
    'Paradise',
    'Paradise Valley',
    'Paradox',
    'Paragon',
    'Paragould',
    'Paramount',
    'Paramus',
    'Pardeeville',
    'Paris',
    'Parish',
    'Park',
    'Park City',
    'Park Falls',
    'Park Forest',
    'Park Hill',
    'Park Hills',
    'Park Rapids',
    'Park Ridge',
    'Park River',
    'Parker',
    'Parker City',
    'Parker Ford',
    'Parkers Lake',
    'Parkers Prairie',
    'Parkersburg',
    'Parkesburg',
    'Parkman',
    'Parksley',
    'Parkston',
    'Parkton',
    'Parkville',
    'Parlier',
    'Parlin',
    'Parma',
    'Parnell',
    'Parowan',
    'Parrish',
    'Parrottsville',
    'Parshall',
    'Parsippany',
    'Parsons',
    'Parsonsburg',
    'Parsonsfield',
    'Partlow',
    'Partridge',
    'Pasadena',
    'Pascagoula',
    'Pasco',
    'Pascoag',
    'Paso Robles',
    'Pass Christian',
    'Passaic',
    'Passumpsic',
    'Pataskala',
    'Patchogue',
    'Paterson',
    'Patoka',
    'Paton',
    'Patrick',
    'Patrick Springs',
    'Patriot',
    'Patten',
    'Patterson',
    'Pattersonville',
    'Pattison',
    'Patton',
    'Pattonsburg',
    'Pattonville',
    'Paul',
    'Paul Smiths',
    'Paulden',
    'Paulding',
    'Paulina',
    'Pauline',
    'Paullina',
    'Pauls Valley',
    'Paulsboro',
    'Pauma Valley',
    'Pavilion',
    'Pavillion',
    'Pavo',
    'Paw Paw',
    'Pawcatuck',
    'Pawhuska',
    'Pawlet',
    'Pawleys Island',
    'Pawling',
    'Pawnee',
    'Pawnee City',
    'Pawtucket',
    'Paxico',
    'Paxinos',
    'Paxton',
    'Payette',
    'Payne',
    'Paynesville',
    'Payneville',
    'Payson',
    'Pea Ridge',
    'Peabody',
    'Peace Dale',
    'Peach Bottom',
    'Peach Creek',
    'Peach Orchard',
    'Peach Springs',
    'Peachland',
    'Peachtree City',
    'Peak',
    'Peaks Island',
    'Peapack',
    'Pearblossom',
    'Pearce',
    'Pearcy',
    'Pearisburg',
    'Pearl',
    'Pearl City',
    'Pearl City, Manana',
    'Pearl River',
    'Pearland',
    'Pearlington',
    'Pearsall',
    'Pearson',
    'Pease',
    'Pebble Beach',
    'Pecatonica',
    'Peck',
    'Pecks Mill',
    'Peckville',
    'Peconic',
    'Pecos',
    'Peculiar',
    'Pedricktown',
    'Pedro Bay',
    'Peebles',
    'Peekskill',
    'Peel',
    'Peetz',
    'Peever',
    'Peggs',
    'Pegram',
    'Pekin',
    'Pelahatchie',
    'Pelham',
    'Pelican',
    'Pelican Lake',
    'Pelican Rapids',
    'Pelion',
    'Pelkie',
    'Pell City',
    'Pella',
    'Pellston',
    'Pelzer',
    'Pemberton',
    'Pemberville',
    'Pembina',
    'Pembine',
    'Pembroke',
    'Pembroke Pines',
    'Pen Argyl',
    'Penasco',
    'Pender',
    'Pendergrass',
    'Pendleton',
    'Penelope',
    'Penfield',
    'Pengilly',
    'Penhook',
    'Peninsula',
    'Penitas',
    'Penn',
    'Penn Laird',
    'Penn Run',
    'Penn Valley',
    'Penn Yan',
    'Pennellville',
    'Penney Farms',
    'Penngrove',
    'Pennington',
    'Pennington Gap',
    'Pennock',
    'Penns Grove',
    'Penns Park',
    'Pennsauken Township',
    'Pennsboro',
    'Pennsburg',
    'Pennsville Township',
    'Pennsylvania Furnace',
    'Pennville',
    'Penokee',
    'Penrose',
    'Penryn',
    'Pensacola',
    'Pentress',
    'Pentwater',
    'Peoria',
    'Peoria Heights',
    'Peosta',
    'Peotone',
    'Pepperell',
    'Pequannock',
    'Pequea',
    'Pequot Lakes',
    'Peralta',
    'Percy',
    'Perdido',
    'Perham',
    'Peridot',
    'Perkasie',
    'Perkins',
    'Perkinston',
    'Perkinsville',
    'Perkiomenville',
    'Perley',
    'Perrineville',
    'Perrinton',
    'Perris',
    'Perronville',
    'Perry',
    'Perry Hall',
    'Perry Point',
    'Perryman',
    'Perryopolis',
    'Perrysburg',
    'Perryton',
    'Perryville',
    'Pershing',
    'Persia',
    'Perth Amboy',
    'Peru',
    'Pescadero',
    'Peshastin',
    'Peshtigo',
    'Pesotum',
    'Petal',
    'Petaluma',
    'Peterborough',
    'Peterman',
    'Petersburg',
    'Petersham',
    'Peterson',
    'Peterstown',
    'Petoskey',
    'Petroleum',
    'Petrolia',
    'Petros',
    'Pettigrew',
    'Pevely',
    'Pewamo',
    'Pewaukee',
    'Pewee Valley',
    'Peyton',
    'Pfafftown',
    'Pflugerville',
    'Pharr',
    'Phelan',
    'Phelps',
    'Phenix',
    'Phenix City',
    'Phil Campbell',
    'Philadelphia',
    'Philip',
    'Philipp',
    'Philippi',
    'Philipsburg',
    'Phillips',
    'Phillipsburg',
    'Philo',
    'Philomath',
    'Philpot',
    'Phippsburg',
    'Phoenicia',
    'Phoenix',
    'Phoenixville',
    'Piasa',
    'Picabo',
    'Picayune',
    'Pickens',
    'Pickerington',
    'Pickett',
    'Pickstown',
    'Pickton',
    'Pico Rivera',
    'Picture Rocks',
    'Piedmont',
    'Pierce',
    'Pierce City',
    'Pierceton',
    'Piercy',
    'Piermont',
    'Pierpont',
    'Pierre',
    'Pierre Part',
    'Pierron',
    'Pierson',
    'Pierz',
    'Piffard',
    'Pigeon',
    'Pigeon Forge',
    'Piggott',
    'Pike Road',
    'Pikesville',
    'Piketon',
    'Pikeville',
    'Pillager',
    'Pilot',
    'Pilot Grove',
    'Pilot Hill',
    'Pilot Knob',
    'Pilot Mound',
    'Pilot Mountain',
    'Pilot Point',
    'Pilot Rock',
    'Pilot Station',
    'Pima',
    'Pima County',
    'Pimento',
    'Pinckney',
    'Pinckneyville',
    'Pinconning',
    'Pine',
    'Pine Beach',
    'Pine Bluff',
    'Pine Bluffs',
    'Pine Brook',
    'Pine Bush',
    'Pine City',
    'Pine Forge',
    'Pine Grove',
    'Pine Grove Mills',
    'Pine Hill',
    'Pine Island',
    'Pine Knot',
    'Pine Lake',
    'Pine Mountain',
    'Pine Mountain Club',
    'Pine Plains',
    'Pine Ridge',
    'Pine River',
    'Pine Top',
    'Pine Valley',
    'Pinebluff',
    'Pinecrest',
    'Pinedale',
    'Pinehill',
    'Pinehurst',
    'Pinellas Park',
    'Pineola',
    'Pinesdale',
    'Pinetop-Lakeside',
    'Pinetops',
    'Pinetown',
    'Pineville',
    'Pinewood',
    'Piney Creek',
    'Piney Flats',
    'Piney View',
    'Piney Woods',
    'Pingree',
    'Pink Hill',
    'Pinnacle',
    'Pinola',
    'Pinole',
    'Pinon',
    'Pinon Hills',
    'Pinson',
    'Pioche',
    'Pioneer',
    'Pioneertown',
    'Pipe Creek',
    'Piper City',
    'Pipersville',
    'Pipestone',
    'Piqua',
    'Pirtleville',
    'Piscataway',
    'Piseco',
    'Pisgah',
    'Pisgah Forest',
    'Pismo Beach',
    'Pitcairn',
    'Pitcher',
    'Pitkin',
    'Pitman',
    'Pittsboro',
    'Pittsburg',
    'Pittsburgh',
    'Pittsfield',
    'Pittsford',
    'Pittston',
    'Pittstown',
    'Pittsview',
    'Pittsville',
    'Pixley',
    'Placedo',
    'Placentia',
    'Placerville',
    'Placida',
    'Placitas',
    'Plain',
    'Plain City',
    'Plain Dealing',
    'Plainfield',
    'Plains',
    'Plainsboro',
    'Plainview',
    'Plainville',
    'Plainwell',
    'Plaistow',
    'Planada',
    'Plankinton',
    'Plano',
    'Plant City',
    'Plantation',
    'Plantersville',
    'Plantsville',
    'Plaquemine',
    'Plato',
    'Platte',
    'Platte City',
    'Plattekill',
    'Plattenville',
    'Platteville',
    'Plattsburg',
    'Plattsburgh',
    'Plattsmouth',
    'Playa del Rey',
    'Plaza',
    'Pleasant City',
    'Pleasant Dale',
    'Pleasant Garden',
    'Pleasant Grove',
    'Pleasant Hill',
    'Pleasant Hope',
    'Pleasant Lake',
    'Pleasant Mount',
    'Pleasant Plain',
    'Pleasant Plains',
    'Pleasant Prairie',
    'Pleasant Ridge',
    'Pleasant Shade',
    'Pleasant Unity',
    'Pleasant Valley',
    'Pleasant View',
    'Pleasanton',
    'Pleasantville',
    'Pleasureville',
    'Plentywood',
    'Plover',
    'Plum Branch',
    'Plum City',
    'Plumerville',
    'Plummer',
    'Plummers Landing',
    'Plumsteadville',
    'Plumville',
    'Plymouth',
    'Plymouth Meeting',
    'Plympton',
    'Poca',
    'Pocahontas',
    'Pocasset',
    'Pocatello',
    'Pocola',
    'Pocomoke City',
    'Pocono Pines',
    'Pocono Summit',
    'Poestenkill',
    'Point',
    'Point Arena',
    'Point Comfort',
    'Point Harbor',
    'Point Hope',
    'Point Lay',
    'Point Lookout',
    'Point Pleasant',
    'Point Pleasant Beach',
    'Point Reyes Station',
    'Point Roberts',
    'Point of Rocks',
    'Pointblank',
    'Polacca',
    'Poland',
    'Polk',
    'Polk City',
    'Polkton',
    'Polkville',
    'Pollard',
    'Pollock',
    'Pollock Pines',
    'Pollocksville',
    'Pollok',
    'Polo',
    'Polson',
    'Pomaria',
    'Pomerene',
    'Pomeroy',
    'Pomfret',
    'Pomfret Center',
    'Pomona',
    'Pomona Park',
    'Pompano Beach',
    'Pompey',
    'Pompton Lakes',
    'Pompton Plains',
    'Ponca',
    'Ponca City',
    'Ponce de Leon',
    'Ponchatoula',
    'Pond Creek',
    'Ponder',
    'Ponderay',
    'Ponemah',
    'Poneto',
    'Ponsford',
    'Ponte Vedra',
    'Ponte Vedra Beach',
    'Pontiac',
    'Pontotoc',
    'Pooler',
    'Poolesville',
    'Poolville',
    'Pope',
    'Pope Valley',
    'Poplar',
    'Poplar Bluff',
    'Poplar Branch',
    'Poplar Grove',
    'Poplarville',
    'Poquoson',
    'Porcupine',
    'Port Allegany',
    'Port Allen',
    'Port Angeles',
    'Port Aransas',
    'Port Arthur',
    'Port Austin',
    'Port Barre',
    'Port Bolivar',
    'Port Byron',
    'Port Carbon',
    'Port Charlotte',
    'Port Chester',
    'Port Clinton',
    'Port Crane',
    'Port Deposit',
    'Port Edwards',
    'Port Elizabeth',
    'Port Gibson',
    'Port Hadlock',
    'Port Henry',
    'Port Hope',
    'Port Hueneme',
    'Port Huron',
    'Port Isabel',
    'Port Jefferson',
    'Port Jefferson Station',
    'Port Jervis',
    'Port Lavaca',
    'Port Leyden',
    'Port Ludlow',
    'Port Mansfield',
    'Port Matilda',
    'Port Monmouth',
    'Port Murray',
    'Port Neches',
    'Port Norris',
    'Port Orange',
    'Port Orchard',
    'Port Orford',
    'Port Penn',
    'Port Reading',
    'Port Republic',
    'Port Richey',
    'Port Royal',
    'Port Saint Joe',
    'Port Saint Lucie',
    'Port Salerno',
    'Port Sanilac',
    'Port Tobacco',
    'Port Townsend',
    'Port Trevorton',
    'Port Washington',
    'Portage',
    'Portageville',
    'Portal',
    'Portales',
    'Porter',
    'Porter Corners',
    'Portersville',
    'Porterville',
    'Portland',
    'Portola',
    'Portola Valley',
    'Portsmouth',
    'Portville',
    'Porum',
    'Posen',
    'Poseyville',
    'Post',
    'Post Falls',
    'Post Mills',
    'Poston',
    'Postville',
    'Poteau',
    'Poteet',
    'Poth',
    'Potlatch',
    'Potomac',
    'Potosi',
    'Potsdam',
    'Potter',
    'Potter Valley',
    'Pottersville',
    'Potterville',
    'Potts Camp',
    'Pottsboro',
    'Pottstown',
    'Pottsville',
    'Potwin',
    'Poughkeepsie',
    'Poughquag',
    'Poulan',
    'Poulsbo',
    'Poultney',
    'Pound',
    'Pound Ridge',
    'Pounding Mill',
    'Poway',
    'Powder Springs',
    'Powderly',
    'Powell',
    'Powell Butte',
    'Powellsville',
    'Powellton',
    'Powers',
    'Powers Lake',
    'Powhatan',
    'Powhatan Point',
    'Pownal',
    'Poyen',
    'Poynette',
    'Poynor',
    'Poyntelle',
    'Prague',
    'Prairie',
    'Prairie City',
    'Prairie Farm',
    'Prairie Grove',
    'Prairie View',
    'Prairie Village',
    'Prairie du Chien',
    'Prairie du Rocher',
    'Prairie du Sac',
    'Prairieton',
    'Prairieville',
    'Prather',
    'Pratt',
    'Pratts',
    'Prattsburgh',
    'Prattsville',
    'Prattville',
    'Preble',
    'Preemption',
    'Premier',
    'Prentiss',
    'Prescott',
    'Prescott Valley',
    'Presho',
    'Presidio',
    'Presque Isle',
    'Presto',
    'Preston',
    'Preston City',
    'Preston Hollow',
    'Prestonsburg',
    'Prewitt',
    'Price',
    'Prichard',
    'Pride',
    'Priest River',
    'Primghar',
    'Primm Springs',
    'Primus',
    'Prince Frederick',
    'Prince George',
    'Princess Anne',
    'Princeton',
    'Princeton Junction',
    'Princeville',
    'Princewick',
    'Prineville',
    'Prinsburg',
    'Printer',
    'Prior Lake',
    'Pritchett',
    'Procious',
    'Proctor',
    'Proctorville',
    'Progreso',
    'Prompton',
    'Prophetstown',
    'Prospect',
    'Prospect Harbor',
    'Prospect Heights',
    'Prospect Park',
    'Prosper',
    'Prosperity',
    'Prosser',
    'Protection',
    'Protivin',
    'Providence',
    'Providence Forge',
    'Provincetown',
    'Provo',
    'Prudenville',
    'Prudhoe Bay',
    'Pryor',
    "Pu'unene",
    'Pueblo',
    'Puerto Rico',
    'Pukalani',
    'Pukwana',
    'Pulaski',
    'Pullman',
    'Pungoteague',
    'Punta Gorda',
    'Punxsutawney',
    'Purcell',
    'Purcellville',
    'Purchase',
    'Purdin',
    'Purdon',
    'Purdy',
    'Purdys',
    'Purgitsville',
    'Purlear',
    'Purling',
    'Purvis',
    'Puryear',
    'Put-in-Bay',
    'Putnam',
    'Putnam Station',
    'Putnam Valley',
    'Putney',
    'Puyallup',
    'Pylesville',
    'Quail',
    'Quakake',
    'Quaker City',
    'Quaker Hill',
    'Quakertown',
    'Quality',
    'Quanah',
    'Quantico',
    'Quapaw',
    'Quarryville',
    'Quartzsite',
    'Quasqueton',
    'Quebeck',
    'Quechee',
    'Queen Anne',
    'Queen City',
    'Queen Creek',
    'Queens Village',
    'Queensbury',
    'Queenstown',
    'Quemado',
    'Questa',
    'Quilcene',
    'Quimby',
    'Quincy',
    'Quinebaug',
    'Quinlan',
    'Quinnesec',
    'Quinter',
    'Quinton',
    'Quinwood',
    'Quitman',
    'Qulin',
    'Quogue',
    'Raccoon',
    'Raceland',
    'Racine',
    'Radcliff',
    'Radcliffe',
    'Radford',
    'Radisson',
    'Radnor',
    'Raeford',
    'Ragland',
    'Rahway',
    'Rainbow',
    'Rainbow City',
    'Rainelle',
    'Rainier',
    'Rainsville',
    'Raleigh',
    'Ralls',
    'Ralph',
    'Ralston',
    'Ramah',
    'Ramer',
    'Ramey',
    'Ramona',
    'Ramseur',
    'Ramsey',
    'Ranburne',
    'Ranchita',
    'Rancho Cordova',
    'Rancho Cucamonga',
    'Rancho Mirage',
    'Rancho Palos Verdes',
    'Rancho Santa Fe',
    'Rancho Santa Margarita',
    'Ranchos de Taos',
    'Rancocas',
    'Randall',
    'Randallstown',
    'Randle',
    'Randleman',
    'Randlett',
    'Randolph',
    'Randolph Center',
    'Randolph Township',
    'Random Lake',
    'Rangely',
    'Ranger',
    'Rankin',
    'Ransom',
    'Ransom Canyon',
    'Ransomville',
    'Ranson',
    'Rantoul',
    'Raphine',
    'Rapid City',
    'Rapid River',
    'Rapidan',
    'Raquette Lake',
    'Raritan',
    'Rathdrum',
    'Ratliff City',
    'Raton',
    'Rattan',
    'Ravalli',
    'Raven',
    'Ravena',
    'Ravencliff',
    'Ravenden',
    'Ravenel',
    'Ravenna',
    'Ravensdale',
    'Ravenswood',
    'Ravenwood',
    'Ravia',
    'Rawlings',
    'Rawlins',
    'Rawson',
    'Ray',
    'Ray Center',
    'Ray City',
    'Rayland',
    'Raymond',
    'Raymondville',
    'Raymore',
    'Rayne',
    'Raynham',
    'Rayville',
    'Raywick',
    'Readfield',
    'Reading',
    'Readlyn',
    'Readsboro',
    'Readyville',
    'Reamstown',
    'Reardan',
    'Rebecca',
    'Rebersburg',
    'Rector',
    'Red Bank',
    'Red Banks',
    'Red Bay',
    'Red Bluff',
    'Red Boiling Springs',
    'Red Bud',
    'Red Cloud',
    'Red Creek',
    'Red Hill',
    'Red Hook',
    'Red House',
    'Red Jacket',
    'Red Lake Falls',
    'Red Level',
    'Red Lion',
    'Red Lodge',
    'Red Oak',
    'Red River',
    'Red Rock',
    'Red Springs',
    'Red Wing',
    'Redan',
    'Redby',
    'Reddick',
    'Redding',
    'Redding Ridge',
    'Redfield',
    'Redford',
    'Redfox',
    'Redkey',
    'Redlands',
    'Redmon',
    'Redmond',
    'Redondo Beach',
    'Redwater',
    'Redway',
    'Redwood',
    'Redwood City',
    'Redwood Estates',
    'Redwood Falls',
    'Redwood Valley',
    'Reed City',
    'Reed Point',
    'Reedley',
    'Reeds',
    'Reeds Spring',
    'Reedsburg',
    'Reedsport',
    'Reedsville',
    'Reedville',
    'Reedy',
    'Reelsville',
    'Reese',
    'Reeseville',
    'Reevesville',
    'Reform',
    'Refugio',
    'Regent',
    'Register',
    'Rego Park',
    'Rehoboth',
    'Rehoboth Beach',
    'Reidsville',
    'Reidville',
    'Reinbeck',
    'Reinholds',
    'Reisterstown',
    'Reklaw',
    'Reliance',
    'Rembert',
    'Rembrandt',
    'Remer',
    'Remington',
    'Remlap',
    'Remote',
    'Remsen',
    'Remsenburg',
    'Remus',
    'Renfrew',
    'Renner',
    'Reno',
    'Renovo',
    'Rensselaer',
    'Rensselaer Falls',
    'Renton',
    'Rentz',
    'Renville',
    'Renwick',
    'Repton',
    'Republic',
    'Resaca',
    'Reseda',
    'Reserve',
    'Reston',
    'Revere',
    'Revillo',
    'Rex',
    'Rexburg',
    'Rexford',
    'Rexmont',
    'Rexville',
    'Reydell',
    'Reydon',
    'Reynolds',
    'Reynoldsburg',
    'Reynoldsville',
    'Rhame',
    'Rheems',
    'Rhine',
    'Rhinebeck',
    'Rhineland',
    'Rhinelander',
    'Rhoadesville',
    'Rhodelia',
    'Rhodell',
    'Rhodes',
    'Rhodesdale',
    'Rhodhiss',
    'Rhome',
    'Rialto',
    'Rib Lake',
    'Ribera',
    'Rice',
    'Rice Lake',
    'Rices Landing',
    'Riceville',
    'Rich Creek',
    'Rich Hill',
    'Rich Square',
    'Richards',
    'Richardson',
    'Richardton',
    'Richboro',
    'Richburg',
    'Richeyville',
    'Richfield',
    'Richfield Springs',
    'Richford',
    'Richgrove',
    'Richland',
    'Richland Center',
    'Richlands',
    'Richlandtown',
    'Richmond',
    'Richmond Hill',
    'Richton',
    'Richton Park',
    'Richvale',
    'Richview',
    'Richwood',
    'Rickman',
    'Rickreall',
    'Riddle',
    'Riddleton',
    'Riderwood',
    'Ridge',
    'Ridge Farm',
    'Ridge Spring',
    'Ridgecrest',
    'Ridgedale',
    'Ridgefield',
    'Ridgefield Park',
    'Ridgeland',
    'Ridgeley',
    'Ridgely',
    'Ridgeview',
    'Ridgeville',
    'Ridgeway',
    'Ridgewood',
    'Ridgway',
    'Ridley Park',
    'Ridott',
    'Riegelsville',
    'Riegelwood',
    'Rienzi',
    'Riesel',
    'Rifle',
    'Riga',
    'Rigby',
    'Riggins',
    'Riley',
    'Rillton',
    'Rimersburg',
    'Rimforest',
    'Rimrock',
    'Rinard',
    'Rincon',
    'Rindge',
    'Riner',
    'Rineyville',
    'Ringgold',
    'Ringle',
    'Ringling',
    'Ringoes',
    'Ringsted',
    'Ringtown',
    'Ringwood',
    'Rio',
    'Rio Dell',
    'Rio Grande',
    'Rio Grande City',
    'Rio Hondo',
    'Rio Linda',
    'Rio Nido',
    'Rio Rancho',
    'Rio Rico',
    'Rio Verde',
    'Rio Vista',
    'Ripley',
    'Ripon',
    'Rippey',
    'Ripplemead',
    'Ripton',
    'Rising City',
    'Rising Fawn',
    'Rising Star',
    'Rising Sun',
    'Risingsun',
    'Rison',
    'Rittman',
    'Ritzville',
    'Riva',
    'River Edge',
    'River Falls',
    'River Forest',
    'River Grove',
    'River Pines',
    'River Rouge',
    'Riverbank',
    'Riverdale',
    'Riverdale Hills',
    'Riverhead',
    'Riverside',
    'Riverside County',
    'Riverton',
    'Riverview',
    'Rives',
    'Rives Junction',
    'Rivesville',
    'Riviera',
    'Rixeyville',
    'Roach',
    'Roachdale',
    'Roan Mountain',
    'Roann',
    'Roanoke',
    'Roanoke Rapids',
    'Roaring Branch',
    'Roaring River',
    'Roaring Spring',
    'Robards',
    'Robbins',
    'Robbinsville',
    'Robersonville',
    'Robert',
    'Robert Lee',
    'Roberta',
    'Roberts',
    'Robertsdale',
    'Robertson',
    'Robertsville',
    'Robesonia',
    'Robins',
    'Robinson',
    'Robinson Creek',
    'Robinsonville',
    'Robstown',
    'Roby',
    'Roca',
    'Rochdale',
    'Rochelle',
    'Rochelle Park',
    'Rochester',
    'Rociada',
    'Rock',
    'Rock Creek',
    'Rock Falls',
    'Rock Hall',
    'Rock Hill',
    'Rock Island',
    'Rock Port',
    'Rock Rapids',
    'Rock River',
    'Rock Spring',
    'Rock Springs',
    'Rock Tavern',
    'Rock Valley',
    'Rock View',
    'Rockaway',
    'Rockaway Beach',
    'Rockaway Park',
    'Rockbridge',
    'Rockdale',
    'Rockfield',
    'Rockford',
    'Rockholds',
    'Rockhouse',
    'Rockingham',
    'Rockland',
    'Rockledge',
    'Rocklin',
    'Rockmart',
    'Rockport',
    'Rocksprings',
    'Rockton',
    'Rockvale',
    'Rockville',
    'Rockville Centre',
    'Rockwall',
    'Rockwell',
    'Rockwell City',
    'Rockwood',
    'Rocky',
    'Rocky Face',
    'Rocky Ford',
    'Rocky Gap',
    'Rocky Hill',
    'Rocky Mount',
    'Rocky Point',
    'Rocky River',
    'Rodanthe',
    'Rodeo',
    'Roderfield',
    'Rodman',
    'Rodney',
    'Roebling',
    'Roebuck',
    'Rogers',
    'Rogers City',
    'Rogersville',
    'Roggen',
    'Rogue River',
    'Rohnert Park',
    'Rohrersville',
    'Roland',
    'Rolesville',
    'Rolfe',
    'Roll',
    'Rolla',
    'Rolling Fork',
    'Rolling Meadows',
    'Rolling Prairie',
    'Rollingbay',
    'Rollingstone',
    'Rollinsford',
    'Roma',
    'Rome',
    'Rome City',
    'Romeo',
    'Romeoville',
    'Romney',
    'Romulus',
    'Ronald',
    'Ronan',
    'Ronceverte',
    'Ronda',
    'Ronkonkoma',
    'Ronks',
    'Roodhouse',
    'Roosevelt',
    'Rootstown',
    'Roper',
    'Ropesville',
    'Rosalia',
    'Rosamond',
    'Rosburg',
    'Roscoe',
    'Roscommon',
    'Rose Bud',
    'Rose City',
    'Rose Creek',
    'Rose Hill',
    'Roseau',
    'Roseboro',
    'Rosebud',
    'Roseburg',
    'Rosebush',
    'Rosedale',
    'Roseland',
    'Roselle',
    'Roselle Park',
    'Rosemary Beach',
    'Rosemead',
    'Rosemont',
    'Rosemount',
    'Rosenberg',
    'Rosendale',
    'Rosenhayn',
    'Rosepine',
    'Roseville',
    'Rosewood',
    'Rosharon',
    'Rosholt',
    'Rosiclare',
    'Rosine',
    'Roslindale',
    'Roslyn',
    'Roslyn Heights',
    'Rosman',
    'Ross',
    'Rosser',
    'Rossford',
    'Rossiter',
    'Rossville',
    'Roswell',
    'Rotan',
    'Rothbury',
    'Rothsay',
    'Rothschild',
    'Rotonda',
    'Rotterdam Junction',
    'Rougemont',
    'Rough and Ready',
    'Roulette',
    'Round Hill',
    'Round Lake',
    'Round Mountain',
    'Round O',
    'Round Rock',
    'Round Top',
    'Roundhill',
    'Roundup',
    'Rouses Point',
    'Rousseau',
    'Rowan',
    'Rowe',
    'Rowena',
    'Rowesville',
    'Rowland',
    'Rowland Heights',
    'Rowlesburg',
    'Rowlett',
    'Rowley',
    'Roxana',
    'Roxboro',
    'Roxbury',
    'Roxie',
    'Roy',
    'Roy-Winifred Junction',
    'Royal',
    'Royal Center',
    'Royal City',
    'Royal Oak',
    'Royal Palm Beach',
    'Royalton',
    'Royersford',
    'Royse City',
    'Royston',
    'Rozet',
    'Rubicon',
    'Ruby',
    'Ruckersville',
    'Rudd',
    'Rudolph',
    'Rudyard',
    'Ruffin',
    'Ruffs Dale',
    'Rugby',
    'Ruidoso',
    'Ruidoso Downs',
    'Rule',
    'Ruleville',
    'Rulo',
    'Rumford',
    'Rumney',
    'Rumsey',
    'Rumson',
    'Runge',
    'Runnells',
    'Runnemede',
    'Running Springs',
    'Rupert',
    'Rural Hall',
    'Rural Retreat',
    'Rural Ridge',
    'Rural Valley',
    'Rush',
    'Rush Center',
    'Rush City',
    'Rush Hill',
    'Rush Valley',
    'Rushford',
    'Rushmore',
    'Rushville',
    'Rusk',
    'Ruskin',
    'Russell',
    'Russell Springs',
    'Russells Point',
    'Russellton',
    'Russellville',
    'Russia',
    'Russian Mission',
    'Russiaville',
    'Rustburg',
    'Ruston',
    'Ruth',
    'Ruther Glen',
    'Rutherford',
    'Rutherfordton',
    'Ruthton',
    'Ruthven',
    'Rutland',
    'Rutledge',
    'Ryan',
    'Rydal',
    'Ryde',
    'Rye',
    'Sabattus',
    'Sabetha',
    'Sabillasville',
    'Sabin',
    'Sabina',
    'Sabinal',
    'Sabot',
    'Sabula',
    'Sac City',
    'Sacaton',
    'Sachse',
    'Sackets Harbor',
    'Saco',
    'Sacramento',
    'Sacred Heart',
    'Saddle Brook',
    'Saddle River',
    'Sadieville',
    'Sadler',
    'Saegertown',
    'Safety Harbor',
    'Safford',
    'Sag Harbor',
    'Sagamore',
    'Sagamore Beach',
    'Sagaponack',
    'Saginaw',
    'Sagle',
    'Saguache',
    'Sahuarita',
    'Sailor Springs',
    'Saint Agatha',
    'Saint Albans',
    'Saint Amant',
    'Saint Ann',
    'Saint Anne',
    'Saint Ansgar',
    'Saint Anthony',
    'Saint Bonaventure',
    'Saint Boniface',
    'Saint Bonifacius',
    'Saint Catharine',
    'Saint Charles',
    'Saint Clair',
    'Saint Clair Shores',
    'Saint Clairsville',
    'Saint Cloud',
    'Saint Croix Falls',
    'Saint David',
    'Saint Edward',
    'Saint Elizabeth',
    'Saint Elmo',
    'Saint Francis',
    'Saint Francisville',
    'Saint Gabriel',
    'Saint George',
    'Saint Germain',
    'Saint Hedwig',
    'Saint Helen',
    'Saint Helena',
    'Saint Helens',
    'Saint Henry',
    'Saint Hilaire',
    'Saint Ignace',
    'Saint Ignatius',
    'Saint Jacob',
    'Saint James',
    'Saint James City',
    'Saint Joe',
    'Saint John',
    'Saint Johns',
    'Saint Johnsbury',
    'Saint Johnsville',
    'Saint Joseph',
    'Saint Leo',
    'Saint Leonard',
    'Saint Libory',
    'Saint Louis',
    'Saint Louis Park',
    'Saint Louisville',
    'Saint Marie',
    'Saint Maries',
    'Saint Marks',
    'Saint Martin',
    'Saint Martinville',
    'Saint Mary',
    'Saint Marys',
    'Saint Matthews',
    'Saint Meinrad',
    'Saint Michael',
    'Saint Michaels',
    'Saint Nazianz',
    'Saint Onge',
    'Saint Paris',
    'Saint Paul',
    'Saint Paul Park',
    'Saint Pauls',
    'Saint Peter',
    'Saint Peters',
    'Saint Petersburg',
    'Saint Regis',
    'Saint Regis Falls',
    'Saint Robert',
    'Saint Simons Island',
    'Saint Stephen',
    'Saint Stephens',
    'Saint Thomas',
    'Sainte Marie',
    'Salado',
    'Salamanca',
    'Sale City',
    'Sale Creek',
    'Salem',
    'Salemburg',
    'Salesville',
    'Salida',
    'Salina',
    'Salinas',
    'Saline',
    'Salineville',
    'Salisbury',
    'Salisbury Mills',
    'Salix',
    'Salkum',
    'Sallis',
    'Sallisaw',
    'Salmon',
    'Salome',
    'Salt Lake City',
    'Salt Lick',
    'Salt Point',
    'Salt Rock',
    'Salters',
    'Saltillo',
    'Salton City',
    'Saltsburg',
    'Saltville',
    'Saluda',
    'Salvisa',
    'Salyer',
    'Salyersville',
    'Sammamish',
    'Samoa',
    'Samson',
    'San Andreas',
    'San Angelo',
    'San Anselmo',
    'San Antonio',
    'San Augustine',
    'San Benito',
    'San Bernardino',
    'San Bernardino County',
    'San Bruno',
    'San Carlos',
    'San Clemente',
    'San Cristobal',
    'San Diego',
    'San Dimas',
    'San Elizario',
    'San Felipe',
    'San Fernando',
    'San Fidel',
    'San Francisco',
    'San Gabriel',
    'San Geronimo',
    'San Gregorio',
    'San Jacinto',
    'San Joaquin',
    'San Jon',
    'San Jose',
    'San Juan',
    'San Juan Bautista',
    'San Juan Capistrano',
    'San Leandro',
    'San Lorenzo',
    'San Luis',
    'San Luis Obispo',
    'San Luis Rey Heights',
    'San Manuel',
    'San Marcos',
    'San Marino',
    'San Martin',
    'San Mateo',
    'San Miguel',
    'San Pablo',
    'San Pedro',
    'San Pierre',
    'San Rafael',
    'San Ramon',
    'San Saba',
    'San Simeon',
    'San Simon',
    'San Ysidro',
    'Sanborn',
    'Sanbornton',
    'Sanbornville',
    'Sand Coulee',
    'Sand Creek',
    'Sand Fork',
    'Sand Lake',
    'Sand Point',
    'Sand Springs',
    'Sandborn',
    'Sanders',
    'Sanderson',
    'Sandersville',
    'Sandia',
    'Sandia Park',
    'Sandoval',
    'Sandoval County',
    'Sandown',
    'Sandpoint',
    'Sandston',
    'Sandstone',
    'Sandusky',
    'Sandwich',
    'Sandy',
    'Sandy City',
    'Sandy Creek',
    'Sandy Hook',
    'Sandy Lake',
    'Sandy Ridge',
    'Sandy Spring',
    'Sandyville',
    'Sanford',
    'Sanger',
    'Sangerville',
    'Sanibel',
    'Santa Ana',
    'Santa Ana Heights',
    'Santa Anna',
    'Santa Barbara',
    'Santa Clara',
    'Santa Clarita',
    'Santa Claus',
    'Santa Cruz',
    'Santa Fe',
    'Santa Fe Springs',
    'Santa Margarita',
    'Santa Maria',
    'Santa Monica',
    'Santa Paula',
    'Santa Rosa',
    'Santa Rosa Beach',
    'Santa Teresa',
    'Santa Ynez',
    'Santa Ysabel',
    'Santaquin',
    'Santee',
    'Santo',
    'Sapphire',
    'Sapulpa',
    'Sarah',
    'Sarah Ann',
    'Sarahsville',
    'Saraland',
    'Saranac',
    'Saranac Lake',
    'Sarasota',
    'Saratoga',
    'Saratoga Springs',
    'Sarcoxie',
    'Sardinia',
    'Sardis',
    'Sarepta',
    'Sargent',
    'Sarita',
    'Sarona',
    'Saronville',
    'Sartell',
    'Sarver',
    'Sasakwa',
    'Satanta',
    'Satartia',
    'Satellite Beach',
    'Satsop',
    'Satsuma',
    'Saucier',
    'Saugatuck',
    'Saugerties',
    'Saugus',
    'Sauk Centre',
    'Sauk City',
    'Sauk Rapids',
    'Saukville',
    'Saulsbury',
    'Saulsville',
    'Sault Ste. Marie',
    'Saunderstown',
    'Saunemin',
    'Sauquoit',
    'Sausalito',
    'Savage',
    'Savanna',
    'Savannah',
    'Savonburg',
    'Savoy',
    'Sawyer',
    'Saxon',
    'Saxonburg',
    'Saxton',
    'Saxtons River',
    'Saybrook',
    'Saylorsburg',
    'Sayre',
    'Sayreville',
    'Sayville',
    'Scales Mound',
    'Scammon Bay',
    'Scandia',
    'Scandinavia',
    'Scappoose',
    'Scarborough',
    'Scarbro',
    'Scarsdale',
    'Scarville',
    'Scenery Hill',
    'Schaefferstown',
    'Schaghticoke',
    'Schaller',
    'Schaumburg',
    'Schellsburg',
    'Schenectady',
    'Schenevus',
    'Schenley',
    'Schererville',
    'Schertz',
    'Schiller Park',
    'Schlater',
    'Schleswig',
    'Schnecksville',
    'Schneider',
    'Schodack Landing',
    'Schoenchen',
    'Schofield',
    'Schoharie',
    'Schoolcraft',
    'Schroon Lake',
    'Schulenburg',
    'Schulter',
    'Schurz',
    'Schuyler',
    'Schuylerville',
    'Schuylkill Haven',
    'Schwenksville',
    'Science Hill',
    'Scio',
    'Sciota',
    'Scipio',
    'Scipio Center',
    'Scituate',
    'Scobey',
    'Scotch Plains',
    'Scotia',
    'Scotland',
    'Scotland Neck',
    'Scotrun',
    'Scott',
    'Scott Air Force Base',
    'Scott City',
    'Scott Depot',
    'Scottdale',
    'Scottown',
    'Scotts',
    'Scotts Hill',
    'Scotts Mills',
    'Scotts Valley',
    'Scottsbluff',
    'Scottsboro',
    'Scottsburg',
    'Scottsdale',
    'Scottsville',
    'Scottville',
    'Scranton',
    'Scribner',
    'Scroggins',
    'Scurry',
    'Sea Cliff',
    'Sea Girt',
    'Sea Island',
    'Sea Isle City',
    'Sea Ranch',
    'Seabeck',
    'Seabrook',
    'Seadrift',
    'Seaford',
    'Seagoville',
    'Seagraves',
    'Seagrove',
    'Seahurst',
    'Seal',
    'Seal Beach',
    'Seal Cove',
    'Seal Rock',
    'Seale',
    'Sealevel',
    'Sealy',
    'Seaman',
    'Searcy',
    'Searsboro',
    'Searsmont',
    'Seaside',
    'Seaside Heights',
    'Seaside Park',
    'Seaton',
    'Seatonville',
    'Seattle',
    'Sebastian',
    'Sebastopol',
    'Sebec',
    'Sebeka',
    'Sebewaing',
    'Sebree',
    'Sebring',
    'Secaucus',
    'Second Mesa',
    'Secor',
    'Section',
    'Sedalia',
    'Sedan',
    'Sedgwick',
    'Sedona',
    'Sedro-Woolley',
    'Seekonk',
    'Seeley',
    'Seeley Lake',
    'Seffner',
    'Seguin',
    'Seiling',
    'Selah',
    'Selawik',
    'Selby',
    'Selbyville',
    'Selden',
    'Seligman',
    'Selinsgrove',
    'Selkirk',
    'Sellersburg',
    'Sellersville',
    'Sells',
    'Selma',
    'Selmer',
    'Seminary',
    'Seminole',
    'Semmes',
    'Senath',
    'Senatobia',
    'Seneca',
    'Seneca Falls',
    'Senecaville',
    'Senoia',
    'Sentinel Butte',
    'Sequim',
    'Sequoia',
    'Serafina',
    'Serena',
    'Sergeant Bluff',
    'Sergeantsville',
    'Sesser',
    'Seth',
    'Seven Mile',
    'Seven Springs',
    'Seven Valleys',
    'Severance',
    'Severn',
    'Severna Park',
    'Severy',
    'Sevierville',
    'Seville',
    'Sewanee',
    'Seward',
    'Sewaren',
    'Sewell',
    'Sewickley',
    'Seymour',
    'Shabbona',
    'Shacklefords',
    'Shade Gap',
    'Shady Cove',
    'Shady Dale',
    'Shady Point',
    'Shady Side',
    'Shady Spring',
    'Shady Valley',
    'Shadyside',
    'Shafer',
    'Shafter',
    'Shaftsbury',
    'Shakopee',
    'Shalimar',
    'Shallotte',
    'Shallowater',
    'Shamokin',
    'Shamokin Dam',
    'Shamrock',
    'Shandaken',
    'Shandon',
    'Shanks',
    'Shanksville',
    'Shannon',
    'Shannon City',
    'Shapleigh',
    'Sharon',
    'Sharon Center',
    'Sharon Hill',
    'Sharon Springs',
    'Sharpsburg',
    'Sharpsville',
    'Sharptown',
    'Shartlesville',
    'Shasta',
    'Shattuck',
    'Shavertown',
    'Shaw',
    'Shaw Island',
    'Shawano',
    'Shawboro',
    'Shawnee',
    'Shawnee on Delaware',
    'Shawneetown',
    'Shawsville',
    'Sheboygan',
    'Sheboygan Falls',
    'Shedd',
    'Sheep Springs',
    'Sheffield',
    'Sheffield Lake',
    'Shelbiana',
    'Shelburn',
    'Shelburne',
    'Shelburne Falls',
    'Shelby',
    'Shelbyville',
    'Sheldahl',
    'Sheldon',
    'Sheldon Springs',
    'Shell',
    'Shell Knob',
    'Shell Lake',
    'Shell Rock',
    'Shelley',
    'Shellman',
    'Shellsburg',
    'Shelly',
    'Shelocta',
    'Shelter Island',
    'Shelter Island Heights',
    'Shelton',
    'Shenandoah',
    'Shenandoah Junction',
    'Shepherd',
    'Shepherdstown',
    'Shepherdsville',
    'Sherborn',
    'Sherburn',
    'Sherburne',
    'Sheridan',
    'Sherman',
    'Sherman Oaks',
    'Shermans Dale',
    'Sherrard',
    'Sherrill',
    'Sherrills Ford',
    'Sherrodsville',
    'Sherwood',
    'Shevlin',
    'Sheyenne',
    'Shickley',
    'Shickshinny',
    'Shidler',
    'Shiloh',
    'Shiner',
    'Shingle Springs',
    'Shinglehouse',
    'Shingleton',
    'Shingletown',
    'Shinnston',
    'Shiocton',
    'Shipman',
    'Shippensburg',
    'Shippenville',
    'Shippingport',
    'Shiprock',
    'Shipshewana',
    'Shirley',
    'Shirley Mills',
    'Shirleysburg',
    'Shoals',
    'Shobonier',
    'Shoemakersville',
    'Shohola',
    'Shokan',
    'Shongaloo',
    'Shoreham',
    'Shorewood',
    'Short Hills',
    'Shoshone',
    'Shoshoni',
    'Show Low',
    'Shreve',
    'Shreveport',
    'Shrewsbury',
    'Shrub Oak',
    'Shubuta',
    'Shumway',
    'Shungnak',
    'Shutesbury',
    'Sibley',
    'Sicily Island',
    'Sicklerville',
    'Side Lake',
    'Sidell',
    'Sidman',
    'Sidney',
    'Sidney Center',
    'Sidon',
    'Siemens',
    'Sierra Madre',
    'Sierra Vista',
    'Signal Hill',
    'Signal Mountain',
    'Sigourney',
    'Sikes',
    'Sikeston',
    'Silas',
    'Siler City',
    'Silex',
    'Siloam',
    'Siloam Springs',
    'Silsbee',
    'Silt',
    'Silva',
    'Silvana',
    'Silver Bay',
    'Silver City',
    'Silver Creek',
    'Silver Grove',
    'Silver Lake',
    'Silver Spring',
    'Silver Springs',
    'Silverado',
    'Silverdale',
    'Silverhill',
    'Silverstreet',
    'Silverthorne',
    'Silverton',
    'Silvis',
    'Simi Valley',
    'Simla',
    'Simmesport',
    'Simms',
    'Simon',
    'Simonton',
    'Simpson',
    'Simpsonville',
    'Sims',
    'Simsboro',
    'Simsbury',
    'Sinclair',
    'Sinclairville',
    'Sinks Grove',
    'Sinton',
    'Sioux Center',
    'Sioux City',
    'Sioux Falls',
    'Sioux Rapids',
    'Sipesville',
    'Sipsey',
    'Siren',
    'Sisseton',
    'Sister Bay',
    'Sisters',
    'Sistersville',
    'Sitka',
    'Six Lakes',
    'Six Mile',
    'Skaguay',
    'Skamokawa',
    'Skandia',
    'Skaneateles',
    'Skaneateles Falls',
    'Skanee',
    'Skellytown',
    'Skiatook',
    'Skidmore',
    'Skillman',
    'Skippack',
    'Skokie',
    'Skowhegan',
    'Skyforest',
    'Skykomish',
    'Skyland',
    'Slagle',
    'Slanesville',
    'Slate Hill',
    'Slater',
    'Slatersville',
    'Slatington',
    'Slaton',
    'Slaty Fork',
    'Slaughter',
    'Slaughters',
    'Slayton',
    'Sleepy Eye',
    'Slidell',
    'Sligo',
    'Slinger',
    'Slingerlands',
    'Slippery Rock',
    'Sloan',
    'Sloansville',
    'Sloatsburg',
    'Slocomb',
    'Sloughhouse',
    'Slovan',
    'Smackover',
    'Smarr',
    'Smartt',
    'Smethport',
    'Smilax',
    'Smiley',
    'Smith Center',
    'Smith River',
    'Smithers',
    'Smithfield',
    'Smithland',
    'Smiths',
    'Smiths Creek',
    'Smiths Grove',
    'Smithsburg',
    'Smithshire',
    'Smithton',
    'Smithtown',
    'Smithville',
    'Smithville Flats',
    'Smoaks',
    'Smock',
    'Smoke Run',
    'Smoketown',
    'Smyer',
    'Smyrna',
    'Smyrna Mills',
    'Sneads',
    'Sneads Ferry',
    'Sneedville',
    'Snelling',
    'Snellville',
    'Snohomish',
    'Snoqualmie',
    'Snover',
    'Snow Camp',
    'Snow Hill',
    'Snow Shoe',
    'Snowflake',
    'Snowmass',
    'Snowmass Village',
    'Snyder',
    'Soap Lake',
    'Sobieski',
    'Social Circle',
    'Society Hill',
    'Socorro',
    'Sod',
    'Soda Springs',
    'Soddy-Daisy',
    'Sodus',
    'Solana Beach',
    'Soldier',
    'Soldiers Grove',
    'Soldotna',
    'Solebury',
    'Soledad',
    'Solen',
    'Solomons',
    'Solon',
    'Solon Springs',
    'Solsberry',
    'Solvang',
    'Solway',
    'Somerdale',
    'Somers',
    'Somers Point',
    'Somerset',
    'Somerset Center',
    'Somersworth',
    'Somerton',
    'Somerville',
    'Somis',
    'Somonauk',
    'Sonoita',
    'Sonoma',
    'Sonora',
    'Sopchoppy',
    'Soper',
    'Soperton',
    'Sophia',
    'Soquel',
    'Sorento',
    'Sorrento',
    'Soso',
    'Soudan',
    'Souderton',
    'Soulsbyville',
    'Sound Beach',
    'Sour Lake',
    'South Amboy',
    'South Barre',
    'South Bay',
    'South Beach',
    'South Beloit',
    'South Bend',
    'South Berwick',
    'South Boardman',
    'South Boston',
    'South Bound Brook',
    'South Bristol',
    'South Burlington',
    'South Cairo',
    'South Canaan',
    'South Casco',
    'South Charleston',
    'South China',
    'South Dartmouth',
    'South Deerfield',
    'South Dennis',
    'South Easton',
    'South Egremont',
    'South El Monte',
    'South Elgin',
    'South English',
    'South Fallsburg',
    'South Fork',
    'South Freeport',
    'South Fulton',
    'South Gate',
    'South Gibson',
    'South Glastonbury',
    'South Glens Falls',
    'South Hackensack',
    'South Hadley',
    'South Hamilton',
    'South Harwich',
    'South Haven',
    'South Hero',
    'South Hill',
    'South Holland',
    'South Houston',
    'South Hutchinson',
    'South Jamesport',
    'South Jordan',
    'South Kent',
    'South Lake Tahoe',
    'South Lancaster',
    'South Lebanon',
    'South Londonderry',
    'South Lyon',
    'South Mills',
    'South Milwaukee',
    'South New Berlin',
    'South Newfane',
    'South Orange',
    'South Otselic',
    'South Ozone Park',
    'South Padre Island',
    'South Paris',
    'South Park Township',
    'South Pasadena',
    'South Pekin',
    'South Pittsburg',
    'South Plainfield',
    'South Plymouth',
    'South Point',
    'South Pomfret',
    'South Portland',
    'South Prairie',
    'South Range',
    'South River',
    'South Rockwood',
    'South Roxana',
    'South Royalton',
    'South Ryegate',
    'South Saint Paul',
    'South Salem',
    'South Sanford',
    'South Seaville',
    'South Shore',
    'South Sioux City',
    'South Solon',
    'South Sterling',
    'South Strafford',
    'South Tamworth',
    'South Vienna',
    'South Wales',
    'South Wayne',
    'South Webster',
    'South Wellfleet',
    'South West City',
    'South Weymouth',
    'South Whitley',
    'South Williamson',
    'South Willington',
    'South Wilmington',
    'South Windsor',
    'South Woodstock',
    'South Yarmouth',
    'Southampton',
    'Southaven',
    'Southborough',
    'Southbridge',
    'Southbury',
    'Southern Pines',
    'Southfield',
    'Southfields',
    'Southgate',
    'Southington',
    'Southlake',
    'Southmayd',
    'Southold',
    'Southport',
    'Southside',
    'Southview',
    'Southwest Harbor',
    'Southwick',
    'Spain',
    'Spalding',
    'Spanaway',
    'Spanish Fork',
    'Spanish Fort',
    'Spanishburg',
    'Sparkill',
    'Sparkman',
    'Sparks',
    'Sparland',
    'Sparrow Bush',
    'Sparrows Point',
    'Sparta',
    'Spartanburg',
    'Spartansburg',
    'Spavinaw',
    'Spearfish',
    'Spearman',
    'Spearsville',
    'Spearville',
    'Speculator',
    'Spencer',
    'Spencerport',
    'Spencertown',
    'Spencerville',
    'Sperry',
    'Sperryville',
    'Spiceland',
    'Spicer',
    'Spicewood',
    'Spickard',
    'Spindale',
    'Spinnerstown',
    'Spirit Lake',
    'Spiro',
    'Spivey',
    'Splendora',
    'Spofford',
    'Spokane',
    'Spooner',
    'Spotswood',
    'Spotsylvania',
    'Spout Spring',
    'Spraggs',
    'Sprakers',
    'Spreckels',
    'Spring',
    'Spring Arbor',
    'Spring Branch',
    'Spring Church',
    'Spring City',
    'Spring Creek',
    'Spring Glen',
    'Spring Green',
    'Spring Grove',
    'Spring Hill',
    'Spring Hope',
    'Spring House',
    'Spring Lake',
    'Spring Lake Park',
    'Spring Mills',
    'Spring Park',
    'Spring Run',
    'Spring Valley',
    'Springboro',
    'Springdale',
    'Springer',
    'Springerville',
    'Springfield',
    'Springfield Gardens',
    'Springfield Township',
    'Springhill',
    'Springport',
    'Springtown',
    'Springvale',
    'Springview',
    'Springville',
    'Springwater',
    'Spruce',
    'Spruce Pine',
    'Spurlockville',
    'Squaw Lake',
    'Squaw Valley',
    'Squires',
    'St Louis',
    'St. Augustine',
    'St. Charles',
    'St. Genevieve',
    'St. Helena',
    'St. Joseph',
    'St. Rose',
    'Staatsburg',
    'Stacy',
    'Stacyville',
    'Stafford',
    'Stafford Springs',
    'Staffordsville',
    'Stahlstown',
    'Staley',
    'Stamford',
    'Stanardsville',
    'Stanberry',
    'Stanchfield',
    'Standard',
    'Standish',
    'Stanfield',
    'Stanford',
    'Stanfordville',
    'Stanhope',
    'Stanley',
    'Stanton',
    'Stantonsburg',
    'Stanville',
    'Stanwood',
    'Staples',
    'Stapleton',
    'Star',
    'Star City',
    'Star Lake',
    'Star Prairie',
    'Starbuck',
    'Starford',
    'Stark',
    'Stark City',
    'Starke',
    'Starksboro',
    'Starkville',
    'Starkweather',
    'Starr',
    'Startex',
    'State Center',
    'State College',
    'State Line',
    'State Road',
    'State University',
    'Stateline',
    'Staten Island',
    'Statenville',
    'Statesboro',
    'Statesville',
    'Statham',
    'Staunton',
    'Stayton',
    'Steamboat Rock',
    'Steamboat Springs',
    'Steamburg',
    'Stearns',
    'Stebbins',
    'Stedman',
    'Steedman',
    'Steele',
    'Steele City',
    'Steeleville',
    'Steelville',
    'Steen',
    'Steens',
    'Steep Falls',
    'Steger',
    'Steilacoom',
    'Steinauer',
    'Steinhatchee',
    'Stella',
    'Stem',
    'Stephen',
    'Stephens',
    'Stephens City',
    'Stephenson',
    'Stephensport',
    'Stephentown',
    'Stephenville',
    'Sterling',
    'Sterling Forest',
    'Sterling Heights',
    'Sterlington',
    'Sterrett',
    'Stetson',
    'Stetsonville',
    'Steuben',
    'Steubenville',
    'Stevens',
    'Stevens Point',
    'Stevenson',
    'Stevenson Ranch',
    'Stevensville',
    'Stevinson',
    'Steward',
    'Stewardson',
    'Stewart',
    'Stewartstown',
    'Stewartsville',
    'Stewartville',
    'Stickney',
    'Stigler',
    'Stilesville',
    'Still Pond',
    'Stillman Valley',
    'Stillmore',
    'Stillwater',
    'Stilwell',
    'Stinesville',
    'Stinnett',
    'Stinson Beach',
    'Stirling',
    'Stites',
    'Stittville',
    'Stockbridge',
    'Stockdale',
    'Stockertown',
    'Stockholm',
    'Stockport',
    'Stockton',
    'Stockton Springs',
    'Stoddard',
    'Stokesdale',
    'Stollings',
    'Stone',
    'Stone Creek',
    'Stone Harbor',
    'Stone Lake',
    'Stone Mountain',
    'Stone Park',
    'Stone Ridge',
    'Stoneboro',
    'Stonefort',
    'Stoneham',
    'Stoneville',
    'Stonewall',
    'Stonington',
    'Stony Brook',
    'Stony Creek',
    'Stony Point',
    'Stonyford',
    'Storden',
    'Storm Lake',
    'Stormville',
    'Storrs',
    'Story',
    'Story City',
    'Stotts City',
    'Stoughton',
    'Stout',
    'Stoutland',
    'Stover',
    'Stow',
    'Stowe',
    'Stoystown',
    'Strafford',
    'Strasburg',
    'Stratford',
    'Stratham',
    'Strathmore',
    'Strattanville',
    'Stratton',
    'Straughn',
    'Strawberry',
    'Strawberry Plains',
    'Strawberry Point',
    'Strawn',
    'Streamwood',
    'Streator',
    'Street',
    'Streeter',
    'Streetman',
    'Streetsboro',
    'Stringer',
    'Stromsburg',
    'Strong',
    'Stronghurst',
    'Strongstown',
    'Strongsville',
    'Stroud',
    'Stroudsburg',
    'Strum',
    'Strunk',
    'Struthers',
    'Stryker',
    'Stuart',
    'Stuarts Draft',
    'Studio City',
    'Stump Creek',
    'Sturbridge',
    'Sturgeon',
    'Sturgeon Bay',
    'Sturgeon Lake',
    'Sturgis',
    'Sturtevant',
    'Stuttgart',
    'Stuyvesant',
    'Suamico',
    'Sublette',
    'Sublimity',
    'Succasunna',
    'Suches',
    'Sudan',
    'Sudbury',
    'Sudlersville',
    'Suffern',
    'Suffield',
    'Suffolk',
    'Sugar City',
    'Sugar Grove',
    'Sugar Hill',
    'Sugar Land',
    'Sugar Loaf',
    'Sugar Run',
    'Sugar Tree',
    'Sugar Valley',
    'Sugarcreek',
    'Sugarloaf',
    'Sugarloaf Township',
    'Sugartown',
    'Suisun',
    'Suitland',
    'Sula',
    'Sulligent',
    'Sullivan',
    "Sullivan's Island",
    'Sully',
    'Sulphur',
    'Sulphur Bluff',
    'Sulphur Springs',
    'Sultan',
    'Sumas',
    'Sumerduck',
    'Sumiton',
    'Summer Shade',
    'Summerdale',
    'Summerfield',
    'Summerhill',
    'Summerland',
    'Summerland Key',
    'Summers',
    'Summersville',
    'Summerton',
    'Summertown',
    'Summerville',
    'Summit',
    'Summit Hill',
    'Summit Lake',
    'Summit Station',
    'Summitville',
    'Sumner',
    'Sumrall',
    'Sumter',
    'Sun',
    'Sun City',
    'Sun City Center',
    'Sun City West',
    'Sun Prairie',
    'Sun River',
    'Sun Valley',
    'Sunapee',
    'Sunbright',
    'Sunburg',
    'Sunburst',
    'Sunbury',
    'Suncook',
    'Sundance',
    'Sunderland',
    'Sundown',
    'Sunflower',
    'Sunland',
    'Sunman',
    'Sunnyside',
    'Sunnyvale',
    'Sunol',
    'Sunray',
    'Sunrise',
    'Sunrise Beach',
    'Sunset',
    'Sunset Beach',
    'Sunshine',
    'Superior',
    'Supply',
    'Suquamish',
    'Surgoinsville',
    'Suring',
    'Surprise',
    'Surrency',
    'Surry',
    'Susanville',
    'Susquehanna',
    'Sussex',
    'Sutersville',
    'Sutherland',
    'Sutherland Springs',
    'Sutherlin',
    'Sutter',
    'Sutter Creek',
    'Sutton',
    'Suttons Bay',
    'Suwanee',
    'Suwannee',
    'Swainsboro',
    'Swaledale',
    'Swampscott',
    'Swan',
    'Swannanoa',
    'Swans Island',
    'Swansboro',
    'Swansea',
    'Swanton',
    'Swanville',
    'Swanzey',
    'Swarthmore',
    'Swartswood',
    'Swartz',
    'Swartz Creek',
    'Swayzee',
    'Swedesboro',
    'Sweeny',
    'Sweet',
    'Sweet Home',
    'Sweet Springs',
    'Sweet Valley',
    'Sweet Water',
    'Sweetser',
    'Sweetwater',
    'Swiftwater',
    'Swink',
    'Swisher',
    'Swisshome',
    'Switz City',
    'Switzer',
    'Swoope',
    'Sybertsville',
    'Sycamore',
    'Sykeston',
    'Sykesville',
    'Sylacauga',
    'Sylmar',
    'Sylva',
    'Sylvan Beach',
    'Sylvan Grove',
    'Sylvania',
    'Sylvester',
    'Symsonia',
    'Syosset',
    'Syracuse',
    'Taberg',
    'Tabernash',
    'Table Grove',
    'Tabor',
    'Tabor City',
    'Tacoma',
    'Taft',
    'Tafton',
    'Taftville',
    'Tahlequah',
    'Tahoe City',
    'Tahoe Valley',
    'Tahoe Vista',
    'Tahoka',
    'Taholah',
    'Tahoma',
    'Tahuya',
    'Takoma Park',
    'Talala',
    'Talbott',
    'Talbotton',
    'Talco',
    'Talcott',
    'Talent',
    'Talihina',
    'Talkeetna',
    'Talking Rock',
    'Tall Timbers',
    'Talladega',
    'Tallahassee',
    'Tallapoosa',
    'Tallassee',
    'Tallmadge',
    'Tallulah',
    'Talmo',
    'Talmoon',
    'Taloga',
    'Tama',
    'Tamaqua',
    'Tamassee',
    'Tamms',
    'Tampa',
    'Tampico',
    'Tamworth',
    'Taneytown',
    'Taneyville',
    'Tangent',
    'Tangerine',
    'Tangier',
    'Tangipahoa',
    'Tanner',
    'Tannersville',
    'Taos',
    'Taos Ski Valley',
    'Tappahannock',
    'Tappan',
    'Tappen',
    'Tar Heel',
    'Tarawa Terrace I',
    'Tarboro',
    'Tarentum',
    'Tariffville',
    'Tarkio',
    'Tarpon Springs',
    'Tarrs',
    'Tarrytown',
    'Tarzana',
    'Tasley',
    'Tatamy',
    'Tate',
    'Tatum',
    'Taunton',
    'Tavares',
    'Tavernier',
    'Tawas City',
    'Taylor',
    'Taylor Springs',
    'Taylors',
    'Taylors Falls',
    'Taylorsville',
    'Taylorville',
    'Tazewell',
    'Tea',
    'Teachey',
    'Teague',
    'Teaneck',
    'Teasdale',
    'Tebbetts',
    'Tecumseh',
    'Teec Nos Pos',
    'Tehachapi',
    'Tehama',
    'Tekamah',
    'Tekoa',
    'Tekonsha',
    'Telephone',
    'Telford',
    'Tell City',
    'Teller',
    'Tellico Plains',
    'Telluride',
    'Telogia',
    'Temecula',
    'Tempe',
    'Temperance',
    'Temple',
    'Temple City',
    'Temple Hills',
    'Templeton',
    'Ten Mile',
    'Ten Sleep',
    'Tenafly',
    'Tenaha',
    'Tenants Harbor',
    'Tenino',
    'Tennent',
    'Tennessee',
    'Tennessee Colony',
    'Tennessee Ridge',
    'Tennille',
    'Tensed',
    'Tenstrike',
    'Terlingua',
    'Terlton',
    'Terra Alta',
    'Terra Bella',
    'Terrace Park',
    'Terre Haute',
    'Terre Hill',
    'Terrebonne',
    'Terrell',
    'Terreton',
    'Terril',
    'Terry',
    'Terryville',
    'Tescott',
    'Tesuque',
    'Teterboro',
    'Teton',
    'Teton Village',
    'Tetonia',
    'Teutopolis',
    'Tewksbury',
    'Texarkana',
    'Texas City',
    'Texhoma',
    'Texico',
    'Texline',
    'Thackerville',
    'Thatcher',
    'Thaxton',
    'Thayer',
    'Thayne',
    'The Bronx',
    'The Colony',
    'The Dalles',
    'The Plains',
    'The Rock',
    'The Villages',
    'Thelma',
    'Thendara',
    'Theodore',
    'Theresa',
    'Thermal',
    'Thermopolis',
    'Thetford Center',
    'Thibodaux',
    'Thief River Falls',
    'Thiells',
    'Thiensville',
    'Thomas',
    'Thomasboro',
    'Thomaston',
    'Thomasville',
    'Thompson',
    'Thompson Falls',
    "Thompson's Station",
    'Thompsontown',
    'Thompsonville',
    'Thomson',
    'Thonotosassa',
    'Thoreau',
    'Thorn Hill',
    'Thornburg',
    'Thorndale',
    'Thorndike',
    'Thorne Bay',
    'Thornton',
    'Thorntown',
    'Thornville',
    'Thornwood',
    'Thorofare',
    'Thorp',
    'Thousand Oaks',
    'Thousand Palms',
    'Thrall',
    'Three Bridges',
    'Three Forks',
    'Three Lakes',
    'Three Mile Bay',
    'Three Oaks',
    'Three Rivers',
    'Three Springs',
    'Throckmorton',
    'Thurmond',
    'Thurmont',
    'Thurston',
    'Tiburon',
    'Tickfaw',
    'Ticonderoga',
    'Tidioute',
    'Tierra Amarilla',
    'Tieton',
    'Tiffin',
    'Tifton',
    'Tigard',
    'Tiger',
    'Tigerton',
    'Tigerville',
    'Tignall',
    'Tijeras',
    'Tilden',
    'Tilghman',
    'Tiline',
    'Tillamook',
    'Tillar',
    'Tilleda',
    'Tillson',
    'Tilton',
    'Tiltonsville',
    'Timber Lake',
    'Timberlake',
    'Timberon',
    'Timberville',
    'Timbo',
    'Timewell',
    'Timmonsville',
    'Timpson',
    'Tingley',
    'Tinley Park',
    'Tioga',
    'Tiona',
    'Tionesta',
    'Tipp City',
    'Tipton',
    'Tiptonville',
    'Tire Hill',
    'Tiro',
    'Tishomingo',
    'Tiskilwa',
    'Titonka',
    'Titus',
    'Titusville',
    'Tiverton',
    'Tivoli',
    'Toano',
    'Tobaccoville',
    'Tobyhanna',
    'Toccoa',
    'Toccoa Falls',
    'Todd',
    'Toddville',
    'Tofte',
    'Togiak',
    'Toivola',
    'Tok',
    'Toksook Bay',
    'Tolar',
    'Toledo',
    'Tolland',
    'Tollesboro',
    'Tolleson',
    'Tollhouse',
    'Tolna',
    'Tolono',
    'Toluca',
    'Tom Bean',
    'Tomah',
    'Tomahawk',
    'Tomball',
    'Tombstone',
    'Tomkins Cove',
    'Tompkinsville',
    'Toms Brook',
    'Toms River',
    'Tonasket',
    'Tonawanda',
    'Toney',
    'Tonganoxie',
    'Tonica',
    'Tonkawa',
    'Tonopah',
    'Tontitown',
    'Tonto Basin',
    'Tony',
    'Tooele',
    'Toomsboro',
    'Toomsuba',
    'Toone',
    'Topanga',
    'Topeka',
    'Topock',
    'Toppenish',
    'Topping',
    'Topsfield',
    'Topsham',
    'Topton',
    'Tornado',
    'Tornillo',
    'Toronto',
    'Torrance',
    'Torreon',
    'Torrey',
    'Torrington',
    'Totowa',
    'Totz',
    'Tougaloo',
    'Toughkenamon',
    'Toulon',
    'Toutle',
    'Tovey',
    'Towaco',
    'Towanda',
    'Tower',
    'Tower City',
    'Tower Hill',
    'Town Creek',
    'Town of Boxborough',
    'Town of Bozrah',
    'Town of East Lyme',
    'Town of Mount Desert',
    'Towner',
    'Townsend',
    'Townshend',
    'Townville',
    'Towson',
    'Toxey',
    'Trabuco Canyon',
    'Tracy',
    'Tracy City',
    'Tracys Landing',
    'Tracyton',
    'Trade',
    'Traer',
    'Trafalgar',
    'Trafford',
    'Trail',
    'Transfer',
    'Traphill',
    'Trappe',
    'Travelers Rest',
    'Traverse City',
    'Treadwell',
    'Trego',
    'Treichlers',
    'Trementina',
    'Tremont',
    'Tremonton',
    'Trempealeau',
    'Trenary',
    'Trent',
    'Trenton',
    'Tres Pinos',
    'Tresckow',
    'Trevett',
    'Trevor',
    'Trevorton',
    'Trexlertown',
    'Treynor',
    'Trezevant',
    'Triadelphia',
    'Triangle',
    'Tribes Hill',
    'Tribune',
    'Trimble',
    'Trimont',
    'Trinidad',
    'Trinity',
    'Trion',
    'Tripoli',
    'Tripp',
    'Triumph',
    'Trivoli',
    'Trona',
    'Tropic',
    'Trosper',
    'Troup',
    'Troupsburg',
    'Trout Creek',
    'Trout Run',
    'Troutdale',
    'Troutman',
    'Troutville',
    'Troy',
    'Truchas',
    'Truckee',
    'Trufant',
    'Truman',
    'Trumann',
    'Trumansburg',
    'Trumbauersville',
    'Trumbull',
    'Truro',
    'Trussville',
    'Truth or Consequences',
    'Truxton',
    'Tryon',
    'Tsaile',
    'Tualatin',
    'Tuba City',
    'Tubac',
    'Tuckahoe',
    'Tuckasegee',
    'Tucker',
    'Tuckerman',
    'Tuckerton',
    'Tucson',
    'Tucumcari',
    'Tujunga',
    'Tulare',
    'Tulare County',
    'Tularosa',
    'Tulelake',
    'Tulia',
    'Tullahoma',
    'Tully',
    'Tulsa',
    'Tumacacori',
    'Tumtum',
    'Tumwater',
    'Tunas',
    'Tunbridge',
    'Tunica',
    'Tunkhannock',
    'Tunnel Hill',
    'Tunnelton',
    'Tuntutuliak',
    'Tuolumne',
    'Tupelo',
    'Tupper Lake',
    'Turbeville',
    'Turbotville',
    'Turin',
    'Turkey',
    'Turkey Creek',
    'Turlock',
    'Turner',
    'Turners Falls',
    'Turners Station',
    'Turnerville',
    'Turpin',
    'Turtle Creek',
    'Turtle Lake',
    'Tuscaloosa',
    'Tuscarawas',
    'Tuscola',
    'Tuscumbia',
    'Tuskegee',
    'Tustin',
    'Tutor Key',
    'Tuttle',
    'Tutwiler',
    'Tuxedo Park',
    'Twain Harte',
    'Twelve Mile',
    'Twentynine Palms',
    'Twin Bridges',
    'Twin City',
    'Twin Falls',
    'Twin Lake',
    'Twin Lakes',
    'Twin Peaks',
    'Twin Valley',
    'Twining',
    'Twinsburg',
    'Twisp',
    'Two Buttes',
    'Two Harbors',
    'Two Rivers',
    'Tyaskin',
    'Tybee Island',
    'Tybo',
    'Tygh Valley',
    'Tyler',
    'Tylertown',
    'Tyndall',
    'Tyner',
    'Tyngsboro',
    'Tyringham',
    'Tyrone',
    'Tyronza',
    'Ubly',
    'Udall',
    'Uhrichsville',
    'Ukiah',
    'Uledi',
    'Ulen',
    'Ullin',
    'Ulm',
    'Ulster',
    'Ulster Park',
    'Ulysses',
    'Umatilla',
    'Umpire',
    'Unadilla',
    'Unalakleet',
    'Unalaska',
    'Uncasville',
    'Underhill',
    'Underwood',
    'Unicoi',
    'Union',
    'Union Bridge',
    'Union City',
    'Union Dale',
    'Union Grove',
    'Union Hall',
    'Union Hill',
    'Union Lake',
    'Union Mills',
    'Union Point',
    'Union Springs',
    'Union Star',
    'Union Township',
    'Uniondale',
    'Uniontown',
    'Unionville',
    'Unity',
    'Universal City',
    'University',
    'University Park',
    'University Place',
    'Unorganized Territory of Camp Lejeune',
    'Upatoi',
    'Upland',
    'Upper Abbot',
    'Upper Black Eddy',
    'Upper Darby',
    'Upper Falls',
    'Upper Jay',
    'Upper Marlboro',
    'Upper Saddle River',
    'Upper Sandusky',
    'Upperco',
    'Upperville',
    'Upsala',
    'Upton',
    'Urbana',
    'Urbandale',
    'Urbanna',
    'Uriah',
    'Urich',
    'Ursa',
    'Ute',
    'Utica',
    'Utopia',
    'Uvalda',
    'Uvalde',
    'Uxbridge',
    'Vacaville',
    'Vacherie',
    'Vader',
    'Vail',
    'Vails Gate',
    'Valatie',
    'Valders',
    'Valdese',
    'Valdez',
    'Valdosta',
    'Vale',
    'Valencia',
    'Valentine',
    'Valentines',
    'Valera',
    'Valhalla',
    'Valhermoso Springs',
    'Valier',
    'Valle',
    'Vallecito',
    'Vallejo',
    'Valles Mines',
    'Valley',
    'Valley Bend',
    'Valley Center',
    'Valley City',
    'Valley Cottage',
    'Valley Falls',
    'Valley Farms',
    'Valley Forge',
    'Valley Green',
    'Valley Head',
    'Valley Mills',
    'Valley Park',
    'Valley Springs',
    'Valley Stream',
    'Valley View',
    'Valley Village',
    'Valleyford',
    'Valliant',
    'Vallonia',
    'Valmeyer',
    'Valparaiso',
    'Valrico',
    'Van',
    'Van Alstyne',
    'Van Buren',
    'Van Dyne',
    'Van Etten',
    'Van Horn',
    'Van Horne',
    'Van Lear',
    'Van Meter',
    'Van Nuys',
    'Van Vleck',
    'Van Vleet',
    'Van Wert',
    'Van Wyck',
    'Vance',
    'Vanceburg',
    'Vancleave',
    'Vancouver',
    'Vandalia',
    'Vanderbilt',
    'Vandergrift',
    'Vandiver',
    'Vanleer',
    'Vanlue',
    'Vansant',
    'Vanzant',
    'Vardaman',
    'Varna',
    'Varnville',
    'Vashon',
    'Vass',
    'Vassalboro',
    'Vassar',
    'Vaughan',
    'Vaughn',
    'Vauxhall',
    'Veblen',
    'Veedersburg',
    'Vega',
    'Velarde',
    'Velva',
    'Vendor',
    'Venedocia',
    'Veneta',
    'Venetia',
    'Venice',
    'Ventnor City',
    'Ventress',
    'Ventura',
    'Venus',
    'Veradale',
    'Verbank',
    'Verdi',
    'Verdigre',
    'Verdon',
    'Verdugo City',
    'Verdunville',
    'Vergas',
    'Vergennes',
    'Vermilion',
    'Vermillion',
    'Vermont',
    'Vermontville',
    'Vernal',
    'Verndale',
    'Vernon',
    'Vernon Center',
    'Vernon Hill',
    'Vernon Hills',
    'Vernonia',
    'Vero Beach',
    'Verona',
    'Verplanck',
    'Versailles',
    'Vershire',
    'Vesper',
    'Vesta',
    'Vestaburg',
    'Vestal',
    'Vesuvius',
    'Vevay',
    'Veyo',
    'Vian',
    'Viborg',
    'Viburnum',
    'Vicco',
    'Vici',
    'Vicksburg',
    'Victor',
    'Victoria',
    'Victorville',
    'Vida',
    'Vidalia',
    'Vidor',
    'Vienna',
    'Vilas',
    'Villa Grove',
    'Villa Park',
    'Villa Rica',
    'Villa Ridge',
    'Village',
    'Village Mills',
    'Villanova',
    'Villanueva',
    'Villard',
    'Villas',
    'Ville Platte',
    'Villisca',
    'Vilonia',
    'Vina',
    'Vinalhaven',
    'Vincennes',
    'Vincent',
    'Vincentown',
    'Vine Grove',
    'Vineland',
    'Vinemont',
    'Vineyard Haven',
    'Vining',
    'Vinita',
    'Vinton',
    'Vintondale',
    'Viola',
    'Violet',
    'Violet Hill',
    'Viper',
    'Virden',
    'Virgie',
    'Virgilina',
    'Virgin',
    'Virginia',
    'Virginia Beach',
    'Viroqua',
    'Visalia',
    'Vista',
    'Vivian',
    'Volborg',
    'Volcano',
    'Volga',
    'Voluntown',
    'Von Ormy',
    'Vona',
    'Vonore',
    'Voorhees Township',
    'Voorheesville',
    'Vulcan',
    'WaKeeney',
    'Waban',
    'Wabash',
    'Wabasha',
    'Wabasso',
    'Waccabuc',
    'Wachapreague',
    'Waco',
    'Waconia',
    'Waddell',
    'Waddy',
    'Wade',
    'Wadena',
    'Wadesboro',
    'Wadesville',
    'Wading River',
    'Wadley',
    'Wadmalaw Island',
    'Wadsworth',
    'Waelder',
    'Wagener',
    'Wagner',
    'Wagoner',
    'Wahiawa',
    'Wahkon',
    'Wahoo',
    'Wahpeton',
    "Wai'anae",
    'Waialua',
    'Waikoloa',
    'Wailuku',
    'Waimanalo',
    'Wainscott',
    'Wainwright',
    'Waipahu',
    'Waite Park',
    'Waitsburg',
    'Waitsfield',
    'Wakarusa',
    'Wake',
    'Wake Forest',
    'Wakefield',
    'Wakeman',
    'Wakita',
    'Wakonda',
    'Walbridge',
    'Walcott',
    'Walden',
    'Waldo',
    'Waldoboro',
    'Waldorf',
    'Waldport',
    'Waldron',
    'Waldwick',
    'Wales',
    'Waleska',
    'Walford',
    'Walhalla',
    'Walhonding',
    'Walker',
    'Walker Valley',
    'Walkersville',
    'Walkerton',
    'Walkertown',
    'Walkerville',
    'Wall',
    'Wall Lake',
    'Walla Walla',
    'Wallace',
    'Wallaceton',
    'Walland',
    'Wallback',
    'Wallburg',
    'Walled Lake',
    'Waller',
    'Wallingford',
    'Wallington',
    'Wallins Creek',
    'Wallis',
    'Wallkill',
    'Wallowa',
    'Walls',
    'Wallsburg',
    'Walnut',
    'Walnut Bottom',
    'Walnut Cove',
    'Walnut Creek',
    'Walnut Grove',
    'Walnut Ridge',
    'Walnut Shade',
    'Walnut Springs',
    'Walnutport',
    'Walpole',
    'Walsenburg',
    'Walsh',
    'Walshville',
    'Walstonburg',
    'Walterboro',
    'Walters',
    'Waltham',
    'Walthill',
    'Walton',
    'Waltonville',
    'Walworth',
    'Wamego',
    'Wampsville',
    'Wampum',
    'Wamsutter',
    'Wana',
    'Wanakena',
    'Wanamingo',
    'Wanaque',
    'Wanatah',
    'Wanchese',
    'Waneta',
    'Wanette',
    'Wannaska',
    'Wantagh',
    'Wapakoneta',
    'Wapato',
    'Wapella',
    'Wapello',
    'Wappapello',
    'Wappingers Falls',
    'Wapwallopen',
    'War',
    'Warba',
    'Ward',
    'Warden',
    'Wardensville',
    'Ware',
    'Ware Neck',
    'Ware Shoals',
    'Wareham',
    'Waretown',
    'Warfordsburg',
    'Warm Springs',
    'Warminster',
    'Warne',
    'Warner',
    'Warner Robins',
    'Warner Springs',
    'Warners',
    'Warren',
    'Warren Center',
    'Warren Township',
    'Warrendale',
    'Warrens',
    'Warrensburg',
    'Warrensville',
    'Warrenton',
    'Warrenville',
    'Warrington',
    'Warrior',
    'Warriors Mark',
    'Warroad',
    'Warsaw',
    'Wartburg',
    'Wartrace',
    'Warwick',
    'Wasco',
    'Waseca',
    'Washburn',
    'Washington',
    'Washington Boro',
    'Washington Court House',
    'Washington Crossing',
    'Washington Grove',
    'Washington Township',
    'Washingtonville',
    'Washougal',
    'Wasilla',
    'Waskom',
    'Wassaic',
    'Watauga',
    'Watchung',
    'Water Mill',
    'Water Valley',
    'Waterboro',
    'Waterbury',
    'Waterbury Center',
    'Waterford',
    'Waterford Flat',
    'Waterford Works',
    'Waterloo',
    'Waterman',
    'Waterport',
    'Waterproof',
    'Waters',
    'Watersmeet',
    'Watertown',
    'Waterville',
    'Waterville Valley',
    'Watervliet',
    'Watford City',
    'Wathena',
    'Watkins',
    'Watkins Glen',
    'Watkinsville',
    'Watonga',
    'Watseka',
    'Watson',
    'Watsontown',
    'Watsonville',
    'Watts',
    'Wattsburg',
    'Wattsville',
    'Waubun',
    'Wauchula',
    'Waucoma',
    'Wauconda',
    'Waukee',
    'Waukegan',
    'Waukesha',
    'Waukomis',
    'Waukon',
    'Waunakee',
    'Wauneta',
    'Waupaca',
    'Waupun',
    'Wauregan',
    'Waurika',
    'Wausa',
    'Wausau',
    'Wauseon',
    'Wautoma',
    'Waveland',
    'Waverly',
    'Waverly Hall',
    'Wawaka',
    'Waxahachie',
    'Waxhaw',
    'Waycross',
    'Wayland',
    'Waymart',
    'Wayne',
    'Wayne City',
    'Waynesboro',
    'Waynesburg',
    'Waynesfield',
    'Waynesville',
    'Waynetown',
    'Waynoka',
    'Wayzata',
    'Weare',
    'Weatherford',
    'Weatherly',
    'Weatogue',
    'Weaubleau',
    'Weaver',
    'Weaverville',
    'Webb',
    'Webb City',
    'Webbers Falls',
    'Webberville',
    'Weber City',
    'Webster',
    'Webster City',
    'Webster Springs',
    'Wedowee',
    'Weed',
    'Weedsport',
    'Weedville',
    'Weehawken',
    'Weeksbury',
    'Weems',
    'Weeping Water',
    'Weidman',
    'Weimar',
    'Weiner',
    'Weippe',
    'Weir',
    'Weirsdale',
    'Weirton',
    'Weiser',
    'Welaka',
    'Welch',
    'Welches',
    'Welcome',
    'Weldon',
    'Weleetka',
    'Wellborn',
    'Wellesley',
    'Wellesley Hills',
    'Wellfleet',
    'Wellford',
    'Wellington',
    'Wellman',
    'Wellpinit',
    'Wells',
    'Wellsboro',
    'Wellsburg',
    'Wellsley',
    'Wellston',
    'Wellsville',
    'Wellton',
    'Welsh',
    'Wenatchee',
    'Wendel',
    'Wendell',
    'Wenden',
    'Wendover',
    'Wenham',
    'Wenona',
    'Wenonah',
    'Wentworth',
    'Wentzville',
    'Wernersville',
    'Weskan',
    'Weslaco',
    'Wesley',
    'Wesley Chapel',
    'Wessington',
    'Wessington Springs',
    'Wesson',
    'West',
    'West Alexander',
    'West Alexandria',
    'West Alton',
    'West Babylon',
    'West Baldwin',
    'West Barnstable',
    'West Bend',
    'West Berlin',
    'West Blocton',
    'West Bloomfield',
    'West Boylston',
    'West Branch',
    'West Briar',
    'West Bridgewater',
    'West Brookfield',
    'West Burke',
    'West Burlington',
    'West Chatham',
    'West Chazy',
    'West Chester',
    'West Chesterfield',
    'West Chicago',
    'West Columbia',
    'West Concord',
    'West Corinth',
    'West Cornwall',
    'West Covina',
    'West Coxsackie',
    'West Creek',
    'West Danville',
    'West Decatur',
    'West Dennis',
    'West Des Moines',
    'West Dover',
    'West Dummerston',
    'West Dundee',
    'West Durham',
    'West Edmeston',
    'West End',
    'West Enfield',
    'West Falls',
    'West Falmouth',
    'West Fargo',
    'West Farmington',
    'West Finley',
    'West Fork',
    'West Frankfort',
    'West Friendship',
    'West Glover',
    'West Granby',
    'West Green',
    'West Greenwich',
    'West Groton',
    'West Grove',
    'West Hamlin',
    'West Harrison',
    'West Hartford',
    'West Hartland',
    'West Harwich',
    'West Haven',
    'West Haverstraw',
    'West Helena',
    'West Hempstead',
    'West Henrietta',
    'West Hills',
    'West Hollywood',
    'West Islip',
    'West Jefferson',
    'West Jordan',
    'West Kingston',
    'West Lafayette',
    'West Lebanon',
    'West Leyden',
    'West Liberty',
    'West Linn',
    'West Long Branch',
    'West Louisville',
    'West Manchester',
    'West Mansfield',
    'West McLean',
    'West Memphis',
    'West Middlesex',
    'West Middleton',
    'West Mifflin',
    'West Milford',
    'West Milton',
    'West Mineral',
    'West Minot',
    'West Monroe',
    'West New York',
    'West Newbury',
    'West Newton',
    'West Nyack',
    'West Olive',
    'West Orange',
    'West Paducah',
    'West Palm Beach',
    'West Paris',
    'West Park',
    'West Pawlet',
    'West Pittsburg',
    'West Plains',
    'West Point',
    'West Portsmouth',
    'West Richland',
    'West River',
    'West Rockport',
    'West Roxbury',
    'West Rutland',
    'West Sacramento',
    'West Salem',
    'West Sand Lake',
    'West Sayville',
    'West Shokan',
    'West Simsbury',
    'West Springfield',
    'West Stockbridge',
    'West Stockholm',
    'West Suffield',
    'West Sunbury',
    'West Swanzey',
    'West Terre Haute',
    'West Tisbury',
    'West Topsham',
    'West Townsend',
    'West Union',
    'West Unity',
    'West Valley',
    'West Van Lear',
    'West Wardsboro',
    'West Wareham',
    'West Warwick',
    'West Wendover',
    'West Winfield',
    'West Yarmouth',
    'West Yellowstone',
    'Westboro',
    'Westborough',
    'Westbrook',
    'Westbrookville',
    'Westbury',
    'Westby',
    'Westchester',
    'Westcliffe',
    'Westerlo',
    'Westerly',
    'Western Grove',
    'Western Springs',
    'Westernport',
    'Westernville',
    'Westerville',
    'Westfield',
    'Westfield Center',
    'Westford',
    'Westgate',
    'Westhampton',
    'Westhampton Beach',
    'Westhope',
    'Westlake',
    'Westlake Village',
    'Westland',
    'Westley',
    'Westminster',
    'Westmont',
    'Westmoreland',
    'Westmoreland City',
    'Weston',
    'Westover',
    'Westphalia',
    'Westpoint',
    'Westport',
    'Westside',
    'Westtown',
    'Westview',
    'Westville',
    'Westwego',
    'Westwood',
    'Wethersfield',
    'Wetmore',
    'Wetumka',
    'Wetumpka',
    'Wewahitchka',
    'Wewoka',
    'Wexford',
    'Weyauwega',
    'Weyerhaeuser',
    'Weyers Cave',
    'Weymouth',
    'Whaleyville',
    'Wharncliffe',
    'Wharton',
    'What Cheer',
    'Whately',
    'Whatley',
    'Wheat Ridge',
    'Wheatfield',
    'Wheatland',
    'Wheaton',
    'Wheeler',
    'Wheelersburg',
    'Wheeling',
    'Wheelwright',
    'Whigham',
    'Whippany',
    'Whipple',
    'Whitakers',
    'White',
    'White Bear Lake',
    'White Bird',
    'White Bluff',
    'White Castle',
    'White City',
    'White Cloud',
    'White Deer',
    'White Hall',
    'White Haven',
    'White Heath',
    'White House',
    'White Lake',
    'White Marsh',
    'White Mountain Lake',
    'White Oak',
    'White Pigeon',
    'White Pine',
    'White Plains',
    'White Post',
    'White River Junction',
    'White Salmon',
    'White Sands',
    'White Springs',
    'White Stone',
    'White Sulphur Springs',
    'White Swan',
    'White Water',
    'Whiteface',
    'Whitefield',
    'Whitefish',
    'Whiteford',
    'Whitehall',
    'Whitehall Township',
    'Whitehouse',
    'Whitehouse Station',
    'Whiteland',
    'Whiteman Air Force Base',
    'Whiteriver',
    'Whites Creek',
    'Whitesboro',
    'Whitesburg',
    'Whiteside',
    'Whitestone',
    'Whitestown',
    'Whitesville',
    'Whitethorn',
    'Whiteville',
    'Whitewater',
    'Whitewright',
    'Whiting',
    'Whitingham',
    'Whitinsville',
    'Whitley City',
    'Whitman',
    'Whitmire',
    'Whitmore',
    'Whitmore Lake',
    'Whitney',
    'Whitney Point',
    'Whitsett',
    'Whittemore',
    'Whittier',
    'Whittington',
    'Whitwell',
    'Wibaux',
    'Wichita',
    'Wichita Falls',
    'Wickenburg',
    'Wickes',
    'Wickliffe',
    'Wiconisco',
    'Widener',
    'Wiergate',
    'Wiggins',
    'Wilber',
    'Wilberforce',
    'Wilbraham',
    'Wilbur',
    'Wilburton',
    'Wilcox',
    'Wild Rose',
    'Wilder',
    'Wilderville',
    'Wildomar',
    'Wildorado',
    'Wildwood',
    'Wiley',
    'Wilkes Barre',
    'Wilkesboro',
    'Wilkinson',
    'Willacoochee',
    'Willamina',
    'Willapa',
    'Willard',
    'Willcox',
    'Willet',
    'Williams',
    'Williams Bay',
    'Williamsburg',
    'Williamsfield',
    'Williamson',
    'Williamsport',
    'Williamston',
    'Williamstown',
    'Williamsville',
    'Willimantic',
    'Willingboro',
    'Willis',
    'Willis Wharf',
    'Willisburg',
    'Williston',
    'Williston Park',
    'Willisville',
    'Willits',
    'Willmar',
    'Willoughby',
    'Willow Creek',
    'Willow Grove',
    'Willow Hill',
    'Willow Lake',
    'Willow River',
    'Willow Springs',
    'Willow Street',
    'Willowbrook',
    'Willows',
    'Wills Point',
    'Willsboro',
    'Willseyville',
    'Willshire',
    'Wilmar',
    'Wilmer',
    'Wilmerding',
    'Wilmette',
    'Wilmington',
    'Wilmont',
    'Wilmore',
    'Wilmot',
    'Wilsall',
    'Wilsey',
    'Wilseyville',
    'Wilson',
    'Wilsonville',
    'Wilton',
    'Wilton Manors',
    'Wimauma',
    'Wimberley',
    'Wimbledon',
    'Winamac',
    'Winchendon',
    'Winchester',
    'Winchester Center',
    'Wind Gap',
    'Wind Ridge',
    'Windber',
    'Winder',
    'Windermere',
    'Windfall',
    'Windham',
    'Windham Center',
    'Windom',
    'Window Rock',
    'Windsor',
    'Windsor Heights',
    'Windsor Locks',
    'Windsor Mill',
    'Windthorst',
    'Winesburg',
    'Winfield',
    'Wing',
    'Wingate',
    'Wingdale',
    'Winger',
    'Wingina',
    'Wingo',
    'Winifrede',
    'Wink',
    'Winlock',
    'Winnebago',
    'Winneconne',
    'Winnemucca',
    'Winner',
    'Winnetka',
    'Winnett',
    'Winnfield',
    'Winnie',
    'Winnsboro',
    'Winona',
    'Winona Lake',
    'Winooski',
    'Winside',
    'Winslow',
    'Winslow Township',
    'Winsted',
    'Winston',
    'Winston-Salem',
    'Winter',
    'Winter Garden',
    'Winter Harbor',
    'Winter Haven',
    'Winter Park',
    'Winter Springs',
    'Winterhaven',
    'Winterport',
    'Winters',
    'Winterset',
    'Winterthur',
    'Winterville',
    'Winthrop',
    'Winthrop Harbor',
    'Winton',
    'Wiota',
    'Wirtz',
    'Wiscasset',
    'Wisconsin Dells',
    'Wisconsin Rapids',
    'Wisdom',
    'Wise',
    'Wishek',
    'Wisner',
    'Wister',
    'Withams',
    'Withee',
    'Witt',
    'Witten',
    'Wittenberg',
    'Wittensville',
    'Wittmann',
    'Wixom',
    'Woburn',
    'Woden',
    'Wofford Heights',
    'Wolbach',
    'Wolcott',
    'Wolcottville',
    'Wolf Point',
    'Wolfe City',
    'Wolfeboro',
    'Wolfeboro Falls',
    'Wolfforth',
    'Wolford',
    'Wolverton',
    'Womelsdorf',
    'Wonder Lake',
    'Wonewoc',
    'Wood Dale',
    'Wood Lake',
    'Wood River',
    'Wood River Junction',
    'Wood-Ridge',
    'Woodacre',
    'Woodberry Forest',
    'Woodbine',
    'Woodbourne',
    'Woodbridge',
    'Woodburn',
    'Woodbury',
    'Woodbury Heights',
    'Woodcliff Lake',
    'Woodford',
    'Woodgate',
    'Woodhaven',
    'Woodhull',
    'Woodinville',
    'Woodlake',
    'Woodland',
    'Woodland Hills',
    'Woodland Mills',
    'Woodland Park',
    'Woodlawn',
    'Woodleaf',
    'Woodlyn',
    'Woodmere',
    'Woodridge',
    'Woodruff',
    'Woods Cross',
    'Woods Hole',
    'Woodsboro',
    'Woodsfield',
    'Woodside',
    'Woodson',
    'Woodstock',
    'Woodstown',
    'Woodsville',
    'Woodville',
    'Woodward',
    'Woodway',
    'Woodworth',
    'Woolstock',
    'Woolwich',
    'Woonsocket',
    'Wooster',
    'Wooton',
    'Worcester',
    'Worden',
    'Worland',
    'Worley',
    'Worth',
    'Wortham',
    'Worthing',
    'Worthington',
    'Worthington Center',
    'Worthington Springs',
    'Worthville',
    'Worton',
    'Wray',
    'Wrens',
    'Wrenshall',
    'Wrentham',
    'Wright',
    'Wright City',
    'Wrightstown',
    'Wrightsville',
    'Wrightsville Beach',
    'Wrightwood',
    'Wurtsboro',
    'Wyaconda',
    'Wyalusing',
    'Wyandanch',
    'Wyandotte',
    'Wyanet',
    'Wyckoff',
    'Wyco',
    'Wye Mills',
    'Wykoff',
    'Wylie',
    'Wylliesburg',
    'Wymore',
    'Wynantskill',
    'Wyncote',
    'Wyndmere',
    'Wynne',
    'Wynnewood',
    'Wynona',
    'Wynot',
    'Wyocena',
    'Wyoming',
    'Wysox',
    'Wytheville',
    'Xenia',
    'Yachats',
    'Yacolt',
    'Yadkinville',
    'Yakima',
    'Yalaha',
    'Yale',
    'Yamhill',
    'Yampa',
    'Yanceyville',
    'Yankeetown',
    'Yankton',
    'Yantis',
    'Yaphank',
    'Yarmouth',
    'Yarmouth Port',
    'Yates Center',
    'Yates City',
    'Yatesboro',
    'Yatesville',
    'Yazoo City',
    'Yeaddiss',
    'Yellow Spring',
    'Yellow Springs',
    'Yellville',
    'Yelm',
    'Yemassee',
    'Yerington',
    'Yermo',
    'Yoakum',
    'Yoder',
    'Yolyn',
    'Yoncalla',
    'Yonkers',
    'Yorba Linda',
    'York',
    'York Beach',
    'York Corner',
    'York Harbor',
    'York Haven',
    'York Springs',
    'Yorkshire',
    'Yorktown',
    'Yorktown Heights',
    'Yorkville',
    'Yosemite Valley',
    'Young',
    'Young America',
    'Young Harris',
    'Youngstown',
    'Youngsville',
    'Youngtown',
    'Youngwood',
    'Yountville',
    'Ypsilanti',
    'Yreka',
    'Yuba City',
    'Yucaipa',
    'Yucca',
    'Yucca Valley',
    'Yukon',
    'Yulan',
    'Yulee',
    'Yuma',
    'Yutan',
    'Zachary',
    'Zaleski',
    'Zalma',
    'Zanesfield',
    'Zanesville',
    'Zapata',
    'Zarephath',
    'Zavalla',
    'Zearing',
    'Zebulon',
    'Zeeland',
    'Zeigler',
    'Zelienople',
    'Zellwood',
    'Zenda',
    'Zephyr',
    'Zephyr Cove',
    'Zephyrhills',
    'Zieglersville',
    'Zillah',
    'Zimmerman',
    'Zion',
    'Zion Grove',
    'Zionhill',
    'Zionsville',
    'Zionville',
    'Zirconia',
    'Zolfo Springs',
    'Zortman',
    'Zullinger',
    'Zumbrota',
    'Zuni',
    'Zurich',
    'Zwingle'
  ],
  India: [
    'Bhandup',
    'Mumbai',
    'Visakhapatnam',
    'Coimbatore',
    'Delhi',
    'Bangalore',
    'Pune',
    'Nagpur',
    'Lucknow',
    'Vadodara',
    'Indore',
    'Jalalpur',
    'Bhopal',
    'Kolkata',
    'Kanpur',
    'New Delhi',
    'Faridabad',
    'Rajkot',
    'Ghaziabad',
    'Chennai',
    'Meerut',
    'Agra',
    'Jaipur',
    'Jabalpur',
    'Varanasi',
    'Allahabad',
    'Hyderabad',
    'Noida',
    'Howrah',
    'Thane',
    'Patiala',
    'Chakan',
    'Ahmedabad',
    'Manipala',
    'Mangalore',
    'Panvel',
    'Udupi',
    'Rishikesh',
    'Gurgaon',
    'Mathura',
    'Shahjahanpur',
    'Bagpat',
    'Sriperumbudur',
    'Chandigarh',
    'Ludhiana',
    'Palakkad',
    'Kalyan',
    'Valsad',
    'Ulhasnagar',
    'Bhiwani',
    'Shimla',
    'Dehradun',
    'Patna',
    'Unnao',
    'Tiruvallur',
    'Kanchipuram',
    'Jamshedpur',
    'Gwalior',
    'Karur',
    'Erode',
    'Gorakhpur',
    'Ooty',
    'Haldwani',
    'Bikaner',
    'Puducherry',
    'Nalbari',
    'Bellary',
    'Vellore',
    'Naraina',
    'Mandi',
    'Rupnagar',
    'Jodhpur',
    'Roorkee',
    'Aligarh',
    'Indraprast',
    'Karnal',
    'Tanda',
    'Amritsar',
    'Raipur',
    'Pilani',
    'Bilaspur',
    'Srinagar',
    'Guntur',
    'Kakinada',
    'Warangal',
    'Tirumala - Tirupati',
    'Nizamabad',
    'Kadapa',
    'Kuppam',
    'Anantpur',
    'Nalgonda',
    'Potti',
    'Nellore',
    'Rajahmundry',
    'Bagalkot',
    'Kurnool',
    'Secunderabad',
    'Mahatma',
    'Bharuch',
    'Miraj',
    'Nanded',
    'Anand',
    'Gandhinagar',
    'Bhavnagar',
    'Morvi',
    'Aurangabad',
    'Modasa',
    'Patan',
    'Solapur',
    'Kolhapur',
    'Junagadh',
    'Akola',
    'Bhuj',
    'Karad',
    'Jalgaon Jamod',
    'Chandrapur',
    'Maharaj',
    'Dhule',
    'Ponda',
    'Dahod',
    'Navsari',
    'Panjim',
    'Patel',
    'Nashik',
    'Amravati',
    'Somnath',
    'Ganpat',
    'Karwar',
    'Davangere',
    'Raichur',
    'Nagara',
    'Kushalnagar',
    'Hassan',
    'Hubli',
    'Bidar',
    'Belgaum',
    'Mysore',
    'Dharwad',
    'Kolar',
    'Tumkūr',
    'Tiruchi',
    'Thiruvananthapuram',
    'Kozhikode',
    'Thrissur',
    'Madurai',
    'Thalassery',
    'Kannur',
    'Karaikudi',
    'Thanjavur',
    'Manor',
    'Idukki',
    'Thiruvarur',
    'Alappuzha',
    'Gandhigram',
    'Kochi',
    'Annamalainagar',
    'Amet',
    'Kottarakara',
    'Kottayam',
    'Tirunelveli',
    'Mohan',
    'Salem',
    'Attingal',
    'Chitra',
    'Chengannur',
    'Guwahati',
    'Kalam',
    'Ranchi',
    'Shillong',
    'Gangtok',
    'Srikakulam',
    'Tezpur',
    'Bhubaneswar',
    'Imphal',
    'Sundargarh',
    'Arunachal',
    'Manipur',
    'Bihar Sharif',
    'Mandal',
    'Dibrugarh',
    'Darbhanga',
    'Gaya',
    'Bhagalpur',
    'Kunwar',
    'Barddhaman',
    'Jadabpur',
    'Kalyani',
    'Cuttack',
    'Barpeta',
    'Jorhat',
    'Kharagpur',
    'Medinipur',
    'Agartala',
    'Saranga',
    'Machilipatnam',
    'Dhanbad',
    'Silchar',
    'Dumka',
    'Kokrajhar',
    'Bankura',
    'Jalpaiguri',
    'Durgapur',
    'Kalinga',
    'Palampur',
    'Jammu',
    'Dwarka',
    'Faridkot',
    'Udaipur',
    'Raigarh',
    'Hisar',
    'Solan',
    'Ajmer',
    'Lala',
    'Gurdaspur',
    'Sultanpur',
    'Jhansi',
    'Vidisha',
    'Jagdalpur',
    'Dipas',
    'Sawi',
    'Etawah',
    'Saharanpur',
    'Ujjain',
    'Kangra',
    'Bhilai',
    'Rohtak',
    'Haryana',
    'Ambala',
    'Bareilly',
    'Bhoj',
    'Kapurthala Town',
    'Sangrur',
    'Pusa',
    'Sagar',
    'Rewa',
    'Bhawan',
    'Rampur',
    'Bhadohi',
    'Cuddalore',
    'Khopoli',
    'Bali',
    'Bhiwandi',
    'Vasai',
    'Badlapur',
    'Sambalpur',
    'Raurkela',
    'Brahmapur',
    'Visnagar',
    'Surendranagar',
    'Ankleshwar',
    'Dahanu',
    'Silvassa',
    'Jamnagar',
    'Dhansura',
    'Muzaffarpur',
    'Wardha',
    'Bodhan',
    'Parappanangadi',
    'Malappuram',
    'Vizianagaram',
    'Mavelikara',
    'Pathanamthitta',
    'Satara',
    'Janjgir',
    'Gold',
    'Himatnagar',
    'Bodinayakkanur',
    'Gandhidham',
    'Mahabalipuram',
    'Nadiad',
    'Virar',
    'Bahadurgarh',
    'Kaithal',
    'Siliguri',
    'Tiruppur',
    'Ernakulam',
    'Jalandhar',
    'Barakpur',
    'Kavaratti',
    'Ratnagiri',
    'Moga',
    'Hansi',
    'Sonipat',
    'Bandra',
    'Aizawl',
    'Itanagar',
    'Nagar',
    'Ghatkopar',
    'Chen',
    'Powai',
    'Bhimavaram',
    'Bhongir',
    'Medak',
    'Karimnagar',
    'Narsapur',
    'Vijayawada',
    'Markapur',
    'Mancherial',
    'Sangli',
    'Moradabad',
    'Alipur',
    'Ichalkaranji',
    'Devgarh',
    'Yavatmal',
    'Hinganghat',
    'Madgaon',
    'Verna',
    'Katra',
    'Bilaspur',
    'Uttarkashi',
    'Muktsar',
    'Bhatinda',
    'Pathankot',
    'Khatauli',
    'Vikasnagar',
    'Kollam',
    'Kovilpatti',
    'Kovvur',
    'Paloncha',
    'Vasco',
    'Alwar',
    'Bijapur',
    'Tinsukia',
    'Ratlam',
    'Kalka',
    'Ladwa',
    'Rajpura',
    'Batala',
    'Hoshiarpur',
    'Katni',
    'Bhilwara',
    'Jhajjar',
    'Lohaghat',
    'Mohali',
    'Dhuri',
    'Thoothukudi',
    'Sivakasi',
    'Coonoor',
    'Shimoga',
    'Kayamkulam',
    'Namakkal',
    'Dharmapuri',
    'Aluva',
    'Antapur',
    'Tanuku',
    'Eluru',
    'Balasore',
    'Hingoli',
    'Quepem',
    'Assagao',
    'Betim',
    'Cuncolim',
    'Ahmednagar',
    'Goa',
    'Caranzalem',
    'Chopda',
    'Petlad',
    'Raipur',
    'Villupuram',
    'Shoranur',
    'Dasua',
    'Gonda',
    'Yadgir',
    'Palladam',
    'Nuzvid',
    'Kasaragod',
    'Paonta Sahib',
    'Sarangi',
    'Anantapur',
    'Kumar',
    'Kaul',
    'Panipat',
    'Uppal',
    'Teri',
    'Tiruvalla',
    'Jamal',
    'Chakra',
    'Narasaraopet',
    'Dharamsala',
    'Ranjan',
    'Garhshankar',
    'Haridwar',
    'Chinchvad',
    'Narela',
    'Aurangabad',
    'Sion',
    'Kalamboli',
    'Chittoor',
    'Wellington',
    'Nagapattinam',
    'Karaikal',
    'Pollachi',
    'Thenkasi',
    'Aranmula',
    'Koni',
    'Ariyalur',
    'Ranippettai',
    'Kundan',
    'Lamba Harisingh',
    'Surana',
    'Ghana',
    'Lanka',
    'Kataria',
    'Kotian',
    'Khan',
    'Salt Lake City',
    'Bala',
    'Vazhakulam',
    'Paravur',
    'Nabha',
    'Ongole',
    'Kaladi',
    'Jajpur',
    'Thenali',
    'Mohala',
    'Mylapore',
    'Bank',
    'Khammam',
    'Ring',
    'Maldah',
    'Kavali',
    'Andheri',
    'Baddi',
    'Mahesana',
    'Nila',
    'Gannavaram',
    'Cumbum',
    'Belapur',
    'Phagwara',
    'Rander',
    'Siuri',
    'Bulandshahr',
    'Bilimora',
    'Guindy',
    'Pitampura',
    'Baharampur',
    'Dadri',
    'Boisar',
    'Shiv',
    'Multi',
    'Bhadath',
    'Ulubari',
    'Palghar',
    'Puras',
    'Sikka',
    'Saha',
    'Godhra',
    'Dam Dam',
    'Ekkattuthangal',
    'Sahibabad',
    'Kalol',
    'Bardoli',
    'Wai',
    'Shirgaon',
    'Nehra',
    'Mangalagiri',
    'Latur',
    'Kottakkal',
    'Rewari',
    'Ponnani',
    'Narayangaon',
    'Hapur',
    'Kalpetta',
    'Khurja',
    'Ramnagar',
    'Neral',
    'Sendhwa',
    'Talegaon Dabhade',
    'Kargil',
    'Manali',
    'Jalalabad',
    'Palani',
    'Sirkazhi',
    'Krishnagiri',
    'Hiriyur',
    'Muzaffarnagar',
    'Kashipur',
    'Gampalagudem',
    'Siruseri',
    'Manjeri',
    'Raniganj',
    'Mahim',
    'Bhusawal',
    'Tirur',
    'Sattur',
    'Angul',
    'Puri',
    'Khurda',
    'Dharavi',
    'Ambur',
    'Vashi',
    'Arch',
    'Colaba',
    'Hosur',
    'Kota',
    'Hugli',
    'Anantnag',
    'Murshidabad',
    'Jharsuguda',
    'Jind',
    'Neyveli',
    'Vaniyambadi',
    'Srikalahasti',
    'Liluah',
    'Pali',
    'Bokaro',
    'Sidhi',
    'Asansol',
    'Darjeeling',
    'Kohima',
    'Shahdara',
    'Chandannagar',
    'Nadgaon',
    'Haripad',
    'Sitapur',
    'Vapi',
    'Bambolim',
    'Baidyabati',
    'Connaught Place',
    'Singtam',
    'Shyamnagar',
    'Sikar',
    'Choolai',
    'Mayapur',
    'Puruliya',
    'Habra',
    'Kanchrapara',
    'Goregaon',
    'Tiptur',
    'Kalpakkam',
    'Serampore',
    'Konnagar',
    'Port Blair',
    'Canning',
    'Mahad',
    'Alibag',
    'Pimpri',
    'Panchgani',
    'Karjat',
    'Vaikam',
    'Mhow',
    'Lakhimpur',
    'Madhoganj',
    'Kheri',
    'Gudivada',
    'Avanigadda',
    'Nayagarh',
    'Bemetara',
    'Bhatapara',
    'Ramgarh',
    'Dhubri',
    'Goshaingaon',
    'Bellare',
    'Puttur',
    'Narnaul',
    'Porbandar',
    'Keshod',
    'Dhrol',
    'Kailaras',
    'Morena',
    'Deolali',
    'Banda',
    'Orai',
    'Fatehpur',
    'Mirzapur',
    'Adilabad',
    'Pithapuram',
    'Ramavaram',
    'Amalapuram',
    'Champa',
    'Ambikapur',
    'Korba',
    'Pehowa',
    'Yamunanagar',
    'Shahabad',
    'Hamirpur',
    'Gulbarga',
    'Sagar',
    'Bhadravati',
    'Sirsi',
    'Honavar',
    'Siruguppa',
    'Koppal',
    'Gargoti',
    'Kankauli',
    'Jalna',
    'Parbhani',
    'Koraput',
    'Barpali',
    'Jaypur',
    'Banswara',
    'Tindivanam',
    'Mettur',
    'Srirangam',
    'Deoria',
    'Basti',
    'Padrauna',
    'Budaun',
    'Bolpur',
    'Gujrat',
    'Balurghat',
    'Binnaguri',
    'Guruvayur',
    'Chandauli',
    'Madikeri',
    'Piduguralla',
    'Vinukonda',
    'Berasia',
    'Sultans Battery',
    'Ramanagaram',
    'Angadipuram',
    'Mattanur',
    'Gobichettipalayam',
    'Banga',
    'Sibsagar',
    'Namrup',
    'North Lakhimpur',
    'Dhenkanal',
    'Karanja',
    'Cheyyar',
    'Vandavasi',
    'Arakkonam',
    'Tiruvannamalai',
    'Akividu',
    'Tadepallegudem',
    'Madanapalle',
    'Puttur',
    'Edavanna',
    'Kodungallur',
    'Marmagao',
    'Sanquelim',
    'Sakri',
    'Shahdol',
    'Satna',
    'Thasra',
    'Bundi',
    'Kishangarh',
    'Firozpur',
    'Kot Isa Khan',
    'Barnala',
    'Sunam',
    'Pithoragarh',
    'Jaspur',
    'Jhargram',
    'Dimapur',
    'Churachandpur',
    'Raxaul',
    'Motihari',
    'Munger',
    'Purnea',
    'Mannargudi',
    'Kumbakonam',
    'Eral',
    'Nagercoil',
    'Kanniyakumari',
    'Ramanathapuram',
    'Sivaganga',
    'Rajapalaiyam',
    'Srivilliputhur',
    'Suratgarh',
    'Gohana',
    'Sirsa',
    'Fatehabad',
    'Nurpur',
    'Chamba',
    'Khergam',
    'Dindigul',
    'Pudukkottai',
    'Kaimganj',
    'Tarn Taran',
    'Khanna',
    'Irinjalakuda',
    'Sehore',
    'Parra',
    'Dicholi',
    'Chicalim',
    'Saligao',
    'Changanacheri',
    'Igatpuri',
    'Sangamner',
    'Ganganagar',
    'Kanhangad',
    'Chidambaram',
    'Chittur',
    'Nilambur',
    'Arvi',
    'Jalesar',
    'Kasganj',
    'Chandausi',
    'Beawar',
    'Bharatpur',
    'Kathua',
    'Chalisgaon',
    'Karamsad',
    'Peranampattu',
    'Arani',
    'Payyanur',
    'Pattambi',
    'Pattukkottai',
    'Pakala',
    'Vikarabad',
    'Bhatkal',
    'Rupnarayanpur',
    'Kulti',
    'Koch Bihar',
    'Nongstoin',
    'Budbud',
    'Balangir',
    'Kharar',
    'Mukerian',
    'Mansa',
    'Punalur',
    'Mandya',
    'Nandyal',
    'Dhone',
    'Candolim',
    'Aldona',
    'Solim',
    'Daman',
    'Koothanallur',
    'Sojat',
    'Alanallur',
    'Kagal',
    'Jhunjhunun',
    'Sirhind',
    'Kurali',
    'Khinwara',
    'Machhiwara',
    'Talwandi Sabo',
    'Malpur',
    'Dhar',
    'Medarametla',
    'Pileru',
    'Yercaud',
    'Ottappalam',
    'Alangulam',
    'Palus',
    'Chiplun',
    'Durg',
    'Damoh',
    'Ambarnath',
    'Haveri',
    'Mundgod',
    'Mandvi',
    'Behala',
    'Fort',
    'Bela',
    'Balana',
    'Odhan',
    'Mawana',
    'Firozabad',
    'Bichpuri',
    'Almora',
    'Pauri',
    'Azamgarh',
    'Phaphamau',
    'Nongpoh',
    'Gangrar',
    'Jhalawar',
    'Nathdwara',
    'Jaisalmer',
    'Pushkar',
    'Sirohi',
    'Baroda',
    'Ambah',
    'Ambejogai',
    'Ambad',
    'Osmanabad',
    'Betalbatim',
    'Gangapur',
    'Dindori',
    'Yeola',
    'Pandharpur',
    'Neri',
    'Umred',
    'Patelguda',
    'Patancheru',
    'Singarayakonda',
    'Peddapuram',
    'Gadag',
    'Chikmagalūr',
    'Chikodi',
    'Amer',
    'Chintamani',
    'Tambaram',
    'Palayam',
    'Karamadai',
    'Omalur',
    'Kuzhithurai',
    'Faizabad',
    'Thirumangalam',
    'Kodaikanal',
    'Devipattinam',
    'Dharapuram',
    'Rudrapur',
    'Talcher',
    'Haldia',
    'Karsiyang',
    'Sandur',
    'Bapatla',
    'Shamsabad',
    'Kandi',
    'Ramapuram',
    'Anchal',
    'Trimbak',
    'Calangute',
    'Arpora',
    'Khargone',
    'Mandla',
    'Kalan',
    'Pachmarhi',
    'Dhamtari',
    'Kumhari',
    'Aundh',
    'Tala',
    'Tuljapur',
    'Botad',
    'Sidhpur',
    'Sanand',
    'Nagwa',
    'Mussoorie',
    'Vadamadurai',
    'Sholavandan',
    'Pochampalli',
    'Perundurai',
    'Lalgudi',
    'Ponneri',
    'Mount Abu',
    'Vadner',
    'Shanti Grama',
    'Nalagarh',
    'Pahalgam',
    'Dinanagar',
    'Jatani',
    'Ganga',
    'Barmer',
    'Hoshangabad',
    'Khajuraho Group of Monuments',
    'Betul',
    'Sangola',
    'Tirumala',
    'Mirza Murad',
    'Attur',
    'Budha',
    'Pala',
    'Tonk',
    'Koni',
    'Rajpur',
    'Shrigonda',
    'Hazaribagh',
    'Nagaur',
    'Mandapeta',
    'Nabadwip',
    'Nandurbar',
    'Nazira',
    'Kasia',
    'Bargarh',
    'Kollegal',
    'Shahkot',
    'Jagraon',
    'Channapatna',
    'Madurantakam',
    'Kamalpur',
    'Ranaghat',
    'Mundra',
    'Mashobra',
    'Rama',
    'Chirala',
    'Bawana',
    'Dhaka',
    'Mahal',
    'Chitradurga',
    'Mandsaur',
    'Dewas',
    'Sachin',
    'Andra',
    'Kalkaji Devi',
    'Pilkhuwa',
    'Mehra',
    'Chhachhrauli',
    'Samastipur',
    'Bangaon',
    'Ghatal',
    'Jayanti',
    'Belgharia',
    'Kamat',
    'Dhariwal',
    'Morinda',
    'Kottagudem',
    'Suriapet',
    'Mahesh',
    'Sirwani',
    'Kanakpura',
    'Mahajan',
    'Sodhi',
    'Chand',
    'Nagal',
    'Hong',
    'Raju',
    'Tikamgarh',
    'Parel',
    'Jaynagar',
    'Mill',
    'Khambhat',
    'Ballabgarh',
    'Begusarai',
    'Shahapur',
    'Banka',
    'Golaghat',
    'Palwal',
    'Kalra',
    'Chandan',
    'Maru',
    'Nanda',
    'Chopra',
    'Kasal',
    'Rana',
    'Chetan',
    'Charu',
    'Arora',
    'Chhabra',
    'Bishnupur',
    'Manu',
    'Karimganj',
    'Ellora Caves',
    'Adwani',
    'Amreli',
    'Soni',
    'Sarwar',
    'Balu',
    'Rawal',
    'Darsi',
    'Nandigama',
    'Mathan',
    'Panchal',
    'Jha Jha',
    'Hira',
    'Manna',
    'Amal',
    'Kheda',
    'Abdul',
    'Roshan',
    'Bhandari',
    'Binavas',
    'Hari',
    'Nandi',
    'Rajapur',
    'Suman',
    'Sakri',
    'Khalapur',
    'Dangi',
    'Thiruthani',
    'Bawan',
    'Basu',
    'Kosamba',
    'Medchal',
    'Kakdwip',
    'Kamalpura',
    'Dogadda',
    'Charan',
    'Basirhat',
    'Nagari',
    'Kangayam',
    'Sopara',
    'Nadia',
    'Mahulia',
    'Alipur',
    'Hamirpur',
    'Fatehgarh',
    'Bagh',
    'Naini',
    'Karari',
    'Ajabpur',
    'Jaunpur',
    'Iglas',
    'Pantnagar',
    'Dwarahat',
    'Dasna',
    'Mithapur',
    'Bali',
    'Nilokheri',
    'Kolayat',
    'Haripur',
    'Dang',
    'Chhota Udepur',
    'Matar',
    'Sukma',
    'Guna',
    'Dona Paula',
    'Navelim',
    'Vainguinim',
    'Curchorem',
    'Balaghat',
    'Bhagwan',
    'Vijapur',
    'Sinnar',
    'Mangaon',
    'Hadadi',
    'Bobbili',
    'Yanam',
    'Udaigiri',
    'Balanagar',
    'Kanigiri',
    'Muddanuru',
    'Panruti',
    'Proddatur',
    'Puliyur',
    'Perambalur',
    'Turaiyur',
    'Tiruchchendur',
    'Shadnagar',
    'Markal',
    'Sultan',
    'Rayagada',
    'Kaniyambadi',
    'Vandalur',
    'Sangam',
    'Katoya',
    'Gudur',
    'Farakka',
    'Baramati',
    'Tohana'
  ],
  Malaysia: [
    'Pantai',
    'Kuala Lumpur',
    'Petaling Jaya',
    'Shah Alam',
    'Kajang',
    'Kuching',
    'Setapak',
    'Ipoh',
    'Bukit Kayu Hitam',
    'Bayan Lepas',
    'Taiping',
    'Kuala Terengganu',
    'Kuantan',
    'Johor Bahru',
    'Malacca',
    'Seremban',
    'Kota Kinabalu',
    'Bukit Mertajam',
    'Klang',
    'Alor Gajah',
    'Cheras',
    'Puchong Batu Dua Belas',
    'Taman Sri Ampang',
    'Muadzam Shah',
    'Kuala Pahang',
    'Simpang Empat',
    'Sungai Petani',
    'Taman Senai',
    'Batu Caves',
    'Banting',
    'Kampar',
    'Sungai Buluh',
    'Seri Kembangan',
    'Kampong Pasir Segambut',
    'Kampung Likas',
    'Penampang',
    'Simpang Tiga',
    'Bandar',
    'Lintang',
    'Putrajaya',
    'Damansara',
    'Batu Pahat',
    'Kota',
    'Sepang',
    'Kuala Selangor',
    'Taman Bukit Skudai',
    'Batang Kali',
    'Ayer Itam',
    'Bandar Tun Razak',
    'Tanah Merah New Village Site C',
    'Kuah',
    'Alor Star',
    'George Town',
    'Bandar Baru Bangi',
    'Kerling',
    'Bentung',
    'Victoria',
    'Kampung Tanjung Aru',
    'Taman Prai',
    'Sabak Bernam',
    'Kangar',
    'Itam',
    'Rawang',
    'Kulai',
    'Muar town',
    'Teluk Intan',
    'Masai',
    'Ulu Tiram',
    'Senggarang',
    'Kuala Kangsar',
    'Sitiawan',
    'Kepong',
    'Perai',
    'Butterworth',
    'Teluk Panglima Garang',
    'Lenggeng',
    'Merlimau',
    'Kluang',
    'Port Klang',
    'Kota Tinggi',
    'Pontian Kechil',
    'Miri',
    'Sibu',
    'Pengkalan Chepa',
    'Kampung Pekan Kechil Beranang',
    'Balik Pulau',
    'Tanjung Bunga',
    'Kota Bharu',
    'Karak',
    'Mentekab',
    'Raub',
    'Dungun',
    'Temerluh',
    'Kampong Kemaman',
    'Tanah Merah',
    'Papar',
    'Keningau',
    'Tawau',
    'Kampung Baharu Nilai',
    'Lahad Datu',
    'Kuala Lipis',
    'Bintulu',
    'Tampin',
    'Pasir Gudang',
    'Tumpat',
    'Seberang Jaya',
    'Lunas',
    'Benut',
    'Rengam',
    'Chemor',
    'Pedas',
    'Ranau',
    'Tanjong Karang',
    'Kulim',
    'Taman Kota Jaya',
    'Kepala Batas',
    'Kuala Penyu',
    'Jenjarum',
    'Kuala Pilah',
    'Kota Baharu',
    'Dengkil',
    'Tanjong',
    'Parit Raja',
    'Teronoh',
    'Kampung Batu Uban',
    'Hulu Langat',
    'Sungai Puyu',
    'Cameron Highlands',
    'Durian Tunggal',
    'Teriang',
    'Kampung Ayer Keroh',
    'Port Dickson',
    'Sandakan',
    'Membakut',
    'Kampung Teluk Baru',
    'Pasir Mas',
    'Changloon',
    'Batu Gajah',
    'Keramat Pulai',
    'Baru',
    'Ampang',
    'Kampung Teriang',
    'Bachok',
    'Tanjung',
    'Cyberjaya',
    'Serdang',
    'Pudu',
    'Pandan',
    'Simunjan',
    'Jertih',
    'Semenyih',
    'Kampong Changkat',
    'Segamat',
    'Bukit Gambir',
    'Kamunting',
    'Batu Arang',
    'Jerantut',
    'Nibong Tebal',
    'Jeram',
    'Tasek Glugor',
    'Lumut',
    'Slim River',
    'Ketereh',
    'Jitra',
    'Mantin',
    'Kampong Kahang Batu Twenty-eight',
    'Tamparuli',
    'Jasin',
    'Lukut',
    'Gemas',
    'Tangkak',
    'Gopeng',
    'Bedong',
    'Tapah Road',
    'Ayer Tawar',
    'Gurun',
    'Kuala Nerang',
    'Pengkalan Kempas',
    'Kompleks Sukan Tenom',
    'Padang Rengas',
    'Labis',
    'Jelutong',
    'Beaufort',
    'Sarikei',
    'Bidur',
    'Seri Manjung',
    'Masjid Tanah',
    'Bukit Asahan',
    'Melaka Tengah',
    'Padang Serai',
    'Serdang',
    'Bongawan',
    'Bagan Serai',
    'Kuala Ketil',
    'Simpang Pertang',
    'Tatau',
    'Gelang Patah',
    'Sungai Pelek',
    'Rembau',
    'Layang Layang',
    'Parit Buntar',
    'Rengit',
    'Gerik',
    'Pulau Pinang',
    'Sungai Besar',
    'Kampung Paya Simpang Empat Genting',
    'Kapar',
    'Bandar Tenggara',
    'Kampung Batu Kikir',
    'Pudu Ulu',
    'Sentul',
    'Serendah',
    'Permatang Pauh',
    'Kuala Berang',
    'Melor',
    'Kota Belud',
    'Kertih',
    'Cukai',
    'Hutan Melintang',
    'Batang Berjuntai',
    'Kuala Kubu Baharu',
    'Pantai Remis',
    'Juasseh',
    'Bahau',
    'Gua Musang',
    'Tanjong Malim',
    'Pusing',
    'Pekan',
    'Merbok',
    'Lahat',
    'Pasir Puteh',
    'Kubang Kerian',
    'Langat',
    'Selama',
    'Menglembu',
    'Taman Seremban Jaya',
    'Sungai Bakap',
    'Bau',
    'Kepala Batas',
    'Pendang',
    'Simpang Renggam',
    'Pengerang',
    'Bukit Pasir',
    'Kampung Bota Kiri',
    'Temoh',
    'Taman Megah Ria',
    'Taman Senawang Indah',
    'Sungai Besi',
    'Menggatal',
    'Tambunan',
    'Batu Berendam',
    'Penaga',
    'Batu Feringgi',
    'Marang',
    'Semerah',
    'Parit',
    'Batu',
    'Inanam',
    'Taman Desa',
    'Tampoi',
    'Bangi',
    'Genting',
    'Kuala Klawang',
    'Sungai Udang',
    'Gebeng',
    'Jawi',
    'Broga',
    'Kuang',
    'Kampung Tasek',
    'Labu',
    'Tanjung Sepat',
    'Kuala',
    'Bukit Rotan',
    'Jinjang',
    'Machang',
    'Mukah'
  ],
  'Republic of Korea': [
    'Seoul',
    'Incheon',
    'Paju',
    'Cheonan',
    'Yongin',
    'Kwanghui-dong',
    'Pon-dong',
    'Gwangju',
    'Gwangmyeong',
    'Tang-ni',
    'Busan',
    'Seongnam-si',
    'Suwon-si',
    'Namyang',
    'Namyangju',
    'Jeju-si',
    'Ulsan',
    'Osan',
    'Hanam',
    'Pyong-gol',
    'Anyang-si',
    'Yangsan',
    'Daejeon',
    'Nonsan',
    'Seocho',
    'Wonju',
    'Kisa',
    'Daegu',
    'Ansan-si',
    'Gongju',
    'Haeundae',
    'Sasang',
    'Bucheon-si',
    'Chuncheon',
    'Ilsan-dong',
    'Naju',
    'Jinju',
    'Uiwang',
    'Gangneung',
    'Yongsan-dong',
    'Pohang',
    'Changwon',
    'Jeonju',
    'Yeosu',
    'Songnim',
    'Gimhae',
    'Songjeong',
    'Hyoja-dong',
    'Icheon-si',
    'Kimso',
    'Iksan',
    'Deokjin',
    'Koyang-dong',
    'Samsung',
    'Anseong',
    'Samjung-ni',
    'Mapo-dong',
    'Gunnae',
    'Nae-ri',
    'Suncheon',
    'Okpo-dong',
    'Moppo',
    'Sangdo-dong',
    'Cheongju-si',
    "Ch'aeun",
    'Taebuk',
    'Yeoju',
    'Seong-dong',
    'Duchon',
    'Gyeongju',
    'Andong',
    'Seosan City',
    'Asan',
    'Miryang',
    'Wonmi-gu',
    'Janghowon',
    'Chungnim',
    'Songam',
    'Tongan',
    "Ap'o",
    'Jecheon',
    'Se-ri',
    'Ka-ri',
    'Hansol',
    'Songang',
    'Hyangyang',
    'Gyeongsan-si',
    'Gumi',
    'Unpo',
    'Ulchin',
    'Namhyang-dong',
    "T'aebaek",
    'Hadong',
    'Haesan',
    'Chungju',
    'Chilgok',
    ''
  ],
  'Hong Kong': [
    'Shuen Wan',
    'Central District',
    'Hung Hom',
    'Kowloon',
    'Quarry Bay',
    'Ngau Tau Kok',
    'Ying Pun',
    'Repulse Bay',
    'Causeway Bay',
    'Tseung Kwan O',
    'Tai Kok Tsui',
    'Tai Wai',
    'Ma On Shan Tsuen',
    'To Kwa Wan',
    'Wong Tai Sin',
    'Tuen Mun San Hui',
    'Ma Yau Tong',
    'Ngau Chi Wan',
    'Yau Ma Tei',
    'Kennedy Town',
    'Chai Wan Kok',
    'Sham Shui Po',
    'Mid Levels',
    'North Point',
    'Happy Valley',
    'Sai Keng',
    'Kwun Hang',
    'Mong Kok',
    'Shek Tong Tsui',
    'Cheung Sha Wan',
    'Sham Tseng',
    'Yuen Long San Hui',
    'Kwai Chung',
    'Sha Tin Wai',
    'Tin Shui Wai',
    'Hong Kong',
    'Tai Hang',
    'Fo Tan',
    'Tsimshatsui',
    'Tsz Wan Shan',
    'San Tung Chung Hang',
    'Peng Chau',
    'Sha Po Kong',
    'Wan Tsai',
    'Shek Kip Mei',
    'Aberdeen',
    'Tai Po',
    'Lai Chi Wo',
    'Shau Kei Wan',
    'Cheung Kong',
    'Tai Lin Pai',
    'Chuen Lung',
    'Sheung Shui',
    'Sheung Tsuen',
    'Fanling',
    'Fa Yuen',
    'Chek Chue',
    'Tai Tan',
    'Kowloon Tong',
    'Ho Man Tin',
    'Ma Wan',
    'Cha Kwo Ling',
    'Wo Che',
    'Lam Tin',
    'Nam A',
    'Tsing Lung Tau',
    'Ting Kau',
    'Tai Chau To',
    'Lin Fa Tei',
    'Chung Hau',
    'Ping Yeung',
    'Wong Chuk Hang',
    'San Tsuen',
    'Pak Ngan Heung',
    'Lam Tei',
    'Kat O Sheung Wai',
    'Lo So Shing',
    'Sha Tau Kok',
    'Tin Wan Resettlement Estate',
    'Tai Wan To',
    'Mau Ping',
    'Shatin'
  ],
  Taiwan: [
    'Taipei',
    'Taoyuan',
    'Tuchang',
    'Hsinchu',
    'Nankan',
    'Taichung',
    'Chang-hua',
    'Kao-sung',
    'Taitung',
    'Tainan',
    'Keelung',
    'Yunlin County',
    'Lugang',
    'Puli',
    'Pingtung',
    "Penghu'ercun",
    'Nantou',
    'Miaoli',
    'Hualian',
    'Gaozhongyicun',
    'Wutai',
    'Checheng',
    'Hengchun',
    'Shalun',
    'Chiayi',
    'Maru',
    'Matsu',
    'Yilan',
    'Shuishang',
    'Shuyi',
    'Budai',
    'Toucheng',
    'Shoufeng',
    'Ma-kung',
    "Wang'an",
    'Meilun',
    'Jincheng',
    'Shulin',
    'Pozi',
    'Suqin',
    'Neihu',
    'Yangmei',
    'Hemei',
    'Wangtongwen',
    'Kaohsiung City',
    'Xinzhuang',
    'Taichang',
    'Linkou',
    'Yanping',
    'Taipingding',
    'Wan-hua',
    'Zhunan',
    'Caogang',
    'Douliu',
    'Erlin',
    'Shuiliu',
    'Dahu',
    'Gangshan',
    'Fengshan',
    'Xinji',
    'Nanhua',
    'Shetou',
    'Fanlu',
    'Shuilin',
    'Zhuqi',
    'Yuli',
    "Su'ao",
    'Wanluan',
    'Zhubei'
  ],
  Philippines: [
    'Manila',
    'Ayala',
    'Bayan',
    'Roosevelt',
    'Blumentritt',
    'Cardona',
    'Pasong Tamo',
    'Valenzuela',
    'Caloocan City',
    'Taytay',
    'Quezon City',
    'Las Pinas',
    'Makati City',
    'Marikina City',
    'San Juan',
    'Pasig',
    'Paranaque City',
    'Muntinlupa',
    'Salinas',
    'Poblacion',
    'Roxas City',
    'Puerto Princesa City',
    'Pasay',
    'Caloocan',
    'Buting',
    'Lipa City',
    'Taguig',
    'Baguio City',
    'San Pablo City',
    'San Mateo',
    'Rizal',
    'Dasmarinas',
    'Sucat',
    'Sampaloc',
    'Marilag',
    'Angeles City',
    'Mandaluyong City',
    'Malabon',
    'Rodriguez',
    'Fairview',
    'Angeles City',
    'Davao City',
    'Iligan',
    'Cagayan de Oro',
    'Tagum',
    'Butuan',
    'Mawab',
    'Ozamiz City',
    'Bacoor',
    'Cavite City',
    'Carmona',
    'Imus',
    'Tacurong',
    'Pateros',
    'Dumaguete',
    'Carolinas',
    'San Pedro',
    'General Santos',
    'Malolos',
    'Cebu City',
    'Malate',
    'New Manila',
    'Antipolo',
    'Villanueva',
    'Concepcion',
    'Koronadal',
    'Pandi',
    'West',
    'Lucena City',
    'Balayan',
    'Laguna',
    'Calamba',
    'Subic',
    'Bonifacio',
    'Quezon',
    'Lapu-Lapu City',
    'Tagaytay',
    'San Carlos City',
    'San Jose',
    'Asia',
    'Subic',
    'Libis',
    'Palma Gil',
    'Fernandez',
    'Kalibo',
    'Santos',
    'Vargas',
    'Novaliches',
    'Plaza',
    'San Antonio',
    'Philippine',
    'Rizal',
    'Alabang',
    'Legaspi',
    'Laguna',
    'Summit',
    'Bates',
    'Bagumbayan',
    'Salcedo',
    'Batis',
    'Cubao',
    'Binondo',
    'Balabag',
    'General',
    'Guagua',
    'Liberty',
    'Tandang Sora',
    'Capitol',
    'Naga City',
    'Binangonan',
    'San Francisco',
    'Mabalacat',
    'Pikit',
    'Antipolo',
    'Imus',
    'Tarlac City',
    'Batangas',
    'Calapan',
    'Binan',
    'Cabuyao',
    'Hilongos',
    'Mandaue City',
    'Tanza',
    'Caloocan',
    'Silang',
    'Malabon',
    'Navotas',
    'Angat',
    'Navotas',
    'Bulacan',
    'Laoag',
    'Bocaue',
    'Dagupan',
    'Calasiao',
    'Dingras',
    'Cainta',
    'Iloilo City',
    'Iloilo City',
    'Tagbilaran City',
    'Balanga',
    'Pulilan',
    'Olongapo City',
    'Marilao',
    'San Jose',
    'San Fernando',
    'Plaridel',
    'Baliuag',
    'Cabanatuan City',
    'Bataan',
    'Navotas',
    'San Fernando',
    'Meycauayan',
    'Manila',
    'Kawit',
    'Bacolod City',
    'Angono',
    'Los Banos',
    'Paete',
    'Santa Rosa',
    'Calamba City',
    'Santa Cruz',
    'Pangil',
    'Canlubang',
    'Tayabas',
    'Pagsanjan',
    'Zamboanga City',
    'Talisay City',
    'Mandaue City',
    'Ugong Norte',
    'Luna',
    'Olongapo City',
    'Tanauan',
    'Cabatuan',
    'Pasig',
    'West Triangle',
    'Pias',
    'San Pedro',
    'Dipolog City',
    'Victorias City',
    'Zamboanga City',
    'Subic',
    'Abucay',
    'Samal',
    'Orion',
    'Arayat',
    'Boac',
    'Siniloan',
    'San Agustin',
    'Cotabato City',
    'Veronica',
    'Masbate',
    'Bool',
    'Tacloban City',
    'Danao',
    'Rosario West',
    'Taft',
    'Sulo',
    'Centre',
    'Santo',
    'Pagasinan',
    'Cafe',
    'Leyte',
    'Interior',
    'Pagadian',
    'Molave',
    'Palo',
    'Mamungan',
    'Hagonoy',
    'Paul',
    'Bagong Nayon',
    'Mauban',
    'La Trinidad',
    'Apalit',
    'Mariveles',
    'General Trias',
    'Cabugao',
    'Province of Pangasinan',
    'Urdaneta',
    'Alaminos',
    'Malasiqui',
    'Bay',
    'Balungao',
    'Amadeo',
    'Macabebe',
    'Del Monte',
    'Limay',
    'Calbayog City',
    'Real',
    'Tanay',
    'Kapatagan',
    'Trece Martires City',
    'Lamitan',
    'Bagac',
    'Jagna',
    'Obando',
    'Balagtas',
    'Kidapawan',
    'Pampanga',
    'Naga',
    'Diliman Primero',
    'Baao',
    'Cauayan',
    'Pacita',
    'Central',
    'Rosales',
    'Bulacan',
    'Roque',
    'Silang',
    'La Salette',
    'San Miguel',
    'Baclaran',
    'Marcos',
    'Suyo',
    'Maquiling',
    'Plaridel',
    'Los Martires',
    'Acacia',
    'Minalin',
    'Salle',
    'Agusan Pequeno',
    'Gitagum',
    'Pardo',
    'Gallego',
    'Gloria',
    'Nueva',
    'Upper Bicutan',
    'Wines',
    'Vito',
    'La Union',
    'Poblacion, San Felipe',
    'Lawis',
    'Toledo City',
    'Concepcion',
    'Digos',
    'Cogan',
    'Calinan',
    'Mindanaw',
    'Dagupan',
    'Barbar',
    'Mangaldan',
    'San Fabian',
    'Alcala',
    'Bautista',
    'Aquino',
    'Ilog',
    'Manaoag',
    'Tayug',
    'Luzon',
    'San Miguel',
    'Victory',
    'Sta Cruz',
    'Sultan Kudarat',
    'Magsaysay',
    'Dapitan',
    'Mangrove',
    'Tanauan',
    'Alfonso',
    'Island Park',
    'Maria',
    'Tigbauan',
    'Campus',
    'Valencia',
    'Occidental',
    'Panabo',
    'Munoz East',
    'Aguilar',
    'Fortuna',
    'Matandang Balara',
    'Osmena',
    'Leon',
    'Balamban',
    'Cotabato City',
    'Cavinti',
    'Alitagtag',
    'Trinidad',
    'Lilio',
    'Carlos',
    'Vigan',
    'Domingo',
    'Candon',
    'Norte',
    'Oara',
    'Alaminos',
    'Calaca',
    'Padre Garcia',
    'Bauan',
    'Cagayan',
    'Tuguegarao City',
    'Ilagan',
    'Santiago',
    'Llanera',
    'China',
    'Ramon',
    'Feliciano',
    'Marina',
    'Cruz',
    'Ventura',
    'Tambo',
    'Tejeros Convention',
    'Del Pilar',
    'Province of Cebu',
    'Bustos',
    'Daraga',
    'Minglanilla',
    'Sariaya',
    'Bauan',
    'Himamaylan',
    'Silay',
    'Orani',
    'Paniqui',
    'San Jose del Monte',
    'Luna',
    'Smart',
    'Santo Tomas',
    'Aurora',
    'Lahug',
    'Miagao',
    'Dinas',
    'Matalam',
    'Province of Pampanga',
    'Sorsogon',
    'Candelaria',
    'Carlatan',
    'Malvar',
    'Princesa',
    'Looc',
    'Putatan',
    'Villa',
    'Cinco',
    'Bataan',
    'Pinaglabanan',
    'Bago City',
    'Tambler',
    'Tuktukan',
    'Bayawan',
    'Laguerta',
    'Isabela',
    'Mabini',
    'Talon',
    'Don Jose',
    'Dolores',
    'Iriga City',
    'Misamis',
    'Taal',
    'Valencia',
    'Bukidnon',
    'Mapua',
    'Don Bosco Executive Village',
    'Centro',
    'Lourdes',
    'Fernando',
    'Tamag',
    'City of Batac',
    'Merville Subdivision',
    'San Andres',
    'Burgos',
    'Naguilian',
    'San Vicente',
    'Sto Nino',
    'Province of Laguna',
    'Quezon'
  ],
  Australia: [
    'East Gosford',
    'City of Parramatta',
    'Pyrmont',
    'Boambee',
    'Paramatta',
    'McGraths Hill',
    'Ipswich',
    'Sydney',
    'Kingscliff',
    'Isabella',
    'Northgate',
    'Boonah',
    'Terry Hills',
    'Beerwah',
    'Gold Coast',
    'Tweed Heads',
    'Yass',
    'Redbank',
    'Bundamba',
    'Palm Beach',
    'Broadbeach',
    'Brisbane',
    'Wynnum',
    'Gladesville',
    'Springwood',
    'Mapleton',
    'Padstow',
    'Enfield',
    'Flinders',
    'Brookfield',
    'Yandina',
    'Merewether',
    'Emu Park',
    'Saint Marys',
    'Upper Coomera',
    'Seaforth',
    'Toogoolawah',
    'Southport',
    'Oatley',
    'Aldinga',
    'Melbourne',
    'Elsternwick',
    'Elwood',
    'Reservoir',
    'Perth',
    'Broadmeadows',
    'Ivanhoe',
    'Ringwood East',
    'Brighton',
    'Elizabeth',
    'Adelaide',
    'Caversham',
    'Brunswick',
    'Ferntree Gully',
    'Salisbury',
    'South Melbourne',
    'Port Melbourne',
    'Abbotsford',
    'Pakenham',
    'Epping',
    'Tarneit',
    'Werribee',
    'Mount Eliza',
    'Reedy Creek',
    'Kirwan',
    'Victoria Point',
    'Mackay',
    'Eight Mile Plains',
    'Toowoomba',
    'Caloundra',
    'Helensvale',
    'Scarborough',
    'Morayfield',
    'Paradise',
    'Stratford',
    'Upper Brookfield',
    'Rockhampton',
    'Petrie',
    'Ripley',
    'Nambour',
    'Albany Creek',
    'Jimboomba',
    'Cairns',
    'Stapylton',
    'Kobble',
    'Thornlands',
    'Logan City',
    'Noosaville',
    'Gladstone',
    'Mooloolah Valley',
    'Park Ridge',
    'Loganlea',
    'Ashgrove',
    'North Tamborine',
    'Toogoom',
    'Bundaberg',
    'Glass House Mountains',
    'Laidley',
    'Samford',
    'Hervey Bay',
    'Buderim',
    'Toowong',
    'Summerholm',
    'Coolum Beach',
    'Manly',
    'Fernvale',
    'Sandstone Point',
    'Bargara',
    'Yaroomba',
    'Narangba',
    'Karana Downs',
    'Townsville',
    'Maryborough',
    'Harvey',
    'Gosnells',
    'Kelmscott',
    'Bunbury',
    'Byford',
    'Karratha',
    'Mandurah',
    'Warnbro',
    'Stoneville',
    'Gooseberry Hill',
    'Geraldton',
    'Busselton',
    'Northam',
    'Coogee',
    'Iluka',
    'Walliston',
    'Maylands',
    'Rockingham',
    'Claremont',
    'Ludlow',
    'Bentley',
    'Bridgetown',
    'Erskine',
    'Belair',
    'Port Pirie',
    'Balaklava',
    'Port Noarlunga',
    'Mount Pleasant',
    'Seaton',
    'Hindmarsh',
    'Darwin',
    'Aldinga Beach',
    'Semaphore',
    'Morphett Vale',
    'Yankalilla',
    'Magill',
    'Mallala',
    'Hallett',
    'Gawler',
    'Little Hampton',
    'Glen Iris',
    'Galston',
    'Bankstown',
    'Newcastle',
    'Balgowlah',
    'Wembley',
    'Ryde',
    'Mudjimba',
    'Wellington Point',
    'Haberfield',
    'Templestowe Lower',
    'Bensville',
    'Glenfield',
    'Ourimbah',
    'Curl Curl',
    'Concord',
    'Albion',
    'Forest',
    'Ivanhoe East',
    'Box Hill',
    'Middleton',
    'Narrabeen',
    'Caboolture',
    'Dapto',
    'Marrickville',
    'Yellow Rock',
    'Ashfield',
    'Manly',
    'Wollongong',
    'Chatswood',
    'Sylvania',
    'Murrumba',
    'Plympton',
    'Wyndham',
    'Bangor',
    'New Farm',
    'North Fitzroy',
    'Hackham',
    'Unley',
    'Modbury',
    'Mitcham',
    'Woodville',
    'Strathalbyn',
    'Happy Valley',
    'Bedford Park',
    'Noarlunga',
    'Hobart',
    'Sandy Bay',
    'Orford',
    'Claremont',
    'New Town',
    'Launceston',
    'Ulverstone',
    'Kingston',
    'Mount Isa',
    'Essendon',
    'Heatherton',
    'Dandenong',
    'Heidelberg',
    'Rosebery',
    'Strathfield',
    'Normanhurst',
    'Blacktown',
    'Epping',
    'Rhodes',
    'Lidcombe',
    'Merrylands',
    'Wentworthville',
    'Baulkham Hills',
    'Balmain',
    'Drummoyne',
    'Saint Leonards',
    'Granville',
    'Quakers Hill',
    'Sutherland',
    'Carlingford',
    'Glenbrook',
    'Blaxland',
    'Jannali',
    'Rooty Hill',
    'Castle Hill',
    'Charlestown',
    'Adamstown',
    'Gosford',
    'Oyster Bay',
    'Lakemba',
    'Kogarah',
    'Bondi',
    'Kensington',
    'Hurstville',
    'Randwick',
    'Earlwood',
    'Annandale',
    'Penshurst',
    'Liverpool',
    'Sans Souci',
    'Alexandria',
    'Villawood',
    'Maroubra',
    'Mascot',
    'Caringbah',
    'Bronte',
    'Newtown',
    'Ingleburn',
    'Engadine',
    'Homebush',
    'Redfern',
    'Coogee',
    'Footscray',
    'Lindfield',
    'Thornleigh',
    'Kellyville',
    'Burwood',
    'Forestville',
    'Belrose',
    'Mosman',
    'Lane Cove',
    'Killara',
    'Gordon',
    'Paddington',
    'Penrith',
    'Minto',
    'Neutral Bay',
    'Cremorne',
    'Rose Bay',
    'Chippendale',
    'Bexley',
    'Rockdale',
    'Newport',
    'Mona Vale',
    'Berowra',
    'Cronulla',
    'Holsworthy',
    'Fairfield',
    'Rosebery',
    'Eastwood',
    'Pennant Hills',
    'Saint Ives',
    'Picton',
    'Maitland',
    'Camden',
    'Spring Hill',
    'Windsor',
    'Cowra',
    'Albion Park',
    'Balgownie',
    'Hornsby',
    'Mount Colah',
    'Leumeah',
    'Goulburn',
    'Revesby',
    'Leichhardt',
    'Westmead',
    'Croydon',
    'Camperdown',
    'Vaucluse',
    'Lambton',
    'Hamilton',
    'Mayfield',
    'Wickham',
    'Waratah',
    'Coburg',
    'Ballarat',
    'Sandringham',
    'Cheltenham',
    'Keilor',
    'Carnegie',
    'Mitcham',
    'Nunawading',
    'Keysborough',
    'Toorak',
    'Clayton North',
    'Fairfield',
    'Yarraville',
    'Ascot Vale',
    'Noble Park',
    'Springvale',
    'Northcote',
    'St Kilda',
    'Richmond',
    'Caulfield South',
    'Narre Warren',
    'Sydenham',
    'Hoppers Crossing',
    'Sunshine',
    'Williamstown',
    'Mulgrave',
    'Burwood',
    'Ashburton',
    'Doncaster East',
    'Fawkner',
    'Lalor',
    'Warrandyte',
    'Glen Waverley',
    'Mount Waverley',
    'Hawthorn',
    'Endeavour Hills',
    'Prahran',
    'Elsternwick',
    'Kew',
    'Vermont',
    'Blackburn',
    'Kensington',
    'Doncaster',
    'Oakleigh',
    'Ringwood',
    'Mooroolbark',
    'Carrum Downs',
    'North Melbourne',
    'Thornbury',
    'Chelsea',
    'Flemington',
    'Bentleigh',
    'Mentone',
    'Glenroy',
    'Carlton',
    'Moonee Ponds',
    'Parkville',
    'Frankston',
    'Bentleigh East',
    'Wantirna',
    'Fitzroy',
    'Langwarrin',
    'Aspendale',
    'East Malvern',
    'Hallam',
    'Tullamarine',
    'Hampton Park',
    'Newport',
    'Belgrave',
    'Thomastown',
    'Mornington',
    'Croydon',
    'Deer Park',
    'Scoresby',
    'Greensborough',
    'Clontarf',
    'Redcliffe',
    'Bundoora',
    'Templestowe',
    'Hampton',
    'Craigieburn',
    'Albert Park',
    'Moorabbin',
    'Derrimut',
    'Sunbury',
    'Geelong',
    'Belmont',
    'Eaglehawk',
    'Hamilton',
    'Croydon North',
    'Berwick',
    'Melton',
    'Carrum',
    'Bayswater',
    'Oakleigh South',
    'Bonbeach',
    'Donvale',
    'Saint Albans',
    'Preston',
    'Balwyn',
    'Coorparoo',
    'Underwood',
    'Woodridge',
    'Bass Hill',
    'Alexandra',
    'Capalaba',
    'Kelvin Grove',
    'Mooloolaba',
    'Sunnybank',
    'Robertson',
    'Nerang',
    'Burleigh Heads',
    'Mudgeeraba',
    'Surfers Paradise',
    'Greenbank',
    'Noosa',
    'Balwyn North',
    'Glen Huntly',
    'Eudlo',
    'Abbotsford',
    'Northmead',
    'Waterloo',
    'Raymond Terrace',
    'Saint Lucia',
    'Canberra',
    'Queanbeyan',
    'Roseville',
    'Cooranbong',
    'Tuncurry',
    'Gunnedah',
    'Prospect',
    'Nairne',
    'Laverton',
    'South Yarra',
    'Mount Martha',
    'Macleod',
    'McKinnon',
    'Subiaco',
    'Maddington',
    'South Perth',
    'Bassendean',
    'Collaroy',
    'Kirribilli',
    'Cammeray',
    'Pendle Hills',
    'Gymea Bay',
    'Wahroonga',
    'Campbelltown',
    'Ayr',
    'Cleveland',
    'Hope Island',
    'Ultimo',
    'Auburn',
    'Botany',
    'Toongabbie',
    'Stanmore',
    'Bathurst',
    'Kelso',
    'Albury',
    'Wodonga',
    'Miranda',
    'Armidale',
    'Surrey Hills',
    'Grovedale',
    'Bendigo',
    'Waurn Ponds',
    'Geelong West',
    'Wendouree',
    'Forest Hill',
    'Hughesdale',
    'Carlton North',
    'Eltham',
    'Hastings',
    'Montmorency',
    'Kew East',
    'Brighton East',
    'Avalon',
    'Rozelle',
    'Valla Beach',
    'Belmont',
    'Tamworth',
    'Mortdale',
    'Lilydale',
    'Butchers Ridge',
    'Lara',
    'Cranbourne',
    'Diamond Creek',
    'Romsey',
    'Caroline Springs',
    'Potts Point',
    'Belmont',
    'Waterford',
    'Riverwood',
    'Monash',
    'Beenleigh',
    'Stirling',
    'Kensington and Norwood',
    'Seaford',
    'Fremantle',
    "O'Halloran Hill",
    'Gymea',
    'Smithfield',
    'Cessnock',
    'Kurri Kurri',
    'Suffolk Park',
    'Glenelg',
    'Rye',
    'Port Fairy',
    'Sebastopol',
    'Tewantin',
    'Riverton',
    'Mullumbimby',
    'Lismore',
    'Ettalong Beach',
    'Tuggerah',
    'Warragul',
    'Spotswood',
    'Camberwell',
    'Gympie',
    'Leopold',
    'Bellambi',
    'Umina',
    'West Hoxton',
    'Doreen',
    'Berkeley Vale',
    'Swansea',
    'Booker Bay',
    'Woy Woy',
    'Unanderra',
    'Towradgi',
    'Fassifern',
    'Moore',
    'Manildra',
    'Middleton',
    'Byron Bay',
    'Ballina',
    'Warrigal',
    'Emerald',
    'Springfield',
    'Jindabyne',
    'Cooma',
    'Berridale',
    'Toronto',
    'Gloucester',
    'Altona',
    'Darlinghurst',
    'Artarmon',
    'Glebe',
    'Woolloomooloo',
    'Lyneham',
    'Greendale',
    'Maroochydore',
    'Central Coast',
    'Queenscliff',
    'Woronora',
    'Lugarno',
    'Berkeley',
    'Thornton',
    'Castlecrag',
    'Killcare',
    'Narellan',
    'Woolwich',
    'Lisarow',
    'Bolton',
    'Matraville',
    'Orient Point',
    'Dural',
    'Nelson Bay',
    'Coniston',
    'Doonside',
    'Saint Peters',
    'Stradbroke',
    'Dakabin',
    'Bellevue Hill',
    'Wyongah',
    'Killarney',
    'Rosehill',
    'Emu Plains',
    'Kangaroo Point',
    'Bahrs Scrub',
    'Mount Cotton',
    'Figtree',
    'Windermere Park',
    'Morisset',
    'New Lambton',
    'Bethania Junction',
    'Ormiston',
    'Fairy Meadow',
    'Long Jetty',
    'Mannering Park',
    'Mount Druitt',
    'Waverley',
    'Coomera',
    'Coolangatta',
    'Benowa',
    'Kincumber',
    'Ebbw Vale',
    'Tugun',
    'Burpengary',
    'Eagleby',
    'Wallan',
    'Boronia',
    'Mordialloc',
    'Mile End',
    'Ormond',
    'Collingwood',
    'Clifton Springs',
    'Minlaton',
    'Wheelers Hill',
    'Seaford',
    'Merlynston',
    'Cowes',
    'Mont Albert North',
    'Fulham',
    'Murrumbeena',
    'Greenvale',
    'Truganina',
    'Wanneroo',
    'Cannington',
    'Armadale',
    'Scarborough',
    'Sorrento',
    'Beaumaris',
    'Kilmore',
    'Corio',
    'Bulleen',
    'Werribee South',
    'Eaglemont',
    'Lyndhurst',
    'Mernda',
    'Rowville',
    'Rosebud',
    'Monbulk',
    'Bacchus Marsh',
    'Morang',
    'Maribyrnong',
    'Warburton',
    'Williamstown',
    'Rockbank',
    'Mount Evelyn',
    'Windsor',
    'Balaclava',
    'Narre Warren North',
    'Ramsgate',
    'Harrington',
    'Sandgate',
    'Gorokan',
    'Maclean',
    'Dayboro',
    'Beachmere',
    'Pialba',
    'Port Lincoln',
    'Wallaroo',
    'Point Cook',
    'Windsor',
    'Deception Bay',
    'Ormeau',
    'Bli Bli',
    'Beaudesert',
    'Goodna',
    'Burnett Heads',
    'Redland Bay',
    'Riverstone',
    'Grove',
    'Gerringong',
    'Freshwater',
    'Guildford',
    'Selby',
    'Loch',
    'Benalla',
    'Heathmont',
    'Findon',
    'Kilsyth',
    'Goolwa',
    'Mount Barker',
    'Marong',
    'Sellicks Hill',
    'Dingley',
    'Encounter Bay',
    'Blair Athol',
    'Narrawong',
    'Wangaratta',
    'Lemon Tree Passage',
    'Seacombe',
    'Lysterfield',
    'Brookfield',
    'Two Rocks',
    'Kenwick',
    'Cringila',
    'Dundas',
    'Moss Vale',
    'Warwick',
    'Woodford',
    'Tamborine',
    'Cundletown',
    'Illawong',
    'Brooklyn',
    'Wagga Wagga',
    'Wauchope',
    'Haymarket',
    'Kangaroo Flat',
    'Fern Tree',
    'Dunolly',
    'Mount Gambier',
    'Kardella',
    'Traralgon',
    'Port MacDonnell',
    'Golden Grove',
    'Murray Bridge',
    'Brighton',
    'Willaston',
    'Brandon',
    'Dromana',
    'Brighton-Le-Sands',
    'Port Kembla',
    'Seven Hills',
    'Wyee',
    'Brookvale',
    'Point Clare',
    'Waverton',
    'Lake Heights',
    'Waitara',
    'Cardiff',
    'Vineyard',
    'Menai',
    'Richmond',
    'Kurnell',
    'Terrigal',
    'Beaumont',
    'Ives',
    'Bowen',
    'Redlynch',
    'Margate',
    'Boondall',
    'Riverview',
    'Aspley',
    'Regents Park',
    'Currumbin',
    'Warren',
    'Murwillumbah',
    'Kingston',
    'Alberton',
    'Strathpine',
    'Mount Gravatt',
    'Rochedale',
    'Gin Gin',
    'Merredin',
    'Orange',
    'Yamba',
    'Coleambally',
    'Rutherford',
    'Coffs Harbour',
    'Lawnton',
    'Norman Park',
    'Oxenford',
    'Pinjarra',
    'Australind',
    'West End',
    'Dimbulah',
    'Kenmore',
    'Alton Downs',
    'Buln Buln',
    'Batesford',
    'Toolern Vale',
    'Strathfieldsaye',
    'Emerald',
    'Somerville',
    'Gellibrand',
    'Barwon Heads',
    'Beauty Point',
    'Legana',
    'Caulfield North',
    'Newtown',
    'Redan',
    'Burnley',
    'Loxton',
    'Albany',
    'Bonython',
    'Blackwood',
    'Bentley',
    'Rathmines',
    'South West Rocks',
    'Eschol',
    'Mansfield',
    'Beveridge',
    'Kilcunda',
    'Streaky Bay',
    'Virginia',
    'Wallsend',
    'Edwardstown',
    'Lake Munmorah',
    'Narara',
    'Wentworth Falls',
    'Mulgoa',
    'Logan',
    'Carlsruhe',
    'Beaconsfield',
    'Sandgate',
    'Springvale South',
    'Clarinda',
    'Kirrawee',
    'Loftus',
    'San Remo',
    'Seville East',
    'Connells Point',
    'Turramurra',
    'Bundeena',
    'Kenthurst',
    'Malvern',
    'Canterbury',
    'Oaklands',
    'Highbury',
    'Campbelltown',
    'Grange',
    'Eltham North',
    'Watsonia',
    'Saint Helena',
    'Nedlands',
    'Cottesloe',
    'Mosman Park',
    'Palm Cove',
    'Birkdale',
    'Brighton',
    'Eagle Farm',
    'Bargo',
    'Warrane',
    'Yatala',
    'Thursday Island',
    'Aitkenvale',
    'Warrimoo',
    'Katoomba',
    'Kurrajong Heights',
    'Woonona',
    'Davistown',
    'Lavington',
    'Chullora',
    'Teralba',
    'Wingham',
    'Neath',
    'Wangi Wangi',
    'Broadford',
    'Melton South',
    'Bulla',
    'Sippy Downs',
    'Kingsthorpe',
    'Lowood',
    'Spring Hill',
    'Collie',
    'Waaia',
    'Woori Yallock',
    'Black Hill',
    'Belgrave Heights',
    'Nindaroo',
    'Pine Mountain',
    'Oman-Ama',
    'Alexandra',
    'Darley',
    'Lower Plenty',
    'Forster',
    'Dungog',
    'Karalee',
    'Mareeba',
    'Windaroo',
    'Bringelly',
    'Crescent Head',
    'Medowie',
    'Glenorie',
    'Kilcoy',
    'Palmwoods',
    'Tallebudgera',
    'Tiaro',
    'Torbanlea',
    'Boulder',
    'Failford',
    'Luddenham',
    'Gisborne',
    'Wantirna South',
    'Baxter',
    'Belconnen',
    'Hall',
    'Margate',
    'Don',
    'Burnie',
    'Devonport',
    'Newstead',
    'Bellerive',
    'Midway Point',
    'Glenorchy',
    'Latrobe',
    'Dilston',
    'Tunnack',
    'Ellendale',
    'Huonville',
    'Lindisfarne',
    'Landsborough',
    'Hope Valley',
    'Normanville',
    'Birkenhead',
    'Two Wells',
    'Kinross',
    'The Basin',
    'Pymble',
    'Little Bay',
    'Laang',
    'Bulimba',
    'Shorncliffe',
    'Tarragindi',
    'Marsden',
    'Loganholme',
    'Red Hill',
    'Hillcrest',
    'Hamilton',
    'Reynella',
    'Ingle Farm',
    'Seacliff',
    'Seaview',
    'Caulfield',
    'Nana Glen',
    'Darling',
    'Mitiamo',
    'Ferny Creek',
    'Osborne',
    'Cardup',
    'Clovelly',
    'The Oaks',
    'Rushworth',
    'Port Macquarie',
    'Yallourn',
    'Burrum Heads',
    'Cardigan',
    'Newstead',
    'Tottenham',
    'Inglewood',
    'Newmarket',
    'Gayndah',
    'Alphington',
    'Davidson',
    'North Turramurra',
    'Greenwich',
    'Malabar',
    'Longueville',
    'Crows Nest',
    'Tennyson',
    'Daisy Hill',
    'Parkwood',
    'Mitchelton',
    'Aylmerton',
    'Gracemere',
    'Yeppoon',
    'Gatton',
    'Stanhope',
    'Kew',
    'Woorim',
    'Condamine',
    'Singleton',
    'Kingswood',
    'The Entrance',
    'Bateau Bay',
    'Mittagong',
    'Shellharbour',
    'Wyong',
    'Hunters Hill',
    'Heddon Greta',
    'Avoca Beach',
    'Highett',
    'Meredith',
    'Seymour',
    'Woolamai',
    'Healesville',
    'Slacks Creek',
    'Bohle Plains',
    'Bushland Beach',
    'Browns Plains',
    'Bongaree',
    'Somerset',
    'Old Beach',
    'St Helens',
    'Adelong',
    'Hurstbridge',
    'Thirroul',
    'Faulconbridge',
    'Yarra Junction',
    'Roma',
    'Pallarenda',
    'Oak Park',
    'Acacia Ridge',
    'Corlette',
    'Stockton',
    'Corrimal',
    'Armadale',
    'Rydalmere',
    'Bruce',
    'Nowra',
    'Raworth',
    'Goonellabah',
    'Taren Point',
    'Wilberforce',
    'Kotara',
    'Barwon Heads',
    'Officer',
    'Creswick',
    'Briar Hill',
    'Moonah',
    'Murrumbateman',
    'Thargomindah',
    'Guildford',
    'Avenue',
    'Stanthorpe',
    'Girraween',
    'Beechworth',
    'Campbellfield',
    'Wanbi',
    'Mannum',
    'Karoonda',
    'Thebarton',
    'Yowie Bay',
    'Wellington',
    'Moana',
    'Alice Springs',
    'Norton Summit',
    'Meadows',
    'Woodside',
    'Crafers',
    'Aldgate',
    'Victor Harbor',
    'Wayville',
    'Wellard',
    'East Fremantle',
    'Seville',
    'Saint James',
    'Swan View',
    'Spearwood',
    'Bicton',
    'Hamilton',
    'Mount Helena',
    'Marion',
    'Aberfoyle',
    'Kapunda',
    'Derwent',
    'Rokeby',
    'Victoria Park',
    'Clifton Hill',
    'Chipping Norton',
    'Como',
    'Toukley',
    'Bonnet Bay',
    'Aberglasslyn',
    'Bellbird',
    'Gilmore',
    'West Pymble',
    'Corinda',
    'Enoggera',
    'Deagon',
    'Drayton',
    'Ascot',
    'Tannum Sands',
    'Drysdale',
    'Kyneton',
    'Riddell',
    'Parkdale',
    'Koo-Wee-Rup',
    'Mooroopna',
    'Alfredton',
    'Ringwood North',
    'Wollert',
    'Kallista',
    'Ballan',
    'Miners Rest',
    'Bannockburn',
    'Lancefield',
    'Waubra',
    'Axedale',
    'Drouin',
    'Burradoo',
    'Yackandandah',
    'Fullerton',
    'Tahmoor',
    'Ulladulla',
    'Bogangar',
    'Wahgunyah',
    'Manning Point',
    'Yerrinbool',
    'Mirrabooka',
    'Norah',
    'Freemans Reach',
    'Ningi',
    'Pomona',
    'Pimpama',
    'Maleny',
    'Harristown',
    'Coolum',
    'Moggill',
    'Jacobs Well',
    'Glenhaven',
    'Elderslie',
    'Virginia',
    'Wacol',
    'Hawthorne',
    'Marino',
    'Dalkeith',
    'Worongary',
    'Redhead',
    'Willow Vale',
    'Lennox Head',
    'Lawson',
    'East Maitland',
    'Alpine',
    'Hazelbrook',
    'Dolans Bay',
    'Tarrawanna',
    'Hat Head',
    'Sylvania Waters',
    'Oxley',
    'Eden',
    'Green Point',
    'Bega',
    'Darling Point',
    'Heathcote',
    'Erina',
    'Moree',
    'Maryland',
    'Smythes Creek',
    'Ross Creek',
    'Haddon',
    'Kiama',
    'Spring Field',
    'Kempsey',
    'Willunga',
    'Maslin Beach',
    'Amaroo',
    'Exeter',
    'Yolla',
    'George Town',
    'Swansea',
    'Cygnet',
    'Deloraine',
    'Selbourne',
    'Hagley',
    'Carrick',
    'Lulworth',
    'Emu Vale',
    'Clifton',
    'Tynong',
    'Pelican',
    'Kawungan',
    'Waikerie',
    'Trunkey',
    'Thompson',
    'Bourke',
    'Young',
    'Wollongbar',
    'Verdun',
    'Colo',
    'Carrington',
    'Waterloo',
    'Milton',
    'Dawson',
    'Mitchell',
    'Narromine',
    'Palmer',
    'Bungendore',
    'Braidwood',
    'Bell',
    'Dubbo',
    'Gaythorne',
    'Saint Georges Basin',
    'Grant',
    'Phillip',
    'Stockyard Creek',
    'Somerset',
    'Gordon',
    'Colac',
    'Hazelwood',
    'Deakin',
    'Glenvale',
    'Southbank',
    'Edith',
    'Monterey',
    'Blakehurst',
    'Hurstville Grove',
    'Kareela',
    'Box Hill South',
    'Montrose',
    'Strathmore',
    'Darra',
    'Lamington',
    'Mourilyan',
    'Sheffield',
    'Fingal',
    'Dardanup',
    'Palm Island',
    'Edmonton',
    'Atherton',
    'Sale',
    'Langhorne Creek',
    'Fyshwick',
    'Wallacia',
    'Leeton',
    'Mooney Mooney',
    'Trafalgar',
    'Hawksburn',
    'Nuriootpa',
    'West Burleigh',
    'Rylstone',
    'Kootingal',
    'Cooroy',
    'Casino',
    'Research',
    'Wonga Park',
    'Napoleons',
    'Whittlesea',
    'River Heads',
    'Clyde',
    'Jilliby',
    'Barnsley',
    'Torquay',
    'Point Lonsdale',
    'Indented Head',
    'Silvan',
    'Portarlington',
    'Olinda',
    'Clunes',
    'Warren Shire',
    'Tyabb',
    'Kyabram',
    'Marysville',
    'Daylesford',
    'The Patch',
    'Goornong',
    'Castlemaine',
    'Oatlands',
    'Tooradin',
    'Lilli Pilli',
    'Dundowran',
    'Proserpine',
    'Bradbury',
    'Taree',
    'Marulan',
    'Bowral',
    'Nords Wharf',
    'Kingston',
    'Drouin South',
    'Neerim South',
    'Moore Park',
    'Pearce',
    'Pioneer',
    'Ridgley',
    'Wilson',
    'Wynyard',
    'Kingston',
    'Hunter',
    'Churchill',
    "O'Connor",
    'Sarina',
    'Salt Ash',
    'Bonnells Bay',
    'Fairview',
    'Banksmeadow',
    'Willaura',
    'Lebrina',
    'Uki',
    'Nerrigundah',
    'Katherine',
    'Oakey',
    'Crookwell',
    'Bungonia',
    'Coondoo',
    'Myrtleford',
    'Maybole',
    'Beeac',
    'Burekup',
    'Bullsbrook',
    'Coolup',
    'Keysbrook',
    'North Mackay',
    'Tempe',
    'Daceyville',
    'Griffith',
    'Macedon',
    'Bayles',
    'Pambula',
    'Shenton Park',
    'Casterton',
    'Woodend',
    'Portland',
    'Trentham',
    'Wamuran',
    'Schofields',
    'Budgewoi',
    'The Rocks',
    'Cambewarra',
    'Grafton',
    'Cowan',
    'Sanctuary Point',
    'Basin View',
    'Murrurundi',
    'Forbes',
    'Mangerton',
    'Anna Bay',
    'Wulkuraka',
    'Kennington',
    'Rocklea',
    'Burnside',
    'Queenstown',
    'Maudsland',
    'Barcoo',
    'Uraidla',
    'Largs North',
    'Keiraville',
    'Stieglitz',
    'Attadale',
    'Lewiston',
    'Melville',
    'Kalgoorlie',
    'Batchelor',
    'Williams',
    'Kingaroy',
    'Binda',
    'Kadina',
    'Tammin',
    'Clare',
    'Lithgow',
    'Yapeen',
    'Oberon',
    'Callington',
    'Calen',
    'Windellama',
    'Hepburn Springs',
    'Snowtown',
    'Yaamba',
    'Wondai',
    'Bundook',
    'Monteagle',
    'Caltowie',
    'Doubtful Creek',
    'Mission Beach',
    'Nebo',
    'Cudlee Creek',
    'Goomeri',
    'Caragabal',
    'Mudgee',
    'Narrabri',
    'Bordertown',
    'Benambra',
    'Yea',
    'Longreach',
    'Gregadoo',
    'Echuca',
    'Buchan',
    'Robertstown',
    'Condobolin',
    'Darkan',
    'Ingham',
    'Mirani',
    'Kenilworth',
    'Esk',
    'Bobin',
    'Kerang',
    'Heathcote',
    'Keith',
    'Weetah',
    'Invermay',
    'Pipers Brook',
    'Hollow Tree',
    'Kindred',
    'Warrnambool',
    'Korumburra',
    'Leongatha',
    'Foster',
    'Jindivick',
    'Garfield',
    'Inverloch',
    'Nyora',
    'Mirboo North',
    'Forsayth',
    'Port Augusta',
    'Woodleigh',
    'Point Pass',
    'Golconda',
    'Jarrahdale',
    'Judbury',
    'Sturt',
    'Mount Lofty',
    'Eumundi',
    'Kuranda',
    'Scottsdale',
    'Tanunda',
    'Ebenezer',
    'Canterbury',
    'Morwell',
    'Stratford',
    'Yarram',
    'Flynn',
    'Bairnsdale',
    'Tinamba',
    'Newry',
    'Alberton',
    'Koetong',
    'Lindenow',
    'Grenfell',
    'Stuart Park',
    'Asquith',
    'Keswick',
    'Bedfordale',
    'Wallington',
    'Toorbul',
    'Clyde North',
    'Cremorne',
    'Lake Illawarra',
    'Holroyd',
    'Blackheath',
    'Wattle Grove',
    'Leura',
    'Edgecliff',
    'Bulli',
    'Woolooware',
    'Woombah',
    'Marsden Park',
    'Diggers Rest',
    'Beverley',
    'Empire Bay',
    'Agnes Banks',
    'Windsor',
    'Silverwater',
    'Cobbitty',
    'Denham',
    'Wattle Grove',
    'Sherbrook',
    'Upwey',
    'Bellbrae',
    'McCrae',
    'Lakes Entrance',
    'Cockatoo',
    'Huntly',
    'Boolarra',
    'Clarkefield',
    'Toolangi',
    'Maryborough',
    'Picnic Bay',
    'Clyde',
    'Sellicks Beach',
    'Lutana',
    'Sassafras',
    'Lyndoch',
    'Whyalla',
    'Sandford',
    'Longwarry',
    'Beaconsfield Upper',
    'Parkes',
    'Arcadia',
    'Pokolbin',
    'Ellalong',
    'Eagle Heights',
    'Taroona',
    'Warrawong',
    'Colo Vale',
    'Yarrambat',
    'Wedderburn',
    'Greta',
    'Jensen',
    'Glenreagh',
    'Franklin',
    'Killarney',
    'Ellerslie',
    'Allansford',
    'Nullawarre',
    'Crossley',
    'Macarthur',
    'Camperdown',
    'Lancelin',
    'Gepps Cross',
    'Kings Park',
    'Kanwal',
    'Long Point',
    'Port Hacking',
    'Edithvale',
    'Barkstead',
    'Buninyong',
    'Nerrina',
    'Fern Tree Gully',
    'Broadwater',
    'Boddington',
    'Manning',
    'Narrogin',
    'Muchea',
    'Berry',
    'Alstonville',
    'Blackwall',
    'Stroud',
    'Tenambit',
    'Warnervale',
    'Beresfield',
    'Matcham',
    'Helensburgh',
    'Avondale',
    'Orchard Hills',
    'Tarro',
    'Thirlmere',
    'Oakdale',
    'Woodford',
    'Lake Cathie',
    'Beechwood',
    'Hastings Point',
    'Marlee',
    'Camden Haven',
    'Boat Harbour',
    'Bowenfels',
    'Hill Top',
    'Weston',
    'Arno Bay',
    'Callala Bay',
    'Abermain',
    'Smithfield',
    'Fingal Bay',
    'Cootamundra',
    'Clareville',
    'Bolwarra',
    'Scarsdale',
    'Swan Hill',
    'Poowong',
    'Teesdale',
    'Wandin North',
    'Moorooduc',
    'Moriac',
    'Mildura',
    'Crib Point',
    'Lethbridge',
    'Euroa',
    'Cardinia',
    'Port Hedland',
    'Donnybrook',
    'Point Vernon',
    'Agnes Water',
    'Port Douglas',
    'Howard',
    'Cawarral',
    'Thuringowa',
    'Closeburn',
    'Innisfail',
    'Meringandan',
    'Yorkeys Knob',
    'Rosewood',
    'Upper Caboolture',
    'Kettering',
    'Longford',
    'Smithton',
    'Perth',
    'Winkleigh',
    'Turners Beach',
    'Penguin',
    'Merriwa',
    'Penfield',
    'Waterloo Corner',
    'Summertown',
    'Gosforth',
    'Barellan',
    'Killingworth',
    'Windang',
    'Oakhurst',
    'Yeoval',
    'Miles',
    'Grantham',
    'Junee',
    'Green Hill',
    'Thagoona',
    'Tully',
    'Amamoor',
    'Mortlake',
    'Batlow',
    'Bangalow',
    'Biloela',
    'Hawkesbury Heights',
    'Narrandera',
    'Wamberal',
    'Linden',
    'Old Bar',
    'Nanango',
    'Silverdale',
    'Cooloolabin',
    'Crystal Creek',
    'Coolah',
    'Woodgate',
    'Bulga',
    'Mount Saint Thomas',
    'Kendall',
    'Wilton',
    'Scarness',
    'Minmi',
    'Broken Hill',
    'Royal Park',
    'Wonthaggi',
    'Buckland',
    'Christies',
    'Austins Ferry',
    'Apollo Bay',
    'Ararat',
    'Yallingup',
    'Mylor',
    'Mooroopna',
    'Ravenswood',
    'Rochester',
    'North Beach',
    'Applecross',
    'Laverton',
    'Tongala',
    'Balnarring',
    'Stanley',
    'Robinvale',
    'Roseworthy',
    'Cummins',
    'Tingoora',
    'Canungra',
    'Glossodia',
    'Woolgoolga',
    'Woombye',
    'Wooloweyah',
    'Howe',
    'Lockhart',
    'Stannum',
    'Port Sorell',
    'Tincurrin',
    'Upper Burringbar',
    'Beverley',
    'Kojonup',
    'Maidenwell',
    'Dirranbandi',
    'Darlington',
    'Baringhup',
    'Newnham',
    'Zeehan',
    'Forrest',
    'Koolewong',
    'Brandy Hill',
    'Branxton',
    'Windsor Downs',
    'Pontville',
    'Evandale',
    'Kinglake',
    'Tallarook',
    'Glenburn',
    'Braeside',
    'Mount Egerton',
    'Wedderburn',
    'Allanson',
    'Brookdale',
    'Broome',
    'Margaret River',
    'Wimbledon',
    'Bundanoon',
    'Gundaroo',
    'Nambucca Heads',
    'Ulmarra',
    'Narooma',
    'Burrum',
    'Tarzali',
    'Mount Julian',
    'Middlemount',
    'Oxley',
    'Mount Larcom',
    'Marmor',
    'Piggabeen',
    'Montville',
    'Armstrong Creek',
    'Bellara',
    'Howlong',
    'Copmanhurst',
    'Mandalong',
    'Wilmot',
    'Cooee',
    'Blackmans Bay',
    'Bagdad',
    'Railton',
    'Beacon',
    'Dover Heights',
    'Yarrawarrah',
    'Mount Morgan',
    'Safety Bay',
    'Bald Hills',
    'Portsea',
    'Ocean Shores',
    'Flinders',
    'Mount Perry',
    'Belgian Gardens',
    'Venus Bay',
    'Seven Mile Beach',
    'Gould',
    'Howard Springs',
    'Bilinga',
    'Horsham',
    'Abbotsham',
    'Black Rock',
    'Blackwarry',
    'Ross',
    'Denman',
    'Clarence Town',
    'Tenterfield',
    'Walgett',
    'Dorrigo',
    'Inverell',
    'Scone',
    'Stawell',
    'Ferndale',
    'Notting Hill',
    'Campbell',
    'Yanchep',
    'Winchelsea',
    'Serpentine',
    'Mount Macedon',
    'Cook',
    'Trinity Beach',
    'Caravonica',
    'Barwon Downs',
    'Burraneer',
    'Saint Andrews',
    'Glenore Grove',
    'Somersby',
    'Riana',
    'Kooyong',
    'Wandoan',
    'Dunsborough',
    'Ellangowan',
    'Balhannah',
    'Tatura',
    'Angaston',
    'Yallourn North',
    'McLaren Vale',
    'Riverside',
    'Urangan',
    'Applethorpe',
    'Booral',
    'Mount Ousley',
    'Douglas Park',
    'Wyreema',
    'Cannonvale',
    'Broadwater',
    'Mount Helen',
    'Buxton',
    'Muswellbrook',
    'Glen Osmond',
    'Risdon',
    'Lovely Banks',
    'Telegraph Point',
    'Medlow Bath',
    'Babinda',
    'Tieri',
    'Mooralla',
    'Normanton',
    'Hove',
    'Mount Direction',
    'Howden',
    'Norwood',
    'Hadspen',
    'Spreyton',
    'Bridgewater',
    'Collinsvale',
    'Sulphur Creek',
    'Lauderdale',
    'Dodges Ferry',
    'Bicheno',
    'Kempton',
    'Goodwood',
    'Endeavour',
    'Dalby',
    'Chinchilla',
    'Yarragon',
    'Parap',
    'Weston Creek',
    'Wongan Hills',
    'McLaren Flat',
    'Moama',
    'White Rock',
    'Deepdene',
    'Somerton',
    'Moruya',
    'Airlie Beach',
    'Canadian',
    'Edge Hill',
    'Advancetown',
    'Big Pats Creek',
    'Myrrhee',
    'Gilberton',
    'Limestone',
    'Logan Village',
    'Rodborough',
    'Highfields',
    'Marshalltown',
    'Stirling',
    'Dereel',
    'Yinnar',
    'Malmsbury',
    'Koornalla',
    'Glengarry',
    'Koroit',
    'Tambo Upper',
    'Maffra',
    'Tarlee',
    'Eudunda',
    'Waterloo',
    'Clare',
    'Long Plains',
    'Gordonvale',
    'Pittsworth',
    'Gheerulla',
    'Westbrook',
    'Lawgi',
    'Blackbutt',
    'Crows Nest',
    'Coutts Crossing',
    'Binnaway',
    'Baynton',
    'Toora',
    'Orroroo',
    'Chatsworth Island',
    'One Tree Hill',
    'Nar Nar Goon',
    'Argents Hill',
    'Dumbalk',
    'Pechey',
    'Bowraville',
    'Nhill',
    'Brown Hill',
    'Kerry',
    'Allora',
    'Yangan',
    'Smythesdale',
    'Success',
    'Cockburn',
    'Norlane',
    'Bridgewater',
    'Port Adelaide',
    'Coromandel Valley',
    'Beaumont',
    'Queensferry',
    'Lockridge',
    'Braddon',
    'Tawonga',
    'Saints',
    'Walhalla',
    'Karnup',
    'Tomago',
    'Drummond Cove',
    'Hyland Park',
    'Moranbah',
    'Willow Grove',
    'Macquarie Park',
    'Gowrie',
    'West Wyalong',
    'Everton',
    'Rosanna',
    'Wyalkatchem',
    'Mundaring',
    'Kununurra',
    'Exmouth',
    'Esperance',
    'Tumut',
    'Blayney',
    'Traralgon South',
    'Clontarf',
    'Heathcote Junction',
    'Wickham',
    'Bundalong',
    'Purnim',
    'Nirranda',
    'Grassmere',
    'Carlton South',
    'Yarra Glen',
    'Mount Dandenong',
    'Macksville',
    'Halls Gap',
    'Kalorama',
    'Bombala',
    'Douglas',
    'Freeling',
    'Corinthia',
    'Tyalgum',
    'Woodburn',
    'Wentworth',
    'Welshpool',
    'Baker',
    'Tooronga',
    'Homebush',
    'Forrest',
    'Torquay',
    'Woodvale',
    'Illawarra',
    'Campbelltown',
    'Blair Athol',
    'Roleystone',
    'Gore Hill',
    'Bilpin',
    'Yenda',
    'Hendon',
    'Ross River',
    'Glenorchy',
    'Dimboola',
    'Blackwood',
    'Pinery',
    'Cotham',
    'Peterborough',
    'Hexham',
    'Murtoa',
    'Rudall',
    'Alma',
    'Cedar Grove',
    'Echunga',
    'Parkhurst',
    'Temora',
    'Wiluna',
    'Mundijong',
    'Langley',
    'Crawford',
    'Marian',
    'Tom Price',
    'Weethalle',
    'Coober Pedy',
    'Glen Huon',
    'Beenak',
    'Anglesea',
    'Carbrook',
    'Little River',
    'Lang Lang',
    'Whitfield',
    'Mangalore',
    'Yarpturk',
    'Kambalda',
    'Blackwater',
    'Darlington',
    'Eaton',
    'Carnarvon',
    'Tuggeranong',
    'City of West Torrens',
    'Lower Chittering',
    'Dunmore',
    'Laguna',
    'Gravesend',
    'Hahndorf',
    'Wollar',
    'Won Wron',
    'Eden Park',
    'Piawaning',
    'Gilston',
    'Drouin West',
    'Parkerville',
    'Coonawarra',
    'Brewarrina',
    'Mallanganee',
    'York',
    'Wooroolin',
    'Martins Creek',
    'Ashton',
    'Austinmer',
    'Westfield',
    'Berrima',
    'Pitt Town',
    'Newstead',
    'Grandchester',
    'Birdwood',
    'Tolga',
    'Pearcedale',
    'Boneo',
    'Walmer',
    'Nagambie',
    'Kardella South',
    'Allambee',
    'Yarraman',
    'Nannup',
    'Perenjori',
    "O'Sullivan Beach",
    'Barry',
    'Sunnyside',
    'Shoal Point',
    'Hawker',
    'Cambridge',
    'McLachlan',
    'Hopetoun',
    'Tallangatta',
    'Pinkenba',
    'Kings Cross',
    'Lyons',
    'Lowanna',
    'Bool Lagoon',
    'Valley Heights',
    'Saint George',
    'Bomaderry',
    'Point Lookout',
    'Ceduna',
    'Mossman',
    'Sandy Hollow',
    'Mount Molloy',
    'Salamander Bay',
    'Woodstock',
    'Menangle',
    'Glen Innes',
    'Arrawarra',
    'Enmore',
    'Koorawatha',
    'Sapphire',
    'Mullewa',
    'Aberdeen',
    'Boyup Brook',
    'Augusta',
    'Dumbleyung',
    'Southern Brook',
    'Wyalong',
    'Talmalmo',
    'Coleraine',
    'Manjimup',
    'Calingiri',
    'Obi Obi',
    'Bullyard',
    'Broke',
    'Womboota',
    'Mirboo',
    'Retreat',
    'Kyogle',
    'Cawongla',
    'Koah',
    'Bulahdelah',
    'Spencer',
    'Cooktown',
    'Coolamon',
    'Sarina Beach',
    'Coledale',
    'Melrose',
    'Maroota',
    'Balmoral',
    'Mount White',
    'Mount Keira',
    'Dunkeld',
    'Hammond',
    'Hoddles Creek',
    'Copacabana'
  ],
  Vietnam: [
    'Hanoi',
    'Ho Chi Minh City',
    'Can Tho',
    'Thanh Nguyen',
    'Dinh Long',
    'Vinh',
    'Nghi Loc',
    'Phu Xuyen',
    'Tan Thuan Dong',
    'Nam Định',
    'Thanh Pho Thai Nguyen',
    'Haiphong',
    'Huế',
    'Da Nang',
    'Da Lat',
    'Khanh Hoa',
    'Quang Trung',
    'An Thanh',
    'Long An',
    'Thanh Pho Ninh Binh',
    'Thanh Pho Hoa Binh',
    'Tan Binh',
    'Xom Truong Dinh',
    'Le Loi',
    'Phuong Tan',
    'Ap Trung',
    'Binh Thanh',
    'Xom Thang',
    'Thu Dau Mot',
    'Bach Ma',
    'Dien Bien Phu',
    'Long Van',
    'Ben Nghe',
    'Phu Nhuan',
    'Ap Sai Gon',
    'Go Vap',
    'Truong Son',
    'Thong Nhat',
    'Thanh Pho Thai Binh',
    'Van Tan',
    'An Tam',
    'Co Giang',
    'Quan Ngoc',
    'Nha Be',
    'Thu Duc',
    'Bac Giang',
    'Tang',
    'Ha Long',
    'Thanh Pho Hai Duong',
    'Bac Ninh',
    'Nha Trang',
    'Pleiku',
    'Vũng Tàu',
    'Thuan An',
    'Hung Yen',
    'Thanh Pho Tuyen Quang',
    'Ha Nam',
    'Bien Hoa',
    'Di An',
    'Vinh Yen',
    'Luong Hoi',
    'Phan Thiết',
    'Quảng Ngãi',
    'Qui Nhon',
    'Buon Ma Thuot',
    'Yen Thanh',
    'Bac Kan',
    'Lao Cai',
    'Lang Son',
    'Phu Tho',
    'Yen Bai',
    'Sơn La',
    'Thanh Pho Ha Giang',
    'An Dinh',
    'Quan Trang',
    'Thanh Hóa',
    'Huong Tinh',
    'Quang Tri',
    'Quang Nam',
    'Giong Ngai',
    'An Nhon',
    'Dac Loc',
    'Phu Yen',
    'Kon Tum',
    'Dong',
    'Binh Thoai',
    'Thon Bao An',
    'Binh Phuoc',
    'Long Tan',
    'Bến Tre',
    'Dong Thap',
    'An Giang',
    'Ca Mau',
    'Soc Trang',
    'Tay Ninh',
    'Mỹ Tho',
    'Dong Nai',
    'Ha Tinh',
    'Lang Dung',
    'Ha Dong',
    'Hoang Mai',
    'Cho Ha',
    'Tra Vinh',
    'Thach That',
    'Bang',
    'Xom Phuoc My',
    'Cam Pha',
    'Vinh Long',
    'Vi Thanh',
    'Phan Thon',
    'Chuong Duong',
    'Minh Khai',
    'Giong Truong',
    'Ben Than',
    'Thuan Cong',
    'Duc Thang',
    'Lang Hong',
    'Van Hai',
    'Tam Ky',
    'Truong Lam',
    'Trao'
  ],
  Russia: [
    'Moscow',
    'Omsk',
    'Peschanka',
    'Novovarshavka',
    'Luzino',
    "Mar'yanovskiy",
    'Alekseyevka',
    'Abakan',
    'Sayanogorsk',
    'Chernogorsk',
    'Borodino',
    'Beya',
    'Volgograd',
    'Rostov-on-Don',
    "Stavropol'skiy Rayon",
    'Smolensk',
    'Kursk',
    'Astrakhan',
    'Ulyanovsk',
    'Rybinsk',
    'Lipetsk',
    'Dimitrovgrad',
    'Kostroma',
    'Kaluga',
    'Kaliningrad',
    'Tolyatti',
    'Sterlitamak',
    'Kemerovo',
    'Chelyabinsk',
    'Krasnodar',
    'Severodvinsk',
    'Samara',
    'Beloretsk',
    'Voronezh',
    'Ufa',
    'Samara',
    'Cherepovets',
    'Tyumen',
    'Saint Petersburg',
    'Shebekino',
    'Elektrostal',
    'Khabarovsk',
    'Novosibirsk',
    'Ryazan',
    'Avtozavodskiy Rayon',
    'Khimki',
    'Korolev',
    'Pushkino',
    'Mytishchi',
    'Kubinka',
    'Kolomna',
    "Novoural'sk",
    'Yekaterinburg',
    "Severoural'sk",
    'Syktyvkar',
    "Kamensk-Ural'skiy",
    'Ulan-Ude',
    'Barnaul',
    'Surgut',
    'Kushva',
    'Liski',
    'Zlatoust',
    'Miass',
    "Yuzhnoural'sk",
    'Vladivostok',
    'Nizhnevartovsk',
    'Zvezda',
    'Shatura',
    'Chik',
    'Iskitim',
    'Linevo',
    'Kuybyshev',
    "Yegor'yevka",
    'Pyatigorsk',
    'Stavropol',
    'Yessentuki',
    'Budënnovsk',
    'Georgiyevsk',
    'Vinsady',
    'Kislovodsk',
    'Georgiyevskoye',
    'Sochi',
    'Labinsk',
    'Zarechenskiy',
    'Bataysk',
    'Taganrog',
    'Novocherkassk',
    'Zolotarevka',
    'Novorossiysk',
    'Svetlyy',
    'Mostovskoy',
    'Chyorny Yar',
    'Cherkessk',
    'Sovetskiy',
    'Megion',
    'Tarko-Sale',
    'Urengoy',
    'Perm',
    'Revda',
    'Alapayevsk',
    'Kurgan',
    'Gubkinskiy',
    "Lys'va",
    'Pangody',
    'Nefteyugansk',
    'Salekhard',
    'Nyagan',
    'Verkhnyaya Salda',
    "Sysert'",
    "Nev'yansk",
    'Aykino',
    'Ukhta',
    'Kotlas',
    'Arkhangelsk',
    'Chernyakhovsk',
    'Sovetsk',
    'Murmansk',
    'Severomorsk',
    'Murmashi',
    'Kandalaksha',
    'Anuchino',
    'Ussuriysk',
    'Nakhodka',
    'Yekaterinovka',
    'Sergeyevka',
    'Novoshakhtinskiy',
    "Arsen'yev",
    "Spassk-Dal'niy",
    'Artëm',
    'Belgorod',
    'Tver',
    'Sputnik',
    'Vologda',
    'Sheksna',
    'Vsevolozhsk',
    'Bryansk',
    'Volgodonsk',
    'Pavlovsk',
    "Sevastopol'",
    'Cheboksary',
    'Taushkasy',
    'Veliky Novgorod',
    'Krasnoyarsk',
    'Yaroslavl',
    'Posad',
    'Tula',
    'Kurgan',
    'Magnitogorsk',
    'Samara',
    'Samsonovka',
    "Syzran'",
    'Nizhniy Novgorod',
    'Bor',
    'Izhevsk',
    'Irkutsk',
    'Kalashnikovo',
    'Penza',
    'Solnechnogorsk',
    'Saratov',
    'Tomsk',
    'Seversk',
    'Ufa',
    'Angarsk',
    'Yoshkar-Ola',
    'Bibayevo',
    'Pervouralsk',
    'Vladikavkaz',
    'Novovoronezh',
    "Rossosh'",
    'Baranchinskiy',
    'Domodedovo',
    'Lytkarino',
    'Podolsk',
    'Pskov',
    "Usol'ye-Sibirskoye",
    'Kushnarenkovo',
    'Iglino',
    'Dyurtyuli',
    'Yaratovo',
    'Tuymazy',
    'Meleuz',
    'Belebey',
    'Ramenskoye',
    'Noginsk',
    'Moskovskiy',
    'Anapa',
    'Zhukovskiy',
    'Azov',
    'Krasnyy Sulin',
    'Zernograd',
    'Rodionovka',
    'Kamensk-Shakhtinskiy',
    'Donetsk',
    'Shakhty',
    'Kurgan',
    'Tagil',
    'Serpukhov',
    'Krasnoufimsk',
    'Lesnoy',
    'Lesnoy',
    'Vladimir',
    'Sobinka',
    'Lakinsk',
    'Arzamas',
    'Balakovo',
    "Engel's",
    'Chkalov',
    'Novosokolovogorskiy',
    'Volga',
    'Kuznetsk',
    'Druzhba',
    "Nal'chik",
    'Glazov',
    'Maykop',
    'Novaya Silava',
    'Bratsk',
    'Pivovar',
    'Konakovo',
    'Biysk',
    'Rybnoye',
    'Shelekhov',
    'Lisikha',
    'Baykal',
    'Volzhskiy',
    'Apatity',
    'Kirovsk',
    "Khot'kovo",
    'Tuapse',
    'Pokrov',
    'Nevinnomyssk',
    'Polevskoy',
    'Unecha',
    'Kingisepp',
    'Chita',
    "Gus'-Khrustal'nyy",
    'Tikhvin',
    "Severobaykal'sk",
    'Novokruchininskiy',
    'Novokuznetsk',
    'Kuznetskiy Rayon',
    'Maloyaroslavets',
    'Blagoveshchensk',
    "Alatyr'",
    'Zavodoukovsk',
    'Kungur',
    'Berezniki',
    'Okhansk',
    'Kachkanar',
    'Irbit',
    'Asha',
    "Chebarkul'",
    'Borovskiy',
    'Ishim',
    'Uktuz',
    'Yalutorovsk',
    'Uray',
    'Novy Urengoy',
    'Krasnokamsk',
    'Nytva',
    'Khanty-Mansiysk',
    'Kstovo',
    'Yurga',
    'Betlitsa',
    "Mosal'sk",
    'Kamyshin',
    'Nikolayevsk',
    'Borodynovka',
    'Kurganinsk',
    'Kanevskaya',
    'Starovelichkovskaya',
    "Gul'kevichi",
    'Uspenskoye',
    'Abinsk',
    'Slavyansk-na-Kubani',
    'Armavir',
    'Chelbasskaya',
    'Yeysk',
    'Mikhnëvo',
    'Vyksa',
    'Novodmitriyevka',
    'Zheleznogorsk',
    'Dzerzhinsk',
    'Saransk',
    'Start',
    "Bol'shoye",
    'Kolpino',
    'Pushkin',
    'Borisoglebsk',
    'Chekhov',
    'Protvino',
    'Klimovsk',
    'Bilibino',
    'Khrenovoye',
    'Volga',
    'Tayga',
    'Kiselëvsk',
    'Sevsk',
    'Kopeysk',
    'Voskresenskoye',
    'Tambov',
    'Uvarovo',
    'Timashëvsk',
    'Gelendzhik',
    'Belorechensk',
    'Dinskaya',
    'Krymsk',
    'Brod',
    'Noyabrsk',
    'Labytnangi',
    'Nadym',
    "Tobol'sk",
    'Stary Oskol',
    'Orenburg',
    'Sosnovoborsk',
    'Kirov',
    "Kazan'",
    'Sibay',
    'Ishkulovo',
    'Birsk',
    'Novobelokatay',
    "Karaidel'",
    'Aramil',
    'Mezhdurechensk',
    'Berdsk',
    'Ural',
    'Solikamsk',
    'Artem',
    'Novocheboksarsk',
    'Novokuybyshevsk',
    'Novosemeykino',
    'Vyshniy Volochëk',
    'Belogorsk',
    'Makhachkala',
    "Kuban'",
    'Nizhniy Tagil',
    'Liniya',
    'Zheleznogorsk',
    'Zheleznovodsk',
    'Kodinsk',
    'Krasnogorsk',
    'Lomonosov',
    'Petergof',
    'Zarechnyy',
    "Sovetskaya Gavan'",
    'Odintsovo',
    'Putilkovo',
    'Veshki',
    'Strezhevoy',
    'Zelenograd',
    'Rassvet',
    'Kamenolomni',
    'Selo',
    'Gukovo',
    'Krasnoye-na-Volge',
    "Kazan'",
    'Alikovo',
    'Zelenodolsk',
    'Achinsk',
    'Fryazino',
    'Vega',
    'Snezhinsk',
    'Lesozavodsk',
    'Priozersk',
    'Roshchino',
    'Yakutsk',
    'Novozolotovskaya',
    "Bol'shiye Saly",
    'Orlovskiy',
    'Persianovka',
    'Rodionovo-Nesvetayskaya',
    'Chaykovskiy',
    'Kurchatov',
    'Novozavedennoye',
    'Belgorod',
    'Orël',
    'Kromy',
    'Kamyshovka',
    'Fatezh',
    'Nizhnekamsk',
    'Dmitrov',
    'Aleksandrov',
    'Sarapul',
    'Khabarskoye',
    'Chiverevo',
    'Pioner',
    'Tikhoretsk',
    'Kropotkin',
    'Leningradskaya',
    'Slavyanka',
    'Parkovyy',
    "Ust'-Labinsk",
    "Kirpil'skaya",
    'Pavlovskaya',
    'Novokorsunskaya',
    'Lesodacha',
    'Kalininskaya',
    'Moldavanskoye',
    'Leninskiy',
    'Staronizhestebliyevskaya',
    'Ivanovo',
    'Rubtsovsk',
    'Snezhinskiy',
    'Serov',
    'Verkhnyaya Pyshma',
    'Svetlyy',
    'Zelenogorsk',
    'Lesosibirsk',
    'Myski',
    'Nova',
    'Zvenigorod',
    'Kaltan',
    'Kristall',
    'Balashov',
    'Vyborg',
    'Gusev',
    'Neftekamsk',
    'Ishimbay',
    'Volga',
    'Salavat',
    'Orsk',
    'Kovrov',
    'Zaraysk',
    'Bashkortostan',
    'Kineshma',
    'Novoaltaysk',
    'Leninskoye',
    'Ruza',
    'Naro-Fominsk',
    'Postnikova',
    'Filial',
    'Asbest',
    'Rubin',
    "Ust'-Ilimsk",
    'Vyatka',
    'Furmanov',
    "Vspomogatel'nyy",
    'Tynda',
    'Kholmsk',
    'Tuchkovo',
    'Skovorodino',
    'Konosha',
    'Istra',
    'Chernogolovka',
    'Kupavna',
    'Vorovskogo',
    'Sharapova Okhota',
    'Sukhanovo',
    'Kosteniki',
    'Amur',
    'Dubna',
    'Komintern',
    'Svetlograd',
    'Lenina',
    'Obninsk',
    "Del'ta",
    'Staraya Russa',
    'Techa',
    'Patrushi',
    'Pushchino',
    'Sosnogorsk',
    'Nartkala',
    'Zhukovskogo',
    "Arkhangel'skoye",
    'Kanavka',
    'Dudinka',
    'Zheleznodorozhnyy',
    'Petropavlovsk-Kamchatsky',
    'Partizansk',
    'Spassk',
    "Dal'negorsk",
    'Chernigovka',
    "Primor'ye",
    "Bol'shoy Kamen'",
    'Barabash',
    "Bol'shak",
    "Koz'modem'yansk",
    'Zvenigovo',
    'Volzhsk',
    'Lobnya',
    'Ozëry',
    'Klin',
    'Reutov',
    'Orekhovo-Zuyevo',
    'Elektrougli',
    'Losino-Petrovskiy',
    'Bronnitsy',
    'Rostov',
    "Gran'",
    'Tarusa',
    'Agidel',
    'Kamchatka',
    'Petropavlovka',
    'Yelizovo',
    'Nazran',
    'Volkhonshchino',
    'Nauka',
    'Pavlovskiy Posad',
    'Rozhdestvenka',
    'Klintsy',
    'Manturovo',
    'Oshib',
    'Ishley',
    'Zavodouspenskoye',
    "Ust'-Kut",
    'Zima',
    'Balabanovo',
    'Borovichi',
    'Gatchina',
    'Kolomino',
    'Romanovka',
    'Marks',
    'Mozhga',
    'Snezhnogorsk',
    'Nikel',
    'Petrozavodsk',
    "Nikol'skoye",
    'Vorkuta',
    'Usinsk',
    'Okulovka',
    'Chudovo',
    'Svetlogorsk',
    'Velikiye Luki',
    'Pryazha',
    'Morozovo',
    'Naryan-Mar',
    'Koryazhma',
    'Sertolovo',
    'Votkinsk',
    'Kvatchi',
    'Pychas',
    "Kush'ya",
    'Kizner',
    'Bras',
    'Igra',
    'Gorno-Altaysk',
    'Temryuk',
    'Morshansk',
    'Kirsanov',
    'Dmitriyevka',
    "Bugul'ma",
    'Metallostroy',
    'Yekaterinoslavka',
    'Svobodnyy',
    'Arkhara',
    'Seryshevo',
    'Belogorka',
    'Zeya',
    'Magdagachi',
    'Raychikhinsk',
    'Vysokovsk',
    'Nadezhdinka',
    'Neryungri',
    'Aldan',
    'Ibresi',
    'Magnitnyy',
    'Agapovka',
    'Novator',
    'Bologoye',
    'Redkino',
    'Kirishi',
    'Mamontovka',
    'Lyubertsy',
    'Kirovgrad',
    "Kotel'niki",
    'Slantsy',
    "Stroitel'",
    'Berezayka',
    'Teykovo',
    "Noril'sk",
    'Glebychevo',
    'Kirzhach',
    'Yuzhno-Sakhalinsk',
    'Pugachev',
    'Otradnyy',
    'Chapayevsk',
    'Zhigulevsk',
    'Lukhovitsy',
    'Sokol',
    'Gagarin',
    'Komsomolsk-on-Amur',
    'Zelenogorsk',
    'Leningradskiy',
    'Piter',
    'Sarov',
    'Vitebskaya',
    'Sloboda',
    'Chernushka',
    'Dolgoprudnyy',
    'Izmaylovo',
    'Vichuga',
    "Ural'skaya",
    'Derbent',
    'Khasavyurt',
    'Malaya Vishera',
    'Podstepki',
    'Magadan',
    'Detchino',
    'Grozny',
    'Staraya Kupavna',
    'Satis',
    'Pskovskoye',
    'Krasnoarmeyskaya',
    'Pervo',
    'Torzhok',
    'Smolenskaya',
    'Kirova',
    'Stroiteley',
    'Altayskoye',
    'Kabardino',
    'Vladykino',
    'Nakhabino',
    'Vnukovo',
    'Kyshtym',
    'Trekhgornyy',
    'Melenki',
    'Lebedev',
    'Sofrino',
    'Tyrma',
    'Borzya',
    'Safonovo',
    'Nizhneudinsk',
    "Ust'-Katav",
    'Elista',
    'Barnaul',
    "Al'met'yevsk",
    'Yelabuga',
    "Chistopol'",
    'Skala',
    'Sharnutovskiy',
    'Nelidovo',
    'Lesnoy Gorodok',
    'Odintsovo',
    'Tomilino',
    'Metallurg',
    'Koptyuga',
    'Markovo',
    'Urgal',
    'Rzhev',
    'Desnogorsk',
    'Krasnoznamensk',
    'Uglich',
    'Kronshtadt',
    'Pargolovo',
    'Sergiyevskoye',
    'Morozov',
    'Mayakovskogo',
    'Ogarev',
    'Lenkino',
    'Gogolya',
    'Prokhladnyy',
    'Baksan',
    'Akhtubinsk',
    'Krasnyy Yar',
    'Frolovo',
    'Rovnoye',
    'Sorochinsk',
    'Ariadnoye',
    'Bovykiny',
    'Chemashur',
    'Lazo',
    'Altay',
    'Shebalino',
    'Millerovo',
    'Zubovo',
    'Nikolayevsk-on-Amur',
    'Birobidzhan',
    'Amursk',
    'Moskovskaya',
    'Rossiyskiy',
    'Mikhaylovskoye',
    'Rodniki',
    'Khangash-Yurt',
    "Kinel'",
    'Volzhskiy',
    'Pervomayskaya',
    'Progress',
    'Sary',
    'Aksenovo',
    'Kadrovik',
    'Korkino',
    'Avangard',
    'Gubakha',
    'Chastyye',
    'Minusinsk',
    'Potok',
    'Vidnoye',
    'Studenets',
    'Sistema',
    'Mariinskiy Posad',
    'Kanash',
    'China',
    'Olimpiyskiy',
    'Neman',
    'Vagay',
    'Nizhegorodskaya',
    'Kraskovo',
    'Verkhnekazymskiy',
    'Soldatskoye',
    'Sofrino',
    'Sviblovo',
    'Krasnoarmeysk',
    'Sergiyev Posad',
    'Bugry',
    'Ugra',
    'Mira',
    'Kartaly',
    'Novotroitsk',
    'Mednogorsk',
    'Troitsk',
    'Sartykov',
    'Sosnovo',
    'Chërnaya Kholunitsa',
    'Vostochnyy',
    'Karin Perevoz',
    'Vostochnaya',
    'Yelanskiy',
    'Lyantor',
    'Sverdlovskaya',
    'Miasskoye',
    'Krasnokamensk',
    "Shtol'nyy",
    'Novomoskovsk',
    'Rakita',
    'Glazunovka',
    "Severo-Kuril'sk",
    'Uglegorsk',
    'Komarov',
    'Slyudyanka',
    'Cheremkhovo',
    'Isheyevka',
    'Povarovo',
    "Bad'-Pashnya",
    'Voskresensk',
    'Denisovo',
    'Dzerzhinskiy',
    'Radist',
    'Ilya',
    "Osel'ki",
    'Novoye Devyatkino',
    'Klyuchi',
    'Zaprudnya',
    'Sosnovyy Bor',
    'Karabulak',
    'Izberbash',
    'Dedovsk',
    'Satka',
    "Yel'niki",
    'Drezna',
    'Damkhurts',
    'Dagomys',
    'Golitsyno',
    'Kavkaz',
    'Novyy',
    'Gorodna',
    'Purovsk',
    'Orshanka',
    'Atlas',
    'Nazarovo',
    'Kholmistyy',
    'Buzuluk',
    'Gudermes',
    'Kulikov',
    'Ovchinnikov',
    'Murom',
    'Verkhniy Ufaley',
    "Al'fa",
    'Kansk',
    'Ilim',
    "L'vovskiy",
    'Novomichurinsk',
    "Uren'",
    'Stupino',
    'Kamennomostskiy',
    'Novaya Igirma',
    "Mebel'naya Fabrika",
    'Poronaysk',
    'Severo-Zapad',
    "Krasnotur'insk",
    'Uryupinsk',
    'Krasnogorskiy',
    'Kugesi',
    'Uyar',
    'Sayansk',
    'Lada',
    'Perepravnaya',
    'Yanaul',
    'Sheregesh',
    'Troitsk',
    'Udomlya',
    "Zaural'skiy",
    "Zavolzh'ye",
    'Lodeynoye Pole',
    "Podporozh'ye",
    "Vyaz'ma",
    'Bezhetsk',
    'Ibrya',
    "Star'",
    'Rostovskaya',
    'Gorin',
    'Vyazemskiy',
    "Kor'",
    "Komsomol'sk",
    'Vysokogornyy',
    "Obluch'ye",
    'Bogorodskoye',
    'Nikulin',
    'Sestroretsk',
    'Begunitsy',
    'Novodvinsk',
    'Nelazskoye',
    'Buyantsevo',
    'Nadvoitsy',
    'Kostomuksha',
    'Prirechensk',
    'Novoselovo',
    'Asino',
    'Zheleznogorsk-Ilimskiy',
    'Kolpashevo',
    "Prokop'yevsk",
    'Anzhero-Sudzhensk',
    'Berezovka',
    'Leninsk-Kuznetsky',
    'Topki',
    'Borkovka',
    "Roslavl'",
    'Kumertau',
    'Zelenogradsk',
    'Avri',
    'Staropesterevo',
    'Belovo',
    'Shchelkovo',
    'Sverdlovskiy',
    'Monino',
    'Perovka',
    'Kamenka',
    "Lomov'",
    "Bednodem'yanovsk",
    'Sursk',
    'Baza',
    'Bogoroditsk',
    'Reftinskiy',
    'Starodub',
    'Kovdor',
    'Dalmatovo',
    'Livny',
    'Korsakov',
    'Okha',
    'Novoshakhtinsk',
    "Ivan'",
    'Osnova',
    'Aro',
    'Sverdlova',
    'Svirsk',
    'Segezha',
    'Aleksin',
    'Shchekino',
    'Kimovsk',
    'Plavsk',
    'Dubovka',
    'Blagovar',
    'Tatlybayevo',
    'Ashchibutak',
    'Kirgiz-Miyaki',
    'Povedniki',
    'Barda',
    'Kusa',
    'Rezh',
    'Ocher',
    'Roshchino',
    'Arti',
    'Yugorsk',
    'Novozybkov',
    'Karachev',
    'Severskaya',
    'Mega',
    'Pulkovo',
    'Pyshma',
    'Kalinina',
    'Sortavala',
    'Raduga',
    'Selyatino',
    "Vrangel'",
    'Kastornoye',
    'Kyzyl',
    "Bol'shaya Rakovka",
    'Dedenevo',
    'Yakhroma',
    'Svobodnaya',
    'Surkhakhi',
    'Ilovlya',
    "Bol'shaya Fëdorovka",
    'Belozërsk',
    'Belyy',
    'Kirovo-Chepetsk',
    'Spas-Demensk',
    'Mga',
    'Ivangorod',
    "Singer'",
    'Elektrogorsk',
    'Krugloye',
    'Likino-Dulevo',
    'Kashira',
    'Mineralnye Vody',
    "Rossosh'",
    'Pavlovo',
    'Zaozersk',
    'Baltiysk',
    'Nizhniy Odes',
    'Troitsko-Pechorsk',
    'Pechora',
    'Inta',
    'Vytegra',
    'Gryazovets',
    'Pestovo',
    'Parfino',
    'Russa',
    'Valday',
    'Monchegorsk',
    'Zori',
    'Tosno',
    "Kinel'-Cherkassy",
    'Vanino',
    "Gavan'",
    "Gavan'",
    'Gubkin',
    'Alekseyevka',
    'Mozhaysk',
    'Shaturtorf',
    'Balashikha',
    'Pushchino',
    'Ratmirovo',
    'Kabanovo',
    "Roshal'",
    'Vurnary',
    'Kochkurovo',
    'Insar',
    'Maksimovka',
    'Atkarsk',
    'Parapino',
    'Snegiri',
    'Gaurilov',
    'Chesnokovka',
    'Kasimov',
    "Yelat'ma",
    "Kol'chugino",
    'Kiryabinskoye',
    'Uchaly',
    'Lesnoy',
    "Pereslavl'-Zalesskiy",
    'Krasnogvardeyskiy',
    'Nevskiy',
    'Frunzenskiy',
    'Uzlovaya',
    'Shchëkino',
    'Donskoy',
    'Serdobsk',
    'Naumkino',
    'Voskresenskoye',
    'Luchegorsk',
    'Lampovo',
    'Krasnodarskiy',
    "Kol'tsovo",
    'Gagino',
    "Sibir'",
    'Chaplygin',
    'Volovo',
    'Yelets',
    'Olenegorsk',
    'Enkheluk',
    'Gusinoozyorsk',
    'Starobachaty',
    'Nizhneyashkino',
    "Gur'yevsk",
    'Yashkino',
    'Yaya',
    "Odan'",
    'Bogdashino',
    'Mtsensk',
    'Golovchino',
    'Dubenka',
    'Leninogorsk',
    'Gornozavodsk',
    'Divnogorsk',
    'Tsna',
    'Aksay',
    'Morozovsk',
    'Ryasnoye',
    'Krasnoarmeyskoye',
    'Krasnoobsk',
    'Yermolkino',
    'Pleloye',
    'Turinsk',
    'Suzëmka',
    "Kozel'sk",
    'Pechory',
    'Volgorechensk',
    'Omchak',
    'Aleysk',
    'Kimry',
    'Sosny',
    'Zarinsk',
    'Belaya Kalitva',
    'Voronezhskiy',
    'Sredneuralsk',
    'Semibratovo',
    'Yugra',
    'Neya',
    'Vyazniki',
    'Poselok',
    'Varvarovka',
    'Pushkina',
    "Ob'",
    'Bagan',
    'Maslyanino',
    'Cherepanovo',
    'Zainsk',
    'Otradnoye',
    'Danilov',
    'Obyedineniye',
    'Ugresha',
    'Lipetsk',
    'Urus-Martan',
    "Tsivil'sk",
    'Taksimo',
    'Kusakovka',
    'Bogorodsk',
    'Kizlyar',
    "Kotel'nich",
    'Ruzayevka',
    'Fialka',
    'Krasnokamensk',
    'Tochka',
    'Aprelevka',
    'Shadrinsk',
    'Zaokskiy',
    'Kuzovatovo',
    'Kondopoga',
    'Adygeysk',
    'Tyrnyauz',
    'Pregradnaya',
    'Mozdok',
    'Shalushka',
    'Vyatskiye Polyany',
    'Shoshma',
    'Urzhum',
    'Podgortsy',
    "Lyambir'",
    'Usinskoye',
    'Krasnyy Kut',
    'Kharp',
    'Kalino',
    "Ivdel'",
    'Dobryanka',
    'Ishimskiy',
    'Kurtamysh',
    'Michurinsk',
    'Rikasikha',
    'Kichmengskiy Gorodok',
    'Velikiy Ustyug',
    'Syamzha',
    "Ust'ye",
    'Poselok',
    'Revda',
    'Kola',
    'Polyarnyy',
    'Kirovsk',
    "Shlissel'burg",
    'Boksitogorsk',
    'Svetogorsk',
    'Pitkyaranta',
    'Sebezh',
    "Bol'shoe Isakovo",
    'Gvardeysk',
    "Tot'ma",
    "Oktyabr'skiy",
    'Serafimovskiy',
    'Linda',
    'Kirovskaya',
    'Klyuchi-Bulak',
    'Shalinskoye',
    'Boguchany',
    'Srostki',
    'Mamontovo',
    "Kamen'-na-Obi",
    "Kuz'minka",
    'Sokolovo',
    'Apazovo',
    'Naberezhnyye Chelny',
    "Lebedyan'",
    'Yalta',
    'Volna',
    'Noginskaya',
    'Lermontov',
    'Volochek',
    'Pavlovskaya Sloboda',
    'Cherkizovo',
    "Usman'",
    'Tolstoy',
    'Birzha',
    'Valentin',
    "Mor'ye",
    'Dubrovka',
    'Divo',
    "Verkhotur'ye",
    'Bulgakov',
    'Bogdanovich',
    'Maykor',
    'Tazovskiy',
    'Filatovskoye',
    'Kasli',
    'Chernushka',
    'Muravlenko',
    'Panayevsk',
    'Tayshet',
    'Zhuravna',
    'Tulun',
    'Medvenka',
    'Tim',
    'Granit',
    'Malino',
    'Semyonov',
    'Kamennogorsk',
    'Poztykeres',
    'Medvedevo',
    'Dubovka',
    "Nevel'sk",
    'Zhirnovsk',
    'Yakovlevo',
    'Tsudakhar',
    "Ust'-Ilimsk",
    'Volkhov',
    "Syas'stroy",
    'Aleksandrovka',
    'Taldom',
    'Timiryazev',
    'Neva',
    'Kamensk',
    "Sven'",
    'Beryozovsky',
    'Gubkin',
    'Pushchino',
    'Volodarskiy',
    'Salym',
    'Karabash',
    "Yemel'yanovo",
    'Rosa',
    'Trubchevsk',
    'Katav-Ivanovsk',
    'Krasnoznamensk',
    'Svetlaya',
    'Ostrov',
    "Buret'",
    'Kirilly',
    'Ostankino',
    'Sodyshka',
    'Karla Marksa',
    'Pikalyovo',
    'Slavgorod',
    "Solnechnodol'sk",
    'Severo',
    'Malysheva',
    'Shumerlya',
    'Aleksino',
    'Skat',
    'Gorbatov',
    "Kel'dysh",
    'Neftekumsk',
    'Vikhorevka',
    'Tapkhar'
  ],
  France: [
    'Pont-Saint-Martin',
    'Luce',
    'Montaigu',
    'La Chapelle-de-Brain',
    'Saint-Mars-du-Desert',
    'Challans',
    'Le Mans',
    'Saint-Laurent-de-la-Plaine',
    'Baule',
    'Cholet',
    'Bouaye',
    'Le Poire-sur-Velluire',
    'Nantes',
    'Trelaze',
    'Pornichet',
    'Sallertaine',
    'La Montagne',
    'Angers',
    'Saint-Nazaire',
    'Chateaubriant',
    'Saint-Michel-le-Cloucq',
    'Brissac-Quince',
    'Saint-Julien-de-Concelles',
    'Andouille',
    'Bessay',
    'Chavagnes-en-Paillers',
    'Moutiers-les-Mauxfaits',
    'Sainte-Luce-sur-Loire',
    'Coueron',
    'Savenay',
    'Craon',
    'Vritz',
    'Chanteloup-les-Bois',
    'Thouare-sur-Loire',
    'Rouillon',
    'Le Pellerin',
    'Clisson',
    'Saint-Herblain',
    'Les Sables',
    'Aizenay',
    'Ecommoy',
    'Saint-Hilaire-de-Chaleons',
    'Brain-sur-Allonnes',
    'Avrille',
    'Breze',
    'Doue-la-Fontaine',
    'Coulaines',
    'Pere',
    'Reaumur',
    'Olonne-sur-Mer',
    'Montournais',
    'Vertou',
    'Sainte-Reine-de-Bretagne',
    'Orvault',
    'Evron',
    "Parigne-l'Eveque",
    'Juigne-sur-Sarthe',
    'Chemille-Melay',
    'Renaze',
    'Aigrefeuille-sur-Maine',
    'Pornic',
    'Montbert',
    'La Garnache',
    'Champ',
    'La Ferriere',
    'Combree',
    'Maze',
    'Bouguenais',
    'Port-Brillet',
    'Saint-Christophe-en-Boucherie',
    'Yzernay',
    'La Meilleraye-de-Bretagne',
    'Fontenay-le-Comte',
    'La Plane',
    'Treillieres',
    'Saint-Saturnin-sur-Loire',
    'Le Pouliguen',
    'Saumur',
    'Le Perrier',
    'Blain',
    'Bouchemaine',
    'Loire',
    'Le Lude',
    'Mouzillon',
    'Cande',
    'La Faute-sur-Mer',
    'Nort-sur-Erdre',
    'Indre',
    'Vallet',
    'Carquefou',
    'Saint-Etienne-de-Montluc',
    'Rezé',
    'Pipriac',
    'Le Loroux',
    'Lege',
    'Quelaines-Saint-Gault',
    'Pontchateau',
    'Pouance',
    'Laval',
    'Saint-Pere-en-Retz',
    'Torce-en-Vallee',
    'Saint-Brevin-les-Pins',
    'Grand',
    'Le Pin',
    'Saint-Berthevin',
    'Suce-sur-Erdre',
    'Argentre',
    'Saint-Macaire-en-Mauges',
    'Chavagnes-les-Redoux',
    'Couesmes-Vauce',
    'Loiron',
    'Ruille-le-Gravelais',
    'La Chapelle-aux-Lys',
    'Saint-Fort',
    'Chemaze',
    'Le Coudray',
    'Palluau',
    'Ecuille',
    "Saint-Barthelemy-d'Anjou",
    'Challain-la-Potherie',
    'Bazoges-en-Paillers',
    'La Bruffiere',
    'Saligny',
    'Segre',
    "Thorigne-d'Anjou",
    'Soulvache',
    'Grazay',
    'Drain',
    'Saint-Lambert-la-Potherie',
    'Herbignac',
    'Beaufou',
    'Saint-Hilaire-de-Talmont',
    'Lamnay',
    'Change',
    'La Chartre-sur-le-Loir',
    'Mouilleron-le-Captif',
    'Chateau-du-Loir',
    'Sainte-Sabine-sur-Longeve',
    'Vincent',
    'Dreffeac',
    'Anjou',
    'Coex',
    'Beaupreau',
    'Le Voide',
    'Parigne',
    'Melleray',
    'Mamers',
    'Chateau-Gontier',
    'Saint-Corneille',
    'Grosbreuil',
    'Beaufort-en-Vallee',
    'Trementines',
    'Bouffere',
    'Touvois',
    'Abbaretz',
    'La Roche',
    'Aubigny',
    "Yvre-l'Eveque",
    'Croix-de-Vie',
    'Saint-Viaud',
    'Madre',
    'Pellouailles-les-Vignes',
    'Loue',
    'Cheffes',
    'Tierce',
    'Brulon',
    'La Suze-sur-Sarthe',
    "Joue-l'Abbe",
    'Les Lucs-sur-Boulogne',
    'Toutlemonde',
    'Saint-Andre-Treize-Voies',
    "Chateau-d'Olonne",
    'Saint-Julien-de-Vouvantes',
    'La Baconniere',
    'Lire',
    'Villaines-sous-Luce',
    'Cugand',
    'Saint-Jean-de-Monts',
    'Le Longeron',
    'Malville',
    'Nozay',
    'Chantonnay',
    'Derval',
    'Guenrouet',
    'Ancenis',
    'Sautron',
    'La Chaize-le-Vicomte',
    'La Vraie-Croix',
    'Mellac',
    'Rennes',
    'Plomelin',
    'Trigavou',
    'Lorient',
    'Saint-Gregoire',
    'Argol',
    'Saint-Brieuc',
    'Pontivy',
    'La Meaugon',
    'Merlevenez',
    'Landevant',
    'Plaine-de-Walsch',
    'Mezieres-sur-Couesnon',
    'Carnac',
    'Broons',
    'Carhaix-Plouguer',
    'Concarneau',
    'Plougastel-Daoulas',
    'Loudeac',
    'Brest',
    'Logonna-Quimerch',
    'Plouzane',
    'Credin',
    'Irodouer',
    'Nivillac',
    'La Trinité-sur-Mer',
    'Breal-sous-Montfort',
    'Cleguerec',
    'Melesse',
    'Brech',
    'Gouesnach',
    'Boqueho',
    'Penguily',
    'Le Palais',
    'Lanvaudan',
    'Montgermont',
    'Pouldreuzic',
    'Bulat-Pestivien',
    'Auray',
    'Guidel',
    'Crevin',
    'Ploubezre',
    'Orgeres',
    'Brehand',
    'Plouay',
    'Sene',
    'Plemet',
    'Evran',
    'Dinard',
    'La Meziere',
    'Vannes',
    'Langoelan',
    'Pleurtuit',
    'Quimper',
    'Pledran',
    'Chateaugiron',
    'Saint-Grave',
    'Quebriac',
    'Theix',
    'Vitré',
    'Saint-Jouan-des-Guerets',
    'Chantepie',
    'Vern-sur-Seiche',
    'Ploeuc-sur-Lie',
    'Bain-de-Bretagne',
    'Pluneret',
    'Breteil',
    'Aubigne',
    'Quedillac',
    'Hopital-Camfrout',
    'Languidic',
    'Kerfourn',
    'Reminiac',
    'Lannion',
    'Lampaul-Plouarzel',
    'Penmarch',
    'Le Bono',
    'Bangor',
    'Port-Launay',
    'Ploermel',
    'Glomel',
    'Landebia',
    'Gestel',
    'Quiberon',
    'Queven',
    'Douarnenez',
    "Saint-Aubin-d'Aubigne",
    'Chartres-de-Bretagne',
    'Le Rheu',
    'Miniac-Morvan',
    'Tréguier',
    'Bannalec',
    'Carantec',
    'Plouescat',
    'Dinan',
    'Cormainville',
    'Guemene-sur-Scorff',
    'Pluvigner',
    'Saint-Nolff',
    'Pace',
    'Guichen',
    'Beganne',
    'Molac',
    "Saint-M'Herve",
    'Saint-Dolay',
    'Dinge',
    'Camoel',
    'Chaumont',
    'Lyon',
    'Saint-Maurice-de-Remens',
    'La Chapelle-en-Vercors',
    'Bourgoin',
    'Sainte-Foy-les-Lyon',
    'Chabeuil',
    'Villeurbanne',
    'Bourg-Argental',
    'Roussillon',
    'Saint-Etienne',
    'La Cote',
    'Izernore',
    'Caluire-et-Cuire',
    'Tournon-sur-Rhône',
    'Saint-Vallier',
    'Bourg-en-Bresse',
    'Chazelles-sur-Lyon',
    'Salagnon',
    'Saint-Chamond',
    'Trevoux',
    'Chanas',
    'Dieulefit',
    'Saint-Chef',
    'Andrezieux-Boutheon',
    'Le Coteau',
    'Loire-sur-Rhone',
    'Saint-Etienne-sur-Chalaronne',
    'Vernioz',
    'Vaulx-en-Velin',
    'Chatillon-la-Palud',
    'Saint-Etienne-de-Valoux',
    'Saint-Sernin',
    'Davezieux',
    'Samognat',
    'Bron',
    'Rillieux-la-Pape',
    'Limonest',
    'Decines-Charpieu',
    'La Talaudiere',
    'Saint-Priest-la-Vetre',
    'Four',
    'Rompon',
    'Ville-sur-Jarnioux',
    'Vernosc-les-Annonay',
    'Dardilly',
    "Fleurieux-sur-l'Arbresle",
    'Saint-Didier-de-la-Tour',
    'Pont-Eveque',
    'Bourg-de-Thizy',
    'Montboucher-sur-Jabron',
    'Lhuis',
    'Brignais',
    'Montbrison',
    'Saint-Cyprien',
    'Lupe',
    'Blyes',
    'Montrond-les-Bains',
    'Meyzieu',
    'Saint-Forgeux',
    'Curtafond',
    'Vénissieux',
    'Montélimar',
    'Ornacieux',
    'La Tour',
    'Janneyrias',
    'Bonson',
    'Sorbiers',
    'Abergement-Saint-Jean',
    'Saint-Fons',
    "Saint-Maurice-l'Exil",
    'La Laupie',
    'Corbas',
    'Neuville-sur-Saone',
    "Saint-Donat-sur-l'Herbasse",
    'Fontaines-sur-Saone',
    'Pont-Salomon',
    'Frontonas',
    'Beauchastel',
    'Saint-Clair-du-Rhone',
    'Montagnieu',
    'Annonay',
    'Marcollin',
    'Viviers',
    'Savasse',
    'Saint-Symphorien-de-Lay',
    'Tassin-la-Demi-Lune',
    'Le Poet-Laval',
    "L'Horme",
    'Oullins',
    'Aubenas',
    'Genay',
    'Auriol',
    'Saint-Cannat',
    'Saint-Martin-de-la-Brasque',
    'Marseille',
    'Beaumont-de-Pertuis',
    'Loriol-du-Comtat',
    'Aix-en-Provence',
    'Eguilles',
    'Barcelonnette',
    'Manosque',
    'Ventabren',
    'La Ciotat',
    'Sorgues',
    'Carces',
    'Avignon',
    'Merindol',
    'Ceyreste',
    'Caumont-sur-Durance',
    'Peypin',
    'Vedene',
    'Arles',
    'Vaison-la-Romaine',
    'St Chamas',
    'Le Puy-Sainte-Reparade',
    'Rognac',
    'Istres',
    'La Brillanne',
    'Sausset-les-Pins',
    'Senas',
    'Valreas',
    'Volx',
    'Saint-Martin-de-Crau',
    'Monteux',
    "Plan-d'Orgon",
    'Saint-Disdier',
    'Salon-de-Provence',
    'Allos',
    'Saint-Rémy-de-Provence',
    'Suze-la-Rousse',
    'Gardanne',
    'Isle',
    'Rochefort-du-Gard',
    'Aubagne',
    'Briançon',
    'Lancon-Provence',
    'Ajaccio',
    'Cadolive',
    'Rousset',
    'Bastia',
    'Chateaurenard',
    'Les Pennes-Mirabeau',
    'Pertuis',
    'Venasque',
    'Martigues',
    'Fos-sur-Mer',
    'Gignac-la-Nerthe',
    'Miramas',
    'Orgon',
    'Velaux',
    'Pujaut',
    'Greasque',
    'Entraigues-sur-la-Sorgue',
    'Meyrargues',
    'Cabries',
    'Courthezon',
    'Les Taillades',
    'Eyragues',
    'Seyne-les-Alpes',
    'Lagnes',
    'Vitrolles',
    'Lambesc',
    'Montclar',
    'Pelissanne',
    'Eyguieres',
    'Mane',
    'Carpentras',
    'Luynes',
    'Les Angles',
    'Grans',
    'La Foux',
    'Corte',
    'Biguglia',
    'Calvi',
    'Moncale',
    'Alata',
    'Lille',
    'Somain',
    'Fresnes-sur-Escaut',
    'Bousbecque',
    'Bertincourt',
    'Hellemmes-Lille',
    'Avesnes-le-Comte',
    'Isbergues',
    'Marly',
    'Riviere',
    'Saint-Pol-sur-Mer',
    "Bruay-sur-l'Escaut",
    'Sains-en-Gohelle',
    'Grand-Fort-Philippe',
    'Ronchin',
    'Tilloy-lez-Cambrai',
    'Hesdin',
    'Jolimetz',
    'Dunkirk',
    'Hautecloque',
    'Hondeghem',
    'Bellebrune',
    'Saint-Saulve',
    'Meurchin',
    'Rieux-en-Cambresis',
    'Lattre-Saint-Quentin',
    'Lens',
    'Oignies',
    'Boisleux-au-Mont',
    'Villers-au-Bois',
    'Douchy-les-Mines',
    'Courcelles-les-Lens',
    'Wallers',
    'Aire-sur-la-Lys',
    'Outreau',
    'Raimbeaucourt',
    'Armentières',
    'Bully-les-Mines',
    'Hem',
    'Hinges',
    'Bellaing',
    'Liévin',
    'Allennes-les-Marais',
    'Lewarde',
    'Phalempin',
    'Saint-Pol-sur-Ternoise',
    'Graincourt-les-Havrincourt',
    'Lorgies',
    'Zouafques',
    'Tourcoing',
    'Blaringhem',
    'Croix',
    'Arras',
    'Lynde',
    'Ostricourt',
    'Erquinghem-Lys',
    'Coudekerque-Branche',
    'Locon',
    'Teteghem',
    'Ferriere-la-Grande',
    'Lestrem',
    'Masnieres',
    'Tournehem-sur-la-Hem',
    'Dourges',
    'Roubaix',
    'Hazebrouck',
    'Beuvry-la-Foret',
    'Saint-Hilaire-de-Court',
    'Flines-lez-Raches',
    'Abscon',
    'Valenciennes',
    'Libercourt',
    'Hardifort',
    'Hergnies',
    'Samer',
    'Wimereux',
    "Conde-sur-l'Escaut",
    'Verquin',
    'Boulogne-sur-Mer',
    'Faches-Thumesnil',
    "Hesdin-l'Abbe",
    'Maroeuil',
    'Beaurainville',
    'Merville',
    'Avion',
    'Estevelles',
    'Béthune',
    'Surques',
    'Courrières',
    'Bleriot-Plage',
    'Villers-Outreaux',
    'Anzin',
    'Wahagnies',
    'Verquigneul',
    'Houdain-lez-Bavay',
    'Saint-Amand-les-Eaux',
    'Givenchy-en-Gohelle',
    'Escaudain',
    'Nieppe',
    'Saint-Folquin',
    'Solesmes',
    'Proville',
    'Douai',
    'Rouvroy',
    'Bourlon',
    'Waziers',
    'Mazingarbe',
    'Richebourg',
    'Pont-a-Marcq',
    'Calais',
    'Noyelles-sur-Escaut',
    'La Foret',
    'Henin-Beaumont',
    'Roncq',
    'Nomain',
    'Beaumetz-les-Loges',
    'Mons-en-Baroeul',
    'Fouquieres-les-Lens',
    'Mouvaux',
    'Aulnoye-Aymeries',
    'Thumeries',
    'Blagnac',
    'Loures-Barousse',
    'Toulouse',
    'Revel',
    'Albi',
    'Deyme',
    'Castres',
    'Pibrac',
    'Cornebarrieu',
    'Cahors',
    'Castanet-Tolosan',
    'Soues',
    'Luz-Saint-Sauveur',
    'Baron',
    'Begles',
    'Talence',
    'Lauzun',
    'Hourtin',
    'Mussidan',
    'Mérignac',
    'Bordeaux',
    'Catus',
    'Frouzins',
    'Lisle-sur-Tarn',
    'Muret',
    'Saujon',
    'Favars',
    'Niort',
    'Frontenay-Rohan-Rohan',
    'Soyaux',
    'Poitiers',
    'Puilboreau',
    'Châtellerault',
    'Gueret',
    'Romans',
    'Limoges',
    'La Rochelle',
    'Tulle',
    'Bretignolles',
    'Angoulême',
    'Ars',
    'Brioux-sur-Boutonne',
    'Lavaur',
    'Roquettes',
    'Balma',
    'Cour-sur-Loire',
    'Verneuil-sur-Vienne',
    'Nieul-sur-Mer',
    'Le Vigeant',
    'Montlieu-la-Garde',
    'Aixe-sur-Vienne',
    'Mesnil-sous-Vienne',
    'Montauban',
    'Verfeil',
    'Nérac',
    'Le Bouscat',
    'Marmande',
    'Sainte-Eanne',
    'Cressat',
    'Bessines-sur-Gartempe',
    'Crayssac',
    'Aussonne',
    'Graulhet',
    'Coutras',
    "Villenave-d'Ornon",
    'Ginestet',
    'Artigues-pres-Bordeaux',
    'Miramont-Sensacq',
    'Cestas',
    'Le Taillan-Medoc',
    'Monpazier',
    'Saint-Lys',
    'Celles-sur-Belle',
    "Saint-Jean-d'Angely",
    'Meschers-sur-Gironde',
    'Chamboret',
    'Saint-Just-pres-Brioude',
    'Terce',
    'Naves',
    'Chef-Boutonne',
    'Lhommaize',
    'Rochefort',
    'Romagne',
    'Besse',
    'Taize-Aizie',
    'Julienne',
    'Arthes',
    'Noilhan',
    'Sainte-Ouenne',
    'Saint-Maxire',
    'Bonnat',
    'Aubusson',
    'La Tremblade',
    'Saint-Maurice-de-Gourdans',
    'Jardres',
    'La Meignanne',
    'Vix',
    'Château-lÉvêque',
    'La Seguiniere',
    'La Bazoge',
    'La Tranche-sur-Mer',
    'La Tessoualle',
    'Murs-Erigne',
    'Clouzeau',
    'Doix',
    'Champagne',
    'Joue-sur-Erdre',
    'Landeronde',
    'Rochetrejoux',
    'Loge-Fougereuse',
    'Saint-Lyphard',
    'Vigneux-de-Bretagne',
    'Le Bignon',
    'Belleville-sur-Vie',
    'Ernee',
    "Saint-Sylvain-d'Anjou",
    'Neuville-sur-Sarthe',
    'Savigne-sous-le-Lude',
    'Landevieille',
    'Saint-Clement-des-Levees',
    'Sable-sur-Sarthe',
    'Beauvoir-sur-Mer',
    'Heric',
    'Blaison-Gohier',
    'Monce-en-Belin',
    'La Limouziniere',
    'Bois',
    'Villeveque',
    'Mayenne',
    'Segrie',
    'La Mothe-Saint-Heray',
    'Vion',
    'Vezins',
    'Saint-Florent-le-Vieil',
    'Pissotte',
    'Mars',
    'Crespin',
    'Les Herbiers',
    'Saint-Saturnin-du-Limet',
    'Longeville-sur-Mer',
    'La Renaudiere',
    'Les Clouzeaux',
    'Dollon',
    'Maisdon-sur-Sevre',
    'Frossay',
    'Lucon',
    'Saint-Philbert-de-Grand-Lieu',
    'Sainte-Flaive-des-Loups',
    'Guerande',
    'La Bernerie-en-Retz',
    'Voutre',
    'Sigournais',
    'Marigne',
    'Noyant-la-Gravoyere',
    'Ligne',
    'Arnage',
    'Petit-Mars',
    'Prinquiau',
    'Saint-Andre-des-Eaux',
    'La Haye',
    'Donges',
    'Cerans-Foulletourte',
    'Le Fuilet',
    'Venansault',
    'Coron',
    'Le Fenouiller',
    'La Guyonniere',
    'Corne',
    'Montjean-sur-Loire',
    'Spay',
    'Noyant-la-Plaine',
    'Martigne-Briand',
    'Brigne',
    'Batz-sur-Mer',
    "Vern-d'Anjou",
    'Nalliers',
    'Saint-Marceau',
    'Saint-Philbert-du-Pont-Charrault',
    'Saint-Gildas-des-Bois',
    'Sept-Forges',
    'Saint-Hilaire-de-Riez',
    'La Planche',
    'Mezieres-sous-Lavardin',
    'Le Croisic',
    'Tillieres',
    'Montreuil',
    'Mars-sur-Allier',
    'Soulitre',
    'Le Plessis-Grammoire',
    'Dompierre-sur-Yon',
    'Treize-Septiers',
    'La Haie',
    'Sainte-Gemme-la-Plaine',
    'Fontaine',
    'Meslay-du-Maine',
    'Froidfond',
    'La Grigonnais',
    'Ruille-Froid-Fonds',
    'Ponts',
    'Louresse-Rochemenier',
    'Martinet',
    'Pouzauges',
    'Saint-Lumine-de-Clisson',
    'Chateauneuf',
    'Les Alleuds',
    'Bouvron',
    'Sainte-Florence',
    'Pierre',
    'Landemont',
    'La Roche-sur-Yon',
    'Disse-sous-Ballon',
    'Tregunc',
    'Ploemeur',
    'Plougoumelen',
    'Le Sourn',
    'Erce-en-Lamee',
    'Saint-Ave',
    'Silfiac',
    'Romagne',
    'Morlaix',
    'Bretagne',
    'Saint-Malo-en-Donziois',
    'Saint-Bihy',
    'Chateauneuf-du-Faou',
    'Hennebont',
    'La Forest-Landerneau',
    'La Fresnais',
    'Plaintel',
    'Le Ferre',
    'Acigne',
    'Landerneau',
    'Saint-Urbain',
    'Noyal-Muzillac',
    'Briec',
    'Plerin',
    'Hanvec',
    'Saint-Yvi',
    'Scaer',
    'Melgven',
    'Rosporden',
    'Baud',
    'Lannilis',
    'Dol-de-Bretagne',
    'Plouneour-Trez',
    'Plougasnou',
    'Penestin',
    'Poullaouen',
    'Riec-sur-Belon',
    'Elven',
    'Quelneuc',
    'Cournon',
    'Saint-Jean-sur-Vilaine',
    'Le Guerno',
    'Guipavas',
    'Saint-Tugdual',
    'Baden',
    'Caulnes',
    'Cleder',
    'Villamee',
    'Iffendic',
    'Redene',
    'Plouguin',
    'Surzur',
    'Plumeliau',
    'Saint-Cast-le-Guildo',
    'Plougonvelin',
    'Ergue-Gaberic',
    'Pleugriffet',
    'Combourg',
    'Elliant',
    'Fougeres',
    'Lanmerin',
    'Lamballe',
    'Camors',
    'Lanester',
    'Nostang',
    'Ferel',
    'Plaine-Haute',
    'Ploemel',
    'Goudelin',
    'Yffiniac',
    'Beauce',
    'Kerpert',
    'Saint-Carreuc',
    'Plouha',
    'Clohars-Carnoet',
    'Treguidel',
    'Quéménéven',
    'Portrieux',
    'Guer',
    'Henon',
    'Saint-Quay-Portrieux',
    'Taule',
    'Kervignac',
    'Tremuson',
    'Pluguffan',
    'Le Trevoux',
    'Pleyben',
    'Mur-de-Bretagne',
    'Montauban-de-Bretagne',
    'Ploufragan',
    'Treverien',
    'Roscoff',
    'Redon',
    'Pont-Scorff',
    'Le Relecq-Kerhuon',
    'Langonnet',
    "Pont-l'Abbé",
    'Ploudalmezeau',
    'Geveze',
    'Saint-Pabu',
    'Saint-Domineuc',
    'Trebeurden',
    'Missiriac',
    'Medreac',
    'Argentre-du-Plessis',
    'Treduder',
    'Combrit',
    'Gomene',
    'Plouaret',
    'Lanvollon',
    'Trebry',
    'Sulniac',
    'Bruz',
    'Lanouee',
    'Ploeren',
    'Saint-Jean-la-Poterie',
    'Betton',
    'Guilvinec',
    'Domagne',
    'Peillac',
    'Ploubalay',
    'Plescop',
    'Saint-Adrien',
    'Vezin-le-Coquet',
    'Domalain',
    'Fouesnant',
    'Gouezec',
    'Crehen',
    'Mael-Carhaix',
    'Pluduno',
    'Locmaria-Plouzane',
    'Saint-Agathon',
    'Pont-de-Buis-les-Quimerch',
    'Plaudren',
    'Saint-Senoux',
    'Erdeven',
    'Saint-Renan',
    'Moncontour',
    'Saint-Pierre-la-Cour',
    'Liffre',
    'Reguiny',
    'Brandivy',
    'Plumaugat',
    'Ploerdut',
    'Corseul',
    'Laille',
    'Locmariaquer',
    'Treffendel',
    'Plelan-le-Grand',
    'Janze',
    'Guignen',
    'Plabennec',
    'Tinteniac',
    'Le Faou',
    'Lokmaria',
    'Begard',
    'Ploudaniel',
    'Plestin-les-Greves',
    'Saint-Derrien',
    'Maure-de-Bretagne',
    'Plumelin',
    'Plelan-le-Petit',
    'Le Verger',
    'Andouille-Neuville',
    'Lennon',
    'Spezet',
    'Minihy-Treguier',
    'Pleucadeuc',
    'Henvic',
    'Taillis',
    'Lignol',
    'Saint-Divy',
    'Guenin',
    'Pont-Sainte-Maxence',
    'Bonnemain',
    'Chateaubourg',
    'Saint-Meen-le-Grand',
    'Saint-Barnabe',
    'Lehon',
    'Botsorhel',
    'Chatelaudren',
    'Pleneuf-Val-Andre',
    'Tregastel',
    'Carentoir',
    'Loctudy',
    'Gahard',
    'Meslin',
    'Vieux',
    'Plouer-sur-Rance',
    'Hede-Bazouges',
    'Kerlaz',
    'Plougourvest',
    'Crach',
    'Tregarantec',
    'Plechatel',
    'Saint-Philibert',
    'Moelan-sur-Mer',
    'Montfort-sur-Meu',
    'Caudan',
    'Squiffiec',
    'Plouneour-Menez',
    'Plougar',
    'Gouesnou',
    'Paris',
    'Pabu',
    'Le Faouet',
    'Plogonnec',
    'Messac',
    'Pouldergat',
    'Mohon',
    'Penvenan',
    'Quimperlé',
    'Larmor-Plage',
    'Cleguer',
    'Pommeret',
    'Crozon',
    'Etel',
    'Pleumeleuc',
    'Belle-Isle-en-Terre',
    'Audierne',
    'Peaule',
    'Bains-sur-Oust',
    'Plurien',
    'Plonevez-du-Faou',
    'Brusvily',
    "L'Hermitage",
    'Louargat',
    'Illifaut',
    'Saint-Gerand',
    'Châteaulin',
    'Saint-Coulomb',
    'Yvias',
    'Bénodet',
    'Saint-Jean-Trolimon',
    'Louannec',
    'Noyal-Pontivy',
    'Larmor-Baden',
    'Beuzec-Cap-Sizun',
    "Ile-d'Arz",
    'Chevaigne',
    'Planguenoual',
    'Plelauff',
    'Saint-Nicolas-du-Pelem',
    'Questembert',
    'Plomeur',
    'Le Foeil',
    'Pont-Aven',
    'Saint-Pol-de-Léon',
    'La Richardais',
    'Le Vieux Bourg',
    'Balaze',
    'Plorec-sur-Arguenon',
    'Plessala',
    'Paimpont',
    'Croixanvec',
    'Billiers',
    'Sauzon',
    'Plouharnel',
    'Landrevarzec',
    'Pludual',
    'Arradon',
    'Lezardrieux',
    'Le Vieux',
    'Bedee',
    'Gourin',
    'Mouaze',
    'Guengat',
    'Brive',
    'Plonéour-Lanvern',
    'Persquen',
    'Baguer-Morvan',
    'Coesmes',
    'Saint-Gonnery',
    'St-Malo',
    'Monterfil',
    'Saint-Meloir-des-Ondes',
    'Le Vivier-sur-Mer',
    'Trelevern',
    'Berric',
    'Plerguer',
    'Grand-Fougeray',
    'La Motte',
    'Saint-Suliac',
    'Poullan-sur-Mer',
    'Pontorson',
    'Ploumagoar',
    'Maxent',
    'Allaire',
    'Luitre',
    'Esquibien',
    'Cogles',
    'Plouec-du-Trieux',
    'Treve',
    'Treflez',
    'Bourbriac',
    'Quevert',
    'Saint-Nicolas-de-Redon',
    'Plusquellec',
    'Leon',
    'Servon-sur-Vilaine',
    'Lanrelas',
    'Colpo',
    'Muzillac',
    'Saint-Armel',
    'Saint-Congard',
    'La Bosse-de-Bretagne',
    'Plouagat',
    'Belz',
    'Plouezec',
    'Plouigneau',
    'Trefflean',
    'Plougonven',
    'Locmine',
    'Sainte-Seve',
    'Plouvorn',
    'Laignelet',
    'Boulogne-Billancourt',
    'Villebon-sur-Yvette',
    'Neuilly-sur-Seine',
    'Les Sorinieres',
    'Argences',
    'Carros',
    'Marcilly-sur-Tille',
    'Chatillon-sur-Chalaronne',
    'Toulon',
    'Bollene',
    'Draguignan',
    'Bagnères-de-Bigorre',
    'Yzeure',
    'Bourg-Saint-Andeol',
    'Petit-Palais-et-Cornemps',
    'Chantilly',
    'Mont-Saint-Martin',
    'Chaulnes',
    'Pont-Saint-Esprit',
    'Saint-Crepin-Ibouvillers',
    'Gambais',
    'Urrugne',
    'Domerat',
    'Peymeinade',
    'Lattes',
    'Sezanne',
    'Generac',
    'Buellas',
    'Cergy',
    'Bayonne',
    'Epernon',
    'Villefontaine',
    'Marolles-en-Hurepoix',
    'Clermont-Ferrand',
    "Cabrieres-d'Avignon",
    'Chevincourt',
    'Villeneuve-sur-Lot',
    'Triel-sur-Seine',
    'Chelles',
    'Savigny-le-Temple',
    'Champs-sur-Marne',
    'Vincennes',
    'Pontaut',
    "Chazay-d'Azergues",
    'Chenimenil',
    'Épinal',
    'Poissy',
    'Saint-Denis-de-Palin',
    'Guyancourt',
    'Andresy',
    'La Courneuve',
    'Grigny',
    'Serris',
    'Grenoble',
    'Marcq-en-Baroeul',
    'Montpellier',
    'Sivry-Courtry',
    'Issy-les-Moulineaux',
    'Noisiel',
    'Orléans',
    'Chaponost',
    'Valence',
    'Buc',
    'Courbevoie',
    'Haubourdin',
    'Meudon',
    'Marly-le-Roi',
    'Fontenay-aux-Roses',
    'Rueil-Malmaison',
    'Montreuil',
    'Scy-Chazelles',
    'Besançon',
    'Bourg-la-Reine',
    'Nice',
    'Échirolles',
    'Maisons-Alfort',
    'Le Havre',
    'Dugny',
    'Suresnes',
    'Le Pecq',
    'Évry',
    'Tours',
    'Athis-Mons',
    'Stains',
    'Le Bourget',
    'Cannes',
    'Juan-les-Pins',
    'Sevran',
    'Saint-Ouen',
    'Vitry-sur-Seine',
    'Les Clayes-sous-Bois',
    'Fontenay-sous-Bois',
    'Épinay-sur-Seine',
    'Versailles',
    'Saint-Maur-des-Fossés',
    'Créteil',
    'Loos',
    'Levallois-Perret',
    'Heillecourt',
    'Pessac',
    'Nancy',
    'Rouen',
    'Perpignan',
    'Montfavet',
    'Dijon',
    'Saint-Germain-en-Laye',
    'Gif-sur-Yvette',
    'Mantes-la-Jolie',
    'Villefranche-sur-Saône',
    'Rosny-sous-Bois',
    'Strasbourg',
    'Cerisy-la-Foret',
    'Billy-Montigny',
    'Le Mee-sur-Seine',
    'Belfort',
    'Colombes',
    'Metz',
    'Lannoy',
    'Dogneville',
    'Montigny-le-Bretonneux',
    'Châtenay-Malabry',
    'Choisy-le-Roi',
    'Chatou',
    'Barlin',
    'Wattrelos',
    'Claye-Souilly',
    'Gagny',
    'Rixheim',
    'Louviers',
    'Amiens',
    'Antibes',
    'Fréjus',
    'Bourg-de-Péage',
    'Troyes',
    'Chambray-lès-Tours',
    'Tinqueux',
    'Sarreguemines',
    'Cavaillon',
    'Saint-Étienne-du-Rouvray',
    'Domont',
    'Neuilly-sur-Marne',
    'Fresnes',
    'Colomiers',
    'Saint-Raphaël',
    'Sartrouville',
    'Surtainville',
    'Bischheim',
    'Hoenheim',
    'Annezin',
    'Vandoeuvre-les-Nancy',
    'Garges-lès-Gonesse',
    'Saint-Jean-de-la-Ruelle',
    'Saint-Omer',
    'Chambéry',
    'Carcassonne',
    'Épernay',
    'Cormeilles-en-Parisis',
    'Seichamps',
    'Le Perreux-sur-Marne',
    "La Cadiere-d'Azur",
    'Combs-la-Ville',
    'Mâcon',
    'Grigny',
    'Villeneuve-sur-Fere',
    'Chevilly-Larue',
    'Vienne',
    'Morsang-sur-Orge',
    'Montmagny',
    'Sens',
    'Compiègne',
    'Oberschaeffolsheim',
    'Maisons-Laffitte',
    'La Riche',
    'Saint-Sébastien-sur-Loire',
    'Fontaine',
    'Chilly-Mazarin',
    'Gaillard',
    "L'Etrat",
    'Narbonne',
    'Conflans-Sainte-Honorine',
    'Nîmes',
    'Auxerre',
    'Caen',
    'Eysines',
    'Eybens',
    'Le Blanc',
    'Allauch',
    'Aix-les-Bains',
    'Villeparisis',
    'Dammarie-les-Lys',
    'Argenteuil',
    'Bezons',
    'Clamart',
    'Kingersheim',
    'Le Portel',
    'Limay',
    'Le Raincy',
    'Franconville',
    'Chalons-en-Champagne',
    'Petit-Couronne',
    'Cagnes-sur-Mer',
    'Saint-Andre-les-Vergers',
    'Périgueux',
    'Nevers',
    'Palaiseau',
    'Le Cannet',
    'Bourges',
    'Saint-Laurent-du-Var',
    'Lourches',
    'Rosay',
    'Évreux',
    'Saint-Germain-les-Arpajon',
    'Egly',
    'Les Mureaux',
    'Terville',
    'Fameck',
    'Neuilly-Plaisance',
    'Saint-Germain-les-Corbeil',
    'Herblay',
    'Brunoy',
    'Blanquefort',
    'Villiers-sur-Marne',
    'Bondy',
    'Noisy-le-Sec',
    'Nanterre',
    'Montevrain',
    'Roquebrune-Cap-Martin',
    'Vaujours',
    'Deuil-la-Barre',
    'La Celle-Saint-Cloud',
    'Villeneuve-Saint-Georges',
    'Saint-Médard-en-Jalles',
    'Creil',
    'Pantin',
    'Lesigny',
    'Melun',
    'Vaires-sur-Marne',
    'Ennery',
    'Maizieres-les-Metz',
    'Soisy-sur-Seine',
    'Le Neubourg',
    'Dame-Marie',
    'Villettes',
    'Le Port-Marly',
    'Cesar',
    'Gravelines',
    'Saint-Aubin-les-Elbeuf',
    'Montataire',
    'Villers-sur-le-Mont',
    'Samazan',
    'Bourran',
    'Foulayronnes',
    'Tombeboeuf',
    'Tayrac',
    'Sainte-Colombe-de-Duras',
    'Penne-dAgenais',
    'Sainte-Gemme-Martaillac',
    'Anthe',
    'Devillac',
    'Ecluzelles',
    'Bonneval',
    'Lutz-en-Dunois',
    'Bailleul',
    'Chorges',
    'Beausoleil',
    'Menton',
    'Drap',
    'Grasse',
    'Rosieres-pres-Troyes',
    'Sainte-Savine',
    "Berre-l'Etang",
    'Bouc-Bel-Air',
    'Septemes-les-Vallons',
    'Ifs',
    'Saint-Germain-la-Blanche-Herbe',
    'Brive-la-Gaillarde',
    'Chevigny-Saint-Sauveur',
    'Mensignac',
    'Etupes',
    'Vieux-Charmont',
    'Romans-sur-Isère',
    'Chartres',
    'Ramonville-Saint-Agne',
    'Cenon',
    'Bruges',
    'Libourne',
    'Bassens',
    'Lormont',
    'Boujan-sur-Libron',
    'Béziers',
    'Frontignan',
    'Sète',
    'Mauguio',
    'Joué-lès-Tours',
    'Blois',
    'Firminy',
    'Rive-de-Gier',
    'Roanne',
    'La Ricamarie',
    'Saint-Genest-Lerpt',
    'Saint-Priest-en-Jarez',
    'Chalette-sur-Loing',
    'Fleury-les-Aubrais',
    'Ingre',
    'Agen',
    'Saint-Memmie',
    'Pierry',
    'Reims',
    'Laxou',
    'Toul',
    'Florange',
    'Forbach',
    'Hagondange',
    'Amneville',
    'Hayange',
    'Hombourg-Haut',
    'Hettange-Grande',
    'Thionville',
    'Cambrai',
    'Hornaing',
    'Senlis',
    'Achicourt',
    'Wingles',
    'Saint-Martin-au-Laert',
    'Chateaugay',
    'Pau',
    'Bizanos',
    'Illkirch-Graffenstaden',
    'Schiltigheim',
    'Colmar',
    'Mulhouse',
    'Illzach',
    'Riedisheim',
    'Hegenheim',
    'Ecully',
    'Saint-Genis-Laval',
    'Genas',
    'Chalon-sur-Saône',
    'Varennes-les-Macon',
    'Le Creusot',
    'Crottet',
    'Montceau-les-Mines',
    'Seez',
    'Annecy',
    'Annemasse',
    'Cluses',
    'Saint-Leu-la-Foret',
    'Le Grand-Pressigny',
    'Deville-les-Rouen',
    'Mont-Saint-Aignan',
    'Sotteville-lès-Rouen',
    'Brie-Comte-Robert',
    'Lieusaint',
    'Meaux',
    'Villeblevin',
    'Rubelles',
    'Montereau-Fault-Yonne',
    'Gretz-Armainvilliers',
    "Bois-d'Arcy",
    'Carrieres-sous-Poissy',
    'Villejuif',
    'Plaisir',
    'Acheres',
    'Maurepas',
    'Verneuil-sur-Seine',
    'Vernouillet',
    'Villepreux',
    'La Garde',
    'Hyères',
    'La Seyne-sur-Mer',
    'Audincourt',
    'Arpajon',
    'Ris-Orangis',
    'Corbeil-Essonnes',
    'Étampes',
    'Asnieres-sur-Seine',
    'Massy',
    'Verrieres-le-Buisson',
    'La Ville-aux-Bois',
    'Sainte-Geneviève-des-Bois',
    'Draveil',
    'Viry-Châtillon',
    'Antony',
    'Clichy',
    'Châtillon',
    'Montrouge',
    'Garches',
    'Aulnay-sous-Bois',
    'Bagnolet',
    'Drancy',
    'Les Pavillons-sous-Bois',
    'Livry-Gargan',
    'Noisy-le-Grand',
    'Les Lilas',
    'Saint-Denis',
    "L'Ile-Saint-Denis",
    'Tremblay',
    'Alfortville',
    'Villecresnes',
    'Champigny-sur-Marne',
    'Le Plessis-Belleville',
    "L'Haÿ-les-Roses",
    'Ivry-sur-Seine',
    'Persan',
    'Ezanville',
    'Gonesse',
    'Montmorency',
    'Caudry',
    'Villetaneuse',
    'Cachan',
    'Mericourt',
    'Tournefeuille',
    'Lescar',
    'Coubron',
    'Billere',
    'Denain',
    'Saint-Gratien',
    'La Barre-en-Ouche',
    'Silly-la-Poterie',
    'Villemomble',
    'Gennevilliers',
    'Vaux-le-Penil',
    'Saint-Laurent-Blangy',
    'Saint-Julien-les-Metz',
    'Fleury-Merogis',
    'Chennevieres-sur-Marne',
    'La Queue-en-Brie',
    'Bourron-Marlotte',
    'Malakoff',
    'Vigneux-sur-Seine',
    'Thiais',
    'Trappes',
    'Linas',
    'Saulx-les-Chartreux',
    'Paron',
    'Fagnieres',
    'Aubervilliers',
    'Montargis',
    'Savigny-sur-Orge',
    'Bagneux',
    'Mougins',
    'Brétigny-sur-Orge',
    'Puteaux',
    'Saint-Brice-sous-Foret',
    'Irigny',
    'Nandy',
    'Nogent-sur-Marne',
    'Saint-Remy-la-Vanne',
    'Chambly',
    'Anglet',
    'Hermes',
    'Évian-les-Bains',
    'Allevard',
    'Saint-Pierre-de-Jards',
    'Dieppe',
    'Brognon',
    'Cordemais',
    'Villeneuve',
    'Coupvray',
    'Roussy-le-Village',
    'Peillon',
    'La Queue-les-Yvelines',
    'Saint-Andre',
    'Monthyon',
    'Riom',
    'Montoire-sur-le-Loir',
    'Mordelles',
    'Hendaye',
    'Speracedes',
    'Carnoules',
    'Elbeuf',
    'Miribel',
    'Vouille',
    'Jouarre',
    'Jacob-Bellecombette',
    'LArbresle',
    'Solaize',
    'Montliard',
    'Neuilly-en-Thelle',
    'Falaise',
    "Saint-Jean-d'Ardieres",
    'Chamblanc',
    'Ballancourt-sur-Essonne',
    'Cregy-les-Meaux',
    'Pernes-les-Fontaines',
    'Semalens',
    'Emerainville',
    'Milly-la-Foret',
    'Châteauroux',
    'Cesson',
    'Yerville',
    'Le Landreau',
    'Gron',
    'Houdan',
    'Eaux-Bonnes',
    'Saint-Loup-Cammas',
    'Lempdes-sur-Allagnon',
    'Maubeuge',
    'La Farlede',
    'Briouze',
    'Alès',
    'Daoulas',
    'Sciez',
    'Guignes',
    'Langlade',
    'Andernos-les-Bains',
    'Ferolles-Attilly',
    'Gien',
    'Frouard',
    'Cugnaux',
    'Cabris',
    'Saint-Jean-de-Boiseau',
    'Onet-le-Chateau',
    'Fécamp',
    'Grandfresnoy',
    'Montferrand-du-Perigord',
    'Brignoles',
    'Marck',
    'Pithiviers',
    'Jarnac-Champagne',
    'Guethary',
    'Le Rouret',
    'Les Essarts',
    'Angliers',
    'Aytre',
    'Floirac',
    'Gradignan',
    'Cherbourg-Octeville',
    'Pontoise',
    'Eaubonne',
    'Voiron',
    'Hallennes-lez-Haubourdin',
    'Flers-en-Escrebieux',
    'Auby',
    'Blendecques',
    'Francheville',
    'Igny',
    'La Grande-Motte',
    'Essey-les-Nancy',
    'Saint-Nicolas-de-Port',
    'Saint-Gervais-sous-Meymont',
    'Canteleu',
    'Yerres',
    'Joinville-le-Pont',
    'Ablon-sur-Seine',
    'Saint-Cyr-sur-Loire',
    'Vélizy-Villacoublay',
    'Viroflay',
    'Saint-Lô',
    'Bois-Colombes',
    'Le Port',
    'Saint-Cloud',
    'Fontainebleau',
    'Nemours',
    'Houilles',
    'Le Vesinet',
    'Chenove',
    'Beaune',
    'Sassenage',
    'Meylan',
    'Leers',
    'Seclin',
    'Carvin',
    'Le Chesnay',
    'Taverny',
    'Montgeron',
    'Pont-à-Mousson',
    'Creutzwald',
    'Merlebach',
    'Cocheren',
    'Illange',
    'Roissy-en-Brie',
    'Alençon',
    'Guebwiller',
    'Haguenau',
    'Lingolsheim',
    'Molsheim',
    'Saverne',
    'Noiseau',
    'Mandres-les-Roses',
    'Beauvais',
    'Biarritz',
    'Jurancon',
    'Saint-Jean-de-Luz',
    'La Valette-du-Var',
    'Saint-Pierre-des-Corps',
    'Montlouis-sur-Loire',
    'Chevreuse',
    'Bailly',
    'Goussainville',
    'Wasquehal',
    'Aubiere',
    'Castelnau-le-Lez',
    'Arcueil',
    'Moulins-les-Metz',
    'Maromme',
    'Romainville',
    'Cran-Gevrier',
    'Boissy-Saint-Léger',
    'Beynes',
    'Anthon',
    'Uzès',
    'Mions',
    'Pierrefitte-sur-Seine',
    'Longjumeau',
    'La Riviere-de-Corps',
    'Saint-Julien-les-Villas',
    'Saint-Doulchard',
    'Groslay',
    'Sainte-Adresse',
    'Pierre-Benite',
    'Mantes-la-Ville',
    'Torcy',
    'Brou-sur-Chantereine',
    'Beaumont',
    'Arlanc',
    'Ormesson-sur-Marne',
    'Orly',
    'Les Loges',
    'Verniolle',
    'Fuveau',
    'Basse-Goulaine',
    'Etroussat',
    'Condom',
    "L'Isle-Jourdain",
    'Pessan',
    'Élancourt',
    'Brochon',
    'Bourg-le-Comte',
    'Gaillac',
    'Le Fraysse',
    'Saussenac',
    'Saint-Ay',
    'Bussy-Saint-Georges',
    "Pont-l'Eveque",
    'Fabregues',
    'Paul',
    'Lunel',
    'Pons',
    'Olargues',
    'Pignan',
    'Ganges',
    'Cessenon-sur-Orb',
    'Poussan',
    'Loupian',
    'Jaure',
    'Mosson',
    'Krafft',
    'Beauvoir',
    'Les Pins',
    'Mitterand',
    'Lansargues',
    'Montagnac',
    'Bene',
    'Roujan',
    'Martin-Eglise',
    'Doux',
    'Marc',
    'Pierre-de-Bresse',
    'Vichy',
    'Mereville',
    'Cahuzac',
    'Cournon-dAuvergne',
    'Senan',
    'Bonneuil-sur-Marne',
    'Arudy',
    'Perols',
    "La Roque-d'Antheron",
    'Coudoux',
    'Saint-Esteve-Janson',
    'Simiane-Collongue',
    'Ozoir-la-Ferriere',
    'Saint-Maurice',
    'Aulnay-sur-Mauldre',
    'Sucy-en-Brie',
    'Le Pre-Saint-Gervais',
    'Saint-Michel-sur-Orge',
    'Maincy',
    'Aucamville',
    'Mitry-Mory',
    'Nanteuil-le-Haudouin',
    'Avrainville',
    'Ollainville',
    'Sarcelles',
    'Vert-le-Grand',
    'Louveciennes',
    'Rumilly',
    'Montfermeil',
    'La Ferte-sous-Jouarre',
    'Bois-le-Roi',
    'Champcerie',
    'Montivilliers',
    'Villeneuve-le-Roi',
    'Villeneuve-la-Garenne',
    'Montigny-sur-Loing',
    'Villepinte',
    'Charenton-le-Pont',
    'Villiers-le-Bel',
    'Chaville',
    'Montesson',
    'Navarrenx',
    'Ogenne-Camptort',
    'Gaillac-Toulza',
    'Gueugnon',
    'Épinay-sur-Orge',
    'Rebais',
    'Saint-Mars-de-Locquenay',
    'Vernou-sur-Brenne',
    'Chateauneuf-de-Bordette',
    'Apt',
    'Saint-Peray',
    'Vibraye',
    'Vias',
    'Montmoreau-Saint-Cybard',
    'Vitrac-Saint-Vincent',
    'Brigueuil',
    'Chasseneuil-sur-Bonnieure',
    'Estrablin',
    'Chatte',
    'Saint-Baudille-de-la-Tour',
    'Moissieu-sur-Dolon',
    'Saint-Front',
    'Monsteroux-Milieu',
    'Fouquebrune',
    'Les Gours',
    'Realville',
    'Auzielle',
    'Plaisance-du-Touch',
    'Montreuil-aux-Lions',
    'Trilport',
    'Mezieres-sur-Seine',
    'Reilly',
    'Lentilly',
    'Cappelle-la-Grande',
    'Fort-Mardyck',
    'Boncourt-sur-Meuse',
    'Bage-la-Ville',
    'Certines',
    'Thil',
    'Chevry',
    'Pougny',
    'Belley',
    'Rance',
    'Dangeul',
    'Saint-Fregant',
    'Gex',
    'Vire',
    "Saint-Didier-d'Aussiat",
    'Ars-sur-Formans',
    'Saint-Germain-de-Joux',
    'Bray-sur-Seine',
    'Egligny',
    'La Brosse',
    'Courtomer',
    'Nangis',
    'Meigneux',
    'Beligneux',
    'Pizay',
    'Lavours',
    'Etrez',
    'Ozan',
    'Echenevex',
    'Rivieres',
    'Valbonne',
    'Ascq',
    'Saint-Etienne-de-Saint-Geoirs',
    'Labege',
    'Sèvres',
    'Mont-Saint-Martin',
    'Chateauneuf-de-Gadagne',
    'Bohal',
    'Sens-de-Bretagne',
    'La Crau',
    'Vanves',
    'La Plaine-Saint-Denis',
    'Crolles',
    'Bailly-Romainvilliers',
    'Sainte-Maxime',
    'Rully',
    'La Celle-sous-Montmirail',
    'Le Mesnil-Fuguet',
    'Vieux-Manoir',
    'Servian',
    "L'Isle-Adam",
    'Bobigny',
    'Santes',
    'Bazoncourt',
    'Chavanod',
    'Montluel',
    'Leaz',
    'Marange-Silvange',
    'Nilvange',
    'Gandrange',
    'Saint-Germain-du-Puy',
    'Croissy-sur-Seine',
    'Moulin',
    'Les Hotelleries',
    'Marie',
    'Chilly',
    'Courcouronnes',
    'Crillon-le-Brave',
    'Vesly',
    'Chereng',
    'Berry-Bouy',
    'Puy-Guillaume',
    'Ermont',
    'Le Plessis-Bouchard',
    'Saint-Max',
    'Plan-de-Cuques',
    'Malzeville',
    'Bihorel',
    'Romagnat',
    'La Norville',
    'Seyssinet-Pariset',
    'Carqueiranne',
    'Laye',
    'Bry-sur-Marne',
    'Jarville-la-Malgrange',
    'Pont-a-Vendin',
    'Ares',
    'Peyrehorade',
    'Meythet',
    'Joeuf',
    'Breuillet',
    'Saint-Martin-de-Bernegoue',
    'Lillebonne',
    'Le Muy',
    'Carbonne',
    'Bonifacio',
    'Veyre-Monton',
    'Escalquens',
    'Aubigny-sur-Nère',
    'Gas',
    'Monchy-Saint-Eloi',
    'Fitz-James',
    'Roquefort',
    'Royat',
    "Raon-l'Etape",
    'Mirecourt',
    'Borgo',
    'Incheville',
    'Cusset',
    'Savieres',
    'Remy',
    'Perrecy-les-Forges',
    'Andeville',
    'Franqueville',
    'Souppes-sur-Loing',
    'Villeneuve-Loubet',
    'Saint-Germer-de-Fly',
    'Villy-Bocage',
    'Aix-Noulette',
    'Petite-Foret',
    'Saussines',
    'Bages',
    'Villers-Cotterêts',
    'Bettignies',
    'Soignolles-en-Brie',
    'Hirson',
    'Rochetoirin',
    'Dourdan',
    'Bletterans',
    'Teloche',
    'Senonches',
    'Jouhet',
    'Saint-Etienne-de-Crossey',
    'Charancieu',
    'Morestel',
    'Tullins',
    'Charavines',
    'Les Abrets',
    'Chirens',
    'Saint-Geoire-en-Valdaine',
    'Oyeu',
    'Saint-Jean-de-Moirans',
    'Villabe',
    'Querrieu',
    'Feucherolles',
    'Rocquencourt',
    'Crouy-sur-Ourcq',
    'Voisins-le-Bretonneux',
    'Saint-Christophe-sur-Avre',
    'Poilly-lez-Gien',
    'Ambérieu-en-Bugey',
    'Eschau',
    'Fegersheim',
    'Pfastatt',
    'Wittenheim',
    'Erstein',
    'Rhinau',
    'Gerstheim',
    'Lignerolles',
    'Mirepoix',
    'Saint-Symphorien-sur-Saone',
    'Le Puy-en-Velay',
    'Les Fontaines',
    'Noeux-les-Auxi',
    'Confolens',
    'Ruffec',
    'Woerth',
    'Wissembourg',
    'La Chapelle-de-Guinchay',
    'Ruelle-sur-Touvre',
    'Diemeringen',
    'Soufflenheim',
    'Dettwiller',
    "Chemin-d'Aisey",
    'Cognac',
    'Saint-Hilaire-Cusson-la-Valmitte',
    'Chateauneuf-les-Martigues',
    'La Rochefoucauld',
    'Le Gond',
    'Ingwiller',
    'Fruges',
    'Beaulieu-sur-Dordogne',
    'Objat',
    "Bourbon-l'Archambault",
    'Marcillac',
    'Sarre',
    'Nexon',
    'Allassac',
    'La Couronne',
    'Argentiere',
    'Reichshoffen',
    'Le Chambon',
    'Drulingen',
    'La Gaillarde',
    'Segonzac',
    'Cize',
    'Chalon',
    'Munster',
    'Chalais',
    'Lourdes',
    'Mazan',
    'Romaine',
    'Eccles',
    'Thiers',
    'Issoire',
    'Corbigny',
    'Blesle',
    'Figeac',
    'Clermont',
    'Fortschwihr',
    'Boussac',
    'Jeumont',
    'Montbard',
    'Larche',
    'Ottmarsheim',
    'Paulhaguet',
    'Eymoutiers',
    'Correze',
    'Moulins',
    'Montemboeuf',
    "Bort-l'Etang",
    'Rochechouart',
    'Chabanais',
    'Saugues',
    'Langeac',
    'Sauze',
    'Argentat',
    'Le Dorat',
    'Le Montel',
    'Maringues',
    'Castelnau-Durban',
    'Semeac',
    'Chauny',
    'Tarentaise',
    'Tarbes',
    'Ceyrat',
    'Pouilly',
    'Saint-Sulpice-le-Vieux',
    'Brazey-en-Plaine',
    'Felletin',
    'Aurec-sur-Loire',
    "Monistrol-d'Allier",
    'Arnay-le-Duc',
    'Treignac',
    'Nolay',
    "Cosne-d'Allier",
    'Besse',
    'Le Comte',
    'Saint-Germain-Nuelles',
    'Puylaurens',
    'Jury',
    'Salviac',
    'Caussade-Riviere',
    'Marciac',
    'Uzerche',
    'Bessines',
    'Billom',
    'Le Monastier-sur-Gazeille',
    'Le Crotoy',
    'Sélestat',
    'Digne-les-Bains',
    'Gignac',
    'Marignane',
    'Baignes-Sainte-Radegonde',
    'Pontailler-sur-Saone',
    'La Chaise',
    'Villedieu',
    'Moltifao',
    'Hochfelden',
    'La Walck',
    'Chamalières',
    'Tence',
    'Sains-du-Nord',
    'Genlis',
    'Chateau-Chervix',
    'Les Martres',
    'Chatelus',
    'Premery',
    'Luzy',
    'Cerizay',
    'Pamiers',
    'Orbey',
    'Clamecy',
    'Ambert',
    'Volvic',
    'Brives-sur-Charente',
    'Ahun',
    'Allegre',
    'Saint-Martin-sur-Arve',
    'Saint-Julien-sur-Bibost',
    'Retournac',
    'Illfurth',
    'Courpiere',
    'Aigre',
    'Craponne',
    'Chatel-Guyon',
    'Estaires',
    'Le Fousseret',
    'Warendin',
    'Oissel',
    'Harnes',
    'Laon',
    'Saint-Benin-dAzy',
    'Gerzat',
    'La Charité-sur-Loire',
    "Benevent-l'Abbaye",
    'Marquion',
    'Tournay-sur-Odon',
    'Saint-Laurent-Rochefort',
    'Conde-Sainte-Libiaire',
    'Brezolles',
    'Apcher',
    'Bellac',
    'Mielan',
    'La Montagne',
    'Meriel',
    'Arzon',
    'Montarnaud',
    'Cheron',
    'Le Cres',
    'Sainte-Marie-de-Cuines',
    'Landos',
    'Oradour',
    'Yssingeaux',
    'Houplines',
    'Semur-en-Brionnais',
    'Neuvic',
    'Grenade',
    'Nantiat',
    'Auzances',
    'Lezoux',
    'Carcopino',
    'Audes',
    'Arreau',
    'Gondecourt',
    'Guerigny',
    'Saint-Didier-sur-Rochefort',
    'Lacaune',
    'Chateaumeillant',
    'Le Château-dOléron',
    'Dreville',
    'Agde',
    'Wittelsheim',
    'Saint-Michel-de-Volangis',
    'Sombernon',
    'Cunlhat',
    'Aspet',
    'Beuvron-en-Auge',
    'Niederbronn-les-Bains',
    'Bouxwiller',
    'Mutzig',
    'Porcheresse',
    'Bugeat',
    'Rouffach',
    'Baillargues',
    'Le Pontet',
    'Wintzenheim',
    'Kaysersberg',
    'Bischwiller',
    'Port-Saint-Louis-du-Rhone',
    'Castelnaudary',
    'Saint-Eloy-la-Glaciere',
    'Aigueperse',
    'Champeix',
    'Orgerus',
    'Blaye',
    'Cere',
    'Givet',
    'Villefagnan',
    'Rene',
    'Septeuil',
    'Mulcent',
    'Guillestre',
    'Chaumont',
    'Sangatte',
    'Touques',
    'Riez',
    'Quetigny',
    'Les Bains',
    'Dechy',
    'Montigny-en-Cambresis',
    'Requista',
    'Villefranche-le-Chateau',
    'Clement',
    'Caraman',
    'Rungis',
    'Seynod',
    'Noisy-le-Roi',
    'Henin-sur-Cojeul',
    'Saint-Maximin',
    'Sinceny',
    'Champagne-sur-Oise',
    'Amen',
    'Malabry',
    'Juvignac',
    'Ernolsheim-Bruche',
    'Wingersheim',
    'Masevaux',
    'Guewenheim',
    'Huningue',
    'Richwiller',
    'Geispolsheim',
    'Ostwald',
    'Wolfisheim',
    'Nouilly',
    'Bouxieres-aux-Dames',
    'Sapogne-et-Feucheres',
    'Noyon',
    'Maxeville',
    'Gaillon',
    'Catenoy',
    'Issus',
    'La Brulatte',
    'Mourenx',
    'Mirepeix',
    'Mesplede',
    'Arthez-de-Bearn',
    'Mazerolles',
    'Meillon',
    'Monein',
    'Maure',
    'Malaussanne',
    'Orthez',
    'Lacadee',
    'Espelette',
    'Morlaas',
    'Assat',
    'Andoins',
    'Mouguerre',
    'Hasparren',
    'Mauleon-Licharre',
    'Maslacq',
    'Bedous',
    'Villefranque',
    'Oloron-Sainte-Marie',
    'Montardon',
    'Saint-Jean-Pied-de-Port',
    'Lanne-en-Baretous',
    'Arbus',
    'Cambo-les-Bains',
    'Bidache',
    'Ondres',
    'Sauveterre-de-Bearn',
    'Bordes',
    'Salies-de-Bearn',
    'Saint-Palais',
    'Artix',
    'Boucau',
    'Larressore',
    'Gomer',
    'Saint-Pee-sur-Nivelle',
    'Sauvagnon',
    'Ascain',
    'Bardos',
    'Asasp-Arros',
    'Sendets',
    'Casteide-Cami',
    'Cescau',
    'Doumy',
    'Soumoulou',
    'Ispoure',
    'Sare',
    'Gan',
    'Louvie-Juzon',
    'Boeil-Bezing',
    'Baudreix',
    'Ogeu-les-Bains',
    'Astis',
    'Saint-Faust',
    'Feas',
    'Lalonquette',
    'Ustaritz',
    'Ciboure',
    'Briscous',
    'Ger',
    'Pontacq',
    'Gabaston',
    'Maucor',
    'Navailles-Angos',
    'Coarraze',
    'Artiguelouve',
    'Rebenacq',
    'Osses',
    'Goes',
    'Louvigny',
    'Higueres-Souye',
    'Beuste',
    'Monesties',
    'Nissan-lez-Enserune',
    'Very',
    'Lommerange',
    'Remilly',
    'Seingbouse',
    'Behren-les-Forbach',
    'Ottange',
    'Saint-Avold',
    'Theding',
    'Duclair',
    'Tournedos-Bois-Hubert',
    'Vernon',
    'Erquy',
    'Morieux',
    'Le Guildo',
    'Marsac',
    'Peyrolles-en-Provence',
    'Trets',
    'Bruebach',
    'Soultz-Haut-Rhin',
    'Turckheim',
    'Hattstatt',
    'Ostheim',
    'Pulversheim',
    'Ensisheim',
    'Bouliac',
    'Carbon-Blanc',
    'Tresses',
    'Ambes',
    'Yvrac',
    'Montaut',
    'Saint-Etienne-de-Baigorry',
    'Bouzonville',
    'Carling',
    'Stiring-Wendel',
    'Heming',
    'Villing',
    'Vittersbourg',
    'Luppy',
    'Goin',
    'Berg-sur-Moselle',
    'Frocourt',
    'Morienval',
    'Eaucourt-sur-Somme',
    'Bourdon',
    'Villers-Bocage',
    'Poix-de-Picardie',
    'Montdidier',
    'Valdoie',
    'Montbouton',
    'Grandvillars',
    'Hericourt',
    'Buhl',
    'Thann',
    'Lahonce',
    'Tarnos',
    'Saint-Vincent-de-Tyrosse',
    'Soustons',
    'Marigny',
    'Granville',
    'Les Pieux',
    'Sotteville',
    'Bolleville',
    'Saint-Sauveur-le-Vicomte',
    'Saint-Pair-sur-Mer',
    'Denneville',
    'Donville-les-Bains',
    'Mortain',
    'La Lucerne-dOutremer',
    'Avranches',
    'Fleury',
    'Torigni-sur-Vire',
    'Gavray',
    'Surville',
    'Valognes',
    'Igoville',
    'Coutances',
    'Blainville-sur-Mer',
    'Gouville-sur-Mer',
    'Jobourg',
    'Corny-sur-Moselle',
    'Dieuze',
    'Colombelles',
    'La Riviere',
    'Le Plessis-Trevise',
    'Antheuil-Portes',
    'Lamorlaye',
    'Scharrachbergheim-Irmstett',
    'Cernay',
    'Bassemberg',
    'Innenheim',
    'Mommenheim',
    'Wiwersheim',
    'Entzheim',
    'Saint-Girons',
    'Foix',
    'Lezat-sur-Leze',
    'La Tour-du-Crieu',
    'Les Cabannes',
    'Lavelanet',
    'Seignalens',
    'Quintin',
    'Jugon-les-Lacs',
    'Mousteru',
    'Hillion',
    'Bonnieres-sur-Seine',
    'Agneaux',
    'Ouzouer-sur-Trezee',
    'Beaugency',
    'Creissan',
    'Portiragnes',
    'Serignan',
    'Villeneuve les beziers',
    'Magalas',
    'Saint-Chinian',
    'Capestang',
    'Bouzigues',
    'Villeneuve',
    'Meze',
    'Palavas-les-Flots',
    'Saint-Thibery',
    'Teyran',
    'Marseillan',
    'Bessan',
    'Prades-le-Lez',
    'Balaruc-les-Bains',
    'Lodève',
    'Balaruc-le-Vieux',
    'Cazouls-les-Beziers',
    'Vailhauques',
    "Clermont-l'Herault",
    'Belarga',
    'Maraussan',
    'Laroque-de-Fa',
    'Canet-de-Salars',
    'Montaud',
    'Pezenas',
    'Cournonsec',
    'Montblanc',
    'Fitilieu',
    'Cintre',
    'Nouvoitou',
    'Saint-Benoit-des-Ondes',
    'Bosc Renoult',
    'Peynier',
    'Locquirec',
    'La Motte-Saint-Jean',
    'Lieuron',
    'Mezzavia',
    'Creuzier-le-Neuf',
    'Doyet',
    'Le Loroux-Bottereau',
    'Champagne-sur-Seine',
    'Ormoy',
    'Roquebrune-sur-Argens',
    'Guilherand-Granges',
    'Lomme',
    'Abbeville',
    'Gentilly',
    'Rambouillet',
    'Langres',
    'Steenvoorde',
    'Serans',
    'Mandelieu-la-Napoule',
    'Vence',
    'Dieue-sur-Meuse',
    'Sarrey',
    'Fleure',
    'Cabasse',
    'Montbéliard',
    'Pontcharra',
    'Oppede',
    'Citerne',
    'Gauchy',
    'Achiet-le-Petit',
    'Saint-Hostien',
    'Orchies',
    'Château-Thierry',
    'Saintes',
    'Vif',
    'Rivarennes',
    "Saint-Pierre-d'Oleron",
    'Ruelle',
    'Grugies',
    'Cauroy-les-Hermonville',
    'Cormicy',
    'Coulommiers',
    'La Teste-de-Buch',
    'Espalais',
    'Goudourville',
    'Vouhe',
    'Izon',
    'Larcay',
    'Ruffiac',
    'Montigne-le-Brillant',
    'La Boissiere-des-Landes',
    'Fontenay-Tresigny',
    'Faremoutiers',
    'Beuvraignes',
    'Jouy-le-Moutier',
    'Villemoisson-sur-Orge',
    'Ouveillan',
    'Caux-et-Sauzens',
    'Pointis-Inard',
    'Villefranche-de-Lauragais',
    'Terraube',
    'Labastide-Saint-Pierre',
    'Viviers-les-Montagnes',
    'Palaja',
    'Montreal',
    'Collioure',
    'Longuenesse',
    "Saint-Martin-d'Hères",
    'Brindas',
    'Mellecey',
    'Simandres',
    'Les Chapelles',
    'Sauvian',
    'Argelliers',
    'Canohes',
    'Colombieres-sur-Orb',
    'Corneilla-de-Conflent',
    'Tressan',
    'Boisseron',
    'Limeil-Brevannes',
    'Clevilliers',
    'Marthod',
    'Montreal',
    'Piney',
    'Balanod',
    'Marles-les-Mines',
    'Wervicq-Sud',
    'Montluçon',
    'Parthenay',
    "Saint-Maixent-l'Ecole",
    'Chauvigny',
    'Sainte-Verge',
    'Avize',
    'Velleron',
    'Vinon-sur-Verdon',
    'Chauray',
    'Saint-Maigrin',
    'Thouars',
    'Le Tallud',
    'Puihardy',
    'Saint-Avertin',
    'Naizin',
    'Paulx',
    'Saint-Pryve-Saint-Mesmin',
    'Louvres',
    'Guise',
    'Provins',
    'Oyonnax',
    'Rocbaron',
    'Tarcenay',
    'Eze',
    'Harfleur',
    'Saivres',
    'Beliet',
    'Montreal-la-Cluse',
    'Jeurre',
    'Preignac',
    'Parcay-Meslay',
    'Olivet',
    'Beaumont-sur-Oise',
    'Charleville-Mézières',
    'Saint-Quentin-le-Verger',
    'Soissons',
    'Lecelles',
    'Homblieres',
    'Laurent',
    'Fontenay-le-Fleury',
    'La Colle-sur-Loup',
    'Still',
    'Biache-Saint-Vaast',
    'Saint-Jean-le-Blanc',
    'Gareoult',
    'Neuville-en-Ferrain',
    'Vieux Conde',
    'Saint-Ismier',
    'Golbey',
    'Notre-Dame-de-Bondeville',
    'Lantriac',
    'Villasavary',
    'Saint-Amans-Soult',
    'Annay',
    'Ponthierry',
    'La Madeleine',
    'Sollies-Pont',
    'Lunéville',
    'Bagnols-sur-Ceze',
    'Moureze',
    'Unieux',
    'Villejust',
    'Signes',
    'Salin-de-Giraud',
    'Saint-Mandrier-sur-Mer',
    'Le Beausset',
    'Peri',
    'La Celle',
    'Montauroux',
    'Pignans',
    'Le Conquet',
    'Varades',
    'Gargenville',
    'Le Thor',
    'Courseulles-sur-Mer',
    'Bernieres-sur-Mer',
    'Rocquemont',
    'Morgny-la-Pommeraye',
    'Douvres-la-Delivrande',
    "Saint-Clair-sur-l'Elle",
    'Cresserons',
    'Yvetot',
    'Clasville',
    'Crépy-en-Valois',
    'Dreux',
    'Longpont-sur-Orge',
    'Givors',
    "Pont-d'Ain",
    'Vendat',
    'Sennecey-le-Grand',
    "Saint-Marcel-d'Urfe",
    'Saint-Germain-du-Plain',
    'Louhans',
    'Savigneux',
    'Saint-Marcel-les-Valence',
    'Bellignat',
    'Saint-Cyr-sur-Mer',
    'Jarrie',
    'Cercoux',
    'Montrelais',
    'Machecoul',
    'Saint-Cyprien-Plage',
    'Saint-Parres-aux-Tertres',
    'Bessieres',
    'Le Haillan',
    'Cousances-les-Forges',
    'Lons-le-Saunier',
    'Fontaine-le-Comte',
    'Saint-Benoit',
    'Eu',
    'Lardy',
    'Saint-Aygulf',
    'Cesson',
    'Grande-Synthe',
    'Cebazat',
    'Mazamet',
    'Deauville',
    'Brou',
    'Mortagne-au-Perche',
    'Hauterive',
    'Ivry-la-Bataille',
    "Saint-Pierre-d'Entremont",
    'La Chaussee',
    'Coudray-au-Perche',
    'Anet',
    'Marcheville',
    'Fresse',
    'Marpent',
    'Villers-Sire-Nicole',
    'Coucy-le-Chateau-Auffrique',
    'Lectoure',
    'Enghien-les-Bains',
    "Saint-Ouen-l'Aumone",
    "Villette-d'Anthon",
    'Saint-Just-en-Bas',
    'Brunstatt',
    'Collegien',
    'Maule',
    'Viarmes',
    'Saint-Alban-Leysse',
    'Labruguiere',
    'Aussillon',
    'Brie',
    'Hasnon',
    'Saint-Die-des-Vosges',
    'Audenge',
    "Saint-Laurent-d'Arce",
    'Geloux',
    'Langon',
    'Saint-Magne-de-Castillon',
    'Sainte-Neomaye',
    'Vergt',
    'Tartas',
    'Bergerac',
    'Veron',
    'Rully',
    'Claix',
    "Saint-Pierre-d'Albigny",
    "L'Isle-sur-la-Sorgue",
    'Le Pont',
    'Angres',
    'Guesnain',
    'Missillac',
    'Saint-Victoret',
    'Vidauban',
    'Peille',
    'Saint-Gaudens',
    'Moissac',
    'Labarthe-sur-Leze',
    'Montricoux',
    'Pinsaguel',
    'Montgaillard',
    'Leuc',
    'Bram',
    'Les Arcs',
    "Cabrieres-d'Aigues",
    'Marguerittes',
    'Autrans',
    'Saint-Etienne-des-Oullieres',
    'Creissels',
    'Villefranche-de-Rouergue',
    'Naucelle',
    'Azay-le-Brule',
    'Entrecasteaux',
    'Nanteuil-les-Meaux',
    'Cranves-Sales',
    'Rodez',
    'Lezignan-Corbieres',
    'Rieupeyroux',
    'Marennes',
    'Annezay',
    'Les Mathes',
    'Chatelaillon-Plage',
    'Tonnay-Charente',
    'Le Bois-Plage-en-Re',
    'Arvert',
    'Cozes',
    'Royan',
    'Vouneuil-sur-Vienne',
    'Archiac',
    'Ecoyeux',
    'Trizay',
    'Pons',
    'Bourgneuf',
    'Cubzac-les-Ponts',
    'Echillais',
    'Port-des-Barques',
    "Saint-Georges-d'Oleron",
    'Rouffignac',
    'Saint-Jean-de-Liversay',
    'Dompierre-sur-Mer',
    'Geay',
    'Saint-Agnant',
    'Hermies',
    'Jaunay-Clan',
    'Pujols',
    'Avanton',
    'Roullet-Saint-Estephe',
    'Saint-Vivien',
    'Martignas-sur-Jalle',
    'Chasseneuil-du-Poitou',
    'Beaumont-du-Perigord',
    'Bonneville-Aptot',
    'Auberchicourt',
    'Scorbe-Clairvaux',
    'Perigny',
    'Portets',
    'Saint-Robert',
    'Port-Sainte-Marie',
    'Saint-Denis-de-Pile',
    'La Chevroliere',
    'Gujan-Mestras',
    'Olemps',
    'Sebazac-Concoures',
    'Bioule',
    'Saint-Pathus',
    'Saint-Georges-de-Baroille',
    'Aubignan',
    'Asnans-Beauvoisin',
    'Villers-les-Pots',
    'Replonges',
    'Ferreol',
    'Auxonne',
    'Guny',
    'Choisy-en-Brie',
    'Renage',
    'Corcieux',
    'Thaon-les-Vosges',
    'Malancourt-la-Montagne',
    'Thonon-les-Bains',
    'Remenoville',
    'Damelevieres',
    'Rougemont',
    'Saint-Maurice-sur-Moselle',
    'Publier',
    'Saessolsheim',
    'La Cerlangue',
    'La Remuee',
    'Pont-Audemer',
    'Portvendres',
    'Castelsarrasin',
    'Saint-Vallier',
    'Dammartin-en-Goele',
    'Othis',
    'Vizille',
    'Voreppe',
    'Avesnes-sur-Helpe',
    'Fourmies',
    'Camon',
    'Corbie',
    'Quaedypre',
    'Vimy',
    'Garancieres-en-Drouais',
    'Vineuil',
    'Viriat',
    'Pegomas',
    'Douzy',
    'Villemoustaussou',
    'Vineuil',
    'Cassis',
    'Les Ormes-sur-Voulzie',
    'Le Deluge',
    'Chaussy',
    'Avaray',
    'Courcelles-sur-Viosne',
    'Migennes',
    'Saint-Nom-la-Breteche',
    'Vierzon',
    'Amure',
    'Saint-Jean-aux-Amognes',
    "L'Isle-d'Abeau",
    'Fontanil-Cornillon',
    'Eragny',
    'Galluis',
    'Saint-Dizier-en-Diois',
    "La Chapelle-d'Armentieres",
    'Le Pradet',
    'Villard-Bonnot',
    'Moirans',
    'Amboise',
    'Chaumontel',
    'Fosses',
    'Herserange',
    'Puyricard',
    'Entre-deux-Guiers',
    'Saint-Christophe-sur-Guiers',
    'Bayeux',
    'Saint-Arnoult',
    "Cire-d'Aunis",
    'Soubise',
    'Aulnay',
    'Chaniers',
    'Lisieux',
    'Saint-Amand-Montrond',
    'Breuillet',
    'Saint-Hippolyte',
    "Saint-Denis-d'Oleron",
    'La Flotte',
    "Saint-Sulpice-d'Arnoult",
    'Marans',
    'Surgeres',
    'Vaux-sur-Mer',
    'Jarny',
    'Thiviers',
    'Morteau',
    'Rivesaltes',
    'Pierrelatte',
    'Angoulins',
    'Breuil-Magne',
    'La Chapelle-Baton',
    'Saint-Leger-des-Vignes',
    'Tonnay-Boutonne',
    'Vallant-Saint-Georges',
    'Bompas',
    'Saint-Servant',
    'Saint-Marcellin',
    'Perenchies',
    'Bouzel',
    'Vertaizon',
    'Portet-sur-Garonne',
    'Tournus',
    'Carrouges',
    'La Gacilly',
    'La Loupe',
    'Querenaing',
    'Douvrin',
    'Tergnier',
    'Lambres-lez-Douai',
    'Blonville-sur-Mer',
    'Preaux',
    'Saint-Aubin-sur-Gaillon',
    'Cany-Barville',
    'Coudray',
    'Poses',
    'Trumilly',
    'Montigny-le-Chartif',
    'Nogent-le-Rotrou',
    'Vauchamps',
    'Reuves',
    'Landivisiau',
    'Varennes-sur-Seine',
    'Noeux-les-Mines',
    'Attiches',
    'Marsillargues',
    'Bouillargues',
    'Saint-Jean-de-Bournay',
    "Pont-de-l'Arche",
    'Le Teilleul',
    'Étretat',
    'Saint-Pierre-en-Port',
    'Elna',
    'Paillencourt',
    'Commelle-Vernay',
    'La Biolle',
    'Banize',
    'Nohanent',
    'Ailly-sur-Noye',
    'Cazeres',
    'Montrejeau',
    'Lege-Cap-Ferret',
    'Fronsac',
    'Saint-Sebastien',
    'Pre-en-Pail',
    'Les Touches',
    'Champeon',
    'Chas',
    'La Verpilliere',
    'Saint-Clair-de-la-Tour',
    'Mont-de-Marsan',
    'Sallanches',
    'Aime',
    'Pont-en-Royans',
    'Saint-Didier-en-Velay',
    'Saint-Loup',
    'La Roche-sur-le-Buis',
    'Messimy',
    'Couternon',
    'Monistrol-sur-Loire',
    'Ludres',
    'Boissy-le-Chatel',
    'Romorantin-Lanthenay',
    'Vire',
    'Les Ollieres-sur-Eyrieux',
    'Viviers-du-Lac',
    'Chabons',
    'La Plagne',
    'Challes-les-Eaux',
    'Gap',
    'Ugine',
    'Sonnay',
    'Fegreac',
    'Bauvin',
    'Wavrin',
    'Plourin',
    'Saint-Bonnet-de-Mure',
    'Valencin',
    'Brouilla',
    'Saint-Orens-Pouy-Petit',
    'Paulhan',
    'Limoux',
    'Saint-Claude',
    'Piennes',
    'Longlaville',
    'Euville',
    'Saint-Mihiel',
    'Longeville-en-Barrois',
    'Sainghin-en-Melantois',
    'Violaines',
    'Naintre',
    'Lussac',
    'Echire',
    'Corme-Royal',
    'Einville-au-Jard',
    'Hadol',
    'Châteauneuf-dIlle-et-Vilaine',
    'Baulon',
    'Le Tronchet',
    'La Milesse',
    'Saint-Thurial',
    'Mareil-sur-Loir',
    'Damigny',
    'Pleugueneuc',
    'Commer',
    'Saint-Jean-sur-Erve',
    'La Tour-Saint-Gelin',
    'Pluzunet',
    'Laissey',
    'Saint-Vit',
    'Bonneville',
    'Darnetal',
    'Folschviller',
    'Avon',
    'Bandol AOC',
    'Saint-Esteve',
    'Le Lavandou',
    'Guingamp',
    'Bagneaux-sur-Loing',
    'Viriville',
    'Auterive',
    'Baziege',
    'Flers',
    'Wattignies',
    'Montoir-de-Bretagne',
    'Vendin-le-Vieil',
    'Albertville',
    'Ardres',
    'Sanary-sur-Mer',
    'Veneux-les-Sablons',
    'Ecuelles',
    'Egletons',
    "Saint-Cyr-l'École",
    'Villeneuve-la-Guyard',
    'Lagrave',
    'Saint-Gregoire',
    'Aubiet',
    'Fayssac',
    "Valence-d'Albigeois",
    'Cagnac-les-Mines',
    'Vielmur-sur-Agout',
    'Brousse',
    'Divonne-les-Bains',
    'Montagnat',
    'Peron',
    'Ornex',
    'Dole',
    'Flevy',
    'Cuvry',
    'Sceaux',
    'Blainville-sur-Orne',
    'Bois-Arnault',
    'Luc-sur-Mer',
    'Appeville',
    'Pont-Saint-Pierre',
    'Pomport',
    'Mios',
    'Barberaz',
    'Wambrechies',
    'Loche',
    'Echenon',
    'Chaspinhac',
    'Saint-Savournin',
    'Noiron',
    'Pessans',
    'Cuges-les-Pins',
    'Uchaux',
    'Mormant',
    'Auvers-Saint-Georges',
    "Fresnay-l'Eveque",
    'La Bazoche-Gouet',
    'Souprosse',
    'Cysoing',
    'Annequin',
    'Templeuve',
    'Dormans',
    'Cogolin',
    'La Voulte-sur-Rhone',
    'Le Teil',
    'Bellegarde-sur-Valserine',
    'Cloyes-sur-le-Loir',
    'Viuz-en-Sallaz',
    'Saint-Michel-Chef-Chef',
    'Cuers',
    'Saint-Maximin-la-Sainte-Baume',
    'Saint-Chaptes',
    'Couhe',
    'Montreuil-Bonnin',
    'La Mailleraye-sur-Seine',
    'Marcheprime',
    'Morcenx',
    'Arengosse',
    "Vicq-d'Auribat",
    'Caudebec-en-Caux',
    'Saint-Andre-le-Gaz',
    'Boussy-Saint-Antoine',
    'Vaureal',
    'Montesquieu-Volvestre',
    'Saint-Gerand-de-Vaux',
    'Abondant',
    'Vendome',
    'Laneuveville-devant-Nancy',
    'Monbalen',
    'Boutigny-Prouais',
    'Versonnex',
    'Magny-le-Hongre',
    'Vivy',
    'Boissy-Mauvoisin',
    'La Fleche',
    'La Ferte',
    'Chablis',
    'Soulaincourt',
    'Sees',
    'Vitry-la-Ville',
    'Chateau-sur-Epte',
    'Saint-Thierry',
    'Biesles',
    'Kienheim',
    'Les Thilliers-en-Vexin',
    'Ussac',
    'La Souterraine',
    'Sainte-Soulle',
    'Sarge-sur-Braye',
    'Verzenay',
    'Hersin-Coupigny',
    'Auchy-les-Mines',
    'Saint-Paul-en-Cornillon',
    'Camaret-sur-Mer',
    'Cornas',
    'Chaon',
    'Loriol-sur-Drome',
    'Toussieu',
    'Saint-Amant-Tallende',
    'Ciry-le-Noble',
    'Villers-sur-Saulnot',
    'Bethoncourt',
    'Villemoirieu',
    'Valines',
    'Belmont',
    'Schweighouse-Thann',
    'Sainte-Anne-Saint-Priest',
    'Portes-les-Valence',
    'Saint-Felicien',
    'Lablachere',
    'Montbizot',
    'Ussel',
    'Puget-sur-Argens',
    'Tresques',
    'Ozolles',
    'Le Teich',
    'Perrigny',
    'Limerzel',
    'Etrepagny',
    'Saint-Urbain',
    'Trouville-sur-Mer',
    'Gravigny',
    'Pont-du-Casse',
    'Mornant',
    'Nuits-Saint-Georges',
    'Les Mages',
    'Ruch',
    'Alluy',
    'Decize',
    'Mennecy',
    'Bouffemont',
    'Brasles',
    'Venelles',
    'Chateauneuf-du-Rhone',
    'Neuille',
    'Chateauneuf-sur-Sarthe',
    'Vinay',
    'Villaz',
    'Baune',
    'Champtoceaux',
    'Pelussin',
    'Salaise-sur-Sanne',
    'Ennevelin',
    'Lillers',
    'Etoges',
    'Pont-sur-Yonne',
    'Allonville',
    'Valenton',
    'Chateaufort',
    'Fere-Champenoise',
    'Caylus',
    'Sin-le-Noble',
    'Saint-Martial-de-Vitaterne',
    'Couzeix',
    'Malemort-sur-Correze',
    'Ambazac',
    'Saint-Yrieix-la-Perche',
    'Saint-Chartres',
    'Allonnes',
    'Connerre',
    'Varennes-Vauzelles',
    'La Jarrie',
    'Forges',
    'Champagne-les-Marais',
    'Toussus-le-Noble',
    'Roye-sur-Matz',
    'Lormaye',
    'Saint-Arnoult-en-Yvelines',
    'Perros-Guirec',
    'Saint-Quay-Perros',
    'Montvalen',
    'Étaples',
    'Licques',
    'Morangis',
    'Sauzelles',
    'Buzan',
    'La Mure',
    'Feissons-sur-Isere',
    'Culoz',
    'Jarcieu',
    'Chignin',
    "Berd'huis",
    'Woippy',
    'Hardencourt',
    'Vendargues',
    'Semoine',
    "Courcelles-Val-d'Esnoms",
    'Verdun',
    'Neuves-Maisons',
    'La Varenne',
    'Saint-Martin-le-Vinoux',
    'Drachenbronn-Birlenbach',
    'Mathenay',
    'Poulainville',
    'Plounerin',
    'Merxheim',
    'La Saulce',
    'Chabottes',
    'Vallouise',
    'Nouzonville',
    'Juniville',
    'Sommerance',
    'Vouziers',
    'Chehery',
    'Jandun',
    'Saint-Andre-de-Chalencon',
    'Centres',
    'Decazeville',
    'Aubin',
    'Saint-Affrique',
    'Charost',
    'Mereau',
    'Vailly-sur-Sauldre',
    'Saint-Florent',
    'Aléria',
    'Brando',
    'Oletta',
    'Gérardmer',
    'Saint-Etienne-les-Orgues',
    'Reillanne',
    'Sisteron',
    'Volonne',
    'Plessis-Barbuise',
    'Eaux-Puiseaux',
    'Etourvy',
    'Marigny-le-Chatel',
    'Auxon',
    'Ploubazlanec',
    'Rospez',
    'Poisy',
    'Aubevoye',
    'Gasny',
    'Ecardenville-la-Campagne',
    'Saint-Etienne-sous-Bailleul',
    'Azerailles',
    'Uchaud',
    'Nages-et-Solorgues',
    'Saint-Hilaire-de-Brethmas',
    'Bernis',
    'Leguevin',
    'Feneyrols',
    'Magny-le-Desert',
    'La Tour-sur-Orb',
    'Sainte-Enimie',
    'Antrain',
    'Tourouvre',
    'Domfront',
    'Bazoches-sur-Hoene',
    'Saint-Germain-du-Corbeis',
    'Soligny-la-Trappe',
    'Couterne',
    'Frencq',
    'Marquise',
    'Brebieres',
    'Coulomby',
    'Merlimont',
    'Landrethun-le-Nord',
    'Langueux',
    'Anzy-le-Duc',
    'Epinac',
    'Palinges',
    'Chenay-le-Chatel',
    'Autun',
    'Buxy',
    'Savigny-en-Revermont',
    'Montfort',
    'Noyen-sur-Sarthe',
    'Vaas',
    'Coulans-sur-Gee',
    'Bourg-Saint-Maurice',
    'La Motte-Chalancon',
    'La Bathie',
    'Villard-sur-Doron',
    'Saint-Julien-des-Landes',
    'Saint-Georges-de-Pointindoux',
    'La Verrie',
    'Curzay-sur-Vonne',
    'Dissay',
    'Civray',
    'Sommieres-du-Clain',
    'Cisse',
    'Fouronnes',
    'Ancy-le-Franc',
    'Courson-les-Carrieres',
    'Ouanne',
    'Ligny-le-Chatel',
    'Noyers',
    'Esson',
    'Baye',
    'Cordes-sur-Ciel',
    'Boissezon',
    'Moulin-Mage',
    'Blan',
    'Briatexte',
    'Trouillas',
    'Limours',
    'Chaumes-en-Brie',
    'Romilly-sur-Seine',
    'Roissy-en-France',
    'Boissy-le-Bois',
    'Marolles-en-Brie',
    'Pecqueuse',
    'Toucy',
    'Six-Fours-les-Plages',
    'Ollioules',
    'Le Revest-les-Eaux',
    'Gassin',
    'Magny-les-Hameaux',
    'Magny-en-Vexin',
    'Blanzat',
    "Boissy-l'Aillerie",
    'Vigny',
    'Contamine-sur-Arve',
    'Saint-Germain-les-Paroisses',
    'Chanoz-Chatenay',
    'Aramon',
    "L'Herbergement",
    'Noyers',
    'Valensole',
    'Saint-Astier',
    'Genis',
    'Belvès',
    'Champagne',
    'Hauteville-Lompnes',
    'Beaupont',
    'Neuville-sur-Ain',
    'Nattages',
    'Molandier',
    'Vinassan',
    'Donzere',
    'Barjac',
    "Le Pre-d'Auge",
    'Demouville',
    'Ouilly-le-Vicomte',
    'Ellon',
    'Mezidon-Canon',
    'Coupesarte',
    'Heurtevent',
    'Saint-Vaast-en-Auge',
    'Bonneville-la-Louvet',
    'Arzano',
    'Scrignac',
    'Delle',
    'Vers-Pont-du-Gard',
    'Lesparre-Médoc',
    'Saint-Seurin-de-Cadourne',
    'Targon',
    'Pauillac Haut',
    'La Reole',
    'La Garde',
    'Cruas',
    'Ouzouer-le-Marche',
    'Selles-sur-Cher',
    'Limogne-en-Quercy',
    'Assier',
    'Geyssans',
    'Rohan',
    'Sarzeau',
    'Pluherlin',
    'Rety',
    'Flechin',
    'Ponte-Leccia',
    'Maintenon',
    'Saint-Valery-en-Caux',
    'Colleville-Montgomery',
    'Thuit-Hebert',
    'Boos',
    'Bolbec',
    'Vacognes-Neuilly',
    'Fere-en-Tardenois',
    'Arcy-Sainte-Restitue',
    'Braine',
    'Vervins',
    'Oulchy-le-Chateau',
    'Quiberville',
    "Sainte-Agathe-d'Aliermont",
    "Saint-Nicolas-d'Aliermont",
    'Gueures',
    'Petreto-Bicchisano',
    "Sari-d'Orcino",
    'Porto-Vecchio',
    'Prigonrieux',
    'Montagne',
    'Cazaux',
    'Loudun',
    'Linars',
    'Pineuilh',
    'Couze-et-Saint-Front',
    'Montpon-Menesterol',
    'Port-Joinville',
    'Sainte-Hermine',
    'Les Pineaux',
    'La Reorthe',
    'Vignot',
    'Eyrans',
    'Saint-Medard-de-Guizieres',
    'Saint-Selve',
    'Neuville-de-Poitou',
    'Nersac',
    'Cordelle',
    'Cremeaux',
    'Machezal',
    'Bourbon-Lancy',
    'Neuilly-le-Real',
    'Dompierre-sur-Besbre',
    'Les Avenieres',
    'Bedarrides',
    'Varces-Allieres-et-Risset',
    'Villelaure',
    'Cornillon-en-Trieves',
    'Beaulieu-sur-Mer',
    'Vauban',
    'Fontain',
    'Varangeville',
    'La Force',
    'Crosne',
    'Lathuile',
    'Vagney',
    'La Roquette-sur-Siagne',
    'Valberg',
    'Lure',
    'Saint-Jorioz',
    'Notre-Dame-de-Gravenchon',
    'Baguer-Pican',
    'Plougoulm',
    'Parne-sur-Roc',
    'Precigne',
    'Andon',
    'Saint-Martin-du-Var',
    'Gruchet-le-Valasse',
    'Vittel',
    'Saint-Cergues',
    'Basse-sur-le-Rupt',
    'Magland',
    'Le Petit-Quevilly',
    'Haverskerque',
    'Saint-Antoine-la-Foret',
    'Saint-Julien-en-Genevois',
    'Beauzelle',
    'Rozoy-Bellevalle',
    'La Couarde-sur-Mer',
    'Reignac-sur-Indre',
    'Audruicq',
    'La Ferte-Saint-Samson',
    'Sainte-Marguerite',
    'Civrieux',
    'Meximieux',
    'Odomez',
    'Honfleur',
    'Genebrieres',
    'Saint-Orens-de-Gameville',
    'Eaunes',
    'Puygouzon',
    'Villemur-sur-Tarn',
    'Pezilla-la-Riviere',
    'Lauzerville',
    'Damiatte',
    'Castelginest',
    'Montaut',
    'Lapalme',
    'Salies-du-Salat',
    'Martel',
    'Mauzac',
    'Cambounet-sur-le-Sor',
    'Seysses',
    'Montbeton',
    'Fenouillet',
    'Millas',
    'Saint-Etienne-de-Tulmont',
    'Tremeven',
    'Herlies',
    'Terdeghem',
    'Evin-Malmaison',
    'Warluzel',
    'Juziers',
    'Bucy-le-Long',
    'Saint-Mande',
    'Montsoult',
    'Charmes',
    'Kerien',
    'Plouguerneau',
    'Saint-Igneuc',
    'Glos-sur-Risle',
    'Mondrainville',
    'Pavilly',
    'Clinchamps-sur-Orne',
    'Fontaine-le-Dun',
    'Desvres',
    'Thivars',
    'Bras',
    'Roquevaire',
    'Saint-Mitre-les-Remparts',
    'Saint-Andiol',
    'Fours-Saint-Laurent',
    'Saint-Andre-sur-Orne',
    'Lusignan',
    'Vayres',
    'Port-de-Bouc',
    'Saint-Apollinaire',
    'Saint-Jean-en-Royans',
    'Les Vans',
    'Vernoux-en-Vivarais',
    'Saint-Lager-Bressac',
    'Saint-Gervais-sur-Roubion',
    'La Begue',
    'Pont-de-Labeaume',
    'Gervans',
    'Beaumont-les-Valence',
    'Peyrins',
    'Puy-Saint-Martin',
    'Chomerac',
    'Serignan-du-Comtat',
    'Roubion',
    'La Souche',
    'Nyons',
    "Tain-l'Hermitage",
    'Desaignes',
    'Jaujac',
    'Saint-Pierreville',
    'Vion',
    'Peyrus',
    'Saint-Sorlin-en-Valloire',
    'Largentière',
    'Alboussiere',
    'Saint-Martin-de-Valamas',
    'Lussas',
    'Saint-Barthelemy-de-Vals',
    'Banne',
    'Vignes',
    'Marsanne',
    'Charnas',
    'Ruoms',
    'La Baume-de-Transit',
    'Colombier-le-Cardinal',
    'Alixan',
    'Anneyron',
    'Saint-Trojan-les-Bains',
    'La Chaussaire',
    'Dampierre-sur-Boutonne',
    'Fouras',
    'Varrains',
    'Vihiers',
    "la Roca d'Albera",
    'Pontcharra-sur-Turdine',
    'Laval-sur-Doulon',
    'Chargey-les-Gray',
    'Cluny',
    'Pouydesseaux',
    'Lozinghem',
    'Hardelot-Plage',
    'Masny',
    'Preseau',
    'La Chapelle',
    'Bourdenay',
    'Saint-Lubin-des-Joncherets',
    'Sorede',
    'Negrepelisse',
    'Grepiac',
    'Brassac',
    'Tarascon',
    'Tourves',
    'Les Pins',
    'Saint-Jean-de-Chevelu',
    'Digoin',
    'Cerny',
    'Baillet-en-France',
    'Livarot',
    'Sebecourt',
    'Sail-sous-Couzan',
    'Algrange',
    'Bournezeau',
    'Hallines',
    'Condette',
    'Quesnoy-sur-Deule',
    'Laventie',
    'Vulaines-sur-Seine',
    'Presles-en-Brie',
    'Montville',
    'Mers-les-Bains',
    'Bosgouet',
    'Le Trait',
    'Rozoy-sur-Serre',
    'Mondonville',
    'Cussey-les-Forges',
    "L'Île-Rousse",
    'Force',
    'Condé-sur-Huisne',
    'Authie',
    'Civaux',
    'Flayosc',
    'Fleurance',
    'Ayguesvives',
    'Le Barboux',
    'Goux-les-Usiers',
    'Saint-Pourcain-sur-Sioule',
    'Prahecq',
    'Fumel',
    "Saint-Marcel-d'Ardeche",
    'Malause',
    'Rousson',
    'Vonnas',
    'Poulx',
    'Sainte-Croix-en-Jarez',
    'Villers-Farlay',
    'Le Touvet',
    'Flumet',
    "L'Albenc",
    'Gieres',
    'Morieres-les-Avignon',
    "Saint-Andre-Goule-d'Oie",
    'La Gaubretiere',
    'Landes',
    'Azerables',
    'Saint-Privat',
    'Jarnages',
    'Grandsaigne',
    'Pechbonnieu',
    'Montigny-les-Cormeilles',
    'Orgeval',
    'Saint-Michel',
    'Sissonne',
    'Villers-Bocage',
    'Pont-du-Chateau',
    'Savigny-les-Beaune',
    'Mareuil',
    'Cambes',
    'Mimbaste',
    'Oiron',
    'Saran',
    'Chevannes',
    'Le Thillot',
    'Arx',
    'Le Tholonet',
    'Durance',
    'Rognes',
    'Lantilly',
    'Varilhes',
    'Mazeres-de-Neste',
    'Ferrieres-sur-Ariege',
    'Pradieres',
    'Le Mas',
    'Miglos',
    'Caumont',
    "Laroque-d'Olmes",
    'Carrieres-sur-Seine',
    'Nozay',
    'Le Kremlin-Bicetre',
    'Mainvilliers',
    'Varages',
    'Pourrieres',
    'Arzal',
    'Beauvoir-de-Marc',
    'Camps-la-Source',
    'Canet-Plage',
    'Saint-Florent-sur-Auzonnet',
    'Dienville',
    'Biermont',
    'Venables',
    'Forges-les-Eaux',
    'Goderville',
    'Les Milles',
    'Rians',
    'Ploumilliau',
    "Nieuil-l'Espoir",
    'Rainfreville',
    'Landrecies',
    'Soorts-Hossegor',
    'La Landec',
    'Vieille-Eglise-en-Yvelines',
    'Steenbecque',
    'Henouville',
    'Croissanville',
    'Conde-sur-Noireau',
    'Saint-Malo-du-Bois',
    'Saint-Rambert-en-Bugey',
    'Surville',
    'Merville-Franceville-Plage',
    'Vic-le-Comte',
    'Cires-les-Mello',
    'Jeandelaincourt',
    'Faucigny',
    'Samoëns',
    'Choisy',
    'Saint-Laurent-des-Autels',
    'Villenoy',
    'Boen-sur-Lignon',
    "Anglesqueville-l'Esneval",
    'Monnieres',
    'Porcheres',
    'Pleumeur-Bodou',
    'Quessoy',
    'Villeton',
    'Moissy-Cramayel',
    'Arles',
    'Pringy',
    'Frepillon',
    'Tomblaine',
    'Saint-Romain-en-Gier',
    'Saint-Lye',
    'Mireval-Lauragais',
    'Nebian',
    'Quint-Fonsegrives',
    'Fleury-sur-Orne',
    'Bonsecours',
    'Epinay-sous-Senart',
    'Vernegues',
    'Renescure',
    'Quincy-Voisins',
    'Saint-Luperce',
    'Bailleau-le-Pin',
    'Berat',
    'Poix-du-Nord',
    'Saint-Michel-de-Maurienne',
    'Rouans',
    'Etang-sur-Arroux',
    'Roquecourbe',
    'Challet',
    'Corancez',
    'Sigean',
    'Sernhac',
    'Saint-Siffret',
    'Fourques',
    'Gignac',
    'Torfou',
    'Montfaucon-Montigne',
    'Seiches-sur-le-Loir',
    'Barjouville',
    'Is-sur-Tille',
    'Sarrebourg',
    'Merville',
    'Sommieres',
    'Amplepuis',
    'Wavrechain-sous-Denain',
    'Laloubere',
    'Juillan',
    'Saint-Laurent-de-la-Salanque',
    'Toulouges',
    'Chateau-Porcien',
    'Buchy',
    'Bosc-le-Hard',
    'Saint-Martin-sur-Ocre',
    'Tavers',
    'Bezouce',
    'Pieusse',
    'Ledenon',
    'Thuir',
    'Herbeys',
    'Beauvoisin',
    'Issenheim',
    'Roussay',
    'Mouy',
    'Saint-Calais',
    'Mimet',
    'Lagupie',
    'Maulevrier',
    'Besne',
    'Steenwerck',
    'Saint-Loubes',
    'Tarnes',
    'Monchecourt',
    'Coulogne',
    'Bompas',
    'Nogent-sur-Oise',
    'Seignosse',
    'Verberie',
    'Foissiat',
    'Longueil-Annel',
    'Jard-sur-Mer',
    'Eauze',
    'Ecos',
    'Dozule',
    'Saint-Erblon',
    'Le Mele-sur-Sarthe',
    'Dercy',
    'Villeneuve-les-Bouloc',
    'Lacs',
    'La Chatre',
    'Clesse',
    'Bazas',
    'Vireux-Wallerand',
    'Etreux',
    'Bourbourg',
    'Wormhout',
    'Macau',
    'Beauvais-sur-Matha',
    'Verquieres',
    'Rucqueville',
    'Nouaille-Maupertuis',
    'Schirrhein',
    'Sainte-Menehould',
    'Sannois',
    'Gorron',
    'Belleville-sur-Mer',
    'Baixas',
    'Mouries',
    'Parigne-sur-Braye',
    'Blainville',
    'Conde-sur-Vesgre',
    'Lampertsloch',
    'Fougerolles',
    'Auch',
    'Amberieux-en-Dombes',
    'Villars-les-Dombes',
    'Cadalen',
    'Froges',
    "Sainte-Foy-d'Aigrefeuille",
    'Aigrefeuille',
    'Reuilly',
    'Annoeullin',
    'Farebersviller',
    'Villerupt',
    'Racquinghem',
    'Monnaie',
    'Chateau-Renault',
    'Ligueil',
    'Auxi-le-Chateau',
    'Le Molay',
    "Saint-Jean-d'Illac",
    'Vinneuf',
    'Saint-Julien-du-Sault',
    'Le Bernard',
    'Saint-Martin-de-Valgalgues',
    'Saint-Pierre-du-Mont',
    'Feneu',
    'Rieulay',
    'Lesconil',
    'Saint-Jean-de-Sauves',
    'Saint-Clair',
    'Seremange-Erzange',
    'Us',
    'Thieville',
    'Le Barp',
    'Nonville',
    'Saintry-sur-Seine',
    'Biscarrosse',
    'Parentis-en-Born',
    'Clohars-Fouesnant',
    'Plouarzel',
    'Lanvallay',
    'May-en-Multien',
    'Chateau-Landon',
    'Mauperthuis',
    'Beautheil',
    'Caden',
    'Grez-sur-Loing',
    'Seugy',
    'Boullay-les-Troux',
    'La Turbie',
    'Alignan-du-Vent',
    'Vendres',
    'Frozes',
    'Vars',
    'Mansle',
    'Pecquencourt',
    'La Menitre',
    'Suippes',
    'Bachant',
    'Assevent',
    'Louvroil',
    'Cotignac',
    'Lery',
    'Le Bocasse',
    'Gauciel',
    'Coignieres',
    'Auribeau-sur-Siagne',
    'Bussang',
    'Tessy-sur-Vire',
    'Beaumont-de-Lomagne',
    'Laroin',
    'Villeconin',
    'Saclas',
    'Montigny-en-Gohelle',
    'Saint-Lormel',
    'Oraison',
    'Brece',
    'Epervans',
    'Hamars',
    'Thury-Harcourt',
    "L'Union",
    'Esternay',
    'Lagnieu',
    'Champigny',
    'Ergersheim',
    'Walheim',
    'Zillisheim',
    'Sury-le-Comtal',
    'Nogent',
    'Rollancourt',
    'Montastruc-la-Conseillere',
    'Bar-le-Duc',
    'Savennieres',
    'Houlette',
    'Nohic',
    'Adissan',
    'Bonneuil-en-France',
    'Le Blanc-Mesnil',
    'Verines',
    'Hericy',
    'Roquefort-sur-Soulzon',
    'Matignon',
    'Severac-le-Chateau',
    'Chissay-en-Touraine',
    'Aubais',
    'Arcachon',
    'Saint-Varent',
    'Airvault',
    'Biot',
    'Uckange',
    'Cordes-Tolosannes',
    'Larrazet',
    'Kuntzig',
    'Le Monastier-Pin-Mories',
    'Montrodat',
    'Bazarnes',
    'Boisseuil',
    'Cazavet',
    'Barr',
    'Lincel',
    'Prix-les-Mezieres',
    'Alleriot',
    'Brionne',
    'Villelongue-de-la-Salanque',
    'Banyuls de la Marenda',
    'Archamps',
    'Chamouilley',
    'Saint-Sulpice-sur-Leze',
    'Longages',
    'Bertrange',
    'Longwy',
    'Rombas',
    'Rosselange',
    'Sierck-les-Bains',
    'LAiguillon-sur-Mer',
    'Rechesy',
    'Duingt',
    'Cons-Sainte-Colombe',
    'Vallauris',
    "Saint-Laurent-d'Aigouze",
    'Saint-Hippolyte-du-Fort',
    'Salindres',
    'Saint-Ambroix',
    'Besseges',
    'Miremont',
    'Daux',
    'Rue',
    'Romeries',
    'Contes',
    'La Mulatiere',
    'Talant',
    'Fontaine-les-Dijon',
    'Vaux-les-Saint-Claude',
    'Colombe',
    'La Frette',
    'Saint-Hilaire-de-la-Cote',
    "Montreuil-l'Argille",
    'Chamblac',
    'Aunay-sur-Odon',
    'Torreilles',
    'Livet-et-Gavet',
    'La Paute',
    'Le Bourg',
    'Pont-de-Vaux',
    'Salernes',
    'Coulounieix-Chamiers',
    'Le Grau-du-Roi',
    'Sathonay-Camp',
    'Les Maures',
    'Mirebeau-sur-Beze',
    'Grignan',
    'Messy',
    'Massieux',
    'Le Barcares',
    'Leucate',
    'Sainte-Marie',
    'Ecourt-Saint-Quentin',
    'Bormes-les-Mimosas',
    'Passy',
    'Chailles',
    'Acquigny',
    'Heudreville-sur-Eure',
    'Fontaine-Heudebourg',
    'Hondouville',
    'Brosville',
    'Bernay',
    'Thiberville',
    'Battenheim',
    'Sentheim',
    'Gattieres',
    'Sainte-Sigolene',
    'Fraisses',
    'Feillens',
    'Messein',
    'Azy-sur-Marne',
    'Lanrodec',
    'Bringolo',
    'Etriche',
    'Rochefort-sur-Loire',
    'Chalonnes-sur-Loire',
    'Clapiers',
    'La Selve',
    'Langoat',
    'Serrieres',
    'Staffelfelden',
    'Labouheyre',
    'Monteils',
    'Mimizan',
    'Chavanoz',
    'Charvieu-Chavagneux',
    "Champagne-au-Mont-d'Or",
    'Rugles',
    'Valras-Plage',
    'Montmirat',
    'Dax',
    'Allouagne',
    'Saint-Sebastien-de-Morsent',
    'Realmont',
    'Fleurbaix',
    'Gundershoffen',
    'Aurignac',
    'Palaminy',
    'Saint-Julien-le-Vendomois',
    'Moirans-en-Montagne',
    'Saint-Lupicin',
    'Sorquainville',
    'Barentin',
    'Le Passage',
    'Beaumont-le-Roger',
    'Etainhus',
    'Saint-Pierre-le-Moutier',
    'Appeville-Annebault',
    'Tarare',
    'Saint-Quentin-la-Poterie',
    'Malaucene',
    'Locmiquelic',
    'Sauteyrargues',
    'Saint-Genis-Pouilly',
    'Fareins',
    'Athies-sous-Laon',
    'Fresnoy-le-Grand',
    'Bohain-en-Vermandois',
    'Effry',
    'La Capelle',
    'Eperlecques',
    'Landrethun-les-Ardres',
    'Ecouflant',
    'Retiers',
    'Jouques',
    'Alleins',
    'Thegra',
    'Boutigny-sur-Essonne',
    'Mourmelon-le-Grand',
    'Joigny',
    'Peyruis',
    'Mayet',
    'Boulazac',
    'Orchaise',
    'Bignan',
    'Ambon',
    'Bienvillers-au-Bois',
    'Briollay',
    'Conde-en-Brie',
    'Hagenthal-le-Haut',
    'Thoiry',
    'Conques-sur-Orbiel',
    'Lescout',
    'Malaunay',
    'Trogues',
    'Sainte-Maure-de-Touraine',
    'Abilly',
    'Buxeuil',
    'Villebarou',
    'Saint-Martin-du-Mont',
    'Grisolles',
    'Chancenay',
    'Les Forges',
    'Commercy',
    'Menil-la-Horgne',
    'Ugny-sur-Meuse',
    'Vaucouleurs',
    'Chatillon',
    'Laurens',
    'Pouzolles',
    'Neffies',
    'Rocheserviere',
    'Thure',
    'Roches-Premarie-Andille',
    'Saint-Maurice-la-Clouere',
    'Calleville',
    'Lherm',
    'Rieux-de-Pelleport',
    'Barbentane',
    'Villars-en-Pons',
    'Gemozac',
    'Carignan',
    'Mouzon',
    'Issancourt-et-Rumel',
    'Donchery',
    'Aÿ',
    'Grauves',
    'Ully-Saint-Georges',
    'Vaumoise',
    'Saint-Hilaire-de-Loulay',
    'Dommartin',
    'Valdahon',
    'La Cluse-et-Mijoux',
    'Metabief',
    'Jougne',
    'Thulay',
    'Sort-en-Chalosse',
    'Gamarde-les-Bains',
    'Vayrac',
    'Payrac',
    'Gourdon',
    'Souillac',
    'Robion',
    'Serent',
    'Saint-Martin-de-Seignanx',
    'Dremil-Lafage',
    'Saint-Paul-Trois-Chateaux',
    'Cavalaire-sur-Mer',
    'Saix',
    'Hérouville-Saint-Clair',
    'Lavernose-Lacasse',
    'Auvers-sur-Oise',
    'Lorgues',
    'Excenevex',
    'Anthy-sur-Leman',
    'Saint-Paul-en-Chablais',
    'Neuvecelle',
    'Longessaigne',
    'Biganos',
    'Couiza',
    'Beauregard-Baret',
    'Canejan',
    'Sierville',
    'Vatimesnil',
    'Breteuil',
    'Couville',
    'Heudebouville',
    'Saint-Samson-sur-Rance',
    'Grand Champ',
    'Aubigny-en-Plaine',
    'Fargues-Saint-Hilaire',
    'Orgelet',
    'Clairvaux-les-Lacs',
    'Senozan',
    'Truyes',
    'Esvres',
    'Marzan',
    'Laiz',
    'Lignerolles',
    'Voujeaucourt',
    'Mamirolle',
    'Villeron',
    'Saint-Parize-le-Chatel',
    'Mondeville',
    'Salins-les-Bains',
    'Obernai',
    'Lisses',
    'Figanieres',
    'Le Plan-de-la-Tour',
    'Fontaines',
    'La Membrolle-sur-Choisille',
    'Saint-Antoine-du-Rocher',
    'Ardentes',
    'Frontenex',
    'Attignat-Oncin',
    'Dortan',
    'Briord',
    'Sochaux',
    'Baume-les-Dames',
    'Gueux',
    'Albens',
    'Yport',
    'Beuzeville',
    'Hussigny-Godbrange',
    'Neuville-les-Dieppe',
    'Nouvelle-Eglise',
    'Wierre-Effroy',
    'Malestroit',
    'Primarette',
    'Pouilly-les-Nonains',
    'Mallemort',
    "Vallon-Pont-d'Arc",
    'Triors',
    'Mallemoisson',
    'Varennes-sur-Loire',
    'Levens',
    'Ribaute-les-Tavernes',
    'Sourcieux-les-Mines',
    'Saint-Pierre-la-Palud',
    'Bessenay',
    'Andilly',
    'Penol',
    'Grussenheim',
    'Dun-sur-Auron',
    'Niederhausbergen',
    'Longpre-les-Corps-Saints',
    'Clichy-sous-Bois',
    'Pommevic',
    'Gaas',
    'Langon',
    'Villefranche-sur-Cher',
    'Sedan',
    'Aigues-Mortes',
    "Brain-sur-l'Authion",
    'La Bohalle',
    'Le May-sur-Evre',
    'La Jubaudiere',
    'Champcueil',
    'Courances',
    'Mauchamps',
    'Goux-les-Dambelin',
    'Clerval',
    'Geney',
    'LIsle-sur-le-Doubs',
    'Quingey',
    "Savigne-l'Eveque",
    'Marseilles-les-Aubigny',
    'Le Perray-en-Yvelines',
    'Montaigut-sur-Save',
    "L'Argentiere-la-Bessee",
    'Puy-Saint-Andre',
    'Jonquieres',
    'Comps',
    'Millau',
    'Neuilly-Saint-Front',
    'Escoutoux',
    'Saint-Dizier',
    'Saint-Jean-Bonnefonds',
    'Bons-en-Chablais',
    'Le Rove',
    'Saint-Zacharie',
    'Gommegnies',
    'Meynes',
    'Avensan',
    'Parthenay-de-Bretagne',
    'Haspres',
    'Saint-Eliph',
    'Saint-Genies-des-Mourgues',
    'Sainte-Lucie-de-Tallano',
    'Saint-Maime',
    'Castries',
    'Vemars',
    'Missy-sur-Aisne',
    'Venizel',
    'Blangy-sur-Bresle',
    'Neufchatel-en-Bray',
    'Offranville',
    'Bressols',
    'Freneuse',
    'Plombieres-les-Dijon',
    'Germainville',
    'Avanne-Aveney',
    'Pontarlier',
    'Arcon',
    'Aniane',
    'Dieulouard',
    'Bruley',
    'Saint-Gilles',
    'Costaros',
    'Vals-pres-le-Puy',
    'Le Grand Village',
    'Labenne',
    'Bourg-Achard',
    'Plouvien',
    'Saint-Ouen-du-Tilleul',
    'Bosrobert',
    'Kunheim',
    'Sartène',
    'Propriano',
    'Goulet',
    'Porticcio',
    'Servon',
    'Thenelles',
    'Ansauvillers',
    'Salouel',
    'Noordpeene',
    'Marsangy',
    'Crochte',
    'Cappelle-Brouck',
    'Luray',
    'Vernouillet',
    'Sainte-Colombe-sur-Guette',
    "La Digne-d'Amont",
    'Margaux',
    'Rabastens-de-Bigorre',
    'Coufouleux',
    'Saint-Savin',
    'Montmorillon',
    'Origny-en-Thierache',
    'Rantigny',
    'Vieillevigne',
    'Argentan',
    'Gace',
    'Rai',
    "L'Aigle",
    'Faucompierre',
    'Seine-Port',
    'Cagny',
    'Guiclan',
    'Saint-Bres',
    'Village-Neuf',
    'Saint-Louis',
    'Embrun',
    'Sibiril',
    'Vignoc',
    'Auboue',
    'Homecourt',
    'Vinca',
    'Clerieux',
    'Witry-les-Reims',
    'Aincourt',
    'Meulan-en-Yvelines',
    'Montalet-le-Bois',
    'Oinville-sur-Montcient',
    'Noyers-Bocage',
    'Châtillon-sur-Seine',
    'Malain',
    'La Rochette',
    'Olonzac',
    'Pepieux',
    'Haironville',
    'Calvisson',
    'Milhaud',
    'Vestric-et-Candiac',
    'Vauvert',
    'Croisilles',
    'Samatan',
    'Bapaume',
    'Bucquoy',
    'Lagardelle-sur-Leze',
    'Metzeral',
    'Fraize',
    'Bruyères',
    'Gray',
    'Brussey',
    'Villers-le-Lac',
    'Ansac-sur-Vienne',
    'Damparis',
    'Saint-Paul-en-Foret',
    'Maisnil',
    'Oresmaux',
    'Moreuil',
    'Hombleux',
    'Neuf-Brisach',
    'Tournan-en-Brie',
    'Livry-sur-Seine',
    'Doullens',
    'Palau-del-Vidre',
    'Arpaillargues-et-Aureillac',
    'Trebes',
    'Arc-sur-Tille',
    'Touville',
    'Iville',
    'Riorges',
    'Varois-et-Chaignot',
    'Etevaux',
    'Binges',
    'Amfreville-la-Mi-Voie',
    'Belbeuf',
    'Avelin',
    'Carmaux',
    'Le Garric',
    'Javene',
    'Cobrieux',
    'Trausse',
    'Bury',
    'San-Nicolao',
    'Folelli',
    'Prunelli-di-Fiumorbo',
    'Ghisonaccia',
    'Fondettes',
    'Maureillas-las-Illas',
    'Brives-Charensac',
    'Blavozy',
    'Porcelette',
    'Sainte-Florine',
    'Saint-Tropez',
    'Riedseltz',
    'Ecault',
    'Saint-Etienne-au-Mont',
    'Selongey',
    'Semblancay',
    'Rinxent',
    'Tilques',
    'Serques',
    'Thiant',
    'Bousies',
    'Le Cateau-Cambresis',
    'Jenlain',
    'Mondeville',
    'Coltainville',
    'Saint-Prest',
    'Saone',
    'Roches-les-Blamont',
    'Saint-Emilion',
    'Aniche',
    'Ecaillon',
    'Verlinghem',
    'Watten',
    'Marconnelle',
    'Moragne',
    'Nesle',
    'Chambry',
    'Goncelin',
    'Saint-Pierre-les-Elbeuf',
    'Saint-Vincent-de-Paul',
    'Lignan-sur-Orb',
    'Lieuran-les-Beziers',
    'Bassan',
    'Oudon',
    'Saffre',
    'Voissant',
    'Samois-sur-Seine',
    'Coublevie',
    'Haveluy',
    'Benfeld',
    'Bar-sur-Seine',
    'Varennes-sur-Allier',
    'Bricquebec',
    'Alenya',
    'Magescq',
    'Saint-Sever',
    'Bonchamp-les-Laval',
    'Chevru',
    'Cauge',
    'Bellegarde',
    'Lorris',
    'Pourrain',
    'Brienon-sur-Armancon',
    'Izeron',
    'Lembach',
    'Saint-Lambert-du-Lattay',
    'Brains',
    'Venarey-les-Laumes',
    'Villars-et-Villenotte',
    'Geste',
    'Fonsorbes',
    'Beyrie-en-Bearn',
    'Aurillac',
    'Sainte-Livrade-sur-Lot',
    'Deulemont',
    'Lachapelle-sous-Rougemont',
    'Locoal-Mendon',
    'Babeau-Bouldoux',
    'Ducey',
    'Noyers-sur-Cher',
    'Mareuil-sur-Cher',
    'Hauteville-sur-Mer',
    'Benouville',
    'Font-Romeu-Odeillo-Via',
    'Roisel',
    'Quarouble',
    'Onnaing',
    'Rosoy-en-Multien',
    'La Sentinelle',
    'Epone',
    'Saint-Coulitz',
    'Villerville',
    "L'Huisserie",
    'Albefeuille-Lagarde',
    'Fontaine-Notre-Dame',
    'Niergnies',
    'Neuville-Saint-Remy',
    'Saint-Cyprien',
    'Saint-Nazaire',
    'Vilallonga dels Monts',
    'Manduel',
    'Tauriac',
    'Andilly',
    'Maxilly-sur-Saone',
    'Sorigny',
    'Azay-sur-Cher',
    'Bourgbarre',
    'Neufchateau',
    'Saint-Christophe-sur-Roc',
    'Montebourg',
    'Ventiseri',
    'Saint-Vaast-la-Hougue',
    'Fontaine-Notre-Dame',
    'Pontaubault',
    'Saint-Gaultier',
    'Pitres',
    'Alizay',
    'Romilly-sur-Andelle',
    'Amfreville-sous-les-Monts',
    'Charleval',
    'Les Andelys',
    'Lyons-la-Forêt',
    'Saint-Pierre-du-Vauvray',
    'Ande',
    'Vic-sur-Aisne',
    'Authevernes',
    'Bueil',
    'Noveant-sur-Moselle',
    'La Garenne',
    'Villard-de-Lans',
    'Saint-Martin-de-Re',
    'Ars-en-Re',
    'Tourly',
    'Graveson',
    'Anjoutey',
    'Sainte-Helene',
    'Saint-Privat-la-Montagne',
    'Amanvillers',
    'Evrecy',
    'Amaye-sur-Orne',
    'Esquay-Notre-Dame',
    'Brouay',
    'Exireuil',
    'Les Aubiers',
    'Mirebeau',
    'Lencloitre',
    'Le Cailar',
    'Aimargues',
    'Codognan',
    'Vergeze',
    'Mus',
    'Bertry',
    'Saint-Denis-de-Cabanne',
    'Charlieu',
    'Blere',
    'Meru',
    'Les Rosiers',
    'Saint-Remy',
    'Marsannay-la-Côte',
    'Perrigny-les-Dijon',
    'Poisat',
    'Villefranche-sur-Mer',
    'Beuvrages',
    'Feyzin',
    'Lasalle',
    'Montferrat',
    'Evans',
    'Etuz',
    'La Roquebrussanne',
    'Lannemezan',
    'La Salvetat-Saint-Gilles',
    'La Salvetat-Lauragais',
    'Fontenilles',
    'Restinclieres',
    'Eymet',
    'Seilhac',
    'Muizon',
    'Villemandeur',
    'Malataverne',
    'Espeluche',
    'Plesse',
    'Calas',
    'Saint-Paul-les-Durance',
    'Trans-en-Provence',
    'Meyenheim',
    'Saint-Mars-la-Jaille',
    'Saint-Gereon',
    'Canly',
    'Jaux',
    'Rupt',
    'Casson',
    'Tracy-le-Val',
    'Attichy',
    'Ermenonville',
    'Geudertheim',
    'Noailles',
    'Auteuil',
    'Loos-en-Gohelle',
    'Tigery',
    'Etiolles',
    'Juvisy-sur-Orge',
    'Villers-Saint-Paul',
    'Saint-Just-en-Chaussee',
    'Breuil-le-Vert',
    'Châteaudun',
    'Horbourg-Wihr',
    'Soisy-sous-Montmorency',
    'Chaponnay',
    'Marennes',
    'Heyrieux',
    'Villeneuve-Tolosane',
    'Saint-Julien-du-Puy',
    'Saint-Pal-de-Mons',
    'La Seauve-sur-Semene',
    'Houlgate',
    'Villers-sur-Mer',
    'Marchiennes',
    'Vred',
    'Warlaing',
    'Donneville',
    'Bruguieres',
    'Gratentour',
    'Saint-Jory',
    'Fronton',
    "Castelnau-d'Estretefonds",
    'Mirepoix-sur-Tarn',
    'Franois',
    'Pirey',
    'Serre-les-Sapins',
    'Mazerolles-le-Salin',
    'Peronne-en-Melantois',
    'Bornel',
    'Saint-Leger-en-Bray',
    'Chaumont-en-Vexin',
    'Trie-Chateau',
    'Paillart',
    'Breteuil',
    'Meuse',
    'Longfosse',
    'Ardoix',
    'Montferrand-le-Chateau',
    'Boussieres',
    'Thoraise',
    'Saint-Jean-Brevelay',
    'Aubin',
    'Pfaffenhoffen',
    'Niedermodern',
    'Obermodern-Zutzendorf',
    'Zutzendorf',
    'Saint-Cyr-en-Val',
    'Oberbronn',
    'Goersdorf',
    'Langensoultzbach',
    'Morsbronn-les-Bains',
    'Louverne',
    'Mauriac',
    'Nery',
    'Bethisy-Saint-Pierre',
    'Holtzwihr',
    'Seilh',
    'Trignac',
    'Uxem',
    'Sain-Bel',
    'Aubergenville',
    'Mareuil-sur-Ourcq',
    'Barbechat',
    'Briare',
    'Ambutrix',
    'Mery-sur-Oise',
    'Seppois-le-Bas',
    'Lognes',
    'Raismes',
    'Saulnot',
    'Bouresse',
    'Granges-le-Bourg',
    'Villersexel',
    'Vesoul',
    'Vaivre-et-Montoille',
    'Luxeuil-les-Bains',
    'Aillevillers-et-Lyaumont',
    'La Bassee',
    'Salome',
    'Epouville',
    'Arnas',
    'Equihen-Plage',
    'Cervens',
    'Mortefontaine',
    'Saint-Pantaleon-de-Larche',
    'Chantrans',
    'Levier',
    'Gemenos',
    'Doubs',
    'Le Vigan',
    'Aveze',
    'Ville-du-Pont',
    'Sarlat-la-Canéda',
    'Gilley',
    'Schwenheim',
    'Les Fins',
    'Flangebouche',
    'Caissargues',
    'Grosbliederstroff',
    'Hambach',
    'Sarralbe',
    'Niderviller',
    'Saulxures-sur-Moselotte',
    'Rohrbach-les-Bitche',
    'Bitche',
    'Domene',
    'La Destrousse',
    'La Bouilladisse',
    'Vermelles',
    'Haisnes',
    'Cambrin',
    'Hoerdt',
    'Gorges',
    'Teurtheville-Hague',
    'Novalaise',
    'Bois-le-Roi',
    'Chartrettes',
    'Gonfaron',
    'Baraqueville',
    'Moyrazes',
    'Lescure',
    'Saint-Didier-sous-Riverie',
    'Perigneux',
    'Saint-Christo-en-Jarez',
    'Estivareilles',
    'La Tourette',
    'Saint-Julien-le-Roux',
    'Saint-Saturnin-les-Avignon',
    'Saint-Vallier-de-Thiey',
    'Neuville-sur-Escaut',
    'Mesnil-Saint-Georges',
    'Villeneuve-de-Berg',
    'Huchenneville',
    'Courtry',
    'Hanches',
    'Bretignolles-sur-Mer',
    'Virieu-le-Grand',
    'Fislis',
    'La Ferte-Milon',
    'Moernach',
    'Canouville',
    'Gisors',
    'Vieux-Ferrette',
    'Wittisheim',
    'Dambach-la-Ville',
    'Cucuron',
    'Cadenet',
    'Lugrin',
    'Oberhaslach',
    'Niederhaslach',
    'Sarrians',
    'Sauverny',
    'Le Cellier',
    'Beine-Nauroy',
    'Notre-Dame-de-Boisset',
    'Authie',
    'Rots',
    'Caromb',
    'Bédoin',
    'Melrand',
    'Pannes',
    'Groisy',
    'Dordives',
    'Nargis',
    'Fontenay-sur-Loing',
    'Villecroze',
    'Ferrieres-en-Gatinais',
    'Courtenay',
    'Beaune-la-Rolande',
    'Montady',
    'Chevilly',
    'Rochecorbon',
    'Roville-aux-Chenes',
    'Villacourt',
    'Saint-Branchs',
    'Le Luc',
    'Chevrieres',
    'Mont',
    'Dieffenbach-au-Val',
    'Roumare',
    'Port-Louis',
    'Ectot-les-Baons',
    'Neville',
    'Laversines',
    'Saint-Romain-le-Puy',
    'Avallon',
    'Blotzheim',
    'Steige',
    'Maisonsgoutte',
    'Port-Saint-Pere',
    'Sainte-Pazanne',
    'Barneville-Carteret',
    'Granges',
    'Pougues-les-Eaux',
    'Revigny-sur-Ornain',
    'Villers-aux-Vents',
    'Arthon-en-Retz',
    'Chemere',
    'Longeville-les-Metz',
    'Ligny-en-Barrois',
    'Givrauval',
    'Faulquemont',
    'Crehange',
    'Ensues-la-Redonne',
    "Sainte-Foy-l'Argentiere",
    'Pihem',
    'Helfaut',
    'Villers-les-Nancy',
    'Heudicourt-sous-les-Cotes',
    'Bassussarry',
    'Bray-Dunes',
    'Momeres',
    'Montignac-de-Lauzun',
    'Conches-en-Ouche',
    'Saint-Elier',
    'Boves',
    'Bilieu',
    'Magagnosc',
    'Le Bar-sur-Loup',
    'Tourrettes-sur-Loup',
    'Sains-en-Amienois',
    'Wasselonne',
    'Ouistreham',
    'Lion-sur-Mer',
    'Hermanville-sur-Mer',
    'Flixecourt',
    'Mouflers',
    'Saint-Ouen',
    'Halloy-les-Pernois',
    'Gezaincourt',
    'Rans',
    'Comines',
    'Oye-Plage',
    'Artigueloutan',
    'Boust',
    'Etoile-sur-Rhone',
    'Vendenheim',
    'Saint-Cyr-sur-Morin',
    'Saint-Denis-les-Rebais',
    'Nazelles-Negron',
    'Saint-Hilaire-du-Harcouet',
    'Saverdun',
    'Pujaudran',
    'Saulxures-les-Nancy',
    'Sospel',
    'La Trinite',
    'Mardyck',
    'Loon-Plage',
    "Pont-de-l'Isere",
    'Saint-Jean-de-Muzols',
    'Saillans',
    'Guilers',
    'Notre-Dame-de-Riez',
    'Givrand',
    'Soullans',
    'Forcalquier',
    'Les Mees',
    'Chateau-Arnoux-Saint-Auban',
    'Saint-Auban',
    'Laragne-Monteglin',
    'Tallard',
    'Saint-Lumier-en-Champagne',
    'Pleuven',
    'Saint-Paul-de-Vence',
    'Fismes',
    'Kutzenhausen',
    'Saint-Soupplets',
    'Seignelay',
    'Saint-Florentin',
    'Venizy',
    'Saint-Paul-les-Dax',
    'Samadet',
    'Saubion',
    'Capbreton',
    'Die',
    "Laval-d'Aix",
    'Valentigney',
    'Rumilly-en-Cambresis',
    'Jonzieux',
    'Roche-la-Moliere',
    'Langan',
    'Saint-Pierre-de-Chandieu',
    'Marignier',
    'Ayse',
    "Pontonx-sur-l'Adour",
    'Arveyres',
    'Saint-Germain-du-Puch',
    'Genissac',
    'Branne',
    'Espiet',
    'Saint-Quentin-de-Baron',
    'Cavignac',
    'Noves',
    'Saint-Yzan-de-Soudiac',
    'Pugnac',
    'Scherwiller',
    'Kintzheim',
    'Exincourt',
    'Lesneven',
    'Moussan',
    'Gruissan',
    'Saint-Cyr-sur-le-Rhone',
    'Saint-Romain-en-Gal',
    'Pont-de-Cheruy',
    'La Cote',
    'Fouillard',
    'Trouhans',
    'Esbarres',
    'Saint-Jean-de-Losne',
    'Seurre',
    'Verpillieres',
    'Champien',
    'Roye',
    'Matigny',
    'Gragnague',
    'Saint-Aubin-du-Cormier',
    'Noyal-sur-Vilaine',
    'Vaire',
    'Annecy-le-Vieux',
    'Etival-Clairefontaine',
    'Saint-Remy',
    'Raon-sur-Plaine',
    'Baccarat',
    'Rambervillers',
    'Maclas',
    'Bouray-sur-Juine',
    'Sully-sur-Loire',
    'Ouzouer-sur-Loire',
    'Tigy',
    'Guilly',
    'Pouxeux',
    'Remiremont',
    'Boigny-sur-Bionne',
    'Archettes',
    'Branoux-les-Taillades',
    'Reignier-Esery',
    'Vincey',
    'Nomexy',
    'Chavigny',
    'Bainville-sur-Madon',
    'Maron',
    'Charmes',
    'Chatel-sur-Moselle',
    'Lux',
    'Branches',
    'Diemoz',
    'Gundolsheim',
    'Appoigny',
    'Gemil',
    'Marcoussis',
    'Potigny',
    "Salles-d'Aude",
    'Serifontaine',
    'Carry-le-Rouet',
    'Halluin',
    'Prevessin-Moens',
    'Ferney-Voltaire',
    'Valleiry',
    'Ammerschwihr',
    'Mittelwihr',
    'Biesheim',
    'Illhaeusern',
    'Ribeauvillé',
    'Boissy-sous-Saint-Yon',
    'Allieres',
    'Remoulins',
    'Couloisy',
    'Pierrefonds',
    'Blincourt',
    'Estrees-Saint-Denis',
    'Rouvillers',
    'Le Lardin-Saint-Lazare',
    'Bas-en-Basset',
    'Le Coudray-Montceaux',
    'Betheny',
    'Puiseaux',
    'Malesherbes',
    'Orveau-Bellesauve',
    'Moneteau',
    'Altkirch',
    'Carspach',
    'Didenheim',
    'Ungersheim',
    'Heidwiller',
    'Waldighofen',
    'Roche-lez-Beaupre',
    'Thise',
    'Gilly-les-Citeaux',
    'Gevrey-Chambertin',
    'Drusenheim',
    'Marly-la-Ville',
    'Cosges',
    'Buigny-les-Gamaches',
    'Beauchamps',
    'Vals-les-Bains',
    'Bazancourt',
    'Warmeriville',
    'Pompey',
    'La Chapelle-Orthemale',
    'Quibou',
    'Balschwiller',
    'Charnecles',
    'Beaucroissant',
    'Guenange',
    'Montrequienne',
    'Trieux',
    'Vigy',
    'Aumetz',
    'Boulange',
    'Serrouville',
    'Mancieulles',
    'Tucquegnieux',
    'Briey',
    'Mont-Bonvillers',
    'Norroy-le-Sec',
    'Joudreville',
    'Mouliets-et-Villemartin',
    'Giraumont',
    'Conflans-en-Jarnisy',
    'Lezennes',
    'Le Grand-Quevilly',
    'Chatignonville',
    'Corbreuse',
    'Baron',
    'Chasse-sur-Rhone',
    'Ornans',
    'Gemeaux',
    'Payrin-Augmontel',
    'Aiguefonde',
    'Lagarrigue',
    'Artannes-sur-Indre',
    'Seloncourt',
    'Offemont',
    'Salbert',
    'Notre-Dame-de-Livoye',
    'Monteynard',
    'Ecole-Valentin',
    'Miserey-Salines',
    'Villiers-sur-Orge',
    'Arbent',
    'Westhoffen',
    'Boulieu-les-Annonay',
    'Champniers',
    'Plailly',
    'Saint-Prix',
    'Andilly',
    'Bavent',
    'Sarre-Union',
    'Arche',
    'Bosc-Guerard-Saint-Adrien',
    'Chambourcy',
    'Longuyon',
    'Haucourt-Moulaine',
    'Mexy',
    'Audun-le-Tiche',
    'Errouville',
    'Tiercelet',
    'Vetraz-Monthoux',
    'Graveron-Semerville',
    'Podensac',
    'Le Chatelet-en-Brie',
    'Léognan',
    'Gordes',
    'Provin',
    'Rott',
    'Moret-sur-Loing',
    'Saint-Mammes',
    'Pontault-Combault',
    'Margon',
    'Ecouen',
    'Lourenties',
    'Saint-Saens',
    'Barbaste',
    'Picquigny',
    'Castillon-Massas',
    'Montech',
    'Renaison',
    'Le Gua',
    'Champforgeuil',
    'Contrexeville',
    'Maing',
    'Le Quesnoy',
    'Louvignies-Quesnoy',
    'Labourse',
    'Secondigny',
    'Laize',
    'Auchel',
    'Bressuire',
    'Verdun-sur-Garonne',
    'Trouy',
    'Sansais',
    'Neuville-aux-Bois',
    'Pierrelaye',
    'Saint-Florent-sur-Cher',
    "Saint-Martin-d'Auxigny",
    'Henrichemont',
    'Sancerre',
    'Langoiran',
    'La Chapelle-sur-Chezy',
    'Chatillon-sur-Seiche',
    'Migne-Auxances',
    'Foug',
    'Chambord',
    'Bracieux',
    'Belleville',
    'Bleigny-le-Carreau',
    'Mousson',
    'Atton',
    'Vandieres',
    'Pagny-sur-Moselle',
    'Cour-Cheverny',
    'Barbazan-Debat',
    'Rivedoux-Plage',
    'Venoy',
    'Weitbruch',
    'Kurtzenhouse',
    'Saint-Martin-le-Beau',
    'Saint-Laurent-du-Pont',
    'Betschdorf',
    'Guipronvel',
    'Milizac',
    'Krautergersheim',
    'Saint-Gely-du-Fesc',
    'Treuzy-Levelay',
    'Saint-Pierre-Montlimart',
    'Eckbolsheim',
    'Saint-Quentin-Fallavier',
    'Ruy',
    'Saint-Alban-de-Roche',
    'Sermerieu',
    'Le Bouchage',
    'Corbelin',
    'Athee',
    'Dolomieu',
    'Eloyes',
    'Cessieu',
    'Guerting',
    "L'Hopital",
    'Ippling',
    'Bozouls',
    'Espalion',
    'Cransac',
    'Laissac',
    'Nointel',
    'Bernes-sur-Oise',
    'Sourdun',
    'Saint-Brice',
    'Lelling',
    'Messia-sur-Sorne',
    'Vallangoujard',
    'Grisy-Suisnes',
    'Santeny',
    'OEting',
    'Arnouville',
    'Saint-Maixant',
    "Espira-de-l'Agly",
    'Tonnerre',
    'Brison-Saint-Innocent',
    'Mouxy',
    'Ravenel',
    'Gruffy',
    'Longvic',
    'Gambsheim',
    'Seronville',
    'Aureilhan',
    'Nesles-la-Vallee',
    'Vic-la-Gardiole',
    "Blainville-sur-l'Eau",
    'Blenod-les-Toul',
    'Azay-le-Rideau',
    'Cerelles',
    'Rittershoffen',
    'Hohwiller',
    'Morigny-Champigny',
    'Coulon',
    'Saint-Savin',
    'Les Eparres',
    'Nivolas-Vermelle',
    'Neufmanil',
    'Aiglemont',
    'Itteville',
    'Bezannes',
    'Capdenac-Gare',
    'Ecquevilly',
    'Saint-Christol-les-Ales',
    'Angerville-la-Martel',
    'Valmont',
    'Trept',
    'Cremieu',
    'Panossas',
    'Chamagnieu',
    'Wattwiller',
    'Bievres',
    'Roderen',
    'Sanvignes-les-Mines',
    'Etricourt-Manancourt',
    'Saint-Macaire',
    'Toulenne',
    'Saint-Cheron-du-Chemin',
    'Janvry',
    'Briis-sous-Forges',
    'Saint-Jacques-de-la-Lande',
    'Villiers-Adam',
    'Epiais-Rhus',
    'Remecourt',
    'Marines',
    'Gallardon',
    'Magne',
    'Guipry',
    'Gannat',
    'Mozac',
    'Rupt-sur-Moselle',
    'Neris-les-Bains',
    'Ramonchamp',
    'Chamblet',
    'Cabestany',
    'La Buissiere',
    'Gosnay',
    'Forges-les-Bains',
    'Berviller-en-Moselle',
    'Lizy-sur-Ourcq',
    'Varreddes',
    'Belleville',
    'Montceaux',
    'Rostrenen',
    'Hangest-en-Santerre',
    'Touquin',
    'Rozay-en-Brie',
    'Courpalay',
    'Malling',
    'Lambersart',
    'Wingen-sur-Moder',
    'Zittersheim',
    'Verson',
    'Tourville-sur-Odon',
    'Cheux',
    'Oberhergheim',
    'Lexy',
    'Crest',
    'Garat',
    'Balzac',
    'Escaudoeuvres',
    "Thun-l'Eveque",
    'Reguisheim',
    'Burnhaupt-le-Haut',
    'Coutiches',
    'Port-sur-Saone',
    'Caille',
    'Chateauneuf-le-Rouge',
    'Mehun-sur-Yevre',
    'Longeville-les-Saint-Avold',
    'La Fare-en-Champsaur',
    'Lougres',
    'Bavans',
    'Lailly-en-Val',
    'Noidans-les-Vesoul',
    'Bénouville',
    'Sardieu',
    'Wangen',
    'Saint-Simeon-de-Bressieux',
    'Montois-la-Montagne',
    'Theize',
    'Morhange',
    'Baronville',
    'Saint-Drezery',
    'La Gaude',
    'Saint-Jeannet',
    'Vinzelles',
    'Creches-sur-Saone',
    'Saint-Evarzec',
    'Monts',
    'Lanton',
    'Cinq-Mars-la-Pile',
    'Luynes',
    'Chinon',
    'Beaumont-en-Veron',
    'Saint-Aubin-sur-Mer',
    'Issoudun',
    'Les Bordes',
    'Seyssins',
    'Déols',
    'Argenton-sur-Creuse',
    'Le Tignet',
    'Chalonvillars',
    'Thierville-sur-Meuse',
    'Cruet',
    'Rotherens',
    'Beauchamp',
    'Vert-le-Petit',
    'Le Plessis-Pate',
    'Leuville-sur-Orge',
    'Haulchin',
    'Veauche',
    'Anse',
    'Reyrieux',
    'Argeville',
    'Pussay',
    'Amillis',
    'Communay',
    'Chasne-sur-Illet',
    'Lasson',
    'Thaon',
    'Beauval',
    'Echenoz-la-Meline',
    'Bourguebus',
    'Sainte-Catherine',
    'Champtercier',
    'Florensac',
    'Beaucaire',
    'Feuguerolles-sur-Seulles',
    'Malijai',
    'Saint-Andre-lez-Lille',
    'Tourlaville',
    'Champhol',
    'Luce',
    'Saint-Marcel-Bel-Accueil',
    'Erome',
    'Cattenom',
    'Vallier',
    'Custines',
    'Champigneulles',
    'Bubry',
    'Ailly-sur-Somme',
    'Villers-Bretonneux',
    'Reaumont',
    'La Ville aux Chiens',
    'Pechabou',
    'Montgiscard',
    'Villenouvelle',
    'La Barre-de-Monts',
    'Dombasle-sur-Meurthe',
    'Montbazin',
    'Laverune',
    'Combronde',
    'Ennezat',
    'Beauregard-Vendon',
    'Prompsat',
    'Argenton-les-Vallees',
    'Saint-Aubin-du-Plain',
    'Aiffres',
    'Château de Loches',
    'Saint-Jean-Saint-Germain',
    'Varennes-Jarcy',
    'Thezan-les-Beziers',
    'Murviel-les-Montpellier',
    "Saint-Martin-d'Uriage",
    'Kaltenhouse',
    'Le Boulay-Morin',
    'Normanville',
    'Roques',
    'Bédarieux',
    'Herepian',
    'Servas',
    'Cournonterral',
    'Gigean',
    'Saint-Jean-de-Vedas',
    'Dachstein',
    'Mathieu',
    'Saint-Junien',
    'Avressieux',
    'Gauriac',
    'Aoste',
    'Saint-Genix-sur-Guiers',
    'Domessin',
    'Oradour-sur-Glane',
    'Compreignac',
    'Saint-Leonard-de-Noblat',
    'Saint-Jouvent',
    'Couvron-et-Aumencourt',
    'Troarn',
    'Festieux',
    'Heutregiville',
    'Aguilcourt',
    'Guignicourt',
    'Attigny',
    'Joigny-sur-Meuse',
    'Revin',
    'Rocroi',
    'Saint-Parres-les-Vaudes',
    'Treves',
    'Montseveroux',
    'Chaufour-Notre-Dame',
    'Noisy-sur-Ecole',
    'Sevrier',
    "Saint-Andre-de-l'Eure",
    'Garencieres',
    'Mousseaux-Neuville',
    'Sainte-Tulle',
    'Montfuron',
    'Sivry-sur-Meuse',
    'Brieulles-sur-Meuse',
    'Stenay',
    'Cherisy',
    'Mercy-le-Bas',
    'Bouligny',
    'Gauriaguet',
    'Peltre',
    'Talange',
    'Maizeroy',
    'Apach',
    'Rettel',
    'Aigueblanche',
    'Metzeresche',
    'Augny',
    'Filstroff',
    'Laquenexy',
    'Courcelles-sur-Nied',
    'Boulay-Moselle',
    'Volmerange-les-Boulay',
    "Illiers-l'Eveque",
    'Courdemanche',
    'Sorel-Moussel',
    'Ezy-sur-Eure',
    'Saint-Remy-sur-Avre',
    'Saucats',
    'Eterville',
    'Binic',
    'Garennes-sur-Eure',
    'Etables-sur-Mer',
    'Paimpol',
    'Kerfot',
    'Pomerols',
    'Pacy-sur-Eure',
    'Villiers-en-Desoeuvre',
    'Pinet',
    'Menville',
    'Pierrevert',
    'Verneuil-sur-Avre',
    'Menucourt',
    'Boisemont',
    'Saint-Ouen-en-Brie',
    'Villeneuve-le-Comte',
    'Mathay',
    'Mandeure',
    'Saint-Omer-en-Chaussee',
    'Truchtersheim',
    'Brumath',
    'Feldkirch',
    'Durmenach',
    'Coray',
    'Goven',
    'Arcay',
    'Orval',
    'Courcelles-sur-Seine',
    'Bois-Guillaume',
    'Martinvast',
    'Brehal',
    'Isneauville',
    'Corps-Nuds',
    'Vendeuvre-du-Poitou',
    'Valmont',
    'Macheren',
    'Pont-de-Roide',
    'Hyemondans',
    'Bourseville',
    'Quincy-sous-Senart',
    'Brancourt-en-Laonnois',
    'Cheval-Blanc',
    'Montagny-les-Lanches',
    'Nogent-le-Roi',
    'La Verriere',
    'La Turballe',
    'Hannogne-Saint-Martin',
    'Sainte-Catherine',
    'Dainville',
    'Haybes',
    'Fumay',
    'Cuincy',
    'Essert',
    'Chocques',
    'Maurecourt',
    'Seyssinet',
    'Puisserguier',
    'Vitry-en-Artois',
    'Villenave',
    'Breuilpont',
    'Thorigny-sur-Marne',
    'Saulxures',
    'La Ravoire',
    'Saint-Quentin',
    'Lapte',
    'Saint-Sauvant',
    'Feternes',
    'Montberon',
    'Tonneins',
    'Noueilles',
    'Saleilles',
    'Cessales',
    'Courcy',
    'La Londe-les-Maures',
    'Hoymille',
    'Tallende',
    'Geovreisset',
    'Brion',
    "Montfort-l'Amaury",
    'Rivery',
    'Gometz-le-Chatel',
    'Les Echelles',
    'Mens',
    'Noyarey',
    'Notre-Dame-du-Pre',
    'Puicheric',
    'Domancy',
    'Saint-Saturnin-les-Apt',
    'La Tronche',
    'Colmars',
    "Lagarde-d'Apt",
    'Argelers',
    'La Roche-des-Arnauds',
    'Luzarches',
    'Cheny',
    'Launaguet',
    'Radinghem-en-Weppes',
    'Optevoz',
    'Osny',
    'Saint-Didier-de-Formans',
    'Fontaines-Saint-Martin',
    'Amberieux',
    'Manziat',
    'La Chapelle-Saint-Luc',
    'Lamothe-Montravel',
    'La Brigue',
    'Bree',
    'Saint-Malo-de-Phily',
    'Grez-en-Bouere',
    "Saint-Pierre-d'Autils",
    'Cannes-Ecluse',
    'Vourey',
    'La Coquille',
    'Tercis-les-Bains',
    'Coulonges',
    'Proissans',
    'Mareuil',
    'Saint-Aubin-de-Baubigne',
    'La Laigne',
    'Chateauneuf-sur-Charente',
    'Oeyreluy',
    'Josselin',
    'La Taillee',
    'Saint-Georges-le-Flechard',
    "Cap-d'Ail",
    'Cleon',
    'Douvaine',
    'Varennes-en-Argonne',
    'Blanchefosse-et-Bay',
    'Villey-Saint-Etienne',
    'Mouans-Sartoux',
    'Sainghin-en-Weppes',
    'Caudebec-lès-Elbeuf',
    'Boiscommun',
    'Mons',
    'Villeneuve-les-Avignon',
    'Paray-le-Monial',
    'Privas',
    'Doudeville',
    'Bourg-le-Roi',
    'Pierre-la-Treiche',
    'Condat-sur-Vienne',
    'Beaupuy',
    'Pavie',
    'Les Essards',
    'Castets-en-Dorthe',
    'Hautmont',
    'Venerque',
    'Rions',
    'Goeulzin',
    'Chaux',
    'Harreville-les-Chanteurs',
    'Vernaison',
    'Pusignan',
    'Montmerle-sur-Saone',
    'Eloise',
    'Poisson',
    'Saint-Christophe-en-Brionnais',
    'Vitry-aux-Loges',
    'Bords',
    'Holtzheim',
    'Cosne-Cours-sur-Loire',
    'Neauphle-le-Chateau',
    'Pont-Remy',
    'Divion',
    'Casteljaloux',
    'Lasbordes',
    'Banyuls-dels-Aspres',
    'Villepinte',
    'Cardesse',
    'Haillicourt',
    'Lapugnoy',
    'Cormeilles',
    'Camphin-en-Carembault',
    'Notre-Dame-de-Sanilhac',
    'Sault-Brenaz',
    'Roussillon',
    'Camarsac',
    'Chaptelat',
    'Breval',
    'Bessancourt',
    'Saint-Vulbas',
    'Aveizieux',
    'Imphy',
    'Varzy',
    'Pouilly-sur-Loire',
    'Urzy',
    'Crozes-Hermitage',
    'La Machine',
    'Marzy',
    'Champagney',
    'Quievy',
    'Archingeay',
    'Auchy-les-Hesdin',
    'Itancourt',
    'Roppenheim',
    'Rountzenheim',
    'Esbly',
    'La Celle-sur-Seine',
    'Livron-sur-Drome',
    'Arques',
    'Jouy-en-Josas',
    'Villette',
    'Aressy',
    'Louvigny',
    'Rancogne',
    'Veigne',
    'Bon-Encontre',
    'Crepey',
    'Lesquin',
    'Wittes',
    'Anzin-Saint-Aubin',
    'Issou',
    'Villennes-sur-Seine',
    'Castelnau-de-Brassac',
    'Le Chatel',
    'Willems',
    'Arleux',
    'Pont-sur-Sambre',
    'Aubigny-au-Bac',
    'Herzeele',
    'Montigny-lès-Metz',
    'Lesdain',
    'Masserac',
    'Grillon',
    'Saint-Georges-sur-Loire',
    'Bourgneuf-en-Retz',
    'Vay',
    'Yebles',
    'Les Baux-de-Breteuil',
    'Pontgouin',
    'Montrichard',
    'Brinay',
    'Lus-la-Croix-Haute',
    'Nuaille-sur-Boutonne',
    'Salles-sur-Mer',
    'Vic-en-Bigorre',
    'La Frette-sur-Seine',
    'Bouvincourt-en-Vermandois',
    'Saint-Erme-Outre-et-Ramecourt',
    'Malafretaz',
    'Beaucouze',
    'Sathonay-Village',
    'Amberac',
    'Zimmersheim',
    'Guiscard',
    'Chassieu',
    'Vezenobres',
    'Bagard',
    "Aigrefeuille-d'Aunis",
    'Corneilla-la-Riviere',
    'Lezan',
    'Saint-Sauveur-Gouvernet',
    'Eschentzwiller',
    'Bellême',
    'Banvou',
    'Breviandes',
    'Cabourg',
    'La Batie',
    'Grainville-la-Teinturiere',
    'Pouillon',
    'Chatillon-sur-Cher',
    'Patay',
    'Saint-Jean-de-Braye',
    'Retschwiller',
    'Montgivray',
    'Mareil-sur-Mauldre',
    'Rousies',
    'Pont-Sainte-Marie',
    'Sailly-en-Ostrevent',
    'Meslay-le-Vidame',
    'Treon',
    'Hirsingue',
    'Galfingue',
    'Heimsbrunn',
    'Manspach',
    'Heimersdorf',
    'Sainte-Croix-en-Plaine',
    'Niederentzen',
    'Moosch',
    'Luemschwiller',
    'Bollwiller',
    'Appenwihr',
    'Herrlisheim-pres-Colmar',
    'Husseren-Wesserling',
    'Ranspach',
    'Mortzwiller',
    'Bischwihr',
    'Werentzhouse',
    'Kembs',
    'Hagenbach',
    'Wickerschwihr',
    'Liepvre',
    'Coignet',
    'Chateaubriand',
    'La Cheneviere',
    'Albert',
    'Clavel',
    'Bertin',
    'Chaumes',
    'Rivieres',
    'La Bretonne',
    'Bourgogne',
    'Montpezat-sous-Bauzon',
    'Bermicourt',
    'Bouleurs',
    'Grand-Charmont',
    'Revel-Tourdan',
    'Jayat',
    'Cailloux-sur-Fontaines',
    'Sandrans',
    'Taluyers',
    'Succieu',
    'Faramans',
    'Beaurepaire',
    'Saint-Georges-sur-Renon',
    'Treffort',
    'Saint-Genis-les-Ollieres',
    'Poncin',
    'Payzac',
    'Bourg-les-Valence',
    'Valencogne',
    'Saint-Marcellin-en-Forez',
    'Corveissiat',
    'Rambert',
    'Viry',
    'Grenay',
    'Donzy',
    'Sainte-Colombe-sur-Gand',
    'Saint-Vincent-de-Durfort',
    'Duniere',
    'Saint-Just-Chaleyssin',
    'Saint-Heand',
    'Neuville-les-Dames',
    'Joux',
    'Coligny',
    'Saint-Galmier',
    'Bouvesse-Quirieu',
    'Lorette',
    'Gleize',
    'Tartaras',
    'La Plaine',
    'Luzinay',
    'Rieux',
    'Saint-Just-le-Martel',
    'Candillargues',
    'Champlan',
    'Gresy-sur-Isere',
    'Bayon',
    'Lavans-Vuillafans',
    'Duranus',
    'Jully-sur-Sarce',
    'Montbellet',
    'Auneau',
    'Saint-Laurent-de-Mure',
    'Villars',
    'Ennery',
    'Georges',
    'Villons-les-Buissons',
    'La Boussac',
    'Marbache',
    'Paizay-le-Chapt',
    'Santeuil',
    'Gervais',
    'Gros-Rederching',
    'Soucht',
    'Lemberg',
    'Goetzenbruck',
    'Epping',
    'Port-Cros',
    'Severac',
    "Val d'Isère",
    'Limas',
    'Garche',
    'Domgermain',
    'Les Combes',
    'Ville-en-Tardenois',
    'Sillingy',
    'Vignols',
    'Chameyrat',
    'Voutezac',
    'Panazol',
    'Saint-Paterne',
    'Gilette',
    'Essegney',
    'Tilly-sur-Meuse',
    'Tourgeville',
    'Oursbelille',
    'Moyeuvre-Grande',
    'Courcelles-Chaussy',
    'Retonfey',
    'Falck',
    'Semecourt',
    'Puttelange-les-Thionville',
    'Rozerieulles',
    'Ancy-sur-Moselle',
    'Ars-sur-Moselle',
    'Ham-sous-Varsberg',
    'Lorquin',
    'Mittelbronn',
    'Guntzviller',
    'Delme',
    'Bechy',
    'Phalsbourg',
    'Louvigny',
    'Donjeux',
    'Reding',
    'Rechicourt-le-Chateau',
    'Morsbach',
    'Brouderdorff',
    'Kerbach',
    'Petite-Rosselle',
    'Bousbach',
    'Altviller',
    'Entrange',
    'Kedange-sur-Canner',
    'Richemont',
    'Contz-les-Bains',
    'Mulcey',
    'Metzervisse',
    'Harreberg',
    'Vitry-sur-Orne',
    'Basse-Rentgen',
    'Vaudreching',
    'Waldhouse',
    'Remering-les-Puttelange',
    'Hundling',
    'Fenetrange',
    'La Maxe',
    'Cherisey',
    'Benestroff',
    'Etting',
    'Verny',
    'Chateau-Salins',
    'Diffembach-les-Hellimer',
    'Ernestviller',
    'Montbronn',
    'Enchenberg',
    'Vigny',
    'Vibersviller',
    'Nousseviller-Saint-Nabor',
    'Amelecourt',
    'Sorbey',
    'Gorze',
    'Nitting',
    'Distroff',
    'Lesse',
    'Hermelange',
    'Chanteloup-les-Vignes',
    'Saulny',
    'Rabat-les-Trois-Seigneurs',
    'Thyez',
    'Vulbens',
    'Lunel-Viel',
    "Saint-Georges-d'Orques",
    "Le Cap D'Agde",
    'Abeilhan',
    'Saint-Christol',
    'Le Bosc',
    'Lamalou-les-Bains',
    'Saussan',
    'Saint-Jean-de-Cornies',
    'Montferrier-sur-Lez',
    'Saint-Andre-de-Sangonis',
    'Labastide-Rouairoux',
    'Valros',
    'Grabels',
    'Puissalicon',
    'Lespignan',
    'Le Bousquet',
    'Espondeilhan',
    'Villeneuve-des-Escaldes',
    'Clary',
    'Tours-sur-Marne',
    'Saint-Cyr-sur-Menthon',
    'Chavannes-sur-Reyssouze',
    'Cras-sur-Reyssouze',
    'Chaleins',
    'Nantua',
    'Tinchebray',
    'Ecorcei',
    'Brix',
    'Dive',
    'Bernieres-le-Patry',
    'Cardonville',
    'Mere',
    'Coulonces',
    'Balleroy',
    'Hardinvast',
    'Norrey-en-Auge',
    'Banville',
    'Saussemesnil',
    'Montabard',
    'Mortree',
    'Coudehard',
    'Cairon',
    'Saint-Martin-des-Champs',
    'Echauffour',
    'Ouville',
    'Saint-Brice-de-Landelles',
    'Carentan',
    'Fontaine-le-Pin',
    'Brecey',
    'Vassy',
    'La Graverie',
    'Buceels',
    'Villers-Canivet',
    'Le Tourneur',
    'Soliers',
    'Saint-Andre-de-Messei',
    'Montmartin-sur-Mer',
    'Boissy-Maugis',
    'Amfreville',
    'Les Forges',
    'Cossesseville',
    'Cerise',
    'Moon-sur-Elle',
    'Saint-Jean-le-Blanc',
    'Bellengreville',
    'Saint-Ouen-sur-Iton',
    'Camprond',
    'Sommervieu',
    'Equeurdreville-Hainneville',
    'La Bigne',
    'Bretteville-sur-Odon',
    'Herouvillette',
    'Saint-Pierre-du-Regard',
    'Ceaux',
    'Fye',
    'Isigny-le-Buat',
    'Saint-Jean-des-Baisants',
    'Villedieu-les-Poeles',
    'Saint-Remy',
    'Irai',
    'Notre-Dame-de-Livaye',
    'Saint-Desir',
    'Giberville',
    "Athis-de-l'Orne",
    'Saint-Sulpice-sur-Risle',
    'Vimoutiers',
    'Ouffieres',
    'La Houblonniere',
    'Thezy-Glimont',
    'Thieuloy-Saint-Antoine',
    'Courmelles',
    'Saint-Martin-le-Noeud',
    'Heilly',
    'Dreslincourt',
    'Moncetz-Longevas',
    'Sault-les-Rethel',
    'Ville',
    'Sebourg',
    'Fechain',
    'Roost-Warendin',
    'Linselles',
    'Leforest',
    'Bavay',
    'Rumaucourt',
    'Courchelettes',
    "Saint-Martin-d'Ablois",
    'La Grandville',
    'Escautpont',
    'Haramont',
    'Mondescourt',
    'Thourotte',
    'Rubempre',
    'Precy-sur-Oise',
    'Alliancelles',
    'Boulogne-la-Grasse',
    'Margny-les-Compiegne',
    'Troissereux',
    'Grivillers',
    'Agnetz',
    'Bantigny',
    'Sainte-Marie-Cappel',
    'Souchez',
    'Houplin-Ancoisne',
    'Aix-en-Ergny',
    'Westbecourt',
    'Ayette',
    'Bouvelinghem',
    'Wanquetin',
    'Sainte-Marie-Kerque',
    'Breuil',
    'Gelannes',
    'Chamagne',
    'Vertus',
    'Taissy',
    'Sillery',
    'Pomacle',
    'Sameon',
    'Beaufort',
    'Holnon',
    'La Varenne',
    'Les Brouzils',
    'Mortagne-sur-Sevre',
    'La Flocelliere',
    'Guemene-Penfao',
    'Saint-Avaugourd-des-Landes',
    'Saint-Mathurin',
    'La Chapelle-Montligeon',
    'Saint-Barthelemy-de-Bussiere',
    'Cosse-le-Vivien',
    'Entrammes',
    'Becon-les-Granits',
    'Durtal',
    'Jumelles',
    'Laigne-en-Belin',
    'Montreuil-sous-Perouse',
    'Saint-Hilaire-le-Vouhis',
    'Pontvallain',
    'La Cropte',
    'Le Pallet',
    'Les Rosiers-sur-Loire',
    'Vue',
    'Chaudefonds-sur-Layon',
    'Willgottheim',
    'Ravieres',
    'Sombacour',
    'Gries',
    'Norges-la-Ville',
    'Gigny-sur-Saone',
    'Le Hohwald',
    'Ingolsheim',
    'Altorf',
    'Blaesheim',
    'Wittersdorf',
    'Harskirchen',
    'Zellwiller',
    'Morschwiller-le-Bas',
    'Limersheim',
    'Mertzen',
    'Courchaton',
    'Coutarnoux',
    'Montenois',
    'Losne',
    'Beauvoisin',
    'Quers',
    'Villeneuve-les-Genets',
    'Broin',
    'Fraisans',
    'Herimoncourt',
    'Freland',
    'La Bourgonce',
    'Chille',
    'Les Fourgs',
    'Bonnard',
    'Creusot',
    'Lapoutroie',
    'Souffelweyersheim',
    'Cussy-les-Forges',
    'Curdin',
    'Annay-sur-Serein',
    'Druyes-les-Belles-Fontaines',
    'Les Hopitaux-Vieux',
    'Nordhouse',
    'Bischoffsheim',
    'Bellefosse',
    'Ruelisheim',
    'Busy',
    'Beutal',
    'Cresancey',
    'Bazoches',
    'Montchanin',
    'Dasle',
    'Malpas',
    'Merry-la-Vallee',
    'Chamoux',
    'Baigneux-les-Juifs',
    'Saint-Mard-de-Vaux',
    'Arlay',
    'Noiron-sous-Gevrey',
    'Villevieux',
    'Roppentzwiller',
    'Hombourg',
    'Rezonville',
    'Arbois',
    'Amange',
    'Malange',
    'Charnay-les-Macon',
    'Moutiers',
    'Groix',
    'Guipel',
    'Saint-Pierre-de-Plesguen',
    'Beignon',
    'Landujan',
    'Langourla',
    'Treveneuc',
    'Osse',
    'Pordic',
    'Moreac',
    'Plussulien',
    'La Bouexiere',
    'Quily',
    'Pommerit-le-Vicomte',
    'Taden',
    'Merdrignac',
    'Callac',
    'Andel',
    'Monterblanc',
    'Lanmeur',
    'Saint-Seglin',
    'Peumerit',
    'Mauron',
    'Le Theil-de-Bretagne',
    'Cinqueux',
    'Lucciana',
    "Labastide-d'Anjou",
    'Aubinges',
    "Aire-sur-l'Adour",
    'Ahuille',
    'Eygurande-et-Gardedeuil',
    "Ville-d'Avray",
    'Elincourt-Sainte-Marguerite',
    "L'Etang-la-Ville",
    'Noyelles-Godault',
    'Camblain-Chatelain',
    'Plouvain',
    'Cognin',
    'Les Halles',
    'Saint-Symphorien-sur-Coise',
    'Saint-Martin-en-Haut',
    'Vaugneray',
    'Hauterives',
    'Duerne',
    'Souzy',
    'Sallaumines',
    'Mareil-Marly',
    'Bougival',
    'Leffrinckoucke',
    'Calonne-Ricouart',
    'Labuissiere',
    'Cauchy-à-la-Tour',
    'Bergues',
    'Marquillies',
    'Ghyvelde',
    'La Tour',
    'Bondues',
    'Loison-sous-Lens',
    'Drocourt',
    'Noyelles-sous-Lens',
    'Jasseron',
    'Les Allues',
    'La Villetelle',
    'Magny',
    'Boran-sur-Oise',
    'Somloire',
    'Florent-en-Argonne',
    'Saint-Prouant',
    'Meilhan',
    'La Foret-Fouesnant',
    'Marques',
    'Saulnes',
    'Anizy-le-Chateau',
    'La Jarne',
    'Camaret-sur-Aigues',
    'Saint-Porquier',
    'Fontenay',
    "Grand'Combe-Chateleu",
    'Saint-Cyr-les-Champagnes',
    'Plobsheim',
    "Collonges-au-Mont-d'Or",
    'Bavilliers',
    'Fourqueux',
    'Vaucresson',
    'Pierre-Levee',
    'Villiers-sur-Morin',
    'Montolivet',
    'Pommeuse',
    'Cuy',
    'Choisy-au-Bac',
    'Acy-en-Multien',
    'Brexent-Enocq',
    'Saint-Michel-sous-Bois',
    'Ruesnes',
    'Denier',
    'Sommaing',
    'Saint Nicolas Les Arras',
    'Fournes-en-Weppes',
    'Montcy-Notre-Dame',
    'Les Rivieres-Henruel',
    'Mandres-la-Cote',
    'Bienville',
    'Crisolles',
    'Sault-Saint-Remy',
    'Baisieux',
    'Ferques',
    'Verton',
    'Berck',
    'Billy-Berclau',
    'Averdoingt',
    'Inxent',
    'Pont-de-Briques',
    'Louvemont',
    'Vitry-le-François',
    'Marcilly-sur-Seine',
    'Crecy-sur-Serre',
    'Saint-Aubin',
    'Bajus',
    'Saint-Martin-Boulogne',
    'Echinghen',
    'Avesnes-le-Sec',
    'Cantin',
    'Ecuires',
    'Famars',
    'Camphin-en-Pevele',
    'Saudoy',
    'Lacroix-Saint-Ouen',
    'Crezancy',
    'Joinville',
    'Brugny-Vaudancourt',
    'Vireux-Molhain',
    'Rethel',
    'Chessy-les-Pres',
    'Forest-sur-Marque',
    'Andres',
    'Brienne-le-Château',
    'Charmont-sous-Barbuise',
    'Arcis-sur-Aube',
    'Colombiers',
    'Orsan',
    'Peyriac-de-Mer',
    'Claira',
    'Argens',
    'Valleraugue',
    'Castillon-du-Gard',
    'Le Lez',
    'Ponteilla',
    'Lauret',
    'Mandagout',
    'Le Soler',
    'Saint-Clement',
    'La Salvetat-sur-Agout',
    'Pia',
    'Luc-sur-Orbieu',
    'Tavel',
    'Anduze',
    'Marvejols',
    'Saint-Andre-de-Roquelongue',
    'Montfaucon',
    "Sonnac-sur-l'Hers",
    'Laurabuc',
    'Cambieure',
    'Gallician',
    'Fontes',
    'Sardan',
    'Caveirac',
    'Sauzet',
    'Caunes-Minervois',
    'Villemolaque',
    'Lamorville',
    'Fresnes-au-Mont',
    'Marche-Allouarde',
    'Brie',
    'La Nerthe',
    'Ludon-Medoc',
    'Ecole',
    'Esches',
    'Berthecourt',
    'Charonville',
    'Catenay',
    'Barbery',
    'Charolles',
    'La Neuville-du-Bosc',
    'Tillieres-sur-Avre',
    'Auvergny',
    'Vouneuil-sous-Biard',
    'Amailloux',
    "Pont-l'Abbe-d'Arnoult",
    'Castillonnes',
    'Alairac',
    'Monsegur',
    'Saint-Caprais-de-Bordeaux',
    'Nailloux',
    'Bicqueley',
    'Montbonnot-Saint-Martin',
    'Le Versoud',
    'Carpiquet',
    'Cormelles-le-Royal',
    'Villiers-sous-Grez',
    'Theoule-sur-Mer',
    'Real',
    'Saint-Gence',
    'Cravent',
    'Cernay-la-Ville',
    'Vauhallan',
    'Saint-Germain-de-la-Grange',
    'Wissous',
    'Les Mesnuls',
    'Paray-Vieille-Poste',
    'Buno-Bonnevaux',
    'Bresles',
    'Saint-Maurice-Montcouronne',
    'Maisse',
    'Asnieres-sur-Oise',
    'Bruyeres-sur-Oise',
    'Chevry-en-Sereine',
    'Neufmoutiers-en-Brie',
    'Engins',
    'La Buisse',
    'Bourget',
    'Apprieu',
    'Corenc',
    'Sinard',
    'Saint-Jean-de-Maurienne',
    'Brie-et-Angonnes',
    'Beauvoisin',
    'Gilly-sur-Isere',
    'Jarrier',
    'Roybon',
    'Lans-en-Vercors',
    'Les Rives',
    'Montcel',
    'Bassens',
    'Brignoud',
    'Modane',
    'Huez',
    'Saint-Laurent-en-Beaumont',
    'Bevenais',
    'Saint-Vital',
    'Les Marches',
    'Clelles',
    'Bernin',
    'Theys',
    'Saint-Etienne-de-Cuines',
    'Villard-Saint-Christophe',
    'Le Chatelard',
    'Biviers',
    'Macot-la-Plagne',
    'La Porte',
    'Rognaix',
    'Chamoux-sur-Gelon',
    'Pugieu',
    'Saint-Appolinard',
    'Veurey-Voroize',
    'Montlhery',
    'Saint-Vrain',
    'Janville-sur-Juine',
    'La Garenne-Colombes',
    'Montcourt-Fromonville',
    'Flins-sur-Seine',
    'Bazoches-sur-Guyonne',
    'Survilliers',
    'Fay-les-Nemours',
    'Neuville-sur-Oise',
    'Sonchamp',
    'Estadens',
    'Barinque',
    'Saint-Georges-du-Bois',
    'Corbarieu',
    'Poulan-Pouzols',
    'Nicole',
    'Saint-Sylvestre-sur-Lot',
    'Casseneuil',
    'Cenac',
    'Coimeres',
    'Le Buisson-de-Cadouin',
    'Le Pian-sur-Garonne',
    'Cornille',
    'Tabanac',
    'Teuillac',
    'Sainte-Bazeille',
    'Jonzac',
    'Aillas',
    'Saint-Sulpice-et-Cameyrac',
    'Roaillan',
    'Riocaud',
    'Fauch',
    'Dourgne',
    'Bouloc',
    'Labastide-dArmagnac',
    'Gimont',
    'Bezac',
    'Jazeneuil',
    'Pellegrue',
    'Boe',
    'Lansac',
    'Puisseguin',
    'Saint-Vincent-de-Connezac',
    'Paleyrac',
    'Les Artigues-de-Lussac',
    'Castelnau-sur-Gupie',
    'Doulezon',
    'Martillac',
    'Grignols',
    'Bruch',
    'Jonquerets-de-Livet',
    'Saint-Pierre-en-Val',
    'Cauville-sur-Mer',
    'Anceaumeville',
    'Heuqueville',
    'Bois-Jerome-Saint-Ouen',
    'Gonfreville-lOrcher',
    'Saint-Ouen-de-Thouberville',
    'Virville',
    'Angiens',
    "Ouville-l'Abbaye",
    'Ventes-Saint-Remy',
    'Carville-la-Folletiere',
    'Envermeu',
    'Val-de-la-Haye',
    'Barc',
    'Caux',
    'Tourville-la-Riviere',
    'Le Plessis-Hebert',
    'Mesnil-Raoul',
    'Longueville-sur-Scie',
    'Ymare',
    'Trouville-la-Haule',
    'Saint-Nicolas-de-Bliquetuit',
    'Yebleron',
    'Sigy-en-Bray',
    'Rolleville',
    'Saint-Arnoult',
    'Aviron',
    'Riville',
    'Saint-Aquilin-de-Pacy',
    'Fontaine-sous-Preaux',
    'Saint-Eustache-la-Foret',
    'Menilles',
    'Martot',
    'Saint-Germain-Village',
    'Saint-Aubin-sur-Scie',
    'Le Mesnil-Esnard',
    'Sainneville',
    'Le Bosc-Roger-en-Roumois',
    'Saussay-la-Campagne',
    'Cuy-Saint-Fiacre',
    'Saint-Martin-du-Manoir',
    'La Vacherie',
    'Sainte-Austreberthe',
    'Ferrieres-Haut-Clocher',
    'Brachy',
    'Valliquerville',
    'Ecretteville-les-Baons',
    'Hattenville',
    'Chauvincourt-Provemont',
    'Montaure',
    'Sancourt',
    'Sacquenville',
    'Mesangueville',
    'Saint-Jean-le-Thomas',
    "Le Mas-d'Azil",
    'Saint-Jean-du-Falga',
    "Villeneuve-d'Olmes",
    'Francheleins',
    'Gournay-sur-Marne',
    'Villes-sur-Auzon',
    'Marly',
    'Lutterbach',
    'Plesnois',
    'Norroy-le-Veneur',
    'Saint-Pierre-la-Garenne',
    'Templemars',
    'Bouxieres-aux-Chenes',
    'Bures-sur-Yvette',
    "Notre-Dame-d'Oe",
    'Mundolsheim',
    'Damville',
    'Toufflers',
    'Follainville-Dennemont',
    'Magnanville',
    'Duppigheim',
    'Brieres-les-Scelles',
    'Pulnoy',
    'Grosrouvre',
    'Bruyeres-le-Chatel',
    'Cadaujac',
    'Champ-sur-Drac',
    'Venon',
    'Breteniere',
    'Saint-Martin-sur-le-Pre',
    'Reitwiller',
    'Saint-Contest',
    'Moisselles',
    'Oberhoffen-sur-Moder',
    'Steinbourg',
    'Offendorf',
    'Cormontreuil',
    'Reventin-Vaugris',
    'Nogent-sur-Seine',
    'Saint-Pierre-du-Perray',
    'Condrieu',
    'Schnersheim',
    'Rosheim',
    'Habsheim',
    'Pontavert',
    'Saclay',
    'Monthieux',
    'Beynost',
    'Montanay',
    'Quincieu',
    'Saint-Lye-la-Foret',
    'Mours',
    'Puiseux-en-France',
    'Parmain',
    'Butry-sur-Oise',
    'Lormaison',
    "Marcy-l'Etoile",
    'Charbonnieres-les-Bains',
    'Gouvieux',
    'Saint-Aubin-de-Medoc',
    'Saint-Sulpice-de-Faleyrens',
    'Notre-Dame-de-Mesage',
    'Montmelian',
    'Apremont',
    'Gamaches',
    'Saint-Remy-les-Chevreuse',
    'Saint-Antonin-sur-Bayon',
    'Auzeville-Tolosane',
    'Pontpoint',
    'Houdemont',
    'La Penne-sur-Huveaune',
    'Latour',
    "Saint-Cyr-au-Mont-d'Or",
    'Magenta',
    'Emmerin',
    'Jouars-Pontchartrain',
    'Mareuil-les-Meaux',
    'La Vallee de Dormelles',
    'Grazac',
    'Saint-Germain-sur-Morin',
    'Sonnaz',
    'Lattainville',
    'Piscop',
    'Maillane',
    'Buxerolles',
    'Aumur',
    'Leves',
    'Le Thillay',
    'Lapeyrouse-Fossat',
    'Saint-Michel',
    'Le Houlme',
    'Pomponne',
    'Sollies-Toucas',
    'Diges',
    "Saint-Didier-au-Mont-d'Or",
    'Finhan',
    'Ingersheim',
    'Valpuiseaux',
    'Le Mesnil-en-Thelle',
    'Westhouse',
    'Chatenois',
    'Golfe-Juan',
    'Nicorps',
    'Gratot',
    'Charleval',
    'Ille-sur-Tet',
    'Vernet-les-Bains',
    'Uzos',
    'Pugny-Chatenod',
    'Sierentz',
    'La Glacerie',
    'Crespieres',
    'Thiverval-Grignon',
    'Bolsenheim',
    'Ambres',
    'Perigny',
    'Villeneuve-les-Sablons',
    'Corbeil-Cerf',
    'Rogerville',
    'Montbazon',
    'Lignieres-Chatelain',
    'Essertaux',
    'Chamborigaud',
    'Scionzier',
    'Marsac-en-Livradois',
    'Saint-Pierre-de-Varennes',
    'Tignieu-Jameyzieu',
    'Mogneville',
    'Laigneville',
    'Liancourt',
    'Balagny-sur-Therain',
    'Amilly',
    'Pontarme',
    'Les Sauvages',
    'Panissieres',
    'Erbray',
    'Meaudre',
    'Praz-sur-Arly',
    'Valleres',
    'Bouquetot',
    'Bramans',
    'Saint-Gondran',
    'Mas-Saintes-Puelles',
    'Gaillan-en-Medoc',
    'Saint-Remimont',
    'Pernay',
    'Bellegarde-en-Diois',
    "Les Aix-d'Angillon",
    'Chatillon-sur-Indre',
    'Molleges',
    'Cheroy',
    'Lancrans',
    'Madirac',
    'Port-Sainte-Foy-et-Ponchapt',
    'Les Ormes',
    'Les Assions',
    "Saint-Martin-d'Heuille",
    'Villard',
    'Gouaix',
    'Megrit',
    'Moisville',
    'Saint-Hilarion',
    'Barjon',
    'Cusy',
    'Brissac',
    'La Roche',
    'Plaimpied-Givaudins',
    'Charron',
    'Epineuil-le-Fleuriel',
    'Labastide-Beauvoir',
    'Rians',
    'Sainte-Solange',
    'Azay-sur-Indre',
    'Bellenaves',
    'Saint-Amand-de-Coly',
    'Chuelles',
    'Coyriere',
    'Saint-Denis-dAnjou',
    'Bremontier-Merval',
    'Carresse-Cassaber',
    'Bonneville',
    'Preignan',
    'Grand-Auverne',
    'Soulaire-et-Bourg',
    'Bazicourt',
    'Saint-Mard',
    'Quiers',
    'Chamigny',
    'Saint-Fiacre',
    'Ferrieres-en-Brie',
    'Longueville',
    'Ozouer-le-Voulgis',
    'Egreville',
    'Lissy',
    'Amponville',
    'Saint-Ouen-sur-Morin',
    'Salins',
    'Beaumont-les-Nonains',
    'Annet-sur-Marne',
    'Cerneux',
    'Couilly-Pont-aux-Dames',
    'Saint-Martin-en-Biere',
    'Fromonville',
    'Coulommes',
    'Jossigny',
    'Thomery',
    'Chalmaison',
    'Charny',
    'Noisy-sur-Oise',
    'Sollies-Ville',
    'Meounes-les-Montrieux',
    'Besse-sur-Issole',
    'Neoules',
    'Fayence',
    'Forcalqueiret',
    'Le Val',
    'Ginasservis',
    'Puget-Ville',
    "Seillons-Source-d'Argens",
    'Evenos',
    'Tavernes',
    'Foret-la-Folie',
    'Rougiers',
    'La Houssaye-en-Brie',
    'Sagy',
    'Saint-Augustin',
    'Chessy',
    "Germigny-l'Eveque",
    'Fresnes-sur-Marne',
    'Boissise-le-Roi',
    'Plomb',
    'Beuvillers',
    'Montchamp',
    'Saint-Germain-Langot',
    'Campagnolles',
    'Sacey',
    'La Foret',
    'Tirepied',
    'May-sur-Orne',
    'Pennedepie',
    'Saint-Denis-le-Gast',
    'Hudimesnil',
    'Coudray-Rabut',
    'Harcourt',
    'Ranville',
    'Conde-sur-Sarthe',
    'Nehou',
    'Sourdeval',
    'La Ferriere-Bochard',
    'Bougy',
    'Sainte-Honorine-du-Fay',
    'La Motte-Fouquet',
    'Gonneville-sur-Mer',
    'Janville',
    'Billy',
    'Precorbin',
    'Le Dezert',
    'Cahagnes',
    'Pair-et-Grandrupt',
    'Aunay-les-Bois',
    'Creully',
    'Trelly',
    'Fontenai-les-Louvets',
    'Sainte-Marie-Laumont',
    'Le Merlerault',
    'Ussy',
    'Viessoix',
    'Angoville-sur-Ay',
    'Cintheaux',
    'Raids',
    'Ancinnes',
    'Samson',
    'Frenes',
    'Rosel',
    'Le Mesnil-Ozenne',
    'Tourville-en-Auge',
    'Neuilly-le-Bisson',
    'Livet-sur-Authou',
    'Morteaux-Couliboeuf',
    'Montfarville',
    'Saint-Pierre-Tarentaine',
    'Saint-Paul-du-Vernay',
    'Sainte-Marie-du-Mont',
    'Avenay',
    'Saint-Quentin-les-Chardonnets',
    'Castilly',
    'Saint-Sylvain',
    'Saint-Michel-des-Andaines',
    'Le Sap',
    'Fierville-Bray',
    'Messei',
    'Menil-Hermei',
    'Durcet',
    'Ceauce',
    'Putot-en-Bessin',
    'Artolsheim',
    'Baldenheim',
    'Marckolsheim',
    'Aspach-le-Haut',
    'Obenheim',
    'Achenheim',
    'Hangenbieten',
    'La Couture-Boussey',
    'Cormoranche-sur-Saone',
    'Puyloubier',
    'Charly-sur-Marne',
    'Feurs',
    'Angervilliers',
    'Ville-la-Grand',
    'Ligny-en-Cambresis',
    'Naours',
    'Saint-Andre-de-Rosans',
    'Odos',
    'Peronne',
    'Crespin',
    'Vert-Saint-Denis',
    'Isles-les-Villenoy',
    'Signy-Signets',
    'Torcy',
    'Flavigny-sur-Moselle',
    'Marnaz',
    'Vic-sur-Seille',
    'Trelissac',
    'Le Chateau Gaillard',
    'Belleu',
    'Bu',
    'Nefiach',
    'Saint-Maximin',
    'Guerville',
    'Lapouyade',
    'La Poterie-Mathieu',
    'Planches',
    'Classun',
    'Cavillargues',
    'Vaugrigneuse',
    'Randan',
    'Escombres-et-le-Chesnois',
    'Ville-sur-Tourbe',
    'Tailly',
    'Sapogne-sur-Marche',
    'Blanzy-la-Salonnaise',
    'Torvilliers',
    'Caurel',
    'Vandy',
    'Wassy',
    'Joncourt',
    'Conchy-les-Pots',
    'Quevauvillers',
    'Verneuil-en-Halatte',
    'Lieramont',
    'Rieux',
    'Saint-Maxent',
    'Bregy',
    'Pasly',
    'Grand-Laviers',
    'Erquinvillers',
    'Rainneville',
    'Lieuvillers',
    'Saint-Paul-de-Vezelin',
    'Campigneulles-les-Petites',
    'Coye-la-Foret',
    'Bailleul-le-Soc',
    'Estrees-sur-Noye',
    'Bussus-Bussuel',
    'Ons-en-Bray',
    'Pende',
    'Barenton-Bugny',
    'Yaucourt-Bussus',
    'Saint-Maulvis',
    'Barzy-en-Thierache',
    'Isques',
    'Berck-Plage',
    'Neuville-Saint-Vaast',
    'Villers-Plouich',
    'Estrees',
    'Fillievres',
    'Souastre',
    'Ablainzevelle',
    'Bollezeele',
    'Eclaron-Braucourt-Sainte-Liviere',
    'Mery-sur-Seine',
    'Morvilliers',
    'Lavannes',
    'Bossancourt',
    'Richebourg',
    'Rouvroy-sur-Marne',
    'Les Charmontois',
    'Fontaine-en-Dormois',
    'Quilly',
    'Chepy',
    'Bernard',
    "Avenay-Val-d'Or",
    'Vaire-sous-Corbie',
    'Bonneuil-en-Valois',
    'Pierrepont-sur-Avre',
    'Nibas',
    'Dargnies',
    'Poeuilly',
    'Bethencourt-sur-Mer',
    'Beautor',
    'Vineuil-Saint-Firmin',
    'Neufvy-sur-Aronde',
    'Brissy-Hamegicourt',
    'Ailette',
    'Pronville',
    'Pontruet',
    'Davenescourt',
    'Ailly-le-Haut-Clocher',
    'Flers-sur-Noye',
    'Contre',
    'Fignieres',
    'Marest',
    'Montcornet',
    'Dury',
    'Beaucamps-Ligny',
    'Radinghem',
    'Farbus',
    'Marcq-en-Ostrevent',
    'Montreuil-sur-Mer',
    'Norrent-Fontes',
    'Wail',
    'Carency',
    'Aynac',
    'Compolibat',
    'Gramat',
    'La Rochette',
    'Saint-Remy-en-Mauges',
    'Plassay',
    'Pompaire',
    'Montbron',
    'Lestelle-Betharram',
    'Aventignan',
    'Flavin',
    'Thoux',
    'Saint-Clement',
    'Lussant',
    'Leugny',
    'Roche-le-Peyroux',
    'Videix',
    'Chatillon-sur-Thouet',
    'Puycornet',
    'Bougue',
    'Benesse-Maremne',
    'Semeacq-Blachon',
    'Montchaude',
    'Benon',
    'Orion',
    'Laruns',
    'Massat',
    'Clairvaux-dAveyron',
    'Smarves',
    'Coussay-les-Bois',
    'Saint-Vaury',
    'Saint-Sauveur-la-Sagne',
    "Saint-Geniez-d'Olt",
    "Dolus-d'Oleron",
    'Varen',
    'Saint-Yrieix-sur-Charente',
    'Châlus',
    'Saint-Laurent-de-la-Pree',
    'Sauze-Vaussais',
    'Jugeals-Nazareth',
    'Saint-Angeau',
    'Pontenx-les-Forges',
    'Commensacq',
    'Izotges',
    'Villeneuve-de-Duras',
    'Montamise',
    'La Foye-Monjault',
    'Tourriers',
    'Montdoumerc',
    'Castetner',
    'Idron',
    'Bignoux',
    'Sedzere',
    'Saint-Jean-de-Marsacq',
    'Bertholene',
    'Garanou',
    'Gencay',
    'Sorcy-Saint-Martin',
    'Angevillers',
    'Fleville-devant-Nancy',
    'Spincourt',
    'Darmont',
    'Aspach-le-Bas',
    'Steinbach',
    'Durlinsdorf',
    'Fellering',
    'Franxault',
    'Lacrost',
    'Vault-de-Lugny',
    'Saint-Leger-sur-Dheune',
    'Chigy',
    'Hurigny',
    'Ovanches',
    'Couchey',
    'Ruffey-les-Beaune',
    'Beaufort',
    'Meursault',
    'Cousance',
    'Danne-et-Quatre-Vents',
    'Moussey',
    'Rarecourt',
    'Senones',
    'Velaines',
    'Nayemont-les-Fosses',
    'Ogy',
    'Taintrux',
    'Sainte-Genevieve',
    'Norroy-les-Pont-a-Mousson',
    'Minversheim',
    'Grentzingen',
    'Wangenbourg-Engenthal',
    'Obersaasheim',
    'Chatillon-le-Duc',
    'Frebuans',
    'Courlaoux',
    'Grandvaux',
    'Champlitte',
    'Bonnevaux-le-Prieure',
    'Saint-Bris-le-Vineux',
    'Saint-Clement',
    'Armaucourt',
    'Rehainviller',
    'Leyr',
    'Oderen',
    'Siegen',
    'Lipsheim',
    'La Frette',
    'Ouhans',
    'Torpes',
    'Saules',
    'Baulay',
    'Levernois',
    'Soucy',
    'Collonges-les-Premieres',
    'Pournoy-la-Grasse',
    'Fontenoy-la-Joute',
    'Treveray',
    'Xures',
    'Gripport',
    'Herny',
    'Hallering',
    'Loudrefing',
    'Jainvillotte',
    'Dieppe-sous-Douaumont',
    'Jezainville',
    'Neuviller-sur-Moselle',
    'Haut-Clocher',
    'Vieux-Thann',
    'Jettingen',
    'Malmerspach',
    'Chemilly-sur-Serein',
    'Mont-sur-Monnet',
    'Farges-les-Chalon',
    'Julienas',
    'Quenoche',
    'Ratte',
    'Courlans',
    'Boussay',
    'La Baule-Escoublac',
    'Beaumont-Pied-de-Boeuf',
    'La Place',
    'Saint-Mars-de-Coutais',
    'Le Fief-Sauvin',
    'Hambers',
    'Besse-sur-Braye',
    'Saint-Aignan-sur-Roe',
    'Triaize',
    'Chambretaud',
    'Mouzeil',
    'Saint-Martin-les-Melle',
    'Saint-Lezin',
    'Mulsanne',
    'Corsept',
    'Fille',
    "Pruille-l'Eguille",
    'Fontenay-sur-Vegre',
    'Saint-Melaine-sur-Aubance',
    'Le Mazeau',
    'Mache',
    'Angles',
    'Le Gavre',
    'Jupilles',
    'Distre',
    'Luceau',
    'Prefailles',
    'Saint-Clement-de-la-Place',
    'Gene',
    'La Salle-de-Vihiers',
    'Monts-sur-Guesnes',
    'Saint-Quentin-en-Mauges',
    'Montigne-les-Rairies',
    'Chatelais',
    'Saint-Laurent-du-Mottay',
    'Mareuil-sur-Lay-Dissais',
    'Le Thou',
    'Maigne',
    'Laigne',
    'Soulaines-sur-Aubance',
    'Xanton-Chassenon',
    'Pontigne',
    'Damvix',
    'Isches',
    'Senonges',
    'Sevrey',
    'Varennes-le-Grand',
    'Ladoix-Serrigny',
    'Couches',
    'Ecrouves',
    'Dornot',
    'Moncel-sur-Seille',
    'Saint-Berain-sur-Dheune',
    'Les Allies',
    'Sausheim',
    'Grandfontaine-Fournets',
    'Mont-sur-Meurthe',
    'Hauconcourt',
    'Ancerville',
    'Magny-Cours',
    'Batilly',
    'Weyersheim',
    'Epfig',
    'Cuisery',
    'Labaroche',
    'Nanton',
    'Salans',
    'Willerwald',
    'Jesonville',
    'Treclun',
    'La Villeneuve',
    'Les Gras',
    'Les Granges',
    'Labry',
    'Dinoze',
    'Velotte-et-Tatignecourt',
    'Haudiomont',
    'Velars-sur-Ouche',
    'La Tagniere',
    'Chevrotaine',
    'Pouilloux',
    'Landres',
    'Knutange',
    'Hilbesheim',
    'Obtree',
    'Vaudrey',
    'Moroges',
    'Juvigny-en-Perthois',
    'Millery',
    'Vezelise',
    'Crancot',
    'Cuinzier',
    "Longevilles-Mont-d'Or",
    'Vermenton',
    'La Tour-de-Scay',
    'Chaudefontaine',
    'Branges',
    'Marchaux',
    'Marliens',
    'Varanges',
    'Le Poet',
    'Jausiers',
    'Les Vigneaux',
    'Callian',
    'La Rouine',
    'Pierrefeu-du-Var',
    'Opio',
    'Agay',
    'Toudon',
    'Le Broc',
    'Castellane',
    'Isola',
    'Ampus',
    'Croix',
    'Regusse',
    'Sepmeries',
    'Senlecques',
    'Tilloy-lez-Marchiennes',
    'Enquin-les-Mines',
    'Ferfay',
    'Cassel',
    'Cauroir',
    'Lallaing',
    'Chemy',
    'Beauquesne',
    'Maignelay-Montigny',
    'La Fere',
    'Mailly-Raineval',
    'Coudun',
    'Essigny-le-Grand',
    'Le Meriot',
    'Janvilliers',
    'Cumieres',
    'Germaine',
    'Erre',
    'Feignies',
    'Le Touquet-Paris-Plage',
    'Fresnes-les-Montauban',
    'Villers-au-Tertre',
    'Roeulx',
    'Brouckerque',
    'Le Parcq',
    'Pouru-Saint-Remy',
    'Cheret',
    'Chesley',
    'Prouilly',
    'Primat',
    'Boeseghem',
    'Prouvy',
    'Hon-Hergies',
    'Rebreuve-sur-Canche',
    'Canlers',
    'Merckeghem',
    'Berles-au-Bois',
    'Lieu-Saint-Amand',
    'Armbouts-Cappel',
    'Rosult',
    'Nedonchel',
    'Frethun',
    'Autremencourt',
    'Hemevillers',
    'Servais',
    'Villers-le-Sec',
    'Lheraule',
    'Caillouel-Crepigny',
    'Marigny-en-Orxois',
    'Ponchon',
    'Meteren',
    'Outines',
    'Gaye',
    'Letanne',
    'Moslins',
    'Boeschepe',
    'Trith-Saint-Leger',
    'Maisnil-les-Ruitz',
    'Sarry',
    'Thennes',
    'Morangis',
    'Sept-Saulx',
    'Dizy',
    'La Groise',
    'Bugnicourt',
    'Bermeries',
    'Wargnies-le-Petit',
    'Callac',
    'Hirel',
    'Plestan',
    'Saint-Briac-sur-Mer',
    'Laurenan',
    'Saint-Pere',
    'Neuville',
    'Plozevet',
    'Plesidy',
    'Kerity',
    'Saint-Christophe-de-Valains',
    'Haute-Goulaine',
    'Saint-Martin-des-Champs',
    'Larchant',
    'Les Granges',
    'Arville',
    'Marles-en-Brie',
    'Bondoufle',
    'Villiers-sur-Seine',
    'Aigremont',
    'Bray-et-Lu',
    'Medis',
    'Vigeois',
    'Beyssenac',
    'Saint-Martin-Terressus',
    'Brouqueyran',
    'Saint-Antoine-Cumond',
    'Ardiege',
    'Boussenac',
    'La Barthe',
    'Sautel',
    'Chervettes',
    'Habas',
    'Saint-Perdon',
    'Campagne',
    'Hossegor',
    "Saint-Leon-sur-l'Isle",
    'Gourvillette',
    'Saint-Pierre-de-Maille',
    'Saint-Hilaire-les-Courbes',
    'Lalinde',
    'Manot',
    'Perpezac-le-Noir',
    'Saint-Saviol',
    'Ainhoa',
    'Mees',
    'Hagetmau',
    'Lacq',
    'Mendionde',
    'Cauneille',
    'Les Eglisottes-et-Chalaures',
    'Chateauneuf-Grasse',
    'Aspremont',
    'Castellar',
    'Villars-sur-Var',
    'Gorbio',
    'Claviers',
    'Colomars',
    'Ramatuelle',
    'Saint-Etienne-de-Tinee',
    'La Napoule',
    'Boissy-le-Girard',
    'Etrechy',
    'Lahosse',
    'Souraide',
    'Chaillevette',
    'Monferran-Plaves',
    'Sarrant',
    'Saint-Projet',
    'Launay',
    'Dabo',
    'Piblange',
    'Moyenmoutier',
    'Ballainvilliers',
    'Guernes',
    'Moulinet',
    'Lezay',
    'Ychoux',
    'Croutelle',
    'Availles-Limouzine',
    'Beaumont',
    'Lanne',
    'Prats-de-Carlux',
    'Borderes-et-Lamensans',
    'Reynies',
    'Cintegabelle',
    'Aussevielle',
    'Maurrin',
    'Lamagistere',
    'Saubens',
    'Mirande',
    'Saint-Martin-le-Pin',
    'Nontron',
    'Saint-Chamassy',
    'Aureilhan',
    'Saint-Julien-en-Born',
    'Veyrac',
    'Thauron',
    'Villedoux',
    'Bassillac',
    'Cancon',
    'Cahuzac',
    'Sergeac',
    'Marignac',
    'Saint-Laurent-Bretagne',
    'Menesplet',
    'Garrevaques',
    'La Cavalerie',
    'Monclar-de-Quercy',
    'Compans',
    "Parc-d'Anxtot",
    'Yves',
    'Ricquebourg',
    'Heres',
    'Auriac',
    'Jonvilliers',
    'Breles',
    'Vauxbuin',
    'Orcet',
    'Saint-Aubin-de-Lanquais',
    'Cheyssieu',
    'Saint-Martin-Choquel',
    'Clarques',
    'Commes',
    'Fontvieille',
    'Crevechamps',
    'Monnetier-Mornex',
    "Cuxac-d'Aude",
    'Lauzerte',
    'Montaigu-de-Quercy',
    "Bailleau-l'Évêque",
    'Malincourt',
    'Coquelles',
    'Voulangis',
    'Saint-Jean-des-Mauvrets',
    'Saint-Crespin-sur-Moine',
    'Champigne',
    'Andard',
    'Orbec',
    'Cuverville',
    'Saint-Gervais-en-Belin',
    'Montgirod',
    'Charmes-sur-Rhone',
    'Sauvagney',
    'Champagnole',
    'Port-en-Bessin-Huppain',
    'Saint-Joachim',
    'Duneau',
    'Courdemanche',
    'Paradou',
    'Allonnes',
    'Lamanon',
    'Saint-Barnabe',
    'Verdon',
    'Maleville',
    'Saint-Sauveur-dAunis',
    'Marsais',
    'Taillebourg',
    'Treffieux',
    'Moinville-la-Jeulin',
    'Chatelet',
    'Val-dIzé',
    'Beaumont-sur-Sarthe',
    'Aubigne-Racan',
    'Requeil',
    'Corroy',
    'Richelieu',
    'La Chevallerais',
    'Ressons-le-Long',
    'Hargicourt',
    'Saint-Eloy-les-Mines',
    'Beaurieux',
    'Villeneuve-sur-Yonne',
    'Faulx',
    'Simorre',
    'Grossoeuvre',
    'Gourlizon',
    'Ollezy',
    'Arches',
    'Mondragon',
    'Le Chaylard',
    'Saint-Germain-Lembron',
    'Giat',
    'Ardes',
    'Chapdes-Beaufort',
    'Nebouzat',
    'Pontgibaud',
    'Neronde-sur-Dore',
    'Pleurs',
    'Quievrecourt',
    'Saint-Denis-sur-Sarthon',
    'Sainte-Cecile',
    'Cauvigny',
    'Gerard',
    'Chatelaines',
    'Robert',
    'Francois',
    'Houlbec-Cocherel',
    'Blacqueville',
    'Berlaimont',
    'Charly',
    'Pont-de-Veyle',
    'Cruzilles-les-Mepillat',
    'Longueau',
    'Saint-Georges-sur-Baulche',
    'Berriac',
    'Jaucourt',
    'Chourgnac',
    'Payzac',
    'Soumeras',
    'Champier',
    'Brezins',
    'Le Thieulin',
    'Frevin-Capelle',
    'Oisy-le-Verger',
    'Arquettes-en-Val',
    'Roquemaure',
    'Berne',
    'Esquerdes',
    'Origny-Sainte-Benoite',
    'Buzet-sur-Baise',
    'Lavardac',
    'Le Pin',
    'Recquignies',
    'Braud-et-Saint-Louis',
    'Aiguillon',
    'Medan',
    'Yenne',
    'Pujo-le-Plan',
    'Englefontaine',
    'Saint-Jean-Lasseille',
    'Rouillac',
    'Le Vaudreuil',
    'Falicon',
    'Damprichard',
    'Fresnes-en-Woevre',
    'Gommerville',
    'Mauves-sur-Huisne',
    'Nuaille',
    'Saint-Bernard',
    'Sainte-Radegonde',
    'Pont-de-Beauvoisin',
    'Montagny-sur-Grosne',
    'Octeville-sur-Mer',
    'Ban-de-Laveline',
    'Luisant',
    'Faverolles-la-Campagne',
    'Fregouville',
    'Varennes',
    'Lacapelle-Marival',
    'Latronquiere',
    'Lamativie',
    'Saint-Privat-de-Vallongue',
    'Gagnieres',
    'Pompadour',
    'Mernel',
    'Louvigne-de-Bais',
    'Mittersheim',
    'Malleloy',
    'Mertzwiller',
    'Zinswiller',
    'Chatel-Saint-Germain',
    'Basse-Ham',
    'Clouange',
    'Lagardere',
    'Vauchelles-les-Domart',
    'Fressenneville',
    'Crevecoeur-le-Grand',
    'Nouvion-le-Vineux',
    "La Neuville-d'Aumont",
    'Feuquieres-en-Vimeu',
    'Milly-sur-Therain',
    'Fresneaux-Montchevreuil',
    'Saint-Riquier',
    'Rosieres-en-Santerre',
    'Peronne',
    'Gournay-en-Bray',
    'Monchy-Humieres',
    'Grilly',
    'Neufgrange',
    'Morainvilliers',
    'Saint-Maurice-sur-Dargoire',
    'Orlienas',
    'Colombier-Saugnieu',
    'Laudun-lArdoise',
    'Lessy',
    'Drace',
    'Saint-Bonnet-le-Troncy',
    'Lantignie',
    'Les Ardillats',
    'Rosteig',
    'Sewen',
    'Mussig',
    'Mittelhausbergen',
    'Dingsheim',
    'Reichstett',
    'Oberhausbergen',
    'La Wantzenau',
    'Lampertheim',
    'Schirmeck',
    'Urmatt',
    'Barembach',
    'La Broque',
    'Baldersheim',
    'Willer-sur-Thur',
    'Petit-Landau',
    'Longes',
    'Cruseilles',
    'La Rixouse',
    'Chatuzange-le-Goubet',
    'Issenhausen',
    'Lixhausen',
    'Boisemont',
    'Gresse-en-Vercors',
    'Venette',
    'Le Meux',
    'Verderonne',
    'Henonville',
    'Auneuil',
    'Froissy',
    'Sainte-Eusoye',
    'Tracy-le-Mont',
    'Ribecourt-Dreslincourt',
    'Haudivillers',
    'Chepoix',
    'Puiseux-le-Hauberger',
    'Francieres',
    'Breuil-le-Sec',
    'Niederbruck',
    'Bergheim',
    'Fessenheim',
    'Saulzoir',
    'Durette',
    'Blerancourdelle',
    'Bongheat',
    'Bucey-les-Gy',
    'Bidart',
    'Villiers-Couture',
    'Epagny',
    'Poissons',
    'Brassempouy',
    'Labatut',
    'Bourgogne',
    'Aumenancourt',
    'Elnes',
    'Pradelles',
    'Belpech',
    'Saint-Ouen-des-Besaces',
    'Choisey',
    'Saint-Paterne-Racan',
    'Dampmart',
    'Sonzay',
    'Villedomer',
    'Buzet-sur-Tarn',
    'Marigny-Marmande',
    'Clermont',
    'Herin',
    'Montoison',
    'Izier',
    'Camiac-et-Saint-Denis',
    'Campeneac',
    'Orphin',
    'Sars-Poteries',
    'Villeneuve-de-la-Raho',
    'Sequedin',
    'Toussieux',
    'Reuil-en-Brie',
    'Cuon',
    'Denice',
    'Saint-Marc-Jaumegarde',
    'Routot',
    'Montlignon',
    'Chamelet',
    'Corconne',
    'Montilly',
    'Combon',
    'Villiers-le-Bacle',
    'Margency',
    'Saint-Denis-en-Val',
    'Clarensac',
    'Vaux-sur-Seine',
    'Spycker',
    'Uchacq-et-Parentis',
    'Theziers',
    'Tautavel',
    'Chambalud',
    'Denee',
    'Sadirac',
    'La Bresse',
    'Contrisson',
    'Fanjeaux',
    'Wizernes',
    'Seyre',
    'Bazet',
    'Lacanau',
    'Pont-Saint-Vincent',
    'Rosieres-aux-Salines',
    'Cuvilly',
    'Moussy',
    'Pargny-sur-Saulx',
    'Vivier-au-Court',
    'Montreuil-sur-Blaise',
    'Anglure',
    'Boubers-sur-Canche',
    'Lumbres',
    'Neuilly-sous-Clermont',
    'Montherme',
    'Therouanne',
    'Caullery',
    'Delettes',
    'Mailly-Champagne',
    'Ecques',
    'Frevent',
    'Roeux',
    'Esnouveaux',
    'Betheniville',
    'Bettancourt-la-Ferree',
    'Dontrien',
    'Neuflize',
    'Vaux-sur-Blaise',
    'Renneville',
    'Bouilly',
    'Tilloy-et-Bellay',
    'Vadenay',
    'Presles-et-Thierny',
    'Versigny',
    'Cottenchy',
    'Etreillers',
    'Sailly-Flibeaucourt',
    'Charteves',
    'Le Plessier-sur-Bulles',
    'Labruyere',
    'Montier-en-Der',
    'Saint-Eulien',
    'Voillecomte',
    'Romain-sur-Meuse',
    'Cunfin',
    'Boursault',
    'Warnecourt',
    'Saint-Martin-Longueau',
    'Reuilly-Sauvigny',
    'Bruyeres-et-Montberault',
    'Annois',
    'Buironfosse',
    'Montiers',
    'Bourguignon-sous-Coucy',
    'Marcoux',
    'Tourrette-Levens',
    'Saint-Andre-les-Alpes',
    'Villars-Colmars',
    'Barreme',
    'Tanneron',
    'Coaraze',
    'Breil-sur-Roya',
    'Albiosc',
    'Moulinet',
    'Blausasc',
    'Utelle',
    'Vers-en-Montagne',
    'Colombier',
    'Sauxillanges',
    'Barges',
    'Saint-Angel',
    'Lafeuillade-en-Vezie',
    'Viellespeze',
    'Dauzat-sur-Vodable',
    'Le Rouget',
    'Bossey',
    'Chenex',
    'Tours-sur-Meymont',
    'Antignac',
    'Couteuges',
    'Loudes',
    'Dallet',
    'Creuzier-le-Vieux',
    'Le Chambon',
    'Verneuil-en-Bourbonnais',
    'Cistrieres',
    'Bayet',
    'Orcines',
    'Vezezoux',
    'Allonzier-la-Caille',
    'Le Monteil',
    'Veigy-Foncenex',
    'Veyrier-du-Lac',
    'Bellerive-sur-Allier',
    'Coubon',
    'La Frasse',
    'Châtelperron',
    'Lanobre',
    'Vieille-Brioude',
    'Villeneuve-sur-Allier',
    'Teillet-Argenty',
    'Quezac',
    'Gouise',
    'Mezel',
    'Menthon-Saint-Bernard',
    'Dingy-en-Vuache',
    'Gouttieres',
    'Serbannes',
    'Marsat',
    'Espaly-Saint-Marcel',
    'La Chapelaude',
    'Cessy',
    'Brugheas',
    'Chadron',
    'Lepuy',
    'Paray-sous-Briailles',
    'Montbeugny',
    'Neuf Eglise',
    'Chadeleuf',
    'Ytrac',
    'Celles-sur-Durolle',
    'Plauzat',
    'Sardon',
    'Les Breviaires',
    'Bazemont',
    'Fleury-en-Biere',
    'Les Essarts',
    'Lere',
    'Gracay',
    'Civray-de-Touraine',
    'Vouzeron',
    'Mardie',
    'Ecueille',
    'Auffargis',
    'Umpeau',
    'Ecrosnes',
    'Meung-sur-Loire',
    'Monthou-sur-Cher',
    'Saligny-le-Vif',
    'Bridore',
    'Alluyes',
    'Neuvy-en-Beauce',
    'Monthodon',
    'Souesmes',
    'Savigny-sur-Braye',
    'Avord',
    'Negron',
    'Averdon',
    'Moree',
    'Foecy',
    'Tournon-Saint-Martin',
    'Armenonville-les-Gatineaux',
    'Donnery',
    'Courcelles-en-Bassee',
    'Huisseau-sur-Mauves',
    'Courmemin',
    'Vierville',
    'Oisly',
    'Beauchene',
    'Blancafort',
    'Checy',
    'Mer',
    'Nibelle',
    'Ormes',
    'Charmont-en-Beauce',
    'Saint-Lactencin',
    'Autry-le-Chatel',
    'Couddes',
    'Houville-la-Branche',
    'Soulangis',
    'Nonvilliers-Grandhoux',
    'Bessais-le-Fromental',
    'Bourgueil',
    'Veuil',
    'Ange',
    'Chaudon',
    'Sandillon',
    'Boismorand',
    'Gievres',
    'Saint-Julien-de-Chedon',
    'Jouy-le-Potier',
    'Arthon',
    'Antogny le Tillac',
    'Orrouer',
    'Meillant',
    'Marcilly-en-Villette',
    'Selles-Saint-Denis',
    'Selles-sur-Nahon',
    'Pressigny',
    'Saint-Georges-sur-Eure',
    'Epieds-en-Beauce',
    'Reipertswiller',
    'Champenay',
    'Bussieres-les-Belmont',
    'Montsauche-les-Settons',
    'Vaux-la-Petite',
    'Cornimont',
    'Conde-Northen',
    'Bazailles',
    'Blenod-les-Pont-a-Mousson',
    'Bennwihr',
    'Soultz-sous-Forets',
    'Vars',
    'Aissey',
    'Hipsheim',
    'Wildersbach',
    'La Veze',
    'Chevigny',
    'Manom',
    'Saint-Pancre',
    'Repaix',
    'Denting',
    'Uruffe',
    'Lemmes',
    'Saint-Pierre-Bois',
    'Holving',
    'Saint-Aubin-les-Forges',
    'Miellin',
    'Fromont',
    'Guercheville',
    'Beaumont-du-Gatinais',
    'Chevrainvilliers',
    'Recloses',
    'Froncles',
    'Mesnil-Sellieres',
    "Saint-Leu-d'Esserent",
    'Saint-Gratien',
    'Iwuy',
    'Montmorency-Beaufort',
    'Celles-en-Bassigny',
    'Pommiers',
    'Villers-sous-Saint-Leu',
    'Oisy',
    'Calonne-sur-la-Lys',
    'Jonchery-sur-Vesle',
    'Roches-sur-Marne',
    'Hautvillers',
    'Rumigny',
    "Moy-de-l'Aisne",
    'Duisans',
    'Godewaersvelde',
    'Saint-Aubert',
    'Ames',
    'Fournival',
    'Moyenneville',
    'Saint-Imoges',
    'Bourg-Sainte-Marie',
    'Valdampierre',
    'Vignacourt',
    'Bussy-les-Daours',
    'Goincourt',
    'Anais',
    'Arces',
    'Saint-Priest-Taurion',
    'Chateauneuf-la-Foret',
    'Chatenet',
    'Luxey',
    'Masparraute',
    'Escout',
    'Aramits',
    'Poey-de-Lescar',
    'Yzosse',
    'Urdos',
    'Heugas',
    'Agnos',
    'Suhescun',
    'Mornac-sur-Seudre',
    'Masseret',
    'Salles-la-Source',
    'Montestruc-sur-Gers',
    'Saint-Creac',
    'Lacabarede',
    'Guitalens',
    'Lombez',
    'Serres-Castet',
    'Bagnères-de-Luchon',
    'Saint-Georges-de-Poisieux',
    'Mauze-sur-le-Mignon',
    'Sereilhac',
    'Paizay-le-Tort',
    'Messanges',
    'Arette',
    'Levignacq',
    'Serres-Sainte-Marie',
    'Orsanco',
    'Theze',
    'Undurein',
    'Cabariot',
    'Mosnac',
    'Gente',
    'Saint-Clar-de-Riviere',
    'Donzacq',
    'Lagor',
    'Montrabe',
    'Villebrumier',
    'Saint-Viance',
    'Esnandes',
    'Mieussy',
    'Frangy',
    'Champanges',
    'Sergy',
    'Montagnole',
    'Genissiat',
    'Thusy',
    'Vaumas',
    'Venthon',
    'Habere-Lullin',
    'Etaux',
    'Saint-Yorre',
    'Bellentre',
    'Etercy',
    'Faverges',
    'Chapareillan',
    'Seyssel',
    'Allinges',
    'La Lechere',
    'Franclens',
    'Doussard',
    'Saint-Alban-du-Rhone',
    'Saint-Jeoire-Prieure',
    'Taninges',
    'Les Gets',
    'Peillonnex',
    'Jonzier-Epagny',
    'Ballaison',
    'Amancy',
    'Collonges-sous-Saleve',
    'Trezioux',
    'Corbonod',
    'Vailly',
    'Charvonnex',
    'La Rochette-sur-Crest',
    'Thones',
    'Cercier',
    'Morzine',
    'Chamonix',
    'Marin',
    'Megève',
    'Thorens-Glieres',
    'Le Bois',
    'Gerbaix',
    'Saint-Offenge-Dessus',
    'Onnion',
    'Boege',
    'Bonneguete',
    'Mazeuil',
    'Pers',
    'Mauvezin-de-Prat',
    'Saint-Martin-de-Lenne',
    'Saint-Rome-de-Tarn',
    "Saint-Jean-d'Aigues-Vives",
    'Montgesty',
    'Terssac',
    'Lavardens',
    'Saint-Amans-Valtoret',
    'Barbezieux-Saint-Hilaire',
    'Mignaloux-Beauvoir',
    'Saint-Secondin',
    'Auterive',
    'Gouts',
    'Vignes',
    'Siros',
    'Pouille',
    'Betaille',
    'La Sauve',
    'Lavaur',
    'Ouzilly',
    'Rieumes',
    'Borderes',
    'Labastidette',
    'Lautrec',
    'Pont-de-Larn',
    'Peyrusse-Massas',
    'Piquecos',
    'Jegun',
    'Lanta',
    'Loubille',
    'Neuvic-Entier',
    'Pimbo',
    'Saint-Nicolas-de-la-Grave',
    'Mas-Grenier',
    'Villegailhenc',
    'Perillos',
    'Fontpedrosa',
    'Montesquieu-des-Alberes',
    'Corneilla-del-Vercol',
    'Homps',
    'Sainte-Anastasie-sur-Issole',
    'Moussoulens',
    'Domessargues',
    'Tuchan',
    'Popian',
    'Beaufort',
    'La Caunette',
    'Asperes',
    'el Volo',
    'Saint-Papoul',
    'Garons',
    'Peyriac-Minervois',
    'Verzeille',
    'Tourbes',
    'Assas',
    'Peret',
    'Mons',
    'Alzonne',
    'Aigaliers',
    'Notre-Dame-de-la-Rouviere',
    'Les Fonts',
    'Collias',
    'Cuxac-Cabardes',
    'Villeneuve-la-Riviere',
    'Saint-Bauzille-de-Putois',
    'Annot',
    'Roquesteron',
    'La Motte-du-Caire',
    'Longchamp',
    'Amagney',
    'Montagney',
    "La Balme-d'Epy",
    'Maiche',
    'Renève',
    'Fallerans',
    'Meroux',
    'Plottes',
    'Montcey',
    'Faucogney-et-la-Mer',
    'Sellieres',
    'Tamnay-en-Bazois',
    'Liverdun',
    'Hellimer',
    'Jambles',
    'Les Deux-Fays',
    'Champs-sur-Yonne',
    "Saint-Symphorien-d'Ancelles",
    'Le Bonhomme',
    'Wihr-au-Val',
    'Loupershouse',
    'Freistroff',
    'Saint-Jean-Kourtzerode',
    'Zimming',
    'Clenay',
    'Walscheid',
    'Belleville-sur-Meuse',
    'Rosenwiller',
    'Dampierre-les-Bois',
    'Domats',
    'Velaine-en-Haye',
    'Jolivet',
    'Hombourg-Budange',
    'Ciel',
    'Pontigny',
    'Romange',
    'Fresne',
    'Corcelles-les-Citeaux',
    'Juilly',
    'Membrey',
    'Montret',
    'Velle-le-Chatel',
    'Arpenans',
    'Saint-Amand-en-Puisaye',
    'Savigny-sur-Clairis',
    'Beure',
    'Arc-et-Senans',
    'Boesenbiesen',
    'Breitenbach-Haut-Rhin',
    'Kruth',
    'Geneuille',
    'Battrans',
    'Pouilly-en-Auxois',
    'Dommartin-les-Toul',
    'Durstel',
    'Ammerzwiller',
    'Callas',
    'Peipin',
    'Montfroc',
    'Mison',
    'Tende',
    'Revest-des-Brousses',
    'Castelnaud-de-Gratecambe',
    'Voultegon',
    'Saint-Medard-en-Forez',
    'Loubieng',
    'Ygos-Saint-Saturnin',
    'Came',
    'Irissarry',
    'Nere',
    'Lamaziere-Basse',
    'Fressines',
    'Escondeaux',
    'Marcilhac-sur-Cele',
    'Noailhac',
    "Preyssac-d'Excideuil",
    'Berson',
    'Saleich',
    'Angely',
    'Cologne',
    'Biriatou',
    'Larriviere-Saint-Savin',
    'Garrosse',
    'Exoudun',
    'Saint-Augustin',
    'Chaillac-sur-Vienne',
    'Sanxay',
    'Matha',
    'Berdoues',
    'Campuac',
    'Bach',
    'Saint-Jean-de-Laur',
    'Belmontet',
    'Sauveterre-la-Lemance',
    'Narrosse',
    'Auvillar',
    'Nanteuil-en-Vallee',
    'Claix',
    'Brioude',
    'Laussonne',
    'Arpajon-sur-Cere',
    'Le Falgoux',
    'Chilhac',
    'Job',
    'Orleat',
    'Teissieres-de-Cornet',
    'Saint-Remy-sur-Durolle',
    'Ally',
    'Aulhat-Saint-Privat',
    'Lorlanges',
    'Tiranges',
    'Le Chambon',
    'Seuillet',
    'Couzon',
    'Vollore-Ville',
    "Saint-Leopardin-d'Augy",
    'Isle-et-Bardais',
    'Marcillat-en-Combraille',
    'Huriel',
    'Courcais',
    'Saint-Germain-des-Fosses',
    'Saint-Desire',
    'Le Mayet',
    'Lurcy-Levis',
    'Yronde-et-Buron',
    'Lapalisse',
    'Prix',
    'Aydat',
    'Saint-Pont',
    'Saint-Germain-Laprade',
    'Domaize',
    'Le Brugeron',
    'Monetay-sur-Loire',
    'Yssac-la-Tourette',
    'Roannes-Saint-Mary',
    'Beauzac',
    'Ronnet',
    'Saint-Remy-en-Rollat',
    'Chambon-sur-Lac',
    'Saint-Genes-du-Retz',
    'Saint-Ours',
    'La Chapelle-Laurent',
    'Nonette',
    'Montfermy',
    'Ainay-le-Chateau',
    'Mazet-Saint-Voy',
    'Berzet',
    'Pierrefort',
    'Servant',
    'Saint-Jean-des-Ollieres',
    'Malrevers',
    'Chanteuges',
    'Arsac-en-Velay',
    'Saint-Maigner',
    'Vertolaye',
    'Saint-Martin-Valmeroux',
    'Riom-es-Montagnes',
    'Saint-Agoulin',
    'Murat',
    'Chassagnes',
    'Velzic',
    'Bucheres',
    'Frechencourt',
    'Beaucamps-le-Vieux',
    'Hantay',
    'Vaudesson',
    'Folembray',
    'Fralignes',
    'Aigny',
    'Pougy',
    'Breuvannes-en-Bassigny',
    'Valcourt',
    'Premontre',
    'Pouilly-sur-Serre',
    'Corbeny',
    'Thonnance-les-Joinville',
    'Orrouy',
    'Urcel',
    'Flavacourt',
    'Ormoy-Villers',
    'Moulin-sous-Touvent',
    'Pogny',
    'Poligny',
    'Venteuil',
    'Larzicourt',
    'Poinsenot',
    'Courtenot',
    'Chassemy',
    'Leme',
    'Machemont',
    'Vauchelles-les-Quesnoy',
    'Ribemont',
    'Montmartin',
    'Vesigneul-sur-Coole',
    'Bassu',
    'Vaudemange',
    'Montjavoult',
    'Esmery-Hallon',
    'Vendeuil-Caply',
    'Vauchelles',
    'Montigny-Lengrain',
    'Bonneuil-les-Eaux',
    'Thiers-sur-Theve',
    'Montagny-en-Vexin',
    'Blérancourt',
    'Vermand',
    'Vivier',
    'Chacenay',
    'Coupeville',
    'Ambrieres',
    'Chambors',
    'Fresne-Cauverville',
    'Saint-Fuscien',
    'Le Vaumain',
    'Offoy',
    'Rivecourt',
    'Aveluy',
    'Roucy',
    'Margencel',
    'Combloux',
    'Abondance',
    'Fillinges',
    'Challex',
    'Tresserve',
    'Aiton',
    'Cornier',
    'Vinzier',
    'Le Grand-Bornand',
    'Colomieu',
    'Leysse',
    'Surat',
    'Lullin',
    'Champfromier',
    'Landry',
    'Mont-Saxonnex',
    'Chens-sur-Leman',
    'Saint-Gervais-les-Bains',
    'Draillant',
    'Chainaz-les-Frasses',
    'Perrignier',
    'Arbusigny',
    'Metz-Tessy',
    'Lully',
    'La Combelle',
    'Araches-la-Frasse',
    'Laissaud',
    'Moens',
    'Le Bourget',
    'Ceyssat',
    'Neussargues-Moissac',
    'Bellevaux',
    'Arenthon',
    'Villy-le-Pelloux',
    'Cordon',
    'Le Cendre',
    'Le Vernet',
    'Viry',
    'Ygrande',
    'Sales',
    'Sauvessanges',
    'Neydens',
    'Rosieres',
    'Chevrier',
    'Buis-les-Baronnies',
    'Afa',
    'Eygalieres',
    'Greoux-les-Bains',
    'Rognonas',
    'Cabannes',
    'Saumane-de-Vaucluse',
    'Sisco',
    'Sarrola-Carcopino',
    'Tavera',
    'Solaro',
    'Saint-Christol',
    'Baume-les-Messieurs',
    'Veynes',
    'Saint-Michel-lObservatoire',
    'Meyreuil',
    'Le Castellet',
    'Grimaud',
    'Nans-les-Pins',
    'Taradeau',
    'Yvette',
    'Saint-Hilliers',
    'Chevry-Cossigny',
    'Montry',
    'Fourches',
    'Gouze',
    'Urcuit',
    'Sainte-Marie-de-Re',
    'Chire-en-Montreuil',
    'Albas',
    'Lapenche',
    'Saulge',
    'Saint-Martin-de-Riberac',
    'Mouthiers-sur-Boeme',
    'Saint-Laurs',
    'La Coudre',
    'Saint-Georges-de-Didonne',
    'Listrac-Médoc',
    'Durfort',
    'Livron',
    'Lahourcade',
    'Cornac',
    'Bignac',
    'Liguge',
    'Cours-de-Monsegur',
    'Reparsac',
    'Maisontiers',
    'Beauvoir-sur-Niort',
    'Martiel',
    'Darney',
    'Liocourt',
    'Villers-sous-Preny',
    'Viomenil',
    'Rosieres-en-Haye',
    'Bartenheim',
    'Bietlenheim',
    'Chatenois-les-Forges',
    'Montmédy',
    'Athesans-Etroitefontaine',
    'Ecuisses',
    'Gruey-les-Surance',
    'Jeuxey',
    'Allondrelle-la-Malmaison',
    'Cheminot',
    'Diebling',
    'Montreux-Jeune',
    'La Charmee',
    'Magny-sur-Tille',
    'Sassenay',
    'Beinheim',
    'Guerlesquin',
    'Saint-Carne',
    'Locquemeau',
    'Damgan',
    'Lanhelin',
    'Sizun',
    'Plougrescant',
    'Tremel',
    'Logonna-Daoulas',
    'Les Ormes',
    'Bazouges-la-Perouse',
    'Tremblay',
    'Dolo',
    'Saint-Goazec',
    'La Gouesniere',
    'Plouvara',
    'Langrune-sur-Mer',
    'Beauchamps',
    'Fontaine-les-Bassets',
    'Le Renouard',
    'Portbail',
    'Periers',
    'Grentheville',
    'Couvains',
    'Auvillars',
    'Varaville',
    'Marchesieux',
    'Saussey',
    'Courcy',
    'Marcilly-sur-Eure',
    'Reviers',
    'Saint-Jean-des-Champs',
    'Esquay-sur-Seulles',
    'Vauville',
    'Perrou',
    'Monts-en-Bessin',
    'Bacilly',
    'La Meauffe',
    'Saint-Ebremond-de-Bonfosse',
    'Louatre',
    'Moyvillers',
    'Houlle',
    'Campagne-les-Hesdin',
    'Journy',
    'Fontaine-sur-Somme',
    'Coeuvres-et-Valsery',
    'Buire-le-Sec',
    'Chalandry-Elaire',
    'Messon',
    'Thennelieres',
    'Les Petites Loges',
    'Wailly-Beaucamp',
    'Doingt',
    'Hargnies',
    'Mareuil-Caubert',
    'Vauxtin',
    'Lagny-le-Sec',
    'Fressain',
    'Merignies',
    'Camiers',
    'Mortefontaine-en-Thelle',
    'Chouy',
    'Bourbonne-les-Bains',
    'Falaise',
    'Verneuil',
    'Neuvy',
    'Bonnet',
    'Anzat-le-Luguet',
    'Brassac-les-Mines',
    'Queyrieres',
    'Saint-Just-en-Chevalet',
    'Saint-Priest-des-Champs',
    'Montfaucon-en-Velay',
    'Auzon',
    'Le Mont-Dore',
    'Frugeres-les-Mines',
    'Manzat',
    'Marmanhac',
    'Villedieu',
    "Mazeyrat-d'Allier",
    'Champs-sur-Tarentaine-Marchal',
    'Laqueuille',
    'Vergongheon',
    'Charbonnieres-les-Varennes',
    'Moissat',
    'Condat',
    'Effiat',
    'Saint-Gervazy',
    'Ayrens',
    'Saint-Julien-de-Coppel',
    'Olby',
    'Montel-de-Gelat',
    'Andelot-Morval',
    'Saligny-sur-Roudon',
    'Aulnat',
    'Aveze',
    'Olmet',
    'Vigouroux',
    'Lozanne',
    'Le Crest',
    'Paulhac',
    'Vassel',
    'Youx',
    "Beauregard-l'Eveque",
    'Saint-Nectaire',
    'Saint-Mamet-la-Salvetat',
    'Montmarault',
    'Vert',
    'Maffliers',
    'Chaufour-les-Bonnieres',
    'Saint-Louis-de-Montferrand',
    'Sigoules',
    'Fleac',
    'Mourioux-Vieilleville',
    "Bosmie-l'Aiguille",
    'Monsempron-Libos',
    'Grun-Bordas',
    'Villemort',
    'Castelsagrat',
    'Le Vernet',
    'Gourge',
    'Crazannes',
    'Marcay',
    'Venerand',
    'Brieuil-sur-Chize',
    'Samoreau',
    'Courquetaine',
    'Chauvry',
    'Saint-Germain-sous-Doue',
    'Montagny-les-Seurre',
    'Savoyeux',
    'Epertully',
    'Tendon',
    'Belleau',
    'Val-et-Chatillon',
    'Diarville',
    'Sauvigney-les-Pesmes',
    'Pexonne',
    'Montcenis',
    'Lucy-sur-Cure',
    'Saint-Laurent-la-Roche',
    'Longecourt-en-Plaine',
    'Mantoche',
    'Chieulles',
    'Abbeville-les-Conflans',
    'Ogeviller',
    'Uxegney',
    'Friauville',
    'Bellechaume',
    'Pouilley-Francais',
    'Choye',
    'Pommard',
    'Sainte-Lucie de Porto-Vecchio',
    'Calenzana',
    'Santa-Maria-Poggio',
    'Lourmarin',
    'Furiani',
    'Rauville-la-Place',
    "Saint-Lo-d'Ourville",
    'Beaumont-Hague',
    'Le Menil',
    "Cartigny-l'Epinay",
    'Dives-sur-Mer',
    'Grangues',
    'Hebecrevon',
    'Coulouvray-Boisbenatre',
    'Radon',
    'Ranes',
    'Tournieres',
    'Le Mesnil-Vigot',
    'Marolles',
    'Bricqueville-sur-Mer',
    'Moyon',
    'Urou-et-Crennes',
    'Airel',
    'Saint-Quentin-sur-le-Homme',
    'Saint-Martin-de-Blagny',
    'Menil-Erreux',
    'Croisilles',
    'Montgaroult',
    'Contres',
    'Toury',
    'Saint-Georges-sur-Cher',
    'Etrechy',
    'Veretz',
    'Coullons',
    'Civray',
    'Luze',
    'Levroux',
    'Fay-aux-Loges',
    'Neuil',
    'Sainville',
    'Pierres',
    'Chanteau',
    'Lamotte-Beuvron',
    'Saint-Claude-de-Diray',
    'Clery-Saint-Andre',
    'La Selle-en-Hermoy',
    'Chatres-sur-Cher',
    'Saint-Quentin-sur-Indrois',
    'Bonny-sur-Loire',
    'Segry',
    'Valence-sur-Baise',
    'Les Varennes',
    'Pressignac-Vicq',
    'Lavergne',
    'Saint-Pé-dArdet',
    'Galie',
    'Fontcouverte',
    'Touzac',
    'Gagnac-sur-Garonne',
    'Rignac',
    'Pontcirq',
    'Lussac-les-Eglises',
    'Bouex',
    'Combrand',
    'Malegoude',
    'Calmont',
    'Ardillieres',
    'Bord',
    'Vaureilles',
    'Saint-Sulpice-les-Feuilles',
    'Nieul-le-Virouil',
    'Biard',
    'Celon',
    'Bouzy-la-Foret',
    'Chemery',
    'Marville-les-Bois',
    'Neung-sur-Beuvron',
    'La Marolle-en-Sologne',
    'Le Bardon',
    'Luant',
    'Darvoy',
    'Chateauneuf-sur-Loire',
    'Jargeau',
    'Villemeux-sur-Eure',
    'Le Poinconnet',
    'Maslives',
    'Cravant-les-Coteaux',
    'Chabris',
    'Jallans',
    'Neuvy-Saint-Sepulchre',
    'Muides-sur-Loire',
    'Lestiou',
    'Billy',
    'Chatillon-Coligny',
    'Saint-Maurice-sur-Fessard',
    'Chevillon-sur-Huillard',
    'Saint-Avit-les-Guespieres',
    'Saint-Hilaire-Saint-Mesmin',
    'Saint-Genies-Bellevue',
    'Maillebois',
    'Arnouville-les-Mantes',
    'Alban',
    'Sainte-Blandine',
    'La Charme',
    'Gelles',
    'Le Plessis-Robinson',
    'Saint-Priest-la-Roche',
    'Lancieux',
    'La Trimouille',
    'Bidarray',
    'Urt',
    'Saint-Abit',
    'Ordiarp',
    'Garris',
    'Domezain-Berraute',
    'Escou',
    'Gelos',
    'Lespielle',
    'Arraute-Charritte',
    'Helette',
    'Alos-Sibas-Abense',
    'Arbonne',
    'Lons',
    'Ousse',
    'Bernadets',
    'Gabat',
    'Bussunarits-Sarrasquette',
    'Montestrucq',
    'Irouleguy',
    'Anhaux',
    'Auga',
    'Uzein',
    'Lasseube',
    'Bielle',
    "Arthez-d'Asson",
    'Claracq',
    'Woustviller',
    'Remelfing',
    'Chantraine',
    'Chateauneuf-en-Thymerais',
    'Castelnau-Montratier',
    'Tremery',
    'Villaines-sous-Bois',
    'Bergholtz',
    'Montalieu-Vercieu',
    'Les Landes-Genusson',
    'Bretteville-sur-Laize',
    'Wignehies',
    'Neuve-Maison',
    'Castelnau-de-Montmiral',
    'Cepoy',
    "Verneuil-l'Etang",
    'Saint-Amancet',
    'Ablis',
    'Saint-Michel-sur-Meurthe',
    'Rumersheim-le-Haut',
    'Magstatt-le-Haut',
    'Dietwiller',
    'Arpheuilles-Saint-Priest',
    'Boissy-Fresnoy',
    'Bressolles',
    'Savigny',
    'Vouillers',
    'Brienne-la-Vieille',
    'Chalette-sur-Voire',
    'Chailly-en-Biere',
    'Barbatre',
    "Noirmoutier-en-l'Ile",
    'Les Essarts',
    'Langeais',
    'Ginestas',
    'Saint-Eloi',
    'Condezaygues',
    'Saint-Vaast-les-Mello',
    'Coursan',
    'Port-la-Nouvelle',
    'Luc',
    'Castres-Gironde',
    'Fourchambault',
    'Areines',
    'Escames',
    'Pouilly',
    'Feuquieres',
    'Grandvilliers',
    'Orny',
    'Marsais-Sainte-Radegonde',
    'Achatel',
    'Marigny-les-Usages',
    'Rebrechien',
    'Loury',
    'Noailles',
    'Le Pout',
    "Creon-d'Armagnac",
    'Belcodene',
    'Tavaux',
    'Poligny',
    'Les Epesses',
    'Morez',
    'Morbier',
    'Les Rousses',
    'Premanon',
    'Reiners',
    'Amelie-les-Bains-Palalda',
    'Baho',
    'Sant Joan de Pladecorts',
    'Ceret',
    'Sant Genis de Fontanes',
    'Jassans-Riottier',
    'Reiningue',
    'Les Peintures',
    'Saacy-sur-Marne',
    'Saint-Georges-de-Reneins',
    'Chauffailles',
    'La Clayette',
    'Jouy-sur-Morin',
    'Poule-les-Echarmeaux',
    'Anor',
    'Trelon',
    'Gourdan-Polignan',
    'Ousson-sur-Loire',
    'Chatillon-sur-Loire',
    'Le Temple-de-Bretagne',
    'Traubach-le-Bas',
    'Kilstett',
    'Bray-en-Val',
    'Grundviller',
    'Saint-Jean-Rohrbach',
    'Antilly',
    'Coupru',
    'Landelles',
    'Fresnay-le-Gilmert',
    'Airaines',
    'Oisemont',
    'Pierrevillers',
    'Lauterbourg',
    'Saint-Barthelemy-Lestra',
    'Marcilloles',
    'Juilly',
    'Plancher-Bas',
    'Recologne-les-Rioz',
    'Esquerchin',
    'Saint-Fulgent',
    'Brax',
    "Saint-Rambert-d'Albon",
    'Montmeyran',
    'Barbieres',
    'Varzay',
    'Cancale',
    'Orbeil',
    'Dadonville',
    'Massiac',
    'Saint-Flour',
    'Martres-Tolosane',
    'Roquefort-sur-Garonne',
    'Vic-sur-Cere',
    'Maureville',
    'Le Port',
    'Harbonnieres',
    'Entressen',
    'Clermont',
    'Dimechaux',
    'Gouves',
    'Aubigny-en-Artois',
    'Capelle-Fermont',
    'Bourcefranc-le-Chapus',
    'Etaules',
    "Bretteville-l'Orgueilleuse",
    'Pouligney-Lusans',
    'Vieilley',
    'Pelousey',
    'Recologne',
    'Valay',
    'Tassille',
    'Longevelle-sur-Doubs',
    'Etouvans',
    'Chadrac',
    'Craincourt',
    'Gresy-sur-Aix',
    'Layrac',
    'Saint-Pargoire',
    'Astaffort',
    'Bossendorf',
    'Roeschwoog',
    'Haussy',
    'Seltz',
    'Lapalud',
    'Piolenc',
    'Nesles',
    'Rang-du-Fliers',
    'Boisjean',
    'Cucq',
    'Trepied',
    'Vendemian',
    'Marqueglise',
    'Le Mesnil-Amelot',
    'Corbeilles',
    'Fressies',
    'Flines-les-Mortagne',
    'Thun-Saint-Amand',
    'Quievrechain',
    'Salbris',
    'Liffol-le-Grand',
    'Aslonnes',
    'Argeles-Gazost',
    'Ordan-Larroque',
    'Vic-Fezensac',
    'Verdun-sur-le-Doubs',
    'Andancette',
    'Beausemblant',
    'Raches',
    'Sachin',
    'Pernes',
    'Vieux-Berquin',
    'La Gorgue',
    'Saint-Jeoire',
    'Bloye',
    'Saint-Trivier-sur-Moignans',
    'Travecy',
    "Saint-Etienne-d'Albagnan",
    'Saint-Martin-de-Londres',
    'Saint-Mathieu-de-Treviers',
    'Ault',
    'Weislingen',
    'Bray-sur-Somme',
    'Querqueville',
    'Mailly-Maillet',
    'Richardmenil',
    'Ceintrey',
    'Pulligny',
    'Gomelange',
    'Chessy',
    'Marmagne',
    'Coincy',
    'Villers-sur-Fere',
    'Morre',
    'Gerbecourt-et-Haplemont',
    'Montfaucon',
    'Hery-sur-Alby',
    'Vasselay',
    'Anglefort',
    'Viesly',
    'Cers',
    'Bieville-Beuville',
    'Maussane-les-Alpilles',
    'Soultzmatt',
    'Roquefort-la-Bedoule',
    'Saint-Piat',
    'La Dominelais',
    'Bourg-Blanc',
    'Coulandon',
    'Verrieres',
    'Terrasson-Lavilledieu',
    'Mazeray',
    'Marcillac-la-Croisille',
    'Bonzac',
    'Boult-sur-Suippe',
    'Campagne-les-Wardrecques',
    'Marcoing',
    'Marcamps',
    'Saint-Aigulin',
    'Authouillet',
    'Ricarville',
    'Bonnebosq',
    "Angerville-l'Orcher",
    'Le Torquesne',
    'Bourth',
    'Meyras',
    'Saint-Laurent-sur-Gorre',
    'Varetz',
    'La Baussaine',
    'Cheverny',
    'Bozel',
    'Pray',
    'Villesiscle',
    'Sannerville',
    'Saint-Julien-sur-Sarthe',
    'Martainville-Epreville',
    'Saint-Vivien-de-Medoc',
    'Frechou',
    'Montelier',
    'Argeliers',
    'Chalezeule',
    'Turquant',
    'Greolieres',
    'Pargny-Filain',
    'Le Luart',
    'Parce-sur-Sarthe',
    'Pern',
    'Pierregot',
    'Quinsac',
    'La Martiniere',
    'Lauris',
    'La Couronne',
    'Aurons',
    'Saint-Agreve',
    'Saint-Remeze',
    "L'Houmeau",
    'Troussencourt',
    'Meilhan-sur-Garonne',
    "Peypin-d'Aigues",
    'Jouy-sur-Eure',
    'Carcans',
    "Le Mas-d'Agenais",
    'Ingrandes',
    'Balbigny',
    "Saint-Medard-d'Aunis",
    'Les Molieres',
    'Malemort-du-Comtat',
    'Faverolles-sur-Cher',
    'Cublac',
    'Feytiat',
    'Lubersac',
    'Contigne',
    'Aigne',
    'Dourdain',
    'Laugnac',
    'Le Molard',
    'Ceyzeriat',
    'Montverdun',
    "Salleles-d'Aude",
    'Contoire',
    'Saint-Valery-sur-Somme',
    'Puchevillers',
    'Prasville',
    'Sille-le-Guillaume',
    'Tuffe',
    'Saint-Julien-de-Cassagnas',
    'Coirac',
    'Emieville',
    'Plounevezel',
    'Clavette',
    'Aougny',
    'Raucourt-et-Flaba',
    'Audun-le-Roman',
    'Bouer',
    'Lignieres-la-Carelle',
    'Etriac',
    'La Halle',
    "Mesnil-sur-l'Estree",
    'Flamanville',
    'Laguenne',
    "Bagnoles-de-l'Orne",
    'La Vespiere',
    'Meslay',
    'Bourganeuf',
    'La Dagueniere',
    'La Possonniere',
    'Bersee',
    'Fretin',
    'Faumont',
    'Courcemont',
    'Saint-Germain-les-Belles',
    'Saint-Germain-les-Vergnes',
    'Oblinghem',
    'Cagnicourt',
    'Troisvilles',
    'Riantec',
    'Saint-Honore-les-Bains',
    'Garchizy',
    'La Feuillade',
    'Yssandon',
    'Aigremont',
    'Blanzy',
    'Aiguebelle',
    'Serrieres-en-Chautagne',
    'Cours-les-Barres',
    'Saint-Martin-des-Olmes',
    'Saint-Cezaire-sur-Siagne',
    'Nueil-sur-Layon',
    'La Romagne',
    'Fiennes',
    'Rebreuviette',
    'La Fouillouse',
    'Busigny',
    'Saramon',
    'Sainte-Croix-Volvestre',
    'Courbeveille',
    'Saint-Aubin-des-Chateaux',
    'Espira-de-Conflent',
    'Vendin-les-Bethune',
    'Le Pont-de-Claix',
    'Marienthal',
    'Sempigny',
    'Pennautier',
    'Jacou',
    'Orvillers-Sorel',
    'Agny',
    'Chatenoy-le-Royal',
    'La Chapelle-Gauthier',
    'Saint-Victor-sur-Arlanc',
    'Grezieu-la-Varenne',
    'Peronnas',
    'Hauteville-les-Dijon',
    'Givry',
    'Mardeuil',
    'Octeville',
    'Urville-Nacqueville',
    'Crissey',
    'Nouvion-le-Comte',
    'Mesanger',
    'Serezin-du-Rhone',
    'Lucenay',
    'Durtol',
    'Griesheim-pres-Molsheim',
    'Balan',
    'Dagneux',
    'Croissy-Beaubourg',
    'Saleux',
    'Vers-sur-Selles',
    'Hautvillers-Ouville',
    'Tatinghem',
    'Lompret',
    'Nuelles',
    'Angicourt',
    'Brenouille',
    'Mornac',
    'Latour-Bas-Elne',
    'La Grand-Croix',
    'Saint-Paul-en-Jarez',
    'Saint-Martin-la-Plaine',
    'Bouzincourt',
    'Ovillers-la-Boisselle',
    'Meaulte',
    "Saint-Rambert-l'Ile-Barbe",
    'Frucourt',
    'Charnay',
    'Vicq',
    'Athies',
    'Perouse',
    'Danjoutin',
    'Rouffiac-Tolosan',
    'Saint-Amarin',
    'Sigloy',
    'Chaingy',
    'Rochemaure',
    'Grane',
    'Espagnac',
    'LIsle-dEspagnac',
    'Sainte-Fortunade',
    "Civrieux-d'Azergues",
    'Lissieu',
    'Izeaux',
    'Sillans',
    'Carnon-Plage',
    'Penchard',
    'Chanteheux',
    'Neuilly-les-Dijon',
    'Montmorot',
    'Chamboeuf',
    'Fenain',
    'Fein',
    'Cambronne-les-Ribecourt',
    'Pimprez',
    'Algolsheim',
    'Wolfgantzen',
    'Attenschwiller',
    'Orry-la-Ville',
    'Cendras',
    'Vieux',
    'Fragnes',
    'Geanges',
    'Verjux',
    'Pfulgriesheim',
    'Millery',
    'Le Pouzin',
    'Nay',
    'Huttenheim',
    'Jardin',
    'La Ferte-Saint-Cyr',
    'Veyras',
    'Coux',
    'Chancelade',
    'Le Treport',
    'Widensolen',
    'Sundhoffen',
    'Andolsheim',
    'Friville-Escarbotin',
    'Woincourt',
    'Chaleze',
    'La Gavotte',
    'Cruscades',
    'Ahuy',
    'Lancey',
    'Saint-Romain-de-Colbosc',
    'Loyettes',
    'Bonnelles',
    'Mareil-en-France',
    'Fontenay-en-Parisis',
    'Saint-Witz',
    'Pluvault',
    'Rilhac-Rancon',
    'Yversay',
    'Mably',
    'Bailleul-sur-Therain',
    'Warhem',
    'Lupstein',
    'Saint-Andre-de-Corcy',
    'Detrier',
    'Arvillard',
    'Soucieu-en-Jarrest',
    'La Ferte-Alais',
    'Estouches',
    'Chalou-Moulineux',
    'Trilbardou',
    'Precy-sur-Marne',
    'Puisieux',
    'Etrepilly',
    'Viry-Noureuil',
    'Pierremande',
    'Vendegies-sur-Ecaillon',
    'Sireuil',
    'Gensac-la-Pallue',
    'Fesches-le-Chatel',
    'Villers-Saint-Sepulcre',
    'Guerard',
    'Villeneuve-les-Maguelone',
    'Ury',
    'Donnemarie-Dontilly',
    'Villecerf',
    'La Madeleine-sur-Loing',
    'Episy',
    'Paimboeuf',
    'Saint-Martin-de-Fontenay',
    'Ottrott',
    'Sainte-Marie-aux-Mines',
    'Chavagne',
    "Chonas-l'Amballan",
    'Caudrot',
    'Saint-Prim',
    "Saint-Remy-l'Honore",
    'Tacoignieres',
    'Nogent-le-Phaye',
    'Happonvilliers',
    'Neron',
    'Saint-Martin-de-Nigelles',
    'Logelbach',
    'Arnieres-sur-Iton',
    'Hordain',
    'Bouchain',
    'Mastaing',
    'Courteranges',
    'Saultain',
    'Jujurieux',
    'Mionnay',
    'Leudeville',
    'Courson-Monteloup',
    'Chalo-Saint-Mars',
    'Coutevroult',
    'Boussens',
    'Villieu-Loyes-Mollon',
    'Saint-Eloi',
    'Boulzicourt',
    "Nogent-l'Artaud",
    'Grand-Rozoy',
    'Orlu',
    'Clairoix',
    'Voulx',
    'Dormelles',
    'Cadillac-en-Fronsadais',
    'Saint-Geoirs',
    'Limoges-Fourches',
    'Crisenoy',
    'La Bridoire',
    'Saint-Beron',
    'Romagnieu',
    'Heiligenberg',
    'Gresswiller',
    'Mettray',
    'Boigneville',
    'Beguey',
    'Cadillac',
    'Poigny-la-Foret',
    'Alissas',
    'Schoeneck',
    'Pfettisheim',
    'Pomeys',
    'Coise-Saint-Jean-Pied-Gauthier',
    'Saint-Gilles-des-Marais',
    'Tossiat',
    'Journans',
    'Chalamont',
    'Montceaux-les-Meaux',
    'Changis-sur-Marne',
    'Saint-Jean-les-Deux-Jumeaux',
    'Sailly-Labourse',
    'Saint-Georges-sur-Fontaine',
    'Mannevillette',
    'Carneville',
    'Saugon',
    'Berville-sur-Seine',
    'Cormes',
    'Cherreau',
    'Chauffry',
    'Villemarechal',
    'Lorrez-le-Bocage-Preaux',
    'Bransles',
    'Chenou',
    'Chatenoy',
    'Marlenheim',
    'Saint-Symphorien-dOzon',
    'Mortcerf',
    'Betz',
    'Guines',
    'Razac-sur-lIsle',
    'Fremainville',
    'Latresne',
    'Tourville-sur-Sienne',
    'Galgon',
    'Ittenheim',
    'Marmoutier',
    'Dimbsthal',
    'Bourogne',
    'Beaucourt',
    'Cuzieu',
    'Saint-Andre-le-Puy',
    'Bar-sur-Aube',
    'Goupillieres',
    'Villiers-le-Mahieu',
    'Marcq',
    'La Feuillie',
    'Villers-Semeuse',
    'Pencran',
    'Saint-Vigor',
    'Barbizon',
    'Le Pont',
    'Malissard',
    'Esery',
    'Roppe',
    'Phaffans',
    'Champeaux',
    'Devecey',
    'Auxon-Dessous',
    'Oingt',
    'Frontenas',
    'Les Olmes',
    'Saint-Thibault-des-Vignes',
    'Pollestres',
    'Druillat',
    'Andance',
    'Fleurey-sur-Ouche',
    'Perruel',
    'Marssac-sur-Tarn',
    'Florentin',
    'Redessan',
    'Cande-sur-Beuvron',
    'Saint-Sixt',
    'Chatenay-sur-Seine',
    'Lachapelle-sous-Chaux',
    'Lepuix',
    'Giromagny',
    'Le Sacq',
    'Saint-Bauzille-de-Montmel',
    'Ouroux',
    'Ouroux-sur-Saone',
    'Etrelles',
    'Alby-sur-Cheran',
    'Wentzwiller',
    'Folgensbourg',
    'Nothalten',
    'Saint-Martin-des-Bois',
    'Albias',
    'Rosny-sur-Seine',
    'Porcheville',
    'La Guerche-de-Bretagne',
    'Killem',
    "Saint-Medard-d'Eyrans",
    'Parempuyre',
    'Chatonnay',
    'Villars-Brandis',
    'Moutiers',
    'Monneville',
    'Neuville-Bosc',
    'Villargondran',
    'Bessens',
    'Saint-Hilaire-lez-Cambrai',
    'Saint-Pardoux-du-Breuil',
    'Miramont-de-Guyenne',
    'Saint-Fiacre-sur-Maine',
    'Haute-Isle',
    'Ponthevrard',
    'Fontenay-le-Vicomte',
    'Beaurains',
    'Vriange',
    'Feigeres',
    'Le Louroux-Beconnais',
    'Saint-Sernin-du-Bois',
    'Le Breuil',
    'Saint-Morillon',
    'Le Tourne',
    'Mons-en-Pevele',
    'Souvigne-sur-Sarthe',
    'Chatel-de-Neuvre',
    'Pontcarre',
    'Saint-Cyr-la-Campagne',
    'Le Thuit-Signol',
    'Warlus',
    'Aubers',
    'Fromelles',
    'Lescherolles',
    'Villiers-Saint-Georges',
    'Trainel',
    'Dhuisy',
    'Tancrou',
    'Jouy-le-Chatel',
    'Everly',
    'Pamfou',
    'Arinthod',
    'Belgentier',
    'Le Champ-pres-Froges',
    'Gironcourt-sur-Vraine',
    'La Terrasse',
    'Lumbin',
    'Etables',
    'Barisey-au-Plain',
    'Cepet',
    'Jalogny',
    'Porcieu-Amblagnieu',
    'Briennon',
    'Eveux',
    'Guegon',
    'Gourhel',
    'Taupont',
    'Chasselay',
    'Chevannes',
    'Villaries',
    'Vacquiers',
    'Boissise-la-Bertrand',
    'Boulancourt',
    'Villetelle',
    'Verargues',
    'Serres-Morlaas',
    'Villotte-sur-Aire',
    'Chanonat',
    'La Roche-Blanche',
    'Saint-Gelais',
    'Themericourt',
    'Commeny',
    'Oelleville',
    'Bressolles',
    'Nievroz',
    'Ballan-Mire',
    'Lempdes',
    'Longchamp-sous-Chatenois',
    'Rouvres-la-Chetive',
    'Thoissey',
    'Bligny-les-Beaune',
    'Chassey-le-Camp',
    'Puligny-Montrachet',
    'Saint-Martin-du-Bois',
    'Sailly-lez-Cambrai',
    'Roumazieres',
    'Saint-Rogatien',
    'Granges-sur-Vologne',
    'Aumontzey',
    'Foussemagne',
    'Novillard',
    'Dierre',
    'Saint-Germain-des-Pres',
    'Chailly-en-Gatinais',
    'Saint-Benoit-sur-Loire',
    'Ladon',
    'Garlin',
    'Dry',
    'Boynes',
    'Trigueres',
    'Ascheres-le-Marche',
    'Coudroy',
    'Estouy',
    'Briarres-sur-Essonne',
    'Batilly-en-Gatinais',
    'Griselles',
    'Nogent-sur-Vernisson',
    'Sermaises',
    'Douchy',
    'Ardon',
    'Ligny-le-Ribault',
    'Auvilliers-en-Gatinais',
    'Ondreville-sur-Essonne',
    'Charsonville',
    'Sennely',
    'Cerdon',
    'Mareau-aux-Pres',
    'Thou',
    'Girolles',
    'Erceville',
    'Guigneville',
    'Tivernon',
    'Boulay-les-Barres',
    'Tournoisis',
    'Rozieres-en-Beauce',
    'Sougy',
    'Yevre-la-Ville',
    'Saint-Maurice-sur-Aveyron',
    'Artenay',
    'Chatillon-le-Roi',
    'Bazoches-sur-le-Betz',
    'Andonville',
    'Saint-Brisson-sur-Loire',
    'Bonnee',
    'Dampierre-en-Burly',
    'Arrabloy',
    'Ascoux',
    'Bazoches-les-Gallerandes',
    'Louzouer',
    'Villemurlin',
    'Beaulieu-sur-Loire',
    'Gidy',
    'Bouafles',
    'Ettendorf',
    'Puyreaux',
    'Les Loges-en-Josas',
    'Etzling',
    'Imling',
    'Saint-Etienne-la-Varenne',
    'Etaimpuis',
    'Saint-Donan',
    'Le Mesnil-le-Roi',
    'Etauliers',
    'Merigny',
    'Roquebilliere',
    'Villabon',
    'Ferin',
    'Lussat',
    'La Greve-sur-Mignon',
    'Conches-sur-Gondoire',
    'Queyrac',
    'Marsainvilliers',
    'Camlez',
    'Chitenay',
    'Courcerac',
    'Esclainvillers',
    'Sainte-Anne-sur-Vilaine',
    'Tregourez',
    'Sainte-Marguerite-des-Loges',
    'Mornay-sur-Allier',
    'Auzouer-en-Touraine',
    'La Guierche',
    'Lassigny',
    'Hesingue',
    'Baulne',
    'Gometz-la-Ville',
    'Saint-Aunes',
    'Lavardin',
    'Uberach',
    'Juigne-sur-Loire',
    'Frauenberg',
    'Moult',
    'Aveize',
    'Moulins-Engilbert',
    'Ossun',
    'Loire-les-Marais',
    'Ferrieres',
    'Saint-Jean-le-Centenier',
    'Saint-Uze',
    'Montvendre',
    'Colombier-le-Vieux',
    'Saint-Christophe-du-Bois',
    'Thaire',
    'Sceaux-sur-Huisne',
    'Souligne-sous-Ballon',
    'Rochegude',
    'Villevocance',
    'Ancone',
    "Saint-Alban-d'Ay",
    'Bazouges-sur-le-Loir',
    'Flee',
    'Saint-Augustin',
    'Roiffieux',
    'Epinouze',
    'Vallans',
    'Chambon',
    'Saint-Victurnien',
    'Fresnay-sur-Sarthe',
    'Donzy-le-Pertuis',
    'Peaugres',
    'Sault',
    'Vitrimont',
    'Bully',
    'Morance',
    'Liergues',
    'Marnes-la-Coquette',
    'Jussy',
    'Alteckendorf',
    'Saint-Andre-Farivillers',
    'Bonlier',
    'Ivry-le-Temple',
    'Allonne',
    'Silly-le-Long',
    'Ver-sur-Launette',
    'Antilly',
    'Ressons-sur-Matz',
    'Plessis-de-Roye',
    'Longueil-Sainte-Marie',
    'Angy',
    'Bailly',
    'Monceaux',
    'Gremevillers',
    'Rousseloy',
    'Moliens',
    'Formerie',
    'Fresnoy-la-Riviere',
    'Rouville',
    'Tartigny',
    'Beauvoir',
    'Valescourt',
    'Cuigy-en-Bray',
    'Rainvillers',
    'Songeons',
    'Berneuil-sur-Aisne',
    'Marseille-en-Beauvaisis',
    'Cuvergnon',
    'Cuise-la-Motte',
    'Dernancourt',
    'Saint-Leger-les-Domart',
    'Ayencourt',
    'Canaples',
    'Criel-sur-Mer',
    'Morisel',
    'Ham',
    'Coivrel',
    'Saint-Aubin-sous-Erquery',
    'Eppeville',
    'Jonquieres',
    'Liancourt-Saint-Pierre',
    'Fourdrinoy',
    'Saint-Aubin-en-Bray',
    'Ablaincourt-Pressoir',
    'Annesse-et-Beaulieu',
    'Marsac-sur-lIsle',
    'Coursac',
    'Dauendorf',
    'Sayat',
    'Beaumont',
    'Wimille',
    'Hautot-sur-Mer',
    'Mattexey',
    'Locmaria-Grand-Champ',
    'Mine de Bert',
    'Hastingues',
    'Moussy-le-Neuf',
    'Montredon-des-Corbieres',
    'Er',
    'Espezel',
    'Molieres-sur-Ceze',
    'Fons',
    'Nevian',
    'Saint-Etienne-du-Valdonnez',
    'Mauressargues',
    'Aubord',
    'Saint-Mamert-du-Gard',
    'Siran',
    'Arrigas',
    'Valliguieres',
    'Pouzilhac',
    'Cazevieille',
    'Estagel',
    'Cazilhac',
    'Fendeille',
    'Graissessac',
    'Prades',
    'Fosse',
    'Fressac',
    'Vacquieres',
    'Montbel',
    'Nizas',
    'Montpezat',
    'Saint-Dionisy',
    'Embry',
    'Aubry-du-Hainaut',
    'Le Doulieu',
    'Auchy-lez-Orchies',
    'Frelinghien',
    'Soulanges',
    'Peroy-les-Gombries',
    'Tricot',
    'Amigny-Rouy',
    'Pont-de-Metz',
    'Cliron',
    'Montmirail',
    'Gouy-en-Ternois',
    'Savy-Berlette',
    'Burbure',
    'Rodelinghem',
    'Recques-sur-Course',
    'Bussy-le-Chateau',
    'Blingel',
    'Hubersent',
    'La Houssoye',
    'Pont-Noyelles',
    'Grandchamp',
    'Dammartin-en-Serve',
    'Breuil',
    'Condecourt',
    'Saulx-Marchais',
    'Les Jumeaux',
    'Fericy',
    'Gressy',
    'Grainville',
    'Marlotte',
    'Villevaude',
    'Liverdy-en-Brie',
    'Mons-en-Montois',
    'Espirat',
    'Le Theil',
    'Roffiac',
    'Bergonne',
    'Saint-Hippolyte',
    'Coudes',
    'Commentry',
    'Le Breuil-sur-Couze',
    'Lachaux',
    'Segonzat',
    'La Chaise',
    'Saint-Sandoux',
    'Champagnac-le-Vieux',
    'Pignols',
    'Vallon-en-Sully',
    'Chauriat',
    'Gennetines',
    'Le Donjon',
    'Saint-Didier-en-Donjon',
    'Le Broc',
    'Saint-Floret',
    'Vernines',
    'Saint-Maurice-de-Lignon',
    "Ceaux-d'Allegre",
    'Fayet-le-Chateau',
    'Parent',
    'Le Vigean',
    'Landogne',
    'Culhat',
    'Felines',
    'Malicorne',
    'Pebrac',
    'Paslieres',
    'Randan',
    'Bains',
    'Perrier',
    'Le Pin',
    'Lacapelle-Viescamp',
    'Fournols',
    "Chirat-l'Église",
    'Fleuriel',
    'Brousse',
    'Les Ancizes-Comps',
    'Menetrol',
    'Abrest',
    'Loubeyrat',
    'Verneugheol',
    'Colondannes',
    'Exideuil',
    'Roquecor',
    'Le Segur',
    'Cardaillac',
    'Caragoudes',
    'Puybegon',
    'Burlats',
    'Cazaux-Layrisse',
    'Rouffignac-Saint-Cernin-de-Reilhac',
    'Salles-de-Barbezieux',
    'Fougeres',
    'Salles-Mongiscard',
    'Castels',
    'Loupes',
    'Donnezac',
    'Meilhan',
    'Le Pizou',
    'Saint-Romain-la-Virvee',
    'Sarrazac',
    'Vaulry',
    'Idrac-Respailles',
    'Saint-Laurent-de-Levezou',
    'Saint-Elix-Seglan',
    'Castelnau-de-Levis',
    'Tournecoupe',
    'Conne-de-Labarde',
    'Sainte-Eulalie-en-Born',
    'Mescoules',
    "Razac-d'Eymet",
    'Pissos',
    'Sainte-Foy-de-Longas',
    'Chaignay',
    'Savouges',
    'Pouilley-les-Vignes',
    'Lixheim',
    'Ars-Laquenexy',
    'Boucheporn',
    'Jarmenil',
    'Gosselming',
    'Damerey',
    'Le Planois',
    'Rodern',
    'Hindlingen',
    'Bisel',
    'Echavanne',
    'Bougnon',
    'Dounoux',
    'Nomeny',
    'Benney',
    'Manoncourt-sur-Seille',
    'Bazien',
    'Le Gratteris',
    'Olwisheim',
    'Fey',
    'Gerbeviller',
    'Marcilly-en-Bassigny',
    "L'Etang-Vergy",
    'Thal-Drulingen',
    'Kesseldorf',
    'Daubensand',
    'Darnieulles',
    'Etival',
    'Saint-Martin-du-Puy',
    'Dancevoir',
    'Montcoy',
    'Champdotre',
    'Thenissey',
    'Chalampe',
    'Chichery',
    'Laneuvelotte',
    'Brin-sur-Seille',
    'Buthiers',
    'Aumont',
    'Precy-sur-Vrin',
    'Lamarche',
    'Chaligny',
    'Valmestroff',
    'Troissy',
    'Lambert',
    'Mareuil-le-Port',
    'Pernes-les-Boulogne',
    'Estree-Blanche',
    'Boutancourt',
    'Reumont',
    'Moyenneville',
    'Moulle',
    'Tubersent',
    'Leval',
    'Villers-Helon',
    'Passel',
    'Villenauxe-la-Grande',
    'Chatonrupt-Sommermont',
    'Pouan-les-Vallees',
    'Enencourt-le-Sec',
    'Wirwignes',
    'Thivencelle',
    'Clairmarais',
    'Esquelbecq',
    'Stella-Plage',
    'Fauquembergues',
    'Roquetoire',
    'Selles',
    'Rouvroy-sur-Audry',
    'Laifour',
    'Berthen',
    'Saint-Floris',
    'Rouilly-Sacey',
    'Elincourt',
    'Plumoison',
    'Barastre',
    'Mametz',
    'Courcelles-Epayelles',
    'Villers-Saint-Frambourg',
    'Mondrepuis',
    'Chatres',
    'Compertrix',
    'Ville-sur-Lumes',
    'Leuilly-sous-Coucy',
    'Estrees-les-Crecy',
    'Wandignies-Hamage',
    'Croisette',
    'Maninghen-Henne',
    'Pierrerue',
    'Vescovato',
    'Taglio-Isolaccio',
    'Châteauneuf-du-Pape',
    'Belgodere',
    'Castello-di-Rostino',
    'Cairanne',
    'Puimichel',
    'Menerbes',
    'Muro',
    'Poggio-dOletta',
    "Saint-Privat-d'Allier",
    'Loubaresse',
    'Mezeres',
    "Villefranche-d'Allier",
    'Neure',
    'Allier',
    'Saint-Front',
    'Madriat',
    'Salzuit',
    'Fontannes',
    'Dingy-Saint-Clair',
    'Crevant-Laveine',
    'Salers',
    "Saint-Dier-d'Auvergne",
    'Roumegoux',
    'Villebret',
    'Sauret-Besserve',
    'Champagnac',
    'La Forie',
    'Auvers',
    'Malintrat',
    'Vorey',
    'Aigueperse',
    'Chateldon',
    'Viplaix',
    'Desertines',
    'Saint-Jeures',
    'Beon',
    'Pessat-Villeneuve',
    'Olloix',
    'Saint-Illide',
    'Thuret',
    'Trizac',
    'Souvigny',
    'Lyaud',
    'Herisson',
    'La Bourboule',
    'Solignac-sur-Loire',
    'Saint-Offenge-Dessous',
    'Menat',
    'Pralognan-la-Vanoise',
    'Chapeiry',
    'Armoy',
    'Saint-Bonnet-les-Allier',
    'Ollieres',
    'Juvigny',
    'Toulouzette',
    'Saint-Paul',
    'Laurede',
    'Courlay',
    'Villejesus',
    'Chamberet',
    'Vieux-Boucau-les-Bains',
    'Atur',
    'Castelmoron-sur-Lot',
    'Saint-Amand-de-Belves',
    'Maurens',
    'Saint-Michel-de-Rieufret',
    'Riberac',
    'Antagnac',
    'Segalas',
    'Moulin-Neuf',
    'Anglade',
    "Les Eglises-d'Argenteuil",
    "Faye-l'Abbesse",
    'Tecou',
    'els Banys de la Presta',
    "Saint-Geours-d'Auribat",
    'Abere',
    'Lit-et-Mixe',
    'Villefranche-du-Queyran',
    'Lussac-les-Chateaux',
    'Eybouleuf',
    'Gardonne',
    'Trie-sur-Baise',
    'Virelade',
    'Corgnac-sur-lIsle',
    'Chenaud',
    'Abzac',
    'Saint-Julien-de-Lampon',
    'Cameyrac',
    'Candresse',
    'Monferran-Saves',
    'Magnan',
    'Beyrede-Jumet',
    'Bascons',
    'Thorigny',
    'Bouin',
    'Lavardin',
    'Bouere',
    'La Chapelle-Heulin',
    'Andreze',
    'La Plaine',
    'Le Mesnil-en-Vallee',
    'Champtoce-sur-Loire',
    'Souzay-Champigny',
    'Le Marillais',
    'Mansigne',
    'Saint-Martin-du-Bois',
    'Saint-Remy-la-Varenne',
    'La Chataigneraie',
    'Bel Air',
    'Trange',
    'Rablay-sur-Layon',
    'La Marche',
    'Astille',
    'Vendrennes',
    'Serigne',
    'Corze',
    'Rammersmatt',
    'Vantoux',
    'Avril',
    'Soing-Cubry-Charentenay',
    'Maux',
    'Bierry-les-Belles-Fontaines',
    'Heiteren',
    'Poilly-sur-Tholon',
    'Griselles',
    'Cessey',
    'Bosjean',
    'Villevallier',
    'Magny-les-Villers',
    'Sommerviller',
    'Champey-sur-Moselle',
    'Fains-Veel',
    'Champenoux',
    'Waldweistroff',
    'Mondelange',
    'Molesme',
    'Dornes',
    'Dompierre-les-Ormes',
    'Vernierfontaine',
    'Soissons-sur-Nacey',
    'Matzenheim',
    'Maidieres',
    'Rurange-les-Thionville',
    'Bellefontaine',
    'Chambley-Bussieres',
    'Ruffey-les-Echirey',
    'Courgis',
    'Lenoncourt',
    'Villers',
    'Plombieres-les-Bains',
    'Foameix-Ornel',
    'Manonviller',
    'Hultehouse',
    'Crusnes',
    'Bocquegney',
    'Corgoloin',
    'Vincelles',
    'Grozon',
    'Roye',
    'Aze',
    'Echenans',
    'Ronno',
    'Arlebosc',
    'Remuzat',
    'Courzieu',
    'Chuzelles',
    'Peray',
    'Saint-Cyr-le-Chatoux',
    'Violay',
    'Lurcy',
    'Sarras',
    'Chabrillan',
    "Albon-d'Ardeche",
    'Dompierre-sur-Veyle',
    'Rozier-en-Donzy',
    'Marols',
    'Saint-Bonnet-le-Chateau',
    'Ville-sous-Anjou',
    'Saint-Andre-en-Vivarais',
    "Saint-Laurent-d'Agny",
    'Champdieu',
    'Saint-Clair-sur-Galaure',
    'Chambonas',
    'Lezigneux',
    'Saint-Sixte',
    'Groissiat',
    'Vocance',
    'Mirabel-aux-Baronnies',
    'Saint-Denis-sur-Coise',
    'Les Etangs',
    'Beauvene',
    'Saint-Michel-de-Chabrillanoux',
    'Chevalet-le-Haut',
    'Saint-Victor-sur-Rhins',
    'Thoirette',
    'Genet',
    'Versailleux',
    'Ancy',
    'Sainte-Consorce',
    'Merignat',
    'Mours-Saint-Eusebe',
    'Chalencon',
    'Saint-Romain-les-Atheux',
    'Doissin',
    'Serezin-de-la-Tour',
    'Saint-Georges-Haute-Ville',
    'Dargoire',
    'Saint-Andeol-de-Vals',
    'Berzeme',
    'Quincie-en-Beaujolais',
    'Saint-Jean-des-Vignes',
    'Chozeau',
    'Perrex',
    'Birieux',
    'Saint-Genest-Malifaux',
    'Jons',
    'Lapeyrouse-Mornay',
    'Dareize',
    'Faverges-de-la-Tour',
    'Artas',
    'Malmont',
    'Saint-Trivier-de-Courtes',
    'Saint-Pierre-de-Boeuf',
    'Vaulx-Milieu',
    'Romain',
    'Saint-Pierre-de-Bressieux',
    'Saint-Victor-sur-Loire',
    'Lent',
    'Tremons',
    'Neuvy-Bouin',
    'Celle-Levescault',
    'Marnes',
    'Doeuil-sur-le-Mignon',
    'Alloue',
    'Eyjeaux',
    'Adriers',
    'La Perche',
    'Rouille',
    'Saint-Maurice-la-Souterraine',
    'Usson-du-Poitou',
    'Aureil',
    'Firmi',
    'Frejairolles',
    'Villeneuve-de-Marsan',
    'Le Porge',
    'Epenede',
    'Les Nouillers',
    'Payroux',
    'Saint-Ciers-sur-Gironde',
    'Gironde-sur-Dropt',
    'Bernay-Saint-Martin',
    'La Rochenard',
    'Cissac-Medoc',
    'Gondeville',
    'Oradour-Fanais',
    'Chaban',
    'Boutiers-Saint-Trojan',
    'Montendre',
    'Chamboulive',
    'Soreze',
    'Brax',
    'Mercues',
    'Noaillan',
    'Verdelais',
    'Vezac',
    'Excideuil',
    'Saint-Pierre-du-Palais',
    'Orches',
    'Grainville-sur-Odon',
    'Cesny-aux-Vignes',
    'Moyaux',
    'Marchainville',
    'Ronthon',
    'Le Fresne-Camilly',
    'Omonville-la-Rogue',
    'Eroudeville',
    'Pertheville-Ners',
    'Landelles-et-Coupigny',
    'Fontaine-Etoupefour',
    'Gilles',
    'Nouainville',
    'Barbeville',
    'Treauville',
    'Champs',
    'Saint-Pair-du-Mont',
    'Tollevast',
    'Auxais',
    'Vascœuil',
    'Fauville-en-Caux',
    'Martigny',
    'Allouville-Bellefosse',
    'Saint-Jean-du-Cardonnay',
    'Reuil-sur-Breche',
    'Ry',
    'La Vaupaliere',
    'Cierrey',
    'Boulleville',
    'Sotteville-sur-Mer',
    'Aignan',
    'Tourville-la-Campagne',
    'Le Thuit-Simer',
    'Courcelles-les-Gisors',
    'Fontaine-Bonneleau',
    'Cavillon',
    'Fontenelle-en-Brie',
    'Luchy',
    'Saint-Venant',
    'Huppy',
    'Guivry',
    'Bouzy',
    'Aubrives',
    'Fampoux',
    'Sissy',
    'Willencourt',
    'Ecury-sur-Coole',
    'Fays-la-Chapelle',
    'Margny',
    'Avancon',
    'Bulles',
    'Wailly',
    'Mailly-le-Camp',
    'Ham-sur-Meuse',
    'Ham-en-Artois',
    'Hestrus',
    'Bavincourt',
    'Camblain-lAbbé',
    'Dieval',
    'Saint-Germain-la-Ville',
    'Perthes-les-Brienne',
    'Pont-Saint-Mard',
    'Croissy-sur-Celle',
    'Saint-Gobain',
    'Ercheu',
    'Chatillon-les-Sons',
    'Bosmont-sur-Serre',
    'Jalons',
    'Neuville-sur-Seine',
    'Blagny',
    'Les Mesneux',
    'Mairy-sur-Marne',
    'Vitry-en-Perthois',
    'Avon-la-Peze',
    'Rethondes',
    'Vieille-Eglise',
    'Sailly-sur-la-Lys',
    'Essoyes',
    'Aix-en-Othe',
    'Aubin-Saint-Vaast',
    'Wambercourt',
    'Fiefs',
    'Acq',
    'Gombergean',
    'Givraines',
    'Saint-Aignan-des-Noyers',
    'Trainou',
    'Chisseaux',
    'Saint-Christophe-sur-le-Nais',
    'Menetreol-sur-Sauldre',
    'Fougeres-sur-Bievre',
    'Genille',
    'Saint-Sigismond',
    'Chenonceaux',
    'Sury-en-Vaux',
    'Menestreau-en-Villette',
    'Dampierre-sur-Blevy',
    'Ports',
    'Saint-Georges-sur-Arnon',
    'Pruniers',
    'Marce-sur-Esves',
    'Molineuf',
    'Druye',
    'Montresor',
    'Vouvray',
    'Montierchaume',
    'Sassay',
    'Neuvy-en-Sullias',
    'Gatelles',
    'Cormeray',
    'Clemont',
    'Verigny',
    'Oizon',
    'Parnay',
    'Sambin',
    'Levainville',
    'Morancez',
    'Belleville-sur-Loire',
    'Vert-en-Drouais',
    'Villaines-les-Rochers',
    'Vimory',
    'Pouille',
    'Mazange',
    'Luzille',
    'Mont-pres-Chambord',
    'Marcilly-sur-Vienne',
    'La Berthenoux',
    'Pellevoisin',
    'Barrou',
    'Montlivault',
    'Thenay',
    'Busloup',
    'Bossay-sur-Claise',
    'Villiers-au-Bouin',
    'Noyant-de-Touraine',
    'Courville-sur-Eure',
    'Braye-sous-Faye',
    'Sainte-Fauste',
    'Semoy',
    'Leouville',
    'Janville',
    'Broue',
    'Prunay-Cassereau',
    'Chedigny',
    'Arrou',
    'La Bussiere',
    'Cellettes',
    'Fontaine-Simon',
    'Villeneuve-Frouville',
    'Saint-Pellerin',
    'Gy-en-Sologne',
    'Huisseau-sur-Cosson',
    'Villers-les-Ormes',
    'Rouziers-de-Touraine',
    'Montainville',
    'Mezieres-en-Drouais',
    'Saint-Flovier',
    'La Guerche',
    'Pouzay',
    'Savigny-en-Septaine',
    'Epuisay',
    'Maron',
    'Courcay',
    'Manthelan',
    'Yermenonville',
    'La Celle',
    'Saint-Firmin-des-Pres',
    'Santranges',
    'Ennordres',
    'Jeu-les-Bois',
    'Neuvy-le-Roi',
    'Souvigny-en-Sologne',
    'Ouarville',
    'Montlouis',
    'Loche-sur-Indrois',
    'Onzain',
    'Moulins-sur-Cephons',
    'Savonnieres',
    'Chataincourt',
    'Theillay',
    'Chaillac',
    'Argent-sur-Sauldre',
    'Thimory',
    'Conflans-sur-Loing',
    'Lury-sur-Arnon',
    'Ervauville',
    'Josnes',
    'Valaire',
    'Monthou-sur-Bievre',
    'Pressigny-les-Pins',
    'Vennecy',
    'Aunay-sous-Auneau',
    'Cuffy',
    'La Celle',
    'Niherne',
    'Ternay',
    'Tour-en-Sologne',
    'Mur-de-Sologne',
    'Chantome',
    'Cangey',
    'Prouais',
    'Touchay',
    'Bricy',
    'Valencay',
    'Montigny',
    'Les Montils',
    'Chanceaux-sur-Choisille',
    'Saint-Bohaire',
    'Belleville-en-Caux',
    'Vandrimare',
    'Senneville-sur-Fecamp',
    'Saint-Leger-du-Bourg-Denis',
    'Fleury-sur-Andelle',
    'Saint-Martin-du-Tilleul',
    'Aumale',
    'Jumièges',
    'Bouville',
    'Biville-sur-Mer',
    'Croix-Mare',
    'Ecaquelon',
    'Ecalles-Alix',
    'Tocqueville-en-Caux',
    'Bacqueville-en-Caux',
    'Bondeville',
    'Thierville',
    'Saint-Ouen-du-Breuil',
    'Theuville-aux-Maillots',
    "Criquetot-l'Esneval",
    'Boissey-le-Chatel',
    'Villez-sous-Bailleul',
    'Saint-Martin-du-Bosc',
    'Vaux-sur-Eure',
    'Huest',
    'Arques-la-Bataille',
    'Moulineaux',
    'Caumont',
    'Irreville',
    'Claville',
    'Le Landin',
    'Prey',
    'Harcanville',
    'Fatouville-Grestain',
    'Orvaux',
    'Le Roncenay-Authenay',
    'Authou',
    'Le Fidelaire',
    'Motteville',
    'Goupillieres',
    'Colleville',
    'Broglie',
    'Toussaint',
    'Campagne',
    'Avremesnil',
    'Quillebeuf-sur-Seine',
    'Bois-Anzeray',
    'Annouville-Vilmesnil',
    'Limesy',
    'Saint-Georges-du-Vievre',
    'Fontaine-sous-Jouy',
    'Nointot',
    'Maneglise',
    'Raffetot',
    'Guerville',
    'Avesnes-en-Bray',
    'Rouge-Perriers',
    'Beaurepaire',
    'Saint-Etienne-du-Vauvray',
    'Saint-Martin-Osmonville',
    'Heudicourt',
    'Thiergeville',
    'Servaville-Salmonville',
    'Eslettes',
    'Rosay-sur-Lieure',
    'Sahurs',
    'Clères',
    'Fontaine-la-Mallet',
    'La Trinite-de-Reville',
    'Gaillefontaine',
    'La Gueroulde',
    'Epaignes',
    'Saint-Germain-sur-Avre',
    'Marcouville',
    'Tourville-les-Ifs',
    'Bellencombre',
    'Folleville',
    'Lisors',
    'Neuville-Ferrieres',
    'Couargues',
    'Allouis',
    'Lassay-sur-Croisne',
    'Champoulet',
    'Auxy',
    'Crouy-sur-Cosson',
    'Selommes',
    'Barville-en-Gâtinais',
    'Villeperdue',
    'Saint-Laurent-en-Gatines',
    'Cormery',
    'Poce-sur-Cisse',
    'Jalognes',
    'Lugny-Champagne',
    'Francourville',
    'Maille',
    'Souvigne',
    'Chaudron-en-Mauges',
    'Gontier',
    'Malicorne-sur-Sarthe',
    'Villaines-sous-Malicorne',
    'Lusanger',
    'La Membrolle-sur-Longuenee',
    'Grandchamps-des-Fontaines',
    'Fay-de-Bretagne',
    'Aze',
    'Pareds',
    'Notre-Dame-des-Landes',
    'Saint-Ouen-des-Toits',
    'La Tardiere',
    'Le Bourgneuf-la-Foret',
    'Daumeray',
    'Cherre',
    'Saint-Germain-de-Princay',
    'Le Coudray-Baillet',
    'Le Plessis',
    'Corpe',
    'Tallud-Sainte-Gemme',
    'Le Champ-Saint-Pere',
    'Saint-Firmin',
    'Revigny',
    'Feves',
    'Obermorschwiller',
    'Remelfang',
    'Badevel',
    'Cravant',
    'Traenheim',
    'Mangiennes',
    'Ormes-et-Ville',
    'Thil',
    'Rougegoutte',
    'Bonnevent-Velloreille',
    'Uhrwiller',
    'La Montagne',
    'Erize-Saint-Dizier',
    'Tincry',
    'Ancemont',
    'Seuil',
    'Vaudebarrier',
    'Aiserey',
    'Guemar',
    'Villiers-sur-Tholon',
    'Igney',
    'Sassey-sur-Meuse',
    'Valff',
    'Dammarie-sur-Saulx',
    'La Neuveville-devant-Lepanges',
    'Volmerange-les-Mines',
    'Bouligney',
    'Chagey',
    'Lans',
    'Eckwersheim',
    'Étain',
    'Sponville',
    'Ville-sur-Cousances',
    'Tremont-sur-Saulx',
    'Clermont',
    'Pers-Jussy',
    'Talloires',
    'Marlioz',
    'Les Avanchers-Valmorel',
    'Chezery-Forens',
    'Saint-Germain-sur-Rhone',
    'Aviernoz',
    'Bernex',
    'Arthaz-Pont-Notre-Dame',
    'Saint-Jean-de-Gonville',
    'Verchaix',
    'Praz',
    'Bellevue-la-Montagne',
    'Etrembieres',
    'La Muraz',
    'Saint-Martin-Bellevue',
    'Esserts-Saleve',
    'Orcier',
    'Mures',
    'Chatel',
    'Copponex',
    'Scientrier',
    'Vacheresse',
    'Lovagny',
    'Arcine',
    'Mezeriat',
    'Les Houches',
    'Sauve',
    'Salses-le-Chateau',
    'Saint-Martin-le-Vieil',
    'Serdinya',
    'Souilhanels',
    'Saint-Hippolyte-de-Montaigu',
    'Gailhan',
    'Quissac',
    'Congenies',
    'Saissac',
    'La Rouviere',
    'Saint-Dezery',
    'Maureilhan',
    'Paraza',
    'Palalda',
    'Haute-Rivoire',
    'Septeme',
    'Ambierle',
    'Bouchet',
    'Echalas',
    'Mornay',
    'Pommiers-la-Placette',
    'Oytier-Saint-Oblas',
    'Saint-Didier-sur-Chalaronne',
    'Leyrieu',
    'Cellieu',
    'Cezay',
    'Saint-Bardoux',
    'Charnay',
    'Satillieu',
    'Genissieux',
    'Montsegur-sur-Lauzon',
    'Grammond',
    'Chassagny',
    'Montrevel',
    'Saint-Laurent-des-Combes',
    'Fargues',
    'Bougarber',
    'Ahaxe-Alciette-Bascassan',
    'Momas',
    'Castillon-Debats',
    'Laparrouquial',
    'Cotdoussan',
    'Clarac',
    'Peyrissas',
    'Saint-Generoux',
    'Lahitte-Toupiere',
    'Saint-Jean-de-Lier',
    'Berenx',
    'Saubusse',
    'Ossages',
    'Carsac-Aillac',
    'Captieux',
    'Belveze',
    'Gavarnie',
    'Bonrepos',
    'Denat',
    'Angoume',
    'Estillac',
    'Orban',
    'Saint-Jean-de-Marcel',
    'Saint-Ybars',
    'Levis',
    'Lux',
    'Goyrans',
    'Ondes',
    'Cauzac',
    'Amou',
    'Saint-Avit',
    'Eance',
    'Inguiniel',
    'Les Champs',
    'Huelgoat',
    'Saint-Helen',
    'Saint-Guinoux',
    'Saint-Herve',
    'Edern',
    'Romille',
    'Plourivo',
    'Cardroc',
    'Martigne-Ferchaud',
    'Saint-Pern',
    'La Lande',
    'Saint-Vincent-des-Landes',
    'Perreux',
    'Langolen',
    'Gennes-sur-Seiche',
    'Meucon',
    'Kergloff',
    'Pleyber-Christ',
    'Chanteloup',
    'Ploeven',
    'Neulliac',
    'Monthureux-sur-Saone',
    'Chaumousey',
    'Xertigny',
    'Genelard',
    'Sampigny-les-Maranges',
    'Saint-Aubin-en-Charollais',
    'Jussey',
    'Hageville',
    'Chateau',
    'Schmittviller',
    'Laneuveville-aux-Bois',
    'Lemainville',
    'Chambilly',
    'Laignes',
    'Roulans',
    'Charquemont',
    'Hugier',
    'Pouilly-sur-Vingeanne',
    'Cubry-les-Soing',
    'Saint-Saulge',
    'Saint-Martin-en-Gatinois',
    'Dambenois',
    'Le Vaudioux',
    'Fretigney-et-Velloreille',
    'Pagny-la-Ville',
    'Les Villedieu',
    'Montfleur',
    'Chissey-en-Morvan',
    'Ecleux',
    'Palleau',
    'Mouthe',
    'Lechatelet',
    'Les Hays',
    'Bussy-le-Grand',
    'Ferrieres-les-Scey',
    'Dracy-le-Fort',
    'Ueberstrass',
    'Le Tholy',
    'Provencheres-sur-Fave',
    'Verze',
    'Armes',
    'Foulcrey',
    'Pugey',
    'Charbuy',
    'Montrond',
    'Faimbe',
    'Fresne-Saint-Mames',
    'Orchamps-Vennes',
    'Roffey',
    'Bonnetage',
    'Fuisse',
    'Corcelles-les-Monts',
    'Montessaux',
    'Supt',
    'Merry-sur-Yonne',
    'Pleure',
    "Lucenay-l'Eveque",
    'Ray-sur-Saone',
    'Verne',
    'Saint-Denis-de-Vaux',
    'Floyon',
    'Belmont',
    'Campremy',
    'Vadencourt',
    'Monceau-le-Neuf-et-Faucouzy',
    'Viels-Maisons',
    'Sainte-Genevieve',
    'Novion-Porcien',
    'Fontvannes',
    'Bazuel',
    'Coupelle-Vieille',
    'Moyencourt',
    'Seraucourt-le-Grand',
    'Foulangues',
    'Tupigny',
    'Conde-sur-Marne',
    'Gouy-en-Artois',
    'Inchy',
    'Millencourt',
    'Moislains',
    'Avesnelles',
    'Cartignies',
    'Radonvilliers',
    'Craonne',
    'Billy-sur-Ourcq',
    'Epagne-Epagnette',
    'Mont-Saint-Pere',
    'Bassoles-Aulers',
    'Bethancourt-en-Valois',
    'Lusigny-sur-Barse',
    'Ecueil',
    'Carnieres',
    'Ablain-Saint-Nazaire',
    'Valmondois',
    'Ferrieres',
    'Clairfontaine',
    'Mennevret',
    'Proyart',
    'Commenchon',
    "Herpy-l'Arlesienne",
    'Secheval',
    'Clety',
    'Choisel',
    'Chavenay',
    'Vaudoy-en-Brie',
    'Chateaubleau',
    'Saint-Georges-sur-Allier',
    'Saint-Pierre-le-Chastel',
    'Saint-Jean-de-Sixt',
    'Villy-le-Bouveret',
    'Cereste',
    'Cornillon-Confoux',
    'Lumio',
    'Artigues',
    'Aups',
    'Calcatoggio',
    'Montussan',
    'Montcaret',
    'Dieupentale',
    'Miradoux',
    'Marigny-Chemereau',
    'Chenevelles',
    'Brux',
    'Aigonnay',
    'Bernos-Beaulac',
    'Cornus',
    'Terrebasse',
    'Puycelsi',
    'Mingot',
    'Arzacq-Arraziguet',
    'Saint-Gein',
    'Saint-Mariens',
    'Cartelegue',
    'Gensac',
    'Laguiole',
    'Glenic',
    'Melle',
    'Villexavier',
    'Mazieres-en-Gatine',
    'Blaudeix',
    'Abzac',
    'Saint-Georges-des-Coteaux',
    'Coulombiers',
    'Savignac-les-Eglises',
    'Castanet-le-Haut',
    'Verdun-en-Lauragais',
    'Vaissac',
    'Moliets-et-Maa',
    'Champlemy',
    'Vaux-les-Pres',
    'Dalhunden',
    'Gendrey',
    'Ruffey-sur-Seille',
    'Spechbach-le-Haut',
    'Vitteaux',
    'Buffard',
    'Montarlot-les-Rioz',
    'Lantenne-Vertiere',
    'Poncey-les-Athee',
    'Talon',
    'Vers',
    'Anteuil',
    'Auxey-Duresses',
    'Le Ban-Saint-Martin',
    'Laval-sur-Vologne',
    'Witternheim',
    'Lohr',
    'Dannemoine',
    'Velleminfroy',
    'Montmalin',
    'Brion',
    'Moineville',
    'Cappel',
    'Ochey',
    'Plappeville',
    'Silly-sur-Nied',
    'Sainte-Suzanne',
    'Farincourt',
    'Filain',
    'Noidans-le-Ferroux',
    'Gottenhouse',
    'Novillars',
    'Montboillon',
    'Tresilley',
    'Sessenheim',
    'Favieres',
    'Sarraltroff',
    'Nousseviller-les-Bitche',
    'Laneuveville-devant-Bayon',
    'Jouaville',
    'Bionville-sur-Nied',
    'Wintersbourg',
    'Remereville',
    'Magnieres',
    'Fletrange',
    'Dommartin-les-Remiremont',
    'Lanthenans',
    'Lorry-Mardigny',
    'Troussey',
    'Deyvillers',
    'Bindernheim',
    'Hunspach',
    'Varsberg',
    'Gondreville',
    'Ortoncourt',
    'Mamey',
    'Benamenil',
    'Belleray',
    'Guessling-Hemering',
    'Coume',
    'Vacon',
    'Landser',
    'Vincelles',
    'Brouennes',
    'Moriville',
    'Billy-sous-les-Cotes',
    'Housseville',
    'Champdray',
    'Letricourt',
    'Niffer',
    'Saint-Gingolph',
    'Escurolles',
    'Chaptuzat',
    'Chavaroux',
    'Loisin',
    'Meillerie',
    'La Clusaz',
    'Ydes',
    'Saignes',
    'Le Fayet',
    'La Riviere',
    'Courchevel',
    'La Flachere',
    'Merlas',
    'Bresson',
    'La Salette-Fallavaux',
    'La Sone',
    'Apremont',
    'Cesarches',
    'Tignes',
    'Aiguebelette-le-Lac',
    'Saint-Pierre-dAllevard',
    'Le Cheylas',
    'Saint-Julien-en-Champsaur',
    'Voglans',
    'Blaise',
    'Barraux',
    'Notre-Dame-de-Commiers',
    'Murianette',
    'Lepin-le-Lac',
    'Saint-Cassin',
    'Entremont-le-Vieux',
    'Pallud',
    'Tours-en-Savoie',
    'Aillon-le-Vieux',
    'Thoiry',
    'Saint-Gondon',
    'Selle',
    'Vouzon',
    'Tauxigny',
    'Jars',
    'Courcy-aux-Loges',
    'Croisilles',
    'Saint-Gervais-la-Foret',
    'Manchecourt',
    'Saint-Genou',
    'Bouilly-en-Gatinais',
    'Mesland',
    'Meusnes',
    'Thilouze',
    'Lion-en-Sullias',
    'Mezieres-lez-Clery',
    'Saint-Regle',
    'Dampierre-en-Crot',
    'Vrigny',
    'Florentin',
    'Le Noyer',
    'Boulleret',
    'Assay',
    'Tillay-le-Peneux',
    'Cabanac',
    'Arsague',
    'Serres-Gaston',
    'Louer',
    'Bastennes',
    'Josse',
    'Aubiac',
    'Ambrus',
    'Noaillac',
    'Cabanac-et-Villagrains',
    'Mombrier',
    'Hure',
    'Camblanes-et-Meynac',
    'Chazelles',
    'Chevanceaux',
    'Rohan',
    'Saint-Martial',
    'Lagord',
    'Bommes',
    'Saint-Geyrac',
    "Saint-Etienne-d'Orthe",
    'Biaudos',
    'Viodos-Abense-de-Bas',
    'Castet',
    'Azur',
    'Buros',
    'Oregue',
    'Monflanquin',
    'Saint-Savinien',
    'Meuzac',
    'Barro',
    'Guignicourt-sur-Vence',
    'Loisy-sur-Marne',
    'Mergey',
    'Tincques',
    'Lederzeele',
    'Boulages',
    'Saint-Pouange',
    'Fontenoy',
    'Haussignemont',
    'Le Herie-la-Vieville',
    'Corbehem',
    'Riencourt-les-Bapaume',
    'Marquay',
    'Chigny-les-Roses',
    'Tremilly',
    'Offekerque',
    'Louvil',
    'Etroeungt',
    'Saint-Michel-sur-Ternoise',
    'Capelle',
    'Neuvireuil',
    'Hamel',
    'Gruson',
    'Vincly',
    'Avesnes-les-Aubert',
    'Blacy',
    'Sainte-Preuve',
    'Montagny-Sainte-Felicite',
    'Droupt-Saint-Basle',
    'Vailly-sur-Aisne',
    'Monceau-Saint-Waast',
    'Monchy-Breton',
    'Damery',
    'Saint-Leger-sous-Brienne',
    'Arrigny',
    'Vauchonvilliers',
    'Fontaine-au-Bois',
    'La Calotterie',
    'Gouy-sous-Bellonne',
    'Jonvelle',
    'Saulon-la-Rue',
    'Surgy',
    'Mantry',
    'Pasques',
    'Goxwiller',
    'Preuschdorf',
    'Sand',
    'Michelbach-le-Bas',
    'Tronville-en-Barrois',
    'Dampvalley-les-Colombe',
    'Bersaillin',
    'Civry-en-Montagne',
    'Denevre',
    'Chapelle-Voland',
    'Painblanc',
    'Dezize-les-Maranges',
    'Abbans-Dessus',
    'Kriegsheim',
    'Schillersdorf',
    'Sondernach',
    'Houssen',
    'La Madeleine',
    'Vervezelle',
    'Lucey',
    'Pouillenay',
    'Billey',
    'Simandre',
    'Blodelsheim',
    'Chasnais',
    'Lombron',
    'Saint-Paul-en-Pareds',
    'Ballon',
    'Verneil-le-Chetif',
    'Mont',
    'Coulombiers',
    'Saint-Michel-Mont-Mercure',
    "Sceaux-d'Anjou",
    'Cosmes',
    'Bonnetable',
    'Lassay-les-Chateaux',
    "Saint-Mars-d'Outille",
    'Saint-Michel-Treve',
    'Bouloire',
    'Oisseau',
    'Quilly',
    'Louplande',
    'Sion-les-Mines',
    'Vouvant',
    'Saint-Georges-de-Montaigu',
    'Gennes',
    'Beaurepaire',
    'Le Breil',
    'Castera-Loubix',
    'Lasseran',
    'Buzy',
    'Solferino',
    'Brignac-la-Plaine',
    'Birac-sur-Trec',
    'La Roche',
    'Saint-Sardos',
    'Montvalent',
    'Entraygues-sur-Truyere',
    'Fargues',
    'Saint-Cezert',
    'Saint-Xandre',
    'Coulaures',
    'La Ville',
    'Soulac-sur-Mer',
    'Surba',
    'Orgueil',
    'Boos',
    'Anche',
    'Saint-Pierre-de-Trivisy',
    'Saint-Georges-de-Luzencon',
    'Clairac',
    'Sainte-Marie-de-Chignac',
    'Bellefond',
    'Savignac-sur-Leyze',
    'Romagne',
    'Montrem',
    "Vabres-l'Abbaye",
    'Juilles',
    'Saurat',
    'Lavit',
    'Flamarens',
    'Vandre',
    'Loubes-Bernac',
    'Baurech',
    'Sarliac-sur-lIsle',
    'Cottun',
    'Gesnes-le-Gandelin',
    'Crouay',
    'Tocqueville',
    'Le Mesnil-Villeman',
    'Baupte',
    'Carantilly',
    'Saint-Malo-de-la-Lande',
    'Le Tronquay',
    'Saint-Sauveur-Lendelin',
    'Vieux-Fume',
    'Hocquigny',
    'Ger',
    'Montmartin-en-Graignes',
    'Le Pin-la-Garenne',
    'Fresne-la-Mere',
    'Saint-Gatien-des-Bois',
    'Sainte-Croix-Grand-Tonne',
    'Luneray',
    'Saint-Pierre-de-Varengeville',
    'Fesques',
    'Beaussault',
    'Guiseniers',
    'Ouville',
    'Saint-Leger-de-Rotes',
    'Saint-Pierre-Lavis',
    'Hodeng-Hodenger',
    'Saint-Denis-le-Thiboult',
    'Pissy-Poville',
    'Thietreville',
    'Nonancourt',
    'Quincampoix',
    'Illois',
    'Bacqueville',
    'La Hallotiere',
    'Cottevrard',
    'Alvimare',
    'Gremonville',
    'Saint-Saire',
    'Flancourt-Catelon',
    'Epinay',
    'Gonneville-la-Mallet',
    'Peisey-Nancroix',
    'Vimines',
    'Saint-Avre',
    'Dionay',
    'Méribel',
    'Etable',
    'Felines',
    'Laure',
    'Saint-Maurice-de-Cazevieille',
    'Thurins',
    'Toulaud',
    'Le Cergne',
    'Montaulieu',
    'Auberives-sur-Vareze',
    'Jonage',
    'Saint-Victor-de-Cessieu',
    'Pact',
    'Mercurol',
    'Grandris',
    'Siccieu-Saint-Julien-et-Carisieu',
    'Lamastre',
    'Margerie-Chantagret',
    'Les Cheres',
    'Mouilleron-en-Pareds',
    'Saint-Hilaire-de-Voust',
    'Villebernier',
    'Pannece',
    'Conquereuil',
    'Monsireigne',
    'Saint-Leger-sous-Cholet',
    'Gesvres',
    'Anetz',
    'Vouille-les-Marais',
    'Chanteloup-en-Brie',
    'Gee',
    'Le Thoureil',
    'Blou',
    'Saint-Pierre-du-Chemin',
    'Echemire',
    'Saint-Christophe-du-Ligneron',
    'Mazieres-en-Mauges',
    'Saint-Martin-des-Noyers',
    'Saint-Georges-du-Rosay',
    'Jallais',
    'Guecelard',
    'La Cornuaille',
    'Saint-Cyr-en-Pail',
    'Puceul',
    'Getigne',
    'Roche',
    'Les Pins',
    'Saint-Germain-sur-Moine',
    'Soulge-sur-Ouette',
    'Mauves-sur-Loire',
    'Saint-Domet',
    'Croze',
    'Lorignac',
    'Conceze',
    'Frechede',
    'Mauran',
    'Valence',
    'Giroussens',
    'Budos',
    'Ayguemorte-les-Graves',
    'Pessac-sur-Dordogne',
    'Saint-Laurent-du-Bois',
    'Arnac-Pompadour',
    'Lariviere-Arnoncourt',
    'Yvrac-et-Malleyrand',
    'Mauremont',
    'Causse-de-la-Selle',
    'Montredon-Labessonnie',
    'Fonbeauzard',
    'Labouquerie',
    'Peyrat-le-Chateau',
    'Condeon',
    'Merignac',
    'Sauveterre-de-Guyenne',
    'Septfonds',
    'Cazals-des-Bayles',
    'Labessiere-Candeil',
    'Sainte-Foy-de-Peyrolieres',
    'Bussac-Foret',
    'Orignolles',
    'Cieux',
    'Rabou',
    'Roches',
    'La Porcherie',
    'Troche',
    'Juillac',
    'Brandonnet',
    'Najac',
    'Courcelles',
    'Rioz',
    'Prisse',
    'Autechaux',
    'Fontaine-les-Luxeuil',
    'Montaigu',
    'Saint-Martin-Belle-Roche',
    "Feche-l'Eglise",
    'Pruzilly',
    'Sermoyer',
    'Mailly-la-Ville',
    'Saint-Amour',
    'Germigny-sur-Loire',
    'Bellevesvre',
    'Entrains-sur-Nohain',
    'Bassigney',
    'Beauvernois',
    'Messigny-et-Vantoux',
    'Molinet',
    'Glanon',
    'La Villedieu-les-Quenoche',
    'Laroche-Saint-Cydroine',
    'Lainsecq',
    'Bresse-sur-Grosne',
    'Verchamp',
    'Parly',
    'Merey-sous-Montrond',
    'Devrouze',
    'Chagny',
    'Saint-Andeux',
    'Chassenard',
    'Lamarche-sur-Saone',
    'Chargey-les-Port',
    'Souvans',
    'Villey-sur-Tille',
    'Boursieres',
    'Bouilly',
    'Echevannes',
    'Eternoz',
    'Vanclans',
    'Ormoy',
    'Saint-Martin-en-Bresse',
    'Chateau-Chinon(Ville)',
    'Vaux-sur-Poligny',
    'Dampierre-les-Conflans',
    'Sainpuits',
    'Laperriere-sur-Saone',
    'Serein',
    'Sornay',
    'Boz',
    'Saint-Usage',
    'Neuilly',
    'Logrian-Florian',
    'Pezens',
    'Boucoiran-et-Nozieres',
    'Liouc',
    'Jeune',
    'Enveig',
    'Barbaira',
    'Banassac',
    'Saint-Privat',
    'Saint-Guilhem-le-Desert',
    'Durban-Corbieres',
    'Generargues',
    'Villeveyrac',
    'Montfrin',
    'Vic-le-Fesq',
    'Campagnan',
    'Combas',
    'Saint-Hippolyte',
    'Mejannes-le-Clap',
    'Murviel-les-Beziers',
    'Fontrabiouse',
    "Nezignan-l'Eveque",
    'Le Collet-de-Deze',
    'Sussargues',
    'Prouzel',
    'Douilly',
    'Morsain',
    'Conflans-sur-Seine',
    'Pargny-la-Dhuys',
    'Villequier-Aumont',
    'Flaucourt',
    'Mourmelon-le-Petit',
    'Saulces-Monclin',
    'Cuperly',
    'Haussimont',
    'Bazeilles',
    'Puisieulx',
    'Vrigne-aux-Bois',
    'Semoutiers-Montsaon',
    'Aulnay-sur-Marne',
    'Fossemanant',
    'Flavy-le-Martel',
    'Froidestrees',
    'Crugny',
    'Autreville',
    'Boubiers',
    'Bichancourt',
    'Braucourt',
    'Morvillers-Saint-Saturnin',
    'Harcigny',
    'Festubert',
    'Etaves-et-Bocquiaux',
    'Laversine',
    'Les Loges',
    'Chambrecy',
    'Saint-Etienne-au-Temple',
    'Feuges',
    'Chapelle-Vallon',
    'Le Baizil',
    'Rumegies',
    'Hallencourt',
    'Fouencamps',
    'Sarcicourt',
    'Villeneuve-au-Chemin',
    'Jarze',
    'Etival-les-le-Mans',
    'Bois-de-Cene',
    'Melay',
    'Louailles',
    'Bouee',
    'Chauve',
    'Andigne',
    'Montsoreau',
    'Chauche',
    'Le Boupere',
    'Soudan',
    'Saint-Philbert-de-Bouaine',
    'Fougere',
    'Moutiers-sur-le-Lay',
    'Longue-Jumelles',
    'Nyoiseau',
    'Fontaine-Guerin',
    'Outille',
    'Lezigne',
    'Saint-Macaire-du-Bois',
    'Moisdon-la-Riviere',
    'Saint-Jean-Ligoure',
    'Ronsenac',
    'Villebois-Lavalette',
    'Nercillac',
    'Boussac',
    'Pageas',
    'Lahontan',
    'Massugas',
    'Capoulet-et-Junac',
    'La Lande',
    'Chenerailles',
    'Puyravault',
    'Lavaurette',
    'Lacour',
    'Salleboeuf',
    'Saint-Vite',
    'Boisse-Penchot',
    'Vallesvilles',
    'Sainte-Terre',
    'Vanzac',
    'La Grave',
    'Lombers',
    'Montpezat-de-Quercy',
    'Escassefort',
    'Peyrignac',
    'Naujan-et-Postiac',
    'Tursac',
    'Thenon',
    'Lizac',
    'Saint-Sebastien',
    'Perignac',
    'Villegouge',
    'Leojac',
    'Trevien',
    'Bor-et-Bar',
    'Les Eyzies-de-Tayac-Sireuil',
    'Lachapelle',
    'Pompertuzat',
    'Forfry',
    'Citry',
    'Jumeauville',
    'Villers',
    'Vetheuil',
    'Auteuil',
    "Caumont-l'Evente",
    'Sainte-Croix-Hague',
    'Moulines',
    'Coulvain',
    'Berjou',
    'Saint-Cornier-des-Landes',
    'Vaux-sur-Aure',
    'Pont-Hebert',
    'Saint-Ouen-du-Mesnil-Oger',
    'Cormolain',
    'Annebault',
    'Quettreville-sur-Sienne',
    'Benerville-sur-Mer',
    'La Coulonche',
    'Cesny-Bois-Halbout',
    'Monteaux',
    'Pontlevoy',
    'Gouillons',
    'Ivoy-le-Pre',
    'Chambourg-sur-Indre',
    'Coulommiers-la-Tour',
    'Villetrun',
    'Houx',
    'Mevoisins',
    'Oucques',
    'Levet',
    'Saint-Hilaire-les-Andresis',
    'Gasville-Oiseme',
    'Dangeau',
    'Vatan',
    'Prunay-le-Gillon',
    'Villeny',
    'Neuville-sur-Brenne',
    'Authon',
    'Monteglin',
    'Belaye',
    'Viabon',
    "Saint-Jean-d'Aubrigoux",
    'Recey-sur-Ource',
    'Bagneaux',
    'La Chatre-Langlin',
    'Cargèse',
    'Illiers-Combray',
    'Courtisols',
    'Ouerre',
    'Romeny-sur-Marne',
    'Breconchaux',
    'Cravanche',
    'Boussois',
    'La Ferte-Mace',
    'Hermillon',
    'Rion-des-Landes',
    'Pompignan',
    'Saint-Amant-de-Boixe',
    'Saint-Pierre-sur-Dives',
    'Tillenay',
    'Capens',
    'Le Passage',
    'Fierville-les-Mines',
    'Orval',
    'Lingreville',
    'Parigny',
    'Cosqueville',
    'Reville',
    'Les Moitiers-en-Bauptois',
    'Roncey',
    'Conde-sur-Vire',
    'Boisyvon',
    'Notre-Dame-de-Cenilly',
    'Doville',
    'Amigny',
    'Quettehou',
    'Montreuil-sur-Lozon',
    'Percy',
    'Sainte-Mere-Eglise',
    'Picauville',
    'Fermanville',
    'Lozon',
    'Saint-Patrice-de-Claids',
    'Quineville',
    'Creances',
    'Reffuveille',
    'Sainte-Cecile',
    'Milly',
    'La Chaise-Baudouin',
    'Bricqueville-la-Blouette',
    'Lessay',
    'Lecousse',
    'Canisy',
    'Glatigny',
    'Pirou',
    'Vasteville',
    'Huberville',
    'Saint-Fromond',
    'La Haye-Saint-Sylvestre',
    'La Feuillie',
    'Barfleur',
    'Neufmesnil',
    'Eschbach-au-Val',
    'Bettwiller',
    'Hatten',
    'Durrenbach',
    'Boersch',
    'Breuschwickersheim',
    'Raedersheim',
    'Stosswihr',
    'Batzendorf',
    'Wintzenbach',
    'Mietesheim',
    'Schopperten',
    'Soultzeren',
    'Neewiller-pres-Lauterbourg',
    'Grassendorf',
    'Chevrieres',
    'Loyes',
    'Saint-Romain-Lachalm',
    'Sevelinges',
    'Ligne',
    'Ecouviez',
    'Le Caylar',
    'Usseau',
    'Germenay',
    "Saint-Jeure-d'Andaure",
    'Thurageau',
    'Saint-Arnoult-des-Bois',
    'Cherveux',
    'Audignies',
    'Voulton',
    'Maurois',
    'Auxange',
    'La Roque-Baignard',
    'Rouperroux',
    'Conde-sur-Iton',
    'Ecardenville-sur-Eure',
    'Pont',
    'Saintes-Maries-de-la-Mer',
    'Genac',
    'Saint-Genies-de-Comolas',
    'Ernemont-sur-Buchy',
    'Frevillers',
    'Escolives-Sainte-Camille',
    'Verneuil-sous-Coucy',
    'Belloy-en-France',
    'Riaille',
    'Commequiers',
    'Torsac',
    'Berville-en-Roumois',
    'La Croix-Valmer',
    'Montayral',
    'Quatremare',
    'La Sauvetat-de-Saveres',
    'Fourques-sur-Garonne',
    'Le Breuil',
    'Livre-sur-Changeon',
    'Pas-en-Artois',
    'Nommay',
    'Ferriere-la-Petite',
    'Villermain',
    'Sainte-Fereole',
    'Brides-les-Bains',
    'Chimilin',
    'Saint-Baldoph',
    'Saint-Martin-de-Belleville',
    'Hauteluce',
    'Proveysieux',
    'Verthemex',
    'Le Pin',
    'Argentine',
    'Saint-Thibaud-de-Couz',
    'Dullin',
    'Mery',
    'Nantoin',
    'Villarlurin',
    'Barby',
    'Saint-Paul-de-Varces',
    'Epierre',
    'Arbin',
    'Saint-Joseph-de-Riviere',
    'Randens',
    'La Motte-Servolex',
    'Monestier-de-Clermont',
    'Massieu',
    'Queige',
    'Puygros',
    'La Chapelle-Blanche',
    'Toulon-sur-Arroux',
    'Beire-le-Chatel',
    'Dixmont',
    'Tracy-sur-Loire',
    'Chaffois',
    'Forleans',
    'Saint-Seine-sur-Vingeanne',
    'Vitreux',
    'Essey',
    'Saint-Martin-sur-Nohain',
    'Epoisses',
    'Mondon',
    'Normier',
    'La Chaux-du-Dombief',
    'Foncine-le-Haut',
    'Ougney',
    'Vathimenil',
    'Chemery-les-Deux',
    'Sexey-aux-Forges',
    'Le Syndicat',
    'Fremonville',
    'Anould',
    'Noisseville',
    'Dorlisheim',
    'Koenigsmacker',
    'Richeling',
    'Romaneche-Thorins',
    'Brinon-sur-Beuvron',
    'Cuiseaux',
    'Fenay',
    'Rosieres-sur-Mance',
    'Vosne-Romanée',
    'Sauvigny-les-Bois',
    'Moloy',
    'Avot',
    'Autoreille',
    'Gergy',
    'Poiseul-les-Saulx',
    'Blanot',
    'Massingy-les-Semur',
    'Boncourt-le-Bois',
    'Tonnoy',
    'Grandvillers',
    'Sommelonne',
    'Saint-Ouen-les-Parey',
    'Pagny-sur-Meuse',
    'Portieux',
    'Ebersviller',
    'Bonnet',
    'Fontenoy-le-Chateau',
    'Vaxoncourt',
    'Eppe-Sauvage',
    'Montenescourt',
    'Bouvigny-Boyeffles',
    'Saint-Souplet',
    'Monceau-le-Waast',
    'Pinon',
    'Varinfroy',
    'Bellancourt',
    'Arvillers',
    'Villers-les-Roye',
    'Signy-le-Petit',
    'Perrogney-les-Fontaines',
    'Maysel',
    'Wavrechain-sous-Faulx',
    'Sainte-Croix',
    'Clamecy',
    'Gouzeaucourt',
    'Ergny',
    'Arleux-en-Gohelle',
    'Groffliers',
    'Honnecourt-sur-Escaut',
    'Genech',
    'Landas',
    'Bouvaincourt-sur-Bresle',
    'Beugneux',
    'Lannoy-Cuillere',
    'Houry',
    'Vouzy',
    'Pont-sur-Seine',
    'Montpothier',
    'Soyers',
    'Dampierre-au-Temple',
    'Muidorge',
    'Bernaville',
    'Bours',
    'Etelfay',
    'Goury',
    'Le Chesne',
    'Estouteville-Ecalles',
    'Gauville-la-Campagne',
    'Auberville-la-Renault',
    'Manneville-es-Plains',
    'Perriers-sur-Andelle',
    'Petiville',
    'Auffay',
    'Totes',
    'Ypreville-Biville',
    'Auberville-la-Campagne',
    'Saint-Pierre-le-Vieux',
    'Montigny',
    'Saint-Martin-du-Tertre',
    'Nerville-la-Foret',
    'Goussainville',
    'Assignan',
    'Mars',
    'Brignon',
    'Boissieres',
    'Malves-en-Minervois',
    'Cesseras',
    'Oupia',
    'Apcher',
    'Montescot',
    'Portel-des-Corbieres',
    'Bellegarde-du-Razes',
    'Saint-Maximin',
    'Saint-Pierre-la-Bourlhonne',
    'Puilacher',
    'Capendu',
    'Pouzols-Minervois',
    'Fontjoncouse',
    'Saint-Amand-Magnazeix',
    'Vignolles',
    'Londigny',
    'Saint-Martin-Lacaussade',
    'Pontours',
    'Carlucet',
    'Germond-Rouvre',
    'Vasles',
    'Vindelle',
    'La Treille',
    'Richemont',
    'Varaignes',
    'Caumont-sur-Garonne',
    'Lusignan-Grand',
    'Marigny-Brizay',
    'Ax-les-Thermes',
    'Le Bez',
    'Lespinasse',
    'Escazeaux',
    'Avignonet-Lauragais',
    'Cassagne',
    'Geaune',
    'Noirterre',
    'Civrac-de-Blaye',
    'Marsaneix',
    'Augignac',
    'Mauzens-et-Miremont',
    'Journiac',
    'La Bastide',
    'Viven',
    'Nieuil',
    'Torxe',
    'Cherac',
    'Rouvre',
    'Saint-Meard-de-Gurcon',
    'Pleumartin',
    'Bois-de-la-Pierre',
    'Peyrilles',
    'Marignac-Lasclares',
    'Nadillac',
    'Rayssac',
    'Asson',
    'Bonnegarde',
    'Treffrin',
    'Guiler-sur-Goyen',
    'Lanveoc',
    'Averton',
    'Amanlis',
    'Plelo',
    'Guilliers',
    'Querrien',
    'Kerlouan',
    'Brasparts',
    'Meneac',
    'Loyat',
    'Landunvez',
    'Plehedel',
    'Pire-sur-Seiche',
    'Talensac',
    'Lannebert',
    'Rieux',
    'Trelivan',
    'Monsac',
    'Colombier',
    'Roquebrune',
    'Gimeux',
    'Neuvicq-le-Chateau',
    'Pouffonds',
    'Chize',
    'Bonrepos-Riquet',
    'Vieillevigne',
    'Asque',
    'Ganac',
    'Frontignan-Saves',
    'Valdurenque',
    'Linxe',
    'Auriac',
    'Sus',
    'Arboucave',
    'Montpeyroux',
    "Villefranche-d'Albigeois",
    'Le Vigen',
    'Saint-Simon-de-Pellouaille',
    'Fenioux',
    'Jabreilles-les-Bordes',
    'Mauprevoir',
    'Les Vallees',
    'Mirambeau',
    'Millac',
    'Vallegue',
    'Pont-de-Salars',
    'Flagnac',
    'Montfort-en-Chalosse',
    'Saint-Nazaire-de-Valentane',
    'Labastide-Cezeracq',
    'La Tour-dAuvergne',
    'Montmiral',
    'Les Places',
    'Mauves',
    'Beauvallon',
    'Vaux-en-Bugey',
    'Piegros-la-Clastre',
    'Marsaz',
    'Limony',
    'Taulignan',
    'Parnans',
    "Saint-Romain-d'Ay",
    'Le Chateau',
    'Montagut',
    'Aouste-sur-Sye',
    'Saint-Sauveur-de-Montagut',
    'Cherier',
    'Rignieux-le-Franc',
    'Epercieux-Saint-Paul',
    'Planzolles',
    'Polliat',
    'Cors',
    'Le Bessat',
    'Sauzet',
    'Charantonnay',
    'Dommartin',
    'Saint-Verand',
    'Soyons',
    'Saint-Rirand',
    'Ceignes',
    'Courtelevant',
    'Sologny',
    'Vallerois-Lorioz',
    'Blondefontaine',
    'Mervans',
    'Anchenoncourt-et-Chazel',
    'Junay',
    'Rochefort-sur-Nenon',
    'Saint-Boil',
    'Cogna',
    "Bois-d'Amont",
    'Belleneuve',
    'Sommecaise',
    'Bining',
    'Harprich',
    'Mangonville',
    'Leintrey',
    'Munster',
    'Eteimbes',
    'Amathay-Vesigneux',
    'Vaumort',
    'Milly-Lamartine',
    'Voisin',
    'Amancey',
    'Cerilly',
    'Crissey',
    'Marcigny',
    'Rehon',
    'Raon-aux-Bois',
    'Racrange',
    'Rochesson',
    'Gezoncourt',
    'Vahl-les-Benestroff',
    'Francaltroff',
    'Ville',
    'Muespach-le-Haut',
    'Jungholtz',
    'Grandfontaine',
    'Thiefosse',
    'Champ-le-Duc',
    'Saint-Etienne-en-Cogles',
    'Quintenic',
    'Pleudihen-sur-Rance',
    'Miniac-sous-Becherel',
    'La Noe',
    'Nevez',
    'Plumergat',
    'Mellionnec',
    'Hemonstoir',
    'Treglamus',
    'Erce-pres-Liffre',
    'Loperhet',
    'Saint-Maudan',
    'Saint-Segal',
    'Soisy-sur-Ecole',
    'Nouan-le-Fuzelier',
    'Pezou',
    'Sully-la-Chapelle',
    'Marcilly-en-Beauce',
    'Avoine',
    'Mareau-aux-Bois',
    'Poisieux',
    'Panzoult',
    'Veaugues',
    'Châteauneuf-sur-Cher',
    'Theuville',
    'Echarcon',
    'Hundsbach',
    'Eulmont',
    'Fremifontaine',
    'Lautenbach',
    'Wuenheim',
    'Jebsheim',
    'Hartmannswiller',
    'Fouday',
    'Lorey',
    'Vecoux',
    'Wittersheim',
    'Strueth',
    'Saales',
    'Gimbrett',
    'Sauvage',
    'Vecqueville',
    'Ambonnay',
    'Connantray-Vaurefroy',
    'Wissant',
    'Vieux-Moulin',
    'Montreuil-sur-Breche',
    'Provencheres-sur-Meuse',
    'Moeslains',
    'Givron',
    'Achiet-le-Grand',
    "L'Epine",
    'Bercenay-en-Othe',
    'Margut',
    'Culmont',
    'Cramant',
    'Fort-Mahon-Plage',
    'Epenancourt',
    'Hebuterne',
    'Fouilleuse',
    'Laleu',
    'Chezy-sur-Marne',
    'Jussy',
    'Pargny-les-Bois',
    'Villers-Allerand',
    'Vailly',
    'Jouy-sous-Thelle',
    'Havrincourt',
    'Trosly-Breuil',
    'Boisbergues',
    'Andechy',
    'Ferreux-Quincey',
    'Abbecourt',
    'Manicamp',
    'Brombos',
    'Bonneil',
    'Chierry',
    'Malras',
    'Jonquieres',
    'Fabrezan',
    'Felix',
    'Cassagnes',
    'Corbere-les-Cabanes',
    'Magrie',
    'Villesequelande',
    'Villeneuve-Minervois',
    'Les Cabanes',
    'Saint-Victor-la-Coste',
    'St Laurent des Arbres',
    'Galargues',
    'Le Martinet',
    'Carlipa',
    'Saint-Series',
    'Castelnau-Valence',
    'Villie-Morgon',
    'Civens',
    'Mont',
    'Drom',
    'Le Breuil',
    'Saint-Paul-les-Romans',
    'Colonzelle',
    'Besayes',
    "Marcilly-d'Azergues",
    'Chateaudouble',
    'Pouilly-le-Monial',
    'Oriol-en-Royans',
    'Condillac',
    'La Coucourde',
    'La Tuiliere',
    'Chassiers',
    'Pont-de-Barret',
    'Saint-Desirat',
    'Alix',
    'Albigny-sur-Saone',
    'Maringes',
    'Perreux',
    'Montcarra',
    'Boisset-et-Gaujac',
    "Saint-Feliu-d'Amont",
    'Sant Andreu de Sureda',
    'Aigues-Vives',
    'Sainte-Valiere',
    'Saint-Jean-de-Serres',
    'Cassagnoles',
    'Florac',
    'Garrigues',
    'Mejannes-les-Ales',
    'Saint-Marcel-sur-Aude',
    'Roquefort-des-Corbieres',
    'Roubia',
    'Saint-Benezet',
    'Teyjat',
    'Urval',
    'Vitrac',
    'Aubas',
    'Parzac',
    'Perigne',
    'Annepont',
    'Villamblard',
    'Bonnetan',
    'Cherves-Richemont',
    'Queaux',
    'Vernon',
    'La Puye',
    'Mainxe',
    'Albussac',
    'Corignac',
    'Saint-Denis-des-Murs',
    'Vianne',
    'La Fouillade',
    'Aubrac',
    'Mouzieys-Teulet',
    'Boulogne-sur-Gesse',
    'Saint-Lary-Boujean',
    'Saint-Jean-du-Bruel',
    'Bouhet',
    'Saint-Sulpice-de-Royan',
    'Villegats',
    'Champsac',
    'Villiers-en-Plaine',
    'Marsilly',
    'Verrieres',
    'Chantemerle-sur-la-Soie',
    'Xaintray',
    'Courcon',
    'Cherves-Chatelars',
    'Surin',
    'Colayrac-Saint-Cirq',
    'Castillon-la-Bataille',
    'Livernon',
    'Serres-sur-Arget',
    'Buanes',
    'Carnin',
    'Verchin',
    'Saint-Pierre-Brouck',
    'Rochy-Conde',
    'Cerisy-la-Salle',
    'Velye',
    'Ambleny',
    'Beaurevoir',
    'Demuin',
    'Chiry-Ourscamp',
    'Muirancourt',
    'Vraux',
    'Osne-le-Val',
    'Juvigny',
    'Quernes',
    'Sailly-le-Sec',
    'Therdonne',
    'Mareuil-en-Brie',
    'Chevillon',
    'Flavigny',
    'Chalindrey',
    'Oger',
    'Herchies',
    'Cagny',
    'Blacourt',
    'Saint-Urbain-Maconcourt',
    'Curel',
    'Boyer',
    'Oiselay-et-Grachaux',
    'Marsannay-le-Bois',
    'Ottonville',
    'Bellemagny',
    'Hammeville',
    'Clemery',
    'Malaumont',
    'Bremenil',
    'Loisy',
    'Berthelange',
    'Irancy',
    'Montenoison',
    'Saint-Romain-le-Preux',
    'Cerisiers',
    'Malay-le-Grand',
    'Saint-Albain',
    'Waville',
    'Dugny-sur-Meuse',
    'Abreschviller',
    'Rauwiller',
    'Cirey-sur-Vezouze',
    'Valleroy-le-Sec',
    'Rainville',
    'Allain',
    'Betting',
    'Prayssas',
    'Sorges',
    'Gornac',
    'Puymiclan',
    'Galapian',
    'Saint-Quentin-du-Dropt',
    'Montdragon',
    'Beurlay',
    'Allonne',
    'Princay',
    'Calmels-et-le-Viala',
    'Cuqueron',
    'Prechac',
    'Cahuzac-sur-Vere',
    'Puybrun',
    'Campet-et-Lamolere',
    'La Riviere',
    'Piegut-Pluviers',
    'Mazion',
    'Veyrignac',
    'Parisot',
    'Sigogne',
    'Les Billanges',
    'Saint-Maurice-les-Brousses',
    'Angeac-Champagne',
    'Autevielle-Saint-Martin-Bideren',
    'Lisle',
    'Bretenoux',
    'Pauilhac',
    'Biars-sur-Cere',
    'Saint-Paul-de-Jarrat',
    'Espinasses',
    'Penta-di-Casinca',
    'Castellare-di-Mercurio',
    'Appietto',
    'Moules',
    'Nessa',
    'Beauvezer',
    'Hermeray',
    'Cheptainville',
    'Boisville-la-Saint-Pere',
    'Petit Chaumont',
    'Buzancais',
    'Villevillon',
    'Greneville-en-Beauce',
    'Mery-es-Bois',
    'Autreche',
    'Saint-Viatre',
    'Fussy',
    'Beaumont-les-Autels',
    'Palluau-sur-Indre',
    'Bleury',
    'Poville',
    'Bazincourt-sur-Epte',
    'La Londe',
    'Dampsmesnil',
    'Bezu-Saint-Eloi',
    "Notre-Dame-d'Aliermont",
    'Sainte-Opportune-du-Bosc',
    'Bosc-Mesnil',
    'Torcy-le-Grand',
    'Argueil',
    'Montreuil-en-Caux',
    'Bosguerard-de-Marcouville',
    'Cuttoli-Corticchiato',
    'Saint-Etienne-du-Gres',
    'Banon',
    'Valle-di-Mezzana',
    'Corbieres',
    'Cervieres',
    'Isigny-sur-Mer',
    'Ouezy',
    'Courson',
    'Saint-Georges-Montcocq',
    'Chailloue',
    'Carolles',
    'Montilly-sur-Noireau',
    'Saint-Germain-de-Tournebut',
    'Sottevast',
    'Courtonne-la-Meurdrac',
    'La Chapelle-Yvon',
    'Les Bordes',
    'Tagnon',
    'Eleu-dit-Leauwette',
    'Dennebroeucq',
    'Wasnes-au-Bac',
    'Remaucourt',
    'Beaudricourt',
    'Morbecque',
    'Houchin',
    'Helesmes',
    'Domart-sur-la-Luce',
    'Hodenc-en-Bray',
    'Tille',
    'Les Ageux',
    'Allery',
    'Silly-Tillard',
    'Varesnes',
    'Le Quesnel',
    'Favieres',
    'Rollot',
    'Brousseval',
    'Saint-Amand-sur-Fion',
    'Audincthun',
    'Sorrus',
    'Cappelle-en-Pevele',
    'Vecquemont',
    'Villembray',
    'Bachy',
    'Gonnehem',
    'Thelus',
    'Ligescourt',
    'Montmacq',
    'Ribemont-sur-Ancre',
    'Mont-Notre-Dame',
    'Cayeux-sur-Mer',
    'Salvagnac',
    'Grezillac',
    'Lafitole',
    'Auxances',
    'Loix',
    'Largeasse',
    'Prin-Deyrancon',
    'Tuzaguet',
    'Cassagnes-Begonhes',
    'Noe',
    'Seissan',
    'Ancizan',
    'Lacaussade',
    'Saint-Seurin-de-Cursac',
    'Maubourguet',
    'Lalbenque',
    'Ballon',
    "L'Isle-Jourdain",
    'Place',
    'Charroux',
    'Villiers-sur-Chize',
    'La Clisse',
    'Saint-Hilaire-Peyroux',
    'Bazian',
    'Peyrilhac',
    'Lalanne-Arque',
    'Lacapelle-Cabanac',
    'Panassac',
    'Saint-Pandelon',
    'Brussieu',
    'Lemps',
    'Souspierre',
    'Allan',
    'Vanosc',
    'Montceau',
    'Ampuis',
    'Bois-de-Gand',
    'Chateauneuf-de-Galaure',
    'Saint-Just-de-Claix',
    'Saint-Maurice-sur-Eygues',
    'Eyzin-Pinet',
    'Saint-Etienne-du-Bois',
    'Saint-Julien',
    'Arcens',
    'Chambost-Longessaigne',
    'Gevrieux',
    'Ratieres',
    'Saint-Etienne-le-Molard',
    'Saint-Denis-en-Bugey',
    'Ansouis',
    'Lafare',
    'Les Baux de Provence',
    'Jonquerettes',
    'Mezel',
    'Esparron-de-Verdon',
    'Chabestan',
    'La Bastide',
    'Taillades',
    'Aubigney',
    'Suin',
    'Baudemont',
    'Crimolois',
    "L'Etoile",
    'Conliege',
    'Cour-et-Buis',
    'Chaussin',
    'Montecheroux',
    'Quarre-les-Tombes',
    'Laning',
    'Gondrecourt-le-Chateau',
    'Vaudeville',
    'Courcelles',
    'Chatenois',
    'Relanges',
    'Spicheren',
    'Urbeis',
    'Fouchy',
    'Sougy-sur-Loire',
    'Bessey-les-Citeaux',
    'Varennes-sous-Dun',
    'Rahon',
    'Blamont',
    'Til-Chatel',
    'Le Deschaux',
    'Saulieu',
    'Morelmaison',
    'Villers-la-Montagne',
    'Haroue',
    'Obermorschwihr',
    'Balgau',
    'Katzenthal',
    'Eichhoffen',
    'Chelan',
    'Lourties-Monbrun',
    'Tauriac-de-Camares',
    'Bagnac-sur-Cele',
    'Montfaucon',
    'Leyme',
    'Beautiran',
    'Sainte-Foy-la-Grande',
    'Champcevinel',
    'Coulx',
    'Civrac-en-Medoc',
    'Houeilles',
    'Saint-André-dAllas',
    'La Villedieu',
    'Sers',
    'Mougon',
    'Bonnes',
    'Vouzan',
    'Saint-Cesaire',
    'Arrenes',
    'Pamproux',
    'Migron',
    'La Pointe',
    'Bannieres',
    'Caucalieres',
    'Cazes-Mondenard',
    'Saint-Cirgues',
    'Lanzac',
    "Saint-Front-d'Alemps",
    'Monbahus',
    'Bagas',
    'Saint-Seurin-de-Prats',
    'Anlhiac',
    'Fors',
    'Les Grands-Chezeaux',
    'Burie',
    'Paizay-le-Sec',
    'Reims-la-Brulee',
    'Azet',
    'Hary',
    "Salles-d'Angles",
    'Montcuq',
    'Uvernet-Fours',
    'Brianconnet',
    'Bellesserre',
    'Lumeau',
    'Lentiol',
    'Chavaignes',
    'Warcq',
    'Saints-Geosmes',
    'Rang',
    'Saint-Jean-de-Soudain',
    'Villerable',
    'Bierne',
    'Sablons',
    'Ambleteuse',
    'Solignac',
    'Pringy',
    'Ribiers',
    'Vorges',
    'Bru',
    'Vourles',
    'Charmant',
    'Val-de-Fier',
    'Bouge-Chambalud',
    'Auxon-Dessus',
    'Telgruc-sur-Mer',
    'Moulins-sur-Yevre',
    'Gosné',
    'Prunieres',
    'Montsalvy',
    'Sauvat',
    'Maurs',
    'Comblanchien',
    'Septvaux',
    'Etigny',
    'Lihons',
    'Menil-sur-Belvitte',
    'Uriage-les-Bains',
    'Mouledous',
    'Pouilly-sous-Charlieu',
    'Charrecey',
    'Illiat',
    'Ners',
    'Courtenay',
    'Bondigoux',
    'Auriac-sur-Vendinelle',
    'Villards',
    'Cormeilles-en-Vexin',
    'Sanguinet',
    'Hauterive',
    'Chevillon',
    "Orbais-l'Abbaye",
    'Lassy',
    'Etouvy',
    'Essia',
    'Bornay',
    'Bief-du-Fourg',
    'Les Essards-Taignevaux',
    'Brimeux',
    'Gouy-Saint-Andre',
    'Galametz',
    'Marenla',
    'Boismont',
    'Gonfreville-Caillot',
    'Plouasne',
    'Saint-Launeuc',
    'Fenouillet',
    'Ur',
    'Marquixanes',
    'Soize',
    'Miserey',
    'Hebecourt',
    'Antigny',
    'Franvillers',
    'Maison-Ponthieu',
    'Sourdon',
    'Saisseval',
    "Neuilly-l'Hopital",
    'Louvrechy',
    'Blaignan',
    'Sery',
    'Sery-les-Mezieres',
    'Ouchamps',
    'Goutrens',
    'Viviez',
    'Saint-Thibault',
    'Boury-en-Vexin',
    'Mignieres',
    'Roquefort-de-Sault',
    'Saint-Martin-Lalande',
    'Feuilla',
    'Urcay',
    'Buxieres-les-Mines',
    'Blomard',
    'Saint-Judoce',
    'Remungol',
    'Malansac',
    'Velines',
    'Saint-Felix-de-Villadeix',
    'Brossac',
    'Villeneuve-Lecussan',
    'Saint-Beat',
    'Murol',
    'Merlines',
    'Colleret',
    'Estaing',
    'Livinhac-le-Haut',
    'Piseux',
    'Prudhomat',
    'Espedaillac',
    'Prayssac',
    'Bourgneuf',
    'Cours-les-Bains',
    'Campeaux',
    'Floing',
    'Matour',
    'Naux',
    'Calavante',
    'Esquieze-Sere',
    'Breux-sur-Avre',
    'Solignac-sous-Roche',
    "La Chapelle-d'Alagnon",
    'Lavoute-Chilhac',
    'Riotord',
    'Le Chatelet-sur-Retourne',
    'Drouville',
    'Joch',
    'Louzy',
    'Frambouhans',
    'Bazens',
    'Damazan',
    'Bricon',
    'Fayl-Billot',
    'Aast',
    'Tajan',
    'Wolschheim',
    'Saint-Blaise-la-Roche',
    'Raizeux',
    'Yzeures-sur-Creuse',
    'Limeray',
    'Montaudin',
    'Villaines-la-Juhel',
    'Chemere-le-Roi',
    'Saint-Pierre-des-Echaubrognes',
    'Montbrun-Bocage',
    'Moncoutant',
    'Thenezay',
    'Bazouges-sous-Hede',
    'Nesles-la-Montagne',
    'Saulnieres',
    'Bailleul-Sir-Berthoult',
    'Etaing',
    'Luxe',
    'Champagnat',
    'Bray-Saint-Christophe',
    'Aumont-en-Halatte',
    'Meharicourt',
    'Wavignies',
    'Le Vieux Bourg',
    'Chaussoy-Epagny',
    'Framerville-Rainecourt',
    'Lihus',
    'Curchy',
    'Mesnil-Saint-Nicaise',
    'Villers-Saint-Genest',
    'Remerangles',
    'Precy-sous-Thil',
    'Morgny',
    'Cavan',
    'Saint-Clet',
    'Plenee-Jugon',
    'Germigny-des-Prés',
    'Aigues-Vives',
    'Saint-Felix-de-Rieutord',
    'Mercenac',
    'Tourtrol',
    'Tarascon-sur-Ariege',
    'Artigat',
    'Bouan',
    'Bulhon',
    'Kappelen',
    'Wintzfelden',
    'Oberentzen',
    'Spechbach-le-Bas',
    'Husseren-les-Chateaux',
    'Friesen',
    'Lutter',
    'Saubrigues',
    'Cruzy',
    'Corbieres',
    'Premian',
    'Montpeyroux',
    'Le Pouget',
    'Bouvante',
    'Saint-Lambert-sur-Dive',
    'Bonnieux',
    'Prechac',
    'Dalmas',
    'Cirey',
    'Seveux',
    'Brue-Auriac',
    'Morogues',
    'Antignac',
    'Champagne-Mouton',
    'Saint-Vincent-sur-Graon',
    'Anguerny',
    'Montrevel-en-Bresse',
    'Villers-Pol',
    'Lurais',
    'Fontaine-les-Gres',
    'Lechelle',
    'Saint-Cyr-du-Gault',
    'Vivieres',
    'Jeufosse',
    'Vanville',
    'Saint-Martin-en-Campagne',
    'Lieurey',
    'Fultot',
    'Chevire-le-Rouge',
    'Mouthier-en-Bresse',
    'Beauchamps-sur-Huillard',
    "Saint-Brevin-l'Ocean",
    'Kernascleden',
    'Bains-les-Bains',
    'Coulonges-Thouarsais',
    'Taller',
    'Vigneulles-les-Hattonchatel',
    'Jametz',
    'Chenailler-Mascheix',
    'Chatelus-le-Marcheix',
    'Marval',
    'Chambon-sur-Voueize',
    'Cresse',
    'Tramoyes',
    'Saint-Lager',
    'Doazit',
    'Hulluch',
    'Colembert',
    'Chanveaux',
    'Les Ecrennes',
    'Sore',
    'Lescheraines',
    'Collobrieres',
    'Fletre',
    'Corcy',
    'Villerest',
    'Blauzac',
    'Vauvillers',
    'Lusigny',
    'Lamagdelaine',
    'Saint-Cere',
    'Cajarc',
    'Marquefave',
    'Saint-Nabord',
    'Roche',
    'Mattaincourt',
    'Reuilly',
    'Capvern',
    'Charentay',
    'Serrieres-de-Briord',
    'La Gree-Saint-Laurent',
    'Clarafond',
    'Minzier',
    'Marcellaz-Albanais',
    'Bailly-Carrois',
    'Verrey-sous-Salmaise',
    'Gerland',
    'Fontaine-Milon',
    'Moncheaux-les-Frevent',
    'Les Hogues',
    'Vraiville',
    'Serquigny',
    'Neffes',
    'Chezy',
    'Thin-le-Moutier',
    'Mogues',
    'Allogny',
    'Prunay-sur-Essonne',
    'Benifontaine',
    'Pressagny-le-Val',
    'Saint-Martin-la-Campagne',
    'Le Meix-Saint-Epoing',
    'Soulieres',
    'La Barben',
    'Troyon',
    'Mollkirch',
    'Barjols',
    'Lorcieres',
    'Molliens-au-Bois',
    'Cases-de-Pene',
    'Saint-Python',
    'Mazeres-sur-Salat',
    'Veria',
    'Curgies',
    'Gorcy',
    'Trevignin',
    'Saint-Porchaire',
    'Charleville',
    'Paillet',
    'Abancourt',
    'Vigoulet-Auzil',
    'Bours',
    'Llupia',
    'Girancourt',
    "Noyant-d'Allier",
    'La Poterie',
    'Longeves',
    "Saint-Victor-d'Epine",
    'Echevis',
    "Cleon-d'Andran",
    'Neauphlette',
    'Andrest',
    'Pierrefitte-Nestalas',
    'Saint-Laurent-de-Neste',
    'Horgues',
    'Villarzel-Cabardes',
    'Beauville',
    'Grezolles',
    'Vouel',
    'Sennece-les-Macon',
    'Brens',
    'Etampes-sur-Marne',
    'Frieres-Faillouel',
    'Nouvion-sur-Meuse',
    'Haraucourt',
    'Leffincourt',
    'Saint-Loup-Hors',
    'Saint-Roch',
    'Thodure',
    "Saint-Georges-d'Esperanche",
    'Gatey',
    'Falletans',
    'Arcon',
    'Mende',
    'Baconnes',
    'Bourghelles',
    'Winnezeele',
    'Cuinchy',
    'Perignat-les-Sarlieve',
    'Servance',
    'Emance',
    'Saint-Etienne-de-Brillouet',
    'Saint-Georges-les-Baillargeaux',
    'Frontenay-sur-Dive',
    'Seraincourt',
    'Cours',
    'Beugin',
    'Labeuvriere',
    'Aix-en-Issart',
    'Cuffies',
    'Villeneuve-Saint-Germain',
    'Virignin',
    'Villemoyenne',
    'Bretigny',
    'Yvoire',
    'Conde-sur-Suippe',
    'Labastide-Gabausse',
    'La Chaumusse',
    'Saint-Romain-la-Motte',
    'Voves',
    'Champeaux',
    'Juvaincourt',
    'Iteuil',
    'Limetz-Villez',
    'Maennolsheim',
    'Marle',
    'Chavanay',
    'Cours-la-Ville',
    'Sermoise',
    'Bois-Grenier',
    'Bruille-Saint-Amand',
    'Saint-Gengoux-de-Scisse',
    'Busque',
    'Theil-sur-Vanne',
    'Pin',
    'Torcieu',
    'Mouchin',
    'Bourg',
    'Chivres-en-Laonnois',
    'Roullens',
    'Codolet',
    'Beaujeu',
    "Les Adrets-de-l'Esterel",
    'Ceton',
    'Rolampont',
    'Sormonne',
    'Touvre',
    'Rieux-Minervois',
    'Plobannalec-Lesconil',
    'Cerons',
    'Arsac',
    'Les Roches',
    'Saint-Hippolyte',
    'Rimogne',
    'Solers',
    'Houppeville',
    'Sommedieue',
    'Fontaines',
    'Quevillon',
    'Rouxmesnil-Bouteilles',
    'Regniowez',
    "Saint-Seurin-sur-l'Isle",
    'Remigny',
    'Bessonies',
    'Javrezac',
    "Borderes-sur-l'Echez",
    'Collorgues',
    'La Calmette',
    'Saint-Genies-de-Malgoires',
    'Ouilly-le-Tesson',
    'Saint-Remy-du-Nord',
    'Sours',
    'Sandouville',
    'Mouroux',
    'Bassou',
    'Laduz',
    'Nogent-sur-Eure',
    'Olle',
    'Bannes',
    'Bertrimoutier',
    'Fontaine-Uterte',
    'Quillan',
    'Claouey',
    'Erbree',
    'Largillay-Marsonnay',
    'Lentigny',
    'Lavans-les-Saint-Claude',
    'Wambaix',
    'Azille',
    'Saint-Leonard',
    'Lespesses',
    'Wissignicourt',
    'Hondschoote',
    'Vieux-Conde',
    'La Frenaye',
    'Bazoilles-sur-Meuse',
    'Colomby-sur-Thaon',
    'Fresnicourt-le-Dolmen',
    'Blaye-les-Mines',
    'Strazeele',
    'Caestre',
    'Bucy-les-Cerny',
    'Royas',
    'Gommerville',
    'Crouttes',
    'Nordausques',
    'Flaux',
    'Bagnols-en-Foret',
    'Saint-Pierre-des-Fleurs',
    "L'Orbrie",
    'Saint-Oulph',
    'Capinghem',
    'Reyssouze',
    'Plouedern',
    'Boigny',
    'Mortagne-du-Nord',
    'Anhiers',
    'Saint-Michel-sur-Savasse',
    'Cizely',
    'Folles',
    'Blanzay-sur-Boutonne',
    'Cenon-sur-Vienne',
    'Vaubecourt',
    'Velleches',
    'Moustier-en-Fagne',
    'Acheville',
    'Vigueron',
    'Sourribes',
    'Corbara',
    'Goult',
    'Monticello',
    'Omessa',
    'Francardo',
    'La Rochegiron',
    'Roumoules',
    'Fredille',
    'Villorceau',
    'Thimert-Gatelles',
    'Charge',
    'Saint-Eloy-de-Gy',
    'Quincy',
    'Bouzonville-en-Beauce',
    'Outarville',
    'Saumeray',
    'Ciron',
    'Cussay',
    'Menetou-Salon',
    'Dun-le-Poelier',
    'Couy',
    'La Gaillarde',
    'Bourneville',
    'Asnieres',
    'Londinieres',
    'Vieux-Rouen-sur-Bresle',
    'Tocqueville',
    'Beautot',
    'Port-Mort',
    'Tourville-la-Chapelle',
    'Etalondes',
    'Berville-la-Campagne',
    'Le Manoir',
    'Saint-Vaast-du-Val',
    'Saints',
    'Torderes',
    'Saint-Paulet-de-Caisson',
    'Vialas',
    'Sauveterre',
    'Targassonne',
    'Narbonne-Plage',
    'Cepie',
    'Cervera de la Marenda',
    'Los Masos',
    'Saint-Alexandre',
    'La Liviniere',
    'La Canourgue',
    'Valflaunes',
    "Saint-Chely-d'Apcher",
    'Venejan',
    'Ferrals-les-Corbieres',
    'La Jumelliere',
    'Puyravault',
    'Ruaudin',
    'Le Puy',
    'Saint-Laurent-sur-Sevre',
    'Falleron',
    'Chaix',
    'La Meilleraie-Tillay',
    'La Pommeraye',
    'Aron',
    'La Jaille-Yvon',
    'Roeze-sur-Sarthe',
    'La Poueze',
    'Solesmes',
    'Fresnay-en-Retz',
    'Chaille-les-Marais',
    'Ardenay-sur-Merize',
    'Saint-Colomban',
    'Pierric',
    'La Bosse',
    'Nesmy',
    'Saint-Just-Malmont',
    'Vertrieu',
    'Magneux-Haute-Rive',
    'Lancie',
    'Saint-Etienne-de-Fontbellon',
    'Seyssuel',
    'Bonnet',
    'Sarcey',
    'Soleymieux',
    'Champoly',
    'Ambronay',
    'Villeneuve-de-Marc',
    'Douvres',
    'Ourches',
    'Brullioles',
    'Satolas-et-Bonce',
    'Ternay',
    'Agnin',
    'Fleurie',
    'Moire',
    'Chambles',
    'Leigneux',
    'Poncins',
    'Villemotier',
    'Tramole',
    'Precieux',
    'Saint-Romain-de-Lerps',
    'Bagnols',
    'Salvizinet',
    'La Palud',
    'Lamaire',
    'Bunzac',
    'Chiche',
    "Lescure-d'Albigeois",
    'Bellegarde-Sainte-Marie',
    'Montignac-le-Coq',
    'Beychac-et-Caillau',
    'Saint-Pompont',
    'Verac',
    'Campugnan',
    'Frontenac',
    'Hautefage-la-Tour',
    'Monbrun',
    'Montpitol',
    'Saint-Mary',
    'Le Castera',
    'Allemans-du-Dropt',
    'Sauveterre-de-Comminges',
    'Montbartier',
    'Corme-Ecluse',
    'Seyches',
    'Vendays-Montalivet',
    'La Clotte',
    'Varaize',
    'Croix-Chapeau',
    'Saint-Meme-les-Carrieres',
    'Melleran',
    'Soublecause',
    'Belmont-sur-Rance',
    'Roqueseriere',
    'Frespech',
    'Rauzan',
    'Labretonie',
    'Roquelaure-Saint-Aubin',
    'Thaims',
    'Muron',
    'Toulx-Sainte-Croix',
    'Camon',
    'Layrisse',
    'Saint-Lieux-les-Lavaur',
    'Carlus',
    'Saint-Pierre-de-Mons',
    'Pomarez',
    'Terves',
    'Gorre',
    'Romegoux',
    'Les Cars',
    'La Riviere',
    'Saint-Lary-Soulan',
    'Cocumont',
    'Jumilhac-le-Grand',
    'Loupiac-de-la-Reole',
    'Montesquieu',
    'Lasserre',
    'Villejoubert',
    'Sablonceaux',
    'Chail',
    'Preguillac',
    'Aumagne',
    'Luquet',
    'Saint-Savin',
    'Vivonne',
    'Saint-Androny',
    'Bouniagues',
    'Douville',
    'La Bastide',
    'Mons',
    'Saint-Reverien',
    'Dampierre',
    'Audelange',
    'Saulon-la-Chapelle',
    'Bolandoz',
    'Marigny',
    'Bulgneville',
    'Logelheim',
    'Flaxlanden',
    'Seppois-le-Haut',
    'Melsheim',
    'Ligsdorf',
    'Serocourt',
    'Domjevin',
    'Commissey',
    'Saint-Hippolyte',
    'Chatillon-sur-Saone',
    'Sermamagny',
    'Virey-le-Grand',
    'Planay',
    'Rothau',
    'Russ',
    'Pfaffenheim',
    'Wahlenheim',
    'Rossfeld',
    'Eblange',
    'La Chapelle-aux-Bois',
    'Solgne',
    'Henridorff',
    'Pleboulle',
    'Lanleff',
    'Le Folgoet',
    'Malguenac',
    'Landeda',
    'Ploumoguer',
    'Corlay',
    'Lampaul-Guimiliau',
    'Cast',
    'Rochefort-en-Terre',
    'Plounevez-Lochrist',
    'Graces',
    'Prat',
    'La Malhoure',
    'Garlan',
    'Mello',
    'Dreuil-les-Amiens',
    'Senuc',
    'Origny-le-Sec',
    'Saint-Germainmont',
    'Leuvrigny',
    'Tauxieres-Mutry',
    'Thilay',
    'Lavau',
    'Crancey',
    'Sacy',
    'Mairieux',
    'Marcelcave',
    'Rouy-le-Grand',
    'Saint-Etienne-sur-Suippe',
    'Bussy-Lettree',
    'Soulaines-Dhuys',
    'Chateauvillain',
    'Huiron',
    'Blaise-sous-Arzillieres',
    'Lealvillers',
    'Roquefort',
    'Arancou',
    'Eyliac',
    'Azas',
    'Saint-Lanne',
    'Savigne',
    'Bussiere-Poitevine',
    'Montpinier',
    'La Faye',
    'Saint-Claud',
    'Angeac-Charente',
    'Marestaing',
    "Saint-Pierre-d'Irube",
    'Vielle-Saint-Girons',
    'Cannet',
    'Lucq-de-Bearn',
    'Villeve',
    'Meauzac',
    'Pranzac',
    'Bussiere-Galant',
    'Le Breuil',
    'La Magdelaine-sur-Tarn',
    'Condac',
    'Pey',
    'Fons',
    'Belfort-du-Quercy',
    'Fougax-et-Barrineuf',
    'Niderhoff',
    'Domevre-sur-Durbion',
    'Fresse-sur-Moselle',
    'Blamont',
    'Ringendorf',
    'Wolxheim',
    'Jeanmenil',
    'Adamswiller',
    'Kientzheim',
    'Niederschaeffolsheim',
    'Juville',
    'Oberlauterbach',
    'Melay',
    'Savigny-le-Sec',
    'Villers-sur-Port',
    'Champvans',
    'Lugny-les-Charolles',
    'Belrupt-en-Verdunois',
    'Sanchey',
    'Waldwisse',
    'Docelles',
    'Durrenentzen',
    'Rohr',
    'Lafox',
    'Belhade',
    'Meilhac',
    'Lacroix-Falgarde',
    'La Loubiere',
    'Escorneboeuf',
    'Tosse',
    'Momy',
    'Fosses-et-Baleyssac',
    'Montiron',
    'Cabara',
    'Merignas',
    'Suzanne',
    'Nieul-les-Saintes',
    'Le Fossat',
    'Cayrac',
    'La Rouquette',
    'La Ronde',
    'Cieurac',
    'Fontanes',
    'Gurs',
    'Lamothe-Landerron',
    'Chantecorps',
    'Castagnede',
    'Caudecoste',
    'Vignonet',
    'Saint-Martin-Sainte-Catherine',
    'Trouley-Labarthe',
    'Plaisance',
    "Saint-Martin-d'Arrossa",
    'Arberats-Sillegue',
    'Vielle-Tursan',
    'Misson',
    'Aubie-et-Espessas',
    'Hautefort',
    'Han-sur-Nied',
    'Marsilly',
    'Latille',
    'Prefontaines',
    'Ruitz',
    'Plerneuf',
    'Saint-Gilles-les-Bois',
    'Pommerit-Jaudy',
    'Saint-Brandan',
    'Orsonville',
    'Sabatier',
    'Eletot',
    'Sainte-Croix-aux-Mines',
    'Meymac',
    'Milon-la-Chapelle',
    'Renault',
    'Laas',
    'Champcenest',
    'San-Giuliano',
    'Bretigny',
    'Epinoy',
    'Les Bordes-sur-Lez',
    'Plateau',
    'Vendeville',
    'Jeux-les-Bard',
    'Saint-Paul',
    'Tredarzec',
    'Saint-Jean-Kerdaniel',
    'Bouquet',
    'Souzy-la-Briche',
    'Saint-Malo-de-Guersac',
    'Pretot-Vicquemare',
    'Griesbach-au-Val',
    'Saint-Senoch',
    'Montagrier',
    'Ormersviller',
    'Schorbach',
    'Trouville',
    'Saint-Theodorit',
    'La Poste',
    'Meisenthal',
    'Bettviller',
    'Giron',
    'Leval',
    'La Defense',
    'Doncourt-les-Conflans',
    'Haute-Vigneulles',
    'Neufchef',
    'Tressange',
    'Marange-Zondrange',
    'Brouviller',
    'Argancy',
    'Koeur-la-Petite',
    'Aumont-Aubrac',
    'Villers-sur-Meuse',
    'Beaulieu-sous-Parthenay',
    'Misse',
    'Loubigne',
    'Landepereuse',
    'La Chapelle-Gaudin',
    'Colombier-Fontaine',
    'Bourg-sous-Chatelet',
    'Denney',
    'Vandoncourt',
    'Bessoncourt',
    'Tavey',
    'Itxassou',
    'Igon',
    'Moumour',
    'Corbere-Aberes',
    'Saint-Sulpice-la-Foret',
    'Noisy-Rudignon',
    'Pietralba',
    'Ota',
    'Auffreville-Brasseuil',
    'Mathieu',
    'Auvillers-les-Forges',
    'Biot',
    'Longnes',
    'Eth',
    'Rocher',
    'Neuvy',
    'Lens-Lestang',
    'Meillonnas',
    'Bailly-le-Franc',
    'Saint-Leger-aux-Bois',
    'Saint-Pierre-le-Vieux',
    'Douy-la-Ramee',
    'Vigny',
    'La Roche',
    'Piazza',
    'Cervione',
    'Vico',
    'Le Bleymard',
    'Labastide-de-Virac',
    'Porto',
    'Ance',
    'Ferriere-Larcon',
    'Poix',
    'Dunes',
    'Cousolre',
    'Bord',
    'Grosseto-Prugna',
    'Crocq',
    'Villemur',
    'Bray',
    'Signy',
    'Cadours',
    'Montastruc-de-Salies',
    'Rouvroy',
    'Mons',
    'Aubigny-en-Laonnois',
    'Montaigu',
    'Orangis',
    'Neuville',
    'Paris 17 Batignolles-Monceau',
    'Castiglione',
    'Vaillant',
    'Englos',
    'Roussy',
    'Merenvielle',
    'Ecoche',
    'Gousse',
    'Savy',
    'Les Palais',
    'Liernais',
    'Gouaux',
    'Chasseneuil',
    'Cros-de-Ronesque',
    'Liart',
    'Precey',
    'Vienville',
    'Saint-Nauphary',
    'Furchhausen',
    'Varennes',
    'Le Val-dAjol',
    'Châteauponsac',
    'Fregimont',
    'Munchhouse',
    'Bagnols',
    'Aymeries',
    'Perols',
    'Faches',
    'Heuchin',
    'Lastic',
    'Hucqueliers',
    'Mortagne',
    'Verne',
    'Grandpre',
    'Parsac',
    'Coupigny',
    'Pionsat',
    'Venant',
    'Sabres',
    'Sainte-Marie-aux-Chenes',
    'Jumelles',
    'Berneval-le-Grand',
    'Thizy-les-Bourgs',
    'Marnand',
    'Ternand',
    'Hochstatt',
    'Ferrette',
    'Savoie',
    'Chevremont',
    'Sorel-en-Vimeu',
    'Fournier',
    'Saint-Simon',
    'Mondetour',
    'Aragon',
    'Nonneville',
    'Cravans',
    'La Copechagniere',
    'La Poiteviniere',
    'Sevres-Anxaumont',
    'Sceaux-du-Gatinais',
    'Gumbrechtshoffen',
    'Hohfrankenheim',
    'Valmy',
    'Besson',
    'Rimbaud',
    'Pompidou',
    'Adam-les-Vercel',
    'Grandville-Gaudreville',
    'Paix',
    'Le Parc',
    'Gouvernes',
    'Casanova',
    'Dorval',
    'Brain-sur-Vilaine',
    'Les Landes',
    'Lesches',
    'Mont-Cauvaire',
    'Collandres-Quincarnon',
    'Henansal',
    'Montrouveau',
    'Les Laumes',
    'Levie',
    'Meyssac',
    'Olliergues',
    'Epegard',
    'Pontaumur',
    'Saint-Sever-Calvados',
    'Vigneulles',
    'Luttange',
    'Gremecey',
    'Haraucourt',
    'Houdelmont',
    'Barisey-la-Cote',
    'Saint-Boingt',
    'Veho',
    'Adelans-et-le-Val-de-Bithaine',
    'Moussy-le-Vieux',
    'Bantzenheim',
    'Duttlenheim',
    'Jallerange',
    'Fourg',
    'Saint-Germain-Laxis',
    'Montpincon',
    'Saulcy-sur-Meurthe',
    'Longeault',
    'Parcieux',
    'Montrond',
    'Saint-Germain',
    'Somme',
    'Serres',
    'Aussonce',
    'Braquis',
    'Guiche',
    'Anneville-Ambourville',
    'La Motte-Saint-Martin',
    'Lacroix-sur-Meuse',
    'Courdimanche-sur-Essonne',
    'Saint-Pierre-de-Lages',
    'Segny',
    'Daubeuf-la-Campagne',
    'Salins-les-Thermes',
    'Grisy-sur-Seine'
  ],
  Germany: [
    'Langgons',
    'Holle',
    'Tespe',
    'Walsrode',
    'Salzgitter',
    'Bad Sassendorf',
    'Vienenburg',
    'Rosdorf',
    'Einbeck',
    'Markersbach',
    'Westensee',
    'Lauterbach',
    'Marburg',
    'Dornburg',
    'Mainz',
    'Weinbach',
    'Cologne',
    'Epfenbach',
    'Essen',
    'Huttenberg',
    'Borgentreich',
    'Mettmann',
    'Schutzbach',
    'Florstadt',
    'Markdorf',
    'Sponheim',
    'Warstein',
    'Gelsenkirchen',
    'Sprockhovel',
    'Miellen',
    'Grebenau',
    'Pohl',
    'Sohren',
    'Grosslittgen',
    'Dillenburg',
    'Frankfurt am Main',
    'Tholey',
    'Runkel',
    'Aerzen',
    'Chemnitz',
    'Worrstadt',
    'Windischleuba',
    'Unnau',
    'Waldbrol',
    'Gebhardshain',
    'Berg',
    'Rheinbreitbach',
    'Wandlitz',
    'Euskirchen',
    'Gau-Bickelheim',
    'Leitzkau',
    'Pfreimd',
    'Biederitz',
    'Langenwetzendorf',
    'Donndorf',
    'Stadtkyll',
    'Pressath',
    'Bockau',
    'Nossen',
    'Homberg',
    'Borken',
    'Tobertitz',
    'Haren',
    'Zossen',
    'Grundau',
    'Schlotfeld',
    'Apolda',
    'Limbach-Oberfrohna',
    'Konigs Wusterhausen',
    'Netphen',
    'Altkirchen',
    'Steyerberg',
    'Hohndorf',
    'Dortmund',
    'Spenge',
    'Selm',
    'Espenau',
    'Lemgo',
    'Bochum',
    'Halver',
    'Bergkamen',
    'Erndtebruck',
    'Rudersdorf',
    'Lübeck',
    'Berlin',
    'Gluckstadt',
    'Bernau bei Berlin',
    'Moor',
    'Dresden',
    'Groditz',
    'Nitz',
    'Sebnitz',
    'Colmnitz',
    'Dobeln',
    'Lauchhammer',
    'Olbernhau',
    'Frankenberg',
    'Rabenau',
    'Finsterwalde',
    'Ebersbach',
    'Hockendorf',
    'Friedersdorf',
    'Coswig',
    'Radebeul',
    'Weinbohla',
    'Klettwitz',
    'Rammenau',
    'Freital',
    'Weisswasser',
    'Thiendorf',
    'Brand-Erbisdorf',
    'Senftenberg',
    'Lunzenau',
    'Halsbrucke',
    'Zwickau',
    'Dippoldiswalde',
    'Ottendorf-Okrilla',
    'Pirna',
    'Niederwiesa',
    'Frauendorf',
    'Wittgensdorf',
    'Falkenberg',
    'Geringswalde',
    'Burkhardtsdorf',
    'Bannewitz',
    'Lichtenberg',
    'Freiberg',
    'Lichtenstein',
    'Niesky',
    'Penig',
    'Calau',
    'Frauenstein',
    'Bischofswerda',
    'Görlitz',
    'Halle',
    'Marienberg',
    'Rudolstadt',
    'Neukirchen',
    'Riesa',
    'Heidenau',
    'Priestewitz',
    'Zittau',
    'Oelsnitz',
    'Grossrohrsdorf',
    'Fraureuth',
    'Altenburg',
    'Ruckersdorf',
    'Leipzig',
    'Annaberg-Buchholz',
    'Sohland',
    'Schwarzenberg',
    'Mittweida',
    'Nunchritz',
    'Goldberg',
    'Schenkenhorst',
    'Woltersdorf',
    'Malchow',
    'Velten',
    'Havelberg',
    'Brandenburg',
    'Mullrose',
    'Magdeburg',
    'Lubtheen',
    'Waren',
    'Gransee',
    'Templin',
    'Genthin',
    'Klein Rogahn',
    'Vellahn',
    'Wefensleben',
    'Potsdam',
    'Luckenwalde',
    'Werneuchen',
    'Werder',
    'Brodhagen',
    'Eisenhüttenstadt',
    'Schermen',
    'Teltow',
    'Schollene',
    'Plate',
    'Hamburg',
    'Ganderkesee',
    'Seth',
    'Laatzen',
    'Stadthagen',
    'Hanover',
    'Varel',
    'Winsen',
    'Isenbuttel',
    'Jevenstedt',
    'Achim',
    'Uetze',
    'Bargfeld-Stegen',
    'Kiel',
    'Oldenburg',
    'Bremen',
    'Bad Zwischenahn',
    'Ratekau',
    'Heist',
    'Elmshorn',
    'Aurich',
    'Padelugge',
    'Buchholz',
    'Seevetal',
    'Meldorf',
    'Bonnigheim',
    'Uberlingen',
    'Geislingen',
    'Sandhausen',
    'Rottenburg',
    'Hessheim',
    'Ittlingen',
    'Mannheim',
    'Niefern-Oschelbronn',
    'Friedenweiler',
    'Bietigheim-Bissingen',
    'Elmstein',
    'Backnang',
    'Zwiefalten',
    'Rheinau',
    'Eigeltingen',
    'Freudenweiler',
    'Bad Liebenzell',
    'Landau',
    'Stuttgart',
    'Worms',
    'Ludwigshafen am Rhein',
    'Karlsruhe',
    'Pforzheim',
    'Bad Duerkheim',
    'Mersheim',
    'Ostfildern',
    'Hamm',
    'Bielefeld',
    'Bad Oeynhausen',
    'Münster',
    'Dusseldorf',
    'Lubbecke',
    'Point',
    'Lohfelden',
    'Nordkirchen',
    'Kassel',
    'Hagen',
    'Soest',
    'Recklinghausen',
    'Paderborn',
    'Buende',
    'Geseke',
    'Neuenrade',
    'Pinneberg',
    'Neustadt-Glewe',
    'Gingst',
    'Calbe',
    'Ploen',
    'Seefeld',
    'Wesenberg',
    'Falkensee',
    'Barleben',
    'Neuruppin',
    'Zehdenick',
    'Weddingstedt',
    'Flensburg',
    'Heide',
    'Bargteheide',
    'Kaltenkirchen',
    'Geesthacht',
    'Ahrensburg',
    'Norderstedt',
    'Einhaus',
    'Alveslohe',
    'Itzehoe',
    'Reinbek',
    'Wedel',
    'Bad Segeberg',
    'Grosshansdorf',
    'Glinde',
    'Bad Bramstedt',
    'Barmstedt',
    'Schenefeld',
    'Borstel-Hohenraden',
    'Henstedt-Ulzburg',
    'Tornesch',
    'Ratzeburg',
    'Wentorf bei Hamburg',
    'Neu Wulmstorf',
    'Kroppelshagen-Fahrendorf',
    'Molln',
    'Husum',
    'Fahrendorf',
    'Halstenbek',
    'Wilhelmshaven',
    'Diepholz',
    'Cloppenburg',
    'Schortens',
    'Jever',
    'Lohne',
    'Damme',
    'Vechta',
    'Wittmund',
    'Delmenhorst',
    'Nuremberg',
    'Stein',
    'Gunzenhausen',
    'Erlangen',
    'Oberasbach',
    'Neumarkt-Sankt Veit',
    'Burgthann',
    'Herzogenaurach',
    'Feucht',
    'Ansbach',
    'Bamberg',
    'Lauf an der Pegnitz',
    'Gnotzheim',
    'Amberg',
    'Fürth',
    'Katzwang',
    'Schwarzenbruck',
    'Roth',
    'Schwabach',
    'Sulzbach-Rosenberg',
    'Heroldsberg',
    'Kummersbruck',
    'Rosstal',
    'Neunkirchen am Sand',
    'Baiersdorf',
    'Zirndorf',
    'Pegnitz',
    'Coburg',
    'Schwerte',
    'Unna',
    'Werl',
    'Waltrop',
    'Munich',
    'Ahlen',
    'Lüdenscheid',
    'Arnsberg',
    'Werne',
    'Kierspe',
    'Iserlohn',
    'Marienheide',
    'Beckum',
    'Warendorf',
    'Olsberg',
    'Duisburg',
    'Sundern',
    'Lünen',
    'Dorsten',
    'Frondenberg',
    'Wickede',
    'Brilon',
    'Herdecke',
    'Menden',
    'Holzwickede',
    'Hemer',
    'Deutsch',
    'Kamen',
    'Bingen am Rhein',
    'Bobenheim-Roxheim',
    'Pirmasens',
    'Eisenberg',
    'Speyer',
    'Landstuhl',
    'Viernheim',
    'Bad Kreuznach',
    'Hettenleidelheim',
    'Frankenthal',
    'Homburg',
    'Weinheim',
    'Dudenhofen',
    'Mutterstadt',
    'Grunstadt',
    'Munster-Sarmsheim',
    'Rodalben',
    'Zweibrücken',
    'Neustadt/Westerwald',
    'Birkenau',
    'Germersheim',
    'Altrip',
    'Schriesheim',
    'Hordt',
    'Ramstein-Miesenbach',
    'Mehlbach',
    'Langen',
    'Neustadt',
    'Heidelberg',
    'Neckargemund',
    'Leimen',
    'Dannstadt-Schauernheim',
    'Schifferstadt',
    'Limburgerhof',
    'Eppelheim',
    'Bad Mergentheim',
    'Schwetzingen',
    'Lampertheim',
    'Dossenheim',
    'Nussloch',
    'Ellerstadt',
    'Neuhofen',
    'Kahl am Main',
    'Ketsch',
    'Rodersheim-Gronau',
    'Rubke',
    'Naila',
    'Steinwiesen',
    'Selbitz',
    'Radolfzell',
    'Konstanz',
    'Rastatt',
    'Rulzheim',
    'Schwäbisch Hall',
    'Gaildorf',
    'Giengen an der Brenz',
    'Lauchheim',
    'Wasser',
    'Freiburg',
    'Bad Krozingen',
    'Schopfheim',
    'Lörrach',
    'Bad Sackingen',
    'Leinfelden-Echterdingen',
    'Echterdingen',
    'Wuppertal',
    'Krefeld',
    'Alfter',
    'Hilchenbach',
    'Kaarst',
    'Winterberg',
    'Steinbach am Taunus',
    'Remscheid',
    'Rheinbach',
    'Preussisch Oldendorf',
    'Huellhorst',
    'Grossbottwar',
    'Erftstadt',
    'Burgkunstadt',
    'Langfurth',
    'Gleissenberg',
    'Viechtach',
    'Nittenau',
    'Chamerau',
    'Jena',
    'Bad Durrenberg',
    'Oranienburg',
    'Bernburg',
    'Wernigerode',
    'Cottbus',
    'Lubbenau',
    'Schonefeld',
    'Plauen',
    'Bodenfelde',
    'Wolfhagen',
    'Host',
    'Kirchheim unter Teck',
    'Kurnbach',
    'Weiden',
    'Hemmingen',
    'Igensdorf',
    'Volkach',
    'Bergtheim',
    'Sulzheim',
    'Baar-Ebenhausen',
    'Beilngries',
    'Altmannstein',
    'Rottweil',
    'Blumberg',
    'Dauchingen',
    'Lauda-Konigshofen',
    'Tauberbischofsheim',
    'Wertheim am Main',
    'Crailsheim',
    'Neckarsulm',
    'Bretzfeld',
    'Heilbronn',
    'Rheinfelden',
    'Tuttlingen',
    'Fridingen an der Donau',
    'Owingen',
    'Berghausen',
    'Appenweier',
    'Bruchsal',
    'Gondelsheim',
    'Friolzheim',
    'Bad Wildbad im Schwarzwald',
    'Olbronn-Durrn',
    'Wildberg',
    'Althengstett',
    'Ludwigsburg',
    'Tamm',
    'Sindelfingen',
    'Herrenberg',
    'Ehningen',
    'Renningen',
    'Gosheim',
    'Bad Durrheim',
    'Wehingen',
    'Villingen-Schwenningen',
    'Esslingen am Neckar',
    'Ellwangen',
    'Wiesloch',
    'Friedrichshafen',
    'Tettnang Castle',
    'Epfendorf',
    'Bohringen',
    'Horb am Neckar',
    'Bad Uberkingen',
    'Göppingen',
    'Gladbeck',
    'Herten',
    'Gera',
    'Stahl',
    'Wintrich',
    'Hermersberg',
    'Thallichtenberg',
    'Merzig',
    'Saarlouis',
    'Engen',
    'Kaufungen',
    'Aachen',
    'Herzogenrath',
    'Frechen',
    'Wetzlar',
    'Herne',
    'Babenhausen',
    'Darmstadt',
    'Morfelden-Walldorf',
    'Taunusstein',
    'Offenbach',
    'Oberhausen',
    'Castrop-Rauxel',
    'Oberursel',
    'Bad Soden am Taunus',
    'Jestadt',
    'Minden',
    'Wesseling',
    'Inden',
    'Hattingen',
    'Kelkheim (Taunus)',
    'Wiesbaden',
    'Kall',
    'Mechernich',
    'Sankt Augustin',
    'Bonn',
    'Leichlingen',
    'Leverkusen',
    'Mülheim',
    'Bad Vilbel',
    'Kempen',
    'Florsheim',
    'Ginsheim-Gustavsburg',
    'Heusenstamm',
    'Hofheim am Taunus',
    'Obertshausen',
    'Nauheim',
    'Rüsselsheim',
    'Hochheim am Main',
    'Kelsterbach',
    'Bischofsheim',
    'Assenheim',
    'Kleve',
    'Willich',
    'Bedburg',
    'Senden',
    'Friedberg',
    'Eriskirch',
    'Ravensburg',
    'Immenstaad am Bodensee',
    'Hockenheim',
    'Albstadt',
    'Wurmlingen',
    'Rielasingen-Worblingen',
    'Singen',
    'Pfullendorf',
    'Waiblingen',
    'Kuchen',
    'Salach',
    'Lorch',
    'Gerlingen',
    'Kressbronn am Bodensee',
    'Oftersheim',
    'Geisingen',
    'Wolfsburg',
    'Wahrenholz',
    'Braunschweig',
    'Meine',
    'Lentfohrden',
    'Holm',
    'Bonningstedt',
    'Hasloh',
    'Moritz',
    'Ulm',
    'Neu-Ulm',
    'Rellingen',
    'Erkner',
    'Neuenhagen',
    'Uelzen',
    'Wustermark',
    'Spremberg',
    'Bestensee',
    'Woltersdorf',
    'Mittenwalde',
    'Furstenwalde',
    'Cuxhaven',
    'Grevesmuhlen',
    'Wismar',
    'Osnabrück',
    'Rostock',
    'Bredstedt',
    'Niebull',
    'Eckernforde',
    'Georgsmarienhutte',
    'Bad Iburg',
    'Hildesheim',
    'Hamelin',
    'Hinte',
    'Emden',
    'Goslar',
    'Peine',
    'Helmstedt',
    'Konigslutter am Elm',
    'Göttingen',
    'Stuhr',
    'Langen',
    'Nordenham',
    'Bremerhaven',
    'Lehrte',
    'Wolfenbüttel',
    'Ahstedt',
    'Nienburg',
    'Rotenburg',
    'Herzberg am Harz',
    'Hemelingen',
    'Hohenkirchen-Siegertsbrunn',
    'Deisenhofen',
    'Halfing',
    'Weilheim',
    'Lindenberg im Allgau',
    'Oberstaufen',
    'Scheidegg',
    'Bayreuth',
    'Burghausen',
    'Schweinfurt',
    'Markt Wald',
    'Weissenburg',
    'Treuchtlingen',
    'Emmering',
    'Fürstenfeldbruck',
    'Gilching',
    'Schwabhausen',
    'Grobenzell',
    'Planegg',
    'Kempten (Allgaeu)',
    'Olching',
    'Maisach',
    'Murnau am Staffelsee',
    'Oberau',
    'Füssen',
    'Waldkraiburg',
    'Passau',
    'Guenzburg',
    'Burgau',
    'Krumbach',
    'Werningshausen',
    'Ilmenau',
    'Handewitt',
    'Harrislee',
    'Kappeln',
    'Bremervorde',
    'Kuddeworde',
    'Greifswald',
    'Preetz',
    'Bordesholm',
    'Jesteburg',
    'Buchholz in der Nordheide',
    'Lüneburg',
    'Winsen',
    'Bad Schwartau',
    'Herrnburg',
    'Raisdorf',
    'Parchim',
    'Ueckermunde',
    'Melsdorf',
    'Stralsund',
    'Simonsberg',
    'Scharbeutz',
    'Heikendorf',
    'Schwerin',
    'Bruel',
    'Wittenforden',
    'Neustrelitz',
    'Robel',
    'Loitz',
    'Ostseebad Kuhlungsborn',
    'Langwedel',
    'Hassel',
    'Mildstedt',
    'Stade',
    'Deutsch Evern',
    'Pasewalk',
    'Neubrandenburg',
    'Schneverdingen',
    'Soltau',
    'Fahrenkrug',
    'Dorverden',
    'Schonberg',
    'Neumünster',
    'Wahlstedt',
    'Rendsburg',
    'Borgstedt',
    'Westerronfeld',
    'Osterronfeld',
    'Kremperheide',
    'Oelixdorf',
    'Oststeinbek',
    'Schleswig',
    'Appen',
    'Maschen',
    'Dassendorf',
    'Schwarzenbek',
    'Wohltorf',
    'Wees',
    'Verden an der Aller',
    'Schonkirchen',
    'Sarkwitz',
    'Hamberge',
    'Kronshagen',
    'Ebstorf',
    'Molfsee',
    'Mielkendorf',
    'Ellerau',
    'Timmendorf Beach',
    'Buedelsdorf',
    'Bendestorf',
    'Uetersen',
    'Lauenburg',
    'Neustadt in Holstein',
    'Heiligenhafen',
    'Lutjenburg',
    'Quickborn',
    'Tangstedt',
    'Tangstedt',
    'Negast',
    'Langendorf',
    'Stockelsdorf',
    'Prangendorf',
    'Lagerdorf',
    'Jersbek',
    'Lutjensee',
    'Hoisdorf',
    'Tremsbuttel',
    'Nahe',
    'Itzstedt',
    'Dabelow',
    'Monkeberg',
    'Altenholz',
    'Buxtehude',
    'Escheburg',
    'Barsbuettel',
    'Ludwigslust',
    'Elmenhorst',
    'Grabau',
    'Sassnitz',
    'Sagard',
    'Kisdorf',
    'Selmsdorf',
    'Kummerfeld',
    'Krempe',
    'Hanstedt',
    'Demmin',
    'Prisdorf',
    'Schwalingen',
    'Sprengel',
    'Neuenkirchen',
    'Munster',
    'Uelvesbull',
    'Neu Duvenstedt',
    'Wendisch Evern',
    'Adendorf',
    'Marne',
    'Celle',
    'Jork',
    'Reppenstedt',
    'Bad Bevensen',
    'Wrestedt',
    'Vogelsen',
    'Bardowick',
    'Apensen',
    'Hemmoor',
    'Rosengarten',
    'Eggingen',
    'Kadelburg',
    'Wiernsheim',
    'Mittelstenweiler',
    'Nagold',
    'Leonberg',
    'Schwäbisch Gmünd',
    'Hochdorf',
    'Ingoldingen',
    'Mutlangen',
    'Mittelbiberach',
    'Biberach an der Riss',
    'Loxstedt',
    'Stinstedt',
    'Wanna',
    'Brake (Unterweser)',
    'Hechthausen',
    'Hambergen',
    'Schiffdorf',
    'Lamstedt',
    'Hagen',
    'Himmelpforten',
    'Vollersode',
    'Nordholz',
    'Bulkau',
    'Drochtersen',
    'Bockhorn',
    'Sande',
    'Zetel',
    'Friedeburg',
    'Wiesmoor',
    'Schweiburg',
    'Sottrum',
    'Dohren',
    'Tarmstedt',
    'Tostedt',
    'Hemsbunde',
    'Zeven',
    'Helvesiek',
    'Otter',
    'Worpswede',
    'Schwanewede',
    'Gnarrenburg',
    'Mittelnkirchen',
    'Lilienthal',
    'Syke',
    'Rastede',
    'Westerstede',
    'Hatten',
    'Garrel',
    'Friesoythe',
    'Norden',
    'Blomberg',
    'Hage',
    'Mittegrossefehn',
    'Eversmeer',
    'Esens',
    'Upgant-Schott',
    'Hesel',
    'Ihlow',
    'Westerholt',
    'Wirdum',
    'Berumbur',
    'Dornum',
    'Grossheide',
    'Remels',
    'Norderney',
    'Herford',
    'Melle',
    'Hiddenhausen',
    'Espelkamp',
    'Lohne',
    'Lauenhagen',
    'Enger',
    'Adorf',
    'Trostberg an der Alz',
    'Insingen',
    'Dietramszell',
    'Rabenholz',
    'Dassow',
    'Weinsfeld',
    'Barkow',
    'Wermelskirchen',
    'Poing',
    'Riedering',
    'Maintal',
    'Rothenstein',
    'Erfurt',
    'Auma',
    'Heidenburg',
    'Laufach',
    'Freilassing',
    'Bad Reichenhall',
    'Piding',
    'Saaldorf',
    'Weissenhorn',
    'Gauting',
    'Grafelfing',
    'Senden',
    'Pfaffenhofen an der Roth',
    'Poppenricht',
    'Ebermannsdorf',
    'Geretsried',
    'Starnberg',
    'Wolfratshausen',
    'Pocking',
    'Freising',
    'Mindelheim',
    'Altusried',
    'Neufahrn bei Freising',
    'Hallbergmoos',
    'Konigsbrunn',
    'Buchloe',
    'Regensburg',
    'Weiden',
    'Marktredwitz',
    'Arzberg',
    'Bad Alexandersbad',
    'Hallstadt',
    'Viereth-Trunstadt',
    'Pressig',
    'Stockstadt am Main',
    'Aschaffenburg',
    'Amorbach',
    'Friedrichsdorf',
    'Kleinostheim',
    'Hosbach',
    'Niedernberg',
    'Emtmannsberg',
    'Speichersdorf',
    'Bindlach',
    'Weidenberg',
    'Osterode',
    'Andreasberg',
    'Bad Pyrmont',
    'Bad Gandersheim',
    'Dassel',
    'Kreiensen',
    'Rinteln',
    'Belm',
    'Meppen',
    'Osterholz-Scharmbeck',
    'Papenburg',
    'Langenhagen',
    'Wangerooge',
    'Gifhorn',
    'Calberlah',
    'Northeim',
    'Uslar',
    'Langelsheim',
    'Springe',
    'Stolzenau',
    'Sulingen',
    'Schoneiche',
    'Marklohe',
    'Liebenau',
    'Landesbergen',
    'Wriezen',
    'Strausberg',
    'Wennigsen',
    'Hagenow',
    'Boizenburg',
    'Bad Rothenfelde',
    'Trappenkamp',
    'Kalefeld',
    'Erding',
    'Pliening',
    'Markt Schwaben',
    'Oberding',
    'Diedorf',
    'Neusass',
    'Burgkirchen an der Alz',
    'Altoetting',
    'Haiming',
    'Neuotting',
    'Oberhaching',
    'Burglengenfeld',
    'Lappersdorf',
    'Polling',
    'Augsburg',
    'Penzberg',
    'Wielenbach',
    'Peissenberg',
    'Miesbach',
    'Kaufbeuren',
    'Gundelfingen',
    'Sauerlach',
    'Mehring',
    'Oberkotzau',
    'Hof',
    'Otterloh',
    'Landshut',
    'Glattbach',
    'Johannesberg',
    'Unterschleissheim',
    'Grafing bei Munchen',
    'Kirchseeon',
    'Haar',
    'Kaiserslautern',
    'Enkenbach-Alsenborn',
    'Reich',
    'Umkirch',
    'Baden-Baden',
    'Efringen-Kirchen',
    'Eschweiler',
    'Alsdorf',
    'Bad Homburg',
    'Niederkruchten',
    'Meerbusch',
    'Marl',
    'Bad Wildungen',
    'Erkelenz',
    'Haltern',
    'Ratingen',
    'Bocholt',
    'Bergisch Gladbach',
    'Gelnhausen',
    'Seligenstadt',
    'Hanau',
    'Bruhl',
    'Lohmar',
    'Mönchengladbach',
    'Neuss',
    'Troisdorf',
    'Geldern',
    'Egenhausen',
    'Altensteig',
    'Grombach',
    'Calw',
    'Inzigkofen',
    'Muhlacker',
    'Ostrach',
    'Flein',
    'Buhl',
    'Wolfschlugen',
    'Wendlingen am Neckar',
    'Volkertshausen',
    'Teningen',
    'Albershausen',
    'Zell unter Aichelberg',
    'Nurtingen',
    'Kirchentellinsfurt',
    'Wannweil',
    'Schiltach',
    'Unterkirnach',
    'Monchweiler',
    'Spaichingen',
    'Weinstadt-Endersbach',
    'Urbach',
    'Vogt',
    'Aulendorf',
    'Reutlingen',
    'Balingen',
    'Konigsbronn',
    'Aalen',
    'Pluderhausen',
    'Neuhausen auf den Fildern',
    'Dietenheim',
    'Ummendorf',
    'Mengen',
    'Saulgau',
    'Unlingen',
    'Metzingen',
    'Sinsheim',
    'Obrigheim',
    'Oedheim',
    'Ditzingen',
    'Fellbach',
    'Langenau',
    'Laupheim',
    'Warthausen',
    'Dornstadt',
    'Winnenden',
    'Schwieberdingen',
    'Maulburg',
    'Lauchringen',
    'Inzlingen',
    'Philippsburg',
    'Waghausel',
    'Ettlingen',
    'Bietigheim',
    'Kehl',
    'Berghaupten',
    'Altlussheim',
    'Frickingen',
    'Freudenstadt',
    'Saarbrücken',
    'Kemnath',
    'Lindau',
    'Wangen',
    'Rodental',
    'Burgrieden',
    'Schuttorf',
    'Emlichheim',
    'Borger',
    'Neuenhaus',
    'Osterwald',
    'Visselhovede',
    'Scheessel',
    'Harsefeld',
    'Vierhofen',
    'Salzhausen',
    'Hollenstedt',
    'Garlstorf',
    'Egestorf',
    'Asendorf',
    'Hemslingen',
    'Sittensen',
    'Ruspel',
    'Hetzwege',
    'Vierden',
    'Selsingen',
    'Grasberg',
    'Hammah',
    'Edewecht',
    'Harpstedt',
    'Bassum',
    'Holtinghausen',
    'Grossenkneten',
    'Beckstedt',
    'Bosel',
    'Dingstede',
    'Mehr',
    'Bank',
    'Memmingerberg',
    'Hetzlinshofen',
    'Lachen',
    'Zusmarshausen',
    'Stolberg',
    'Gütersloh',
    'Vellmar',
    'Ennepetal',
    'Neuwerk',
    'Viersen',
    'Korschenbroich',
    'Nidda',
    'Erkrath',
    'Hilden',
    'Isenburg',
    'Biedenkopf',
    'Langenfeld',
    'Rosrath',
    'Monheim am Rhein',
    'Frankenberg',
    'Dreieich',
    'Fulda',
    'Düren',
    'Kerpen',
    'Julich',
    'Kreuzau',
    'Espelkamp-Mittwald',
    'Sigmaringen',
    'Schlaitdorf',
    'Tübingen',
    'Ettenheim',
    'Offenburg',
    'Staufen im Breisgau',
    'Nufringen',
    'Böblingen',
    'Hildrizhausen',
    'Heidenheim',
    'Rosengarten',
    'Leutkirch im Allgau',
    'Sankt Leon-Rot',
    'Heddesheim',
    'Reichenbach',
    'Talheim',
    'Steinheim am der Murr',
    'Lochgau',
    'Sipplingen',
    'Sulz am Neckar',
    'Ilsfeld',
    'Mosbach',
    'Friesenheim',
    'Durbach',
    'Welzheim',
    'Donzdorf',
    'Dettingen unter Teck',
    'Filderstadt',
    'Ohringen',
    'Rietheim',
    'Tuningen',
    'Karben',
    'Heusweiler',
    'Sankt Ingbert',
    'Illingen',
    'Volklingen',
    'Grossrosseln',
    'Sankt Wendel',
    'Riegelsberg',
    'Quierschied',
    'Saarwellingen',
    'Remmesweiler',
    'Dessau',
    'Bernsdorf',
    'Grimma',
    'Saalfeld',
    'Neumark',
    'Erlenmoos',
    'Vaihingen an der Enz',
    'Erdmannhausen',
    'Weitnau',
    'Bad Waldsee',
    'Kornwestheim',
    'Bad Herrenalb',
    'Loffenau',
    'Weingarten',
    'Wangen im Allgau',
    'Herrischried',
    'Denkingen',
    'Loffingen',
    'Schorndorf',
    'Denzlingen',
    'Eschenbach',
    'Bissingen an der Teck',
    'Eislingen',
    'Unterensingen',
    'Bad Ditzenbach',
    'Neuenburg am Rhein',
    'Walldorf',
    'Laudenbach',
    'Durmersheim',
    'Untergruppenbach',
    'Eberstadt',
    'Bad Rappenau',
    'Raibach',
    'Braunsbach',
    'Heubach',
    'Ladenburg',
    'Bruhl',
    'Plankstadt',
    'Schwaikheim',
    'Kuppenheim',
    'Hirrlingen',
    'Rangendingen',
    'Weikersheim',
    'Pliezhausen',
    'Erbach',
    'Mietingen',
    'Huttisheim',
    'Rottenacker',
    'Zaberfeld',
    'Eisingen',
    'Weil der Stadt',
    'Schlat',
    'Kongen',
    'Walldurn',
    'Niederstetten',
    'Bad Wimpfen',
    'Zweiflingen',
    'Siegelsbach',
    'Brackenheim',
    'Asperg',
    'Aspach',
    'Dusslingen',
    'Hechingen',
    'Grossbettlingen',
    'Herdwangen-Schonach',
    'Schallstadt',
    'Schliengen',
    'Gernsbach',
    'Kuenzelsau',
    'Lautenbach',
    'Kippenheim',
    'Niederstotzingen',
    'Baltringen',
    'Rehburg-Loccum',
    'Stockse',
    'Neustadt am Rubenberge',
    'Husum',
    'Uchte',
    'Dornheim',
    'Premnitz',
    'Ingolstadt',
    'Dachau',
    'Pullhausen',
    'Sochtenau',
    'Rosenheim',
    'Radevormwald',
    'Boostedt',
    'Gross Kummerfeld',
    'Bonebuttel',
    'Ehndorf',
    'Grossenaspe',
    'Bruckberg',
    'Krugzell',
    'Oesterdeichstrich',
    'Kolpien',
    'Numbrecht',
    'Eppingen',
    'Oppenweiler',
    'Wald-Michelbach',
    'Trebur',
    'Tellingstedt',
    'Proseken',
    'Inzell',
    'Aichwald',
    'Hude',
    'Pleckhausen',
    'Velbert',
    'Pulheim',
    'Delitzsch',
    'Westerburg',
    'Hürth',
    'Solingen',
    'Elz',
    'Quedlinburg',
    'Frankfurt (Oder)',
    'Unterfoehring',
    'Fohren',
    'Ottobrunn',
    'Weil am Rhein',
    'Heimstetten',
    'Schacht-Audorf',
    'Bohmstedt',
    'Rumohr',
    'Probsteierhagen',
    'Schellhorn',
    'Laboe',
    'Lebrade',
    'Schonberg',
    'Flintbek',
    'Wisch',
    'Quarnbek',
    'Felde',
    'Schoenberg',
    'Drelsdorf',
    'Langwieden',
    'Sippersfeld',
    'Morschheim',
    'Fohren-Linden',
    'Altenglan',
    'Perl',
    'Kirchheimbolanden',
    'Schellweiler',
    'Losheim',
    'Saalstadt',
    'Hoppstadten-Weiersbach',
    'Hoheinod',
    'Schiffweiler',
    'Hefersweiler',
    'Battenberg',
    'Lotzbeuren',
    'Pfeffelbach',
    'Gollheim',
    'Hohenollen',
    'Nonnweiler',
    'Herschweiler-Pettersheim',
    'Weingarten',
    'Etschberg',
    'Ayl',
    'Wattenheim',
    'Klingenmunster',
    'Ulmet',
    'Kirchheim an der Weinstrasse',
    'Dillingen',
    'Weitersweiler',
    'Bolanden',
    'Bubenheim',
    'Molsheim',
    'Esselborn',
    'Brucken',
    'Bernstadt',
    'Dinkelsbühl',
    'Zipplingen',
    'Neresheim',
    'Rammingen',
    'Donaueschingen',
    'Villingendorf',
    'Bad Griesbach',
    'Lahr',
    'Achern',
    'Hambrucken',
    'Eggenstein-Leopoldshafen',
    'Dielheim',
    'Ilvesheim',
    'Guglingen',
    'Abstatt',
    'Grenzach-Wyhlen',
    'Auggen',
    'Wehr',
    'Deizisau',
    'Steinenbronn',
    'Aldingen',
    'Weissach',
    'Bisingen',
    'Vellberg',
    'Schechingen',
    'Eschach',
    'Gartringen',
    'Freudental',
    'Breisach',
    'Freiberg am Neckar',
    'Murrhardt',
    'Weinsberg',
    'Ehingen',
    'Weil im Schonbuch',
    'Offenau',
    'Ortenberg',
    'Hohberg',
    'Gemmrigheim',
    'Baiersbronn',
    'Jettingen-Scheppach',
    'Bosingen',
    'Bad Urach',
    'Dogern',
    'Hilzingen',
    'Oberkirch',
    'Schutterwald',
    'Thalheim',
    'Oberndorf',
    'Langenargen',
    'Weilheim',
    'Hirschberg',
    'Ebersbach an der Fils',
    'Beuren',
    'Deggingen',
    'Notzingen',
    'Neuffen',
    'Karlsdorf-Neuthard',
    'Otigheim',
    'Blaubeuren Abbey',
    'Schnurpflingen',
    'Stockach',
    'Amstetten',
    'Riedlingen',
    'Teublitz',
    'Straubing',
    'Weilmunster',
    'Wehrheim',
    'Hungen',
    'Floha',
    'Klaus',
    'Holzgerlingen',
    'Wurmberg',
    'Treffelhausen',
    'Dettingen an der Erms',
    'Müllheim',
    'Uhingen',
    'Ohmden',
    'Riedhausen',
    'Denkendorf',
    'Kreuzwertheim',
    'Mossingen',
    'Sindringen',
    'Bad Konig',
    'Heppenheim an der Bergstrasse',
    'Michelstadt',
    'Hochst im Odenwald',
    'Finkenbach',
    'Beerfelden',
    'Langerringen',
    'Unterhaching',
    'Holzheim',
    'Denklingen',
    'Landsberg am Lech',
    'Dillingen an der Donau',
    'Meitingen',
    'Langweid',
    'Schwifting',
    'Rednitzhembach',
    'Schrobenhausen',
    'Schwaig',
    'Seukendorf',
    'Hamminkeln',
    'Ahaus',
    'Heiden',
    'Bad Berka',
    'Ramerberg',
    'Seeon-Seebruck',
    'Pfaffing',
    'Prien am Chiemsee',
    'Artlenburg',
    'Amelinghausen',
    'Oldendorf',
    'Prebberede',
    'Michendorf',
    'Badow',
    'Linow',
    'Gorschendorf',
    'Tieplitz',
    'Fincken',
    'Stolzenhagen',
    'Protzen',
    'Siedenbrunzow',
    'Warin',
    'Neutrebbin',
    'Klotze',
    'Ahrensfelde',
    'Beelitz',
    'Guestrow',
    'Warrenzin',
    'Rovershagen',
    'Ankershagen',
    'Burg bei Magdeburg',
    'Schwaneberg',
    'Zerbst',
    'Steinhagen',
    'Kirchlengern',
    'Vohl',
    'Lugde',
    'Rollshausen',
    'Lengerich',
    'Niederaula',
    'Immenhausen',
    'Blomberg',
    'Grebenstein',
    'Emstal',
    'Rotenburg an der Fulda',
    'Meinerzhagen',
    'Attendorn',
    'Dorentrup',
    'Neuenstein',
    'Eslohe',
    'Warburg',
    'Horn-Bad Meinberg',
    'Medebach',
    'Dulmen',
    'Oelde',
    'Halle',
    'Werdohl',
    'Felsberg',
    'Siegen',
    'Alheim',
    'Beverungen',
    'Petershagen',
    'Kreuztal',
    'Bad Hersfeld',
    'Glandorf',
    'Bramsche',
    'Baunatal',
    'Stelle',
    'Brokdorf',
    'Sulfeld',
    'Bomlitz',
    'Osterrade',
    'Bad Oldesloe',
    'Bosau',
    'Sommerland',
    'Triptis',
    'Wundersleben',
    'Limbach',
    'Mannichswalde',
    'Fuchshain',
    'Wetterzeube',
    'Osterhausen',
    'Mechelroda',
    'Bornitz',
    'Schkolen',
    'Friedrichroda',
    'Meuselwitz',
    'Saara',
    'Sangerhausen',
    'Plaue',
    'Ilshofen',
    'Konigsfeld im Schwarzwald',
    'Bopfingen',
    'Otisheim',
    'Grevenbroich',
    'Radefeld',
    'Domsdorf',
    'Weischlitz',
    'Luebben',
    'Berka',
    'Hohenmolsen',
    'Kodersdorf',
    'Zeithain',
    'Holzhausen',
    'Hohen',
    'Nohra',
    'Hundhaupten',
    'Deiningen',
    'Siegenburg',
    'Mettenheim',
    'Mainburg',
    'Parsdorf',
    'Antdorf',
    'Puchheim',
    'Wolfgang',
    'Taufkirchen',
    'Petersdorf',
    'Neufarn',
    'Pottmes',
    'Weichering',
    'Saal',
    'Steinhoring',
    'Soyen',
    'Hagelstadt',
    'Westendorf',
    'Gersthofen',
    'Mitwitz',
    'Kirchberg',
    'Haselbach',
    'Oberschneiding',
    'Hadamar',
    'Wittlich',
    'Dietzenbach',
    'Sinn',
    'Plein',
    'Asslar',
    'Eschborn',
    'Holzhausen an der Haide',
    'Bad Soden-Salmunster',
    'Berg',
    'Idstein',
    'Dexheim',
    'Butzbach',
    'Koblenz',
    'Massbach',
    'Reiskirchen',
    'Montabaur',
    'Becheln',
    'Obermeilingen',
    'Diefflen',
    'Hundsangen',
    'Glashutten',
    'Neuwied',
    'Udenheim',
    'Waldbrunn',
    'Wolfersheim',
    'Hunfeld',
    'Hunzel',
    'Mandel',
    'Zeitlofs',
    'Mehlingen',
    'Grunberg',
    'Altenstadt',
    'Rodenbach',
    'Sterzhausen',
    'Bad Camberg',
    'Landsberg',
    'Pockau',
    'Ettersburg',
    'Zeitz',
    'Kelbra',
    'Riethnordhausen',
    'Schwerstedt',
    'Naumburg',
    'Oranienbaum',
    'Rechenberg-Bienenmuhle',
    'Berga',
    'Glauchau',
    'Waltershausen',
    'Arensdorf',
    'Waldau',
    'Wilhelmsdorf',
    'Isseroda',
    'Lugau',
    'Eberstadt',
    'Callenberg',
    'Fambach',
    'Erlau',
    'Freyburg',
    'Tanna',
    'Kleinschwabhausen',
    'Lederhose',
    'Unterwellenborn',
    'Grossposna',
    'Kirchhasel',
    'Schleid',
    'Neunheilingen',
    'Goch',
    'Elsdorf',
    'Blankenheim',
    'Kevelaer',
    'Dormagen',
    'Titz',
    'Dinslaken',
    'Bergheim',
    'Nettersheim',
    'Neunkirchen',
    'Heimbach',
    'Zulpich',
    'Wesel',
    'Konigswinter',
    'Gummersbach',
    'Kirchlinteln',
    'Bienenbuttel',
    'Schuby',
    'Blender',
    'Friedrichsholm',
    'Schwienau',
    'Anderlingen',
    'Gommern',
    'Kritzow',
    'Mieste',
    'Ringenwalde',
    'Boldebuck',
    'Gulzow',
    'Nauen',
    'Bastorf',
    'Rehfelde',
    'Rossau',
    'Bautzen',
    'Luckau',
    'Welzow',
    'Pinnow',
    'Grossschirma',
    'Kamenz',
    'Berggiesshubel',
    'Kesselsdorf',
    'Merschwitz',
    'Hoyerswerda',
    'Malschwitz',
    'Pfaffroda',
    'Kurort Hartha',
    'Anzing',
    'Ottenhofen',
    'Irschenberg',
    'Riegsee',
    'Selb',
    'Bolsterlang',
    'Rottenburg an der Laaber',
    'Cham',
    'Wessobrunn Abbey',
    'Moschenfeld',
    'Dietmannsried',
    'Dollern',
    'Berne',
    'Breuna',
    'Eppstein',
    'Girod',
    'Schwalbach',
    'Chiemsee',
    'Pattensen',
    'Gaishofen',
    'Sarstedt',
    'Bad Salzungen',
    'Gerstetten',
    'Laichingen',
    'Essingen',
    'Bobingen',
    'Machtolsheim',
    'Abtsgmuend',
    'Hauzenberg',
    'Wegscheid',
    'Hunxe',
    'Greven',
    'Borken',
    'Linz am Rhein',
    'Hattersheim',
    'Poppenhausen',
    'Eiterfeld',
    'Ranstadt',
    'Kleinkahl',
    'Happurg',
    'Hannover',
    'Streich',
    'Schutzenhof',
    'Markt',
    'Alexander',
    'Breckerfeld',
    'Dolgesheim',
    'Reinhartshausen',
    'Monschau',
    'Simmerath',
    'Hartenstein',
    'Massenheim',
    'Herzfeld',
    'Volkmarsen',
    'Kamp-Lintfort',
    'Mohnesee',
    'Witten',
    'Georgensgmund',
    'Westhausen',
    'Weiltingen',
    'Sontheim an der Brenz',
    'Nördlingen',
    'Huttlingen',
    'Tannhausen',
    'Steinweiler',
    'Nachrodt-Wiblingwerde',
    'Herscheid',
    'Grenzau',
    'Dobler',
    'Mulheim-Karlich',
    'Karlstein',
    'Gehrden',
    'Wurselen',
    'Detmold',
    'Altena',
    'Schmolln',
    'Kanzlei',
    'Erika',
    'Ergersheim',
    'Ebermannstadt',
    'Kranenburg',
    'Freund',
    'Hohenhaus',
    'Hirschau',
    'Bruckmuhl',
    'Worfelden',
    'Gronau',
    'Heiligenroth',
    'Roding',
    'Wohlsborn',
    'Butzow',
    'Neu Sanitz',
    'Gremersdorf',
    'Neubukow',
    'Stabelow',
    'Glasewitz',
    'Dettmannsdorf',
    'Wattmannshagen',
    'Tutow',
    'Splietsdorf',
    'Gravenwiesbach',
    'Usingen',
    'Konigstein im Taunus',
    'Urspringen',
    'Eichenbuhl',
    'Konigsberg in Bayern',
    'Theilheim',
    'Schwanfeld',
    'Wurzburg',
    'Knetzgau',
    'Wiesentheid',
    'Dombuhl',
    'Munchaurach',
    'Neunkirchen',
    'Weisendorf',
    'Bubenreuth',
    'Telgte',
    'Limburg an der Lahn',
    'Marbach am Neckar',
    'Kitzingen',
    'Gettorf',
    'Wesselburen',
    'Gross Kreutz',
    'Ochsenhausen',
    'Bad Wurzach',
    'Bissendorf',
    'Berg',
    'Werdau',
    'Berching',
    'Anklam',
    'Much',
    'Hauser',
    'Huglfing',
    'Penzing',
    'Garmisch-Partenkirchen',
    'Rohrmoos',
    'Forstern',
    'Bad Aibling',
    'Krailling',
    'Hohenkammer',
    'Feldkirchen-Westerham',
    'Hohenpeissenberg',
    'Klais',
    'Supplingen',
    'Clausthal-Zellerfeld',
    'Altenau',
    'Sassenburg',
    'Korntal',
    'Offenbach an der Queich',
    'Rheurdt',
    'Moers',
    'Hasloch',
    'Friedberg',
    'Buttenwiesen',
    'Munster',
    'Marxheim',
    'Ziemetshausen',
    'Althegnenberg',
    'Wessiszell',
    'Schmiechen',
    'Langenneufnach',
    'Nordendorf',
    'Leipheim',
    'Fahrenzhausen',
    'Apfeldorf',
    'Peiting',
    'Gaimersheim',
    'Loiching',
    'Rhinow',
    'Wiesenburg',
    'Verl',
    'Wipperfürth',
    'Geiselbach',
    'Ingelheim am Rhein',
    'Barsinghausen',
    'Oestrich-Winkel',
    'Seebad Bansin',
    'Bayerisch Gmain',
    'Ismaning',
    'Garbsen',
    'Stellshagen',
    'Usedom',
    'Wilhelmsthal',
    'Dornstetten',
    'Dieburg',
    'Mombris',
    'Forsthaus',
    'Albertshausen',
    'Osloss',
    'Tappenbeck',
    'Raubling',
    'Waldfeucht',
    'Dockingen',
    'Donauwörth',
    'Monheim',
    'Niederschonenfeld',
    'Ellzee',
    'Aletshausen',
    'Ebershausen',
    'Kettershausen',
    'Oberndorf',
    'Grassau',
    'Maximilian',
    'Bonen',
    'Aschheim',
    'Margertshausen',
    'Memmingen',
    'Beiersdorf',
    'Bingen',
    'Hausen ob Verena',
    'Messkirch',
    'Immendingen',
    'Bondorf',
    'Muhlheim an der Donau',
    'Schwenningen',
    'Bietingen',
    'Leibertingen',
    'Veringenstadt',
    'Emmingen-Liptingen',
    'Grunheide',
    'Jacobsdorf',
    'Hennickendorf',
    'Rauen',
    'Angermunde',
    'Pfedelbach',
    'Kupferzell',
    'Schnelldorf',
    'Untereisesheim',
    'Igersheim',
    'Grosserlach',
    'Wustenrot',
    'Erlenbach',
    'Sulzbach',
    'Katzenstein',
    'Wiesau',
    'Ostseebad Boltenhagen',
    'Eden',
    'Oschersleben',
    'Timmendorf',
    'Lage',
    'Oberboihingen',
    'Lennestadt',
    'Niederzier',
    'Bemerode',
    'Gerbrunn',
    'Mainaschaff',
    'Randersacker',
    'Kleinwallstadt',
    'Zell am Main',
    'Thungersheim',
    'Weibersbrunn',
    'Schollkrippen',
    'Krombach',
    'Haibach',
    'Kohren-Sahlis',
    'Rheine',
    'Frohburg',
    'Thallwitz',
    'Voerde',
    'Raesfeld',
    'Sonsbeck',
    'Schermbeck',
    'Weeze',
    'Rees',
    'Affing',
    'Obergriesbach',
    'Thierhaupten',
    'Wertingen',
    'Finningen',
    'Bissingen',
    'Rain',
    'Mering',
    'Mertingen',
    'Zoschingen',
    'Untermeitingen',
    'Welden',
    'Hainsfarth',
    'Bad Laasphe',
    'Freudenberg',
    'Bad Berleburg',
    'Bergneustadt',
    'Wenden',
    'Schnega',
    'Bodenteich',
    'Ruhen',
    'Warberg',
    'Zernien',
    'Neu Darchau',
    'Meinersen',
    'Vechelde',
    'Kissenbruck',
    'Neetze',
    'Wagenhoff',
    'Bottrop',
    'Neukirchen-Vluyn',
    'Weener',
    'Spetzerfehn',
    'Leezdorf',
    'Detern',
    'Borkum',
    'Leer',
    'Holtland',
    'Firrel',
    'Aschersleben',
    'Jessnitz',
    'Lutzen',
    'Nauendorf',
    'Mansfeld',
    'Abberode',
    'Petersberg',
    'Jessen',
    'Weissenfels',
    'Bad Lauchstadt',
    'Raguhn',
    'Schkopau',
    'Köthen',
    'Wengelsdorf',
    'Bad Bibra',
    'Sandersleben',
    'Wolfen',
    'Allstedt',
    'Grossrinderfeld',
    'Schwegenheim',
    'Billigheim-Ingenheim',
    'Hemsbach',
    'Albersweiler',
    'Steinweiler',
    'Buchen',
    'Kuhardt',
    'Gerolsheim',
    'Sulzfeld',
    'Oberviechtach',
    'Schwarzenbach an der Saale',
    'Tannesberg',
    'Dohlau',
    'Helmbrechts',
    'Fichtelberg',
    'Mehlmeisel',
    'Hohenberg',
    'Thierstein',
    'Marsberg',
    'Anrochte',
    'Schotten',
    'Bad Harzburg',
    'Liebenburg',
    'Steinlah',
    'Ebersberg',
    'Vaterstetten',
    'Garching bei Munchen',
    'Schondorf am Ammersee',
    'Grasbrunn',
    'Feldkirchen',
    'Brunnthal',
    'Oberschleissheim',
    'Giessen',
    'Lich',
    'Hessen',
    'Seeheim-Jugenheim',
    'Pfungstadt',
    'Erzhausen',
    'Griesheim',
    'Bendorf',
    'Lahnstein',
    'Hohr-Grenzhausen',
    'Emmelshausen',
    'Heuchelheim',
    'Lollar',
    'Bad Nauheim',
    'Staufenberg',
    'Marktheidenfeld',
    'Bad Neustadt an der Saale',
    'Niederwerrn',
    'Sennfeld',
    'Zellingen',
    'Hochberg',
    'Bad Kissingen',
    'Fuchsstadt',
    'Leinach',
    'Geldersheim',
    'Schwalbach am Taunus',
    'Kronberg',
    'Raunheim',
    'Ostheim',
    'Vallendar',
    'Albig',
    'Bruchkobel',
    'Veitshochheim',
    'Neu-Anspach',
    'Grossostheim',
    'Nidderau',
    'Sulzbach am Main',
    'Kriftel',
    'Erlensee',
    'Momlingen',
    'Gross-Umstadt',
    'Lorsch',
    'Ober-Ramstadt',
    'Grosswallstadt',
    'Reinheim',
    'Morlenbach',
    'Gross-Bieberau',
    'Rodgau',
    'Bensheim',
    'Zwingenberg',
    'Gross-Gerau',
    'Richen',
    'Sulzbach',
    'Lautersheim',
    'Staudt',
    'Schoneck',
    'Niederzeuzheim',
    'Andernach',
    'Langenselbold',
    'Buhlertal',
    'Rauenberg',
    'Gaggenau',
    'Ostringen',
    'Ubstadt-Weiher',
    'Eningen unter Achalm',
    'Neckartailfingen',
    'Dagersheim',
    'Meckenbeuren',
    'Gottmadingen',
    'Cleebronn',
    'Lauffen am Neckar',
    'Schonborn',
    'Gundelfingen',
    'Waldenbuch',
    'Odenheim',
    'Sinzheim',
    'Bretten',
    'Muhlhausen',
    'Bischweier',
    'Rechberghausen',
    'Ispringen',
    'Waldkirch',
    'Irsee',
    'Schwabmunchen',
    'Traunreut',
    'Traunstein',
    'Sulzberg',
    'Sonthofen',
    'Durach',
    'Oberstdorf',
    'Prutting',
    'Germering',
    'Eching',
    'Karlsfeld',
    'Toging am Inn',
    'Bergkirchen',
    'Grafenau',
    'Aichach',
    'Bad Worishofen',
    'Immenstadt im Allgau',
    'Gessertshausen',
    'Marktoberdorf',
    'Zorneding',
    'Bad Toelz',
    'Schechen',
    'Ergolding',
    'Aresing',
    'Kirchreit',
    'Kissing',
    'Arnstadt',
    'Suhl',
    'Weimar',
    'Eisenach',
    'Schmalkalden',
    'Oschatz',
    'Markranstadt',
    'Markkleeberg',
    'Taucha',
    'Wurzen',
    'Crimmitschau',
    'Wiedemar',
    'Schkeuditz',
    'Meerane',
    'Wilkau-Hasslau',
    'Radeberg',
    'Meissen',
    'Schneeberg',
    'Bad Muskau',
    'Glücksburg',
    'Eutin',
    'Falkendorf',
    'Busdorf',
    'Ammerndorf',
    'Rothenbach an der Pegnitz',
    'Kulmbach',
    'Mohrendorf',
    'Bischberg',
    'Veitsbronn',
    'Heisede',
    'Ronnenberg',
    'Nordstemmen',
    'Vieselbach',
    'Nordhausen',
    'Gotha',
    'Greiz',
    'Possneck',
    'Bucha',
    'Soemmerda',
    'Bad Langensalza',
    'Sonneberg',
    'Mühlhausen',
    'Leinefelde',
    'Mettingen',
    'Quakenbruck',
    'Harsewinkel',
    'Korbach',
    'Buckeburg',
    'Rheda-Wiedenbruck',
    'Ochtrup',
    'Nordhorn',
    'Lotte',
    'Altenbeken',
    'Delbruck',
    'Bad Lippspringe',
    'Höxter',
    'Bad Salzuflen',
    'Rietberg',
    'Oerlinghausen',
    'Versmold',
    'Rahden',
    'Calden',
    'Hannoversch Münden',
    'Hofgeismar',
    'Ibbenbueren',
    'Lippstadt',
    'Borgholzhausen',
    'Neuenkirchen',
    'Hovelhof',
    'Bad Driburg',
    'Duderstadt',
    'Lingen',
    'Salzkotten',
    'Bovenden',
    'Nettetal',
    'Wilnsdorf',
    'Pesch',
    'Kalkar',
    'Mudersbach',
    'Scheuerfeld',
    'Olpe',
    'Betzdorf',
    'Tonisvorst',
    'Gevelsberg',
    'Heiligenhaus',
    'Wuelfrath',
    'Wetter (Ruhr)',
    'Emmerich',
    'Styrum',
    'Oer-Erkenschwick',
    'Eitorf',
    'Rheinberg',
    'Schwalmtal',
    'Wildeshausen',
    'Oyten',
    'Ritterhude',
    'Siegburg',
    'Overath',
    'Bad Honnef',
    'Rommerskirchen',
    'Ubach-Palenberg',
    'Wegberg',
    'Baesweiler',
    'Bornheim',
    'Linnich',
    'Hirzlei',
    'Neunkirchen',
    'Ottweiler',
    'Trier',
    'Gillenfeld',
    'Wasserliesch',
    'Konz',
    'Leeheim',
    'Rossdorf',
    'Eppertshausen',
    'Schaafheim',
    'Birkenwerder',
    'Fredersdorf',
    'Eberswalde',
    'Hennigsdorf',
    'Hohen Neuendorf',
    'Eichwalde',
    'Schwedt (Oder)',
    'Finowfurt',
    'Buckow',
    'Ludwigsfelde',
    'Waldsieversdorf',
    'Rathenow',
    'Halberstadt',
    'Obernburg am Main',
    'Gross-Zimmern',
    'Burgstadt',
    'Grunwald',
    'Bad Neuenahr-Ahrweiler',
    'Hennef',
    'Nassau',
    'Remagen',
    'Grossheubach',
    'Wunstorf',
    'Hagenburg',
    'Alfeld',
    'Lindhorst',
    'Ilsede',
    'Luhden',
    'Obernkirchen',
    'Heuerssen',
    'Bad Salzdetfurth',
    'Seelze',
    'Seesen',
    'Salzgitter-Bad',
    'Sandkrug',
    'Burgdorf, Hanover',
    'Wallenhorst',
    'Hasbergen',
    'Norten-Hardenberg',
    'Bad Lauterberg im Harz',
    'Diekholzen',
    'Bad Munder am Deister',
    'Walkenried',
    'Bueddenstedt',
    'Grunenplan',
    'Visbek',
    'Lengede',
    'Vorden',
    'Bad Grund',
    'Burgwedel',
    'Bad Nenndorf',
    'Hemmingen',
    'Lehre',
    'Schoningen',
    'Loheide',
    'Walle',
    'Osterode am Harz',
    'Dinklage',
    'Wardenburg',
    'Weyhe',
    'Bodenwerder',
    'Holzminden',
    'Stadtoldendorf',
    'Steinfeld',
    'Minsen',
    'Klausheide',
    'Wietmarschen',
    'Lemwerder',
    'Nudlingen',
    'Ostrhauderfehn',
    'Dissen',
    'Rattelsdorf',
    'Neubiberg',
    'Assling',
    'Obertrubach',
    'Schnabelwaid',
    'Putzbrunn',
    'Gmund am Tegernsee',
    'Isen',
    'Wittislingen',
    'Aindling',
    'Langenmosen',
    'Konigsdorf',
    'Eckersdorf',
    'Glonn',
    'Schonbrunn',
    'Hassmersheim',
    'Winden',
    'Neufra',
    'Erlabrunn',
    'Himmelstadt',
    'Guntersleben',
    'Leidersbach',
    'Barth',
    'Poppenlauer',
    'Munnerstadt',
    'Bitterfeld-Wolfen',
    'Friedrichsthal',
    'Stassfurt',
    'Malsch',
    'Dettighofen',
    'Stendal',
    'Besitz',
    'Werther',
    'Dettelbach',
    'Untereuerheim',
    'Werneck',
    'Atteln',
    'Halle',
    'Billerbeck',
    'Stadtlohn',
    'Steinfurt',
    'Coesfeld',
    'Issum',
    'Vreden',
    'Fritzlar',
    'Hessisch Lichtenau',
    'Morshausen',
    'Porta Westfalica',
    'Havixbeck',
    'Westerkappeln',
    'Menslage',
    'Emsdetten',
    'Gescher',
    'Rhede',
    'Eltville',
    'Saint Egidien',
    'Dohna',
    'Forst',
    'Gornsdorf',
    'Eppendorf',
    'Hohenstein-Ernstthal',
    'Grossenhain',
    'Eilenburg',
    'Oberhermsdorf',
    'Bernsdorf',
    'Stolpen',
    'Winkwitz',
    'Meckenheim',
    'Niederkassel',
    'Weilerswist',
    'Burscheid',
    'Sinzig',
    'Bruggen',
    'Langerwehe',
    'Xanten',
    'Luchem',
    'Haan',
    'Barssel',
    'Samern',
    'Niedernhausen',
    'Sondershausen',
    'Mylau',
    'Reichenbach/Vogtland',
    'Puttlingen',
    'Eppelborn',
    'Wittenberg',
    'Merseburg',
    'Leuna',
    'Zeuthen',
    'Mahlow',
    'Bergfelde',
    'Thyrow',
    'Leisnig',
    'Schonebeck',
    'Gnadau',
    'Blankenburg',
    'Wittstock',
    'Kremmen',
    'Furstenwald',
    'Wusterhausen',
    'Flothe',
    'Sehnde',
    'Borgsdorf',
    'Schulzendorf',
    'Horst',
    'Krauschwitz',
    'Burkau',
    'Wachau',
    'Oberlungwitz',
    'Lobau',
    'Cannewitz',
    'Lichtentanne',
    'Uttenreuth',
    'Kosching',
    'Rohrenfels',
    'Neunburg vorm Wald',
    'Langensendelbach',
    'Kronach',
    'Mainleus',
    'Johanngeorgenstadt',
    'Oberwiesenthal',
    'Grunhainichen',
    'Aue',
    'Stollberg',
    'Bach',
    'Wallerfangen',
    'Kaisersesch',
    'Eichenzell',
    'Ehrenfeld',
    'Zollstock',
    'Odenthal',
    'Kendenich',
    'Wachtberg',
    'Merzenich',
    'Ensen',
    'Bayenthal',
    'Wiehl',
    'Mogglingen',
    'Untermunkheim',
    'Magstadt',
    'Birenbach',
    'Pleidelsheim',
    'Kirchberg',
    'Hessigheim',
    'Besigheim',
    'Rust',
    'Zell am Harmersbach',
    'Eberbach',
    'Neulussheim',
    'Kronau',
    'Malsch',
    'Dietingen',
    'Trossingen',
    'Vogtsburg',
    'Trochtelfingen',
    'Meersburg',
    'Orsingen-Nenzingen',
    'Allensbach',
    'Deidesheim',
    'Edingen-Neckarhausen',
    'Herbrechtingen',
    'Sachsenheim',
    'Affalterbach',
    'Neubrunn',
    'Schramberg',
    'Durnau',
    'Hirschhorn',
    'Waldshut-Tiengen',
    'Schworstadt',
    'Botzingen',
    'Oberteuringen',
    'Hopfingen',
    'Mulfingen',
    'Riederich',
    'Grosselfingen',
    'Schonaich',
    'Waschenbeuren',
    'Aichelberg',
    'Titisee-Neustadt',
    'Beimerstetten',
    'Westerstetten',
    'Maselheim',
    'Schwendi',
    'Dellmensingen',
    'Illerrieden',
    'Buch',
    'Moglingen',
    'Birkenlohe',
    'Oberkochen',
    'Forchtenberg',
    'Markgroningen',
    'Erligheim',
    'Pfullingen',
    'Neckartenzlingen',
    'Ofterdingen',
    'Hulben',
    'Munsingen',
    'Niedernhall',
    'Straelen',
    'Herzebrock',
    'Ludinghausen',
    'Sendenhorst',
    'Grefrath',
    'Beesten',
    'Huckelhoven',
    'Bilk',
    'Altenberge',
    'Schlangen',
    'Ahrensbok',
    'Nottuln',
    'Herborn',
    'Vlotho',
    'Schwelm',
    'Schonberg',
    'Wendelstein',
    'Lain',
    'Gemmingen',
    'Gau-Bischofsheim',
    'Nierstein',
    'Waibstadt',
    'Grosskrotzenburg',
    'Buchen',
    'Ebhausen',
    'Liederbach',
    'Waldaschaff',
    'Blankenfelde',
    'Neu Isenburg',
    'Hermsdorf',
    'Zella-Mehlis',
    'Hoppegarten',
    'Kahla',
    'Reilingen',
    'Reinfeld',
    'Hemdingen',
    'Bentwisch',
    'Klein Lichtenhagen',
    'Zingst',
    'Schenefeld',
    'Hittbergen',
    'Doberlug-Kirchhain',
    'Hohenwarthe',
    'Lietzen',
    'Burg Stargard',
    'Treuenbrietzen',
    'Wolmirstedt',
    'Linden',
    'Weiterstadt',
    'Holte',
    'Green',
    'Gendorf',
    'Winnen',
    'Wenzenbach',
    'Geiselhoring',
    'Bad Abbach',
    'Pentling',
    'Pfatter',
    'Bruck in der Oberpfalz',
    'Regenstauf',
    'Neutraubling',
    'Sunching',
    'Stamsried',
    'Wiesenfelden',
    'Wackersdorf',
    'Falkenstein',
    'Zwiesel',
    'Schwandorf in Bayern',
    'Donaustauf',
    'Schrozberg',
    'Eggesin',
    'Altentreptow',
    'Prenzlau',
    'Friedland',
    'Rangsdorf',
    'Fahrland',
    'Pritzwalk',
    'Freckenhorst',
    'Ascheberg',
    'Salzbergen',
    'Worthsee',
    'Strasslach-Dingharting',
    'Raisting',
    'Windach',
    'Diessen am Ammersee',
    'Juterbog',
    'Kleinmachnow',
    'Baruth',
    'Gittelde',
    'Hehlen',
    'Badenhausen',
    'Grossalmerode',
    'Fuldatal',
    'Melsungen',
    'Guxhagen',
    'Klosterhauser',
    'Belzig',
    'Ziesar',
    'Biesenthal',
    'Zechlin Dorf',
    'Leegebruch',
    'Schildow',
    'Glienicke',
    'Fehrbellin',
    'Alt Ruppin',
    'Kaisersbach',
    'Rudersberg',
    'Feldberg',
    'Wredenhagen',
    'Viecheln',
    'Ribnitz-Damgarten',
    'Malchin',
    'Teterow',
    'Leizen',
    'Gnoien',
    'Brussow',
    'Leopoldshagen',
    'Ribnitz',
    'Woldegk',
    'Freden',
    'Eime',
    'Everode',
    'Warmsen',
    'Kemberg',
    'Zahna',
    'Krauschwitz',
    'Coswig',
    'Labejum',
    'Ockenfels',
    'Ransbach-Baumbach',
    'Scharnhorst',
    'Puderbach',
    'Burstadt',
    'Herxheim',
    'Lochau',
    'Erdeborn',
    'Hoya',
    'Wachtendonk',
    'Velen',
    'Sudlohn',
    'Dirmingen',
    'Urweiler',
    'Kleinblittersdorf',
    'Reimsbach',
    'Beckingen',
    'Mettlach',
    'Wadgassen',
    'Merchweiler',
    'Sulzbach',
    'Lebach',
    'Bexbach',
    'Hohenfurch',
    'Rottach-Egern',
    'Gerzen',
    'Willebadessen',
    'Buren',
    'Schloss Holte-Stukenbrock',
    'Steinheim',
    'Brakel',
    'Peckelsheim',
    'Barntrup',
    'Oldendorf',
    'Bad Essen',
    'Hille',
    'Pullach im Isartal',
    'Lingerhahn',
    'Morsfeld',
    'Klein-Winternheim',
    'Bechtolsheim',
    'Eich',
    'Seifhennersdorf',
    'Stukenbrock',
    'Oberderdingen',
    'Monsheim',
    'Illingen',
    'Maulbronn',
    'Wernau',
    'Hankensbuttel',
    'Langeln',
    'Dedelstorf',
    'Lautenthal',
    'Vordorf',
    'Parsau',
    'Winnigstedt',
    'Schladen',
    'Remlingen',
    'Naunheim',
    'Altenkirchen',
    'Brensbach',
    'Messel',
    'Gerau',
    'Gernsheim',
    'Schoppenstedt',
    'Woltersdorf',
    'Evessen',
    'Brome',
    'Destedt',
    'Wittingen',
    'Knesebeck',
    'Cremlingen',
    'Hofbieber',
    'Schwalmstadt',
    'Grebenhain',
    'Schluchtern',
    'Herbstein',
    'Neuhof',
    'Steinau an der Strasse',
    'Oberaula',
    'Alsfeld',
    'Birstein',
    'Bermuthshain',
    'Metzlos-Gehag',
    'Gehaus',
    'Frielendorf',
    'Bad Salzschlirf',
    'Kunzell',
    'Odendorf',
    'Neunkirchen',
    'Bad Munstereifel',
    'Villmar',
    'Gladenbach',
    'Bad Endbach',
    'Niederweidbach',
    'Breidenbach',
    'Niederfischbach',
    'Berod bei Wallmerod',
    'Schoeffengrund',
    'Ewersbach',
    'Kirschhofen',
    'Katzenelnbogen',
    'Rettert',
    'Treffelstein',
    'Stammbach',
    'Munchberg',
    'Nagel',
    'Weissdorf',
    'Erbendorf',
    'Kirchenlamitz',
    'Waldershof',
    'Gefrees',
    'Neustadt an der Aisch',
    'Konigstein',
    'Thurnau',
    'Schonwald',
    'Kastl',
    'Konradsreuth',
    'Hollfeld',
    'Neustadt an der Waldnaab',
    'Weiherhammer',
    'Plossberg',
    'Witzenhausen',
    'Gieboldehausen',
    'Braunlage',
    'Gleichen',
    'Hattorf',
    'Luerdissen',
    'Eschershausen',
    'Moringen',
    'Hardegsen',
    'Ulbersdorf',
    'Moritzburg',
    'Spechtshausen',
    'Neustadt in Sachsen',
    'Schwarmstedt',
    'Steimbke',
    'Oldendorf',
    'Elze',
    'Essel',
    'Hessisch Oldendorf',
    'Horgau',
    'Ichenhausen',
    'Rehling',
    'Fischach',
    'Mottingen',
    'Welden',
    'Klosterlechfeld',
    'Hollenbach',
    'Offingen',
    'Gablingen',
    'Lauingen',
    'Inchenhofen',
    'Nienhagen',
    'Eicklingen',
    'Rodenberg',
    'Coppengrave',
    'Wathlingen',
    'Hohnhorst',
    'Delligsen',
    'Giesen',
    'Rheden',
    'Nienhagen',
    'Marienhagen',
    'Schoneberg',
    'Seelow',
    'Lieberose',
    'Vorland',
    'Alt Buchhorst',
    'Barendorf',
    'Radbruch',
    'Wendhausen',
    'Kolln-Reisiek',
    'Aschendorf',
    'Heede',
    'Marienhafe',
    'Juist',
    'Baltrum',
    'Alttechau',
    'Hohenaspe',
    'Klein Ammensleben',
    'Ilsenburg',
    'Wanzleben',
    'Heringsdorf',
    'Hemmingstedt',
    'Hohenschaftlarn',
    'Manching',
    'Geisenfeld',
    'Wasserburg am Inn',
    'Wessling',
    'Egling',
    'Eurasburg',
    'Pforring',
    'Wilsum',
    'Niederlangen',
    'Sogel',
    'Twist',
    'Engden',
    'Bad Bentheim',
    'Getelo',
    'Solms',
    'Gemunden',
    'Kirchhain',
    'Friedensdorf',
    'Lohnberg',
    'Dreikirchen',
    'Braunfels',
    'Gemunden an der Wohra',
    'Wetter',
    'Nordwalde',
    'Leer',
    'Spelle',
    'Beelen',
    'Sassenberg',
    'Horstmar',
    'Metelen',
    'Geltendorf',
    'Reichertshausen',
    'Greifenberg',
    'Vierkirchen',
    'Walleshausen',
    'Welshofen',
    'Ostbevern',
    'Tecklenburg',
    'Rhade',
    'Beverstedt',
    'Wietzen',
    'Kirchseelte',
    'Rhadereistedt',
    'Horstedt',
    'Scharrel',
    'Ovelgoenne',
    'Carolinensiel',
    'Ovelgonne',
    'Bakum',
    'Dotlingen',
    'Ramsloh',
    'Kirn',
    'Furtwangen im Schwarzwald',
    'Hufingen',
    'Niedereschach',
    'Gaienhofen',
    'Uhldingen-Muhlhofen',
    'Triberg im Schwarzwald',
    'Dahlewitz',
    'Putlitz',
    'Bad Boll',
    'Nattheim',
    'Sievershutten',
    'Seedorf',
    'Hilter',
    'Alfhausen',
    'Neuenkirchen',
    'Bersenbruck',
    'Gehrde',
    'Trittau',
    'Gronwohld',
    'Todesfelde',
    'Todendorf',
    'Wunnenberg',
    'Lichtenau',
    'Weiberg',
    'Etteln',
    'Kruden',
    'Haldensleben I',
    'Hecklingen',
    'Lostau',
    'Biere',
    'Atzendorf',
    'Mahndorf',
    'Igel',
    'Neroth',
    'Urzig',
    'Mastershausen',
    'Sensweiler',
    'Enkirch',
    'Tawern',
    'Herrstein',
    'Idar-Oberstein',
    'Geisfeld',
    'Morschied',
    'Pellingen',
    'Bausendorf',
    'Thomm',
    'Newel',
    'Horbruch',
    'Ralingen',
    'Sonnschied',
    'Pelm',
    'Kenn',
    'Olzheim',
    'Serrig',
    'Steineberg',
    'Pluwig',
    'Feusdorf',
    'Lautzenhausen',
    'Helfant',
    'Iffezheim',
    'Hugelsheim',
    'Gengenbach',
    'Neuried',
    'Lichtenau',
    'Ringsheim',
    'Ottenhofen',
    'Kappelrodeck',
    'Forbach',
    'Meissenheim',
    'Lauf',
    'Dasing',
    'Affaltern',
    'Ahlhorn',
    'Pfaffenhausen',
    'Weiler-Simmerberg',
    'Wertach',
    'Oberrieden',
    'Markt Rettenbach',
    'Buchenberg',
    'Eppishausen',
    'Eggenthal',
    'Waal',
    'Zaisertshofen',
    'Heimenkirch',
    'Waltenhofen',
    'Ruckholz',
    'Sigmarszell',
    'Turkheim',
    'Ascha',
    'Parnsberg',
    'Bodenmais',
    'Kelheim',
    'Strasskirchen',
    'Wolfsegg',
    'Hemau',
    'Mariaposching',
    'Thalmassing',
    'Eichendorf',
    'Pemfling',
    'Bischofsmais',
    'Beratzhausen',
    'Breitenbrunn',
    'Rastenberg',
    'Worbis',
    'Weilar',
    'Blankenhain',
    'Brotterode',
    'Rausdorf',
    'Oechsen',
    'Singenbach',
    'Dorfen',
    'Amerang',
    'Gerolsbach',
    'Ernsgaden',
    'Lenggries',
    'Vohburg an der Donau',
    'Brunsbuttel',
    'Burg-Grambke',
    'Olderup',
    'Pansdorf',
    'Rantzau',
    'Gersheim',
    'Namborn',
    'Weiskirchen',
    'Blieskastel',
    'Marpingen',
    'Kollerbach',
    'Freisen',
    'Siesbach',
    'Rodach',
    'Baunach',
    'Dentlein am Forst',
    'Rothenburg',
    'Uffenheim',
    'Langenzenn',
    'Weismain',
    'Wirsberg',
    'Kasendorf',
    'Vilseck',
    'Topen',
    'Kodnitz',
    'Stockheim',
    'Grafenwohr',
    'Nordhalben',
    'Tirschenreuth',
    'Gossweinstein',
    'Goldkronach',
    'Obernzell',
    'Unterdietfurt',
    'Dingolfing',
    'Untergriesbach',
    'Furstenzell',
    'Spiegelau',
    'Tittling',
    'Stubenberg',
    'Ruderting',
    'Tann',
    'Vilshofen',
    'Fursteneck',
    'Hutthurm',
    'Perlesreut',
    'Ergoldsbach',
    'Frontenhausen',
    'Lindlar',
    'Engelskirchen',
    'Pilsting',
    'Landau an der Isar',
    'Obertraubling',
    'Schnaittenbach',
    'Brunn',
    'Seubersdorf',
    'Maxhutte-Haidhof',
    'Nittendorf',
    'Ringsberg',
    'Teising',
    'Muehldorf',
    'Bischofswiesen',
    'Winhoring',
    'Berchtesgaden',
    'Pleiskirchen',
    'Tittmoning',
    'Kleinrinderfeld',
    'Bastheim',
    'Rottendorf',
    'Gerolzhofen',
    'Rollbach',
    'Schonau',
    'Rothenfels',
    'Karlstadt am Main',
    'Kleinheubach',
    'Zell',
    'Rothhausen',
    'Lutzelbach',
    'Wunsiedel',
    'Trabitz',
    'Hochstadt an der Aisch',
    'Auerbach',
    'Freihung',
    'Wernberg-Koblitz',
    'Stornstein',
    'Pullenreuth',
    'Kirchenthumbach',
    'Rehau',
    'Eilenburg, Berg',
    'Pleystein',
    'Mantel',
    'Mitterteich',
    'Pottenstein',
    'Eslarn',
    'Windischeschenbach',
    'Timmenrode',
    'Budenheim',
    'Gensingen',
    'Schlangenbad',
    'Hausen uber Aar',
    'Langenlonsheim',
    'Heidesheim',
    'Grolsheim',
    'Oppenheim',
    'Ober-Hilbersheim',
    'Ellgau',
    'Thannhausen',
    'Altomunster',
    'Wemding',
    'Dinkelscherben',
    'Eichstaett',
    'Muhr am See',
    'Freystadt',
    'Hirschaid',
    'Oberreichenbach',
    'Oberreichenbach',
    'Waldbockelheim',
    'Merenberg',
    'Schwabenheim',
    'Nastatten',
    'Offstein',
    'Boppard',
    'Hergenfeld',
    'Auen',
    'Gau-Algesheim',
    'Thalheim',
    'Schlema',
    'Eibenstock',
    'Burgstadt',
    'Ehrenfriedersdorf',
    'Zwonitz',
    'Rochlitz',
    'Lengenfeld',
    'Hilbersdorf',
    'Drebach',
    'Crottendorf',
    'Schoenheide',
    'Augustusburg',
    'Stutzengrun',
    'Filsen',
    'Oberhausen',
    'Meckenbach',
    'Kaub',
    'Hahnheim',
    'Kirchzell',
    'Worth',
    'Worth am Rhein',
    'Grettstadt',
    'Obernbreit',
    'Goldbach',
    'Kirchlauter',
    'Elsenfeld',
    'Hammelburg',
    'Miltenberg',
    'Pfarrweisach',
    'Eibelstadt',
    'Hassfurt',
    'Klingenberg am Main',
    'Ettleben',
    'Lohr a. Main',
    'Erlenbach am Main',
    'Wachtersbach',
    'Budingen',
    'Gedern',
    'Ober-Morlen',
    'Bad Orb',
    'Westergellersen',
    'Moisburg',
    'Cadenberge',
    'Wingst',
    'Burweg',
    'Kirchgellersen',
    'Kollmar',
    'Untereglfing',
    'Eichenau',
    'Bruchweiler',
    'Zemmer',
    'Zeltingen-Rachtig',
    'Baumholder',
    'Hermeskeil',
    'Monzelfeld',
    'Nordrach',
    'Aldenhoven',
    'Hellenthal',
    'Nideggen',
    'Heinsberg',
    'Wassenberg',
    'Pahl',
    'Markt Indersdorf',
    'Marienstein',
    'Rottenbuch',
    'Schongau',
    'Bad Feilnbach',
    'Baiern',
    'Holzkirchen',
    'Eschenlohe',
    'Winterbach',
    'Murr',
    'Heringen',
    'Barchfeld',
    'Windehausen',
    'Gerstungen',
    'Stadtilm',
    'Breitungen',
    'Dingelstadt',
    'Ernstroda',
    'Stadtroda',
    'Bischofferode',
    'Ilfeld',
    'Bad Sulza',
    'Mihla',
    'Neuhaus am Rennweg',
    'Kindelbruck',
    'Seligenthal',
    'Urbach',
    'Zottelstedt',
    'Oberhof',
    'Lichte',
    'Mellenbach-Glasbach',
    'Olbersleben',
    'Thale',
    'Meiningen',
    'Heilbad Heiligenstadt',
    'Hohenstein',
    'Wernrode',
    'Walldorf',
    'Crossen',
    'Weida',
    'Bad Lobenstein',
    'Kamsdorf',
    'Eisenberg',
    'Schleiz',
    'Gossnitz',
    'Bocka',
    'Blankenstein',
    'Bad Blankenburg',
    'Zeulenroda',
    'Muhltroff',
    'Remptendorf',
    'Zuhlsdorf',
    'Vogelsdorf',
    'Trebbin',
    'Am Mellensee',
    'Bad Kostritz',
    'Neustadt (Orla)',
    'Munchenbernsdorf',
    'Wadern',
    'Rehlingen-Siersburg',
    'Muehlheim am Main',
    'Zainingen',
    'Grethem',
    'Haste',
    'Salzhemmendorf',
    'Burg',
    'Burladingen',
    'Haigerloch',
    'Lossburg',
    'Grunenberg',
    'Zimmern',
    'Rosenfeld',
    'Rheinsberg',
    'Krummensee',
    'Wildau',
    'Muncheberg',
    'Altlandsberg',
    'Asbach',
    'Alsbach-Hahnlein',
    'Buttelborn',
    'Lindenfels',
    'Einhausen',
    'Kefenrod',
    'Leingarten',
    'Massenbachhausen',
    'Nordheim',
    'Neuenstadt am Kocher',
    'Laubach',
    'Aull',
    'Mittenaar',
    'Ehringshausen',
    'Diez',
    'Wiesenbach',
    'Hassloch',
    'Rieseby',
    'Reher',
    'Tonning',
    'Burg auf Fehmarn',
    'Lensahn',
    'Fehmarn',
    'Kellinghusen',
    'Hohenwestedt',
    'Ahrensboek',
    'Unterschneidheim',
    'Satteldorf',
    'Buhlerzell',
    'Wolpertshausen',
    'Obersontheim',
    'Mainhardt',
    'Twiste',
    'Gudensberg',
    'Schwarzenborn',
    'Wabern',
    'Helsa',
    'Zierenberg',
    'Nieste',
    'Korle',
    'Fuldabruck',
    'Sachsenhagen',
    'Fassberg',
    'Konigheim',
    'Schonfeld',
    'Gernrode',
    'Magdala',
    'Kolleda',
    'Arenshausen',
    'Steinach',
    'Kallstadt',
    'Gundelsheim',
    'Neunkirchen',
    'Wilhelmsfeld',
    'Oberotterbach',
    'Huffenhardt',
    'Bohl-Iggelheim',
    'Jockgrim',
    'Rhein',
    'Aglasterhausen',
    'Beindersheim',
    'Lambrecht',
    'Leinsweiler',
    'Waldhambach',
    'Gonnheim',
    'Hatzenbuhl',
    'Niederkirchen bei Deidesheim',
    'Heiligkreuzsteinach',
    'Neupotz',
    'Graben-Neudorf',
    'Funfstetten',
    'Altenmunster',
    'Forheim',
    'Hirzenhain',
    'Ortenberg',
    'Rosbach vor der Hohe',
    'Schlitz',
    'Mindelstetten',
    'Icking',
    'Tutzing',
    'Krautheim',
    'Osterburken',
    'Glauburg',
    'Neuhausen',
    'Birkenfeld',
    'Kieselbronn',
    'Steinegg',
    'Konigsbach-Stein',
    'Rath',
    'Vetschau',
    'Grossraschen',
    'Neuhausen',
    'Borna',
    'Hartha',
    'Bad Lausick',
    'Schnaittach',
    'Hillersleben',
    'Gross Santersleben',
    'Wedringen',
    'Tangermunde',
    'Samswegen',
    'Elend',
    'Gusten',
    'Moser',
    'Osterwieck',
    'Zielitz',
    'Eilsleben',
    'Tangerhutte',
    'Weferlingen',
    'Flechtingen',
    'Obing',
    'Pittenhart',
    'Rimsting',
    'Waging am See',
    'Flinsbach',
    'Gimbsheim',
    'Mockmuhl',
    'Plochingen',
    'Benningen am Neckar',
    'Illertissen',
    'Holzgunz',
    'Aitrang',
    'Ottobeuren',
    'Gronenbach',
    'Altenstadt',
    'Friesenried',
    'Rieden',
    'Rosshaupten',
    'Unterthingau',
    'Altenstadt',
    'Leopoldshohe',
    'Muhlen',
    'Augustdorf',
    'Rieden',
    'Lemforde',
    'Essen',
    'Lienen',
    'Rehden',
    'Bohmte',
    'Unkel',
    'Rettigheim',
    'Bockenem',
    'Bleckede',
    'Astfeld',
    'Unterluss',
    'Tulau',
    'Lehmke',
    'Eschwege',
    'Bad Arolsen',
    'Waldkappel',
    'Trendelburg',
    'Wanfried',
    'Westerdeichstrich',
    'Schwabstedt',
    'Rodewisch',
    'Geilenkirchen',
    'Hurtgenwald',
    'Juchen',
    'Schleiden',
    'Gangelt',
    'Rutesheim',
    'Kastellaun',
    'Simmern',
    'Eppelsheim',
    'Gau-Odernheim',
    'Miehlen',
    'Rheinbollen',
    'Ustersbach',
    'Waidhaus',
    'Schirnding',
    'Pechbrunn',
    'Vohenstrauss',
    'Marktleugast',
    'Parkstein',
    'Kueps Oberfranken',
    'Gestungshausen',
    'Bad Berneck im Fichtelgebirge',
    'Etzenricht',
    'Gleiritsch',
    'Schauenstein',
    'Leonberg',
    'Marktleuthen',
    'Buchbach',
    'Niederneuching',
    'Haag',
    'Reithofen',
    'Gross Gronau',
    'Hasenmoor',
    'Rethwisch',
    'Stahnsdorf',
    'Schonfliess',
    'Borkheide',
    'Borkwalde',
    'Nennhausen',
    'Gorenzen',
    'Nebra',
    'Wulfen',
    'Bennungen',
    'Bad Schmiedeberg',
    'Goseck',
    'Eckartsberga',
    'Apen',
    'Voslapp',
    'Emstek',
    'Linkenheim-Hochstetten',
    'Forst',
    'Marschacht',
    'Kothel',
    'Weddelbrook',
    'Grossensee',
    'Rausdorf',
    'Krummesse',
    'Niendorf',
    'Ellhofen',
    'Waldenburg',
    'Hohenlinden',
    'Buch am Buchrain',
    'Oberornau',
    'Edling',
    'Mittbach',
    'Gars',
    'Schliersee',
    'Andechs',
    'Velden',
    'Egmating',
    'Herrsching am Ammersee',
    'Seefeld',
    'Bernried',
    'Baldham',
    'Babensham',
    'Hausham',
    'Schwaig',
    'Kirchen',
    'Tabarz',
    'Gorkwitz',
    'Schwabhausen',
    'Grainau',
    'Forstinning',
    'Benediktbeuern',
    'Farchant',
    'Odelzhausen',
    'Hodenhagen',
    'Hoheneggelsen',
    'Kirchhundem',
    'Horgertshausen',
    'Tiefenbach',
    'Berglern',
    'Wartenberg',
    'Winzeln',
    'Schomberg',
    'Durchhausen',
    'Empfingen',
    'Dornhan',
    'Frasdorf',
    'Unterneukirchen',
    'Ruhpolding',
    'Hoslwang',
    'Bad Endorf',
    'Eggstatt',
    'Engelsberg',
    'Kirchweidach',
    'Neuberg',
    'Neuberg',
    'Leimbach',
    'Breitenbach',
    'Homberg',
    'Nentershausen',
    'Jesberg',
    'Bergen',
    'Coppenbrugge',
    'Hofer',
    'Hademstorf',
    'Niedernwohren',
    'Hespe',
    'Dankmarshausen',
    'Tann',
    'Schwalmtal',
    'Sterbfritz',
    'Kirchheim',
    'Obersuhl',
    'Bebra',
    'Heringen',
    'Gilserberg',
    'Hilders',
    'Petersberg',
    'Mottgers',
    'Schenklengsfeld',
    'Philippsthal',
    'Wippershain',
    'Meuro',
    'Plessa',
    'Brauweiler',
    'Vettweiss',
    'Winkelhaid',
    'Uehlfeld',
    'Cadolzburg',
    'Strullendorf',
    'Meeder',
    'Bad Staffelstein',
    'Altdorf',
    'Winkelhaid',
    'Memmelsdorf',
    'Jagstzell',
    'Obermarchtal',
    'Spraitbach',
    'Ingelfingen',
    'Alfdorf',
    'Fichtenberg',
    'Kaldenkirchen',
    'Aach',
    'Bodman-Ludwigshafen',
    'Willstatt',
    'Rheinau',
    'Seelbach',
    'Hornberg',
    'Oppenau',
    'Wolfach',
    'Balve',
    'Saerbeck',
    'Lichtenstein',
    'Bohmenkirch',
    'Schelklingen',
    'Gingen an der Fils',
    'Durlangen',
    'Isny im Allgau',
    'Hattenhofen',
    'Erolzheim',
    'Nellingen',
    'Bad Buchau',
    'Gruibingen',
    'Drensteinfurt',
    'Heek',
    'Ennigerloh',
    'Rattenkirchen',
    'Ubersee',
    'Kraiburg am Inn',
    'Reit im Winkl',
    'Taching am See',
    'Ampfing',
    'Tacherting',
    'Marktl',
    'Siegsdorf',
    'Anger',
    'Ohningen',
    'Moos',
    'Schonach im Schwarzwald',
    'Aftholderberg',
    'Neufrach',
    'Gailingen',
    'Muhlhausen-Ehingen',
    'Ronsberg',
    'Pforzen',
    'Niederrieden',
    'Pfronten',
    'Boos',
    'Jengen',
    'Lautrach',
    'Missen-Wilhams',
    'Westerheim',
    'Kirchdorf',
    'Mulsen',
    'Neuhausen',
    'Klingenthal',
    'Muhlau',
    'Oberschona',
    'Oederan',
    'Hartmannsdorf',
    'Auerbach',
    'Lauter',
    'Taura',
    'Gelenau',
    'Zschopau',
    'Werda',
    'Neckarsteinach',
    'Meckesheim',
    'Helmstadt',
    'Schieder-Schwalenberg',
    'Kurort Steinbach-Hallenberg',
    'Bad Liebenstein',
    'Hattstedt',
    'Friedrichstadt',
    'Dahme',
    'Brokstedt',
    'Erfde',
    'Behrendorf',
    'Suderstapel',
    'Buchwalde',
    'Langenberg',
    'Ehrenburg',
    'Suhlendorf',
    'Driedorf',
    'Haiger',
    'Colbe',
    'Wolframs-Eschenbach',
    'Lichtenfels',
    'Lauterhofen',
    'Petershagen',
    'Arnstorf',
    'Postmunster',
    'Neuhaus',
    'Eggenfelden',
    'Simbach am Inn',
    'Neukirchen am Teisenberg',
    'Pfarrkirchen',
    'Gottfrieding',
    'Neuhaus',
    'Aldersbach',
    'Mallersdorf-Pfaffenberg',
    'Ortenburg',
    'Chostlarn',
    'Falkenberg',
    'Wietze',
    'Eschede',
    'Haenigsen',
    'Laubenheim',
    'Biebelsheim',
    'Norheim',
    'Simmertal',
    'Sommerloch',
    'Windesheim',
    'Welgesheim',
    'Boos',
    'Odernheim',
    'Pfaffen-Schwabenheim',
    'Rudesheim',
    'Altenberg',
    'Bahratal',
    'Klipphausen',
    'Merkendorf',
    'Faulbach',
    'Kutenholz',
    'Bederkesa',
    'Nordholz',
    'Ruhland',
    'Lohsa',
    'Tschernitz',
    'Kolkwitz',
    'Broeckel',
    'Eldingen',
    'Steinhorst',
    'Adelheidsdorf',
    'Bad Fallingbostel',
    'Algermissen',
    'Harsum',
    'Edemissen',
    'Hambuhren',
    'Unteruhldingen',
    'Villingen',
    'Salem',
    'Wiesensteig',
    'Genkingen',
    'Munderkingen',
    'Neukirch',
    'Mochenwangen',
    'Schlier',
    'Eberhardzell',
    'Aitrach',
    'Amtzell',
    'Waldburg',
    'Hettstadt',
    'Eltmann',
    'Donnersdorf',
    'Bad Konigshofen im Grabfeld',
    'Gochsheim',
    'Ochsenfurt',
    'Oberickelsheim',
    'Kirschfurt',
    'Gollhofen',
    'Ebelsbach',
    'Frammersbach',
    'Estenfeld',
    'Munster',
    'Muhltal',
    'Dransfeld',
    'Golmbach',
    'Hardenberg',
    'Duderode',
    'Neuendorf',
    'Brieskow-Finkenheerd',
    'Mixdorf',
    'Steintoch',
    'Bad Freienwalde',
    'Spree',
    'Beeskow',
    'Wendisch Rietz',
    'Gross Lindow',
    'Mark',
    'Bad Saarow',
    'Waldstetten',
    'Westerheim',
    'Geislingen an der Steige',
    'Iggingen',
    'Merklingen',
    'Grabenstetten',
    'Lonsee',
    'Alpen',
    'Lauenau',
    'Muden',
    'Schoneworde',
    'Luechow',
    'Hitzacker',
    'Elbe',
    'Gielde',
    'Baddeckenstedt',
    'Everswinkel',
    'Olfen',
    'Wadersloh',
    'Schoppingen',
    'Kiedrich',
    'Geisenheim',
    'Uhler',
    'Kirchberg',
    'Reichelsheim',
    'Westkirchen',
    'Pflummern',
    'Wain',
    'Lauterach',
    'Jungingen',
    'Kisslegg',
    'Schwarzenfeld',
    'Rosendahl',
    'Arnschwang',
    'Kakenstorf',
    'Wittorf',
    'Gardelegen',
    'Garbek',
    'Siebenbaumen',
    'Berkenthin',
    'Gronau',
    'Schmilau',
    'Rondeshagen',
    'Lucka',
    'Langenbernsdorf',
    'Wurzbach',
    'Leutenberg',
    'Hartmannsdorf',
    'Dobitschen',
    'Kaulsdorf',
    'Pausa',
    'Mohlsdorf',
    'Dahn',
    'Hutschenhausen',
    'Thaleischweiler-Froschen',
    'Weilerbach',
    'Bruchmuhlbach-Miesau',
    'Rammelsbach',
    'Imsbach',
    'Winnweiler',
    'Glan-Munchweiler',
    'Waldfischbach-Burgalben',
    'Rudelzhausen',
    'Tauberfeld',
    'Au in der Hallertau',
    'Wolfersdorf',
    'Fraunberg',
    'Schweitenkirchen',
    'Reitmehring',
    'Allershausen',
    'Ohren',
    'Weilburg',
    'Mengerskirchen',
    'Fleisbach',
    'Weimar',
    'Leun',
    'Seeburg',
    'Adelebsen',
    'Wendeburg',
    'Barwedel',
    'Ruppichteroth',
    'Hausern',
    'Weisweil',
    'Heitersheim',
    'Zell',
    'Gorwihl',
    'Endingen',
    'Eberdingen',
    'Fintel',
    'Neuenkirchen',
    'Affinghausen',
    'Alpirsbach',
    'Winterlingen',
    'Zimmern ob Rottweil',
    'Hohentengen am Hochrhein',
    'Gammertingen',
    'Bitz',
    'Schafflund',
    'Satrup',
    'Jubek',
    'Struxdorf',
    'Bargum',
    'Dorpstedt',
    'Brodersby',
    'Bollingstedt',
    'Schaalby',
    'Kampen',
    'Leutershausen',
    'Burk',
    'Petersaurach',
    'Ornbau',
    'Diebach',
    'Megesheim',
    'Ederheim',
    'Sielenbach',
    'Kaisheim',
    'Ernstthal',
    'Ronneburg',
    'Buttenheim',
    'Neundorf',
    'Haiterbach',
    'Sigmaringendorf',
    'Oschingen',
    'Arnstein',
    'Uettingen',
    'Alzenau in Unterfranken',
    'Kurnach',
    'Giebelstadt',
    'Weisen',
    'Galenbeck',
    'Perleberg',
    'Zerrenthin',
    'Reisbach',
    'Neureichenau',
    'Hinterschmiding',
    'Am See',
    'Waldkirchen',
    'Querfurt',
    'Karsdorf',
    'Mucheln',
    'Nienburg/Saale',
    'Steigra',
    'Elster',
    'Markt Einersheim',
    'Stettfeld',
    'Karbach',
    'Zeil',
    'Iphofen',
    'Roetgen',
    'Lahden',
    'Grossruckerswalde',
    'Schwarzheide',
    'Hainichen',
    'Elterlein',
    'Plettenberg',
    'Osthofen',
    'Geinsheim',
    'Biblis',
    'Dienheim',
    'Bermersheim',
    'Westhofen',
    'Bad Elster',
    'Bernsbach',
    'Kirchberg',
    'Zaulsdorf',
    'Zschorlau',
    'Windsbach',
    'Muhlhausen',
    'Dietersheim',
    'Puschendorf',
    'Litzendorf',
    'Stegaurach',
    'Podeldorf',
    'Gutenstetten',
    'Wiefelstede',
    'Wagenfeld',
    'Wetschen',
    'Egelsbach',
    'Niederdorfelden',
    'Wehlheiden',
    'Willingen',
    'Naumburg',
    'Spangenberg',
    'Mehmels',
    'Romhild',
    'Catterfeld',
    'Linden',
    'Bad Breisig',
    'Ruppach-Goldhausen',
    'Nauort',
    'Mendig',
    'Plaidt',
    'Wassenach',
    'Heinz',
    'Heinzenberg',
    'Langenbach',
    'Neustadt bei Coburg',
    'Pessenburgheim',
    'Ruthnick',
    'Bismark',
    'Kyritz',
    'Langewiesen',
    'Grossbreitenbach',
    'Wallersdorf',
    'Irlbach',
    'Taufkirchen',
    'Grafschaft',
    'Wohnbach',
    'Waldems',
    'Hammersbach',
    'Mengkofen',
    'Rohr',
    'Mauth',
    'Marklkofen',
    'Hohenau',
    'Bad Fussing',
    'Hofkirchen',
    'Kirchham',
    'Rotthalmunster',
    'Deggendorf',
    'Aidenbach',
    'Haidmuhle',
    'Massing',
    'Egglham',
    'Ruhstorf',
    'Neuhaus am Inn',
    'Legden',
    'Burgbernheim',
    'Neuzelle',
    'Fischbach',
    'Mittelneufnach',
    'Kirkel',
    'Horst',
    'Burbach',
    'Bullay',
    'Traben-Trarbach',
    'Klausen',
    'Gondenbrett',
    'Zell',
    'Hallschlag',
    'Prum',
    'Schweich',
    'Schwirzheim',
    'Bernkastel-Kues',
    'Badem',
    'Irmenach',
    'Lieser',
    'Dreis',
    'Rhaunen',
    'Losheim',
    'Seffern',
    'Morbach',
    'Leiwen',
    'Malberg',
    'Daun',
    'Hochscheid',
    'Kordel',
    'Hillesheim',
    'Sehlem',
    'Eschfeld',
    'Osterburg',
    'Schwanebeck',
    'Irxleben',
    'Hohenerxleben',
    'Kroppenstedt',
    'Bismark',
    'Welsleben',
    'Wegeleben',
    'Huckeswagen',
    'Uberfeld',
    'Zorbig',
    'Braunsbedra',
    'Wimmelburg',
    'Pouch',
    'Teutschenthal',
    'Annaburg',
    'Norvenich',
    'Drolshagen',
    'Lehrberg',
    'Burgoberbach',
    'Kottenheim',
    'Hinterzarten',
    'Haimhausen',
    'Wurmsham',
    'Petershausen',
    'Elstra',
    'Radeburg',
    'Konigswartha',
    'Prietitz',
    'Langebruck',
    'Leutenbach',
    'Burgstall',
    'Beilstein',
    'Lowenstein',
    'Calvorde',
    'Stadtallendorf',
    'Friedland',
    'Heltersberg',
    'Horbach',
    'Nalbach',
    'Gerlfangen',
    'Queidersbach',
    'Kallmunz',
    'Leiblfing',
    'Iggensbach',
    'Kirchroth',
    'Abensberg',
    'Haidhof',
    'Morsbach',
    'Nohfelden',
    'Grafenroda',
    'Dippach',
    'Ruhla',
    'Schleusingen',
    'Vacha',
    'Schonwald im Schwarzwald',
    'Lampertswalde',
    'Kreischa',
    'Ostseebad Nienhagen',
    'Seeheilbad Graal-Muritz',
    'Marlow',
    'Glashagen',
    'Vietgest',
    'Schwaan',
    'Blankenhagen',
    'Weitenhagen',
    'Hebertshausen',
    'Gaissach',
    'Greiling',
    'Kirspenich',
    'Heimerzheim',
    'Nortrup',
    'Voltlage',
    'Loningen',
    'Bad Laer',
    'Bad Teinach-Zavelstein',
    'Meschede',
    'Kammerforst',
    'Hohenleimbach',
    'Leubsdorf',
    'Helferskirchen',
    'Kempenich',
    'Bell',
    'Rheinbrohl',
    'Eitelborn',
    'Schmitten',
    'Hepberg',
    'Jetzendorf',
    'Bad Heilbrunn',
    'Singhofen',
    'Freckenfeld',
    'Burgstetten',
    'Thyrnau',
    'Kirchdorf',
    'Triftern',
    'Pfeffenhausen',
    'Freyung',
    'Bad Schwalbach',
    'Nackenheim',
    'Rudesheim am Rhein',
    'Kiefersfelden',
    'Teisendorf',
    'Isselburg',
    'Billigheim',
    'Zwingenberg',
    'Weisenheim am Berg',
    'Krauchenwies',
    'Bevern',
    'Ravenstein',
    'Gaiberg',
    'Seckach',
    'Bammental',
    'Adelsheim',
    'Burbach',
    'Derschen',
    'Erwitte',
    'Mastholte',
    'Arnbach',
    'Ampermoching',
    'Vilsbiburg',
    'Wettstetten',
    'Moorenweis',
    'Datteln',
    'Schonstett',
    'Chieming',
    'Tuszshing',
    'Aschau',
    'Oberneukirchen',
    'Laufen',
    'Ramsau',
    'Schleching',
    'Petting',
    'Vachendorf',
    'Grabenstatt',
    'Breest',
    'Grambin',
    'Carmzow',
    'Torgelow',
    'Wilhelmsburg',
    'Thurkow',
    'Horstel',
    'Dahlem',
    'Dunningen',
    'Oberopfingen',
    'Oberkammlach',
    'Egg an der Gunz',
    'Blaichach',
    'Seeg',
    'Buhlertann',
    'Bonndorf im Schwarzwald',
    'Buchenbach',
    'Schluchsee',
    'Sexau',
    'Bahlingen',
    'Merdingen',
    'Solden',
    'Blankenrath',
    'Fell',
    'Trierweiler',
    'Arenrath',
    'Saarburg',
    'Irrel',
    'Beuren',
    'Longuich',
    'Gerolstein',
    'Osterspai',
    'Bad Honningen',
    'Willroth',
    'Hausten',
    'Hachenburg',
    'Mertloch',
    'Wissen',
    'Cochem',
    'Kruft',
    'Faid',
    'Bad Marienberg',
    'Dernau',
    'Oberelbert',
    'Alpenrod',
    'Welver',
    'Reichertshofen',
    'Karlskron',
    'Bestwig',
    'Schmallenberg',
    'Ruthen',
    'Kervenheim',
    'Treudelberg',
    'Winden',
    'Albbruck',
    'Neuhausen ob Eck',
    'Bad Steben',
    'Seebad Ahlbeck',
    'Hallenberg',
    'Finnentrop',
    'Orbis',
    'Hofen an der Enz',
    'Darstein',
    'Moyland',
    'Borsfleth',
    'Rackwitz',
    'Maierhofen',
    'Waldschlosschen',
    'Kirburg',
    'Mulda',
    'Vollkofen',
    'Spessart',
    'Grosskugel',
    'Weiding',
    'Woltingerode',
    'Eibsee',
    'Schlierbach',
    'Hermannsburg',
    'Wehnrath',
    'Neuental',
    'Kurten',
    'Purgen',
    'Hahn',
    'Festenburg',
    'Stockhausen',
    'Thoma',
    'Teschow',
    'Ballendorf',
    'Hohne',
    'Wentorf',
    'Vettelschoss',
    'Lubmin',
    'Stapelfeld',
    'Klein Offenseth-Sparrieshoop',
    'Hinterm Horn',
    'Altenmedingen',
    'Spalt',
    'Hilpoltstein',
    'Kettenkamp',
    'Haselunne',
    'Jaderberg',
    'Metjendorf',
    'Recke',
    'Salzburg',
    'Basel',
    'Bulow',
    'Buchwaldchen',
    'Wadelsdorf',
    'Beilrode',
    'Schwarz',
    'Grove',
    'Sanssouci',
    'Tegernsee',
    'Anker',
    'Seehof',
    'Kettig',
    'Marschall',
    'Pixel',
    'Flieden',
    'Watzenborn',
    'Delingsdorf',
    'Dorpen',
    'Hennstedt',
    'Drage',
    'Deinste',
    'Melbeck',
    'Estorf',
    'Wankendorf',
    'Heidehof',
    'Horhausen',
    'Dernbach',
    'Selters',
    'Kurtscheid',
    'Kaltenengers',
    'Rengsdorf',
    'Waldesch',
    'Spay',
    'Bassenheim',
    'Breitscheid',
    'Linkenbach',
    'Dierdorf',
    'Mellingen',
    'Komp',
    'Rhein',
    'Ostercappeln',
    'Dorf Doberitz',
    'Gutzkow',
    'Staig',
    'Achstetten',
    'Kirchzarten',
    'Gunningen',
    'Kapellenberg',
    'Bogen',
    'Ehekirchen',
    'Hitzhofen',
    'Kirchweg',
    'Netze',
    'Lohra',
    'Merching',
    'Herringen',
    'Birkenhof',
    'Badenweiler',
    'Bodensee',
    'Bensberg',
    'Stromberg',
    'Ohlsdorf',
    'Walpertshofen',
    'Rothenburg',
    'Gravenbruch',
    'Osch',
    'Wall',
    'Munich',
    'Bad Schachen',
    'Vorbach',
    'Garden',
    'Im Loh',
    'Teistungenburg',
    'Nobitz',
    'Brandl',
    'Heideck',
    'Postbauer-Heng',
    'Berngau',
    'Timmaspe',
    'Gnutz',
    'Wattenbek',
    'Kayhude',
    'Bimohlen',
    'Stephanskirchen',
    'Martinsried',
    'Geisenhausen',
    'Forchheim',
    'Adelsdorf',
    'Hausen',
    'Ihringen',
    'Kenzingen',
    'Altdorf',
    'Rechberg',
    'Erzingen',
    'Altbach',
    'Althutte',
    'Eitensheim',
    'Kutzenhausen',
    'Osburg',
    'Bickenbach',
    'Erbach im Odenwald',
    'Furth',
    'Alzey',
    'Biebesheim',
    'Wallmerod',
    'Partenheim',
    'Harxheim',
    'Laurenburg',
    'Sprendlingen',
    'Friesenhagen',
    'Macken',
    'Bardenberg',
    'Berlin',
    'Uelsen',
    'Suddendorf',
    'Bawinkel',
    'Hoogstede',
    'Georgsdorf',
    'Lage',
    'Furstenau',
    'Freren',
    'Renchen',
    'Hofstetten',
    'Fischerbach',
    'Oberharmersbach',
    'Haslach im Kinzigtal',
    'Schwaibach',
    'Hausach',
    'Hesedorf',
    'Peitz',
    'Bad Liebenwerda',
    'Munchweiler an der Alsenz',
    'Dietrichingen',
    'Eppenbrunn',
    'Rosenkopf',
    'Rockenhausen',
    'Krickenbach',
    'Walshausen',
    'Oberndorf',
    'Alfstedt',
    'Sontra',
    'Bahrenfleth',
    'Neuenbrook',
    'Steinburg',
    'Neuwittenbek',
    'Strande',
    'Danischenhagen',
    'Gleschendorf',
    'Colnrade',
    'Cappeln',
    'Buchlberg',
    'Hebertsfelden',
    'Haarbach',
    'Roehrnbach',
    'Hamb',
    'Gronau',
    'Bruggen',
    'Haina',
    'Breitscheid',
    'Schonecken',
    'Bitburg',
    'Kinheim',
    'Mehring',
    'Neuhutten',
    'Wolsfeld',
    'Berglicht',
    'Binsfeld',
    'Rittersdorf',
    'Murlenbach',
    'Neuerburg',
    'Dettenhausen',
    'Drestedt',
    'Halsenbach',
    'Welterod',
    'Duchroth',
    'Beckedorf',
    'Schellerten',
    'Wollstein',
    'Ober-Olm',
    'Bad Munster am Stein-Ebernburg',
    'Trollenhagen',
    'Hilwartshausen',
    'Lachendorf',
    'Bad Sooden-Allendorf',
    'Malterdingen',
    'Katzenmoos',
    'Oldersum',
    'Langeoog',
    'Neuborger',
    'Nieder-Olm',
    'Bad Sachsa',
    'Harste',
    'Wulften',
    'Lottstetten',
    'Brachbach',
    'Daaden',
    'Dorstadt',
    'Hoitlingen',
    'Brandis',
    'Borsdorf',
    'Roklum',
    'Jelmstorf',
    'Suderburg',
    'Leiferde',
    'Vohringen',
    'Oggelshausen',
    'Reichenbach im Tale',
    'Aichstetten',
    'Frickenhausen',
    'Sehlde',
    'Bieber',
    'Wiggensbach',
    'Buxheim',
    'Halblech',
    'Konigsbruck',
    'Hohnstein',
    'Glashutte',
    'Diepenau',
    'Mauer',
    'Hardheim',
    'Kulsheim',
    'Hopsten',
    'Lembruch',
    'Windhagen',
    'Braubach',
    'Oberrod',
    'Woldert',
    'Selbach',
    'Steimel',
    'Buchholz',
    'Steuden',
    'Britz',
    'Twistringen',
    'Bexhovede',
    'Binzen',
    'Schweringen',
    'Hassel',
    'Schwarme',
    'Stadtprozelten',
    'Weilbach',
    'Sailauf',
    'Riedenberg',
    'Kalbach',
    'Rothlein',
    'Zwesten',
    'Liebenau',
    'Berkheim',
    'Winterrieden',
    'Lauben',
    'Eichelgarten',
    'Waldeck',
    'Herleshausen',
    'Cornberg',
    'Weissenbrunn',
    'Ludwigsstadt',
    'Stadtsteinach',
    'Eystrup',
    'Martfeld',
    'Mudau',
    'Schwarzach',
    'Tessin',
    'Suderholz',
    'Loddin',
    'Hohen Schwarfs',
    'Broderstorf',
    'Ducherow',
    'Horst',
    'Steinhagen',
    'Kritzmow',
    'Volkenshagen',
    'Gribow',
    'Bad Doberan',
    'Brandshagen',
    'Zempin',
    'Dummerstorf',
    'Kassow',
    'Kramerhof',
    'Grammow',
    'Birkheim',
    'Weinsheim',
    'Bacharach',
    'Suckow',
    'Rehna',
    'Crivitz',
    'Banzkow',
    'Cambs',
    'Wardow',
    'Zarrentin',
    'Spornitz',
    'Mecklenburg',
    'Gadebusch',
    'Laage',
    'Lubz',
    'Krakow am See',
    'Eldena',
    'Neu Vitense',
    'Camin',
    'Gross Bengerstorf',
    'Niepars',
    'Wittenberge',
    'Brusewitz',
    'Leezen',
    'Rehhorst',
    'Rettin',
    'Brunstorf',
    'Huttblek',
    'Spangdahlem',
    'Bettingen',
    'Landscheid',
    'Thalfang',
    'Ebenweiler',
    'Wolpertswende',
    'Todenbuttel',
    'Bargenstedt',
    'Altwittenbek',
    'Hanerau-Hademarschen',
    'Warnau',
    'Sankt Peter-Ording',
    'Tetenbull',
    'Witzwort',
    'Oldenswort',
    'Ascheberg',
    'Quarnstedt',
    'Bornhoved',
    'Kasseedorf',
    'Elsdorf-Westermuhlen',
    'Ascheffel',
    'Pellworm',
    'Arlewatt',
    'Bistensee',
    'Hochdonn',
    'Brickeln',
    'Sarlhusen',
    'Schulldorf',
    'Breitenburg',
    'Elisabeth-Sophien-Koog',
    'Schonwalde',
    'Witzhave',
    'Lichtenberg',
    'Warmensteinach',
    'Grafengehaig',
    'Wittenburg',
    'Wenzendorf',
    'Vastorf',
    'Bad Schussenried',
    'Hosskirch',
    'Dieblich',
    'Asbach',
    'Kotterichen',
    'Arzbach',
    'Rennerod',
    'Flammersfeld',
    'Kobern-Gondorf',
    'Niederzissen',
    'Assel',
    'Helmste',
    'Kennenburg',
    'Korb',
    'Freiburg',
    'Brackel',
    'Stadelhofen',
    'Wallenfels',
    'Wonsees',
    'Wagersrott',
    'Taarstedt',
    'Langstedt',
    'Hasselberg',
    'Suderbrarup',
    'Keitum',
    'Borgsum',
    'Rantum',
    'Stolk',
    'Boel',
    'Burgsinn',
    'Mellrichstadt',
    'Pettstadt',
    'Wipfeld',
    'Grossbardorf',
    'Egenhausen',
    'Burgwindheim',
    'Heustreu',
    'Bergrheinfeld',
    'Monchsroth',
    'Gillersdorf',
    'Beinerstadt',
    'Palling',
    'Kienberg',
    'Bernau am Chiemsee',
    'Surheim',
    'Halsbach',
    'Ering',
    'Geratskirchen',
    'Rimbach',
    'Wolfegg',
    'Nersingen',
    'Uttenweiler',
    'Bachingen an der Brenz',
    'Bodenwohr',
    'Kirchdorf im Wald',
    'Schierling',
    'Nabburg',
    'Bernhardswald',
    'Geisling',
    'Tegernheim',
    'Riedenburg',
    'Wiesent',
    'Painten',
    'Metten',
    'Wildenberg',
    'Offenberg',
    'Bayern',
    'Brenz',
    'Altenriet',
    'Blaibach',
    'Eschlkam',
    'Rinchnach',
    'Hunderdorf',
    'Bayerisch Eisenstein',
    'Gotteszell',
    'Plattling',
    'Leinburg',
    'Oettersdorf',
    'Tengen',
    'Eschenbach',
    'Kohlberg',
    'Teuschnitz',
    'Creussen',
    'Roslau',
    'Regnitzlosau',
    'Rimbach',
    'Pfalzgrafenweiler',
    'Hersbruck',
    'Hallerndorf',
    'Durrbrunn',
    'Grafenberg',
    'Hemhofen',
    'Rottenbach',
    'Kirchehrenbach',
    'Finsing',
    'Warngau',
    'Waakirchen',
    'Seeshaupt',
    'Otterfing',
    'Kochel',
    'Kipfenberg',
    'Diepersdorf',
    'Muggendorf',
    'Spardorf',
    'Greding',
    'Leutenbach',
    'Buckenhof',
    'Ottensoos',
    'Grossenseebach',
    'Rechtenbach',
    'Rothenbuch',
    'Hollstadt',
    'Hergolshausen',
    'Bischofsheim an der Rhon',
    'Eussenheim',
    'Stockheim',
    'Oberelsbach',
    'Schillingsfurst',
    'Neuhof',
    'Sonnefeld',
    'Neuhof',
    'Gundelsheim',
    'Adelschlag',
    'Markt Erlbach',
    'Adelshofen',
    'Sesslach',
    'Ahorn',
    'Bellershausen',
    'Dollnstein',
    'Wicklesgreuth',
    'Langenbach',
    'Lenting',
    'Essenbach',
    'Riemerling',
    'Nassenfels',
    'Herrieden',
    'Fatschenbrunn',
    'Lichtenau',
    'Lichtenau',
    'Wilburgstetten',
    'Pleinfeld',
    'Weitramsdorf',
    'Feuchtwangen',
    'Leibelbach',
    'Gerolfingen',
    'Ehingen',
    'Deining',
    'Allersberg',
    'Heimbuchenthal',
    'Gaukonigshofen',
    'Wiesthal',
    'Poppenhausen',
    'Unterpleichfeld',
    'Gemünden am Main',
    'Frensdorf',
    'Flachslanden',
    'Kammerstein',
    'Kemmern',
    'Obermichelbach',
    'Retzstadt',
    'Rottingen',
    'Butthard',
    'Schonungen',
    'Schollbrunn',
    'Umpfenbach',
    'Bad Bocklet',
    'Schondra',
    'Hafenlohr',
    'Seligenstadt',
    'Mainstockheim',
    'Riedmoos',
    'Tuntenhausen',
    'Emmering',
    'Weyarn',
    'Inning am Ammersee',
    'Uffing',
    'Valley',
    'Rott am Inn',
    'Bad Wiessee',
    'Utting',
    'Groitzsch',
    'Husby',
    'Wyk auf Fohr',
    'Grafrath',
    'Kolbermoor',
    'Kuhbach',
    'Iffeldorf',
    'Oberammergau',
    'Eydelstedt',
    'Barnstorf',
    'Bippen',
    'Badbergen',
    'Berge',
    'Pentenried',
    'Wildenfels',
    'Oberdolling',
    'Rohrbach',
    'Gammelsdorf',
    'Feldafing',
    'Albaching',
    'Wolnzach',
    'Neukeferloh',
    'Rieden',
    'Batzhausen',
    'Aholming',
    'Parkstetten',
    'Schmidmuhlen',
    'Laaber',
    'Moosburg',
    'Wang',
    'Lutau',
    'Grossbeeren',
    'Pfaffenhofen an der Ilm',
    'Munsing',
    'Moosach',
    'Hohenwart',
    'Neuburg an der Donau',
    'Kottgeisering',
    'Attenkirchen',
    'Sulzemoos',
    'Langenpreising',
    'Rachelsbach',
    'Zuchering',
    'Zolling',
    'Tuerkenfeld',
    'Walpertskirchen',
    'Allendorf',
    'Wieseck',
    'Gutmadingen',
    'Stuhlingen',
    'Niederstaufenbach',
    'Kusel',
    'Trippstadt',
    'Rimschweiler',
    'Haschbach an der Glan',
    'Hochspeyer',
    'Bundenthal',
    'Stelzenberg',
    'Otterberg',
    'Nanzweiler',
    'Sembach',
    'Allmannshofen',
    'Genderkingen',
    'Leitershofen',
    'Graben',
    'Villenbach',
    'Fremdingen',
    'Schwaben',
    'Stadtbergen',
    'Monchsdeggingen',
    'Mickhausen',
    'Dielingen',
    'Mahlberg',
    'Rugland',
    'Ehingen',
    'Wallerstein',
    'Bachhagel',
    'Zusamaltheim',
    'Schiltberg',
    'Oettingen in Bayern',
    'Mechelgrun',
    'Beierfeld',
    'Lossnitz',
    'Markneukirchen',
    'Breitenbrunn',
    'Herfatz',
    'Opfenbach',
    'Betzigau',
    'Rettenberg',
    'Schwangau',
    'Untrasried',
    'Eisenberg',
    'Bad Hindelang',
    'Herzlake',
    'Lunne',
    'Hohenstein',
    'Heiningen',
    'Baienfurt',
    'Bunde',
    'Konigseggwald',
    'Bartholoma',
    'Attenweiler',
    'Buhlen',
    'Niedenstein',
    'Emerkingen',
    'Griesingen',
    'Altshausen',
    'Dettingen an der Iller',
    'Owen',
    'Bierlingen',
    'Motzingen',
    'Aichhalden',
    'Bad Rippoldsau-Schapbach',
    'Deisslingen',
    'Sauldorf',
    'Pulsnitz',
    'Olbersdorf',
    'Ostritz',
    'Rohrsdorf',
    'Bad Duben',
    'Nerchau',
    'Belgern',
    'Torgau',
    'Pegau',
    'Oeversee',
    'Leck',
    'Westerland',
    'Kropp',
    'Sollerup',
    'Grossenwiehe',
    'Tetenhusen',
    'Brebel',
    'Heudorf',
    'Altheim',
    'Wyhl',
    'Breitnau',
    'Steinen',
    'Kandern',
    'Ibach',
    'Herbolzheim',
    'Laufenburg',
    'Todtmoos',
    'Ebringen',
    'Eschbach',
    'Sasbach',
    'Uhlingen-Birkendorf',
    'Ballrechten',
    'Utzenfeld',
    'Schalksmuhle',
    'Schomberg',
    'Engelsbrand',
    'Ostelsheim',
    'Gomaringen',
    'Raubach',
    'Munstermaifeld',
    'Stockhausen-Illfurth',
    'Mundersbach',
    'Alsbach',
    'Ulmen',
    'Kleinmaischeid',
    'Neuweiler',
    'Sternenfels',
    'Neugersdorf',
    'Oppach',
    'Goda',
    'Mistelbach',
    'Himmelkron',
    'Luhe-Wildenau',
    'Altenstadt',
    'Speinshart',
    'Partenstein',
    'Heigenbrucken',
    'Bodenkirchen',
    'Hohenbrunn',
    'Dorum',
    'Seefeld',
    'Bichl',
    'Rottleberode',
    'Neukieritzsch',
    'Colditz',
    'Rosswein',
    'Mutzschen',
    'Ottersweier',
    'Oberwolfach',
    'Muggensturm',
    'Kappel-Grafenhausen',
    'Greifenstein',
    'Selters',
    'Niederquembach',
    'Rodheim',
    'Schornsheim',
    'Sulzheim',
    'Mengerschied',
    'Oberhausen-Rheinhausen',
    'Unterliezheim',
    'Asbach',
    'Lutzingen',
    'Gerabronn',
    'Jagsthausen',
    'Burtenbach',
    'Gachenbach',
    'Oberrot',
    'Allmersbach im Tal',
    'Scheuerhalden',
    'Fischbach',
    'Kirtorf',
    'Rasdorf',
    'Hosenfeld',
    'Nennig',
    'Ensdorf',
    'Hirstein',
    'Gusterath',
    'Burgen',
    'Auw',
    'Maring-Noviand',
    'Mandern',
    'Baasem',
    'Eckfeld',
    'Korperich',
    'Sefferweich',
    'Schleid',
    'Platten',
    'Prumzurlay',
    'Lambertsberg',
    'Hilscheid',
    'Altenkirchen',
    'Niederkirchen',
    'Schindhard',
    'Clausen',
    'Heinzenhausen',
    'Konken',
    'Hornbach',
    'Contwig',
    'Medard',
    'Offenbach-Hundheim',
    'Waldmohr',
    'Olsbrucken',
    'Einselthum',
    'Breitenbach',
    'Dittweiler',
    'Wilgartswiesen',
    'Papendorf',
    'Rullstorf',
    'Scharnebeck',
    'Klein-Gerau',
    'Rieneck',
    'Ippesheim',
    'Lautertal',
    'Klein-Zimmern',
    'Billings',
    'Oberstenfeld',
    'Dorzbach',
    'Stimpfach',
    'Schmidthachenbach',
    'Hohen',
    'Oberdiebach',
    'Sankt Georgen im Schwarzwald',
    'Norsingen',
    'Vohrenbach',
    'Waldsassen',
    'Waldalgesheim',
    'Kesselbach',
    'Sargenroth',
    'Pfalzfeld',
    'Pleizenhausen',
    'Engelstadt',
    'Erbes-Budesheim',
    'Abenberg',
    'Walsdorf',
    'Heroldsbach',
    'Burgebrach',
    'Buchenbach',
    'Kinding',
    'Oberwesel',
    'Hochstetten-Dhaun',
    'Kasdorf',
    'Bergen',
    'Geisig',
    'Bornich',
    'Merxheim',
    'Monsheim',
    'Seesbach',
    'Breitscheid',
    'Neubeckum',
    'Mommenheim',
    'Flonheim',
    'Pyrbaum',
    'Priesendorf',
    'Euerdorf',
    'Rimpar',
    'Stadtlauringen',
    'Kolitzheim',
    'Stammheim',
    'Waldbuttelbrunn',
    'Dittelbrunn',
    'Tauberrettersheim',
    'Unterbaldingen',
    'Malborn',
    'Speicher',
    'Oberbillig',
    'Mechenried',
    'Burkardroth',
    'Schlusselfeld',
    'Grafenrheinfeld',
    'Heppdiel',
    'Hofheim',
    'Possenheim',
    'Heinrichsthal',
    'Sand',
    'Neukirchen',
    'Ulrichstein',
    'Kandel',
    'Schmiechen',
    'Berghulen',
    'Steinheim am Albuch',
    'Roigheim',
    'Dirmstein',
    'Minfeld',
    'Eichtersheim',
    'Edenkoben',
    'Lambsheim',
    'Gerlachsheim',
    'Jebenhausen',
    'Laumersheim',
    'Schonau im Schwarzwald',
    'Ruchheim',
    'Neubulach',
    'Aidlingen',
    'Leimersheim',
    'Neckargerach',
    'Hagenbach',
    'Karlshuld',
    'Brannenburg',
    'Oberpframmern',
    'Eicherloh',
    'Eitting',
    'Maikammer',
    'Maxdorf',
    'Roschbach',
    'Neuleiningen',
    'Putbus',
    'Lassentin',
    'Gagern',
    'Prohn',
    'Ostseebad Zinnowitz',
    'Wiek',
    'Obrigheim',
    'Harthausen',
    'Thum',
    'Niederdorf',
    'Neudorf',
    'Sehma',
    'Gersdorf',
    'Erda',
    'Annerod',
    'Rauschenberg',
    'Fronhausen',
    'Eschau',
    'Roden',
    'Monchberg',
    'Rentweinsdorf',
    'Beuern',
    'Herschbach',
    'Allendorf',
    'Altendiez',
    'Hausen',
    'Rummingen',
    'Oberried',
    'Lehmen',
    'Melsbach',
    'Mayen',
    'Gieleroth',
    'Willmenrod',
    'Steinen',
    'Uxheim',
    'Werlau',
    'Kelberg',
    'Oberraden',
    'Bad Gottleuba',
    'Bodenheim',
    'Haide',
    'Neusitz',
    'Ohorn',
    'Hirschfelde',
    'Oberseifersdorf',
    'Dittelsdorf',
    'Spitzkunnersdorf',
    'Grossschonau',
    'Burgsponheim',
    'Schmittweiler',
    'Monzingen',
    'Lauschied',
    'Lindewitt',
    'Goldelund',
    'Suderlugum',
    'Humptrup',
    'Langenweddingen',
    'Oebisfelde',
    'Dardesheim',
    'Stapelburg',
    'Colbitz',
    'Harzgerode',
    'Pfaffenhausen',
    'Uedem',
    'Kagsdorf',
    'Pfaffenhofen',
    'Schneizlreuth',
    'Fridolfing',
    'Hohenbergham',
    'Reichelsheim',
    'Echzell',
    'Lauta',
    'Bad Schandau',
    'Arnsdorf',
    'Klingenberg',
    'Hegge',
    'Haldenwang',
    'Burgberg',
    'Obergunzburg',
    'Harsleben',
    'Wilsdruff',
    'Langenwolmsdorf',
    'Geising',
    'Aukrug',
    'Norderstapel',
    'Hohenlockstedt',
    'Schlettau',
    'Wechselburg',
    'Mildenau',
    'Jahnsdorf',
    'Reinsdorf',
    'Syrau',
    'Lichtenau',
    'Leubsdorf',
    'Dahlenburg',
    'Herrnhut',
    'Doberschau',
    'Wilthen',
    'Mittelherwigsdorf',
    'Bernstadt',
    'Cunewalde',
    'Eibau',
    'Rennersdorf',
    'Obergurig',
    'Holdorf',
    'Unterwossen',
    'Perach',
    'Bergen',
    'Seebruck',
    'Breitbrunn am Chiemsee',
    'Feilitzsch',
    'Neuenmarkt',
    'Trostau',
    'Sandesneben',
    'Wakendorf',
    'Zarpen',
    'Breitengussbach',
    'Thalmassing',
    'Gremsdorf',
    'Neustadt an der Orla',
    'Konigsee',
    'Niederpollnitz',
    'Lehndorf',
    'Salzwedel',
    'Gatersleben',
    'Jerichow',
    'Buden',
    'Luftkurort Arendsee',
    'Neenstetten',
    'Gerbstedt',
    'Dieskau',
    'Aken',
    'Baasdorf',
    'Weissandt-Golzau',
    'Kretzschau',
    'Bobenheim am Berg',
    'Ilbesheim',
    'Neckarbischofsheim',
    'Kirchardt',
    'Lingenfeld',
    'Bad Bergzabern',
    'Wettin',
    'Milzau',
    'Zschornewitz',
    'Rosslau',
    'Dederstedt',
    'Horstdorf',
    'Grafenhainichen',
    'Frose',
    'Helbra',
    'Nachterstedt',
    'Droyssig',
    'Konnern',
    'Lieskau',
    'Langendorf',
    'Niemberg',
    'Seeburg',
    'Helmershausen',
    'Altengottern',
    'Herbsleben',
    'Niederspier',
    'Grossengottern',
    'Georgenthal',
    'Vesser',
    'Bad Frankenhausen',
    'Bleicherode',
    'Bad Tennstedt',
    'Geraberg',
    'Schmiedefeld am Rennsteig',
    'Uder',
    'Laucha',
    'Sollstedt',
    'Greussen',
    'Thalwenden',
    'Wachenroth',
    'Gebesee',
    'Bosleben-Wullersleben',
    'Oberdorla',
    'Hildburghausen',
    'Buttstedt',
    'Wechmar',
    'Elxleben',
    'Niederdorla',
    'Horselgau',
    'Stotternheim',
    'Noda',
    'Kaltenwestheim',
    'Geschwenda',
    'Buch',
    'Essenheim',
    'Waldheim',
    'Grethen',
    'Krostitz',
    'Regis-Breitingen',
    'Mochau',
    'Dommitzsch',
    'Lauta',
    'Heide',
    'Straupitz',
    'Burg',
    'Altdobern',
    'Spremberg',
    'Guben',
    'Schonewalde',
    'Neupetershain',
    'Hellstein',
    'Stegen',
    'Grafenhausen',
    'Wutoschingen',
    'Waldshut',
    'Istein',
    'Feldberg-Ort',
    'Cappel',
    'Gross-Rohrheim',
    'Otzing',
    'Wald',
    'Langquaid',
    'Laer',
    'Birnbach',
    'Heringsdorf',
    'Jarmen',
    'Karlshagen',
    'Gramzow',
    'Mirow',
    'Mallin',
    'Grambow',
    'Saal',
    'Grossenluder',
    'Altengronau',
    'Banteln',
    'Vehlow',
    'Walsleben',
    'Zerf',
    'Dienstweiler',
    'Waxweiler',
    'Dabergotz',
    'Flecken Zechlin',
    'Dreetz',
    'Hohendodeleben',
    'Zerben',
    'Hundisburg',
    'Derben',
    'Egeln',
    'Parey',
    'Eggersdorf',
    'Dedeleben',
    'Bruck',
    'Betzenstein',
    'Mistelgau',
    'Glien',
    'Oberirsen',
    'Friedewald',
    'Wolferlingen',
    'Lonnig',
    'Streithausen',
    'Fachbach',
    'Herdorf',
    'Muschenbach',
    'Halbs',
    'Schonwalde',
    'Beim Kronsberg',
    'Haus',
    'Ankum',
    'Icker',
    'Lathen',
    'Goldenstedt',
    'Lastrup',
    'Toppenstedt',
    'Kuhstedt',
    'Siedenburg',
    'Wischhafen',
    'Osten',
    'Storkow',
    'Rechtenfleth',
    'Elsfleth',
    'Butjadingen',
    'Ohrensen',
    'Elstorf',
    'Hamwarde',
    'Ottersberg',
    'Brieselang',
    'Herzberg',
    'Bad Windsheim',
    'Lychen',
    'Gromitz',
    'Bergen auf Ruegen',
    'Lynow',
    'Dallgow-Doeberitz',
    'Alt Golm',
    'Tutzpatz',
    'Krugsdorf',
    'Paradies',
    'Walheim',
    'Frittlingen',
    'Nusplingen',
    'Kusterdingen',
    'Entringen',
    'Ruppertshofen',
    'Deilingen',
    'Riegel',
    'Silling',
    'Karsbach',
    'Bogenhausen',
    'Gadegast',
    'Blumenthal',
    'Bornstein',
    'Marienburg',
    'Fockbek',
    'Boklund',
    'Tarp',
    'Borgdorf-Seedorf',
    'Wasbek',
    'Fleckeby',
    'Owschlag',
    'Rickert',
    'Padenstedt',
    'Nubbel',
    'Kirchhellen',
    'Neuendettelsau',
    'Stulln',
    'Geithain',
    'Tettau',
    'Konigsfeld',
    'Etterschlag',
    'Spatzenhausen',
    'Leezen',
    'Nakensdorf',
    'Cramonshagen',
    'Domsuhl',
    'Retgendorf',
    'Witzin',
    'Bornsen',
    'Trent',
    'Rambin',
    'Verchen',
    'Neuenkirchen',
    'Grimmen',
    'Neuenkirchen',
    'Dipbach',
    'Benshausen',
    'Weissensee',
    'Bregenstedt',
    'Barby',
    'Dahlen',
    'Mugeln',
    'Ursensollen',
    'Freudenberg',
    'Claussnitz',
    'Klutz',
    'Strohkirchen',
    'Lauenbruck',
    'Wirges',
    'Gershasen',
    'Luckenbach',
    'Burgbrohl',
    'Idenheim',
    'Werlte',
    'Lindern',
    'Oberthulba',
    'Weissenthurm',
    'Ohrdruf',
    'Kranichfeld',
    'Burghaun',
    'Ainring',
    'Lehesten',
    'Gefell',
    'Lonnerstadt',
    'Ellingen',
    'Wellheim',
    'Vahlbruch',
    'Obernfeld',
    'Ostseebad Binz',
    'Wolgast',
    'Erzenhausen',
    'Busenberg',
    'Leimen',
    'Lauterecken',
    'Linden',
    'Bernterode',
    'Schwallungen',
    'Munchhausen',
    'Battenberg',
    'Kirberg',
    'Ballersbach',
    'Teuchern',
    'Jestetten',
    'Bollschweil',
    'Linsburg',
    'Westfeld',
    'Werlaburgdorf',
    'Treuen',
    'Gomadingen',
    'Winzer',
    'Weiler',
    'Bromskirchen',
    'Sickte',
    'Fallersleben',
    'Dannenberg',
    'Sachsenkam',
    'Fischbachau',
    'Neubeuern',
    'Obermoschel',
    'Hirschhorn',
    'Grosskarolinenfeld',
    'Buggingen',
    'Quaal',
    'Pohnstorf',
    'Heilsbronn',
    'Pommelsbrunn',
    'Velburg',
    'Breitenberg',
    'Strohn',
    'Hobeck',
    'Atting',
    'Traitsching',
    'Hohenlimburg',
    'Bellheim',
    'Hochstadten',
    'Wolfmannshausen',
    'Steisslingen',
    'Elsterberg',
    'Kranzberg',
    'Oberaudorf',
    'Sosa',
    'Bruchhausen-Vilsen',
    'Ahausen',
    'Boddenstedt',
    'Bankewitz',
    'Muhl Rosin',
    'Alling',
    'Hofen',
    'Egenhofen',
    'Sotterhausen',
    'Merzalben',
    'Schmalenberg',
    'Bunsoh',
    'Schmalensee',
    'Hundsdorf',
    'Grossmaischeid',
    'Brey',
    'Pesterwitz',
    'Arnbruck',
    'Bayrischzell',
    'Haag an der Amper',
    'Hasselbach',
    'Riede',
    'Hamdorf',
    'Aurach',
    'Domitz',
    'Dreveskirchen',
    'Oelerse',
    'Regen',
    'Walderbach',
    'Morslingen',
    'Munzingen',
    'Grossaitingen',
    'Landensberg',
    'Rogling',
    'Elbingerode',
    'Benneckenstein',
    'Friedrichswerth',
    'Hasselfelde',
    'Nandlstadt',
    'Dingolshausen',
    'Nieder-Beerbach',
    'Gundernhausen',
    'Erlbach',
    'Eschelbronn',
    'Hergisdorf',
    'Hengersberg',
    'Runding',
    'Kollnburg',
    'Lam',
    'Waldsee',
    'Birkenheide',
    'Frankenstein',
    'Rohrbach',
    'Wachenheim an der Weinstrasse',
    'Carlsberg',
    'Hochdorf-Assenheim',
    'Heudeber',
    'Engelthal',
    'Hartenstein',
    'Hausen',
    'Simmelsdorf',
    'Rottenstuben',
    'Offenhausen',
    'Alfeld',
    'Adelsdorf',
    'Hessdorf',
    'Vestenbergsgreuth',
    'Oberhundem',
    'Hohe',
    'Wallertheim',
    'Horrweiler',
    'Friesenheim',
    'Alsheim',
    'Selzen',
    'Horperath',
    'Bretthausen',
    'Marienhausen',
    'Schuld',
    'Pracht',
    'Horressen',
    'Moosinning',
    'Bayersoien',
    'Scheuring',
    'Buch',
    'Steingaden',
    'Griesstatt',
    'Spiesen-Elversberg',
    'Mitterfels',
    'Sankt Englmar',
    'Bad Karlshafen',
    'Soller',
    'Hastenrath',
    'Niederdorf',
    'Bofingen',
    'Hoym',
    'Hettstedt',
    'Neutz-Lettewitz',
    'Neustadt',
    'Altleiningen',
    'Schweigen-Rechtenbach',
    'Prestewitz',
    'Leuthen',
    'Elsterwerda',
    'Otterbach',
    'Ruppertsweiler',
    'Kleinbundenbach',
    'Dellfeld',
    'Guntersblum',
    'Gundersheim',
    'Hochborn',
    'Walluf',
    'Meisenheim',
    'Eimsheim',
    'Laudert',
    'Kisselbach',
    'Bockenau',
    'Fischerhude',
    'Thedinghausen',
    'Fernthal',
    'Oberzissen',
    'Urbar',
    'Steineroth',
    'Lahrbach',
    'Jembke',
    'Wesendorf',
    'Beierstedt',
    'Lommatzsch',
    'Weissenborn',
    'Stromberg',
    'Hatzfeld',
    'Eppenrod',
    'Thomasburg',
    'Schnackenburg',
    'Gohrde',
    'Soltendieck',
    'Schirgiswalde',
    'Waltersdorf',
    'Crostau',
    'Demitz-Thumitz',
    'Hainewalde',
    'Neusalza-Spremberg',
    'Neukirch/Lausitz',
    'Kubschutz',
    'Barenstein',
    'Reinsberg',
    'Oelsnitz',
    'Thermalbad Wiesenbad',
    'Herbertingen',
    'Dirlewang',
    'Hinterhermsdorf',
    'Neumarkt in der Oberpfalz',
    'Dorndorf',
    'Oberweissbach',
    'Effelder',
    'Obergebra',
    'Aspach',
    'Tiefenort',
    'Hohenleuben',
    'Berga',
    'Teichwolframsdorf',
    'Spora',
    'Solkwitz',
    'Dobra',
    'Gossersweiler-Stein',
    'Au am Rhein',
    'Sternberg',
    'Spielberg',
    'Treben',
    'Seeland',
    'Holzweissig',
    'Sandersdorf',
    'Wallhausen',
    'Zwenkau',
    'Kriebstein',
    'Gruna',
    'Belgershain',
    'Altenhasslau',
    'Salm',
    'Garz',
    'Wenddorf',
    'Kladen',
    'Jersleben',
    'Schwarzholz',
    'Gerwisch',
    'Eichstedt',
    'Eichenbarleben',
    'Apenburg',
    'Niederndodeleben',
    'Seehausen',
    'Lenzkirch',
    'Elzach',
    'Heimertingen',
    'Legau',
    'Babenhausen',
    'Erkheim',
    'Fischen',
    'Ottersheim',
    'Lengdorf',
    'Altfraunhofen',
    'Volkenschwand',
    'Sankt Wolfgang',
    'Inning am Holz',
    'Gunthersleben',
    'Wumbach',
    'Wiehe',
    'Rauenstein',
    'Rottach',
    'Kaufering',
    'Rohrdorf',
    'Ettenstatt',
    'Kunreuth',
    'Oberscheinfeld',
    'Munchsteinach',
    'Hiltrup',
    'Ladbergen',
    'Wettringen',
    'Untersiemau',
    'Nennslingen',
    'Ebensfeld',
    'Wettelsheim',
    'Obernzenn',
    'Dorfprozelten',
    'Schneppenbach',
    'Oerlenbach',
    'Falls',
    'Weilbach',
    'Billingshausen',
    'Ermershausen',
    'Erdweg',
    'Neuburg',
    'Bubesheim',
    'Rofingen',
    'Biberbach',
    'Todtnauberg',
    'Hochenschwand',
    'Bad Bellingen',
    'Eichstetten',
    'Todtnau',
    'Gutach im Breisgau',
    'Bamlach',
    'Morscheid',
    'Nittel',
    'Lissendorf',
    'Bous',
    'Rodenbach',
    'Mackenbach',
    'Rutsweiler an der Lauter',
    'Schwedelbach',
    'Gehrweiler',
    'Steinwenden',
    'Obermohr',
    'Herschberg',
    'Niederwuerschnitz',
    'Geyer',
    'Wachtum',
    'Brockum',
    'Ellrich',
    'Schnaitsee',
    'Jesendorf',
    'Marzling',
    'Freimersheim',
    'Mauer',
    'Schonau',
    'Sasbach',
    'Sasbachwalden',
    'Tiddische',
    'Lahm',
    'Oberleichtersbach',
    'Erlenbach bei Marktheidenfeld',
    'Kolbingen',
    'Scheer',
    'Marksuhl',
    'Stutzerbach',
    'Ohlstadt',
    'Burgheim',
    'Elm',
    'Donsieders',
    'Schmelz',
    'Uberherrn',
    'Walhausen',
    'Wiedergeltingen',
    'Ruderatshofen',
    'Auenstein',
    'Weissbach',
    'Michelbach an der Bilz',
    'Wimsheim',
    'Ottrau',
    'Wallhausen',
    'Schwaigern',
    'Baindt',
    'Gutenzell-Hurbel',
    'Deckenpfronn',
    'Bempflingen',
    'Bahrdorf',
    'Oldenburg in Holstein',
    'Reutlingendorf',
    'Oberdischingen',
    'Ertingen',
    'Schaulings',
    'Neuravensburg',
    'Bortlingen',
    'Ersingen',
    'Bellamont',
    'Veltheim',
    'Sohlde',
    'Ramsla',
    'Oldisleben',
    'Wustheuterode',
    'Frankenheim',
    'Kaltennordheim',
    'Grosswechsungen',
    'Sattelstadt',
    'Dermbach',
    'Empfertshausen',
    'Lengerich',
    'Geeste',
    'Obristfeld',
    'Oberhaid',
    'Wassermungenau',
    'Schesslitz',
    'Pappenheim',
    'Scheinfeld',
    'Michelau',
    'Marktgraitz',
    'Ebersdorf',
    'Bockelwitz',
    'Alterhofen',
    'Mamming',
    'Kunzing',
    'Baltmannsweiler',
    'Bedburdyck',
    'Gladbach',
    'Hohn',
    'Eddelak',
    'Herforst',
    'Langweiler',
    'Nattenheim',
    'Bollendorf',
    'Welschbillig',
    'Karlshausen',
    'Piesport',
    'Breit',
    'Wiltingen',
    'Hetzerath',
    'Metterich',
    'Krov',
    'Wistedt',
    'Geversdorf',
    'Handeloh',
    'Arlaching',
    'Reischach',
    'Aindorf',
    'Halbe',
    'Senzig',
    'Gersfeld',
    'Hochkirch',
    'Gelting',
    'Bockhorn',
    'Kloster',
    'Ferchesar',
    'Ketzin',
    'Heinsdorf',
    'Ermke',
    'Strucklingen',
    'Gross Koris',
    'Oberkirchen',
    'Plech',
    'Schonsee',
    'Marktschorgast',
    'Kupferberg',
    'Lubstorf',
    'Passow',
    'Dabel',
    'Carlow',
    'Pinnow',
    'Aumuhle',
    'Wusterhusen',
    'Kropelin',
    'Ostseebad Prerow',
    'Ziesendorf',
    'Gelbensande',
    'Breesen',
    'Rhumspringe',
    'Muldenstein',
    'Farnroda',
    'Breitenbach',
    'Eisfeld',
    'Willmersdorf',
    'Schwansee',
    'Stepfershausen',
    'Wilhelmsthal',
    'Altstadt',
    'Weissen',
    'Spiegelsberge',
    'Rosengarten',
    'Hofgarten',
    'Deutsches Haus',
    'Tonbach',
    'Diana',
    'Dunnwald',
    'Ernst',
    'Linde',
    'Haverkamp',
    'Bad Godesberg',
    'Lausitz',
    'Oberwinter',
    'Wittekindshof',
    'Lehen',
    'Bredeney',
    'Allee',
    'Konigsdorf',
    'Schaumburg',
    'Rodinghausen',
    'Eimeldingen',
    'Nesselwang',
    'Sussen',
    'Mittenwald',
    'Mammendorf',
    'Wustenriet',
    'Rudersdorf',
    'Munchsmunster',
    'Traunwalchen',
    'Weichs',
    'Wangen',
    'Wasserlosen',
    'Hohenberg',
    'Rosenthal',
    'Ellerbek',
    'Velpke',
    'Rosendahl',
    'Hohenhameln',
    'Oppurg',
    'Pauscha',
    'Lalendorf',
    'Rastow',
    'Funfeichen',
    'Harra',
    'Grossharthau',
    'Rauda',
    'Plau am See',
    'Goldewin',
    'Neuhausel',
    'Weiden',
    'Kirchsahr',
    'Altenahr',
    'Gross Twulpstedt',
    'Weiden',
    'Altheim',
    'Unterreit',
    'Buch am Erlbach',
    'Saldenburg',
    'Innernzell',
    'Thannberg',
    'Ruckeroth',
    'Breitenau',
    'Vielbach',
    'Nordhofen',
    'Rentrisch',
    'Otterstadt',
    'Polch',
    'Monreal',
    'Wackernheim',
    'Marnheim',
    'Freirachdorf',
    'Oberthal',
    'Hanhofen',
    'Wittgert',
    'Stadecken-Elsheim',
    'Fusternberg',
    'Holthausen',
    'Niederau',
    'Roxel',
    'Konigsbrunn',
    'Holzheim',
    'Ruckersdorf',
    'Langwasser',
    'Hurlach',
    'Aislingen',
    'Oy-Mittelberg',
    'Vier',
    'Uckendorf',
    'Sindorf',
    'Illschwang',
    'Wartenberg-Rohrbach',
    'Womrath',
    'Wallhausen',
    'Blatzheim',
    'Sachsen',
    'Eggersdorf',
    'Venne',
    'Adlum',
    'Heiningen',
    'Otze',
    'Stadt',
    'Gerichshain',
    'Weede',
    'Ellerdorf',
    'Merzen',
    'Heiligenstedtenerkamp',
    'Nienborstel',
    'Oersdorf',
    'Klein Nordende',
    'Huje',
    'Nindorf',
    'Niederfrohna',
    'Pfaffenweiler',
    'Nienstadt',
    'Deutzen',
    'Naunhof',
    'Ranis',
    'Rieder',
    'Welbsleben',
    'Abtsbessingen',
    'Bad Kosen',
    'Altbelgern',
    'Klostermansfeld',
    'Gangloff',
    'Lauscha',
    'Schwarzhausen',
    'Niederau',
    'Moosthenning',
    'Julbach',
    'Wormstedt',
    'Artern',
    'Kirchworbis',
    'Ichtershausen',
    'Heldrungen',
    'Kleinfurra',
    'Schalkau',
    'Niederorschel',
    'Seebergen',
    'Steinach',
    'Sinzing',
    'Hohenfels',
    'Malgersdorf',
    'Hahnbach',
    'Weyhausen',
    'Stoetze',
    'Wasbuttel',
    'Gross Oesingen',
    'Emmendorf',
    'Mariental',
    'Osingen',
    'Lessien',
    'Nahrendorf',
    'Messstetten',
    'Heinade',
    'Eichenberg',
    'Willensen',
    'Morenhoven',
    'Heinkenborstel',
    'Habersdorf',
    'Gschwend',
    'Lindwedel',
    'Betheln',
    'Helpsen',
    'Almstedt',
    'Beedenbostel',
    'Sibbesse',
    'Furholzen',
    'Dingelstedt',
    'Groeningen',
    'Luttgenrode',
    'Mockern',
    'Ditfurt',
    'Ballenstedt',
    'Schopsdorf',
    'Harmstorf',
    'Seester',
    'Sorup',
    'Niesgrau',
    'Mohrkirch',
    'Wrixum',
    'Wittdun',
    'Gross Rheide',
    'Pommerby',
    'Linden',
    'Friedrichskoog',
    'Damp',
    'Barkenholm',
    'Bockhorst',
    'Neermoor',
    'Furth im Wald',
    'Borne',
    'Eresing',
    'Lengenfeld',
    'Bad Kohlgrub',
    'Rennertshofen',
    'Schwindegg',
    'Au',
    'Todtenweis',
    'Wechingen',
    'Asbach-Baumenheim',
    'Hilgertshausen-Tandern',
    'Marktschellenberg',
    'Gmain bei Weidach',
    'Schonberg',
    'Weissenohe',
    'Vorra',
    'Bredenbek',
    'Kaaks',
    'Wilhelm',
    'Thaden',
    'Bendfeld',
    'Wendtorf',
    'Puls',
    'Oldenburg',
    'Damlos',
    'Waischenfeld',
    'Hiltpoltstein',
    'Buchbach',
    'Furstenberg',
    'Langsur',
    'Koxhausen',
    'Strickscheid',
    'Birkenfeld',
    'Ochtendung',
    'Ruschberg',
    'Herborn',
    'Rinzenberg',
    'Bispingen',
    'Pollhagen',
    'Machern',
    'Posing',
    'Kofering',
    'Altenthann',
    'Gartow',
    'Jameln',
    'Weisenheim am Sand',
    'Limbach',
    'Wilhermsdorf',
    'Sugenheim',
    'Oberkail',
    'Bescheid',
    'Kempfeld',
    'Osann-Monzel',
    'Neumagen-Dhron',
    'Naurath',
    'Graach',
    'Junkerath',
    'Veldenz',
    'Oberstadtfeld',
    'Schwollen',
    'Freudenburg',
    'Longkamp',
    'Bekond',
    'Horath',
    'Alsdorf',
    'Kell',
    'Reuth',
    'Brietlingen',
    'Appel',
    'Neuendorf',
    'Bad Kleinen',
    'Neukloster',
    'Grabow',
    'Sternberg',
    'Oberhof',
    'Gustavel',
    'Herren Steinfeld',
    'Uelitz',
    'Birkenfeld',
    'Wonfurt',
    'Winterhausen',
    'Sulzthal',
    'Marktbreit',
    'Westheim',
    'Weidhausen',
    'Oberneuses',
    'Mitteleschenbach',
    'Emskirchen',
    'Buchberg',
    'Theilenhofen',
    'Sengenthal',
    'Tiefenbronn',
    'Vestenberg',
    'Alesheim',
    'Wassertrudingen',
    'Pollenfeld',
    'Rothenbach',
    'Karweiler',
    'Ahrbruck',
    'Weitersburg',
    'Bad Ems',
    'Insul',
    'Adenau',
    'Bonefeld',
    'Brucktal',
    'Waldbreitbach',
    'Westerhorn',
    'Haseldorf',
    'Leimbach',
    'Hartenfels',
    'Sondheim',
    'Willmars',
    'Weihenzell',
    'Dietfurt',
    'Wittelshofen',
    'Burghaslach',
    'Denkendorf',
    'Zapfendorf',
    'Belecke',
    'Wittighausen',
    'Abtswind',
    'Schneeberg',
    'Sulzfeld',
    'Untergimpern',
    'Knoringen',
    'Fahrenbach',
    'Oberschefflenz',
    'Lehrensteinsfeld',
    'Rot am See',
    'Adelmannsfelden',
    'Ellenberg',
    'Spiegelberg',
    'Unterreichenbach',
    'Zaisenhausen',
    'Reute',
    'Trebendorf',
    'Laubusch',
    'Gehofen',
    'Niendorf',
    'Roseburg',
    'Haffkrug',
    'Oberweimar',
    'Oberndorf',
    'Wolkenstein',
    'Weyerbusch',
    'Harschbach',
    'Elkenroth',
    'Niederwambach',
    'Waldorf',
    'Katzwinkel',
    'Uess',
    'Treis-Karden',
    'Eichen',
    'Hillscheid',
    'Uersfeld',
    'Nickenich',
    'Dietlingen',
    'Radegast',
    'Kuhndorf',
    'Kleinfahner',
    'Dachwig',
    'Gierstadt',
    'Badra',
    'Woffleben',
    'Schnellmannshausen',
    'Wiesenfeld',
    'Kefferhausen',
    'Rohr',
    'Halle Neustadt',
    'Grosslobichau',
    'Vollersroda',
    'Buchfart',
    'Pohl',
    'Mittelbach',
    'Hormersdorf',
    'Annweiler am Trifels',
    'Friedelsheim',
    'Bischheim',
    'Freinsheim',
    'Albisheim',
    'Salgen',
    'Nieder Ohmen',
    'Boxberg',
    'Himbergen',
    'Rummer',
    'Weste',
    'Grafhorst',
    'Danndorf',
    'Barum',
    'Mitterstetten',
    'Pornbach',
    'Elsendorf',
    'Hergolding',
    'Kumhausen',
    'Treia',
    'Sieverstedt',
    'Fahrdorf',
    'Jorl',
    'Lurschau',
    'Grosssolt',
    'Karlum',
    'Klein Wittensee',
    'Langballig',
    'Schlotheim',
    'Mattstedt',
    'Themar',
    'Sundhausen',
    'Possendorf',
    'Furthen',
    'Bannberscheid',
    'Winningen',
    'Mannebach',
    'Mogendorf',
    'Weibern',
    'Fehl-Ritzhausen',
    'Welkenbach',
    'Niederalsen',
    'Marienrachdorf',
    'Vohringen',
    'Warza',
    'Neuhaus-Schierschnitz',
    'Reinstadt',
    'Trusetal',
    'Neudietendorf',
    'Kirchheim',
    'Goritz',
    'Veilsdorf',
    'Ingersleben',
    'Seck',
    'Thur',
    'Weitefeld',
    'Ebernhahn',
    'Dreisbach',
    'Meinsdorf',
    'Kleinbeeren',
    'Diedorf',
    'Albrechts',
    'Othenstorf',
    'Altenkrempe',
    'Malente',
    'Klein Ronnau',
    'Talkau',
    'Hartenholm',
    'Politz',
    'Merzhausen',
    'Schwarzwald',
    'March',
    'Neu Zauche',
    'Schacksdorf',
    'Kasel-Golzig',
    'Drebkau',
    'Axstedt',
    'Bohlen',
    'Schildau',
    'Hattenhofen',
    'Vilsheim',
    'Niederviehbach',
    'Adlkofen',
    'Weihmichl',
    'Neufraunhofen',
    'Trebsen',
    'Bucha',
    'Doberschutz',
    'Rockenberg',
    'Munzenberg',
    'Schwarzach',
    'Tettenwang',
    'Bartow',
    'Medow',
    'Klein Kedingshagen',
    'Ernsthof',
    'Patzig',
    'Vitte',
    'Gross Miltzow',
    'Murchin',
    'Meesiger',
    'Samtens',
    'Sellin',
    'Kluis',
    'Ostseebad Baabe',
    'Hiddensee',
    'Wackerow',
    'Aspisheim',
    'Oelsberg',
    'Beltheim',
    'Schnellbach',
    'Barstadt',
    'Stauchitz',
    'Panschwitz-Kuckau',
    'Groditz',
    'Hirschstein',
    'Hof',
    'Muhlhausen',
    'Ecklingerode',
    'Seega',
    'Bendeleben',
    'Isserstedt',
    'Tonndorf',
    'Bienstadt',
    'Bleckenrode',
    'Neundorf',
    'Mengersgereuth-Hammern',
    'Hauteroda',
    'Wolfis',
    'Grobzig',
    'Wansleben',
    'Bodelshausen',
    'Bermatingen',
    'Merkers',
    'Westhausen',
    'Viernau',
    'Springstille',
    'Seebach',
    'Rotterode',
    'Heyerode',
    'Hetschburg',
    'Hildebrandshausen',
    'Ammern',
    'Elgersburg',
    'Wohnrod',
    'Heidenau',
    'Hilgermissen',
    'Luttum',
    'Wurmannsquick',
    'Rickenbach',
    'Erfelden',
    'Heiligenhagen',
    'Pruchten',
    'Wittichenau',
    'Rietschen',
    'Tettau',
    'Ortrand',
    'Loch',
    'Reichenbach-Steegen',
    'Becherbach',
    'Schneckenhausen',
    'Ransweiler',
    'Reipoltskirchen',
    'Kindsbach',
    'Hettenrodt',
    'Weidenbach',
    'Niederstadtfeld',
    'Wilferdingen',
    'Schmidgaden',
    'Prackenbach',
    'Alteglofsheim',
    'Hardt',
    'Eutingen',
    'Hermaringen',
    'Nehren',
    'Renquishausen',
    'Unterroth',
    'Heimsheim',
    'Knittlingen',
    'Johstadt',
    'Gechingen',
    'Ramsen',
    'Reichartshausen',
    'Neckarzimmern',
    'Neidenstein',
    'Opfingen',
    'Rechtenstein',
    'Durmentingen',
    'Alleshausen',
    'Ebnat',
    'Steinhausen',
    'Lauterburg',
    'Niederbreitbach',
    'Braunlingen',
    'Rappenau',
    'Frankweiler',
    'Erpolzheim',
    'Botersen',
    'Michelfeld',
    'Emtinghausen',
    'Sandbostel',
    'Schonenberg-Kubelberg',
    'Bad Peterstal-Griesbach',
    'Wittgendorf',
    'Steinigtwolmsdorf',
    'Blievenstorf',
    'Lamspringe',
    'Bad Eilsen',
    'Salzstetten',
    'Stetten',
    'Pastetten',
    'Baierbrunn',
    'Ilmmunster',
    'Jemgum',
    'Terborg',
    'Rechtsupweg',
    'Rhede',
    'Esterwegen',
    'Osterbrock',
    'Emsburen',
    'Graveneck',
    'Frankenau',
    'Linden',
    'Bischoffen',
    'Lahr',
    'Ebeleben',
    'Wildbergerhutte',
    'Weddel',
    'Veltheim',
    'Wriedel',
    'Gerdau',
    'Ribbesbuttel',
    'Vogtareuth',
    'Einsbach',
    'Samerberg',
    'Golzow',
    'Wusterwitz',
    'Kiez',
    'Raben',
    'Wenzlow',
    'Niemegk',
    'Milow',
    'Hausen am Tann',
    'Barenthal',
    'Boich',
    'Mutzenich',
    'Osterhofen',
    'Salzweg',
    'Eggebek',
    'Langenhorn',
    'Gammellund',
    'Ulsnis',
    'Bordelum',
    'Klanxbull',
    'Fargau-Pratjau',
    'Delve',
    'Landrecht',
    'Dietersburg',
    'Hohenthann',
    'Greussenheim',
    'Margetshochheim',
    'Euerbach',
    'Vluyn',
    'Suderau',
    'Volkersweiler',
    'Esthal',
    'Bockenheim',
    'Kirchtimke',
    'Neverin',
    'Driftsethe',
    'Wremen',
    'Ueterlande',
    'Bramstedt',
    'Midlum',
    'Mulsum',
    'Tapfheim',
    'Obermeitingen',
    'Ahrensmoor',
    'Albersdorf',
    'Martensrade',
    'Riepsdorf',
    'Selent',
    'Kellenhusen',
    'Loose',
    'Gross Wittensee',
    'Nordhastedt',
    'Wester-Ohrstedt',
    'Sehlen',
    'Tating',
    'Breiholz',
    'Susel',
    'Wesseln',
    'Krumstedt',
    'Satjendorf',
    'Barkelsby',
    'Lohe-Rickelshof',
    'Otterndorf',
    'Belum',
    'Schopfloch',
    'Glatten',
    'Strassberg',
    'Bischofsgrun',
    'Schlammersdorf',
    'Barnau',
    'Glashutten',
    'Fichtenwalde',
    'Ribbeck',
    'Babow',
    'Brieske',
    'Hennweiler',
    'Flacht',
    'Birlenbach',
    'Bergweiler',
    'Dausenau',
    'Liebenscheid',
    'Bach',
    'Hattert',
    'Wieselbach',
    'Lochum',
    'Hochstenbach',
    'Sankt Katharinen',
    'Niederburg',
    'Erpel',
    'Dattenberg',
    'Wincheringen',
    'Riol',
    'Hof',
    'Hohn',
    'Norken',
    'Hellenhahn-Schellenberg',
    'Gutenacker',
    'Forst',
    'Steinmauern',
    'Oberriexingen',
    'Rohrdorf',
    'Borstel',
    'Beckeln',
    'Jeddeloh Eins',
    'Marxen',
    'Tangendorf',
    'Bargstedt',
    'Wilstedt',
    'Elmlohe',
    'Gyhum',
    'Nortmoor',
    'Augustfehn',
    'Molbergen',
    'Neukamperfehn',
    'Westrhauderfehn',
    'Filsum',
    'Heinbockel',
    'Horneburg',
    'Hepstedt',
    'Heeslingen',
    'Lorup',
    'Hasel',
    'Breitenfelde',
    'Nusse',
    'Schiphorst',
    'Labenz',
    'Klein Wesenberg',
    'Bliesdorf',
    'Schulendorf',
    'Kastorf',
    'Duchelsdorf',
    'Lasbek',
    'Kirchheim am Ries',
    'Dischingen',
    'Eging',
    'Hilgert',
    'Helmern',
    'Markt',
    'Norddeich',
    'Juliusburg',
    'Wilster',
    'Schenefeld',
    'Brande-Hornerkirchen',
    'Tharandt',
    'Netzschkau',
    'Marwitz',
    'Arft',
    'Pomster',
    'Herresbach',
    'Ebertsheim',
    'Niederding',
    'Neustadt',
    'Schloss',
    'Weidenhof',
    'Karin',
    'Kreuz',
    'Vegesack',
    'Beaumarais',
    'Klostersee',
    'Ruit',
    'Balgheim',
    'Degerloch',
    'Westeregeln',
    'Plattenburg',
    'Ziegendorf',
    'Osternienburg',
    'Possendorf',
    'Kitzscher',
    'Freiwalde',
    'Dahme',
    'Lindenau',
    'Mittelsaida',
    'Ritschenhausen',
    'Buttelstedt',
    'Rettersen',
    'Blomesche Wildnis',
    'Nortorf',
    'Hollingstedt',
    'Duingen',
    'Barghorst',
    'Simmersfeld',
    'Tennenbronn',
    'Moos',
    'Ober-Erlenbach',
    'Hohenfelde',
    'Kirchweyhe',
    'Poggensee',
    'Wanne-Eickel',
    'Neuhardenberg',
    'Wettenberg',
    'Hemmelzen',
    'Lochhausen',
    'Milbertshofen',
    'Laussnitz',
    'Osede',
    'Rieste',
    'Rugheim',
    'Weil',
    'Weissenfeld',
    'Hopferau',
    'Stetten',
    'Lutzerath',
    'Filzen',
    'Oberporlitz',
    'Lindigshof',
    'Kammerforst',
    'In der Meer',
    'Giesenkirchen',
    'Sulzgries',
    'Hochdorf',
    'Altingen',
    'Barbing',
    'Scheyern',
    'Waffenbrunn',
    'Ihrlerstein',
    'Krumhermersdorf',
    'Hellweg',
    'Felm',
    'Marienfeld',
    'Hohenhorn',
    'Osdorf',
    'Holtsee',
    'Nonnenhorn',
    'Oberpleichfeld',
    'Pleisweiler-Oberhofen',
    'Anrath',
    'Wienhausen',
    'Altersbach',
    'Salmtal',
    'Wolsendorf',
    'Roetz',
    'Bad Gogging',
    'Frauenau',
    'Waldmunchen',
    'Lupburg',
    'Kleinwolmsdorf',
    'Stadt Wehlen',
    'Wisper',
    'Niederneisen',
    'Mudershausen',
    'Niederbrechen',
    'Hahnstatten',
    'Zorge',
    'Sankt Andreasberg',
    'Betzendorf',
    'Hohnstorf',
    'Prebitz',
    'Gattendorf',
    'Eschenbach',
    'Caputh',
    'Ellefeld',
    'Haaren',
    'Golssen',
    'Kraupa',
    'Hohenleipisch',
    'Schlieben',
    'Lebusa',
    'Crinitz',
    'Breddorf',
    'Puchersreuth',
    'Edelsfeld',
    'Meerbeck',
    'Zarrendorf',
    'Pantelitz',
    'Bannemin',
    'Gahlkow',
    'Molschow',
    'Greppin',
    'Schmerz',
    'Niederselters',
    'Wohra',
    'Amoneburg',
    'Eibelshausen',
    'Scherenbostel',
    'Gangkofen',
    'Bayerbach',
    'Windorf',
    'Furstenstein',
    'Niederalteich',
    'Brennberg',
    'Niederwinkling',
    'Kinsau',
    'Anzing',
    'Berzdorf',
    'Paunzhausen',
    'Hohenbocka',
    'Benningen',
    'Kraftisried',
    'Neckarwestheim',
    'Hohenfelde',
    'Wohrden',
    'Windeby',
    'Petersdorf',
    'Langwitz',
    'Mollenhagen',
    'Peetsch',
    'Schossin',
    'Fischingen',
    'Heuweiler',
    'Schackendorf',
    'Neversdorf',
    'Kukels',
    'Wittenborn',
    'Hobbersdorf',
    'Lindhorst',
    'Drubeck',
    'Derenburg',
    'Loderburg',
    'Wienrode',
    'Harmsdorf',
    'Klein-Schlamin',
    'Wotersen',
    'Bentfeld',
    'Unterammergau',
    'Wiesenbach',
    'Pickliessem',
    'Krettnach',
    'Densborn',
    'Neuschonau',
    'Lemberg',
    'Neuried',
    'Dabendorf',
    'Stocksee',
    'Grosshabersdorf',
    'Eggolsheim',
    'Oberpleis',
    'Effeltrich',
    'Poxdorf',
    'Frankisch-Crumbach',
    'Konzell',
    'Simbach',
    'Bobrach',
    'Rattenberg',
    'Geiersthal',
    'Wittenbeck',
    'Roggentin',
    'Reddelich',
    'Neu Kaliss',
    'Boldela',
    'Lubesse',
    'Katlenburg-Lindau',
    'Mielenhausen',
    'Scheden',
    'Fredelsloh',
    'Neckarrems',
    'Kleinsteinhausen',
    'Wahnwegen',
    'Niederalben',
    'Perkam',
    'Aholfing',
    'Deggenhausen',
    'Hagnau',
    'Wollbrandshausen',
    'Kirchdorf am Inn',
    'Aicha vorm Wald',
    'Schuttertal',
    'Weisenbach',
    'Fronhofen',
    'Kestert',
    'Zornheim',
    'Weitersborn',
    'Hungenroth',
    'Lipporn',
    'Wiebelsheim',
    'Ruchsen',
    'Habach',
    'Hemmersdorf',
    'Trulben',
    'Rohrbach',
    'Wustweiler',
    'Warnow',
    'Franzenheim',
    'Sellerich',
    'Dackscheid',
    'Klotten',
    'Aach',
    'Oberpierscheid',
    'Oberweis',
    'Kalenborn-Scheuern',
    'Bleialf',
    'Harburg',
    'Laugna',
    'Nußdorf (Chiemgau)',
    'Oberndorf',
    'Halvesbostel',
    'Embsen',
    'Oberstreu',
    'Neuhutten',
    'Ettringen',
    'Stotten',
    'Lamerdingen',
    'Bad Wilsnack',
    'Hohen Wangelin',
    'Vollrathsruhe',
    'Alt Schwerin',
    'Liepgarten',
    'Satzvey',
    'Tinnum',
    'Rabenkirchen-Fauluck',
    'Grundhof',
    'Meyn',
    'Medelby',
    'Lindenthal',
    'Ipsheim',
    'Wittnau',
    'Wasenweiler',
    'Bernau',
    'Zell im Wiesental',
    'Kirchheilingen',
    'Creuzburg',
    'Schwarzbach',
    'Oelzschau',
    'Wermsdorf',
    'Espenhain',
    'Kossa',
    'Otterwisch',
    'Osterberg',
    'Sontheim',
    'Bodolz',
    'Wolpinghausen',
    'Schlepzig',
    'Zerre',
    'Fichtenberg',
    'Paulinenaue',
    'Friesack',
    'Dessow',
    'Kirschau',
    'Gnaschwitz',
    'Schlegel',
    'Aldekerk',
    'Emmerzhausen',
    'Dungenheim',
    'Siershahn',
    'Oberlahr',
    'Sorth',
    'Oberfell',
    'Bell',
    'Neitersen',
    'Freisbach',
    'Binau',
    'Bornheim',
    'Mertendorf',
    'Reichardtswerben',
    'Wolferode',
    'Farnstadt',
    'Riedbach',
    'Unterschleichach',
    'Riedbach',
    'Uchtelhausen',
    'Prichsenstadt',
    'Allmendingen',
    'Tomerdingen',
    'Titting',
    'Teugn',
    'Hornburg',
    'Melchow',
    'Ivenack',
    'Stockstadt am Rhein',
    'Burgschwalbach',
    'Immenreuth',
    'Neudrossenfeld',
    'Romrod',
    'Breitenbach am Herzberg',
    'Heubach',
    'Furstenberg',
    'Sauensiek',
    'Twielenfleth',
    'Hollern',
    'Beckdorf',
    'Oring',
    'Unterhausen',
    'Oberhausen',
    'Alten',
    'Raschau',
    'Brekendorf',
    'Schwesing',
    'Gutach (Schwarzwaldbahn)',
    'Zuzenhausen',
    'Rechberg',
    'Argenthal',
    'Vendersheim',
    'Lorscheid',
    'Nachtsheim',
    'Auerbach',
    'Angern',
    'Karstadt',
    'Neukirchen',
    'Redefin',
    'Zapel',
    'Bollstein',
    'Romsthal',
    'Dibbersen',
    'Jheringsfehn',
    'Thuine',
    'Dersum',
    'Breddenberg',
    'Walchum',
    'Klein Meckelsen',
    'Habenhausen',
    'Wallendorf',
    'Huffelsheim',
    'Biesingen',
    'Feilbingert',
    'Traisen',
    'Meudt',
    'Wiesbach',
    'Armsheim',
    'Keidelheim',
    'Mutterschied',
    'Theley',
    'Bad Klosterlausnitz',
    'Bann',
    'Gries',
    'Petersberg',
    'Kerzenheim',
    'Merkelbach',
    'Rotenhain',
    'Guckheim',
    'Urexweiler',
    'Bliesmengen-Bolchen',
    'Barbelroth',
    'Obernheim-Kirchenarnbach',
    'Hahnenbach',
    'Munchweiler am Klingbach',
    'Rheinzabern',
    'Gommersheim',
    'Geinsheim',
    'Hargesheim',
    'Kaden',
    'Kerlingen',
    'Jugenheim',
    'Hackenheim',
    'Pretzfeld',
    'Arberg',
    'Flomborn',
    'Eyendorf',
    'Frommern',
    'Reimlingen',
    'Rimhorn',
    'Sereetz',
    'Schongeising',
    'Schenkenzell',
    'Gammelshausen',
    'Holzmaden',
    'Mittelrot',
    'Gurtweil',
    'Lichtenbroich',
    'Deutz',
    'Durbheim',
    'Wilhelmsdorf',
    'Koditz',
    'Berg',
    'Hetzenhausen',
    'Salching',
    'Vilshoven',
    'Altstadt',
    'Gutow',
    'Ebendorf',
    'Insel',
    'Redekin',
    'Born',
    'Boddin',
    'Dahlenwarsleben',
    'Grebbin',
    'Boden',
    'Zimmern',
    'Bothkamp',
    'Grossmonra',
    'Ostramondra',
    'Blankenhain',
    'Graitschen',
    'Kalkreuth',
    'Blankenloch',
    'Dormettingen',
    'Lauterbach/Schwarzwald',
    'Dobel',
    'Weilheim an der Teck',
    'Neidlingen',
    'Teisnach',
    'Gebenbach',
    'Schonthal',
    'Knittelsheim',
    'Platkow',
    'Falkenfels',
    'Birlinghoven',
    'Luft',
    'Seehof',
    'Dotternhausen',
    'Ottenbach',
    'Altdorf',
    'Eisenbach',
    'Ohlsbach',
    'Apfelstadt',
    'Clausthal',
    'Kottweiler-Schwanden',
    'Temmels',
    'Friemar',
    'Schonenberg',
    'Radibor',
    'Zschortau',
    'Grafenthal',
    'Wulfen',
    'Goggingen',
    'Dipperz',
    'Golm',
    'Langenbach',
    'Schadeleben',
    'Alperstedt',
    'Gossel',
    'Flurstedt',
    'Behringen',
    'Liebenstein',
    'Prittriching',
    'Hard',
    'Malsfeld',
    'Bermbach',
    'Wald',
    'Lippe',
    'Joachimsthal',
    'Oderberg',
    'Wiederau',
    'Kayna',
    'Weidenhahn',
    'Niederfell',
    'Eiselfing',
    'Mintraching',
    'Kotzting',
    'Ensdorf',
    'Heiligenthal',
    'Friedrichsort',
    'Langlingen',
    'Dorf',
    'Niedersachswerfen',
    'Einhausen',
    'Neehausen',
    'Holzengel',
    'Kasbach-Ohlenberg',
    'Henfenfeld',
    'Bobbau',
    'Muldenhammer',
    'Murg',
    'Wintersdorf',
    'Bechhofen',
    'Harlachen',
    'Rugendorf',
    'Wacken',
    'Rabke',
    'Ballstadt',
    'Wernersberg',
    'Inzmuhlen',
    'Wilhelmsheim',
    'Krolpa',
    'Wehrbleck',
    'Ellerhoop',
    'Herzogsweiler',
    'Somborn',
    'Schmidt',
    'Oelsen',
    'Petriroda',
    'Hohenroth',
    'Ramsthal',
    'Salz',
    'Mittelberg',
    'Altenmarkt',
    'Stein an der Traun',
    'Weilersbach',
    'Flintsbach',
    'Nussdorf am Inn',
    'Krun',
    'Jettenbach',
    'Reichersbeuern',
    'Trunkelsberg',
    'Hawangen',
    'Reichertshausen',
    'Mitterfelden',
    'Werpeloh',
    'Klein Berssen',
    'Fresenburg',
    'Rastdorf',
    'Lintig',
    'Keller',
    'Gulzowshof',
    'Malsburg',
    'Buborn',
    'Lollbach',
    'Herren-Sulzbach',
    'Abtweiler',
    'Beetzendorf',
    'Vorstetten',
    'Ludersdorf',
    'Dittelsheim-Hessloch',
    'Preith',
    'Jagel',
    'Hartheim',
    'Eisenbach',
    'Schuller',
    'Heilingen',
    'Neuhemsbach',
    'Sack',
    'Plan',
    'Sudheide',
    'Ludersfeld',
    'Neuengors',
    'Volxheim',
    'Sersheim',
    'Oberstadion',
    'Korchow',
    'Traubing',
    'Reichenschwand',
    'Krautheim',
    'Waldenburg',
    'Steudnitz',
    'Seelitz',
    'Kastel',
    'Lutter am Barenberge',
    'Heidekamp',
    'Trimbs',
    'Schwarzenbach am Wald',
    'Schwarzenbach',
    'Runthe',
    'Oberottmarshausen',
    'Mittelstetten',
    'Gundremmingen',
    'Schwabniederhofen',
    'Tussenhausen',
    'Dittenheim',
    'Oberlaindern',
    'Arzbach',
    'Truchtlaching',
    'Garching an der Alz',
    'Biessenhofen',
    'Mauerstetten',
    'Westheim',
    'Elfershausen',
    'Gerach',
    'Untersteinach',
    'Marktrodach',
    'Hesselbach',
    'Altenkunstadt',
    'Schney',
    'Schwebheim',
    'Ebern',
    'Dorfles',
    'Grossheirath',
    'Kirchendemenreuth',
    'Schirmitz',
    'Pirk',
    'Eisingen',
    'Heinersreuth',
    'Bellenberg',
    'Grossmehring',
    'Lautertal',
    'Wildpoldsried',
    'Marxgrun',
    'Schnee',
    'Stahlhofen',
    'Grunenthal',
    'Pressen',
    'Isenburg',
    'Vorbach',
    'Heeg',
    'Vorwerk',
    'Bellenberg',
    'Wachsenburg',
    'Rechtmehring',
    'Jagerhof',
    'Dierkow',
    'Weissenstadt',
    'Sierksdorf',
    'Seehaus',
    'Berlingerode',
    'Linden',
    'Welle',
    'Theissen',
    'Kuhn',
    'Blechhammer',
    'Wasser',
    'Weisel',
    'Gutenberg',
    'Bergbau',
    'Halberg',
    'Gossen',
    'Konigstein',
    'Karlsbad',
    'Dohle',
    'Gehlenbeck',
    'Blume',
    'Kraft',
    'Hohenroda',
    'Rasselstein',
    'Wichte',
    'Kleinsendelbach',
    'Klink',
    'Ostseebad Sellin',
    'Benz',
    'Klinge',
    'Cranz',
    'Ostseebad Ahrenshoop',
    'Autenzell',
    'Schutz',
    'Waschenbach',
    'Buhler',
    'Schillingshof',
    'Lerbach',
    'Ludwigshafen',
    'Wieske',
    'Feldkirchen',
    'Roes',
    'Wiesbach',
    'Buschhausen',
    'Karl',
    'Ostseebad Dierhagen',
    'Seelscheid',
    'Blitzenreute',
    'Langenenslingen',
    'Thulba',
    'Diedersdorf',
    'Oberberg',
    'Todtenried',
    'Lawalde',
    'Erlach',
    'Hallnberg',
    'Eisenbach',
    'Schlossberg',
    'Buhlerhohe',
    'Gleisweiler',
    'Reichenbach',
    'Burgerhof',
    'Zirkow',
    'Gevensleben',
    'Wolsdorf',
    'Hochstrasse',
    'Albeck',
    'Heimbach',
    'Neuburg',
    'Feldrennach',
    'Oberreichenbach',
    'Engelsburg',
    'Grunkraut',
    'Lichtenwald',
    'Otterbach',
    'Oppendorf',
    'Mohren',
    'Werste',
    'Zeiskam',
    'Bach an der Donau',
    'Steinfeld',
    'Blankensee',
    'Dorth',
    'Friedensau',
    'Buschbell',
    'Mailing',
    'Huhnerhof',
    'Munchhausen',
    'Lutz',
    'Maasholm',
    'Laasdorf',
    'Redwitz an der Rodach',
    'Eckartsberg',
    'Osterfeld',
    'Rackith',
    'Lebus',
    'Tauche',
    'Tantow',
    'Werben',
    'Bruch',
    'Abbensen',
    'Weenzen',
    'Ostenfeld',
    'Osterstedt',
    'Landscheide',
    'Jahnshof',
    'Margarethen',
    'Saint Michaelisdonn',
    'Tarbek',
    'Schafstedt',
    'Massenhausen',
    'Aying',
    'Maitenbeth',
    'Giggenhausen',
    'Kapellen-Drusweiler',
    'Lagesbuttel',
    'Geiselwind',
    'Ebrach',
    'Burglauer',
    'Thungen',
    'Rumeltshausen',
    'Esgrus',
    'Sterup',
    'Neckarweihingen',
    'Tailfingen',
    'Schopfloch',
    'Walting',
    'Gerhardshofen',
    'Trautskirchen',
    'Lettenreuth',
    'Scherstetten',
    'Lohnsfeld',
    'Schauerberg',
    'Zuffenhausen',
    'Liebenwalde',
    'Tarmow',
    'Waldlaubersheim',
    'Niederheimbach',
    'Holzbach',
    'Kamp-Bornhofen',
    'Niederlindhart',
    'Elmpt',
    'Windberg',
    'Listerfehrda',
    'Wasungen',
    'Insheim',
    'Hochstadt',
    'Boffzen',
    'Wolfsheim',
    'Lautern',
    'Nebringen',
    'Egesheim',
    'Woltershausen',
    'Wietzendorf',
    'Wenzingen',
    'Pilsach',
    'Kirchhaslach',
    'Lengthal',
    'Adelsried',
    'Ehrenberg',
    'Floss',
    'Trogen',
    'Etzelwang',
    'Issigau',
    'Neusorg',
    'Reitsch',
    'Kulz',
    'Brand',
    'Tiefenbach',
    'Gablenz',
    'Uebigau',
    'Schleife',
    'Schweizerhof',
    'Aschau',
    'Wittenberg',
    'Lechbruck',
    'Querum',
    'Riding',
    'Rothof',
    'Wassersleben',
    'Steglitz Bezirk',
    'Grunhof',
    'Ober-Roden',
    'Birnbach',
    'Stiege',
    'Schlanstedt',
    'Freudenberg',
    'Weidenthal',
    'Meckenheim',
    'Ruppertsberg',
    'Edesheim',
    'Urmitz',
    'Saffig',
    'Helmenzen',
    'Breitscheidt',
    'Ehlscheid',
    'Weinahr',
    'Wilhelmsdorf',
    'Egloffstein',
    'Ochtmersleben',
    'Rosenburg',
    'Zeppernick',
    'Gernrode',
    'Sandberg',
    'Worth am Main',
    'Neuendorf',
    'Benzingerode',
    'Morsleben',
    'Vahldorf',
    'Hotensleben',
    'Arrach',
    'Leukersdorf',
    'Heiligengrabe',
    'Klausdorf',
    'Honebach',
    'Plodda',
    'Krumpa',
    'Stangerode',
    'Tilleda',
    'Tiefenthal',
    'Rorichum',
    'Pewsum',
    'Dalum',
    'Heiligenstadt',
    'Mainbernheim',
    'Riedenheim',
    'Kist',
    'Mittelstreu',
    'Aub',
    'Dietenhofen',
    'Langenfeld',
    'Baudenbach',
    'Spechtsbrunn',
    'Niedereisenhausen',
    'Dauborn',
    'Kirrweiler',
    'Glindenberg',
    'Veckenstedt',
    'Wasserleben',
    'Garz',
    'Behrenhoff',
    'Katzow',
    'Ostseebad Gohren',
    'Kroslin',
    'Gohren',
    'Seebad Heringsdorf',
    'Dranske',
    'Hinrichshagen',
    'Altenkirchen',
    'Gross Kiesow',
    'Lassan',
    'Zussow',
    'Hochwang',
    'Duggendorf',
    'Weigendorf',
    'Joehlingen',
    'Ittersbach',
    'Rosdorf',
    'Wrist',
    'Grosskorbetha',
    'Ruscheid',
    'Stein-Neukirch',
    'Rieden',
    'Buchel',
    'Thalhausen',
    'Oberhonnefeld-Gierend',
    'Weitzschen',
    'Kohra',
    'Hohenfinow',
    'Spreenhagen',
    'Freudenberg',
    'Ziltendorf',
    'Arnsdorf',
    'Dintesheim',
    'Ludwigshohe',
    'Dahnsdorf',
    'Oberporing',
    'Drachselsried',
    'Aufhausen',
    'Niedermurach',
    'Roggenburg',
    'Veringendorf',
    'Konigsheim',
    'Marquartstein',
    'Marsdorf',
    'Muhle',
    'Beucha',
    'Mockrehna',
    'Knobelsdorf',
    'Rotha',
    'Fahrenbach',
    'Strahlungen',
    'Dambeck',
    'Quitzow',
    'Adenbuettel',
    'Schollnach',
    'Gulzow',
    'Parum',
    'Ahrenshagen',
    'Biburg',
    'Oberschweinbach',
    'Hayingen',
    'Bulowerburg',
    'Lohberg',
    'Kapsweyer',
    'Rositz',
    'Schlehdorf',
    'Tieringen',
    'Weixerau',
    'Kriebitzsch',
    'Straussfurt',
    'Gerbershausen',
    'Kieselbach',
    'Geisenhain',
    'Oberbodnitz',
    'Callbach',
    'Dankenfeld',
    'Schammelsdorf',
    'Nieheim',
    'Bruchhausen',
    'Eichen',
    'Haswede',
    'Bonstetten',
    'Beetzsee',
    'Hoppegarten',
    'Breitenworbis',
    'Unterdiessen',
    'Stottwang',
    'Obertaufkirchen',
    'Niederaichbach',
    'Aichtal',
    'Dunzweiler',
    'Weselberg',
    'Holler',
    'Heuchlingen',
    'Lonsingen',
    'Munsterdorf',
    'Alt Bukow',
    'Winsen',
    'Trassem',
    'Engelshof',
    'Winterbach',
    'Neidenfels',
    'Kemerting',
    'Aurachtal',
    'Frankenwinheim',
    'Armstorf'
  ],
  Israel: [
    'Lod',
    'Tel Aviv',
    'Jerusalem',
    'Kfar Saba',
    'Or Yehuda',
    'Azor',
    'Bat Yam',
    'Ramat Gan',
    'Holon',
    'Ashdod',
    'Beersheba',
    'Yehud',
    'Rishon LeZion',
    'Hazav',
    'Ness Ziona',
    'Eilat',
    'Rehovot',
    'Herzliya',
    'Nahariya',
    'Haifa',
    'Shave Ziyyon',
    'Ramat HaSharon',
    'Ramat Poleg',
    'Kadima',
    "Karmi'el",
    'Pardesiyya',
    'Netanya',
    'Gan Yavne',
    'Petah Tikwah',
    'Ashqelon',
    'Savyon',
    'Naham',
    'Mazkeret Batya',
    '`Arugot',
    'Ofaqim',
    'Shetulim',
    'Hadera',
    'Qiryat Bialik',
    'Tel Mond',
    'Hod HaSharon',
    'Qiryat Yam',
    'Shelomi',
    'Binyamina',
    'Liman',
    '`Alma',
    'Ramla',
    'Nazareth',
    'Et Taiyiba',
    'Rinnatya',
    'Yavne',
    'Acre',
    'Nazerat `Illit',
    'Baraq',
    'Even Yehuda',
    'Nesher',
    'Pardes Hanna Karkur',
    'Netivot',
    'Tirat Karmel',
    'Or `Aqiva',
    'Kefar Yona',
    'Bet Oren',
    'Haluz',
    'Biriyya',
    'Gan Hayyim',
    'Qiryat Tiv`on',
    'Qiryat Gat',
    "Be'er Toviyya",
    '`En Ayyala',
    'Misgav Regional Council',
    'Safed',
    'Gedera',
    'Dimona',
    'Bet Shemesh',
    '`En HaShelosha',
    'Herut',
    'Mazor',
    'Shamir',
    "Ra'anana",
    'Bat Hadar',
    "Rosh Ha'Ayin",
    'Zoran',
    'Tirat Yehuda',
    'Bareqet',
    'Elyakhin',
    'Hadid',
    'Hadar Ramatayim',
    'Modiin',
    'Hazor Ashdod',
    'Kefar Daniyyel',
    'Newe Efrayim',
    'Talme Menashe',
    'Kefar Netter',
    'Mazliah',
    'Moran',
    'Shoval',
    'Ramat Aviv',
    'Afiqim',
    'Ramat Dawid',
    'Sde Warburg',
    'Magen',
    'Karkur',
    'Qiryat Ata',
    'Maghar',
    'Bet Alfa',
    'Timrat',
    'Elyaqim',
    'Bet Dagan',
    'Ginnosar',
    "Qiryat Mal'akhi",
    'Hadar `Am',
    'maalot Tarshiha',
    'Ahituv',
    'Qiryat Motzkin',
    'Migdal',
    'Bat Hefer',
    'Ben Shemen-Kefar Hano`ar',
    'Emunim',
    'Tiberias',
    'Mikhmoret',
    'Hever',
    'Ramat Yishay',
    'Sderot',
    'Gimzo',
    'Mesillat Ziyyon',
    'Pasuta',
    "Bet She'an",
    'Newe Yamin',
    'Nordiyya',
    'HaKarmel',
    'Gibbeton',
    'Qiryat Ono',
    'Sarid',
    'Nir Zevi',
    'Ramat Ef`al',
    'Bene Ziyyon',
    'Hurfeish',
    'Shefayim',
    'Dabburiya',
    'Rishpon',
    'Mizpe Netofa',
    'Sde Boker',
    'Yaqum',
    'Bahan',
    'Jaffa',
    'Allonim',
    '`Evron',
    'Hazor HaGelilit',
    'Ramot Naftali',
    'Giv`at Hayyim',
    'Urim',
    'Sedot Yam',
    '`Aseret',
    'Nirim',
    '`Amir',
    'Ganne Tiqwa',
    "Ma`agan Mikha'el",
    'Qiryat Hayyim',
    'Kefar Witqin'
  ],
  Sweden: [
    'Stockholm',
    'Uppsala',
    'Malmo',
    'Handen',
    'Skaerholmen',
    'Gothenburg',
    'Lidingoe',
    'Vaellingby',
    'Lindome',
    'Solna',
    'OErkelljunga',
    'Bromma',
    'Vallentuna',
    'Vaxjo',
    'Motala',
    'Haegersten',
    'Tyreso Strand',
    'Borås',
    'Alta',
    'Sollentuna',
    'Akersberga',
    'Norrköping',
    'Taby',
    'Vaermdoe',
    'Halmstad',
    'Varby',
    'Forsby',
    'Norsborg',
    'Huddinge',
    'Storvreta',
    'Molnbo',
    'Rimbo',
    'Djurhamn',
    'Gränna',
    'Trollhattan',
    'Uddevalla',
    'Nodinge-Nol',
    'Skanninge',
    'Nynashamn',
    'Ekerö',
    'Norrtalje',
    'Malma',
    'Graestorp',
    'Flen',
    'Kungsbacka',
    'Moelndal',
    'Asa',
    'Hoerby',
    'Vastra Frolunda',
    'Gabo',
    'Savedalen',
    'Aled',
    'Eskilstuna',
    'Oskarstrom',
    'Örebro',
    'Södertälje',
    'Nacka',
    'Arloev',
    'Enkoping',
    'Hjo',
    'Kista',
    'Alvsjo',
    'Sigtuna',
    'Hasselby',
    'Billesholm',
    'Upplands Vasby',
    'Mora',
    'Stora',
    'Svedala',
    'Marsta',
    'Skepplanda',
    'Nyköping',
    'Haerryda',
    'OEdeshoeg',
    'Saro',
    'Tierp',
    'Helsingborg',
    'Partille',
    'Immeln',
    'Kalmar',
    'Rockneby',
    'Krokom',
    'Varberg',
    'Hallstavik',
    'Slottsbron',
    'Halso',
    'Oregrund',
    'Linköping',
    'Romakloster',
    'Bro',
    'Enskede-Arsta-Vantoer',
    'Laxa',
    'Molkom',
    'Mariestad',
    'Marback',
    'Skövde',
    'Vaddo',
    'Svangsta',
    'Nysater',
    'Skanor med Falsterbo',
    'Lidkoping',
    'Hollviken',
    'Borgstena',
    'Varnamo',
    'Bergsjoe',
    'Västerås',
    'Sölvesborg',
    'Hultafors',
    'Reso',
    'Morrum',
    'Visby',
    'Stenungsund',
    'Ronninge',
    'Stocksund',
    'Danderyd',
    'Bollebygd',
    'Huskvarna',
    'Karlshamn',
    'Lund',
    'Barseback',
    'Vimmerby',
    'Staffanstorp',
    'Solleftea',
    'Hallevadsholm',
    'Stroemstad',
    'Hedekas',
    'Munkedal',
    'Kungälv',
    'Falkenberg',
    'Gammelstad',
    'Luleå',
    'Östersund',
    'Gnesta',
    'Gävle',
    'Djursholm',
    'Tullinge',
    'Spanga',
    'Rydeback',
    'Bara',
    'Tranas',
    'Nynas',
    'Karlstad',
    'Rottne',
    'Umeå',
    'Jönköping',
    'Saltsjobaden',
    'Karlskoga',
    'Skallinge',
    'Brunflo',
    'Boden',
    'Lerum',
    'Osterhaninge',
    'Varsta',
    'Skarblacka',
    'Graddo',
    'Hallingsjo',
    'Bjuv',
    'Ludvika',
    'Borlänge',
    'Sala',
    'Strangnas',
    'Stenhamra',
    'Vetlanda',
    'Tollarp',
    'Gislaved',
    'Osmo',
    'Gualov',
    'Toereboda',
    'Vagnharad',
    'Arboga',
    'Smedjebacken',
    'Aby',
    'Nykvarn',
    'Falun',
    'Langshyttan',
    'Backa',
    'Vanersborg',
    'Lilla Edet',
    'Ljungby',
    'Saevsjoe',
    'Rotebro',
    'Kaevlinge',
    'Vargarda',
    'Lonashult',
    'Gemla',
    'Kristinehamn',
    'Sundbyberg',
    'Kallered',
    'Alingsas',
    'Kareby',
    'Harestad',
    'Eslov',
    'Kode',
    'Bohus',
    'Ojersjo',
    'Gnosjoe',
    'Piteå',
    'Skellefteå',
    'Bromoella',
    'Nodinge',
    'Ornskoldsvik',
    'Lyckeby',
    'Tving',
    'Hjarnarp',
    'Alafors',
    'Ulricehamn',
    'Aseda',
    'Oskarshamn',
    'Ytterby',
    'Jordbro',
    'Sundsvall',
    'Lycksele',
    'Krylbo',
    'Vendelso',
    'Edsbro',
    'Surahammar',
    'Knivsta',
    'Saltsjo-Duvnas',
    'Holo',
    'Vasterfarnebo',
    'Hallstahammar',
    'Akers Styckebruk',
    'Fagersta',
    'Vingaker',
    'Sandviken',
    'Karra',
    'Ullared',
    'Sjomarken',
    'Teckomatorp',
    'Lysekil',
    'Skarpnaeck',
    'Tumba',
    'Johanneshov',
    'Brottby',
    'Orbyhus',
    'Tegeltorp',
    'Oxie',
    'Ingmarso',
    'Bodafors',
    'Hedemora',
    'Furulund',
    'Astorp',
    'Trangsund',
    'Herrljunga',
    'Timra',
    'Ängelholm',
    'Domsjo',
    'Angered',
    'Degerfors',
    'Morgongava',
    'Bjornlunda',
    'Fritsla',
    'Skogas',
    'Stallarholmen',
    'Torshalla',
    'Katrineholm',
    'Dalaro',
    'Ransta',
    'Trosa',
    'Jarna',
    'Laholm',
    'Jokkmokk',
    'Grums',
    'Skurup',
    'Hoganas',
    'Surte',
    'Orsa',
    'Raettvik',
    'Uttran',
    'Skara',
    'Landskrona',
    'Mjolby',
    'Mardaklev',
    'Dals Rostock',
    'Vinninga',
    'Hunnestad',
    'Molnlycke',
    'Falköping',
    'Olofstorp',
    'Langas',
    'Skene',
    'Fjaeras station',
    'Landvetter',
    'Hono',
    'Jonsered',
    'Grabo',
    'Askim',
    'Kullavik',
    'Goetene',
    'Floda',
    'Vargon',
    'Roro',
    'Varobacka',
    'Malsryd',
    'Orby',
    'Åhus',
    'Ramdala',
    'Kristianstad',
    'Hoeoer',
    'Paarp',
    'Akarp',
    'Loddekopinge',
    'Ronneby',
    'Tyringe',
    'Ekeby',
    'Sturko',
    'Karlskrona',
    'Sibbhult',
    'Kattarp',
    'Osby',
    'Limhamn',
    'Tagarp',
    'Tingsryd',
    'Simrishamn',
    'Klagstorp',
    'Morarp',
    'Hässleholm',
    'Smedstorp',
    'Vinslov',
    'Klippan',
    'Asarum',
    'Sjoebo',
    'Svaloev',
    'Trelleborg',
    'Navlinge',
    'Fjalkinge',
    'Hyllinge',
    'Bjarnum',
    'Listerby',
    'Billeberga',
    'Ystad',
    'Harby',
    'Vankiva',
    'Olofstroem',
    'Bjarred',
    'Nattraby',
    'Haljarp',
    'Vastra Ramlosa',
    'Delsbo',
    'Forslov',
    'Obbola',
    'Tvaralund',
    'Bygdsiljum',
    'Byske',
    'Jarved',
    'Taberg',
    'Moelle',
    'Villan',
    'Restad',
    'Mullsjoe',
    'Faerjestaden',
    'Oxelosund',
    'Lindesberg',
    'Bergshamra',
    'Ransater',
    'Viken',
    'Kungshamn',
    'Vansbro',
    'Bengtsfors',
    'Getinge',
    'Sennan',
    'OEsthammar',
    'OEckeroe',
    'Leksand',
    'Deje',
    'Torslanda',
    'Gimo',
    'Forshaga',
    'Uddeholm',
    'Ekshaerad',
    'Stollet',
    'Tidan',
    'Stenstorp',
    'Tidaholm',
    'Likenas',
    'Hagfors',
    'Ambjornarp',
    'Henan',
    'Ljungskile',
    'Edsvalla',
    'Torup',
    'Harplinge',
    'Kilafors',
    'Bollnas',
    'Lanna',
    'Knivsta',
    'Overlida',
    'Sexdrega',
    'Munkfors',
    'Harmanger',
    'Alno',
    'Bergeforsen',
    'Hudiksvall',
    'Savsjostrom',
    'Avesta',
    'Mariefred',
    'Vidsel',
    'Stenkullen',
    'Varekil',
    'Liden',
    'Hebo',
    'Rengsjo',
    'Vallsta',
    'Arbra',
    'Segersta',
    'Billdal',
    'Vellinge',
    'Balsta',
    'Härnösand',
    'Veinge',
    'Ockelbo',
    'Torsaker',
    'Svartinge',
    'Nasum',
    'Haesselby',
    'Vaxholm',
    'Holmsund',
    'Bjorkoby',
    'Kage',
    'Ursviken',
    'Burtraesk',
    'Burea',
    'Nassjo',
    'Mellbystrand',
    'Knared',
    'Finspang',
    'Vaennaes',
    'Ojebyn',
    'Hemmingsmark',
    'Tavelsjo',
    'Froso',
    'Tvaaker',
    'Kungsaengen',
    'Alvesta',
    'Karlsborg',
    'Molltorp',
    'Svenljunga',
    'Taftea',
    'Sjotofta',
    'Ed',
    'Fristad',
    'Dalstorp',
    'Sodra Sunderbyn',
    'Grebbestad',
    'Hofors',
    'Svanesund',
    'Rydboholm',
    'Faergelanda',
    'Jamshog',
    'Agunnaryd',
    'Kyrkobyn',
    'Glommen',
    'Kramfors',
    'Edsele',
    'Svardsjo',
    'Grycksbo',
    'Arjeplog',
    'Bygdea',
    'Robertsfors',
    'Bastad',
    'Lagan',
    'Valdemarsvik',
    'Sodra Sandby',
    'Alvangen',
    'Romelanda',
    'Norsholm',
    'Soederkoeping',
    'Garsnas',
    'Kivik',
    'Sankt Olof',
    'Hammenhog',
    'Tomelilla',
    'Vitaby',
    'Kopingebro',
    'Tenhult',
    'Lomma',
    'Vissefjarda',
    'Bralanda',
    'Mellerud',
    'Frandefors',
    'Ucklum',
    'Jorlanda',
    'Stora Hoga',
    'Nybro',
    'Jaemjoe',
    'Torsas',
    'Smalandsstenar',
    'Asarna',
    'Oviken',
    'Fjardhundra',
    'Amal',
    'Gusum',
    'Bunkeflostrand',
    'Markaryd',
    'Haparanda',
    'Vaxtorp',
    'Eldsberga',
    'Skummeslovsstrand',
    'Genevad',
    'Munka-Ljungby',
    'Grillby',
    'Paskallavik',
    'Västervik',
    'Blentarp',
    'Borrby',
    'Brosarp',
    'Kimstad',
    'Stra',
    'Ostra Ryd',
    'Johannishus',
    'Kallinge',
    'Hovas',
    'Hammerdal',
    'Harbo',
    'OEstervala',
    'Tarnsjo',
    'Forserum',
    'Tallberg',
    'Kisa',
    'Anderstorp',
    'Svenstavik',
    'Åre',
    'Vittaryd',
    'Elloes',
    'Kiruna',
    'Lit',
    'Hyltebruk',
    'Kolmarden',
    'Billingsfors',
    'Perstorp',
    'Ashammar',
    'Ankarsvik',
    'Arvika',
    'Amotfors',
    'Klassbol',
    'Charlottenberg',
    'Guldsmedshyttan',
    'Gusselby',
    'Fellingsbro',
    'Borensberg',
    'Asensbruk',
    'Vinberg',
    'Gällivare',
    'Rosersberg',
    'Valskog',
    'Nashulta',
    'Harslov',
    'Alandsbro',
    'Vato',
    'Arholma',
    'Stravalla',
    'Kungsoer',
    'Rekarne',
    'Sunne',
    'Gullspang',
    'Timmersdala',
    'Skoldinge',
    'Svartsjo',
    'Malmkoping',
    'Halleforsnas',
    'Valla',
    'Aneby',
    'Ormaryd',
    'Flisby',
    'Malmback',
    'Grimstorp',
    'Hajom',
    'Horred',
    'Kinna',
    'Skee',
    'Moensteras',
    'Tygelsjo',
    'Glimakra',
    'Broby',
    'Killeberg',
    'Hastveda',
    'Tormestorp',
    'Finja',
    'Sosdala',
    'Vittsjo',
    'Ballingslov',
    'Hasslo',
    'Oxaback',
    'Viskafors',
    'Ljustero',
    'Haellefors',
    'Grythyttan',
    'Storfors',
    'Vintrosa',
    'Gyttorp',
    'Skaerhamn',
    'Edsbyn',
    'Alfta',
    'Kristdala',
    'Atvidaberg',
    'Vikingstad',
    'Nykil',
    'Saeffle',
    'Skinnskatteberg',
    'Hoernefors',
    'Saevar',
    'Hjaerup',
    'Kopparberg',
    'Alunda',
    'Osterbybruk',
    'Bankeryd',
    'Nora',
    'Malmberget',
    'Kvissleby',
    'Tanumshede',
    'Insjon',
    'Asbro',
    'Kolback',
    'Degeberga',
    'AElvdalen',
    'Vannasby',
    'Iggesund',
    'Skoghall',
    'Marma',
    'Nasviken',
    'Soderhamn',
    'Ljusne',
    'Siljansnas',
    'Djura',
    'Almunge',
    'Skultuna',
    'Loberod',
    'Storuman',
    'Haknas',
    'Enviken',
    'Stoby',
    'Bollstabruk',
    'Helgum',
    'Langsele',
    'Nyland',
    'Bjartra',
    'Kopmanholmen',
    'Mjallom',
    'Gidea',
    'Bjasta',
    'Langviksmon',
    'Overhornas',
    'Sjalevad',
    'Bjorna',
    'Husum',
    'Sidensjo',
    'Moliden',
    'Eksjoe',
    'Klintehamn',
    'Farnas',
    'Veddige',
    'Varing',
    'Ronnang',
    'Vara',
    'Orsundsbro',
    'Valbo',
    'Kalix',
    'Kil',
    'Ljungsbro',
    'Tungelsta',
    'Sjuntorp',
    'Frillesas',
    'Skogstorp',
    'Alunda',
    'Hallsberg',
    'Storvik',
    'Hede',
    'Sveg',
    'Sifferbo',
    'Mantorp',
    'Vislanda',
    'Mala',
    'Glommerstrask',
    'Bullmark',
    'Dalby',
    'Vreta Kloster',
    'Sturefors',
    'Hedesunda',
    'Norrsundet',
    'Ljusdal',
    'Farila',
    'Jaervsoe',
    'Tibro',
    'Fagersanna',
    'Ostra Karup',
    'Vejbystrand',
    'Vastra Karup',
    'Hoegsby',
    'Ekenassjon',
    'Nossebro',
    'Sollebrunn',
    'Stromsbruk',
    'Sorsele',
    'Vilhelmina',
    'Bjurholm',
    'Asele',
    'Garpenberg',
    'Ska',
    'Koping',
    'Skondal',
    'Hogsjo',
    'Vindeln',
    'Sandarne',
    'Hova',
    'Norsjoe',
    'Stensele',
    'Dorotea',
    'Dikanaes',
    'Nordmaling',
    'Lavsjo',
    'Botsmark',
    'Matfors',
    'Soderala',
    'Norrala',
    'Bergvik',
    'Stratjara',
    'Holmsveden',
    'Fjugesta',
    'Brakne-Hoby',
    'Backaryd',
    'Hammaro',
    'Gustavsberg',
    'Enebyberg',
    'Arjaeng',
    'Valberg',
    'Toere',
    'Norrahammar',
    'Vaggeryd',
    'Odensbacken',
    'Trekanten',
    'Dalsjofors',
    'Garphyttan',
    'Finnerodja',
    'Gronskara',
    'Gnarp',
    'Jarbo',
    'Indal',
    'Soraker',
    'Bramhult',
    'Bredared',
    'Ljung',
    'Seglora',
    'Unnaryd',
    'Rydobruk',
    'Gunnilse',
    'Rejmyre',
    'Klagshamn',
    'Gustafs',
    'Atorp',
    'Palange',
    'Alem',
    'Dala-Floda',
    'Genarp',
    'Bjoringe',
    'Storebro',
    'Langasjo',
    'Alsterbro',
    'Mockfjard',
    'Farlov',
    'Grangesberg',
    'Odakra',
    'Hok',
    'Saeter',
    'Vallda',
    'Figeholm',
    'Mansarp',
    'Enanger',
    'Hemse',
    'Emmaboda',
    'Slite',
    'Ostansjo',
    'Morlunda',
    'Filipstad',
    'Erikslund',
    'Fagelfors',
    'Atran',
    'Torsby',
    'Ryd',
    'Malung',
    'Balinge',
    'Transtrand',
    'Askersund',
    'Satila',
    'Eriksmala',
    'Linneryd',
    'Kinnarp',
    'Bjorkvik',
    'Bettna',
    'Hammarstrand',
    'Braecke',
    'Sjovik',
    'Tollered',
    'Dala-Jarna',
    'Vasterljung',
    'Gamleby',
    'Ostra Hindalebyn',
    'Kinnared',
    'Grisslehamn',
    'Hunnebostrand',
    'Bjorneborg',
    'Tystberga',
    'Stanga',
    'Drottningholm',
    'Vadstena',
    'Bjorklinge',
    'Overum',
    'Hacksvik',
    'Forsa',
    'Ostra',
    'Faro',
    'Stalldalen',
    'Gullbrandstorp',
    'Kvicksund',
    'Hallsta',
    'Lottorp',
    'Norberg',
    'Agnesberg',
    'Rostanga',
    'Skillingaryd',
    'Vretstorp',
    'Tranemo',
    'Backefors',
    'Ingelstad',
    'Kumla',
    'Uddebo',
    'Branno',
    'Borgholm',
    'Moerbylanga',
    'Riddarhyttan',
    'Nyhammar',
    'Rangedala',
    'Hultsfred',
    'Sandby',
    'Axvall',
    'OEverkalix',
    'Ranea',
    'Kvibille',
    'Lycke',
    'Uppharad',
    'Floby',
    'Askloster',
    'Hanaskog',
    'Ramvik',
    'Dals Langed',
    'Knutby',
    'Nygard',
    'Kungsgarden',
    'AElvsbyn',
    'Rodeby',
    'Vikmanshyttan',
    'Lerberget',
    'Norrfjarden',
    'Skruv',
    'Dio',
    'Glanshammar',
    'Stora Mellosa',
    'Malilla',
    'Larv',
    'Hedared',
    'Vegby',
    'Gards Kopinge',
    'Reftele',
    'Veberod',
    'Soederbaerke',
    'Skillinge',
    'Boxholm',
    'Lerdala',
    'Mellansel',
    'Hamburgsund',
    'Arnasvall',
    'Sodergard',
    'Ankarsrum',
    'Kosta',
    'AElmhult',
    'Havdhem',
    'Rosson',
    'Frufallan',
    'Langhem',
    'Aspered',
    'Styrso',
    'Hortlax',
    'Jarpen',
    'Vintrie',
    'Edshultshall',
    'Slutarp',
    'Raa',
    'Ramsele',
    'Vattholma',
    'Bjursas',
    'Stora Skedvi',
    'Frovi',
    'Ange',
    'Fransta',
    'Ljungaverk',
    'Torpshammar',
    'Kagerod',
    'Nyvang',
    'Saxtorp',
    'Mariannelund',
    'OEsterbymo',
    'Zinkgruvan',
    'Katthammarsvik',
    'OEvertornea',
    'Orrefors',
    'Maleras',
    'Bockara',
    'Linghem',
    'Lidhult',
    'Lovikka',
    'Soderakra',
    'Vassmolosa',
    'Stjarnhov',
    'Vase',
    'Fagervik',
    'Sorberge',
    'Overkovland',
    'Ljustorp',
    'Njurundabommen',
    'Duved',
    'Morsil',
    'Sloinge',
    'Ramnas',
    'Kolsva',
    'Virsbo Bruk',
    'Toecksfors',
    'Hovmantorp',
    'Grimslov',
    'Jarlasa',
    'Utansjo',
    'Sand',
    'Smoegen',
    'Grundsund',
    'Dingle',
    'Brastad',
    'Allerum',
    'Strovelstorp',
    'Vessigebro',
    'Kvidinge',
    'Vega',
    'Rolfstorp',
    'Sorombacken',
    'Lima',
    'Fredriksberg',
    'Ludvigsborg',
    'Dosjebro',
    'Kvanum',
    'Lodose',
    'Nyhamnslage',
    'Joern',
    'Gallo',
    'Follinge',
    'Stroemsund',
    'Pilgrimstad',
    'Skarplinge',
    'Soderfors',
    'Mankarbo',
    'Aplared',
    'Svaneholm',
    'Ganghester',
    'Mullhyttan',
    'Skollersta',
    'Harads',
    'Stode',
    'Skelleftehamn',
    'Ljungbyhed',
    'Skanes Fagerhult',
    'Traryd',
    'Vedum',
    'Limmared',
    'Yngsjo',
    'Varsas',
    'Bjuraker',
    'Sundsbruk',
    'Garsas',
    'Osterskar',
    'Bottnaryd',
    'Vikarbyn',
    'Vartofta',
    'Svensbyn',
    'Sundborn',
    'Lindas',
    'Ugglarp',
    'Larbro',
    'Aspas',
    'Valadalen',
    'Anderstorp',
    'Hillerstorp',
    'Ostra Frolunda',
    'Norrhult',
    'Karesuando',
    'Junosuando',
    'Lannavaara',
    'Stigen',
    'Nissafors',
    'Vastra Torup',
    'Simlangsdalen',
    'Knislinge',
    'Klagerup',
    'Stehag',
    'Rydsgard',
    'Skivarp',
    'Musko',
    'Taljo',
    'Hallabro',
    'Karna',
    'Grasmyr',
    'Roback',
    'Kattilstorp',
    'Backe',
    'Myresjo',
    'Sandhem',
    'Donso',
    'Ljungbyholm',
    'Vallakra',
    'Arla',
    'Skyllberg',
    'Ringarum',
    'Moheda',
    'Pajala',
    'Djuras',
    'Korpilombolo',
    'Soderby',
    'Orno',
    'Sandhult',
    'Tingstade',
    'Forsbacka',
    'Blattnicksele',
    'Blasmark',
    'Taenndalen',
    'Brokind',
    'Graso',
    'Marieholm',
    'Marstrand',
    'Halta',
    'Skane',
    'Vollsjo',
    'Sovde',
    'Grangarde',
    'Ulrika',
    'Almeboda',
    'Korsberga',
    'Hillared',
    'Stigtomta',
    'Hjalteby',
    'Fagered',
    'Follinge',
    'Pixbo',
    'Hindas',
    'Gagnef',
    'Sagmyra',
    'Ravlanda',
    'Kulla',
    'Furuvik',
    'Arvidsjaur',
    'Fors',
    'Alsen',
    'Glostorp',
    'Korskrogen',
    'Ramsjo',
    'Tallasen',
    'Los',
    'Stugun',
    'Virserum',
    'Holmsjo',
    'Sommen',
    'Horn',
    'Rimforsa',
    'Borghamn',
    'Holsbybrunn',
    'Tolg',
    'Hammar',
    'Flyinge',
    'Taernaby',
    'Hokerum',
    'Grimsas',
    'Holsljunga',
    'Olsfors',
    'AElvkarleby',
    'Asmundtorp',
    'Gallstad',
    'Sunnemo',
    'Lessebo',
    'Hasselfors',
    'Hallekis',
    'Gota',
    'Odeborg',
    'Glumslov',
    'Stora Vika',
    'Bergkvara',
    'Eneryda',
    'Klavrestrom',
    'Lammhult',
    'Aryd',
    'Ryssby',
    'Vittaryd',
    'Svenshogen',
    'Morup',
    'Timmele',
    'Onnestad',
    'Stromsholm',
    'Malma',
    'Timmernabben',
    'Berga',
    'Hasslarp',
    'Tun',
    'Stromsnasbruk',
    'Navekvarn',
    'Boliden',
    'Osterbybruk',
    'Lenhovda',
    'Lonsboda',
    'Aspero',
    'As',
    'Fengersfors',
    'Hoviksnas',
    'Moholm',
    'Lysvik',
    'Fageras',
    'Skattkarr',
    'Dyltabruk',
    'Vackelsang',
    'Urshult',
    'Konga',
    'Sodra Vi',
    'Braas',
    'Vrena',
    'Fallfors',
    'Trehorningsjo',
    'Arsunda',
    'Undersaker',
    'Loderup',
    'Blackstad',
    'Hamneda',
    'Kallby',
    'Njutanger',
    'Gryt',
    'Lekeryd',
    'Vastra Amtervik',
    'Ovanaker',
    'Viksjofors',
    'Jonaker',
    'Bredaryd',
    'Rydaholm',
    'Bjorbo',
    'Orviken',
    'Asenhoga',
    'Kulltorp',
    'Byske',
    'Hogsaters',
    'Lovanger',
    'Billinge',
    'Rundvik',
    'Lovestad',
    'Bofors',
    'Sparsor',
    'Traslovslage',
    'Anderslov',
    'Alstermo',
    'Grondal',
    'Fagerhult',
    'Nyland',
    'Lugnvik',
    'Kristineberg',
    'Skaraborg',
    'Varmland',
    'Fleninge',
    'Jude',
    'Bua',
    'Halland',
    'Grimeton',
    'Ansvar',
    'Hagglund',
    'Fiskeby',
    'Storlien',
    'Ingarp',
    'Talje',
    'Holmen',
    'Fagerberg',
    'Travad',
    'Otterbacken',
    'Gamla stan',
    'Hackas',
    'Altersbruk',
    'Barkarby',
    'Hakkas',
    'Bjorkhaga',
    'Torekov',
    'Vaeja',
    'Traslovslage',
    'Farna',
    'Brunnby',
    'Ljungsarp',
    'Lillpite',
    'Nordingra',
    'Hyssna',
    'Gargnas',
    'Hallberg',
    'Rinkaby',
    'Hestra',
    'Svarta',
    'Beddinge Lage',
    'Hjartum',
    'Landfjarden',
    'Bor',
    'Forsheda',
    'Spekerod',
    'Sandared',
    'Landeryd',
    'Skyttorp',
    'Landsbro',
    'Rorvik',
    'Stockaryd',
    'Marieholm',
    'Horndal',
    'Rosvik',
    'Farbo',
    'Kallo',
    'Vaderstad',
    'Saevast',
    'Hallestad',
    'Annelov',
    'Huarod',
    'Arkelstorp',
    'Roke',
    'Tjornarp',
    'Hallarod',
    'Nykroppa',
    'Koppom',
    'Hamrangefjarden',
    'Segmon',
    'Hallestad',
    'Ring',
    'Ruda',
    'Karl Gustav',
    'Herrangen',
    'Annerstad',
    'Palsboda',
    'Varnhem',
    'Ljugarn',
    'Vastervik',
    'Barsebackshamn',
    'Myggenas',
    'Svaneholm',
    'Lotorp',
    'Ljusfallshammar',
    'Gunnebo',
    'Sunnansjo',
    'Kladesholmen',
    'Klovedal',
    'Nalden',
    'Koskullskulle'
  ],
  Italy: [
    'Ferrara',
    'Sarnano',
    'Rome',
    'Gabicce Mare',
    'Sasso Marconi',
    'Sarno',
    'Collegno',
    'Fasano',
    'Bitritto',
    'Bitetto',
    'Bari',
    'Florence',
    'San Filippo',
    'Giardini-Naxos',
    'Augusta',
    'Messina',
    'Carlentini',
    'Troina',
    'Piaggine',
    'Tito',
    'Lampedusa',
    'San Mango Piemonte',
    'Mercato San Severino',
    'San Chirico Raparo',
    'Gesualdo',
    'Taranto',
    'Torricella',
    'Cicognolo',
    'Pedaso',
    'Cesena',
    'Casorezzo',
    'Brescia',
    'Loreto',
    'Vaiano Cremasco',
    'Milan',
    'Monza',
    'Monterosso Calabro',
    'Pesaro',
    'Militello Rosmarino',
    'Floridia',
    'Rionero in Vulture',
    'San Giorgio del Sannio',
    'Modena',
    "Reggio nell'Emilia",
    'Vicenza',
    'Rimini',
    'Piacenza',
    'Saronno',
    'Ancona',
    'Verona',
    'Asti',
    'Legnano',
    'Castellanza',
    'Massa e Cozzile',
    'Montecatini Terme',
    'Pescara',
    'Bergamo',
    'Bollate',
    'San Donato Milanese',
    'Termoli',
    'Turin',
    'Pisa',
    'Pioltello',
    'Gallarate',
    'Varese',
    'Gaeta',
    'Parma',
    'Teramo',
    'Busto Arsizio',
    'Padua',
    'Naples',
    'Catania',
    'Novara',
    'Bologna',
    'Sestri Levante',
    'San Giovanni Valdarno',
    'Sesto San Giovanni',
    'Cinisello Balsamo',
    'Genoa',
    'Bitonto',
    'Forlì',
    'La Spezia',
    'Latina',
    'Segrate',
    'Peschiera Borromeo',
    'Treviso',
    'Ravenna',
    'Rho',
    'Rosignano Solvay-Castiglioncello',
    'Rosignano Marittimo',
    'Fondi',
    'Formia',
    'Carpaneto Piacentino',
    'Petacciato',
    'Como',
    'Siena',
    'Iglesias',
    'Gerano',
    'Pogno',
    'Vicolungo',
    'Corzano',
    'Albenga',
    'Pero',
    'Pieve Ligure',
    'Domaso',
    'Sarnico',
    'Verdellino',
    'Melegnano',
    'Alessandria',
    'Pavia',
    'Racconigi',
    'Perosa Argentina',
    'Moncalvo',
    'Vigevano',
    'Rivoli',
    'Brandizzo',
    'Castellamonte',
    'Gorgonzola',
    'Ghedi',
    'Tradate',
    'Clusone',
    'Alzano Lombardo',
    'Cremona',
    'Curno',
    'Ovada',
    'Arenzano',
    'Savona',
    'Sanremo',
    'Viareggio',
    'Brugherio',
    'Novate Milanese',
    'Cesano Maderno',
    'Abbiategrasso',
    'Arona',
    'Chieri',
    'Pinerolo',
    'Nichelino',
    'Vigliano Biellese',
    'Romagnano Sesia',
    'Biella',
    'Settimo Torinese',
    'Rivalta di Torino',
    'Domodossola',
    'Piossasco',
    'San Carlo',
    'Terlan',
    'Tolentino',
    'Casalecchio di Reno',
    'Casier',
    'Scorzè',
    'San Giovanni in Persiceto',
    'Cento',
    'Borgonovo Val Tidone',
    'Albinea',
    'Vascon',
    'Rivignano',
    'Grado',
    'Asolo',
    'Annone Veneto',
    "San Michele all'Adige",
    'Illasi',
    'San Giovanni Lupatoto',
    'Garda',
    'Volpago del Montello',
    'Trivignano Udinese',
    'Cavallino',
    'Zero Branco',
    'Castello di Godego',
    'Sedico',
    'Alberoni',
    'La Valle Agordina',
    'Pasian di Prato',
    'Tavagnacco',
    'Brugnera',
    'Ponzano Veneto',
    'Mira',
    'Ponte di Piave',
    'Marcon',
    'Porcia',
    'Dosson',
    'Pravisdomini',
    'Pianiga',
    'Sagrado',
    'Turriaco',
    'Roncade',
    'Vittorio Veneto',
    'Trieste',
    'Chioggia',
    'Venice',
    'Spinea',
    'Udine',
    'Mogliano Veneto',
    'Pordenone',
    'Legnago',
    'Bolzano',
    'Trento',
    'San Bonifacio',
    'Bussolengo',
    'Isola della Scala',
    'Belluno',
    'Bovolone',
    'Portomaggiore',
    'Cittàdi Castello',
    'Dozza',
    'Budrio',
    'Imola',
    'Sassuolo',
    'Bastia umbra',
    'Monghidoro',
    'Cervia',
    'Formigine',
    'Castel San Pietro Terme',
    'Santarcangelo di Romagna',
    'Perugia',
    'Schio',
    'Rovigo',
    'Malo',
    'Sarcedo',
    'Somma Lombardo',
    'Erba',
    'Casalpusterlengo',
    'Cermenate',
    'Luino',
    'Solbiate Olona',
    'Codogno',
    'Mariano Comense',
    'Bresso',
    'Lodi',
    'Cologno al Serio',
    'Cologno Monzese',
    'Moncalieri',
    'Grugliasco',
    'Casale Monferrato',
    'Aosta',
    'Gessate',
    'Brivio',
    'Calolziocorte',
    'Parabiago',
    'Zelo',
    'Albiate',
    'Lomagna',
    'Venegono Superiore',
    'Lainate',
    'Villa Cortese',
    'Locate di Triulzi',
    'Inzago',
    'Besana in Brianza',
    'Biassono',
    'Osnago',
    'Gorla Maggiore',
    'Triuggio',
    'Cerro Maggiore',
    "Cornate d'Adda",
    'Binasco',
    'Broni',
    "Lurago d'Erba",
    "Vaprio d'Adda",
    'Calizzano',
    'Mezzanego',
    'Carcare',
    'Azzate',
    'Missaglia',
    'Giussano',
    'Paderno Dugnano',
    'Seveso',
    'Villasanta',
    'Lentate sul Seveso',
    'Corsico',
    'Avezzano',
    'Senigallia',
    'San Benedetto dei Marsi',
    'Massa',
    'Arezzo',
    'Forlimpopoli',
    'Pontedera',
    'Livorno',
    'Formello',
    'Anzio',
    'Frascati',
    'Guidonia',
    'Aprilia',
    'Sansepolcro',
    'Sesto Fiorentino',
    'Pistoia',
    'Prato',
    'Montecarlo',
    'Riccione',
    'Castelfiorentino',
    'Arconate',
    'Varedo',
    'Barzanò',
    'Trezzano sul Naviglio',
    'Usmate Velate',
    'Lonigo',
    'Occhiobello',
    'Albignasego',
    'Cittadella',
    'Maseràdi Padova',
    'Longiano',
    'Rovereto',
    'Spoltore',
    'Figline Valdarno',
    'Palermo',
    'Benevento',
    'Alcamo',
    'Trecastagni',
    'Syracuse',
    'Giarre',
    'Acireale',
    'Monreale',
    'Alba',
    'Rapallo',
    'Albissola Marina',
    'Cuneo',
    'Magenta',
    'Novi Ligure',
    'Mortara',
    'Ivrea',
    'Alpignano',
    'Pianezza',
    'Valenza',
    'Tortona',
    'Lungavilla',
    'Olgiate Molgora',
    'Arcore',
    'Garlasco',
    'San Martino in Strada',
    'Cislago',
    'Landriano',
    'Limbiate',
    'Vermezzo',
    'Siziano',
    'San Genesio ed Uniti',
    'Liscate',
    'Fombio',
    'Campertogno',
    "Trezzo sull'Adda",
    'Lugagnano',
    'Trescore Balneario',
    'Mantova',
    'Pieve',
    'Vertova',
    'Darfo',
    'Chiari',
    'Seriate',
    'Travagliato',
    'Cene',
    'Gussago',
    'Urgnano',
    'Casnigo',
    'Saluzzo',
    'Orbassano',
    'Gattinara',
    'Borgosesia',
    'Carmagnola',
    'Fidenza',
    'Bagnolo Cremasco',
    'Ventimiglia',
    'Desio',
    'Cornaredo',
    'Vimercate',
    'Rozzano',
    'Milanesi',
    'Favria',
    'Giaveno',
    'Mondovì',
    'Fossano',
    'San Michele Mondovì',
    'Lessona',
    'Sandigliano',
    'Oglianico',
    'Omegna',
    'Bricherasio',
    'Castelnuovo Don Bosco',
    'Rivarolo Ligure',
    'Occhieppo Inferiore',
    'Varallo Sesia',
    'Varallo Pombia',
    'Momo',
    'San Giovanni',
    'Finale Ligure',
    "Calusco d'Adda",
    'Marano Ticino',
    'Gressoney-Saint-Jean',
    'San Martino di Lupari',
    'Rosà',
    'Tricesimo',
    "Sant'Ilario d'Enza",
    'Zola Predosa',
    'Peschiera del Garda',
    'Tregnago',
    'San Pietro in Casale',
    'Maranello',
    'Camposampiero',
    'Abano Terme',
    'Brixen',
    'Marostica',
    'Civezzano',
    'Arco',
    'Portogruaro',
    'Crema',
    'Lecco',
    'Camparada',
    'Massa Lombarda',
    'Savignano sul Panaro',
    'Baricella',
    'Castelfranco Emilia',
    'Comacchio',
    'Masi Torello',
    'Cotignola',
    'Mordano',
    'Montecchio Emilia',
    'Bagnacavallo',
    'Bagnolo in Piano',
    'Copparo',
    'Pianoro',
    'Savignano sul Rubicone',
    'Finale Emilia',
    'Rosolina',
    'Ficarolo',
    'Brugine',
    'Oppeano',
    'Marano Vicentino',
    'San Pietro in Gu',
    'Villadose',
    'Agna',
    'Noventa Vicentina',
    'Villaggio Montegrappa',
    'Castelmassa',
    'Altavilla Vicentina',
    'Tezze sul Brenta',
    'Vescovana',
    'Teolo',
    'Vigonza',
    'Zevio',
    'Recoaro Terme',
    'Sterzing',
    'Castel',
    'Sanguinetto',
    'Sommacampagna',
    'Maniago',
    'Casinalbo',
    'Felino',
    'Mirandola',
    'Mansuè',
    'Martellago',
    'Motta di Livenza',
    'Azzano Decimo',
    'Urbisaglia',
    "L'Aquila",
    'Notaresco',
    'Civitanova Marche',
    'Castorano',
    'Montegranaro',
    'Folignano',
    'Lettomanoppello',
    'Maiolati Spontini',
    'Cupello',
    'Urbania',
    'Agugliano',
    'Manoppello',
    'Mondolfo',
    'Castelbellino',
    'Orsogna',
    'Ascoli Piceno',
    'Montesilvano Colle',
    'Casalbordino',
    'Porto San Giorgio',
    "Sant'Angelo in Pontano",
    'Chieti',
    'Polverigi',
    'Oratino',
    'Frosinone',
    'Silvi Paese',
    'Iesi',
    'San Benedetto del Tronto',
    'Porto',
    'Lucca',
    'Empoli',
    'Ferentino',
    'Sermoneta',
    'Santa Marinella',
    'Fiumicino',
    'Ardea',
    'Castel Madama',
    'Ischia di Castro',
    'Bassano Romano',
    'Mineo',
    'Bronte',
    "Capo d'Orlando",
    'Motta San Giovanni',
    "Aci Sant'Antonio",
    'Melilli',
    'Piedimonte Etneo',
    'Leonforte',
    'Agira',
    'Patti',
    'Pedara',
    'Biancavilla',
    'Nicosia',
    'Spadafora',
    'Cardito',
    'Torre del Greco',
    "Pomigliano d'Arco",
    'Telese',
    'Giugliano in Campania',
    'Belmonte Mezzagno',
    'Termini Imerese',
    'Salve',
    'Andrano',
    'San Marco Argentano',
    'Reggio Calabria',
    'Novoli',
    'Cosenza',
    'Triggiano',
    'Trapani',
    'Aci Catena',
    "Sant'Agata di Militello",
    'Castenedolo',
    'Iseo',
    'Candiolo',
    'Candelo',
    'Zimone',
    'Sestriere',
    'Cogoleto',
    'Borgomanero',
    'Porlezza',
    'Pontevico',
    'Zogno',
    'Belforte',
    'Piazza Brembana',
    'Rudiano',
    'Nerviano',
    'Montemurlo',
    'Pomezia',
    'Cisterna di Latina',
    'Nettuno',
    'Civitavecchia',
    'Campagnano di Roma',
    'Rieti',
    'Calcata',
    'Sabaudia',
    'Genazzano',
    'Pontinia',
    'Viterbo',
    'Olevano Romano',
    'Cerveteri',
    'Sacrofano',
    'Nepi',
    'Monte Porzio Catone',
    'Veroli',
    'Ladispoli',
    'Fornaci di Barga',
    'Signa',
    'Cascina',
    'Porcari',
    'Pescia',
    'Corciano',
    'Fucecchio',
    "Colle di Val d'Elsa",
    'Grosseto',
    'Capannoli',
    'Quarrata',
    'Montelupo Fiorentino',
    'Magliano',
    'Cervaro',
    'Sarroch',
    'Villaurbana',
    'Elmas',
    'Muravera',
    'Sassari',
    'Sorso',
    'Arbus',
    'Atzara',
    'Soleminis',
    'Olmedo',
    'Gonnostramatza',
    'Terralba',
    'Villanovafranca',
    'Marrubiu',
    'Masainas',
    'Ussana',
    'Alghero',
    'Ossi',
    'Villa San Pietro',
    'Oristano',
    'Musei',
    'Samassi',
    'Villaputzu',
    'Siniscola',
    'Cusano Milanino',
    'San Giuliano Milanese',
    'Grandate',
    'Bareggio',
    'Seregno',
    'Cantu',
    'Baranzate',
    'Colico',
    'Merate',
    'Romano di Lombardia',
    "Fiorenzuola d'Arda",
    'Sarezzo',
    'Stresa',
    'Forno Canavese',
    'Chivasso',
    'Crevalcore',
    "Granarolo dell'Emilia e Viadagola",
    'Cervignano del Friuli',
    'Conegliano',
    'Novellara',
    'Assisi',
    'Foligno',
    'Dolo',
    'Cadelbosco di Sopra',
    'Gualdo Tadino',
    'Vignola',
    'Faenza',
    'Valdobbiadene',
    'Monterenzio',
    'Novi di Modena',
    'Medicina',
    'Palau',
    'Villaspeciosa',
    'Cagliari',
    "Quartu Sant'Elena",
    'Guasila',
    'Castelfidardo',
    'Ostra Vetere',
    'San Giovanni',
    'Macerata',
    'Vasto',
    'Osimo',
    'Fabriano',
    'Chiaravalle',
    'Campobasso',
    'Recanati',
    'San Casciano in Val di Pesa',
    'Montespertoli',
    'Genzano di Roma',
    'Bagno a Ripoli',
    'Piombino',
    'Poggibonsi',
    'Loro Ciuffenna',
    'Bocchignano',
    'Bracciano',
    'Foggia',
    'Melfi',
    'Caivano',
    'Ariano Irpino',
    'Avella',
    'Portici',
    'Caserta',
    'Catanzaro',
    'Andria',
    'Santeramo in Colle',
    'Terlizzi',
    'Altamura',
    'Caltagirone',
    'Piazza Armerina',
    'Niscemi',
    'Lentini',
    'Caltanissetta',
    'Palma di Montechiaro',
    'Piano di Sorrento',
    'Manduria',
    'Acquaviva delle Fonti',
    "Pieve d'Olmi",
    'Misinto',
    'Torre',
    'Zerfaliu',
    'Lacchiarella',
    'Mirano',
    'Tivoli',
    'Villetta',
    'Lido',
    'Urtijëi',
    'Fiume Veneto',
    'Olivetta',
    'Airole',
    'Quart',
    'Vallecrosia',
    'Rovello Porro',
    'Moso',
    'Villagrazia',
    'Piemonte',
    'Antico di Maiolo',
    'Romana',
    'San Miniato',
    'Afragola',
    "Cava de' Tirreni",
    'Ragusa',
    'Ferrari',
    'Torgiano',
    'Anna',
    "Silvano d'Orba",
    'Pineta',
    'Cola',
    'Modugno',
    'San Raineri',
    'San Giorgio',
    'Salerno',
    'Campagna',
    'San Cipriano Picentino',
    'Brunella',
    'Rosa',
    'Fantina',
    'San Giorgio',
    'San Francesco',
    'Piove di Sacco',
    'Gioia',
    'Gazzo Veronese',
    'Corona',
    'Vado Ligure',
    'Zanetti',
    'Bruno',
    'Bianco',
    'Sale',
    'Altofonte',
    'Salsomaggiore Terme',
    'Monteverde',
    'Marco',
    'San Michele al Tagliamento',
    'Sestu',
    'Positano',
    'Ravanusa',
    'Lecce',
    'Portoscuso',
    'Ranieri',
    'Casali',
    'Forte dei Marmi',
    'Nogarole Rocca',
    'Crescentino',
    'Rivanazzano',
    'Taglio di Po',
    'Filottrano',
    'Cairo Montenotte',
    'Sesto al Reghena',
    'Cernusco sul Naviglio',
    'Dello',
    'Vitorchiano',
    'Modica',
    'Lanusei',
    'Bassano del Grappa',
    'Angolo Terme',
    'Bagheria',
    'Piedimonte Matese',
    'Sant Angelo',
    'Cortenova',
    'Bomporto',
    'Pozzo di Gotto',
    'Braccagni',
    'Cassino',
    'San Vendemiano',
    'Carpeneto',
    'Monticello',
    'Mestre',
    'Cerasolo',
    'Ronchi dei Legionari',
    'Colorno',
    'San Giovanni in Marignano',
    'San Martino Buon Albergo',
    'Castel Guelfo di Bologna',
    'Monselice',
    'Aviano',
    'Thiene',
    'Coriano',
    'Adria',
    'Fossò',
    'Vas',
    'Bertinoro',
    'Podenzano',
    'Caldogno',
    'Zoppola',
    'Cesenatico',
    'Argelato',
    'Sandon',
    'San Pietro',
    'Brendola',
    'Cordenons',
    'Pescantina',
    'Roverbella',
    'Paese',
    'Carpi',
    'Sacile',
    'Cividale del Friuli',
    'Staranzano',
    'Latisana',
    'Misano Adriatico',
    'Campodarsego',
    'Fogliano Redipuglia',
    'Correggio',
    'Castelfranco di Sopra',
    'Cadoneghe',
    'Dozza',
    'Ala',
    'Marzabotto',
    'Casale sul Sile',
    'Borgo San Lorenzo',
    "Santa Vittoria d'Alba",
    'Zanica',
    'Magliano Alpi',
    'Ronco Scrivia',
    'Moniga del Garda',
    'Canelli',
    'Castel Mella',
    'Baldissero Torinese',
    'Vercelli',
    'Ortovero',
    'Barlassina',
    'Castano Primo',
    'Calcinato',
    'Verdello',
    'Varazze',
    'Cascine Maggio',
    'Cossato',
    'Uscio',
    'Muggiò',
    'Vignate',
    'Treviglio',
    'Sedriano',
    'Roncadelle',
    'Quarona',
    'Nova Milanese',
    'Castiglione Olona',
    'Sesto Calende',
    'Rovagnate',
    'Chiavari',
    'Besozzo',
    'Olgiate Olona',
    'Pavone Canavese',
    'Ponte Nossa',
    'Melzo',
    'Crispiano',
    'Agropoli',
    'Scalea',
    'Toritto',
    'Pozzallo',
    'Manfredonia',
    'Corridonia',
    'Montecorvino Rovella',
    'Ripe',
    'San Donaci',
    'Villaricca',
    'Nocera Superiore',
    'Misterbianco',
    'Aversa',
    'Trentola-Ducenta',
    'Battipaglia',
    'Saviano',
    'San Giovanni la Punta',
    'Vico Equense',
    'Laterza',
    'Mazara del Vallo',
    'Palo del Colle',
    'Torano Castello',
    'Castellammare di Stabia',
    'Veglie',
    'San Giorgio a Cremano',
    'Marcianise',
    'Barletta',
    'Trani',
    'Ercolano',
    'Frattamaggiore',
    'San Tammaro',
    'Falconara Marittima',
    'Brindisi',
    'Ceglie Messapica',
    'Nardò',
    'Matera',
    'Molfetta',
    'Partinico',
    'Balestrate-Foce',
    'Carmiano',
    'Noicattaro',
    'Giovinazzo',
    'Grumo Nevano',
    'Scorrano',
    'Ischia',
    'Castelvenere',
    'San Severo',
    "Sant'Arpino",
    'Potenza',
    'Pagani',
    'Regalbuto',
    'Casapulla',
    'Acerra',
    'Casoria',
    'Terni',
    'Crespina',
    'Raiano',
    'Sulmona',
    'Ariccia',
    'San Donato',
    'Marino',
    'Saltocchio',
    'Morlupo',
    'Monterotondo',
    'Assemini',
    'San Salvo',
    'Nazzano',
    'Scandicci',
    'Sora',
    'Montemassi',
    'Vetralla',
    'Santa Croce',
    'Fonni',
    'Castelnuovo di Garfagnana',
    "CittàSant'Angelo",
    "Monteroni d'Arbia",
    'Mentana',
    'Cecina',
    'Olbia',
    'Le Castella',
    'Giulianova',
    'Nuoro',
    'Silvi',
    'Ficarazzi',
    'Piana degli Albanesi',
    'Civita Castellana',
    'Luco dei Marsi',
    'Licata',
    'Acri',
    "Rignano sull'Arno",
    'Truccazzano',
    'Spotorno',
    'Villalago',
    'Vicari',
    'Monopoli',
    'Sandrigo',
    'Gusti',
    'Patù',
    'Montecchio Maggiore',
    'Pramaggiore',
    'San Giuseppe Vesuviano',
    'Corbetta',
    'Offanengo',
    'Lancenigo-Villorba',
    'Andorno Micca',
    'Vimodrone',
    "Cassano d'Adda",
    'Campi Bisenzio',
    'Tavullia',
    'Cellamare',
    'Millesimo',
    'Ardesio',
    'Recco',
    'Ponte San Pietro',
    'Sarzana',
    'Carate Brianza',
    'Nave',
    'Bovisio-Masciago',
    'Castegnato',
    'Poirino',
    'Malnate',
    'Cava Manara',
    'Bra',
    'Imperia',
    'Monte',
    'San Mauro Torinese',
    'Castelnuovo Nigra',
    'Lomazzo',
    'Baratte',
    'Trofarello',
    'Carnate',
    'Garbagnate Milanese',
    'Appiano Gentile',
    'Concorezzo',
    'Canegrate',
    'Opera',
    'Mozzanica',
    'Desenzano del Garda',
    'Robassomero',
    'Crespiatica',
    'Acqui Terme',
    'Grosio',
    'Torre Boldone',
    'Carrara',
    'Massa',
    'Velletri',
    'Serravalle Pistoiese',
    'Paliano',
    'Bosa',
    'Campomarino',
    'Senorbì',
    'Cortona',
    'San Gemini',
    'Camaiore',
    'Calcinaia',
    'Roseto degli Abruzzi',
    'Riano',
    'Santa Giusta',
    'Fiano Romano',
    'Impruneta',
    'Grottaferrata',
    'Marina di Cerveteri',
    'San Sebastiano al Vesuvio',
    'San Gregorio di Catania',
    'Scafa',
    'Nola',
    'Caccamo',
    'Fano',
    'Martina Franca',
    'Santa Maria a Vico',
    'Salemi',
    'Cerignola',
    'Agrigento',
    'Riposto',
    'Atripalda',
    'Cittanova',
    'Pineto',
    'Calcinelli',
    'Lizzano',
    'Fermo',
    'Santo Pietro',
    'Cicciano',
    "Sant'Agata de' Goti",
    'Nocera Inferiore',
    'Favara',
    'Mugnano del Cardinale',
    'Villarosa',
    'Qualiano',
    'Caltavuturo',
    'Erice',
    'Ruvo di Puglia',
    'Poli',
    'Mussomeli',
    'Villa Castelli',
    'Avellino',
    'Alvignano',
    'Mercogliano',
    'San Giuseppe Jato',
    'San Nicola',
    'Boscoreale',
    'Belpasso',
    'Urbino',
    'Lissone',
    'Ozzero',
    'Travedona Monate',
    'Gavirate',
    'Santo Stefano',
    'Beinasco',
    'Saint-Vincent',
    'Fossalta di Portogruaro',
    'San Martino',
    'Rovato',
    'Gambolò',
    'Stradella',
    'Trecate',
    'Fino Mornasco',
    'Venaria Reale',
    'Aquileia',
    'Valdagno',
    'Asiago',
    'San Lazzaro di Savena',
    'Limana',
    'Lavis',
    "Castel d'Ario",
    'Cormons',
    'Curtarolo',
    'Feltre',
    'Rubano',
    'Bardolino',
    'Zanè',
    'Farra di Soligo',
    'Castelnuovo Rangone',
    'Castelvetro di Modena',
    'Noale',
    'Pievepelago',
    'Castelfranco Veneto',
    'San Dona',
    'Selvazzano Dentro',
    'Merano',
    'Palmanova',
    'Villafranca di Verona',
    'Collecchio',
    'Montechiarugolo',
    'Massa',
    'Casalgrande',
    'Cappella Maggiore',
    'Gattatico',
    'San Donàdi Piave',
    'Preganziol',
    'Castenaso',
    'Decima',
    'San Giovanni in Croce',
    'Nervesa della Battaglia',
    'Toirano',
    'Voghera',
    'Cardano',
    'Cameri',
    'Oggiono',
    'Lonate Pozzolo',
    'Dairago',
    'Villastellone',
    'Montoggio',
    'Vicoforte',
    'Almè',
    'Vittuone',
    'Caselle Torinese',
    'Altessano',
    'Altare',
    'Carugate',
    'Sondrio',
    'Agrate Brianza',
    "Spino d'Adda",
    'Pietrasanta',
    'Monte',
    'Segni',
    'Terranuova Bracciolini',
    'Monsummano Terme',
    'Labico',
    'Grottammare',
    'Palestrina',
    'Putignano',
    'Marano di Napoli',
    'Pozzuoli',
    "Sant'Anastasia",
    'Angri',
    'Cercola',
    'Sciacca',
    'Auletta',
    'Ortona',
    'Fiumefreddo di Sicilia',
    "Sant'Elpidio a Mare",
    'San Michele Salentino',
    'Matelica',
    'Casaluce',
    'Grammichele',
    'Statte',
    'Martinsicuro',
    'Capurso',
    'Maderno',
    'Casamassima',
    'Bisceglie',
    'Mariglianella',
    'Castiglione di Sicilia',
    'Cotronei',
    'Tortora',
    'Petrosino',
    'Pergola',
    'Cervino',
    'Lioni',
    'Mascalucia',
    'San Prisco',
    'Brusciano',
    'Camposano',
    'Rende',
    'Offida',
    'Piana',
    'Adro',
    'Santo Stefano Ticino',
    'Arluno',
    'Fagnano Olona',
    'Monterosso al Mare',
    'Grumello Cremonese',
    'Certosa di Pavia',
    'Castiglione Chiavarese',
    'Bussoleno',
    'Savigliano',
    'Solaro',
    'Stezzano',
    'Belgioioso',
    'Monte Compatri',
    'Celano',
    'Lamporecchio',
    'Francavilla al Mare',
    'Albano Laziale',
    'Fregene',
    'Castel Viscardo',
    'Morciano di Romagna',
    'Casina',
    'Romano Canavese',
    'Cesiomaggiore',
    'Porto Viro',
    'Nogaredo al Torre',
    'Villaverla',
    'Quinto Vicentino',
    'Mandello del Lario',
    'Cizzago-Comezzano',
    'Manerbio',
    'Campofranco',
    'Gadoni',
    'Carema',
    'San Martino Siccomario',
    'Pontecchio Polesine',
    'Apricena',
    'Legnaro',
    'Alviano',
    'San Mauro Pascoli',
    'Guarda Veneta',
    'Golasecca',
    'Forenza',
    'Cerveno',
    'Campodimele',
    'Piancogno',
    'Cepagatti',
    'Ailoche',
    'Suzzara',
    'Vailate',
    'Bassano Bresciano',
    'Valeggio sul Mincio',
    'Gera Lario',
    "San Martino Dall'Argine",
    'Arabba',
    'Casale di Scodosia',
    'Murano',
    'Posta Fibreno',
    'Carceri',
    'Cortina',
    'Isola Vicentina',
    'San Daniele del Friuli',
    'San Giorgio',
    'Este',
    'Falcone',
    'Telgate',
    'Crotone',
    'Castel Maggiore',
    'Mercato Saraceno',
    'Pomarance',
    'Sirignano',
    'Rosolini',
    'Comiso',
    'Costa',
    'Santa Maria di Licodia',
    'Covo',
    'Santa Eufemia Lamezia',
    'San Severino',
    'San Gimignano',
    'Serramazzoni',
    'Farnese',
    'Castiglione della Pescaia',
    'Carapelle',
    'Belvedere di Spinello',
    'Montecarotto',
    'Marotta',
    'Arcevia',
    'Calcara',
    'Milano',
    'Bibbiano',
    'Bellagio',
    'Palombara Sabina',
    'Pavona',
    'Anagni',
    'Zagarolo',
    'Lograto',
    'Colloredo di Prato',
    'Gravedona',
    'Vogogna',
    'Gorno',
    'Gignese',
    'Monte di Malo',
    'Felitto',
    'Manta',
    'Vigone',
    'Monastero',
    'Castelletto',
    'None',
    'Montignoso',
    'Verzuolo',
    'Demonte',
    'Sarsina',
    'Sovicille',
    'Gaiole in Chianti',
    'Soliera',
    'Borgoricco',
    'Barrali',
    'Monterosi',
    'Calamandrana',
    'Mezzomerico',
    'Cerea',
    'Gazzaniga',
    'Ambrogio',
    'Lignano Sabbiadoro',
    'Arre',
    'Orio Litta',
    'Cedessano',
    'Todi',
    'Cannara',
    'Stefania',
    'Massignano',
    'Pioraco',
    'Contigliano',
    'San Giorgio Piacentino',
    'Campolongo Maggiore',
    'Montaldo',
    'Borgo San Dalmazzo',
    'Susa',
    "Sant'Angelo Lodigiano",
    'Castelli Calepio',
    'Casa Paradiso',
    'Camponogara',
    'Roncoferraro',
    'Collalto',
    'Carbonera',
    'Pico',
    'Mirabella',
    'Cattolica',
    'Russo',
    'San Niccolo',
    'Parigi',
    'Lurate Caccivio',
    'Ospedaletto-Expopisa',
    'Morgano',
    'Campiglio',
    'Mestrino',
    'Noventa di Piave',
    'Cimadolmo',
    'Leno',
    'Sumirago',
    'Pontecchio',
    'Ponsacco',
    'Mirabella Eclano',
    'Carosino',
    'Castelfranco di Sotto',
    'Pietrabbondante',
    'Bojano',
    'Montemurro',
    'Monserrato',
    'Gela',
    'Passetto',
    'Bruneck',
    'Trappeto',
    'Montebelluna',
    'Crocetta del Montello',
    'San Fior',
    'Frassineto Po',
    'Casteggio',
    'Fortunago',
    'Villanova Mondovì',
    'Valle',
    'Gioia Tauro',
    'Stella Cilento',
    'Sava',
    'Pozzuolo del Friuli',
    'Montanaro',
    'Balangero',
    'Benna',
    'Caluso',
    'Andezeno',
    'Feletto',
    'Tollegno',
    'Cassano',
    'Vigolo Vattaro',
    'Caldaro sulla Strada del Vino',
    'Costabissara',
    'Orgiano',
    'Roncegno',
    "Quarto d'Altino",
    'Riva del Garda',
    'Pedemonte',
    'Laives',
    'Calenzano',
    'Lanciano',
    'Arsago Seprio',
    'Vergiate',
    'Casorate Sempione',
    'Torri di Quartesolo',
    'Chiampo',
    'Monticello Conte Otto',
    'Gandino',
    'Marnate',
    'Carnago',
    'Albino',
    'Artogne',
    'Santa Giustina',
    'Oderzo',
    'Vedelago',
    'Rubiera',
    'Acquasparta',
    'Ruda',
    'Comerio',
    'Zibido San Giacomo',
    'Arese',
    'Robbiate',
    'Meda',
    'Ospedaletto Euganeo',
    'Malcesine',
    'Valenzano',
    'Palagiano',
    'San Giorgio Ionico',
    'Adelfia',
    'San Vito dei Normanni',
    'Costa',
    'Cuorgnè',
    'Vignale Monferrato',
    'Casanova',
    'Selci',
    'Castelnuovo Scrivia',
    'Bubbio',
    'Bellinzago Novarese',
    'Trino',
    'Madonna',
    'Paullo',
    'Cenaia',
    'Lucino',
    'Casazza',
    'Ponte San Nicolo',
    'Locri',
    'Laigueglia',
    'Lerma',
    'Nembro',
    'Predosa',
    'Bobbio',
    'Riccò del Golfo di Spezia',
    'Saluggia',
    'Valperga',
    'Grignasco',
    'Pozzolo Formigaro',
    'San Piero a Sieve',
    'Trebaseleghe',
    'Castelluccio Inferiore',
    'Fontaniva',
    'Lucito',
    'Storo',
    'Alfonsine',
    'Montebello',
    'Fauglia',
    'Ripa-Pozzi-Querceta-Ponterosso',
    'Sannicandro Garganico',
    'Ponzone',
    'Levanto',
    'Maresca',
    'Ponte San Giovanni',
    'Vittoria',
    'Gorgo al Monticano',
    'Revigliasco',
    'Pederobba',
    'Carobbio',
    'Fontana',
    'Castelvecchio',
    'Castelnuovo',
    'Santa Maria degli Angeli',
    'Carbonara di Bari',
    'Albuzzano',
    'Plan da Tieja',
    'San Giorgio in Bosco',
    'Santa Giustina in Colle',
    'Casalvecchio Siculo',
    'Fossombrone',
    'Azzano San Paolo',
    'Marmirolo',
    'Frontone',
    'Colmurano',
    'Castelplanio',
    'Orciano di Pesaro',
    'San Marcello',
    'Montemarciano',
    'Deruta',
    'Antea',
    'Certaldo',
    'Fastello',
    'Montenerodomo',
    'Guardiagrele',
    'Pretoro',
    'Colledimezzo',
    'Resana',
    'Navacchio',
    'Trissino',
    'Fumane',
    'Palmi',
    'Milazzo',
    'Eboli',
    'Asciano',
    'Marineo',
    'Casalvieri',
    'Pietra Ligure',
    'Mareno di Piave',
    'Bagnolo',
    'Zoldo Alto',
    'Veneto',
    'Costa di Rovigo',
    'Affi',
    'Vaglia',
    'Tomba',
    'Tredozio',
    'Venetico',
    'Caccuri',
    'Mendicino',
    'Tempio Pausania',
    'Porto Torres',
    'Macomer',
    'Sennori',
    'Mezzano',
    'Bozzolo',
    'Barberino di Mugello',
    'Greve in Chianti',
    'Fiesole',
    'Casarano',
    'Bagnoli del Trigno',
    'Cavriglia',
    'Castilenti',
    'Collecorvino',
    'Carpenedolo',
    'Santo Stefano di Magra',
    'Negrar',
    'Piasco',
    'Barbarasco',
    'Asso',
    'Belvedere',
    'Seravezza',
    'San Piero in Bagno',
    'Monteprandone',
    'Monteveglio',
    'Grumello del Monte',
    'Cazzago San Martino',
    'Monticelli',
    'Paderno Franciacorta',
    'Scafati',
    'Calvizzano',
    "Sant'Antimo",
    'Vietri sul Mare',
    "Sant'Antonio Abate",
    "Sant'Antonio",
    'Marigliano',
    'Boscotrecase',
    'Pompei',
    'Pugliano',
    'Sorrento',
    'Casalnuovo di Napoli',
    'Frattaminore',
    'Atella',
    'Casandrino',
    'Torre Annunziata',
    'Ottaviano',
    'Appiano sulla strada del vino',
    'Amaro',
    'Piano',
    'Pozza-San Marco',
    'Casto',
    'Ruviano',
    'Arielli',
    'Torrita Tiberina',
    'Castelnuovo di Verona',
    'Allerona',
    'Pellezzano',
    'Procida',
    'Montalbano Jonico',
    'Nova Siri',
    'Avigliano',
    'San Giorgio',
    'Soave',
    'Filiano',
    'Cologna Veneta',
    'Mafalda',
    'Aquino',
    'Vicopisano',
    'Mezzolombardo',
    'Cirié',
    'Gozzano',
    'Ghemme',
    'Chiomonte',
    'San Giorgio di Piano',
    'Case Franchini',
    'Valdengo',
    'Bagnolo Mella',
    "Palazzolo sull'Oglio",
    'Roccavione',
    'Bosconero',
    'Volpiano',
    'Silea',
    'Pofi',
    'Grotte di Castro',
    'Anghiari',
    'Carini',
    'Collesano',
    'Agerola',
    'Province of Messina',
    'Ispica',
    'Rutigliano',
    'Gioia del Colle',
    'Arnesano',
    "Sant'Angelo Muxaro",
    'Padula',
    'Anzano del Parco',
    'Olginate',
    'Olevano sul Tusciano',
    'Miravalle',
    'Il Ciocco',
    'Fontanella',
    'Torri',
    'Aurelia',
    'Villanova',
    'Monaco',
    'Bascapè',
    'Cuggiono',
    'Viola',
    'Diamante',
    'Carpegna',
    'Uggiate Trevano',
    'Orio al Serio',
    'Nizza Monferrato',
    'Quattordio',
    'Nonantola',
    'Ozzano Monferrato',
    'Crespellano',
    'Minerbio',
    'Bentivoglio',
    'Colbordolo',
    'Monte San Pietro',
    'Montefiore Conca',
    'Saludecio',
    'San Leo',
    'Lugo',
    'Magreta',
    'Montese',
    'Montecchio',
    'Pratola Serra',
    'Berlingo',
    'Castagneto Po',
    'Strambino',
    'Agliè',
    'Centallo',
    'Bagnolo Piemonte',
    'Morozzo',
    'Rocca',
    'Lanzo Torinese',
    'Almese',
    'Scalenghe',
    'Volvera',
    'Sommariva del Bosco',
    'Quargnento',
    'Cassano Spinola',
    'Lonate Ceppino',
    'Olgiate Comasco',
    'Verrone',
    'Erbè',
    'Pomponesco',
    'Castel Goffredo',
    'Serravalle Sesia',
    'Valduggia',
    'Coggiola',
    'Agrano',
    "San Maurizio d'Opaglio",
    'Casaleggio Novara',
    'Mottalciata',
    'Masserano',
    'Poncarale',
    'Buscate',
    'Fabbri',
    'Dueville',
    'Seglia San Bernardo',
    'Fornace di Miramare',
    'Busalla',
    'Valentina',
    'Rizzi',
    'Rino',
    'Guido',
    'Nicola',
    'Saline',
    'Manzoni',
    'Pellegrino',
    'Poviglio',
    'San Pietro',
    'Pace',
    'Riviera',
    'Venafro',
    'Ciciliano',
    'Baglio Rizzo',
    'Aragona',
    'San Giuseppe',
    'Atena Lucana',
    'Venturina',
    'Mariano',
    'Lipari',
    'Corno',
    'Fontevivo',
    'Paola',
    'Sasso',
    'Ameglia',
    'Centrale',
    'Portofino',
    'San Francesco',
    'Castelletto',
    'Fabbrico',
    'Villanova Monferrato',
    'Lido di Ostia',
    'Marina di Carrara',
    "Sant'Oreste",
    'Melicucco',
    'Gemona',
    'Fiumicello',
    'Rottofreno',
    'Ittiri',
    'Sedilo',
    'Villasalto',
    'Ploaghe',
    'Selegas',
    'Cornuda',
    'Nurachi',
    'Paternò',
    'Aci Castello',
    'Sinnai',
    'Dolianova',
    'Siliqua',
    'Carbonia',
    'Selargius',
    "Sant'Antioco",
    'Villacidro',
    'Capoterra',
    'Quartucciu',
    'Alleghe',
    'Bagno di Romagna',
    'Ballabio',
    'Baveno',
    'Castronno',
    'San Zenone degli Ezzelini',
    'Salara',
    'Carlazzo',
    'Ternate',
    'Premosello-Chiovenda',
    'Campi',
    'Santa Lucia',
    'Gravellona Toce',
    'Prevalle',
    'Cirimido',
    'Breganze',
    'Borgo Ticino',
    'Pallanza-Intra-Suna',
    'Oliveto Lario',
    'Canzo',
    'Caprino Veronese',
    'Montagnana',
    'San Zeno',
    'Gurro',
    'Cambiago',
    'Brembilla',
    'Castello',
    'Livigno',
    'Sondalo',
    'Trecenta',
    'Falze-Signoressa',
    'Pasturo',
    'Cannobio',
    'Mel',
    'Bianzone',
    'Rancio Valcuvia',
    'Barni',
    'Rosate',
    'Camisano Vicentino',
    'Altopascio',
    'Vecchiano-Nodica',
    'Passarella',
    'Minerbe',
    'Asola',
    'Piazza',
    'Pallerone',
    'Pianello Val Tidone',
    'San Giuliano Terme',
    'Sutri',
    'Anguillara',
    'Sala Bolognese',
    'Montalto delle Marche',
    'Vigolzone',
    'Fontanellato',
    'Gallicano nel Lazio',
    'Caerano di San Marco',
    'Follonica',
    'San Salvatore',
    'Santhià',
    'Cavallirio',
    'Comun Nuovo',
    'Caravaggio',
    'Dalmine',
    'Coccaglio',
    'Sabbioneta',
    'Pergine Valsugana',
    'Cavareno',
    'Taufers im Münstertal',
    'Mori',
    'Dervio',
    'Cogorno',
    'Santa Margherita Ligure',
    'Gazzada Schianno',
    'Gropello Cairoli',
    'Robecchetto con Induno',
    'Godiasco',
    'Aulla',
    'Angera',
    'Cumiana',
    'Ariano nel Polesine',
    'Castiglione delle Stiviere',
    'Vigodarzere',
    'Torreglia',
    'Stanghella',
    'Campo San Martino',
    'Limena',
    'Roveredo',
    'Taggia',
    'Villa Faraldi',
    'Montalto Dora',
    'Azeglio',
    'Mombercelli',
    'Burolo',
    'Villafranca Piemonte',
    "Santa Margherita d'Adige",
    'San Francesco al Campo',
    'Samone',
    'Torre Pellice',
    'Corio',
    'Dogliani',
    "Gradisca d'Isonzo",
    'Monfalcone',
    'San Vito al Tagliamento',
    'Muggia',
    "Cortina d'Ampezzo",
    'Vigasio',
    'Militello in Val di Catania',
    'Noto',
    'San Martino in Rio',
    'Montesarchio',
    'Bisaccia',
    'Dugenta',
    'Capannori',
    'Valmontone',
    'Tossicia',
    'Tolfa',
    'Corato',
    'Nogara',
    "Ronco all'Adige",
    'Santa Lucia di Piave',
    'Braone',
    'Isola Dovarese',
    'Villa Vicentina',
    'Povegliano Veronese',
    'Serravalle Scrivia',
    'Galliate',
    'Ciserano',
    'Cisano Bergamasco',
    'Galbiate',
    'Viadana',
    'Guidizzolo',
    'Ceresara',
    'Orzivecchi',
    'Taio',
    'Castelnuovo Magra',
    'Licciana Nardi',
    'Capriate San Gervasio',
    'Vanzago',
    'San Colombano al Lambro',
    'Tromello',
    'Spineto',
    'Palazzo Canavese',
    'Calimera',
    'Piovene Rocchette',
    'Guastalla',
    'Cengio',
    'Loano',
    'Candia Canavese',
    'Mercenasco',
    'Pessinetto',
    'Verolengo',
    'Rivara',
    'Fontanafredda',
    'Spilimbergo',
    'Basiliano',
    'Arzene',
    'Spresiano',
    'Codroipo',
    'Solagna',
    'Sona',
    'Cefalù',
    'Camporeale',
    'Montelepre',
    'Granitola Torretta',
    'Altavilla Irpina',
    'Piane Crati',
    'Scandiano',
    'Spilamberto',
    'Pavullo nel Frignano',
    'Polesine Parmense',
    'Medesano',
    "Sant'Agata Bolognese",
    'Mirabello',
    'Morrovalle',
    'Sannicandro di Bari',
    'Massafra',
    'Celico',
    "San Gregorio d'Ippona",
    'Villasmundo',
    'Ramacca',
    'Roccalumera',
    'Dipignano',
    'San Demetrio Corone',
    'Calci',
    'San Marco Evangelista',
    'Villa Literno',
    'Caiatia',
    'Monte di Procida',
    'Santa Maria Capua Vetere',
    'Villamagna',
    'Somma Vesuviana',
    'Marsala',
    'Campobello di Licata',
    'Squinzano',
    'Alessano',
    'Casacalenda',
    'San Giovanni',
    'Sigillo',
    'Mesagne',
    'Cellino San Marco',
    'Soleto',
    'Torchiarolo',
    'Narni',
    'Passignano sul Trasimeno',
    'Vernole',
    'Trepuzzi',
    'Cartoceto',
    'Scauri',
    'Sezze',
    'Camerano',
    "Cerreto d'Esi",
    'Pulsano',
    'Ostuni',
    'Palma Campania',
    'San Lucido',
    'Marina di Campo',
    'Terracina',
    'Barga',
    'Mondavio',
    'Ripe San Ginesio',
    'Servigliano',
    'Ostra',
    'Traversetolo',
    "Ponte dell'Olio",
    "Porto Sant'Elpidio",
    'Acerno',
    'Moliterno',
    'Acquaviva Picena',
    'Trinitapoli',
    'Sammichele di Bari',
    'Castelpagano',
    'Santa Croce Camerina',
    'Mazzarino',
    'Castrovillari',
    'Giffoni Valle Piana',
    'Torregrotta',
    "Casole d'Elsa",
    "San Quirico d'Orcia",
    'Cesa',
    'Amalfi',
    'Bacoli',
    'Corleone',
    'Colledara',
    'Spinete',
    'Sambuceto',
    'Jolanda di Savoia',
    'San Vito Lo Capo',
    'Pontenure',
    'Orte',
    'Filettino',
    'Barbiano',
    'Stazione Ponte Rio',
    'Gallipoli',
    "Morro d'Alba",
    'Ponte a Evola',
    'Ripatransone',
    "Baldichieri d'Asti",
    'Caorso',
    'Gatteo a Mare',
    'Cavarzere',
    'Ormea',
    'Bellaria-Igea Marina',
    'Scarlino',
    'Garlenda',
    'Montichiari',
    'Montelparo',
    'Rezzato',
    'Apiro',
    'Castelnuovo dei Sabbioni',
    'Bondeno',
    'Valguarnera Caropepe',
    'Margherita di Savoia',
    'Racalmuto',
    "Sant'Egidio alla Vibrata",
    'Priolo Gargallo',
    'Prizzi',
    'Villa San Sebastiano',
    'Isernia',
    'Barrafranca',
    'Capri',
    'Controguerra',
    'Carinola',
    'Almenno San Bartolomeo',
    'Solbiate Arno',
    'Medolago',
    'Vercurago',
    'Casaglia',
    'Sabbio Chiese',
    'Portoferraio',
    'Jesolo',
    'Arcade',
    'Lancenigo',
    'San Salvatore',
    'Strevi',
    'Oleggio',
    'Pontecurone',
    'San Pellegrino Terme',
    'Valmadrera',
    'Vadena',
    'Dorno',
    'Montù Beccaria',
    'Rescaldina',
    'Borghetto Santo Spirito',
    'Alassio',
    'Cona',
    'Bolzano Vicentino',
    'Casalgrasso',
    'Saliceto',
    'Vallo',
    'Lentiai',
    'Cinto Caomaggiore',
    'Grezzana',
    'Calalzo di Cadore',
    'Cantagallo',
    'Puianello',
    'Marghera',
    'Milena',
    'Santa Flavia',
    'Pieris',
    'Pradamano',
    'Paluzza',
    'Pressana',
    'Tarcento',
    'Maserada sul Piave',
    'Castro dei Volsci',
    'Borgo Vercelli',
    'Suno',
    'Mongrando',
    'Castelleone',
    'Gallignano',
    'Mapello',
    'Palazzo',
    'Vezzano Ligure',
    'Bolano',
    'Cologne',
    'Toscolano-Maderno',
    'Ospitaletto',
    'Ceto',
    'Gazoldo degli Ippoliti',
    'Cembra',
    'Cencenighe Agordino',
    'Lozzo di Cadore',
    'Arosio',
    'Dicomano',
    'San Giorgio di Lomellina',
    'Santa Maria',
    'Candia Lomellina',
    'Lodi Vecchio',
    'Motta Visconti',
    'Maslianico',
    'Rondissone',
    'Maddaloni',
    'Montoro',
    'Tombolo',
    'Castellarano',
    'Ceriale',
    'Casalmaiocco',
    'Busca',
    'Druento',
    'Cogne',
    'Castelnuovo',
    'Bibione',
    "Romans d'Isonzo",
    'Trichiana',
    'Gruaro',
    'Migliarino',
    'Gioiosa Marea',
    'Francavilla di Sicilia',
    'Massarosa',
    'Canossa',
    'Fiorano Modenese',
    'Montefalcione',
    'Montecalvoli',
    'Bazzano',
    'Castello di Serravalle',
    'Gallo',
    'Bovino',
    'Monte Urano',
    'Camerino',
    'San Costanzo',
    'Mola di Bari',
    "San Nicolò d'Arcidano",
    'Grumo Appula',
    'Taormina',
    'Valverde',
    'Nizza di Sicilia',
    'Casale',
    'Roccapiemonte',
    'Castel Volturno',
    'Montevarchi',
    'Scarperia',
    'Solofra',
    'Baselice',
    'Meta',
    'Calatafimi',
    'Paceco',
    "Sant'Angelo d'Alife",
    'Castellalto',
    'Spoleto',
    'Gualdo Cattaneo',
    'Recale',
    'Montelabbate',
    'Piglio',
    'Cinisi',
    'Capena',
    'Pontecorvo',
    'Colleferro',
    'Gatteo',
    'Rocca San Casciano',
    'Punta Marina',
    'Montecastrilli',
    'Taviano',
    'Aradeo',
    'Sannicola',
    'Chiusi della Verna',
    'Vaiano',
    'Fossato di Vico',
    'San Vincenzo',
    'Soragna',
    'Serino',
    'Capua',
    'San Ginesio',
    'Cingoli',
    'Gravina in Puglia',
    'San Giorgio la Molara',
    'Cassano delle Murge',
    'Solarino',
    'Maletto',
    'San Fratello',
    'Squillace',
    'Roccella Ionica',
    'Calangianus',
    'Vinci',
    'Sinalunga',
    'Pimonte',
    'Monte San Giovanni Campano',
    'Grumento Nova',
    'Menfi',
    'Nereto',
    'Ciminna',
    'Monte San Savino',
    'San Gregorio',
    'Campo Tizzoro',
    'Arzano',
    'Minturno',
    'Trevignano Romano',
    'Galatone',
    'Vitigliano',
    'Tricase',
    'Anacapri',
    'Leporano',
    'Catenanuova',
    'Brolo',
    'San Ferdinando',
    'Grazzano Badoglio',
    'Gavardo',
    'Castelverde',
    'Levico Terme',
    'Feldthurns',
    'Klausen',
    'Cles',
    'Lierna',
    'Abbadia Lariana',
    'Lavagna',
    'Varano',
    'Cavacurta',
    'Casatenovo',
    "Albano Sant'Alessandro",
    'Busto Garolfo',
    'Cesana Torinese',
    'Miradolo Terme',
    'Mozzate',
    'Comazzo',
    "Villanova d'Asti",
    'Pettorazza Grimani',
    'Castelnuovo',
    'Tavernola Bergamasca',
    'Capaci',
    'Foglizzo',
    'San Maurizio Canavese',
    'San Maurizio',
    'Gassino Torinese',
    'Rocca Canavese',
    'Valle Mosso',
    'Castelnuovo',
    'Anguillara Veneta',
    'Morbegno',
    'Ossona',
    'Isorella',
    'Marina di Gioiosa Ionica',
    'Casal Velino',
    'Galdo',
    'Gibellina',
    'Falconara',
    'Altomonte',
    'Lovere',
    'Pontestura',
    'Sotto il Monte Giovanni XXIII',
    'Colle',
    'Capergnanica',
    'Volta Mantovana',
    'Borgo di Terzo',
    'Terzo',
    'Camogli',
    'Pieve Albignola',
    'Rovellasca',
    'Castelnuovo',
    'Gaggiano',
    'Mason',
    'Villanova del Ghebbo-Valdentro',
    'Torricella',
    'Vedano Olona',
    'Caprie',
    'Ovaro',
    'San Rocco',
    'Paterno',
    'Cutrofiano',
    'Morano Calabro',
    'Sogliano Cavour',
    'Maglie',
    'Porretta Terme',
    'Torre San Patrizio',
    "Lesignano de' Bagni",
    'Molinella',
    'Calderara di Reno',
    'San Benedetto Val di Sambro',
    'San Cesareo',
    'Bellocchi',
    'Grottazzolina',
    'San Pietro in Lama',
    'Conversano',
    'San Marcello Pistoiese',
    'Belvedere Marittimo',
    'Fontana Liri',
    'Centuripe',
    'Scordia',
    'Riva',
    'Scaletta Zanclea',
    "Serra d'Aiello",
    'Vibo Valentia',
    'San Casciano dei Bagni',
    'Terzigno',
    'Montesano sulla Marcellana',
    'Vairano Patenora',
    'Baronissi',
    'Ripa Teatina',
    'San Vito Chietino',
    'Trevi',
    'Castiglione',
    'Fisciano',
    'Lake Bolsena',
    'Corleto Perticara',
    'Grottaglie',
    'Specchia',
    'Castrolibero',
    'Atessa',
    'Altino',
    'Rio Saliceto',
    'Cortemaggiore',
    'Gossolengo',
    'Ribera',
    'Santa Margherita di Belice',
    'Alberobello',
    'Poggiomarino',
    "Sant'Agnello",
    'Calatabiano',
    'Acate',
    'Acquaro',
    'Pizzo',
    'Cetraro',
    'Bibbiena',
    'Cittàdella Pieve',
    'Scanzano',
    'Alessandria della Rocca',
    'Castelvetrano',
    'Rosciano',
    "Mosciano Sant'Angelo",
    'Otricoli',
    'Magione',
    'San Venanzo',
    "Monte Sant'Angelo",
    'Toffia',
    'Torrice',
    'Fragagnano',
    'Ruffano',
    'Diano Marina',
    'Susegana',
    'Cassacco',
    'Cernobbio',
    'Sassoferrato',
    'Porto Potenza Picena',
    'Vestone',
    'Colle Umberto',
    'Montegrotto Terme',
    'Canale',
    'Spinetoli',
    'Villafrati',
    'Pula',
    'Gioiosa Ionica',
    'Cellole',
    'Sicignano degli Alburni',
    'San Cassiano',
    'Castrignano del Capo',
    'Besnate',
    'Civitella del Tronto',
    'Falciano',
    'Lariano',
    'Gragnano',
    'Cantalupo in Sabina',
    'Castel Frentano',
    'Fagagna',
    'Montebello Vicentino',
    'Castiglion Fibocchi',
    'Castel San Giovanni',
    "Incisa in Val d'Arno",
    'Fermignano',
    'Magliano in Toscana',
    'Santa Maria a Monte',
    'Fumone',
    'Agliano',
    'Tronzano Vercellese',
    'Occhieppo Superiore',
    'Riva presso Chieri',
    'Palosco',
    'Villa di Serio',
    'Chiavenna',
    'Vobarno',
    'Calvatone',
    'Salò',
    'Mezzocorona',
    'Lerici',
    'Campo Ligure',
    'Serra Riccò',
    'Lago',
    'Zeme',
    'Zelo Buon Persico',
    "Cassina de' Pecchi",
    'Mornago',
    'Trezzano Rosa',
    'Carmignano di Brenta',
    'Front',
    'Cervere',
    "Sant'Ambrogio di Torino",
    'Gonars',
    'Chions',
    'San Gregorio da Sassola',
    'Rapagnano',
    'Lastra a Signa',
    'Contursi Terme',
    'Lanuvio',
    'Agordo',
    'Castel San Pietro Romano',
    'Cerano',
    'Spigno Saturnia',
    'Martinengo',
    'Dongo',
    'Colere',
    'Calascibetta',
    'Lonato',
    'Ospedaletto',
    'Calliano',
    'Ortonovo',
    'Guamaggiore',
    'Canneto Pavese',
    'Lana',
    'Campione',
    'Cavagnolo',
    'Pastrengo',
    'ArquàPetrarca',
    'Loreggia',
    'Trequanda',
    'Cocquio-Trevisago',
    'Albano Vercellese',
    'Rignano Flaminio',
    'Trevignano',
    'Postioma',
    'Teggiano',
    'Rapolla',
    'San Giovanni Rotondo',
    'Saponara',
    'San Sosti',
    'Badolato',
    'Rubbiano',
    'Casale di Mezzani',
    'Noceto',
    "Anzola dell'Emilia",
    'Porto Garibaldi',
    'Monzuno',
    'Noci',
    'Mogoro',
    'Serrenti',
    'Narcao',
    'Enna',
    'Santa Venerina',
    'Santa Teresa di Riva',
    'Santa Caterina Villarmosa',
    'Stalettì',
    'Civitella in Val di Chiana',
    'Rufina',
    'Camerata Picena',
    'Macerata Campania',
    'Abbasanta',
    'Bucchianico',
    'Bellante',
    'Canistro',
    'Penne',
    'Castiglione del Lago',
    'Novafeltria',
    'Corfinio',
    'Riccia',
    'Oppido Lucano',
    'San Martino',
    'Torella dei Lombardi',
    'Marcellina',
    'Allumiere',
    'Mazzano Romano',
    'San Cesario di Lecce',
    'Parabita',
    'Striano',
    'Cancello ed Arnone',
    'Amorosi',
    'Francavilla in Sinni',
    'Amelia',
    'Castellabate',
    'Bova Marina',
    'Sorbolo',
    'Reno',
    'Falerone',
    'Colli del Tronto',
    'Castel Colonna',
    'Cisternino',
    'Polignano a Mare',
    'Domusnovas',
    'Alife',
    'Itri',
    'Antrodoco',
    'Itala',
    'Piraino',
    'Rometta',
    'Castellina in Chianti',
    'Cerreto Guidi',
    'Reggello',
    'Poggio',
    'Leverano',
    'Castellana Sicula',
    'Gubbio',
    'Nicotera',
    'Ripafratta-Farneta',
    'Priverno',
    'Cittaducale',
    'Poiana Maggiore',
    'Santorso',
    'Costermano',
    'Ampezzo',
    'Rivarolo Mantovano',
    'Cimego',
    'Cavaglià',
    'Frascaro',
    'Esino Lario',
    'Trescore Cremasco',
    'Pisogne',
    'Arcene',
    'Rovetta',
    'Prata Camportaccio',
    'Pizzighettone',
    'Pegognaga',
    'Villa Lagarina',
    'Lasino',
    'Castelletto sopra Ticino',
    'Verolavecchia',
    'Airuno',
    'Valle',
    'Ornago',
    'Peglio',
    'Rubiana',
    'Chambave',
    'Cherasco',
    "Sant'Albano Stura",
    'Gorizia',
    'Dignano',
    'Musile di Piave',
    'Borgo',
    "Monforte d'Alba",
    'Pompiano',
    'Cavaion Veronese',
    'Tolmezzo',
    'Cordovado',
    'Sospirolo',
    'Samarate',
    'Pandino',
    'Bottanuco',
    'Sergnano',
    'Buscoldo',
    'Bovezzo',
    'Villa Guardia',
    'Trivero',
    'Quinto di Treviso',
    'Brembate',
    'Magnago',
    'Saletto',
    'Stra',
    'Pontedassio',
    'Cigliano',
    'Ceres',
    'Peveragno',
    'Verrès',
    'Alimena',
    'Casarsa della Delizia',
    'Buia',
    'Cerete',
    'Monte San Vito',
    'Cologna',
    'Domanico',
    'Sommatino',
    'Altavilla Silentina',
    'Palazzolo Acreide',
    'Monteodorisio',
    'Stornarella',
    'Cassine Superiore',
    'Turi',
    'Ales',
    'Scilla',
    'Soverato Marina',
    'Mesoraca',
    'Bovalino',
    'Marina',
    'San Pietro a Maida',
    'Trebisacce',
    'Castelpoto',
    'Quarto',
    'Morrone',
    'Barra',
    'Bracigliano',
    'Sgurgola',
    'Sessa Aurunca',
    'Escalaplano',
    'Codaruina',
    'Orvieto',
    'Russi',
    'Vitulazio',
    'Salice Salentino',
    'Zollino',
    'Puglianello',
    'Ailano',
    'Arienzo',
    'Medolla',
    'Guardavalle',
    'Bientina',
    'San Gavino Monreale',
    'Baunei',
    'Ozieri',
    'Erchie',
    'Muro Leccese',
    'Gimigliano',
    'Chiesina Uzzanese',
    'Santa Caterina dello Ionio',
    'Morcone',
    'Buccino',
    'Norcia',
    'Narni Scalo',
    'Spello',
    'Ceccano',
    'Artena',
    'Rocca Massima',
    'Muros',
    'Reggiolo',
    'Roccastrada',
    'Romentino',
    'Zumaglia',
    'Ornavasso',
    'Cressa',
    'Buronzo',
    'Creazzo',
    'Villafranca in Lunigiana',
    'Suardi',
    'San Giovanni Bianco',
    'Sermide',
    'Sirmione',
    'Welsberg-Taisten',
    'Coseano',
    'Menaggio',
    'Casargo',
    'Carasco',
    'Caravino',
    'Rivalba',
    'Villafranca di Forli',
    'Crespino',
    'San Biagio di Callalta',
    'Celle Ligure',
    'Carignano',
    'Avigliana',
    'Leinì',
    'Bagnara Calabra',
    'Manzano',
    'Torri del Benaco',
    'Verucchio',
    'Giavera del Montello',
    'Santa Maria di Sala',
    'Izano',
    'Amandola',
    'San Giorgio di Nogaro',
    'Gaiarine',
    'Capistrano',
    'San Cataldo',
    'Salvirola',
    'Brignano',
    'Ponte',
    'Borno',
    'Passirano',
    'Dimaro',
    'Livinallongo del Col di Lana',
    'Arsiè',
    'Subiaco',
    'Borzonasca',
    'Castello',
    'Cavenago di Brianza',
    'Zerbo',
    'Gavi',
    'Laveno-Mombello',
    'Domegliara',
    'Grumolo delle Abbadesse',
    "Paderno d'Adda",
    'Vahrn',
    'Margarita',
    'Badia Calavena',
    'Stretti',
    'Castellana Grotte',
    'Rivello',
    'Altavilla Milicia',
    'Cascia',
    'Roccaromana',
    'Zangarona',
    'Boretto',
    'Langhirano',
    'Ferriere',
    'Piandimeleto',
    'Monte San Giusto',
    'Sorgono',
    'Pietraperzia',
    'Zambrone',
    'Vernio',
    'Chiusano di San Domenico',
    'Tagliacozzo',
    'Palagonia',
    'Mondragone',
    'Ponte Buggianese',
    'Poggiardo',
    'Isola del Giglio',
    'Volterra',
    'Taurianova',
    'Africo Nuovo',
    'Porto',
    'Cugnoli',
    'Gissi',
    'Villafranca Tirrena',
    'Francofonte',
    'Serra Pedace',
    'San Gennaro Vesuviano',
    'Serre',
    'Picerno',
    'Frasso Telesino',
    'Concordia sulla Secchia',
    'Vacri',
    'Tortoreto',
    'Buggiano',
    'Pignataro Interamna',
    'Santi Cosma e Damiano',
    'Tuglie',
    "Zanca-Sant'Andrea",
    'Sulzano',
    'Bruino',
    'Marone',
    'Brusnengo',
    'San Benigno Canavese',
    'Origgio',
    'Marignano',
    'Selva',
    'Bastiglia',
    'Ravarino',
    'Tirrenia',
    'Palmas Arborea',
    'Colliano',
    'Cavallino',
    'San Piero Patti',
    'Casamarciano',
    'Montefredane',
    'Manocalzati',
    'Sortino',
    'Golfo Aranci',
    'Serramezzana',
    'Airola',
    'Roccabernarda',
    'Filadelfia',
    'Soveria Mannelli',
    'Caldarola',
    'Vizzini',
    'Ponte a Moriano',
    'Santa Fiora',
    'Podenzana',
    "Santo Stefano d'Aveto",
    'San Colombano Certénoli',
    'Moneglia',
    'Mignanego',
    'Campofilone',
    'Petritoli',
    'Colle',
    'Mantovana',
    'Montanara',
    'Alcara li Fusi',
    'Seminara',
    'Moglia',
    'Acquafredda',
    'Gualtieri',
    'Gottolengo',
    'Orzinuovi',
    'Bronzolo',
    'Aldeno',
    'Dro',
    'Bosisio Parini',
    'Casaloldo',
    'Rivarolo del Re ed Uniti',
    'Mesola',
    'Piano di Coreglia-Ghivizzano',
    'Viserba',
    'Robecco sul Naviglio',
    'Marsciano',
    'Nanto',
    'Chiusanico',
    'Feroleto della Chiesa',
    'Valdieri',
    'Montalenghe',
    'Cintano',
    "Costigliole d'Asti",
    'Pessione',
    'Casalmaggiore',
    'Nimis',
    'Lizzola',
    'Piadena',
    'Rodeneck-Rodengo',
    'Auronzo di Cadore',
    'Santa Lucia',
    'Tarquinia',
    'Terrasini',
    'Castelraimondo',
    'Pontida',
    'Fontanella',
    'Carenno',
    'Casalromano',
    'Poggio Rusco',
    'San Vito di Cadore',
    'Cassolnovo',
    'Casorate Primo',
    'Robbio',
    'Viagrande',
    'Torrebelvicino',
    'San Nazario',
    'Salzano',
    'Vellezzo Bellini',
    'Bressana Bottarone',
    'La Morra',
    'Bollengo',
    'Badia Polesine',
    'Mereto di Tomba',
    'Campoformido',
    'Cagliari',
    'Inveruno',
    'Berceto',
    'Guiglia',
    'Roncalceci',
    "Barano d'Ischia",
    'San Felice sul Panaro',
    'Ferrara',
    'Galliera',
    'Vigarano Pieve',
    'Bari Sardo',
    "Corigliano d'Otranto",
    'Francavilla Fontana',
    'Riesi',
    'Chiaravalle Centrale',
    'Esperia',
    'Partanna',
    'Capistrello',
    'San Giustino',
    'Giove',
    'Montefranco',
    'Alatri',
    'Norma',
    'San Felice Circeo',
    'Ugento',
    'Caggiano',
    'Roccamonfina',
    'Locorotondo',
    'Grimaldi',
    'San Rufo',
    'Scanzano Jonico',
    'Castelbuono',
    'Misilmeri',
    'Cave',
    'Stagno',
    'Ribolla',
    'San Pancrazio Salentino',
    'Davoli',
    'Castiglion Fiorentino',
    'Brancaleone',
    'Vallefiorita',
    'Prata',
    "San Mango d'Aquino",
    'Casaratta',
    'Volla',
    'Minervino Murge',
    'Canosa di Puglia',
    'Altidona',
    'Vezzano sul Crostolo',
    'Monte Vidon Corrado',
    'San Ferdinando di Puglia',
    'Thiesi',
    'Silanus',
    'Adrano',
    'Spezzano Albanese',
    'Tropea',
    'Civitella Marittima',
    "Barberino Val d'Elsa",
    'Seano',
    'Aiello del Sabato',
    'Cattolica Eraclea',
    'Atri',
    'Rio Marina',
    'Blera',
    'Fusignano',
    'Talsano',
    'Riolo Terme',
    'Castelnovo di Sotto',
    'Migliarino',
    'Bernate Ticino',
    'Murlo',
    'Cusano Mutri',
    'Buonabitacolo',
    'Bompietro',
    'Montorio al Vomano',
    'Castiglione di Garfagnana',
    'Bagnara di Romagna',
    'San Pietro Vernotico',
    'Passo Corese',
    'Montalto Uffugo',
    'Pontecagnano',
    'Vieste',
    'Butera',
    'Siderno',
    'Spezzano della Sila',
    'Lauro',
    'Montevago',
    'Vanzaghello',
    'Saturnia',
    'Civitella San Paolo',
    'San Secondo Parmense',
    'Pietracuta',
    'Arquata Scrivia',
    'Pogliano Milanese',
    'Flero',
    'Caorle',
    'Mortegliano',
    'Villanuova sul Clisi',
    'Cairate',
    "Sant'Antonino",
    'Monteriggioni',
    'Ponso',
    'Corno Giovine',
    'Mascali',
    'Rossano',
    'Rocca di Papa',
    'Polla',
    'Poggio Mirteto',
    "Sant'Andrea",
    'Corigliano Calabro',
    'Futani',
    'San Cipirello',
    'Sala Consilina',
    'Torviscosa',
    'Quinto Vercellese',
    'Mergozzo',
    'Livorno Ferraris',
    'Breno',
    'Arignano',
    'San Gillio',
    'Moretta',
    'La Salle',
    'Foiano della Chiana',
    'Borgaro Torinese',
    'Trana',
    'Chianciano Terme',
    'Ospedaletti',
    'Soncino',
    'Dubino',
    'Vescovato',
    'Bienno',
    'Acquafredda Inferiore',
    'Prad am Stilfser Joch',
    'Pignone',
    'Campomorone',
    'San Fiorano',
    'Confienza',
    'Canaro',
    'Ozegna',
    'Gaggio Montano',
    'Collepasso',
    'Lugnano in Teverina',
    'Sangano',
    'Carlino',
    'Loria',
    'Bagnolo San Vito',
    'La Salute di Livenza',
    'Custonaci',
    'Porto Empedocle',
    'Roasio',
    'Beinette',
    'Edolo',
    'Neumarkt',
    'Naturns',
    'Bertonico',
    'Turbigo',
    'Filighera',
    'Giussago',
    'Senago',
    'Follina',
    'San Germano Vercellese',
    'Buriasco',
    'Oulx',
    'San Lorenzo Isontino',
    'San Quirino',
    'Marina di Ragusa',
    'Tramonti',
    'Sibari',
    'Praia a Mare',
    'Rogliano',
    'Codigoro',
    'Altedo',
    'Serra San Quirico',
    'Curcuris',
    'Scicli',
    'Baiano',
    'Villa San Giovanni',
    'Casteldaccia',
    'Santo Stefano di Briga',
    'Pianella',
    'Moscufo',
    'Lettopalena',
    'Giove',
    'Caianello',
    'Lauria',
    'Lari',
    "Lugagnano Val d'Arda",
    'Santa Sofia',
    'Latiano',
    'Troia',
    'Nicolosi',
    'Casal di Principe',
    'Voghenza',
    'Benestare',
    'Monastir',
    'Tertenia',
    'Masullas',
    'Formicola',
    'Zafferana Etnea',
    'Canicattini Bagni',
    'Siculiana',
    'Miglianico',
    'San Giovanni Suergiu',
    'Vigarano Mainarda',
    'Rocca Priora',
    'Carpineto Romano',
    "Sant'Agata sul Santerno",
    'Gambara',
    'Serraglio',
    'Cimitile',
    'Popoli',
    'Cortandone',
    'Bianzè',
    'Terruggia',
    'Barzana',
    'San Severino Marche',
    'San Lorenzo',
    'Sexten',
    'Avio',
    'Figino',
    'Cozzo',
    'Caravate',
    'Copiano',
    'Valle Lomellina',
    'Arqua Polesine',
    'Lombardore',
    'Scarmagno',
    'Borgone',
    'San Pietro di Cadore',
    'Montegaldella',
    'Arzignano',
    'Lendinara',
    "Fara Gera d'Adda",
    'Montalbano',
    'Graglia',
    'San Carlo',
    'Pezzana',
    'Ghisalba',
    'Calvisano',
    'Corte Franca',
    'Villastrada',
    'Lajen',
    'Cittiglio',
    'Caselle Lurani',
    'Pantigliate',
    'Conselve',
    'Bevilacqua',
    'Cervo',
    'Moimacco',
    'Ponte nelle Alpi',
    'Ponte',
    'Agnone',
    'Castelfranci',
    'Paternopoli',
    'Villamar',
    'Barumini',
    'Rizziconi',
    'Cinquefrondi',
    'Montemiletto',
    'Pelago',
    'Buti',
    'Ancarano',
    'Larino',
    'Arrone',
    'Castagneto Carducci',
    'Lido di Camaiore',
    'Castelnuovo di Val di Cecina',
    'Marlia',
    'Vasanello',
    'Lenola',
    'Camerlona',
    'Tufino',
    'Rovito',
    'Muro Lucano',
    'Moio della Civitella',
    'Bellona',
    'Strongoli',
    'Loiano',
    'Mergo',
    'Bernalda',
    'Verbicaro',
    'Longobardi',
    'Pontassieve',
    "Sant'Andrea di Conza",
    'Ventimiglia di Sicilia',
    'Corropoli',
    'Ponza',
    'Policoro',
    'Monteiasi',
    'Mottola',
    'Calcio',
    'Roveredo in Piano',
    'Bovolenta',
    'Corsano',
    'Dronero',
    "Farra d'Alpago",
    'Balvano',
    'Panaro',
    'Druogno',
    'Traona',
    'Filo',
    'Ascoli Satriano',
    'Circello',
    'Fornovo di Taro',
    'Argenta',
    'Monteforte Irpino',
    'Avola',
    'Milo',
    'Fossacesia',
    'Abbadia San Salvatore',
    'Locati',
    'Santo Stefano Quisquina',
    "Sant'Omero",
    'Castello',
    'Castelnuovo di Porto',
    'Carovigno',
    'Malalbergo',
    'Montegiorgio',
    "Torre de' Passeri",
    'Loreto Aprutino',
    'Monsampolo del Tronto',
    'Rivalta',
    'Cadeo',
    'Ghiffa',
    'Castellar Guidobono',
    'Valfenera',
    'Camisano',
    'Gazzuolo',
    'Comano',
    'Sale Marasino',
    'Longarone',
    'San Marco',
    'San Giorgio Canavese',
    'Ala di Stura',
    'Montecassiano',
    'Buttrio',
    'Favaro Veneto',
    'Mühlbach',
    'Caldonazzo',
    'Ponte Basso',
    'Calitri',
    'San Mango',
    'Nocera Terinese',
    'Armeno',
    'Gardone Val Trompia',
    'Gonzaga',
    'Toblach',
    "Villafranca d'Asti",
    'Bornasco',
    'Lomello',
    'Arcugnano',
    'Pocenia',
    'Moiano',
    'Triggianello',
    'Nuraminis',
    'Nurri',
    'Chiaramonte Gulfi',
    'Borgia',
    'Laureana di Borrello',
    'Laino Borgo',
    'Castel San Lorenzo',
    'Pisticci',
    'Raffadali',
    'Bagni di Lucca',
    'Arce',
    'Lesina',
    'Surbo',
    'Alliste',
    'Poggio Moiano',
    'Cerreto Laziale',
    'San Filippo',
    'Pianella',
    'Potenza Picena',
    'Ricadi',
    'Poggio a Caiano',
    'Aquilonia',
    'Colonnella',
    'Mugnano',
    'Atina',
    'Campi Salentina',
    'San Giovanni al Natisone',
    'Neive',
    'Castagnole',
    'Crespano del Grappa',
    'Molazzana',
    'Ottiglio',
    'Issime',
    'Cavriana',
    'Tresenda',
    'Grigno',
    'Rossiglione',
    'Mede',
    'Rognano',
    'Vernate',
    'Solesino',
    'Ceregnano',
    'Cuceglio',
    'Torrazza',
    'Carrù',
    'San Martino al Tagliamento',
    'Revine',
    'Pian Camuno',
    'Zimella',
    'Taverna',
    'Poggio Berni',
    'Gambettola',
    "Sant'Angelo in Vado",
    'Venarotta',
    'San Marco in Lamis',
    'Fluminimaggiore',
    'Cavriago',
    'San Giorgio a Liri',
    'Biancano',
    'San Marco',
    'Gallese',
    'Portico di Caserta',
    'Santa Elisabetta',
    'Vallo della Lucania',
    'Cappelle sul Tavo',
    'Baschi',
    'Vajont',
    'Camino',
    'Barile',
    'Diecimo',
    'Campiglia Marittima',
    'Castel Giorgio',
    'Galatina',
    'Goito',
    'Oliva',
    'Anfo',
    "Sant'Antonino di Susa",
    'Fontanarosa',
    'San Pietro Viminario',
    'Annicco',
    'Pianezze',
    'San Martino',
    'Bellariva',
    'Bedollo',
    'Bosco Chiesanuova',
    'Spin',
    'Rivodutri',
    'Poggio Nativo',
    'Fara in Sabina',
    'Cantalice',
    'Battaglia Terme',
    'Fiera',
    'Regina',
    'Pian-Fossal',
    'Gabbiano',
    'Postiglione',
    'Abetone',
    'Roccella',
    'Montorso Vicentino',
    'Coppe',
    'San Polo',
    'Medea',
    'Lapio',
    "Fiesso d'Artico",
    'San Vittore',
    'Sotto Castello',
    'Gorle',
    'Varese Ligure',
    'Rocchetta di Vara',
    'Gazzola',
    'Sernaglia della Battaglia',
    'Cordignano',
    'Pero',
    'Pieve del Cairo',
    'Villetta',
    'Capriolo',
    'Poggio Renatico',
    'Spineda',
    'San Giorgio della Richinvelda',
    'Scaldasole',
    'Rodigo',
    'Mathi',
    'Duino-Aurisina',
    'Villa Bartolomea',
    'Bagnoli di Sopra',
    'Numana',
    'Ceprano',
    'Minori',
    'San Miniato Basso',
    'Lercara Friddi',
    'Castello',
    'Renazzo',
    'Ramiseto',
    'San Giovanni in Fiore',
    "Robecco d'Oglio",
    'Pozzonovo',
    'Villa Verucchio',
    'Gardone Riviera',
    'Tassullo',
    'Agliana',
    'Piancastagnaio',
    'Roseto Valfortore',
    'Villa Basilica',
    'Roccarainola',
    'Niederdorf',
    'Venegono Inferiore',
    'Valli del Pasubio',
    'Marcallo con Casone',
    'San Lorenzo al Mare',
    'Cavallermaggiore',
    'Montecchio',
    'Martignacco',
    'Gonnesa',
    'Revello',
    'Castellengo',
    'Torre del Lago Puccini',
    'Orta Nova',
    'Castellammare del Golfo',
    'Cuglieri',
    'Ripi',
    'Umbertide',
    'San Sperate',
    'Bonea',
    'Torre Santa Susanna',
    'Monteroni di Lecce',
    'Isola del Liri',
    'Briatico',
    'Porano',
    'Ticengo',
    'Montafia',
    'Ginosa',
    'Sassetta',
    'Bussi sul Tirino',
    'Ronciglione',
    'Vistrorio',
    'Cicagna',
    'Cesara',
    'Fiuggi',
    'Liveri',
    'Torre di Ruggiero',
    'Valganna',
    'Vena di Maida',
    'Casa Bartolacelli-Stella',
    'Arcole',
    'Piegaro',
    'Novalesa',
    'Torrile',
    'Polverara',
    'Molino',
    'Soresina',
    'Casalbuttano ed Uniti',
    'Cignone',
    'Ripalta Cremasca',
    'Pescarolo ed Uniti',
    "Cappella de' Picenardi",
    'Garibaldi',
    'Doria',
    'Riese Pio X',
    'Giuliana',
    'Inverigo',
    'Cantalupo Ligure',
    'Alice Castello',
    'Pallanzeno',
    'Briga Novarese',
    'Garlate',
    'San Zenone al Lambro',
    'Zocca',
    'Tessera',
    'Castellazzo Bormida',
    'Cremolino',
    'Beura-Cardezza',
    'Pagazzano',
    'Sanluri',
    'Lenna',
    'Roccafranca',
    'Villanterio',
    'Casaletto Spartano',
    'Montappone',
    'Belmonte Piceno',
    'Santa Maria',
    'Vicchio',
    'Ceppaloni',
    'Gaggi',
    'Isola del Gran Sasso',
    'San Sossio Baronia',
    "Montegrosso d'Asti",
    'Castel Bolognese',
    'Roncone',
    'Borgo San Giacomo',
    'Montanaso Lombardo',
    'Algund',
    'Strada San Zeno',
    'Pontelongo',
    'Bordighera',
    'Casarile',
    'Terricciola',
    'Remanzacco',
    'Casteltermini',
    'Castel del Piano',
    'Cropani',
    'Torrita di Siena',
    'Poppi',
    'Ocre',
    'Castiglione di Cervia',
    'Paciano',
    'San Marzano sul Sarno',
    'Carentino',
    'Madignano',
    'San Lazzaro degli Armeni',
    'Morter',
    'Taino',
    'Biandronno',
    'Lavena Ponte Tresa',
    'Felegara',
    'Campogalliano',
    'Villa',
    'Casalserugo',
    'Piombino Dese',
    'Pecetto',
    'Venaus',
    'Strigno',
    'Raldon',
    'Campodenno',
    'Guspini',
    'Montemaggiore al Metauro',
    'Assoro',
    'Fornaci',
    'Curinga',
    'Montalcino',
    'Pago Veiano',
    'Campobello di Mazara',
    'Catignano',
    'Oriolo Romano',
    'Tuscania',
    'Roviano',
    'Stimigliano',
    'Erve',
    'Cividate Camuno',
    'Settimo Vittone',
    'Lallio',
    'Leffe',
    'Salorno',
    "Canneto sull'Oglio",
    'Cornedo Vicentino',
    'Sillavengo',
    'Barbarano Vicentino',
    'Grantorto',
    'Eraclea',
    'La Maddalena',
    'Frascineto',
    'Tolve',
    'Santa Maria',
    'Scerni',
    'Meldola',
    'Cori',
    'Calcinate',
    'Foresto',
    'Marcaria',
    'Seniga',
    'Castellucchio',
    'Costa Volpino',
    'Bellano',
    'Viscone',
    'Ossago Lodigiano',
    'Carpineti',
    'Piazzola sul Brenta',
    'Terzorio',
    'Dego',
    'Dolceacqua',
    'Bardonecchia',
    'Crevacuore',
    'Pollein',
    'Sgonico',
    'Sutrio',
    'Lazise',
    'Appignano',
    'San Lorenzo in Campo',
    'Case Catania',
    "Sant'Arcangelo",
    'Coppito',
    'Stia',
    'San Zaccaria',
    'Castellina Marittima',
    'Vallerano',
    'San Donato',
    'Monsano',
    'Gradara',
    'San Benedetto Po',
    'Gerenzago',
    'Chiarano',
    'Maccagno',
    'Corteolona',
    'Fiesso Umbertiano',
    'Deiva Marina',
    'Terrarossa',
    'Premariacco',
    'Bianconese',
    'Basaldella',
    'Lamon',
    'Settimo San Pietro',
    'Escolca',
    'Santo Stefano di Rogliano',
    'Sersale',
    'Croce',
    'Sarteano',
    'Prata Sannita',
    'Mas',
    'Giuliano Teatino',
    'Larciano',
    'Gagliano del Capo',
    'Faggiano',
    'Alezio',
    'Carvico',
    'Capaccio',
    'Offlaga',
    'Pacentro',
    'Fosdinovo',
    "Cavenago d'Adda",
    'Paratico',
    'Torriana',
    'Lesa',
    'Cavour',
    'Tarvisio',
    'Pinzano al Tagliamento',
    'Pollenza',
    'Montefortino',
    'Levane',
    'Castignano',
    'Valle Agricola',
    'Vallata',
    'Sala Baganza',
    'Campegine',
    'Bucine',
    'Guagnano',
    'Roccadaspide',
    'Galliera Veneta',
    "Sant'Agostino",
    'Mazzo di Valtellina',
    'Villasor',
    'Paulilatino',
    'Gabbro',
    'Sindia',
    'Regina Margherita',
    'Flussio',
    'Gavoi',
    'Santo Stefano Belbo',
    'Migliaro',
    'Vo',
    'Sorisole',
    'Manziana',
    'Valentano',
    'Guardamiglio',
    'Bettola',
    'Perinaldo',
    'Brosso',
    'Codevigo',
    'Fanna',
    'Tuili',
    'Pratovecchio',
    'Vignanello',
    "Massa d'Albe",
    'Montenero di Bisaccia',
    'Panicale',
    'Cupramontana',
    'Cagli',
    'Vallombrosa',
    'Tavarnelle Val di Pesa',
    'Massa',
    'Borrello',
    'Avigliano Umbro',
    'Ravi',
    'Ortelle',
    'Gordona',
    'Esine',
    'Arcola',
    'Pellegrino Parmense',
    'Campodoro',
    'Villa',
    'Vergato',
    'Settimo',
    'Flaibano',
    'Folgaria',
    'Boffalora sopra Ticino',
    'Albizzate',
    'Monticiano',
    'Castel Gandolfo',
    'Envie',
    'Busseto',
    'Virgilio',
    'Sëlva',
    'Schiavon',
    'Motta San Guglielmo',
    'Baia',
    'Gazzo',
    'Melara',
    'Dossobuono',
    'Neviano degli Arduini',
    'Compiano',
    'Piano',
    'Neirone',
    'Osiglia',
    'Borghetto di Vara',
    'Caravonica',
    'Localita della Chiesa',
    'San Bartolomeo',
    'Diano San Pietro',
    'Lacco Ameno',
    'Arborio',
    'Cambiano',
    'Cerredolo',
    'Pian di Scò',
    'Rassina',
    'Chitignano',
    'Altavilla',
    'Buttigliera Alta',
    'San Giovanni',
    'Coredo',
    'Pannarano',
    'Francolise',
    'San Cesario sul Panaro',
    'Salvaterra',
    'Corvara',
    'Deutschnofen',
    'Marche',
    'Frattocchie',
    'Villa Minozzo',
    'Gonnosfanadiga',
    'Arborea',
    'Albaredo',
    'Saint-Pierre',
    'Istrana',
    'Pont-Saint-Martin',
    'Castiglione',
    'Lavello',
    'Uzzano',
    'Montale',
    'Tribiano',
    'Simaxis',
    'Codrongianos',
    'Isili',
    'Siapiccia',
    'Orsenigo',
    'Castello',
    'Nave',
    'Tione di Trento',
    'San Lorenzo del Vallo',
    'Caulonia',
    'Vidigulfo',
    'Fontanelle',
    'Santa Barbara',
    'Santi',
    'Selvino',
    'San Martino Canavese',
    'Brebbia',
    'Tenno',
    'Seborga',
    'Caminetto',
    'Tina',
    'Castiglione',
    "Albaredo d'Adige",
    'San Pietro Mosezzo',
    'Marini',
    'Maggio',
    'Palazzolo dello Stella',
    'Meran',
    'Grotta',
    'Narbolia',
    'Posta Vecchia',
    'Castello',
    'Rose',
    'Fontanelle',
    'San Luca',
    'Tornaco',
    'Limatola',
    'Viano',
    'Baiso',
    'Montefiorino',
    'Maser',
    'Voltago',
    'Vigo Rendena',
    'Predazzo',
    'Vigo di Fassa',
    'Ronco',
    "Torre de' Busi",
    'Villavesco',
    'Pilcante',
    'Quistello',
    'Rossano Veneto',
    'Antignano',
    'Cerrione',
    'Campagnola',
    'Caselle',
    'Villanova',
    'Cavaria con Premezzo',
    'Castello',
    'Brissago-Valtravaglia',
    'Lozzo Atestino',
    'Soldano',
    'Castellaro',
    'Santa Vittoria di Libiola',
    'Châtillon',
    'Breda di Piave',
    'Delianuova',
    'Lungro',
    'Corinaldo',
    'Lucera',
    'Castroreale',
    'Fuscaldo',
    'Castiglione Cosentino',
    'Corea Castelluccia San Paolo',
    'San Giovanni a Piro',
    'Prezza',
    'Lucugnano',
    'Quero',
    'Concordia Sagittaria',
    'Tarzo',
    'Burano',
    'Bogliasco',
    'San Polo di Piave',
    'Castione della Presolana',
    'Castions di Strada',
    'Mossa',
    'San Polo',
    'Ponti',
    'Ponte di Barbarano',
    'Monteroduni',
    'Borgosatollo',
    'Villapiana',
    'Osoppo',
    "Sant'Agata Feltria",
    'Monterubbiano',
    'Montefano',
    'Siamaggiore',
    'Usellus',
    'Carmignano',
    'Roccavivi',
    'Roccasecca',
    'Solarolo',
    'Vattaro',
    'Verolanuova',
    'Strona',
    'Cedrasco',
    'Breguzzo',
    'Portovenere',
    'Sparanise',
    'Calice al Cornoviglio',
    'Cisano sul Neva',
    'Airasca',
    'San Pietro di Feletto',
    'Nuvolento',
    'Ferno',
    'Stroppiana',
    'Schlanders',
    'Canazei',
    'Riomaggiore',
    'Santa Giuletta',
    'Riva Ligure',
    'Tarantasca',
    'Salassa',
    'Viguzzolo',
    'Borgo Val di Taro',
    'Rolo',
    'Castiglione dei Pepoli',
    'Appignano del Tronto',
    'Uta',
    'Girifalco',
    'Campo',
    'Forio',
    'Campolieto',
    'San Vito Romano',
    'Gradoli',
    'Patrica',
    'Otranto',
    'Cerreto Sannita',
    'Terranova da Sibari',
    'Cupra Marittima',
    'Laterina',
    'Serrara Fontana',
    'Siano',
    'Castello',
    'Grottolella',
    'Piedimonte San Germano',
    'Civitella di Romagna',
    'Presicce',
    'Castelcucco',
    'Piavon',
    'Capriva del Friuli',
    'Pieve di Cadore',
    'San Biagio',
    'Conco',
    'Provesano',
    'Deggiano',
    'Sizzano',
    'Tres',
    'Marola',
    'Correzzola',
    'Nove',
    'Ceva',
    'Sparone',
    'Caselette',
    'Montefiascone',
    'Melissano',
    'Luzzano',
    'Capodrise',
    'Staffolo',
    'Santadi',
    'Librizzi',
    'Oliveri',
    'Chiusi',
    'San Lorenzello',
    'Isola delle Femmine',
    'Tornareccio',
    'Valfabbrica',
    'Senerchia',
    'Torre Orsaia',
    'Maratea',
    "Castell'Arquato",
    'Brescello',
    'Piangipane',
    'Subbiano',
    'Condofuri',
    'Crosia',
    'Polistena',
    'Montepaone',
    'Trasacco',
    'Roccavivara',
    'Stigliano',
    'Porto Azzurro',
    'Paglieta',
    'Arsita',
    'Fabrica di Roma',
    'Boves',
    'Calvenzano',
    'Monzambano',
    'Spinazzola',
    'Campofelice di Roccella',
    'Massa Marittima',
    'Naro',
    'Castel San Niccolò',
    'Casalmorano',
    'Soave',
    'Lesegno',
    'Calendasco',
    'Volpedo',
    'Serramanna',
    'Fontanelice',
    'Casa Pace',
    'Fara Filiorum Petri',
    'Lappato',
    'Melendugno',
    'Casale Corte Cerro',
    'Caselle',
    'Gargnano',
    'Fiano',
    'Loranzè',
    'Isola',
    'Villar',
    'Forno Alpi Graie',
    'Caraglio',
    "Rivolta d'Adda",
    'Bonavicina',
    "San Damiano d'Asti",
    'Monasterolo del Castello',
    'Acquaviva',
    'Trevenzuolo',
    'Bovegno',
    'Qualso',
    'Riccovolto',
    "Castel d'Aiano",
    'Mandas',
    'Siamanna',
    'Guardia Piemontese',
    'Taurano',
    'Lugnano',
    'Santa Maria Codifiume',
    'Capoliveri',
    'Rotondi',
    'Basilicanova',
    'Amantea',
    'San Marco dei Cavoti',
    'Marzano di Nola',
    'Volturara Irpina',
    'Caronia',
    'Porto Ercole',
    'Palaia',
    'Castrocaro Terme',
    'Conselice',
    'Mercatino Conca',
    'Torre a Mare',
    'Arpino',
    'Orani',
    'Arpaia',
    'Foiano di Val Fortore',
    'Delia',
    'Pescina',
    'Trevico',
    'Giarratana',
    'Falconara Albanese',
    'Palizzi Marina',
    'Capodimonte',
    'Sperlonga',
    'Pennabilli',
    'Monterchi',
    'Sassinoro',
    'Tramutola',
    'Ponte',
    'Pistrino',
    'San Mariano',
    'Marzano',
    'Palagianello',
    'Luzzi',
    'Lama Mocogno',
    'Predore',
    'Tiriolo',
    'Ghilarza',
    'Cercepiccola',
    'Forano',
    'Vivaro',
    'Masera',
    'Cartura',
    'Innichen',
    'Visinale',
    'Castelgomberto',
    'San Paolo',
    'Gavinana',
    'Bivongi',
    'Nusco',
    'San Giovanni Gemini',
    'Berbenno di Valtellina',
    'Paderno Ponchielli',
    'Santo Stefano di Cadore',
    'Noli',
    'Buttapietra',
    'Ispra',
    'Charvensod',
    'Pescocostanzo',
    'Castellaneta',
    'Montepulciano Stazione',
    'Ronchi',
    'Pieve',
    'Povegliano',
    'Tusa',
    "Monteforte d'Alpone",
    'Meolo',
    'Loro Piceno',
    'Prata di Pordenone',
    'Ratschings',
    'Torrenova',
    'San Marzano di San Giuseppe',
    'Greci',
    'Pellestrina',
    'Pettinengo',
    'Orotelli',
    'Pettoranello del Molise',
    'Santo Stino di Livenza',
    'Panzano',
    'Marina di Castagneto Carducci',
    'Valle',
    'Lingotto',
    'Albina',
    'San Pietro',
    'Bosco',
    'Fiore',
    'Corte',
    'Porta',
    'Pavia di Udine',
    'Collefontana-Fontana Liri Inferiore',
    'Torre',
    'Brenner',
    'Mora',
    'Bosco',
    'Rocco',
    'Amato',
    'Meduna di Livenza',
    'Fossalta di Piave',
    'Giuliano',
    'Fosco',
    'Rossi',
    'Bagnoli Irpino',
    'Albergo',
    'Castelli',
    'Palazzo',
    'Carcereri',
    'Luca',
    'Giardini',
    'Palladio',
    'La Gala',
    'Aiello',
    'Villaggio',
    'Trabia',
    'Ai Palazzi',
    'Cecchini',
    'Palazzina',
    'Borghetto',
    'Luciana',
    'Certosa',
    'Grottaminarda',
    'Sabatino',
    'Mariae',
    'Sacco',
    'Boville Ernica',
    'Attimis',
    'Macello',
    'Barge',
    'La Loggia',
    'Ramponio',
    'Casate Raffa',
    'Dolcè',
    'Premana',
    'Ormelle',
    'Furore',
    'Giungano',
    'Pollica',
    'Sordevolo',
    'Vespolate',
    'Tirano',
    'Zoagli',
    'Palù',
    'Cese',
    'Belvedere Ostrense',
    'Villa Marina',
    'San Secondo',
    'Cannella',
    'San Gregorio',
    'Martignano',
    'Roiano',
    'Borgo Tossignano',
    'Agugliaro',
    'Sossano',
    'Sand in Taufers',
    'Termeno',
    'Rignano Garganico',
    'Castelnuovo della Daunia',
    'Volturino',
    'Carpignano Sesia',
    'Colombaro',
    'Limite',
    'Perfugas',
    'Ferrero',
    'Guardialfiera',
    'Novate Mezzola',
    'Pieve Vecchia',
    'Endine Gaiano',
    'Giano Vetusto',
    'Castelliri',
    'Campoli Appennino',
    'Scansano',
    'Decollatura',
    'Nocelleto',
    'Rivergaro',
    'Mairano',
    'Ferrandina',
    'Castelvecchio Pascoli',
    'Ceggia',
    'Vignacastrisi',
    'Tratalias',
    'Serra',
    'Marina di Andora',
    'Romanengo',
    "Casirate d'Adda",
    'Carbonara al Ticino',
    'Mulazzano',
    'Agazzano',
    'Malgrate',
    'San Fedele',
    'Brunate',
    "Brignano Gera d'Adda",
    'Trigolo',
    'Campagnola Emilia',
    "Monticelli d'Ongina",
    'Madonna di Campiglio',
    'Pieve di Soligo',
    'Longare',
    'Vigonovo',
    'Etroubles',
    'Limone Piemonte',
    'Stazzano',
    'Terenzo',
    'Nurallao',
    'Capranica',
    'Ficulle',
    'Albanella',
    'Bisacquino',
    'Canicattì',
    'Alanno',
    'Modigliana',
    'Treia',
    'Campli',
    "Serra de' Conti",
    'Serracapriola',
    'Rodi Garganico',
    'San Donato di Lecce',
    'Solopaca',
    'Pignataro Maggiore',
    'Pachino',
    'Guarrato',
    'Perdasdefogu',
    'Riparbella',
    'Orbetello',
    'Rodano',
    'Soriano nel Cimino',
    'Santa Maria',
    'Miglionico',
    'Latronico',
    'Magliano Sabina',
    'Isola del Cantone',
    'Costarainera',
    'Condove',
    'Frigento',
    'Montecosaro',
    'Montescudo',
    'Carloforte',
    'Villabate',
    'Attigliano',
    'Antella',
    'Serrano',
    'Maltignano',
    'Polesella',
    'Quaregna',
    'Cison di Valmarino',
    'Cavalese',
    'Badia',
    'Morolo',
    "Castiglione d'Adda",
    'Viggiù',
    'Vicarello',
    'Montepulciano',
    'Tavarnuzze',
    'Lezzeno',
    'Induno Olona',
    'Benetutti',
    'Bonarcado',
    'Alpette',
    'Ostigliano',
    'Quiliano',
    'Pieve di Cento',
    'Veronella',
    'Tortolì',
    'Ceriano Laghetto',
    'Sovere',
    'Azzano',
    'Capralba',
    "Quinzano d'Oglio",
    'Mirabello Monferrato',
    'Parre',
    'Francenigo',
    'Castelnuovo Berardenga',
    'Marciana Marina',
    'Soci',
    'Arcidosso',
    'Centola',
    'Gragnano Trebbiense',
    'Ronchis',
    "Puos d'Alpago",
    'Cariati',
    'Montalto di Castro',
    'Sonnino',
    'Torri in Sabina',
    'Borgo Priolo',
    'Besano',
    'Levata',
    'Berzo Demo',
    'Revere',
    'Pieve San Giacomo',
    'San Giusto Canavese',
    'Ameno',
    'Omignano',
    'Foglianise',
    'Bassano in Teverina',
    'Lunamatrona',
    'Moricone',
    'Camburzano',
    "Sasso d'Ombrone",
    'Martiniana Po',
    'San Pietro',
    'Sassello',
    'Fara Novarese',
    'Copertino',
    'Carolei',
    'Maccarese',
    'Pirri',
    'Santo Spirito',
    'Sapri',
    'Venosa',
    'Basciano',
    'Cellino Attanasio',
    'Lastra',
    'Montecchia di Crosara',
    'Monteviale',
    'Fiavè',
    'Lago',
    'Pertosa',
    'Ronco-Chiesa',
    'Pray',
    'Carpinello',
    'Lago',
    'Luciano',
    'Camisa',
    'Sommariva Perno',
    'Boschetto',
    'Ville',
    'Casa Gherardi',
    'Malè',
    'Rovere',
    'Giardinetto Vecchio',
    'Pellegrini',
    'Bettolle',
    'Leonessa',
    'Chiara',
    'Spinetta Marengo',
    'Balzola',
    'Lustra',
    'Bressanvido',
    'Gangi',
    'Benevello',
    'Perteole',
    'Cusino',
    "Torre d'Isola",
    'Frugarolo',
    'Teglio Veneto',
    'San Valentino Torio',
    'Baruchella',
    'Cilavegna',
    'Vidardo',
    'Montemarano',
    'Marta',
    'Germagnano',
    'Lutago',
    'Bargagli',
    'San Prospero',
    'Mozzecane',
    'San Mauro',
    'Montesano Salentino',
    'Ponticelli',
    'Camporosso',
    "Porto d'Ascoli",
    'Monforte San Giorgio',
    'Quarto Inferiore',
    'Zermeghedo',
    'Bolzano',
    'Faiano',
    'Orgosolo',
    'Piobesi Torinese',
    'Bonassola',
    'Peschici',
    'Fonzaso',
    'Mistretta',
    'Petriano',
    'Courmayeur',
    'Serradifalco',
    'Roggiano Gravina',
    'Riola Sardo',
    'Guardia Sanframondi',
    'Pietrapaola',
    'Falerna',
    'Civitanova Alta',
    'Sedegliano',
    'Savio',
    'Cellio',
    'Pozzo',
    'Castel Gabbiano',
    'Sospiro',
    'San Giacomo',
    'Trovo',
    'Villa Estense',
    'Boara',
    'Villa Santa Lucia',
    'Binanuova',
    'Pombia',
    'Medole',
    'Ostiano',
    'Pozza di Fassa',
    'Pinzolo',
    'Bucciano',
    'Pratola Peligna',
    'Santa Maria Imbaro',
    'Chiusavecchia',
    'Mirabella Imbaccari',
    'Montescaglioso',
    'Bonorva',
    'Trivignano',
    'Sarmede',
    'San Martino in Pensilis',
    'San Giacomo degli Schiavoni',
    'Rota Greca',
    'Vado',
    'Villamassargia',
    'Cabras',
    'San Vito',
    'Perdaxius',
    'Collinas',
    'Martirano',
    'Grassano',
    'Castrofilippo',
    'Mozzagrogna',
    'Scanno',
    'Contrada',
    'Piazza al Serchio',
    'Magliano',
    'Piazza Roma',
    'Mignano Monte Lungo',
    'Villanova',
    'Petilia Policastro',
    'Montepiano',
    'Pauli Arbarei',
    'Riva',
    'Campodipietra',
    'Parghelia',
    'Matino',
    'Castri di Lecce',
    'Roccabianca',
    'Santa Maria',
    'Montelanico',
    'Arizzano',
    'Gais',
    'Zambana',
    'Canonica',
    "Sannazzaro de' Burgondi",
    'Donorì',
    'Alvignanello',
    'Ponte Lambro',
    'Mogliano',
    'Oliena',
    'San Teodoro',
    'Meana Sardo',
    'Sperone',
    'Lammari',
    'Campora',
    'Forino',
    'Melito di Porto Salvo',
    'Peccioli',
    'Ravello',
    'Gallicano',
    'Casperia',
    'Solato',
    'Chiavazza',
    'Welschnofen',
    'Casola di Napoli',
    'Cannero Riviera',
    'Lurago Marinone',
    'Lenno',
    'Nocciano',
    'Grisignano di Zocco',
    'Quattro Castella',
    'Ragogna',
    'Piumazzo',
    'Castello',
    'Ischitella',
    'Grazzanise',
    'Cagnano Varano',
    "Montopoli in Val d'Arno",
    'Arzachena',
    'San Dorligo della Valle',
    'Corno di Rosazzo',
    'Zelarino',
    'Pietra',
    'Zinasco',
    'Tonezza del Cimone',
    'Vitulano',
    'San Martino Sannita',
    'Sturno',
    'Rotello',
    'Santa Maria Nuova',
    'Magliano di Tenna',
    'Boccheggiano',
    'Maierato',
    'Monasterace',
    'San Fili',
    'Roseto Capo Spulico',
    'Segariu',
    'Varapodio',
    'San Sisto',
    'San Nicola Arcella',
    'Pastene',
    'Pozzilli',
    'Trivento',
    'Ficarra',
    'Mammola',
    'Apice',
    'Melizzano',
    'Praiano',
    'Borgo Sabotino-Foce Verde',
    'Solto Collina',
    'Caprino Bergamasco',
    'Meano',
    'Porpetto',
    'Cancello',
    "Morro d'Oro",
    'Santa Vittoria in Matenano',
    'Bitti',
    'Armento',
    'Taranta Peligna',
    'Ferrazzano',
    "Castello d'Argile",
    'Racale',
    'Cavezzo',
    "Montefiore dell'Aso",
    'Stroncone',
    'Acquappesa',
    'Passo di Treia',
    'Caspoggio',
    'Capalbio',
    'Ururi',
    'Castagnaro',
    'Torgnon',
    'San Salvatore Monferrato',
    'Pralungo',
    'San Martino',
    'Vidiciatico',
    'San Martino',
    'Varzo',
    'Moena',
    'Magno',
    'Forno',
    'Case',
    'San Pietro',
    'Pinarella',
    'Milano Marittima',
    'Barisciano',
    'Scoppito',
    'Casignana',
    'Frabosa Sottana',
    'Pontremoli',
    'Santo Stefano al Mare',
    'Ostiglia',
    'Villa di Tirano',
    "Castello dell'Acqua",
    'Metaponto',
    'Paruzzaro',
    'Champdepraz',
    'Pancalieri',
    'Pievebovigliana',
    'Petruro',
    'Novi Velia',
    'Perdifumo',
    'Montecorice',
    'Piano-Vetrale',
    'Scuola',
    'Casalzuigno',
    'Orta San Giulio',
    'Sartirana Lomellina',
    'Stazione Castelguelfo',
    'Toro',
    'Larderello',
    'Belsito',
    'Ripa',
    'Bossolasco',
    'San Vero Milis',
    'Poggio di Roio',
    'Belfiore',
    'Alvito',
    'San Lorenzo',
    'Lido',
    'Candiana',
    'Cornaiano',
    'Casei Gerola',
    'Pozzolo',
    'Santa Cristina',
    'Cisliano',
    'Crodo',
    'Pogliano',
    'Castelseprio',
    'Acquapendente',
    'Vicovaro',
    'Corchiano',
    'Borgorose',
    'Ponzano',
    'Canino',
    'Supino',
    'Castello di Annone',
    'Caprese Michelangelo',
    'Limiti di Greccio',
    'Godo',
    'Monticelli',
    'Filetto',
    'Lestizza',
    "Godega di Sant'Urbano",
    'Begliano',
    'Paderno',
    'Villa del Conte',
    'Maerne',
    'Sottomarina',
    'Sambruson',
    'Nociglia',
    'Palmariggi',
    'Cirò',
    'Drapia',
    'Pezze di Greco',
    'Lizzanello',
    'Martano',
    'Mareo',
    'Enemonzo-Quinis',
    'Valle di Cadore',
    'Faedis',
    'Borgetto',
    'Giardinello',
    'Grotte',
    'Montodine',
    'Cumignano sul Naviglio',
    'Zandobbio',
    'Gergei',
    'Castiadas',
    'Santu Lussurgiu',
    'Oria',
    'Transacqua',
    'Cocconato',
    'Pralormo',
    'Garessio',
    'Fenile',
    'Paesana',
    'Prato Sesia',
    'Quagliuzzo',
    'San Bernardino',
    "Vaprio d'Agogna",
    'Volano',
    'Ripoli',
    'Celenza Valfortore',
    'Carpiano',
    'Fanano',
    'Solignano Nuovo',
    'Santa Ninfa',
    'Bagnara',
    'Pignola',
    'Villa Morelli',
    'Secondigliano',
    'Furci Siculo',
    'Randazzo',
    'Palazzolo',
    'Senise',
    'San Salvatore Telesino',
    'Pisciotta',
    'Quindici',
    'Scala',
    'Montecchio',
    'Porto Recanati',
    'Tuoro sul Trasimeno',
    'Monsampietro Morico',
    'Frisa',
    'Civitella Roveto',
    'Carsoli',
    'Garrufo',
    'Casoli',
    'Mercato',
    'Agnadello',
    'Collazzone',
    'Monte Castello di Vibio',
    'Spina',
    'Collelungo',
    'Saccolongo',
    'Vergano-Villa',
    'Casciana Terme',
    'Mottafollone',
    'Serle',
    'Arta Terme',
    'Rotella',
    'Monte Giberto',
    'Capitignano',
    'Montereale',
    'Fossa',
    'Castel di Judica',
    'Gessopalena',
    'Quadri',
    'Massa Fiscaglia',
    'Villa Ottone',
    'Casalvolone',
    'Albareto',
    'Carbonara di Po',
    'Albano di Lucania',
    'Aglientu',
    'Travo',
    'Simone',
    'Agro',
    'Poggio San Lorenzo',
    'Mauro',
    'Sinopoli',
    'Tursi',
    'Pantano',
    'Field',
    'Capo Rizzuto',
    'Lardirago',
    'Novello',
    'Solarolo',
    'Pollina',
    'Mezzolara',
    'Pozzolengo',
    'Dosolo',
    'Castel di Casio',
    'Teglio',
    'Sori',
    "Sant'Angelo dei Lombardi",
    'Piateda',
    'Marina di Ginosa',
    'Lajatico',
    'Morgex',
    'Pitigliano',
    'Lagosanto',
    'Ariano Ferrarese',
    'Gallio',
    'Pozzoleone',
    'Stienta',
    'Massa Martana',
    'Roncà',
    'San Bassano',
    "Isola Sant'Antonio",
    'Maruggio',
    'Corbola',
    'Albettone',
    'Gambellara',
    'Calvanico',
    'Montereale Valcellina',
    'Pedavena',
    'Feletto Umberto',
    'Piobbico',
    'Torrevecchia Teatina',
    'Baselga di Pinè',
    'Taibon Agordino',
    'Orosei',
    'Usini',
    'Santa Teresa Gallura',
    'Serra San Bruno',
    'Tricarico',
    'Porto Tolle',
    'San Zenone al Po',
    'Bettona',
    'Borgo',
    'Vigo di Cadore',
    'Biancade',
    'Lazzaro',
    'Casaccia II',
    'Paganica',
    'Dovadola',
    'San Michele Prazzo',
    'Pont',
    'Molise',
    'Ramon',
    'Pont-Canavese',
    'Maddalena di Cazzano',
    'Galeata',
    'Sassa',
    'Corti',
    'Marsico Nuovo',
    'Baragiano',
    'Ruoti',
    'Castelgrande',
    'Bella',
    'Vaglio Basilicata',
    'Cantarana',
    'Cunico',
    'Casalborgone',
    'Camino',
    'Canosa Sannita',
    'Castiglione Messer Raimondo',
    'Capannelle',
    "Fontaneto d'Agogna",
    'Perignano',
    'Bibbona',
    'Borgonovo',
    "Sant'Angelo Romano",
    'Cameriano',
    'Pertengo',
    'Caresana',
    'Offagna',
    'La Villa',
    'Malonno',
    'Badia',
    'Mals',
    'Natz-Schabs',
    'Casateia',
    'Sarnthein',
    'Ponte Galeria-La Pisana',
    'Minervino di Lecce',
    'Neviano',
    'Morciano di Leuca',
    'Monte Roberto',
    'Treglio',
    'Casacanditella',
    'Mojo Alcantara',
    'Mühlwald',
    'Terenten',
    'Resia',
    'Anterselva di Mezzo',
    'Solda',
    'Polinago',
    'San Venanzio',
    'Pernumia',
    'Castel Focognano',
    'Bagni',
    'Spirano',
    'Salento',
    'Molinari',
    'Colonna',
    'Cinecitta',
    'Borgo a Mozzano',
    'Brossasco',
    'Salmour',
    'Malcontenta',
    'Baluello',
    'Loreo',
    'Pieve Fosciana',
    'Campoleone',
    'Casa Zola',
    'San Lorenzo',
    'Torretta',
    'Sella',
    'Bagnasco',
    'Fabbrica',
    'San Nicolo',
    'San Nicola',
    'Ambra',
    'Oliveto',
    'Passo',
    'Casavecchia',
    'Santa Severa',
    'Torrecuso',
    'Coltura',
    'Antonimina',
    'Genivolta',
    "Crotta d'Adda",
    'Oliveto Citra',
    'Prarolo',
    'La Via',
    'Radda in Chianti',
    'Riale'
  ],
  Netherlands: [
    'Amsterdam',
    'The Hague',
    'Zoetermeer',
    'Helmond',
    'Beek',
    'Breukelen',
    'Almere',
    'Enschede',
    'Alphen aan den Rijn',
    'Dordrecht',
    'Leeuwarden',
    'Rotterdam',
    'Geldrop',
    'Son en Breugel',
    'Vianen',
    'Heerenveen',
    'Sint-Oedenrode',
    'Dronten',
    'Groningen',
    'Veendam',
    'Vught',
    'Sevenum',
    'Asten',
    'Gemert',
    'Bakel',
    'Erp',
    'Eindhoven',
    'Greup',
    'Ede',
    'Burgum',
    'Hilversum',
    'Haarlem',
    'Bleskensgraaf',
    'Nunspeet',
    'Valkenswaard',
    'Arnhem',
    'Apeldoorn',
    'Vlaardingen',
    'Hellevoetsluis',
    'Voorthuizen',
    'Westervoort',
    'Nijmegen',
    'Hoofddorp',
    'Diemen',
    'Sliedrecht',
    'Alkmaar',
    'Zaandam',
    'Oud-Beijerland',
    'Puttershoek',
    'Strijensas',
    'Drachten',
    "'s-Gravendeel",
    'Maasdam',
    'Twello',
    'Zuilichem',
    'Zutphen',
    'Huissen',
    'Spijkenisse',
    'Purmerend',
    'Vaassen',
    'Groesbeek',
    'Lelystad',
    'Wageningen',
    'Warnsveld',
    'Druten',
    'Amersfoort',
    'Renkum',
    'Deest',
    'Oude-Tonge',
    'Duiven',
    'Koog aan de Zaan',
    'Bleiswijk',
    'Hengelo',
    'Schiedam',
    'Heerhugowaard',
    'Waardenburg',
    'Woerden',
    'Valkenburg',
    'Brunssum',
    'Egmond aan Zee',
    'Oudega',
    'Emmeloord',
    'Breda',
    'Wellerlooi',
    'Huizen',
    'Raalte',
    'Utrecht',
    'Loenen',
    'Amstelveen',
    'De Cocksdorp',
    'Silvolde',
    'Op den Bosch',
    'Leek',
    'Ulft',
    'Terborg',
    'Megchelen',
    'Netterden',
    'Heerde',
    'Haalderen',
    'Mildam',
    'Uddel',
    'Abcoude',
    'Nederweert',
    'Liessel',
    'Barneveld',
    'Deurne',
    'Numansdorp',
    'Mijnsheerenland',
    'Veldhoven',
    'Ospel',
    'Bussum',
    'Putten',
    'Ermelo',
    'Ridderkerk',
    'Velddriel',
    'Papendrecht',
    'Valburg',
    'Westzaan',
    'Wijchen',
    'Tiel',
    'Wolfheze',
    'Roden',
    'Moordrecht',
    'Waddinxveen',
    'Mook',
    'Heythuysen',
    'Beek',
    'Hellendoorn',
    'Hoorn',
    'Domburg',
    'Wassenaar',
    'Zierikzee',
    'Petten',
    'Hardenberg',
    'Bergen',
    'Oirschot',
    'Kinderdijk',
    'Udenhout',
    'Dalfsen',
    'Lemiers',
    'Horn',
    'Flushing',
    'Ouddorp',
    'Winschoten',
    'Rockanje',
    'Nieuwkoop',
    'Wormerveer',
    'Gilze',
    'Rijswijk',
    'Venlo',
    'Bergeijk',
    'De Lutte',
    'Nieuwerkerk aan den IJssel',
    'Voorburg',
    'Haamstede',
    'Baarn',
    'Dokkum',
    'Nes',
    'Zwolle',
    'Rheden',
    'Schijndel',
    'Waalwijk',
    'Hapert',
    'De Westereen',
    'Kollum',
    'Buren',
    'Nieuwegein',
    'Mijdrecht',
    'Zeist',
    'Dieren',
    'Oosterhout',
    'Norg',
    'Veghel',
    'Gulpen',
    'Scherpenzeel',
    'Middelburg',
    'Roermond',
    'Heesch',
    'Renesse',
    'Sellingen',
    'Epen',
    'Eethen',
    'Rozenburg',
    'Oostvoorne',
    'Leiden',
    'Maastricht',
    'Bodegraven',
    'Witteveen',
    'Badhoevedorp',
    'Oudenbosch',
    'Nieuwkuijk',
    'Schinnen',
    'Dijk',
    'Heiloo',
    'Nootdorp',
    'Herten',
    'Bilthoven',
    'Naaldwijk',
    'Leusden',
    'Veenendaal',
    'Bergharen',
    "'s-Hertogenbosch",
    'Sint Anthonis',
    'Soest',
    'Soesterberg',
    'Woudenberg',
    'Beuningen',
    'Elst',
    'Zevenaar',
    'Best',
    'Ommen',
    'Deventer',
    'Ewijk',
    'Molenhoek',
    'Vragender',
    'Duivendrecht',
    'Dirksland',
    'Bergschenhoek',
    "'s-Graveland",
    'Krommenie',
    'West-Knollendam',
    'Zaandijk',
    'Beekbergen',
    'Zwijndrecht',
    'Wehl',
    'Assendelft',
    'Wittem',
    'Bladel',
    'Tilburg',
    'Oranje',
    'Delft',
    'Overasselt',
    'Noordwijk-Binnen',
    'Velden',
    'Lies',
    'Delfzijl',
    'Someren',
    'Zevenbergen',
    'Oostwold',
    'De Steeg',
    'Krimpen aan den IJssel',
    'Bergen op Zoom',
    'Kaatsheuvel',
    'Uithoorn',
    'Arcen',
    'Wognum',
    'Boxmeer',
    'Edam',
    'Vleuten',
    'Etten',
    'Baars',
    'Overloon',
    'IJsselstein',
    'Enkhuizen',
    'Haarlemmerliede',
    'Assen',
    'Hummelo',
    'Borger',
    'De Bilt',
    'Foxhol',
    'Wolvega',
    'Zoutelande',
    'Centrum',
    "'s-Gravenzande",
    'De Lier',
    'Wateringen',
    'Maassluis',
    'Hendrik-Ido-Ambacht',
    'Gouda',
    'Borculo',
    'Geesteren',
    'Hilvarenbeek',
    'Schipluiden',
    'Aalsmeer',
    'Malden',
    'Harderwijk',
    'Biest',
    'Doorn',
    'Kudelstaart',
    'Poortugaal',
    'Maasdijk',
    'Culemborg',
    'Maasland',
    'Honselersdijk',
    'Monster',
    'Kwintsheul',
    'Delfgauw',
    'Pijnacker',
    'Rhoon',
    'Leidschendam',
    'Velp',
    'Landsmeer',
    'Geldermalsen',
    'Nederhemert',
    'Uitgeest',
    'Doorwerth',
    'Den Helder',
    'Voorschoten',
    'Meppel',
    'Oss',
    'Weert',
    'Posterholt',
    'Spaubeek',
    'Lievelde',
    'Volendam',
    'Rijswijk',
    'Palemig',
    'Limbricht',
    'Beneden-Leeuwen',
    'Heerlen',
    'Ulvenhout',
    'Goes',
    'Lisse',
    'Rodenrijs',
    'Sassenheim',
    'Velsen',
    'Krimpen aan de Lek',
    'Staphorst',
    'Urmond',
    'Kerkrade',
    'Annen',
    'Retranchement',
    'Hedel',
    'Aagtekerke',
    'Eersel',
    'Roosendaal',
    'Born',
    'Noordwelle',
    'Roelofarendsveen',
    'Sittard',
    'Eibergen',
    'Harkema',
    'Surhuisterveen',
    'Heemstede',
    'Middelharnis',
    'Barendrecht',
    'Lochem',
    'Epse',
    'Heerjansdam',
    'Doetinchem',
    'Nieuwe-Tonge',
    'Brummen',
    'Biddinghuizen',
    'Doesburg',
    'Rixtel',
    'Waalre',
    'Wezep',
    'Boven-Leeuwen',
    'De Zilk',
    'Hoek van Holland',
    'Hurdegaryp',
    'Leuth',
    'Winssen',
    'Bennekom',
    'Eerbeek',
    'Voorst',
    'Winterswijk',
    'Nuenen',
    'Hoogvliet',
    'Duin',
    'Hoge Donk',
    'Oostzaan',
    'Oudehorne',
    'Afferden',
    'Puiflijk',
    'Grou',
    'Steensel',
    'Akkrum',
    'Oosterbeek',
    'Wintelre',
    'Aalten',
    'Kekerdom',
    'Soerendonk',
    'Weesp',
    'Erichem',
    'Berlicum',
    'Heinenoord',
    'Giesbeek',
    'Bemmel',
    'Nieuwendijk',
    'Aalst',
    'Heukelum',
    'Boven-Hardinxveld',
    'Muiderberg',
    'Hallum',
    'Steenbergen',
    'Sluis',
    'Moergestel',
    'Den Burg',
    'De Koog',
    'Slenaken',
    'Vierhouten',
    'IJmuiden',
    'Coevorden',
    'Almelo',
    'Laren',
    'Axel',
    'Zaamslag',
    'Terneuzen',
    'Hoek',
    'Westdorpe',
    'Hulst',
    'Koewacht',
    'Oostburg',
    'Arnemuiden',
    'Oudelande',
    'Hoedekenskerke',
    'Clinge',
    "'s-Heerenhoek",
    'Koudekerke',
    'Westkapelle',
    'Sint Annaland',
    'Tholen',
    'Sint Philipsland',
    'Scherpenisse',
    'Noordgouwe',
    'Bruinisse',
    'Oosterland',
    'Rilland',
    'Kruiningen',
    'Yerseke',
    'Oost-Souburg',
    'Eede',
    'Schore',
    'Wemeldinge',
    "'s-Gravenpolder",
    'Drunen',
    'Capelle aan den IJssel',
    'Sluiskil',
    'Kapelle',
    'Giessendam',
    'Bosch en Duin',
    'Oudemirdum',
    'Schagen',
    'Blaricum',
    'Zeewolde',
    'Anloo',
    'Houten',
    'Venhuizen',
    'Pieterburen',
    'Zandvoort',
    'Emmen',
    'Langedijk',
    'Wieringerwerf',
    'Grootebroek',
    'Rhenen',
    'Hoenderloo',
    'Garderen',
    'Buitenpost',
    'Montfoort',
    'Ezinge',
    'Sneek',
    'Joure',
    'Boesingheliede',
    'Ossendrecht',
    'Hoogerheide',
    'Hoogland',
    'Didam',
    'Munstergeleen',
    'Oijen',
    'Herwijnen',
    'Tweede Exloermond',
    'Noordwijkerhout',
    'Sprang',
    'Lage Mierde',
    'Elburg',
    'Oosterend',
    'Oirsbeek',
    'Rozenburg',
    'Holthone',
    'Biervliet',
    'Hoogeveen',
    'Neede',
    'Beilen',
    'Boerakker',
    'Simpelveld',
    'Wijk bij Duurstede',
    'Opheusden',
    'Sint Annaparochie',
    'Kesteren',
    'Overberg',
    'Epe',
    'Eelderwolde',
    'Alblasserdam',
    'Gemonde',
    'Smilde',
    'Overdinkel',
    'Uden',
    'Espel',
    'De Meern',
    'Zetten',
    'Hedel',
    'Buren',
    'Hollandsche Rading',
    'Engelen',
    'Beverwijk',
    'Noord-Scharwoude',
    'Reeuwijk',
    'Ankeveen',
    'Philippine',
    'Dongen',
    'Oegstgeest',
    'Damwald',
    'Hoensbroek',
    'Hoogkarspel',
    'Gorinchem',
    'Zuidoostbeemster',
    'Haren',
    'Westerbork',
    'Bavel',
    'Heijningen',
    'Dinteloord',
    'Loon op Zand',
    'Sint Joost',
    'Nieuwe Pekela',
    'Roodeschool',
    'Boelenslaan',
    'Oud-Alblas',
    'Kerk-Avezaath',
    'Sint Odilienberg',
    'Dalen',
    'Loozen',
    'Oosterbierum',
    'Lutten',
    'Nieuw-Lekkerland',
    'Rosmalen',
    'Dinxperlo',
    'Gorssel',
    'Den Dolder',
    'Toldijk',
    'Tynaarlo',
    'Borne',
    'Wervershoof',
    'Heemskerk',
    'Cuijk',
    'Oldebroek',
    'Zoeterwoude',
    'Leiderdorp',
    'Almere Haven',
    'Swalmen',
    'Goor',
    'Nijkerk',
    'Terheijden',
    'Vilt',
    'Baarle-Nassau',
    'Schoonhoven',
    'Nederhorst den Berg',
    'Beugen',
    'Waarland',
    'Hazerswoude-Dorp',
    'Volkel',
    'Bellingwolde',
    'Kantens',
    'Nieuwolda',
    'Diepenheim',
    'Augustinusga',
    'Appelscha',
    'Oldemarkt',
    'Tytsjerk',
    'Zuidland',
    'Groot-Ammers',
    'Achthuizen',
    'Maarn',
    'Belfeld',
    'De Wijk',
    'Ens',
    'Ane',
    'Heeten',
    'Raamsdonksveer',
    'Brielle',
    'Klundert',
    'Holtum',
    'Tegelen',
    'Kessel',
    'Grathem',
    'Maarssen',
    'Geleen',
    'Westmaas',
    's-Heerenberg',
    'De Horst',
    'Geervliet',
    'Meerssen',
    'Haaften',
    'Zaltbommel',
    'Lathum',
    'IJzendoorn',
    'Gemeente Rotterdam',
    'Fijnaart',
    'Aalden',
    'Harich',
    'Dodewaard',
    'Olst',
    'Beek',
    'Warmond',
    'Nijverdal',
    'Rijnsburg',
    'Driebruggen',
    'Ravenstein',
    'Middenbeemster',
    'Monnickendam',
    'Vinkeveen',
    'Zevenbergschen Hoek',
    'Zelhem',
    'Eefde',
    'Schalkhaar',
    'Veenoord',
    'Bunschoten',
    'Spakenburg',
    'Lichtenvoorde',
    'Heino',
    'Tricht',
    'Boskoop',
    'Mierlo',
    'Luyksgestel',
    'Muiden',
    'Beesd',
    'Jubbega',
    'Swifterbant',
    'Gendringen',
    'Rekken',
    'Budel-Dorplein',
    'Suwald',
    'Heelsum',
    'Riethoven',
    'Stiens',
    'Ameide',
    'Tolkamer',
    'Earnewald',
    'Noardburgum',
    'Garyp',
    'Reusel',
    'Dronryp',
    'Kerkdriel',
    'Ingen',
    'Hoogeloon',
    'Varik',
    'Rozendaal',
    'Drogeham',
    'Twijzelerheide',
    'Molenhoek',
    'Drempt',
    'Britsum',
    'Strijen',
    'Lent',
    'Budel',
    'Goudswaard',
    'Beusichem',
    'Klaaswaal',
    'Millingen aan de Rijn',
    'Oudorp',
    'Sumar',
    'Sommelsdijk',
    'Gameren',
    'Maarheeze',
    'Ellecom',
    'Marrum',
    'Baambrugge',
    'Gaanderen',
    'Goutum',
    'Ouderkerk aan de Amstel',
    'Pernis',
    'Lieshout',
    'Zwartebroek',
    'Plasmolen',
    'Gytsjerk',
    'Lienden',
    'Stellendam',
    'Sterksel',
    'Lexmond',
    'Santpoort-Noord',
    'Mookhoek',
    'Broek op Langedijk',
    'De Glind',
    'Baexem',
    'Yde',
    'Haaksbergen',
    'Werkendam',
    'Holten',
    'Andijk',
    'Colmschate',
    'Zeddam',
    'Bunnik',
    'Poeldijk',
    'Asperen',
    'Chaam',
    'Balk',
    'Noordwolde',
    'Kootstertille',
    'Denekamp',
    'Oldenzaal',
    'De Hoef',
    'Naarden',
    'Dwingeloo',
    'Ter Apel',
    'Eemnes',
    'Linschoten',
    'Venray',
    'Dedemsvaart',
    'Huis ter Heide',
    'Boeicop',
    'Zevenhoven',
    'De Waal',
    'Westerwijtwerd',
    'Boxtel',
    'Zwaag',
    'Katwijk',
    'Gendt',
    'Nieuwerbrug',
    'Hoogezand',
    'Broekhin',
    'Oranjewoud',
    'Hillegom',
    'Hierden',
    'Ederveen',
    'Genemuiden',
    'Valkenburg',
    'Markelo',
    'Borsele',
    'Velsen-Noord',
    'Sint Agatha',
    'Den Hoorn',
    'Feanwalden',
    'Walterswald',
    'Anjum',
    'Oosternijkerk',
    'Nieuw-Vennep',
    'Vogelenzang',
    'Sambeek',
    'Vlijmen',
    'Enschot',
    'Halsteren',
    'Lutjebroek',
    'Leersum',
    'Maurik',
    'Melick',
    'Enter',
    'Haaren',
    'Hooghalen',
    'Bathmen',
    'Veen',
    'Lunteren',
    'Ten Boer',
    'Gieten',
    'Schipborg',
    'Grijpskerk',
    'Gasselte',
    'Herpen',
    'Amstenrade',
    'Delden',
    'Einighausen',
    'Eys',
    'Joppe',
    'Willemstad',
    'Erica',
    'Lottum',
    'Montfort',
    'Maasbree',
    'Berkhout',
    'Vijfhuizen',
    'Eext',
    'Losser',
    'Vaals',
    'Kortenhoef',
    'Heteren',
    'Achtmaal',
    'Vlagtwedde',
    'Ureterp',
    'Partij',
    "'t Zand",
    'Groet',
    'Hartwerd',
    'Haelen',
    'Aerdenhout',
    'Moerkapelle',
    'Herkingen',
    'Hernen',
    'Berkenwoude',
    'Bredevoort',
    'Oene',
    'Ingelum',
    'Abbekerk',
    'Eexterzandvoort',
    'Bocholtz',
    'Gronsveld',
    'Bierum',
    'Farmsum',
    'Appingedam',
    'Spijk',
    'Oude Pekela',
    'Muntendam',
    'Noordbroek',
    'Meeden',
    'Zuidbroek',
    'Rottum',
    'Winsum',
    'Zandeweer',
    'Warffum',
    'Bedum',
    'Eenrum',
    'Ulrum',
    'Vriezenveen',
    'Vries',
    'Zuidlaren',
    'Buinerveen',
    'Tubbergen',
    'Albergen',
    'Wierden',
    'Uithuizermeeden',
    'Daarle',
    'Geesteren',
    'Fleringen',
    'Haarle',
    'Vroomshoop',
    'Manderveen',
    'Vasse',
    'Ysbrechtum',
    'Weidum',
    'Woudsend',
    'Nijland',
    'IJlst',
    'Heeg',
    'Hennaard',
    'Jutrijp',
    'Wommels',
    'Burgwerd',
    'Hoogersmilde',
    'Lemmer',
    'Hemelum',
    'Echtenerbrug',
    'Koudum',
    'Oosterwolde',
    'Haule',
    'Wijckel',
    'Bakhuizen',
    'Loppersum',
    'Schildwolde',
    'Stedum',
    'Zeerijp',
    'Oosterzee',
    'Workum',
    'Hindeloopen',
    'Hoogkerk',
    'Bovensmilde',
    'Rolde',
    'Hijken',
    'Wijster',
    'Tilligte',
    'Westerhaar-Vriezenveensewijk',
    'Delden',
    'Hengevelde',
    'Kamperland',
    'Rietveld',
    'Rossum',
    'Wilnis',
    'Roggel',
    'Wilp',
    'Spaarndam',
    'Castricum',
    'Poortvliet',
    'Helenaveen',
    'De Kwakel',
    'Bosschenhoofd',
    'Sint Willebrord',
    'Rucphen',
    'Stampersgat',
    'Sprundel',
    'Oud-Gastel',
    'Putte',
    'Liempde',
    'Bunde',
    'Appeltern',
    'Oudeschild',
    'Geertruidenberg',
    'Nieuw-Buinen',
    'Elsloo',
    'Steenwijk',
    'Hout',
    'Zwartemeer',
    'Balgoij',
    'Kampen',
    'Doornspijk',
    'Peize',
    'Oudehaske',
    'Dreumel',
    'Rivierenwijk',
    'Oudendijk',
    'Wormer',
    'Oisterwijk',
    'Leerdam',
    'Made',
    'Guttecoven',
    'Stadskanaal',
    'Kerkenveld',
    'Wijhe',
    'Rijssen',
    'Vlodrop',
    'Steijl',
    'Sloterdijk',
    'Schoondijke',
    'Hoofdplaat',
    'Kats',
    'Brouwershaven',
    'Wissenkerke',
    'Kortgene',
    'Colijnsplaat',
    'Gapinge',
    'Breskens',
    'Sint Maartensdijk',
    'Serooskerke',
    'Oostkapelle',
    'Sint Jansteen',
    'Heikant',
    'Vogelwaarde',
    'Sas van Gent',
    'Wolphaartsdijk',
    'Kloetinge',
    'Aardenburg',
    'Bruchem',
    'Arkel',
    'Moerdijk',
    'Genderen',
    'Stompwijk',
    'Hoornaar',
    'Nieuwveen',
    'Ter Aar',
    'Galder',
    'Prinsenbeek',
    'Teteringen',
    'Giessenburg',
    'Hoogblokland',
    'Meeuwen',
    'Noordeloos',
    'Lopik',
    'Ammerstol',
    'Bergambacht',
    'Hank',
    'Sleeuwijk',
    'Neer-Andel',
    'Harmelen',
    'Driebergen',
    'Odijk',
    'Renswoude',
    'Tull',
    'Maartensdijk',
    'Nieuwer-Ter-Aa',
    'Zijderveld',
    'Elsendorp',
    'Everdingen',
    'Voorhout',
    'Katwijk aan Zee',
    'Oud-Zuilen',
    'Loosdrecht',
    'Noordwijk aan Zee',
    'Zevenhuizen',
    'Hooglanderveen',
    'Craailo',
    'Berg',
    'Achterveld',
    'Zuidhorn',
    'Overveen',
    'Berghem',
    'Maasbommel',
    'Bitgum',
    'Kamerik',
    "'s-Heer-Hendrikskinderen",
    'Merkelbeek',
    'Hattem',
    'Holthees',
    'Gasselternijveen',
    'Wijk aan Zee',
    'Oosthuizen',
    'Rinsumageast',
    'Groessen',
    'Nuth',
    'Koudekerk aan den Rijn',
    'Oudkarspel',
    'Uithuizen',
    'Millingen',
    'Rijen',
    'Goirle',
    "'s Gravenmoer",
    'Waspik',
    'Heeswijk',
    'Aalburg',
    'De Moer',
    'Haren',
    'Schinveld',
    'Obbicht',
    'Sweikhuizen',
    'Voerendaal',
    'Klimmen',
    'Jabeek',
    'Schimmert',
    'Puth',
    'Eygelshoven',
    'Hulsberg',
    'Eijsden',
    'Rijsbergen',
    'Zundert',
    'Kruisland',
    'Nieuw-Vossemeer',
    'Wouwsche Plantage',
    'Oudemolen',
    'Heerle',
    'Nuland',
    'Helvoirt',
    'Cromvoirt',
    'Nistelrode',
    'Heusden',
    'Sint-Michielsgestel',
    'Den Dungen',
    'Maaskantje',
    'Herkenbosch',
    'Beesel',
    'Echt',
    'Ittervoort',
    'Maasbracht',
    'Reuver',
    'Wessem',
    'Stevensweert',
    'Susteren',
    'Stein',
    'Buchten',
    'Dieteren',
    'Nieuwstadt',
    'Roosteren',
    'Meijel',
    'Panningen',
    'Beringe',
    'Grashoek',
    'Helden',
    'Egchel',
    'Blerick',
    'Baarlo',
    'Well',
    'Groeningen',
    'Vierlingsbeek',
    'Rijkevoort',
    'Grave',
    'Zeeland',
    'Maashees',
    'Vortum-Mullem',
    'Wanroij',
    'Haps',
    'Schaijk',
    'Escharen',
    'Mill',
    'Stevensbeek',
    'Margraten',
    'Banholt',
    'Wijlre',
    'Terblijt',
    'Noorbeek',
    'Cadier en Keer',
    'Mechelen',
    'Milsbeek',
    'Nieuw-Bergen',
    'Ven-Zelderheide',
    'Wanssum',
    'Merselo',
    'Bergen',
    'Ottersum',
    'Grubbenvorst',
    'Blitterswijck',
    'Siebengewald',
    'Almkerk',
    'Woudrichem',
    'Heijen',
    'Meerlo',
    'Tienraij',
    'Horst',
    'America',
    'Diessen',
    'Molenschot',
    'Barchem',
    'Beinsdorp',
    'Buurse',
    'Kraggenburg',
    'Zonnemaire',
    'Tjerkwerd',
    'Nieuw-Balinge',
    'Nieuwdorp',
    'Vuren',
    'Erm',
    'Hengelo',
    'Gees',
    'Tijnje',
    'Ursem',
    'Varsseveld',
    'Maarssenbroek',
    'Limmen',
    'Finsterwolde',
    'Slochteren',
    'Anna Paulowna',
    'Ruurlo',
    'Halle',
    'Mastenbroek',
    'Uitwellingerga',
    'Nuis',
    'Ulicoten',
    'Ulestraten',
    'Gouderak',
    'Sint Pancras',
    'Bovenkarspel',
    'Lithoijen',
    'Milheeze',
    'IJzendijke',
    'Valthermond',
    'Papekop',
    'Heeze',
    'Zwartsluis',
    'Weurt',
    'Kalenberg',
    'Kockengen',
    'Sappemeer',
    'Angeren',
    'Purmerland',
    'Hazerswoude-Rijndijk',
    'Nijbroek',
    'Winkel',
    'Nijkerkerveen',
    'Ter Apelkanaal',
    'Hoevelaken',
    'Dorst',
    'Den Hout',
    'Wijdenes',
    'Hem',
    'Twisk',
    'Nibbixwoud',
    'Oostwoud',
    'Benningbroek',
    'Hoorn',
    'Benthuizen',
    'Katwoude',
    'Oterleek',
    'Obdam',
    'Stompetoren',
    'Tweede Valthermond',
    'De Rijp',
    'Schermerhorn',
    'Beets',
    'Broek in Waterland',
    'Wijdewormer',
    'Jisp',
    'Westbeemster',
    'De Goorn',
    'Avenhorn',
    'Schellinkhout',
    'Hensbroek',
    'Blokker',
    'Medemblik',
    'Hauwert',
    'Middenmeer',
    'Den Oever',
    'Hoogwoud',
    'Opmeer',
    'Spanbroek',
    'Spierdijk',
    'Keijenborg',
    'Nieuw-Beijerland',
    "Stad aan 't Haringvliet",
    'Gelselaar',
    'Werkhoven',
    'Gennep',
    'Driemond',
    'Heveadorp',
    'Nigtevecht',
    'Wamel',
    'Tuitjenhorn',
    'Zieuwent',
    'Langezwaag',
    'Stoutenburg',
    'Boekel',
    'Hooge Zwaluwe',
    'Woerdense Verlaat',
    'Nederasselt',
    'Waarder',
    'Surhuizum',
    'Wapenveld',
    'Marienberg',
    'Batenburg',
    'Marum',
    'Mortel',
    'Nieuwpoort',
    'Bergentheim',
    'Koedijk',
    'Marknesse',
    'Bloemendaal',
    'Homoet',
    'Huijbergen',
    'Doornenburg',
    'Warder',
    'Leimuiden',
    'Emst',
    'Westbroek',
    'Noordhorn',
    'Oostendam',
    'Neer',
    'Kootwijkerbroek',
    'Brucht',
    'Stolwijk',
    'Vreeland',
    'Gorredijk',
    'Garmerwolde',
    'Slijk-Ewijk',
    'Woltersum',
    'Haulerwijk',
    'Enspijk',
    'Ophemert',
    'Ilpendam',
    'Nieuwleusen',
    'Beerzerveld',
    'IJsselmuiden',
    'Uitdam',
    'Eckart',
    'Breugel',
    'Rips',
    'Brakel',
    'Elst',
    'Ochten',
    'Randwijk',
    'Hellouw',
    "'t Harde",
    'Lekkerkerk',
    'Rhenoy',
    'Lijnden',
    'Wildervank',
    'Vledder',
    'Rossum',
    'Zorgvlied',
    'Ferwert',
    'Bemelen',
    'Callantsoog',
    'Princenhof',
    'Cadzand',
    'Alem',
    'Broekhuizen',
    'Kaag',
    'Ootmarsum',
    'Groot-Agelo',
    'Oudewater',
    'Vrouwenpolder',
    'Houtakker',
    'Ugchelen',
    'Molenaarsgraaf',
    'Hekelingen',
    'Tinte',
    'Zwartewaal',
    'Heenvliet',
    'Vierpolders',
    'Pannerden',
    'Lobith',
    'Babberich',
    'Aerdt',
    'Groenlo',
    'Meerkerk',
    'Alde Leie',
    'Westerhoven',
    'Rooth',
    'Wierum',
    'Ballum',
    'Alphen',
    'Hoogmade',
    'Woubrugge',
    'Noorden',
    'Wagenberg',
    'Lage Zwaluwe',
    'Amerongen',
    'Breukeleveen',
    'Tienhoven',
    'Stationsbuurt',
    'Beegden',
    'Nunhem',
    'Ell',
    'Julianadorp',
    'Breezand',
    'Oudesluis',
    'Zoutkamp',
    'Steggerda',
    'Mussel',
    'Gieterveen',
    'Garsthuizen',
    'Kropswolde',
    'Aduard',
    'Briltil',
    'Oldekerk',
    'Kommerzijl',
    'Grootegast',
    'Valthe',
    'Pieterzijl',
    'Opeinde',
    'Glimmen',
    'Scharmer',
    'Harkstede',
    'Eelde-Paterswolde',
    'Enumatil',
    'Tolbert',
    'De Wilp',
    'Noordlaren',
    'Paterswolde',
    'Onnen',
    'Diepenveen',
    'Lettele',
    'Nieuw-Schoonebeek',
    'Schoonebeek',
    'Noord-Sleen',
    'Emmer-Compascuum',
    'Klazienaveen',
    'Oosterhesselen',
    'Nieuwlande',
    'Noordscheschut',
    'Stuifzand',
    'Zuidwolde',
    'Zwinderen',
    'Hollandscheveld',
    'Gramsbergen',
    'Kloosterhaar',
    'Slagharen',
    'Sibculo',
    'Vorstenbosch',
    'Linne',
    'Kolham',
    'Beerta',
    'Scheemda',
    'Siddeburen',
    'Havelte',
    'Oosterwolde',
    'Giethoorn',
    'Koekange',
    'Ruinerwold',
    'Frederiksoord',
    'IJhorst',
    'Diever',
    'Den Bommel',
    'Ooltgensplaat',
    'Lienden',
    'Heilig Landstichting',
    'Berg en Dal',
    'Stroe',
    'Herveld',
    'Franeker',
    'Zuidermeer',
    'Budel-Schoot',
    'Oud-Ade',
    'Nieuweschoot',
    "'t Kabel",
    'Baken',
    'Halfweg',
    'Zuid-Beijerland',
    'Piershil',
    'Hulshorst',
    'Zaanstad',
    'Bolsward',
    'Ammerzoden',
    'Laag-Soeren',
    'Ressen',
    'Dorp',
    'Venhorst',
    'Bourtange',
    'Vlieland',
    'Zwaagdijk-Oost',
    'Zuidzande',
    'Otterlo',
    'De Punt',
    'Cruquius',
    'De Klomp',
    'Makkum',
    'Fluitenberg',
    'Buitenkaag',
    'Terschuur',
    'Rijckholt',
    'Nijnsel',
    'Oeffelt',
    'Zalk',
    'Schiermonnikoog',
    'Akersloot',
    'Schin op Geul',
    'Holwierde',
    'Den Deijl',
    'Heeswijk-Dinther',
    'Taarlo',
    'Ruinen',
    'Witharen',
    'Vorden',
    'Oldehove',
    'Nijhuizum',
    'Amstelhoek',
    'Driel',
    'Meer',
    'Burum',
    'Ovezande',
    'Beetsterzwaag',
    'Dirkshorn',
    'Keldonk',
    'Sint Hubert',
    'Limmel',
    'Waterland',
    'Burgerveen',
    'Anderen',
    'Wijngaarden',
    'Nieuw-Namen',
    'Kuitaart',
    'Rijpwetering',
    'Oude Wetering',
    'Nieuwe Wetering',
    'Nieuwehorne',
    'Den Ilp',
    'Schoorl',
    'Ten Post',
    'Wagenborgen',
    'Legemeer',
    'Sint Nicolaasga',
    'Exloo',
    'Veenhuizen',
    'Doenrade',
    'Wijnandsrade',
    'Middelbeers',
    'Achterberg',
    'Zuiderpark',
    'Woensdrecht',
    'Hollum',
    'Hantum',
    'Oudwoude',
    'De Falom',
    'Hantumhuizen',
    'Baarland',
    'Harlingen',
    'Wijnaldum',
    'Herbaijum',
    'Tzummarum',
    'Oldeberkoop',
    'Lippenhuizen',
    'Ravenswoud',
    'Sintjohannesga',
    'Rottum',
    'West-Terschelling',
    'Echten',
    'Midlum',
    'De Blesse',
    'Oldeholtpade',
    'Kimswerd',
    'Wons',
    'Pingjum',
    'Hippolytushoef',
    'Slootdorp',
    'Kolhorn',
    'Nieuwe-Niedorp',
    'Zuid-Scharwoude',
    'Hasselt',
    'Urk',
    'Rutten',
    'Lemele',
    'Lemelerveld',
    'Blokzijl',
    'Schagerbrug',
    'Sint Maarten',
    'Warmenhuizen',
    'Groenveld',
    'Sleen',
    'Nieuw-Amsterdam',
    'Nieuw-Dordrecht',
    'Bennebroek',
    'Rijsenhout',
    'Aalsmeerderbrug',
    'Abbenes',
    'Zwaanshoek',
    'Zwanenburg',
    'Egmond-Binnen',
    'Lutjewinkel',
    'Wieringerwaard',
    'Driehuis',
    'Saasveld',
    'Weerselo',
    'Lewenborg',
    'Ooij'
  ],
  Greece: [
    'Athens',
    'Ekali',
    'Thessaloniki',
    'Chalcis',
    'Aegina',
    'Rhodes',
    'Kos',
    'Rafina',
    'Voula',
    'Vouliagmeni',
    'Alimos',
    'Glyfada',
    'Corinth',
    'Servia',
    'Kozani',
    'Serres',
    'Panorama',
    'Katerini',
    'Larisa',
    'Elassona',
    'Komotini',
    'Alexandroupoli',
    'Metaxades',
    'Marathon',
    'Filothei',
    'Paiania',
    'Tinos',
    'Psychiko',
    'Megalochari',
    'Kallithea',
    'Pallini',
    'Nikaia',
    'Ioannina',
    'Loutraki',
    'Aliartos',
    'Argos',
    'Kilkis',
    'Lamia',
    'Heraklion',
    'Cholargos',
    'Agrinio',
    'Markopoulo Mesogaias',
    'Piraeus',
    'Keratsini',
    'Aigaleo',
    'Pátrai',
    'Aigio',
    'Messolonghi',
    'Karditsa',
    'Corfu',
    'Lefkada',
    'Chania',
    'Veroia',
    'Giannitsa',
    'Vergina',
    'Ptolemaida',
    'Xanthi',
    'Kavala',
    'Sparta',
    'Moires',
    'Kalamata',
    'Nea Peramos',
    'Chios',
    'Perama',
    'Attica',
    'Magoula',
    'Elliniko',
    'Nafplion',
    'Salamina',
    'Ayios Stefanos',
    'Galatsi',
    'Kymi',
    'Elefsina',
    'Aspropyrgos',
    'Nea Ionia',
    'Kifissia',
    'Farsala',
    'Amaliada',
    'Pefki',
    'Nea Makri',
    'Alexandreia',
    'Melissia',
    'Volos',
    'Sindos',
    'Kalamaria',
    'Goumenissa',
    'Kastoria',
    'Florina',
    'Vari',
    'Skala',
    'Chania',
    'Kaisariani',
    'Igoumenitsa',
    'Ierapetra',
    'Ialysos',
    'Patmos',
    'Kassiopi',
    'Anavyssos',
    'Koropi',
    'Trikala',
    'Varkiza',
    'Andravida',
    'Kranidi',
    'Hydra',
    'Xylokastro',
    'Nea Kios',
    'Tripoli',
    'Ano Liosia',
    'Peristeri',
    'Orestiada',
    'Keratea',
    'Kouvaras',
    'Porto Rafti',
    'Paloukia',
    'Dafni',
    'Eretria',
    'Thebes',
    'Pyrgos',
    'Rio',
    'Mandra',
    'Megara',
    'Peristeri',
    'Argyroupoli',
    'Thermi',
    'Arta',
    'Lefkimmi',
    'Parga',
    'Preveza',
    'Litochoro',
    'Naxos',
    'Spata',
    'Nea Smyrni',
    'Mytilene',
    'Zakynthos',
    'Argostoli',
    'Lixouri',
    'Nafpaktos',
    'Oropos',
    'Drama',
    'Kalymnos',
    'Kalamos',
    'Mesagros',
    'Skiathos',
    'Skala Oropou',
    'Makrygialos',
    'Samothraki',
    'Ormylia',
    'Pella',
    'Pylaia',
    'Rethymno',
    'Filippiada',
    'Nea Alikarnassos',
    'Lykovrysi',
    'Nea Filadelfeia',
    'Pikermi',
    'Kamatero',
    'Gonnoi',
    'Agioi Anargyroi',
    'Archaia Nemea',
    'Polichni',
    'Platy',
    'Dionysos',
    'Kryonerion',
    'Symi',
    'Agios Nikolaos',
    'Tavros',
    'Leontario',
    'Naousa'
  ],
  Spain: [
    'Valencia',
    'Riola',
    'Barcelona',
    'Montornès del Vallès',
    'Begues',
    'Terrassa',
    'Sant Cugat del Vallès',
    "l'Hospitalet de l'Infant",
    'Vallirana',
    'Blanes',
    'el Prat de Llobregat',
    'Cornellàde Llobregat',
    'Sant Just Desvern',
    'Gava',
    'Pallejà',
    'Castelldefels',
    'Madrid',
    'Leganés',
    'Villaviciosa de Odon',
    'Alcorcón',
    'Valdemoro',
    'Mostoles',
    'Cobena',
    'Getafe',
    'Aranjuez',
    'Humanes de Madrid',
    'Las Rozas de Madrid',
    'Arroyomolinos',
    'Colmenar Viejo',
    'Santa Cruz',
    'Majadahonda',
    'Fuenlabrada',
    'Arucas',
    'Las Palmas de Gran Canaria',
    'Badalona',
    'Collado Villalba',
    'Villamanta',
    'Alcobendas',
    'Alcalá de Henares',
    'Torrelodones',
    'Illescas',
    'Sesena',
    'Mataelpino',
    'Villalbilla',
    'Pinto',
    'Moralzarzal',
    'Ciempozuelos',
    'San Sebastián de los Reyes',
    'Galapagar',
    'Parla',
    'Toledo',
    'San Martin de la Vega',
    'Coslada',
    'Arganda',
    'Hoyo de Manzanares',
    'Los Hueros',
    'Los Molinos',
    'Torres de la Alameda',
    'Tarragona',
    'les Borges del Camp',
    'Jesus',
    'Reus',
    'Santa Coloma de Queralt',
    'Cambrils',
    'Cartagena',
    'Murcia',
    'Lorca',
    'Balsicas',
    'Villafranca',
    'Boadilla del Monte',
    'Valdilecha',
    'Villanueva del Pardillo',
    'Portillo de Toledo',
    'Villanueva de la Canada',
    'Grinon',
    'El Alamo',
    'Torrejon de la Calzada',
    'Monte',
    'Brunete',
    'Cerceda',
    'Chinchón',
    'Sevilla La Nueva',
    "L'Hospitalet de Llobregat",
    'Cervello',
    'Granollers',
    'Navalcarnero',
    'Pozuelo de Alarcón',
    'Robledo de Chavela',
    'El Escorial',
    'Campo Real',
    'Nuevo Baztan',
    'Torrejón de Ardoz',
    'Perales del Alfambra',
    'Becerril de la Sierra',
    'Colmenarejo',
    'San Martin de Valdeiglesias',
    'Colmenar del Arroyo',
    'Batres',
    'Las Ventas de Retamosa',
    'Las Matas',
    'Rubí',
    'Gelida',
    'Badajoz',
    'Mérida',
    'Olivenza',
    'Telde',
    'Gáldar',
    'Candelaria',
    'Alta',
    'Arona',
    'Mondragón',
    'Ermua',
    'Girona',
    'Sant Bartomeu del Grau',
    'Olula del Rio',
    'Garrucha',
    'Almería',
    'Vera',
    'Huercal de Almeria',
    'Nijar',
    'El Ejido',
    'Albox',
    'Los Gallardos',
    'Cuevas del Almanzora',
    'Viator',
    'Aguadulce',
    'Roquetas de Mar',
    'Tijola',
    'Huercal Overa',
    'Arboleas',
    'Tabernas',
    'Vicar',
    'Sant Joan de Vilatorrada',
    'Sitges',
    'Vilanova del Camí',
    'Dosrius',
    'Ametlla',
    'Vallgorguina',
    'Valles',
    'Vall de Almonacid',
    'Lucena del Cid',
    'Manresa',
    'Roda de Bara',
    'Piera',
    'Olesa de Montserrat',
    'Igualada',
    'Sant Pere de Ribes',
    'Mijas',
    'Málaga',
    'Fuengirola',
    'Huelva',
    'Mazagon',
    'Los Marines',
    'Aracena',
    'Hinojos',
    'Bonares',
    'Úbeda',
    'Jaén',
    'Alcala la Real',
    'Santiago',
    'Linares',
    'Jodar',
    'Sabiote',
    'Bilbao',
    'Guadix',
    'Oria',
    'Irun',
    'San Sebastián de La Gomera',
    'Zumarraga',
    'San Sebastian',
    'Fuentes de Leon',
    'Burguillos del Cerro',
    'Azuaga',
    'San Cristóbal de La Laguna',
    'Santa Cruz de Tenerife',
    'La Orotava',
    'La Higuerita',
    'Playa',
    'Adeje',
    'Sabadell',
    'Marbella',
    'Torremolinos',
    'Antequera',
    'El Moral',
    'Rincon de la Victoria',
    'Logroño',
    'Valle',
    'Las Navas del Marques',
    'Mataró',
    'Taradell',
    'Rupit',
    'Canovelles',
    'Tacoronte',
    'Granadilla de Abona',
    'La Vera',
    'Santa Cruz de La Palma',
    'Santa Úrsula',
    'Puerto de la Cruz',
    'Icod de los Vinos',
    'La Victoria de Acentejo',
    'San Isidro',
    'Tigaiga',
    'Valverde',
    'Tegueste',
    'Los Cristianos',
    'Zamora',
    'Corrales',
    'Seville',
    'Cáceres',
    'Santa Maria de Corcó',
    'Valldoreix',
    'Tortosa',
    'Malgrat de Mar',
    'Villena',
    'Vilassar de Mar',
    'Algete',
    'El Astillero',
    'Lucena',
    'Puebla de Alfinden',
    'Loeches',
    'Mollet del Vallès',
    'Algeciras',
    'Azuqueca de Henares',
    'La Alameda de la Sagra',
    'Creixell',
    'Fuentelapena',
    'Torroella de Montgri',
    'Mahon',
    'Tona',
    'Artes',
    'Torelló',
    'Vic',
    'San Juan',
    'Mojacar Pueblo',
    'Castellón de la Plana',
    'Cuevas de Vinroma',
    'Cadiz',
    'Cordova',
    'Segura',
    'Villanueva del Arzobispo',
    'Alginet',
    'Santa Pola',
    'San Julian de Muskiz',
    'Betera',
    'Morón de la Frontera',
    'Poboa de San Xulian',
    'Marchena',
    'Águilas',
    'La Puebla de Cazalla',
    'Alicante',
    'Granada',
    'Lugo',
    'Amorebieta',
    'Tortella',
    'Vélez-Málaga',
    'Corchuela',
    'Onda',
    'Chiva',
    'Beniparrell',
    'Almenara',
    'Brenes',
    'Jerez de la Frontera',
    'Los Barrios',
    'Alsasua – Altsasu',
    'Orcoyen',
    'Santander',
    'Manzanares',
    'Castro Urdiales',
    'Santa Eulalia Bajera',
    'Villanueva de Villaescusa',
    'Ribadeo',
    'Jijona',
    'Elche',
    'Errenteria',
    'Zaragoza',
    'Tolosa',
    'Valladolid',
    'Santa Lucia',
    'Moana',
    'León',
    'Vitoria-Gasteiz',
    'Zafra',
    'La Red de Valdetuejar',
    'Mairena del Aljarafe',
    'Pedreguer',
    'Trujillo',
    'Los Alcazares',
    'Pilar de la Horadada',
    'Almoradi',
    'Hortichuela',
    'Puerto Real',
    'Cartama',
    'Cullera',
    'Guadalajara',
    'Esquivias',
    'Cervera',
    'La Pobla de Lillet',
    'Ourense',
    'Roldan',
    'Torre-Pacheco',
    'San Pedro del Pinatar',
    'Torrevieja',
    'San Miguel',
    'Real',
    'Acala del Rio',
    'Vigo',
    'Pontevedra',
    'Cangas do Morrazo',
    'Nigran',
    'Valdemorillo',
    'San Fernando de Henares',
    'El Escorial',
    'Segovia',
    'Llosa de Ranes',
    'La Guancha',
    'Pamplona',
    'Estella-Lizarra',
    'Atarrabia',
    'Berriozar',
    'Baranain',
    'Tudela',
    'Burlata',
    'A Coruña',
    'Ferrol',
    'Narón',
    'Sada',
    'Ames',
    'Fene',
    'Verin',
    'Palencia',
    'Villamuriel de Cerrato',
    'Viveiro',
    'Vivero',
    'Toro',
    'Parets del Vallès',
    'la Garriga',
    'Cardedeu',
    'Polinya',
    'Palafolls',
    'Pineda de Mar',
    'Santa Coloma de Cervelló',
    'Calella',
    'Castellar del Valles',
    'Ibiza',
    'Santa Eulària des Riu',
    'Moratalla',
    'Totana',
    'Las Torres de Cotillas',
    'Cieza',
    'Palma de Mallorca',
    'Capdepera',
    'Inca',
    'Pollença',
    'Marratxí',
    'Premia de Mar',
    'Vilanova de Segriá',
    'Moia',
    'Ripoll',
    'Plegamans',
    'Manlleu',
    'Alboraya',
    'Sagunto',
    'Paterna',
    'Torrent',
    'Benisano',
    'San Antonio',
    'Gandia',
    'La Seca',
    'Molina de Segura',
    'Alcantarilla',
    'La Unión',
    'Jabali Nuevo',
    'Ceuti',
    'Los Garres',
    'Alcoy',
    'Villanueva de Castellon',
    'Alfafar',
    'Corbera de Llobregat',
    'Vilanova i la Geltrú',
    'Molins de Rei',
    'Cabrils',
    'Torrelles de Llobregat',
    'Calafell',
    'Castellet',
    'Sant Vicenc de Castellet',
    'Terrassa',
    'Montmeló',
    'Abrera',
    'els Hostalets de Pierola',
    'Silla',
    'Margarida',
    'La Llagosta',
    'Martorell',
    'el Masnou',
    'Tiana',
    'Sallent',
    'Santa Perpetua de Mogoda',
    'Santa Margarida',
    'Montseny',
    'Port de Sagunt',
    'Palmera',
    'Bormujos',
    'Bollullos de la Mitacion',
    'Salamanca',
    'San Javier',
    'Anora',
    'Penarroya-Pueblonuevo',
    'Galvez',
    'Muro del Alcoy',
    'La Llacuna',
    'Los Palacios y Villafranca',
    'Capellades',
    'Iznate',
    'Nerva',
    'La Galera',
    'Hellin',
    'Baza',
    'Sayalonga',
    'Monterrubio de la Serena',
    'Urda',
    'Tomelloso',
    'Villar del Arzobispo',
    'Alzira',
    'Mislata',
    'Albolote',
    'Atarfe',
    'Dos Hermanas',
    'Oasis (La Marina)',
    'Hernani',
    'Orihuela',
    'Rojales',
    'Laredo',
    'Elexalde',
    'Pucol',
    'Rafelbunyol',
    'Blanca',
    'Archena',
    'Mondujar',
    'Benalmádena',
    'Coin',
    'Yecla',
    'Ferreries',
    'Campos',
    'San Juan de Moro',
    'Montellano',
    'Oliva',
    'Olvera',
    'La Roda',
    'Almagro',
    'Librilla',
    'Villaverde del Rio',
    'Benicassim',
    'Argentona',
    'Alhaurin de la Torre',
    'El Pozo de los Frailes',
    'Gijón',
    'Mogente',
    "l' Argentera",
    'Nules',
    'Punta Umbría',
    'Aizoain',
    'Silleda',
    'Almoster',
    'Torres de Berrellen',
    'Casar de Caceres',
    'Aliseda',
    'Rute',
    'Tomares',
    'Legazpia',
    'Villacanas',
    'Rielves',
    'Milagro',
    'Boiro',
    'Sobarzo',
    'Zumaia',
    'Muriedas',
    'Santiago Pena',
    'Rebordanes',
    'Castalla',
    'Castilleja de la Cuesta',
    'Santpedor',
    'Cambre',
    'Villalar de los Comuneros',
    'Cheste',
    'Durango',
    'Petrel',
    'Arbo',
    'Balaguer',
    'Cabrera de Almanza',
    'Llafranc',
    'Ponferrada',
    'Elda',
    'Onil',
    'Mejorada del Campo',
    'La Puebla del Rio',
    'Albacete',
    'Hoya-Gonzalo',
    'Villaricos',
    'Adra',
    'Somo',
    'Arenys de Munt',
    'Fuensalida',
    'Falset',
    'Lleida',
    'Maspalomas',
    'Cuenca',
    'Alhaurin el Grande',
    'El Puerto de Santa María',
    'Manacor',
    'Culleredo',
    'Ribadavia',
    'Burgos',
    'Aspe',
    'Palafrugell',
    'Villarrobledo',
    'Pozoblanco',
    'Cruce de Arinaga',
    'Monforte de Lemos',
    'Oviedo',
    'Las Vegas',
    'Alcorisa',
    'Castilblanco de los Arroyos',
    'Tomino',
    'Biar',
    'el Morche',
    'Serracines',
    'Navalperal de Pinares',
    'Benidorm',
    'Villamiel de Toledo',
    'Ripollet',
    'Meis',
    'Catarroja',
    'Jaraiz de la Vera',
    'Llanera',
    'Avilés',
    'Pina de Ebro',
    'Jaca',
    'Villanueva del Rosario',
    'Vergel',
    'El Palmar',
    'Cobatillas',
    'Gallarta',
    'Langreo',
    'Sant Joan les Fonts',
    'El Papiol',
    'Couso de Salas',
    'Ribeira',
    "L'Alcudia",
    'Algemesi',
    'Melilla',
    'La Guardia de Jaen',
    'Los Villares',
    'Vilches',
    'La Carlota',
    'Utrera',
    'Las Cabezas de San Juan',
    'San Juan de Aznalfarache',
    'Santa Pau',
    'Bescano',
    'Cabanes',
    'Baena',
    'Cortiguera',
    'Cenes de la Vega',
    'Carlet',
    'Salinas',
    'Barakaldo',
    'Ribarroja',
    'Vinalesa',
    'Churriana de la Vega',
    'Santiago de Compostela',
    "Vilanova d'Escornalbou",
    'Marchamalo',
    'Vaciamadrid',
    'Torrelavega',
    'Limpias',
    'Ampuero',
    'Los Corrales de Buelna',
    'Liencres',
    'Maliano',
    'Colindres',
    'Santona',
    'Guarnizo',
    'Cabezon de la Sal',
    'Santiago de Cartes',
    'Santa Cruz de Bezana',
    'Liano',
    'Entrambasaguas',
    'Termino',
    'Soto de la Marina',
    'Renedo',
    'Calvià',
    'Llubí',
    'Santa Maria del Cami',
    'Felanitx',
    'ses Salines',
    'Muro',
    'Ibi',
    'Vila-real',
    'Novelda',
    "l'Alcora",
    'Paradas',
    'Chiclana de la Frontera',
    'Valencina de la Concepcion',
    'San Pedro',
    'Rafelguaraf',
    'Paterna del Campo',
    'Casabermeja',
    'Cambados',
    'Arrecife',
    'Écija',
    'La Roda de Andalucia',
    'Rioja',
    'Lloret de Mar',
    'Benitachell',
    'Mengibar',
    'Pilas',
    'Alcossebre',
    'Puerto del Carmen',
    'Carrion de los Cespedes',
    'Soller',
    'Puig',
    'Cocentaina',
    'Miramar',
    'Campillo',
    'Aznalcazar',
    'Herrera',
    'Burriana',
    'Salteras',
    'Chucena',
    'Alovera',
    'Olot',
    'Alcoletge',
    'Subirats',
    'Villabona',
    'Erandio',
    'Portugalete',
    'Usurbil',
    'Lekeitio',
    'Urtuella',
    'Eibar',
    'Ondarroa',
    'Huesca',
    'San Fernando',
    "Quart d'Onyar",
    'Moncada',
    "L'Eliana",
    'Rocafort',
    'Godella',
    'Laguna de Duero',
    'Cisterniga',
    'Porrino',
    'Ogijares',
    'Motril',
    'Albal',
    'Vilagarcia de Arousa',
    'Daganzo de Arriba',
    'Canals',
    'Cantillana',
    'Puerto',
    'Peñíscola',
    'Mirador',
    'Almodovar del Rio',
    'Capela',
    'Madrigalejo',
    'Estepa',
    'Bocairent',
    'Don Benito',
    'Aranda de Duero',
    'Ledana',
    'Fuente del Maestre',
    'Oleiros',
    'Foz',
    'Lalin',
    'As Pontes de Garcia Rodriguez',
    'Rianxo',
    'Oroso',
    'Teo',
    'Carballo',
    'Mino',
    'A Estrada',
    'Estrada',
    'Brion',
    'Ordes',
    'Arzua',
    'Betanzos',
    'Burela de Cabo',
    'O Carballino',
    'O Barco de Valdeorras',
    'Gondomar',
    'Rairiz de Veiga',
    'Redondela',
    'Curtis',
    'Friol',
    'Bergondo',
    'Trives',
    'Caion',
    'Muro',
    'Laracha',
    'Garcia',
    'Arino',
    'Villafranca del Campo',
    'Cabra',
    'Campillos',
    'La Rambla',
    'Rota',
    'Alanis',
    'Sanlúcar de Barrameda',
    'Ronda',
    'Tordera',
    'Valls',
    'Baga',
    'Castellbell i el Vilar',
    'Cubelles',
    'Camargo',
    'Pradejon',
    'Arce',
    'La Alberca',
    'Denia',
    'Almassora',
    'Moja',
    'Sant Andreu de la Barca',
    'Viladecans',
    'Santa Coloma de Gramenet',
    'Algorta',
    'Los Navalucillos',
    'Estepona',
    'Idiazabal',
    'Puertollano',
    'el Campello',
    'Calpe',
    'Javea',
    'Sant Feliu de Guíxols',
    'Celra',
    'Pau',
    'Vila',
    'Lardero',
    'La Línea de la Concepción',
    'Amposta',
    'El Vendrell',
    'Bueu',
    'Plasencia',
    'Paiporta',
    'Santa Maria',
    'Gines',
    'la Vall',
    'Irura',
    'Frailes',
    'Montesquiu',
    'Salceda',
    'Ciudad Real',
    'Muchamiel',
    'Mancha Real',
    'Huetor Vega',
    'Maracena',
    'Costa',
    'Santurtzi',
    'Talavera de la Reina',
    'Entrevias',
    "Sant Sadurni d'Anoia",
    'Gurb',
    'Berga',
    'Altea',
    'Pola de Siero',
    'Mieres',
    'Utebo',
    'Calatayud',
    'Casetas',
    'Palamós',
    'Armilla',
    'Cala del Moral',
    'Casares',
    'Arroyo de la Miel',
    'Andujar',
    'Monzon',
    'Churra',
    'Beniajan',
    'Salou',
    'Calahorra',
    'Almuñécar',
    'Martos',
    'Medina del Campo',
    'Nerja',
    'Alcalá de Guadaira',
    'Villajoyosa',
    'Zarautz',
    'La Rinconada',
    'Carmona',
    'Montcada i Reixac',
    'Figaro',
    'Bargas',
    'Miguelturra',
    'Santa Maria de Palautordera',
    'Alella',
    'Zaramillo',
    'Moguer',
    'Mairena del Alcor',
    'Santomera',
    'Gernika-Lumo',
    'Cala Millor',
    'Churriana',
    'San Agustin de Guadalix',
    'Requena',
    'Teulada',
    'Alameda',
    'Palma del Rio',
    'Fuentes de Andalucia',
    'Cehegín',
    'San Vicent del Raspeig',
    'Almonte',
    'Valverde del Camino',
    'Caravaca',
    'Las Islas',
    'Guardo',
    'Trobajo del Camino',
    'Armunia',
    'San Andres del Rabanedo',
    'Miranda de Ebro',
    'Montearagon',
    'Albarreal de Tajo',
    'Anchuelo',
    'Alberic',
    'Priego de Cordoba',
    'Mora de Rubielos',
    'La Fresneda',
    'Ateca',
    'Andorra',
    'Ceuta',
    'Espartinas',
    'Villares de la Reina',
    'Noain',
    'Mutilva Baja',
    'Huarte-Araquil',
    'Alguazas',
    'Incio',
    'Tordesillas',
    'Pedrajas',
    'Artana',
    'Elizondo',
    'Urdiain',
    'Mayor',
    'Noja',
    'Saron',
    'Mugardos',
    'Garriguella',
    'Godelleta',
    'Argamasilla de Alba',
    'Medina-Sidonia',
    'Socuellamos',
    'Guadarrama',
    'Monserrat',
    'Huertas',
    'Firgas',
    'Meliana',
    'Catral',
    'Benejuzar',
    'Covaleda',
    'Canet de Mar',
    'Balenya',
    'Corralejo',
    'Escalonilla',
    'Villasequilla de Yepes',
    'La Puebla de Montalban',
    'Navata',
    'Vilajuiga',
    'Bas',
    'Cadaqués',
    "Móra d'Ebre",
    'Alcanar',
    'Ventallo',
    'Ontigola',
    'Alumbres',
    'San Roque',
    'Santa Lucia',
    'Arenys de Mar',
    'Loja',
    'Cijuela',
    'La Algaba',
    'Piedrabuena',
    'Simancas',
    'Ajofrin',
    'Vilanova',
    'Santalla',
    'Chantada',
    'Malpartida de Caceres',
    'Arroyo',
    'Egues-Uharte',
    'Castejon',
    'Corella',
    'Arcicollar',
    'Olias del Rey',
    'El Viso de San Juan',
    'Cistierna',
    'Palomares del Rio',
    'San Miguel',
    'Lomo de Arico',
    'Aldeatejada',
    'Alhendin',
    'Navalcan',
    'Labajos',
    'Poblete',
    'Prado del Rey',
    'Artajo',
    'Arroyo de la Luz',
    'Montehermoso',
    'Vinaros',
    'Borriol',
    'Benicarló',
    'Cala',
    'Breda',
    'La Bisbal',
    'Sant Pere de Torelló',
    'Vilabertran',
    'Joanetes',
    'Serra',
    'Turia',
    'Arges',
    'La Canonja',
    'Taboada',
    'Trujillanos',
    'Busturi-Axpe',
    'Barcarrota',
    'Salvatierra de los Barros',
    'Berja',
    'Duenas',
    'Reyes',
    'Navarrete',
    'Quel',
    'Portocolom',
    'Algaida',
    'Riells i Viabrea',
    'Navarcles',
    'Masquefa',
    'Lepe',
    'Aljaraque',
    'Cabana',
    'Cacheiras',
    'Sils',
    'Bellvis',
    'Sabinanigo',
    'Fraga',
    'Teguise',
    'Ugijar',
    'Sant Joan de les Abadesses',
    'Villarrubia de Santiago',
    'Almenar',
    'Alcarras',
    "el Palau d'Anglesola",
    'Els Monjos',
    'Beniganim',
    'Agullent',
    'Albaida',
    'Matadepera',
    'Alcantara',
    'Madrigal de la Vera',
    'Mestanza',
    'Ávila',
    'Cardenosa',
    'Soria',
    'La Flecha',
    'Villalba de los Alcores',
    'Puente-Genil',
    'Navarres',
    'Collbato',
    'Lasarte',
    'Valencia de Don Juan',
    'Cea',
    'Pomar de Cinca',
    'Fruiz',
    'Ubrique',
    'Guadiaro',
    'La Mojonera',
    'San Cristobal de Entrevinas',
    'Castello',
    'Villablino',
    'Basauri',
    'Priego',
    'La Figuera',
    'Paracuellos de Jarama',
    'Alcañiz',
    'Lledo',
    'Toreno',
    'Villaconejos',
    'Ibarra',
    'Cintruénigo',
    'Valdemaqueda',
    'Hernansancho',
    'Navaconcejo',
    'Galaroza',
    'Castrillon',
    'Arriate',
    'El Saler',
    'Santisteban del Puerto',
    'Ibros',
    'Durcal',
    'Vilamalla',
    'Arnedo',
    'Quer',
    'Haro',
    'Escatron',
    'Reocin',
    'Nájera',
    'Cabanillas del Campo',
    'Igualeja',
    'Zalamea la Real',
    'Cartaya',
    'Minas de Riotinto',
    'Guía de Isora',
    'La Puerta de Segura',
    'Bailen',
    'Ataun',
    'Alcala de Xivert',
    'Nambroca',
    'Mocejon',
    'Puerto de Gandia',
    'Amurrio',
    'Villamalea',
    'Plasenzuela',
    'Jerte',
    'Villafranca de Cordoba',
    'Venta',
    'San Pedro',
    'Valladolises',
    'La Cavada',
    'Parbayon',
    'Baeza',
    'Oyon',
    'Eneriz',
    'San Sebastian de los Ballesteros',
    'Algar',
    'Mediona',
    'Conil de la Frontera',
    'Manises',
    'El Barco de Avila',
    'Cabrerizos',
    'Villabanez',
    'Valencia',
    'Gilet',
    'Cazorla',
    'Agüimes',
    'Numancia de la Sagra',
    'Collado Mediano',
    'Boltana',
    'Sojuela',
    'Oliana',
    'La Torre',
    'Escalona',
    'Villaluenga',
    'Iznajar',
    'Teià',
    'Castro',
    'Reinosa',
    'Yeles',
    'Enguera',
    'La Batlloria',
    'Carranque',
    'Lantadilla',
    'Coiros',
    'Torreaguera',
    'Alquerias',
    'Aiguafreda',
    'Meco',
    'Coria',
    'Sierra de Fuentes',
    'Balerma',
    'Zubia',
    'Graus',
    'Gandesa',
    'Guadalix de la Sierra',
    'Gerena',
    'Chelva',
    'San Nicolas',
    'Yepes',
    'Ocana',
    'Cercedilla',
    'Posadas',
    'Tarancon',
    'Carinena',
    'Órgiva',
    'Odena',
    'La Robla',
    'La Llosa',
    'Pals',
    'Begur',
    'Fernán Núñez',
    'Montilla',
    'Cangas de Onis',
    'Torrijos',
    'Valdepenas',
    'El Medano',
    'Horta de Sant Joan',
    'Finestrat',
    'Montemayor de Pililla',
    'Boecillo',
    'Huecas',
    'Palol',
    'Sarria',
    'Hinojosa del Duque',
    'Huelves',
    'Villar de Olalla',
    'El Sauzal',
    'Urroz',
    'El Vellon',
    'Jimena de la Frontera',
    'Arcos de la Frontera',
    'La Yedra',
    'La Abadilla',
    'Viveda',
    'Alava',
    'La Puebla de Hijar',
    'Beniarres',
    'Ahigal',
    'Paracuellos',
    'Axpe',
    'Arbizu',
    'Carcar',
    'Pueyo',
    'Serranillos del Valle',
    'El Molar',
    'Arcade',
    'el Poal',
    'Beas',
    'Pola de Laviana',
    'Bolvir',
    'Toral de los Vados',
    'Aguilar de Campoo',
    'Saldana',
    'Selva',
    'Capileira',
    'Osuna',
    'Villa del Prado',
    'Ribes de Freser',
    'El Real de la Jara',
    'Sorbas',
    'Lopera',
    'Barbastro',
    'Alcúdia',
    'Cortegana',
    'Isla Cristina',
    'Arama',
    'Arafo',
    'Rivabellosa',
    'Aravaca',
    'Alpedrete',
    'Alaró',
    'Monistrol de Calders',
    'La Virgen del Camino',
    'Viana',
    'Formentera de Segura',
    'Arjona',
    'Beas de Segura',
    'Ciutadella',
    'Andoain',
    'Pescueza',
    'Casas del Monte',
    'Palau',
    'Benahavis',
    'Gallur',
    'Morata de Jalon',
    'Quintanar del Rey',
    'Utiel',
    'Villarcayo',
    'Valderrey',
    'Villafranca de los Caballeros',
    'Parcent',
    'Robledillo de la Vera',
    'Hervás',
    'Albalat dels Tarongers',
    'Aizarnazabal',
    'Nubledo',
    'Montgat',
    'Penagos',
    'Sopela',
    'Villasana de Mena',
    'Gironella',
    'Olesa de Bonesvalls',
    'La Rapita',
    'Castellvell del Camp',
    'Santa Barbara',
    'Albalate de Cinca',
    'El Grado',
    'Porto',
    'Cerdeda',
    'Camarinas',
    'Oliva de Plasencia',
    'Villanueva de la Serena',
    'Marin',
    'Peligros',
    'Frigiliana',
    'Lerin',
    'Tamames',
    'Celanova',
    'Torrecaballeros',
    'Carpio',
    'Mojados',
    'Penafiel',
    'Chipiona',
    'La Caridad',
    'Solares',
    'Torreperogil',
    'Altafulla',
    'Santa Oliva',
    'Pego',
    "l'Ametlla de Mar",
    'El Espinar',
    'San Esteban del Valle',
    'Vinales',
    'Vinaixa',
    'Alfaro',
    'Casla',
    'Archidona',
    'Dumbria',
    'Valdesogo de Abajo',
    "l'Estartit",
    'Torresandino',
    'Sant Josep de sa Talaia',
    'Benameji',
    'Villarquemado',
    'Espejo',
    'Valdecabras',
    'Quintana de Raneros',
    'Alaejos',
    'Pedrajas de San Esteban',
    'Hontanares de Eresma',
    'Laguardia',
    'Coca',
    'Segovia',
    'Huercanos',
    'Tormantos',
    'Autol',
    'Cenicientos',
    'Bermeo',
    'Tarazona de la Mancha',
    'Almensilla',
    'Sant Pere, Santa Caterina i La Ribera',
    'Carrion de los Condes',
    'Alforja',
    'Torreblascopedro',
    'Cobisa',
    'Bernuy',
    'Falces',
    'Villamayor',
    'Portonovo',
    'Torrejon del Rey',
    'Tortola de Henares',
    'Lugones',
    'Candas',
    'Playa Blanca',
    'Galapagos',
    'Polop',
    'San Juan de Alicante',
    'Coristanco',
    'Villarta de San Juan',
    'Hontoria del Pinar',
    'Traslavina',
    'El Burgo de Osma',
    'Villamayor',
    'Mecina Fondales',
    'Purchil',
    'Gelves',
    'Velez de Benaudalla',
    'La Almunia de Dona Godina',
    'Aguilar del Rio Alhama',
    'Jumilla',
    'Herrera de Pisuerga',
    'Fuente Vaqueros',
    'Grado',
    'Beriain',
    'El Ballestero',
    'Salobre',
    'Vedra',
    'Corme-Porto',
    'Pozuelo del Rey',
    'Binefar',
    'Abanto',
    'Los Narejos',
    'Alberite',
    'Elburgo',
    'Son Curt',
    'Derio',
    'La Oliva',
    'Villamediana de Iregua',
    'Daya Vieja',
    'Sotrondio',
    'Villafranca de los Barros',
    'Anorbe',
    'Puerto',
    'Casas del Castanar',
    'Navalmoral de la Mata',
    'Santa Coloma de Farners',
    'Nanclares de la Oca',
    'Medinya',
    'Manzanares el Real',
    'Torremenga',
    'Cuarte de Huerva',
    'Buñol',
    'Portus',
    'Pedrezuela',
    'Ingenio',
    'Camarenilla',
    'Sarria de Ter',
    'Salas de los Infantes',
    'Guitiriz',
    'Arrigorriaga',
    'Zamudio',
    'Elorrio',
    'La Muela',
    'Mariola',
    'Quijorna',
    'Masdenverge',
    'Ajalvir',
    'Villatuerta',
    'Madridejos',
    'Dosbarrios',
    'Consuegra',
    'Ugena',
    'Cubas',
    'Pinoso',
    "L'Arboc",
    'Guadiana del Caudillo',
    'Robledillo de Gata',
    'Garrovillas',
    'Las Galletas',
    'Salt',
    'Luanco',
    'Pravia',
    'Illas',
    'Riudellots de la Selva',
    'els Pallaresos',
    'Cunit',
    "L' Espluga de Francoli",
    'Pedro Abad',
    'Val de Santo Domingo',
    'Miraflores de la Sierra',
    'Riudoms',
    'el Morell',
    'La Adrada',
    'La Guardia',
    'Cabanas de la Sagra',
    'Guadamur',
    'La Pueblanueva',
    'Petres',
    'Cubas',
    'Perales del Puerto',
    'Los Navalmorales',
    'La Orden',
    'Llagostera',
    'El Garrobo',
    'Consell',
    'Huelma',
    'Benavente',
    'Huete',
    'Langa de Duero',
    'Ares',
    'Villares del Saz',
    'Ayamonte',
    'Alosno',
    'Sant Carles de la Rapita',
    'Beniarjo',
    'Moya',
    'La Roca del Valles',
    'Avia',
    'Villaviad',
    'Cecenas',
    'San Felices',
    'Lamadrid',
    'Navia',
    'Capella',
    'Bellpuig',
    'Villar de Gallimazo',
    'Valdepolo',
    'Carcastillo',
    'Miranda del Castanar',
    'Santa Marta de Tormes',
    'Malpica',
    'La Calzada de Oropesa',
    'Torre del Mar',
    'Sufli',
    'Casas Viejas',
    'Sestao',
    'Galdakao',
    'Alhama de Granada',
    'Villacarrillo',
    'Navàs',
    'Olite',
    'Illora',
    'Artesa de Segre',
    'Luzaide',
    'Colmenar de Oreja',
    "Vallbona d'Anoia",
    'Gata de Gorgos',
    'Alcover',
    'Deba',
    'Constanti',
    'Aguilar',
    'Candeleda',
    'Manilva',
    'San Martin',
    'Yunquera de Henares',
    'Ador',
    'Amer',
    'Castejon del Puente',
    'Santa Fe',
    'Santa Eulalia',
    'Brea de Aragon',
    'Santiago de la Ribera',
    'Padrón',
    'Flix',
    'Calasparra',
    'Villafranca',
    'la Nucia',
    'Berango',
    'Macanet de la Selva',
    'Mula',
    'Luyando',
    'Torrefarrera',
    'Casarrubios del Monte',
    'Soto',
    'Lebrija',
    'Valleseco',
    'Los Yebenes',
    'Salcedo',
    'Castellbisbal',
    'Neda',
    'Espiel',
    'Recas',
    'Santa Brigida',
    'Balsareny',
    'Humanes',
    'Alcazar de San Juan',
    'Lagartera',
    'Carbajal de la Legua',
    'Teruel',
    'Ezcaray',
    'Tafalla',
    'Alora',
    'Torrox',
    'Sotopalacios',
    'La Pobla de Claramunt',
    'Cardona',
    'Camponaraya',
    'Cortes de la Frontera',
    'La Vinuela',
    'Velilla de San Antonio',
    'La Selva',
    'Verges',
    'Mira',
    'Llerena',
    'Belgida',
    'Navaridas',
    'La Codosera',
    'Pinos del Valle',
    'Sanlucar la Mayor',
    'Los Montes',
    'Espirdo',
    'La Calzada',
    'Mondonedo',
    'Orellana la Vieja',
    'Olvega',
    'Villaquilambre',
    'Castillo del Romeral',
    'Sabucedo',
    'Llinars del Valles',
    'Mora',
    'Huetor-Tajar',
    'Albatera',
    'Figueras',
    'Monteagudo',
    'Sueca',
    'Sedavi',
    'Esplugues de Llobregat',
    'Tarrega',
    'Torredembarra',
    'La Riba',
    'Alfondeguilla',
    'Altet',
    'La Puebla de Almoradiel',
    'Herencia',
    'Zaratamo',
    'Chauchina',
    'Esteiro',
    'Botorrita',
    'Chiclana de Segura',
    'Urnieta',
    'Renedo',
    'Villacastin',
    'Hornachuelos',
    'Mallen',
    'Cogollos',
    'Quincoces de Yuso',
    'Odon',
    'Rosell',
    'Sancti Spiritus',
    'Santiurde de Toranzo',
    'Bujaraloz',
    'Belicena',
    'Almendralejo',
    'Trillo',
    'Villamayor',
    'La Torre',
    'San Bartolomé de Tirajana',
    'Castelseras',
    'La Palma del Condado',
    'la Bisbal del Penedes',
    'Llano',
    'Mondariz',
    'Monterroso',
    'Hinojosa del Valle',
    'Guimar',
    'Guarena',
    'Otero',
    'Ribera del Fresno',
    'Cruz',
    'Mezalocha',
    "S'Arenal",
    'Perillo',
    'Guardamar del Segura',
    'Valderrobres',
    'Sesena Nuevo',
    'Talamanca de Jarama',
    'Puerto del Rosario',
    'Uceda',
    'Alfambra',
    'La Arena',
    'Calo',
    'Orpesa/Oropesa del Mar',
    'Abaran',
    'Luarca',
    'Olazagutia',
    'Yuncler',
    'Igueste',
    'Zarzalejo',
    'Villa',
    'Monòver',
    'Lucillos',
    'Villacondide',
    'La Antilla',
    'Zarzuela',
    'Pereiro de Aguiar',
    'Muros de Nalon',
    'Cati',
    'Arnedillo',
    'La Paz',
    'La Carolina',
    'El Tablero',
    'Ojen',
    'La Granja',
    'Teror',
    'Marmolejo',
    'Mollina',
    'Mazarrón',
    'Diezma',
    'Limones',
    'Bornos',
    'Los Santos de la Humosa',
    'Malpartida de Plasencia',
    'Santa Maria del Campo',
    'Palos de la Frontera',
    'Roales',
    'Benatae',
    'Olmedo',
    'Guadalcazar',
    'Maside',
    'La Florida',
    'Ricla',
    'Estellencs',
    'Artajona',
    'Fitero',
    'Fortuna',
    'Tudela de Duero',
    'El Toro',
    'Quintes',
    'Cangas del Narcea',
    'Corbera',
    'Villarejo',
    'Molina de Aragon',
    'Alegria',
    'Arenas de San Pedro',
    'Villamayor de Santiago',
    'Mercadal',
    'Higuera la Real',
    'Villanueva de la Torre',
    'Sabero',
    'La Fuente de San Esteban',
    'Albujon',
    'Membrilla',
    'Almodovar del Campo',
    'Pola de Lena',
    'Cruilles',
    'Asteasu',
    'Tobarra',
    'Useras',
    'Salobreña',
    'Algadefe',
    'Lliria',
    'Baina',
    'Monesterio',
    'Benagalbon',
    'Astigarraga',
    'Alhama de Murcia',
    'Cerro Muriano',
    'Faura',
    'Gata',
    'Cuadros',
    'Palmeira',
    'El Corrillo',
    'Santa Marta de Ortigueira',
    'Sierra',
    'Plan',
    'Plentzia',
    'Moriscos',
    'Barbate',
    'Santa Cruz de la Zarza',
    'Benissa',
    'Galdames Beitia',
    'Herrera del Duque',
    'Santa Eugènia de Berga',
    'Cifuentes',
    'Montijo',
    'Paradela',
    'Alcanadre',
    'Pinseque',
    'Cantimpalos',
    'La Secuita',
    'la Febro',
    'Ouces',
    'Vilafant',
    'Mura',
    'Torrecilla de Alcaniz',
    'Tuineje',
    'Ibarruri',
    'Cuntis',
    'Cedillo del Condado',
    'Yaiza',
    'Ledrada',
    'Terradillos',
    'Camas',
    'Muros',
    'Malpica',
    'Villarrubia de los Ojos',
    'Sant Andreu de Llavaneres',
    'Fornells de la Selva',
    'Vejer de la Frontera',
    'Agaete',
    'Guamasa',
    'San Luis de Sabinillas',
    'Comares',
    'La Serna',
    'Villaralbo',
    'Navatejera',
    'La Granada',
    'Albuixech',
    'Museros',
    'Massamagrell',
    'La Plana',
    'Torrent',
    'Aldaia',
    'Villarreal de Alava',
    'Vilavella',
    'Burjassot',
    'Benimodo',
    'La Mancha Blanca',
    'Berastegui',
    'Zuazo de Vitoria',
    'Olaberria',
    'Mendaro',
    'Anoeta',
    'Orio',
    'Berriz',
    'La Puebla de Arganzon',
    'Gizaburuaga',
    'Moraleja de Enmedio',
    'Villacarriedo',
    'San Jorge',
    'Lucena del Puerto',
    'San Martino',
    'El Raal',
    'Cenicero',
    'Fuenmayor',
    'Santo Domingo de la Calzada',
    'Berriobeiti',
    'Marcilla',
    'Aduna',
    'Fuentes de Ebro',
    'Vilamaniscle',
    'Navacerrada',
    'Santa Maria del Paramo',
    'Norena',
    'Brafim',
    'Cabanas',
    'Monzalbarba',
    'Rincon',
    'Cabezo de Torres',
    'Garachico',
    'Fasnia',
    'Segura de la Sierra',
    'Riba',
    'Anglesola',
    'Juneda',
    'Barranda',
    'la Nou de Bergueda',
    'Caldelas',
    'Catoira',
    'Lillo',
    'Allariz',
    'Coles',
    "La Seu d'Urgell",
    'Azagra',
    'Campohermoso',
    'Maria de la Salut',
    'Coria del Río',
    'Aldealsenor',
    'Golmayo',
    'Bigues i Riells',
    'Torrelletas',
    'Cidones',
    'San Feliz de Torio',
    'Medrano',
    'Premiàde Dalt',
    'Guenes',
    'Tombrio de Arriba',
    'Samalús',
    'Yebes',
    'Grove, O',
    'Encinasola',
    'Sevares',
    'Valverde del Fresno',
    'Martin',
    'Zahara de los Atunes',
    'Fortia',
    'Mutiloa',
    'Illana',
    'Checa',
    'Rios',
    'Montfulla',
    'Torres de Segre',
    'Llanes',
    'Carrascal de Barregas',
    'Martinet',
    'San Anton',
    'Suances',
    'Selaya',
    'Sotogrande',
    'Azpeitia',
    'Crevillent',
    'Morro del Jable',
    'Albalat dels Sorells',
    'Sepulveda',
    'La Virgen del Pilar',
    'Costur',
    'Andeiro',
    'La Pedrera',
    'Valdezufre',
    'Puigpelat',
    'Aldeanueva de Ebro',
    'La Campana',
    'Marugan',
    'Pantoja',
    'Puerto Lumbreras',
    'Navamorcuende',
    'Santa Uxia de Ribeira',
    'La Perdoma',
    'Casillas de Coria',
    'Urduna',
    'Motilla del Palancar',
    'Henares',
    'San Juan del Puerto',
    'Trazo',
    'Villanueva de Gallego',
    'El Perello',
    'Villaverde de los Cestos',
    'Calders',
    'Almonacid de Zorita',
    'Ipazter',
    'Alonsotegi',
    'San Pedro',
    'Villanueva de Gomez',
    'Los Dolores',
    'Benavarri / Benabarre',
    'Sixto',
    'Agoncillo',
    'Santovenia de Pisuerga',
    'Cuellar',
    'Tias',
    'Lago',
    'Fuente el Saz',
    'Oliva de Merida',
    'Sant Jordi',
    'El Viso del Alcor',
    'Pedralba',
    'Benisuera',
    'Tarazona',
    'Anguciana',
    'Algezares',
    'Cervera del Rio Alhama',
    'Castellar de la Frontera',
    'Mombeltran',
    'La Granja de San Vicente',
    'Bicorp',
    'Umbrete',
    'Losar de la Vera',
    'Traiguera',
    'Chilches',
    'Getaria',
    'Aielo de Malferit',
    'Cassàde la Selva',
    'Chiloeches',
    'Valdeaveruelo',
    'Verdu',
    'Perafort',
    'Castillo de Locubin',
    'O Grove',
    'Cilleros',
    'Lobios',
    "L'Olleria",
    'Gamiz',
    'Avinyó',
    'Arteixo',
    'Madronera',
    'Grijota',
    'Perdiguera',
    'Cazalegas',
    'Garciaz',
    'Canillas de Albaida',
    'Son Servera',
    'Torija',
    'Moscas del Paramo',
    'Corrales',
    'Artà',
    'Ejea de los Caballeros',
    'la Riera de Gaia',
    'Pauels',
    'Antigua',
    'Almargen',
    'Piedra',
    'Pontos',
    'Encinas Reales',
    'Elgorriaga',
    'Pajara',
    'Caldes de Malavella',
    'Agullana',
    'Bullas',
    'Alba de Tormes',
    'Carrizo de la Ribera',
    'Pont de Molins',
    'Sagra',
    'Benavides',
    'Casillas del Angel',
    'Vilacolum',
    'Los Rosales',
    'Penaflor',
    'Callosa de Segura',
    'Itza',
    'Vallada',
    'Babilafuente',
    'Aldeaseca de Armuna',
    "L'Albir",
    'Madridanos',
    'Aldeamayor de San Martin',
    'Briviesca',
    'Fabero',
    'Catral',
    'Vicolozano',
    'Fresno de la Vega',
    'Puentenansa',
    'Ramales de la Victoria',
    'Camarma de Esteruelas',
    'Genoves',
    'Nivar',
    'Montagut',
    'Portillo',
    'Ondara',
    'Olocau',
    'Jorba',
    'Tineo',
    'Navas del Rey',
    'Corvera',
    'Puebla del Maestre',
    'Corvera',
    'Fuente-Alamo',
    'Valsequillo de Gran Canaria',
    'Camos',
    'Pruvia',
    'Dolores',
    'Argamasilla de Calatrava',
    'Magan',
    'Torres',
    'El Abrigo',
    'Villasevil',
    'Cruces',
    'Bonmatí',
    'Almansa',
    'Mont',
    'Pulgar',
    'Antas',
    'Almazcara',
    'Sarinena',
    'Barros',
    'Rasines',
    'Almudebar',
    'Trespaderne',
    'Jerez de los Caballeros',
    'Puentelarra',
    'Begis',
    'Morella',
    'Argonos',
    'Porto Cristo',
    'Ferreira',
    'San Bartolomé',
    'Dalias',
    'Jabaga',
    'Yuncos',
    'Nalda',
    'Villanueva de Cordoba',
    'Cubillos del Sil',
    'Infantes',
    'Carabana',
    'Begijar',
    'Talarn',
    'Agramunt',
    'Villamartin',
    'Sarroca de Lleida',
    'Rodonya',
    'Morata de Tajuna',
    'Castandiello',
    'San Blas',
    'Matapozuelos',
    'La Espina',
    'Caso',
    'Broto',
    'Mata',
    'Zubiri',
    'Carral',
    'Meano',
    'Borox',
    'Novallas',
    'Laudio-Llodio',
    'Mentrida',
    'Mutriku',
    'Grazalema',
    'Berantevilla',
    'Corral de Almaguer',
    'Hostalric',
    'Abanilla',
    'Alburquerque',
    'Rafelcofer',
    'Lorqui',
    'Gaceta',
    'Pelayos de la Presa',
    'San Clemente',
    'Gojar',
    'Sant Marti de Malda',
    'Cabanaquinta',
    'Torre del Campo',
    'Chinchilla de Monte Aragon',
    'La Penilla',
    'Castellvi de Rosanes',
    'Seva',
    'Planes',
    'La Pedraja de Portillo',
    'Zuera',
    'Alagon',
    'Corgo',
    'Torredonjimeno',
    'Ron',
    'Avinyonet del Penedes',
    'Les Fonts',
    'Alfara de Algimia',
    'Siguenza',
    'Brihuega',
    'Castrogonzalo',
    'Calamonte',
    'Fuensanta de Martos',
    'Aldeanueva',
    'Lajares',
    'Torrelaguna',
    'Monachil',
    'Vitigudino',
    'Horcajo de las Torres',
    'Nestares',
    'Somahoz',
    'San Miguel de las Duenas',
    'Alfarrasi',
    'Sax',
    'Berriatua',
    'La Garganta',
    "Bellcaire d'Emporda",
    'San Rafael',
    'Tijarafe',
    'Amavida',
    'San Isidro',
    'Bollullos par del Condado',
    'Periedo',
    'Oruna',
    'Ajo',
    'Ortiguero',
    'Cudillero',
    'Sangüesa',
    'Echarri-Aranaz',
    'Pozo de la Serna',
    'Campo de Criptana',
    'Pedro Munoz',
    'Villar',
    'Sant Pere de Riudebitlles',
    'Viladrau',
    'Aranguren',
    'Llofriu',
    'Andosilla',
    'Lezama',
    "Platja d'Aro",
    'Albendin',
    'Canete de las Torres',
    'Pueyo de Santa Cruz',
    'Esplus',
    'Arico',
    'Anglès',
    'Pinar',
    'Villa del Rio',
    'Almadén',
    'Cox',
    'Jacarilla',
    'Bogajo',
    'Cornellana',
    'Villaseca de la Sagra',
    'Quintanar de la Orden',
    'Villamanrique',
    'Santa Cruz de Mudela',
    'Chozas de Canales',
    'Mosqueruela',
    'Zorraquin',
    'Zaratan',
    'La Canada de San Urbano',
    'La Arena',
    'Aznalcollar',
    "l'Escala",
    'Benidoleig',
    'Burguillos',
    'Cueto',
    'Villanueva de la Reina',
    'Matamorosa',
    'Cadiar',
    'Monda',
    'Bembibre',
    'Villanueva del Ariscal',
    'Bedmar',
    'Capmany',
    'Santa Olalla',
    'Pezuela de las Torres',
    'Beranga',
    'Collado',
    'Sigueiro',
    'Carcabuey',
    'el Catllar',
    'Masllorenc',
    'Súria',
    'La Frontera',
    'Escurial',
    'Cicero',
    'Bolanos de Calatrava',
    'Torralba de Calatrava',
    'Malagon',
    'Belvis de Jarama',
    'Puebla de la Calzada',
    'Montefrio',
    'Torrenueva',
    'Xunqueira de Espadanedo',
    'Aguilafuente',
    'Olula de Castro',
    'Benahadux',
    'Torremocha del Campo',
    'Albelda de Iregua',
    'Tremp',
    'Arevalo',
    'Boimorto',
    'Berguenda',
    'El Arahal',
    'Terrer',
    'Sarria',
    'Molinos de Duero',
    'Luzmela',
    'Riaza',
    'Pasaia',
    'Trasona',
    'Illueca',
    'Touro',
    'Vilanova de Arousa',
    'Lodosa',
    'San Adrian',
    'Legasa',
    'Cizur Menor',
    'Bara',
    'Mungia',
    'Medina de Pomar',
    'Abadino',
    'Usansolo',
    'Ciriza',
    'Gorriti',
    'Villacuende',
    'Revellinos',
    'Bustarviejo',
    'La Victoria',
    'Millares',
    'Sollana',
    'Nino Perdido',
    'Caserio El Campello',
    'Forua',
    'Landa',
    'Villanueva',
    'Villafranqueza',
    'Santo Angel',
    'Pedro',
    'Palmones',
    'Los Barrios',
    'La Canada',
    'Valverde',
    'San Roman',
    'Ontinyent',
    'La Nora',
    'Guadalupe',
    'Balazote',
    'Bejar',
    'Ciudad Rodrigo',
    'Astorga',
    'Montesa',
    "L'Alcudia de Crespins",
    'Manuel',
    'Quart de Poblet',
    'Caudete',
    'Mortera',
    'Valdecilla',
    'Ontur',
    'Naquera',
    'Loriguilla',
    'Fuentealbilla',
    'Benijofar',
    'Daimiel',
    'el Camp de Mirra',
    'Orce',
    'Galera',
    'Mequinensa / Mequinenza',
    'Salinas',
    'Solsona',
    'Padul',
    'Campo',
    'Sangonera la Verde',
    'Calig',
    'Santa Maria',
    'Gelsa',
    'Pedrola',
    'Torralba de Oropesa',
    'Venturada',
    'Pomar',
    'Alfajarin',
    "Canet d'En Berenguer",
    'Trebujena',
    'Espera',
    'Benifato',
    'Tamaraceite',
    'San Cristobal',
    'La Felguera',
    'La Baneza',
    'Cigales',
    'Fuentes de Valdepero',
    'Linyola',
    'Olivella',
    'Cerdanyola del Vallès',
    'Cebolla',
    'Villaviciosa',
    'Sotillo de la Adrada',
    'Cuevas de San Marcos',
    'Cajar',
    'La Herradura',
    'Huetor Santillan',
    'Dodro',
    'Oleiros',
    'Sodupe',
    'Placencia',
    'Legorreta',
    'Elgoibar',
    'Landa',
    'Azkoitia',
    'Compostela',
    'Rendar',
    'Orial',
    'Berron',
    'Villaviciosa',
    'Ribadesella',
    'Obanos',
    'Peralta – Azkoien',
    'Cadreita',
    'Betelu',
    'Mollo',
    'Serrateix',
    'Bellver de Cerdanya',
    'Siete Iglesias de Trabancos',
    'San Martin de Unx',
    'Lazagurria',
    'Eltzaburu',
    'Tordillos',
    'Valcabado',
    'Garrigas',
    'Guijuelo',
    'Robleda-Cervantes',
    'Trevino',
    "L'Ampolla",
    'Bordils',
    'Pedrosillo de Alba',
    'Gozon de Ucieza',
    'Mataluenga',
    'la Pobla de Montornes',
    'Santa Fe de Mondujar',
    'Tarifa',
    'Bajamar',
    'Alozaina',
    'La Playa de Arguineguin',
    'Salvatierra',
    'Marzagan',
    'Tejina',
    'Sierra de Luna',
    'La Rambla',
    'Gamonal',
    'Las Hortichuelas',
    'El Puerto',
    'Nueva',
    'Gibraleón',
    'El Sotillo',
    'Fuentes',
    'Aceuchal',
    'Villanueva de Perales',
    'Real de Gandia',
    'Venta de Banos',
    'Orusco',
    'Rajadell',
    'San Nicolas del Puerto',
    'Tenteniguada',
    'Pescador',
    'Santa Eulalia',
    'Hornachos',
    'Velilla',
    'Borben',
    'Guejar-Sierra',
    'Valdepenas de la Sierra',
    'Valderrubio',
    'El Torno',
    'Brazatortas',
    'Granja de Rocamora',
    'Almazan',
    'Guadalupe',
    'La Solana',
    'Competa',
    'Pruna',
    'Tous',
    'Castellar de Santiago',
    'Almassera',
    'Villarrubia',
    'Calonge',
    'Montillana',
    'Montejicar',
    'Sancibrian',
    'Elechas',
    'Pontejos',
    'Colinas de Trasmonte',
    'Lanaja',
    'Pioz',
    'Calahonda',
    'Moncofar',
    'Botarell',
    'La Calzada de Calatrava',
    'Cabezarrubias del Puerto',
    'Astrain',
    'Ullastrell',
    'Garraf',
    'Los Angeles',
    'Gautegiz Arteaga',
    'la Baronia de Rialb',
    'La Torre',
    'Castell de Ferro',
    'Alpicat',
    'Cabeza del Buey',
    'Navezuelas',
    'Capsec',
    'Ponts',
    'La Pola de Gordon',
    'Valdeavero',
    'Sarandones',
    'Miono',
    'Hinojedo',
    'Los Silos',
    'Titulcia',
    'Villabalter',
    'Campanario',
    'Beniarbeig',
    'Calaf',
    'Berrobi',
    'Horche',
    'Miranda de Arga',
    'Castropol',
    'Astudillo',
    'Daroca',
    'Agreda',
    'Vallmoll',
    'El Burgo de Ebro',
    'Pozo de Guadalajara',
    'Campuzano',
    'Ruente',
    'Barcena de Cicero',
    'Montmajor',
    'Benifairo de les Valls',
    'Abegondo',
    'Maceira',
    'Santo Domingo',
    'La Aljorra',
    'Riumors',
    'Santa Cristina de Aro',
    'Camprodon',
    'Campdevànol',
    'Llers',
    'Peratallada',
    'Vila-seca',
    'Fuente el Fresno',
    'Benamargosa',
    'Lerma',
    'San Pedro',
    'Colera',
    'Ordis',
    'Gordon',
    'Outes',
    'Posadas',
    'El Palmar de Troya',
    'Guillena',
    'Villanubla',
    'Fuentes de Jiloca',
    'Rincon de Soto',
    'El Provencio',
    'Constantina',
    'La Puebla de los Infantes',
    'Los Llanos de Aridane',
    'Altura',
    'Rubielos de Mora',
    'Valmojado',
    'Can Picafort',
    'San Juan',
    'Galizano',
    'Puente Viesgo',
    'Nembro',
    'Valverde del Majano',
    'Banugues',
    'Langa',
    'Arrubal',
    'Curro',
    'Villanueva de Azoague',
    'Las Navas de la Concepcion',
    'Perafita',
    'la Granadella',
    'Serena',
    'Gaucin',
    'Villanueva del Rio',
    'San Ildefonso',
    'Peralejo',
    'Ourense',
    'Cartelle',
    'Pastrana',
    'El Casar',
    'Lanjaron',
    'Herrera',
    'Maria de Huerva',
    'Villarluengo',
    'Cebreros',
    'Iniesta',
    'Dilar',
    'Redovan',
    'La Raya',
    'Montroy',
    'Para',
    'Pasai San Pedro',
    'Benacazon',
    'Monforte del Cid',
    'Agost',
    'Barbarroja',
    'Castilleja de Guzman',
    'Esparreguera',
    'Espinardo',
    'Roses',
    'Los Palacios',
    'Torrefeta',
    'Bellvei',
    'San Vicente',
    'Arnuero',
    'Ayegui',
    'Almuna',
    'Chapela',
    'Salvatierra de Mino',
    'Luou',
    'Vilasantar',
    'A Pobra do Caraminal',
    'Camarassa',
    'Arroyal',
    'Alza',
    'Lezo',
    'Golmes',
    'Central',
    'Ministerio',
    'El Pedernoso',
    'Villalpardo',
    'Hondon de las Nieves',
    'Rus',
    'Huerto',
    'Panillo',
    'Pinos Puente',
    'Otura',
    'Astrabudua',
    'Fuenterrabia',
    'Catadau',
    'Cala Ratjada',
    'Carbajosa de la Sagrada',
    'El Grao',
    'Carpesa',
    'Rafal',
    'Celeiros',
    'Santiponce',
    'Pedrosillo el Ralo',
    'Lijar',
    'Santa Cristina',
    'Adrall',
    'Benicolet',
    'Beniel',
    'Belvis de la Jara',
    'Parres',
    'Santa Eulàlia de Riuprimer',
    'Hernialde',
    'Poboleda',
    'Comillas',
    'Paredes de Nava',
    'Calatorao',
    'Hondon de los Frailes',
    'Castuera',
    'Alfara del Patriarca',
    'Poris de Abona',
    'Isora',
    'Illa',
    'Navas de Riofrio',
    'Cetina',
    'Valpalmas',
    'Senija',
    'Tahiche',
    'San Fernando',
    'Artenara',
    'San Vicente de Alcantara',
    'Cervo',
    'Pulianas',
    'Panticosa',
    'Turis',
    'La Cabrera',
    'Oza de los Rios',
    'Alcala del Obispo',
    'Villanueva del Fresno',
    'Sant Juliàde Vilatorta',
    'Mota del Marques',
    'Fregenal de la Sierra',
    'Segorbe',
    'Montalban de Cordoba',
    'Alhambra',
    'Villanueva de la Jara',
    'Miguel Esteban',
    'Pozoamargo',
    "s'Arracó",
    'Sardina',
    'Querol',
    'La Lantejuela',
    'Villamanrique de la Condesa',
    'Isla Becoa',
    'Montealegre del Castillo',
    'Sudanell',
    'Tora de Riubregos',
    'Madrigueras',
    'Redes',
    'Soto de Cerrato',
    'Campina',
    'Torroella de Fluvia',
    'Saamasas',
    'Negreira',
    'Navahermosa',
    'Medio',
    'Campins',
    'Salobral',
    'Caspe',
    'La Torre',
    'Puebla de Don Fadrique',
    'La Canalosa',
    'Busot',
    'Alhama de Aragon',
    'Pulpí',
    'Bitem',
    'Hijar',
    'Guzman',
    'Padilla del Ducado',
    'Arca',
    'Buenache de Alarcon',
    'Noves',
    'Valdetorres',
    'Vilatenim',
    'Sant Feliu de Llobregat',
    'la Pobla de Mafumet',
    'Puigcerdà',
    'Algodonales',
    'Mejorada',
    'Algarrobo',
    'La Mamola',
    'Velez-Rubio',
    'Rebolledo',
    'Alcalá de los Gazules',
    'El Hoyo de Pinares',
    'Valle de Oro',
    'Macael',
    'Colonia de San Pedro',
    'Soneja',
    'Sobradiel',
    'Hurchillo',
    'Villa de Don Fadrique',
    'El Alamo',
    'O Mazo',
    'Quintana de la Serena',
    'Ontinena',
    'Moreda',
    'Nuez de Ebro',
    'Hospital',
    'Cardenal',
    'Canar',
    'Neda',
    'Celeiro',
    'Boceguillas',
    'La Matanza de Acentejo',
    'San Vicente de Leon',
    'Seoane',
    'Deustu',
    'Arroyo del Ojanco',
    'Canal',
    'Vilaplana',
    'Olivares',
    'la Vall del Bac',
    'Domingo Perez',
    'Santa Marina del Rey',
    'Tiagua',
    'Nava de la Asuncion',
    'Meira',
    'Zahinos',
    'Trabada',
    'Villahermosa',
    'Miajadas',
    'Teba',
    'Caneja',
    'Bigastro',
    'Los Corrales',
    'Las Ventanas',
    'Epila',
    'Luceni',
    'Grisen',
    'Aroche',
    'El Cuervo',
    'El Coronil',
    'Sobremazas',
    'Valdetorres de Jarama',
    'A Cidade',
    'Argelaguer',
    'La Floresta Pearson',
    'Villalonga',
    'Espolla',
    'Lousame',
    'Segura',
    'Polan',
    'Los Rios',
    'Guevejar',
    'Caborana',
    'Ujo',
    'Ziga',
    'Nora',
    'La Gineta',
    'Triana',
    'Blancos',
    'Parada',
    'Lama',
    'Gargallo',
    'Borja',
    'La Luisiana',
    'Huevar',
    'Beznar',
    'Fuensanta',
    'Lires'
  ],
  Austria: [
    'Vienna',
    'Kapfenberg',
    'Kirchham',
    'Hohenzell',
    'Gmunden',
    'Molln',
    'Judendorf',
    'Ebersdorf',
    'Hinterstoder',
    'Sarleinsbach',
    'Sankt Michael im Burgenland',
    'Bad Ischl',
    'Salzburg',
    'Linz',
    'Fieberbrunn',
    'Graz',
    'Seiersberg',
    'Hohenau',
    'Brixen im Thale',
    'Schwaz',
    'Maria Enzersdorf',
    'Tulln',
    'Hartkirchen',
    'Hermagor',
    'Imst',
    'Tarrenz',
    'Jerzens',
    'Plangeross',
    'Wilhelmsburg',
    'Melk',
    'Korneuburg',
    'Giesshubl',
    'Stockerau',
    'Mödling',
    'Mondsee',
    'Saalfelden am Steinernen Meer',
    'Weiz',
    'Weinburg am Sassbach',
    'Feldbach',
    'Waidhofen an der Ybbs',
    'Seitenstetten Markt',
    'Schweiggers',
    'Weitra',
    'Wallern an der Trattnach',
    'Neumarkt im Hausruckkreis',
    'Sankt Florian',
    'Wartberg an der Krems',
    'Taufkirchen an der Trattnach',
    'Zell an der Ybbs',
    'Kopfing im Innkreis',
    'Pottendorf',
    'Weigelsdorf',
    'Klosterneuburg',
    'Vosendorf',
    'Markgrafneusiedl',
    'Munchendorf',
    'Pillichsdorf',
    'Pichling bei Stainz',
    'Zettling',
    'Stainz',
    'Frohnleiten',
    'Lannach',
    'Deutschlandsberg',
    'Unterrohr',
    'Pischelsdorf in Steiermark',
    'Kindberg',
    'Fehring',
    'Neunkirchen',
    'Oberkohlstaetten',
    'Wiener Neustadt',
    'Grunbach am Schneeberg',
    'Ternitz',
    'Pitten',
    'Marktl',
    'Parndorf',
    'Ollern',
    'Schonkirchen',
    'Strasshof an der Nordbahn',
    'Traiskirchen',
    'Mauerbach',
    'Pettnau',
    'Schwoich',
    'Aschau',
    'Aldrans',
    'Jochberg',
    'Obernberg am Brenner',
    'Wels',
    'Rubring',
    'Enzenkirchen',
    'Lochen',
    'Aschach an der Donau',
    'Klagenfurt',
    'Woelfnitz',
    'Obsteig',
    'Kirchbichl',
    'Innsbruck',
    'Schoenberg im Stubaital',
    'Fulpmes',
    'Freistadt',
    'Walding',
    'Ried im Innkreis',
    'Luftenberg an der Donau',
    'Feldkirch',
    'Bregenz',
    'Hochst',
    'Lustenau',
    'Wallsee',
    'Pochlarn',
    'Wieselburg',
    'Horn',
    'Leoben',
    'Weisskirchen in Steiermark',
    'Obdach',
    'Muerzhofen',
    'Gross',
    'Dietersdorf am Gnasbach',
    'Stein an der Enns',
    'Fohnsdorf',
    'Murfeld',
    'Stainz bei Straden',
    'Gratkorn',
    'Villach',
    'Heimschuh',
    'Wolfsberg',
    'Althofen',
    'Buchkirchen',
    'Neumarkt an der Ybbs',
    'Gunskirchen',
    'Neumarkt im Muhlkreis',
    'Zipf',
    'Eferding',
    'Wernstein am Inn',
    'Gurten',
    'Esternberg',
    'Niederneukirchen',
    'Neuhofen',
    'Attnang-Puchheim',
    'Schardenberg',
    'Kirchschlag bei Linz',
    'Steyr',
    'Aistersheim',
    'Pettenbach',
    'Siezenheim',
    'Neukirchen am Walde',
    'Hochleithen',
    'Ringelsdorf',
    'Baernbach',
    'Koflach',
    'Krems',
    'Amstetten',
    'Weitersfeld',
    'Amaliendorf',
    'Emmersdorf an der Donau',
    'Grafenworth',
    'Kienberg',
    'Neudorf bei Staatz',
    'Waldenstein',
    'Poelten',
    'Kalsdorf bei Graz',
    'Wagna',
    'Muerzzuschlag',
    'Lanzenkirchen',
    'Bad Fischau',
    'Kilb',
    'Wiesmath',
    'Eisenstadt',
    'Kufstein',
    'Hatting',
    'Oberperfuss',
    'Telfs',
    'Bad Goisern',
    'Strobl',
    'Hallein',
    'Hard',
    'Dornbirn',
    'Rankweil',
    'Lauterach',
    'Klosterle',
    'Zirl',
    'Admont',
    'Hartberg',
    'Prabach',
    'Trieben',
    'Grossweikersdorf',
    'Laa an der Thaya',
    'Gaflenz',
    'Dietach',
    'Altenberg bei Linz',
    'Engerwitzdorf',
    'Ulrichsberg',
    'Ried in der Riedmark',
    'Feldkirchen an der Donau',
    'Mehrnbach',
    'Scharnstein',
    'Oehling',
    'Oberrohrbach',
    'Sommerein',
    'Horsching',
    'Werfenweng',
    'Seefeld in Tirol',
    'Bad Gleichenberg',
    'Lech',
    'Serfaus',
    'Muerzsteg',
    'Sieghartskirchen',
    'Harbach',
    'Kaindorf',
    'Gleisdorf',
    'Purkersdorf',
    'Markt Allhau',
    'Deutschfeistritz',
    'Sebersdorf',
    'Straden',
    'Lassnitzhohe',
    'Unterwaltersdorf',
    'Jois',
    'Feldkirchen in Karnten',
    'Feistritz',
    'Stein im Jauntal',
    'Strasswalchen',
    'Anif',
    'Tiefgraben',
    'Perchtoldsdorf',
    'Schwarzenau',
    'Himmelreich',
    'Gaaden',
    'Horbranz',
    'Hopfgarten im Brixental',
    'Tirol',
    'Ernst',
    'Grimmenstein',
    'Bergheim',
    'Ansfelden',
    'Herzogenburg',
    'Schwechat',
    'Hollabrunn',
    'Unterlamm',
    'Gnas',
    'Weibern',
    'Hohnhart',
    'Aurolzmunster',
    'Puch bei Hallein',
    'Fugen',
    'Brunn am Gebirge',
    'Rannersdorf',
    'Ainet',
    'Traun',
    'Hof am Leithaberge',
    'Weikersdorf am Steinfelde',
    'Zillingtal',
    'Zillingdorf',
    'Hall',
    'Absam',
    'Hall in Tirol',
    'Tribuswinkel',
    'Gablitz',
    'Breitenfurt bei Wien',
    'Reith im Alpbachtal',
    'Worgl',
    'Ellmau',
    'Thaur',
    'Kirchberg in Tirol',
    'Rum',
    'Kramsach',
    'Mayrhofen',
    'Stattegg',
    'Bad Voslau',
    'Altach',
    'Gotzis',
    'Altenhof am Hausruck',
    'Gallspach',
    'Bad Schallerbach',
    'Grieskirchen',
    'Schwanenstadt',
    'Ohlsdorf',
    'Pichl bei Wels',
    'Baden bei Wien',
    'Vocklabruck',
    'Leonding',
    'Ampfelwang',
    'Scharding',
    'Perg',
    'Unterjoch',
    'Holzhausen',
    'Natternbach',
    'Bad Gastein',
    'Wals',
    'Saalbach',
    'Anthering',
    'Sankt Johann im Pongau',
    'Bad Hofgastein',
    'Hinterglemm',
    'Obertauern',
    'Zell am See',
    'Stubach',
    'Lavant',
    'Tristach',
    'Sillian',
    'Waidring',
    'Lermoos',
    'Vent',
    'Oberau',
    'Matrei in Osttirol',
    'Rinn',
    'Berwang',
    'Pettneu',
    'OEtztal-Bahnhof',
    'Bruggen',
    'Pertisau',
    'Kuehtai',
    'Finkenberg',
    'Ischgl',
    'Ried im Zillertal',
    'Zell am Ziller',
    'Nauders',
    'Alpbach',
    'Walchsee',
    'Ehrwald',
    'Gschnitz',
    'Riezlern',
    'Wald am Arlberg',
    'Schoppernau',
    'Galtur',
    'Gargellen',
    'Bludenz',
    'Bartholomaberg',
    'Krumpendorf',
    'Mittelberg',
    'Neustift',
    'Bizau',
    'Im Brand',
    'Schrems',
    'Bad Pirawarth',
    'Eggenburg',
    'Sankt Polten',
    'Baumgarten im Burgenland',
    'Wundschuh',
    'Glan',
    'Poertschach am Woerthersee',
    'Oberaichwald',
    'Doebriach',
    'Maria Saal',
    'Millstatt',
    'Kleinkirchheim',
    'Bodensdorf',
    'Velden am Woerthersee',
    'Sirnitz',
    'Grafenstein',
    'Sankt Ulrich bei Steyr',
    'Sipbachzell',
    'Ennsdorf',
    'Pucking',
    'Neusiedl bei Guessing',
    'Raab',
    'Reichendorf',
    'Miesenbach',
    'Ebbs',
    'Wangle',
    'Hofen',
    'Poysdorf',
    'Untersiebenbrunn',
    'Sitzenberg',
    'Obersiebenbrunn',
    'Purgstall',
    'Wurmbrand',
    'Asperhofen',
    'Axams',
    'Steinach am Brenner',
    'Nussdorf',
    'Gallneukirchen',
    'Klein-Harras',
    'Kirchberg am Wagram',
    'Schwadorf',
    'Oberkirchbach',
    'Bisamberg',
    'Wiener Neudorf',
    'Oberneukirchen',
    'Kremsmunster',
    'Seewalchen',
    'Oberkappel',
    'Andorf',
    'Schiefling am See',
    'Voelkermarkt',
    'Landskron',
    'Purgg',
    'Leutschach',
    'Ebenthal',
    'Arriach',
    'Gosdorf',
    'Hohenems',
    'Mader',
    'Schruns',
    'Rohrau',
    'Kierling',
    'Oed',
    'Oggau',
    'Hainburg an der Donau',
    'Reisenberg',
    'Steinhaus am Semmering',
    'Steinabruckl',
    'Donnerskirchen',
    'Bruck an der Leitha',
    'Wulkaprodersdorf',
    'Gols',
    'Aichbach',
    'Lindenberg',
    'Vocklamarkt',
    'Lichtenberg',
    'Micheldorf in Oberoesterreich',
    'Grein',
    'Mairhof',
    'Gross-Enzersdorf',
    'Hinterbruehl',
    'Tattendorf',
    'Traboch',
    'Spielberg bei Knittelfeld',
    'Tauplitz',
    'Ramsau im Zillertal',
    'Grosspesendorf',
    'Passail',
    'Hohenburg',
    'Knittelfeld',
    'Bruck an der Mur',
    'Enns',
    'Ungenach',
    'Asten',
    'Oftering',
    'Laakirchen',
    'Weissenbach am Lech',
    'Pfunds',
    'Graen',
    'Mathon',
    'Haiming',
    'Panzendorf',
    'Scheffau am Wilden Kaiser',
    'Schoenau',
    'Saint Anton',
    'Hochgurgl',
    'Wenns',
    'Kitzbühel',
    'Hoehe',
    'Maurach',
    'Solden',
    'Leutasch',
    'Vorderlanersbach',
    'Lienz',
    'Niederachen',
    'Hinterthiersee',
    'Achenkirch',
    'Kossen',
    'Bichlbach',
    'Uderns',
    'Sierndorf',
    'Altlengbach',
    'Langenlois',
    'Schongrabern',
    'Litschau',
    'Guntramsdorf',
    'Afritz',
    'Friesach',
    'Faak am See',
    'Latschach',
    'Aifersdorf',
    'Bleiberg ob Villach',
    'Sankt Martin am Techelsberg',
    'Ossiach',
    'Winkl',
    'Seeboden',
    'Annenheim',
    'Ostermiething',
    'Rauris',
    'Bramberg am Wildkogel',
    'Filzmoos',
    'Pichl',
    'Heissingfelding',
    'Plankenau',
    'Karteis',
    'Grossarl',
    'Reitdorf',
    'Grodig',
    'Kremsdorf',
    'Moosburg',
    'Strau',
    'Mutters',
    'Maildorf',
    'Arnoldstein',
    'Viktring',
    'Petersdorf',
    'Markt Neuhodis',
    'Liezen',
    'Mank',
    'Sankt Georgen am Ybbsfelde',
    'Oberndorf an der Melk',
    'Boheimkirchen',
    'Pyhra',
    'Randegg',
    'Karlstetten',
    'Lichtenau',
    'Erlauf',
    'Maiersdorf',
    'Ebergassing',
    'Grafenbach',
    'Neudorfl',
    'Neckenmarkt',
    'Sollenau',
    'Langenzersdorf',
    'Katzelsdorf',
    'Krottendorf bei Ligist',
    'Sankt Lorenzen im Muerztal',
    'Unterpurkla',
    'Falkenburg',
    'Geras',
    'Spitz',
    'Heidenreichstein',
    'Rotenbach',
    'Petronell-Carnuntum',
    'Kirchschlag',
    'Grieselstein',
    'Gattendorf',
    'Kottingbrunn',
    'Asparn',
    'Sankt Wolfgang',
    'Untergrub',
    'Sankt Wolfgang',
    'Schwertberg',
    'Fuerstenfeld',
    'Wettmannstatten',
    'Tschagguns',
    'Warth',
    'Sankt Gallenkirch',
    'Kreuz',
    'Lochau',
    'Gaschurn',
    'Rottenmann',
    'Guglwald',
    'Haag am Hausruck',
    'Burgkirchen',
    'Werndorf',
    'Grossraming',
    'Thalheim bei Wels',
    'Asberg',
    'Pasching',
    'Krenglbach',
    'Haid',
    'Ottensheim',
    'Mitterdorf im Muerztal',
    'Loipersbach',
    'Unterweitersdorf',
    'Pottenbrunn',
    'Hohenberg',
    'Gumpoldskirchen',
    'Wollersdorf',
    'Markt Piesting',
    'Pfaffstatten',
    'Laxenburg',
    'Himberg',
    'Neudorf bei Landsee',
    'Leobendorf',
    'Turnitz',
    'Pressbaum',
    'Biedermannsdorf',
    'Wolfsgraben',
    'Rekawinkel',
    'Tullnerbach-Lawies',
    'Gramatneusiedl',
    'Maria Lanzendorf',
    'Zwolfaxing',
    'Hennersdorf',
    'Trumau',
    'Hofstetten',
    'Unterradlberg',
    'Muckendorf an der Donau',
    'Ganserndorf',
    'Hainfeld',
    'Trautmannsdorf an der Leitha',
    'Bad Radkersburg',
    'Waltersdorf an der March',
    'Lutzmannsburg',
    'Gotzendorf an der Leitha',
    'Westendorf',
    'Oberwart',
    'Weiden am See',
    'Unterpullendorf',
    'Am See',
    'Winden am See',
    'Purbach am Neusiedlersee',
    'Oslip',
    'Mattersburg',
    'Kundl',
    'Lenzing',
    'Stadl-Paura',
    'Abtenau',
    'Radstadt',
    'Eben im Pongau',
    'Leogang',
    'Gallbrunn',
    'Lilienfeld',
    'Maria Taferl',
    'Ybbs an der Donau',
    'Gosing',
    'Bernstein',
    'Rappoltenkirchen',
    'Zuers',
    'Dechantskirchen',
    'Gnadenwald',
    'Mieming',
    'Getzersdorf',
    'Marchtrenk',
    'Fiss',
    'Feuchten',
    'Oberweis',
    'Mauthausen',
    'Reichenau im Muhlkreis',
    'Hagenberg im Muhlkreis',
    'Leibnitz, Styria',
    'Bruck an der Grossglocknerstrasse',
    'Uttendorf',
    'Fusch an der Glocknerstrasse',
    'Kaprun',
    'Altenberg',
    'Piesendorf',
    'Maria Alm am Steinernen Meer',
    'Werfen',
    'Sulzau',
    'Gaisberg',
    'Goldegg',
    'Lend',
    'Schwarzach im Pongau',
    'Tamsweg',
    'Margarethen am Moos',
    'Burmoos',
    'Seeham',
    'Ebensee',
    'Mattsee',
    'Seekirchen am Wallersee',
    'Thalgau',
    'Hof bei Salzburg',
    'Koppl',
    'Eugendorf',
    'Ebenau',
    'Tiefenbach',
    'Elixhausen',
    'Oberndorf bei Salzburg',
    'Kuchl',
    'Golling an der Salzach',
    'Lungotz',
    'Altenmarkt im Pongau',
    'Flachau',
    'Wagrain',
    'Sankt Veit im Pongau',
    'Oberalm',
    'Henndorf am Wallersee',
    'Fuschl am See',
    'Lamprechtshausen',
    'Sausenstein',
    'Deutsch-Wagram',
    'Obergurgl',
    'Zams',
    'Landeck',
    'Neusiedl am See',
    'Rohrbach am Steinfelde',
    'Laab im Walde',
    'Statzendorf',
    'Gerasdorf am Steinfelde',
    'Walbersdorf',
    'Reichenau an der Rax',
    'Felixdorf',
    'Mollersdorf',
    'Hirtenberg',
    'Kennelbach',
    'Parschlug',
    'Hausmannstatten',
    'Wolfurt',
    'Koblach',
    'Klaus',
    'Fussach',
    'Muntlix',
    'Elsbethen',
    'Mariazell',
    'Leobersdorf',
    'Hofstatten an der Raab',
    'Grobming',
    'Innermanzing',
    'Enzesfeld',
    'Hohentauern',
    'Maria Worth',
    'Rosenbach',
    'Freienstein',
    'Wattens',
    'Leitring',
    'Ligist',
    'Stiegl',
    'Kaltenbrunn',
    'Gratwein',
    'Eggersdorf bei Graz',
    'Strassengel',
    'Ferndorf',
    'Wildon',
    'Spittal an der Drau',
    'Sankt Veit an der Glan',
    'Weiler',
    'Raaba',
    'Voitsberg',
    'Lofer',
    'Haidershofen',
    'Unterrohr',
    'Neukirchen an der Vockla',
    'Frankenburg',
    'Oberaich',
    'Sankt Michael in Obersteiermark',
    'Petzenkirchen',
    'Edelschrott',
    'Mannsworth',
    'Garsten',
    'Kirchdorf an der Krems',
    'Sulz',
    'Hadersfeld',
    'Wolkersdorf im Weinviertel',
    'Berndorf',
    'Leisach',
    'Reith bei Seefeld',
    'Bad Hall',
    'Wiesing',
    'Kritzendorf',
    'Riedenthal',
    'Hornstein',
    'Biberbach',
    'Langenstein',
    'Vorchdorf',
    'Puchenau',
    'Bad Kreuzen',
    'Traunkirchen',
    'Neukirchen',
    'Judenburg',
    'Scheifling',
    'Murau',
    'Teufenbach',
    'Apfelberg',
    'Kapfing',
    'Barwies',
    'Tumeltsham',
    'Hinterwaldberg',
    'Passthurn',
    'Sankt Lorenzen',
    'Aigen',
    'Hipping',
    'Eberndorf',
    'Turrach',
    'Sankt Andrae',
    'Augsdorf',
    'Hirschegg',
    'Bezau',
    'Miesenbach',
    'Schwarzau am Steinfelde',
    'Semmering',
    'Pirka',
    'Bad Gams',
    'Steinerkirchen an der Traun',
    'Leonfelden',
    'Soll',
    'Igls',
    'Haimingerberg',
    'Gerlos',
    'Patsch',
    'Kappl',
    'Kematen in Tirol',
    'Oetz',
    'Jungholz',
    'Stein an der Donau',
    'Glinzendorf',
    'Liebenfels',
    'Wieting',
    'Vordernberg',
    'Kobenz',
    'Lieboch',
    'Krieglach',
    'Ludesch',
    'Nuziders',
    'Au',
    'Zistersdorf',
    'Guntersdorf',
    'Ort im Innkreis',
    'Weilbach',
    'Hellmonsodt',
    'Eberschwang',
    'Altheim',
    'Labach',
    'Lembach im Muhlkreis',
    'Sulz im Wienerwald',
    'Zwentendorf',
    'Schleinbach',
    'Gerasdorf bei Wien',
    'Hintersdorf',
    'Mettmach',
    'Ach',
    'Alberndorf in der Riedmark',
    'Dellach',
    'Wilhering',
    'Gaspoltshofen',
    'Scharten',
    'Waxenberg',
    'Alkoven',
    'Meggenhofen',
    'Sankt Pankraz',
    'Freilassing',
    'Desselbrunn',
    'Plescherken',
    'Flattach',
    'Gailitz',
    'Kleinbergl',
    'Gruner Baum',
    'Steyregg',
    'Isper',
    'Huetten',
    'Sernau',
    'Niederwolz',
    'Schwanberg',
    'Haller',
    'Finsing',
    'Sankt Oswald',
    'Kleinstubing',
    'Leiten',
    'Obertrum am See',
    'Stans',
    'Strassen',
    'Moos',
    'Sankt Valentin',
    'Spratzern',
    'Keutschach am See',
    'Rohrmoos',
    'Schladming',
    'Stuhlfelden',
    'Mittersill',
    'Hollersbach im Pinzgau',
    'Vigaun',
    'Dorf',
    'Krispl',
    'Tadten',
    'Drassmarkt',
    'Riedlingsdorf',
    'Wallern im Burgenland',
    'Siegendorf im Burgenland',
    'Pottsching',
    'Sankt Andra am Zicksee',
    'Mannersdorf an der Rabnitz',
    'Reutte',
    'Kappern',
    'Weisskirchen an der Traun',
    'Regau',
    'Sankt Georgen im Attergau',
    'Kefermarkt',
    'Neuhofen an der Krems',
    'Ebreichsdorf',
    'Peuerbach',
    'Bruendl',
    'Ternberg',
    'Innerkrems',
    'Sankt Kanzian',
    'Stadelbach',
    'Kuehnsdorf',
    'Sachendorf',
    'Eisenberg',
    'Sauerbrunn',
    'Payerbach',
    'Hohenwarth',
    'Bad Tatzmannsdorf',
    'Stegersbach',
    'Waidmannsfeld',
    'Roppen',
    'Mosern',
    'Meisten',
    'Schlins',
    'Fischbach',
    'Weissenkirchen in der Wachau',
    'Altwaidhofen',
    'Kaumberg',
    'Hausbrunn',
    'Zwettl Stadt',
    'Laaben',
    'Liesfeld',
    'Sankt Ruprecht an der Raab',
    'Sierning',
    'Neukirchen an der Enknach',
    'Gramastetten',
    'Edlitz',
    'Kittsee',
    'Stoob',
    'Sankt Martin am Ybbsfelde',
    'Michelbach Markt',
    'Watzelsdorf',
    'Dross',
    'Gfohl',
    'Judenau',
    'Konigstetten',
    'Lobenstein',
    'Opponitz',
    'Freinberg',
    'Waizenkirchen',
    'Thalling',
    'Rothis',
    'Schwarzach',
    'Wordern',
    'Frauenkirchen',
    'Purbach',
    'Grafenschlag',
    'Gostling an der Ybbs',
    'Steinakirchen am Forst',
    'Ulmerfeld',
    'Gmund',
    'Redlham',
    'Sankt Agatha',
    'Ernstbrunn',
    'Puchberg am Schneeberg',
    'Arbesthal',
    'Fuernitz',
    'Steindorf am Ossiacher See',
    'Traunstein',
    'Gerersdorf',
    'Niederland',
    'Tweng',
    'Dorfgastein',
    'Hinterburg',
    'Madseit',
    'Motz',
    'Emberg',
    'Mellach',
    'Spital am Pyhrn',
    'Eggendorf',
    'Waltersdorf in Oststeiermark',
    'Schonbuhel an der Donau',
    'Wienerherberg',
    'Tannheim',
    'Grafenweg',
    'Schwaigs',
    'Haldensee',
    'Hintertux',
    'Oepping',
    'Weng im Innkreis',
    'Gurtis',
    'Heiterwang',
    'Siebenhirten',
    'Maria-Anzbach',
    'Knappenberg',
    'Launsdorf',
    'Lavamund',
    'Vols',
    'Brandberg',
    'Braunau am Inn',
    'Hofkirchen im Traunkreis',
    'Feldkirchen bei Mattighofen',
    'Feldkirchen bei Graz',
    'Hollenthon',
    'Pottelsdorf',
    'Nenzing',
    'Egg',
    'Lichtenworth',
    'Theresienfeld',
    'Gunselsdorf',
    'Vorau',
    'Unterpremstatten',
    'Lebring',
    'Weissbriach',
    'Eisbach',
    'Peggau',
    'Zeiselmauer',
    'Kapelln',
    'Frattingsdorf',
    'Neumarkt in Steiermark',
    'Kroatisch Minihof',
    'Enzenreith',
    'Gloggnitz',
    'Furthof',
    'Dalaas',
    'Blindenmarkt',
    'Zellerndorf',
    'Grossebersdorf',
    'Grossrussbach',
    'Orth an der Donau',
    'Ulrichskirchen',
    'Engersdorf',
    'Schoerfling',
    'Neuzeug',
    'Perwang am Grabensee',
    'Attersee',
    'Achensee',
    'Gotzens',
    'Inzing',
    'Ernsthofen',
    'Ebenfurth',
    'Klingfurth',
    'Potzneusiedl',
    'Weppersdorf',
    'Tobaj',
    'Aspang Markt',
    'Wurflach',
    'Hausleiten',
    'Herrnbaumgarten',
    'Fliess',
    'Sistrans',
    'Nassereith',
    'Telfes im Stubai',
    'Pfons',
    'Egg am Faaker See',
    'Eberstein',
    'Rangersdorf',
    'Moellbruecke',
    'Eisentratten',
    'Koestenberg',
    'Gaal',
    'Ardning',
    'Pinkafeld',
    'Anger',
    'Schwarzenberg am Boehmerwald',
    'Buch bei Jenbach',
    'Flinsdorf',
    'Konigswiesen',
    'Schalchen',
    'Mauerkirchen',
    'Neumarkt am Wallersee',
    'Frankenmarkt',
    'Mallnitz',
    'Schutzen am Gebirge',
    'Trausdorf an der Wulka',
    'Deutschkreutz',
    'Breitenbrunn',
    'Edelstal',
    'Mistelbach',
    'Bad Blumau',
    'Pregarten',
    'Rosental',
    'Dobersberg',
    'Neulengbach',
    'Poggstall',
    'Weiten',
    'Pinggau',
    'Purgstall',
    'Rudersdorf',
    'Stuhlsdorf',
    'Grossklein',
    'Diepoltsham',
    'Pinsdorf',
    'Schlierbach',
    'Eggendorf im Thale',
    'Trofaiach',
    'Heissberg',
    'Jennersdorf',
    'Berg',
    'Satteins',
    'Ehrenhausen',
    'Thal',
    'Riegersburg',
    'Kollmitzberg',
    'Gresten',
    'Pernegg an der Mur',
    'Aigen im Muehlkreis',
    'Nesselwangle',
    'Rotholz',
    'Pillberg',
    'Wimpassing im Schwarzatale',
    'Niederalm',
    'Leopoldsdorf',
    'Bach',
    'Erpfendorf',
    'Gries',
    'Lanersbach',
    'Blumau',
    'Kumberg',
    'Neudau',
    'Edt bei Lambach',
    'Gugging',
    'Mannersdorf am Leithagebirge',
    'Wampersdorf',
    'Obervellach',
    'Zemendorf',
    'Guessing',
    'Bruckneudorf',
    'Oberpullendorf',
    'Zurndorf',
    'Going',
    'Mitterndorf an der Fischa',
    'Thoerl',
    'Breitenwang',
    'Lechaschau',
    'Bauer',
    'Prinzersdorf',
    'Nickelsdorf',
    'Neufeld an der Leitha',
    'Enzersdorf an der Fischa',
    'Tschoeran',
    'Toeschling',
    'Durnstein',
    'Eibiswald',
    'Krumbach',
    'Hernstein',
    'Kanzelhoehe',
    'Maglern',
    'Troepolach',
    'Hallstatt',
    'Karl',
    'Maria Buch',
    'Paldau',
    'Birgitz',
    'Hoflein',
    'Gaweinstal',
    'Loimersdorf',
    'Weikendorf',
    'Bosendurnbach',
    'Pischeldorf',
    'Loosdorf',
    'Marbach',
    'Allhartsberg',
    'Waidhofen an der Thaya',
    'Traisen',
    'Ruden',
    'Feistritz an der Drau',
    'Rosegg',
    'Bruckl',
    'Fernitz',
    'Fladnitz im Raabtal',
    'Wies',
    'Polfing',
    'Altendorf',
    'Offenhausen',
    'Herzogsdorf',
    'Taufkirchen an der Pram',
    'Niederdorf',
    'Feld am See',
    'Pattergassen',
    'Taxenbach',
    'Nussdorf am Haunsberg',
    'Zell am Moos',
    'Huttschlag',
    'Vitis',
    'Jagenbach',
    'Altendorf',
    'Hoheneich',
    'Eisgarn',
    'Steinhaus',
    'Ried im Traunkreis',
    'Obernberg',
    'Munzbach',
    'Waldzell',
    'Munzkirchen',
    'Gampern',
    'Haibach ob der Donau',
    'Dorfla',
    'Heiligenkreuz am Waasen',
    'Krumegg',
    'Stubenberg',
    'Marchegg',
    'Aichdorf',
    'Langenrohr',
    'Steinerberg',
    'Jeging',
    'Alland',
    'Sinabelkirchen',
    'Rohrbach am Kulm',
    'Mooskirchen',
    'Unzmarkt',
    'Bernhardsthal',
    'Eckartsau',
    'Bockfliess',
    'Biedermann',
    'Altmunster',
    'Tieschen',
    'Rieding',
    'Grabelsdorf',
    'Mandling',
    'Ottenheim',
    'Strass',
    'Aggsbach',
    'Nestelbach bei Graz',
    'Wimpassing an der Leitha',
    'Pottenstein',
    'Ampass',
    'Biberwier',
    'Gosau',
    'Schneeberg',
    'Sankt Peter',
    'Absdorf',
    'Abtsdorf',
    'Kirchdorf in Tirol',
    'Schwendau',
    'Stadtschlaining',
    'Lichtenegg',
    'Wolfau',
    'Fischerndorf',
    'Unterberg',
    'Aufhausen',
    'Eichgraben',
    'Mollram',
    'Schwarzau im Gebirge',
    'Sankt Margarethen im Burgenland',
    'Riefensberg',
    'Windischgarsten',
    'Kraig',
    'Maria Elend',
    'Haag',
    'Naarn im Machlande',
    'Maria Ellend',
    'Wilfleinsdorf',
    'Bad Deutsch-Altenburg',
    'Raabs an der Thaya',
    'Neidling',
    'Gutau',
    'Pram',
    'Enzersfeld',
    'Altenmarkt an der Triesting',
    'Rauchenwarth',
    'Steinfeld',
    'Sachsenburg',
    'Lendorf',
    'Niederndorf',
    'Gries am Brenner',
    'Schmirn',
    'Matrei am Brenner',
    'Hadres',
    'Maissau',
    'Hochburg',
    'Oberwolbling',
    'Hadersdorf am Kamp',
    'Atzbach',
    'Maria Schmolln',
    'Obergruenburg',
    'Piberbach',
    'Engelhartszell',
    'Rohrbach in Oberosterreich',
    'Ottendorf bei Gleisdorf',
    'Doren',
    'Sibratsgfall',
    'Bad Erlach',
    'Oberwaltersdorf',
    'Staatz',
    'Altenburg',
    'Neutal',
    'Monchhof',
    'Katsdorf',
    'Utzenaich',
    'Poetting',
    'Niederleis',
    'Unterparschenbrunn',
    'Auersthal',
    'Baumkirchen',
    'Thaya',
    'Eggendorf',
    'Ebendorf',
    'Winzendorf',
    'Piringsdorf',
    'Kobersdorf',
    'Klostermarienberg',
    'Poysbrunn',
    'Erpersdorf',
    'Bleiburg',
    'Ybbsitz',
    'Kaltenleutgeben',
    'Reifnitz',
    'Burgschleinitz',
    'Traismauer',
    'Stams',
    'Radfeld',
    'Maria Lankowitz',
    'Wartberg',
    'Buch',
    'Puch',
    'Sankt Stefan im Rosental',
    'Dobersdorf',
    'Landegg',
    'Stumm',
    'Viktorsberg',
    'Gortipohl',
    'Krumbach Markt',
    'Hochneukirchen',
    'Marz',
    'Seckau',
    'Altlichtenwarth',
    'Bergau',
    'Gotzendorf',
    'Pischelsdorf am Engelbach',
    'Pabneukirchen',
    'Rosental',
    'Doblbach',
    'Obritzberg',
    'Gansbach',
    'Schattendorf',
    'Halbturn',
    'Rosenau am Hengstpass',
    'Grambach',
    'Langeck im Burgenland',
    'Waldkirchen am Wesen',
    'Zeltweg',
    'Ober-Grafendorf',
    'Steg',
    'Forst',
    'Studenzen',
    'Kottmannsdorf',
    'Preding',
    'Neuhofen an der Ybbs',
    'Schachendorf',
    'Pernitz',
    'Goedersdorf',
    'Geinberg',
    'Reichraming',
    'Rottenbach',
    'Rutzenmoos',
    'Mitterkirchen im Machland',
    'Lassee',
    'Nochling',
    'Stettenhof',
    'Warth',
    'Hirm',
    'Sankt Kathrein am Hauenstein',
    'Wang',
    'Zeillern',
    'Gutenstein',
    'Adnet',
    'Muhlbach am Manhartsberg',
    'Spillern',
    'Watzendorf',
    'Oberort',
    'Grafendorf bei Stainz',
    'Grafendorf',
    'Zobing',
    'Moosdorf',
    'Bruck am Ziller',
    'Regelsbrunn',
    'Sooss',
    'Wolfpassing',
    'Hautzendorf',
    'Hart',
    'Lambrechten',
    'Volders',
    'Guttaring',
    'Griffen',
    'Foederlach',
    'Koetschach',
    'Haag',
    'Japons',
    'Gofis',
    'Tal',
    'Gollersdorf',
    'Niederkreuzstetten',
    'Toesenssteinach',
    'Schlitters',
    'Meiningen',
    'Bludesch',
    'Thuringen',
    'Sulzberg',
    'Inzersdorf im Kremstal',
    'Semriach',
    'Gaming',
    'Pachfurth',
    'Drassburg',
    'Fischamend Dorf',
    'Kremsbruecke',
    'Niederwaldkirchen',
    'Loretto',
    'Trasdorf',
    'Horersdorf',
    'Friedburg',
    'Grafendorf bei Hartberg',
    'Steinberg bei Ligist',
    'Deutsch Goritz',
    'Kirchbach in Steiermark',
    'Kainach bei Voitsberg',
    'Jenbach',
    'Virgen',
    'Stummerberg',
    'Gross-Siegharts',
    'Ardagger Markt',
    'Gars am Kamp',
    'Haslau an der Donau',
    'Siebenhirten',
    'Gobelsburg',
    'Oberhofen im Inntal',
    'Ziersdorf',
    'Glaubendorf',
    'Baldramsdorf',
    'Reith',
    'Feuersbrunn',
    'Oberschutzen',
    'Goesselsdorf',
    'Jagerberg',
    'Sinnersdorf',
    'Mauterndorf',
    'Eisenerz',
    'Weng bei Admont',
    'Haus',
    'Veitsch',
    'Sankt Marienkirchen am Hausruck',
    'Lohnsburg',
    'Gasthof',
    'Heiligenblut am Großglockner',
    'Hochsoelden',
    'Hatzendorf',
    'Silz',
    'Gallzein',
    'Gross-Schweinbarth',
    'Klam',
    'Baumgartenberg',
    'Dobldorf',
    'Oberhausen',
    'Obersdorf',
    'Alberschwende'
  ],
  'United Kingdom': [
    'Pershore',
    'Rowley Regis',
    'Stroud',
    'Birmingham',
    'Worcester',
    'Smethwick',
    'Alcester',
    'Dudley',
    'Gloucester',
    'Solihull',
    'Tamworth',
    'Bidford-on-Avon',
    'Tewkesbury',
    'Oldbury',
    'Coleford',
    'Stourbridge',
    'Cheltenham',
    'Redditch',
    'Malvern',
    'Stonehouse',
    'Bromsgrove',
    'Mitcheldean',
    'Erdington',
    'Halesowen',
    'Rugby',
    'Walsall',
    'Llandrindod Wells',
    'Sutton Coldfield',
    'Evesham',
    'Stratford-upon-Avon',
    'Bristol',
    'Plymouth',
    'Fareham',
    'Newquay',
    'Wells',
    'Swanage',
    'Bournemouth',
    'Frome',
    'Burnham-on-Sea',
    'Liskeard',
    'Verwood',
    'Redruth',
    'Weymouth',
    'Devizes',
    'Poole',
    'St Austell',
    'Trowbridge',
    'Christchurch',
    'Bath',
    'Torquay',
    'New Milton',
    'Warminster',
    'Southampton',
    'Bradford-on-Avon',
    'Bideford',
    'Pontypridd',
    'Swansea',
    'Cardiff',
    'Abertillery',
    'Caerphilly',
    'Blackwood',
    'Aberdare',
    'Hengoed',
    'Maesteg',
    'Mountain Ash',
    'Usk',
    'Carmarthen',
    'Bargoed',
    'Newport',
    'Penarth',
    'Neath',
    'Rhondda',
    'Llanelli',
    'Tonypandy',
    'Bridgend',
    'Salisbury',
    'Porth',
    'Porthcawl',
    'Ammanford',
    'Ebbw Vale',
    'Caldicot',
    'Leicester',
    'Nottingham',
    'Coalville',
    'Grantham',
    'Coventry',
    'Burton-on-Trent',
    'Heanor',
    'Northampton',
    'Lutterworth',
    'Bourne',
    'Buxton',
    'Ilkeston',
    'Atherstone',
    'Derby',
    'Barrow upon Soar',
    'Loughborough',
    'Gainsborough',
    'Prestwick',
    'Edinburgh',
    'Dalkeith',
    'Kilsyth',
    'Maybole',
    'Dunbar',
    'Falkirk',
    'Livingston',
    'Bonnybridge',
    'Largs',
    'St Andrews',
    'Kilmarnock',
    'Ayr',
    'Penicuik',
    'Kilwinning',
    'Kennoway',
    'Leven',
    'Darvel',
    'Larbert',
    'Clydebank',
    'Irvine',
    'Kilbirnie',
    'Troon',
    'Fochabers',
    'Stirling',
    'Crieff',
    'Aviemore',
    'Carluke',
    'Kinross',
    'Carlisle',
    'South Shields',
    'Newcastle upon Tyne',
    'York',
    'Middlesbrough',
    'Farnham',
    'Crawley',
    'Sandhurst',
    'Southsea',
    'Westerham',
    'Uxbridge',
    'Woking',
    'Bexhill',
    'Uxbridge',
    'Aldershot',
    'Reading',
    'Chichester',
    'Byfleet',
    'Portsmouth',
    'Petersfield',
    'Horsham',
    'South Hayling',
    'Faversham',
    'Guildford',
    'Leatherhead',
    'Kent',
    'Basingstoke',
    'Gillingham',
    'Ryde',
    'Whitstable',
    'Billingshurst',
    'Camberley',
    'Fleet',
    'Newport',
    'Dover',
    'Aberdeen',
    'Glasgow',
    'Musselburgh',
    'Alloa',
    'Dunfermline',
    'East Kilbride',
    'West Linton',
    'Ballynahinch',
    'Strathaven',
    'Broxburn',
    'Paisley',
    'Lanark',
    'Belfast',
    'London',
    'Chelmsford',
    'Hove',
    'Eastbourne',
    'Hastings',
    'New Cross',
    'Shoreham-by-Sea',
    'Uckfield',
    'Royal Tunbridge Wells',
    'Crowborough',
    'Rochester',
    'Henfield',
    'Littlehampton',
    'Polegate',
    'Emsworth',
    'Newhaven',
    'Hailsham',
    'Brighton',
    'Hassocks',
    'Sevenoaks',
    'Waterlooville',
    'Belvedere',
    'Barnet',
    'Enfield',
    'Greenford',
    'Rickmansworth',
    'Stanmore',
    'Wickford',
    'Royston',
    'Abbots Langley',
    'Wembley',
    'Edgware',
    'Ruislip',
    'Hatfield',
    'Borehamwood',
    'Braintree',
    'Hertford',
    'Harrow',
    'Heckmondwike',
    'Bradford',
    'Sheffield',
    'Leeds',
    'Wetherby',
    'Keighley',
    'Barnsley',
    'Ripon',
    'Wakefield',
    'Halifax',
    'Bridlington',
    'Huddersfield',
    'Birstall',
    'Castleford',
    'Scarborough',
    'Knottingley',
    'Dewsbury',
    'Leigh-on-Sea',
    'Loughton',
    'Rayleigh',
    'Upminster',
    'Walton-on-the-Naze',
    'Ilford',
    'Gravesend',
    'Romford',
    'Ipswich',
    'South Ockendon',
    'Grays',
    'Maidstone',
    'Maldon',
    'Hornchurch',
    'Basildon',
    'Colchester',
    'Billericay',
    'Tonbridge',
    'Sheerness',
    'Tilbury',
    'Banstead',
    'Tadworth',
    'Dartford',
    'Beckenham',
    'Croydon',
    'Worcester Park',
    'Surbiton',
    'Acton',
    'Sutton',
    'Thornton Heath',
    'Orpington',
    'Malden',
    'Burgess Hill',
    'Bexleyheath',
    'Milton Keynes',
    'Dunstable',
    'Hitchin',
    'Wellingborough',
    'Wootton',
    'Kidlington',
    'Luton',
    'Chipping Norton',
    'Towcester',
    'Chacombe',
    'Abingdon',
    'Market Harborough',
    'Oxford',
    'Kings Langley',
    'Daventry',
    'Flitwick',
    'Wallingford',
    'Biggleswade',
    'Ware',
    'Corby',
    'Bedford',
    'Manchester',
    'Warrington',
    'Cheadle',
    'Upton',
    'Oldham',
    'Stockport',
    'Chester',
    'Liverpool',
    'Salford',
    'Colne',
    'Colwyn Bay',
    'Leyland',
    'Bacup',
    'Bolton',
    'Urmston',
    'Runcorn',
    'Bury',
    'Sale',
    'Northwich',
    'Wallasey',
    'Mochdre',
    'Flint',
    'Wigan',
    'Lymm',
    'Jarrow',
    'Darlington',
    'Durham',
    'Sunderland',
    'Hartlepool',
    'Ashington',
    'Gateshead',
    'North Shields',
    'Redcar',
    'Blaydon',
    'Seaham',
    'Newton Aycliffe',
    'Whitley Bay',
    'Brandon',
    'Prudhoe',
    'Rowlands Gill',
    'Spennymoor',
    'Crook',
    'Peterlee',
    'Bedlington',
    'Shildon',
    'Corbridge',
    'Houghton-le-Spring',
    'Chester-le-Street',
    'Bishop Auckland',
    'Billingham',
    'Consett',
    'Bognor Regis',
    'Huntingdon',
    'Kettering',
    'Wymondham',
    'Bungay',
    'Mildenhall',
    'Saint Neots',
    'Brigg',
    'Haverhill',
    'Sandy',
    'March',
    'Skegness',
    'Kings Lynn',
    'Long Melford',
    'Boston',
    'Waltham',
    'Oakham',
    'Peterborough',
    'Attleborough',
    'Grimsby',
    'Halstead',
    'Holt',
    'Sudbury',
    'Norwich',
    'Wattisfield',
    'Spalding',
    'Stowmarket',
    'Wrawby',
    'Lincoln',
    'Bury',
    'Clacton-on-Sea',
    'Cambridge',
    'Saxtead',
    'Graveley',
    'Southport',
    'Morecambe',
    'Blackpool',
    'Ellesmere Port',
    'St Helens',
    'Preston',
    'Darwen',
    'Poulton le Fylde',
    'Chorley',
    'Lytham',
    'Clitheroe',
    'Skelmersdale',
    'Rawtenstall',
    'Blackburn',
    'Hyde',
    'Birkenhead',
    'Accrington',
    'Dalton in Furness',
    'Ormskirk',
    'Killamarsh',
    'Worksop',
    'Rotherham',
    'Wrexham',
    'Doncaster',
    'Alfreton',
    'East Retford',
    'Mansfield',
    'Barrow in Furness',
    'Chesterfield',
    'Llanfair-Dyffryn-Clwyd',
    'Slough',
    'Southall',
    'Sunbury-on-Thames',
    'Redhill',
    'Kingsbridge',
    'London Borough of Hounslow',
    'Shepperton',
    'Hayes',
    'Feltham',
    'Brentford',
    'Chard',
    'Shaftesbury',
    'Honiton',
    'Martock',
    'Sidmouth',
    'Marlow',
    'Wokingham',
    'Exeter',
    'Ottery St Mary',
    'Exmouth',
    'Newbury',
    'Welshpool',
    'Telford',
    'Wolverhampton',
    'Stoke-on-Trent',
    'Tipton',
    'Nantwich',
    'Stafford',
    'Newent',
    'Willenhall',
    'Lichfield',
    'Broseley',
    'Cannock',
    'Winsford',
    'Crewe',
    'Kingswinford',
    'Hereford',
    'Macclesfield',
    'Oswestry',
    'Kidderminster',
    'Bilston',
    'Congleton',
    'Newport',
    'Uttoxeter',
    'Shrewsbury',
    'Cradley',
    'Wem',
    'Ossett',
    'Biggin Hill',
    'Boxley',
    'Wotton-under-Edge',
    'Burntwood',
    'Droitwich',
    'Stourport On Severn',
    'Ledbury',
    'Cirencester',
    'Dursley',
    'Brierley Hill',
    'Dukinfield',
    'Farnworth',
    'Rochdale',
    'Littleborough',
    'Heswall',
    'Rhyl',
    'Knutsford',
    'Hinckley',
    'Kenilworth',
    'Warwick',
    'Belper',
    'Twickenham',
    'Ilminster',
    'Richmond',
    'Barnstaple',
    'Colyton',
    'Taunton',
    'Dawlish',
    'Seaton',
    'Thatcham',
    'Totnes',
    'Tadley',
    'Blandford Forum',
    'Wishaw',
    'Alexandria',
    'Woodford Green',
    'Worthing',
    'Chatham',
    'Greenhithe',
    'Forfar',
    'Peterhead',
    'Glossop',
    'Southminster',
    'Harwich',
    'Brentwood',
    'Epping',
    'Aylesford',
    'Barking',
    'Cramlington',
    'Morpeth',
    'Penrith',
    'Ferryhill',
    'Blyth',
    'Galashiels',
    'Stanley',
    'Hexham',
    'Dumfries',
    'Wallsend',
    'Cockermouth',
    'Stockton-on-Tees',
    'Haslemere',
    'Haywards Heath',
    'Newick',
    'Wadhurst',
    'Saint Leonards-on-Sea',
    'Havant',
    'Edenbridge',
    'Catford',
    'Ilkley',
    'Driffield',
    'Sowerby Bridge',
    'Pudsey',
    'Hebden Bridge',
    'Barnoldswick',
    'Harrogate',
    'Wath upon Dearne',
    'Holmfirth',
    'Selby',
    'Mirfield',
    'Southwell',
    'Buckley',
    'Pontefract',
    'Ashfield',
    'Bakewell',
    'Newark on Trent',
    'Matlock',
    'Herne Bay',
    'Horley',
    'Ashford',
    'Margate',
    'Winchester',
    'Eastleigh',
    'Staines',
    'West Cowes',
    'Sandown',
    'Cranleigh',
    'Canterbury',
    'Warden',
    'Carnforth',
    'Lancaster',
    'Cleveleys',
    'Burnley',
    'Bebington',
    'Nelson',
    'Glastonbury',
    'Wroughton',
    'Saltash',
    'Penryn',
    'Ringwood',
    'Weston-super-Mare',
    'Launceston',
    'Par',
    'Swindon',
    'Newton Abbot',
    'Westbury',
    'Penzance',
    'Bushey',
    'Saffron Walden',
    'Banbury',
    'Bicester',
    'Royal Leamington Spa',
    'Cheshunt',
    'Tring',
    'Hoddesdon',
    'Broxbourne',
    'Tottenham',
    'Watford',
    'Harpenden',
    'Pyle',
    'Merthyr Tydfil',
    'Pontypool',
    'New Tredegar',
    'Milford Haven',
    'Kidwelly',
    'Tredegar',
    'Barry',
    'Fishguard',
    'Llangattock',
    'Treorchy',
    'Tenby',
    'Woodstock',
    'Stevenage',
    'Rushden',
    'Leighton Buzzard',
    'Lidlington',
    'Corsham',
    'Aylesbury',
    'Swindon Village',
    'Thame',
    'Kilbride',
    'Burntisland',
    'Perth',
    'Bathgate',
    'Ashby de la Zouch',
    'Saint Bees',
    "Bishop's Stortford",
    'Potters Bar',
    'Hemel Hempstead',
    'Dromore',
    'Palmers Green',
    'Pinner',
    'Witham',
    'Baldock',
    'Shefford',
    'Wickham Bishops',
    'Dagenham',
    'Rainham',
    'St Albans',
    'Harlow',
    'Shipley',
    'Mitcham',
    'Epsom',
    'Godalming',
    'Covent Garden',
    'East Grinstead',
    'Lavant',
    'Wallington',
    'Tiverton',
    'Northolt',
    'Reigate',
    'Isleworth',
    'High Wycombe',
    'Ashford',
    'Wellington',
    'Lyndhurst',
    'Henstridge',
    'Great Torrington',
    'Sturminster Newton',
    'Redhill',
    'Bewdley',
    'Quinton',
    'Tenbury Wells',
    'Southam',
    'Ludlow',
    'Middlewich',
    'Stone',
    'Bridgnorth',
    'Armitage',
    'Newcastle',
    'Louth',
    'Holywell',
    'Brook',
    'Clevedon',
    'Tetbury',
    'Wilmslow',
    'Bangor-is-y-coed',
    'Stalybridge',
    'High Peak',
    'Chepstow',
    'Hamble',
    'Bodmin',
    'Helston',
    'Milford on Sea',
    'Tavistock',
    'Dorchester',
    'Truro',
    'Bridgwater',
    'Todmorden',
    'Kingston',
    'West Wittering',
    'Bracknell',
    'Weybridge',
    'Drayton Saint Leonard',
    'Alton',
    'Farnborough',
    'Hook',
    'Shanklin',
    'Kendal',
    'Craigavon',
    'Bury St Edmunds',
    'Hayes',
    'Felixstowe',
    'Great Yarmouth',
    'Liversedge',
    'Batley',
    'Brighouse',
    'Washington',
    'Wednesbury',
    'Dundee',
    'Ashton-under-Lyne',
    'Ely',
    'Wisbech',
    'Mauchline',
    'Hackbridge',
    'Carshalton',
    'Queensferry',
    'Axminster',
    'Crewkerne',
    'Yeovil',
    'Widnes',
    'Diss',
    'Perranporth',
    'Lydney',
    'Prestatyn',
    'Langport',
    'London Borough of Bromley',
    'Harleston',
    'Halesworth',
    'Thetford',
    'Wimborne Minster',
    'Buckie',
    'Longton',
    'Ascot',
    'Buckfastleigh',
    'East Stour',
    'Folkestone',
    'Hedgerley',
    'Bisley',
    'Montrose',
    'Heywood',
    'Denbigh',
    'Bagillt',
    'Caernarfon',
    'West Calder',
    'Grangemouth',
    'Glenrothes',
    "Bo'ness",
    'Berwick-Upon-Tweed',
    'Linlithgow',
    'Kirkcaldy',
    'Beaconsfield',
    'Windsor',
    'Maidenhead',
    'Lancing',
    'Seaford',
    'Lochgelly',
    'Ferndale',
    'Gelligaer',
    'Lisburn',
    'Portadown',
    'Cowdenbeath',
    'Altrincham',
    'Hurstpierpoint',
    'Mexborough',
    'Coppull',
    'Leek',
    'Ramsgate',
    'Elgin',
    'Inverness',
    'Dereham',
    'Brough',
    'East Ham',
    'Erith',
    'Sandbach',
    'Kidsgrove',
    'Bingley',
    'Southend-on-Sea',
    'Redbourn',
    'Chesham',
    'Newtownabbey',
    'Thornaby',
    'Whyteleafe',
    'South Benfleet',
    'Cromer',
    'Wadebridge',
    'Cwmbran',
    'Wimbledon',
    'West Wickham',
    'West Drayton',
    'Colnbrook',
    'Freshwater',
    'Wargrave',
    'Crowthorne',
    'Menai Bridge',
    'Moreton',
    'Hawley',
    'Snodland',
    'Scunthorpe',
    'Hornsea',
    'Walthamstow',
    'Stuartfield',
    'Antrim',
    'Stamford',
    'Tranent',
    'Gosport',
    'Gorleston-on-Sea',
    'Abergavenny',
    'Morden',
    'Welling',
    'Pendlebury',
    'Risca',
    'Newry',
    'Old Malton',
    'Conway',
    'Brandon',
    'Wicken',
    'Saint Ives',
    'Burry Port',
    'Addlestone',
    'Calne',
    'Prescot',
    'Wrea Green',
    'Caterham',
    'Lingfield',
    'Old Colwyn',
    'Lowestoft',
    'Sompting',
    'Cullompton',
    'Rosyth',
    'Carrickfergus',
    'Stanton Fitzwarren',
    'Downham Market',
    'Stanford',
    'Bangor',
    'Andover',
    'Swadlincote',
    'Rugeley',
    'Magherafelt',
    'Hockley',
    'Keith',
    'Cumnock',
    'Chippenham',
    'Cookham',
    'Weedon Bec',
    'Shipston on Stour',
    'Ashton in Makerfield',
    'Larne',
    'West Bromwich',
    'Girvan',
    'Denny',
    'Haslingden',
    'Llandudno Junction',
    'Camborne',
    'Newton-le-Willows',
    'Newmarket',
    'Ardrossan',
    'Oakley',
    'Goole',
    'Egham',
    'Teddington',
    'Port Talbot',
    'Londonderry',
    'Strabane',
    'Bellshill',
    'Fraserburgh',
    'Drayton Bassett',
    'Bishopstoke',
    'Dumbarton',
    'Crossgar',
    'Ballymena',
    'Millom',
    'Berkhamstead',
    'Motherwell',
    'Saltcoats',
    'Sittingbourne',
    'Birchington',
    'Hamilton',
    'Edmonton',
    'Nuneaton',
    'Ulverston',
    'Newtownards',
    'Waltham Abbey',
    'Huyton',
    'Melton Mowbray',
    'Gretna Green',
    'Irlam',
    'Cleckheaton',
    'Coleraine',
    'Fulham',
    'Shotton',
    'Paignton',
    'Haltwhistle',
    'Hackney',
    'Dronfield',
    'Chipping Ongar',
    'Heathfield',
    'St Asaph',
    'Rochford',
    'Dalry',
    'Cowbridge',
    'Rathfriland',
    'Carterton',
    'Prestonpans',
    'Northwood',
    'Woolwich',
    'Atherton',
    'Hayle',
    'Houghton on the Hill',
    'Llandudno',
    'Holywood',
    'Didcot',
    'Westgate on Sea',
    'Blackwood',
    'Chapel en le Frith',
    'North Berwick',
    'Whitburn',
    'Grendon',
    'Teignmouth',
    'Ilfracombe',
    'Hull',
    'Draycott in the Moors',
    'Sidcup',
    'Welwyn Garden City',
    'Chatteris',
    'Streatham',
    'Tarleton',
    'Warfield',
    'Cumbernauld',
    'Saltburn-by-the-Sea',
    'Bonnyrigg',
    'Ormiston',
    'Longfield',
    'Yarm',
    'Northallerton',
    'Trimdon',
    'Clapham',
    'Berkeley',
    'Bishops Cleeve',
    'Drybrook',
    'Highworth',
    'Leominster',
    'Ross on Wye',
    'Faringdon',
    'Broadstairs',
    'Oxted',
    'Iver',
    'Woodbridge',
    'Kirkliston',
    'Fleetwood',
    'Walton on the Hill',
    'Esher',
    'Ellon',
    'Rhoose',
    'Chartham',
    'Deal',
    'Teynham',
    'Bedworth',
    'Catterick',
    'Cam',
    'Shrivenham',
    'Sedgefield',
    'Lewes',
    'Newcastle-under-Lyme',
    'Lossiemouth',
    'Invergordon',
    'Molesey',
    'Bourne End',
    'Johnstone',
    'Well',
    'Gorebridge',
    'Currie',
    'Limavady',
    'Banbridge',
    'Hendon',
    'Mold',
    'Oswaldtwistle',
    'Renfrew',
    'Coatbridge',
    'Newport Pagnell',
    'Chessington',
    'Eastwood',
    'Abergele',
    'Kenley',
    'Otford',
    'Kirkintilloch',
    'Syston',
    'Frodsham',
    'Haydock',
    'Bridgemary',
    'Manningtree',
    'Frinton-on-Sea',
    'Portchester',
    'Leigh',
    'Nelson',
    'Royston',
    'Betchworth',
    'Cobham',
    'Hungerford',
    'Hillingdon',
    'Hanwell',
    'Calverton',
    'Eastington',
    'Egremont',
    'Tweedmouth',
    'Ponteland',
    'Rothbury',
    'Countess Wear',
    'Maryport',
    'Barton upon Humber',
    'Beccles',
    'Warlingham',
    'Chislehurst',
    'Askam in Furness',
    'Gillingham',
    'Richmond',
    'Halewood',
    'Blairgowrie',
    'Cupar',
    'Thurso',
    'Kinghorn',
    'Ruthin',
    'Crickhowell',
    'Oundle',
    'Somersham',
    'Sherborne',
    'Caersws',
    'Brackley',
    'Great Gransden',
    'Airdrie',
    'Clovenfords',
    'Stromness',
    'Gosberton',
    'Biddenden',
    'Roydon',
    'Knaresborough',
    'Shepton Mallet',
    'Helensburgh',
    'Bolney',
    'Curdridge',
    'Llandeilo',
    'Tenterden',
    'Fakenham',
    'Aberaeron',
    'Market Drayton',
    'Holyhead',
    'Clun',
    'Wigton',
    'Bowes',
    'Brecon',
    'Neston',
    'Treforest',
    'River',
    'Granby',
    'Melbourne',
    'Arnold',
    'Meldreth',
    'Hessle',
    'Beverley',
    'North Ferriby',
    'Cottingham',
    'Anlaby',
    'Elloughton',
    'Hillside',
    'Hursley',
    'Newton Stewart',
    'Carlton',
    'Pickering',
    'Bedale',
    'Thornton-in-Craven',
    'Settle',
    'Manor',
    'Fauldhouse',
    'Sleaford',
    'Duffield',
    'Monmouth',
    'Coupar Angus',
    'Wick',
    'Chipstead',
    'West Kirby',
    'Magheralin',
    'Alnwick',
    'Haverfordwest',
    'Holmewood',
    'Winslow',
    'Ampthill',
    'Hillsborough',
    'Dunmurry',
    'Thirsk',
    'East Hagbourne',
    'Rhayader',
    'Newport',
    'Abberton',
    'Sandwich',
    'Much Wenlock',
    'Laugharne',
    'Clunderwen',
    'Churchill',
    'Finchampstead',
    'Godstone',
    'Howden',
    'Stanford',
    'Tregarth',
    'Pembroke Dock',
    'Newtown',
    'Ely',
    'Rye',
    'New Romney',
    'Coulsdon',
    'Dorking',
    'Newham',
    'Cliffe',
    'Goring',
    'Molesey',
    'Hampton',
    'Pewsey',
    'Marlborough',
    'Croston',
    'Elland',
    'Pocklington',
    'Witney',
    'Padbury',
    'Radstock',
    'Holt',
    'Alva',
    'Gourock',
    'Greenock',
    'Stanwell',
    'Craven Arms',
    'Bucknell',
    'Ferryside',
    'Treharris',
    'Lampeter',
    'Lauder',
    'Malton',
    'Crowland',
    'Whittlesey',
    'Llanfyrnach',
    'Weston',
    'Budleigh Salterton',
    'Ashington',
    'Llanishen',
    'Battle',
    'Minehead',
    'Liphook',
    'Chertsey',
    'Ingatestone',
    'Brasted',
    'Headley',
    'Arundel',
    'Axbridge',
    'Amlwch',
    'Hawick',
    'Alness',
    'Auchinleck',
    'Anstruther',
    'Cross',
    'Ballycastle',
    'Portrush',
    'Woodhall Spa',
    'Southwold',
    'Totternhoe',
    'Wantage',
    'Walmer',
    'Falmouth',
    'Milnathort',
    'Skipton',
    'Great Dunmow',
    'Brechin',
    'Birtley',
    'Bildeston',
    'Papworth Everard',
    'Wrangaton',
    'Fordingbridge',
    'Bagshot',
    'Earley',
    'Bolsover',
    'Bromyard',
    'Stokesley',
    'Tillicoultry',
    'Haddington',
    'Armadale',
    'Romsey',
    'Draycott',
    'Sedbergh',
    'Otley',
    'Meanwood',
    'Market Rasen',
    'East Boldon',
    'Workington',
    'Llandovery',
    'Rutherglen',
    'Peebles',
    'Horwich',
    'Ballyclare',
    'Buckingham',
    'Purley',
    'Kings Sutton',
    'Okehampton',
    'Stone Allerton',
    'Yelverton',
    'Blackford',
    'Wedmore',
    'Ashtead',
    'Hill',
    'Omagh',
    'Barnard Castle',
    'Appleby',
    'Frithville',
    'Caythorpe',
    'Swaffham',
    'Milnthorpe',
    'Kelso',
    'Shinfield',
    'Swinton',
    'Buntingford',
    'Holbeach',
    'Sunningdale',
    'Somerset',
    'East Molesey',
    'Sheringham',
    'Caistor',
    'Gretton',
    'Whitby',
    'Kilmacolm',
    'Stow on the Wold',
    'North Walsham',
    'Dromara',
    'Aughnacloy',
    'Whitchurch',
    'Liss',
    'Stone',
    'Dinnington',
    'Sutton in Ashfield',
    'Chinley',
    'Braunstone',
    'Ibstock',
    'Heath',
    'Deptford',
    'Denham',
    'Bootle',
    'Burslem',
    'Broughton',
    'Bulkington',
    'Barwell',
    'Stocksbridge',
    'High Peak Junction',
    'Chadwell',
    'Attleborough',
    'Perivale',
    'Tunstall',
    'Whitefield',
    'Brierfield',
    'Balerno',
    'Speldhurst',
    'Knighton',
    'Wick',
    'Leeds',
    'Eltham',
    'Amersham',
    'Iwerne Courtney',
    'Tollard Royal',
    'Dartmouth',
    'Cookstown',
    'Burscough',
    'Aston',
    'Lerwick',
    'Kelbrook',
    'Knebworth',
    'Sawbridgeworth',
    'Bridge',
    'Toddington',
    'Hammersmith',
    'Crymych',
    'Sutton on the Hill',
    'Letchworth',
    'Redland',
    'New Ferry',
    'Walton on Thames',
    'Levenshulme',
    'Choppington',
    'Normanton',
    'Dungannon',
    'Armagh',
    'Eastchurch',
    'Beckton',
    'Woodford',
    'Rawreth',
    'Stansted',
    'Lymington',
    'Robertsbridge',
    'Croxley Green',
    'Biggar',
    'Uppingham',
    'Hotham',
    'Partridge Green',
    'Burythorpe',
    'Derwen',
    'Studley',
    'Ripley',
    'Wareham',
    'South Brewham',
    'Thornton',
    'Aberford',
    'Melksham',
    'Golborne',
    'Patchway',
    'Valley',
    'Stoke upon Tern',
    'Llantrisant',
    'Woodsetts',
    'Markfield',
    'Finchley',
    'Wigston',
    'Back',
    'Islington',
    'Ruddington',
    'Send',
    'Seale',
    'Grove',
    'Hindhead',
    'Long Eaton',
    'Bawtry',
    'Turnberry',
    'Morley',
    'Overton',
    'Victoria',
    'Eastcote',
    'Harefield',
    'Seven Kings',
    'Newlands',
    'Kilkeel',
    'Princethorpe',
    'Beaumont',
    'Hurworth',
    'London Borough of Wandsworth',
    'Corringham',
    'Marsham',
    'Porthleven',
    'Sandwell',
    'Shardlow',
    'Stoneleigh',
    'Star',
    'Bluntisham',
    'Minchinhampton',
    'Chiswick',
    'Fairford',
    'Ickenham',
    'Chigwell',
    'Clifton',
    'Pulborough village hall',
    'Laurencekirk',
    'Watlington',
    'Cheddar',
    'Woodhouse',
    'Wilden',
    'Arlesey',
    'North Weald',
    'Mill Hill',
    'Castle Donington',
    'Castlereagh',
    'Ifield',
    'Kirby Cross',
    'Waltham Cross',
    'Caddington',
    'Reddish',
    'Bransgore',
    'Stretford',
    'Timperley',
    'Wythenshawe',
    'Wales',
    'Seaton',
    'Standlake',
    'Lower Brailes',
    'Takeley',
    'Long Buckby',
    'Great Missenden',
    'Selsey',
    'Hedon',
    'Market Weighton',
    'Withernsea',
    'Burnham',
    'Winnersh',
    'Aintree',
    'Crediton',
    'Formby',
    'Carnoustie',
    'Silsden',
    'Swanscombe',
    'Northfleet',
    'Aveley',
    'Strood',
    'Heston',
    'Albrighton',
    'Chapeltown',
    'Filey',
    'Sunninghill',
    'Bickenhill',
    'Christleton',
    'Pembury',
    'East Horsley',
    'Upton',
    'Burton',
    'Blackford',
    'Swalwell',
    'Broughton',
    'Bramley',
    'Skelton',
    'Crick',
    'Mayfair',
    'Whisby',
    'Willaston',
    'Buxted',
    'Duxford',
    'Moreton in Marsh',
    'Three Legged Cross',
    'Hunstanton',
    'Radlett',
    'Broadway',
    'Winnington',
    'Corwen',
    'Cooksbridge',
    'Shoeburyness',
    'Drumchapel',
    'Larkhall',
    'Higham Ferrers',
    'Steeton',
    'Farnham Royal',
    'Chandlers Ford',
    'Ashley',
    'Twyford',
    'Farningham',
    'Wrangle',
    'Forest Row',
    'Horrabridge',
    'Portishead',
    'Hadleigh',
    'Bishops Waltham',
    'Brightlingsea',
    'Cuckfield',
    'Steyning',
    'Southwick',
    'Ivybridge',
    'Kirton in Lindsey',
    'Treuddyn',
    'Tewin',
    'Ramsbottom',
    'Tynemouth',
    'Spilsby',
    'Denby',
    'Henley',
    'Hartley',
    'Box',
    'Ripe',
    'Thurleigh',
    'Alresford',
    'Little Hulton',
    'Halton',
    'Dunoon',
    'Port Glasgow',
    'Bishopton',
    'Trent',
    'Streatley',
    'Hythe',
    'Wilmington',
    'Wickham',
    'Eastham',
    'Thames Ditton',
    'Little Paxton',
    'Meggies',
    'Merton',
    'Bookham',
    'Cottenham',
    'Earls Court',
    'Immingham',
    'Barrow upon Humber',
    'Bingham',
    'Broadstone',
    'Southbourne',
    'Biddulph',
    'Bridport',
    'Beer',
    'Thornton',
    'Huntspill',
    'Isham',
    'Thrapston',
    'Queenborough',
    'Stornoway',
    'Postling',
    'Cranbrook',
    'Fillongley',
    'Kirriemuir',
    'Elstree',
    'Slinfold',
    'Wombwell',
    'Linton',
    'Buckhurst Hill',
    'Cosham',
    'Catherington',
    'Broughton',
    'Burley',
    'Beauly',
    'Teddington',
    'Twyford',
    'Midsomer Norton',
    'Uddingston',
    'Ambleside',
    'Alford',
    'Low Ham',
    'Barton on Sea',
    'Wethersfield',
    'Hindley',
    'Pelsall',
    'Darlaston',
    'Gnosall',
    'Newbiggin-by-the-Sea',
    'Bromborough',
    'Highbridge',
    'Ashbourne',
    'Aberystwyth',
    'Windsor',
    'Hopwood',
    'Woodham Ferrers',
    'Auchterarder',
    'Chinnor',
    'Checkley',
    'Heathrow',
    'Knowsley',
    'Minsterley',
    'Clarbeston Road',
    'Lee-on-the-Solent',
    'Pinxton',
    'Alderley Edge',
    'Gorton',
    'Padiham',
    'The Hyde',
    'Downpatrick',
    'Cuffley',
    'Wolstanton',
    'Worsley',
    'Tilehurst',
    'Guisborough',
    'Westgate',
    'Clifton',
    'Shirebrook',
    'Kirkby in Ashfield',
    'Houghton Regis',
    'Hapton',
    'Mount Bures',
    'Rothwell',
    'Clifton',
    'Maulden',
    'Sutton on Trent',
    'West Byfleet',
    'Mansfield Woodhouse',
    'Frimley',
    'Ravenstone',
    'Shepshed',
    'Old Buckenham',
    'Desborough',
    'Rainhill',
    'Fitzwilliam',
    'Garstang',
    'Dulwich',
    'Pilton',
    'Tile Hill',
    'Llanidloes',
    'Eaglesham',
    'Whitchurch',
    'Pwllheli',
    'Blakeney',
    'South Petherton',
    'Stutton',
    'Melton Constable',
    'Jedburgh',
    'Fishburn',
    'Corfe Mullen',
    'Hartwell',
    'Ketley',
    'Radcliffe',
    'Fort William',
    'Galston',
    'Arbroath',
    'Strathmiglo',
    'Clackmannan',
    'Beaulieu',
    'Plockton',
    'Voe',
    'Inveraray',
    'Kinglassie',
    'Buckland',
    'Purleigh',
    'Beaminster',
    'Easingwold',
    'Llanymynech',
    'Newburgh',
    'Fintry',
    'Alford',
    'Aberchirder',
    'Callander',
    'Aberlour',
    'Cotgrave',
    'Great Chesterford',
    'Lyme Regis',
    'Longstanton',
    'Shotts',
    'Unstone',
    'Brewood',
    'Whitwick',
    'Somerton',
    'Stockbridge',
    'Lyminge',
    'Broughton',
    'Gunnislake',
    'Saint Agnes',
    'Easington',
    'Garston',
    'Church Gresley',
    'West Kilbride',
    'Normandy',
    'Rainham',
    'Milton',
    'Keswick',
    'Etchingham',
    'Saxmundham',
    'Anstey',
    'Saint Brides Major',
    'Ventnor',
    'Hindon',
    'Weston under Lizard',
    "Bishop's Castle",
    'Kinnerley',
    'Barkway',
    'Hurstbourne Tarrant',
    'Westbury-sub-Mendip',
    'Dolwyddelan',
    'Talybont',
    'Castleton',
    'Northleach',
    'Chipping Campden',
    'New Quay',
    'Ottershaw',
    'Petworth',
    'Bedminster',
    'Caston',
    'Selston',
    'Effingham',
    'Brixham',
    'New Southgate',
    'Ashwell',
    'Totton',
    'Bletchley',
    'Winterbourne Dauntsey',
    'Southwater',
    'Blakeney',
    'Llangefni',
    'Staplehurst',
    'Great Fransham',
    'Pencarreg',
    'Benenden',
    'Limekilns',
    'Leyburn',
    'Great Gidding',
    'Hemingstone',
    'Chaddesden',
    'Little Kimble',
    'Seascale',
    'Exminster',
    'Loanhead',
    'Atworth',
    'Brixton Hill',
    'Datchet',
    'Middleton One Row',
    'Middleton',
    'Tollerton',
    'Much Hadham',
    'More',
    'West Horsley',
    'Ulceby',
    'Myton on Swale',
    'Lindfield',
    'Nairn',
    'Northrepps',
    'Church Stretton',
    'Shifnal',
    'Dyserth',
    'Arthog',
    'Malmesbury',
    'Wincanton',
    'Silloth',
    'Weobley',
    'Kirkwall',
    'Carlton le Moorland',
    'Broom',
    'Cinderford',
    'Rogiet',
    'Offord Cluny',
    'Pevensey',
    'Eyemouth',
    'Carbrooke',
    'Kimbolton',
    'Giggleswick',
    'Cobham',
    'Leysdown-on-Sea',
    'Oare',
    'Banchory',
    'Hinton St George',
    'Ashburton',
    'Windermere',
    'Whitehaven',
    'Saundersfoot',
    'Compton',
    'Crumlin',
    'Whitbourne',
    'Fulmer',
    'Pencader',
    'Shotley Gate',
    'Fressingfield',
    'Corfe Castle',
    'Melrose',
    'Innerleithen',
    'Whiteparish',
    'Zeals',
    'Holsworthy',
    'Pitlochry',
    'Lockerbie',
    'Seend',
    'Burghclere',
    'Drayton',
    'Insch',
    'Inverness-shire',
    'Gargunnock',
    'Port Erroll',
    'Llanbedr',
    'Beckley',
    'Symington',
    'Wymeswold',
    'Banff',
    'Nordelph',
    'Hartfield',
    'Bayford',
    'Greensted',
    'Dulverton',
    'Bickleigh',
    'Oakamoor',
    'Beckingham',
    'Dymock',
    'Tadcaster',
    'Wragby',
    'Gosforth',
    'Narberth',
    'North Moreton',
    'Hawes',
    'New Barnet',
    'Stratford',
    'Burstwick',
    'Aldeburgh',
    'Mablethorpe',
    'Beith',
    'Tiptree',
    'Watton',
    'Bridgham',
    'Drighlington',
    'Inverurie',
    'Connahs Quay',
    'Dinas Powys',
    'Soham',
    'Leiston',
    'Gosfield',
    'Wootton',
    'Stonehaven',
    'Moston',
    'Muir of Ord',
    'Codsall',
    'Stretton on Fosse',
    'Barnwell',
    'Llangollen',
    'Herstmonceux',
    'South Milford',
    'Bottesford',
    'Gaerwen',
    'Montgomery',
    'Cynwyd',
    'Broadwas',
    'Talgarth',
    'Great Ryburgh',
    'Wyverstone',
    'Sheering',
    'Meifod',
    'Brampton',
    'Machynlleth',
    'Kirkby',
    'Auchtermuchty',
    'Mark',
    'Lifton',
    'Polperro',
    'South Molton',
    'Alfold',
    'Birnam',
    'Strathyre',
    'Cardigan',
    'Lechlade',
    'Clavering',
    'Gayton Thorpe',
    'Malpas',
    'Longdown',
    'Ramsey',
    'Piddington',
    'Newport-On-Tay',
    'Blunham',
    'Wilburton',
    'Dumbleton',
    'Owslebury',
    'Easton',
    'Bruton',
    'Aycliffe',
    'Angmering',
    'Henley-on-Thames',
    'Cricklade',
    'Edwalton',
    'Harlaxton',
    'South Normanton',
    'Chingford',
    'Clipstone',
    'Tansley',
    'Princes Risborough',
    'Stock',
    'Waterbeach',
    'Ormesby',
    'Leyton',
    'Bishopbriggs',
    'Portslade-by-Sea',
    'Goodmayes',
    'Stanford',
    'Lowton',
    'Chalgrove',
    'Studham',
    'Botley',
    'Denton',
    'Kempston',
    'Lewisham',
    'Market Deeping',
    'Rainworth',
    'Swavesey',
    'Hucknall Torkard',
    'Droylsden',
    'Sawley',
    'Keynsham',
    'Sidlesham',
    'Harrow Weald',
    'Brockworth',
    'Hebburn',
    'Theydon Bois',
    'Burbage',
    'Crayford',
    'Hersham',
    'Balloch',
    'High Blantyre',
    'Thornbury',
    'Yate',
    'Norwood',
    'Winlaton',
    'Studley',
    'Whickham',
    'Salfords',
    'Maghull',
    'Neasden',
    'Hanworth',
    'Comrie',
    'Purfleet',
    'Houston',
    'Baildon',
    'Nailsea',
    'Polmont',
    'Walton on the Hill',
    'Norbury',
    'Addington',
    'Cheam',
    'Boldon Colliery',
    'Chelsfield',
    'Sedgley',
    'Edgbaston',
    'Freckleton',
    'Honingham',
    'Northfield',
    'Holmes Chapel',
    'Much Hoole',
    'Bowdon',
    'Middleton',
    'East Linton',
    'Charfield',
    'Gipping',
    'Milton on Stour',
    'Greenfield',
    'Moulton St Michael',
    'Chilbolton',
    'Histon',
    'Bothwell',
    'Barton-le-Clay',
    'Square',
    'Burnopfield',
    'Aston Clinton',
    'Langstone',
    'Whitchurch',
    'Nettleton',
    'Waldringfield',
    'Greenwich',
    'Rosslea',
    'Chryston',
    'Skilgate',
    'Chalfont Saint Peter',
    'Waddesdon',
    'Horley',
    'Grantown on Spey',
    'Ben Rhydding',
    'Oban',
    'Chestfield',
    'Stoke Poges',
    'East Harling',
    'Eye',
    'Thorrington',
    'Burnham on Crouch',
    'Sanderstead',
    'Stallingborough',
    'Rustington',
    'Binsted',
    'Steventon',
    'Heysham',
    'Torpoint',
    'Worle',
    'Wick',
    'Blidworth',
    'Stonesfield',
    'Laxton',
    'Billesley',
    'Uphall',
    'Hilton',
    'Oadby',
    'Cowley',
    'Lubenham',
    'Lambourn',
    'West Malling',
    'Willington',
    'Pencaitland',
    'Lochmaddy',
    'Whitland',
    'Willington',
    'West Mersea',
    'Wentworth',
    'Painswick',
    'Donnington',
    'Hatch',
    'Turvey',
    'Haddenham',
    'Millbrook',
    'Pembroke',
    'Chellaston',
    'Bletchingley',
    'Balham',
    'Tonyrefail',
    'Carnmoney',
    'East Dulwich',
    'Irthlingborough',
    'Puckeridge',
    'Wheathampstead',
    'Briton Ferry',
    'Westhoughton',
    'Cheadle Hulme',
    'Yeadon',
    'Bulford',
    'Godmanchester',
    'Henlow',
    'Coseley',
    'Humberston',
    'Stotfold',
    'Horndean',
    'Tongham',
    'Elderslie',
    'Sandiacre',
    'Marske',
    'North Hykeham',
    'Tyldesley',
    'Olney',
    'Sheldon',
    'Cambuslang',
    'Thornaby',
    'Ash Vale',
    'Sonning',
    'Willesden',
    'Bealings',
    'East Cowes',
    'Wootton',
    'Durrington',
    'Blaenavon',
    'Dunchurch',
    'Horsmonden',
    'Brixworth',
    'Harwell',
    'Bugbrooke',
    'Cleator Moor',
    'Steeple Claydon',
    'Rothesay',
    'Headington',
    'Dovercourt',
    'Tarporley',
    'Greenisland',
    'Carlton',
    'Coalisland',
    'Hagley',
    'Brimscombe',
    'Pulloxhill',
    'Welwyn',
    'Kingston Seymour',
    'Binfield',
    'Royal Wootton Bassett',
    'Newcastle',
    'Monifieth',
    'Duffus',
    'Llantwit Major',
    'Stewarton',
    'Dunblane',
    'South Killingholme',
    'Itchen Abbas',
    'West Raynham',
    'Kirkby Stephen',
    'Stillington',
    'Inverkeilor',
    'Glenshee',
    'Kingussie',
    'Campbeltown',
    'Leitholm',
    'Prees',
    'Bala',
    'Dunkeld',
    'Longniddry',
    'Sutton Courtenay',
    'Wix',
    'Yoxford',
    'Leigh',
    'Spetisbury',
    'Kingskerswell',
    'Looe',
    'Upwell',
    'Pampisford',
    'Tain',
    'Marden',
    'Penn',
    'Horsted Keynes',
    'East Bergholt',
    'Westcott',
    'Stocksfield',
    'Oldmeldrum',
    'Canewdon',
    'Winterborne Kingston',
    'Kirkconnel',
    'Mayfield',
    'Norton',
    'Haydon Bridge',
    'Horam',
    'Little Gaddesden',
    'Stanbridge',
    'Turriff',
    'Lochgilphead',
    'East Tilbury',
    'Storrington',
    'Hathersage',
    'Macduff',
    'Dingwall',
    'Farmborough',
    'Gullane',
    'Bozeat',
    'Crosby',
    'Wick',
    'Glasdrumman',
    'Llanrwst',
    'Hadleigh',
    'Warrenpoint',
    'Enniskillen',
    'Fivemiletown',
    'Ellington',
    'Selkirk',
    'Ringmer',
    'Hawkhurst',
    'Barnham',
    'Bude',
    'Prestwich',
    'Ashdon',
    'Cogenhoe',
    'Portree',
    'Aultbea',
    'Enstone',
    'Finedon',
    'Grange',
    'Frant',
    'High Halden',
    'Lochwinnoch',
    'Arrochar',
    'Winkleigh',
    'Watchet',
    'Beaworthy',
    'Doune',
    'Stiffkey',
    'Bonar Bridge',
    'High Bentham',
    'Edgefield',
    'Gargrave',
    'Glentham',
    'North Kilworth',
    'Ramsbury',
    'Lyng',
    'Heckington',
    'Lowdham',
    'Lydbury North',
    'Dolgelly',
    'Blaenau-Ffestiniog',
    'Barmston',
    'Whittlebury',
    'Llanwrtyd Wells',
    'Nutley',
    'Cardross',
    'Harlech',
    'Brede',
    'Hilborough',
    'Portmadoc',
    'Penshurst',
    'Templecombe',
    'Moira',
    'Flordon',
    'Yatton',
    'Milton',
    'Slapton',
    'Kelty',
    'Dollar',
    'Braunton',
    'South Cerney',
    'Horncastle',
    'Dornoch',
    'Rowhedge',
    'South Carlton',
    'Grendon Underwood',
    'Grateley',
    'Pentraeth',
    'Llwyngwril',
    'Mollington',
    'Little Barningham',
    'Trehafod',
    'Martley',
    'Ellesmere',
    'Warton',
    'Little Canfield',
    'Bradwell on Sea',
    'Tackley',
    'Dunkeswell',
    'Nutfield',
    'Dunning',
    'Abergynolwyn',
    'Westhay',
    'Huntly',
    'Baslow',
    'Bowness-on-Windermere',
    'Chulmleigh',
    'Charmouth',
    'Llanfairfechan',
    'New Buckenham',
    'Tarbert',
    'Wyton',
    'Rock Ferry',
    'Alderholt',
    'Odiham',
    'Calstock',
    'Benburb',
    'Bangor',
    'Hemsworth',
    'St Ives',
    'Templepatrick',
    'Higher Bebington',
    'Boscastle',
    'Castlederg',
    'Helens Bay',
    'Marhamchurch',
    'Snettisham',
    'Besthorpe',
    'Midhurst',
    'Stow',
    'Banham',
    'Great Waldingfield',
    'Norton',
    'Kilbarchan',
    'Claygate',
    'Gorslas',
    'Mytholmroyd',
    'Presteigne',
    'Upton upon Severn',
    'Amesbury',
    'Drumahoe',
    'Juniper Green',
    'Horndon on the Hill',
    'Barrow',
    'Aghalee',
    'Ruabon',
    'Ramsey Saint Marys',
    'Wirksworth',
    'Peterculter',
    'Kingswood',
    'Fladbury',
    'Callington',
    'Napton on the Hill',
    'Tayport',
    'Wing',
    'Hingham',
    'Hermitage',
    'Wellington',
    'Littleport',
    'Anstey',
    'Biddenham',
    'Inverkeithing',
    'Fowey',
    'Conon Bridge',
    'Marple',
    'Hednesford',
    'Saint Columb Major',
    'Newcastle Emlyn',
    'Aberkenfig',
    'North Tawton',
    'Great Milton',
    'Matching',
    'Taynuilt',
    'Shalford',
    'Great Glemham',
    'Northop',
    'Froggatt',
    'Brandesburton',
    'Masham',
    'Birdbrook',
    'Landrake',
    'Trusthorpe',
    'Reydon',
    'Darfield',
    'Whimple',
    'Elstead',
    'Widdrington',
    'Lambeth',
    'East Peckham',
    'Taplow',
    'Benllech',
    'Bramford',
    'Kibworth Harcourt',
    'Byfield',
    'Kimberley',
    'Keresley',
    'Barton under Needwood',
    'Clare',
    'Pilham',
    'Patrington',
    'Helmsley',
    'Leconfield',
    'Roxwell',
    'Mickleton',
    'Bow',
    'Henham',
    'Sherfield upon Loddon',
    'Kirkcudbright',
    'Duddington',
    'Tetsworth',
    'Naseby',
    'Akeley',
    'Nettlebed',
    'Harrold',
    'Colyford',
    'Blewbury',
    'Loddington',
    'Aylsham',
    'Cranfield',
    'Totteridge',
    'Maghera',
    'Deighton',
    'Gresford',
    'Southgate',
    'Ballingry',
    'Newton on Trent',
    'Lisnaskea',
    'Ballymoney',
    'Newport',
    'Harworth',
    'Eccleshall',
    'Annesley',
    'Oxshott',
    'Randalstown',
    'Moneymore',
    'East Budleigh',
    'Tandragee',
    'Stawell',
    'Silverstone',
    'Long Ditton',
    'Windlesham',
    'Monkswood',
    'Sedgeberrow',
    'Mendlesham',
    'Selborne',
    'Lustleigh',
    'Talsarnau',
    'Avoch',
    'Stockcross',
    'Kelmarsh',
    'Gamlingay',
    'Stanton',
    'Lostwithiel',
    'Westleton',
    'Corpach',
    'Belbroughton',
    'Thorpe St Peter',
    'Broughton',
    'Seaview',
    'East Barnet',
    'Falkland',
    'Kelsall',
    'Minster Lovell',
    'Shireoaks',
    'Forres',
    'Wombourne',
    'Claverdon',
    'Measham',
    'Rotherfield',
    'Wingate',
    'Menheniot',
    'Radcliffe on Trent',
    'Blackwater',
    'Earnley',
    'Kedington',
    'Camelford',
    'Newbridge',
    'Barmouth',
    'Bledlow',
    'Llanfyllin',
    'Cowden',
    'Staindrop',
    'Kingham',
    'Cockerham',
    'Frizington',
    'Silverdale',
    'Willingham',
    'Laxfield',
    'Butterton',
    'Blaenwaun',
    'Barton in Fabis',
    'Ashen',
    'Burbage',
    'Eynsham',
    'Weston',
    'Llanwrda',
    'Betws',
    'Elsworth',
    'Ufford',
    'Kirtlington',
    'Faulkland',
    'Hatherleigh',
    'Scalby',
    'Cullen',
    'Fringford',
    'Grasmere',
    'Hightown',
    'Hambleton',
    'Friskney',
    'Stoke Climsland',
    'Clifton Hampden',
    'Horbling',
    'Marcham',
    'Guiseley',
    'Wroxall',
    'Frensham',
    'Avonmouth',
    'Trefonen',
    'East Challow',
    'Rhos-on-Sea',
    'Stevenston',
    'Addington',
    'Castlewellan',
    'Maiden Newton',
    'Rowlands Castle',
    'Boroughbridge',
    'Linton upon Ouse',
    'Nafferton',
    'Coalbrookdale',
    'Lower Darwen',
    'Mells',
    'Inverkip',
    'Meopham',
    'Roath',
    'Broughty Ferry',
    'Sileby',
    'Widmerpool',
    'North Waltham',
    'Doagh',
    'Saint Erme',
    'Harold Wood',
    'Dorney',
    'Brockenhurst',
    'Fairlight',
    'Goring',
    'Morriston',
    'Alsager',
    'Wemyss Bay',
    'Murton',
    'Bodelwyddan',
    'Ticehurst',
    'Alveston',
    'Garrison',
    'Skelmorlie',
    'Roehampton',
    'Bacton',
    'Nassington',
    'Wraysbury',
    'Castle Cary',
    'Capel',
    'Four Marks',
    'Llansantffraid-ym-Mechain',
    'Aldridge',
    'Caister-on-Sea',
    'Bordesley',
    'Shalford',
    'Godmersham',
    'West Clandon',
    'Brownhills',
    'Linton',
    'Edenfield',
    'Rothwell',
    'Builth Wells',
    'Cross in Hand',
    'Wanstead',
    'Bembridge',
    'High Legh',
    'Empingham',
    'Hever',
    'Arley',
    'Pontesbury',
    'Water Orton',
    'Wolverley',
    'Tisbury',
    'Harlesden',
    'Paddock Wood',
    'Sully',
    'Llwynypia',
    'Carrowdore',
    'Burwash',
    'Chilton',
    'Loudwater',
    'Theale',
    'Renton',
    'Langley',
    'Pittenweem',
    'Moulton St Michael',
    'Englefield Green',
    'Halstead',
    'Cheadle',
    'Lapworth',
    'South Cave',
    'Marnhull',
    'Blackwater',
    'Brimpton',
    'Risley',
    'Wooburn',
    'Broughton',
    'Crofton',
    'Whetstone',
    'Kensington',
    'Ardmillan',
    'Powfoot',
    'Girton',
    'Kennington',
    'Rainford',
    'Bredbury',
    'Beach',
    'Brenchley',
    'Pennington',
    'Littlebury',
    'Clayton West',
    'Kirkham',
    'Staple',
    'Kelvedon',
    'Spratton',
    'Bramhall',
    'Bloomsbury',
    'Brook',
    'Martin',
    'City of London',
    'Bourton on the Water',
    'Charlwood',
    'Croughton',
    'Southgate',
    'Hennock',
    'Old Windsor',
    'Laindon',
    'Walkden',
    'Barnes',
    'Norton Lindsey',
    'Eversholt',
    'Horton Kirby',
    'Wilshamstead',
    'Crookham',
    'Bermondsey',
    'Castle Douglas',
    'Neilston',
    'Whitwell',
    'Longridge',
    'East Leake',
    'Alvechurch',
    'Eccles',
    'Ashmore',
    'Loughor',
    'Hampton Lucy',
    'Tattershall',
    'Ystalyfera',
    'Kinmel',
    'Merthyr Mawr',
    'Milford',
    'Llangennech',
    'Epping Green',
    'Preesall',
    'Ewelme',
    'Whittlesford',
    'Mountsorrel',
    'Ripley',
    'Hoylake',
    'Willington Quay',
    'Pathhead',
    'Framlingham',
    'East Hanningfield',
    'Donaghadee',
    'North Elmsall',
    'Milltimber',
    'Askern',
    'Oldbury',
    'Pontycymer',
    'Great Baddow',
    'Dollis Hill',
    'Slawitt',
    'Glenboig',
    'Colne',
    'Portlethen',
    'Little Lever',
    'Bulwell',
    'Felbridge',
    'Poplar',
    'Shenfield',
    'Northenden',
    'Threlkeld',
    'Ullesthorpe',
    'Stanton',
    'Goostrey',
    'Walgrave',
    'Berwick',
    'Halwell',
    'Mallaig',
    'Ticknall',
    'Bentley',
    'Kneeton',
    'Tatsfield',
    'Broughton',
    'Millbrook',
    'Stalham',
    'Cleator',
    'Gretna',
    'Lenham',
    'Ewhurst',
    'Sacriston',
    'Melton',
    'Caversham',
    'Wickham Market',
    'Penwortham',
    'Grimsargh',
    'Cambusnethan',
    'Litherland',
    'Gatwick',
    'Bromham',
    'Lurgan',
    'North Petherton',
    'East Hoathly',
    'Manton',
    'Rawmarsh',
    'Draperstown',
    'Otterburn',
    'Kilbride',
    'Lavendon',
    'Lee',
    'Leek Wootton',
    'Potton',
    'Mobberley',
    'Swynnerton',
    'South Hetton',
    'Pontardawe',
    'Hucknall under Huthwaite',
    'Weston in Gordano',
    'Needingworth',
    'Partington',
    'Portstewart',
    'Dungiven',
    'Swaythling',
    'Handforth',
    'Caxton',
    'Hadlow Down',
    'Harrow on the Hill',
    'Weaverham',
    'Bidston',
    'Abercynon',
    'Lawrencetown',
    'Stranraer',
    'Bollington',
    'Pencoed',
    'Woodley',
    'Maerdy',
    'Whaley Bridge',
    'Stony Stratford',
    'Abergwynfi',
    'Nantyglo',
    'Dorridge',
    'Astley',
    'Shirehampton',
    'Raynes Park',
    'Kings Norton',
    'Maida Vale',
    'Chipping Sodbury',
    'Wrington',
    'Hawkwell',
    'Menston',
    'Poynton',
    'Port Sunlight',
    'Clapham',
    'Hanley',
    'Horbury',
    'Bignor',
    'Altham',
    'Oxenhope',
    'Sandycroft',
    'Llantwit Fardre',
    'New Mills',
    'St. Dogmaels',
    'Higham on the Hill',
    'Elmley Lovett',
    'Aberfeldy',
    'Earlston',
    'Charlestown',
    'Carrbridge',
    'Spondon',
    'Shaw',
    'Hatton',
    'West Rudham',
    'Whitton',
    'Tillingham',
    'Pleshey',
    'Botesdale',
    'Willingale Doe',
    'Coed-Talon',
    'Beaumaris',
    'Alston',
    'Woore',
    'Bosbury',
    'Tadmarton',
    'Brigstock',
    'Clutton',
    'Mere',
    'Highcliffe',
    'Burton Pidsea',
    'Middleton',
    'Withernwick',
    'North Somercotes',
    'Wold Newton',
    'Tideswell',
    'Fangfoss',
    'Porlock',
    'Loppington',
    'Powick',
    'Diddington',
    'Saint Annes on the Sea',
    'Woolacombe',
    'Chilcompton',
    'Stanstead',
    'Ponders End',
    'Borough Green',
    'Buckden',
    'Birtley',
    'Little Eaton',
    'Dundonald',
    'Hawarden',
    'Barrowford',
    'Mundesley',
    'Sutton on Hull',
    'Shawbury',
    'Oulton',
    'Methil',
    'Langho',
    'Burgh le Marsh',
    'Lakenheath',
    'Lesmahagow',
    'Midgham',
    'Parbold',
    'Long Stratton',
    'Brandon',
    'Sanquhar',
    'Longhope',
    'Portaferry',
    'Tempo',
    'Waringstown',
    'Rowde',
    'Whitchurch',
    'Watchfield',
    'Whalley',
    'Moulton',
    'Mattishall',
    'Metheringham',
    'Duns',
    'Aboyne',
    'Heybridge',
    'Great Staughton',
    'Smarden',
    'Shilton',
    'Ridingmill',
    'Walker',
    'Cawston',
    'Farndon',
    'Conisbrough',
    'Sarratt',
    'Leuchars',
    'East Malling',
    'Llanddulas',
    'Clydach',
    'Wheatley',
    'Islip',
    'West Ashby',
    'Stifford',
    'Ilchester',
    'Padstow',
    'Tywardreath',
    'Roche',
    'Camberwell',
    'Goodwick',
    'Treherbert',
    'Radnage',
    'Cublington',
    'Chilton',
    'Llanharan',
    'Ripponden',
    'Gaydon',
    'Chasetown',
    'Bilsthorpe',
    'Gomersal',
    'Wendover',
    'Groombridge',
    'Weare',
    'Upper Beeding',
    'Coggeshall',
    'Heriot',
    'Appleford',
    'Belcoo',
    'Belleek',
    'Kingswood',
    'Blaina',
    'Gordon',
    'Norton',
    'Appleton',
    'Moorsholm',
    'Roffey',
    'Kingston',
    'Seacroft',
    'Daresbury',
    'Babraham',
    'Hornsey',
    'Reed',
    'Birchanger',
    'Smalley',
    'Edwinstowe',
    'Monyash',
    'Friern Barnet',
    'Newhall',
    'Stockton',
    'Seghill',
    'Adlingfleet',
    'Plumstead',
    'Keele',
    'Eagle',
    'Bishopstrow',
    'Temple',
    'Rimington',
    'West Wickham',
    'South Brent',
    'Isleham',
    'Annan',
    'Upton',
    'Sutton Bridge',
    'Plaistow',
    'South Elmsall',
    'Potterspury',
    'Newtonmore',
    'Stepps',
    'Dalbeattie',
    'Keston',
    'Rottingdean',
    'Crosshouse',
    'Cricklewood',
    'Rogerstone',
    'Bovey Tracey',
    'Kingsteignton',
    'Ryton',
    'East Preston',
    'Rayne',
    'Kirk Ella',
    'Sturmer',
    'Stisted',
    'Egton',
    'Appley Bridge',
    'Hartford',
    'Oakford',
    'Paul',
    'Bayble',
    'Pitsford',
    'Rock',
    'Guthrie',
    'Lympsham',
    'Medstead',
    'Midford',
    'Comber',
    'Mid Calder',
    'West Row',
    'Hemswell',
    'Speke',
    'Whitnash',
    'Capenhurst',
    'Itchen',
    'Brompton',
    'Jordanstown',
    'Southwark',
    'Bishop Burton',
    'West Drayton',
    'Wotton Underwood',
    'Dawley',
    'Benson',
    'Upper Slaughter',
    'Worth',
    'Bekesbourne',
    'Stoke',
    'Medmenham',
    'Brookland',
    'Hooke',
    'Nuffield',
    'Failsworth',
    'Newbold',
    'Ballybogy',
    'Annalong',
    'Gifford',
    'Writtle',
    'Newbridge',
    'Newham',
    'Bradfield St George',
    'Lindal in Furness',
    'Ardingly',
    'Mickleton',
    'Ovingdean',
    'Stranmillis',
    'Walford',
    'Askham',
    'Heythrop',
    'Charing Cross',
    'City of Westminster',
    'Llandrillo',
    'Gorseinon',
    'North Newton',
    'Lupton',
    'Egerton',
    'Stoke Newington',
    'North Leigh',
    'Repton',
    'Tendring',
    'Great Billing',
    'Cromwell',
    'Bampton',
    'Marr',
    'Cranford',
    'Wyke',
    'Felsted',
    'Clare',
    'Stoke by Nayland',
    'Thurston',
    'Herriard',
    'Crathorne',
    'Dyce',
    'Kelly',
    'Chilwell',
    'Theale',
    'Belmont',
    'Hambleden',
    'Mossley',
    'Woldingham',
    'Charlton on Otmoor',
    'Brockley Green',
    'Eton',
    'Barley',
    'Ford',
    'Moss',
    'Dean',
    'Ball',
    'Hartpury',
    'Holborn',
    'Bournville',
    'Llanon',
    'Highbury',
    'Humbie',
    'Cardonald',
    'Stow',
    'Anniesland',
    'Havering atte Bower',
    'Greenhead',
    'Handsworth',
    'Waltham',
    'Salthouse',
    'Inchinnan',
    'Longport',
    'Milton',
    'Holywell',
    'Beaufort',
    'Earls Colne',
    'Wealdstone',
    'Weedon',
    'Burwell',
    'Warmington',
    'Surrey',
    'Hazelwood',
    'Wilnecote',
    'Hellesdon',
    'Weald',
    'Reepham',
    'Eaglesfield',
    'Maryhill',
    'Portglenone',
    'Broad Blunsdon',
    'Scapa',
    'Wheaton Aston',
    'Church',
    'Myddle',
    'Penkridge',
    'Woodhouse',
    'Rivington',
    'Stanground',
    'Sidcot',
    'Monkton',
    'Wootton',
    'Spittal',
    'Plymstock',
    'Stowe',
    'Witley',
    'Ratcliffe on Soar',
    'Hadlow',
    'Huish',
    'Paston',
    'Otley',
    'East Down',
    'Cadbury',
    'Alton',
    'Sparsholt',
    'Hilderstone',
    'Nash',
    'Burton',
    'Kingsland',
    'Meesden',
    'Stratfield Mortimer',
    'Sprowston',
    'Moseley',
    'Earlham',
    'Ashby de la Launde',
    'Mayfield',
    'Bettws',
    'Lytchett Minster',
    'Hampton',
    'Roade',
    'Wednesfield',
    'Stanhope',
    'Abersychan',
    'Highway',
    'Cross Keys',
    'Cottered',
    'East Keal',
    'Kyle of Lochalsh',
    'Killin',
    'Great Barton',
    'Dingestow',
    'Headcorn',
    'Plaitford',
    'Whitehouse',
    'Great Amwell',
    'Boreham',
    'Quedgeley',
    'Swanland',
    'Ushaw Moor',
    'Worminghall',
    'Ash',
    'Old Basing',
    'Ystrad Mynach',
    'Moorside',
    'Aldermaston',
    'Beeston',
    'Bainton',
    'Rushden',
    'Shadoxhurst',
    'Hardham',
    'Stoke Prior',
    'Shelford',
    'Boston Spa',
    'Fownhope',
    'Criccieth',
    'Ystradgynlais',
    'Church Leigh',
    'Drymen',
    'Little Milton',
    'Barbaraville',
    'Oakhill',
    'Bierton',
    'Droxford',
    'Ulcombe',
    'Oving',
    'Kinloch Rannoch',
    'Llantrisant',
    'Thakeham',
    'Staverton',
    'Woburn Sands',
    'Essendon',
    'Saint Clears',
    'Crossgates',
    'Lower Beeding',
    'West Grinstead',
    'Bransford',
    'Privett',
    'Woodmancote',
    'Aberfoyle',
    'Douglas',
    'Augher',
    'Hazel Grove',
    'Bloxwich',
    'Linthwaite',
    'Silsoe',
    'Sundon Park',
    'Goudhurst',
    'Brooke',
    'Compton Dundon',
    'Beal',
    'Coppenhall',
    'Garth',
    'Minster',
    'Brayton',
    'Snaresbrook',
    'Norham',
    'Mount Hamilton',
    'Penrice',
    'Westfield',
    'Chilton Trinity',
    'Downend',
    'Urchfont',
    'Winton',
    'Withington',
    'Wickersley',
    'Burford',
    'Childwall',
    'Hitcham',
    'Westleigh',
    'West Langdon',
    'Handcross',
    'Blackheath',
    'Alrewas',
    'Clapham',
    'Harby'
  ],
  Belgium: [
    'Mortsel',
    'Wilrijk',
    'Kontich',
    'Vise',
    'Brussels',
    'Wetteren',
    'Liège',
    'Antwerp',
    'Mechelen',
    'Puurs',
    'Braine-le-Chateau',
    'Namur',
    'Sint-Michiels',
    'Brasmenil',
    'Bruges',
    'Lives-sur-Meuse',
    'Seraing',
    'Pepinster',
    'Beaufays',
    'Hannut',
    'Argenteau',
    'Angleur',
    'Hollogne',
    'Hognoul',
    'Crisnee',
    'Fexhe-Slins',
    'Lixhe',
    'Marche-en-Famenne',
    'Ougree',
    'Awans',
    'Mons',
    'Huy',
    'Ans',
    'Modave',
    'Wanze',
    'Flobecq',
    'Beyne-Heusay',
    'Magnee',
    'Oupeye',
    'Herstal',
    'Grez-Doiceau',
    'Holland',
    'Turnhout',
    'Ghent',
    'Waregem',
    'Heverlee',
    'Kortrijk',
    'Zwijndrecht',
    'Merksem',
    'Sint-Genesius-Rode',
    'Hasselt',
    'Eupen',
    'Wemmel',
    'Vilvoorde',
    'Strombeek-Bever',
    'Kraainem',
    'Wilsele',
    'Duffel',
    'Dworp',
    'Linkebeek',
    'Jemeppe-sur-Meuse',
    'Franiere',
    'Chenee',
    'Jette',
    'Ixelles-Elsene',
    'La Hulpe',
    'Rixensart',
    'Evere',
    'Overijse',
    'Hoeilaart',
    'Sint-Niklaas',
    'Zaventem',
    'Denderleeuw',
    'Destelbergen',
    'Lochristi',
    'Wachtebeke',
    'Kalken',
    'Zaffelare',
    'Gentbrugge',
    'Merelbeke',
    'Appelterre-Eichem',
    'Haaltert',
    'Ninove',
    'Nederhasselt',
    'Outer',
    'Gijzegem',
    'Viane',
    'Idegem',
    'Geraardsbergen',
    'Grimminge',
    'Opbrakel',
    'Nieuwenhove',
    'Nederbrakel',
    'Everbeek',
    'Waasmunster',
    'Temse',
    'Beveren',
    'Melsele',
    'Zottegem',
    'Sint-Amandsberg',
    'Herzele',
    'Erpe',
    'Hamme',
    'Buggenhout',
    'Bornem',
    'Londerzeel',
    'Malderen',
    'Essene',
    'Aalst',
    'Opwijk',
    'Dendermonde',
    'Sint-Gillis-bij-Dendermonde',
    'Baasrode',
    'Lokeren',
    'Eksaarde',
    'De Pinte',
    'Meldert',
    'Moerzeke',
    'Lebbeke',
    'Belsele',
    'Sint-Martens-Latem',
    'Kruibeke',
    'De Klinge',
    'Wondelgem',
    'Oostakker',
    'Zelzate',
    'Stekene',
    'Bazel',
    'Steendorp',
    'Sint-Gillis-Waas',
    'Schendelbeke',
    'Ressegem',
    'Deftinge',
    'Schellebelle',
    'Berlare',
    'Zele',
    'Burcht',
    'Mespelare',
    'Sint-Amands',
    'Heusden',
    'Laarne',
    'Erembodegem',
    'Iddergem',
    'Sint-Lievens-Houtem',
    'Welle',
    'Nieuwerkerken',
    'Herdersem',
    'Asse',
    'Hofstade',
    'Moerbeke',
    'Vlekkem',
    'Overmere',
    'Meerbeke',
    'Pollare',
    'Denderhoutem',
    'Moorsel',
    'Mariakerke',
    'Drongen',
    'Kapellen',
    'Sint-Pauwels',
    'Kemzeke',
    'Eeklo',
    'Evergem',
    'Zwijnaarde',
    'Deurle',
    'Oppuurs',
    'Oudegem',
    'Appels',
    'Schoonaarde',
    'Hingene',
    'Hekelgem',
    'Wichelen',
    'Sint-Denijs-Westrem',
    'Afsnee',
    'Erondegem',
    'Verrebroek',
    'Teralfene',
    'Borsbeke',
    'Elversele',
    'Zeveneken',
    'Beervelde',
    'Merendree',
    'Berchem',
    'Grembergen',
    'Drogenbos',
    'Anderlecht',
    'Perwez',
    'Landelies',
    'Villers-la-Ville',
    'Fleurus',
    'Thuin',
    'Montignies-sur-Sambre',
    'Gembloux',
    'Wavre',
    'Chapelle-lez-Herlaimont',
    'Marcinelle',
    'Presles',
    'Charleroi',
    'Gilly',
    'Lodelinsart',
    'Roux',
    'Labuissiere',
    'Le Tilleul',
    'Cour-sur-Heure',
    'Ham-sur-Heure',
    'Nalinnes',
    'Bierges',
    'Tamines',
    'Chatelineau',
    'Rochefort',
    'Jemelle',
    'Chatelet',
    'Godarville',
    'Forchies-la-Marche',
    'Falisolle',
    'Mont-sur-Marchienne',
    'Obaix',
    'Fontaine-Valmont',
    'Ransart',
    'Trazegnies',
    'Marchienne-au-Pont',
    'Courcelles',
    'Jumet',
    'Haine-Saint-Paul',
    'Peronnes',
    'Leval-Trahegnies',
    'Hyon',
    'Gosselies',
    'Ruiselede',
    'Lede',
    'Mouscron',
    'Tervuren',
    'Dilbeek',
    'Ohain',
    'Waterloo',
    'Groot-Bijgaarden',
    'Sint-Agatha-Berchem',
    'Uccle',
    'Schepdaal',
    'Sint-Pieters-Leeuw',
    'Erps-Kwerps',
    'Beersel',
    'Huizingen',
    'Meise',
    'Grimbergen',
    'Ganshoren',
    'Itterbeek',
    'Zellik',
    'Alsemberg',
    'Boutersem',
    'Tubize',
    'Arlon',
    'Melle',
    'Quievrain',
    "Meslin-l'Eveque",
    'Loupoigne',
    'Frasnes-lez-Buissenal',
    'Erbaut',
    'Barry',
    'Montignies-lez-Lens',
    'Ellezelles',
    'Dison',
    'Soumagne',
    'Lontzen',
    'Beez',
    'Jambes',
    'Etalle',
    'Gesves',
    'Profondeville',
    'Dave',
    'Verviers',
    'Andenne',
    'Jauche',
    'Sprimont',
    'Thimister',
    'Saint-Georges-sur-Meuse',
    'Vielsalm',
    'Zeebrugge',
    'Romsee',
    'Wezembeek-Oppem',
    'Loncin',
    'Manhay',
    'Hamoir',
    'Erpent',
    'Gerpinnes',
    'Transinne',
    'Steenokkerzeel',
    'Woluwe-Saint-Pierre - Sint-Pieters-Woluwe',
    'Brecht',
    'Liedekerke',
    'Wezemaal',
    'Roeselare',
    'Vorselaar',
    'Denderbelle',
    'Deurne',
    'Berchem',
    'Borsbeek',
    'Wuustwezel',
    'Havre',
    'Geel',
    'Mol',
    'Dessel',
    'Balen',
    'Wijnegem',
    'Bonheiden',
    'Aartselaar',
    'Nijlen',
    'Zemst',
    'Sint-Katelijne-Waver',
    'Onze-Lieve-Vrouw-Waver',
    'Keerbergen',
    'Elewijt',
    'Willebroek',
    'Tremelo',
    'Brasschaat',
    'Westerlo',
    'Kalmthout',
    'Stabroek',
    'Veerle',
    'Hoevenen',
    'Wommelgem',
    'Itegem',
    'Booischot',
    'Heist-op-den-Berg',
    'Wiekevorst',
    'Schoten',
    'Schilde',
    'Ekeren',
    'Zoersel',
    'Arendonk',
    'Poppel',
    'Meerle',
    'Vlezenbeek',
    'Kortenberg',
    'Izegem',
    'Leuven',
    'Kampenhout',
    'Gavere',
    'Scherpenheuvel',
    'Paal',
    'Lanaken',
    'Eksel',
    'Zonhoven',
    'Alken',
    'Maasmechelen',
    'Overpelt',
    'Sint-Truiden',
    'Bilzen',
    'Bunsbeek',
    'Zandhoven',
    'Aalter',
    'Diksmuide',
    'Kuurne',
    'Oudenaarde',
    'Deinze',
    'Gistel',
    'Deerlijk',
    'Zedelgem',
    'Hever',
    'Ranst',
    'Oosterzele',
    'Grobbendonk',
    'Pulderbos',
    'Ravels',
    'Borgerhout',
    'Vosselaar',
    'Beerse',
    'Bouwel',
    'Westmalle',
    'Aarschot',
    'Hoboken',
    'Tisselt',
    'Weelde',
    'Kapelle-op-den-Bos',
    'Haacht',
    'Essen',
    'Oud-Turnhout',
    'Boechout',
    'Rumst',
    'Reet',
    'Muizen',
    'Herenthout',
    'Berlaar',
    'Hulshout',
    'Rotselaar',
    'Putte',
    'Herentals',
    'Morkhoven',
    'Retie',
    'Wechelderzande',
    'Beerzel',
    'Halle',
    'Lembeek',
    'Wolvertem',
    'Lennik',
    'Sterrebeek',
    'Humbeek',
    'Beigem',
    'Ternat',
    'Lot',
    'Merchtem',
    'Etterbeek',
    'Herne',
    'Vollezele',
    'Tienen',
    'Hoegaarden',
    'Woluwe-Saint-Lambert',
    'Buizingen',
    'Forest',
    'Nossegem',
    'Schaarbeek',
    'Gooik',
    'Galmaarden',
    'Machelen',
    'Werchter',
    'Herent',
    'Olen',
    'Lille',
    'Gierle',
    'Kasterlee',
    'Waarloos',
    'Tongerlo',
    'Rijmenam',
    'Hombeek',
    'Leest',
    'Boortmeerbeek',
    'Muizen',
    'Lint',
    'Edegem',
    'Hove',
    'Koningshooikt',
    'Anzegem',
    'Ingooigem',
    'Tielt',
    'Meulebeke',
    'Ingelmunster',
    'Dentergem',
    'Veldegem',
    'Middelkerke',
    'Westende',
    'Ostend',
    'Oostrozebeke',
    'Ledegem',
    'Assenede',
    'Jabbeke',
    'Oudenburg',
    'Nevele',
    'Kaprijke',
    'Lovendegem',
    'Rumbeke',
    'Moorslede',
    'Zonnebeke',
    'Dadizele',
    'Beveren',
    'Maldegem',
    'Adegem',
    'Wingene',
    'Sint-Laureins',
    'Knesselare',
    'Waarschoot',
    'Park',
    'Nameche',
    'Yvoir',
    'Molenbeek-Saint-Jean',
    'Anseroeul',
    'Ittre',
    'Chievres',
    'Manage',
    'Arsimont',
    'La Louvière',
    'Sirault',
    'Kain',
    'Dampremy',
    'Corroy-le-Grand',
    'Jemeppe-sur-Sambre',
    'Tournai',
    'Bonlez',
    'Chercq',
    'Wasmes',
    'Chaumont-Gistoux',
    'Court-Saint-Etienne',
    'Nodebais',
    'Nethen',
    'Wanfercee-Baulet',
    'Bousval',
    'Mont-Saint-Guibert',
    'Lommel',
    'Niel',
    'Boom',
    'Hofstade',
    'Ciney',
    'Wepion',
    'Celles',
    'Vremde',
    'Goor',
    'Jupille-sur-Meuse',
    'Waremme',
    'Rocourt',
    'Montegnee',
    'Grivegnee',
    'Saint-Leger',
    'Faimes',
    'Loppem',
    'Ronse',
    'Assebroek',
    'Warneton',
    'Fleron',
    'Massemen',
    'Herseaux',
    'Landegem',
    'Aartrijke',
    'Esneux',
    'Bastogne',
    'Genval',
    'Rosieres',
    'Schelle',
    'Helchteren',
    'Strepy-Bracquegnies',
    'Oud-Heverlee',
    'Hollain',
    'Heusden',
    'Bissegem',
    'Engis',
    'Incourt',
    'Frameries',
    'Burst',
    'Couvin',
    'Sint-Stevens-Woluwe',
    'Nazareth',
    'Estaimpuis',
    'Auderghem',
    'Fayt-lez-Manage',
    'Lambermont',
    'Saint-Symphorien',
    'Houdeng-Goegnies',
    'Cuesmes',
    'Nil Saint-Vincent',
    'Binche',
    'Pont-a-Celles',
    'Haut-Ittre',
    'Relegem',
    'Andrimont',
    'Butgenbach',
    'Wierde',
    'Clavier',
    'Erezee',
    'Ferrieres',
    'Wanne',
    'Torhout',
    'Bellem',
    'Ohey',
    'Marchin',
    'Villers-sur-Semois',
    'Theux',
    'Bavikhove',
    'Tessenderlo',
    'Moha',
    'Henri-Chapelle',
    'Alleur',
    'Haccourt',
    'Richelle',
    'Horion-Hozemont',
    'Trooz',
    'Kessel-Lo',
    'Peer',
    'Marais',
    'Anderlues',
    'Tertre',
    'Sankt Vith',
    'Morlanwelz-Mariemont',
    'Bocholt',
    'Hamont',
    'Achel',
    'Genk',
    'As',
    'Bree',
    'Opglabbeek',
    'Kinrooi',
    'Maaseik',
    'Begijnendijk',
    'Ham',
    'Dilsen',
    'Stokkem',
    'Elen',
    'Opgrimbie',
    'Hoeselt',
    'Riemst',
    'Tongeren',
    'Kaulille',
    'Baal',
    'Tielt',
    'Lubbeek',
    'Nieuwrode',
    'Kortrijk-Dutsel',
    'Gingelom',
    'Borgloon',
    'Nieuwerkerken',
    'Klein-Gelmen',
    'Heers',
    'Zutendaal',
    'Rosmeer',
    'Munsterbilzen',
    'Halen',
    'Leopoldsburg',
    'Zolder',
    'Lummen',
    'Heppen',
    'Neerpelt',
    'Hechtel',
    'Geetbets',
    'Zoutleeuw',
    'Attenhoven',
    'Landen',
    'Budingen',
    'Kersbeek-Miskom',
    'Langdorp',
    'Ramsel',
    'Rillaar',
    'Herselt',
    'Diest',
    'Houthalen',
    'Sint-Lambrechts-Herk',
    'Boorsem',
    'Gruitrode',
    'Neerlinter',
    'Hoeleden',
    'Blanden',
    'Molenstede',
    'Averbode',
    'Zichem',
    'Kuringen',
    'Diepenbeek',
    'Opoeteren',
    'Opitter',
    'Meeuwen',
    'Herk-de-Stad',
    'Melkwezer',
    'Bekkevoort',
    'Haasrode',
    'Meensel-Kiezegem',
    'Wellen',
    'Velm',
    'Ulbeek',
    'Kozen',
    'Holsbeek',
    'Betekom',
    'Pellenberg',
    'Linden',
    'Testelt',
    'Stevoort',
    'Kortessem',
    'Beringen',
    'Beverlo',
    'Koersel',
    'Schulen',
    'Meerlaar',
    'Kwaadmechelen',
    'Ham',
    'Oostham',
    'Rekem',
    'Eigenbilzen',
    'Vliermaalroot',
    'Kermt',
    'Vliermaal',
    'Wolfsdonk',
    'Assent',
    'Glabbeek',
    'Baarle-Hertog',
    'Poederlee',
    'Lichtaart',
    'Hemiksem',
    'Sinaai',
    'Minderhout',
    'Merksplas',
    'Hoogstraten',
    'Meersel',
    'Oelegem',
    'Kessel',
    'Rijkevorsel',
    'Pulle',
    'Heindonk',
    'Eppegem',
    'Schriek',
    'Hallaar',
    'Loenhout',
    'Berendrecht',
    'Terhagen',
    'Wespelaar',
    'Tildonk',
    'Blankenberge',
    'Buvrinnes',
    'Bierset',
    'Saive',
    'Remicourt',
    'Hodeige',
    'Braives',
    'Flemalle-Grande',
    'Ramet',
    'Vivegnis',
    'Flemalle-Haute',
    'Neuville-en-Condroz',
    'Boncelles',
    'Saint-Nicolas',
    'Vottem',
    'Juprelle',
    'Bassenge',
    'Melen',
    'Saint-Remy',
    'Fexhe-le-Haut-Clocher',
    'Amay',
    'Comblain-au-Pont',
    'Thulin',
    'Aubel',
    'Goutroux',
    'Herlaimont',
    'Chastre',
    'Viesville',
    'Loverval',
    'Couillet',
    'Luttre',
    'Velaine-sur-Sambre',
    'Thimeon',
    'Auvelais',
    'Pont-de-Loup',
    'Farciennes',
    'Fontaine',
    "Fontaine-l'Eveque",
    'Isnes',
    'Wagnelee',
    'Louvain-la-Neuve',
    "Braine-l'Alleud",
    'Lillois-Witterzee',
    'Herve',
    'Ath',
    'Casteau',
    'Bernissart',
    'Quevaucamps',
    'Floreffe',
    'Bovesse',
    'Malonne',
    'Saint-Servais',
    'Membach',
    'Spa',
    'Lambermont',
    'Aubange',
    'Mettet',
    'Hotton',
    'Hampteau',
    'Gourdinne',
    'Xhoris',
    'Filot',
    'Ampsin',
    'Naast',
    'Nivelles',
    'Houtain-le-Val',
    'Remersdaal',
    'Anhée',
    'Messancy',
    'Bressoux',
    'Vedrin',
    'Roeulx',
    'Aywaille',
    'Sclayn',
    'Bilstain',
    'Grand-Rechain',
    'Eghezee',
    'Ecaussinnes-Lalaing',
    'Braine-le-Comte',
    'Baisy-Thy',
    'Stembert',
    'Rhisnes',
    'Battice',
    'Blegny',
    'Donceel',
    'Chaudfontaine',
    'Limbourg',
    'Embourg',
    'Haneffe',
    'Bredene',
    'Oostkamp',
    'Wevelgem',
    'Marke',
    'Staden',
    'Knokke',
    'Oordegem',
    'Vance',
    'Emptinne',
    'Gelrode',
    'Borchtlombeek',
    'Eke',
    'Koksijde',
    'Wolkrange',
    'Duisburg',
    'Vaux-sous-Chevremont',
    'Olne',
    'Verlaine',
    'Flawinne',
    'Florenville',
    'Kettenis',
    'Walhorn',
    'Malmedy',
    'Montzen',
    'Izel',
    'Tilff',
    'Astenet',
    'Lisogne',
    'Natoye',
    'Cerexhe-Heuseux',
    'Nandrin',
    'Belle Fontaine',
    'Jemappes',
    'Saint-Ghislain',
    'Jodoigne',
    'Baudour',
    'Villers-le-Bouillet',
    'Coret',
    'Saint-Gilles',
    'Quaregnon',
    'Lessines',
    'Flenu',
    'Boussu',
    'Hermee',
    'Plainevaux',
    'Houffalize',
    'Gellik',
    'Leerbeek',
    'Neerharen',
    'Ghlin',
    'Limal',
    'Pipaix',
    'Ottignies',
    'Limelette',
    'Hornu',
    'Dour',
    'Leuze',
    'Hautrage',
    'Jurbise',
    'Leernes',
    'Sart Melin',
    'Watermael-Boitsfort',
    'Xhendremael',
    'Huldenberg',
    'Warsage',
    'Fallais',
    'Baelen',
    'Othee',
    'Stavelot',
    'Ressaix',
    'Estinnes-au-Val',
    'Nimy',
    'Mont-Sainte-Aldegonde',
    'Obourg',
    'Maisieres',
    'Waudrez',
    'Warquignies',
    'Awirs',
    'Bouillon',
    'Avelgem',
    'De Panne',
    'Zulte',
    'Wortegem',
    'Eernegem',
    'Ruisbroek',
    'Humbeek',
    'Sart-lez-Spa',
    'Ciplet',
    'Damme',
    'Ertvelde',
    'Agimont',
    'Barchon',
    'Virton',
    'Heusy',
    'Nokere',
    'Vichte',
    'Vlierzele',
    'Laken',
    'Ways',
    'Plancenoit',
    'Charneux',
    'Walhain',
    'Hooglede',
    'Beernem',
    'Colfontaine',
    'Sart-Dames-Avelines',
    'Ledeberg',
    'Diegem',
    'Tohogne',
    'Bierbeek',
    'Sainte-Ode',
    'Chiny',
    'Dorinne',
    'Brugelette',
    'Dottignies',
    'Oostende',
    'Hamme-Mille',
    'Pamel',
    'Peutie',
    'Vossem',
    'Veltem-Beisem',
    'Everberg',
    'Leefdaal',
    'Bertem',
    'Neerijse',
    'Wijgmaal',
    'Saint-Jean-Geest',
    'Winksele',
    'Korbeek-Dijle',
    'Sint-Agatha-Rode',
    'Ruisbroek',
    'Elingen',
    'Brussegem',
    'Korbeek-Lo',
    'Oostmalle',
    'Meerhout',
    'Broechem',
    'Emblem',
    'Ramsdonk',
    'Blaasveld',
    'Bevel',
    "'s-Gravenwezel",
    'Recht',
    'Latinne',
    'Hermalle-sous-Argenteau',
    'Waleffe Saint-Georges',
    'Enghien',
    'Thisnes',
    'Heure-le-Romain',
    'Housse',
    'Beauvechain',
    'Glimes',
    'Souvret',
    'Monceau-sur-Sambre',
    'Wangenies',
    'Welkenraedt',
    'Geer',
    'Goe',
    'Assesse',
    'Voroux-Goreux',
    'Momalle',
    'Florennes',
    'Morialme',
    "Ecaussinnes-d'Enghien",
    'Belgrade',
    'Ligny',
    'Nouvelles',
    'Dalhem',
    'Dinant',
    'Ham-sur-Sambre',
    'Carnieres',
    'Ophain',
    'Bouge',
    'Basecles',
    'Stoumont',
    'Lincent',
    'Ensival',
    'Dongelberg',
    'Louveigne',
    'Orp-le-Grand',
    'Soignies',
    'Saint-Josse-ten-Noode',
    'Haren',
    'Saint-Sauveur',
    'Gages',
    'Hensies',
    'Gobard',
    'Herinnes',
    'Sombreffe',
    'Chaussee-Notre-Dame-Louvignies',
    'Tongre-Notre-Dame',
    'Rode',
    'Mariekerke',
    'Maurage',
    'Sleidinge',
    'Mariembourg',
    'La Hestre',
    'Rance',
    "Bois-d'Haine",
    'Velaines',
    'Renlies',
    'Knokke-Heist',
    'Lippelo',
    'Bertrix',
    'Houtaing',
    'Rebaix',
    'Sart-en-Fagne',
    'Havay',
    'Attre',
    'Saint-Mard',
    'Retinne',
    'Oedelem',
    'Wieze',
    'Parike',
    'Zandbergen',
    'Mere',
    'Kruishoutem',
    'Zingem',
    'Schorisse',
    'Etikhove',
    'Klemskerke',
    'Zuienkerke',
    'Menen',
    'Westouter',
    'Poperinge',
    'Kortemark',
    'Astene',
    'Heist',
    'Dudzele',
    'Kanegem',
    'Oeselgem',
    'Harelbeke',
    'Kortenaken',
    'Rummen',
    'Letterhoutem',
    'Toernich',
    'Jamioulx',
    'Fosse des Loups',
    'Ster',
    'Tilleur',
    'Ellignies-Sainte-Anne',
    'Couthuin',
    'Seilles',
    'Anthisnes',
    'Varsenare',
    'De Haan',
    'Ruien',
    'Zwartenbroek',
    'Petegem',
    'Stene',
    'Sijsele',
    'Vlissegem',
    'Ursel',
    'Zomergem',
    'Hansbeke',
    'Vinderhoute',
    'Bassevelde',
    'Watervliet',
    'Oosteeklo',
    'Nukerke',
    'Werken',
    'Bovekerke',
    'Koekelare',
    'Bavegem',
    'Scheldewindeke',
    'Sint-Andries',
    'Ypres',
    'Elverdinge',
    'Langemark',
    'Zwevegem',
    'Aalbeke',
    'Heule',
    'Markegem',
    'Ooigem',
    'Houthulst',
    'Boezinge',
    'Woesten',
    'Reninge',
    'Alveringem',
    'Lauwe',
    'Wervik',
    'Comines',
    'Houthem',
    'Rekkem',
    'Ploegsteert',
    'Lichtervelde',
    'Vladslo',
    'Ruddervoorde',
    'Meigem',
    'Wakken',
    'Sint-Baafs-Vijve',
    'Wielsbeke',
    'Aarsele',
    'Oostvleteren',
    'Passendale',
    'Geluwe',
    'Ichtegem',
    'Nieuwpoort',
    'Nieuwpoort',
    'Zwevezele',
    'Kachtem',
    'Pervijze',
    'Desselgem',
    'Loker',
    'Voormezele',
    'Moen',
    'Tiegem',
    'Otegem',
    'Heestert',
    'Ramskapelle',
    'Sint-Maria-Latem',
    'Middelburg',
    'Outrijve',
    'Lendelede',
    'Gullegem',
    'Pittem',
    'Zarren',
    'Overheide',
    'Molenbeek',
    'Grand-Reng',
    'Erquelinnes',
    'Stree',
    'Chimay',
    'Feluy',
    'Sablon',
    'Ghislenghien',
    'Post',
    'Melsbroek',
    'Sint-Pieters op de Dijk',
    'Grand',
    'Libramont',
    'Veurne',
    'Vaux',
    'Gentinnes',
    'Orbais',
    'Grand-Leez',
    'Biercee',
    'Seneffe',
    'Thynes',
    'Warchin',
    'Okegem',
    'Ardooie',
    'Leffinge',
    'Avekapelle',
    'Leisele',
    'Adinkerke',
    'Lissewege',
    'Serskamp',
    'Sint-Kornelis-Horebeke',
    'Impe',
    'Lotenhulle',
    'Haasdonk',
    'Lanklaar',
    'Schalkhoven',
    'Thy-le-Chateau',
    'Lobbes',
    'Beloeil',
    'Gouy-lez-Pieton',
    'La Calamine',
    'Chevron',
    'Saintes',
    'Neder-Over-Heembeek',
    'Oppem',
    'Arquennes',
    'Banneux',
    'Burdinne',
    'Marche-lez-Ecaussinnes',
    'Heffen',
    'Houtvenne',
    'Lier',
    'Schaffen',
    'Kerkhove',
    'Gits',
    'Wenduine',
    'Dranouter',
    'Poelkapelle',
    'Kerksken',
    'Quenast',
    'Arbrefontaine',
    'Forrieres',
    'Freylange'
  ],
  'United Arab Emirates': [
    'Abu Dhabi',
    'Abu al Abyad',
    'Adhen',
    'Ajman',
    'Al Ain',
    'Al Ajban',
    'Al Aryam',
    'Al Awir',
    'Al Badiyah',
    'Al Bataeh',
    'Al Bithnah',
    'Al Faqa',
    'Al Halah',
    'Al Hamraniyah',
    'Al Hamriyah',
    'Al Jazirah Al Hamra',
    'Al Jeer',
    'Al Khawaneej',
    'Al Lisaili',
    'Al Madam',
    'Al Manama',
    'Al Mirfa',
    'Al Qor',
    'Al Qusaidat',
    'Al Rafaah',
    'Al Rashidya',
    'Al Shuwaib',
    'Al Yahar',
    'Ar-Rams',
    'Asimah',
    'Dadna',
    'Dalma',
    'Dhaid',
    'Dibba Al-Fujairah',
    'Dibba Al-Hisn',
    'Digdaga',
    'Dubai',
    'Falaj Al Mualla',
    'Fujairah',
    'Ghalilah',
    'Ghayathi',
    'Ghub',
    'Habshan',
    'Hatta',
    'Huwaylat',
    'Jebel Ali',
    'Kalba',
    'Khatt',
    'Khor Fakkan',
    'Khor Khwair',
    'Lahbab',
    'Liwa Oasis',
    'Madinat Zayed',
    'Marawah',
    'Masafi',
    'Masfut',
    'Mirbah',
    'Mleiha',
    'Nahil',
    'RAK City',
    'Ruwais',
    "Sha'am",
    'Sharjah',
    'Sila',
    'Sir Bani Yas',
    'Sweihan',
    'Umm Al Quwain',
    'Wadi Shah',
    'Zubarah'
  ],
  Kazakhstan: [
    'Almaty',
    'Oral',
    'Kostanay',
    'Qaskeleng',
    'Karagandy',
    'Temirtau',
    'Astana',
    'Taldykorgan',
    'Talghar',
    'Pavlodar',
    'Ekibastuz',
    'Atyrau',
    'Kökshetaū',
    'Aksay',
    'Taraz',
    'Petropavl',
    'Shymkent',
    'Serebryansk',
    'Ridder',
    'Shemonaīkha',
    'Semey',
    'Aqsū',
    'Kyzylorda',
    'Aqtöbe',
    'Khromtaū',
    'Aqtau',
    'Taldyk',
    'Zhangaözen',
    'Karagandy',
    'Dzhezkazgan',
    'Baikonur',
    'Kyzyl',
    'Koktem',
    'Dostyk',
    'Sonaly',
    'Kentau',
    'Rudnyy',
    "Turgen'",
    'Esil',
    'Vostok',
    'Qaraghandy',
    'Uritskiy',
    'Kazakh',
    'Stepnogorsk',
    'Ust-Kamenogorsk',
    'Līsakovsk',
    'Aksoran',
    'Zyryanovsk',
    'Aqtas',
    'Börili',
    'Lomonosovka',
    'Turkestan'
  ],
  Portugal: [
    'Amarante',
    'Sanfins do Douro',
    'Vilarinho das Paranheiras',
    'Chaves',
    'Boticas',
    'Braganca',
    'Vila Real',
    'Miranda do Douro',
    'Carrazedo',
    'Vilarinho de Arcos',
    'Mirandela',
    'Macedo de Cavaleiros',
    'Vinhais',
    'Peso da Régua Municipality',
    'Lamego',
    'Tabuaco',
    'Penafiel',
    'Dalvares',
    'Santa Marta de Penaguiao',
    'Torre de Moncorvo',
    'Viana do Castelo',
    'Moncao',
    'Caminha',
    'Paredes de Coura',
    'Braga',
    'Ponte da Barca',
    'Vila Praia de Ancora',
    'Castelo',
    'Arcos de Valdevez',
    'Paços de Ferreira',
    'Maia',
    'Castelo de Paiva',
    'Santo Tirso',
    'Paredes',
    'Carvalhosa',
    'Porto',
    'Sobrado de Paiva',
    'Sobrosa',
    'Lousada',
    'Marco',
    'Guimarães',
    'Vila Nova de Gaia',
    'Olival',
    'Ponte de Lima',
    'Paredes da Beira',
    'Tarouca',
    'Mesao Frio',
    'Coimbra',
    'Mira',
    'Cernache',
    'Condeixa-a-Nova',
    'Alfarelos',
    'Soure Municipality',
    'Ceira',
    'Montemor-o-Velho',
    'Coimbra',
    'Granja do Ulmeiro',
    'Carapinheira',
    'Sao Joao do Campo',
    'Miranda do Corvo',
    'Viseu',
    'Arganil',
    'Tondela',
    'Castelejo',
    'Águeda Municipality',
    'Campia',
    'Nelas',
    'Satao',
    'Sao Vicente de Lafoes',
    'Parada de Gonta',
    'Mangualde',
    'Santa Comba Dao',
    'Mamouros',
    'Murtede',
    'Cantanhede',
    'Mortagua',
    'Oliveira do Bairro',
    'Mealhada',
    'Poiares',
    'Anadia',
    'Vilarinho do Bairro',
    'Ovar',
    'Oliveira de Azeméis',
    'Esmoriz',
    'Sao Roque',
    'Cucujaes',
    'Feira',
    'Sao Joao da Madeira',
    'Arouca',
    'Vale de Cambra',
    'Santiago de Riba-Ul',
    'Aveiro',
    'Vagos',
    'Aguada de Cima',
    'Palhaca',
    'Estarreja',
    'Mourisca do Vouga',
    'Arrifana',
    'Sao Joao de Ver',
    'Romariz',
    'Sao Joao',
    'Vale',
    'Caldas de Sao Jorge',
    'Nogueira do Cravo',
    'Matosinhos Municipality',
    'Vieira de Leiria',
    'Porto',
    'Baguim do Monte',
    'Guarda',
    'Seia',
    'Gouveia',
    'Vilar Formoso',
    'Trancoso',
    'Lagarinhos',
    'Sao Vicente da Beira',
    'Silvares',
    'Castelo Branco',
    'Lisbon',
    'Covilha',
    'Fundao',
    'Paul',
    'Carvalho',
    'Valverde',
    'Serta',
    'Santarém',
    'Vale da Pinta',
    'Rio Maior',
    'Almeirim',
    'Ponte de Sor',
    'Vila Cha de Ourique',
    'Cartaxo',
    'Pinheiro de Azere',
    'Lousã',
    'Penacova',
    'Ferrel',
    'Caldas da Rainha',
    'Nadadouro',
    'Benedita',
    'Atouguia da Baleia',
    'Alcobaca',
    'Nazaré',
    'Peniche',
    'Obidos',
    'Casal da Areia',
    'Tomar',
    'Abrantes',
    'Entroncamento',
    'Minde',
    'Ourem',
    'Amiaes de Cima',
    'Constancia',
    'Alcanena',
    'Salvaterra de Magos',
    'Mafra',
    'Azueira',
    'Torres Vedras',
    'Ericeira',
    'Sobreiro',
    'Sobral de Monte Agraco',
    'Carregado',
    'Samora Correia',
    'Vila Franca de Xira',
    'Sacavem',
    'Alenquer',
    'Cercal',
    'Pombal',
    'Leiria',
    'Figueira da Foz Municipality',
    'Avelar',
    'Costa de Lavos',
    'Carrico',
    'Alvaiazere',
    'Marinha Grande',
    'Porto de Mos',
    'Calvaria de Cima',
    'Maceira',
    'Juncal',
    'Souto da Carpalhosa',
    'Pedreiras',
    'Garruchas',
    'Batalha',
    'Pataias',
    'Amor',
    'Martinganca',
    'Serpins',
    'Carvalhal',
    'Murteira',
    'Obidos',
    'Vila Nova da Barquinha',
    'Pontevel',
    'Praia do Ribatejo',
    'Torres Novas',
    'Aveiras de Cima',
    'Lavos',
    'Figueiro dos Vinhos',
    'Vermoil',
    'Graca',
    'Zambujal',
    'Regueira de Pontes',
    'Benavente',
    'Guia',
    'Gondomar',
    'Espinho',
    'Senhora da Hora',
    'Soito',
    'Vila Nova de Famalicao',
    'Riba de Ave',
    'Marinha',
    'Porto Alto',
    'Sao Romao do Coronado',
    'Portimao',
    'Cesar',
    'Setúbal',
    'Almada',
    'Massama',
    'Alcochete',
    'Felgueiras',
    'Vila do Conde',
    'Rio Tinto',
    'Mem Martins',
    'Oeiras',
    'Cacem',
    'Queluz',
    'Alfragide',
    'Amora',
    'Moita',
    'Pedroucos',
    'Vizela',
    'Barreiro',
    'Faro',
    'Bouca',
    'Barcelos',
    'Bombarral',
    'Celorico de Basto',
    'Angra do Heroísmo',
    'Pinhal Novo',
    'Oliveira do Douro',
    'Silveira',
    'Moscavide',
    'Rio de Mouro',
    'Ferradosa',
    'Turcifal',
    'Prime',
    'Vizela',
    'Amadora',
    'Cacela',
    'Palmela',
    'Macieira da Lixa',
    'Ponta Delgada',
    'Funchal',
    'Estoril',
    'Beja',
    'Recarei',
    'Cascais',
    'Povoa de Lanhoso',
    'Almancil',
    'Amoreira',
    'Bucelas',
    'Albufeira',
    'Quejas',
    'Póvoa de Varzim',
    'Odivelas',
    'Valongo',
    'Caparica',
    'Loures',
    'Gafanha da Nazare',
    'Amares',
    'Carnaxide',
    'Colmeias',
    'Fafe',
    'Povoa de Santa Iria',
    'Caxias',
    'Vila Nova',
    'Gandra',
    'Leca da Palmeira',
    'Acores',
    'Varzea do Douro',
    'Vale',
    'Moure',
    'Roriz',
    'Vialonga',
    'Seixal',
    'Paco de Arcos',
    'Alcabideche',
    'Alges',
    'Sintra',
    'Monte Estoril',
    'Galiza',
    'Lourinhã',
    'Seixal',
    'Olivais',
    'Alhos Vedros',
    'Montijo',
    'Loule',
    'Tavira Municipality',
    'Sao Bras',
    'Quarteira',
    'Tercena',
    'Agualva',
    'Pevidem',
    'Rana',
    'Praia do Almoxarife',
    'Parede',
    'Sao Pedro do Estoril',
    'Sao Domingos de Rana',
    'Famoes',
    'Costa de Caparica',
    'Lagos',
    'Lagoa',
    'Parchal',
    'Olhao',
    'Cavaleiros',
    'Santa Iria da Azoia',
    'Quinta',
    'Marinhais',
    'Boliqueime',
    'Palhais',
    'Sobralinho',
    'Samouco',
    'Porto Salvo',
    'Frielas',
    'Linda a Velha',
    'Portela',
    'Alhandra',
    'Santo Antao do Tojal',
    'Carcavelos',
    'Silves',
    'Santo Antonio',
    'Vila Nogueira de Azeitao',
    'Castanheira do Ribatejo',
    'Porto da Cruz',
    'Santo Antonio da Charneca',
    'Pinhal',
    'Sarilhos Grandes',
    'Pinheiro',
    'Sesimbra',
    'Trancoso de Baixo',
    'Lousa',
    'Alverca',
    'Montemor-o-Novo',
    'Evora',
    'Alandroal',
    'Redondo',
    'Sao Juliao do Tojal',
    'Torre da Marinha',
    'Ramada',
    'Elvas',
    'Dona Maria',
    'Alter do Chao',
    'Manique de Baixo',
    'Lagoa',
    'Colares',
    'Charneca',
    'Venda do Pinheiro',
    'Ourique',
    'Estremoz',
    'Sao Joao dos Montes',
    'Milharado',
    'Pontinha',
    'Sao Bras de Alportel',
    'Alportel',
    'Almodovar',
    'Alfornelos',
    'Rinchoa',
    'Aldeia de Paio Pires',
    'Barcarena',
    'Caldas de Vizela',
    'Aguiar da Beira Municipality',
    'Anta',
    'Sao Mamede de Infesta',
    'Lourical',
    'Runa',
    'Penha Garcia',
    'Atalaia',
    'Zibreira',
    'Reguengo Grande',
    'Ilhavo',
    'Madeira',
    'Alcains',
    'Chao de Couce',
    'Vila Franca do Rosario',
    'Golega',
    'Arruda dos Vinhos',
    'Alpiarca',
    'Gandara dos Olivais',
    'Ponte de Vagos',
    'Boavista',
    'Lago',
    'Câmara de Lobos',
    'Vila Nova',
    'Cabecudos',
    'Felgueiras',
    'Povoa do Forno',
    'Canical',
    'Canico',
    'Santa Cruz',
    'Oliveira',
    'Cortegaca',
    'Pragal',
    'Corroios',
    'Ferro',
    'Santo Andre',
    'Baleia',
    'Sanguedo',
    'Arraiolos',
    'Vendas Novas',
    'Vidigueira',
    'Sao Francisco',
    'Carvoeiro',
    'Pardilho',
    'Valado de Frades',
    'Assumar',
    'Valega',
    'Argoncilhe',
    'Mozelos',
    'Belmonte',
    'Roque',
    'Portel',
    'Canedo',
    'Silva',
    'Portalegre',
    'Oliveira do Hospital',
    'Sabugal',
    'Quinta do Anjo',
    'Gulpilhares',
    'Lordelo',
    'Queluz de Baixo',
    'Malveira',
    'Taveiro',
    'Linho',
    'Damaia',
    'Cruz Quebrada',
    'Canecas',
    'Lavradio',
    'Vilar do Pinheiro',
    'Machico',
    'Estombar',
    'Monchique',
    'Mexilhoeira Grande',
    'Castro Verde Municipality',
    'Estoi',
    'Poceirao',
    'Pegoes Velhos',
    'Santa Barbara de Nexe',
    'Pero Pinheiro',
    'Almargem',
    'Bicesse',
    'Campo Maior',
    'Praia do Carvoeiro',
    'Algoz',
    'Benafim',
    'Pechao',
    'Santana',
    'Casal',
    'Cano',
    'Armacao de Pera',
    'Castro Marim',
    'Altura',
    'Alvarinhos',
    'Ponta do Sol',
    'Porto Moniz',
    'Tires',
    'Lajes',
    'Igreja Nova',
    'Abobada',
    'Cabeco de Vide',
    'Cacilhas',
    'Vila Alva',
    'Tunes',
    'Santa Clara-a-Velha',
    'Cabrela',
    'Nisa',
    'Arronches',
    'Olhos de Agua',
    'Ribeira Grande',
    'Ermidas',
    'Santiago do Cacem',
    'Sines Municipality',
    'Serpa',
    'Aljustrel',
    'Vila Nova',
    'Cuba',
    'Grandola',
    'Santiago',
    'Campanario',
    'Vila de Porto Santo',
    'Viana do Alentejo',
    'Monsaraz',
    'Penteado',
    'Sobreda',
    'Praia da Vitoria',
    'Reguengos de Monsaraz',
    'Sao Joao das Lampas',
    'Paderne',
    'Montelavar',
    'Canhas',
    'Arco da Calheta',
    'Monforte',
    'Vila Real de Santo António',
    'Porto',
    'Lourel de Baixo',
    'Souzel',
    'Bobadela',
    'Cidade',
    'Algueirao',
    'Belas',
    'Moura',
    'Moura',
    'Outeiro',
    'Campinho',
    'Vimieiro',
    'Fogueteiro',
    'Odemira',
    'Escoural',
    'Povoa da Galega',
    'Buraca',
    'Borba',
    'Aljezur',
    'Poco Barreto',
    'Canedo',
    'Vila Verde',
    'Trofa',
    'Canelas',
    'Marco de Canaveses',
    'Santa Marta',
    'Felgueiras',
    'Vermelha',
    'Vidago',
    'Travanca',
    'Grijo',
    'Lavra',
    'Alfena',
    'Azurem',
    'Creixomil',
    'Godim',
    'Escapaes',
    'Leca do Bailio',
    'Guimaraes',
    'Brito',
    'Porto',
    'Esposende',
    'Fao',
    'Joane',
    'Vilela',
    'Pedroso',
    'Granja',
    'Vila Cha',
    'Muro',
    'Sendim',
    'Paredes',
    'Moreira de Conegos',
    'Lousado',
    'Forte',
    'Vila Nova',
    'Massarelos',
    'Sao Marcos',
    'Manique do Intendente',
    'Sardoal',
    'Bico',
    'Trafaria',
    'Malveira da Serra',
    'Fanzeres',
    'Bemfica',
    'Sobrado',
    'Sao Felix da Marinha',
    'Sul',
    'Praia de Mira',
    'Sangalhos',
    'Arcozelo',
    'Infesta',
    'Baguim',
    'Caldas das Taipas',
    'Aldeia dos Fernandes',
    'Penela',
    'Povoa de Santo Adriao',
    'Freamunde',
    'Camarate',
    'Talaide',
    'Polima',
    'Sao Joao da Talha',
    'Entroncamento',
    'Riachos',
    'Fátima',
    'Mira',
    'Azoia',
    'Alcoentre',
    'Foz do Sousa',
    'Valbom',
    'Rio de Moinhos',
    'Milhazes',
    'Santa Catarina',
    'Proenca-a-Nova',
    'Teixoso',
    'Canidelo',
    'Santiago',
    'Piedade',
    'Vilar de Perdizes',
    'Montes',
    'Cinfaes',
    'Seroa',
    'Vila Boa de Quires',
    'Touguinha',
    'Fradelos',
    'Ribeirao',
    'Ferreira',
    'Cabeceiras de Basto',
    'Alcanede',
    'Vale de Acor',
    'Gradil',
    'Sao Pedro da Cadeira',
    'Encarnacao',
    'Folgados',
    'Linhaceira',
    'Igreja Nova',
    'Santa Catarina da Serra',
    'Figueira de Lorvao',
    'Vila Nova de Cerveira',
    'Valenca',
    'Mocarria',
    'Constance',
    'Albergaria-a-Velha',
    'Oia',
    'Monsanto',
    'Neiva',
    'Amorim',
    'Agucadoura',
    'Povoa',
    'Povoa',
    'Nine',
    'Macas de Caminho',
    'Dossaos',
    'Modivas',
    'Rebordosa',
    'Ota',
    'Margaride',
    'Baião',
    'Nespereira',
    'Lourosa',
    'Paraiso',
    'Avintes',
    'Prazins',
    'Basto',
    'Salto',
    'Ronfe',
    'Mesao Frio',
    'Guardizela',
    'Gemunde',
    'Jovim',
    'Prado',
    'Alvelos',
    'Tamel',
    'Santiago da Guarda',
    'Olivais',
    'Landim',
    'Vila Nova de Paiva',
    'Vieira do Minho',
    'Montalvo',
    'Marteleira',
    'Barrosas',
    'Avanca',
    'Darque',
    'Pinheiro da Bemposta',
    'Nogueira',
    'Soalheira',
    'Tocha',
    'Sandim',
    'Cadaval',
    'Parada do Bispo',
    'Sever do Vouga',
    'Calvao',
    'Campo',
    'Cavaloes',
    'Avidos',
    'Quinchaes',
    'Negrelos',
    'Lagoa',
    'Vilar do Paraiso',
    'Ponte',
    'Alijó Municipality',
    'Arazede',
    'Ponta',
    'Arvore',
    'Dois Portos',
    'Samil',
    'Vale',
    'Beira',
    'Ramo',
    'Caramulo',
    'Terena',
    'Alqueidao da Serra',
    'Alcacer do Sal',
    'Vila do Bispo',
    'Ortiga',
    'Sete Rios',
    'Pereira',
    'Cabanelas',
    'Montes',
    'Telhado',
    'Real',
    'Apulia',
    'Baltar',
    'Palmeira',
    'Quinta',
    'Lagoas',
    'Ribeira Brava',
    'Castro Daire',
    'Catraia de Sao Paio',
    'Salreu',
    'Aldeia do Meio',
    'Ferreira do Alentejo',
    'Tourais',
    'Eixo',
    'Penedono',
    'Nogueira da Regedoura',
    'Fiaes',
    'Maceda',
    'Caldas',
    'Treixedo'
  ],
  'Saudi Arabia': [
    'Riyadh',
    'Dammam',
    'Safwa',
    'Al Qatif',
    'Dhahran',
    'Al Faruq',
    'Khobar',
    'Jubail',
    'Sayhat',
    'Jeddah',
    "Ta'if",
    'Mecca',
    'Al Hufuf',
    'Medina',
    'Rahimah',
    'Rabigh',
    'Yanbu` al Bahr',
    'Abqaiq',
    'Mina',
    'Ramdah',
    'Linah',
    'Abha',
    'Jizan',
    'Al Yamamah',
    'Tabuk',
    'Sambah',
    'Ras Tanura',
    'At Tuwal',
    'Sabya',
    'Buraidah',
    'Madinat Yanbu` as Sina`iyah',
    'Hayil',
    'Khulays',
    'Khamis Mushait',
    "Ra's al Khafji",
    'Najran',
    'Sakaka',
    'Al Bahah',
    'Rahman',
    'Jazirah'
  ],
  Denmark: [
    'Copenhagen',
    'Frederiksberg',
    'Bronshoj',
    'Albertslund',
    'Hillerød',
    'Frederiksværk',
    'Vasby',
    'Elsinore',
    'Roskilde',
    'Brande',
    'Vejle',
    'Viborg',
    'Køge',
    'Herlev',
    'Aarhus',
    'Randers',
    'Uldum',
    'Egtved',
    'Galten',
    'Hammel',
    'Hørsholm',
    'Birkerød',
    'Hvidovre',
    'Ganlose',
    'Hedehusene',
    'Rødovre',
    'Ishøj',
    'Frederikssund',
    'Tarm',
    'Haderslev',
    'Soborg',
    'Rønne',
    'Vordingborg',
    'Fakse',
    'Ringkøbing',
    'Assens',
    'Odense',
    'Niva',
    'Olstykke',
    'Lemvig',
    'Holstebro',
    'Fensmark',
    'Jægerspris',
    'Jyllinge',
    'Korsør',
    'Naestved',
    'Holbaek',
    'Karup',
    'Karlstrup',
    'Kongens Lyngby',
    'Hundested',
    'Farum',
    'Snekkersten',
    'Allerod',
    'Skaering',
    'Ega',
    'Hornslet',
    'Hjortshoj',
    'Skodstrup',
    'Lystrup',
    'Hinnerup',
    'Dragør',
    'Gentofte',
    'Ronde',
    'Vallensbæk',
    'Risskov',
    'Kokkedal',
    'Kirke Hvalso',
    'Ballerup',
    'Fredericia',
    'Humlebaek',
    'Nakskov',
    'Nørre Alslev',
    'Glostrup',
    'Ikast',
    'Malov',
    'Fredensborg',
    'Stenlose',
    'Skovlunde',
    'Kirke-Hyllinge',
    'Kirke Saby',
    'Lejre',
    'Tune',
    'Havdrup',
    'Solrod',
    'Viby',
    'Sonderso',
    'Faaborg',
    'Marstal',
    'Langeskov',
    'Kerteminde',
    'Middelfart',
    'Ejby',
    'Arslev',
    'Rudkøbing',
    'Svendborg',
    'Tommerup',
    'Silkeborg',
    'Ans',
    'Herning',
    'Aabenraa',
    'Ribe',
    'Christiansfeld',
    'Karlslunde',
    'Sønderborg',
    'Augustenborg',
    'Vojens',
    'Skanderborg',
    'Braedstrup',
    'Horsens',
    'Odder',
    'Kolding',
    'Vamdrup',
    'Stenderup',
    'Juelsminde',
    'Bredballe',
    'Lunderskov',
    'Hampen',
    'Ejstrup',
    'Gedved',
    'Brejning',
    'Ostbirk',
    'Ry',
    'Snedsted',
    'Nykobing Mors',
    'Hurup',
    'Baekmarksbro',
    'Struer',
    'Roslev',
    'Thisted',
    'Vinderup',
    'Skive',
    'Hellerup',
    'Charlottenlund',
    'Klampenborg',
    'Greve',
    'Slagelse',
    'Dalmose',
    'Bagsvaerd',
    'Ringsted',
    'Gilleleje',
    'Helsinge',
    'Graested',
    'Slangerup',
    'Tollose',
    'Kalundborg',
    'Vig',
    'Hadsten',
    'Viby',
    'Marslet',
    'Hojbjerg',
    'Ledoje',
    'Soro',
    'Haslev',
    'Borup',
    'Harlev',
    'Tureby',
    'Ruds-Vedby',
    'Olsted',
    'Vejby',
    'Hjørring',
    'Vadum',
    'Brønderslev',
    'Frederikshavn',
    'Vra',
    'Sindal',
    'Strandby',
    'Norre Bindslev',
    'Gandrup',
    'Hirtshals',
    'Kastrup',
    'Liseleje',
    'Virum',
    'Hornbaek',
    'Espergaerde',
    'Holte',
    'Gislinge',
    'Ugerlose',
    'Farevejle',
    'Follenslev',
    'Taastrup',
    'Præstø',
    'Lundby Stationsby',
    'Knebel',
    'Havndal',
    'Grenå',
    'Morke',
    'Virring',
    'Hasselager',
    'Tilst',
    'Malling',
    'Abyhoj',
    'Vaerlose',
    'Stege',
    'Aalborg',
    'Stovring',
    'Ringe',
    'Sakskøbing',
    'Videbæk',
    'Nykobing Falster',
    'Idestrup',
    'Brovst',
    'Aabybro',
    'Morkov',
    'Svinninge',
    'Dianalund',
    'Hals',
    'Lasby',
    'Skjern',
    'Bjerringbro',
    'Vedbaek',
    'Rungsted',
    'Naerum',
    'Løgstør',
    'Farsø',
    'Gistrup',
    'Norresundby',
    'Storvorde',
    'Hadsund',
    'Hobro',
    'Terndrup',
    'Haderup',
    'Oster Assels',
    'Erslev',
    'Frostrup',
    'Svaneke',
    'Hojby',
    'Herfolge',
    'Lille Skensved',
    'Rønnede',
    'Vissenbjerg',
    'Svanninge',
    'Ærøskøbing',
    'Nyborg',
    'Asnaes',
    'Jelling',
    'Brabrand',
    'Gadstrup',
    'Askeby',
    'Tappernoje',
    'Maribo',
    'Nysted',
    'Stubbekøbing',
    'Eskilstrup',
    'Rødby',
    'Vemb',
    'Skørping',
    'Norager',
    'Lynge',
    'Gadbjerg',
    'Them',
    'Vejen',
    'Aars',
    'Hedensted',
    'Harpelunde',
    'Rodvig',
    'Frederiksberg',
    'Brondby Strand',
    'Tagerup',
    'Skibby',
    'Ejby',
    'Vivild',
    'Esbjerg',
    'Norre Snede',
    'Skaelskor',
    'Horve',
    'Regstrup',
    'Jyderup',
    'Høng',
    'Stroby',
    'Store Heddinge',
    'Bjaeverskov',
    'Stenlille',
    'Gammel Svebolle',
    'Snertinge',
    'Vemmelev',
    'Grevinge',
    'Munke Bjergby',
    'Stokkemarke',
    'Sollested',
    'Guldborg',
    'Mern',
    'Karrebaeksminde',
    'Bogo By',
    'Kettinge',
    'Rødekro',
    'Spentrup',
    'Gudbjerg',
    'Tønder',
    'Randbol',
    'Bramming',
    'Løgumkloster',
    'Varde',
    'Ølgod',
    'Vorbasse',
    'Krusa',
    'Nordborg',
    'Oksbol',
    'Rodding',
    'Billund',
    'Hemmet',
    'Aller',
    'Ullerslev',
    'Gislev',
    'Mariager',
    'Baelum',
    'Ranum',
    'Alestrup',
    'Nibe',
    'Tranebjerg',
    'Ulstrup',
    'Orbaek',
    'Torring',
    'Allingabro',
    'Tjaereborg',
    'Henne',
    'Sherrebek',
    'Holsted',
    'Gram',
    'Tistrup',
    'Brorup',
    'Nordenskov',
    'Padborg',
    'Agerskov',
    'Bredebro',
    'Vandel',
    'Broager',
    'Kjargaard',
    'Daugard',
    'Losning',
    'Vestbjerg',
    'Tylstrup',
    'Suldrup',
    'Vodskov',
    'Sjolund',
    'Almind',
    'Glumso',
    'Hovedgard',
    'Ebeltoft',
    'Hjallerup',
    'Dronninglund',
    'Tars',
    'Give',
    'Arre',
    'Brenderup',
    'Auning',
    'Jerslev',
    'Tisvildeleje',
    'Kvistgard',
    'Alsgarde',
    'Bredsten',
    'Gording',
    'Otterup',
    'Lokken',
    'Alslev',
    'Fuglebjerg',
    'Store Merlose',
    'Ferritslev',
    'Tranbjerg',
    'Kirke Eskilstrup',
    'Hundslund',
    'Sandager',
    'Saltum',
    'Jerslev',
    'Asa',
    'Fjerritslev',
    'Gjol',
    'Saeby',
    'Dybvad',
    'Ostervra',
    'Skagen',
    'Pandrup',
    'Akirkeby',
    'Vekso',
    'Jorlunde',
    'Svenstrup',
    'Avlum',
    'Gorlose',
    'Harlev',
    'Gudme',
    'Harby',
    'Kibaek',
    'Klarup',
    'Munkebo',
    'Norre Aby',
    'Skævinge',
    'Solbjerg',
    'Vildbjerg',
    'Vipperod',
    'Arup',
    'Gelsted',
    'Glamsbjerg',
    'Ryslinge',
    'Stensved',
    'Strib',
    'Bovrup',
    'Tornby',
    'Morud',
    'Oure',
    'Klemensker',
    'Gudhjem',
    'Bogense',
    'Frorup',
    'Trige',
    'Logstrup',
    'Ryomgard',
    'Kejlstrup',
    'Kolind',
    'Engesvang',
    'Hojslev',
    'Kjellerup',
    'Tinglev',
    'Gredstedbro',
    'Egernsund',
    'Blokhus',
    'Ulfborg',
    'Grindsted',
    'Gørlev',
    'Orsted',
    'Farup',
    'Langa',
    'Klovborg',
    'Ale',
    'Bryrup',
    'Nykobing Sjaelland',
    'Sandved',
    'Agedrup',
    'Vejstrup',
    'Sabro',
    'Tarbaek',
    'Vaeggerlose',
    'Kastrup',
    'Hanstholm',
    'Hornsyld',
    'Norre Nebel',
    'Jerup',
    'Sunds',
    'Thorso',
    'Hellebaek',
    'Moldrup',
    'Hosterkob',
    'Skamby',
    'Lyngby',
    'Nodebo',
    'Skarup',
    'Ebberup',
    'Fovling',
    'Beder',
    'Arden',
    'Ostermarie',
    'Gråsten',
    'Sulsted',
    'Sonder Omme',
    'Fjenneslev',
    'Harndrup',
    'Borkop',
    'Albaek',
    'Ornhoj',
    'Gladsaxe',
    'Horning',
    'Karise',
    'Toftlund',
    'Hejnsvig',
    'Hesselager',
    'Vester-Skerninge',
    'Stenstrup',
    'Hoje Tastrup',
    'Agerbaek',
    'Alslev',
    'Herlufmagle',
    'Koldby',
    'Laurbjerg',
    'Dannemare',
    'Ansager',
    'Refsvindinge',
    'Sorring',
    'Orum',
    'Blommenslyst',
    'Slet',
    'Norre Asmindrup',
    'Lambjerg',
    'Hoptrup',
    'Stakroge',
    'Bandholm',
    'Bovlingbjerg',
    'Harboore',
    'Spottrup',
    'Farvang',
    'Gjern',
    'Hvide Sande',
    'Rodkaersbro',
    'Lemming',
    'Lem',
    'Bording Stationsby',
    'Vester Hassing',
    'Hou',
    'Trustrup',
    'Gjerlev',
    'Balle',
    'Kongerslev',
    'Vebbestrup',
    'Nexo',
    'Allinge',
    'Soby',
    'Humble',
    'Marslev',
    'Rude',
    'Langebæk',
    'Borre',
    'Store Fuglede',
    'Oddesund Syd',
    'Hasle',
    'Blavand',
    'Omme',
    'Kolsen',
    'Bedsted',
    'Skodsborg',
    'Himmelev',
    'Sundby',
    'Jordrup',
    'Baekke',
    'Gesten',
    'Viuf',
    'Stouby',
    'Sonder Stenderup',
    'Barrit',
    'Hejls',
    'Bjaert',
    'Trelde',
    'Millinge',
    'Nordby',
    'Odsted',
    'Veflinge',
    'Brobyvaerk',
    'Baring',
    'Verninge',
    'Osby',
    'Glesborg',
    'Frederiksdal',
    'Rynkeby',
    'Asperup',
    'Kvaerndrup',
    'Skals',
    'Astrup',
    'Holme-Olstrup',
    'Holeby',
    'Flemming',
    'Salten',
    'Stoholm',
    'Sanderum',
    'Vantinge',
    'Toreby',
    'Horbelev',
    'Vesterborg',
    'Glyngore',
    'Tjebberup',
    'Eskebjerg',
    'Nygard',
    'Nimtofte',
    'Melby',
    'Boeslunde',
    'Klippinge',
    'Tim',
    'Hammershoj',
    'Nim',
    'Greve Strand',
    'Ramlose',
    'Ferslev',
    'Soesmarke',
    'Tullebolle',
    'Tranekaer',
    'Klokkerholm',
    'Laeso',
    'Spjald',
    'Sorvad',
    'Hodsager',
    'Dronningmolle',
    'Bylderup-Bov',
    'Norreballe',
    'Sommersted',
    'Glejbjerg',
    'Janderup',
    'Branderup',
    'TRUE',
    'Tårnby',
    'Vinkel',
    'Bolderslev',
    'Torring',
    'Borum',
    'Arslev',
    'Bruunshab',
    'Kvols',
    'Tikob',
    'No',
    'Hyllinge',
    'Kalvehave',
    'Brøndby',
    'Horslunde',
    'Torrig',
    'Flauenskjold',
    'Anholt',
    'Tejn',
    'Vestervig',
    'Hjerm',
    'Vederso',
    'Vils',
    'Kaldred',
    'Dong',
    'Holme',
    'Henne Strand',
    'Jystrup',
    'Rebaek',
    'Bevtoft',
    'Martofte',
    'Naesby',
    'Errindlev',
    ''
  ],
  Slovenia: [
    'Velenje',
    'Ljubljana',
    'Smartno ob Paki',
    'Radovljica',
    'Begunje na Gorenjskem',
    'Sencur',
    'Bevke',
    'Kamnik',
    'Lesce',
    'Bled',
    'Žirovnica',
    'Prebold',
    'Tisina',
    'Jesenice',
    'Bohinjska Bela',
    'Crensovci',
    'Mojstrana',
    'Kočevje',
    'Piran',
    'Sežana',
    'Nova Gorica',
    'Cerknica',
    'Ig',
    'Zgornje Gorje',
    'Kranj',
    'Gornja Radgona',
    'Ptuj',
    'Gorisnica',
    'Markovci',
    'Lendava',
    'Beltinci',
    'Sredisce ob Dravi',
    'Naklo',
    'Breginj',
    'Laško',
    'Novo Mesto',
    'Velika Loka',
    'Zgornje Hoce',
    'Prevalje',
    'Šentjur pri Celju',
    'Rogaška Slatina',
    'Mozirje',
    'Mezica',
    'Slovenj Gradec',
    'Stahovica',
    'Murska Sobota',
    'Kranjska Gora',
    'Koper',
    'Breznica',
    'Maribor',
    'Cirkovce',
    'Pernica',
    'Krajna',
    'Domžale',
    'Dob',
    'Dobrova',
    'Grosuplje',
    'Trbovlje',
    'Ilirska Bistrica',
    'Vrhnika',
    'Logatec',
    'Prestranek',
    'Horjul',
    'Globoko',
    'Stari Trg pri Lozu',
    'Izola',
    'Spodnji Ivanjci',
    'Hrusica',
    'Kidricevo',
    'Komenda',
    'Šentjernej',
    'Medvode',
    'Krško',
    'Dolenjske Toplice',
    'Pesnica pri Mariboru',
    'Poljcane',
    'Ruše',
    'Lovrenc na Dravskem Polju',
    'Miklavz na Dravskem Polju',
    'Spodnji Duplek',
    'Slovenska Bistrica',
    'Žalec',
    'Dol pri Ljubljani',
    'Polhov Gradec',
    'Videm',
    'Pobegi',
    'Ajdovščina',
    'Radomlje',
    'Vipava',
    'Litija',
    'Notranje Gorice',
    'Smlednik',
    'Hotedrsica',
    'Dornava',
    'Vojnik',
    'Ljubecna',
    'Škofja Loka',
    'Zgornje Skofije',
    'Skofljica',
    'Divača',
    'Kobarid',
    'Polzela',
    'Menges',
    'Stara Cerkev',
    'Kojsko',
    'Ankaran',
    'Tolmin',
    'Podčetrtek',
    'Log pri Brezovici',
    'Ravne',
    'Trzin',
    'Sevnica',
    'Brestanica',
    'Zelezniki',
    'Grize',
    'Verzej',
    'Mirna',
    'Knezak',
    'Dekani',
    'Moravce',
    'Lenart v Slov. Goricah',
    'Limbus',
    'Brežice',
    'Sentvid pri Sticni',
    'Borovnica',
    'Besnica',
    'Smarje-Sap',
    'Postojna',
    'Smarje pri Jelsah',
    'Slovenske Konjice',
    'Hrastnik',
    'Zagorje ob Savi',
    'Lovrenc na Pohorju',
    'Dravograd',
    'Smarje',
    'Selnica ob Dravi',
    'Bistrica pri Rusah',
    'Radlje ob Dravi',
    'Podbocje',
    'Zgornja Polskava',
    'Puconci',
    'Rocinj',
    'Dobravlje',
    'Vodice',
    'Idrija',
    'Gmajnica',
    'Ravne na Koroskem',
    'Sostanj',
    'Sempeter pri Gorici',
    'Rakek',
    'Portorož',
    'Gorenja Vas',
    'Cemsenik',
    'Spodnji Brnik',
    'Preserje',
    'Trzic',
    'Ziri',
    'Starse',
    'Lokev',
    'Secovlje',
    'Cersak',
    'Cerklje ob Krki',
    'Spodnje Hoce',
    'Nova Cerkev',
    'Sentjanz',
    'Zidani Most',
    'Petrovce',
    'Dobrunje',
    'Miren'
  ],
  Iran: [
    'Karaj',
    'Golestan',
    'Mashhad',
    'Khorasan',
    'Razaviyeh',
    'Semnan',
    'Sistan-e `Olya',
    'Birjand',
    'Qazvin',
    'Farsi',
    'Tiran',
    'Bandar Būshehr',
    'Kerman',
    'Sirjan',
    'Yasuj',
    'Kermanshah',
    'Hamadan',
    'Kordestan-e `Olya',
    'Tabriz',
    'Azaran',
    'Ardabil',
    'Zanjan',
    'Shahid',
    'Pasargad',
    'Sena',
    'Sabz',
    'Baharan',
    'Negar',
    'Rasht',
    'Rafsanjan',
    'Isfahan',
    'Ahvaz',
    'Shiraz',
    'Khong',
    'Baladeh',
    'Barbun',
    'Hasanabad-e Qadamgah',
    'Behjan',
    'Shahrak-e Pars',
    'Peshgoman',
    'Gostar',
    'Yazd',
    'Ilam',
    'Bakhtiari',
    'Gharbi',
    'Gilan-e Gharb',
    'Gazan-e Bala',
    'Sharif',
    'Shahrud',
    '`Oryan',
    'Ashna',
    'Shahrak',
    'Payam',
    'Baseri Hadi',
    'Mohr',
    'Tonekabon',
    'Dezful',
    'Peyk',
    'Abadan',
    'Samen',
    'Shahriar',
    'Azad',
    'Taha',
    'Borran-e Bala',
    'Arnan',
    'Najafabad',
    'Golriz',
    'Hirad',
    'Kashan',
    'Banak',
    'Torkan',
    'Vaj',
    'Piruz',
    'Mehin',
    'Bakhtiaruyeh',
    'Golpayegan',
    'Bandar-e Mahshahr',
    'Sanandaj',
    'Zahedan',
    'Arak',
    'Bastak',
    'Gilas',
    'Urmia',
    'Lahijan',
    'Shahr',
    'Parpis',
    'Kelarabad',
    'Neka',
    'Behshahr',
    'Esfarayen',
    'Abol',
    'Chabahar',
    'Zabol',
    'Saravan',
    'Iranshahr',
    'Rasak',
    'Khash',
    'Falavarjan',
    'Chah Deraz',
    'Baghin',
    'Zangiabad',
    'Kord Kandi',
    'Rigan',
    'Jiroft',
    'Bandar-e Khamir',
    'Ravar',
    'Varamin',
    'Damavand',
    'Razan',
    'Nahavand',
    'Homa',
    'Hashtgerd',
    'Amol',
    'Arsanjan',
    'Karimkhan',
    'Fardis',
    'Parsian',
    'Emam',
    'Neyshabur',
    'Vandar',
    'Hormozabad',
    'Baft',
    'Onar',
    'Bardsir',
    'Qeshm',
    'Siahkal',
    'Miandoab',
    'Hadishahr',
    'Sahand',
    'Soltanabad',
    'Sabzevar',
    'Gorgan',
    'Kordovan-e Sofla',
    'Chapar',
    'Shahid Madani',
    'Khameneh',
    'Khalkhal',
    'Manjil',
    'Hashtpar',
    'Salmas',
    'Shirvan',
    'Shahrak-e Milad',
    'Darmian',
    'Nehbandan',
    'Quchan',
    'Babol',
    'Mamasani',
    'Malayer',
    'Razi',
    'Band',
    'Badr',
    'Behbahan',
    'Ashan',
    'Dadeh',
    'Saman',
    'Sari',
    'Shahrak-e Direh',
    'Fasa',
    'Wahna',
    'Qasr od Dasht',
    'Mahestan',
    'Jahan',
    'Mobarakeh',
    'Kaman',
    'Kish',
    'Bidak',
    'Ravand',
    'Jadir',
    'Jahrom',
    'Post',
    'Naji',
    'Hush',
    'Mehregan',
    'Khowrshid',
    'Birizg',
    'Gerash',
    'Lamerd',
    'Khowrasgan',
    'Kian',
    'Novin',
    'Shahreza',
    'Idah',
    'Jahan Nama',
    'Behdasht',
    'Azadi',
    'Evin',
    'Nasir',
    'Shadman',
    'Khvajeh',
    'Malek Talesh',
    'Bostan',
    'Eslamshahr',
    'Saveh',
    'Mahallat',
    'Takestan',
    'Zahra',
    'Garmsar',
    'Damghan',
    'Shazand',
    'Khomeyn',
    'Ashtian',
    'Semirom',
    'Khomeyni Shahr',
    'Valilu',
    'Rayen',
    'Meybod',
    'Ardakan',
    'Rabor',
    '`Aliabad',
    'Zarand',
    'Neyriz',
    'Estahban',
    'Fordu',
    'Servan',
    'Maragheh',
    'Marand',
    'Bonab',
    'Malekan',
    'Mianej',
    'Julfa',
    'Khvoy',
    'Mahabad',
    'Bukan',
    'Meshgin Shahr',
    'Moghan',
    'Germi',
    '`Aliabad-e Aq Hesar',
    'Astara',
    'Amlash',
    'Asadabad',
    'Dorud',
    'Ravansar',
    'Shahid Chamran',
    'Kabir'
  ],
  Norway: [
    'Jar',
    'Karlshus',
    'Moss',
    'Oslo',
    'Frogner',
    'Drammen',
    'Vestby',
    'Aursmoen',
    'Tranby',
    'Bergen',
    'Sarpsborg',
    'Kongsvinger',
    'Asgardstrand',
    'Kolbotn',
    'Lillestrøm',
    'Horten',
    'Lysaker',
    'Nesbru',
    'Skarnes',
    'Aseral',
    'Stokke',
    'Hammerfest',
    'Askim',
    'Sortland',
    'Rygge',
    'Leirsund',
    'Dal',
    'Raholt',
    'Dilling',
    'Elverum',
    'Trondheim',
    'Kolsas',
    'Fornebu',
    'Skien',
    'Finnsnes',
    'Tomasjorda',
    'Tromsø',
    'Tromsdalen',
    'Hovik verk',
    'Stabekk',
    'Oppegard',
    'Arendal',
    'Gjøvik',
    'Salhus',
    'Hol',
    'Gjerdrum',
    'Fetsund',
    'Ålesund',
    'Molde',
    'Skodje',
    'Fosnavag',
    'Brattvag',
    'Langevag',
    'Syvik',
    'Vestnes',
    'Volda',
    'Ulsteinvik',
    'Eide',
    'Hareid',
    'Stranda',
    'Stordal',
    'Hjelset',
    'Tennfjord',
    'Lyngstad',
    'Kristiansund',
    'Rovde',
    'Aukra',
    'Skotterud',
    'Moelv',
    'Magnor',
    'Brumunddal',
    'Harstad',
    'Larvik',
    'Andalsnes',
    'Ski',
    'Halden',
    'Sandefjord',
    'Fredrikstad',
    'Tønsberg',
    'Porsgrunn',
    'Baerums verk',
    'Tertnes',
    'Rjukan',
    'Holmestrand',
    'Lillehammer',
    'Bodø',
    'Krakeroy',
    'Rolvsoy',
    'Saltnes',
    'Haukeland',
    'Ranheim',
    'Jessheim',
    'Sandvika',
    'Haslum',
    'Asker',
    'Langhus',
    'Stavern',
    'Gressvik',
    'Kragero',
    'Nevlunghamn',
    'Hamar',
    'Ottestad',
    'Arnes',
    'Gran',
    'Mo i Rana',
    'Frekhaug',
    'Eidsvoll verk',
    'Kristiansand',
    'Narvik',
    'Stathelle',
    'Heimdal',
    'Nøtterøy',
    'Sorum',
    'Sorumsand',
    'Saupstad',
    'Ørsta',
    'Bones',
    'Steinkjer',
    'Engalsvik',
    'Karlshus',
    'Nesoddtangen',
    'Stange',
    'Honefoss',
    'Jevnaker',
    'Royken',
    'Hunndalen',
    'Melsomvik',
    'Honningsvåg',
    'Slemmestad',
    'Randaberg',
    'Krokstadelva',
    'Raufoss',
    'Heggedal',
    'Namsos',
    'Sellebakk',
    'Haugesund',
    'Kyrksaeterora',
    'Geilo',
    'Al',
    'Gol',
    'Nesbyen',
    'Sagvag',
    'Levanger',
    'Tiller',
    'Hell',
    'Hegra',
    'Stavanger',
    'Indre Arna',
    'Laksevag',
    'Spydeberg',
    'Orkanger',
    'Åfjord',
    'Son',
    'Nesttun',
    'Langesund',
    'Sorbo',
    'Floro',
    'Selje',
    'Stryn',
    'Bygstad',
    'Sandane',
    'Naustdal',
    'Vassenden',
    'Kalvag',
    'Forde',
    'Deknepollen',
    'Raudeberg',
    'Leikang',
    'Sokndal',
    'Hoyanger',
    'Storslett',
    'Skjervoy',
    'Silsand',
    'Samuelsberg',
    'Lyngseidet',
    'Moen',
    'Gargan',
    'Vadsø',
    'Jakobselv',
    'Kirkenes',
    'Båtsfjord',
    'Ivgobahta',
    'Cavkkus',
    'Tennevoll',
    'Birtavarre',
    'Olderdalen',
    'Kautokeino',
    'Hasvik',
    'Sorvaer',
    'Akkarfjord',
    'Talvik',
    'Varangerbotn',
    'Evje',
    'Grimstad',
    'Kvinesdal',
    'Nodeland',
    'Lyngdal',
    'Valle',
    'Strommen',
    'Kopervik',
    'Orje',
    'As',
    'Kongsberg',
    'Vatne',
    'Gardermoen',
    'Garnes',
    'Saebo',
    'Manger',
    'Hjellestad',
    'Godvik',
    'Flekke',
    'Granvin',
    'Storebo',
    'Os',
    'Leirvik',
    'Mosjoen',
    'Loddefjord',
    'Kvinlog',
    'Amli',
    'Mandal',
    'Sandnessjoen',
    'Hemnesberget',
    'Movik',
    'Vennesla',
    'Fenstad',
    'Naersnes',
    'Oppdal',
    'Holen',
    'Hvalstad',
    'Husoy',
    'Skedsmokorset',
    'Fla',
    'Lena',
    'Eidsvoll',
    'Dokka',
    'Koppang',
    'Vingelen',
    'Heggenes',
    'Ringebu',
    'Kolbu',
    'Vestre Gausdal',
    'Rogne',
    'Land',
    'Gaupen',
    'Sor-Fron',
    'Bruflat',
    'Eina',
    'Follebu',
    'Hov',
    'Valer',
    'Nordland',
    'Ballangen',
    'Fauske',
    'Nesna',
    'Straumen',
    'Alta',
    'Karasjok',
    'Lakselv',
    'Bjornevatn',
    'Burfjord',
    'Svolvær',
    'Rorvik',
    'Angvika',
    'Eide',
    'Batnfjordsora',
    'Kvisvik',
    'Flemma',
    'Vagland',
    'Indre Klubben',
    'Sandnes',
    'Nydalen',
    'Hemnes',
    'Loken',
    'Bjorkelangen',
    'Fosser',
    'Trollfjorden',
    'Stokmarknes',
    'Melbu',
    'Dverberg',
    'Andenes',
    'Stronstad',
    'Risoyhamn',
    'Drag',
    'Manndalen',
    'Fagernes',
    'Leira',
    'Rakkestad',
    'Eidsberg',
    'Solbergelva',
    'Sande',
    'Mjondalen',
    'Lunner',
    'Jaren',
    'Felle',
    'Drangedal',
    'Stabbestad',
    'Sannidal',
    'Etne',
    'Kolnes',
    'Svortland',
    'Rubbestadneset',
    'Vestra Mosterhamn',
    'Skjoldastraumen',
    'Stol',
    'Naerbo',
    'Tau',
    'Tananger',
    'Algard',
    'Byglandsfjord',
    'Flekkefjord',
    'Farsund',
    'Vanse',
    'Eiken',
    'Varhaug',
    'Royneberg',
    'Oltedal',
    'Jorpeland',
    'Bryne',
    'Sola',
    'Kongsvika',
    'Evenskjer',
    'Borkenes',
    'Gratangen',
    'Lodingen',
    'Sandeid',
    'Skudeneshavn',
    'Vedavagen',
    'Olen',
    'Bomlo',
    'Akrehamn',
    'Sveio',
    'Tjelta',
    'Gullaug',
    'Lier',
    'Klepp',
    'Kleppe',
    'Vikedal',
    'Odda',
    'Berge',
    'Bo',
    'Bo',
    'Notodden',
    'Hokksund',
    'Seljord',
    'Ulefoss',
    'Dirdal',
    'Hommersak',
    'Steinberg',
    'Svelvik',
    'Greaker',
    'Sirevag',
    'Konsmo',
    'Finsand',
    'Mosby',
    'Fossdalen',
    'Oveland',
    'Borhaug',
    'Ovrebo',
    'Fiskum',
    'Skoppum',
    'Flam',
    'Lærdal',
    'Voksa',
    'Isdalsto',
    'Drobak',
    'Suldalsosen',
    'Myre',
    'Inndyr',
    'Melhus',
    'Egersund',
    'Erdal',
    'Mathopen',
    'Hylkje',
    'Mysen',
    'Florvag',
    'Kleppesto',
    'Søgne',
    'Froland',
    'Klofta',
    'Straume',
    'Loten',
    'Oksvoll',
    'Agotnes',
    'Vollen',
    'Ikornnes',
    'Hvam',
    'Hakadal',
    'Brekstad',
    'Lillesand',
    'Vestfossen',
    'Bjerkvik',
    'Bjaland',
    'Almas',
    'Amot',
    'Odnes',
    'Minnesund',
    'Maura',
    'Setermoen',
    'Auli',
    'Austmarka',
    'Kirkenaer',
    'Kroderen',
    'Bruvik',
    'Brandbu',
    'Sokna',
    'Grua',
    'Gan',
    'Sjovegan',
    'Vaksdal',
    'Sandvoll',
    'Holter',
    'Kjeller',
    'Kvelde',
    'Valestrandfossen',
    'Torpo',
    'Ranasfoss',
    'Saebovik',
    'Helgeroa',
    'Hvittingfoss',
    'Tonstad',
    'Hemsedal',
    'Nordby Bruk',
    'Fjellstrand',
    'Skabu',
    'Otta',
    'Lom',
    'Vinstra',
    'Faberg',
    'Fyrde',
    'Davik',
    'Nordfjordeid',
    'Rognan',
    'Bud',
    'Rossland',
    'Lonevag',
    'Sandtorg',
    'Tovik',
    'Stavern',
    'Lalm',
    'Dovre',
    'Vage',
    'Kolltveit',
    'Voss',
    'Moi',
    'Kvam',
    'Maloy',
    'Sogn',
    'Heroysund',
    'Hagavik',
    'Feiring',
    'Lidaladdi',
    'Myre',
    'Sorvik',
    'Tomter',
    'Fagerstrand',
    'Hyllestad',
    'Hellvik',
    'Kjopsvik',
    'Skutvika',
    'Nykirke',
    'Hof',
    'Skreia',
    'Trengereid',
    'Hornnes',
    'Skatoy',
    'Staubo',
    'Vegarshei',
    'Grendi',
    'Hagan',
    'Venabygd',
    'Ise',
    'Kjellmyra',
    'Langfjordbotn',
    'Kjollefjord',
    'Mehamn',
    'Tysnes',
    'Vormedal',
    'Fitjar',
    'Figgjo',
    'Hovden',
    'Bykle',
    'Onarheim',
    'Skanevik',
    'Mirza Rafi Sauda',
    'Skjold',
    'Folderoy',
    'Sira',
    'Hauge',
    'Sandnes',
    'Ana-Sira',
    'Sto',
    'Utsira',
    'Flateby',
    'Roa',
    'Finnoy',
    'Rost',
    'Værøy',
    'Gravdal',
    'Kabelvag',
    'Henningsvaer',
    'Stamsund',
    'Leknes',
    'Ballstad',
    'Soreidgrenda',
    'Havik',
    'Uggdal',
    'Reistad',
    'Sylling',
    'Bostad',
    'Gvarv',
    'Glomfjord',
    'Kaupanger',
    'Svarstad',
    'Dyrvika',
    'Hvitsten',
    'Lunde',
    'Nedenes',
    'Faervik',
    'Birkeland',
    'Fevik',
    'Eikelandsosen',
    'Rong',
    'Eikanger',
    'Amot',
    'Geithus',
    'Vikersund',
    'Eggedal',
    'Prestfoss',
    'Romedal',
    'Alvdal',
    'Innbygda',
    'Indre Ulvsvag',
    'Siggerud',
    'Bjoneroa',
    'Evanger',
    'Skotbu',
    'Torod',
    'Lokken Verk',
    'Skaun',
    'Eggkleiva',
    'Storas',
    'Rennebu',
    'Berkak',
    'Hommelvik',
    'Tanem',
    'Rud',
    'Berger',
    'Barkaker',
    'Sande',
    'Austbygdi',
    'Lysoysundet',
    'Eidsvag',
    'Hestvika',
    'Kval',
    'Hovin',
    'Gjolme',
    'Haerland',
    'Sunndalsora',
    'Feda',
    'Surnadalsora',
    'Nedre Frei',
    'Halsa',
    'Enga',
    'Verdal',
    'Furnes',
    'Sander',
    'Roverud',
    'Knapper',
    'Uvdal',
    'Rodberg',
    'Fardal',
    'Ardalstangen',
    'Rypefjord',
    'Sistranda',
    'Ovre Ardal',
    'Skjolden',
    'Hamarvik',
    'Singsas',
    'Follese',
    'Knarrlaget',
    'Vangsvika',
    'Balestrand',
    'Solvorn',
    'Tjeldsto',
    'Knarrevik',
    'Saetre',
    'Slastad',
    'Brandval',
    'Arneberg',
    'Misje',
    'Glesvaer',
    'Hitra',
    'Tofte',
    'Vevang',
    'Hersaeter',
    'Meraker',
    'Neverdal',
    'Selvaer',
    'Reipa',
    'Vadheim',
    'Slattum',
    'Risør',
    'Bagn',
    'Aurdal',
    'Tjøme',
    'Ramnes',
    'Beitostolen',
    'Krakstad',
    'Salsnes',
    'Foldereid',
    'Abelvaer',
    'Flesberg',
    'Tyristrand',
    'Valle',
    'Tveit',
    'Ulvik',
    'Sunde',
    'Uskedalen',
    'Valen',
    'Rosendal',
    'Berge',
    'Havik',
    'Hatlestranda',
    'Dimmelsvik',
    'Tafjord',
    'Godoy',
    'Eidsnes',
    'Valldal',
    'Grong',
    'Skage',
    'Sorli',
    'Veggli',
    'Rykene',
    'Lindas',
    'Hellesylt',
    'Kjerstad',
    'Brennasen',
    'Tyssedal',
    'Kolvereid',
    'Brattholmen',
    'Namsskogan',
    'Trones',
    'Snaase',
    'Hemnskjela',
    'Eidsvag',
    'Brevik',
    'Eidfjord',
    'Na',
    'Lofthus',
    'Norheimsund',
    'Oystese',
    'Herand',
    'Oma',
    'Mjolkeraen',
    'Torp',
    'Skjeberg',
    'Knappstad',
    'Borgen',
    'Bo',
    'Vikesa',
    'Vigrestad',
    'Bru',
    'Havøysund',
    'Oresvika',
    'Masfjorden',
    'Reinsvoll',
    'Hokkasen',
    'Kleive',
    'Lovund',
    'Spangereid',
    'Sparbu',
    'Asen',
    'Salsbruket',
    'Skogn',
    'Skogn',
    'Aure',
    'Tingvoll',
    'Oksfjord',
    'Sandstad',
    'Haddal',
    'Sem',
    'Longva',
    'Nordkisa',
    'Tistedal',
    'Siljan',
    'Herre',
    'Saeveland',
    'Meldal',
    'Adalsbruk',
    'Re',
    'Digermulen',
    'Kleppstad',
    'Sommaroy',
    'Berlevåg',
    'Ramberg',
    'Sund',
    'Ulvagen',
    'Sorvagen',
    'Malm',
    'Indreby',
    'Hansnes',
    'Hjelmas',
    'Valestrand',
    'Kvalsund',
    'Haram',
    'Vinje',
    'Fyresdal',
    'Kviteseid',
    'Treungen',
    'Opphaug',
    'Selva',
    'Sandshamn',
    'Tvedestrand',
    'Svene',
    'Hetlevik',
    'Eidsfoss',
    'Ask',
    'Sletta',
    'Aros',
    'Strusshamn',
    'Indre Billefjord',
    'Bronnoysund',
    'Somna',
    'Hordvik',
    'Fister',
    'Helgeland',
    'Hundven',
    'Eidsdal',
    'Otteroy',
    'Sperrebotn',
    'Haltdalen',
    'Korsvegen',
    'Tjotta',
    'Leirfjord',
    'Bekkjarvik',
    'Austbo',
    'Utskarpen',
    'Eydehavn',
    'Buskerud',
    'Noresund',
    'Steigen',
    'Kongshamn',
    'Hallingby',
    'Bjerka',
    'Storforshei',
    'Haegeland',
    'Rena',
    'Re',
    'Leines',
    'Jomna',
    'Nybergsund',
    'Ringsaker',
    'Os',
    'Tolga',
    'Laukvik',
    'Tynset',
    'Myking',
    'Askøy',
    'Hovet',
    'Kamben',
    'Tysse',
    'Ervik',
    'Klokkarvik',
    'Nordstrono',
    'Mo',
    'Modalen',
    'Holmefjord',
    'Saevareid',
    'Roldal',
    'Fedje',
    'Vannvag',
    'Laksvatn',
    'Storsteinnes',
    'Midsund',
    'Tornes',
    'Rollag',
    'Tjorvag',
    'Barstadvik',
    'Lauvstad',
    'Flesnes',
    'Batsto',
    'Vikran',
    'Stamnes',
    'Hustad',
    'Folkestad',
    'Orre',
    'Rindal',
    'Valsoyfjord',
    'Kvanne',
    'Groa',
    'Meisingset',
    'Sundal',
    'Raudsand',
    'Vardø',
    'Bugøynes',
    'Rauland',
    'Svelgen',
    'Vevelstad',
    'Loen',
    'Bovagen',
    'Eidsa',
    'Rokland',
    'Lepsoy',
    'Beisfjord',
    'Ytre Alvik',
    'Gjerstad',
    'Roros',
    'Malvik',
    'Vormsund',
    'Turoy',
    'Gasbakken',
    'Vikebygd',
    'Eivindvik',
    'Ankenes',
    'Ramfjordbotn',
    'Trofors',
    'Lorenfallet',
    'Dalen',
    'Konsvikosen',
    'Bleikvasslia',
    'Kyrkjebo',
    'Kvammen',
    'Gransherad',
    'Korgen',
    'Nesgrenda',
    'Horte',
    'Alen',
    'Moldjord',
    'Forde',
    'Fall',
    'Miland',
    'Steinsdalen',
    'Brusand',
    'Helleland',
    'Espeland',
    'Undeim',
    'Erfjord',
    'Hovag',
    'Lauvsnes',
    'Snillfjord',
    'Svartskog',
    'Disena',
    'Sand',
    'Fonnes',
    'Nygardsjoen',
    'Blaker',
    'Bjordal',
    'Brekke',
    'Dale',
    'Byrknes Nordre',
    'Ardal',
    'Blakset',
    'Olden',
    'Bangsund',
    'Sulisjielmma',
    'Agskardet',
    'Ornes',
    'Rodoy',
    'Skjerstad',
    'Tjong',
    'Misvaer',
    'Tangen',
    'Larkollen',
    'Arland',
    'Sand',
    'Vale',
    'Hyggen',
    'Holsen',
    'Hedal',
    'Ebru',
    'Morgedal',
    'Austevoll',
    'Spillum',
    'Bjorke',
    'Nyborg',
    'Tretten',
    'Lundamo',
    'Ask',
    'Langangen',
    'Oyeren',
    'Reine',
    'Hebnes',
    'From',
    'Aker',
    'Vraliosen',
    'Trana',
    'Nyhus',
    'Ringstad',
    'Borge',
    'Valer',
    'Dolemo',
    'Borregard',
    'Lensvik',
    'Innvik',
    'Selasvatn',
    'Sondeled',
    'Moen',
    'Kaldfarnes',
    'Follafoss',
    'Enebakkneset',
    'Boverfjorden',
    'Lenvik',
    'Amli',
    'Hildre',
    'Fotlandsvag',
    'Hole',
    'Rana',
    'Reitan',
    'Holmen',
    'Kornsjo',
    'Skarer',
    'Mestervik',
    'Skulsfjord',
    'Adland',
    'Fiska',
    'Annstad',
    'Kolbjornsvik',
    'Hjorungavag',
    'Ulstein',
    'Holmenkollen',
    'Slemdal',
    'Buvika',
    'Hellandsjoen',
    'Marheim',
    'Fresvik',
    'Soknedal',
    'Vossestrand',
    'Skeie'
  ],
  Mexico: [
    'Monterrey',
    'Mexico City',
    'Cancún',
    'San Nicolás de los Garza',
    'Santiago de Queretaro',
    'Guadalajara',
    'Puebla',
    'Pachuca',
    'Mexicali',
    'Acapulco',
    'Puerto Vallarta',
    'Cabo San Lucas',
    'Tijuana',
    'Playa del Carmen',
    'Rosarito',
    'Chihuahua',
    'Veracruz',
    'Baja California',
    'Quintana Roo',
    'Ecatepec de Morelos',
    'Oaxaca',
    'Ciudad Hidalgo',
    'Campeche',
    'Aguascalientes',
    'Cholula',
    'Tlaquepaque',
    'Colonia Madero (Madero)',
    'Colonia Mexico',
    'Mérida',
    'Ciudad Juárez',
    'Saltillo',
    'Colima',
    'Salamanca',
    'Toluca',
    'Morelia',
    'Zacatecas',
    'Cuernavaca',
    'Tlaxcala',
    'Tuxtla Gutiérrez',
    'Reynosa',
    'Victoria',
    'Heroica Matamoros',
    'Guanajuato',
    'La Paz',
    'Hermosillo',
    'Celaya',
    'Abasolo',
    'Villahermosa',
    'Jalapa',
    'Coatzacoalcos',
    'Cuauhtemoc',
    'San Luis Potosí',
    'Culiacán',
    'Motozintla de Mendoza',
    'Arriaga',
    'Jiutepec',
    'San Nicolas',
    'Tampico',
    'Topolobampo',
    'Tuxpan',
    'Zapopan',
    'Ciudad del Carmen',
    'Xalapa',
    'Ciudad Victoria',
    'Garza Garcia',
    'Tala',
    'León',
    'Kilometro 32 Texcoco Lecheria',
    'Guadalupe',
    'Nuevo Leon',
    'Nuevo Laredo',
    'Mazatlán',
    'Irapuato',
    'Naucalpan',
    'Garcia',
    'Apodaca',
    'Tulancingo',
    'Tlalnepantla',
    'La Reforma',
    'Huixquilucan',
    'Torreón',
    'Cuauhtemoc',
    'Boca del Rio',
    'Salinas de Hidalgo',
    'Tepic',
    'Jalupa',
    'Poncitlan',
    'Ramos Arizpe',
    'Orizaba',
    'Cuautitlan',
    'Zamora',
    'Los Reyes',
    'Centro',
    'Ameca',
    'Adolfo Ruiz Cortines',
    'San Andres Tuxtla',
    'Zaragoza',
    'Santa Isabel Tlanepantla',
    'Chalco de Diaz Covarrubias',
    'Ejido Distrito Federal',
    'Victoria',
    'Venustiano Carranza',
    'Santa Catarina',
    'Lerdo',
    'San Bernardo',
    'Uruapan',
    'Ciudad Obregón',
    'Jacobo',
    'Cuautitlán Izcalli',
    'Valle de Santiago',
    'Montemorelos',
    'Mextepec',
    'Ciudad Nezahualcoyotl',
    'Netzahualcoyotl',
    'San Patricio',
    'Granjas',
    'Temascalcingo',
    'Tlajomulco de Zuniga',
    'Durango',
    'Tlalpan',
    'Insurgentes',
    'Salvatierra',
    'Mineral de la Reforma',
    'Teoloyucan',
    'Tultitlan',
    'Paseos de Tecamac',
    'Tultepec',
    'Piedras Negras',
    'Azcapotzalco',
    'Ensenada',
    'Cajeme',
    'Tablon Grande (Bombas de Apaxco)',
    'Pueblo Juarez (La Magdalena)',
    'Santa Maria Chimalhuacan',
    'Chilpancingo',
    'Tula de Allende',
    'Tampico Alto',
    'Chiapas',
    'Sinaloa de Leyva',
    'Cocula',
    'Navojoa',
    'Chihuahua',
    'Colonia Benito Juarez',
    'Nuevo Casas Grandes',
    'Jocotepec',
    'San Antonio',
    'Union de Tula',
    'Xalisco',
    'Patzcuaro',
    'Pastor Ortiz',
    'Los Reyes',
    'Yurecuaro',
    'Tocumbo',
    'Los Reyes de Salgado',
    'Tangancicuaro de Arista',
    'La Piedad',
    'Barranca de Santa Clara',
    'Izucar de Matamoros',
    'Apizaco',
    'Tecamachalco',
    'San Andres Cholula',
    'Atlixco',
    'San Juan',
    'Libres',
    'Agua Azul',
    'San Martin Texmelucan de Labastida',
    'Berriozabal',
    'Rayon',
    'Graciano Sanchez',
    'San Pedro de Nolasco',
    'Linares',
    'Escobedo',
    'Guadalupe',
    'Cadereyta',
    'El Leon',
    'Juarez',
    'Benito Juarez',
    'Santiago',
    'El Colli',
    'Tequila',
    'Tonala',
    'El Salto',
    'El Salto',
    'Tapalpa',
    'Tecalitlan',
    'Ciudad Guzmán',
    'San Miguel de Cozumel',
    'Córdoba',
    'San Agustin (Salvador Alvarado)',
    'Morelos',
    'Fresnillo',
    'Sombrerete',
    'Guadalupe',
    'Jesus Maria',
    'Tapachula',
    'San Cristóbal de las Casas',
    'Tuxtla',
    'Ocozocoautla de Espinosa',
    'San Juan del Río',
    'Cortazar',
    'Acambaro',
    'Pedro Escobedo',
    'Corregidora',
    'San Miguel de Allende',
    'Yuriria',
    'La Florida',
    'Morelos',
    'Escondida de lo Verde',
    'Iztacalco',
    'Tuxpan',
    'Lázaro Cárdenas',
    'Banderas del Aguila',
    'Ajijic',
    'Bucerias',
    'Reyes de Vallarta',
    'Tepatitlán de Morelos',
    'Atotonilco el Alto',
    'El Tecuan',
    'Ciudad de Villa de Alvarez',
    'Izamal',
    'Tizimín',
    'Valladolid',
    'Hunucma',
    'Yucatan',
    'Los Mochis',
    'El Alto Culiacan',
    'Navolato',
    'Guamuchil',
    'El Fuerte de Montes Claros',
    'Guasave',
    'Ahome',
    'La Cruz',
    'Candelaria',
    'Tlapa de Comonfort',
    'Petaquillas',
    'Mazatlan',
    'Ixtapa-Zihuatanejo',
    'El Ocotito',
    'Zitlala',
    'Mixquiahuala de Juarez',
    'Coacalco',
    'Tolcayuca',
    'Ixtapaluca',
    'Isla Holbox',
    'Puerto Morelos',
    'Juarez',
    'Tulum',
    'Chetumal',
    'Tecax',
    'Muna',
    'Uman',
    'Oxkutzkab',
    'Cananea',
    'Heroica Caborca',
    'Bahia de Kino',
    'La Paletena',
    'Nacozari Viejo',
    'Nogales',
    'Leon',
    'Magdalena de Kino',
    'Cuautepec de Hinojosa',
    'Huauchinango',
    'Monterrey',
    'San Nicolas',
    'Escobedo',
    'General Escobedo',
    'Refractarios Guadalajara',
    'El Sabinal (San Pedro)',
    'Cuautlancingo',
    'Estado de Mexico',
    'Iztapalapa',
    'Coyoacán',
    'Tlalmanalco de Velazquez',
    'Tlahuac',
    'Benito Juarez',
    'Panotla',
    'Fortin de las Flores',
    'Zongolica',
    'Miguel Aleman',
    'Mendoza',
    'Tezonapa',
    'Yanga',
    'Paraje Nuevo',
    'Ciudad Mendoza',
    'Cuitlahuac',
    'Tierra Blanca',
    'Rio Blanco',
    'Cadereyta Jimenez',
    'San Nicolas',
    'Allende',
    'San Juan',
    'Villa Hidalgo',
    'La Barca',
    'Tacambaro de Codallos',
    'Apatzingán',
    'Tamazula de Gordiano',
    'Ciudad Ayala',
    'Tabasco',
    'Macuspana',
    'Comalcalco',
    'Cardenas',
    'Cosoleacaque',
    'Villa Nanchital',
    'Minatitlán',
    'San Juan del Rio',
    'Cuautla',
    'Tecate',
    'Yautepec',
    'Emiliano Zapata',
    'Valles',
    'San Vicente Chicoloapan',
    'Ocotlan',
    'Poza Rica Chacas',
    'Las Delicias',
    'Seccion Primera Santiago Tetla',
    'San Salvador Tzompantepec',
    'Guadalajara',
    'Epazoyucan',
    'Cunduacan',
    'Ciudad Lopez Mateos',
    'Villa Cuauhtemoc Otzolotepec',
    'Melchor Muzquiz',
    'Los Sabinos',
    'Santiago Ixcuintla',
    'Colonia Hidalgo',
    'Jala',
    'Zacualpan',
    'Ixtlan del Rio',
    'Manzanillo',
    'Tecoman',
    'Zacapu',
    'Villadiego',
    'Heroica Zitacuaro',
    'Huandacareo',
    'Campestre Tarimbaro',
    'Puruandiro',
    'Tejupilco de Hidalgo',
    'Tenancingo de Degollado',
    'Lerma',
    'San Mateo',
    'Zacatepec',
    'San Miguel Zinacantepec',
    'Valle de Bravo',
    'Tonatico',
    'Ixtapan de la Sal',
    'Luvianos',
    'San Mateo Mexicaltzingo',
    'Malinalco',
    'Ocoyoacac',
    'Tequisquiapan',
    'Gueramaro',
    'Santa Anita',
    'La Victoria',
    'Jaumave',
    'Atoyac de Alvarez',
    'Colonia Guerrero (Los Guajes)',
    'Petatlan',
    'Ex-Hacienda Pantitlan',
    'La Ciudad',
    'Tehuacán',
    'San Pedro Cholula',
    'Vicente Guerrero',
    'Huejotzingo',
    'Colonia Cuauhtemoc',
    'Mazatecochco',
    'Nueva Italia de Ruiz',
    'Apatzingan',
    'Colimas',
    'Zapotitlan de Vadillo',
    'El Grullo',
    'San Jose del Valle',
    'Ixtapa',
    'Tecuala',
    'Cuarta Brigada',
    'Libramiento Leon-Salamanca',
    'Tepotzotlan',
    'Purisima del Zapote',
    'San Francisco del Rincón',
    'Purisima de Bustos',
    'Nicolas Romero',
    'Rincon de Tamayo',
    'Silao',
    'San Francisco',
    'Venustiano Carranza',
    'Tlalnepantla',
    'Atizapan',
    'Gustavo A. Madero Borough',
    'Chiconcuac',
    'Colonia Emiliano Zapata (Ejido Santiago Temoaya)',
    'Acambay',
    'Tlaltizapan',
    'San Mateo Atenco',
    'Temascaltepec de Gonzalez',
    'San Agustin',
    'Fraccionamiento Iberica Calimaya',
    'Zacualpan',
    'Santa Apolonia',
    'Valle Hermoso',
    'Rio Bravo',
    'El Torreon',
    'Madero',
    'La Canada',
    'San Jose Iturbide',
    'Quiroga',
    'Cadereyta de Montes',
    'Asuncion Ixtaltepec',
    'Ciudad de Huajuapam de Leon',
    'Acatlan de Perez Figueroa',
    'Miguel Hidalgo',
    'La Pista de Tizayuca',
    'Pueblo Hidalgo',
    'Iglesia Vieja',
    'Tepeji de Ocampo',
    'Ixmiquilpan',
    'Tlahuelilpan',
    'Brisas Barra de Suchiate',
    'Huixtla',
    'Vicente Guerrero',
    'Comitán',
    'Perote',
    'Zacapoaxtla',
    'Altotonga',
    'Magdalena Petlacalco',
    'Selene',
    'Xochimilco',
    'Alvaro Obregon Borough',
    'Amecameca de Juarez',
    'Juarez',
    'Banderilla',
    'Emiliano Zapata',
    'Angamacutiro de la Union',
    'Zinapecuaro',
    'Jiquilpan de Juarez',
    'Sahuayo de Morelos',
    'Vistahermosa de Negrete',
    'Chiapa de Corzo',
    'Pericos',
    'Cajititlan',
    'Acatic',
    'San Sebastian el Grande',
    'El Sauz',
    'Coatzintla',
    'Cazones de Herrera',
    'Ciudad Valles',
    'Pedro Meoqui',
    'San Martin de Camargo',
    'Marquelia',
    'Iguala de la Independencia',
    'Lagos de Moreno',
    'San Agustin Tlaxiaca',
    'Las Delicias',
    'Ciudad Madero',
    'San Martin Notario',
    'San Salvador Huixcolotla',
    'Autopista Puebla Orizaba 185 (La Condusa)',
    'Tlaxcalancingo',
    'Jose Cardel',
    'Heroica Alvarado',
    'Tlacotalpan',
    'Medellin de Bravo',
    'San Juan Bautista Tuxtla',
    'Chiautempan',
    'Zacatelco',
    'Chignahuapan',
    'Atlzayanca',
    'Cuyoaco',
    'San Sebastian Tutla',
    'Acatzingo de Hidalgo',
    'Jesus Carranza',
    'Agua Dulce',
    'Las Choapas',
    'San Cristobal',
    'Rayon',
    'Huimanguillo',
    'Tonala',
    'La Habana',
    'Teapa',
    'Reforma (Madrigal 2da. Seccion)',
    'Pichucalco',
    'Balancan de Dominguez',
    'Paraiso',
    'Centro',
    'Mina Mexico',
    'Polanco',
    'Cuautepec',
    'Colonia Lopez Nelio (Las Palmas)',
    'Juarez',
    'Jesus del Monte',
    'Cuajimalpa',
    'Jilotepec',
    'Atlatlahucan',
    'Tepoztlan',
    'Tlaquiltenango',
    'Cocoyoc',
    'Temixco',
    'Jojutla',
    'Oaxtepec',
    'Xochitepec',
    'Tezoyuca',
    'Yecapixtla',
    'Tepetlapa',
    'Almoloya',
    'Jiquipilco',
    'Ejido Santa Maria Tianguistenco (Ejido el Rosario)',
    'San Simon de Guerrero',
    'Santiago Tianguistenco',
    'Xonacatlan',
    'San Antonio la Isla',
    'Atlacomulco',
    'Actopan',
    'Tezontepec',
    'Ciudad Sahagun',
    'Tlanalapa',
    'Tepeapulco',
    'Huehuetoca',
    'Noxtepec de Zaragoza (El Aguacate)',
    'Acolman',
    'San Sebastian Xhala',
    'Zumpango',
    'Puerto Escondido',
    'Asuncion Nochixtlan',
    'Santa Catarina Juquila',
    'Morelos',
    'Quintana Roo',
    'Isla Mujeres',
    'Colonia Veracruz',
    'Alamo',
    'Los Garzas',
    'Jimenez',
    'Ojinaga',
    'Cuauhtemoc',
    'Nuevo Coahuila',
    'Francisco I. Madero',
    'Mariano Matamoros',
    'Soto la Marina',
    'Altamira',
    'Colonia Miguel Aleman Valdez',
    'Camargo',
    'Atlacholoaya',
    'Puente Grande',
    'Mineral',
    'Atequiza',
    'Tizapan el Alto',
    'Monte Leon',
    'Dolores Hidalgo Cuna de la Independencia Nacional',
    'Ignacio Allende',
    'Apaseo el Grande',
    'Moroleon',
    'La Union',
    'Apaseo el Alto',
    'San Luis de la Paz',
    'San Felipe Torres Mochas [Ganaderia]',
    'Villagran',
    'San Miguel',
    'Tepalcatepec',
    'San Miguel el Alto',
    'Zapotlanejo',
    'Ixtlahuacan del Rio',
    'Zapotlanejo',
    'San Luis Soyatlan',
    'Autlan de Navarro',
    'Huejucar',
    'Guadalupito',
    'Juan',
    'Villa Garcia',
    'Jalpa',
    'Loreto',
    'Jacona de Plancarte',
    'Francisco Castellanos',
    'Chicago',
    'Tirindaro',
    'Erongaricuaro',
    'San Martin Hidalgo',
    'Ahualulco de Mercado',
    'Tenamaxtlan',
    'Chapala',
    'Ciudad Constitucion',
    'Loreto',
    'Todos Santos',
    'Las Canoras (Altiva)',
    'Teloloapan',
    'Escuinapa de Hidalgo',
    'Badiraguato',
    'San José del Cabo',
    'Guaymas',
    'Sonora',
    'Empalme',
    'Cabos',
    'Huatabampo',
    'Ezequiel Montes',
    'El Rio',
    'Manuel Avila Camacho',
    'El Mirador',
    'San Miguel el Alto (El Puerto)',
    'Nextlalpan',
    'San Gabriel',
    'Guadalupe Victoria',
    'Queseria',
    'Villa de Costa Rica',
    'Anahuac',
    'Ojuelos de Jalisco',
    'Pabellon de Arteaga',
    'San Pedro Piedra Gorda',
    'Tlacolula de Matamoros',
    'Heroica Coscomatepec de Bravo',
    'Salina Cruz',
    'Tocatlan',
    'Zacatlan',
    'Mineral del Chico',
    'Martinez de la Torre',
    'Coatepec',
    'Tlapacoyan',
    'La Isla',
    'Teziutlán',
    'Melchor',
    'Tequisistlan',
    'Ejido de Otumba (Loma Bonita)',
    'San Francisco Javier',
    'Pantanal',
    'Ixtapa',
    'Puerto Peñasco',
    'Bamoa',
    'Agua Prieta',
    'El Potrero',
    'Rancho Xalnene',
    'Tuxpan de Rodriguez Cano',
    'Ures',
    'Amado Nervo',
    'Juchitán de Zaragoza',
    'San Luis Río Colorado',
    'Kanasin',
    'Benito Juarez',
    'Gomez Palacio',
    'Tekoh',
    'Poza Rica de Hidalgo',
    'Zapotlan de Juarez',
    'Progreso',
    'Jaltipan de Morelos',
    'San Juan de Aragon',
    'Guadalupe',
    'Pesqueria',
    'Estados Unidos',
    'Agua Amarga',
    'El Carmen',
    'Colon',
    'Colonia de Venado',
    'Taxco',
    'San Pablo Hidalgo',
    'Coyuca de Catalan',
    'Tilzapotla',
    'Axochiapan',
    'Almada',
    'Parral',
    'Huanimaro',
    'Escuintla',
    'La Moncada',
    'Huimilpan',
    'Las Cabanas (Colinas de los Angeles)',
    'General Teran',
    'Acuna',
    'Tacotalpa',
    'Suchiapa',
    'Tenosique de Pino Suarez',
    'La Tapachula',
    'Aldama',
    'Arizpe',
    'San Pedro',
    'Frontera',
    'Monclova',
    'Ojo de Agua',
    'Puerto Mexico',
    'Chiautla',
    'Gutierrez Zamora',
    'Jimenez',
    'Ixtlahuaca de Villada',
    'Tlatlaya',
    'Satelite',
    'Pueblo Viejo',
    'San Nicolas Totolapan',
    'Monclova',
    'Canatlan',
    'Nuevo Ideal',
    'San Miguel de Papasquiaro',
    'Tlatlauquitepec',
    'Cosamaloapan de Carpio',
    'Ursulo Galvan',
    'Tierra Colorada',
    'Pueblo Yaqui',
    'San Fernando',
    'Roma',
    'Coahuila',
    'Coyoacan',
    'Pabellon',
    'Alamos',
    'San Cristobal',
    'San Fernando',
    'San Luis',
    'San Pedro Pochutla',
    'Huichapan',
    'Ciudad Sabinas',
    'Catemaco',
    'Tepeaca',
    'Mier',
    'Anahuac',
    'Colonia la Presa (Ejido de Chapultepec)',
    'Las Americas',
    'Santa Catalina',
    'Jalostotitlan',
    'San Jeronimo de Juarez',
    'Primero de Mayo',
    'La Joya',
    'Paso Real',
    'Jalisco',
    'Jesus Maria',
    'Jocotitlan',
    'Tequixquiac',
    'Apaxco de Ocampo',
    'Teotihuacan de Arista',
    'San Luis',
    'La Herradura',
    'Cardonal',
    'Zacualtipan',
    'Apan',
    'Tezontepec de Aldama',
    'Mineral del Monte',
    'Tezoyuca',
    'San Pedro de Las Colonias',
    'Magiscatzin',
    'Xochimilco',
    'Jerez de Garcia Salinas',
    'San Salvador Atenco',
    'El Parque',
    'Texcoco',
    'Azteca',
    'Barra de Navidad',
    'Compostela',
    'Santa Fe',
    'San Felipe',
    'Cutzamala de Pinzon',
    'San Agustin Altamirano',
    'Puente de Ixtla',
    'Jonacatepec de Leandro Valle',
    'Arcelia',
    'San Pedro',
    'Mazatepec',
    'San Martin Duraznos',
    'Pachuca de Soto',
    'San Antonio Virreyes',
    'Cerritos',
    'San Luis Potosí',
    'Loma de los Cavazos (Maria de la Luz)',
    'Visitacion',
    'San Rafael',
    'Leyva Solano',
    'Chihuahua',
    'Prolongacion Avenida Mexico Norte',
    'Barrio de Mexico',
    'Santa Lucia',
    'San Pedro',
    'San Angel Inn',
    'Pedregal de Jerico',
    'San Miguel',
    'Adolfo Lopez Mateos',
    'San Vicente',
    'Arandas',
    'Uriangato',
    'Tamaulipas',
    'Laredo',
    'Villa de Almoloya de Juarez',
    'Florida',
    'Sierra Madre',
    'Colonia',
    'Piedras Negras',
    'San Miguel de Atotonilco',
    'San Miguel Balderas',
    'San Francisco',
    'Moctezuma',
    'Tepetlacolco',
    'Benito Juarez (Santa Elena)'
  ],
  Canada: [
    'Toronto',
    'Montreal',
    'Vancouver',
    'Lachine',
    'Mississauga',
    'Leamington',
    'Camrose',
    'Richmond',
    'Markham',
    'Creston',
    'Coquitlam',
    'Victoria',
    'Port Coquitlam',
    'North Vancouver',
    'Burnaby',
    'Surrey',
    'Delta',
    'Vernon',
    'New Westminster',
    'Langley',
    'Kamloops',
    'Maple Ridge',
    'Abbotsford',
    'Chilliwack',
    'Whistler Village',
    'Kelowna',
    'Port Moody',
    'Calgary',
    'Lethbridge',
    'Edmonton',
    'Lacombe',
    'Fort McMurray',
    'Fort Saskatchewan',
    'Red Deer',
    'Morinville',
    'Spruce Grove',
    'Sherwood Park',
    'Okotoks',
    'Strathmore',
    'Airdrie',
    'High Level',
    'Fairview, Alberta',
    'Leduc',
    'Grande Prairie',
    'Chatham',
    'Tavistock',
    'Perth',
    'Midland',
    'Laval',
    'Hamilton',
    'Ottawa',
    'Fergus',
    'Scarborough',
    'Cambridge',
    'Waterloo',
    'Welland',
    'Vieux-Saint-Laurent',
    'Kitchener',
    'Longueuil',
    'Oshawa',
    'Brampton',
    'Rougemont',
    'Saint-Remi',
    'London',
    'Milton',
    'Pointe-Claire',
    'Sherbrooke',
    'Kanata',
    'Gatineau',
    'Lasalle',
    'Quebec',
    'Beauport',
    'Donnacona',
    'Charlesbourg',
    'Saint-Augustin',
    'Joliette',
    'Saint-Felicien',
    'Dolbeau-Mistassini',
    'Albanel',
    'Port Clements',
    'Queen Charlotte',
    'Masset',
    'Ingersoll',
    'Alma',
    'Hawkesbury',
    'Shannonville',
    'Elk Point',
    'Burgessville',
    'Brantford',
    'Woodstock',
    'Thornhill',
    'Stoney Creek',
    'Caledonia',
    'Burlington',
    'Grimsby',
    'Vineland Station',
    'Beamsville',
    'Niagara Falls',
    'Smithville',
    'Jordan',
    'Peterborough',
    'Granby',
    'Cowansville',
    'Boisbriand',
    'Levis',
    'Sorel',
    'Blainville',
    'Mont-Royal',
    'Saint-Michel-des-Saints',
    'Saint-Hubert-de-Riviere-du-Loup',
    'Rosemere',
    'Sainte-Marthe',
    'Nominingue',
    'Saint-Eustache',
    'Sainte-Catherine',
    'Terrebonne',
    'Saint-Leonard',
    'Westmount',
    'Kirkland',
    'Delson',
    'Saint-Sauveur',
    'Saint-Jean-sur-Richelieu',
    'Sabrevois',
    'Saint-Lambert',
    'Mascouche',
    'Saint-Jerome',
    'Chambly',
    'Varennes',
    'Mirabel',
    'Rivière-du-Loup',
    'Brossard',
    'Repentigny',
    'Sainte-Therese',
    'Dorval',
    'Leonard',
    'Saint-Lin-Laurentides',
    'Verdun',
    'Saint-Agapit',
    'Boucherville',
    'Jonquiere',
    'Chicoutimi',
    'Saint-Lambert',
    'Brossard',
    'Candiac',
    'Kincardine',
    'North Perth',
    'Stratford',
    'Les Escoumins',
    'Saint-Jacques',
    "L'Assomption",
    'Greenfield Park',
    'Deux-Montagnes',
    'Waterville',
    'Amos',
    'La Morandiere',
    'Barraute',
    "Val-d'Or",
    'Rouyn-Noranda',
    'Saint-Prosper',
    'Saint-Georges',
    'Saint-Simon-de-Rimouski',
    'Saint-Victor',
    'La Guadeloupe',
    'Alma',
    'Drummondville',
    'Roberval',
    'Asbestos',
    'Rimouski',
    'Saint-Anaclet-de-Lessard',
    'Trois-Rivières',
    'Magog',
    'Shawinigan',
    'Sainte-Agathe-des-Monts',
    'Sainte-Adele',
    'Sainte-Anne-des-Monts',
    'Salaberry-de-Valleyfield',
    'Saint-Germain-de-Grantham',
    'Thetford-Mines',
    'Champlain',
    'North York',
    'Aurora',
    'Richmond Hill',
    'Orleans',
    'Etobicoke',
    'Guelph',
    'Whitby',
    'Pickering',
    'Vercheres',
    'Saint-Gabriel-de-Valcartier',
    'Canning',
    'Wolfville',
    'Kentville',
    'Centreville',
    'Cambridge',
    'Ancaster',
    'Jerseyville',
    'North Russell',
    'Cote-Saint-Luc',
    'Mont-Tremblant',
    'Saint-Faustin--Lac-Carre',
    'Plessisville',
    'Port-Cartier',
    'Sept-Iles',
    'Montmagny',
    'Notre-Dame-du-Mont-Carmel',
    'Shawinigan-Sud',
    'Saint-Mathieu',
    'Windsor',
    'Belle River',
    'Tecumseh',
    'Kingsville',
    'Amherstburg',
    'Newmarket',
    'Essex',
    'Harrow',
    'Wheatley',
    'Tecumseh',
    'Memphremagog',
    'Stettler',
    'Rocky Mountain House',
    'Banff',
    'Canmore',
    'Didsbury',
    'Carstairs',
    'Blairmore',
    'Pincher Creek',
    'Coleman',
    'Cowley',
    'Taber',
    'Brooks',
    'Drumheller',
    'High River',
    'Drayton Valley',
    'Barrhead',
    'Edson',
    'Lloydminster',
    'Vermilion',
    'Winnipeg',
    'Hinton',
    'Jasper',
    'Westlock',
    'Kimberley',
    'Cranbrook',
    'Marysville',
    'Fernie',
    'Canyon',
    'Golden',
    'Invermere',
    'Fairmont Hot Springs',
    'Penticton',
    'Summerland',
    'Okanagan Centre',
    'Westbank',
    'Chase',
    'Nelson',
    'Coldstream',
    'Port Alberni',
    'Sooke',
    'Courtenay',
    'Comox',
    'Parksville',
    'Cumberland',
    'Winfield',
    'Enderby',
    'Salmon Arm',
    'Armstrong',
    'Sorrento',
    'Williams Lake',
    'Grand Forks',
    'Greenwood',
    'Peachland',
    'Regina',
    'Rosetown',
    'Canora',
    'Kindersley',
    'La Ronge',
    'Humboldt',
    'Warman',
    'Melfort',
    'Meadow Lake',
    'Unity',
    'Esterhazy',
    'Kamsack',
    'North Battleford',
    'Weyburn',
    'Maidstone',
    'Estevan',
    'Yorkton',
    'Melville',
    'Maple Creek',
    'Moosomin',
    'Saint-Pierre-de-Broughton',
    'Saint-Hyacinthe',
    'Steinbach',
    'Stonewall',
    'Selkirk',
    'Teulon',
    'Pine Falls',
    'Beausejour',
    'Thompson',
    'St. Paul',
    'Portage la Prairie',
    'Tsawwassen',
    'White Rock',
    'Sechelt',
    'Mission',
    'Biggar',
    'Midale',
    'Nipawin',
    'Lampman',
    'Saskatoon',
    'Halifax',
    'Bridgewater',
    'Italy Cross',
    'Lunenburg',
    'Shelburne',
    'Mahone Bay',
    'Dartmouth',
    'Eastern Passage',
    'Sydney',
    'Truro',
    'Springhill',
    'Halifax',
    'Sackville',
    'Mount Uniacke',
    'Beaver Bank',
    'Port Dover',
    'Canfield',
    'Dunnville',
    'Hagersville',
    'Waterford',
    'Cayuga',
    'Sidney',
    'Crofton',
    'Saanichton',
    'North Saanich',
    'Mayne',
    'Shawnigan Lake',
    'Duncan',
    'Chemainus',
    'Lake Cowichan',
    'Cobble Hill',
    'Ladysmith',
    'Powell River',
    'Van Anda',
    'Sault Ste. Marie',
    'Manitouwadge',
    'Wawa',
    'Thunder Bay',
    'Atikokan',
    'Terrace Bay',
    'Schreiber',
    'Woodbridge',
    'Shelburne',
    'Bolton',
    'Puslinch',
    'York',
    'Acton',
    'Stouffville',
    'Ajax',
    'Orangeville',
    'Glencoe',
    'Caledon',
    'Oakville',
    'Norfolk County',
    'Tillsonburg',
    'Orillia',
    'Millbrook',
    'Arthur',
    'North Hatley',
    'Roxboro',
    'Crabtree',
    'Huntingdon',
    'Beloeil',
    'Rawdon',
    'Bourget',
    'Niagara-on-the-Lake',
    'Concord',
    'Maskinonge',
    'Saint-Maurice',
    'Saint-Barthelemy',
    'Batiscan',
    'Pierreville',
    'Becancour',
    'Nicolet',
    'Saint-Etienne-des-Gres',
    'Yamachiche',
    'Gaspé',
    'Baie-Comeau',
    'Saint-Pierre',
    'Sainte-Angele-de-Premont',
    'Chandler',
    'Val-David',
    'Yamaska',
    'Matane',
    'Saint-Ulric',
    "Saint-Leonard-d'Aston",
    'Deschaillons-sur-Saint-Laurent',
    'Aston-Jonction',
    'Sainte-Perpetue',
    'Saint-Cyrille-de-Wendover',
    'Roxton Falls',
    'Richmond',
    'Disraeli',
    'Normandin',
    'Saint-Gedeon',
    'Louiseville',
    'Saint-Dominique',
    'East Broughton',
    'Desbiens',
    'Hebertville',
    'Carmel',
    'Sainte-Anne-de-la-Perade',
    "Ayer's Cliff",
    'Windsor',
    'Tumbler Ridge',
    'Osoyoos',
    'Oliver',
    'Fenelon Falls',
    'Lindsay',
    'Bobcaygeon',
    'Dunsford',
    'Potter',
    'Vaughan',
    'Murray River',
    'Charlottetown',
    'New Glasgow',
    'Trenton',
    'Stellarton',
    'Sydney',
    'North Sydney',
    'Villa Marie',
    'Yarmouth',
    'Digby',
    'Brookfield',
    'Victoria',
    "Clarke's Beach",
    'Harbour Grace',
    'Bellevue',
    'Bay Roberts',
    "Heart's Delight-Islington",
    'Summerside',
    'Coleman',
    'Annapolis Royal',
    'Oxford',
    'Amherst',
    'Fogo',
    'Greenspond',
    'Lumsden',
    'Digby',
    'Antigonish',
    'Kensington',
    'Arichat',
    'Port Hawkesbury',
    "D'Escousse",
    'Petit-de-Grat',
    'Cornwall',
    'Georgetown',
    'New Hamburg',
    "St. John's",
    'Mount Pearl',
    'Bedford',
    'Church Point',
    'Placentia',
    'Meteghan River',
    'East Chezzetcook',
    'Sydney Mines',
    'Stratford',
    'Windsor',
    'Barrie',
    'King City',
    'Beeton',
    'Alliston',
    'Bradford',
    'Uxbridge',
    'Angus',
    'Schomberg',
    'Tottenham',
    'Caledon',
    'Greater Sudbury',
    'Lively',
    'Chelmsford',
    'New Liskeard',
    'Timmins',
    'Copper Cliff',
    'Elliot Lake',
    'Blind River',
    'Casimir',
    'Massey',
    'Rockwood',
    'Belleville',
    'Sturgeon Falls',
    'North Bay',
    'Aylmer',
    'Garson',
    'Port Elgin',
    'Hanover',
    'Listowel',
    'Mildmay',
    'Palmerston',
    'Wingham',
    'Mount Forest',
    'Chesley',
    'Kingston',
    'Napanee',
    'Simcoe',
    'Marmora',
    'Campbellford',
    'Napanee',
    'Winchester',
    'Walkerton',
    'Chesterville',
    'Cornwall',
    'Kapuskasing',
    'Picton',
    'Fort-Coulonge',
    'Owen Sound',
    'Durham',
    'Britt',
    'Gore Bay',
    'Dundas',
    'Waterdown',
    'Carlisle',
    'Hanna',
    'Lougheed',
    'Wetaskiwin',
    'Cold Lake',
    'Calmar',
    'Clairmont',
    'Bonnyville',
    'Aylesford',
    'Berwick',
    'Greenwood',
    'Middleton',
    'Kingston',
    'Three Mile Plains',
    'Saint-Frederic',
    'Rigaud',
    'Huntsville',
    'Bracebridge',
    'Gravenhurst',
    'Parry Sound',
    'Maple',
    'Saint-Joseph-de-Beauce',
    'La Patrie',
    'Saint-Camille',
    'Eastman',
    'Napierville',
    'Austin',
    'Martinville',
    'Compton',
    'Sawyerville',
    'Sainte-Marie',
    'Saint-Louis-de-Gonzague',
    'Trenton',
    'Corbyville',
    'Petawawa',
    'Pembroke',
    'Grenville',
    'Elora',
    'Vaudreuil-Dorion',
    'Lachute',
    'Saint-Basile-le-Grand',
    'Chateauguay',
    "Sainte-Sophie-d'Halifax",
    'Pointe-aux-Trembles',
    'Mont-Saint-Hilaire',
    'Otterburn Park',
    "Circonscription electorale d'Anjou",
    'Howick',
    'Pointe-Calumet',
    'La Pocatiere',
    'Ange-Gardien',
    'Saint Romuald',
    'Saint-Honore-de-Temiscouata',
    'Dollard-Des Ormeaux',
    'Sainte-Julie',
    "L'Epiphanie",
    'La Prairie',
    "Baie-D'Urfe",
    'Oka',
    "Saint-Jean-de-l'Ile-d'Orleans",
    'Beaupre',
    'Marieville',
    'Clarence-Rockland',
    'Contrecoeur',
    'Maniwaki',
    'Saint-Lin',
    'Lavaltrie',
    'Hampton',
    'Saint John',
    'Luskville',
    'Hudson',
    'Bruno',
    'Saint-Joachim-de-Shefford',
    'McMasterville',
    'Mercier',
    'Sainte-Julienne',
    'Saint-Antonin',
    'Tadoussac',
    'Chute-aux-Outardes',
    'Pointe-Lebel',
    'Beaconsfield',
    'Saint-Marc-sur-Richelieu',
    'Portneuf',
    'Farnham',
    'Saint-Venant-de-Paquette',
    'Vallee-Jonction',
    'Sutton',
    'Saint-Zacharie',
    'Lac-Megantic',
    'Brome',
    'Sainte-Cecile-de-Whitton',
    'Ormstown',
    'Stanbridge East',
    'Dudswell',
    'Saint-Elzear',
    'St. Catharines',
    'Callander',
    'Renfrew',
    'Gibsons',
    'Prince George',
    'Princeton',
    'Merritt',
    'Fort Nelson',
    'Mackenzie',
    'Roberts Creek',
    'Halfmoon Bay',
    'La Sarre',
    'Dupuy',
    'Notre-Dame-du-Nord',
    'Ville-Marie',
    'Temiscaming',
    'Macamic',
    'Massueville',
    'Saint-Hugues',
    'Malartic',
    'Bearn',
    'La Tuque',
    'Laverlochere',
    'Richmond',
    'St. Thomas',
    'Nepean',
    'Stewiacke',
    'Valley',
    'Westville',
    'Eureka',
    'Pictou',
    'Marystown',
    'Troy',
    'La Conception',
    'Middle Musquodoboit',
    'Hebron',
    'Cavendish',
    'Grono Road',
    'Jordan Falls',
    'Barrington Passage',
    'Barrington',
    'Crapaud',
    'Brigus',
    'Clarenville',
    'Blackville',
    'Scotsburn',
    "Hant's Harbour",
    'Carbonear',
    'Holyrood',
    'Cornwallis',
    'Black Diamond',
    'Trochu',
    'Three Hills',
    'Bonne Bay',
    'Fortune',
    'Morell',
    'Bridgetown',
    'Clementsvale',
    'Bear River',
    'Springdale',
    'Murray Harbour',
    'Seldom-Little Seldom',
    'Liverpool',
    'Port Medway',
    'Paradise Cove',
    'Victoria Cove',
    'Birchy Bay',
    'Gander',
    'Twillingate',
    'Burin',
    "St. Alban's",
    'West Lake',
    'Margaretsville',
    'Hampton',
    'Corner Brook',
    'Wainwright',
    "Daniel's Harbour",
    'Tusket',
    'Saulnierville',
    'Rockville',
    'Chester',
    'Baie Verte',
    'Happy Valley-Goose Bay',
    'Gambo',
    'Granville Ferry',
    'Western Bay',
    'Old Perlican',
    'Bay de Verde',
    "Circonscription electorale d'Outremont",
    'Bromont',
    'Victoriaville',
    'Acton Vale',
    'Valcourt',
    'Metabetchouan-Lac-a-la-Croix',
    'Wickham',
    'Warwick',
    'La Presentation',
    'Saint-Damase',
    'La Baie',
    "L'Anse-Saint-Jean",
    'Pont-Rouge',
    'Baie-Saint-Paul',
    'Mitchell',
    'Paisley',
    'Iroquois Falls',
    'Kirkland Lake',
    'Levack',
    'Omemee',
    'Pefferlaw',
    'Goderich',
    'Clinton',
    'Exeter',
    'Bayfield',
    'Prescott',
    'Berthierville',
    'Lakefield',
    'Cobourg',
    'Baltimore',
    'Port Hope',
    'Port Perry',
    'Kitimat',
    'Terrace',
    'Smithers',
    'Prince Rupert',
    'Roseneath',
    'Brandon',
    'Stittsville',
    'Norway House',
    'Winkler',
    'Port Colborne',
    'Noyan',
    'Fredericton',
    'Moncton',
    'Perth-Andover',
    'Tracadie–Sheila',
    'Miramichi',
    'Woodstock',
    'Edmundston',
    'Campbellton',
    'Bathurst',
    'Souris',
    'Coniston',
    'Saint-Quentin',
    'Plaster Rock',
    'Neguac',
    'Canterbury',
    'Rogersville',
    'Doaktown',
    'Lavillette',
    'Hartland',
    'Nackawic',
    'Pointe-Verte',
    'Dover',
    'Lewisporte',
    'Port Saunders',
    "Saint Fintan's",
    "L'Anse-au-Loup",
    'Deer Lake',
    'Grand Falls-Windsor',
    'Conception Bay South',
    'Hantsport',
    'Bay Bulls',
    'Dieppe',
    'Berry Mills',
    'Rothesay',
    'Quispamsis',
    'South Porcupine',
    'Haileybury',
    'Grand Bay–Westfield',
    'Timberlea',
    'Elmsdale',
    'Carters Cove',
    'Mira Gut',
    'Sable River',
    'Alberton',
    'Glace Bay',
    'New Waterford',
    'Portugal Cove',
    'Grand Falls',
    'Pouch Cove',
    'Sicamous',
    'Tappen',
    'Pritchard',
    'Pitt Meadows',
    'Fort St. John',
    'Onoway',
    'St. Albert',
    'Oyama',
    'Aldergrove',
    'Squamish',
    'Garibaldi',
    'Brackendale',
    'Trail',
    'Castlegar',
    'Hope',
    'Vanderhoof',
    'Fort St. James',
    'Lumby',
    'Sexsmith',
    'McLennan',
    'Peace River',
    'Grimshaw',
    'Beaverlodge',
    'Rycroft',
    'Irricana',
    'Turner Valley',
    'Bassano',
    'Innisfail',
    'Blackfalds',
    'Sylvan Lake',
    'Ponoka',
    'Campbell River',
    'Medicine Hat',
    'Redcliff',
    'Whitecourt',
    'Magrath',
    'Claresholm',
    'Raymond',
    'Bellevue',
    'Donnelly',
    'Wallaceburg',
    'Mooretown',
    'Sarnia',
    'Rockland',
    'Innisfil',
    'Collingwood',
    'Thamesville',
    'Mandeville',
    "Sainte-Emelie-de-l'Energie",
    'Thurso',
    'Sainte-Cecile-de-Masham',
    'Kemptville',
    'Petrolia',
    'Glen Robertson',
    'Long Sault',
    'Ridgeway',
    'Keswick Beach',
    'Paris',
    'Harriston',
    'Alexandria',
    'Cardinal',
    'Norwood',
    'Pinawa',
    'Weir',
    "L'Ile Perrot",
    'La Salle',
    'Milford',
    'Milford Station',
    'Bancroft',
    'Belwood',
    'Cochrane',
    'Hearst',
    'Moosonee',
    'Matheson',
    'Earlton',
    'Iqaluit',
    'Gloucester',
    'Niagara',
    'Drumbo',
    'Delhi',
    'Devon',
    'Gananoque',
    'Bath',
    'Newburgh',
    'Stella',
    'Sydenham',
    'Moraviantown',
    'Blenheim',
    'Oil Springs',
    'Merlin',
    'Garibaldi Highlands',
    'Dauphin',
    'Gilbert Plains',
    'Lang',
    'Rouleau',
    'Moose Jaw',
    'Wadena',
    'Foam Lake',
    'Theodore',
    'Wynyard',
    'Kelvington',
    'Swift Current',
    'Grenfell',
    'Carlyle',
    'North Portal',
    'Redvers',
    'Wakaw',
    'Dundurn',
    'Prince Albert',
    'Indian Head',
    'Broadview',
    'Wawota',
    'Wilkie',
    'Kerrobert',
    'Macklin',
    'Coleville',
    'Bella Bella',
    'Valemount',
    'Kitwanga',
    'Kitchener',
    'Dawson Creek',
    'Port Renfrew',
    'Nakusp',
    'Skidegate',
    'Revelstoke',
    'Westport',
    'Elgin',
    'Yarker',
    'Saint-Jean-de-Matha',
    'Port Royal',
    'Slave Lake',
    'Valleyview',
    'Morden',
    'Atikameg',
    'Forestburg',
    'Bashaw',
    'Quesnel',
    'Port McNeill',
    'Ucluelet',
    'Tofino',
    'Merville',
    'Nanaimo',
    'Heron Bay',
    'Sioux Lookout',
    'Kuujjuaq',
    'Gogama',
    'Sandy Lake',
    'Cat Lake',
    'Ivujivik',
    'Pikangikum',
    'Bearskin Lake',
    'Fermont',
    'Ohsweken',
    'Griswold',
    'Swan River',
    'Lashburn',
    'Onion Lake',
    'Eston',
    'Outlook',
    'Mortlach',
    'Cabri',
    'Dryden',
    'Saint-Andre',
    'Saint-Sebastien',
    'Nantes',
    'Vankleek Hill',
    'Haliburton',
    'Espanola',
    'Thorold',
    'Mount Brydges',
    'Grand Valley',
    'Camlachie',
    'Deseronto',
    'Ayr',
    'Yellowknife',
    'Cambridge Bay',
    'Kugaaruk',
    'Sanikiluaq',
    'Rankin Inlet',
    'Kugluktuk',
    'Deline',
    'Orton',
    'Eaglesham',
    'Lachenaie',
    'Atlin',
    'Shaunavon',
    'Saint-Guillaume',
    'Masson-Angers',
    'Montreal East',
    'Bowmanville',
    'Melbourne Ridge',
    'Saint-Gabriel',
    'Finch',
    'Arnprior',
    'Saint-Pie',
    'Stayner',
    'Limoges',
    'Greely',
    'Sainte-Melanie',
    'Carleton Place',
    'Brockville',
    'Coldwater',
    'Everett',
    'Fort Frances',
    'Ignace',
    'La Malbaie',
    'Baie-Sainte-Catherine',
    'Casselman',
    'Almonte',
    'Thorndale',
    'Markdale',
    'Wiarton',
    'Saint-Zotique',
    'Kohler',
    'Holman',
    'Galahad',
    'Neudorf',
    'Kars',
    'Ardrossan',
    'Apsley',
    'Havelock',
    "Lion's Head",
    'Clermont',
    'Eden',
    'Wyoming',
    'Morrisburg',
    'Port Burwell',
    'Mont-Joli',
    'Metis-sur-Mer',
    'Saint-Fabien',
    'Sainte-Croix',
    'Czar',
    'Cherry Grove',
    'Athabasca',
    'Mannville',
    'Paradise Valley',
    'Nampa',
    'Lac La Biche',
    'Wolseley',
    'Montmartre',
    'Balcarres',
    'Wood Mountain',
    'Glentworth',
    'Saint-Colomban',
    'Cookshire-Eaton',
    'Saint-Pacome',
    'Saint-Philippe-de-Neri',
    'Saint-Roch-des-Aulnaies',
    'Saint-Jean-Port-Joli',
    'Saint-Philippe',
    'Kamouraska',
    'Saint-Aubert',
    'Les Cedres',
    "L'Islet",
    'Lorraine',
    'Bois-des-Filion',
    'Deer Lake',
    'Inukjuak',
    'Poplar Hill',
    'Fort Hope',
    'Kangiqsujuaq',
    'Tasiujaq',
    'Fort Severn',
    'Trois Pistoles',
    'Saint-Raymond',
    'St. Andrews',
    'Hay Lakes',
    'Houston',
    'Fruitvale',
    'Pemberton',
    'Clearwater',
    'Cache Creek',
    'Logan Lake',
    'Savona',
    'Landrienne',
    'Prince',
    'Saint-Elie',
    'Beauceville',
    'Sainte-Monique',
    'Daveluyville',
    'Bluewater',
    'Hensall',
    'Lucan',
    'Grand Bend',
    'Dublin',
    'Huron East',
    'Norwich',
    'Ailsa Craig',
    'Thedford',
    'Saint-Adalbert',
    'Lotbiniere',
    'Saint-Apollinaire',
    'Cap-Saint-Ignace',
    'Little Current',
    'South River',
    'Markstay',
    'Fort Macleod',
    'Hawkestone Beach',
    'Midhurst',
    'Hythe',
    'Chestermere',
    'Moosehorn',
    'Emsdale',
    'Kagawong',
    'Inverness',
    'Roland',
    'Milk River',
    'Dashwood',
    'Lucknow',
    'Kippen',
    'Ripley',
    'Kirkton',
    'Daysland',
    'Viking',
    'Rosalind',
    'Willingdon',
    'Vulcan',
    'Lake Louise',
    'Vegreville',
    'Saint-Cesaire',
    'Baden',
    'Alvinston',
    'Arkona',
    'Beaverton',
    'Hemmingford',
    'Emo',
    "L'Ascension",
    'Saint-Eusebe',
    'Saint-Zenon',
    'Saint-Donat',
    'Tweed',
    'Saint-Ferdinand',
    'Deep River',
    'Ingleside',
    'Don Mills',
    'Hespeler',
    'Erin',
    'Stirling',
    'Saint-Come',
    'Ilderton',
    'Arundel',
    'Alfred',
    'Weedon',
    'Port Stanley',
    'Chertsey',
    'South Lancaster',
    'Lancaster',
    'Brighton',
    'Colborne',
    'Carrying Place',
    'Lyn',
    'Athens',
    'Brochet',
    "L'Orignal",
    'Verner',
    'Ayton',
    'Dominion City',
    'Inwood',
    'Watford',
    'Blue River',
    'Sainte-Brigitte-des-Saults',
    'Big River',
    'Canwood',
    'Spiritwood',
    'Francis',
    'Willow Bunch',
    'Assiniboia',
    'Gravelbourg',
    'Lafleche',
    'Viscount',
    'Allan',
    'Gull Lake',
    'St. Walburg',
    'Glaslyn',
    'Ile-a-la-Crosse',
    'La Loche',
    'Canoe Narrows',
    'Arborfield',
    'Carrot River',
    'Cumberland House',
    'Langenburg',
    'Rocanville',
    'Milden',
    'Elrose',
    'Elbow',
    'Buffalo Narrows',
    'Hanley',
    'Oxdrift',
    'Wabigoon',
    'Saint-Stanislas-de-Kostka',
    'Clifford',
    'Teeswater',
    'Watrous',
    'Watson',
    'Southend Reindeer',
    'White Fox',
    'Nokomis',
    'Lumsden',
    'Southey',
    'Cupar',
    'Stevensville',
    'Fort Erie',
    'Cochrane',
    'Lancaster Park',
    'Bentley',
    'Beaumont',
    'Stony Plain',
    'Redwater',
    'Grand-Remous',
    'Namur',
    'Papineauville',
    'Ripon',
    'Messines',
    'Shawville',
    'Portage-du-Fort',
    'Laurentia Beach',
    'Downsview',
    'Enterprise',
    'Leader',
    'Rosthern',
    'Waldheim',
    'Radville',
    'Herbert',
    'Pilot Butte',
    'Frobisher',
    'Wishart',
    'Dalmeny',
    'Langham',
    'Silton',
    'Neilburg',
    'Caronport',
    'Brechin',
    'Chicoutimi',
    'Minden',
    'Senneterre',
    'Saint-Marc-de-Figuery',
    'Sainte-Gertrude',
    'Kenora',
    'Keewatin',
    'Niverville',
    'Shoal Lake',
    'Nanton',
    'Rimbey',
    'Langdon',
    'Nobleford',
    'Stirling',
    'Rainbow Lake',
    'Foremost',
    'Milverton',
    'Atwood',
    'Saint-Ludger',
    'Lambton',
    'Saint-Severe',
    'Saint-Alexis-des-Monts',
    'Saint-Pierre-les-Becquets',
    'La Visitation',
    'Saint-Liboire',
    'Saint-Paulin',
    'Grand-Saint-Esprit',
    'Parisville',
    'Saint-Barnabe-Sud',
    'Saint-Wenceslas',
    'Coleraine',
    'Manseau',
    'Saint-Sylvere',
    'Cleveland',
    'River John',
    'Mabou',
    "Burk's Falls",
    'Forest',
    'Mount Elgin',
    'Courtland',
    'Sainte-Anne-de-Bellevue',
    'Saint-Roch-de-Richelieu',
    'Saint-Charles-sur-Richelieu',
    'Pincourt',
    'Shannon',
    'Saint-Bruno-de-Montarville',
    'Calixa-Lavallee',
    'Beaver Crossing',
    'Quaqtaq',
    'Puvirnituq',
    'Kangirsuk',
    'Oxford House',
    'Saint-Jean-de-Dieu',
    'Fonthill',
    "L'Ancienne-Lorette",
    'Lanark',
    'Roblin',
    'Ridgetown',
    'Saint-Paul-de-la-Croix',
    'Riviere-Saint-Paul',
    'Madoc',
    'Nakina',
    'Frankford',
    'Dorchester',
    'Saint-Isidore',
    'Nobleton',
    'Saint-Denis',
    'Riviere-Beaudette',
    'McGregor',
    'Foymount',
    'Cheapside',
    'West Osgoode',
    'Williamsburg',
    'Port Hardy',
    'Sointula',
    'Lefroy',
    'Smiths Falls',
    'Sundridge',
    'Alton',
    'Port Credit',
    'Val-des-Monts',
    'Newcastle Village',
    'Severn Bridge',
    'Penetanguishene',
    'Wainfleet',
    'Maidstone',
    'Washago',
    'Coboconk',
    'Biencourt',
    'Cap-Chat',
    'Saint-Joachim',
    'Sainte-Marcelline-de-Kildare',
    'Lanoraie',
    'Wasaga Beach',
    'Tilbury',
    'Labelle',
    'Spanish',
    'Dundalk',
    'Echo Bay',
    'Saint-Roch',
    'Geraldton',
    'South Gower',
    'Drayton',
    'Saint-Léonard',
    'Bala',
    'Elmvale',
    'Saint-Anicet',
    'Pointe-Fortune',
    'Manotick Station',
    'Clarkson',
    'Ascot Corner',
    'Tring-Jonction',
    'Begin',
    'Montebello',
    'Plantagenet Station',
    'Waubaushene',
    'Bowden',
    'Olds',
    'Penhold',
    'Coaldale',
    'Clive',
    'Gabriola',
    'Qualicum Beach',
    'Nanoose Bay',
    'Bowser',
    'Honeymoon Bay',
    'Youbou',
    'Cowichan Bay',
    'Agassiz',
    'Crossfield',
    'Bow Island',
    'Red Rock',
    'Nipigon',
    'White River',
    'Marathon',
    'Red Lake',
    '100 Mile House',
    'Rossland',
    'Lillooet',
    'Charlemagne',
    'Saint-Lazare-de-Bellechasse',
    'Saint-Felix-de-Valois',
    'Saint-Charles-de-Bellechasse',
    'Sainte-Martine',
    'Lery',
    'Gore',
    'Saint-Hilaire',
    'Beauharnois',
    'Colliers',
    'Port au Port',
    'Stephenville',
    'Musquodoboit Harbour',
    'Middle Arm',
    'Shubenacadie',
    'Plympton',
    'Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico',
    'Arcadia',
    'New-Wes-Valley',
    'Enfield',
    'Burgeo',
    'Colinet',
    'Upper Island Cove',
    'West Chezzetcook',
    'Lawrencetown',
    "Fort Qu'Appelle",
    'Maryfield',
    'Bredenbury',
    'Luseland',
    'Waldeck',
    'Duck Lake',
    'Calder',
    'Hudson Bay',
    'Lucky Lake',
    'Dinsmore',
    'Carnduff',
    'Lipton',
    'Domain',
    'Lac-Drolet',
    'Knowlton',
    'Dixville',
    'Saint-Sylvestre',
    'Scotstown',
    'Racine',
    'Cardston',
    'Courtright',
    'Point Edward',
    'Fraser Lake',
    'Alexander',
    'Shamattawa',
    'Sheho',
    'Cumberland',
    'Foxtrap',
    "Bishop's Falls",
    'Grande-Anse',
    'Strathroy',
    'Elmira',
    'Thamesford',
    'Memramcook',
    'Ashmont',
    'Myrnam',
    'Two Hills',
    'Thorhild',
    'Lamont',
    'Tofield',
    'Holden',
    'Mundare',
    'Irma',
    'Boyle',
    'Turtleford',
    'Woodridge',
    'Berwyn',
    'Hastings',
    'Buckhorn',
    'MacTier',
    'Saint Etienne-De-Lauzon',
    'Creemore',
    'Sombra',
    'New Dundee',
    'Saint-Francois-du-Lac',
    'Charny',
    'Saint-Odilon-de-Cranbourne',
    'Sainte-Justine',
    'Fortierville',
    'Saint-Fabien-de-Panet',
    'Baie-du-Febvre',
    'Dunham',
    'East Angus',
    'Roxton Pond',
    'Berthier-Sur-Mer',
    'Cabano',
    'Saint-Vallier',
    'Riviere-Bleue',
    'Coaticook',
    'Cheneville',
    'Sainte-Claire',
    'Melocheville',
    'Saint-Henri',
    'Saint-Anselme',
    'Virden',
    'Cromer',
    'Saint-Godefroi',
    'McCreary',
    'Killarney',
    'Rivers',
    'Fort Albany',
    'Attawapiskat',
    'Amqui',
    'Kingsey Falls',
    'Ham-Nord',
    'Blandford',
    'Eatonville',
    'Saint-Tite',
    'Saint-Stanislas',
    'Chelsea',
    'Clarke City',
    'Val-Brillant',
    'Fort Kent',
    'Girouxville',
    'Mallaig',
    'Smoky Lake',
    'Andrew',
    'Glendon',
    'Woodlands',
    'Stony Mountain',
    'Altona',
    'Sainte-Helene-de-Bagot',
    'Armagh',
    'Saint-Alban',
    'Saint-Georges-de-Windsor',
    'Saint-Adelphe',
    'Murdochville',
    'Paspebiac',
    'Newport',
    'Grande-Riviere',
    'Maria',
    'Saint-Michel-de-Bellechasse',
    'Deschambault',
    'Cap-Sante',
    'Saint-Basile',
    'Saint-Antoine-de-Tilly',
    'Saint-Philemon',
    'Saint-Pamphile',
    'Gadsby',
    'New-Richmond',
    'Windermere',
    'Boswell',
    'Kaslo',
    'Salmo',
    'Leslieville',
    'Notre-Dame-de-Lourdes',
    'Esprit-Saint',
    'New Carlisle',
    'Bonaventure',
    'Westree',
    'Hay River',
    'Fort Good Hope',
    'Fort Simpson',
    'Whatì',
    'Whitehorse',
    'Inuvik',
    'Elliston',
    "King's Point",
    'New Ross',
    'Chapleau',
    'Bedford',
    'Pont-Viau',
    'Carp',
    'Pass Lake',
    'Theresa',
    'Victoria Harbour',
    'Utterson',
    'Saint-Clement',
    'Circonscription electorale de Jacques-Cartier',
    'Cartier',
    'Pakenham',
    'Warkworth',
    'Saint-Cyprien',
    'Parham',
    'Keene',
    'Notre-Dame-du-Portage',
    'Carignan',
    'Saint-Flavien',
    'Saint-Polycarpe',
    'Saint-Placide',
    'Princeville',
    'St-Lazare',
    'Hull',
    'Hubbards',
    'Brooklyn',
    'Mill Village',
    'Lower Island Cove',
    'Roddickton',
    'Entwistle',
    'Newtown',
    'Petitcodiac',
    'Dawson Settlement',
    'Waweig',
    'Keswick',
    'Sussex',
    'Gold Bridge',
    'Seton Portage',
    'Canim Lake',
    'Cobden',
    'Duchess',
    'Tilley',
    'Rosemary',
    'Gem',
    'Union Bay',
    'Black Creek',
    'Cumberland Bay',
    'Lanigan',
    'Oxbow',
    'Davidson',
    'Cudworth',
    'Lacolle',
    'Saint-Urbain-Premier',
    'Newton',
    'Port au Choix',
    'Upton',
    'Bonsecours',
    'Labrador City',
    'Wallace',
    'Cheticamp',
    'Pugwash',
    'Castle Bay',
    'Terre Noire',
    'Laurier-Station',
    'Havre-Saint-Pierre',
    'Willowdale',
    'Thornbury',
    'Rexdale',
    'Conestogo',
    'Churchill',
    'Hillsburgh',
    'Embrun',
    'Sutton',
    'Wellesley',
    'St. Marys',
    'Torbay',
    'Witless Bay',
    'Scotland',
    'Maxville',
    'Delaware West',
    'Sparta',
    'Komoka',
    'Jacksons',
    'Port McNicoll',
    'Meaford',
    'Newcastle Creek',
    'Oromocto',
    'Millville',
    'Riverview',
    'Salisbury',
    'Shediac',
    'Fredericton Junction',
    'Ripples',
    'Orono',
    'Denfield',
    'Kitscoty',
    'Flesherton',
    'St. Jacobs',
    'South Augusta',
    'DeBolt',
    'St. Brieux',
    'Strasbourg',
    'Raymore',
    'Granton',
    'Borden',
    'Brentwood Bay',
    'Pelly',
    'Sturgis',
    "L'Etang-du-Nord",
    'Chalk River',
    'Saint-Luc',
    'Boischatel',
    'Chateau-Richer',
    'Sainte-Famille',
    'Sainte-Anne-de-Beaupre',
    'Saint-Ferreol-les-Neiges',
    'Aylmer',
    'Price',
    'Duhamel',
    'St-Hubert',
    'Danville',
    'Barriere',
    'Charlie Lake',
    'Burns Lake',
    'Nisku',
    'Montague',
    'Debert',
    'Port Lorne',
    'Central Bedeque',
    'Hunter River',
    'North Rustico',
    'Milton',
    'LaHave',
    'Great Village',
    'Head of Jeddore',
    'Meteghan',
    'Caledonia',
    'Porters Lake',
    'Mount Stewart',
    'Cardigan',
    'Vernon Bridge',
    'Tatamagouche',
    'Minto',
    'Pynns',
    'Glenwood',
    'Stephenville Crossing',
    'Pasadena',
    'Raleigh',
    'Barnaby River',
    "Spaniard's Bay",
    'Tyndall',
    'Neepawa',
    'The Pas',
    'Sperling',
    'Pilot Mound',
    'Carman',
    'Gimli',
    "Heart's Content",
    'Trout River',
    'Bell Island',
    'New Harbour',
    'Chapel Arm',
    'Eastport',
    'Fermeuse',
    'St. Anthony',
    'Garnish',
    "O'Regan's",
    'Cow Head',
    "Parson's Pond",
    'Clarenville-Shoal Harbour',
    "St. Joseph's",
    'Rencontre East',
    'Caraquet',
    'Mobile',
    'Wabana',
    'Stoughton',
    'Fillmore',
    'Vonda',
    'Battleford',
    'Arcola',
    'Preeceville',
    'White City',
    'Shellbrook',
    'Wymark',
    'Star City',
    'Gronlid',
    'Garrick',
    'Bienfait',
    'Radisson',
    'Richibucto',
    'Shanklin',
    'Saint Andrews',
    'Florenceville-Bristol',
    'Baddeck',
    'Canso',
    'Riverside-Albert',
    'Scoudouc',
    'Apohaqui',
    'Oak Point',
    'Havre Boucher',
    'Mulgrave',
    'Little Narrows',
    'Kingsville',
    'Guysborough',
    'Sherbrooke',
    'Dalhousie',
    'Bouctouche',
    'Wabush',
    'Port Elgin',
    'River Hebert',
    'Chipman',
    'Weldon',
    'Harvey',
    'La Tabatiere',
    'Betsiamites',
    'Miscouche',
    'East Margaree',
    'Whites Lake',
    'Ingonish',
    'Margaree Valley',
    'Renous',
    'Flat River',
    'Emerald Junction',
    'Iona',
    'Mont-Laurier',
    'Chibougamau',
    'Saint-Aime-du-Lac-des-Iles',
    'Chapais',
    'Beaux-Rivages--Lac-des-Ecorces--Val-Barrette',
    'Sainte-Anne-du-Lac',
    'Ferme-Neuve',
    'Otter Lake',
    'Mont-Saint-Michel',
    'Matagami',
    'Guigues',
    'Angliers',
    'Palmarolle',
    'Cap-aux-Meules',
    'Authier',
    'Forestville',
    'Taschereau',
    'East Coulee',
    'Rumsey',
    'Hazelton',
    'Blacks Harbour',
    'Caroline',
    'Saint-Gilles',
    'Lac-aux-Sables',
    'Saint-Marc-des-Carrieres',
    'Saint-Come--Liniere',
    'Borden-Carleton',
    'Saint-Leon-de-Standon',
    'Sayabec',
    'Causapscal',
    'Slocan',
    'Audet',
    'Glenwood',
    'Plamondon',
    'Wittenburg',
    'Lytton',
    'Alert Bay',
    'Ardmore',
    'Carberry',
    'Torrington',
    'Acme',
    'Moose Creek',
    'Golden Lake',
    'Boissevain',
    'Sainte-Helene-de-Breakeyville',
    'Gleichen',
    'Beiseker',
    'Millet',
    'Fort Vermilion',
    'Taylor',
    'Petite-Riviere-Saint-Francois',
    'Eganville',
    'Carleton-sur-Mer',
    'Saint-Paul',
    'Saint-Thomas',
    'Saint-Pascal',
    'Saguenay',
    'Arthabaska',
    'Mont-Saint-Gregoire',
    'Dorion-Gardens',
    'Saint-Andre-Avellin',
    'Quathiaski Cove',
    'Denman Island',
    'La Durantaye',
    'Saint-Ubalde',
    'Sainte-Marguerite',
    'Inglewood',
    'Edgerton',
    'Provost',
    'Marwayne',
    'Legal',
    'Hardisty',
    'Cayley',
    'Delburne',
    'Hobbema',
    'Wembley',
    'Winfield',
    'Breton',
    'Salmon River',
    'Wellington',
    'Ramea',
    'Lac du Bonnet',
    'Whitemouth',
    'Miami',
    'Kinistino',
    'Birch Hills',
    'Saint-Antoine-sur-Richelieu',
    'Clyde River',
    'Hare Bay',
    'Glovertown',
    'Notre-Dame-du-Bon-Conseil',
    'Shigawake',
    'Port-Daniel',
    'Saint-Camille',
    'Willowvale',
    'Wakefield',
    'Lourdes',
    'Lethbridge',
    'Gander Bay',
    'St. Peters Bay',
    'Tlell',
    'Saint-Nazaire',
    'Hornepayne',
    "Sainte-Brigide-d'Iberville",
    'Henryville',
    'Mansonville',
    'Nauwigewauk',
    'Perth',
    'Lakeburn',
    'Howley',
    'Harbour Main',
    'Allardville',
    'Saint-Louis de Kent',
    'Pennfield',
    'Big Valley',
    'High Prairie',
    'Wildwood',
    'Ganges',
    'Canal Flats',
    'Grindrod',
    'Bloomfield',
    'Les Coteaux',
    'Dresden',
    "L'Isle-Verte",
    'Wellington',
    'Claremont',
    'Saint-Ambroise',
    'Senneville',
    'Saint-Denis',
    'Londonderry',
    'Flin Flon',
    'Elgin',
    'Rossburn',
    'Pain Court',
    'La Corey',
    'Economy',
    'Cannington',
    'Sainte-Foy',
    'Wilcox',
    'Sainte-Marthe-sur-le-Lac',
    'Iroquois',
    'Priceville',
    'South Mountain',
    'Petherton',
    'Merrickville',
    'Avonmore',
    'Apple Hill',
    'North Lunenburg',
    'Quyon',
    'Maberly',
    'Kazabazua',
    'Warsaw',
    'Islington',
    'Millarville',
    'Weston',
    'Burnside',
    'Portage',
    'Waterloo',
    'Saint-Paul',
    "Saint-Paul-d'Abbotsford",
    'Brigham',
    'Rapid City',
    'Dufresne',
    'Fabreville',
    'Prospect',
    'Eriksdale',
    'Minnedosa',
    'Cranberry Portage',
    'Bonshaw',
    'Colonsay',
    'Coburg',
    'Sardis',
    'Pascal',
    'Hafford',
    'Spencerville',
    'Odessa',
    'Trout Creek',
    'Powassan',
    'Sorel-Tracy',
    'Kispiox',
    'Cedar Valley',
    'Barons',
    'Cottam',
    'Saint-Augustin-de-Desmaures',
    'Fort McPherson',
    'Tuktoyaktuk',
    'Aklavik',
    'Breslau',
    'Morrin',
    'Youngstown',
    'Consort',
    'Empress',
    'Jenner',
    'Clinton',
    'Eckville',
    'Riverton',
    'Sherrington',
    'Scott',
    'Mar',
    'Grande Cache',
    'Saint-Michel',
    'Tracadie',
    'Newdale',
    'Saint-Raphael',
    'West Hill',
    'Granum',
    'Binscarth',
    'Moffat',
    'Otterburne',
    'Beauval',
    'Yellow Grass',
    'Craik',
    'Norquay',
    'Quill Lake',
    'Piney',
    'Morris',
    'Plum Coulee',
    'Manitou',
    'Churchill',
    'Arborg',
    'Moose Lake',
    'Gillam',
    'Winnipegosis',
    'Comber',
    'Campbellville',
    'Havre-Aubert',
    'Bamfield',
    'North Delta',
    'Churchville',
    'Kinuso',
    'Munson',
    'St. Stephen',
    'Arva',
    'Malton',
    'West Bay',
    'Pontypool',
    'Clarence',
    'South Slocan',
    'Lantzville',
    'New Aiyansh',
    'Port Edward',
    'Pinantan Lake',
    'Riviere-Ouelle',
    'Munster',
    'Kilworthy',
    'Elma',
    'MacGregor',
    'Gladstone',
    'Riviere-Rouge',
    'Norval',
    'Chatsworth',
    'Unionville',
    'Rossport',
    'Wemindji',
    'Arviat',
    'Cape Dorset',
    'Saint-Bruno',
    'Rosedale',
    'Sillery',
    'Cartwright',
    'Russell',
    'Ashville',
    'Treherne',
    'Gretna',
    'Streetsville',
    'Clair',
    'Ferintosh',
    'Agincourt',
    'Omerville',
    'Bramalea',
    'Yahk',
    'Sunderland',
    'Nouvelle',
    'Edam',
    'Buckingham',
    'Muirkirk',
    'Coutts',
    'Pukatawagan',
    'Ogoki',
    'Kuujjuarapik',
    'Cross Lake',
    'Grand Rapids',
    'South Indian Lake',
    'Oldcastle',
    'Pouce Coupe',
    'Dog Creek',
    'New Denver',
    'Deroche',
    'Parson',
    'Spillimacheen',
    'Saint-Casimir',
    'Sainte-Thecle',
    'Lemberg',
    'Tompkins',
    'Penobsquis',
    'Whitney',
    'Kerwood',
    'Port Alice',
    'Stoneham',
    'Saint-Telesphore',
    'Obedjiwan',
    'Saint-Jude',
    'Baldonnel',
    'Hagensborg',
    'Malakwa',
    'Edgewood',
    'Sayward',
    'Les Mechins',
    'Neuville',
    'Plumas',
    'Kedgwick',
    'Val-Morin',
    'Dawson City',
    'Carmacks',
    'Watson Lake',
    'Paulatuk',
    'Norman Wells',
    'Gray Creek',
    'Bridge Lake',
    'Klemtu',
    'Field',
    'Tisdale',
    'Spy Hill',
    'Bromhead',
    'Debden',
    'Abbey',
    'Blaine Lake',
    'Wiseton',
    'Mankota',
    'Bethune',
    "Qu'Appelle",
    'Kisbey',
    'Ituna',
    'Mistatim',
    'Conquest',
    'Saint-Felix-de-Kingsey',
    'Sainte-Clotilde-de-Horton',
    'Saint-Malachie',
    'Wrentham',
    'Carseland',
    'Delaware',
    'Snow Lake',
    'Dugald',
    'Marchand',
    'Mountain View',
    'Worsley',
    'Standard',
    'Smooth Rock Falls',
    'Acadia Valley',
    'Spirit River',
    'Englehart',
    'Moonbeam',
    'Swastika',
    'Hampden',
    'Aylsham',
    'Penzance',
    'Delisle',
    'Rhein',
    'Arelee',
    'Baie-Des-Sables',
    'Schefferville',
    'Tahsis',
    'Marbleton',
    'Coal Harbour',
    'McBride',
    'Grasmere',
    'Barkerville',
    'Moberly Lake',
    'Bic',
    'Ashcroft',
    'Hartley Bay',
    'North Star',
    'Cranberry',
    'Dorset',
    'Field',
    'Warner',
    'Tompkins',
    'Kettleby',
    'Saint-Irenee',
    'Saint-Arsene',
    'Saint-Urbain',
    'Pointe-a-la-Croix',
    'Ladysmith',
    'Windham Centre',
    'Parkhill',
    'Kent Bridge',
    'Searchmont',
    'Garden River',
    'Blackie',
    'Seaforth',
    'Roblin',
    'Grandora',
    'St. Louis',
    'Lake Lenore',
    'Cut Knife',
    'Birtle',
    'Hartney',
    'Pierson',
    'Onanole',
    'Baldur',
    'Melita',
    'Bryson',
    'Springfield',
    'Takla Landing',
    'Louisbourg',
    'Port Morien',
    'Malagawatch',
    'Whycocomagh',
    'Fox Creek',
    'Fort Smith',
    'Arctic Bay',
    'Kimmirut',
    'Colville Lake',
    'Hamiota',
    'Inglis',
    'Oak Lake',
    'Souris',
    'Sandy Bay',
    'Young',
    'Domremy',
    'St-Timothee',
    'Sharbot Lake',
    'Beachburg',
    'Morewood',
    'Spring Hill',
    'Allenford',
    'Emerald',
    'Alameda',
    'Manor',
    'Ceylon',
    'Naicam',
    'Wright-Gracefield-Northfield',
    'Frelighsburg',
    'Lyster',
    'Saint-Benjamin',
    "Saint-Francois-de-l'Ile-d'Orleans",
    'Vauxhall',
    'Tennion'
  ],
  Syria: ['Damascus', '`Ara', 'Aleppo', 'Ad Darah'],
  Ukraine: [
    'Kiev',
    'Vyshhorod',
    'Ternopil',
    'Brody',
    'Donetsk',
    'Mariupol',
    'Rodinskoye',
    'Sloviansk',
    'Yenakiyeve',
    'Kherson',
    'Zaporizhia',
    'Dnipropetrovsk',
    "Berdyans'k",
    'Novomoskovsk',
    'Chervonohrad',
    'Zhytomyr',
    'Odesa',
    'Chernihiv',
    'Boryspil',
    'Kharkiv',
    'Luhansk',
    'Cherkasy',
    'Kerch',
    'Mykolayiv',
    'Teplodar',
    "Alchevs'k",
    'Kirovsk',
    'Petrenky',
    'Kovel',
    'Uzhhorod',
    'Vinnytsya',
    'Desna',
    "Krasnoarmiys'k",
    "Syeverodonets'k",
    'Bila Tserkva',
    'Pavlograd',
    'Makiyivka',
    'Krasnodon',
    'Simferopol',
    'Sumy',
    'Oleh',
    "Obolon'",
    'Kuna',
    "Volodars'k-Volyns'kyy",
    'Kalynivka',
    'Lviv',
    "Volodymyr-Volyns'kyy",
    "Sverdlovs'k",
    'Illintsi',
    "Ivano-Frankivs'k",
    'Lutsk',
    'Novi Sanzhary',
    'Svalyava',
    'Tsyurupinsk',
    'Shpola',
    'Chernivtsi',
    'Buchach',
    'Dimitrov',
    'Mala Tokmachka',
    'Poltava',
    'Korosten',
    'Sevastopol',
    'Smila',
    "Khmel'nyts'kyy",
    'Kryvyy Rih',
    'Drohobych',
    'Rivne',
    "Dniprodzerzhyns'k",
    "Pervomays'k",
    "Volochys'k",
    'Slavuta',
    'Pavlohrad',
    "Ternopol'skiy Rayon",
    'Feodosiya',
    'Yalta',
    'Stakhanov',
    'Brovary',
    'Kremenchuk',
    'Aleksandriya',
    'Zaporozhe',
    "Marhanets'",
    'Kalush',
    'Melitopol',
    'Vorovskiy',
    'Kirovohrad',
    "Dolyns'ka",
    'Nizhyn',
    'Ivanov',
    "Krasnoperekops'k",
    'Zolotonosha',
    'Kolomyia',
    'Belaya',
    'Shostka',
    "Storozhynets'",
    "Anan'yiv",
    'Netishyn',
    'Spas',
    "Vinogradar'",
    'Bucha',
    'Lohvynove',
    'Podol',
    'Boyarka',
    'Rayhorodka',
    'Stat',
    'Rivne',
    'Kramatorsk',
    'Turka',
    'Chop',
    'Irpin',
    'Voznesenka',
    'Bolekhiv',
    'Chervona Hirka',
    'Energodar',
    "Kotovs'k",
    'Vasylkiv',
    'Alekseyevo-Druzhkovka',
    'Kalynivka',
    'Soroki',
    'Zolochiv',
    'Tkachenka',
    'Rubizhne',
    'Malyn',
    'Zhashkiv',
    'Zavod',
    "Vasylets'",
    'Teremky',
    'Lugansk',
    'Mega',
    'Shevchenko',
    'Aleksandrovka',
    'Kurylivka',
    'Poplavy',
    'Zdolbuniv',
    'Kyseliv',
    'Komsomolsk',
    'Romny',
    "Artemivs'k",
    'Lutugino',
    'Makariv',
    'Horlivka',
    'Bryanka',
    'Chernev',
    'Zhmerynka',
    'Yasinovataya',
    'Fastiv',
    'Boryspil Raion',
    "Debal'tseve",
    'Ternovka',
    'Lisichansk',
    'Gorlovka',
    'Ugledar',
    'Sudak',
    'Berehove',
    'Kremenets',
    'Rovenki',
    "Armyans'k",
    'Konotop',
    'Kuznetsovsk',
    'Shevchenka',
    "Bilohirs'k",
    'Alushta',
    'Kozyatyn',
    "Tul'chyn",
    'Alupka',
    'Svessa',
    'Volnovakha',
    'Bila Hora',
    'Zhdanovka',
    'Ukrayinka',
    "Kirovs'ke",
    'Novyy Buh',
    'Mykolayiv',
    'Berdychiv',
    'Maksim',
    'Shepetivka',
    'Dzhankoy',
    "Bobrynets'",
    'Kakhovka',
    'Sokoliv',
    'Korostyshiv',
    'Lanovka',
    'Zabolotiv',
    'Horodenka',
    "Hostomel'",
    'Voronovo',
    "Dykan'ka",
    'Kvasyliv',
    'Zlazne',
    'Obukhov',
    'Trypillia',
    'Kaniv',
    'Saky',
    'Krasnohrad',
    'Sorotskoye',
    "Novovolyns'k",
    'Mylove',
    'Knyazhychi',
    'Nosivka',
    'Bortnychi',
    'Zastava',
    "Uman'",
    'Nadezhda',
    'Stanislav',
    "Khmil'nyk",
    'Ivankov',
    'Darakhov',
    'Semen',
    'Babin',
    'Romaniv',
    'German',
    'Slynkivshchyna',
    'Toporov',
    'Bukova',
    'Davydov',
    'Tsvetkov',
    'Volkov',
    'Pobeda',
    "Stepan'",
    'Frunze',
    'Kurakhovo',
    "Mostys'ka",
    'Lityn',
    'Hoshiv',
    "Sokil'nyky",
    'Grebenyuk',
    'Boryslav',
    'Lubny',
    'Borodyanka',
    'Bozhenka',
    'Zmiyiv',
    'Merefa',
    'Bushtyno',
    'Barashivka',
    'Stryi',
    'Soroka',
    'Dmitrovichi',
    'Osypenko',
    'Osnova',
    'Izmail',
    'Hlukhiv',
    'Serhiyi',
    'Sencha',
    'Baryshivka',
    "Sverdlovs'ke",
    'Markova',
    'Dubno',
    'Luganskoye',
    'Kreminna',
    'Mukacheve',
    "Khotin'",
    'Haysyn',
    'Sokol',
    'Kyyiv',
    'Tyachiv',
    'Druzhkovka',
    'Khust',
    'Pyrohiv',
    'Torez',
    'Petranka',
    'Selidovo',
    'Lenina',
    'Goncharov',
    'Monastyryshche',
    'Fontanka',
    'Tokmak',
    "Uspens'ke",
    'Letychiv',
    'Khyzha',
    "Nikopol'",
    'Oreanda',
    'Vyshneve'
  ],
  Cyprus: [
    'Limassol',
    'Nicosia',
    'Larnaca',
    'Paphos',
    'Geroskipou',
    'Ayia Marina',
    'Livadia',
    'Kato Polemidia',
    'Chlorakas',
    'Paralimni',
    'Parekklisha',
    'Morfou',
    'Strovolos',
    'Kyrenia',
    'Laxia',
    'Aradippou',
    'Mouttagiaka',
    'Kiti',
    'Prodromi',
    'Agia Anna',
    'Kato Lakatamia',
    'Famagusta',
    'Deryneia',
    'Sotira',
    'Tera'
  ],
  'Czech Republic': [
    'Prague',
    'Chodov',
    'Ústí nad Labem',
    'Jablunkov',
    'Český Těšín',
    'Vlcovice',
    'Chrudim',
    'Kolín',
    'Tehov',
    'Koprivnice',
    'Beroun',
    'Horovice',
    'Valasske Klobouky',
    'Bylnice',
    'Slavicin',
    'Poritsch',
    'As',
    'Lanskroun',
    'Chlumcany',
    'Cernosice',
    'Jablonec nad Nisou',
    "Zd'ar nad Sazavou",
    'Bystrice nad Pernstejnem',
    'Semily',
    'Roznov pod Radhostem',
    'Hovezi',
    'Nove Straseci',
    'Pilsen',
    'Naklo',
    'Kyjov',
    'Milotice',
    'Rtyne',
    'Jaroslav',
    'Uhersky Ostroh',
    'Ceska',
    'Horni Bojanovice',
    'Hodonín',
    'Straznice',
    'Raj',
    'Silherovice',
    'Darkovicky',
    'Vsetin',
    'Valasske Mezirici',
    'Strazek',
    'Kutná Hora',
    'Troja',
    'Pysely',
    'Brno',
    'Caslav',
    'Ronov nad Doubravou',
    'Chotebor',
    'Rychnov nad Kneznou',
    'Policka',
    'Prosetin',
    'Prelouc',
    'Ledec nad Sazavou',
    'Svetla nad Sazavou',
    'Golcuv Jenikov',
    'Bojanov',
    'Hermanuv Mestec',
    'Nove Mesto nad Metuji',
    'Ostrava',
    'Sudkov',
    'Olomouc',
    'Cestice',
    'Děčín',
    'Citoliby',
    'Zeliv',
    'Karlovy Vary',
    'Zampach',
    'Lichkov',
    'Zlín',
    'Jablunka',
    'Nymburk',
    'Votice',
    'Podebrady',
    'Břeclav',
    'Lomnice',
    'Nedvedice',
    'Bruntal',
    'Dolni Sucha',
    'Krnov',
    'Písek',
    'Ostrov',
    'Teplice',
    'Liberec',
    'Vysoke Myto',
    'Jevicko',
    'Ceska Ves',
    'Sumperk',
    'Jihlava',
    "Trest'",
    'Radostin',
    'Litoměřice',
    'Třebíč',
    'Novy Bydzov',
    'Jablonne v Podjestedi',
    'Trstenice',
    'Pardubice',
    'Hodice',
    'Chrastna',
    'Kladno',
    'Brezi',
    'Hosteradice',
    'Jistebnik',
    'Opava',
    'Sezemice',
    'Jaromer',
    'Česká Lípa',
    'Vamberk',
    'Pribor',
    'Krupka',
    'Milovice',
    'Susice',
    'Nyrsko',
    'Blatna',
    'Velke Hydcice',
    'Tvrdonice',
    'Dolni Bojanovice',
    'Prusanky',
    'Lanzhot',
    'Hluboka nad Vltavou',
    'Chomutov',
    'Litvinov',
    'Krivoklat',
    'Horesedly',
    'Louny',
    'Postoloprty',
    'Kralupy nad Vltavou',
    'Mělník',
    'Vyškov',
    'Drnovice',
    'Bucovice',
    'Prostějov',
    'Havlíčkův Brod',
    'Boskovice',
    'Blansko',
    'Tisnov',
    'Cesky Brod',
    'Hulin',
    'Tachov',
    'Bor',
    'Mariánské Lázně',
    'Plana',
    'Siluvky',
    'Kurim',
    'Mesice',
    'Podivin',
    'Bludovice',
    'Dehylov',
    'Velka Bites',
    'Stritez nad Ludinou',
    'Letohrad',
    'Frydlant nad Ostravici',
    'Frydek-Mistek',
    'Nachod',
    'Libeznice',
    'Hradec Králové',
    'Bernartice nad Odrou',
    'Vitkov',
    'Moran',
    'Petrvald',
    'Jirkov',
    'Zdiby',
    'Cepi',
    'Chrast',
    'Zamberk',
    'Jablonne nad Orlici',
    'Kratonohy',
    'Dobre',
    'Karvina',
    'Lutin',
    'Vsenory',
    'Zabcice',
    'Turnov',
    'Novy Jicin',
    'Mohelnice',
    'Znojmo',
    'Horni Becva',
    'Vladislav',
    'Unetice',
    'Spalene Porici',
    'Bystrice pod Hostynem',
    'Ricany',
    'Stare Mesto',
    'Uhersky Brod',
    'Dolni Nemci',
    'Ceska Trebova',
    'Kostelec nad Orlici',
    'Lysa nad Labem',
    'Dobriv',
    'Velke Mezirici',
    'Hranice',
    'Nyrany',
    'Merklin',
    'Domazlice',
    'Kbely',
    'Mratin',
    'Zajeci',
    'Ejpovice',
    'Opocno',
    'Banov',
    'Uherské Hradiště',
    'Bilovice',
    'Spytihnev',
    'Dubne',
    'Telc',
    'Zidlochovice',
    'Albrechtice',
    'Hustopece',
    'Holoubkov',
    'Jičín',
    'Chodov',
    'Napajedla',
    'Kunovice',
    'Uhrice',
    'Vacenovice',
    'Svatava',
    'Frenstat pod Radhostem',
    'Sokolov',
    'Cheb',
    'Vysoka nad Labem',
    'Lazne Bohdanec',
    'Most',
    'Žatec',
    'Klasterec nad Ohri',
    'Dobris',
    'Mnisek pod Brdy',
    'Kraluv Dvur',
    'Tachlovice',
    'Modrice',
    'Přerov',
    'Mnichovice',
    'Babice',
    'Mladá Boleslav',
    'Hlucin',
    'Strakonice',
    'Havířov',
    'Lhota',
    'Rychvald',
    'Tábor',
    'Klatovy',
    'Liban',
    'Brandys nad Labem-Stara Boleslav',
    'Skalsko',
    'Velky Borek',
    'Rokycany',
    'Kroměříž',
    'Orlova',
    'Jindrichuv Hradec',
    'Jalubi',
    'Nove Mesto na Morave',
    'Kosmonosy',
    'Rovensko pod Troskami',
    'Bela pod Bezdezem',
    'Rakovnik',
    'Luzna',
    'Vlasim',
    'Skutec',
    'Roudnicek',
    'Roudnice nad Labem',
    'Nedomice',
    'Steti',
    'Rovna',
    'Ctidruzice',
    'Pocatky',
    'Jevisovice',
    'Batelov',
    'Lesonice',
    'Satov',
    'Zeletava',
    'Trinec',
    'Vendryne',
    'Slany',
    'Lodenice',
    'Svojek',
    'Volyne',
    'Veseli nad Moravou',
    'České Budějovice',
    'Lhota',
    'Nova Lhota',
    'Ratibor',
    'Zubri',
    'Polna',
    'Grucovice',
    'Velke Heraltice',
    'Vizovice',
    'Nydek',
    'Celadna',
    'Bavory',
    'Moravsky Zizkov',
    'Valtice',
    'Mikulov',
    'Starovicky',
    'Zlata',
    'Vlachovice',
    'Litovel',
    'Jilovec',
    'Moravska Trebova',
    'Chocen',
    'Ladvi',
    'Luhacovice',
    'Janovice nad Uhlavou',
    'Rousinov',
    'Kraslice',
    'Ondrejov',
    'Krasna Lipa',
    'Jenec',
    'Mokrany',
    'Dvur Kralove nad Labem',
    'Marianska',
    'Borsice',
    'Ktis',
    'Predklasteri',
    'Pisecna',
    'Vysker',
    'Velka Polom',
    'Vimperk',
    'Strunkovice nad Blanici',
    'Chlumec nad Cidlinou',
    'Uvaly',
    'Jirny',
    'Sibrina',
    'Doubravy',
    'Bystrice',
    'Hlinsko',
    'Kamenne Zehrovice',
    'Velka Dobra',
    'Pchery',
    'Bustehrad',
    'Tremosna',
    'Mnichovo Hradiště',
    'Uhonice',
    'Sany',
    'Rymarov',
    'Malesov',
    'Dubnany',
    'Hysly',
    'Stribro',
    'Stenovice',
    'Vrbno pod Pradedem',
    'Sedlcany',
    'Vrbice',
    'Cejkovice',
    'Usti nad Orlici',
    'Prestice',
    'Holysov',
    'Holice',
    'Pariz',
    'Frystak',
    'Svitavy',
    'Kretin',
    'Slavkov u Brna',
    'Novosedly',
    'Hostomice',
    'Ostopovice',
    'Ostrozska Nova Ves',
    'Stochov',
    'Moravske Budejovice',
    'Cerveny Kostelec',
    'Filipova',
    'Drisy',
    'Benešov',
    'Bukovec',
    'Vsechovice',
    'Horni Pocernice',
    'Benatky nad Jizerou',
    'Krhova',
    'Sadska',
    'Prezletice',
    'Deblin',
    'Hradec nad Moravici',
    'Velke Losiny',
    'Lhota pod Libcany',
    'Hradek',
    'Sivice',
    'Hrusovany nad Jevisovkou',
    'Vinarice',
    'Kamenice',
    'Nehvizdy',
    'Hradiste',
    'Rudna',
    'Cvikov',
    'Ostrov u Macochy',
    'Skripov',
    'Okrisky',
    'Ceska Skalice',
    'Dvorce',
    'Chotesov',
    'Novy Bor',
    'Slavonice',
    'Choustnik',
    'Hrdejovice',
    'Roztoky',
    'Horni Podluzi',
    'Dolni Poustevna',
    'Sumice',
    'Lubenec',
    'Harrachov',
    'Snezne',
    'Letovice',
    'Tremesna',
    'Lysice',
    'Svitavka',
    'Temice',
    'Cechtice',
    'Osecna',
    'Bystrice',
    'Mesto Touskov',
    'Dily',
    'Sobeslav',
    'Lampertice',
    'Rychnov',
    'Hradec',
    'Velka Jesenice',
    'Trutnov',
    'Dolni Radechova',
    'Planany',
    'Dymokury',
    'Chyne',
    'Drzovice',
    'Pisecna',
    'Sternberk',
    'Telnice',
    'Mukarov',
    'Luby',
    'Hranice',
    'Horni Slavkov',
    'Mikulovice',
    'Cernotin',
    'Drahotuse',
    'Senice na Hane',
    'Malenovice',
    'Hostivice',
    'Lovosice',
    'Novy Knin',
    'Maly Ujezd',
    'Stribrna Skalice',
    'Libos',
    'Jilove u Prahy',
    'Chodova Plana',
    'Dolni Sytova',
    'Zasova',
    'Velke Prilepy',
    'Brezova',
    'Trebon',
    'Bernartice',
    'Raspenava',
    'Dolni Kounice',
    'Nove Veseli',
    'Osik',
    'Velke Popovice',
    'Hostomice',
    'Mokra Horakov',
    'Zulova',
    'Stalkov',
    'Chocerady',
    'Doubravnik',
    'Pelhrimov',
    'Pecin',
    'Velky Osek',
    'Kacice',
    'Lhotka',
    'Veverska Bityska',
    'Strachotin',
    'Veseli nad Luznici',
    'Tursko',
    'Kravare',
    'Kolodeje',
    'Prepere',
    'Brusperk',
    'Chribska',
    'Habartov',
    'Velke Brezno',
    'Vsetaty',
    'Sluknov',
    'Varnsdorf',
    'Novy Hrozenkov',
    'Halenkov',
    'Moravska Nova Ves',
    'Zatcany',
    'Tucapy',
    'Slapanice',
    'Bohumin',
    'Hlasna Treban',
    'Jinocany',
    'Horousanky',
    'Lounovice pod Blanikem',
    'Dalovice',
    'Bolebor',
    'Dolni Benesov',
    'Doksy',
    'Svojkov',
    'Kozmice',
    'Trhovy Stepanov',
    'Senov',
    'Vrchlabi',
    'Kamyk',
    'Dobroslavice',
    'Dubi',
    'Kostelec nad Labem',
    'Celakovice',
    'Dubec',
    'Jesenice',
    'Mutenice',
    'Tyniste nad Orlici',
    'Hradek nad Nisou',
    'Olsany',
    'Dlouha Trebova',
    'Kraliky',
    'Nechanice',
    'Kaplice',
    'Bechyne',
    'Milevsko',
    'Kostice',
    'Krumvir',
    'Fulnek',
    'Malcice',
    'Ujezd nad Lesy',
    'Zadni Treban',
    'Trebechovice pod Orebem',
    'Ochoz',
    'Nezamyslice',
    'Boretice',
    'Mlada Vozice',
    'Brod',
    'Kralovice',
    'Kamenice',
    'Syrovice',
    'Rajec-Jestrebi',
    'Vidce',
    'Knezmost',
    'Zvole',
    'Sobotka',
    'Poricany',
    'Msec',
    'Pustejov',
    'Zdeslav',
    'Prachatice',
    'Menany',
    'Vselibice',
    'Bochov',
    'Mladkov',
    'Libocany',
    'Loukov',
    'Hospozin',
    'Rajecko',
    'Nepomysl',
    'Stitina',
    'Lipova',
    'Brodek',
    'Jedovnice',
    'Potstejn',
    'Zabreh',
    'Jaroslavice',
    'Damnice',
    'Sedliste',
    'Hodslavice',
    'Cesky Dub',
    'Loket',
    'Desna',
    'Vyprachtice',
    'Leskovec',
    'Jiloviste',
    'Radnice',
    'Benesov',
    'Lachov',
    'Nova Vcelnice',
    'Tuchomerice',
    'Osek',
    'Pecky',
    'Kamenny Privoz',
    'Krepenice',
    'Stribrec',
    'Velke Opatovice',
    'Chrast',
    'Krizanov',
    'Lichnov (o. Novy Jicin)',
    'Libcany',
    'Branov',
    'Veltrusy',
    'Dolni Brezany',
    'Tynec nad Sazavou',
    'Mirosov',
    'Jirice',
    'Skuhrov nad Belou',
    'Arnoltice',
    'Tetin',
    'Citov',
    'Zehun',
    'Dolni Becva',
    'Stare Sedliste',
    'Smrzovka',
    'Strancice',
    'Mechenice',
    'Ohrobec',
    'Trebestovice',
    'Cervene Pecky',
    'Bilovice nad Svitavou',
    'Starovice',
    'Petrovice u Karvine',
    'Polepy',
    'Cerekvice nad Loucnou',
    'Horsovsky Tyn',
    'Branky',
    'Bratrikov',
    'Nova Paka',
    'Otrokovice',
    'Haj ve Slezsku',
    'Mosty u Jablunkova',
    'Vcelna',
    'Chvalec',
    'Zbuch',
    'Protivin',
    'Domasov',
    'Nove Dvory',
    'Prikazy',
    'Neratovice',
    'Psary',
    'Horomerice',
    'Zipotin',
    'Stoky',
    'Mlazovice',
    'Postrelmov',
    'Radun',
    'Liten',
    'Brezno',
    'Velvary',
    'Citov',
    'Strachotice',
    'Cheznovice',
    'Prlov',
    'Klecany',
    'Ujezd',
    'Hukvaldy',
    'Kostelec na Hane',
    'Krmelin',
    'Borovany',
    'Bilina',
    'Příbram',
    'Skalice',
    'Rozdrojovice',
    'Duchcov',
    'Bilina',
    'Smirice',
    'Dukovany',
    'Mlade Buky',
    'Horice',
    'Zelezny Brod',
    'Dejvice',
    'Haje',
    'Visnove',
    'Hlubocepy',
    'Krchleby',
    'Stod',
    'Velehrad',
    'Radostice',
    'Ivancice',
    'Hronov',
    'Broumov',
    'Marov u Upice',
    'Police nad Metuji',
    'Zbysov',
    'Kvetnice',
    'Sedlec',
    'Brezolupy',
    'Pacov',
    'Luze',
    'Vernovice',
    'Kralice na Hane',
    'Senec',
    'Stezery',
    'Nivnice',
    'Chotoviny',
    'Nejdek',
    'Malenice',
    'Rozsochatec',
    'Nova Ves',
    'Blovice',
    'Odry',
    'Ricany',
    'Zeleznice',
    'Line',
    'Bystrice',
    'Dolni Berkovice',
    'Bezdekov',
    'Kamenicky Senov',
    'Rebesovice',
    'Dolni Terlicko',
    'Kunratice',
    'Dusejov',
    'Lhota',
    'Zdirec',
    'Dobronin',
    'Kochovice',
    'Nupaky',
    'Frantiskovy Lazne',
    'Nelahozeves',
    'Horni Lutyne',
    'Blizevedly',
    'Trmice',
    'Lisov',
    'Komorni Lhotka',
    'Lomnice nad Popelkou',
    'Perstejn',
    'Krouna',
    'Ostromer',
    'Velke Svatonovice',
    'Horni Briza',
    'Ckyne',
    'Dolni Jircany',
    'Krtiny',
    'Nova Cerekev',
    'Hamr',
    'Merklin',
    'Jirickov',
    'Vinicne Sumice',
    'Doudleby nad Orlici',
    'Bitovany',
    'Vsechovice',
    'Lazne Libverda',
    'Cercany',
    'Predmerice nad Labem',
    'Zdice',
    'Kunstat',
    'Predmerice nad Jizerou',
    'Budyne nad Ohri',
    'Strelice',
    'Olovi',
    'Mostek',
    'Cerncice',
    'Prazmo',
    'Senohraby',
    'Stity',
    'Stankov',
    'Cerhenice',
    'Lustenice',
    'Zlate Hory',
    'Mrlinek',
    'Brodce',
    'Borohradek',
    'Zelesice',
    'Humpolec',
    'Rikovice',
    'Michle',
    'Mirosovice',
    'Starec',
    'Seberov',
    'Mseno',
    'Stara Paka',
    'Moravsky Beroun',
    'Provodov',
    'Obristvi',
    'Lety',
    'Baska',
    'Opatovice nad Labem',
    'Kdyne',
    'Horni Nemci',
    'Veverske Kninice',
    'Kostelni Hlavno',
    'Litava',
    'Kostelec nad Cernymi Lesy',
    'Nesovice',
    'Zborovice',
    'Tisice',
    'Rapotice',
    'Bosin',
    'Choustnikovo Hradiste',
    'Detmarovice',
    'Nedakonice',
    'Havrice',
    'Vracov',
    'Zdounky',
    'Jilemnice',
    'Sloup',
    'Unicov',
    'Vresina',
    'Velka Bystrice',
    'Nucice',
    'Rudikov',
    'Belotin',
    'Rudolfov',
    'Mimon',
    'Kadan',
    'Komarice',
    'Mezibori',
    'Lipnik nad Becvou',
    'Velesin',
    'Mala Skala',
    'Vratkov',
    'Ratiskovice',
    'Hostin',
    'Dobra Voda',
    'Holesov',
    'Cebin',
    'Odolena Voda',
    'Holubice',
    'Vrane nad Vltavou',
    'Markvartovice',
    'Tuchlovice',
    'Ostrozska Lhota',
    'Zeravice',
    'Hradiste',
    'Dobra',
    'Sviadnov',
    'Tosovice',
    'Hudlice',
    'Snedovice',
    'Sestajovice',
    'Bludov',
    'Mcely',
    'Loucen',
    'Strasice',
    'Rozmital pod Tremsinem',
    'Repy',
    'Luka',
    'Hradcovice',
    'Bojkovice',
    'Drzkov',
    'Chroustovice',
    'Navsi u Jablunkova',
    'Zlicin',
    'Predmesti',
    'Kylesovice',
    'Hostice',
    'Ivanovice na Hane',
    'Seibersdorf',
    'Vtelno',
    'Straz nad Nisou',
    'Plouznice',
    'Benesovice',
    'Cista',
    'Adamov',
    'Prackovice nad Labem',
    'Kresice',
    'Rapotin',
    'Jesenik',
    'Rokytnice',
    'Svihov',
    'Milin',
    'Davle',
    'Hajnice',
    'Libochovicky',
    'Hovorcovice',
    'Dolni Zandov',
    'Dubenec',
    'Slapy',
    'Moravsky Krumlov',
    'Libesice',
    'Uzice',
    'Hvozd',
    'Zelenec',
    'Chlupice',
    'Miroslav',
    'Hrusovany u Brna',
    'Oslavany',
    'Otnice',
    'Rudoltice',
    'Tupesy',
    'Polesovice',
    'Dolni Dunajovice',
    'Petrovice',
    'Studenka',
    'Velke Albrechtice',
    'Krnsko',
    'Kouty',
    'Moravsky Pisek',
    'Vysoka',
    'Divisov',
    'Frydlant',
    'Milikov',
    'Rovina',
    'Kotencice',
    'Vojkovice',
    'Rokytnice nad Jizerou',
    'Lasenice',
    'Kacerov',
    'Vratimov',
    'Palkovice',
    'Zdanice',
    'Korycany',
    'Hovorany',
    'Horni Benesov',
    'Bohunovice',
    'Loucany',
    'Kaznejov',
    'Konice',
    'Radim',
    'Nezvestice',
    'Prerov nad Labem',
    'Český Krumlov',
    'Lomnice nad Luznici',
    'Svaty Jan nad Malsi',
    'Vysoke Veseli',
    'Zelenka',
    'Kourim',
    'Ceske Mezirici',
    'Nikolcice',
    'Borotin',
    'Olomucany',
    'Sebrov',
    'Chlumec',
    'Ruda nad Moravou',
    'Vodnany',
    'Oparany',
    'Zliv',
    'Stare Mesto',
    'Hlubocky',
    'Tesetice',
    'Kuncice',
    'Novy Oldrichov',
    'Straz pod Ralskem',
    'Preckov',
    'Nenkovice',
    'Hostinne',
    'Holasice',
    'Ustin',
    'Dlouhe',
    'Statenice',
    'Blucina',
    'Stepanov',
    'Dobrany',
    'Mezilesi',
    'Pavlice',
    'Klimkovice',
    'Obrany',
    'Stara Voda',
    'Zandov',
    'Pistovice',
    'Libis',
    'Jince',
    'Klicany',
    'Krenovice',
    'Janovice',
    'Horni Redice',
    'Karlin',
    'Zizkov',
    'Lednice',
    'Velky',
    'Primda',
    'Cerna Hora',
    'Vochov',
    'Moravicany',
    'Dubicko',
    'Rosice',
    'Pribyslav',
    'Pruhonice',
    'Velesovice',
    'Krasna Hora nad Vltavou',
    'Sanov',
    'Jezov',
    'Sucha Loz',
    'Milonice',
    'Ritka',
    'Kuncina',
    'Litomyšl',
    'Vsestary',
    'Stary Bydzov',
    'Dobrichovice',
    'Bolatice',
    'Bradlec',
    'Kopidlno',
    'Chotetov',
    'Kyjov',
    'Lipence',
    'Dobruska',
    'Klobouky',
    'Dolany',
    'Tvarozna',
    'Libice nad Cidlinou',
    'Zilina',
    'Horin',
    'Hrabetice',
    'Rosice',
    'Cerna za Bory',
    'Nasavrky',
    'Koci',
    'Mestec',
    'Brtnice',
    'Cakovice',
    'Podborany',
    'Ujezd',
    'Slusovice',
    'Lukov',
    'Rataje',
    'Francova Lhota',
    'Rybna',
    'Vlastislav',
    'Sterboholy',
    'Tman',
    'Tlumacov',
    'Domazelice',
    'Pocenice',
    'Cejc'
  ],
  Switzerland: [
    'Zurich',
    'Geneva',
    'Lucerne',
    'Grenchen',
    'Bettlach',
    'Lengnau',
    'Pieterlen',
    'Huttwil',
    'Langenthal',
    'Lotzwil',
    'Murgenthal',
    'Roggwil',
    'Butzberg',
    'Bannwil',
    'Wynau',
    'Leukerbad',
    'Zermatt',
    'Visp',
    'Brig',
    'Glis',
    'Naters',
    'Saas-Fee',
    'Matzendorf',
    'Oensingen',
    'Egerkingen',
    'Holderbank',
    'Wolfwil',
    'Attiswil',
    'Oberbipp',
    'Balsthal',
    'Mumliswil',
    'Wiedlisbach',
    'Welschenrohr',
    'Mohlin',
    'Niederbipp',
    'Bern',
    'Gumligen',
    'Muri bei Bern',
    'Kirchberg',
    'Hindelbank',
    'Wasen',
    'Burgdorf',
    'Lyssach',
    'Oberburg',
    'Ersigen',
    'Kernenried',
    'Kirchberg',
    'Belp',
    'Munsingen',
    'Rubigen',
    'Toffen',
    'Aarberg',
    'Seedorf',
    'Herzogenbuchsee',
    'Oberonz',
    'Rheinfelden',
    'Therwil',
    'Basel',
    'Oberwil',
    'Binningen',
    'Bottmingen',
    'Pratteln',
    'Oberdorf',
    'Oberdorf',
    'Zeglingen',
    'Wegenstetten',
    'Gelterkinden',
    'Reinach',
    'Seltisberg',
    'Kaiseraugst',
    'Wallisellen',
    'Dubendorf',
    'Collombey',
    'Bouveret',
    'Martigny-Ville',
    'Basse-Nendaz',
    'Saxon',
    'Monthey',
    'Riddes',
    'Arlesheim',
    'Onex',
    'Doettingen',
    'Bariswil',
    'Lausanne',
    'Breitenbach',
    'Thayngen',
    'Uhwiesen',
    'Reinach',
    'Biel/Bienne',
    'Pully',
    'Sachseln',
    'Zizers',
    'Cazis',
    'Chur',
    'Steinhausen',
    'Ebnat-Kappel',
    'Meyrin',
    'Hunenberg',
    'Giubiasco',
    'Laufen',
    'Frauenfeld',
    'Affoltern am Albis',
    'Pfaeffikon',
    'Kriens',
    'Yverdon-les-Bains',
    'Rueti',
    'Wetzikon',
    'Mendrisio',
    'Stabio',
    'Vevey',
    'Orbe',
    'Baar',
    'Uster',
    'Trimbach',
    'Appenzell',
    'Fallanden',
    'Fribourg',
    'Diessenhofen',
    'Sulgen',
    'Kreuzlingen',
    'Schonholzerswilen',
    'Schaffhausen',
    'Bulach',
    'Winterthur',
    'Au',
    'Massagno',
    'Lugano',
    'Rotkreuz',
    'Bernex',
    'St. Gallen',
    'Nidau',
    'Carouge',
    'Wil',
    'Neuenhof',
    'Viganello',
    'Renens',
    'Eysins',
    'Nyon',
    'Prilly',
    'Aigle',
    'Sarnen',
    'Chene-Bourg',
    'Bern / Liebefeld',
    'Erlenbach',
    'Hochdorf',
    'Entlebuch',
    'Unterageri',
    'Dielsdorf',
    'Sissach',
    'Wettingen',
    'Deitingen',
    'Hergiswil',
    'Rupperswil',
    'Forel',
    'Muri',
    'Turbenthal',
    'Laupen',
    'Chiasso',
    'Riva San Vitale',
    'Cadro',
    'Banco',
    'Melide',
    'Chene-Bougeries',
    'Solothurn',
    'Augst',
    'Oberschlatt',
    'Beinwil',
    'Rorschach',
    'Baretswil',
    'Lenzburg',
    'Oberlunkhofen',
    'Villigen',
    'Zaziwil',
    'Hagneck',
    'Schanis',
    'Autigny',
    'Düdingen',
    'Menziken',
    'Frutigen',
    'Bassersdorf',
    'Nurensdorf',
    'Ruschlikon',
    'Mannedorf',
    'Tenero',
    'Blonay',
    'Weinfelden',
    'Wabern',
    'Wurenlos',
    'Oberentfelden',
    'Jona',
    'Kronbuhl',
    'Goldach',
    'Daettlikon',
    'Schlieren',
    'Montreux',
    'Dornach',
    'Peseux',
    'Allschwil',
    'Marin',
    'Rumlang',
    'Horw',
    'Saint-Sulpice',
    'Munchenstein',
    'Emmenbruecke',
    'Veyrier',
    'Liestal',
    'Klingnau',
    'Dottikon',
    'Windisch',
    'Dietikon',
    'Gland',
    'Celigny',
    'Gingins',
    'Coppet',
    'Crassier',
    'Kriegstetten',
    'Derendingen',
    'Subingen',
    'Lohn',
    'Munchenbuchsee',
    'Urtenen',
    'Schonbuhl',
    'Richenthal',
    'Nussbaumen',
    'Wollerau',
    'Lachen',
    'Merenschwand',
    'Bonstetten',
    'Kuesnacht',
    'Thun',
    'Courtion',
    'Berikon',
    'Nunningen',
    'Kloten',
    'Fischingen',
    'Baden',
    'Bioggio',
    'Siebnen',
    'Gummenen',
    'Bex',
    'Rorbas',
    'Embrach',
    'Wittenbach',
    'Teufen AR',
    'Morschwil',
    'Oftringen',
    'Cointrin',
    'Regensdorf',
    'La Chaux-de-Fonds',
    'Neuchatel',
    'Horgen',
    'Richterswil',
    'Oberweningen',
    'Lucens',
    'Moudon',
    'Thierachern',
    'Heimberg',
    'Zollikofen',
    'Wadenswil',
    'Pregassona',
    'Wikon',
    'Strengelbach',
    'Hagendorf',
    'Olten',
    'Aarau',
    'Volketswil',
    'Crissier',
    'Corgemont',
    'Bellevue',
    'Vernier',
    'Versoix',
    'Plan-les-Ouates',
    'Samstagern',
    'Pfaeffikon',
    'Vullierens',
    'Ecublens',
    'Niederrohrdorf',
    'Fehraltorf',
    'Ausser-Dinhard',
    'Bussigny',
    'Romanel',
    'Saint-Prex',
    'Tegerfelden',
    'Untersiggenthal',
    'Nussbaumen',
    'Birr',
    'Wurenlingen',
    'Endingen',
    'Turgi',
    'Habsburg',
    'Kirchdorf',
    'Holderbank',
    'Gebenstorf',
    'Birmenstorf',
    'Brugg',
    'Seewis im Pratigau',
    'Tamins',
    'Seengen',
    'Wohlen',
    'Egliswil',
    'Nanikon',
    'Meisterschwanden',
    'Bruttisellen',
    'Flums',
    'Untervaz',
    'Domat',
    'Haldenstein',
    'Wangs',
    'Greifensee',
    'Bassecourt',
    'Malleray',
    'Bevilard',
    'Rapperswil',
    'Eschenbach',
    'Roche',
    'Chatel-Saint-Denis',
    'Buchs / Buchs (Dorf)',
    'Bellinzona',
    'Belmont-sur-Lausanne',
    'Zollikon',
    'Le Locle',
    'Altdorf',
    'Courtepin',
    'Lamboing',
    'La Neuveville',
    'Wileroltigen',
    'Speicher',
    'Vacallo',
    'Mettmenstetten',
    'Nottwil',
    'Sursee',
    'Mauensee',
    'Avenches',
    'Couvet',
    'Zofingen',
    'Heerbrugg',
    'Berneck',
    'Ermatingen',
    'Tagerwilen',
    'Holstein',
    'Magden',
    'Colombier',
    'Cornaux',
    'Preles',
    'Delémont',
    'Minusio',
    'Langnau am Albis',
    'Herisau',
    'Frick',
    'Ober Urdorf',
    'Murten',
    'Vesenaz',
    'Rickenbach',
    'Zug',
    'Feuerthalen',
    'Huntwangen',
    'Wagenhausen',
    'Biberist',
    'Morges',
    'Chardonne',
    'Le Mont-sur-Lausanne',
    'Gossau',
    'Grueningen',
    'Küttigen',
    'Troistorrents',
    'Villars-sur-Glane',
    'Gstaad',
    'Sempach',
    'Unterengstringen',
    'Cadempino',
    'Locarno',
    'Tann',
    'Berlikon',
    'Hinwil',
    'Weiningen',
    'Meilen',
    'Oberengstringen',
    'Stettlen',
    'Illnau',
    'Küssnacht',
    'Elsau-Raeterschen',
    'Hinterkappelen',
    'Losone',
    'Oetwil / Oetwil an der Limmat',
    'Cham',
    'Vandoeuvres',
    'Morigen',
    'Bristen',
    'Willisau',
    'Beromuenster',
    'Moutier',
    'Hunibach',
    'Schmitten',
    'Ueberstorf',
    'Albligen',
    'Schwarzenburg',
    'Köniz',
    'Puidoux',
    'Cully',
    'Chexbres',
    'Payerne',
    'Rolle',
    'Gottlieben',
    'Romanshorn',
    'Steckborn',
    'Confignon',
    'Niederuzwil',
    'Oberuzwil',
    'Otelfingen',
    'Andwil',
    'Hettlingen',
    'Elgg',
    'Glattfelden',
    'Thalwil',
    'Rudolfstetten',
    'Oberbuchsiten',
    'Lostorf',
    'Ecuvillens',
    'Avry',
    'Neyruz',
    'Gruyères',
    'Grandvillard',
    'Treyvaux',
    'Domdidier',
    'Faoug',
    'Broc',
    'Dompierre',
    'Ependes',
    'Opfikon',
    'Henggart',
    'Zell',
    'Russikon',
    'Seuzach Dorf',
    'Hagenbuch',
    'Pfungen',
    'Aadorf',
    'Weisslingen',
    'Gravesano',
    'Morbio Inferiore',
    'Morcote',
    'Littau',
    'Schachen',
    'Dattwil',
    'Courrendlin',
    'Courroux',
    'Coeuve',
    'Chambesy',
    'Bach',
    'Granges',
    'Apples',
    'Cudrefin',
    'Kempttal',
    'Oberembrach',
    'Reconvilier',
    'Saint-Imier',
    'Cortaillod',
    'Boudry',
    'Bevaix',
    'Fontainemelon',
    'Grandson',
    'Estavayer-le-Lac',
    'Champagne',
    'Bolligen',
    'Worblaufen',
    'Jegenstorf',
    'Ostermundigen',
    'Adliswil',
    'Kilchberg',
    'Steffisburg',
    'Spiez',
    'Einigen',
    'Durnten',
    'Hergiswil',
    'Kastanienbaum',
    'Alpnach',
    'Seegraben',
    'Monchaltorf',
    'Ebikon',
    'Aeugst am Albis',
    'Mettmenstetten',
    'Stallikon',
    'Rondchatel',
    'Schenkon',
    'Eich',
    'Oberkirch',
    'Weggis',
    'Emmen',
    'Wiesendangen',
    'Roschenz',
    'Birmensdorf',
    'Herrliberg',
    'Hombrechtikon',
    'Feldmeilen',
    'Stafa',
    'Oetwil am See',
    'Uerikon',
    'Uetikon',
    'Glattburg',
    'Villars-sur-Ollon',
    'Altstatten',
    'Arbon',
    'Buix',
    'Courtemaiche',
    'Courgenay',
    'Courtedoux',
    'Riedholz',
    'Zuchwil',
    'Gerlafingen',
    'Batterkinden',
    'Obergerlafingen',
    'Yvonand',
    'Saint-Maurice',
    "Val d'Illiez",
    'Vionnaz',
    'Villeneuve',
    'Ayent',
    'Erde',
    'Sion',
    'Bramois',
    'Sierre',
    'Vetroz',
    'Grimisuat',
    'Saint-Leonard',
    'Ayer',
    'Ossingen',
    'Le Landeron',
    'Altenrhein',
    'Abtwil',
    'Evilard',
    'Tramelan',
    'Orvin',
    'Villeret',
    'Neftenbach',
    'Niederweningen',
    'Koblenz',
    'Leibstadt',
    'Bad Zurzach',
    'Rekingen',
    'Porrentruy',
    'Court',
    'Wangen',
    'Buchs',
    'Bremgarten',
    'Eggenwil',
    'Lutry',
    'Ormalingen',
    'Mellingen',
    'Hagglingen',
    'Hendschiken',
    'Trimmis',
    'Arosa',
    'Cheseaux-sur-Lausanne',
    'Uzwil',
    'Unterehrendingen',
    'Saignelegier',
    'Frenkendorf',
    'Unterlunkhofen',
    'Jonen',
    'Les Breuleux',
    'Hermetschwil-Staffeln',
    'Oberwil',
    'Moriken',
    'Reigoldswil',
    'Waldenburg',
    'Oberglatt',
    'Thonex',
    'Eschlikon',
    'Stein',
    'Kaisten',
    'Laufenburg',
    'Hofstetten',
    'Travers',
    'Wahlen',
    'Cottens',
    'Lausen',
    'Brutten',
    'Rothrist',
    'Échallens',
    'Wald',
    'Sainte-Croix',
    'Bubikon',
    'Gossau',
    'Neunkirch',
    'Flaach',
    'Uitikon',
    'Niederurnen',
    'Uetendorf',
    'Brenzikofen',
    'Killwangen',
    'Kehrsatz',
    'Sementina',
    'Bottens',
    'Gunten',
    'Habkern',
    'Rafz',
    'Aarburg',
    'Muhlau',
    'Davos',
    'Schoetz',
    'Künten',
    'Schnottwil',
    'Saviese',
    'Conthey',
    'Arbaz',
    'Nax',
    'Evionnaz',
    'Grone',
    'Montana',
    'Martigny-Croix',
    'Chalais',
    'Granges-pres-Sion',
    'Champsec',
    'Champex',
    'Sembrancher',
    'Verbier',
    'Chippis',
    'Vercorin',
    'Fully',
    'Charrat-les-Chenes',
    'Dürrenroth',
    'Russin',
    'Prangins',
    'Fulenbach',
    'Burglen',
    'Rechthalten',
    'Charmey',
    'Niederhallwil',
    'Le Grand-Saconnex',
    'Balerna',
    'Hitzkirch',
    'Winkel',
    'Spreitenbach',
    'Termen',
    'Munster',
    'Oberhelfenschwil',
    'Stansstad',
    'Obfelden',
    'Muhen',
    'Hirzel-Kirche',
    'Rheineck',
    'Wolhusen',
    'Neuheim',
    'Root',
    'Meierskappel',
    'Werthenstein',
    'Neuenkirch',
    'Brunnen',
    'Ottenbach',
    'Walchwil',
    'Kappel',
    'Vuadens',
    'Romont',
    'Bulle',
    'Riaz',
    'Marsens',
    'Siviriez',
    'Chavannes-les-Forts',
    'Hauteville',
    'Maur',
    'Zumikon',
    'Egg',
    'Lignieres',
    'Saint-Blaise',
    'Hauterive',
    'Ennetbürgen',
    'Giswil',
    'Thierrens',
    'Epalinges',
    'Wangi',
    'Bazenheid',
    'Cremines',
    'Muhleberg',
    'Bavois',
    'Aubonne',
    'Gimel',
    'Grandval',
    'Pery',
    'Les Genevez',
    'Lurtigen',
    'Lyss',
    'Saint-Livres',
    'Meinisberg',
    'Lenzerheide',
    'Langnau',
    'Gwatt',
    'Rikon / Rikon (Dorfkern)',
    'Beringen',
    'Flurlingen',
    'Buch',
    'Schlatt',
    'Trasadingen',
    'Kappel',
    'Portalban- Dessous',
    'Schwyz',
    'Kerns',
    'Enney',
    'Cugy',
    'Kefikon',
    'Le Vaud',
    'Savigny',
    'Daniken',
    'Meggen',
    'Bangerten',
    'Le Cret',
    'Hauptwil',
    'Himmelried',
    'Bissegg',
    'Preverenges',
    'Paudex',
    'Worben',
    'Busswil',
    'Bosingen',
    'Liebistorf',
    'Tafers',
    'Cordast',
    'Heitenried',
    'Ferenbalm',
    'Schubelbach',
    'Ascona',
    'Berg',
    'Rickenbach',
    'Heimiswil',
    'Agno',
    'Erlach',
    'Freienbach',
    'Safenwil',
    'Kerzers',
    'Ins',
    'Siselen',
    'Altendorf',
    'Reichenburg',
    'Ziegelbrucke',
    'Bilten',
    'Kallnach',
    'Unteriberg',
    'La Tour-de-Peilz',
    'Bettingen',
    'Villmergen',
    'Corcelles',
    'Arisdorf',
    'Fullinsdorf',
    'Nafels',
    'Mollis',
    'Hundwil',
    'Uznach',
    'Sankt Gallenkappel',
    'Ennenda',
    'Fahrwangen',
    'Blumenstein',
    'Einsiedeln',
    'Rothenthurm',
    'Corcelles',
    'Sariswil',
    'Thorishaus',
    'Niederlenz',
    'Wildegg',
    'Staufen',
    'Stadel',
    'Airolo',
    'Netstal',
    'Glarus',
    'Goslikon',
    'Sarmenstorf',
    'La Chiesaz',
    'Yvorne',
    'Vouvry',
    'Burglen',
    'Schindellegi',
    'Kolliken',
    'Lungern',
    'Inwil',
    'Felsberg',
    'Kaiserstuhl',
    'Alt-Bachs',
    'Siglistorf',
    'Chavannes',
    'Bottenwil',
    'Brittnau',
    'Vordemwald',
    'Villars',
    'Cossonay',
    'Densbueren',
    'Courtelary',
    'Niedergosgen',
    'Erschwil',
    'Quartino',
    'Saint-Cergue',
    'Chavannes de Bogis',
    'Tesserete',
    'Interlaken',
    'Benken',
    'Suhr',
    'Sonvico',
    'Udligenswil',
    'Pfeffikon',
    'Sigriswil',
    'Därligen',
    'Buchs',
    'Muttenz',
    'Caslano',
    'Stein',
    'Othmarsingen',
    'Zuzgen',
    'Canobbio',
    'Stans',
    'Buochs',
    'Dallenwil',
    'Wolfenschiessen',
    'Beckenried',
    'Buren nid dem Bach',
    'Brügg',
    'Zuzwil',
    'Neukirch',
    'Amriswil',
    'Horn',
    'Novazzano',
    'Magadino',
    'Gordola',
    'Matzingen',
    'Ellikon an der Thur',
    'Melano',
    'Zwingen',
    'Gunzgen',
    'Ricken',
    'Adligenswil',
    'Steinen',
    'Grosswangen',
    'Rueggisberg',
    'Aeschau',
    'Schinznach Dorf',
    'Obergosgen',
    'Untererlinsbach',
    'Seon',
    'Villnachern',
    'Founex',
    'Jussy',
    'Oron-la-ville',
    'Arth',
    'Aesch',
    'Worb',
    'Cressier',
    'Gryon',
    'Ruswil',
    'Weesen',
    'Wangen',
    'Flamatt',
    'Amsoldingen',
    'Effretikon',
    'Waldstatt',
    'Bonnefontaine',
    'Le Bry',
    'Bellerive',
    'Vex',
    'Veytaux',
    'Epautheyres',
    'Rothenbach',
    'Schoftland',
    'Nesslau',
    'Rebstein',
    'Schattdorf',
    'Grancy',
    'Zuckenriet',
    'Hoerstetten',
    'Essertines-sur-Rolle',
    "L'Abbaye",
    'Biere',
    'Vernayaz',
    'Feldbach',
    'Chapelle',
    'Villaz-Saint-Pierre',
    'Birsfelden',
    'Oberhofen bei Etzgen',
    'Vaulruz',
    'Steg',
    'Vallorbe',
    'Alterswil',
    'Niederbuchsiten',
    'Munchwilen',
    "L'Isle",
    'Plaffeien',
    'Sins',
    'Buttes',
    'Mumpf',
    'Soyhieres',
    'Gampelen',
    'Kiesen',
    'Davos Platz',
    'Vauderens',
    'Chamoson',
    'Ovronnaz',
    'Froideville',
    'Felben',
    'Neu-Rheinau',
    'Lenk',
    'Territet',
    'Leutwil',
    'Oberrieden',
    'Oberstocken',
    'Bubendorf',
    'Veltheim',
    'Grandcour',
    'Tuggen',
    'Saint-Aubin-Sauges',
    'Les Verrieres',
    'Wattwil',
    'Rhazuns',
    'Contone',
    'Brissago',
    'Neuhausen',
    'Tauffelen',
    'Sankt Margrethen',
    'Chatelaine',
    'Vicques',
    'Eschenbach',
    'Cernier',
    'Hildisrieden',
    'Degersheim',
    'Dardagny',
    'Morschach',
    'Malters',
    'Buchrain',
    'Fluehli',
    'Buttisholz',
    'Hellbuehl',
    'Schuepfheim',
    'Cartigny',
    'Rue',
    'Begnins',
    'Coppet',
    'La Plaine',
    'Graenichen',
    'Maggia',
    'Rossens',
    'Busserach',
    'Grindelwald',
    'Basadingen',
    'Dachsen',
    'Eglisau',
    'Rudlingen',
    'Buchberg',
    'Stein am Rhein',
    'Nohl',
    'Hemmental',
    'Ramsen',
    'Grabs',
    'Mels',
    'Lienz',
    'Au',
    'Sargans',
    'Balgach',
    'Sevelen',
    'Sennwald',
    'Cressier',
    'Thielle',
    'Gachlingen',
    'Erlen',
    'Diepoldsau',
    'Bad Ragaz',
    'Daillens',
    'Rohrbach',
    'Muolen',
    'Gipf-Oberfrick',
    'Islikon',
    'Wauwil',
    'Freidorf',
    'Schonenwerd',
    'Niedererlinsbach',
    'Wittnau',
    'Thalheim',
    'Auenstein',
    'Schafisheim',
    'Moosleerau',
    'Teufenthal',
    'Unterkulm',
    'Gontenschwil',
    'Reiden',
    'Dagmersellen',
    'Ibach',
    'Fischenthal',
    'Tavannes',
    'Hornussen',
    'Vinzel',
    'Klosters Serneus',
    'Furstenaubruck',
    'Wolfgang',
    'Cheyres',
    'Rohr',
    'Hofstetten',
    'Bachenbulach',
    'Thundorf',
    'Pfaffnau',
    'Corsier',
    'Le Noirmont',
    'Montagnola',
    'Gandria',
    'Selzach',
    'Biel-Benken',
    'Landquart',
    'Arni',
    'Auvernier',
    'Schonenberg',
    'Bauma',
    'Gordevio',
    'Iragna',
    'Durrenasch',
    'Oberegg',
    'Hittnau',
    'Boll',
    'Campfer',
    'Obersaxen',
    'Dombresson',
    'Sottens',
    'Oberdiessbach',
    'Langnau',
    'Geuensee',
    'Utzenstorf',
    'Sezegnin',
    'Koppigen',
    'Bonaduz',
    "Chateau-d'Oex",
    'Corminboeuf',
    'Les Pommerats',
    'Ligerz',
    'Trubschachen',
    'Oberhofen',
    'Miecourt',
    'Courtetelle',
    'La Chaux',
    'Gampel',
    'Ilanz',
    'Bellmund',
    'Emmetten',
    'Grund',
    'Flims',
    'Waldhaus',
    'Laax',
    'Igis',
    'Luvis',
    'Weiningen',
    'Brislach',
    'Buren an der Aare',
    'Belfaux',
    'Attalens',
    'Palezieux',
    'La Sarraz',
    'Wil',
    'Bronschhofen',
    'Hausen',
    'La Roche',
    'Ballwil',
    'Goldau',
    'Sorengo',
    'Agra',
    'Concise',
    'Eiken',
    'Beuson',
    'Scuol',
    'Diegten',
    'Wangen an der Aare',
    'Schonenbuch',
    'Orsonnens',
    'Bigenthal',
    'Baulmes',
    'Ettingen',
    'Aettenschwil',
    'Wigoltingen',
    'Hilterfingen',
    'Kaltbrunn',
    'Chez-le-Bart',
    'Unter-Teufen',
    'Wichtrach',
    'Adelboden',
    'Wimmis',
    'Saanenmoser',
    'Gommiswald',
    'Klosters Platz',
    'Davos Dorf',
    'Eggersriet',
    'Bühler',
    'Oberriet',
    'Ruthi',
    'Eichberg',
    'Camorino',
    'Pampigny',
    'Yens',
    'Auw',
    'Onnens',
    'Kleinlutzel',
    'Seftigen',
    'Grolley',
    'Weissbad',
    'Kradolf',
    'Mettendorf',
    'Stettfurt',
    'Matt',
    'Assens',
    'Bursins',
    'Morgins',
    'Flawil',
    'Full',
    'Sirnach',
    'Saint-Aubin',
    'Muotathal',
    'Satigny',
    'Vilters',
    'Guttingen',
    'Altishofen',
    'Schongau',
    'Vitznau',
    'Sattel',
    'Attinghausen',
    'Novaggio',
    'Buonas',
    'Bogis-Bossey',
    'Alchenstorf',
    'Noreaz',
    'Buus',
    'Anieres',
    'Alberswil',
    'Immensee',
    'Givrins',
    'Borex',
    'Meinier',
    'Henau',
    'Hausen am Albis / Hausen (Dorf)',
    'Claro',
    'Lodrino',
    'Waldkirch',
    'Richigen',
    'Arogno',
    'Glaris',
    'Maschwanden',
    'Schmerikon',
    'Euthal',
    'Orpund',
    'Zollbruck',
    'Sulz',
    'Hochwald',
    'Gersau',
    'Bottighofen',
    'Walzenhausen',
    'Egnach',
    'Heiden',
    'Maerstetten-Dorf',
    'Mullheim',
    'Grono',
    'Sarn',
    'Tasch',
    'Les Hauts-Geneveys',
    'Tanay',
    'Niederburen',
    'Lutisburg',
    'Mosnang',
    'Maisprach',
    'Amden',
    'Arzier',
    'Gelfingen',
    'Aristau',
    'Schleitheim',
    'Semsales',
    'Porsel',
    'Gerzensee',
    'Niederscherli',
    'Ingenbohl',
    'Courfaivre',
    'Aesch',
    'Gais',
    'Lichtensteig',
    'Pfeffingen',
    'Rueyres',
    'Colombier',
    'Diessbach',
    'Goldiwil',
    'Fraubrunnen',
    'Messen',
    'Konolfingen',
    'Triengen',
    'Castione',
    'Krauchthal',
    'Ursenbach',
    'Chene-Paquier',
    'Sonvilier',
    'Fleurier',
    'Lenz',
    'Lützelflüh',
    'Langenbruck',
    'Uerkheim',
    'Cadenazzo',
    "Cassina d'Agno",
    'Trubbach',
    'Thalheim',
    'Schmitten',
    'Schwellbrunn',
    'Gettnau',
    'Maroggia',
    'Altnau',
    'Roggwil',
    'Oberwangen',
    'Trogen',
    'Chavornay',
    'Vuiteboeuf',
    'Rances',
    'Romainmotier',
    'Le Brassus',
    'Ardon',
    'Leysin',
    'Jonschwil',
    'Engelberg',
    'Biasca',
    'Capolago',
    'Manno',
    'Feldbrunnen',
    'Unterseen',
    'Twann',
    'Arzo',
    'Burchen',
    'Merishausen',
    'Urnäsch',
    'Balterswil',
    'Menzingen',
    'Iserables',
    'Bissone',
    'Glion',
    'Melchnau',
    'Mettlen',
    'Buetschwil',
    'Zihlschlacht',
    'Ried',
    'Bitsch',
    'Les Geneveys-sur-Coffrane',
    'Pfyn',
    'Riggisberg',
    'Bigorio',
    'Zeihen',
    'Roveredo',
    'Arbedo',
    'Chesieres',
    'Schiers',
    'Seedorf',
    'Erstfeld',
    'Hasle',
    'Felsenau',
    'Kollbrunn',
    'Eggiwil',
    'Rehetobel',
    'Genestrerio',
    'Grellingen',
    'Oberbalm',
    'Gerlikon',
    'Barbereche',
    'Schinznach Bad',
    'Cugnasco',
    'Wila',
    'Iseo',
    'Schwarzenberg',
    'Pura',
    'Waltenschwil',
    'Rapperswil',
    'Gnosca',
    'Perroy',
    'Chancy',
    'Liddes',
    'Grimentz',
    'Seelisberg',
    'Schonried',
    'Greppen',
    'Thusis',
    'Saanen',
    'Zullwil',
    'Sils-Segl Maria',
    'Celerina',
    'Raperswilen',
    'Feusisberg',
    'Sonceboz',
    'Salmsach',
    'Ittenthal',
    'Laufelfingen',
    'Lauerz',
    'Les Acacias',
    'Le Sentier',
    'Herdern',
    'Gonten',
    'Andermatt',
    'Stoos',
    'Zunzgen',
    'Niederhelfenschwil',
    'Wuppenau',
    'Lommis',
    'Ganterschwil',
    'Wilderswil',
    'Ringgenberg',
    'Brienz',
    'Bonigen',
    'Meiringen',
    'Walkringen',
    'Ufhusen',
    'Zell',
    'Ouchy',
    'Villa',
    'Apro',
    'Finhaut',
    'Allaman',
    'Saint-Sulpice',
    'Motiers',
    'Winterberg',
    'Lindau',
    'Schupfen',
    'Perlen',
    'Arcegno',
    'Kulm',
    'Tschuggen',
    'Trun',
    'Wolfhalden',
    'Savognin',
    'Ulrichen',
    'Lauenen',
    'Sumiswald',
    'Cheiry',
    'Vessy',
    'Hunzenschwil',
    'Dozwil',
    'Montet',
    'Wengen',
    'Raron',
    'Verscio',
    'Flueli',
    'Rufi',
    'Hermance',
    'Saas-Grund',
    'Jenaz',
    'Loco',
    'Roemerswil',
    'Aeschlen ob Gunten',
    'Escholzmatt',
    'Ferden',
    'Herbetswil',
    'Grossandelfingen',
    'Mühlehorn',
    'Muerren',
    'Zufikon',
    'Obervaz',
    'Rifferswil',
    'Les Diablerets',
    'Brusino Arsizio',
    'Sessa'
  ],
  Iraq: [
    'Baghdad',
    'Karkh',
    'Sulaymaniyah',
    'Kirkuk',
    'Erbil',
    'Basra',
    'Bahr',
    'Tikrit',
    'Najaf',
    'Al Hillah',
    'Mosul',
    'Haji Hasan',
    'Al `Amarah',
    'Basere',
    'Manawi',
    'Hayat'
  ],
  Turkey: [
    'Izmir',
    'Ankara',
    'Antakya',
    'Dortyol',
    'İskenderun',
    'Bitlis',
    'Samsun',
    'Istanbul',
    'Antalya',
    'Kisir',
    'Orhangazi',
    'Bursa',
    'Gebze',
    'Trabzon',
    'Ordu',
    'Rize',
    'Diyarbakır',
    'İzmit',
    'Mersin',
    'Etimesgut',
    'Adana',
    'Adapazarı',
    'Sincan',
    'Eskisehir',
    'Sisli',
    'Magnesia ad Sipylum',
    'Bergama',
    'Tire',
    'Balcova',
    'Konya',
    'Kartal',
    'Kibriskoy',
    'Mahmutkoy',
    'Edirne',
    'Denizli',
    'Zonguldak',
    'Muratpasa',
    'Sisman',
    'Kirikkale',
    'Karamursel',
    'Atakoy',
    'Bodrum',
    'Fatih',
    'Kadikoy',
    'Kayseri',
    'Acibadem',
    'Çorlu',
    'Erzurum',
    'Sanayi',
    'Sanliurfa',
    'Dogankoy',
    'Kars',
    'Mugla',
    'Isparta',
    'Bulut',
    'Girne',
    'Bogazici',
    'Ugur',
    'Bilgi',
    'Osmaniye',
    'Afyonkarahisar',
    'Malatya',
    'Mardin',
    'Kahramanmaraş',
    'Batman',
    'Nevşehir',
    'Kırşehir',
    'Amasya',
    'Yalova',
    'Balıkesir',
    'Doga',
    'Merzifon',
    'Tekirdağ',
    'Gaziantep',
    'Bandirma',
    'Kadikoey',
    'Maltepe',
    'Erzincan',
    'Guzelyurt',
    'Çanakkale',
    'Golmarmara',
    'Çerkezköy',
    'Sultanpinar',
    'Dogus',
    'Burgaz',
    'Aydogan',
    'Bolu',
    'Bilecik',
    'Duzce',
    'Siirt',
    'Cermik',
    'Seydisehir',
    'Kastamonu',
    'Kecioeren',
    'Demetevleri',
    'Yaman',
    'Gümüşhane',
    'Bayburt',
    'Ardeşen',
    'Giresun',
    'Sivas',
    'Aydin',
    'Ceyhan',
    'Ada',
    'Kozan',
    'Seyhan',
    'Muş',
    'Ardahan',
    'Manavgat',
    'Burdur',
    'Bartin',
    'Iskilip',
    'Çorum',
    'Sehitkamil',
    'Yozgat',
    'Calkaya',
    'Niğde',
    'Van',
    'Aksaray',
    'Ercis',
    'Nizip',
    'Tunceli',
    'Karabük Province',
    'Karabuk',
    'Hakkari',
    'Görele',
    'Tokat Province',
    'Artvin',
    'Niksar',
    'Kadirli',
    'Cekme',
    'Kucukcekmece',
    'Niluefer',
    'Ağrı',
    'UEskuedar',
    'Pendik',
    'Cine',
    'Selcuk',
    'Umraniye',
    'Uşak',
    'Banaz',
    'Konak',
    'Nazilli',
    'Mustafakemalpasa',
    'Gemlik',
    'İnegöl',
    'Lüleburgaz',
    'Uchisar',
    'Urgub',
    'Tesvikiye',
    'Kırklareli',
    'Esenyurt',
    'Silifke',
    'Siliviri',
    'Bueyuekcekmece',
    'Bayrampasa',
    'Zeytinburnu',
    'Uzun Keupru',
    'Edremit',
    'Soeke',
    'Kusadasi',
    'Erbaa',
    'Tarsus',
    'Karaman',
    'Elâzığ',
    'Besiktas',
    'Kütahya',
    'Sürmene',
    'Gelibolu',
    'Karatekin',
    'Sirnak',
    'Alpaslan',
    'Mehmet Akif Ersoy',
    'Harran',
    'Yagcilar',
    'Sinop',
    'Bingol',
    'Marmaris',
    'Çankırı',
    'Patnos',
    'Maslak',
    'Kagithane',
    'Yasarkoy',
    'Harbiye',
    'Ulker',
    'Safranbolu',
    'Taksim',
    'Beykoz',
    'Bor',
    'Yuregir',
    'Germencik',
    'Torbali',
    'Ikitelli',
    'Zekeriya',
    'Amasra',
    'Ayazaga',
    'Karaca',
    'Bozuyuk',
    'Darica',
    'Geulzuk',
    'Elbistan',
    'Findikli',
    'Osmaneli',
    'Camliyayla',
    'Sahinbey',
    'Baskil',
    'Kumluca',
    'Bucak',
    'Erzin',
    'Meric',
    'Sariyer',
    'Akhisar',
    'Turgutlu',
    'Biga',
    'Salihli',
    'Burhaniye',
    'Gonen',
    'Hendek',
    'Dalaman',
    'Milas',
    'Fethiye',
    'Urla',
    'Seferihisar',
    'Bornova',
    'Buca',
    'Akçaabat',
    'Yakakoy',
    'Pamukkale',
    'Sile',
    'Unieh',
    'Beykent',
    'Kocatepe',
    'Işık',
    'Yeni',
    'Gunbuldu',
    'Ostim',
    'Tasdelen',
    'Esenkent',
    'Esentepe',
    'Mezitli',
    'Ibrahim',
    'Erenkoy',
    'Kuzey',
    'Belek',
    'Sungurlu',
    'Alanya',
    'Serik',
    'Babaeski',
    'Serdivan',
    'Yildiz',
    'Suleyman',
    'Ataturk',
    'Abdullah',
    'Doruklu',
    'Mustafa Kemal',
    'Bitam',
    'Toros',
    'Elek',
    'Dokuz',
    'Kocakoy',
    'Muradiye',
    'Kilis',
    'Korfez',
    'Andac',
    'Birbir',
    'Dayanikli',
    'Bugdayli',
    'Aydogdu',
    'Mustafa',
    'Duran',
    'Eser',
    'Beysehir',
    'Ferizli',
    'Karakoy',
    'Turgut',
    'Dogu',
    'Cukurova',
    'Basari',
    'Edebey',
    'Piri',
    'Sabanci',
    'Osmangazi',
    'Kadir',
    'Baskent',
    'Kemal',
    'Adıyaman',
    'Gediz',
    'Trakya',
    'Gazi',
    'Sinankoy',
    'Adnan Menderes',
    'Celal',
    'Deniz',
    'Uludag',
    'Iğdır',
    'Erciyes',
    'Meliksah',
    'Dumlupinar',
    'Toprak',
    'Yeditepe',
    'Orta',
    'Hurriyet',
    'Atlas',
    'Ulus',
    'Tatvan',
    'Saglik',
    'Yenibosna',
    'Susurluk',
    'İbişkōy',
    'Halic'
  ],
  Romania: [
    'Piteşti',
    'Bucharest',
    'Bacău',
    'Roman',
    'Oneşti',
    'Piatra Neamţ',
    'Galați',
    'Constanța',
    'Cernavodă',
    'Tulcea',
    'Navodari',
    'Calarasi',
    'Sibiu',
    'Târgu Mureş',
    'Râmnicu Vâlcea',
    'Cluj-Napoca',
    'Alba Iulia',
    'Mediaş',
    'Petresti',
    'Botoşani',
    'Craiova',
    'Buzău',
    'Pantelimon',
    'Cuza Voda',
    'Mihai Bravu',
    'Santimbru',
    'Braşov',
    'Timişoara',
    'Targu Gangulesti',
    'Suceava',
    'Motru',
    'Teiu',
    'Voronet',
    'Giurgiu',
    'Borca',
    'Filipestii de Padure',
    'Cetatea de Balta',
    'Alexandria',
    'Cetatuia',
    'Berzasca',
    'Cernisoara',
    'Miercurea Sibiului',
    'Miercurea-Ciuc',
    'Racoasa',
    'Panciu',
    'Urziceni',
    'Seini',
    'Bucu',
    'Corbeanca',
    'Hunedoara',
    'Ramnicu Sarat',
    'Chiajna',
    'Salcioara',
    'Târgovişte',
    'Floresti',
    'Alba',
    'Pucheni',
    'Ploieşti',
    'Bistriţa',
    'Turda',
    'Odorheiu Secuiesc',
    'Zalău',
    'Pascani',
    'Buhusi',
    'Radauti',
    'Musenita',
    'Bocsa',
    'Campina',
    'Falticeni',
    'Buftea',
    'Magurele',
    'Dimitrie Cantemir',
    'Focşani',
    'Garla-Mare',
    'Lehliu-Gara',
    'Jibou',
    'Rosia',
    'Turnu Ruieni',
    'Salatrucel',
    'Blaj',
    'Reghin',
    'Petroşani',
    'Popesti-Leordeni',
    'Tomesti',
    'Iași',
    'Deva',
    'Campia Turzii',
    'Lunguletu',
    'Slobozia',
    'Mangalia',
    'Titu',
    'Chitila',
    'Campia',
    'Chisoda',
    'Bârlad',
    'Vaslui',
    'Oradea',
    'Târgu Jiu',
    'Hateg',
    'Arad',
    'Bethausen',
    'Simeria',
    'Fogarasch',
    'Huedin',
    'Reşiţa',
    'Cisnadie',
    'Carei',
    'Satu Mare',
    'Curtea de Argeş',
    'Victoria',
    'Husi',
    'Orsova',
    'Slatina',
    'Ditrau',
    'Lugoj',
    'Tecuci',
    'Baia Mare',
    'Caransebes',
    'Gaesti',
    'Cugir',
    'Sighetu Marmaţiei',
    'Traian',
    'Mihail Kogalniceanu',
    'Madaras',
    'Decebal',
    'Sovata',
    'Voluntari',
    'Otopeni',
    'Geoagiu',
    'Brăila',
    'Afumati',
    'Schela',
    'Otelu Rosu',
    'Mircea',
    'Obreja',
    'Horia',
    'Rosiori de Vede',
    'Corabia',
    'Turnu Magurele',
    'Gherla',
    'Viseu de Jos',
    'Iernut',
    'Busteni',
    'Sfantu Gheorghe',
    'Dobroesti',
    'Unirea',
    'Spiru Haret',
    'Marginea',
    'Aiud',
    'Berceni',
    'Lacu',
    'Domnesti',
    'Racovita',
    'Zimnicea',
    'Buzias',
    'Moinesti',
    'Bragadiru',
    'Vetresti-Herastrau',
    'Targu Neamt',
    'Sfantu-Gheorghe',
    'Avrig',
    'Mogosoaia',
    'Sighisoara',
    'Crasna',
    'Bogdanita',
    'Baicoi',
    'Harsova',
    'Ungheni',
    'Vidin',
    'Bascov',
    'Racari',
    'Fetesti',
    'Ocna Mures',
    'Covasna',
    'Trestiana',
    'Catcau',
    'Gheorgheni',
    'Medgidia',
    'Nasaud',
    'Somcuta Mare',
    'Lacu Sinaia',
    'Caracal',
    'Magura',
    'Timus',
    'Cristuru Secuiesc',
    'Vacaresti',
    'Cara',
    'Doamna',
    'Ludesti',
    'Gherea',
    'Valea Seaca',
    'Alunu',
    'Lazuri',
    'Târnăveni',
    'Moldova Noua',
    'Eforie',
    'Matasari',
    'Crangasi',
    'Tina',
    'Sangeorge',
    'Nucsoara',
    'Bilciuresti',
    'Iorga',
    'Dorohoi',
    'Uioara de Jos',
    'Faget',
    'Bucurestii Noi',
    'Tulnici',
    'Petresti',
    'Catunele',
    'Urlati',
    'Nana',
    'Mizil',
    'Oltenita',
    'Bozieni',
    'Valenii de Munte',
    'Comana',
    'Fabrica',
    'Baba Novac',
    'Adjud',
    'Popesti',
    'Tatarani',
    'Roata de Jos',
    'Moisei',
    'Vedea',
    'Livezi',
    'Falcau',
    'Calan',
    'Nădlac',
    'Bujor',
    'Campeni',
    'Titesti',
    'Bucsani',
    'Masloc',
    'Giarmata',
    'Moreni',
    'Lenauheim',
    'Dudestii Vechi',
    'Voiteg',
    'Tipari',
    'Deta',
    'Becicherecu Mic',
    'Gataia',
    'Dej',
    'Gilau',
    'Daia Romana',
    'Teius',
    'Unirea',
    'Salonta',
    'Tileagd',
    'Alesd',
    'Soimus',
    'Viseu de Sus',
    'Borsa',
    'Baia Sprie',
    'Branistea',
    'Rohia',
    'Sangeorz-Bai',
    'Floresti',
    'Mioveni',
    'Utvin',
    'Tartasesti',
    'Floresti',
    'Bolintin Deal',
    'Tunari',
    'Tandarei',
    'Filias',
    'Sfantu Gheorghe',
    'Apata',
    'Cotnari',
    'Tuzla',
    'Poiana Ilvei',
    'Radovanu',
    'Fundulea',
    'Carbunesti',
    'Costesti',
    'Stalpeni',
    'Stroesti',
    'Voinesti',
    'Comanesti',
    'Iadara',
    'Sancraieni',
    'Agnita',
    'Breaza',
    'Plopeni',
    'Telega',
    'Bobolia',
    'Magurele',
    'Vatra Dornei',
    'Vama',
    'Gramesti',
    'Corlateni',
    'Gura Humorului',
    'Salcea',
    'Codlea',
    'Prejmer',
    'Talisoara',
    'Baraolt',
    'Dor Marunt',
    'Valea Lupului',
    'Birda',
    'Dumbravita',
    'Mocira',
    'Curtici',
    'Tibeni',
    'Topoloveni',
    'Barbuletu',
    'Dridu',
    'Balotesti',
    'Jilava',
    'Chiselet',
    'Ocnita',
    'Albesti-Paleologu',
    'Calinesti',
    'Corbita',
    'Dragotesti',
    'Moara Vlasiei',
    'Marasheshty',
    'Prod',
    'Apahida',
    'Susenii Bargaului',
    'Banesti',
    'Brazii de Sus',
    'Sutesti',
    'Ion Ghica',
    'Râşnov',
    'Grojdibodu',
    'Oravita',
    'Fagetu',
    'Giroc',
    'Macin',
    'Sebes',
    'Lancram',
    'Saveni',
    'Bucecea',
    'Padureni',
    'Cristian',
    'Stefanesti',
    'Baile Olanesti',
    'Balomiru de Camp',
    'Glina',
    'Prim Decembrie',
    'Campulung Moldovenesc',
    'Negresti',
    'Ciumani',
    'Potlogi',
    'Stefanestii de Jos',
    'Cojasca',
    'Rabagani',
    'Stanilesti',
    'Videle',
    'Tatarusi',
    'Murfatlar',
    'Calafat',
    'Chisineu-Cris',
    'Mandruloc',
    'Moroda',
    'Cris',
    'Pecica',
    'Cobadin',
    'Nenciulesti',
    'Panduri',
    'Campulung Muscel',
    'Bic',
    'Manta',
    'Bumbesti-Jiu',
    'Baiut',
    'Negru Voda',
    'Cosbuc',
    'Humulesti',
    'Vidra',
    'Filiasi',
    'Izvoru Crisului',
    'Zetea',
    'Giulesti',
    'Odobesti',
    'Hangulesti',
    'Sacele',
    'Marasti',
    'Podu Dambovitei',
    'Siculeni',
    'Valcea',
    'Patarlagele',
    'Matei',
    'Nicolae Balcescu'
  ],
  Lebanon: [
    'Beirut',
    'Zgharta',
    'Bsalim',
    'Halba',
    'Ashrafiye',
    'Sidon',
    'Dik el Mehdi',
    'Baalbek',
    'Tripoli',
    'Baabda',
    'Adma',
    'Hboub',
    'Yanar',
    'Dbaiye',
    'Aaley',
    'Broummana',
    'Sarba',
    'Chekka'
  ],
  Hungary: [
    'Dunaharaszti',
    'Budapest',
    'Gyal',
    'Nyirmartonfalva',
    'Csorvas',
    'Esztergom',
    'Kunhegyes',
    'Alsopahok',
    'Sarmellek',
    'Edeleny',
    'Balatonfured',
    'Koszeg',
    'Szombathely',
    'Tiszafured',
    'Ullo',
    'Érd',
    'Gyula',
    'Kistelek',
    'Szeged',
    'Sarszentmihaly',
    'Rakocziujfalu',
    'Kiskunfelegyhaza',
    'Ajak',
    'Ujszilvas',
    'Kecskemét',
    'Pécs',
    'Debrecen',
    'Hegyhatmaroc',
    'Szolnok',
    'Mako',
    'Apatfalva',
    'Klarafalva',
    'Békéscsaba',
    'Posfa',
    'Tiszaujvaros',
    'Keszu',
    'Tata',
    'Kisujszallas',
    'Kerekegyhaza',
    'Miskolc',
    'Kunszentmarton',
    'Visegrád',
    'Solymar',
    'Piliscsaba',
    'God',
    'Budajeno',
    'Budakalasz',
    'Karcag',
    'Nagykoros',
    'Salfold',
    'Kapuvar',
    'Vitnyed',
    'Repceszemere',
    'Szajol',
    'Tapioszele',
    'Almasfuzito',
    'Szecseny',
    'Nogradsipek',
    'Kacsota',
    'Pakod',
    'Zalaegerszeg',
    'Szentkatalin',
    'Beloiannisz',
    'Fot',
    'Erdokertes',
    'Kerepes',
    'Csecse',
    'Taplanszentkereszt',
    'Szazhalombatta',
    'Zahony',
    'Kisvarda',
    'Kistarcsa',
    'Mateszalka',
    'Boly',
    'Szajk',
    'Oroshaza',
    'Medgyesegyhaza',
    'Vegegyhaza',
    'Hodmezovasarhely',
    'Totkomlos',
    'Nyiregyhaza',
    'Pomaz',
    'Kismaros',
    'Veresegyhaz',
    'Kisgyor',
    'Siófok',
    'Győr',
    'Pusztavam',
    'Szemely',
    'Hajdusamson',
    'Tiszalok',
    'Ajka',
    'Székesfehérvár',
    'Szerencs',
    'Beled',
    'Nagynyarad',
    'Aparhant',
    'Komlo',
    'Komárom',
    'Szabadbattyan',
    'Soskut',
    'Eperjeske',
    'Tiszaszalka',
    'Somogysard',
    'Kaposvár',
    'Diosd',
    'Pusztazamor',
    'Mende',
    'Mosonmagyaróvár',
    'Bonyhad',
    'Sasd',
    'Nagymanyok',
    'Szekszárd',
    'Tolna',
    'Paks',
    'Seregelyes',
    'Kisvaszar',
    'Dunafoldvar',
    'Tevel',
    'Nemetker',
    'Kurd',
    'Kulcs',
    'Alsoszentivan',
    'Sarbogard',
    'Sajoszentpeter',
    'Mucsony',
    'Sajokapolna',
    'Kazincbarcika',
    'Balatonszabadi',
    'Balatonszarszo',
    'Per',
    'Kadarkut',
    'Lebeny',
    'Fertoszentmiklos',
    'Dunakeszi',
    'Ivancsa',
    'Telep',
    'Lajoskomarom',
    'Roszke',
    'Gödöllő',
    'Csogle',
    'Csomor',
    'Tatabánya',
    'Dunaújváros',
    'Tompa',
    'Batonyterenye',
    'Veszprém',
    'Tura',
    'Parádfürdő',
    'Gyomro',
    'Békés',
    'Csepa',
    'Nagyhalasz',
    'Isaszeg',
    'Gorgeteg',
    'Somogyudvarhely',
    'Labod',
    'Nagyatad',
    'Sandorfalva',
    'Lenti',
    'Zalalovo',
    'Nagypali',
    'Boncodfolde',
    'Szigliget',
    'Tapolca',
    'Balloszog',
    'Kiskunhalas',
    'Gyongyos',
    'Nagycserkesz',
    'Gencsapati',
    'Pecol',
    'Nemesbod',
    'Vep',
    'Sarvar',
    'Szentendre',
    'Szirmabesenyo',
    'Helvecia',
    'Bukkaranyos',
    'Hernadkak',
    'Almosd',
    'Gyorsag',
    'Fertod',
    'Ikreny',
    'Telki',
    'Nyirtass',
    'Hont',
    'Saska',
    'Bataszek',
    'Pellerd',
    'Mohács',
    'Gyenesdias',
    'Bicske',
    'Szomod',
    'Alsogalla',
    'Lovas',
    'Kisber',
    'Polgardi',
    'Cegled',
    'Pilisvorosvar',
    'Nagyszenas',
    'Domaszek',
    'Nagyszekeres',
    'Asotthalom',
    'Hercegszanto',
    'Egyek',
    'Bohonye',
    'Zsambek',
    'Sopron',
    'Kormend',
    'Tiszaluc',
    'Arnot',
    'Inancs',
    'Taktaszada',
    'Csobad',
    'Sajokeresztur',
    'Dorog',
    'Hatvan',
    'Hosszuheteny',
    'Mogyorod',
    'Urom',
    'Aszod',
    'Balatonalmadi',
    'Szodliget',
    'Galgamacsa',
    'Ozd',
    'Nagydobsza',
    'Rabapatona',
    'Csorna',
    'Kony',
    'Szilsarkany',
    'Pilismarot',
    'Ercsi',
    'Visznek',
    'Biharkeresztes',
    'Hajduboszormeny',
    'Nagytotfalu',
    'Nyirbator',
    'Nemesbuek',
    'Budaors',
    'Teglas',
    'Vamosszabadi',
    'Otteveny',
    'Tab',
    'Balatonlelle',
    'Kalocsa',
    'Simontornya',
    'Mezoszilas',
    'Deg',
    'Nyul',
    'Gyorujbarat',
    'Csanadpalota',
    'Galambok',
    'Badacsonytomaj',
    'Nagykanizsa',
    'Ujfeherto',
    'Boldog',
    'Eger',
    'Salgotarjan',
    'Nagykallo',
    'Aszalo',
    'Onga',
    'Budakeszi',
    'Toeroekbalint',
    'Balatonfuzfo',
    'Berhida',
    'Mor',
    'Szikszo',
    'Gyongyossolymos',
    'Maklar',
    'Szigetszentmiklos',
    'Farmos',
    'Varpalota',
    'Magyaregres',
    'Kaposmero',
    'Nagyberki',
    'Taszar',
    'Cserenfa',
    'Juta',
    'Kartal',
    'Nagyvenyim',
    'Marokpapi',
    'Baja',
    'Kubekhaza',
    'Tiszafoldvar',
    'Szentgotthard',
    'Hegyeshalom',
    'Bag',
    'Hevizgyoerk',
    'Janossomorja',
    'Kistokaj',
    'Delegyhaza',
    'Agard',
    'Alsozsolca',
    'Gardony',
    'Bokod',
    'Homrogd',
    'Csobanka',
    'Mezotur',
    'Paty',
    'Hajdunanas',
    'Szalaszend',
    'Hernadnemeti',
    'Nagykoru',
    'Biatorbagy',
    'Marcali',
    'Hajduszoboszlo',
    'Mesztegnyo',
    'Danszentmiklos',
    'Encs',
    'Verseg',
    'Tet',
    'Halaszi',
    'Gyorladamer',
    'Pilisszentkereszt',
    'Tarnok',
    'Abony',
    'Sajovamos',
    'Vamospercs',
    'Varosfoeld',
    'Cserszegtomaj',
    'Berettyoujfalu',
    'Keszthely',
    'Lajosmizse',
    'Zalakomar',
    'Martonvasar',
    'Balkany',
    'Toekoel',
    'Bakonyszentlaszlo',
    'Egyhazashetye',
    'Papa',
    'Zsambok',
    'Repcelak',
    'Vacszentlaszlo',
    'Bajna',
    'Vác',
    'Csovar',
    'Magy',
    'Nagyhegyes',
    'Ladbesenyo',
    'Korosladany',
    'Garab',
    'Rabapaty',
    'Adony',
    'Felsoszentivan',
    'Puspokladany',
    'Zebecke',
    'Lovaszi',
    'Paka',
    'Gyar',
    'Mosonszentmiklos',
    'Leanyfalu',
    'Szany',
    'Velence',
    'Tarjan',
    'Pakozd',
    'Borcs',
    'Oroszlany',
    'Fuzesabony',
    'Bakonyszombathely',
    'Szigetvar',
    'Kornye',
    'Malyi',
    'Selyeb',
    'Kiskinizs',
    'Sored',
    'Bakonycsernye',
    'Nagykereki',
    'Sarospatak',
    'Torokszentmiklos',
    'Balatonboglar',
    'Nezsa',
    'Pilisszanto',
    'Vasvar',
    'Dunabogdany',
    'Retsag',
    'Pilis',
    'Tarpa',
    'Mariapocs',
    'Tetetlen',
    'Petohaza',
    'Tiszavasvari',
    'Komlod',
    'Abda',
    'Sumeg',
    'Siklos',
    'Berkesd',
    'Bodajk',
    'Nemesvita',
    'Ujszentmargita',
    'Kotaj',
    'Hajduhadhaz',
    'Magyarhertelend',
    'Maglod',
    'Gelse',
    'Gorbehaza',
    'Kincsesbanya',
    'Rakoczifalva',
    'Abaujszanto',
    'Turkeve',
    'Martfu',
    'Harta',
    'Nyiradony',
    'Badacsonytordemic',
    'Fegyvernek',
    'Kisdorog',
    'Szentes',
    'Magyarnandor',
    'Vaja',
    'Zebegeny',
    'Szokolya',
    'Almaskamaras',
    'Kevermes',
    'Mindszent',
    'Deszk',
    'Ujlengyel',
    'Dabas',
    'Monor',
    'Ecser',
    'Peteri',
    'Csakvar',
    'Vecses',
    'Soltszentimre',
    'Pilisszentivan',
    'Tiszacsege',
    'Barand',
    'Mezokovesd',
    'Csemo',
    'Balassagyarmat',
    'Sajooeroes',
    'Heves',
    'Tapiogyorgye',
    'Bacsalmas',
    'Patvarc',
    'Polgar',
    'Ofeherto',
    'Janoshalma',
    'Toszeg',
    'Zalaszentgrot',
    'Szentlorinc',
    'Barcs',
    'Gyulahaza',
    'Levelek',
    'Hévíz',
    'Kiskoros',
    'Kesznyeten',
    'Rackeresztur',
    'Bekasmegyer',
    'Felsopakony',
    'Zagyvaszanto',
    'Demjen',
    'Pecel',
    'Szendehely',
    'Tordas',
    'Ostoros',
    'Egyhazasdengeleg',
    'Vertesszolos',
    'Felsozsolca',
    'Alsotold',
    'Jaszfelsoszentgyorgy',
    'Csoeroeg',
    'Veroce',
    'Jaszapati',
    'Szarvas',
    'Csabacsud',
    'Bekesszentandras',
    'Totszerdahely',
    'Becsehely',
    'Cered',
    'Pazmand',
    'Besnyo',
    'Sukoro',
    'Nemesvid',
    'Doroghaza',
    'Tabajd',
    'Letenye',
    'Szepetnek',
    'Valkonya',
    'Ujudvar',
    'Kistapolca',
    'Nagykeresztur',
    'Halasztelek',
    'Makkoshotyka',
    'Jaszbereny',
    'Szigethalom',
    'Janoshida',
    'Bocs',
    'Jaszfenyszaru',
    'Nyekladhaza',
    'Ujszasz',
    'Gyongyostarjan',
    'Nagytarcsa',
    'Emod',
    'Ecs',
    'Szuhakallo',
    'Kurityan',
    'Kophaza',
    'Hegyfalu',
    'Nagycenk',
    'Tarcal',
    'Taksony',
    'Vertestolna',
    'Labatlan',
    'Dunavarsany',
    'Majoshaza',
    'Domsod',
    'Balatonkenese',
    'Sátoraljaújhely',
    'Revfueloep',
    'Vertessomlo',
    'Bazsi',
    'Szeremle',
    'Hercegkut',
    'Koka',
    'Nagykata',
    'Alsonemedi',
    'Inarcs',
    'Ocsa',
    'Szentmartonkata',
    'Monorierdo',
    'Uri',
    'Benye',
    'Tapioszecso',
    'Kerecsend',
    'Vizslas',
    'Koszarhegy',
    'Apc',
    'Tiszaadony',
    'Sirok',
    'Vajszlo',
    'Taborfalva',
    'Bugyi',
    'Pusztavacs',
    'Atkar',
    'Nagykozar',
    'Nyergesujfalu',
    'Se',
    'Paszto',
    'Ibrany',
    'Felsotarkany',
    'Egerszolat',
    'Markaz',
    'Hajmasker',
    'Szentkiralyszabadja',
    'Herend',
    'Kallo',
    'Hered',
    'Hehalom',
    'Csomad',
    'Tapiobicske',
    'Tapiosag',
    'Part',
    'Domoszlo',
    'Karancssag',
    'Celldomolk',
    'Mezofalva',
    'Zirc',
    'Aszar',
    'Nagyigmand',
    'Acs',
    'Babolna',
    'Rackeve',
    'Kerekharaszt',
    'Nagyvazsony',
    'Jaszkiser',
    'Kemence',
    'Devavanya',
    'Ketegyhaza',
    'Valko',
    'Morahalom',
    'Fuzesgyarmat',
    'Dany',
    'Karancslapujto',
    'Karancskeszi',
    'Martely',
    'Neszmely',
    'Cirak',
    'Senyo',
    'Sarosd',
    'Csanytelek',
    'Terem',
    'Rad',
    'Szar',
    'Nagybarca',
    'Csongrad',
    'Melykut',
    'Tass',
    'Dunavecse',
    'Nagymagocs',
    'Pusztaszemes',
    'Tomorkeny',
    'Kunbaja',
    'Szegvar',
    'Kunszentmiklos',
    'Apostag',
    'Mezobereny',
    'Hernad',
    'Varbalog',
    'Balmazujvaros',
    'Gonyu',
    'Ocseny',
    'Bogyiszlo',
    'Mecseknadasd',
    'Attala',
    'Dombovar',
    'Vardomb',
    'Varalja',
    'Iregszemcse',
    'Dunaszentgyorgy',
    'Decs',
    'Racalmas',
    'Ciko',
    'Nagyszokoly',
    'Tamasi',
    'Izmeny',
    'Alap',
    'Bikal',
    'Hidas',
    'Sarisap',
    'Tat',
    'Banhorvati',
    'Herceghalom',
    'Vadna',
    'Varbo',
    'Zichyujfalu',
    'Szogliget',
    'Dedestapolcsany',
    'Many',
    'Bodaszolo',
    'Kislang',
    'Albertirsa',
    'Kulsobarand',
    'Zamoly',
    'Szod',
    'Szecsenyfelfalu',
    'Baracs',
    'Tiszavalk',
    'Kispest',
    'Naszaly',
    'Rudabanya',
    'Borsodszirak',
    'Golle',
    'Vasarosdombo',
    'Mernye',
    'Tokodaltaro',
    'Szabolcs',
    'Derecske',
    'Buzsak',
    'Gerjen',
    'Palotabozsok',
    'Dobrokoz',
    'Mezonyarad',
    'Szentistvan',
    'Dunaszeg',
    'Solt',
    'Szabadszallas',
    'Kecel',
    'Hajdudorog',
    'Dombrad',
    'Szabolcsveresmart',
    'Mandok',
    'Patroha',
    'Gemzse',
    'Ujkenez',
    'Devecser',
    'Dudar',
    'Bakonybel',
    'Kunsziget',
    'Kemecse',
    'Kunagota',
    'Kismanyok',
    'Balatonfokajar',
    'Sarpentele',
    'Jaszladany',
    'Tar',
    'Matraszolos',
    'Vacratot',
    'Vacduka',
    'Ocsod',
    'Adacs',
    'Belapatfalva',
    'Hevesaranyos',
    'Kotelek',
    'Cserepfalu',
    'Acsa',
    'Kisnemedi',
    'Makad',
    'Rimoc',
    'Berekfurdo',
    'Kajaszo',
    'Nagysap',
    'Gyomaendrod',
    'Szigetcsep',
    'Csopak',
    'Petfuerdo',
    'Szigetszentmarton',
    'Kiskunlachaza',
    'Petofibanya',
    'Sarkad',
    'Orbottyan',
    'Baracska',
    'Szeghalom',
    'Nagykapornak',
    'Sajopalfala',
    'Matraballa',
    'Bugac',
    'Tinnye',
    'Bagyogszovat',
    'Sopronkovesd',
    'Nagykovacsi',
    'Rajka',
    'Perbal',
    'Boldva',
    'Torony',
    'Hegyhatszentmarton',
    'Tiszapalkonya',
    'Cegledbercel',
    'Hort',
    'Nogradsap',
    'Dad',
    'Donat',
    'Center',
    'Kapolnasnyek',
    'Kolontar',
    'Bodrogkisfalud',
    'Balaton',
    'Kompolt',
    'Lazi',
    'Halimba',
    'Ujkigyos',
    'Pusztaszabolcs',
    'Mezohegyes',
    'Kisar',
    'Fehergyarmat',
    'Gavavencsello',
    'Tepe',
    'Harkany',
    'Perenye',
    'Dusnok',
    'Szabadszentkiraly',
    'Tiszabo',
    'Csajag',
    'Buk',
    'Madocsa',
    'Sarszentagota',
    'Sarpilis',
    'Kistormas',
    'Gyorkony',
    'Sarszentlorinc',
    'Uraiujfalu',
    'Szomor',
    'Gyorzamoly',
    'Ebes',
    'Kaloz',
    'Vezseny',
    'Mosonszolnok',
    'Csolyospalos',
    'Zsombo',
    'Misefa',
    'Telekes',
    'Gasztony',
    'Suelysap'
  ],
  Georgia: [
    'Tbilisi',
    'Sukhumi',
    "Ts'khinvali",
    "Shek'vetili",
    'Kutaisi',
    'Zemo-Avchala',
    'Qazbegi',
    'Gogolesubani',
    'Lentekhi',
    'Samtredia',
    'Zugdidi'
  ],
  Brazil: [
    'Sao Paulo',
    'Sorocaba',
    'Curitiba',
    'Salvador',
    'Recife',
    'Porto Alegre',
    'Campinas',
    'Rio de Janeiro',
    'Brasília',
    'Lagoa',
    'Tibau',
    'Campos dos Goytacazes',
    'Itaquaquecetuba',
    'Bocaiuva do Sul',
    'Petrópolis',
    'São José dos Campos',
    'Florianópolis',
    'Belo Horizonte',
    'Campina Grande',
    'João Pessoa',
    'Rio Branco',
    'Ariquemes',
    'Goiânia',
    'Dourados',
    'Terenos',
    'Ceilandia',
    'Caarapo',
    'Porto Nacional',
    'Anápolis',
    'Rio Verde de Mato Grosso',
    'Cacoal',
    'Ponta Pora',
    'Vilhena',
    'Várzea Grande',
    'Cuiabá',
    'Campo Grande',
    'Palmas',
    'Marialva',
    'Fenix',
    'Araucária',
    'Palmas',
    'Ponta Grossa',
    'Sao Francisco do Sul',
    'Aguas de Chapeco',
    'Ararangua',
    'Timbo',
    'Itapema',
    'Rio Negrinho',
    'Forquilhinha',
    'Icara',
    'Joinville',
    'Brusque',
    'Blumenau',
    'Sombrio',
    'Imbituba',
    'Orleans',
    'Palhoca',
    'Cascavel',
    'Colombo',
    'Rio Branco do Sul',
    'Borrazopolis',
    'São José dos Pinhais',
    'Rio Brilhante',
    'Juruena',
    'Posto Fiscal Rolim de Moura',
    'Senador Canedo',
    'Mutum',
    'Pelotas',
    'Ibiaca',
    'Caxias do Sul',
    'Rio Grande',
    'Eldorado',
    'Ijui',
    'Santiago',
    'Jatai',
    'Sobradinho',
    'Porto Velho',
    'Jaraguá do Sul',
    'Araquari',
    'Rio do Sul',
    'Ibirama',
    'Indaial',
    'Tubarao',
    'Criciúma',
    'Novo Hamburgo',
    'Dom Feliciano',
    'Rosario do Sul',
    'Farroupilha',
    'Canela',
    'Capao do Leao',
    'Guaíba',
    'Campo Bom',
    'São Leopoldo',
    'Viamão',
    'Panambi',
    'Sao Sebastiao do Cai',
    'Virmond',
    'Chopinzinho',
    'Cambe',
    'Sarandi',
    'Imbituva',
    'Guaramirim',
    'Camboriu',
    'Mafra',
    'Apiuna',
    'Santo',
    'Xaxim',
    'Nova Palma',
    'Sao Jose',
    'Goias',
    'Rondonópolis',
    'Acorizal',
    'Três Lagoas',
    'Guara',
    'Santa Rosa',
    'Porteira do Pinhal',
    'Canoas',
    'Dom Pedrito',
    'Dois Irmaos',
    'Taquara',
    'Boa Vista do Burica',
    'Uniao da Vitoria',
    'Arapongas',
    'Luziania',
    'Miranda',
    'Horizontina',
    'Alvorada',
    'Butia',
    'Taquari',
    'Lima Campos',
    'Sapucaia',
    'Cachoeira do Sul',
    'Flores da Cunha',
    'Foz do Iguaçu',
    'Paranaguá',
    'Castro',
    'Lapa',
    'Maringá',
    'Campo Largo',
    'Guaratuba',
    'Novo Recreio',
    'Caceres',
    'Garopaba',
    'Gaspar',
    'São Bento do Sul',
    'Andradina',
    'Santa Maria',
    'Nova Prata',
    'Vera Cruz',
    'Paverama',
    'Cachoeirinha',
    'Tramandai',
    'Mato Grosso',
    'Miracema do Tocantins',
    'Realeza',
    'Guarapuava',
    'Inhumas',
    'Bela Vista',
    'Quarai',
    'Candelaria',
    'Gravataí',
    'Lambari',
    'Morro Agudo',
    'Sao Miguel',
    'Urussanga',
    'Itauna',
    'Marabá',
    'Sao Sepe',
    'Bandeirantes',
    'Venancio Aires',
    'Porto Ferreira',
    'Jaci',
    'São Paulo',
    'Embu',
    'Caico',
    'Jucurutu',
    'Santana do Matos',
    'Amparo',
    'Limeira',
    'Camacari',
    'Ribeirão Preto',
    'Fernandopolis',
    'Barrinha',
    'Igarapava',
    'Catanduva',
    'Americo Brasiliense',
    'Mirassol',
    'Jaborandi',
    'Monte Aprazivel',
    'Araraquara',
    'Jaguariuna',
    'Rio Claro',
    'Ipero',
    'Leme',
    'Piracicaba',
    'Cosmopolis',
    'Hortolândia',
    'Itapira',
    'Louveira',
    'Porto Feliz',
    'Capivari',
    'Americana',
    'Iracemapolis',
    'Cerquilho',
    'Serra Negra',
    'Valinhos',
    'Mogi Mirim',
    'Candido Mota',
    'Presidente Venceslau',
    'Presidente Prudente',
    'Poa',
    'Guarulhos',
    'Caieiras',
    'Varzea Paulista',
    'Francisco Morato',
    'Jundiaí',
    'Cabreuva',
    'Franco da Rocha',
    'São Bernardo do Campo',
    'Sao Vicente',
    'Itanhaem',
    'Carapicuiba',
    'Santo Antonio do Pinhal',
    'Cachoeira Paulista',
    'Guaratingueta',
    'Lorena',
    'Tremembe',
    'Canas',
    'Jacareí',
    'Jacupiranga',
    'Santos',
    'Santo André',
    'Diadema',
    'Itapecerica da Serra',
    'Barueri',
    'Cotia',
    'Santana de Parnaiba',
    'Osasco',
    'Jandira',
    'Itu',
    'Taboao da Serra',
    'Itapevi',
    'Embu Guacu',
    'Varginha',
    'Medianeira',
    'Sao Miguel do Iguacu',
    'Extrema',
    'Sao Goncalo',
    'Palmeira',
    'Sao Luiz Gonzaga',
    'Barro',
    'Sao Marcos',
    'Nova Bassano',
    'Vacaria',
    'Sete Lagoas',
    'Estancia',
    'Goncalves',
    'Paraisopolis',
    'Montes Claros',
    'Sao Joao de Meriti',
    'Xanxere',
    'Caxambu',
    'Volta Redonda',
    'Itatiaia',
    'Duque de Caxias',
    'Seropedica',
    'Japeri',
    'Penha',
    'Itajaí',
    'Vinhedo',
    'Rialma',
    'Ceres',
    'Teresina',
    'Piracanjuba',
    'Ipameri',
    'Roca Sales',
    'Passos',
    'Garibaldi',
    'Alegrete',
    'Marataizes',
    'Nova Iguaçu',
    'Cachoeiro de Itapemirim',
    'Santa Rita do Passa Quatro',
    'São Lourenço',
    'Lagarto',
    'Salgado',
    'Poco Verde',
    'Erechim',
    'Delfinopolis',
    'Feliz',
    'Maua',
    'Domingos Martins',
    'Jaiba',
    'Niterói',
    'Natal',
    'Itaporanga',
    'Abaetetuba',
    'Ananindeua',
    'Macapá',
    'Santa Isabel do Para',
    'Ipira',
    'Paulo Jacinto',
    'Maceió',
    'Arapiraca',
    'Rio Largo',
    'Balsas',
    'Araguaína',
    'Prata',
    'Londrina',
    'Rolandia',
    'Paranavai',
    'Pinhais',
    'Pinhoes',
    'Quatro Barras',
    'Freitas',
    'Olinda',
    'Paulista',
    'Cariacica',
    'Vitória',
    'Vila Velha',
    'Jaboatao dos Guararapes',
    'Igarassu',
    'Serra',
    'Tijuca',
    'Taguatinga',
    'Taguatinga',
    'Trindade',
    'Xavantina',
    'Paranoa',
    'Esteio',
    'Passo Fundo',
    'Estancia Velha',
    'Nossa Senhora Das Gracas',
    'Camaqua',
    'Suzano',
    'Juiz de Fora',
    'Contagem',
    'Ibirite',
    'Marica',
    'Cocal',
    'Caucaia',
    'Maracanau',
    'Bento Gonçalves',
    'Brumadinho',
    'Itatinga',
    'Anhembi',
    'Itapeva',
    'Aracaju',
    'Nossa Senhora',
    'Sao Domingos',
    'Itabaiana',
    'Serafina',
    'Parai',
    'Marau',
    'Guapore',
    'Simoes',
    'Uchoa',
    'Barretos',
    'Granja',
    'Marco',
    'Acarau',
    'Paraipaba',
    'Veranopolis',
    'Sao Sebastiao da Amoreira',
    'Mangueirinha',
    'Itapua',
    'Navegantes',
    'Alto Piquiri',
    'Matinhos',
    'Cornelio Procopio',
    'Ipiranga',
    'Nova Brescia',
    'Encantado',
    'Quixeramobim',
    'Bagé',
    'Tambau',
    'Ibiruba',
    'Itajuba',
    'Flexeiras',
    'Bom Conselho',
    'Canhotinho',
    'Calcado',
    'Garanhuns',
    'Corguinho',
    'Sao Gabriel',
    'Governador Valadares',
    'Itabirinha de Mantena',
    'Itanhomi',
    'Lavras da Mangabeira',
    'Monteiro',
    'Mairinque',
    'Doutor Mauricio Cardoso',
    'Ipatinga',
    'Nazare',
    'Jaguaripe',
    'Muniz Ferreira',
    'Matozinhos',
    'Grao Mogol',
    'Sao Domingos',
    'Sao Gotardo',
    'Santo Estevao',
    'Madre de Deus',
    'Carpina',
    'Barra do Garças',
    'Rio Pardo',
    'Minas',
    'Tucum',
    'Braganca Paulista',
    'Bauru',
    'Manaus',
    'Sao Caetano do Sul',
    'Praia Grande',
    'Mogi das Cruzes',
    'Lajeado',
    'Itapetininga',
    'Guaruja',
    'Franca',
    'Sacramento',
    'São Carlos',
    'Marília',
    'Aracatuba',
    'Indaiatuba',
    'Cubatao',
    'Atibaia',
    'Lagoa dos Gatos',
    'Amaraji',
    'Gloria do Goita',
    'Sao Joaquim do Monte',
    'Palmares',
    'Cortes',
    'Escada',
    'Moreno',
    'Santa Cruz do Capibaribe',
    'Pesqueira',
    'Diamantina',
    'Serro',
    'Tres Marias',
    'Santa Fe',
    'Sumare',
    'Votorantim',
    'Tatuí',
    'Rio Pardo de Minas',
    'Conceicao do Araguaia',
    'Monte Belo',
    'Sao Jose da Barra',
    'Cassia',
    'Pouso Alegre',
    'Juruaia',
    'Campos Novos',
    'Dores do Indaia',
    'Bambui',
    'Cataguases',
    'Duas Barras',
    'Mar de Espanha',
    'Alto Santo',
    'Morada Nova',
    'Mossoro',
    'Gomes',
    'Acopiara',
    'Iguatu',
    'Piquet Carneiro',
    'Limoeiro do Norte',
    'Russas',
    'Pau dos Ferros',
    'Coronel',
    'Norte',
    'Juazeiro do Norte',
    'Eloi Mendes',
    'Lavrinhas',
    'Parana',
    'Banabuiu',
    'Lagoa da Prata',
    'Boa Esperanca',
    'Vicosa',
    'Muriaé',
    'Charqueadas',
    'Sao Jeronimo',
    'Arroio dos Ratos',
    'Colinas',
    'Assis Chateaubriand',
    'Guanambi',
    'Bom Jesus da Lapa',
    'Vitória da Conquista',
    'Matina',
    'Brejo Santo',
    'Tres Pontas',
    'Muqui',
    'Vargem Alta',
    'Nossa Senhora do Socorro',
    'Torres',
    'Tres Cachoeiras',
    'Valentim Gentil',
    'Sao Sebastiao do Paraiso',
    'Pimenta',
    'Vista Alegre do Alto',
    'Taquaral',
    'Ferraz de Vasconcelos',
    'Igrejinha',
    'Formiga',
    'Palotina',
    'Itajuipe',
    'Iacu',
    'Saquarema',
    'Araruama',
    'Sao Pedro da Aldeia',
    'Sao Vicente de Paula',
    'Sao Tome',
    'Pitangueiras',
    'Francisco Beltrao',
    'Linhares',
    'Itaipu',
    'Colatina',
    'Camaragibe',
    'Guararapes',
    'Itamaraca',
    'Campos',
    'Belford Roxo',
    'Brasilandia',
    'Almirante Tamandare',
    'Esmeraldas',
    'Betim',
    'Salvador',
    'Cabo de Santo Agostinho',
    'Jaguaruna',
    'Aracuai',
    'Itabira',
    'Jacarepagua',
    'Conde',
    'Brasopolis',
    'Lavras',
    'Unai',
    'Brumado',
    'Caetite',
    'Itamarati',
    'Rio Bonito',
    'Tabuleiro do Norte',
    'Cardeal da Silva',
    "Itaporanga d'Ajuda",
    'Belem',
    'Monte Azul',
    'Jerico',
    'Carnaiba',
    'Afogados da Ingazeira',
    'São Luís',
    'Botucatu',
    'Itaguai',
    'Rio Bonito',
    'Ouro Fino',
    'Stio Ouro Fino',
    'Borda da Mata',
    'Ponte Nova',
    'Oratorios',
    'Urucania',
    'Sem Peixe',
    'Dom Silverio',
    'Santo Antonio do Grama',
    'Salto',
    'Agulha',
    'Araras',
    'Sao Jose do Ouro',
    'Campo Mourao',
    'Itirapina',
    'Itanhandu',
    'Ibaiti',
    'Japira',
    'Bom Jesus',
    'Itumbiara',
    'Ararica',
    'Miraima',
    'Cordeiropolis',
    'Estrela',
    'Mogi-Gaucu',
    'Estreito',
    'Petrolina',
    'Laranjeiras',
    'Sao Cristovao',
    'Antonio Carlos',
    'Biguacu',
    'Tijucas',
    'Araguari',
    'Nova Venecia',
    'Mantena',
    'Valerio',
    'Marilandia',
    'Boa Esperanca',
    'Nova Friburgo',
    'Pedranopolis',
    'Belém',
    'Carolina',
    'Sao Geraldo do Araguaia',
    'Nova Esperanca',
    'Presidente Medici',
    'Piraju',
    'Ourinhos',
    'Jacarezinho',
    'Altamira',
    'Itaperuna',
    'Teresopolis',
    'Guapimirim',
    'Parapeuna',
    'Santo Antonio',
    'Aracati',
    'Icapui',
    'Sao Bernardo',
    'Crateus',
    'Ribeirão das Neves',
    'Joao Monlevade',
    'Bom Jesus dos Perdoes',
    'Vilar dos Teles',
    'Sao Domingos',
    'Vargem Grande Paulista',
    'Tiete',
    'Monte Mor',
    'Parnamirim',
    'Sao Goncalo do Amarante',
    'Mesquita',
    'Aparecida',
    'Manacapuru',
    'Alagoinhas',
    'Santa Rita do Sapucai',
    'Sape',
    'Sao Fidelis',
    'Oeiras',
    'Picos',
    'Novo Oriente',
    'Simplicio Mendes',
    'Santo Antonio de Lisboa',
    'Betania',
    'Morro da Fumaca',
    'Aguas Lindas',
    'Uberlandia',
    'Aracas',
    'Sao Jose do Rio Preto',
    'Guajeru',
    'Tanque Novo',
    'Bom Jardim de Minas',
    'Liberdade',
    'Mococa',
    'Bela Vista de Goias',
    'Santa',
    'Niquelandia',
    'Itapaci',
    'Santo Antonio de Padua',
    'Formosa',
    'Maraial',
    'Girau do Ponciano',
    'Pedreira',
    'Sao Pedro do Sul',
    'Serra',
    'Barra do Bugres',
    'Votuporanga',
    'Biritiba Mirim',
    'Cajamar',
    'Campos do Jordao',
    'Iguape',
    'Peruibe',
    'Taubate',
    'Pindamonhangaba',
    'Olimpia',
    'Sertaozinho',
    'Sao Sebastiao da Grama',
    'Serrana',
    'Dumont',
    'Novo Horizonte',
    'Aracoiaba da Serra',
    'Paulinia',
    'Pirapozinho',
    'Boituva',
    'Santa Luzia',
    'Angra dos Reis',
    'Nova Odessa',
    'Pontal',
    'Morrinhos',
    'Palmeira das Missoes',
    'Sarandi',
    'Carazinho',
    'Paraty',
    'Umbauba',
    'Tobias Barreto',
    'Ivaipora',
    'Prudentopolis',
    'Pirassununga',
    'Santa Maria',
    'Itaguacu',
    'Santa Teresa',
    'Barra Mansa',
    'Quatis',
    "Aparecida d'Oeste",
    'Cristalina',
    'Sao Gabriel',
    'Vera Cruz',
    'Sao Paulo do Potengi',
    'Palmares do Sul',
    'Guarei',
    'Queimados',
    'Itabaianinha',
    'Tomar do Geru',
    'Tabira',
    'Teixeira',
    'Osorio',
    'Apodi',
    'Rolante',
    'Bonito',
    'Itubera',
    'Conceicao',
    'Seabra',
    'Navirai',
    'Fatima',
    'Maxaranguape',
    'Touros',
    'Pomerode',
    'Sitio Figueira',
    'Santo Antonio do Amparo',
    'Sertania',
    'Terra Rica',
    "Palmeira d'Oeste",
    'Sousa',
    'Pombal',
    'Miguel Pereira',
    'Patos',
    'Santa Luzia',
    'Porto Uniao',
    'Vicencia',
    'Macaparana',
    'Umbuzeiro',
    'Orobo',
    'Maravilha',
    'Abelardo Luz',
    'Quilombo',
    "Sao Miguel d'Oeste",
    'Cordilheira',
    'Itamonte',
    'Três Corações',
    'Sao Goncalo do Sapucai',
    'Alfenas',
    'Cambuquira',
    'Santa Cruz',
    'Ribeirao Pires',
    'Campo',
    'Mairiporã',
    'Tatuamunha',
    'Jaú',
    'Marcelino Ramos',
    'Campinas do Sul',
    'Capao da Canoa',
    'Piracaia',
    'Goianesia',
    'Sales',
    'Potirendaba',
    'Toritama',
    'Caruaru',
    'Sanharo',
    'Propria',
    'Presidente Kennedy',
    'Brejetuba',
    'Cachoeiras',
    'Lencois',
    'Itapecerica',
    'Mage',
    'Cosmorama',
    'Alagoa Grande',
    'Arroio Grande',
    'Aragarcas',
    'Caiaponia',
    'Patrocinio',
    'Santana do Paraiso',
    'Coronel Fabriciano',
    'Rio das Ostras',
    'Santo Antonio de Jesus',
    'America Dourada',
    'Cafarnaum',
    'Ceara Mirim',
    'Joao Camara',
    'Turmalina',
    'Curitibanos',
    'Santa Isabel do Ivai',
    'Querencia do Norte',
    'Simao Dias',
    'Santo Antonio do Leverger',
    'Primavera',
    'Jaguarao',
    'Jequitinhonha',
    'Toledo',
    'Biritinga',
    'Terra Boa',
    'Rondon',
    'Doutor Camargo',
    'Santo Antonio do Caiua',
    'Jacutinga',
    'Socorro',
    'Vespasiano',
    'Lontra',
    'Curvelo',
    'Morro de Sao Paulo',
    'Restinga Seca',
    'Ipora',
    'Jacarau',
    'Cacimba de Dentro',
    'Parelhas',
    'Bom Despacho',
    'Balneário Camboriú',
    'Joaquim Tavora',
    'Bonito',
    'Quipapa',
    'Quirinopolis',
    'Santo Antonio da Patrulha',
    'Altinopolis',
    'Matao',
    'Santo Amaro',
    "Olho d'Agua",
    'Sabara',
    'Sao Joaquim de Bicas',
    'Sao Jose do Rio Pardo',
    'Icem',
    'Pongai',
    'Sao Lourenco da Mata',
    'Lages',
    'Concordia',
    'Porto Real',
    'Itaborai',
    'Campina Grande do Sul',
    'Fazenda Rio Grande',
    'Samambaia',
    'Cidade',
    'Aguas Claras',
    'Guarapari',
    'Sao Roque',
    'Barreiras',
    'Feira de Santana',
    'Maragogipe',
    'Catu',
    'Sapeacu',
    'Candeal',
    'Sao Goncalo dos Campos',
    'Cansancao',
    'Timbe',
    'Nova Lima',
    'Iuna',
    'Abreu e Lima',
    'Macaé',
    'Timbauba',
    'Goiana',
    'Pancas',
    'Ituporanga',
    'Ascurra',
    'Sao Joaquim',
    'Capanema',
    'Gravatá',
    'Milagres',
    'Poco das Antas',
    'Cruzeiro do Sul',
    'Soledade',
    'Tuiuti',
    'Itatiba',
    'Cruzeiro',
    'Sao Sebastiao',
    'Sao Lourenco da Serra',
    'Charqueada',
    'Santa Gertrudes',
    'Ipeuna',
    'Rio das Pedras',
    'Agudos',
    'Assis',
    'Dracena',
    'Brotas',
    'Promissao',
    'Gaviao Peixoto',
    'Pitangueiras',
    'Monte Alto',
    'Pradopolis',
    'Vera Cruz',
    'Pirajui',
    'Pacaembu',
    'Macatuba',
    'Botuvera',
    'Ouro Branco',
    'Caete',
    'Leopoldina',
    'Apucarana',
    'Tres Rios',
    'Tucurui',
    'Cerro Branco',
    'Cameta',
    'Baiao',
    'Tome Acu',
    'Heliodora',
    'Cruzeiro do Sul',
    'Irati',
    'Currais Novos',
    'Caxias',
    'Joao Pinheiro',
    'Brasil',
    'Lagoa Santa',
    'Orlandia',
    'Ribeirao Corrente',
    'Ipua',
    'Guara',
    'Nuporanga',
    'Sao Joaquim da Barra',
    'Jardinopolis',
    'Brodosqui',
    'Guaira',
    'Sales Oliveira',
    'Ituverava',
    'Batatais',
    'Miguelopolis',
    'Joaquim',
    'Tupaciguara',
    'Patos de Minas',
    'Ituiutaba',
    'Capinopolis',
    'Sao Joao da Boa Vista',
    'Santo Antonio do Jardim',
    'Catalao',
    'Camanducaia',
    'Florania',
    'Sao Vicente',
    'Marechal Candido Rondon',
    'Quatro Pontes',
    'Barao de Cocais',
    'Conceicao do Mato Dentro',
    'Guapo',
    'Bom Jardim',
    'Guararema',
    'Pedro Leopoldo',
    'Mariana',
    'Igarape',
    'Ipojuca',
    'Humaitá',
    'Jaguaribe',
    'Ico',
    'Maria da Fe',
    'Laguna',
    'Piraquara',
    'Anhumas',
    'Bocaiuva',
    'Mandaguacu',
    'Catende',
    'Araripina',
    'Jaguariaiva',
    'Mariluz',
    'Ji Parana',
    'Bom Retiro do Sul',
    'Imperatriz',
    'Rio do Pires',
    'Macaubas',
    'Botupora',
    'Afonso Claudio',
    'Pontalina',
    'Arealva',
    'Ibitinga',
    'Aloandia',
    'Pien',
    'Benedito Novo',
    'Descanso',
    'Itapiranga',
    'Braco do Norte',
    'Barbalha',
    'Getulio Vargas',
    'Apora',
    'Santa Luzia',
    'Sao Carlos',
    'Ponte Serrada',
    'Barbacena',
    'São João del Rei',
    'Santo Inacio',
    'Jaboticatubas',
    'Sao Francisco',
    'Sao Salvador',
    'Sao Joao Evangelista',
    'Maracaju',
    'Camamu',
    'Itacare',
    'Ipiau',
    'Itagiba',
    'Candeias',
    'Coremas',
    'Inhauma',
    'Aracai',
    'Barauna',
    'Tiangua',
    'Caninde de Sao Francisco',
    'Santa Cruz do Sul',
    'Cedral',
    'Sapiranga',
    'Canarana',
    'Agua Boa',
    'Querencia',
    'Cocalinho',
    'Carlos Barbosa',
    'Santarém',
    'Abidos',
    'Almeirim',
    'Martinopolis',
    'Presidente Bernardes',
    'Centenario do Sul',
    'Alvares Machado',
    'Poços de Caldas',
    'Candeias',
    'Porto Grande',
    'Anajas',
    'Joacaba',
    'Alegre',
    'Santo Aleixo',
    'Caçador',
    'Cianorte',
    'Corumba de Goias',
    'Nazare da Mata',
    'Niteroi',
    'Arujá',
    'Caratinga',
    'Salvador',
    'Cruz das Almas',
    'Laguna Carapa',
    'Glorinha',
    'Sapucaia do Sul',
    'Lagoa Salgada',
    'Ouricangas',
    'Itapicuru',
    'Caraguatatuba',
    'Conchal',
    "Santa Barbara d'Oeste",
    'Coelho',
    'Crato',
    'Granito',
    'Monte Santo',
    'Santa Cruz Cabralia',
    'Cabralia',
    'Aracruz',
    'Ubatuba',
    'Sao Roque',
    'Rio Grande da Serra',
    'Avare',
    'Pena',
    'Sao Pedro',
    'Pilar do Sul',
    'Jose Bonifacio',
    'Monte Azul Paulista',
    'Salto de Pirapora',
    'Artur Nogueira',
    'Joanopolis',
    'Iacanga',
    'Paraguacu Paulista',
    'Areia Branca',
    'Boa Vista',
    'Picui',
    'Machadinho',
    'Birigui',
    'Campo Verde',
    'Coroados',
    'Buritama',
    'Jaru',
    'Sao Joao',
    'Videira',
    'Heliopolis',
    'Cicero Dantas',
    'Conceicao de Macabu',
    'Macuco',
    'Alta Floresta',
    'Progresso',
    'Bento',
    'Pato Branco',
    'Umuarama',
    'Convento da Penha',
    'Aquidaba',
    'Recanto',
    'Piedade',
    'Cabo Frio',
    'Brejo da Madre de Deus',
    'Vassouras',
    'Rosario',
    'Itapecuru Mirim',
    'Luis Correia',
    'Vicosa',
    'Campo Alegre',
    'Boa Vista',
    'Boa Vista',
    'Conselheiro Lafaiete',
    'Sarzedo',
    'Claudio',
    'Barra do Piraí',
    'Paracambi',
    'Salinas da Margarida',
    'Ouro Preto',
    'Tabatinga',
    'Pirai',
    'Cristiano Otoni',
    'Jaguaruana',
    'Juru',
    'Sao Jose do Egito',
    'Virgem da Lapa',
    'Chapada',
    'Cristais',
    'Francisco Sa',
    'Varzelandia',
    'Janauba',
    'Sao Sebastiao',
    'Penapolis',
    'Barbosa',
    'Itapolis',
    'Pederneiras',
    'Registro',
    'Mongagua',
    'Salesopolis',
    'Lencois Paulista',
    'Bertioga',
    'Abadiania',
    'Terra Nova',
    'Repartimento',
    'Araxá',
    'Uberaba',
    'Divinopolis',
    'Meier',
    'Maracana',
    'Sao Jose',
    'Cajuru',
    'Arceburgo',
    'Ipu',
    'Sao Caetano',
    'Bezerros',
    'Aquidauana',
    'São Miguel das Missões',
    'Gramado',
    'Rio Branco',
    'Corumbá',
    'Ivoti',
    'Montenegro',
    'Sao Lourenco do Sul',
    'Campina',
    'Bela Vista do Paraiso',
    'Montividiu',
    'Jaciara',
    'Araruna',
    'Cangucu',
    'Paraiso',
    'Itau de Minas',
    'Nobres',
    'Branco',
    'Monte Alegre',
    'Elias Fausto',
    'Bom Jesus do Itabapoana',
    'Riacho das Almas',
    'Parobe',
    'Nova Cruz',
    'Venda Nova',
    'Machado',
    'Ouvidor',
    'Caldas Novas',
    'Guaraciaba',
    'Maruim',
    'Siqueira Campos',
    'Jaboticabal',
    'Taquaritinga',
    'Jarinu',
    'General Salgado',
    'Bebedouro',
    'Guaicara',
    'Vargem Grande do Sul',
    'Lins',
    'Condado',
    'Itapetinga',
    'Alterosa',
    'Julio de Castilhos',
    'Mateus Leme',
    'Reduto',
    'Teixeira',
    'Arroio do Silva',
    'Cacapava',
    'Cravinhos',
    'Congonhas',
    'Itaitinga',
    'Varzea',
    'Garimpo Novo',
    'Espinosa',
    'Macedonia',
    'Sao Jose do Goiabal',
    'Lagoa do Carro',
    'Sao Miguel dos Campos',
    'Nilopolis',
    'Amazonas',
    'Fortaleza',
    'Vicente',
    'Tamandare',
    'Raposos',
    'Pacatuba',
    'Eusebio',
    'Riachao do Jacuipe',
    'Santa Rita',
    'Timoteo',
    'Maranguape',
    'Queimadas',
    'Frei Paulo',
    'Altinho',
    'Amarante',
    'Lucena',
    'Goianira',
    'Vitoria de Santo Antao',
    'Novo Mundo',
    'Arapua',
    'Itambe',
    'Caapora',
    'Alem Paraiba',
    'Para de Minas',
    'Serra',
    'Viana',
    'Caldas',
    'Guaraci',
    'Itapixuna',
    'Jaguaquara',
    'Tocantins',
    'Piedade dos Gerais',
    'Quixada',
    'Tupanatinga',
    'Alto',
    'Calcoene',
    'Sao Gabriel',
    'Cantanhede',
    'Itapipoca',
    'Sobral',
    'Abrantes',
    'Caninde',
    'Andarai',
    'Uruguaiana',
    'Botafogo',
    'Resende',
    'Italva',
    'Niteroi',
    'Dois Corregos',
    'Limoeiro',
    'Castanhal',
    'Uba',
    'Itabuna',
    'Bombinhas',
    'Ribeirao do Pinhal',
    'Jardim Alegre',
    'Sao Jose do Cedro',
    'Forquilha',
    'Nova Canaa',
    'Sao Pedro dos Ferros',
    'Caputira',
    'Sao Jose de Mipibu',
    'Goianinha',
    'Mutum',
    'Jeronimo Monteiro',
    'Cajazeiras',
    'Espumoso',
    'Planalto',
    'Caparao',
    'Manhumirim',
    'Espera Feliz',
    'Jetiba',
    'Sao Jose do Calcado',
    'Tupa',
    'Mineiros do Tiete',
    'Carmo do Rio Claro',
    'Serrania',
    'Rancharia',
    'Andradas',
    'Joao Lisboa',
    'Sitio Novo',
    'Caripi',
    'Graca Aranha',
    'Ibipeba',
    'Irece',
    'Morro do Chapeu',
    'Carapebus',
    'Casimiro de Abreu',
    'Ribeirao',
    'Valparaiso',
    'Tiradentes',
    'Valente',
    'Sao Francisco de Paula',
    'Nova Petropolis',
    'Guanhaes',
    'Inga',
    'Gardenia Azul',
    'Pontal do Parana',
    'Marques',
    'Neopolis',
    'Marechal Deodoro',
    'Messias',
    'Sao Jose da Laje',
    'Coruripe',
    'Anadia',
    'Capela',
    'Sertao',
    'Sananduva',
    'Guamare',
    'Pauliceia',
    'Junqueiropolis',
    'Panorama',
    'Mostardas',
    'Barra do Ribeiro',
    'Cerro Grande',
    'Itapuranga',
    'Santana da Vargem',
    'Campos Gerais',
    'Cha Grande',
    'Cuite',
    'Ribeirao Bonito',
    'Pedro Avelino',
    'Campo Novo',
    'Frederico Westphalen',
    'Seberi',
    'Paranaiba',
    'Nova Serrana',
    'Lebon Regis',
    'Aquiraz',
    'Cacequi',
    'Bom Jesus do Amparo',
    'Santa Barbara',
    'Santana',
    'Piuma',
    'Teófilo Otoni',
    'Silva Jardim',
    'Itabapoana',
    'Tangua',
    'Miracema',
    'Aperibe',
    'Itaocara',
    'Boa Vista',
    'Campo Grande',
    'Bras',
    'Sao Manuel',
    'Santos Dumont',
    'Sao Luis de Montes Belos',
    'Cesario Lange',
    'Jumirim',
    'Alambari',
    'Marzagao',
    'Parnaiba',
    'Catarina',
    'Machados',
    'Paracuru',
    'Tamboril',
    'Floriano',
    'Campo Maior',
    'Marcolandia',
    'Capistrano',
    'Baturite',
    'Visconde do Rio Branco',
    'Quixere',
    'Nova Alianca',
    'Guapiacu',
    'Balsamo',
    'Urupes',
    'Santa Fe do Sul',
    'Bady Bassitt',
    'Mendonca',
    'Ubarana',
    'Capelinha',
    'Berilo',
    'Arroio do Meio',
    'Cacapava do Sul',
    'Sao Joao Batista',
    'Jardim de Piranhas',
    'Acu',
    'Ipumirim',
    'Goianapolis',
    'Palmitos',
    'Campo Alegre',
    'Porto Seguro',
    'Pau Brasil',
    'Barrocas',
    'Serrinha',
    'Conceicao do Coite',
    'Araci',
    'Valenca',
    'Alvorada do Sul',
    'Itapagipe',
    'Palestina',
    'Narandiba',
    'Amarante do Maranhao',
    'Pratapolis',
    'Mamonas',
    'Volta Grande',
    'Pedra Bonita',
    'Raul Soares',
    'Mangaratiba',
    'Quissama',
    'Natercia',
    'Malacacheta',
    'Cruz Alta',
    'Tenorio',
    'Alagoa Nova',
    'Nossa Senhora da Gloria',
    'Ribeira do Pombal',
    'Gurupi',
    'Guaxupe',
    'Alegres',
    'Santo Anastacio',
    'Castelo',
    'Arcos',
    'Piabeta',
    'Aroeiras',
    'Laranjeiras do Sul',
    'Itapejara',
    'Cerqueira Cesar',
    'Arandu',
    'Cabedelo',
    'Sao Joao do Paraiso',
    'Bicas',
    'Lagoa da Canoa',
    'Pao de Acucar',
    'Paulo Afonso',
    'Junqueiro',
    'Penedo',
    'Nanuque',
    'Medeiros Neto',
    'Mucuri',
    'Fundao',
    'Redencao',
    'Itaete',
    'Boa Vista',
    'Lagoa Vermelha',
    'Arroio do Tigre',
    'Progresso',
    'Ibipora',
    'Silveiras',
    'Trindade',
    'Arraial do Cabo',
    'Sao Francisco',
    'Jose Boiteux',
    'Presidente Getulio',
    'Witmarsum',
    'Mirim Doce',
    'Sebastianopolis do Sul',
    'Mangabeira',
    'Juatuba',
    'Boa Vista',
    'Sampaio Correia',
    'Mendes',
    'Dias',
    'Pojuca',
    'Paraopeba',
    'Pote',
    'Rio Casca',
    'Moema',
    'Santa Luzia',
    'Vila Muriqui',
    'Pinheiral',
    'Ilhéus',
    'Vera Cruz',
    'Barra de Sao Miguel',
    'Chapadinha',
    'Santa Maria de Itabira',
    'Santana do Cariri',
    'Altaneira',
    'Exu',
    'Dix-sept Rosado',
    'Paragominas',
    'Urucuca',
    'Ibirataia',
    'Nilo Pecanha',
    'Porto',
    'Pedra Grande',
    'Fortaleza',
    'Claro dos Pocoes',
    'Irai',
    'Mandaguari',
    'Tapira',
    'Sao Domingos',
    'Ipaba',
    'Encruzilhada do Sul',
    'Itabirito',
    'Capetinga',
    'Itaberaba',
    'Pratania',
    'Piratininga',
    'Santa Ines',
    'Adustina',
    'Bernardo',
    'Tupancireta',
    'Sao Jose do Inhacora',
    'Mineiros',
    'Rancho Queimado',
    'Luzerna',
    "Erval d'Oeste",
    'Reboucas',
    'Massaranduba',
    'Correia Pinto',
    'Rio dos Cedros',
    'Tres Coroas',
    'Nucleo Bandeirante',
    'Sao Miguel do Araguaia',
    'Porangatu',
    'Seara',
    'Campo Ere',
    'Jacuizinho',
    'Iguaracu',
    'Vila Rica',
    'Pirapora do Bom Jesus',
    'Espirito Santo',
    'Espirito Santo do Pinhal',
    'Fartura',
    'Palmeira',
    'Barra Bonita',
    'Cabralia Paulista',
    'Salto Grande',
    'Tupi Paulista',
    'Santa Isabel',
    'Guaianases',
    'Terra Roxa',
    'Braganca',
    'Paraiba do Sul',
    'Morretes',
    'Mambore',
    'Paicandu',
    'Canoinhas',
    'Tres Barras',
    'Simao Pereira',
    'Teutonia',
    'Cachoeiras de Macacu',
    'Dois Vizinhos',
    'Gandu',
    'Ubata',
    'Valença',
    'Santa Helena',
    'Sao Jose de Piranhas',
    'Rio Negro',
    'Conceicao',
    'Sao Joao Nepomuceno',
    'Ponte de Itabapoana',
    'Itabaiana',
    'Juripiranga',
    'Mogeiro',
    'Taua',
    'Missao Velha',
    'Campos Sales',
    'Parana',
    'Pereiro',
    'Caraubas',
    'Iracema',
    'Carius',
    'Vicosa do Ceara',
    'Pimenta Bueno',
    'Diamantino',
    'Timon',
    'Sao Goncalo do Para',
    'São Mateus',
    'Aguas de Sao Pedro',
    'Palmital',
    'Mamanguape',
    'Baia da Traicao',
    'Sao Manoel',
    'Ilhabela',
    'Cajati',
    'Morungaba',
    'Bom Jesus',
    'Triunfo',
    'Miranda',
    'Sao Jose de Ribamar',
    'Nunes Freire',
    'Buriti',
    'Itaguatins',
    'Cururupu',
    'Raposa (1)',
    'Angicos',
    'Serra',
    'Macaiba',
    'Extremoz',
    'Japaratinga',
    'Barao de Grajau',
    'Angical do Piaui',
    'Esperantina',
    'Pedreira',
    'Mirinzal',
    'Vitorino Freire',
    'Cacique Doble',
    'Conchas',
    'Pinhalzinho',
    'Carinhanha',
    'Correntina',
    'Santa Maria da Vitoria',
    'Cocos',
    'Santa Leopoldina',
    'Guaira',
    'Portao',
    'Alto Paraiso de Goias',
    'Capao Alto',
    'Esperanca',
    'Pinhalao',
    'Urai',
    'Ladario',
    'Itaqui',
    'Jundiai do Sul',
    'General Carneiro',
    'Bairro da Velha Central',
    'Santana do Livramento',
    'Amambai',
    'Pirajuba',
    'Batista',
    'Tapirai',
    'Cassilandia',
    'Sao Lourenco dOeste',
    'Restinga',
    'Urania',
    'Cruz',
    'Sao Luis do Paraitinga',
    'Monteiro Lobato',
    'Bananal',
    'Santa Branca',
    'Paraibuna',
    'Juquitiba',
    'Mirandopolis',
    'Duartina',
    'Ibiuna',
    'Descalvado',
    'Contenda',
    'Varjota',
    'Apuiares',
    'Luminarias',
    'Cambui',
    'Bueno Brandao',
    'Santo Antonio do Retiro',
    'Rio Bananal',
    'Dionisio Cerqueira',
    'Sao Gabriel da Cachoeira',
    'Jataizinho',
    'Rio Claro',
    'Puxinana',
    'Putinga',
    'Nao Me Toque',
    'Erval Grande',
    'Grajau',
    'Capela',
    'Belem',
    'Riacho Fundo',
    'Benevides',
    'Lapa',
    'Monte Carmelo',
    'Ibate',
    'Itaituba',
    'Paraibano',
    'Colinas',
    'Presidente Dutra',
    'Sao Felipe',
    'Ubaira',
    'Nova Itarana',
    'Santa Teresinha',
    'Jiquirica',
    'Entre Rios de Minas',
    'Paraguacu',
    'Campestre',
    'Mendes Pimentel',
    'Tumiritinga',
    'Garca',
    'Laranjal Paulista',
    'Pirangi',
    'Juazeiro',
    'Gameleira',
    'Serra Talhada',
    'Belo Jardim',
    'Velha',
    'Massape',
    'Morrinhos',
    'Sao Paulo',
    'Chaval',
    'Alagoinha',
    'Andre da Rocha',
    'Caraa',
    'Uirauna',
    'Vespasiano Correa',
    'Bom Principio',
    'Schroeder',
    'Quata',
    'Jampruca',
    'Virginopolis',
    'Lagoa Seca',
    'Esperanca',
    'Cassino',
    'Carmopolis',
    'Buritizeiro',
    'Sao Francisco do Conde',
    'Mairi',
    'Piritiba',
    'Uaua',
    'Campo Formoso',
    'Nova',
    'Cachoeira',
    'Uibai',
    'Jucara',
    'Jesuania',
    'Caxambu',
    'Buzios',
    'Armacao de Buzios',
    'Arapoti',
    'Wenceslau Braz',
    'Itarare',
    'Resende Costa',
    'Caiana',
    'Guariba',
    'Nova Independencia',
    'Igarata',
    'Guarabira',
    'Luis Antonio',
    'Dourado',
    'Ilha Solteira',
    'Serra Azul',
    'Sao Simao',
    'Aguas de Lindoia',
    'Cajobi',
    'Itajobi',
    'Santa Adelia',
    'Taiuva',
    'Elisiario',
    'Sao Domingos',
    'Jacobina',
    'Redencao',
    'Muritiba',
    'Tapejara',
    'Santo Amaro da Imperatriz',
    'Luis Antonio',
    'Cidade',
    'Tres Passos',
    'Tucunduva',
    'Palmitinho',
    'Areado',
    'Guaranesia',
    'Rio Manso',
    'Piracema',
    'Carmopolis de Minas',
    'Bonfim',
    'Aguanil',
    'Cidade Nova',
    'Aldeia',
    'Cerro Largo',
    'Renascenca',
    'Igaracu do Tiete',
    'Nova Resende',
    'Jandaia do Sul',
    'Tuparendi',
    'Joao Neiva',
    'Santana do Ipanema',
    'Belem do Brejo do Cruz',
    'Virginia',
    'Coreau',
    'Senhor do Bonfim',
    'Itiuba',
    'Pindobacu',
    'Serra Preta',
    'Gurjao',
    'Paulo Lopes',
    'Boqueirao',
    'Padre',
    'Rio Novo do Sul',
    'Anchieta',
    'Guaranta',
    'Inubia Paulista',
    'Muzambinho',
    'Rafard',
    'Groairas',
    'Rio Acima',
    'Cruzilia',
    'Lambari',
    'Santa Cruz do Rio Pardo',
    'Codó',
    'Fragoso',
    'Sao Sebastiao',
    'Antonio Prado',
    'Nova Londrina',
    'Sertanopolis',
    'Antonina',
    'Marumbi',
    'Cruzeiro do Oeste',
    'Engenheiro Beltrao',
    'Imperatriz',
    'Posse',
    'Sao Luis',
    'Campos',
    'Acucena',
    'Manga',
    'Canelinha',
    'Muliterno',
    'Vila Maria',
    'Casca',
    'Conceicao das Alagoas',
    'Frutal',
    'Presidente Olegario',
    'Jaguaras',
    'Guarani dOeste',
    'Julio Mesquita',
    'Taiacu',
    'Pariquera Acu',
    'Coroata',
    'Alto Alegre',
    'Sao Jose',
    'Gloria',
    'Nova Aurora',
    'Riacho Fundo',
    'Sarapui',
    'Santa Barbara do Sul',
    'Sangao',
    'Iraceminha',
    'Faxinal dos Guedes',
    'Erval Velho',
    'Mandirituba',
    'Peabiru',
    'Santo Ângelo',
    'Sidrolandia',
    'Paraiso do Tocantins',
    'Brejinho de Nazare',
    'Monte Alegre',
    'Turvo',
    'Jaguapita',
    'Balsa Nova',
    'Iretama',
    'Ibatiba',
    'Manhuacu',
    'Lajinha',
    'Muniz Freire',
    'Rio Paranaiba',
    'Cruzeiro da Fortaleza',
    'Matutina',
    'Nova Ponte',
    'Pitangui',
    'Perdigao',
    'Araujos',
    'Papagaios',
    'Nova Araca',
    'Pedro de Toledo',
    'Parnamirim',
    'Ribas do Rio Pardo',
    'Guararapes',
    'Barcarena',
    'Satuba',
    'Carmo da Mata',
    'Oliveira',
    'Rubim',
    'Taiobeiras',
    'Itarantim',
    'Paraiso',
    'Ibicoara',
    'Itororo',
    'Sao Bento',
    'America de Cima',
    'Presidente Epitacio',
    'Iguai',
    'Faxinal do Soturno',
    'Quebrangulo',
    'Itapissuma',
    'Cupira',
    'Salgueiro',
    'Bayeux',
    'Areial',
    'Remigio',
    'Rio Tinto',
    'Boquim',
    'Feira Nova',
    'Porto Esperidiao',
    'Porto Amazonas',
    'Candoi',
    'Rio Azul',
    'Porto Belo',
    'Itapo',
    'Armazem',
    'Lauro',
    'Comodoro',
    'Guaragi',
    'Colorado',
    'Campinorte',
    'Curimata',
    'Morro Reuter',
    'Aparecida do Rio Negro',
    'Ouro Verde',
    'Porecatu',
    'Munhoz',
    'Pirai do Sul',
    'Santa Teresa',
    'Itamogi',
    'Cedro do Abaete',
    'Sabinopolis',
    'Marcelino Vieira',
    'Colina',
    'Auriflama',
    'Casa Nova',
    'Sao Bento',
    'Marituba',
    'Arcoverde',
    'Flores',
    'Buique',
    'Sao Joao de Pirabas',
    'Inhangapi',
    'Bonito',
    'Santarem Novo',
    'Maracana',
    'Piedade do Rio Grande',
    'Carmo do Cajuru',
    'Itatira',
    'Umirim',
    'Belo Horizonte',
    'Irupi',
    'Macau',
    'Palma',
    'Eugenopolis',
    'Perdoes',
    'Montes Altos',
    'Pompeia',
    'Cordeiro',
    'Cantagalo',
    'Patrocinio Paulista',
    'Santo Antonio do Monte',
    'Alto do Rodrigues',
    'Sao Sebastiao do Maranhao',
    'Aracariguama',
    'Itaparica',
    'Rodrigues',
    'Canavieiras',
    'Icarai',
    'Sao Joao da Barra',
    'Pachecos',
    'Rio Piracicaba',
    'Azurita',
    'Paulista',
    'Ibirarema',
    'Dobrada',
    'Paraitinga',
    'Nazare Paulista',
    'Fortaleza',
    'Iturama',
    'Sapucaia',
    'Sorriso',
    'Cipotanea',
    'Antonio Carlos',
    'Caiua',
    'Bela Vista',
    'Juquia',
    'Saudades',
    'Cunha Pora',
    'Pecem',
    'Beberibe',
    'Pindoretama',
    'Florinia',
    'Monte Siao',
    'Ocara',
    'Tamoios',
    'Eugenio',
    'Barra do Corda',
    'Pauini',
    'Fortuna',
    'Pastos Bons',
    'Luz',
    'Faxinal',
    'Jequié',
    'Central',
    'Chavantes',
    'Palmital',
    'Tenente Ananias',
    'Brasa',
    'Jales',
    'Rio Formoso',
    'Andre',
    'Botafogo',
    'Parana',
    'Santa Terezinha',
    'Nova Esperanca',
    'Chapadao',
    'Pitanga',
    'Barra do Jacare',
    'Aricanduva',
    'Garuva',
    'Jussara',
    'Carangola',
    'Ervalia',
    'Sao Bento do Sapucai',
    'Icoraci',
    'Capitao Poco',
    'Campo Magro',
    'Quatigua',
    'Bituruna',
    'Tibagi',
    'Ceu Azul',
    'Sulina',
    'Sabaudia',
    'Pompeu',
    'Ipaucu',
    'Lavinia',
    'Aguai',
    'Aguas da Prata',
    'Roseira',
    'Capao Bonito',
    'Tanabi',
    'Itambaraca',
    'Matelandia',
    'Entre Rios',
    'Jose de Freitas',
    'Floresta Azul',
    'Terra Nova',
    'Altos',
    'Avanhandava',
    'Fronteira',
    'Pontes e Lacerda',
    'Coari',
    'Porto Sao Jose Velho',
    'Sao Pedro do Ivai',
    'California',
    'Santa Helena',
    'Buriti',
    'Picada',
    'Estancia Nova',
    'Capela do Alto',
    'Carambei',
    'Urubici',
    'Itaipava',
    'Barracao',
    'Turvo',
    'Foz do Jordao',
    'Boa Ventura',
    'Ipubi',
    'Uruburetama',
    'Rio Novo',
    'Agrestina',
    'Chapadao',
    'Matipo',
    'Sao Sebastiao',
    'Rodeio Bonito',
    'Ronda Alta',
    'Nova Timboteua',
    'Ilhota',
    'Barras',
    'Tarumirim',
    'Coromandel',
    'Teodoro Sampaio',
    'Forquilha',
    'Jussari',
    'Santa Cruz da Baixa Verde',
    'Pilao Arcado',
    'Itape',
    'Antas',
    'Pereira Barreto',
    'Rosalia',
    'Santo Expedito',
    'Lavras do Sul',
    'Taquarana',
    'Sao Sebastiao',
    'Agua Clara',
    'Santo Antonio de Posse',
    'Itobi',
    'Miracatu',
    'Rubiataba',
    'Neropolis',
    'Loanda',
    'Lumiar',
    'Sardoa',
    'Cabrobo',
    'Ubaitaba',
    'Sao Felix',
    'Planalto',
    'Conde',
    'Poco Branco',
    'Pendencias',
    'Guacui',
    'São Borja',
    'Caibate',
    'Pedro Afonso',
    'Jabora',
    'Cafelandia',
    'Salto',
    'Platina',
    'Quitandinha',
    'Iguaba Grande',
    'Barra Velha',
    'Palmeiras de Goias',
    'Clevelandia',
    'Sede Nova',
    'Inhacora',
    'Apiai',
    'Areal',
    'Cidade de Deus',
    'Igreja Nova',
    'Palmeira dos Indios',
    'Santa Lucia',
    'Bela Vista',
    'Porto Velho',
    'Ouro Preto do Oeste',
    'Itagi',
    'Coroas',
    'Otacilio Costa',
    'Adamantina',
    'Santo Amaro',
    'Nova',
    'Angatuba',
    'Palmelo',
    'Rodeiro',
    'Breves',
    'Monte Dourado',
    'Juruti',
    'Sao Miguel',
    'Clementina',
    'Arraial',
    'Bangu',
    'Barra da Tijuca',
    'Palestina',
    'Barao de Cotegipe',
    'Indianopolis',
    'Itinga da Serra',
    'Cachoeira Grande',
    'Baixo Guandu',
    'Barra',
    'Abreu',
    'Ouricuri',
    'Santo Antonio da Platina',
    'Telemaco Borba',
    'Cerro Azul',
    'Mantenopolis',
    'Agudo',
    'Inocencia',
    'Nova Trento',
    'Paranapanema',
    'Sao Miguel Arcanjo',
    'Cardoso',
    'Fernando Prestes',
    'Iranduba',
    'Bom Jesus',
    'Conceicao da Barra',
    'Barra',
    'Guai',
    'Alfredo Chaves',
    'Bonito',
    'Primeiro de Maio',
    'Catanduvas',
    'Engenheiro Schmidt',
    'Estiva',
    'Terra Roxa',
    'Brochier'
  ],
  Azerbaijan: [
    'Baku',
    'Sumqayit',
    'Xirdalan',
    'Quba',
    'Zurges',
    'Şǝki',
    'Qusar',
    'Nakhchivan',
    'Sulutapa',
    'Xaçmaz'
  ],
  Palestine: [
    'Gaza',
    'Ramallah',
    'Bethlehem',
    'Hebron',
    'Jericho',
    'Al Mawasi',
    'Nablus',
    'Jenin'
  ],
  'Republic of Lithuania': [
    'Vilnius',
    'Alytus',
    'Marijampolė',
    'Šilutė',
    'Grigiškės',
    'Šiauliai',
    'Mažeikiai',
    'Plungė',
    'Jurbarkas',
    'Klaipėda',
    'Kaunas',
    'Radviliškis',
    'Jonava',
    'Panevezys',
    'Garliava',
    'Mastaiciai',
    'Rokiškis',
    'Joniškėlis',
    'Kupiškis',
    'Telšiai',
    'Kretinga',
    'Elektrėnai',
    'Vievis',
    'Visaginas',
    'Uzliedziai',
    'Anykščiai',
    'Kaišiadorys',
    'Juskonys',
    'Trakai',
    'Palanga',
    'Gargždai',
    'Kėdainiai',
    'Vilniaus Apskritis',
    'Ignalina',
    'Tauragė',
    'Anciskiai',
    'Eišiškės',
    'Salcininkai',
    'Antakalnis',
    'Kelmė',
    'Sakiai',
    'Venta',
    'Ukmerge'
  ],
  Oman: [
    'Muscat',
    'Salalah',
    'Ruwi',
    'Al Sohar',
    'Nizwa',
    'Samad',
    'Saham',
    ''
  ],
  Slovakia: [
    'Bratislava',
    "Vel'ke Canikovce",
    'Zavod',
    'Studienka',
    'Žilina',
    'Likavka',
    'Krupina',
    'Zvolen',
    'Klokoc',
    'Pliesovce',
    'Trnava',
    'Stará Ľubovňa',
    'Banská Bystrica',
    'Michalovce',
    'Humenné',
    'Sobrance',
    'Varin',
    'Zelenec',
    'Detva',
    'Ružomberok',
    'Galanta',
    'Dubnica nad Váhom',
    'Vrutky',
    'Lukacovce',
    'Nitra',
    "Vel'ky Grob",
    'Potvorice',
    'Krompachy',
    'Lozorno',
    'Poprad',
    'Vrable',
    'Dolna Streda',
    'Vahovce',
    'Košice',
    'Nová Dubnica',
    'Gbely',
    'Dlha nad Oravou',
    'Mutne',
    'Prievidza',
    'Tvrdosin',
    'Oravske Vesele',
    'Námestovo',
    'Trstena',
    'Pernek',
    'Solosnica',
    'Krizovany nad Dudvahom',
    'Dolny Stal',
    'Pata',
    'Sucha nad Parnou',
    'Šaľa',
    'Piešťany',
    'Hlohovec',
    'Nové Zámky',
    'Malacky',
    'Opoj',
    'Stara Tura',
    'Sladkovicovo',
    'Pezinok',
    'Stupava',
    'Ruzindol',
    'Marianka',
    'Slovensky Grob',
    'Reca',
    'Kvetoslavov',
    'Boleraz',
    "Topol'niky",
    'Hruby Sur',
    'Jelka',
    'Cerova',
    'Lučenec',
    'Vranov nad Topľou',
    'Prešov',
    'Moldava nad Bodvou',
    'Ludanice',
    'Dolny Hricov',
    'Ladce',
    'Púchov',
    'Liptovsky Hradok',
    'Vojcice',
    'Giraltovce',
    'Levoča',
    'Sabinov',
    'Dlhe nad Cirochou',
    'Fintice',
    'Sarisske Bohdanovce',
    'Gelnica',
    'Bardejov',
    'Cecejovce',
    'Secovska Polianka',
    'Bernolakovo',
    'Cifer',
    'Baka',
    "Presel'any nad Ipl'om",
    'Kopcany',
    'Senica',
    'Bahon',
    'Nizna Kamenica',
    "Dol'any",
    'Skalica',
    "Vel'ke Kostol'any",
    'Miloslavov',
    'Prakovce',
    'Komjatice',
    'Trenčín',
    'Golianovo',
    'Branc',
    'Levice',
    'Cerveny Hradok',
    'Bystricka',
    'Opatovce nad Nitrou',
    'Martin',
    'Turčianske Teplice',
    'Horna Stubna',
    'Topoľčany',
    'Hlinik',
    'Ivanka pri Dunaji',
    'Cerveny Kamen',
    'Myjava',
    'Hrochot,Slovakia',
    'Považská Bystrica',
    'Zikava',
    'Lednicke Rovne',
    'Kysucké Nové Mesto',
    'Lemesany',
    'Svidník',
    'Udavske',
    'Bohdanovce',
    'Zuberec',
    'Ilava',
    'Kysucky Lieskovec',
    'Rosina',
    'Nededza',
    'Liptovský Mikuláš',
    'Komárno',
    'Rožňava',
    'Tlmace',
    "Host'ovce",
    'Nova Bana',
    'Okoc',
    'Spissky Stiavnik',
    'Druzstevna pri Hornade',
    'Dolný Kubín',
    'Dunajská Streda',
    'Samorin',
    'Čadca',
    'Svätý Jur',
    'Rovinka',
    'Staskov',
    'Velicna',
    'Istebne',
    'Hencovce',
    'Banská Štiavnica',
    'Snina',
    'Brezno',
    'Spišská Nová Ves',
    "Vel'ky Kyr",
    'Stropkov',
    'Nitrianske Rudno',
    'Vlkanova',
    'Dolne Vestenice',
    'Šurany',
    'Podbrezova',
    'Štúrovo',
    "Ipel'ske Ul'any",
    'Bela',
    'Kežmarok',
    'Jelenec',
    'Holic',
    'Dlhe Klcovo',
    'Velcice',
    'Slovenska Ves',
    'Banovce nad Bebravou',
    'Trebišov',
    'Secovce',
    "Vel'ke Kapusany",
    "Hnust'a",
    'Borovce',
    'Mojmirovce',
    'Spacince',
    'Senec',
    'Stefanov',
    'Pastuchov',
    'Chtelnica',
    'Dolne Oresany',
    'Eliasovce',
    'Moravany nad Vahom',
    'Vlckovce',
    'Madunice',
    "Topol'nica",
    'Svodin',
    'Jablonove',
    'Jacovce',
    'Olesna',
    'Chorvatsky Grob',
    'Tomasov',
    'Turany',
    'Sucany',
    'Klokocov',
    'Trnovec nad Vahom',
    'Tesedikovo',
    'Tvrdosovce',
    "Kmet'ovo",
    'Hlinik nad Hronom',
    'Ziar nad Hronom',
    'Besenov',
    'Sena',
    'Nove Sady',
    'Brezova pod Bradlom',
    'Praznovce',
    'Bytča',
    'Zlate',
    'Vinne',
    'Zbince',
    'Hrinova',
    'Vrbové',
    'Sipkove',
    'Polomka',
    'Handlova',
    'Smizany',
    'Spissky Stvrtok',
    'Jaslovske Bohunice',
    "Vel'ky Krtis",
    'Zeliezovce',
    'Torysky',
    'Spissky Hrusov',
    'Rimavská Sobota',
    'Nizna',
    'Urmince',
    'Kanianka',
    'Diviaky nad Nitricou',
    'Novaky',
    'Partizánske',
    'Novy Salas',
    'Varhanovce',
    'Svaty Peter',
    'Nyrovce',
    'Proc',
    'Revúca',
    'Nové Mesto nad Váhom',
    'Plave Vozokany',
    'Nitrianske Sucany',
    'Blatnica',
    'Cechynce',
    'Velky Meder',
    'Holice',
    'Vysne Raslavice',
    "Vel'aty",
    'Nana',
    'Tajov',
    'Svit',
    "Vel'ka Maca",
    'Selice',
    'Tatranská Lomnica',
    'Kremnica',
    'Stary Smokovec',
    'Stara Lesna',
    'Liptovske Revuce',
    'Kosicka Nova Ves',
    'Maly Lapas',
    'Vysny Orlik',
    'Horne Oresany',
    'Klatova Nova Ves',
    'Hradiste',
    'Praha',
    'Horny Smokovec',
    'Kajal',
    "Vel'ke Orviste",
    'Horna Lehota',
    'Lehota',
    'Kukova',
    'Jelšava',
    'Kolarovo',
    'Horne Saliby',
    "Novot'",
    'Zakamenne',
    'Svaty Kriz',
    'Terchova',
    'Lubina',
    'Mesto',
    'Beladice',
    'Krusovce',
    'Dolna Marikova',
    'Plavecke Podhradie',
    'Podolie',
    'Pobedim',
    'Sliač',
    'Vecelkov',
    'Chlebnice',
    'Jasenica',
    'Bobrov',
    'Vitanova',
    "Dolne Sl'azany",
    'Diakovce',
    'Selpice'
  ],
  Serbia: [
    'Belgrade',
    'Zemun Polje',
    'Kragujevac',
    'Novi Sad',
    'Subotica',
    'Niš',
    'Vajska',
    'Futog',
    'Pančevo',
    'Backa Topola',
    'Cantavir',
    'Becej',
    'Backo Gradiste',
    'Kraljevo',
    'Basaid',
    'Bocar',
    'Kovin',
    'Novi Belgrade',
    'Savski Venac',
    'Sremska Kamenica',
    'Aleksinac',
    'Surcin',
    'Grocka',
    'Umcari',
    'Nova Pazova',
    'Sombor',
    'Smederevo',
    'Sremski Karlovci',
    'Jagodina',
    'Novi Becej',
    'Stara Pazova',
    'Apatin',
    'Požarevac',
    'Dolovo',
    'Coka',
    'Gornji Milanovac',
    'Veternik',
    'Sibac',
    'Tornjos',
    'Zlatibor',
    'Čačak',
    'Ugrinovci',
    'Kruševac',
    'Paracin',
    'Priboj',
    'Despotovac',
    'Prokuplje',
    'Titel',
    'Rumenka',
    'Temerin',
    'Backi Petrovac',
    'Backi Jarak',
    'Kanjiza',
    'Srbobran',
    'Golubinci',
    'Petrovac',
    'Barajevo',
    'Bor',
    'Zrenjanin',
    'Melenci',
    'Novi Banovci',
    'Ingija',
    'Odzaci',
    'Drenovac',
    'Glozan',
    'Ratkovo',
    'Banatsko Novo Selo',
    'Ruma',
    'Svilajnac',
    'Avala',
    'Cuprija',
    'Popovac',
    'Svrljig',
    'Simanovci',
    'Sremcica',
    'Leskovac',
    'Selo Mladenovac',
    'Užice',
    'Valjevo',
    'Pirot',
    'Arilje',
    'Novi Pazar',
    'Kacarevo',
    'Loznica',
    'Petrovaradin',
    'Zajecar',
    'Debeljaca',
    'Senta',
    'Vrbas',
    'Vršac',
    'Stari Banovci',
    'Batajnica',
    'Umka',
    'Izvor',
    'Nikinci',
    'Sirig',
    'Sremska Mitrovica',
    'Palic',
    'Cukarica',
    'Ada'
  ],
  Finland: [
    'Helsinki',
    'Hamina',
    'Kotka',
    'Kuusankoski',
    'Lappeenranta',
    'Imatra',
    'Tampere',
    'Kuopio',
    'Rauma',
    'Eurajoki',
    'Oulu',
    'Parkano',
    'Kihnioe',
    'Pori',
    'Tyrnaevae',
    'Lahti',
    'Espoo',
    'Ulvila',
    'Hämeenlinna',
    'Tuomikylae',
    'Hyvinkaeae',
    'Kankaanpaeae',
    'Nummela',
    'Nokia',
    'Turku',
    'Tuusula',
    'Vantaa',
    'Jyväskylä',
    'Marttila',
    'Tarvasjoki',
    'Kärsämäki',
    'Savonlinna',
    'Louhi',
    'Varpaisjärvi',
    'Haukipudas',
    'Heinola',
    'Lohja',
    'Orimattila',
    'Nastola',
    'Vaeaeksy',
    'Järvenpää',
    'Larsmo',
    'Nykarleby',
    'Jakobstad',
    'Lepplax',
    'Littoinen',
    'Bennäs',
    'Ruovesi',
    'Maenttae',
    'Virrat',
    'Valkeakoski',
    'Poikelus',
    'Halli',
    'Vilppula',
    'Hollola',
    'Villähde',
    'Salo',
    'Kimito',
    'Somero',
    'Saunalahti',
    'Ylöjärvi',
    'Joensuu',
    'Vaasa',
    'Kajaani',
    'Vuokatti',
    'Outokumpu',
    'Peraseinajoki',
    'Hollola',
    'Porvoo',
    'Hammaslahti',
    'Kitee',
    'Kontiolahti',
    'Ylaemylly',
    'Pirkkala',
    'Rovaniemi',
    'Suomussalmi',
    'Kerava',
    'Kempele',
    'Kolinkylae',
    'Sotkuma',
    'Polvijärvi',
    'Kauttua',
    'Sipoo',
    'Loviisa',
    'Tolkis',
    'Pernå',
    'Porlammi',
    'Soederkulla',
    'Lapinjärvi',
    'Rautjaervi',
    'Anjala',
    'Kouvola',
    'Koria',
    'Neuvoton',
    'Ravijoki',
    'Iisalmi',
    'Kiuruvesi',
    'Sonkajärvi',
    'Vieremä',
    'Lielax',
    'Pargas',
    'Hoegsara',
    'Kirjala',
    'Hiltulanlahti',
    'Kirkkonummi',
    'Mikkeli',
    'Oulunsalo',
    'Vammala',
    'Koskioinen',
    'Karkku',
    'Punkalaidun',
    'Pornainen',
    'Huuvari',
    'Monninkylä',
    'Myrskylä',
    'Hinthaara',
    'Sälinkää',
    'Kerkkoo',
    'Mäntsälä',
    'Anttila',
    'Laukkoski',
    'Multia',
    'Jämsänkoski',
    'Tikkakoski',
    'Äänekoski',
    'Muurame',
    'Jokela',
    'Taipalsaari',
    'Joutseno',
    'Riihimäki',
    'Jääli',
    'Pattijoki',
    'Huittinen',
    'Kokemäki',
    'Soeoermarkku',
    'Kauvatsa',
    'Poitsila',
    'Kausala',
    'Valkeala',
    'Pilkanmaa',
    'Kauniainen',
    'Suolahti',
    'Keuruu',
    'Jämsä',
    'Laukaa',
    'Purola',
    'Pockar',
    'Varkaus',
    'Harjavalta',
    'Naantali',
    'Raisio',
    'Pieksämäki',
    'Puumala',
    'Kemi',
    'Tornio',
    'Ypaejae',
    'Aura',
    'Loimaa',
    'Kangasniemi',
    'Hirvensalmi',
    'Ristiina',
    'Rantasalmi',
    'Sotkamo',
    'Alavus',
    'Kokkola',
    'Terjaerv',
    'Kaustinen',
    'Seinäjoki',
    'Kokkola',
    'Kaakamo',
    'Lautiosaari',
    'Viantie',
    'Kuivakangas',
    'Karunki',
    'Aavasaksa',
    'Kemijärvi',
    'Kolari',
    'Kaarina',
    'Merimasku',
    'Noormarkku',
    'Toijala',
    'Kaipiainen',
    'Linnavuori',
    'Aitoo',
    'Forssa',
    'Paelkaene',
    'Laitikkala',
    'Kylmaekoski',
    'Metsaekansa',
    'Erajarvi',
    'Viiala',
    'Tammela',
    'Tuulos',
    'Turenki',
    'Akaa',
    'Saaksmaki',
    'Riihikoski',
    'Utti',
    'Ruotsinpyhtää',
    'Elimäki',
    'Gammelby',
    'Jurva',
    'Kaskinen',
    'Kurikka',
    'Teuva',
    'Laihia',
    'Kauhajoki',
    'Isojoki',
    'Niinijoki',
    'Vaskio',
    'Saarijaervi',
    'Viitasaari',
    'Niittylahti',
    'Nurmes',
    'Nunnanlahti',
    'Suhmura',
    'Taavetti',
    'Ruokolahti',
    'Savitaipale',
    'Sammaljoki',
    'Haijaa',
    'Roismala',
    'Mouhijaervi',
    'Kivijaervi',
    'Kyyjärvi',
    'Kinnula',
    'Pihtipudas',
    'Karstula',
    'Kannonkoski',
    'Humppila',
    'Latovainio',
    'Jokioinen',
    'Liljendal',
    'Korsholm',
    'Närpes',
    'Toelby',
    'Vörå',
    'Seinaejoki',
    'Mantila',
    'Lapua',
    'Kuortane',
    'Tuuri',
    'Ähtäri',
    'Muhos',
    'Raahe',
    'Alavieska',
    'Ylivieska',
    'Nivala',
    'Pyhaejoki',
    'Kannus',
    'Kalajoki',
    'Sykaeraeinen',
    'Sievi',
    'Haapavesi',
    'Oulainen',
    'Lampinsaari',
    'Inkeroinen',
    'Klaukkala',
    'Nuppulinna',
    'Siilinjärvi',
    'Uusikaupunki',
    'Kalanti',
    'Pyhamaa',
    'Vaulammi',
    'Halikko',
    'Kisko',
    'Kruusila',
    'Pertteli',
    'Pernioe',
    'Karkkila',
    'Linna',
    'Skinnarby',
    'Laitila',
    'Padasjoki',
    'Jaervelae',
    'Karinkanta',
    'Kiiminki',
    'Laeyliaeinen',
    'Nurmijärvi',
    'Sajaniemi',
    'Hikiä',
    'Jyskae',
    'Vaajakoski',
    'Korplax',
    'Haapamäki',
    'Keminmaa',
    'Ylitornio',
    'Aapajoki',
    'Kantomaanpaeae',
    'Simoniemi',
    'Lehtimaeki',
    'Evijaervi',
    'Okskoski',
    'Vimpeli',
    'Veteli',
    'Kauhava',
    'Lappajaervi',
    'Lempäälä',
    'Kangasala',
    'Paattinen',
    'Mynaemaeki',
    'Piikkiö',
    'Rymättylä',
    'Vahto',
    'Tortinmaeki',
    'Masku',
    'Piispanristi',
    'Vanhalinna',
    'Nousiainen',
    'Lieto',
    'Kantvik',
    'Veikkola',
    'Masala',
    'Ylämaa',
    'Laikko',
    'Särkisalmi',
    'Luumäki',
    'Oripää',
    'Alastaro',
    'Karttula',
    'Suonenjoki',
    'Leppävirta',
    'Lapinlahti',
    'Heinävesi',
    'Punkaharju',
    'Maentyharju',
    'Kaipola',
    'Petäjävesi',
    'Palokka',
    'Kyroeskoski',
    'Kyynaeroe',
    'Ii',
    'Pello',
    'Uurainen',
    'Valkola',
    'Lammi',
    'Hauho',
    'Levanto',
    'Kello',
    'Kontiomaeki',
    'Puolanka',
    'Venetheitto',
    'Jormua',
    'Saukkola',
    'Liminka',
    'Kronoby',
    'Alaveteli',
    'Sundby',
    'Munsala',
    'Pjelax',
    'Purmo',
    'Forsby',
    'Kållby',
    'Oja',
    'Kälviä',
    'Ilmajoki',
    'Naervijoki',
    'Ylihaermae',
    'Isokyrö',
    'Nurmo',
    'Siikainen',
    'Kantti',
    'Tervakoski',
    'Ilveskylae',
    'Kopsa',
    'Lohtaja',
    'Revonlahti',
    'Himanka',
    'Luohua',
    'Saloinen',
    'Mankila',
    'Toholampi',
    'Angelniemi',
    'Somerniemi',
    'Suomusjaervi',
    'Muurla',
    'Alvettula',
    'Sappee',
    'Hattula',
    'Bergoe',
    'Kerimäki',
    'Svarta',
    'Nakkila',
    'Ihode',
    'Vasarainen',
    'Saekylae',
    'Salomaa',
    'Martensby',
    'Kellokoski',
    'Sahalahti',
    'Launonen',
    'Kulho',
    'Solberg',
    'Kuusisto',
    'Kaivanto',
    'Lehmo',
    'Siivikkala',
    'Siuntio',
    'Narva',
    'Oitti',
    'Ryttylae',
    'Saynatsalo',
    'Loppi',
    'Hausjaervi',
    'Vesanka',
    'Kormu',
    'Paippinen',
    'Nummenkylae',
    'Paimio',
    'Sauvo',
    'Lievestuore',
    'Jorvas',
    'Siuro',
    'Tottijarvi',
    'Pukkila',
    'Vakkola',
    'Nummenkylae',
    'Hanko',
    'Pankakoski',
    'Lieksa',
    'Juuka',
    'Uimaharju',
    'Liperi',
    'Ekenäs',
    'Simpele',
    'Nilsiä',
    'Saeviae',
    'Lumijoki',
    'Vikajärvi',
    'Kiistala',
    'Peraelae',
    'Sääksjärvi',
    'Askola',
    'Köyliö',
    'Peipohja',
    'Hervanta',
    'Halsua',
    'Raseborg',
    'Karjaa',
    'Fiskars',
    'Karjalohja',
    'Tenala',
    'Viljakkala',
    'Hämeenkyrö',
    'Vihti',
    'Hoeytiae',
    'Vehniae',
    'Nuutajaervi',
    'Nahkela',
    'Rajamäki',
    'Roeykkae',
    'Nukari',
    'Vihtavuori',
    'Leppävesi',
    'Box',
    'Kaerrby',
    'Vaesterskog',
    'Kaemmenniemi',
    'Kuivaniemi',
    'Myllykoski',
    'Perttula',
    'Mönni',
    'Rusko',
    'Orivesi',
    'Juupajoki',
    'Degerby',
    'Pusula',
    'Poeytyae',
    'Ilmarinen',
    'Ruutana',
    'Jokikunta',
    'Tervalampi',
    'Otalampi',
    'Palojoki',
    'Kiljava',
    'Kuohu',
    'Evitskog',
    'Kuhmalahti',
    'Nummi',
    'Lempää',
    'Ingå',
    'Toivala',
    'Merijärvi',
    'Haapajärvi',
    'Karhula',
    'Pellinki',
    'Korppoo',
    'Lahela',
    'Virolahden Kirkonkylä',
    'Karvia',
    'Koskenkorva',
    'Valkama',
    'Tervajoki',
    'Kuni',
    'Sundom',
    'Kvevlax',
    'Ruto',
    'OEvermalax',
    'Helsingby',
    'Mieto',
    'Hirvihaara',
    'Skuru',
    'Kauhakorpi',
    'Tampella',
    'Pohjola',
    'Majvik',
    'Rautjaervi',
    'Utsjoki',
    'Muonio',
    'Sodankylä',
    'Mattila',
    'Kuusamo',
    'Mehtaekylae',
    'Heimari',
    'Haeme',
    'Ilkka',
    'Ivalo',
    'Eura',
    'Parkkuu',
    'Tarttila',
    'Pyhaentae',
    'Joroinen',
    'Pyhäsalmi',
    'Alajärvi',
    'Paalijaervi',
    'Luoma-aho',
    'Haukkala',
    'Itaekylae',
    'Koljonvirta',
    'Rautalampi',
    'Lemi',
    'Broby',
    'Kuhmo',
    'Eno',
    'Haermae',
    'Rääkkylä',
    'Pyhtää',
    'Iittala',
    'Harviala',
    'Janakkala',
    'Jokimaa',
    'Lepaa',
    'Riistavesi',
    'Halkia'
  ],
  Iceland: [
    'Reykjavik',
    'Selfoss',
    'Grindavik',
    'Keflavík',
    'Dalvik',
    'Akureyri',
    'Hvammstangi',
    'Husavik',
    'Kopavogur',
    'Akranes',
    'Stokkseyri',
    'Hveragerdi',
    'Hvolsvollur',
    'Borgarnes',
    'Vestmannaeyjar',
    'Vogar',
    'Hella',
    'Skagastrond',
    'Holmavik'
  ],
  'Republic of Moldova': [
    'Chişinău',
    'Tiraspol',
    'Comrat',
    'Edineţ',
    'Tighina',
    'Anenii Noi',
    'Bălţi',
    'Orhei',
    'Ialoveni',
    'Rîbniţa',
    'Soroca',
    'Nisporeni',
    'Cahul',
    'Briceni',
    'Magdacesti',
    'Straseni',
    'Ciorescu',
    'Durlesti',
    'Cojusna',
    'Vulcanesti',
    'Drochia',
    'Calarasi',
    'Voinescu'
  ],
  Bulgaria: [
    'Sofia',
    'Plovdiv',
    'Varna',
    'Targovishte',
    'Velingrad',
    'Burgas',
    'Popovo',
    'Karlovo',
    'Pavel Banya',
    'Trustenik',
    'Isperikh',
    'Shumen',
    'Orlovets',
    'Dobrich',
    'Gabrovo',
    'Haskovo',
    'Provadiya',
    'Kharmanli',
    'Sliven',
    'Stara Zagora',
    'Kozloduy',
    'Shabla',
    'Kavarna',
    'Sevlievo',
    'Lovech',
    'Gorna Oryakhovitsa',
    'Blagoevgrad',
    'Petrich',
    'Krumovgrad',
    'Krichim',
    'Smolyan',
    'Radnevo',
    'Muglizh',
    'Troyan',
    'Veliko Tarnovo',
    'Nikopol',
    'Kazanlak',
    'Chakalarovo',
    'Pernik',
    'Ruen',
    'Barutin',
    'Bratsigovo',
    'Isperikhovo',
    'Godech',
    'Chernolik',
    'Razgrad',
    'Dulovo',
    'Yambol',
    'Asenovgrad',
    'Pazardzhik',
    'Chirpan',
    'Rozino',
    'Ravda',
    'Montana',
    'General-Toshevo',
    'Aksakovo',
    'Baltchik',
    'Rodopi',
    'Ikhtiman',
    'Samokov',
    'Svoge',
    'Sopot',
    'Kardzhali',
    'Pleven',
    'Kyustendil',
    'Topoli',
    'Beloslav',
    'Dulgopol',
    'Obzor',
    'Cherven',
    'Vratsa',
    'Borovan',
    'Knezha',
    'Cherven Bryag',
    'Sandanski',
    'Dupnitsa',
    'Lozno',
    'Krupnik',
    'Boboshevo',
    'Rousse',
    'Sandrovo',
    'Zavet',
    'Svishtov',
    'Belene',
    'Pavlikeni',
    'Samovodene',
    'Polski Trumbesh',
    'Kostievo',
    'Akhtopol',
    'Nesebar',
    'Sredets',
    'Pomorie',
    'Simitli',
    'Slivnitsa',
    'Mezdra',
    'Batak',
    'Elkhovo',
    'Belogradchik',
    'Tutrakan',
    'Botevgrad',
    'Moderno Predgradie',
    'Borovo',
    'Topolovo',
    'Bankya',
    'Konush',
    'Dimitrovgrad',
    'Smyadovo',
    'Gulubovo',
    'Simeonovgrad',
    'Panagyurishte',
    'Bansko',
    'Yakoruda',
    'Silistra',
    'Byala',
    'Choba',
    'Iskrets',
    'Vlado Trichkov',
    'Zlatitrap',
    'Nova Zagora',
    'Devin',
    'Gotse Delchev',
    'Dragizhevo',
    'Byala',
    'Momchilgrad',
    'Pravets',
    'Etropole',
    'Malo Konare',
    'Aytos',
    'Bukovo',
    'Banite',
    'Chiprovtsi',
    'Mesta',
    'Tryavna',
    'Berkovitsa',
    'Novi Pazar',
    'Krivodol',
    'Elena',
    'Strazhitsa',
    'Peshtera',
    'Sozopol',
    'Moravka',
    'Kovachite',
    'Omurtag',
    'Vidin',
    'Kostinbrod',
    'Ardino',
    'Alfatar',
    'Rakovski',
    'Poleto',
    'Rila',
    'Draginovo',
    'Oryakhovitsa',
    'Dolna Oryakhovitsa',
    'Kostenets',
    'Raduil',
    'Bukovo',
    'Strelcha',
    'Sokolets',
    'Skravena',
    'Vrachesh',
    'Stamboliyski',
    'Lom',
    'Bistritsa',
    'Khisarya',
    'Shivachevo',
    'Anton',
    'Madan',
    'Elin Pelin',
    'Veliko',
    'Debelets',
    'Oryakhovets',
    'Markovo',
    'Bregare',
    'Byala Slatina',
    'Sofronievo',
    'Kula',
    'Lyulyakovo',
    'Kableshkovo',
    'Antonovo',
    'Belovo',
    'Dospat',
    'Dolna Banya',
    'Septemvri',
    'Bobovdol',
    'Zlatograd',
    'Tervel',
    'Lyaskovets',
    'Mikrevo',
    'Vievo',
    'Chernomorets',
    'Kran',
    'Karnobat',
    'Dryanovo',
    'Ovoshtnik',
    'Koprivets',
    'Vetren',
    'Lukovit',
    'Babovo',
    'Kamenovo',
    'Orizovo',
    'Svilengrad',
    'Rudozem',
    'Aleksandriya',
    'Rakitovo',
    'Ivaylovgrad',
    'Glogovo',
    'Gurkovo',
    'Marikostinovo',
    'Tochilari',
    'Slatina',
    'Razlog',
    'Prespa',
    'Gurmazovo',
    'Novi Iskur',
    'Samuil',
    'Pchelishte',
    'Dragash Voyvoda',
    'Dolna Mitropoliya',
    'Koprivlen',
    'Suedinenie',
    'Kazashka Reka',
    'Ganchovo'
  ],
  Macedonia: [
    'Lisice',
    'Skopje',
    'Prilep',
    'Veles',
    'Kumanovo',
    'Ilinden',
    'Cair',
    'Kavadarci',
    'Pripor',
    'Kočani',
    'Makedonska Kamenica',
    'Struga',
    'Shtip',
    'Bitola',
    'Debar',
    'Demir Hisar',
    'Strumica',
    'Kicevo',
    'Berovo',
    'Tetovo',
    'Ohrid',
    'Gostivar',
    'Labunista',
    'Radovis',
    'Negotino',
    'Probistip',
    'Gevgelija',
    'Bogdanci',
    'Delcevo',
    'Kadino',
    'Novo Selo'
  ],
  Liechtenstein: [
    'Mauren',
    'Eschen',
    'Nendeln',
    'Vaduz',
    'Schellenberg',
    'Planken',
    'Triesen',
    'Balzers',
    'Triesenberg',
    'Schaan',
    'Gamprin',
    'Ruggell',
    'Bendern',
    'Schaanwald'
  ],
  Jersey: ['Saint Helier', 'Saint Peter', "Saint John's Church"],
  Poland: [
    'Wrocław',
    'Warsaw',
    'Elzbieta',
    'Debica',
    'Sedziszow Malopolski',
    'Dabki',
    'Sławno',
    'Gorlice',
    'Wola Rebkowska',
    'Cierpice',
    'Mińsk Mazowiecki',
    'Zabia Wola',
    'Kampinos',
    'Krakow',
    'Olesnica',
    'Pruszków',
    'Marek',
    'Grupa',
    'Szczecin',
    'Kielce',
    'Warzachewka Polska',
    'Olsztyn',
    'Ełk',
    'Golasowice',
    'Katowice',
    'Mysłowice',
    'Zawiercie',
    'Chorzów',
    'Gracze',
    'Częstochowa',
    'Wadowice',
    'Radom',
    'Raszyn',
    'Tarnogrod',
    'Rzewnie',
    'Białystok',
    'Otwock',
    'Garwolin',
    'Borowiec',
    'Gliwice',
    'Gdańsk',
    'Wielki Klincz',
    'Debina',
    'Sedziszow',
    'Gozdowo',
    'Lublin',
    'Radoslaw',
    'Czersk',
    'Skoczow',
    'Jozefow',
    'Sopot',
    'Nowe Lignowy',
    'Poznan',
    'Gdynia',
    'Limanowa',
    'Chociwel',
    'Grzmucin',
    'Lubicz',
    'Celestynów',
    'Siemianowice Śląskie',
    'Lidzbark',
    'Sulejowek',
    'Biedrusko',
    'Malbork',
    'Chelm',
    'Chełm',
    'Kwidzyn',
    'Mielec',
    'Chorzelow',
    'Rzeszów',
    'Świdnica',
    'Przeclaw',
    'Suwałki',
    'Wejherowo',
    'Puck',
    'Rumia',
    'Tomaszow',
    'Golub-Dobrzyn',
    'Bielsko-Biala',
    'Bydgoszcz',
    'Bielawa',
    'Polanica-Zdrój',
    'Lebork',
    'Stegna',
    'Nakło nad Notecią',
    'Koscierzyna',
    'Jelenia Góra',
    'Ostrołęka',
    'Nowa Ruda',
    'Libiaz',
    'Młynary',
    'Oświęcim',
    'Jarosław',
    'Koszalin',
    'Zambrów',
    'Lukow',
    'Wisniew',
    'Wieluń',
    'Łódź',
    'Gorzów Wielkopolski',
    'Teofilow',
    'Koniecpol',
    'Klomnice',
    'Kruszyna',
    'Zawada',
    'Huta Stara',
    'Praszka',
    'Czeladz',
    'Stalowa Wola',
    'Suchowola',
    'Zabrze',
    'Opole',
    'Wałbrzych',
    'Linowko',
    'Witkowo',
    'Ranizow',
    'Nowy Tomysl',
    'Kornik',
    'Dolice',
    'Zawady',
    'Miłomłyn',
    'Barciany',
    'Biskupiec',
    'Bytom',
    'Radostowice',
    'Przeworsk',
    'Szowsko',
    'Radymno',
    'Trzebownisko',
    'Strazow',
    'Sokolow Malopolski',
    'Wysoka Strzyzowska',
    'Ogrody',
    'Siedlce',
    'Kotuń',
    'Świętochłowice',
    'Cisiec',
    'Wegierska Gorka',
    'Trawniki',
    'Swidnik',
    'Giżycko',
    'Mszczonów',
    'Kłodawa',
    'Ząbki',
    'Radzyń Chełmiński',
    'Bierun',
    'Sosnowiec',
    'Gorno',
    'Pabianice',
    'Rybnik',
    'Zakopane',
    'Lubin',
    'Sulechow',
    'Kargowa',
    'Zielona Góra',
    'Szprotawa',
    'Przylep',
    'Czerwiensk',
    'Przemysl',
    'Piotrków Trybunalski',
    'Kutno',
    'Rabien',
    'Gadka Stara',
    'Tomaszów Mazowiecki',
    'Sieradz',
    'Bełchatów',
    'Koło',
    'Poswietne',
    'Chalupki',
    'Janin',
    'Braniewo',
    'Biała Podlaska',
    'Kamienna Gora',
    'Goleniów',
    'Ostrowiec Świętokrzyski',
    'Szypliszki',
    'Wołomin',
    'Ruda Śląska',
    'Słupsk',
    'Budziska',
    'Tarnowskie Gory',
    'Police',
    'Słubice',
    'Nowy Dwor Mazowiecki',
    'Kalisz',
    'Międzyrzecz',
    'Wilczyce',
    'Debno',
    'Puławy',
    'Tyczyn',
    'Poreba',
    'Piekary',
    'Tarnowiec',
    'Radgoszcz',
    'Dąbrowa Górnicza',
    'Mikołów',
    'Wieliczka',
    'Tychy',
    'Gorz',
    'Raszowa',
    'Nowy Sącz',
    'Wilkowice',
    'Korytow',
    'Fabianki',
    'Marki',
    'Znin',
    'Swarzedz',
    'Płock',
    'Grudziądz',
    'Dzierzazno',
    'Polkowice',
    'Modlnica',
    'Teresin',
    'Sochaczew',
    'Skwierzyna',
    'Pielice',
    'Deszczno',
    'Wojciech',
    'Tarnów',
    'Wyszków',
    'Strzegom',
    'Swiebodzice',
    'Oborniki Śląskie',
    'Biłgoraj',
    'Gmina Widawa',
    'Inowrocław',
    'Anin',
    'Glowno',
    'Tłuszcz',
    'Nowe Skalmierzyce',
    'Konstancin-Jeziorna',
    'Kowalowa',
    'Proszowice',
    'Gorzow Slaski',
    'Mysliborz',
    'Walcz',
    'Bolszewo',
    'Ozarow Mazowiecki',
    'Zdunska Wola',
    'Starachowice',
    'Kobylka',
    'Sadlinki',
    'Dabrowa Chotomowska',
    'Piekary Slaskie',
    'Piastow',
    'Głogów',
    'Liszki',
    'Grodzisk Mazowiecki',
    'Krasnystaw',
    'Lowicz',
    'Dabrowka Wielka',
    'Czerwionka-Leszczyny',
    'Ornontowice',
    'Przyszowice',
    'Sanok',
    'Zgorzelec',
    'Piensk',
    'Zbaszyn',
    'Zbaszynek',
    'Jaslo',
    'Konin',
    'Żywiec',
    'Lipsko',
    'Reda',
    'Lubartow',
    'Plochocin',
    'Knurow',
    'Brochocin',
    'Barcin',
    'Włocławek',
    'Jablonica',
    'Chełmno',
    'Miedzyborow',
    'Serock',
    'Zielonka',
    'Sroda Wielkopolska',
    'Belsk Duzy',
    'Radzymin',
    'Gmina Morawica',
    'Gmina Brwinów',
    'Gmina Końskie',
    'Zielonki',
    'Lask',
    'Jerzmanowice',
    'Krzeszowice',
    'Tuszyn',
    'Mechelinki',
    'Kościan',
    'Szemud',
    'Lomianki',
    'Komorow',
    'Parczew',
    'Żyrardów',
    'Szczawno-Zdroj',
    'Lubliniec',
    'Zlocieniec',
    'Strzelce Opolskie',
    'Warka',
    'Toruń',
    'Mragowo',
    'Lodygowice',
    'Wabrzezno',
    'Annopol',
    'Ladek',
    'Chrzanów',
    'Gmina Strzelin',
    'Wola Zaradzynska',
    'Mikoszewo',
    'Rostarzewo',
    'Ryman',
    'Swietoniowa',
    'Strzyzow',
    'Płońsk',
    'Raciaz',
    'Solec Kujawski',
    'Bielsk',
    'Zamość',
    'Lack',
    'Ostróda',
    'Korsze',
    'Starogard Gdański',
    'Mońki',
    'Sokołów Podlaski',
    'Suchy Las',
    'Wysogotowo',
    'Lulin',
    'Murowana Goslina',
    'Kosakowo',
    'Pierwoszyno',
    'Pogwizdow',
    'Leszno',
    'Pobiedziska',
    'Tarnowo Podgorne',
    'Puszczykowo',
    'Gniezno',
    'Stawiany',
    'Garby',
    'Szamotuły',
    'Strzalkowo',
    'Buk',
    'Pleszew',
    'Wronki',
    'Czarze',
    'Maksymilianowo',
    'Gruczno',
    'Gzin',
    'Adama',
    'Wrzesnia',
    'Borzykowo',
    'Oblaczkowo',
    'Tulce',
    'Obrzycko',
    'Plewiska',
    'Gmina Śrem',
    'Mogilno',
    'Zabierzow Bochenski',
    'Świnoujście',
    'Maria',
    'Cigacice',
    'Chotel',
    'Nowa Sol',
    'Legnica',
    'Lubsko',
    'Grebocice',
    'Gorzyce',
    'Lubaszowa',
    'Wieszowa',
    'Tyczyn',
    'Chmielnik',
    'Blazowa',
    'Dynow',
    'Lubenia',
    'Ząbkowice Śląskie',
    'Gorzow',
    'Zarow',
    'Jaworzyna Slaska',
    'Tomaszow',
    'Szczytno',
    'Ciechocinek',
    'Rypin',
    'Kozieglowy',
    'Grzebien',
    'Gora',
    'Balice',
    'Studzieniec',
    'Czarnkow',
    'Zlotow',
    'Chodzież',
    'Pila',
    'Miroslawiec',
    'Ujscie',
    'Wyrzysk',
    'Slupca',
    'Międzychód',
    'Grodzisk Wielkopolski',
    'Gostyn',
    'Owinska',
    'Trzcianka',
    'Osno',
    'Wijewo',
    'Bochnia',
    'Dziewin',
    'Jastrzębie Zdrój',
    'Nysa',
    'Nowe Miasto Lubawskie',
    'Wojkowice',
    'Zofia',
    'Ostrów Wielkopolski',
    'Tarchaly Wielkie',
    'Krotoszyn',
    'Jarocin',
    'Lubraniec',
    'Kluczbork',
    'Osięciny',
    'Władysławowo',
    'Cekcyn',
    'Tarnobrzeg',
    'Olkusz',
    'Serock',
    'Odolin',
    'Czechowice-Dziedzice',
    'Meszna',
    'Legionowo',
    'Piekoszow',
    'Swoboda',
    'Wilkowisko',
    'Kolbudy',
    'Bytów',
    'Aleksandrów Kujawski',
    'Sadlno',
    'Chełmża',
    'Karczew',
    'Błonie',
    'Jozefoslaw',
    'Zalubice Stare',
    'Kołbiel',
    'Jutrosin',
    'Ustron',
    'Wodzisław Śląski',
    'Strumien',
    'Lubon',
    'Łapy',
    'Blachownia',
    'Ziebice',
    'Jaworzno',
    'Pewel Mala',
    'Naklo',
    'Będzin',
    'Miasteczko Slaskie',
    'Weglowice',
    'Koszecin',
    'MaryLka',
    'Zgierz',
    'Skawina',
    'Pruszcz Gdanski',
    'Tluczan',
    'Podegrodzie',
    'Wegrzce Wielkie',
    'Stary Sacz',
    'Olawa',
    'Naleczow',
    'Piaseczno',
    'Kozy',
    'Nowa',
    'Żagań',
    'Gubin',
    'Luban',
    'Kowary',
    'Piechowice',
    'Bolesławiec',
    'Jawor',
    'Pieszkow',
    'Bystrzyca Klodzka',
    'Elblag',
    'Swiebodzin',
    'Borek Strzelinski',
    'Cieszyn',
    'Aleksandrów Łódzki',
    'Rzeszotary',
    'Ozorkow',
    'Prochowice',
    'Kłodzko',
    'Szymanow',
    'Kunice',
    'Zary',
    'Sieniawa Zarska',
    'Lipinki Luzyckie',
    'Niwica',
    'Sobotka',
    'Pietrzykowice',
    'Środa Śląska',
    'Mary',
    'Jugow',
    'Bogatynia',
    'Miedzylesie',
    'Zalesie Górne',
    'Pisz',
    'Hrubieszów',
    'Haczow',
    'Nadarzyn',
    'Chojnice',
    'Ostrog',
    'Racibórz',
    'Wasilkow',
    'Węgrów',
    'Zmigrod',
    'Tczew',
    'Sokółka',
    'Siemiatycze',
    'Zabłudów',
    'Mazancowice',
    'Sierpc',
    'Łomża',
    'Morąg',
    'Wagrowiec',
    'Dzierzoniow',
    'Nowogard',
    'Choszczno',
    'Gmina Jędrzejów',
    'Ustka',
    'Gmina Świecie',
    'Łęczyca',
    'Chojnów',
    'Frysztak',
    'Twardawa',
    'Lubomia',
    'Smigiel',
    'Andrespol',
    'Oksywie',
    'Siechnice',
    'Lutynia',
    'Rzuchowa',
    'Radzanowo',
    'Pyrzyce',
    'Sompolno',
    'Żory',
    'Białogard',
    'Swidwin',
    'Karlino',
    'Zaczernie',
    'Korczyna',
    'Strykow',
    'Katy',
    'Przemysl',
    'Makow Mazowiecki',
    'Wielen Zaobrzanski',
    'Skierniewice',
    'Chmielow',
    'Polajewo',
    'Bukowice',
    'Zaniemysl',
    'Gryfice',
    'Skorzewo',
    'Sosnie',
    'Kuznica Czarnkowska',
    'Mikstat',
    'Trzciel',
    'Przezmierowo',
    'Szczaniec',
    'Wolsztyn',
    'Gmina Lipno',
    'Pogorzela',
    'Zalakowo',
    'Służewo',
    'Stargard Szczeciński',
    'Trzebiatów',
    'Wolin',
    'Mrowino',
    'Papowo',
    'Mielno',
    'Kostrzyn',
    'Steszew',
    'Gozdnica',
    'Gnuszyn',
    'Babimost',
    'Czerwonak',
    'Kamien Pomorski',
    'Biesiekierz',
    'Swietno',
    'Rosnowko',
    'Klenica',
    'Zerkow',
    'Budzyn',
    'Wilkanowo',
    'Iwiczna',
    'Zosin',
    'Ostrowek',
    'Cmielow',
    'Sandomierz',
    'Rakow',
    'Wachock',
    'Nowa Deba',
    'Sidra',
    'Maslow',
    'Busko-Zdrój',
    'Michałowo',
    'Tykocin',
    'Bodzentyn',
    'Iwaniska',
    'Kazimierza Wielka',
    'Bejsce',
    'Nowa Slupia',
    'Deblin',
    'Ulez',
    'Suchedniow',
    'Mniow',
    'Lipnik',
    'Brańsk',
    'Chmielnik',
    'Gmina Gnojno',
    'Gmina Sadowie',
    'Jastkow',
    'Terespol',
    'Stopnica',
    'Staszow',
    'Baranow',
    'Krosno',
    'Narew',
    'Slawno B',
    'Boćki',
    'Gmina Wyszki',
    'Zagnansk',
    'Olsztynek',
    'Gmina Połaniec',
    'Humniska',
    'Choroszcz',
    'Kamien',
    'Wola Kopcowa',
    'Margonin',
    'Barwice',
    'Wolow',
    'Lubomierz',
    'Winsko',
    'Kamionki',
    'Stare Kurowo',
    'Osielsko',
    'Gizalki',
    'Gmina Dąbie',
    'Dzikowiec',
    'Stara Kamienica',
    'Miszkowice',
    'Czarny Bor',
    'Bogdaszowice',
    'Domaslaw',
    'Strzeszow',
    'Zloty Stok',
    'Milicz',
    'Drawsko Pomorskie',
    'Msciwojow',
    'Sepolno Krajenskie',
    'Gmina Bobrowo',
    'Brzeg',
    'Kudowa-Zdrój',
    'Gmina Bądkowo',
    'Przysiek',
    'Wystep',
    'Bochowo',
    'Gniewkowo',
    'Osiek',
    'Dabrowa Chelminska',
    'Waganiec',
    'Swiekatowo',
    'Ciechocin',
    'Kołobrzeg',
    'Kobierzyce',
    'Pilawa',
    'Żuromin',
    'Latowicz',
    'Beblo',
    'Gmina Chmielno',
    'Zblewo',
    'Mława',
    'Racula',
    'Smolnik',
    'Laska',
    'Pinczow',
    'Tolkmicko',
    'Lacko',
    'Szydlowo',
    'Przechlewo',
    'Supraśl',
    'Kielczow',
    'Balice',
    'Makow',
    'Lotyn',
    'Kotlin',
    'Stolno',
    'Sucha Beskidzka',
    'Hucisko Jawornickie',
    'Kobior',
    'Ledziny',
    'Rybno',
    'Milanówek',
    'Mogilany',
    'Rozprza',
    'Dziekanow Lesny',
    'Wolka',
    'Raciazek',
    'Dobra',
    'Staniatki',
    'Zagorow',
    'Pegow',
    'Trzebnica',
    'Zwolen',
    'Myslenice',
    'Rzgow Pierwszy',
    'Rawa Mazowiecka',
    'Regimin',
    'Tarczyn',
    'Pułtusk',
    'Ksieginice',
    'Golczewo',
    'Swieta Katarzyna',
    'Santok',
    'Wloszczowa',
    'Chylice',
    'Bralin',
    'Gmina Książ Wielkopolski',
    'Targanice',
    'Sztum',
    'Kostrzyn nad Odra',
    'Czluchow',
    'Radzionkow',
    'Wola Krzysztoporska',
    'Pionki',
    'Brzostowka',
    'Oborniki',
    'Gmina Stąporków',
    'Turobin',
    'Cwiklice',
    'Końskowola',
    'Jemielnica',
    'Dobrodzien',
    'Lidzbark Warmiński',
    'Mszana Dolna',
    'Andrychow',
    'Ruda',
    'Nieporęt',
    'Gizyce',
    'Tyszowce',
    'Krzyszkowice',
    'Grojec',
    'Scinawa Nyska',
    'Kamieniec',
    'Otoki',
    'Ilowo',
    'Kowal',
    'Bielsk Podlaski',
    'Wschowa',
    'Krosno Odrzanskie',
    'Dukla',
    'Bazanowka',
    'Gogolin',
    'Złotoryja',
    'Bielkowo',
    'Gołdap',
    'Tuchola',
    'Brzeg Dolny',
    'Opoczno',
    'Kowiesy',
    'Jedlicze',
    'Karpacz',
    'Gronowo Elblaskie',
    'Zrecin',
    'Kętrzyn',
    'Niemcz',
    'Wylatowo',
    'Krzepice',
    'Żychlin',
    'Zdzieszowice',
    'Szczucin',
    'Debno',
    'Wisla',
    'Kazimierz Biskupi',
    'Kolbuszowa',
    'Skarszewy',
    'Wlodawa',
    'Dygowo',
    'Lublewo',
    'Rogow',
    'Tymowa',
    'Ostrow',
    'Dziwnow',
    'Chelmsko Slaskie',
    'Bolewice',
    'Sycow',
    'Miroslaw',
    'Witaszyce',
    'Ropczyce',
    'Lubanie',
    'Przeclaw',
    'Rewa',
    'Nidzica',
    'Orneta',
    'Rzepin',
    'Barlinek',
    'Pszczyna',
    'Stara',
    'Ksiazenice',
    'Kocmyrzow',
    'Stryszow',
    'Augustów',
    'Mosty',
    'Labiszyn',
    'Gmina Moszczenica',
    'Krokowa',
    'Zegrze Pomorskie',
    'Prudnik',
    'Konarzyce',
    'Popowice',
    'Nowy Dwor',
    'Krzyki-Partynice',
    'Lobez',
    'Kowalew',
    'Działdowo',
    'Piaski',
    'Grodki',
    'Rokietnica',
    'Lubnice',
    'Mialy',
    'Chelmek',
    'Kliniska',
    'Brodnica',
    'Krepa Kaszubska',
    'Wicko',
    'Konczyce Male',
    'Kozmin Wielkopolski',
    'Zebrzydowice',
    'Jelesnia',
    'Susz',
    'Cybinka',
    'Gluszyca',
    'Borkowice',
    'Iwla',
    'Kozy',
    'Gora Pulawska',
    'Stary Garbow',
    'Jablonna',
    'Pobiedna',
    'Chocianow',
    'Pajeczno',
    'Rożental',
    'Nowy Targ',
    'Dobra',
    'Olesno',
    'Sulejow',
    'Lubawa',
    'Dzwierzuty',
    'Blizne',
    'Kopki',
    'Rawicz',
    'Bystra',
    'Katy',
    'Jordanow',
    'Czernichow',
    'Pruchna',
    'Jawiszowice',
    'Stadla',
    'Walim',
    'Gmina Miękinia',
    'Rydułtowy',
    'Milkowice',
    'Poczesna',
    'Mierzecice',
    'Klobuck',
    'Raszowka',
    'Wieloglowy',
    'Koniusza',
    'Wambierzyce',
    'Sokolowsko',
    'Ostroszowice',
    'Gmina Borów',
    'Pila Koscielecka',
    'Piasek',
    'Paniowki',
    'Nowogrodziec',
    'Boguszów-Gorce',
    'Bobrowniki',
    'Trabki',
    'Wilczyn',
    'Toszek',
    'Kedzierzyn-Kozle',
    'Luborzyca',
    'Klikuszowa',
    'Kalwaria Zebrzydowska',
    'Boleslaw',
    'Wisniowa',
    'Orzesze',
    'Karniowice',
    'Suloszowa',
    'Niepolomice',
    'Malczyce',
    'Jankowice',
    'Wrzosowa',
    'Krośniewice',
    'Zelow',
    'Pyskowice',
    'Swierklany',
    'Slemien',
    'Koniakow',
    'Krzyzanowice',
    'Myszkow',
    'Jaroszow',
    'Niemcza',
    'Bestwinka',
    'Glogowek',
    'Glucholazy',
    'Rogow',
    'Stare Bogaczowice',
    'Sieniawka',
    'Pilawa Gorna',
    'Jastrzebie',
    'Bestwina',
    'Lipowa',
    'Koscielisko',
    'Biala',
    'Kamienica Polska',
    'Koluszki',
    'Dobron',
    'Trzebinia',
    'Ogrodzieniec',
    'Czaniec',
    'Jaroszowiec',
    'Wola Filipowska',
    'Belk',
    'Katy Wroclawskie',
    'Korfantow',
    'Gdow',
    'Stryszawa',
    'Rudy',
    'Skala',
    'Polanka Wielka',
    'Radlin',
    'Chrzesne',
    'Grodzisk',
    'Chotomow',
    'Nowe Miasto nad Pilica',
    'Laskarzew',
    'Łosice',
    'Przasnysz',
    'Lancut',
    'Trzciana',
    'Kurylowka',
    'Czudec',
    'Kanczuga',
    'Rudnik nad Sanem',
    'Zurawica',
    'Rakszawa',
    'Majdan Krolewski',
    'Iława',
    'Kuligow',
    'Nacpolsk',
    'Stare Babice',
    'Wyszogrod',
    'Lesnica',
    'Szadek',
    'Gmina Lutomiersk',
    'Kolonowskie',
    'Barglowka',
    'Bojszow',
    'Nowy Wisnicz',
    'Rybna',
    'Czarny Dunajec',
    'Brzeszcze',
    'Gromiec',
    'Barcice',
    'Slomniki',
    'Piekielnik',
    'Brzezinka',
    'Chrzan',
    'Tegoborze',
    'Kukow',
    'Zalesie',
    'Siemkowice',
    'Sidzina',
    'Bialy Dunajec',
    'Kojszowka',
    'Gorzyce',
    'Wymiarki',
    'Prabuty',
    'Szczawno',
    'Swidnica',
    'Dabrowa Biskupia',
    'Lysomice',
    'Kcynia',
    'Rogowo',
    'Komorsk',
    'Tuczno',
    'Miedzyzdroje',
    'Bojano',
    'Straszyn',
    'Kolodziejewo',
    'Gmina Sierakowice',
    'Ilowa',
    'Dargoslaw',
    'Sarbinowo',
    'Jeziora Wielkie',
    'Zbiczno',
    'Baruchowo',
    'Pieszyce',
    'Gmina Bolków',
    'Marcinowice',
    'Krasnik',
    'Grajewo',
    'Checiny',
    'Ryn',
    'Szyldak',
    'Bisztynek',
    'Dabrowno',
    'Dywity',
    'Dylewo',
    'Kurów',
    'Grunwald',
    'Bartoszyce',
    'Zabrowo',
    'Stawiguda',
    'Kozienice',
    'Drozdowo',
    'Trzesniow',
    'Swiatki',
    'Samin',
    'Dobre Miasto',
    'Stare Juchy',
    'Zalewo',
    'Trzydnik Duzy',
    'Prawiedniki',
    'Garbow',
    'Milowka',
    'Zablocie',
    'Dobroszyce',
    'Gmina Puszcza Mariańska',
    'Falkow',
    'Bedkow',
    'Radomsko',
    'Kamien Slaski',
    'Nieborowice',
    'Slawkow',
    'Wilkow',
    'Roztoka',
    'Zembrzyce',
    'Ciezkowice',
    'Hecznarowice',
    'Szklarska Poręba',
    'Zagrodno',
    'Krosnowice',
    'Krasiejow',
    'Lubawka',
    'Twardogora',
    'Zorawina',
    'Prusice',
    'Smardzew',
    'Domaniewice',
    'Brudzice',
    'Zduny',
    'Tworog',
    'Stroze',
    'Klucze',
    'Alwernia',
    'Suchy Dab',
    'Rokitki',
    'Sztutowo',
    'Ostaszewo',
    'Drewnica',
    'Godziszewo',
    'Pelplin',
    'Drezdenko',
    'Dobrzany',
    'Trzcinsko',
    'Klecko',
    'Ostrowite',
    'Pszczew',
    'Kicin',
    'Legowo',
    'Barczewo',
    'Swarzewo',
    'Leba',
    'Pasłęk',
    'Komorniki',
    'Kartuzy',
    'Raczki',
    'Rokitnica',
    'Bukowina Tatrzanska',
    'Myslachowice',
    'Siennica Nadolna',
    'Radzyn Podlaski',
    'Gorki Wielkie',
    'Brenna',
    'Sianow',
    'Smardzowice',
    'Turek',
    'Gmina Przywidz',
    'Zukowo',
    'Opatow',
    'Kunow',
    'Nowy Dwor Gdanski',
    'Milcza',
    'Krzywin',
    'Kazmierz',
    'Skopanie',
    'Kwaczala',
    'Labowa',
    'Orzysz',
    'Lubochnia',
    'Frombork',
    'Gmina Kolno',
    'Kowalewo',
    'Rejowiec',
    'Brzesko',
    'Dabrowa',
    'Sieroszowice',
    'Zawonia',
    'Wojkowice Koscielne',
    'Gowarzewo',
    'Wreczyca Wielka',
    'Gmina Gołuchów',
    'Siekierczyn',
    'Dziegielow',
    'Wrzosowka',
    'Oleszno',
    'Swarorzyn',
    'Falkowo',
    'Niechanowo',
    'Kikół',
    'Wielgie',
    'Radomin',
    'Otyn',
    'Kaczkowo',
    'Osno Lubuskie',
    'Swiniary',
    'Krzyz Wielkopolski',
    'Rydzyna',
    'Pepowo',
    'Baranowko',
    'Czapury',
    'Klodawa',
    'Czempin',
    'Piaski',
    'Gmina Nowogród Bobrzański',
    'Wielichowo',
    'Gmina Babiak',
    'Kwilcz',
    'Szczytniki',
    'Gmina Szubin',
    'Lisewo',
    'Gruszczyn',
    'Karnice',
    'Darnowo',
    'Skrzetusz',
    'Duszniki-Zdroj',
    'Gmina Kostomłoty',
    'Kielcz',
    'Pruszcz Pomorski',
    'Manowo',
    'Siemirowice',
    'Damnica',
    'Czernica',
    'Tanowo',
    'Stare Czarnowo',
    'Rewal',
    'Stanowice',
    'Miedzyborz',
    'Lomnica',
    'Laka',
    'Konopiska',
    'Poraj',
    'Glubczyce',
    'Rzeczyce',
    'Ozimek',
    'Jasienica',
    'Lekawica',
    'Huta Dlutowska',
    'Dobieszowice',
    'Ligota',
    'Niemodlin',
    'Kraszew',
    'Czekanow',
    'Brudzowice',
    'Kocierzew Poludniowy',
    'Karchowice',
    'Wierzchowisko',
    'Torzym',
    'Chełmce',
    'Lipno',
    'Kleszczewo',
    'Gmina Kiszkowo',
    'Slone',
    'Golanice',
    'Kowalewo Pomorskie',
    'Witnica',
    'Niegoslawice',
    'Szadlowice',
    'Pniewy',
    'Zaparcin',
    'Paliszewo',
    'Melno',
    'Juchnowiec Koscielny',
    'Gmina Ciechanowiec',
    'Grabowka',
    'Krypno',
    'Gmina Jedlińsk',
    'Knyszyn',
    'Osieck',
    'Ciechanów',
    'Niedrzwica Duza',
    'Bochotnica',
    'Moszczanka',
    'Wawolnica',
    'Lipiny',
    'Klikawa',
    'Wola Baranowska',
    'Wadowice Gorne',
    'Wielbark',
    'Gorzkow',
    'Bychawa',
    'Jeziorany',
    'Siennica',
    'Pilawa',
    'Ojrzen',
    'Duczki',
    'Gąsocin',
    'Dzierzgon',
    'Miloradz',
    'Koleczkowo',
    'Milobadz',
    'Liniewo',
    'Stary Dzierzgoń',
    'Gmina Poddębice',
    'Gmina Sulęcin',
    'Nowe Miasto nad Warta',
    'Gołubie',
    'Sulmin',
    'Slawoszyno',
    'Mierzeszyn',
    'Otomin',
    'Choczewo',
    'Kobylnica',
    'Szlachta',
    'Rakowiec',
    'Stankowo',
    'Majewo',
    'Borkowo',
    'Bojanowo',
    'Lipusz',
    'Sobowidz',
    'Pniewy',
    'Lubichowo',
    'Tenczyn',
    'Nowa Biala',
    'Szyce',
    'Michalowice',
    'Gaworzyce',
    'Iwanowice',
    'Konstantynow',
    'Konstantynow Lodzki',
    'Olszyna',
    'Lesna',
    'Lądek Zdrój',
    'Rabka-Zdroj',
    'Krapkowice',
    'Mucharz',
    'Szewce',
    'Wozniki',
    'Dlugopole-Zdroj',
    'Kruszyn',
    'Tymbark',
    'Skarzysko-Kamienna',
    'Peczniew',
    'Gluchow',
    'Gryfow Slaski',
    'Gmina Zakliczyn',
    'Bibice',
    'Gorzyczki',
    'Kalety',
    'Biskupice',
    'Mirkow',
    'Szczyrk',
    'Jarkowice',
    'Wegliniec',
    'Mokrsko',
    'Zawadzkie',
    'Ciasna',
    'Uciechow',
    'Pielgrzymka',
    'Szczercow',
    'Garki',
    'Klecza Dolna',
    'Nisko',
    'Kroscienko Wyzne',
    'Lezajsk',
    'Gmina Księżpol',
    'Nielisz',
    'Ostrow Lubelski',
    'Grodzisko Dolne',
    'Milanow',
    'Nowo-Aleksandrowo',
    'Jezowe',
    'Pysznica',
    'Susiec',
    'Lubycza Krolewska',
    'Szczuczyn',
    'Otrebusy',
    'Slupno',
    'Frydrychowice',
    'Kuznia',
    'Dankowice',
    'Dzialoszyn',
    'Marklowice',
    'Rogoznik',
    'Bielany',
    'Bolechowice',
    'Minoga',
    'Chybie',
    'Popow',
    'Jablonka',
    'Zawoja',
    'Biecz',
    'Byczyna',
    'Gorzkowice',
    'Druzbice',
    'Trzebunia',
    'Gora Siewierska',
    'Skrbensko',
    'Wisla Wielka',
    'Smolec',
    'Konradowka',
    'Bielany Wroclawskie',
    'Drzewica',
    'Konstantyn',
    'Namyslow',
    'Janow',
    'Szczejkowice',
    'Sanniki',
    'Rajszew',
    'Jasieniec',
    'Ustanow',
    'Pomiechowek',
    'Kazimierz Dolny',
    'Czarna',
    'Nasielsk',
    'Ryki',
    'Staroźreby',
    'Bukowsko',
    'Magnuszew',
    'Strzyze',
    'Dunaj',
    'Trzeszczyn',
    'Brzeznica',
    'Belsznica',
    'Kasinka',
    'Szczecinek',
    'Baran',
    'Zielone',
    'Makowiec',
    'Praca',
    'Kielno',
    'Rogozno',
    'Szreniawa',
    'Gmina Władysławów',
    'Gniewino',
    'Luzino',
    'Gościcino',
    'Karlikowo',
    'Dobrzyca',
    'Stara Lubianka',
    'Pila',
    'Dolaszewo',
    'Turowiec',
    'Grzegorz',
    'Suszec',
    'Szczurowa',
    'Dulcza Wielka',
    'Liw',
    'Ostrow Mazowiecka',
    'Szczepanow',
    'Borzecin',
    'Wysokie Mazowieckie',
    'Szepietowo',
    'Śniadowo',
    'Rudzica',
    'Kokoszkowy',
    'Rychwal',
    'Ostrzeszow',
    'Golkowice',
    'Zelazkow',
    'Kępno',
    'Brok',
    'Chwaszczyno',
    'Bierun Nowy',
    'Odolanow',
    'Topola Mala',
    'Rebkow',
    'Cieszkow',
    'Zduny',
    'Strzelce Krajenskie',
    'Polomia',
    'Miejska Gorka',
    'Strzelno',
    'Goleszow',
    'Rozanka',
    'Lochow',
    'Henrykow',
    'Pietrowice Wielkie',
    'Gorzyce',
    'Glowienka',
    'Reszel',
    'Olecko',
    'Dabrowskie',
    'Zabor',
    'Kozuchow',
    'Czosnow',
    'Zielona',
    'Wilga',
    'Zloczew',
    'Zagosciniec',
    'Podlasie',
    'Zerniki',
    'Sulistrowice',
    'Chyliczki',
    'Kleczany',
    'Międzyrzec Podlaski',
    'Bratkowice',
    'Wolbrom',
    'Radlow',
    'Wawel',
    'Rozgarty',
    'Osiek',
    'Pawlowice',
    'Pszow',
    'Zagorzyce',
    'Iwierzyce',
    'Nawodna',
    'Warzymice',
    'Darłowo',
    'Ostrowy',
    'Miłki',
    'Węgorzewo',
    'Prabuty',
    'Stare Miasto',
    'Brzozow',
    'Kamien',
    'Stoczek',
    'Hajnówka',
    'Leczna',
    'Leszkowice',
    'Strzyzow',
    'Sulmierzyce',
    'Karsko',
    'Pustkow',
    'Zyrakow',
    'Brzostek',
    'Radomysl Wielki',
    'Brzeznica',
    'Zarowka',
    'Rzemien',
    'Plesna',
    'Niedomice',
    'Bogumilowice',
    'Borne Sulinowo',
    'Polczyn-Zdroj',
    'Czaplinek',
    'Szostka',
    'Kuzniki',
    'Cianowice Duze',
    'Zbroslawice',
    'Zernica',
    'Biezanow-Prokocim',
    'Prokocim',
    'Suwaki',
    'Jabłonowo Pomorskie',
    'Krzywcza',
    'Zabrzeg',
    'Zglobien',
    'Krzeszow',
    'Mscice',
    'Rudna Mala',
    'Zielonki-Wies',
    'Bramki',
    'Makow Podhalanski',
    'Czernica',
    'Nowa Wies Elcka',
    'Gmina Osjaków',
    'Mieczewo',
    'Kety',
    'Klaj',
    'Czerniewice',
    'Raczka',
    'Serwis',
    'Gomunice',
    'Rusiec',
    'Marta',
    'Debe Wielkie',
    'Olszowice',
    'Kowalkow',
    'Banino',
    'Skrzyszow',
    'Lachowice',
    'Rumianek',
    'Kobylanka',
    'Radziechowy',
    'Giby',
    'Bukowno',
    'Modrze',
    'Mosina',
    'Miastko',
    'Keblowo',
    'Wielowies',
    'Opatowek',
    'Strzebin',
    'Stary Zamosc',
    'Labunie',
    'Skomlin',
    'Warzno',
    'Klimontow',
    'Lochowo',
    'Debrzno Wies',
    'Rudziniec',
    'Lany',
    'Ujazd',
    'Trzyciaz',
    'Pilica',
    'Bydlin',
    'Nebrowo Wielkie',
    'Naprawa',
    'Szydlowiec',
    'Bielcza',
    'Arkadia',
    'Lubsza',
    'Babienica',
    'Miedzybrodzie Zywieckie',
    'Ryglice',
    'Sieniawa',
    'Przystajn',
    'Przemyslaw',
    'Bukowiec Opoczynski',
    'Maszewo',
    'Orzel',
    'Dabrowa Bialostocka',
    'Jozefatow',
    'Bolimów',
    'Rzasnia',
    'Rudka',
    'Buczkowice',
    'Pomorskie',
    'Gieraltowice',
    'Chocznia',
    'Obsza',
    'Sobienie Jeziory',
    'Dobiesz',
    'Czersk',
    'Gora Kalwaria',
    'Bojadla',
    'Grzmiaca',
    'Ilza',
    'Siwek',
    'Strzeleczki',
    'Gorazdze',
    'Osieczna',
    'Harasiuki',
    'Liskow',
    'Strzebielino',
    'Gryfino',
    'Skorcz',
    'Grebocin',
    'Chorzele',
    'Kobiernice',
    'Korzenna',
    'Koronowo',
    'Wielopole Skrzynskie',
    'Przygodzice',
    'Sulmierzyce',
    'Opalenica',
    'Maslice',
    'Bobrza',
    'Dzielna',
    'Zarszyn',
    'Huta Dabrowa',
    'Cegłów',
    'Borowa',
    'Biała Rawska',
    'Kolonia Zawada',
    'Sochocin',
    'Staniszow',
    'Bialobrzegi',
    'Byslaw',
    'Pogodki',
    'Brynica',
    'Daleszyce',
    'Gloskow',
    'Strachówka',
    'Wola Radlowska',
    'Golotczyzna',
    'Osiek Jasielski',
    'Baniocha',
    'Czarny Las',
    'Radziejowice',
    'Wola Rasztowska',
    'Rebowo',
    'Zglobice',
    'Borki',
    'Kielpin',
    'Nowy Staw',
    'Bielawy',
    'Janow Lubelski',
    'Leszno',
    'Koteze',
    'Kurdwanow',
    'Nowa Huta',
    'Czyzyny',
    'Podgorze',
    'Goszczyn',
    'Wisznice',
    'Koszyce',
    'Koszyce',
    'Wieliszew',
    'Miedzna',
    'Czarne',
    'Dopiewiec',
    'Lusowko',
    'Sady',
    'Paledzie',
    'Biskupice',
    'Przemet',
    'Rogalinek',
    'Gostynin',
    'Skorzec',
    'Kusnierz',
    'Gąbin',
    'Kurzetnik',
    'Zabno',
    'Dobroszyce',
    'Olejnica',
    'Czastary',
    'Bierutow',
    'Zawada',
    'Wiazow',
    'Kurek',
    'Bojszowy',
    'Subkowy',
    'Myszyniec',
    'Juszkowo',
    'Olszany',
    'Puszczew',
    'Zorawia',
    'Katarzyna',
    'Braszewice',
    'Tomaszow Lubelski',
    'Wieckowice',
    'Staw',
    'Pisarzowice',
    'Trojanow',
    'Baborow',
    'Lobzenica',
    'Grabiec',
    'Jasienica Dolna',
    'Stary Broniszew',
    'Przyrow',
    'Charzykowy',
    'Pastuchow',
    'Mackowice',
    'Krynica-Zdroj',
    'Tylicz',
    'Morawica',
    'Nowy Swietow',
    'Chrzowice',
    'Lelow',
    'Tuchow',
    'Lipnica Murowana',
    'Wysoka',
    'Pawlowice',
    'Czarna Białostocka',
    'Radziejów',
    'Scinawa Mala',
    'Jastrzebie',
    'Czarnozyly',
    'Baboszewo',
    'Drohiczyn',
    'Iwonicz-Zdroj',
    'Kosina',
    'Brzyska Wola',
    'Bircza',
    'Podleze',
    'Zator',
    'Tomaszowice',
    'Wilczeta',
    'Swiecie nad Osa',
    'Wapielsk',
    'Bledzew',
    'Trzemeszno Lubuskie',
    'Rymanow',
    'Odrzykon',
    'Roczyny',
    'Kolno',
    'Grabow nad Prosna',
    'Zaleszany',
    'Turbia',
    'Milejow',
    'Gmina Ludwin',
    'Kaczor',
    'Kochcice',
    'Brzeziny',
    'Frank',
    'Imielin',
    'Cmolas',
    'Wolborz',
    'Zawidow',
    'Gieraltowice',
    'Stronie Slaskie',
    'Lewin Brzeski',
    'Podgorne',
    'Gniew',
    'Laziska Gorne',
    'Nowe Chechlo',
    'Soczewka',
    'Tarnow Opolski',
    'Gmina Konarzyny',
    'Swierk',
    'Reczno',
    'Kamien Krajenski',
    'Zgorsko',
    'Polskie',
    'Boguslaw',
    'Ustrzyki Dolne',
    'Chojna',
    'Towarzystwo',
    'Borowe',
    'Sobotka',
    'Lajsk',
    'Pokrzywnica',
    'Prosna',
    'Supienie',
    'Pilchowice',
    'Unieszewo',
    'Mszana',
    'Bory',
    'Gaszowice',
    'Syrynia',
    'Bogunice',
    'Żelechów',
    'Siewierz',
    'Skierdy',
    'Modlniczka',
    'Zgorzala',
    'Burzenin',
    'Wasniow',
    'Radoszyce',
    'Nowy Korczyn',
    'Pacanow',
    'Grabki Duze',
    'Gmina Szydłów',
    'Strawczyn',
    'Kedzierzyn',
    'Bolkow',
    'Karpiska',
    'Wieruszow',
    'Laziska',
    'Tecza',
    'Biskupice Oloboczne',
    'Biskupin',
    'Hornowek',
    'Bohdan',
    'Debowiec',
    'Medyka',
    'Pcim',
    'Lubien',
    'Lomianki Dolne',
    'Sycewice',
    'Bielawa',
    'Niewierz',
    'Glowna',
    'Winnica',
    'Krasocin',
    'Blizyn',
    'Bieliny',
    'Jedlnia-Letnisko',
    'Olimpia',
    'Brześć Kujawski',
    'Lipnica Wielka',
    'Mieroszow',
    'Jastrowie',
    'Lubaczow',
    'Krynica',
    'Gmina Opole Lubelskie',
    'Siedlec',
    'Ślesin',
    'Kozminek',
    'Zaluski',
    'Paulina',
    'Kleszczow',
    'Miechow',
    'Wlodzimierz',
    'Zebowice',
    'Chrzastowka',
    'Szebnie',
    'Zalubice Nowe',
    'Pedziwiatry',
    'Medynia Glogowska',
    'Nienadowka',
    'Dziechciniec',
    'Glogow Malopolski',
    'Krasne',
    'Kozieglowy',
    'Izabelin',
    'Keblowo',
    'Gora',
    'Gowino',
    'Moryn',
    'Kijewo Krolewskie',
    'Lesko',
    'Domaradz',
    'Suraż',
    'Radzanow',
    'Malogoszcz',
    'Mikolajki',
    'Mirsk',
    'Trzemeszno',
    'Pakość',
    'Orchowo',
    'Gębice',
    'Izabela',
    'Dobromierz',
    'Wiorek',
    'Brzoza Krolewska',
    'Narzym',
    'Gmina Potęgowo',
    'Smolnica',
    'Grodkow',
    'Konarzewo',
    'Muszyna',
    'Cekow',
    'Trzemesnia',
    'Mrocza',
    'Grybow',
    'Oltarzew',
    'Drawno',
    'Tylmanowa',
    'Zalasewo',
    'Dobrzen Wielki'
  ],
  Ireland: [
    'Roscommon',
    'Roscrea',
    'Mountrath',
    'Dublin',
    'Porterstown',
    'Carrigaline',
    'Cork',
    'Ballina',
    'Manorhamilton',
    'Kells',
    'Listowel',
    'Lisselton',
    'Carrick',
    'Galway',
    'Tipperary',
    'Carlow',
    'Abbeyleix',
    'Mullinavat',
    'Maynooth',
    'Tallaght',
    'Templeogue',
    'Swords',
    'Bray',
    'Cabinteely',
    'Clondalkin',
    'Ballyfermot',
    'Artane',
    'Malahide',
    'Limerick',
    'Saggart',
    'Prosperous',
    'Castleknock',
    'Clonsilla',
    'Kilkenny',
    'Droichead Nua',
    'Kildare',
    'Celbridge',
    'Lucan',
    'Blackrock',
    'Dundrum',
    'Wicklow',
    'Rathgar',
    'Stillorgan',
    'Mullingar',
    'Clonmel',
    'Sandyford',
    'Ashbourne',
    'Athlone',
    'Marino',
    'Dalkey',
    'Finglas',
    'Glasnevin',
    'Naas',
    'Blessington',
    'Waterford',
    'Leixlip',
    'Walkinstown',
    'Mayo',
    'Terenure',
    'Clane',
    'Killarney',
    'Trim',
    'Seafield',
    'Mount Merrion',
    'Glen',
    'Nenagh',
    'Shannon',
    'Kilbride Cross Roads',
    'Killybegs',
    'Sligo',
    'Athboy',
    'Crosshaven',
    'Drogheda',
    'Youghal',
    'Greystones',
    'Ballsbridge',
    'Foxrock',
    'Shankill',
    'Killiney',
    'Abbeyfeale',
    'Dunshaughlin',
    'Navan',
    'Ballincollig',
    'Ardee',
    'Ballyroe',
    'Sandymount',
    'Slieve',
    'Macroom',
    'Wexford',
    'Blanchardstown',
    'Milltown',
    'Dundalk',
    'Athy',
    'Kilrush',
    'Dungarvan',
    'Gorey',
    'Letterkenny',
    'Ballymahon',
    'Island',
    'Stepaside',
    'Ballintober',
    'Firhouse',
    'Cabra',
    'Donabate',
    'Coolock',
    'Rathfarnham',
    'Thurles',
    'Kilcock',
    'Sutton',
    'Newport',
    'Monaghan',
    'Hospital',
    'Bettystown',
    'River',
    'Tralee',
    'Ballybrit',
    'Cobh',
    'Enniskerry',
    'Duleek',
    'Rush',
    'Lusk',
    'Mitchelstown',
    'Kanturk',
    'Balbriggan',
    'Rathmolyon',
    'Crumlin',
    'Clontarf',
    'Kingswood',
    'Kinsale',
    'Dunboyne',
    'Caher',
    'Broadford',
    'Kilmichael',
    'Berrings',
    'Ballineen',
    'Tyrrellspass',
    'Donegal',
    'Bundoran',
    'Glenties',
    'Killygordon',
    'Athenry',
    'Moycullen',
    'Mallow',
    'Castlebar',
    'Ratoath',
    'Bandon',
    'Douglas',
    'Glengarriff',
    'Ballygarvan',
    'Clonakilty',
    'Ballyphilip',
    'Boherbue',
    'Kilfinane',
    'Fermoy',
    'Carbury',
    'Monasterevin',
    'Monkstown',
    'Clogherhead',
    'Enfield',
    'Edenderry',
    'Kildalkey',
    'Castlerea',
    'Stradbally',
    'Skerries',
    'Garristown',
    'Santry',
    'Ballybrack',
    'Dún Laoghaire',
    'Leopardstown',
    'Ballyhooly',
    'Claregalway',
    'Dunlavin',
    'Straffan',
    'Summerhill',
    'Ballymount',
    'Courtown',
    'Loughrea',
    'Enniscorthy',
    'Portarlington',
    'Buncrana',
    'Cashel',
    'Carrickmacross',
    'Geevagh',
    'Killala',
    'Carrick on Shannon',
    'Saint Mullins',
    'Carrickmines',
    'Killorglin',
    'Passage West',
    'Ennis',
    'Oysterhaven',
    'Cavan',
    'Virginia',
    'Clones',
    'Bailieborough',
    'Oranmore',
    'Shrule',
    'Callan',
    'Tullow',
    'Carnew',
    'New Ross',
    'Castlebridge',
    'Glenealy',
    'Carrick-on-Suir',
    'Ballymote',
    'Longford',
    'Ballivor',
    'Louth',
    'Castleblayney',
    'Birr',
    'Clarecastle',
    'Tullamore',
    'Ballon',
    'Kingscourt',
    'Coachford',
    'Clonee',
    'Kilmainham',
    'County Wexford',
    'Balgriffin',
    'Kiltamagh',
    'Foxford',
    'Westport',
    'Claremorris',
    'Swinford',
    'Foynes',
    'Sixmilebridge',
    'Newcastle West',
    'Grange',
    'Dunleer',
    'Longwood',
    'Killaloe',
    'Feakle',
    'Baldoyle',
    'Windy Arbour',
    'Doughiska',
    'Thomastown',
    'Raheny',
    'Dundrum',
    'Rathowen',
    'Patrickswell',
    'Delgany',
    'Ballyvaghan',
    'Doolin',
    'Killurin',
    'Midleton',
    'Ringaskiddy',
    'Skibbereen',
    'Ballinadee',
    'Kinvarra',
    'Oughterard',
    'Ballinrobe',
    'Tuam',
    'Headford',
    'Spiddal',
    'Gort',
    'Williamstown',
    'Ballinasloe',
    'Cahersiveen',
    'Bantry',
    'Crookhaven',
    'Portumna',
    'County Galway',
    'Kilmore',
    'Leamlara',
    'Bagenalstown',
    'Ballyragget',
    'Carraroe',
    'Killinick',
    'Crusheen',
    'Glanmire',
    'Arklow',
    'Kilcoole',
    'Kilcullen',
    'Ferbane',
    'Mornington',
    'Naul',
    'Donnybrook',
    'Portmarnock',
    'Ballycullen',
    'Sallins',
    'Irishtown',
    'Ballymun',
    'Jamestown',
    'Bodyke',
    'Rathcoole',
    'Rathmines',
    'Inchicore',
    'Kenmare',
    'Sallynoggin',
    'Carrigtohill',
    'Cross',
    'Castlemaine',
    'Newmarket'
  ],
  Croatia: [
    'Velika Gorica',
    'Zagreb',
    'Ivanec',
    'Core',
    'Labin',
    'Varaždin',
    'Split',
    'Glina',
    'Vinkovci',
    'Sinj',
    'Karlovac',
    'Rijeka',
    'Gornja Lomnica',
    'Šibenik',
    'Zlatar',
    'Novi Vinodolski',
    'Dubrovnik',
    'Cavtat',
    'Zadar',
    'Simuni',
    'Tar',
    'Rab',
    'Lupoglav',
    'Županja',
    'Đakovo',
    'Piskorevci',
    'Vukovar',
    'Brsadin',
    'Nustar',
    'Delnice',
    'Vrbnik',
    'Krk',
    'Lovran',
    'Petrcane',
    'Trogir',
    'Betina',
    'Dugo Selo',
    'Sisak',
    'Zapresic',
    'Oroslavje',
    'Matulji',
    'Poreč',
    'Makarska',
    'Brela',
    'Baska Voda',
    'Lekenik',
    'Strmec Samoborski',
    'Petrinja',
    'Galgovo',
    'Krapina',
    'Vrbovec',
    'Sesvete',
    'Popovaca',
    'Bestovje',
    'Nedelisce',
    'Rijeka',
    'Metković',
    'Mlini',
    'Postira',
    'Primosten',
    'Drniš',
    'Privlaka',
    'Darda',
    'Samobor',
    'Opatija',
    'Bibinje',
    'Podastrana',
    'Osijek',
    'Fuzine',
    'Orebic',
    'Rovinj',
    'Lukavec',
    'Pula',
    'Viskovci',
    'Podgajci Posavski',
    'Antunovac',
    'Cepin',
    'Silas',
    'Vardarac',
    'Solin',
    'Čakovec',
    'Kriz',
    'Lupoglav',
    'Kastel Gomilica',
    'Kastel Luksic',
    'Crikvenica',
    'Pazin',
    'Krsan',
    'Umag',
    'Medulin',
    'Icici',
    'Buje',
    'Sapjane',
    'Karojba',
    'Kostrena',
    'Fazana',
    'Cabuna',
    'Pakrac',
    'Bizovac',
    'Vojnic',
    'Ivanic-Grad',
    'Novo Cice',
    'Sveti Ivan Zelina',
    'Orle',
    'Bregana',
    'Donja Pusca',
    'Gornji Stupnik',
    'Bedekovcina',
    'Koprivnica',
    'Varazdinske Toplice',
    'Mursko Sredisce',
    'Vinica',
    'Desinic',
    'Prelog',
    'Pregrada',
    'Torcec',
    'Donji Kraljevec',
    'Novi Marof',
    'Senkovec',
    'Brdovec',
    'Zabok',
    'Strmec',
    'Kraljevec na Sutli',
    'Požega',
    'Nova Gradiska',
    'Belišće',
    'Okrug Gornji',
    'Cestica',
    'Vratisinec',
    'Lepoglava',
    'Tuzno',
    'Sokolovac',
    'Kastel Sucurac',
    'Bracevci',
    'Virovitica',
    'Orahovica',
    'Slavonski Brod',
    'Kutjevo',
    'Sice',
    'Vetovo',
    'Slatina',
    'Dalj',
    'Bjelovar',
    'Sesvetski Kraljevec',
    'Vrata',
    'Hreljin',
    'Rakitje',
    'Gornji Kriz',
    'Virje',
    'Splitska',
    'Baska',
    'Lapad',
    'Donja Zelina'
  ],
  'Bosnia and Herzegovina': [
    'Sarajevo',
    'Zenica',
    'Mostar',
    'Livno',
    'Ljubuski',
    'Lukavac',
    'Tuzla',
    'Jelah',
    'Bihać',
    'Vogosca',
    'Bijeljina',
    'Doboj',
    'Banja',
    'Donja Mahala',
    'Srpska',
    'Banja Luka',
    'Tesanj',
    'Breza',
    'Visoko',
    'Stup',
    'Lopare',
    'Prijedor',
    'Bosanska Dubica',
    'Zivinice',
    'Gracanica',
    'Core',
    'Brcko',
    'Bosanska Krupa',
    'Sanski Most',
    'Cazin',
    'Odzak',
    'Modrica',
    'Zepce',
    'Goražde',
    'Bugojno',
    'Jajce',
    'Travnik',
    'Novi Travnik',
    'Vitez',
    'Kiseljak',
    'Siroki Brijeg',
    'Ilidza'
  ],
  Estonia: [
    'Maardu',
    'Kuressaare',
    'Tallinn',
    'Laagri',
    'Põlva',
    'Võru',
    'Viimsi',
    'Tabasalu',
    'Keila',
    'Rakvere',
    'Sillamäe',
    'Kohtla-Järve',
    'Tartu',
    'Jõhvi',
    'Viljandi',
    'Pärnu',
    'Paide',
    'Türi',
    'Harku',
    'Saku',
    'Vara',
    'Alliku',
    'Kulna',
    'Kohtla',
    'Narva',
    'Tamsalu',
    'Aasmae',
    'Kiviõli',
    'Ahtma',
    'Toila',
    'Voka',
    'Kaarma',
    'Paldiski',
    'Loo',
    'Sindi',
    'Jüri',
    'Emmaste',
    'Aespa',
    'Peetri',
    'Jõgeva',
    'Haapsalu',
    'Lagedi',
    'Haabneeme',
    'Lubja',
    'Venevere',
    'Põltsamaa',
    'Vaida',
    'Ardu',
    'Puhja',
    'Otepaeae',
    'Antsla',
    'Palivere',
    'Aaviku',
    'Lepna',
    'Kehra',
    'Kuusalu',
    'Loksa',
    'Elva',
    'Valga',
    'Tabivere',
    'Saue',
    'Rapla',
    'Kohila',
    'Kiili',
    'Lahtse',
    'Muraste',
    'Somerpalu',
    'Tapa',
    'Kose',
    'Haage',
    'Jõelähtme Parish',
    'Ambla',
    'Kadrina',
    'Syare',
    'Harju',
    'Rakke',
    'Avinurme',
    'Halinga',
    'Kilingi-Nõmme',
    'Kärdla',
    'Paikuse'
  ],
  Latvia: [
    'Riga',
    'Centrs',
    'Salaspils',
    'Jelgava',
    'Ozolnieki',
    'Priedkalne',
    'Ventspils',
    'Liepāja',
    'Jūrmala',
    'Preiļi',
    'Aizkraukle',
    'Ropaži',
    'Tukums',
    'Ikšķile',
    'Daugavpils',
    'Agenskalns',
    'Rēzekne',
    'Cēsis',
    'Dobele',
    'Izvalta',
    'Liepa',
    'Valmiera',
    'Alūksne',
    'Pilsrundale',
    'Bauska',
    'Ogre',
    'Lēdmane',
    'Ķegums',
    'Ilguciems',
    'Saldus',
    'Kuldīga',
    'Jēkabpils',
    'Marupe',
    'Valka',
    'Balvi',
    'Strenči',
    'Madona',
    'Līvāni',
    'Zasa',
    'Stalgene',
    'Pure',
    'Aizpute',
    'Kandava',
    'Ķekava',
    'Carnikava',
    'Malta',
    'Zilupe',
    'Varakļāni',
    'Talsi',
    'Lapmezciems',
    'Smiltene',
    'Sigulda',
    'Mazsalaca',
    'Kolka',
    'Varsava',
    'Jaunolaine',
    'Adazi',
    'Ludza',
    'Olaine',
    'Baldone',
    'Limbaži',
    'Gulbene',
    'Sunākste',
    'Viesīte',
    'Malpils',
    'Brankas',
    'Durbe'
  ],
  'Hashemite Kingdom of Jordan': [
    'Amman',
    'Irbid',
    'Farah',
    'Sahab',
    'Madaba',
    'Salt',
    'Ar Ramtha'
  ],
  Kyrgyzstan: ['Bishkek'],
  Réunion: [
    'Saint-Joseph',
    'Le Tampon',
    'Saint-Andre',
    'Saint-Piorre',
    'Saint-Paul',
    'La Possession',
    'Le Port',
    'Le Petit Tampon',
    'Sainte-Suzanne',
    'Ravine des Cabris',
    'Saint-Gilles-les Bains',
    'Sainte-Clotilde',
    'Saint-Denis',
    'Sainte-Marie',
    'Petite-Ile',
    'La Riviere',
    'Les Avirons',
    'La Saline',
    'Etang-Sale les Bains',
    'Saint-Louis',
    'La Chaloupe Saint-Leu',
    'Entre-Deux',
    'Piton',
    'Saint-Benoit'
  ],
  'Isle of Man': [
    'Douglas',
    'Onchan',
    'Ballasalla',
    'Castletown',
    'Port Erin',
    'Ramsey',
    'Laxey',
    'Dalby',
    'Foxdale',
    'Peel',
    'Port Saint Mary',
    'Crosby',
    'Saint Johns'
  ],
  Libya: ['Tripoli', 'Benghazi', 'Misratah', 'Zliten', 'Sabha'],
  Luxembourg: [
    'Steinsel',
    'Bascharage',
    'Itzig',
    'Dudelange',
    'Helmsange',
    'Soleuvre',
    'Belvaux',
    'Bettembourg',
    'Luxembourg',
    'Hunsdorf',
    'Junglinster',
    'Bertrange',
    'Sandweiler',
    'Beggen',
    'Heffingen',
    'Strassen',
    'Contern',
    'Mersch',
    'Bereldange',
    'Alzingen',
    'Holzem',
    'Leudelange',
    'Ernster',
    'Luxembourg-Kirchberg',
    'Tetange',
    'Esch-sur-Alzette',
    'Lamadelaine',
    'Rumelange',
    'Hesperange',
    'Medernach',
    'Goetzingen',
    'Nospelt',
    'Gosseldange',
    'Schifflange',
    'Grosbous',
    'Merl',
    'Gonderange',
    'Ettelbruck',
    'Huncherange',
    'Bridel',
    'Rodange',
    'Hautcharage',
    'Kayl',
    'Berchem',
    'Bofferdange',
    'Mondercange',
    'Dippach',
    'Burmerange',
    'Oberanven',
    'Pétange',
    'Grevenmacher',
    'Differdange',
    'Grevenknapp',
    'Schieren',
    'Pontpierre',
    'Sanem',
    'Crauthem',
    'Beaufort',
    'Syren',
    'Heisdorf-sur-Alzette',
    'Helmdange',
    'Lintgen',
    'Hersberg',
    'Moutfort',
    'Uebersyren',
    'Olm',
    'Tuntange',
    'Bissen',
    'Koetschette',
    'Niedercorn',
    'Mertert',
    'Erpeldange-lez-Bous',
    'Ehnen',
    'Canach',
    'Wasserbillig',
    'Wormeldange',
    'Remerschen',
    'Bigonville',
    'Gostingen',
    'Bourglinster',
    'Bergem',
    'Hobscheid',
    'Schouweiler',
    'Capellen',
    'Ahn',
    'Kleinbettingen',
    'Bonnevoie',
    'Foetz',
    'Walferdange',
    'Steinfort',
    'Colmar',
    'Limpertsberg',
    'Larochette',
    'Hollerich',
    'Hagen',
    'Kehlen',
    'Mamer',
    'Mondorf-les-Bains',
    'Schoos',
    'Beidweiler',
    'Oetrange',
    'Lorentzweiler',
    'Wolpert',
    'Dalheim',
    'Bivange',
    'Godbrange',
    'Roeser',
    'Cap',
    'Roodt-sur-Syre',
    'Frisange',
    'Noerdange',
    'Beckerich',
    'Echternach',
    'Esch-sur-Sure',
    'Betzdorf',
    'Schuttrange',
    'Remich'
  ],
  Armenia: [
    'Aparan',
    'Yerevan',
    'Jermuk',
    'Abovyan',
    'Vanadzor',
    'Apaga',
    'Ashtarak',
    'Arabkir',
    'Agarak',
    'Kapan',
    'Erebuni Fortress',
    'Hrazdan',
    'Ijevan',
    'Tsaghkadzor'
  ],
  'British Virgin Islands': ['Road Town', 'Tortola'],
  Yemen: ["Sana'", 'Sanaa', 'Aden'],
  Belarus: [
    'Minsk',
    'Mogilev',
    'Brest',
    'Vitebsk',
    "Dvor-Gomel'",
    'Hrodna',
    'Navapolatsk',
    'Lida',
    'Horki',
    'Borisov',
    'Lyakhovichi',
    'Radoshkovichi',
    'Polatsk',
    'Orsha',
    "Valer'yanovo",
    'Baranovichi',
    'Mazyr',
    'Lyaskavichy',
    'Zhodzina',
    'Zaslawye',
    'Lahoysk',
    'Shklow',
    "Mozyr'",
    'Dzyarzhynsk',
    'Pinsk'
  ],
  Gibraltar: ['Gibraltar'],
  Kenya: [
    'Nairobi',
    'Mombasa',
    'Kisumu',
    'Machakos',
    'Thika',
    'Eldoret',
    'Kitui',
    'Kabete',
    'Wote',
    'Kiambu',
    'Nakuru',
    'Kaiboi',
    'Kitale',
    'Kisii',
    'Bondo',
    'Siaya',
    'Chuka',
    'Karatina',
    'Maseno',
    'Kikuyu',
    'Mbita',
    'Sawa Sawa',
    'Turbo'
  ],
  Chile: [
    'Las Condes',
    'Santiago',
    'Viña del Mar',
    'Hanga Roa',
    'Antofagasta',
    'Iquique',
    'Concepción',
    'Valparaíso',
    'Ovalle',
    'Chuquicamata',
    'Providencia',
    'Copiapó',
    'Maquina Atacama',
    'Valdivia',
    'Coihaique',
    'Galvarino',
    'Puerto Montt',
    'Collipulli',
    'Molina',
    'Los Angeles',
    'Quillota',
    'Punta Arenas',
    'Tome',
    'Chillan',
    'La Laja',
    'Temuco',
    'La Union',
    'Quilpué',
    'Puchuncavi',
    'Macul',
    'Lampa',
    'Rancagua',
    'Curicó',
    'Talca',
    'Vallenar',
    'Coquimbo',
    'Mejillones',
    'Talcahuano',
    'San Javier',
    'San Fernando',
    'La Cisterna',
    'Pucon',
    'Constitucion',
    'San Carlos',
    'Chillan Viejo',
    'Bulnes',
    'Rengo',
    'Graneros',
    'Nacimiento',
    'San Bernardo',
    'Talagante',
    'Nunoa',
    'Colina',
    'Machali',
    'Caldera',
    'El Monte',
    'Melipilla',
    'La Granja',
    'Coronel',
    'Monte Aguila',
    'Arauco',
    'Chiguayante',
    'Curacavi',
    'La Reina',
    'Osorno',
    'Centro',
    'Puerto Varas',
    'Castro',
    'Quilicura',
    'La Serena',
    'Villa Alemana',
    'Buin',
    'Arica',
    'Calama',
    'San Antonio',
    'Renaca',
    'Concon',
    'Renca',
    'Recoleta',
    'Apoquindo',
    'Lolol',
    'Puerto Aisen',
    'Penablanca',
    'Maipu',
    'San Felipe',
    'Villarrica',
    'Paillaco',
    'Rio Bueno',
    'Puente Alto',
    'Olmue',
    'Purranque',
    'Chicureo Abajo',
    'El Salvador',
    'Miraflores',
    'El Salto',
    'Rinconada',
    'El Arco',
    'Cerrillos de Tamaya',
    'El Bosque',
    'El Valle',
    'Santa Barbara',
    'Los Andes',
    'San Pedro',
    'Cunco',
    'Angol',
    'Puren',
    'San Martin',
    'Vitacura',
    'Fresia',
    'Tarapaca',
    'Maule',
    'Lo Barnechea',
    'Puerto Natales',
    'La Calera',
    'La Ligua',
    'Nogales',
    'Chimbarongo',
    'Llanquihue',
    'Algarrobo',
    'Dalcahue',
    'Lautaro',
    'Quemchi',
    'Quellon',
    'Chonchi',
    'Catemu',
    'Penaflor',
    'El Quisco'
  ],
  Qatar: ['Doha', 'Al Wakrah', 'Ar Rayyan'],
  Kuwait: [
    'Kuwait City',
    'Bayan',
    'Al Farwaniyah',
    'Hawalli',
    'Ar Rawdah',
    'As Salimiyah',
    'Salwa',
    'Al Shamiya',
    'Kayfan',
    'Al Ahmadi',
    'Abraq Khaytan',
    'Ash Shuwaykh',
    'Janub as Surrah',
    'Ad Dasmah',
    'Ad Dawhah',
    'Ash Shu`aybah'
  ],
  Guadeloupe: [
    'Les Abymes',
    'Basse-Terre',
    'Pointe-à-Pitre',
    'Sainte-Anne',
    "Morne-a-l'Eau",
    'Saint-Francois',
    'Lamentin',
    'Saint-Claude',
    'Baie-Mahault',
    'Petit-Bourg',
    'Le Moule',
    'Gourbeyre',
    'Port-Louis',
    'Capesterre-Belle-Eau',
    'Baie Mahault',
    'Le Gosier',
    'Sainte-Rose',
    'Terre-de-Bas',
    'Grand-Bourg',
    'Anse-Bertrand',
    'Petit-Canal',
    'Baillif',
    'Goyave',
    'Capesterre-de-Marie-Galante',
    'Deshaies',
    'Terre-de-Haut',
    'Trois-Rivieres',
    'Pointe-Noire',
    'Saint-Louis',
    'Village'
  ],
  Martinique: [
    'Riviere-Salee',
    'Fort-de-France',
    'Le Gros-Morne',
    'Saint-Joseph',
    'Ducos',
    'Le Lamentin',
    'Case-Pilote',
    'Schoelcher',
    'Le Robert',
    'Le Carbet',
    'Sainte-Luce',
    'Les Trois-Ilets',
    "Les Anses-d'Arlet",
    'Le Francois',
    'Sainte-Anne',
    'Le Diamant',
    'Le Morne-Rouge',
    'Sainte-Marie',
    'Saint-Esprit'
  ],
  'French Guiana': ['Petit Cayenne', 'Kourou', 'Remire-Montjoly', 'Matoury'],
  'Dominican Republic': [
    'Santo Domingo',
    'Santiago de los Caballeros',
    'San Cristobal',
    'San Fernando de Monte Cristi',
    'Moca',
    'Dominica',
    'Boca Chica',
    'Punta Cana',
    'San Jose de Ocoa',
    'La Romana',
    'Puerto Plata',
    'San Francisco de Macorís',
    'San Pedro de Macorís',
    'Jimani',
    'Guaricano',
    'Los Alcarrizos',
    'Santa Cruz de Barahona',
    'Baní',
    'Salvaleón de Higüey',
    'Hato Mayor del Rey',
    'Arenazo',
    'Pedernales',
    'Cotui',
    'Cabarete',
    'Concepción de la Vega',
    'Sabaneta',
    'Bavaro',
    'Los Prados'
  ],
  Guam: [
    'Santa Rita',
    'Hagåtña',
    'Barrigada Village',
    'Tamuning-Tumon-Harmon Village',
    'Yigo Village',
    'Inarajan Village',
    'Dededo Village'
  ],
  'U.S. Virgin Islands': [
    'Frederiksted',
    'Charlotte Amalie',
    'Christiansted',
    'St John Island',
    'Kingshill'
  ],
  'Puerto Rico': [
    'Bayamón',
    'Dorado',
    'Carolina',
    'Rio Grande',
    'Guaynabo',
    'San Juan',
    'Caguas',
    'Salinas',
    'Vega Baja',
    'Morovis',
    'Arecibo',
    'Catano',
    'Toa Baja',
    'Las Piedras',
    'Corozal',
    'Aguas Buenas',
    'Punta Santiago',
    'Luquillo',
    'Fajardo',
    'Ciales',
    'Humacao',
    'Juncos',
    'Barceloneta',
    'Hatillo',
    'Manati',
    'Vega Alta',
    'Naguabo',
    'Saint Just',
    'Trujillo Alto',
    'Lares',
    'San Sebastian',
    'Camuy',
    'Bajadero',
    'Gurabo',
    'San Lorenzo',
    'Aibonito',
    'Cayey',
    'Santa Isabel',
    'Ponce',
    'Guayanilla',
    'Villalba',
    'Aguadilla',
    'Isabela',
    'Moca',
    'Mayaguez',
    'Hormigueros',
    'Guayama',
    'Maunabo',
    'Patillas',
    'Ensenada',
    'Sabana Grande',
    'Barranquitas',
    'Utuado',
    'Garrochales',
    'Cidra',
    'Loiza',
    'Canovanas',
    'Yabucoa',
    'Arroyo',
    'Coamo',
    'Cabo Rojo',
    'Aguada',
    'Juana Diaz',
    'Rincon',
    'Anasco',
    'Guanica',
    'Boqueron',
    'Quebradillas',
    'Toa Alta',
    'Ceiba',
    'Yauco',
    'Palmer',
    'Florida',
    'Penuelas',
    'Lajas',
    'San German',
    'Residencial Puerto Real',
    'Orocovis',
    'Maricao',
    'Naranjito',
    'Adjuntas',
    'Sabana Seca',
    'Vieques',
    'San Antonio',
    'Jayuya',
    'Culebra',
    'Coto Laurel',
    'Mercedita'
  ],
  Mongolia: [
    'Ulan Bator',
    'Chihertey',
    'Altai',
    'Bayanhongor',
    'Mörön',
    'Baruun-Urt',
    'Sühbaatar',
    'Bayangol',
    'Darhan',
    'Han-Uul',
    'Arvayheer',
    'Javhlant',
    'Choyr',
    'Undurkhaan',
    'Ovoot',
    'Ulaan-Uul',
    'Mandalgovi',
    'Dalandzadgad',
    'Toyrim',
    'Cecerleg',
    'Saynshand',
    'Khovd',
    'Uliastay'
  ],
  'New Zealand': [
    'Auckland',
    'Palmerston North',
    'Wellington',
    'Windermere',
    'Tauranga',
    'Christchurch',
    'Cheviot',
    'Levels',
    'Panmure',
    'Saint Heliers',
    'Torbay',
    'Waitakere City',
    'Silverdale',
    'Tuakau',
    'Manukau',
    'Manukau',
    'Papakura',
    'Johnsonville',
    'Takapuna',
    'Waitangi',
    'Taradale',
    'Whangarei',
    'Paihia',
    'Waiau',
    'Amberley',
    'Belmont',
    'Wellsford',
    'Hamilton',
    'Red Beach',
    'Avondale',
    'Yaldhurst',
    'Lower Hutt',
    'Greenhithe',
    'Wanganui',
    'Masterton',
    'Stanmore Bay',
    'Mangere',
    'Titirangi North',
    'Waimauku',
    'Rotorua',
    'Ponsonby',
    'Dunedin',
    'Waihi Beach',
    'Kawerau',
    'Taupo',
    'Matakana',
    'Pakuranga',
    'Albany',
    'Waiau Pa',
    'Parnell',
    'Napier City',
    'Nelson',
    'New Plymouth',
    'Winton',
    'Douglas',
    'Marua',
    'Queenstown',
    'Gisborne',
    'Cave',
    'Papamoa',
    'Whakatane',
    'Opotiki',
    'Northcote Point',
    'Hastings',
    'Petone',
    'Lincoln',
    'Otaki',
    'Timaru',
    'Wendon Valley',
    'Wanaka',
    'Greymouth',
    'Westport',
    'Punakaiki',
    'Leeston',
    'Te Kauwhata',
    'Ohaupo',
    'Haast',
    'Katikati',
    'Waihi',
    'Paeroa',
    'Tinwald',
    'Glenbrook',
    'Renwick',
    'Waikawa',
    'Ngaio',
    'Clyde',
    'Invercargill',
    'Pokeno',
    'Oxford',
    'Rakaia',
    'Halswell',
    'Linwood',
    'Mangaroa',
    'Khandallah',
    'Woodend',
    'Ngatea',
    'Balclutha',
    'Takaka',
    'Riversdale',
    'Otautau',
    'Tuatapere',
    'Riverton',
    'Pirongia',
    'Ohura',
    'Te Atatu',
    'Otorohanga',
    'Tirau',
    'Leamington',
    'North Shore',
    'Tokomaru',
    'Hawera',
    'Rolleston',
    'Mount Maunganui',
    'Whangamata',
    'Raglan',
    'Whangaparaoa',
    'Porirua',
    'Opaheke',
    'Drury',
    'Waikuku Beach',
    'Waiuku',
    'Tahoraiti',
    'Levin',
    'Tokoroa',
    'Turangi',
    'Eltham',
    'Waitoa',
    'Paraparaumu',
    'Kaiwharawhara',
    'Riverhead',
    'Warkworth',
    'Takanini',
    'Kumeu',
    'Waikanae',
    'Northland',
    'Waipara',
    'Kaikoura',
    'Thames',
    'Woolston',
    'Wainuiomata',
    'Kaitangata',
    'Lumsden',
    'Murupara',
    'Mangawhai Heads',
    'Kaiwaka',
    'Orewa',
    'Walton',
    'Cashmere Hills',
    'Wairoa',
    'Templeton',
    'Waitara',
    'Urenui',
    'Porangahau',
    'Waiouru',
    'Herne Bay',
    'Howick',
    'Hokitika',
    'Glen Eden',
    'Te Anau',
    'Oban',
    'Balfour',
    'Weston',
    'Lake Tekapo',
    'Kurow',
    'Ahaura',
    'Mangonui',
    'Havelock North',
    'Waipawa',
    'Takapau',
    'Winchester',
    'Opunake',
    'Sawyers Bay',
    'Whitianga',
    'Kawhia',
    'Taupiri',
    'Colville',
    'Upper Moutere',
    'Hikuai',
    'Owaka',
    'Waitati',
    'Hawarden',
    'Karamea',
    'Milton',
    'Kaiata',
    'Franz Josef',
    'Springs Junction',
    'Dargaville',
    'Ashhurst',
    'Foxton',
    'Foxton Beach',
    'Eastbourne',
    'Piopio',
    'Manunui',
    'Pukeatua',
    'Okaiawa',
    'Kimbolton',
    'Otaki Beach',
    'Bulls',
    'Orakei',
    'Awanui',
    'Kohukohu',
    'Okaihau',
    'Ruawai',
    'Mission Bay',
    'Ranfurly',
    'Clinton',
    'Mosgiel',
    'Outram',
    'Menzies Ferry',
    'Mossburn',
    'Rakaia Gorge',
    'West Melton',
    'Fairlie',
    'Wairoa',
    'Puhoi',
    'Campbells Bay',
    'Blackburn',
    'Waipu',
    'Portland',
    'Kerikeri',
    'Moerewa',
    'East Tamaki',
    'Tasman',
    'Favona',
    'Coromandel',
    'Russell',
    'Kaeo',
    'Te Roti',
    'Kirwee',
    'Hunterville',
    'Tamahere',
    'Waimana',
    'Bethlehem',
    'Wakefield',
    'Okato',
    'Maungaturoto',
    'Maungatapere',
    'Kamo',
    'Onga Onga',
    'Myross Bush',
    'Waverley',
    'Beachlands',
    'Otane',
    'Clarks',
    'Weymouth',
    'Wyndham',
    'Oratia',
    'Swanson',
    'Paremata',
    'Paekakariki',
    'Pleasant Point',
    'Greerton',
    'Port Chalmers',
    'Oturehua',
    'Oamaru',
    'Paradise',
    'Rotherham',
    'Longburn',
    'Clive',
    'Otematata',
    'Penrose',
    'Collingwood',
    'Bombay',
    'Clevedon',
    'Waikari',
    'Coalgate',
    'Oakura',
    'Darfield',
    'Duvauchelle',
    'Sefton',
    'Edendale',
    'Browns Bay',
    'Methven',
    'Doyleston',
    'Waikuku',
    'Kawakawa',
    'Brightwater',
    'Paraparaumu Beach',
    'Coatesville',
    'Reporoa'
  ],
  Singapore: [
    'Singapore',
    'Yishun New Town',
    'Bedok New Town',
    'Ayer Raja New Town',
    'Kalang',
    'Tampines New Town',
    'Ang Mo Kio New Town',
    'Kampong Pasir Ris',
    'Hougang',
    'Yew Tee',
    'Choa Chu Kang New Town',
    'Punggol',
    'Changi Village',
    'Bukit Timah Estate',
    'Serangoon',
    'Jurong Town',
    'Tanglin Halt',
    'Woodlands New Town',
    'Jurong East New Town',
    'Bukit Panjang New Town',
    'Bukit Batok New Town',
    'Pasir Panjang',
    'Holland Village',
    'Tai Seng',
    'Toa Payoh New Town',
    'Bukit Timah',
    'Jurong West New Town',
    'Kembangan',
    'Queenstown Estate',
    'Boon Lay',
    'Simei New Town',
    'Pandan Valley',
    'Clementi New Town',
    'Tanjong Pagar'
  ],
  Indonesia: [
    'Bandung',
    'Jakarta',
    'Samarinda',
    'Pekanbaru',
    'Balikpapan',
    'Padang',
    'Patam',
    'Malang',
    'Medan',
    'Pangaturan',
    'Tebingtinggi',
    'Sungailiat',
    'Palembang',
    'Bengkalis',
    'Jambi City',
    'Depok',
    'Bogor',
    'Sangereng',
    'Bekasi',
    'Karawang',
    'Sukabumi',
    'Tasikmalaya',
    'Subang',
    'Ciamis',
    'Cirebon',
    'Garut',
    'Kuningan',
    'Majalengka',
    'Sumedang',
    'Sukoharjo',
    'Semarang',
    'Pekalongan',
    'Kudus',
    'Klaten',
    'Jepara',
    'Demak',
    'Salatiga',
    'Tegal',
    'Yogyakarta',
    'Sleman',
    'Cilacap',
    'Magelang',
    'Wonosobo',
    'Surakarta',
    'Bantul',
    'Temanggung',
    'Kebumen',
    'Purwokerto',
    'Purbalingga',
    'Kulon',
    'Surabaya',
    'Bangkalan',
    'Pasuruan',
    'Mojokerto',
    'Sidoarjo',
    'Surabayan',
    'Batu',
    'Blitar',
    'Lumajang',
    'Tulungagung',
    'Magetan',
    'Kediri',
    'Trenggalek',
    'Madiun',
    'Ngawi',
    'Nganjuk',
    'Bojonegoro',
    'Banyuwangi',
    'Jember',
    'Situbondo',
    'Probolinggo',
    'Gresik',
    'Lamongan',
    'Pamekasan',
    'Pontianak',
    'Singkawang',
    'Banjarmasin',
    'Buntok',
    'Bontang',
    'Palangkaraya',
    'Tarakan',
    'Denpasar',
    'Badung',
    'Ubud',
    'Mataram',
    'Selong',
    'Manado',
    'Tondano',
    'Bitung',
    'Bima',
    'Sungguminasa',
    'Adiantorop',
    'Makassar',
    'Sekupang',
    'Kota',
    'Bangkinang',
    'Binjai',
    'Banda Aceh',
    'Lhokseumawe',
    'Serdang',
    'Balige',
    'Lampeong',
    'Baturaja',
    'Bandar',
    'Cimahi',
    'Indramayu',
    'Banyumas',
    'Jombang',
    'Mojoagung',
    'Kepanjen',
    'Ponorogo',
    'Pacitan',
    'Palu',
    'Sengkang',
    'Gorontalo',
    'Gianyar',
    'Jayapura',
    'Soasio',
    'Wonosari',
    'Bengkulu',
    'Guntung',
    'Langsa',
    'Kerinci',
    'Porsea',
    'Bali',
    'Cianjur',
    'Tirtagangga',
    'Purworejo',
    'Pandeglang',
    'Tigaraksa',
    'Cilegon',
    'Cilegon',
    'Sanur',
    'Darussalam',
    'Kupang',
    'Bandar Lampung',
    'Pati',
    'Panasuan',
    'Darmaga',
    'Dumai',
    'Timur',
    'Riau',
    'Bukit Tinggi',
    'Parman',
    'Cihampelas',
    'Tangsel',
    'Duren',
    'Angkasa',
    'Jimbaran',
    'Menara',
    'Pamulang',
    'Bantan',
    'Baratjaya',
    'Utara',
    'Veteran',
    'Tengah',
    'Tenggara',
    'Selatan',
    'Simpang',
    'Gunungsitoli',
    'Pemalang',
    'Tenggarong',
    'Tanjung Balai',
    'Serang',
    'Cikarang',
    'Cibitung',
    'Bondowoso',
    'Singaraja',
    'Poso',
    'Ambon City',
    'Negeribesar',
    'Cempaka',
    'Lestari',
    'Kandangan',
    'Ciputat',
    'Kartasura',
    'Jagakarsa',
    'Pondok',
    'Solo',
    'Polerejo',
    'Muntilan',
    'Boyolali',
    'Nusantara',
    'Cipinanglatihan',
    'Kalimantan',
    'Serang',
    'Serpong',
    'Cikini',
    'Purwodadi Grobogan',
    'Kendal',
    'Tanjungpinang',
    'Lubuk Pakam',
    'Nusa',
    'Kelapa Dua',
    'Gandul',
    'Gedung',
    'Tanjung',
    'Kuta',
    'Kalideres',
    'Mega',
    'Area',
    'Wilayah',
    'Soho',
    'Menteng',
    'Tuban',
    'Cilincing',
    'Sunggal',
    'Sijunjung',
    'Kerobokan',
    'Negara',
    'Amlapura',
    'Baubau',
    'Karanganyar',
    'Sampang',
    'Depok Jaya',
    'Parakan',
    'Lawang',
    'Pare',
    'Airmadidi',
    'Tembagapura',
    'Banjarbaru',
    'Palangka',
    'Cimanggis',
    'Kebayoran Baru',
    'Lapan',
    'Pusat',
    'Sigli',
    'Kabanjahe',
    'Pematangsiantar',
    'Payakumbuh',
    'Kebayoran Lama Selatan',
    'Tigarasa',
    'Purwakarta',
    'Cibubur',
    'Wonogiri',
    'Sragen',
    'Ungaran',
    'Batang',
    'Ambarawa',
    'Palaihari',
    'Tanjung',
    'Sampit',
    'Bulukumba',
    'Bangli',
    'Soe',
    'Nusa Dua',
    'Stabat',
    'Maros',
    'Tipar Timur',
    'Holis',
    'Banjarnegara',
    'Banjar',
    'Kopeng',
    'Duri',
    'Bantaeng',
    'Blora',
    'Tomohon',
    'Citeureup',
    'Pekan',
    'Mamuju',
    'Badung',
    'Abadi',
    'Anggrek',
    'Sejahtera',
    'Cakrawala',
    'Indo',
    'Sentul',
    'Utama',
    'Mail',
    'Udayana',
    'Cengkareng',
    'Kemang',
    'Tabanan'
  ],
  Nepal: ['Patan', 'Jawlakhel', 'Kathmandu', 'Bharatpur', 'Palpa', 'Lumbini'],
  'Papua New Guinea': [
    'Waigani',
    'Madang',
    'Port Moresby',
    'Namatanai',
    'Arawa',
    'Pongani',
    'Lae',
    'Goroka',
    'Wewak',
    'Kimbe',
    'Daru',
    'Kokopo',
    'Kerema',
    'Lorengau',
    'Popondetta',
    'Wabag',
    'Kundiawa',
    'Mount Hagen',
    'Nambaga',
    'Kikori',
    'Aitape',
    'Kiunga',
    'Vanimo',
    'Mendi',
    'Paivara',
    'Kavieng',
    'Kupano'
  ],
  Pakistan: [
    'Gulshan-e-Iqbal',
    'Habib Baihk',
    'Karachi',
    'Ziauddin',
    'Okara',
    'Islamabad',
    'Rawalpindi',
    'Khanpur',
    'Jhelum',
    'Swābi',
    'Lahore',
    'Faisalabad',
    'Cantt',
    'Gujar Khan',
    'Attock',
    'Goth Abad Magsi',
    'Kasur',
    'Nangar',
    'Sheikhupura',
    'Sialkot',
    'Mandi Bahauddin',
    'Gujrat',
    'Wazirabad',
    'Narowal',
    'Sargodha',
    'Mianwali',
    'Daud Khel',
    'Bahāwalpur',
    'Burewala',
    'Abbottabad',
    'Batgram',
    'Havelian',
    'Haripur',
    'Mansehra',
    'Plot',
    'Hyderabad',
    'Miran Shah',
    'Peshawar',
    'Gujranwala',
    'Multan',
    'Quetta',
    'Khan',
    'Kabirwala',
    'Fazal',
    'Clifton',
    'Sarwar',
    'New Mirpur',
    'Fātima',
    'Saddar',
    'Gulberg',
    'Gilgit',
    'Muzaffarabad',
    'Sarai Sidhu',
    'Dera Ghazi Khan',
    'Sahiwal',
    'Chakwal',
    'Bhimbar',
    'Sukkur',
    'Mandi',
    'Usman',
    'Chārsadda',
    'Nowshera',
    'Mardan',
    'Mian Channu',
    'Khanewal',
    'Jhang Sadr',
    'Jhang City',
    'Toba Tek Singh',
    'Jhumra',
    'Daska',
    'Kohat',
    'Nankana Sahib',
    'Pindi',
    'Rawlakot'
  ],
  Panama: [
    'Panama City',
    'Santa Catalina',
    'Playa Blanca',
    'El Arado',
    'Cocle',
    'Chiriqui',
    'Colón',
    'Plaza',
    'Bocas del Toro',
    'Guadalupe',
    'Penonomé',
    'Chigore',
    'David',
    'Boquete',
    'Portobelo',
    'Bella Vista',
    'Pueblo Nuevo',
    'Veraguas',
    'Sacramento',
    'Arraijan',
    'Paitilla',
    'Santa Clara',
    'Balboa',
    'Torre',
    'Kuba',
    'Chitré',
    'Santiago',
    'San Miguelito',
    'La Chorrera',
    'Juan Franco',
    'Juan Diaz',
    'Vista Alegre',
    'Juan Diaz',
    'Mulatupo',
    'Torres Bluff',
    'Entre Rios',
    'Las Mercedes',
    'Rio Abajo',
    'El Cangrejo',
    'San Francisco',
    'Parque Lefevre',
    'Llano Tugri',
    'Bugaba',
    'Santo Domingo',
    'Ancon',
    'Las Tablas',
    'Cerro Viento',
    'San Felipe',
    'Santa Isabel',
    'San Cristobal',
    'Quebrada de Camilo',
    'Arosemena',
    'Tocumen',
    'Las Sabanas',
    'Los Angeles',
    'Calidonia',
    'Corozal',
    'Santa Ana',
    'Curundu',
    'Elena',
    'Albrook',
    'Jimenez',
    'El Lago',
    'Campo Alegre',
    'Vista Hermosa',
    'Muerto',
    'Victoria',
    'La Loma',
    'Finca Blanco Numero Uno',
    'La Exposicion',
    'Cristobal',
    'Fuerte Amador',
    'Aguadulce',
    'Peru',
    'Sabalo',
    'Playón Chico',
    'Curundame'
  ],
  'Costa Rica': [
    'San José',
    'Alajuela',
    'Puerto Jimenez',
    'Santa Ana',
    'Heredia',
    'Tres Rios',
    'Santa Cruz',
    'San Rafael',
    'Alfaro',
    'San Pedro',
    'Guadalupe',
    'Barva',
    'Corazon de Jesus',
    'Moravia',
    'Alajuelita',
    'San Isidro',
    'La Garita',
    'Puntarenas',
    'Cartago',
    'Escazu',
    'Santa Cecilia',
    'Liberia',
    'Coyol',
    'Curridabat',
    'Paraiso',
    'Llorente',
    'Pavas',
    'Desamparados',
    'Limón',
    'San Pablo',
    'San Rafael',
    'Colon',
    'Perez',
    'Aserri',
    'Guacima',
    'San Ramon',
    'Guachipelin',
    'Guapiles',
    'San Francisco',
    'Grecia',
    'Isla de Tibas',
    'San Josecito',
    'Turrialba',
    'San Marcos',
    'Coronado',
    'San Francisco',
    'Palmares',
    'Zapote',
    'San Diego',
    'Quesada',
    'Zarcero',
    'Mexico',
    'Santo Domingo',
    'San Joaquin',
    'Llorente',
    'Mercedes',
    'Sabanilla',
    'San Jose de la Montana',
    'San Juan',
    'Turrucares',
    'Miramar',
    'Filadelfia',
    'Atenas',
    'Jaco',
    'Poas',
    'Sarchi',
    'Quepos',
    'Siquirres',
    'Esparza'
  ],
  Peru: [
    'Lima',
    'Cerro de Pasco',
    'Santiago',
    'San Miguel',
    'Cusco',
    'Arequipa',
    'Iquitos',
    'Callao',
    'San Isidro',
    'Chimbote',
    'Machu Picchu',
    'Juliaca',
    'San Borja',
    'Tarapoto',
    'Ica',
    'Huancayo',
    'Puno',
    'Ayacucho',
    'Piura',
    'Abancay',
    'San Juan Bautista',
    'Trujillo',
    'Pimentel',
    'Chiclayo',
    'Huancavelica',
    'Bellavista',
    'Victoria',
    'Chincha',
    'Huaraz',
    'Jose Olaya',
    'Jesus Maria',
    'Puerto Maldonado',
    'Huacho',
    'Pucallpa',
    'Tacna',
    'Ilo',
    'Lambayeque',
    'Paucarpata',
    'Lince',
    'La Punta',
    'Bolivar',
    'La Perla',
    'Surquillo',
    'San Martin',
    'Lurigancho',
    'Barranco',
    'La Molina',
    'Rimac',
    'Ate',
    'El Agustino',
    'Santiago De Surco',
    'Barranca',
    'Cajamarca',
    'Huaytara',
    'Huaral',
    'Jaen',
    'Junin',
    'Surco',
    'Cuzco',
    'Calle',
    'Ventanilla',
    'Huánuco',
    'Moquegua',
    'Talara',
    'Chachapoyas',
    'San Martin',
    'Minas de Marcona',
    'Maldonado',
    'Bagua',
    'Caras',
    'Loreto',
    'Hacienda La Palma',
    'Rioja',
    'Pisco',
    'Imperial',
    'Tingo Maria',
    'Tumbes',
    'Moyobamba',
    'Miraflores',
    'Puerto Inca',
    'Pasco',
    'Sullana',
    'Progreso',
    'Rimac',
    'Santa',
    'San Isidro'
  ],
  Belize: [
    'Belize City',
    'Benque Viejo del Carmen',
    'Belmopan',
    'San Ignacio',
    'Ladyville',
    'Freetown Sibun',
    'San Pedro Town'
  ],
  Nigeria: [
    'Ikeja',
    'Lagos',
    'Maryland',
    'Isolo',
    'Yaba',
    'Iseri-Oke',
    'Shomolu',
    'Ojo',
    'Lekki',
    'Ifako',
    'Ayobo',
    'Suleja',
    'Ipaja',
    'Aganga',
    'Bwari',
    'Uyo',
    'Mushin',
    'Port Harcourt',
    'Aba',
    'Forum',
    'Ibadan',
    'Kano',
    'Benin City',
    'Mowe',
    'Apapa',
    'Ojota',
    'Suru-Lere',
    'Ajuwon',
    'Ikorodu',
    'Bonny',
    'Agege',
    'Birnin Kebbi',
    'Zaria',
    'Warri',
    'Calabar',
    'Orile Oshodi',
    'Ota',
    'Osogbo',
    'Ekpoma',
    'Odau',
    'Damaturu',
    'Yola',
    'Egbeda',
    'Magodo',
    'Ilesa',
    'Onigbongbo',
    'Ughelli',
    'Kaduna',
    'Owerri',
    'Enugu',
    'Ilorin',
    'Jos',
    'Warri',
    'Anambra',
    'Abraka',
    'Abraka',
    'Abuja',
    'Abakaliki',
    'Ado-Ekiti',
    'Jalingo',
    'Ile-Ife',
    'Burutu',
    'Lokoja',
    'Ido',
    'Minna',
    'Obudu',
    'Abeokuta',
    'Oworonsoki',
    'Alimosho',
    'Akure',
    'Makurdi',
    'Funtua',
    'Obafemi',
    'Tara',
    'Nsukka',
    'Adodo',
    'Ondo',
    'Ungwan Madaki',
    'Igueben',
    'Oshodi',
    'Igando',
    'Agidingbi',
    'Ikotun',
    'Ajegunle',
    'Chafe',
    'Mogho',
    'Kebbi'
  ],
  Venezuela: [
    'Puerto La Cruz',
    'Caracas',
    'Ocumare',
    'El Roque',
    'Guachara',
    'Santa Fe',
    'Torre',
    'Maracaibo',
    'Valencia',
    'Mérida',
    'Puerto Ordaz and San Felix',
    'Guayana',
    'Ciudad Guayana',
    'San Felix',
    'Maracay',
    'Barcelona',
    'Cabimas',
    'San Jose de Guanipa',
    'Guarenas',
    'Mariguitar',
    'Macuto',
    'Ciudad Bolívar',
    'Punto Fijo',
    'Barquisimeto',
    'San Diego',
    'Carrizal',
    'San Antonio de Los Altos',
    'Los Teques',
    'Cabudare',
    'Acarigua',
    'Araure',
    'Valera',
    'Guatire',
    'La Guaira',
    'Caraballeda',
    'Catia La Mar',
    'Maturín',
    'Santa Cruz del Zulia',
    'Bachaquero',
    'Maiquetia',
    'Valle',
    'Naguanagua',
    'La Cruz',
    'Porlamar',
    'Tucupita',
    'Calabozo',
    'Campo de Carabobo',
    'Charallave',
    'La Victoria',
    'Santa Teresa',
    'Gueiria',
    'Vargas',
    'La Villa del Rosario',
    'Ciudad Ojeda',
    'Turmero',
    'Cagua',
    'San Felipe',
    'Trujillo',
    'Anaco',
    'San Cristóbal',
    'El Tigre',
    'Cumaná',
    'Miranda',
    'San Antonio',
    'Pampatar',
    'El Recreo',
    'Ciudad Piar',
    'Chivacoa',
    'Londres',
    'Coro',
    'Caripe',
    'Bejuma',
    'Matanzas',
    'El Rosal',
    'La Verdad',
    'Monte Cristo',
    'El Chorro',
    'Solano',
    'Maria',
    'San Joaquin',
    'La Urbina',
    'Los Chorros',
    'La Castellana',
    'Curazaito',
    'Guanare',
    'Caja de Agua',
    'Barinas',
    'Bolivar',
    'Puerto Cabello',
    'San Francisco',
    'Cua',
    'Colonia Tovar',
    'Tariba',
    'El Vigia',
    'Sabaneta'
  ],
  Bahamas: [
    'Nassau',
    'Freeport',
    'Marsh Harbour',
    'Spanish Wells',
    'Andros Town',
    'Dunmore Town',
    'Palmetto Point',
    ''
  ],
  Morocco: [
    'Marrakesh',
    'Tangier',
    'Temara',
    'Skhirat',
    'Casablanca',
    'Rabat',
    'Kenitra',
    "Sidi Yahia az Za'er",
    'Fes',
    'Tarfaya',
    'Meknes',
    'Agadir',
    'Khouribga',
    'Salé',
    'Tétouan',
    'Touissite',
    'Oujda',
    'Nador',
    'Erfoud',
    'Mehediyah',
    'Khemisset',
    'Oulad Teima',
    'Mohammedia',
    'El Gara',
    'Berrechid',
    'Safi',
    'Benguerir',
    'Tiznit',
    'Sidi Slimane',
    'Sefrou',
    "Ifrane de l'Anti-Atlas",
    'El Hajeb',
    'Larache',
    'Ksar el Kebir',
    "M'dik",
    'Tan-Tan',
    'Al Hoceima',
    'Ouazzane',
    'Settat',
    'Tiflet',
    'Tahala',
    'El Jadida',
    'Sidi Bennour',
    'Afourer',
    'Beni Mellal',
    'Fkih Ben Salah',
    'Oued Zem',
    'Deroua',
    'Assa',
    'Ait Melloul',
    'Taza',
    'Mansour'
  ],
  Colombia: [
    'Medellín',
    'Bogotá',
    'Barranquilla',
    'Miami',
    'Cundinamarca',
    'Antioquia',
    'Magdalena',
    'Palmira',
    'Villavicencio',
    'Bermudez',
    'Acevedo',
    'Cartagena',
    'Bucaramanga',
    'San Andrés',
    'Neiva',
    'Santiago de Cali',
    'Ibague',
    'Buenaventura',
    'Cúcuta',
    'Chocho',
    'Valledupar',
    'Tarapaca',
    'Engativa',
    'Inirida',
    'Duitama',
    'Fusagasuga',
    'Envigado',
    'Itagui',
    'Pereira',
    'Armenia',
    'Manizales',
    'Santa Rosa de Cabal',
    'Floridablanca',
    'Santa Marta',
    'Riohacha',
    'Sincelejo',
    'Pasto',
    'Tuluá',
    'Tunja',
    'Yopal',
    'Acacias',
    'Bello',
    'Rionegro',
    'Popayan',
    'Chia',
    'Municipio de Copacabana',
    'La Estrella',
    'Calarcá',
    'Barrancabermeja',
    'San Diego',
    'San Clemente',
    'Piedecuesta',
    'Tauramena',
    'Giron',
    'Calamar',
    'San Martin',
    'Urumita',
    'Dosquebradas',
    'Fundacion',
    'Soacha',
    'Turbaco',
    'Villamaria',
    'Soledad',
    'Tocancipa',
    'Tenjo',
    'Cajica',
    'Buga',
    'Montería',
    'Villa del Rosario',
    'Silvania',
    'Florencia',
    'Candelaria',
    'Montenegro',
    'Sogamoso',
    'Espinal',
    'Sabaneta',
    'Planadas',
    'Facatativá',
    'Madrid',
    'Puerto Carreño',
    'El Colegio',
    'Ipiales',
    'Galapa',
    'La Mesa',
    'Ginebra',
    'Yotoco',
    'Obando',
    'Zipaquirá',
    'Sachica',
    'Corozal',
    'San Gil',
    'Pamplona',
    'Purificacion',
    'Boyaca',
    'Chiquinquira',
    'Caldas',
    'Ubaque',
    'Arauca',
    'Sabana de Torres',
    'Los Patios',
    'Cauca',
    'Aguachica',
    'Santa Rosa del Sur',
    'Mitu',
    'Fonseca',
    'Velez',
    'Pitalito',
    'San José del Guaviare',
    'Sopo',
    'Cartago',
    'La Union',
    'Guarne',
    'La Ceja',
    'Yumbo',
    'Columbia',
    'Atlantico',
    'Mosquera',
    'Cumaribo',
    'Puerto Tejada',
    'Cota',
    'Puerta Roja',
    'Risaralda'
  ],
  Seychelles: ['Victoria', 'Takamaka'],
  Barbados: [
    'Warrens',
    'Bridgetown',
    'Pine Housing Estate',
    'Atlantic Shores',
    'Hastings',
    'Wildey',
    'Rendezvous',
    'Bloomsbury',
    'The Garden',
    'Oistins',
    'Rockley',
    'Holetown',
    'Wanstead',
    'Warners',
    'Bruce Vale',
    'Bagatelle',
    'Worthing',
    'Cave Hill',
    'Clapham',
    'White Hill',
    'Porters',
    'Husbands',
    'Jackmans'
  ],
  Egypt: [
    'Cairo',
    'New Cairo',
    'Alexandria',
    'Giza',
    'Helwan',
    'Shubra',
    'Damietta',
    'Suez',
    'Madinat an Nasr',
    'Al Mansurah',
    'Bani Suwayf',
    'Sohag',
    'Tanta',
    'Zagazig',
    'Ramsis',
    'Ar Rawdah',
    'Al Mahallah al Kubra',
    'Heliopolis',
    'Hurghada',
    'Az Zamalik',
    'Rafah',
    'Madinat as Sadis min Uktubar',
    'Ismailia',
    'Faraskur',
    'Shirbin',
    'Toukh',
    'Banha',
    'Port Said',
    'Luxor',
    'Minya',
    'Sadat',
    'Almazah',
    'Assiut',
    'Al Marj',
    'Abu Hammad',
    'Nasr',
    'Flaminj',
    'Kafr ash Shaykh',
    'Badr'
  ],
  Argentina: [
    'Ingeniero Beaugey',
    'San Isidro',
    'Buenos Aires',
    'Paternal',
    'Córdoba',
    'Olivos',
    'Florencio Varela',
    'Pilar',
    'Vicente Lopez',
    'Rosario',
    'Federal',
    'San Luis',
    'Puerto Madryn',
    'Gualeguaychú',
    'Gualeguay',
    'Leones',
    'Corrientes',
    'Mendoza',
    'Rivadavia',
    'Santa Fe',
    'La Pampa',
    'Colon',
    'Cipolletti',
    'Libertad',
    'San Jorge',
    'Trelew',
    'Villaguay',
    'Santiago del Estero',
    'Tortuguitas',
    'San Miguel',
    'Pilar',
    'Paso del Rey',
    'San Telmo',
    'Del Viso',
    'La Plata',
    'Quilmes',
    'Hipolito Yrigoyen',
    'Ituzaingo',
    'San Juan',
    'Villa Elisa',
    'Santos Lugares',
    'Los Polvorines',
    'Jose Marmol',
    'Temperley',
    'Banfield',
    'Ezeiza',
    'Canning',
    'Llavallol',
    'Esteban Echeverria',
    'Gonzalez Moreno',
    'Pico Truncado',
    'Casilda',
    'San Rafael',
    'San Andres de Giles',
    'Luis Guillon',
    'Burzaco',
    'Eduardo Castex',
    'Martinez',
    'Bragado',
    'Mar de Ajo',
    'Bahía Blanca',
    'Villa Regina',
    'Gobernador Galvez',
    'El Talar',
    'Fatima',
    'Lanus',
    'Malargüe',
    'Alvear',
    'Mariano Acosta',
    'Villa Carlos Paz',
    'Mariano Moreno',
    'San Miguel de Tucumán',
    'Villa Ballester',
    'Don Torcuato',
    'Matheu',
    'San Miguel',
    'General San Martin',
    'Yerba Buena',
    'Tafi Viejo',
    'Zárate',
    'Belen de Escobar',
    'Jose Leon Suarez',
    'Río Cuarto',
    'La Banda',
    'General Juan Madariaga',
    'San Andres',
    'Tigre',
    'Nordelta',
    'Villa Urquiza',
    'Campana',
    'Villa de Mayo',
    'San Lorenzo',
    'San Fernando',
    'General Pico',
    'Caseros',
    'Garin',
    'Bell Ville',
    'San Nicolás de los Arroyos',
    'Salta',
    'De Mayo',
    'Thames',
    'Chorroarin',
    'San Martin',
    'Esperanza',
    'Posadas',
    'Frias',
    'Munro',
    'Uriburu',
    'Santa Rita',
    'San Jose',
    'La Rioja',
    'Pueyrredon',
    'Castillo',
    'San Salvador de Jujuy',
    'Concordia',
    'Bariloche',
    'Cervantes',
    'San Fernando del Valle de Catamarca',
    'Rocamora',
    'Apostoles',
    'Beccar',
    'San Miguel',
    'Mercedes',
    'El Palomar',
    'Grand Bourg',
    'Cruz del Eje',
    'Villa María',
    'Villa Huidobro',
    'Margarita',
    'Viamonte',
    'Capilla del Monte',
    'La Para',
    'Huanchillas',
    'Cosquin',
    'Villa de Maria',
    'San Jose de la Esquina',
    'Sacanta',
    'Las Varillas',
    'Ramos Mejia',
    'Godoy Cruz',
    'Ushuaia',
    'Rio Grande',
    'City Bell',
    'Mar del Plata',
    'Merlo',
    'Las Heras',
    'Rafael Calzada',
    'Claypole',
    'Avellaneda',
    'Lomas de Zamora',
    'Tristan Suarez',
    'Remedios de Escalada',
    'Longchamps',
    'La Union',
    'Dock Sud',
    'Punta Alta',
    'Castelar',
    'Moron',
    'Hurlingham',
    'Don Bosco',
    'Bernal',
    'Balcarce',
    'Moreno',
    'Miramar',
    'Batan',
    'Lomas del Mirador',
    'Villa Madero',
    'Benavidez',
    'San Justo',
    'General Pacheco',
    'Mariano J. Haedo',
    'Palermo',
    'Almagro',
    'Florida',
    'Humahuaca',
    'Mendiolaza',
    'Oncativo',
    'Rio Segundo',
    'Victoria',
    'Rio Ceballos',
    'Francisco Alvarez',
    'La Boca',
    'Berazategui',
    'Obera',
    'Resistencia',
    'San Benito',
    'Puerto Iguazú',
    'Santa Rosa',
    'Ramallo',
    'Intendente Alvear',
    'Ingeniero Luiggi',
    'Bernardo Larroude',
    'Winifreda',
    'Rancul',
    'Capitan Bermudez',
    'Reconquista',
    'Tandil',
    'Caleta Olivia',
    'Caballito',
    'Wilde',
    'General Roca',
    'Berisso',
    'Boulogne',
    'Belgrano',
    'Plottier',
    'Neuquén',
    'Guatrache',
    'Goya',
    'San Salvador',
    'Palpala',
    'Las Rosas',
    'Carlos Pellegrini',
    'Rafaela',
    'Villa Gesell',
    'San Bernardo',
    'Tres Arroyos',
    'Darregueira',
    'San Clemente',
    'Castelli',
    'Comodoro Rivadavia',
    'Río Gallegos',
    'Paraná',
    'Laborde',
    'Junín',
    'El Calafate',
    'Curuzu Cuatia',
    'Mercedes',
    'Minacar',
    'Bosques',
    'Piamonte',
    'San Antonio de Padua',
    'Marcos Paz',
    'Tostado',
    'Huinca Renanco',
    'General Deheza',
    'General Cabrera',
    'Villa Alsina',
    'Ciudadela',
    'Tablada',
    'Villa Luzuriaga',
    'Chacarita',
    'Villa Dominico',
    'General Las Heras',
    'Flores',
    'Sarandi',
    'Montserrat',
    'Aldo Bonzi',
    'La Tablada',
    'Lynch',
    'Oliva',
    'Perito Moreno',
    'Navarro',
    'Tornquist',
    'Río Turbio',
    'El Bolsón',
    'Rawson',
    'Vera',
    'Coronel Martinez de Hoz',
    'Pigue',
    'Villa Rosa',
    'Catriel',
    'San Gregorio',
    'Viedma',
    'Presidente Derqui',
    'Villa Nueva',
    'Alberdi',
    'El Hoyo',
    'General Acha',
    'Coy Aike',
    'San Cayetano',
    'San Miguel del Monte',
    'Leandro N. Alem',
    'General Alvear',
    '28 de Noviembre',
    'Abasto',
    'Olavarría',
    'General Mansilla',
    'Monte Hermoso',
    'Brandsen',
    'Villa Ocampo',
    'Manuel J. Cobo',
    'General San Martin',
    'Salto',
    'San Manuel',
    'Bordenave',
    'Suipacha',
    'San Antonio de Obligado',
    'Fernandez',
    'Partido de Jose C. Paz',
    'Gonzalez Catan',
    'Pontevedra',
    'Capilla del Senor',
    'Chivilcoy',
    'Luján',
    'Isidro Casanova',
    'Laferrere',
    'Rafael Castillo',
    'Bella Vista',
    'Ciudad General Belgrano',
    'Tapiales',
    'Ezpeleta',
    'Platanos',
    'Santa Lucia',
    'Villa Mercedes',
    'Maipu',
    'Tunuyan',
    'Corralitos',
    'Lujan de Cuyo',
    'Magdalena',
    'Ensenada',
    'San Antonio Oeste',
    'Alejandro Korn',
    'Roque Perez',
    'Guernica',
    'Glew',
    'San Vicente',
    'Navarro',
    'Lobos',
    'San Francisco Solano',
    'Almirante Brown',
    'Ranelagh',
    'San Francisco de Santa Fe',
    'Devoto',
    'Pico de Salamanca',
    'Azul',
    'Pedro Luro',
    'Villa Adelina',
    'Las Talitas',
    'Formosa',
    'San Salvador',
    'Villa Allende',
    'Valle Hermoso',
    'Concepción del Uruguay',
    'Canada de Gomez',
    'Banda del Rio Sali',
    'Villa Bosch',
    'Victoria',
    'Chateaubriand',
    'Oliveros',
    'Despenaderos',
    'Bandera',
    'Chacabuco',
    'Viale',
    'Maquinista Savio',
    'Necochea',
    'Adelia Maria',
    'Monje',
    'Coronel Dorrego',
    'Crespo',
    'Ceres',
    'Baradero',
    'Villa Trinidad',
    'San Guillermo',
    'Canada del Ucle',
    'La Leonesa',
    'Tupungato',
    'Palmira',
    'Allen',
    'Villa Insuperable',
    'Open Door',
    'Cinco Saltos',
    'El Palomar',
    'General Rodriguez',
    'La Lucila',
    'Villa del Parque',
    'Turdera',
    'Funes',
    'Avellaneda',
    'Canada Rosquin',
    'Villa La Angostura',
    'Rufino',
    'Salguero',
    'Rojas',
    'Barrio Fisherton',
    'Rosas',
    'General Paz',
    'Villa Media Agua',
    'Alicia',
    'Ranchos',
    'Puan',
    'Pueblo San Jose',
    'Huanguelen',
    'La Calera',
    'Santa Teresita',
    'Villa Lugano',
    'Granadero Baigorria',
    'Sunchales',
    'Angelica',
    'Bombal',
    'Villa Amelia',
    'Hersilia',
    'Maria Juana',
    'Villa Mercedes',
    'La Paz',
    'Alberti',
    'Antonio Carboni',
    'Pedernales',
    'Belgrano',
    'Venado Tuerto',
    'Rio Tercero',
    'Justiniano Posse',
    'Roldan',
    'Mar del Tuyu',
    'General Viamonte',
    'Pergamino',
    'San Francisco',
    'Salsipuedes',
    'Pilar',
    'San Pedro',
    'Cavanagh',
    'Pavon',
    'La Cumbre',
    'Azara',
    'Chilecito',
    'Alta Gracia',
    'General Roca',
    'Recoleta',
    'La Falda',
    'Saladillo',
    'Trenque Lauquen',
    'Esquel',
    'Sierra de los Padres',
    'Santa Clara de Saguier',
    'Arroyito',
    'Arroyito',
    'El Trebol',
    'Empalme Lobos',
    'Nunez',
    'Villa Maria Grande',
    'Santa Elena',
    'Arrecifes',
    'Todd',
    'Coronel Pringles',
    'Chajari',
    'Freyre',
    'Tapalque',
    'Totoras',
    'Henderson',
    'Carhue',
    'Colonia San Miguel Arcangel',
    'Choele Choel',
    'Colon',
    'Cutral-Co',
    'Jauregui',
    'Muniz',
    'Bernasconi',
    'Pasco',
    'James Craik',
    'Rafael Obligado',
    'La Madrid',
    'Catrilo',
    'Caleufu',
    'Alta Italia',
    'Colonia Baron',
    'Realico',
    'Miramar',
    'Balnearia',
    'Centenario',
    'Canuelas',
    'Alvarez',
    'Franck',
    'Plaza Huincul',
    'Sarmiento',
    'Luque',
    'Colonia Caroya',
    'Jesus Maria',
    'Ordonez',
    'Fray Luis A. Beltran',
    'Hipatia',
    'Zenon Pereyra',
    'Villa Giardino',
    'Lavalle',
    'San Isidro',
    'San Isidro de Lules',
    'San Martin de las Escobas',
    'Armstrong',
    'Eldorado',
    'Bustinza',
    'Vicuna Mackenna',
    'Lima',
    'Floresta',
    'Rawson',
    'General Lavalle',
    'Gaiman',
    'Dolores',
    'Trevelin',
    'Luzuriaga',
    'Ibicuy',
    'San Cristobal',
    'Concepcion',
    'San Javier',
    'Segui',
    'Veinticinco de Mayo',
    'Villa Canas',
    'Nogoya',
    'Larroque',
    'Veronica',
    'Liniers',
    'Rio Colorado',
    'Arequito',
    'Los Molinos',
    'Arteaga',
    'Canals',
    'Godoy',
    'Ascension',
    'Sampacho',
    'Constituyentes',
    'Acevedo',
    'Vuelta de Obligado',
    'Albardon',
    'Marcos Juarez',
    'Independencia',
    'Ibarlucea',
    'Perez',
    'Rodriguez Pena',
    'Santa Lucia',
    'Villa Constitucion',
    'Bigand',
    'Maipu',
    'Manantial',
    'San Genaro',
    'General Villegas',
    'Arroyo Seco',
    'Firmat',
    'Merlo',
    'Acassuso',
    'La Violeta',
    'San Pedro',
    'Charata',
    'Bella Vista',
    'Saenz Pena',
    'Monteros',
    'Santo Tome',
    'Garupa',
    'Sierra de la Ventana',
    'General Lagos',
    'Juan Pujol',
    'Rio Piedras',
    'Ayacucho',
    'Bosch',
    'Diaz',
    'San Jeronimo Norte',
    'Jeppener',
    'Rivadavia',
    'Tio Pujio',
    'La Paz',
    'Almafuerte',
    'Tacuari',
    'Rivadavia',
    'Sanchez',
    'Concepcion',
    'Maggiolo',
    'Villa Dolores',
    'El Dorado',
    'Perico',
    'Villa Las Rosas',
    'Embalse',
    'Diego de Alvear',
    'San Antonio de Arredondo',
    'Bovril',
    'Anatuya',
    'Pozo del Molle',
    'Magdalena',
    'Las Flores',
    'Pellegrini',
    'San Carlos de Bolivar',
    'Salto Grande',
    'General Guido',
    'Teodelina',
    'Vedia',
    'Las Flores',
    'Arroyito Challaco',
    'Villa Paranacito',
    'Morteros',
    'Las Perdices',
    'Corral de Bustos',
    'Asamblea',
    'Albarellos',
    'Gobernador Crespo',
    'Villa Nueva',
    'Camilo Aldao',
    'Retiro',
    'Hernando',
    'Galvez',
    'Brinkmann',
    'Agua de Oro',
    'Rada Tilly',
    'Famailla',
    'Villa Aberastain',
    'Hilario Ascasubi',
    'Manfredi',
    'Drabble',
    'Hilario',
    'Piquete Cabado',
    'Los Antiguos',
    'Laboulaye',
    'Sinsacate',
    'Ucacha',
    'General Martin Miguel de Guemes',
    'San Nicolas',
    'Los Cardales',
    'Doblas',
    'Pinamar',
    'Villa General Belgrano',
    'San Martín de los Andes',
    'La Punta',
    'Las Catitas',
    'Florentino Ameghino',
    'Portena',
    'Carlos Casares',
    'Wheelwright',
    'Mayor Buratovich',
    'Germania',
    'Saladillo',
    'Sarmiento',
    'Carlos Tejedor',
    'Melincue',
    'Clorinda',
    'Pehuajó',
    'Veinticinco de Mayo',
    'Warnes',
    'Maria Ignacia',
    'Villa Robles',
    'Macachin',
    'Laguna Alsina',
    'Puerto Rico',
    'Acebal',
    'Del Campillo',
    'Villa Angela',
    'Ingeniero Maschwitz',
    'San Antonio de Areco',
    'Cramer',
    'Calderon'
  ],
  Brunei: [
    'Bandar Seri Begawan',
    'Seria',
    'Tutong',
    'Kuala Belait',
    'Kampong Jerudong',
    'Kuala Balai',
    'Panaga',
    'Bangar'
  ],
  Bahrain: [
    'Manama',
    'Barbar',
    'Al Muharraq',
    'Madinat Hamad',
    'Al Janabiyah',
    'Al Markh',
    'Al Budayyi`',
    'Bani Jamrah',
    'Sanad',
    'Madinat `Isa',
    'Al Hadd',
    'Al Hamalah',
    'Sitrah',
    'Tubli',
    'Jurdab',
    'Oil City',
    'Sanabis'
  ],
  Aruba: [
    'Palm Beach',
    'Oranjestad',
    'Sero Blanco',
    'Tanki Lender',
    'Santa Cruz',
    'Noord',
    'Paradera',
    'Sabaneta',
    'San Barbola',
    'Sint Nicolaas',
    'Ponton',
    ''
  ],
  'Saint Lucia': [
    'Castries',
    'Dauphin',
    'Vieux Fort',
    'Anse La Raye',
    'Gros Islet',
    'Choiseul'
  ],
  Bangladesh: [
    'Dhaka',
    'Tejgaon',
    'Chittagong',
    'Agrabad',
    'Bangla',
    'Delta',
    'Barisal',
    'Kabi',
    'Comilla',
    'Hossain',
    'Kazipur',
    'Rajshahi',
    'Bazar',
    'Mymensingh',
    'Narayanganj',
    'Jessore',
    'Uttara'
  ],
  Tokelau: ['Atafu Village'],
  Cambodia: [
    'Phnom Penh',
    'Phumi Preah Haoh',
    'Phumi Siem Reab',
    'Phumi Vott Phnum',
    'Sihanoukville',
    'Phumi Boeng (1)',
    'Tuol Kok',
    'Phumi Thnal',
    'Moung Roessei',
    'Phumi Prek Mrinh',
    'Vott Kampong Svay',
    'Phumi Thmei',
    'Phumi Prei',
    'Phumi Chhuk'
  ],
  Macao: ['Macao', 'Taipa'],
  Maldives: ['Malé', 'Naifaru'],
  Afghanistan: [
    'Kabul',
    'Wazir Akbar Khan',
    'Kandahar',
    'Sharif',
    'Shar',
    'Molah',
    'Rana',
    'Herat'
  ],
  'New Caledonia': ['Nouville', 'Noumea', 'Mont-Dore', 'Dumbea', 'Paita'],
  Fiji: ['Suva', 'Lautoka', 'Nadi', 'Tamavua Heights'],
  'Wallis and Futuna': ['Vailala', 'Mata-Utu'],
  Albania: [
    'Tirana',
    'Pogradec',
    'Petran',
    'Ura Vajgurore',
    'Shkoder',
    'Elbasan',
    'Durrës',
    'Vlorë'
  ],
  Uzbekistan: [
    'Afrasiab',
    'Akkula',
    'Andijon',
    'Angren',
    'Asaka',
    'Baxt',
    'Bekobod',
    'Beruniy',
    'Bukhara',
    'Chartak',
    'Chimboy',
    'Chirchiq',
    'Dashtobod',
    'Denov',
    'Fergana',
    "G'ijduvon",
    "G'uzor",
    'Guliston',
    'Jizzax',
    'Juma',
    "Kattaqo'rg'on",
    'Kegeyli',
    'Khakkulabad',
    'Khiva',
    'Kogon',
    'Kokand',
    'Kosonsoy',
    'Kungrad',
    'Margilan',
    "Mo'ynoq",
    'Namangan',
    'Navoiy',
    'Nukus',
    'Nurota',
    'Okhangaron',
    'Olmaliq',
    'Oqtosh',
    'Piskent',
    'Qarshi',
    "Qorako'l",
    'Qorasuv',
    'Qozoonketkan',
    'Quva',
    'Quvasoy',
    'Rishdan',
    'Samarqand',
    'Shahrisabz',
    'Shakhrihon',
    'Shirabad',
    'Shirin',
    'Sirdaryo',
    'Tashkent',
    'Taxiatosh',
    'Termez',
    'Tomdibuloq',
    "To'ytepa",
    'Turtkul',
    "Uchqo'rg'on",
    'Uchquduq',
    'Urganch',
    'Urgut',
    'Vobkent',
    'Xalqobod',
    'Xonobod',
    "Xo'jayli",
    'Yangiobod',
    'Yangiyer',
    "Yangiyo'l",
    'Zarafshon'
  ],
  Montenegro: [
    'Podgorica',
    'Pljevlja',
    'Igalo',
    'Kotor',
    'Crna Gora',
    'Budva',
    'Stari Bar',
    'Herceg Novi',
    'Ulcinj',
    'Bečići',
    'Niksic'
  ],
  'North Korea': ['Pyongyang', ''],
  'Vatican City': [''],
  Antarctica: ['Antarctica'],
  Bermuda: ['Hamilton', 'Saint George', 'Somerset'],
  Curaçao: ['Willemstad', 'Vredeberg', 'Emmastad'],
  Ecuador: [
    'Quito',
    'Pichincha',
    'Guayas',
    'Guayaquil',
    'Ibarra',
    'Riobamba',
    'Ambato',
    'Otavalo',
    'Guamote',
    'Tababela',
    'Guayllabamba',
    'Cayambe',
    'Tena',
    'Tarapoa',
    'Latacunga',
    'Santo Domingo de los Colorados',
    'Cuenca',
    'Esmeraldas',
    'Paccha',
    'Zaruma',
    'El Carmen',
    'La Libertad',
    'Huaquillas',
    'Olmedo',
    'Durán',
    'Florida',
    'Machala',
    'Loja',
    'Manta',
    'Nabon',
    'Pelileo',
    'Gualaceo',
    'Macas',
    'Samborondon',
    'Puyo',
    'Zumba',
    'Portoviejo',
    'General Leonidas Plaza Gutierrez',
    'Calderon',
    'Sucre',
    'Jipijapa',
    'Macara',
    'Santa Elena',
    'La Esperanza',
    'Machachi',
    'Atacames',
    'La Troncal',
    'San Rafael',
    'Guabo',
    'Santa Cruz',
    'Milagro',
    'Tulcán',
    'Atuntaqui',
    'Daule',
    'Pasaje',
    'Playas',
    'San Miguel',
    'Puerto Francisco de Orellana',
    'Loreto',
    'Montecristi',
    'Hacienda Santa Catalina',
    'Zamora',
    'Vinces',
    'Quevedo',
    'Hacienda Duran',
    'Bahia de Caraquez',
    'Salinas',
    'Hacienda Ibarra',
    'Hacienda Bolivia',
    'El Naranjal',
    'Banos'
  ],
  'South Africa': [
    'Johannesburg',
    'Sandton',
    'Midrand',
    'Pretoria',
    'Malmesbury',
    'Kempton Park',
    'Despatch',
    'Durban',
    'Port Elizabeth',
    'Meyerton',
    'Bloemfontein',
    'Delmas',
    'Benoni',
    'Centurion',
    'Newcastle',
    'Cape Town',
    'Stellenbosch',
    'Mthatha',
    'Gansbaai',
    'Bellville',
    'Paarl',
    'Panorama',
    'George',
    'Wellington',
    'Winterton',
    'Strand',
    'East London',
    'Somerset West',
    'Hartenbos',
    'Sun Valley',
    'Kraaifontein',
    'Port Alfred',
    'Sedgefield',
    'London',
    'Pietermaritzburg',
    'Margate',
    'Kloof',
    'Greytown',
    'Krugersdorp',
    'Westonaria',
    'Pongola',
    'Boksburg',
    'Scottburgh',
    'Port Shepstone',
    'Germiston',
    'Lephalale',
    'Groblersdal',
    'Randburg',
    'Roodepoort',
    'Kimberley',
    'Edenvale',
    'Carletonville',
    'Brits',
    'Rivonia',
    'Springs',
    'Heidelberg',
    'Vanderbijlpark',
    'Randfontein',
    'Vereeniging',
    'Sasolburg',
    'Brakpan',
    'Isando',
    'Florida Glen',
    'Alberton',
    'Olifantsfontein',
    'Walkerville',
    'Franschhoek',
    'Rustenburg',
    'Lombardy East',
    'Sebokeng',
    'Morningside Manor',
    'Bethal',
    'Amanzimtoti',
    'Lyttelton',
    'Witbank',
    'Sinoville',
    'Nigel',
    'Hartbeespoort',
    'Tokai',
    'Fochville',
    'Witpoortjie',
    'Polokwane',
    'Klerksdorp',
    'Nelspruit',
    'Pinelands',
    'Uitenhage',
    'Hermanus',
    'Kleinmond',
    'Mooi River',
    'Mookgophong',
    'Louis Trichardt',
    'Upington',
    'Dube Location',
    'Saint James',
    'Walmer Heights',
    'Kingsley',
    'Durbanville',
    'Pinetown',
    'Middelburg',
    'Vrededorp',
    'Brackenfell',
    'Ermelo',
    'Kranskop',
    'Richards Bay',
    'Oranjezicht',
    'Elarduspark',
    'Bronkhorstspruit',
    'Soshanguve',
    'Tongaat',
    'Katlehong',
    'Cullinan',
    'Bedfordview',
    'Weltevreedenpark',
    'Modderfontein',
    'Ogies',
    'Hekpoort',
    'Potchefstroom',
    'Hoedspruit',
    'Kingsmead',
    'Himeville',
    'Vryheid',
    'Emnambithi-Ladysmith',
    'Bethlehem',
    'Hluhluwe',
    'Kroonstad',
    'Philipstown',
    'Oudtshoorn',
    'Rawsonville',
    'Pringle Bay',
    'Villiersdorp',
    'Rooigrond',
    'Stilfontein',
    'Hartswater',
    'Bryanston',
    'Eloff',
    'Coega',
    'Waverley',
    'Beaufort West',
    'White River',
    'Secunda',
    'Duiwelskloof',
    'Newlands',
    'Alrode',
    'Umdloti',
    'Lynnwood',
    'Tembisa',
    'Northcliff',
    'Hilton',
    'Hout Bay',
    'Queensburgh',
    'Botshabelo',
    'Salt Rock',
    'Warner Beach',
    'Stanford',
    'KwaDukuza',
    'Worcester',
    'Shelly Beach',
    'Melkbosstrand',
    'Rosettenville',
    'Umhlanga',
    'Welkom',
    'De Aar',
    'Knysna',
    'Ixopo',
    'Lyndhurst',
    'Riebeek-Kasteel',
    'Lutzville',
    'Mafikeng',
    'Richmond',
    'Virginia',
    'Odendaalsrus',
    'Bredasdorp',
    'Plettenberg Bay',
    'New Germany',
    'Ottosdal',
    'Ladybrand',
    'Constantia',
    'Hazyview',
    'Lichtenburg',
    'Maidstone',
    'Hillcrest',
    'Montagu',
    'Howick',
    'Dullstroom',
    'Phalaborwa',
    'Welkom',
    'Senekal',
    'Verulam',
    'Standerton',
    'Umkomaas',
    'Eshowe',
    'Tzaneen',
    'Onverwacht',
    'Kriel',
    'Napier',
    'Meadowridge',
    'Empangeni',
    'Humansdorp',
    'Soweto',
    'Phoenix',
    'Atlantis',
    'Mossel Bay',
    'Welgemoed',
    'City of Cape Town',
    'Buffalo',
    'Merrivale',
    'Vredenburg',
    'Evander',
    'Umtentweni',
    'Vaalpark',
    'Three Rivers',
    'Hawston',
    'Parow',
    'Houghton Estate',
    'Muizenberg',
    "Gordon's Bay",
    'Claremont',
    'Hennenman',
    'Kuils River',
    'Overport',
    'Milnerton',
    'Deneysville',
    'Kinross',
    'Lydenburg',
    'Temba',
    'Prieska',
    'Henley on Klip',
    'Coligny',
    'Wartburg',
    'Zeerust',
    'Thabazimbi',
    'Harrismith',
    "King William's Town",
    'Kingsborough',
    'Bergvliet',
    'Westville',
    'Mmabatho',
    'Goodwood',
    'La Lucia',
    'Wilderness',
    'Phokeng',
    'Haenertsburg',
    'Balfour',
    'Grabouw',
    'Graaff Reinet',
    'Mount Edgecombe',
    'Rhodes',
    'Grahamstown',
    'La Motte',
    'Saldanha',
    'Parkview',
    'Rooihuiskraal',
    'Wynberg',
    'Middelburg',
    'Rosslyn',
    'Silverton',
    'Roggebaai',
    'Ganspan',
    'Ashton',
    'Erasmia',
    'Dundee',
    'Belhar',
    'Marshalltown',
    'Braamfontein',
    'Hogsback',
    'Alice',
    'Elsenburg',
    'Onderstepoort',
    'Monument Park',
    'Emerald Hill',
    'Elandsfontein',
    'Maraisburg',
    'Fort Cox',
    'Butterworth',
    'Thohoyandou',
    'Medunsa',
    'Schweizer-Reneke',
    'Moorreesburg',
    'Queenstown',
    'Peninsula',
    'Faure',
    'Doornfontein',
    'Waverley',
    'Mobeni',
    'Hatfield',
    'Capital Park',
    'Parktown',
    'Sandown',
    'Bathurst',
    'Vosloorus',
    'Bothasig',
    'The Rest',
    'Majuba',
    'Queenswood',
    'Mokopane',
    'Hyde Park',
    'Kalkbaai',
    'Craighall',
    'Clanwilliam',
    'Brooklyn',
    'Wonderboom',
    'Irene',
    'Kokstad',
    'Welgelegen',
    'Philadelphia',
    'Primrose',
    'Plumstead',
    'Lansdowne',
    'Edenburg',
    'Gingindlovu',
    'Malvern',
    'Elsburg',
    'Fordsburg',
    'Three Anchor Bay',
    'Saint Helena Bay',
    'Gillitts',
    'Messina',
    'Weltevreden',
    'Vaalbank',
    'Machadodorp',
    'Eersterivier',
    'Sabie',
    'Laudium',
    'Gezina',
    'Glen Austin',
    'Umbogintwini',
    'Oranjeville',
    'Carolina',
    'Swartkops',
    'Rosebank'
  ],
  'Saint Kitts and Nevis': ['Charlestown', 'Basseterre', 'Sandy Point Town'],
  Samoa: ['Apia', 'Safotulafai', ''],
  Bolivia: [
    'La Paz',
    'Santa Cruz',
    'Sucre',
    'Cochabamba',
    'Santa Cruz',
    'Potosí',
    'Oruro',
    'Guayaramerin',
    'Tarija',
    'Riberalta',
    'Trinidad',
    'Bermejo',
    'Cruz',
    'Caracasa',
    'Cobija',
    'Aroma',
    'Cotoca',
    'Anillo'
  ],
  Guernsey: ['St Peter Port', 'Torteval', 'St. Saviour'],
  Malta: [
    'Mellieha',
    'Hamrun',
    'Siggiewi',
    'Saint John',
    'Zurrieq',
    'Paola',
    'Tarxien',
    'Fgura',
    'Marsa',
    'Kirkop',
    'Birżebbuġa',
    'Gharb',
    'Mosta',
    'Munxar',
    'Rabat',
    'Attard',
    'Xaghra',
    'Naxxar',
    'Lija',
    'Imsida',
    'Birkirkara',
    'Zejtun',
    'Safi',
    'Zabbar',
    'Haz-Zebbug',
    'Victoria',
    'Sannat',
    'Valletta',
    'Cospicua',
    'San Pawl il-Bahar',
    'Sliema',
    'Qormi',
    'Mdina',
    'Marsaxlokk',
    'Floriana',
    'Luqa',
    'Marsaskala',
    'Pietà',
    'Fontana',
    'Il-Pergla',
    'Saint Venera',
    'Pembroke',
    'Mqabba',
    'Marsalforn',
    'Gzira',
    "St. Julian's",
    'Imtarfa',
    'Ghasri',
    'Hal Gharghur',
    'Saint Lucia',
    'Il-Madliena',
    'Senglea',
    'Bingemma',
    'Dingli',
    'L-Iklin',
    'Gudja',
    'Saint Lawrence',
    'Ghajnsielem',
    'Xewkija',
    'Bugibba',
    'Nadur',
    'Balzan',
    'Birgu',
    'Qrendi',
    'Ghaxaq',
    'L-Iskorvit',
    'Manikata',
    'Iz-Zebbiegh',
    "Ta' Xbiex",
    'Swieqi'
  ],
  Tajikistan: ['Dushanbe', 'Khorugh'],
  Zimbabwe: [
    'Harare',
    'Greendale',
    'Bulawayo',
    'Mutare',
    'Chinhoyi',
    'Kwekwe',
    'Victoria Falls',
    'Gwanda',
    'Mufakose'
  ],
  Liberia: ['Monrovia', 'Fish Town', 'Paynesville'],
  Ghana: [
    'Tema',
    'Accra',
    'Koforidua',
    'Sunyani',
    'Bolgatanga',
    'Winneba',
    'Home',
    'Navrongo',
    'Legon',
    'Wa',
    'Kumasi',
    'Takoradi',
    'Mampong',
    'Cape Coast',
    'Bawku',
    'Berekum'
  ],
  Tanzania: [
    'Dar es Salaam',
    'Arusha',
    'Mwanza',
    'Zanzibar',
    'Dodoma',
    'Morogoro',
    'Tanga',
    'Raha',
    'Bukoba',
    'Njombe'
  ],
  Zambia: [
    'Lusaka',
    'Mumbwa',
    'Kitwe',
    'Ndola',
    'Macha',
    'Kalomo',
    'Solwezi',
    'Siavonga',
    'Livingstone'
  ],
  Madagascar: [
    'Toamasina',
    'Antananarivo',
    'Antsirabe',
    'Ambanja',
    'Toliara',
    'Fianarantsoa',
    'Antsiranana'
  ],
  Angola: [
    'Kuito',
    'Uíge',
    'Luanda',
    'Benguela',
    'Lobito',
    'Cabinda',
    'Malanje',
    'Piri',
    'Namibe',
    'Camabatela',
    'Lucapa',
    "N'dalatando",
    'Menongue',
    'Talatona',
    'Ondjiva',
    'Cazeta',
    'Cacole',
    'Saurimo',
    'Ambriz',
    'Muxaluando',
    'Lumeje',
    'Huambo',
    'Lubango'
  ],
  Namibia: [
    'Windhoek',
    'Katima Mulilo',
    'Olympia',
    'Outapi',
    'Otjiwarongo',
    'Swakopmund',
    'Walvis Bay',
    'Oshakati',
    'Ondangwa',
    'Ongwediva',
    'Tsumeb',
    'Grootfontein',
    'Keetmanshoop',
    'Oranjemund',
    'Mpapuka',
    'Etunda'
  ],
  'Ivory Coast': ['Abidjan'],
  Sudan: ['Kassala', 'Khartoum', 'Nyala', 'Shendi', 'Thabit', 'Umm Durman'],
  Uganda: ['Kampala'],
  Cameroon: [
    'Douala',
    'Yaoundé',
    'Bafoussam',
    'Ringo',
    'Bamenda',
    'Ngaoundéré',
    'Tibati',
    'Kumba',
    'Buea',
    'Bafia',
    'Kribi'
  ],
  Malawi: ['Made', 'Mzuzu', 'Blantyre', 'Lilongwe'],
  Gabon: [
    'Libreville',
    'Moanda',
    'Mamagnia',
    'Lambaréné',
    'Gamba',
    'Port-Gentil'
  ],
  Mali: ['Bamako', ''],
  Benin: ['Cotonou', 'Abomey-Calavi'],
  Chad: ['Massaguet', 'Abéché', "N'Djamena", 'Moundou'],
  Botswana: ['Francistown', 'Gaborone', 'Orapa', 'Serowe', 'Village'],
  'Cape Verde': ['Praia'],
  Rwanda: ['Kigali', 'Nyarugunga'],
  'Republic of the Congo': ['Brazzaville', 'Pointe Noire'],
  Mozambique: [
    'Maputo',
    'Mozambique',
    'Matola',
    'Nampula',
    'Quelimane',
    'Beira',
    'Pemba',
    'Tete'
  ],
  Gambia: ['Banjul'],
  Lesotho: ['Maseru', 'Roma'],
  Mauritius: [
    'Port Louis',
    'Beau Bassin',
    'Quatre Bornes',
    'Goodlands',
    'Tamarin',
    'Rose Hill',
    'Floreal',
    'Port Mathurin',
    'Chemin Grenier',
    'Ebene CyberCity',
    'Le Reduit',
    'Vacoas',
    'Saint Jean'
  ],
  Algeria: [
    'Algiers',
    'Souk Ahras',
    'Annaba',
    'Batna City',
    'Sétif',
    'Béjaïa',
    'Bordj Bou Arreridj',
    'Bordj',
    'Tiaret',
    'Oran',
    'Saida',
    'Tlemcen',
    'Oued Smar',
    'Tizi',
    'Tizi Ouzou',
    'Azazga',
    'Blida',
    'Rouiba',
    'Hussein Dey',
    'Draria',
    'Tissemsilt',
    'El Tarf',
    'Constantine',
    'Illizi',
    'Laghouat',
    'Kouba',
    'Jijel',
    'Ghardaïa',
    'Bougara',
    'Chlef',
    'Cheraga',
    'Ouargla',
    'Relizane',
    'Djelfa',
    'Béchar',
    'Tamanghasset'
  ],
  Guinea: [
    'Conakry',
    'Forécariah',
    'Nzérékoré',
    'Labé',
    'Kankan',
    'Port Kamsar',
    'Boké',
    'Mamou',
    'Dabola',
    'Lola',
    'Sangaredi',
    'Kalia'
  ],
  Congo: ['Kinshasa', 'Lubumbashi', 'Goma', 'Banana', 'Likasi'],
  Swaziland: ['Mbabane', 'Piggs Peak', 'Lobamba', 'Kwaluseni', 'Manzini'],
  'Burkina Faso': ['Ouagadougou', 'Bobo-Dioulasso', 'Tenkodogo'],
  'Sierra Leone': ['Freetown'],
  Somalia: ['Hargeysa', 'Mogadishu'],
  Niger: ['Niamey'],
  'Central African Republic': ['Bangui'],
  Togo: ['Lomé', 'Sansanne-Mango'],
  Burundi: ['Bujumbura', ''],
  'Equatorial Guinea': ['Malabo'],
  'South Sudan': ['Juba'],
  Senegal: [
    'Sama',
    'Dakar',
    'Guediawaye',
    'Louga',
    'Kaolack',
    'Dodji',
    'Boussinki',
    'Tanaf',
    'Saint-Louis',
    'Camberene',
    'Kedougou',
    'Madina Kokoun'
  ],
  Mauritania: ['Nouakchott'],
  Djibouti: ['Djibouti'],
  Comoros: ['Moutsamoudou', 'Moroni'],
  Tunisia: [
    'Tunis',
    'Le Bardo',
    'Sousse',
    'Gafsa',
    'Monastir',
    'Hammamet',
    'Sidi Bouzid',
    'Manouba',
    'Beja',
    'Rades',
    'Ariana',
    'Sfax'
  ],
  Mayotte: [
    'Koungou',
    'Mamoudzou',
    'Tsoundzou 1',
    'Pamandzi',
    'Sada',
    'Chiconi',
    'Ouangani',
    'Tsingoni',
    'Bandaboa',
    'Dzoumonye',
    'Combani',
    'Dzaoudzi'
  ],
  Bhutan: ['Thimphu', 'Paro', 'Phuntsholing', 'Mongar'],
  Greenland: [
    'Nuussuaq',
    'Kapisillit',
    'Nuuk',
    'Maniitsoq',
    'Qeqertarsuaq',
    'Qaqortoq',
    'Ilulissat',
    'Uummannaq Kommune',
    'Qasigiannguit',
    'Paamiut',
    'Narsaq',
    'Upernavik',
    'Tasiilaq',
    'Sisimiut',
    'Aasiaat',
    'Qeqertat',
    'Narsarsuaq'
  ],
  Kosovo: ['Pristina', 'Ferizaj', 'Mitrovica', 'Dunavo', 'Prizren'],
  'Cayman Islands': [
    'Coral Gables',
    'George Town',
    'West Bay',
    'Savannah',
    'Spot Bay',
    'Bodden Town',
    'Newlands'
  ],
  Jamaica: [
    'Runaway Bay',
    'Kingston',
    'Spanish Town',
    'Montego Bay',
    'Portmore',
    'Gregory Park',
    'Gordon Town',
    'Mandeville',
    'May Pen',
    'Ocho Rios',
    'Portland Cottage',
    'Old Harbour',
    'Negril',
    'Port Maria',
    'Browns Town',
    'Moneague',
    'Black River'
  ],
  Guatemala: [
    'Huehuetenango',
    'Guatemala City',
    'Vista Hermosa',
    'El Salvador',
    'Catarina',
    'Amatitlán',
    'Mixco',
    'Melchor de Mencos',
    'Chimaltenango',
    'San Pedro Sacatepequez',
    'Antigua Guatemala',
    'Santa Catarina Pinula',
    'Quetzaltenango',
    'Quesada',
    'Santa Cruz Naranjo',
    'Villa Nueva',
    'Zacapa',
    'Ciudad Vieja',
    'Petapa',
    'San Antonio Miramar',
    'Palin',
    'La Reforma',
    'Coban',
    'Mazatenango',
    'Santa Lucia Cotzumalguapa',
    'Retalhuleu',
    'Escuintla',
    'San Marcos',
    'La Reforma',
    'San Juan',
    'Panajachel',
    'Jocotenango',
    'Santa Cruz La Laguna',
    'Esquipulas',
    'Solola',
    'Totonicapán',
    'Chiquimula',
    'Puerto Barrios',
    'Central',
    'Flores',
    'Santa Rosa',
    'San Jose Pinula',
    'El Naranjo',
    'El Limon',
    'Cambote'
  ],
  'Marshall Islands': ['Majuro', ''],
  Monaco: ['Monaco', 'Monte Carlo'],
  Anguilla: ['The Valley', 'George Hill'],
  Grenada: ['Sauteurs', "St. George's", 'Gouyave'],
  Paraguay: [
    'Coronel Oviedo',
    'Yataity',
    'Asunción',
    'Boqueron',
    'San Juan Bautista',
    'Ayolas',
    'Fernando de la Mora',
    'Nemby',
    'Santa Rosa',
    'Lambaré',
    'Hernandarias',
    'Ciudad del Este',
    'Salto del Guaira',
    'San Alberto',
    'San Lorenzo',
    'Luque',
    'Capiatá',
    'Villarrica',
    'Villa Elisa',
    'Villa Hayes',
    'Colonia Mariano Roque Alonso',
    'Encarnación',
    'Chore',
    'La Paz',
    'Fuerte Olimpo',
    'Santa Rita',
    'Presidente Franco',
    'Loma Plata',
    'Limpio',
    'Hohenau',
    'Independencia'
  ],
  Montserrat: ['Davy Hill', 'Salem'],
  'Turks and Caicos Islands': [
    'Cockburn Harbour',
    'Over Back The Damp',
    'Balfour Town'
  ],
  'Antigua and Barbuda': [
    "St. John's",
    'Old Road',
    'All Saints',
    'Woods',
    'Cassada Gardens',
    'Codrington',
    'Parham'
  ],
  Tuvalu: ['Funafuti'],
  'French Polynesia': [
    'Papeete',
    'Mahina',
    'Faaa',
    'Pirae',
    'Punaauia',
    'Arue',
    'Paeau'
  ],
  'Solomon Islands': ['Honiara', ''],
  Vanuatu: ['Port Vila', 'Level', 'Loltong'],
  Suriname: [
    'Friendship',
    'Paramaribo',
    'Onverwacht',
    'Totness',
    'Nieuw Amsterdam',
    'Moengo',
    'Botopasi',
    'Groningen',
    'Brownsweg'
  ],
  'Cook Islands': ['Avarua', 'Titikaveka'],
  Kiribati: ['Umwa Village', ''],
  Niue: ['Alofi', ''],
  Tonga: ['Neiafu', 'Kanokupolu'],
  'French Southern Territories': ['Port-aux-Francais'],
  'Norfolk Island': ['Kingston', ''],
  Turkmenistan: ['Darganata', 'Ashgabat'],
  'Pitcairn Islands': ['Adamstown'],
  'San Marino': [
    'Falciano',
    'Fiorentino',
    'San Marino',
    'Serravalle',
    'Acquaviva'
  ],
  Åland: [
    'Mariehamn',
    'Eckerö',
    'Lemland',
    'OEdkarby',
    'Godby',
    'Gottby',
    'Jurmo',
    'Saltvik',
    'Hammarland'
  ],
  'Faroe Islands': [
    'Strendur',
    'Tórshavn',
    'Leirvik',
    'Saltangara',
    'Hoyvík',
    'Argir',
    'Hvalba',
    'Klaksvík',
    'Innan Glyvur',
    'Signabour',
    'Glyvrar'
  ],
  'Svalbard and Jan Mayen': ['Longyearbyen'],
  'Cocos [Keeling] Islands': ['West Island'],
  Nauru: ['Anabar'],
  'South Georgia and the South Sandwich Islands': ['Grytviken'],
  'U.S. Minor Outlying Islands': [''],
  'Sint Maarten': ['Philipsburg', ''],
  'Guinea-Bissau': ['Cacheu', 'Bissau', ''],
  'Saint Martin': ['Marigot', 'Anse Marcel'],
  'Saint Vincent and the Grenadines': ['Kingstown', 'Georgetown', 'Union', ''],
  'Saint Pierre and Miquelon': [''],
  'Saint-Barthélemy': ['Grand Cul-de-Sac', 'Gustavia', ''],
  Dominica: ['Berekua', 'Roseau', 'Marigot'],
  'São Tomé and Príncipe': ['Neves', 'São Tomé', ''],
  'Falkland Islands': ['Stanley'],
  'Northern Mariana Islands': ['Saipan'],
  'East Timor': ['Dili', ''],
  Bonaire: ['Kralendijk', 'Dorp Nikiboko'],
  'American Samoa': ['American Samoa', 'Pago Pago'],
  'Federated States of Micronesia': ['Yap'],
  Palau: [''],
  Guyana: ['Georgetown', 'New Amsterdam', 'Linden'],
  Honduras: [
    'Tegucigalpa',
    'San Pedro Sula',
    'Morazan',
    'La Ceiba',
    'Santa Barbara',
    'Copán',
    'Coxen Hole',
    'El Progreso',
    'San Antonio de Flores',
    'Piraera',
    'La Hacienda',
    'Comayaguela',
    'Choloma',
    'Comayagua',
    'Nacaome',
    'Pinalejo',
    'Puerto Lempira',
    'Sula',
    'El Barro',
    'El Paraiso'
  ],
  Nicaragua: [
    'Managua',
    'León',
    'Los Arados',
    'Rivas',
    'Ocotal',
    'San Juan del Sur',
    'Granada',
    'Bluefields',
    'Chinandega',
    'Esteli',
    'Masaya',
    'Matagalpa',
    'Jinotega',
    'El Panama'
  ],
  'El Salvador': [
    'San Salvador',
    'San Miguel',
    'La Libertad',
    'Ilopango',
    'Antiguo Cuscatlan',
    'Soyapango',
    'Santa Tecla',
    'Botoncillal El Botoncillo',
    'Mejicanos',
    'Nuevo Cuscatlan',
    'San Jose Villanueva',
    'Zaragoza',
    'Santa Ana',
    'Gigante',
    'Colonia Escalon',
    'Santiago Texacuangos',
    'Ayutuxtepeque',
    'Apopa',
    'Coatepeque',
    'San Marcos',
    'Usulutan',
    'Ozatlan',
    'Delgado',
    'Guazapa',
    'Apaneca',
    'Ahuachapan',
    'Sonsonate',
    'Lourdes',
    'Colon',
    'Cuscatancingo'
  ],
  Andorra: [
    'Andorra la Vella',
    'Sant Juliàde Lòria',
    'Escaldes-Engordany',
    'Engordany',
    'Encamp',
    'Ordino',
    'La Massana',
    'Santa Coloma',
    'Canillo',
    'Llorts',
    'Soldeu',
    'Sispony'
  ],
  'Myanmar [Burma]': [
    'Hlaing',
    'Yangon',
    'Kyauktada',
    'Inya',
    'Mandalay',
    'Wagan'
  ],
  'Sri Lanka': [
    'Colombo',
    'Katubedda',
    'Kandy',
    'Pannipitiya',
    'Makola South',
    'Wattala',
    'Maharagama',
    'Wellampitiya',
    'Kaduwela',
    'Battaramulla South',
    'Nugegoda',
    'Sri Jayewardenepura Kotte',
    'Homagama',
    'Dehiwala',
    'Orugodawatta',
    'Kohuwala',
    'Piliyandala',
    'Dehiwala-Mount Lavinia',
    'Ragama',
    'Negombo',
    'Boralesgamuwa South',
    'Moratuwa',
    'Badulla',
    'Padukka',
    'Kandana',
    'Hekitta',
    'Rajagiriya',
    'Gampaha',
    'Nattandiya Town',
    'Matale',
    'Peradeniya',
    'Eppawala',
    'Talapathpitiya',
    'Katunayaka',
    'Kelaniya',
    'Gangodawila North',
    'Biyagama'
  ],
  Haiti: [
    'Port-au-Prince',
    'Cap-Haïtien',
    'Delmar',
    'Carrefour',
    'Petionville',
    'Moise',
    'Jacmel',
    'Masseau',
    'Prince',
    'Turgeau',
    'Duverger'
  ],
  'Trinidad and Tobago': [
    'Arima',
    'San Fernando',
    'Princes Town',
    'Piarco',
    'Rio Claro',
    'Port of Spain',
    'Victoria',
    'Maraval',
    'Fyzabad',
    'Debe',
    'Couva',
    'Diego Martin',
    'Chaguanas',
    'Penal',
    'Cunupia',
    'Curepe',
    'Roxborough',
    'San Juan',
    'Arouca',
    'Saint Joseph',
    'California',
    'Marabella',
    'Siparia',
    'Gasparillo',
    'Morvant',
    'Barataria',
    'Saint Clair',
    'Laventille',
    'Carenage',
    'Ward of Tacarigua',
    'Caroni',
    'Lopinot',
    'Tunapuna',
    'Santa Cruz',
    'Saint Augustine',
    'Golden Lane',
    'Scarborough',
    'Moriah',
    'Saint James',
    'Carapichaima',
    'Valsayn',
    'Freeport',
    'Claxton Bay',
    'Sangre Grande',
    'Cumuto',
    'Woodbrook',
    'Petit Valley',
    'El Dorado',
    'Phoenix Park'
  ],
  Laos: ['Vientiane'],
  Uruguay: [
    'Montevideo',
    'La Floresta',
    'Barra de Carrasco',
    'Paysandú',
    'Salto',
    'Las Piedras',
    'Tacuarembó',
    'Toledo',
    'Colonia del Sacramento',
    'Mercedes',
    'Union',
    'Florida',
    'Maldonado',
    'Canelones',
    'La Paz',
    'San Carlos',
    'Durazno',
    'Punta del Este'
  ],
  Eritrea: ['Asmara', ''],
  Cuba: [
    'Havana',
    'Habana',
    'La Habana',
    'Matanzas',
    'Villa',
    'Bayamo',
    'Cienfuegos',
    'Santiago de Cuba',
    'Holguín',
    'Ciego de Ávila',
    'Pinar del Río',
    'Sancti Spíritus',
    'Camagüey',
    'Las Tunas',
    'Guantánamo',
    'Varadero'
  ],
  'Saint Helena': ['Tristan Da Cunha', 'Jamestown'],
  'Christmas Island': ['Flying Fish Cove'],
  Ethiopia: ['Addis Ababa', 'Awasa', 'Jijiga'],
  'British Indian Ocean Territory': ['']
};
