import { Box } from '@mui/material';
import GetFundingForm from 'components/FundingForm';
import WidgetWrapper from 'components/WidgetWrapper';

const GetFundingWidget = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll',
        width: '100%'
      }}
    >
      <WidgetWrapper>
        <GetFundingForm  isOffering={false}/>
      </WidgetWrapper>
    </Box>
  );
};

export default GetFundingWidget;
