import { Typography, useTheme } from '@mui/material';
import Friend from 'components/Friend';
import WidgetWrapper from 'components/WidgetWrapper';
import { getDisplayNames } from 'utils/helperFunction';

const FundingWidget = ({
  amount,
  business,
  description,
  isOffering,
  occupation,
  subOccupation,
  userId
}) => {
  const { palette } = useTheme();
  const main = palette.neutral.main;
  if(!userId || !business) return null;

  return (
    <WidgetWrapper m='2rem 0'>
      <Friend
        friendId={userId._id}
        name={getDisplayNames(userId.firstName, userId.lastName)}
        // subtitle={location}
        userPicturePath={userId.picturePath}
      />
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Business Name:</b> {business.name.name}
      </Typography>

      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Business Description:</b> {description}
      </Typography>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Occupation:</b> {occupation}
      </Typography>
      {subOccupation && (
        <Typography color={main} sx={{ mt: '1rem' }}>
          <b>Sub Occupation:</b> {subOccupation}
        </Typography>
      )}
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Amount:</b> ${amount}
      </Typography>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Offering:</b> {isOffering ? 'Yes' : 'No'}
      </Typography>
    </WidgetWrapper>
  );
};

export default FundingWidget;
