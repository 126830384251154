import { Box, Typography } from "@mui/material";
import { useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import GroupBoxWidget from "./GroupBoxWidget";
import QuickSearch from "components/QuickSearch";

const MyGroupsWidget = ({ userGroups }) => {
  const { _id } = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    setFilteredGroups(
      groups.filter((group) =>
        group.name.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  }, [keyword, groups]);

  useEffect(() => {
    getGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getGroups = async () => {
    try {
      setIsLoading(true);
      const response = userGroups
        ? await axiosHelper(API_METHOD.GET, "groups", `${_id}`)
        : await axiosHelper(API_METHOD.GET, "groups", "");
      setIsLoading(false);
      if (response.isSuccess) {
        setGroups(response.group);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box
      sx={{
        height: "calc(100vh - 200px)",
        overflowY: "scroll",
      }}
    >
      <QuickSearch
        title="Search Groups"
        keyword={keyword}
        setKeyword={setKeyword}
      />
      {filteredGroups.length > 0 ? (
        filteredGroups.map((group) => (
          <GroupBoxWidget key={group._id} group={group} />
        ))
      ) : (
        <Typography sx={{ textAlign: 'center', my: 2 }}>
          No groups found.
        </Typography>
      )}
    </Box>
  );
};

export default MyGroupsWidget;
