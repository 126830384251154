import {
  Avatar,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QuickSearch from "components/QuickSearch";

const GroupListWidget = ({ groups, isLoading }) => {
  const [keyword, setKeyword] = useState("");
  const [filteredGroups, setFilteredGroups] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredGroups(
      groups.filter((group) =>
        group.name.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  }, [keyword, groups]);

  if (isLoading) return <div>Loading...</div>;
  return (
    <WidgetWrapper
      sx={{
        height: "100vh",
        overflowY: "scroll",
        position: "sticky",
        top: "0",
      }}
    >
      <QuickSearch
        title="Groups you have joined"
        keyword={keyword}
        setKeyword={setKeyword}
      />

      <Box sx={{ my: 2, gap: 5 }}>
        {filteredGroups.length > 0 &&
          filteredGroups.map((group) => (
            <Grid
              container
              spacing={2}
              onClick={() => navigate(`/group/${group._id}`)}
              sx={{
                cursor: "pointer",
                border: `1px solid ${
                  window.location.pathname === `/group/${group._id}` &&
                  theme.palette.primary.light
                }`,
                marginY: 2,
                // hover
                "&:hover": {
                  boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                },
                // background for selected
                // background: `${
                //   window.location.pathname === `/group/${group._id}` &&
                //   theme.palette.primary.light
                // }`
              }}
            >
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Avatar src={group.groupCoverImage} />
                  <Stack direction="column" spacing={1}>
                    <Typography variant="h4" fontWeight="500">
                      {group.name}
                    </Typography>
                    <Typography variant="caption">
                      {" "}
                      {group.description}
                    </Typography>
                    <Typography variant="caption">
                      {group.groupMembers.length} Members
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          ))}
      </Box>
    </WidgetWrapper>
  );
};

export default GroupListWidget;
