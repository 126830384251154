import { Box, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFriends } from "state/auth";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";

const FriendListWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { user } = useAppSelector((state) => state.auth); // Assuming token is used inside axiosHelper
  const friends = user.friends;

  const getFriends = async () => {
    const response = await axiosHelper(
      API_METHOD.GET,
      "users",
      `${userId}/friends`
    );
    if (response.isSuccess) {
      dispatch(setFriends({ friends: response.friends }));
    }
  };

  useEffect(() => {
    if (userId) {
      getFriends();
    }
  }, [userId]); // Correct dependency to trigger re-fetch when userId changes

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Connection List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {friends && friends.length > 0 ? (
          friends.map((friend) => (
            <Friend
              key={friend._id}
              friendId={friend._id}
              name={`${friend.firstName} ${friend.lastName}`}
              subtitle={friend.occupation}
              userPicturePath={friend.picturePath}
            />
          ))
        ) : (
          <Typography color={palette.text.primary} sx={{ textAlign: "center" }}>
            No connections
          </Typography>
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidget;
