import { Box } from '@mui/material';

const UserImage = ({ image, size = '60px' }) => {
  return (
    <Box width={size} height={size}>
      <img
        style={{ objectFit: 'cover', borderRadius: '50%' }}
        width={size}
        height={size}
        alt='user'
        src={image}
        onError={e => {
          e.target.onerror = null;
          e.target.src = 'https://i.imgur.com/7IyjN2j.png';
         
        }
        }
      />
    </Box>
  );
};

export default UserImage;
