export const companysizeData = [
  {
    id: 1,
    name: '1-10'
  },
  {
    id: 2,
    name: '11-50'
  },
  {
    id: 3,
    name: '51-200'
  },
  {
    id: 4,
    name: '201-500'
  },
  {
    id: 5,
    name: '501-1000'
  },
  // more than 1000
  {
    id: 6,
    name: '1000+'
  }
];

export const OCCUPATION_DATA = [
  {
    name: 'information technology',
    sublist: [
      {
        name: 'network administration'
      },
      {
        name: 'software development'
      },
      {
        name: 'database administration'
      },
      {
        name: 'cloud computing'
      },
      {
        name: 'cybersecurity'
      },
      {
        name: 'artificial intelligence'
      },
      {
        name: 'web development'
      },
      {
        name: 'data analysis'
      }
    ]
  },
  {
    name: 'finance',
    sublist: [
      {
        name: 'investment banking'
      },
      {
        name: 'corporate finance'
      },
      {
        name: 'financial planning'
      },
      {
        name: 'insurance'
      },
      {
        name: 'accounting'
      },
      {
        name: 'taxation'
      },
      {
        name: 'venture capital'
      }
    ]
  },
  {
    name: 'healthcare',
    sublist: [
      {
        name: 'nursing'
      },
      {
        name: 'physician'
      },
      {
        name: 'physical therapy'
      },
      {
        name: 'medical research'
      },
      {
        name: 'occupational therapy'
      },
      {
        name: 'pharmacy'
      },
      {
        name: 'healthcare administration'
      }
    ]
  },
  {
    name: 'education',
    sublist: [
      {
        name: 'teaching'
      },
      {
        name: 'curriculum development'
      },
      {
        name: 'educational administration'
      },
      {
        name: 'special education'
      },
      {
        name: 'instructional design'
      },
      {
        name: 'education technology'
      }
    ]
  },
  {
    name: 'engineering',
    sublist: [
      {
        name: 'civil engineering'
      },
      {
        name: 'mechanical engineering'
      },
      {
        name: 'electrical engineering'
      },
      {
        name: 'chemical engineering'
      },
      {
        name: 'software engineering'
      },
      {
        name: 'aerospace engineering'
      },
      {
        name: 'biomedical engineering'
      },
      {
        name: 'environmental engineering'
      }
    ]
  },
  {
    name: 'marketing',
    sublist: [
      {
        name: 'digital marketing'
      },
      {
        name: 'brand management'
      },
      {
        name: 'public relations'
      },
      {
        name: 'market research'
      },
      {
        name: 'social media management'
      },
      {
        name: 'product management'
      }
    ]
  },
  {
    name: 'human resources',
    sublist: [
      {
        name: 'talent acquisition'
      },
      {
        name: 'employee relations'
      },
      {
        name: 'compensation and benefits'
      },
      {
        name: 'training and development'
      },
      {
        name: 'organizational development'
      },
      {
        name: 'diversity and inclusion'
      }
    ]
  },
  {
    name: 'sales',
    sublist: [
      {
        name: 'inside sales'
      },
      {
        name: 'outside sales'
      },
      {
        name: 'key account management'
      },
      {
        name: 'retail sales'
      },
      {
        name: 'sales operations'
      },
      {
        name: 'business development'
      },
      {
        name: 'channel sales'
      },
      {
        name: 'sales engineering'
      }
    ]
  },
  {
    name: 'health and fitness',
    sublist: [
      {
        name: 'personal training'
      },
      {
        name: 'yoga instruction'
      },
      {
        name: 'group fitness instruction'
      },
      {
        name: 'pilates instruction'
      },
      {
        name: 'physical therapy'
      },
      {
        name: 'nutrition counseling'
      },
      {
        name: 'sports coaching'
      },
      {
        name: 'massage therapy'
      }
    ]
  },
  {
    name: 'health and fitness',
    sublist: [
      {
        name: 'personal training'
      },
      {
        name: 'yoga instruction'
      },
      {
        name: 'group fitness instruction'
      },
      {
        name: 'pilates instruction'
      },
      {
        name: 'physical therapy'
      },
      {
        name: 'nutrition counseling'
      },
      {
        name: 'sports coaching'
      },
      {
        name: 'massage therapy'
      }
    ]
  },
  {
    name: 'arts and entertainment',
    sublist: [
      {
        name: 'performing arts'
      },
      {
        name: 'fine arts'
      },
      {
        name: 'film and television'
      },
      {
        name: 'music'
      },
      {
        name: 'photography'
      },
      {
        name: 'writing and editing'
      },
      {
        name: 'graphic design'
      },
      {
        name: 'animation and gaming'
      }
    ]
  },
  {
    name: 'legal',
    sublist: [
      {
        name: 'lawyer'
      },
      {
        name: 'paralegal'
      },
      {
        name: 'legal secretary'
      },
      {
        name: 'court reporter'
      },
      {
        name: 'legal consultant'
      },
      {
        name: 'mediation and arbitration'
      },
      {
        name: 'intellectual property'
      },
      {
        name: 'compliance and ethics'
      }
    ]
  },
  {
    name: 'customer service',
    sublist: [
      {
        name: 'technical support'
      },
      {
        name: 'customer success'
      },
      {
        name: 'call center operations'
      },
      {
        name: 'customer experience'
      }
    ]
  },
  {
    name: 'Operations',
    sublist: [
      {
        name: 'Supply chain management'
      },
      {
        name: 'Logistics'
      },
      {
        name: 'Procurement'
      },
      {
        name: 'Project management'
      },
      {
        name: 'Quality control'
      }
    ]
  },
  {
    name: 'Creative',
    sublist: [
      {
        name: 'Graphic design'
      },
      {
        name: 'Video production'
      },
      {
        name: 'Photography'
      },
      {
        name: 'Animation'
      },
      {
        name: 'Writing and editing'
      }
    ]
  },
  {
    name: 'Media and Communications',
    sublist: [
      {
        name: 'Journalism'
      },
      {
        name: 'Public relations'
      },
      {
        name: 'Broadcasting'
      },
      {
        name: 'Advertising'
      },
      {
        name: 'Content creation'
      }
    ]
  },
  {
    name: 'Manufacturing',
    sublist: [
      {
        name: 'Production Planning'
      },
      {
        name: 'Quality Assurance'
      },
      {
        name: 'Process Engineering'
      },
      {
        name: 'Assembly'
      },
      {
        name: 'Machining'
      },
      {
        name: 'Packaging'
      },
      {
        name: 'Maintenance and Repair'
      }
    ]
  }
];

export const VISIBILITY_DATA = ["public", "private", "hidden"]



export const COLLEGE_DATA = [
  {
    "name": "All Souls College"
  },
  {
    "name": "Balliol College"
  },
  {
    "name": "Brasenose College"
  },
  {
    "name": "Christ Church"
  },
  {
    "name": "Corpus Christi College"
  },
  {
    "name": "Exeter College"
  },
  {
    "name": "Green Templeton College"
  },
  {
    "name": "Harris Manchester College"
  },
  {
    "name": "Hertford College"
  },
  {
    "name": "Jesus College"
  },
  {
    "name": "Keble College"
  },
  {
    "name": "Kellogg College"
  },
  {
    "name": "Lady Margaret Hall"
  },
  {
    "name": "Linacre College"
  },
  {
    "name": "Lincoln College"
  },
  {
    "name": "Magdalen College"
  },
  {
    "name": "Mansfield College"
  },
  {
    "name": "Merton College"
  },
  {
    "name": "New College"
  },
  {
    "name": "Nuffield College"
  },
  {
    "name": "Oriel College"
  },
  {
    "name": "Pembroke College"
  },
  {
    "name": "The Queen's College"
  },
  {
    "name": "Regent's Park College"
  },
  {
    "name": "St Anne's College"
  },
  {
    "name": "St Antony's College"
  },
  {
    "name": "St Catherine's College"
  },
  {
    "name": "St Cross College"
  },
  {
    "name": "St Edmund Hall"
  },
  {
    "name": "St Hilda's College"
  },
  {
    "name": "St Hugh's College"
  },
  {
    "name": "St John's College"
  },
  {
    "name": "St Peter's College"
  },
  {
    "name": "Somerville College"
  },
  {
    "name": "Trinity College"
  },
  {
    "name": "University College"
  },
  {
    "name": "Wadham College"
  },
  {
    "name": "Wolfson College"
  },
  {
    "name": "Worcester College"
  }
]


export const MATRICULATION_YEAR_DATA = [
  1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959,
  1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969,
  1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979,
  1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
  1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
  2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
]