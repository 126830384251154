
// Client socket configuration
import io from "socket.io-client";



const baseUrl = process.env.REACT_APP_SOCKET_IO_URL || 'wss://oxsaid.net';
const socket = io(baseUrl, {
  path: '/ws/socket.io/', // Correct path setup
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ["websocket"],
});

socket.on("connect", () => {
  console.log("socket connected");
});

socket.on("connect_error", (error) => {
  console.log("socket connect error", error);
});

socket.on("disconnect", () => {
  if (localStorage.userId) {
    socket.emit("user-setOffline", localStorage.userId);
  }
});

export default socket;
