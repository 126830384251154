const stringConstants = {
  required: {
    name: "Name is required!",
    joinLink: "Join Link is required",
    time: "Time is required",
    date: "Date is required!",
    is_private: "Event Permission is required!",
  },
  title: {
    joining_link: "Joining Link",
  },
  join_link_placeholder: "Enter your joining link",
  join_link_helper_text:
    "Enter your meeting link of Zoom, Google Meet, Teams, etc.",
  add_event: "Add Event",
  cancel: "Cancel",
};

export default stringConstants;
