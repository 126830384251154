import { useEffect, useState } from 'react';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import FundingCardWidget from './FundingCardWidget';

const FundingsWidget = () => {
  const [fundings, setFundings] = useState([]);

  useEffect(() => {
    getFundings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFundings = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'fundings', '');
      if (response.isSuccess) {
        setFundings(response.fundings);
        // dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {fundings.length > 0 ? (
        fundings.map(({
          amount,
          name,
          description,
          isOffering,
          occupation,
          subOccupation,
          userId
        }) => (
          <FundingCardWidget
            key={userId} // It's better to have a unique ID for each funding, if possible
            amount={amount}
            business={name}
            description={description}
            isOffering={isOffering}
            occupation={occupation}
            subOccupation={subOccupation}
            userId={userId}
          />
        ))
      ) : (
        <p>No funding found</p>
      )}
    </>
  );
};

export default FundingsWidget;
