import React, { useState } from 'react';
import { Modal, Box, Typography, Checkbox, Button, Backdrop } from '@mui/material';
import toast from 'react-hot-toast';

const TermsAndConditionModal = ({ handleClose, onSend }) => {
  const [isChecked1, setChecked1] = useState(false);
  const [isChecked2, setChecked2] = useState(false);

  const handleSend = () => {
    if (isChecked1 && isChecked2) {
      // Perform the action to send the email invitation
      onSend();
      handleClose(); // Close the modal after sending
    } else {
      // Handle the case where the checkboxes are not checked
      toast.error('Please check both checkboxes before sending the invitation.', {
        position: 'top-right',
      });
    }
  };

  return (
    <div>
      <Modal
        open={true} // Assuming you want it open by default
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        BackdropComponent={Backdrop}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Invitation Confirmation
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Users only get to invite 2 other users to the platform. We recommend inviting someone that you think will find the platform valuable.
          </Typography>

          {/* Checkbox 1 */}
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mt: 2 
            }}
            >
          <Checkbox
            checked={isChecked1}
            onChange={(e) => setChecked1(e.target.checked)}
            color='primary'
          />
          <Typography>
            I can vouch for the character of the user.
          </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 2,
            }}
          >
          {/* Checkbox 2 */}
          <Checkbox
            checked={isChecked2}
            onChange={(e) => setChecked2(e.target.checked)}
            color='primary'
          />
          <Typography>
            I can confirm that the user was matriculated into {process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} University and graduated from a recognized program there.
          </Typography>
          </Box>

          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant='contained' color='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handleSend}>
              Send Invitation
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop open={true} />
    </div>
  );
};

export default TermsAndConditionModal;
