import { Cancel, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import GroupCover from "components/GroupCover";
import WidgetWrapper from "components/WidgetWrapper";
import { useAppSelector } from "hooks/useRedux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GroupHeaderWidget = ({ group, value, handleChange }) => {
  const theme = useTheme();
  const { elum, _id } = useAppSelector((state) => state.auth.user);

  const isMember = group.groupMembers.some((member) => member._id === _id);

  const [edit, setEdit] = useState(false);
  const [groupState, setgroupState] = useState(group);
  const [loading, setLoading] = useState({
    leave: false,
    delete: false,
    update: false,
  });

  const navigate = useNavigate();

  const leaveGroup = async () => {
    setLoading({ ...loading, leave: true });
    const res = await axiosHelper(
      API_METHOD.PUT,
      "groups",
      `${group._id}/leave`,
      {
        userId: _id,
      }
    );
    setLoading({ ...loading, leave: false });
    navigate("/groups");
  };

  const joinGroup = async () => {
    setLoading({ ...loading, join: true });
    const res = await axiosHelper(
      API_METHOD.PUT,
      "groups",
      `join`,
      {
        userId: _id,
        groupId: group._id,
      }
    );
    setLoading({ ...loading, join: false });
    navigate("/groups");
  };


  const deleteGroup = async () => {
    setLoading({ ...loading, delete: true });
    const res = await axiosHelper(API_METHOD.DELETE, "groups", `${group._id}`);
    setLoading({ ...loading, delete: false });
    navigate("/groups");
  };
  const updateNameAndDescription = async () => {
    setLoading({ ...loading, update: true });
    const res = await axiosHelper(
      API_METHOD.PUT,
      `groups`,
      `${group._id}/update`,

      groupState
    );
    setLoading({ ...loading, update: false });
    setEdit(false);
  };

  // const isAdmin = group.admins.includes(group._id);

  if (!group) return <div>loading...</div>;
  const isAdmin = group.adminMembers.some((admin) => admin._id === _id);

  return (
    <WidgetWrapper>
      <Box>
        <GroupCover imageUrl={group.groupCoverImage} />
        {edit && isAdmin ? (
          <Stack direction="column" spacing={1} width="30%">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              py={2}
            >
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={groupState.name}
                onChange={(e) =>
                  setgroupState({ ...groupState, name: e.target.value })
                }
              />

              <IconButton aria-label="edit" size="large">
                <Cancel onClick={() => setEdit(false)} />
              </IconButton>
            </Stack>
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              value={groupState.description}
              onChange={(e) =>
                setgroupState({ ...groupState, description: e.target.value })
              }
            />
            <Button
              variant="contained"
              color="primary"
              disabled={loading.update}
              onClick={updateNameAndDescription}
            >
              {loading.update ? "Updating..." : "Update"}
            </Button>
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              py={2}
            >
              <Typography variant="h1" fontWeight="700">
                {groupState.name}
              </Typography>
              {isAdmin && (
                <IconButton
                  aria-label="edit"
                  size="large"
                  onClick={() => setEdit(true)}
                >
                  <Edit />
                </IconButton>
              )}
            </Stack>
            <Typography
              variant="h6"
              ml={0.5}
              mb={3}
              fontSize={15}
              color={"gray"}
              fontWeight="400"
            >
              {groupState.description}
            </Typography>
          </>
        )}
      </Box>
      <Grid container>
        <Grid item xs={4} md={6}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* <Tab label='Groups' {...a11yProps(0)} /> */}
              <Tab label="Discussions" {...a11yProps(0)} />
              <Tab label="Group Members" {...a11yProps(1)} />
              <Tab label="Group Chat" {...a11yProps(2)} />
              <Tab label="Events" {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={8} md={6}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {isAdmin ? (
              <Button
                variant="contained"
                color="error"
                disabled={loading.delete}
                onClick={deleteGroup}
              >
                {loading.delete ? "Deleting..." : "Delete Group"}
              </Button>
            ) : isMember ? (
              <Button
                disabled={loading.leave}
                variant="outlined"
                color="error"
                onClick={leaveGroup}
              >
                {loading.leave ? "Leaving..." : "Leave Group"}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={joinGroup}
              >
                Join Group
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </WidgetWrapper>
  );
};

export default GroupHeaderWidget;
