import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: 'light',
  user: null,
  token: null,
  posts: [],
  businesses: [],
  myBusinesses: [],
  jobs: [],
  countries: [],
  allUsers: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMode: state => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem('USER_TOKEN', action.payload.token);
    },
    setLogout: state => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('USER_TOKEN');
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error('user friends non-existent :(');
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map(post => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    removePost: (state, action) => {
      const updatedPosts = state.posts.filter(post => post._id !== action.payload.postId);
      state.posts = updatedPosts;
    },
    setBusinesses: (state, action) => {
      state.businesses = action.payload.businesses;
    },
    setBusiness: (state, action) => {
      const updatedbusinesses = state.businesses.map(business => {
        if (business._id === action.payload.business._id)
          return action.payload.business;
        return business;
      });
      state.businesses = updatedbusinesses;
    },
    setMyBusinesses: (state, action) => {
      state.myBusinesses = action.payload.myBusinesses;
    },
    setJobs: (state, action) => {
      state.jobs = action.payload.jobs;
    },
    loadUser: (state, action) => {
      state.user = action.payload.user;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload.allUsers;
    }
  }
});

export const {
  setMode,
  setLogin,
  setLogout,
  setFriends,
  setPosts,
  setPost,
  setBusiness,
  setBusinesses,
  setMyBusinesses,
  removePost,
  setJobs,
  loadUser,
  setAllUsers
} = authSlice.actions;
export default authSlice.reducer;
