import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { Formik, isString } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBusinesses, setMyBusinesses } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { OCCUPATION_DATA, VISIBILITY_DATA, companysizeData } from 'utils/data';
import { capitalize } from 'utils/helperFunction';
import * as yup from 'yup';

const initialValuesRegister = {
  description: '',
  businessNameId: '',
  amount: '',
  occupation: '',
  subOccupation: '',
  isOffering: false,
  isSeeking: false,
  visibility: '',
  isAlumniOwned: false,
  isLessThanTwoYears: false,
  yearFounded: '',
  size: '',
  email: '',
  phone: '',
  address: '',
  name: '',
  websiteUrl: ''


};

const BusinessForm = ({ selectedBusiness, setselectedBusiness }) => {
  const [loading, setLoading] = useState({
    register: false,
    page: false
  });
  const [businessName, setBusinessName] = useState([]);
  const { user, myBusinesses, businesses } = useAppSelector(
    state => state.auth
  );
  const dispatch = useDispatch();
  const wordCount = (str) => (str ? str.split(/\s+/).length : 0);

 
const registerSchema = yup.object().shape({
  name: yup.string()
    .required('Name is required')
    .max(30, 'Maximum 30 characters are allowed'),
  size: yup.number().required('Size is required'),
  email: yup.string().email('Invalid email'),
  address: yup.string()
    .required('Address is required')
    .test(
      'wordCount',
      'Address must be between 3 and 300 words',
      value => {
        const count = wordCount(value);
        return count >= 3 && count <= 300;
      }
    ),
  description: yup.string(),
  phone: yup.string()
    .required('Phone is required')
    .matches(/^\+?(\(\d+\)[\s-]*)?(\d[\d\s-]*)$/, 'Phone number must be numeric and can include spaces, dashes, parentheses'),
  isAlumniOwned: yup.boolean(),
  occupation: yup.string().required('Occupation is required'),
  isLessThanTwoYears: yup.boolean(),
  yearFounded: yup
    .number()
    .when('isLessThanTwoYears', {
      is: true,
      then: yup.number().required('Year founded is required')
    })
    .typeError('Year founded must be a number'),
  visibility: yup.string().required('Visibility is required'),
  websiteUrl: yup.string().url('Invalid URL'),
});

  const getBusinessNames = async () => {
    try {
      setLoading({ ...loading, page: true });
      const response = await axiosHelper(API_METHOD.GET, 'businesses', 'names');
      if (response.isSuccess) {
        setBusinessName(response.businessNames);
      }
      setLoading({ ...loading, page: false });
      // dispatch(setBusinesses({ businesses: response.data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    if (selectedBusiness) {
      await editMyBusiness(values, resetForm);
      setSubmitting(false);
    } else {
      try {
        setLoading({ ...loading, register: true });
        let res;
        try {
          res = await axiosHelper(API_METHOD.POST, 'businesses', '', {
            ...values,
            userId: user && user._id
          });
        } catch (error) {
          console.log(error);
        }

        resetForm();
        if (res.isSuccess) {
          dispatch(
            setBusinesses({ businesses: [...businesses, res.business] })
          );
          dispatch(
            setMyBusinesses({ myBusinesses: [...myBusinesses, res.business] })
          );
        }
        setLoading({ ...loading, register: false });
        setSubmitting(false);

        // await register(values, onSubmitProps);
      } catch (error) {
        console.log(error);
        setSubmitting(false);
      }
    }
  };

  const editMyBusiness = async (values, resetForm) => {
    try {
      setLoading({ ...loading, register: true });
      const res = await axiosHelper(
        API_METHOD.PUT,
        `businesses/${selectedBusiness._id}`,
        '',
        {
          ...values,
          userId: user._id
        }
      );
      if (res.isSuccess) {
        const updatedBusiness = res.business;
        const updatedMyBusinesses = myBusinesses.map(business =>
          business._id === updatedBusiness._id ? updatedBusiness : business
        );
        const updatedBusinesses = businesses.map(business =>
          business._id === updatedBusiness._id ? updatedBusiness : business
        );
        dispatch(setBusinesses({ businesses: updatedBusinesses }));

        dispatch(setMyBusinesses({ myBusinesses: updatedMyBusinesses }));
        setselectedBusiness(undefined);
        resetForm();
      }

      setLoading({ ...loading, register: false });
    } catch (error) {}
  };

  useEffect(() => {
    getBusinessNames();
  }, []);

  if (loading.page) return <div>Loading...</div>;


 

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={
        selectedBusiness
          ? {
              name: selectedBusiness.name.name,
              size: selectedBusiness.size,
              email: selectedBusiness.email,
              address: selectedBusiness.address,
              description: selectedBusiness.description,
              phone: selectedBusiness.phone,
              isAlumniOwned: selectedBusiness.isAlumniOwned,
              occupation: selectedBusiness.occupation,
              subOccupation: selectedBusiness.subOccupation,
              isLessThanTwoYears: selectedBusiness.isLessThanTwoYears,
              yearFounded: selectedBusiness.yearFounded,
              visibility: selectedBusiness.visibility,
              websiteUrl: selectedBusiness.websiteUrl
            }
          : initialValuesRegister
      }
      validationSchema={registerSchema}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Autocomplete
              name='name'
              value={values.name}
              onChange={(event, newValue) => {
                setFieldValue('name', newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setFieldValue('name', newInputValue);
              }}
              freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={businessName
                .map(option => option.name)
                .sort((a, b) => b.localeCompare(a))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Business Name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  helperText={touched.name ? errors.name : ''}
                  error={touched.name && Boolean(errors.name)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />
            <TextField
              label='Business Address'
              onBlur={handleBlur}
              onChange={handleChange}
               error={touched.address && Boolean(errors.address)}
              helperText={touched.address && errors.address}
              value={values.address}
              name='address'
            />
            <TextField
              label='Business Phone'
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
              value={values.phone}
              name='phone'
            />
            <TextField
              label='Business Email'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name='email'
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
            />
            <TextField
              label='Business Description'
              onBlur={handleBlur}
              multiline
              rows={4}
              onChange={handleChange}
              value={values.description}
              name='description'
            />
            <TextField
              label='Business Website URL'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.websiteUrl}
              name='websiteUrl'
              helperText={touched.websiteUrl ? errors.websiteUrl : ''}
              error={touched.websiteUrl && Boolean(errors.websiteUrl)}
            />
            
            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='occupation'>Occupation</InputLabel>
              <Select
                label='Occupation'
                name='occupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.occupation}
                onChange={
                  (handleChange,
                  e => {
                    setFieldValue('occupation', e.target.value);
                    setFieldValue('subOccupation', '');
                  })
                }
                onBlur={handleBlur}
                error={
                  Boolean(touched.occupation) && Boolean(errors.occupation)
                }
                helperText={touched.occupation && errors.occupation}
              >
                {OCCUPATION_DATA.sort((a, b) =>
                  a.name.localeCompare(b.name)
                ).map((occupation, index) => {
                  return (
                    <MenuItem key={index} value={occupation.name}>
                      {capitalize(occupation.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {touched.occupation && errors.occupation}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ gridColumn: 'span 4' }}>
              <InputLabel id='subOccupation'>Sub Occupation</InputLabel>
              <Select
                disabled={!values.occupation}
                label='Sub Occupation'
                name='subOccupation'
                sx={{ gridColumn: 'span 4' }}
                value={values.subOccupation}
                onChange={handleChange}
                onBlur={handleBlur}
                // error={
                //   Boolean(touched.subOccupation) &&
                //   Boolean(errors.subOccupation)
                // }
                // helperText={touched.subOccupation && errors.subOccupation}
              >
                {values.occupation &&
                  isString(values.occupation) &&
                  OCCUPATION_DATA.find(
                    occupation =>
                      occupation.name.toLowerCase() ===
                      values.occupation.toLowerCase()
                  )
                    .sublist.sort((a, b) => a.name.localeCompare(b.name))
                    .map((subOccupation, index) => {
                      return (
                        <MenuItem key={index} value={subOccupation.name}>
                          {capitalize(subOccupation.name)}
                        </MenuItem>
                      );
                    })}
              </Select>
              <FormHelperText error>
                {touched.subOccupation && errors.subOccupation}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Business Size
              </InputLabel>
              <Select
                label='Business Size
'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.size}
                name='size'
                helperText={touched.size ? errors.size : ''}
                error={touched.size && Boolean(errors.size)}
              >
                {companysizeData.map(size => {
                  return <MenuItem value={size.id}>{size.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error>
                {touched.size ? errors.size : ''}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Business Visibility
              </InputLabel>
              <Select
                label='Business Visibility'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.visibility}
                name='visibility'
                helperText={touched.visibility ? errors.visibility : ''}
                error={touched.visibility && Boolean(errors.visibility)}
              >
                {VISIBILITY_DATA.map(visibility => {
                  return (
                    <MenuItem value={visibility}>
                      {capitalize(visibility)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {touched.visibility ? errors.visibility : ''}
              </FormHelperText>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isAlumniOwned}
                    onChange={handleChange}
                    name='isAlumniOwned'
                  />
                }
                label='More than 50% alumni owned'
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isLessThanTwoYears}
                    onChange={(e, value) => {
                      setFieldValue('isLessThanTwoYears', value);
                      setFieldValue('yearFounded', '');
                    }}
                    name='isLessThanTwoYears'
                  />
                }
                label='Less than 2 years old?'
              />
            </FormGroup>
            {values.isLessThanTwoYears && (
              <TextField
                label='Year Founded'
                onBlur={handleBlur}
                onChange={handleChange}
                type='number'
                value={values.yearFounded}
                name='yearFounded'
                helperText={touched.yearFounded ? errors.yearFounded : ''}
                error={touched.yearFounded && Boolean(errors.yearFounded)}
              />
            )}

            <Button
              variant='contained'
              type='submit'
              disabled={loading.register}
            >
              {loading.register
                ? 'Loading...'
                : selectedBusiness
                ? 'Update'
                : 'Register'}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default BusinessForm;
