import {
  LocationOnOutlined,
  SchoolOutlined,
  WorkOutlineOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import Friend from 'components/Friend';
import TermsAndConditionModal from 'components/TermsAndConditionModal';
import UserImage from 'components/UserImage';
import WidgetWrapper from 'components/WidgetWrapper';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { isValidEmail } from 'utils/helperFunction';

const UserWidget = ({ userId, picturePath, currentUser }) => {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [inviteesEmails, setinviteesEmails] = useState({
    email1: '',
    email2: ''
  });
  const domainName = window.location.origin;
  const [isUserEmail1, setIsUserEmail1] = useState(false);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const getUser = async () => {
    const response = await axiosHelper(
      API_METHOD.GET,
      'users',
      `${userId}`,
      null
    );
    if (response.isSuccess) {
      setUser(response.user);
    }
  };

  const inviteFriends = async (isEmail1 = false) => {
    const response = await axiosHelper(
      API_METHOD.POST,
      'auth',
      'invite-friend',
      {
        email:
          isEmail1 || isUserEmail1
            ? inviteesEmails.email1
            : inviteesEmails.email2,
        userId: userId,
        domainName: domainName
      }
    );
    await getUser();
    if (isAdmin) {
      if (isEmail1 || isUserEmail1) {
        setinviteesEmails({ ...inviteesEmails, email1: '' });
      } else {
        setinviteesEmails({ ...inviteesEmails, email2: '' });
      }
    }
  };

  useEffect(() => {
    getUser();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null;
  }

  const isAdmin = process.env.REACT_APP_ADMIN_EMAIL === user.email;

  const handleClose = () => setOpen(false);

  const adminSendHandler = isEmail1 => {
    let isValid = false;
    if (isEmail1) {
      isValid = isValidEmail(inviteesEmails.email1);
    } else {
      isValid = isValidEmail(inviteesEmails.email2);
    }

    if (!isValid) {
      toast.error('Invalid email address', {
        position: 'top-right'
      });
      return;
    }
    inviteFriends(isEmail1);
  };

  const userSendHandler = isEmail1 => {
    let isValid = false;
    if (isEmail1) {
      isValid = isValidEmail(inviteesEmails.email1);
    } else {
      isValid = isValidEmail(inviteesEmails.email2);
    }

    if (!isValid) {
      toast.error('Invalid email address', {
        position: 'top-right'
      });
      return;
    }
    setIsUserEmail1(isEmail1);
    setOpen(true);
  };

  const {
    firstName,
    lastName,
    location,
    occupation,
    viewedProfile,
    impressions,
    friends,
    college,
    matriculationYear
  } = user;

  return (
    <WidgetWrapper>
      {open && (
        <TermsAndConditionModal
          handleClose={handleClose}
          onSend={inviteFriends}
        />
      )}

      <FlexBetween
        gap='0.5rem'
        pb='1.1rem'
        onClick={() => navigate(`/profile/${userId}`)}
      >
        {/* FIRST ROW */}

        <FlexBetween gap='1rem'>
          <UserImage image={picturePath} />
          <Box>
            <Typography
              variant='h4'
              color={dark}
              fontWeight='500'
              sx={{
                '&:hover': {
                  color: palette.primary.light,
                  cursor: 'pointer'
                }
              }}
            >
              {firstName} {lastName}
            </Typography>
            <Typography color={medium}>{friends.length} friends</Typography>
          </Box>
        </FlexBetween>
        {!currentUser && (
          <Friend
            friendId={userId}
            name={firstName + ' ' + lastName}
            subtitle={location}
            userPicturePath={picturePath}
            isShow={false}
          />
        )}
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p='1rem 0' sx={{ overflowY: 'auto', maxHeight: '200px' }}>
        <Box display='flex' alignItems='center' gap='1rem' mb='0.125rem' sx={{ '@media (max-width: 600px)': { mb: '0.125rem' } }}>
          <LocationOnOutlined fontSize='large' sx={{ color: main }} />
          <Typography color={medium}>{location}</Typography>
        </Box>
        <Box display='flex' alignItems='center' gap='1rem' mb='0.125rem' sx={{ '@media (max-width: 600px)': { mb: '0.125rem' } }}>
          <WorkOutlineOutlined fontSize='large' sx={{ color: main }} />
          <Typography color={medium}>{occupation}</Typography>
        </Box>
        <Box display='flex' alignItems='center' gap='1rem' mb='0.125rem' sx={{ '@media (max-width: 600px)': { mb: '0.125rem' } }}>
          <SchoolOutlined fontSize='large' sx={{ color: main }} />
          <Typography color={medium}>
            {college} ({matriculationYear})
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box p='1rem 0'>
        <Typography fontSize='1rem' color={main} fontWeight='500' mb='1rem'>
          Invite SBS Alums to Oxsaid
        </Typography>

        <FlexBetween gap='1rem' mb='0.5rem'>
          <TextField
            placeholder='Invite by Email'
            variant='outlined'
            fullWidth
            disabled={user.invites[0] && !isAdmin ? true : false}
            value={
              user.invites && user.invites[0] && !isAdmin
                ? user.invites[0].email
                : inviteesEmails.email1
            }
            onChange={e => {
              setinviteesEmails({ ...inviteesEmails, email1: e.target.value });
            }}
            InputProps={{
              style: { backgroundColor: 'lightyellow' }
            }}
          />
          <Button
            variant='contained'
            color='primary'
            disabled={
              (user.invites[0] && !isAdmin) ||
              inviteesEmails.email1.trim().length === 0
                ? true
                : false
            }
            onClick={() =>
              isAdmin ? adminSendHandler(true) : userSendHandler(true)
            }
          >
            {user.invites && user.invites[0] && !isAdmin ? 'Sent' : 'Send'}
          </Button>
        </FlexBetween>

        <FlexBetween gap='1rem' mb='0.5rem'>
          <TextField
            placeholder='Invite by Email'
            variant='outlined'
            fullWidth
            disabled={user.invites[1] && !isAdmin ? true : false}
            value={
              user.invites && user.invites[1] && !isAdmin
                ? user.invites[1].email
                : inviteesEmails.email2
            }
            onChange={e => {
              setinviteesEmails({ ...inviteesEmails, email2: e.target.value });
            }}
            InputProps={{
              style: { backgroundColor: 'lightyellow' }
            }}
          />
          <Button
            variant='contained'
            color='primary'
            disabled={
              (user.invites && user.invites[1] && !isAdmin) ||
              inviteesEmails.email2.trim().length === 0
                ? true
                : false
            }
            onClick={() =>
              isAdmin ? adminSendHandler(false) : userSendHandler(false)
            }
          >
            {user.invites && user.invites[1] && !isAdmin ? 'Sent' : 'Send'}
          </Button>
        </FlexBetween>
      </Box>
    </WidgetWrapper>
  );
};

export default UserWidget;

