import axios from "axios";
import toast from "react-hot-toast";
import { BASE_URL } from "./apiHelper";

export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const axiosHelper = async (
  method,
  entity,
  endpoint,
  data,
  headers,
  isBlob = false
) => {
  const accessToken = localStorage.getItem("USER_TOKEN") || "";

  let response = undefined;
  try {
    if (method === API_METHOD.GET) {
      response = axios.get(
        `${BASE_URL}/${entity}${endpoint ? `/${endpoint}` : ""}`,
        {
          headers: {
            Authorization: accessToken ?? "",
            ...headers,
          },
          responseType: isBlob ? "blob" : "json",
        }
      );
    } else if (method === API_METHOD.POST) {
      response = axios.post(`${BASE_URL}/${entity}/${endpoint}`, data, {
        headers: {
          Authorization: accessToken ?? "",
          ...headers,
        },
      });
    } else if (method === API_METHOD.PUT) {
      response = axios.put(`${BASE_URL}/${entity}/${endpoint}`, data, {
        headers: {
          Authorization: accessToken ?? "",
          ...headers,
        },
      });
    } else if (method === API_METHOD.DELETE) {
      response = axios.delete(`${BASE_URL}/${entity}/${endpoint}`, {
        headers: {
          Authorization: accessToken ?? "",
          ...headers,
        },
      });
    }
    const res = await Promise.resolve(response);
    if (res.data && res.data.isError) {
      toast.error(res.data.msg, {
        position: "top-right",
      });
      return;
    }
    if (res.data && res.data.msg) {
      toast.success(res.data.msg, {
        position: "top-right",
      });
    }

    return res.data;
  } catch (e) {
    const errorMessage =
      e.response?.data?.msg || e.message || "Something went wrong";

    toast.error(errorMessage, {
      position: "top-right",
    });
  }
};

export default axiosHelper;
