import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import ChangePasswordForm from 'components/ChangePasswordForm';
import Form from 'components/Form';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SettingWidget = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [value, setValue] = useState(0);
  const { user } = useAppSelector(state => state.auth);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!user) return <h1>Loading...</h1>;

  // const isAdmin = process.env.REACT_APP_ADMIN_EMAIL === user.email;

  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <Typography
        variant='h4'
        fontWeight='700'
        sx={{ mb: '1.5rem', textAlign: 'center' }}
      >
        Settings
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: theme.palette.background.paper
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Update Profile' {...a11yProps(0)} />
          <Tab label='Change Password' {...a11yProps(1)} />
          {/* {isAdmin && <Tab label='Admin Panel' {...a11yProps(2)} />} */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Form elum={true} isLogin={false} isUpdate user={user} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <OfferFundingWidget /> */}
        <ChangePasswordForm />
      </TabPanel>
      {/* {isAdmin && (
        <TabPanel value={value} index={2}>
          <AdminPanel />
        </TabPanel>
      )} */}

    </Box>
  );
};

export default SettingWidget;
