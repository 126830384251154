import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  styled
} from '@mui/material';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '1.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.alt,
  padding: '1rem 6%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const CardContentContainer = styled('div')({
  flex: 1,
  textAlign: 'center'
});

const CardImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '150px',
  objectFit: 'contain'
});

const DividerStyled = styled(Divider)({
  margin: '2rem 0'
});

const FeaturePage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const navigate = useNavigate();

  const actionSections = [
    {
      title: 'Advertise Your Business',
      description: `Promote your business to the ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns community.`,
      image:
        'https://cdn2.iconfinder.com/data/icons/feedback-4/512/Want_your_feedback-512.png' // Replace with the actual image URL
    },
    {
      title: 'Post a Job Offer',
      description: `Hire talented individuals from the ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns network.`,
      image: 'https://static.thenounproject.com/png/3872123-200.png' // Replace with the actual image URL
    },
    {
      title: 'Look for Work',
      description: `Explore job opportunities posted by fellow ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns.`,
      image: 'https://cdn-icons-png.flaticon.com/512/65/65053.png' // Replace with the actual image URL
    },
    {
      title: 'Offer Funding',
      description: 'Provide financial support for projects or initiatives.',
      image: 'https://cdn-icons-png.flaticon.com/512/7533/7533463.png' // Replace with the actual image URL
    },
    {
      title: 'Raise Funding',
      description: 'Seek funding for your projects or ventures.',
      image:
        'https://cdn4.vectorstock.com/i/1000x1000/76/48/raise-money-icon-outline-style-vector-34687648.jpg' // Replace with the actual image URL
    },
    {
      title: 'Organise Meetings',
      description: 'Plan and coordinate meetings or events with ease.',
      image:
        'https://www.shutterstock.com/image-vector/conference-icon-vectormeeting-vector-icondiscussion-260nw-1673901592.jpg' // Replace with the actual image URL
    },
    {
      title: 'Chat',
      description: `Connect and communicate with other ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns.`,
      image:
        'https://static.vecteezy.com/system/resources/previews/005/337/802/non_2x/icon-symbol-chat-outline-illustration-free-vector.jpg' // Replace with the actual image URL
    },
    {
      title: 'Form Groups, Share Passions',
      description: 'Find like-minded individuals and form groups.',
      image:
        'https://cdn-icons-png.flaticon.com/512/32/32441.png' // Replace with the actual image URL
    }
  ];

  const handleBack = () => {
    // navigate to the previous page
    navigate(-1);
  };

  return (
    <Box>
      <HeaderContainer>
        <Button variant='contained' color='primary' onClick={handleBack}>
          Back
        </Button>
        <Typography fontWeight='bold' fontSize='32px' color='primary'>
          {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
        <div style={{ width: '64px' }} />{' '}
        {/* Placeholder for better alignment */}
      </HeaderContainer>

      <Box width={isNonMobileScreens ? '50%' : '93%'} p='2rem' m='2rem auto'>
        {actionSections.map((section, index) => (
          <Box key={index}>
            <CardContainer>
              <CardImage
                src={section.image}
                alt={`${section.title} Screenshot`}
              />
              <CardContentContainer>
                <Typography variant='h4' gutterBottom>
                  {section.title}
                </Typography>
                <Typography>{section.description}</Typography>
              </CardContentContainer>
            </CardContainer>
            {index < actionSections.length - 1 && <DividerStyled />}
          </Box>
        ))}
      </Box>
      <Footer />
    </Box>
  );
};

export default FeaturePage;
