import { Button, Grid, useTheme } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const { user } = useAppSelector(state => state.auth);
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        zIndex: theme.zIndex.appBar
      }}
      justifyContent='space-between'
    >
      <Grid item xs={6}>
        <footer>
          <p> made with ❤️  going further together</p>
        </footer>
      </Grid>
      {user && (
        <Grid item xs={6} textAlign='right'>
          <Button color='primary' variant='outlined' onClick={() => navigate('/contact-us')}>
            Contact Us
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Footer;
