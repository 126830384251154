import { Box, useMediaQuery } from '@mui/material';
import Footer from 'components/Footer';
import SearchPeople from 'components/SearchPeople';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from 'scenes/navbar';
import { setMyBusinesses } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';

const SearchPage = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id } = useAppSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const getBuinessesById = async () => {
    const res = await axiosHelper(API_METHOD.GET, 'businesses', _id);
    if (res.isSuccess) {
      dispatch(
        setMyBusinesses({
          myBusinesses: res.business
        })
      );
    }
  };

  useEffect(() => {
    getBuinessesById();
  }, []);

  return (
    <Box pb={10} height='100vh'>
      <Navbar />
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap='0.5rem'
        justifyContent='center'
      >
        <Box
          // flexBasis={isNonMobileScreens ? '42%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
          width='100%'
        >
          <SearchPeople />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default SearchPage;
