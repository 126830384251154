import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Banner from 'components/Banner';
import Footer from 'components/Footer';
import SupportBox from 'components/SupportBox';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Home_Image from "../../common/images/home_image.png";
import Form from '../../components/Form';

const LoginPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const [isAboutUsPage, setIsAboutUsPage] = useState(false);
  const [elum, setElum] = useState(true);

  return (
    <Box>
      <Box
        width='100%'
        backgroundColor={theme.palette.background.alt}
        p='1rem 6%'
        textAlign='center'
      >
        <Typography fontWeight='bold' fontSize='32px' color='primary'>
          {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
      </Box>
	  <Banner />
	  
      <Grid container spacing={2} sx={{ maxWidth: '100%', m: '2rem auto' }}>
        {isNonMobileScreens && (
          <Grid item md={6}>
            {/* Placeholder for an image */}
            <Box
              component="img"
              src={Home_Image}
              alt="Home Image"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '1.5rem',
                display: 'block' // Ensures the image is block level to avoid any unwanted white space below the image
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
           <Box  
            p='2rem'
            m='2rem'
            borderRadius='1.5rem'
            backgroundColor={theme.palette.background.alt}
          >
	  {/*<SupportBox />*/}
          </Box>
          <Box
            p='2rem'
            borderRadius='1.5rem'
            backgroundColor={theme.palette.background.alt}
          >
            {isAboutUsPage ? (
              <>
                <Typography fontWeight='500' variant='h5' sx={{ mb: '1.5rem' }}>
                  Welcome to {process.env.REACT_APP_ORGANIZATION_NAME}, A Job
                  Search, Fundraising, Event, and Networking Option for{' '}
                  {process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum'
                    ? 'Oxford'
                    : 'Business School'}{' '}
                  Alumns!
                </Typography>
                {/* Other texts */}
              </>
            ) : (
              <>
                <Typography fontWeight='500' variant='h5' sx={{ mb: '1.5rem' }}>
                  Dont have an account{' '}
                  <Button onClick={() => navigate('register')}>Register?</Button>
                </Typography>
                <Form elum={elum} isLogin={true} />
                <Button onClick={() => navigate('/about-us')}> About Us</Button>
                <Button onClick={() => navigate('/feature')}>
                  Want to know more about {process.env.REACT_APP_ORGANIZATION_NAME}?
                </Button>
                <Button onClick={() => setElum(!elum)}>
                  {elum ? 'Login As Guest?' : 'Login As Verified User?'}
                </Button>
                <Button>
                  <a href='mailto:admin@nanoosedev.com'>Contact Us</a>
                </Button>
              </>
            )}
          </Box>
         
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default LoginPage;
