import { useTheme } from '@emotion/react';
import { PersonAddOutlined, PersonRemoveOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography, capitalize } from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import WidgetWrapper from 'components/WidgetWrapper';
import { useAppSelector } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFriends } from 'state/auth';
import { companysizeData } from 'utils/data';
import { getDisplayNames } from 'utils/helperFunction';
import UserImage from '../../components/UserImage';
const BusinessWidget = ({ business }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useAppSelector(state => state.auth);
  const { _id } = user;
  const friends = user.friends;

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;
  if (!business) return null;

  const isFriend =
    friends &&
    friends.length > 0 &&
    friends.find(friend => friend._id === business.userId._id);
  const notCurrentUser = _id !== business.userId._id;
  const patchFriend = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/${_id}/${business.userId._id}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };
  if (!business) return null;
  return (
    <WidgetWrapper m='2rem 0'>
      <FlexBetween>
        <FlexBetween gap='1rem'>
          <UserImage size='55px' image={business.userId.picturePath} />
          <Box
          // onClick={() => {
          //   // navigate(`/profile/${business.userId._id}`);
          //   navigate(0);
          // }}
          >
            <Typography color={main} variant='h5' fontWeight='500'>
              <b>Business created By: </b>{' '}
              <Box
                onClick={() => {
                  navigate(`/profile/${business.userId._id}`);
                  navigate(0);
                }}
                sx={{
                  '&:hover': {
                    color: palette.primary.light,
                    cursor: 'pointer'
                  }
                }}
              >
                {getDisplayNames(
                  business.userId.firstName,
                  business.userId.lastName
                )}
              </Box>
            </Typography>
            <Typography color={medium} fontSize='0.75rem'>
              <b>Business Name: </b>
              {business.name.name}
            </Typography>
            <Typography color={medium} fontSize='0.75rem'>
              <b>Description: </b>
              {business.description}
            </Typography>
            {business.websiteUrl && (
              <Typography color={medium} fontSize='0.75rem'>
                <b>Business Website URL: </b> 
                {/* a tag which click on it takes to new tab */}
                <a href={business.websiteUrl} target='_blank' rel='noreferrer'>
                  {business.websiteUrl}
                </a>
              </Typography>
            )}

            <Typography color={medium} fontSize='0.75rem'>
              <b>Employees: </b>{' '}
              {companysizeData[business.size]
                ? companysizeData[business.size].name
                : ''}
            </Typography>
            <Typography color={medium} fontSize='0.75rem'>
              <b>Occupation: </b> {capitalize(business.occupation || '')}
            </Typography>
            <Typography color={medium} fontSize='0.75rem'>
              <b>Sub Occupation: </b> {capitalize(business.subOccupation || '')}
            </Typography>
          </Box>
        </FlexBetween>

        {notCurrentUser && (
          <IconButton
            onClick={() => patchFriend()}
            sx={{ backgroundColor: primaryLight, p: '0.6rem' }}
          >
            {isFriend ? (
              <PersonRemoveOutlined sx={{ color: primaryDark }} />
            ) : (
              <PersonAddOutlined sx={{ color: primaryDark }} />
            )}
          </IconButton>
        )}
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default BusinessWidget;
