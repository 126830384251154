import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { Formik, isString } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { OCCUPATION_DATA } from 'utils/data';
import { capitalize } from 'utils/helperFunction';
import * as yup from 'yup';

const initialValuesRegister = {
  amount: '',
  description: '',
  occupation: '',
  subOccupation: '',
  name: ''
};

const FundingForm = ({ isOffering }) => {
  const [loading, setLoading] = useState({
    register: false,
    page: false
  });
  const { user, businesses } = useAppSelector(state => state.auth);
  const navigate = useNavigate();

  const registerSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .min(100, 'Amount must be greater than 100')
      .required('Amount is required'),
    description: yup
      .string()
      .required('Description is required')
      .min(20, 'Description should have a minimum of 20 characters')
      .max(100, 'Description should have a maximum of 100 characters'),
    name: yup
      .string()
      .required('Business name is required')
      .max(100, 'Business name should have a maximum of 100 characters'),
    occupation: yup.string().required('Occupation is a required field')
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      setLoading({ ...loading, register: true });
      const res = await axiosHelper(API_METHOD.POST, 'fundings', '', {
        ...values,
        isOffering: isOffering,
        isSeeking: !isOffering,
        userId: user._id,
        businessNameId: isBusinessNameExist(values.name)._id
      });
      if (res.isSuccess) {
        resetForm();
      }
      setLoading({ ...loading, register: false });

      // await register(values, onSubmitProps);
    } catch (error) {}
  };

  const isBusinessNameExist = name => {
    if (!name) return true;
    return businesses.find(
      business => business.name.name.toLowerCase() === name.toLowerCase()
    );
  };
  // useEffect(() => {
  //   getBusinessNames();
  // }, []);

  if (loading.page) return <div>Loading...</div>;

  return businesses.length > 0 ? (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesRegister}
      validationSchema={registerSchema}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Autocomplete
              name='name'
              value={values.name}
              onChange={(event, newValue) => setFieldValue('name', newValue)}
              onInputChange={(event, newInputValue) =>
                setFieldValue('name', newInputValue)
              }
              id='free-solo-2-demo'
              disableClearable
              options={businesses
                .map(option => option.name.name)
                .sort((a, b) => b.localeCompare(a))}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Business Name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  helperText={touched.name ? errors.name : ''}
                  error={touched.name && Boolean(errors.name)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />
            <>
              <FormControl sx={{ gridColumn: 'span 4' }}>
                <InputLabel id='occupation'>Occupation</InputLabel>
                <Select
                  label='Occupation'
                  name='occupation'
                  sx={{ gridColumn: 'span 4' }}
                  value={values.occupation}
                  onChange={
                    (handleChange,
                    e => {
                      setFieldValue('occupation', e.target.value);
                      setFieldValue('subOccupation', '');
                    })
                  }
                  onBlur={handleBlur}
                  error={
                    Boolean(touched.occupation) && Boolean(errors.occupation)
                  }
                  helperText={touched.occupation && errors.occupation}
                >
                  {OCCUPATION_DATA.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  ).map((occupation, index) => {
                    return (
                      <MenuItem key={index} value={occupation.name}>
                        {capitalize(occupation.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>
                  {touched.occupation && errors.occupation}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ gridColumn: 'span 4' }}>
                <InputLabel id='subOccupation'>Sub Occupation</InputLabel>
                <Select
                  disabled={!values.occupation}
                  label='Sub Occupation'
                  name='subOccupation'
                  sx={{ gridColumn: 'span 4' }}
                  value={values.subOccupation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(touched.subOccupation) &&
                    Boolean(errors.subOccupation)
                  }
                  helperText={touched.subOccupation && errors.subOccupation}
                >
                  {values.occupation &&
                    isString(values.occupation) &&
                    OCCUPATION_DATA.find(
                      occupation =>
                        occupation.name.toLowerCase() ===
                        values.occupation.toLowerCase()
                    )
                      .sublist.sort((a, b) => a.name.localeCompare(b.name))
                      .map((subOccupation, index) => {
                        return (
                          <MenuItem key={index} value={subOccupation.name}>
                            {capitalize(subOccupation.name)}
                          </MenuItem>
                        );
                      })}
                </Select>
                <FormHelperText error>
                  {touched.subOccupation && errors.subOccupation}
                </FormHelperText>
              </FormControl>
            </>

            <TextField
              label='Amount in CAD'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.amount}
              name='amount'
              helperText={touched.amount ? errors.amount : ''}
              error={touched.amount && Boolean(errors.amount)}
              type='number'
            />

            <TextField
              label='Description'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              name='description'
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
              multiline
              rows={4}
            />

            <Button
              variant='contained'
              type='submit'
              disabled={loading.register}
            >
              {loading.register ? 'Loading...' : 'Submit'}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  ) : (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <h2>
          You don't have a business set up yet. To post a funding, please create a
          business first by clicking the button below.
        </h2>
        <Button variant='contained' onClick={() => navigate('/business')}>
          Create Business
        </Button>
      </Grid>
    </Grid>
  );
};

export default FundingForm;
