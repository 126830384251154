import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import EventCardWidget from './EventCardWidget';

const RenderGroupEventWidget = ({groupId}) => {
  const [events, setEvents] = useState([]);
  const { user } = useSelector(state => state.auth);
  console.log("gelgskdfds")

  useEffect(() => {
    getEvents();
  }, []); // Including this line as it seems to be an oversight in your previous component setup

  const getEvents = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'events', `get-group-events/${groupId}`);
      console.log(response)
      if (response.isSuccess) {
        // filter the events with no userId
        const updatedEvents = response.events.filter(event => event.userId);
        setEvents(updatedEvents);
        // dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {events.length > 0 ? (
        events.map((event) => (
          <EventCardWidget
            
            {...event}
          />
        ))
      ) : (
        <p>No events found.</p> // Displaying a message if no jobs are found
      )}
    </>
  );
};

export default RenderGroupEventWidget;
