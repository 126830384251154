import { Box, useMediaQuery } from '@mui/material';
import Footer from 'components/Footer';
import Navbar from 'scenes/navbar';
import ChatListWidget from 'scenes/widgets/ChatListWidget';

const ChatPage = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');

  return (
    <Box
      pb={10}
      style={{
        maxHeight: '100%',
        
        overflowY: 'hidden'
      }}
    >
      <Navbar />
      <Box
        width='100%'
        // padding='2rem 6%'
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap='0.5rem'
        justifyContent='center'
      >
        <Box
          // flexBasis={isNonMobileScreens ? '42%' : undefined}
          // mt={isNonMobileScreens ? undefined : '2rem'}
          width='100%'
        >
          {/* <GroupWidget /> */}
          <ChatListWidget />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ChatPage;
