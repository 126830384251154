import { Box } from '@mui/material';
import React from 'react';

const GroupCover = ({ imageUrl }) => {
  return (
    <Box
      sx={{
        height: 300,
        background: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box
        sx={{
          height: '100%',
          background: 'rgba(0,0,0,0.5)'
        }}
      ></Box>
    </Box>
  );
};

export default GroupCover;
