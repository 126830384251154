import { Box, Divider, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import MyPostWidget from './MyPostWidget';
import PostsWidget from './PostsWidget';

const DiscussionWidget = ({ group }) => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id, picturePath } = useAppSelector(state => state.auth.user);
  if (!group) return <div>Loading...</div>;
  return (
    <Box
      flexBasis={isNonMobileScreens ? '42%' : undefined}
      mt={isNonMobileScreens ? undefined : '2rem'}
      p={4}
    >
      <MyPostWidget picturePath={picturePath} groupId={group._id} />
      <Divider />
      <Box m='2rem 0' />
      <PostsWidget userId={_id} groupId={group._id} />
    </Box>
  );
};

export default DiscussionWidget;
