import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { geoData } from 'utils/geoData';
import { getDisplayNames } from 'utils/helperFunction';
import * as yup from 'yup';

const initialValuesRegister = {
  name: '',
  description: '',
  groupMembers: [],
  adminMembers: [],
  isPrivate: false,
  groupCoverImage: ''
};

const GroupForm = ({ isAdmin }) => {
  const [loading, setLoading] = useState({
    register: false,
    page: false
  });
  const [users, setUsers] = useState([]);
  const { user } = useAppSelector(state => state.auth);
  const dispatch = useDispatch();
 const registerSchema = yup.object().shape({
  name: yup.string()
    .required('Name is required')
    .test(
      'len',
      'Group name must be between 5 and 30 words',
      val => {
        const words = val.split(/\s+/).filter(Boolean); // Splits the string by whitespace and filters out empty strings
        return words.length >= 5 && words.length <= 30;
      }
    ),
  description: yup.string()
    .required('Description is required')
    .test(
      'len',
      'Description must be between 50 and 300 words',
      val => {
        const words = val.split(/\s+/).filter(Boolean);
        return words.length >= 50 && words.length <= 300;
      }
    ),
  groupMembers: isAdmin
    ? yup.array().optional()
    : yup.array()
        .min(1, 'There should be at least 1 member')
        .required('There should be at least 1 member'),
  location: yup.string().optional(),
  adminMembers: yup.array().optional(),
  groupCoverImage: yup.mixed().required('Group Cover Image is required')
});

  const [checkboxState, setCheckboxState] = useState({
    addAll: false,
    addByCountry: false,
    addByContinent: false
  });

  const handleCheckboxChange = (event, setFieldValue) => {
    const { name, checked } = event.target;
    setCheckboxState({
      addAll: false,
      addByCountry: false,
      addByContinent: false,
      [name]: checked
    });
    setFieldValue('location', '');
  };

  const getUsers = async () => {
    try {
      setLoading({ ...loading, page: true });
      const response = await axiosHelper(API_METHOD.GET, 'users', '');
      const filteredUsers = response.filter(u => u._id !== user._id && u.elum);
      setUsers(filteredUsers);
      setLoading({ ...loading, page: false });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      setLoading({ ...loading, register: true });
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('isPrivate', values.isPrivate);
      formData.append('groupCoverImage', values.groupCoverImage);
      formData.append('description', values.description);
      formData.append('groupMembers', [
        user._id,
        ...values.groupMembers.map(m => m._id)
      ]);
      formData.append('adminMembers', values.adminMembers);
      formData.append('userId', user._id);
      if (isAdmin) {
        const filters = {
          isGlobal: checkboxState.addAll ? true : false,
          isCountry: checkboxState.addByCountry ? true : false,
          location: values.location
        };

        formData.append('filters', JSON.stringify(filters));
      }

      const res = await axiosHelper(API_METHOD.POST, 'groups', '', formData);
      onSubmitProps.resetForm();
      if (res.isSuccess) {
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
        onSubmitProps.setFieldValue('isPrivate', false);
        onSubmitProps.setFieldValue('groupCoverImage', '');
        onSubmitProps.setFieldValue('groupMembers', []);
        onSubmitProps.setValues(initialValuesRegister);
        onSubmitProps.setFieldValue('adminMembers', []);
        if (isAdmin) {
          onSubmitProps.setFieldValue('location', '');
          setCheckboxState({
            addAll: false,
            addByCountry: false,
            addByContinent: false
          });
        }
      }
      setLoading({ ...loading, register: false });

      // await register(values, onSubmitProps);
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  if (loading.page) return <div>Loading...</div>;

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesRegister}
      validationSchema={registerSchema}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label='Group Name'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              name='name'
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />

            <TextField
              label='Description'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              multiline
              rows={4}
              name='description'
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
            />
            {!isAdmin && (
              <>
                <Autocomplete
                  multiple
                  id='tags-standard'
                  options={users}
                  getOptionLabel={option =>
                    getDisplayNames(option.firstName, option.lastName) || ''
                  }
                  onChange={(event, value) => {
                    setFieldValue('groupMembers', value);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component='li'
                      id={option._id}
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        cursor: 'pointer',
                        pointerEvents: 'all'
                      }}
                      {...props}
                    >
                      <Avatar
                        src={
                          option && option.picturePath ? option.picturePath : ''
                        }
                      >
                        {option.firstName &&
                          option.firstName[0].toUpperCase() + option &&
                          option.lastName &&
                          option.lastName[0].toUpperCase()}{' '}
                        || ""
                      </Avatar>
                      <Typography variant='body2' color='text.primary' pl={2}>
                        {getDisplayNames(option.firstName, option.lastName)} ||
                        ""
                      </Typography>
                    </Box>
                  )}
                  onBlur={handleBlur}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Group Members'
                      placeholder='Group Members'
                      error={touched.groupMembers ? errors.groupMembers : ''}
                    />
                  )}
                />
                {touched.groupMembers && errors.groupMembers && (
                  <FormHelperText error>{errors.groupMembers}</FormHelperText>
                )}
              </>
            )}

            {isAdmin && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxState.addAll}
                      onChange={e => handleCheckboxChange(e, setFieldValue)}
                      name='addAll'
                    />
                  }
                  label='Add all members'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxState.addByCountry}
                      onChange={e => handleCheckboxChange(e, setFieldValue)}
                      name='addByCountry'
                    />
                  }
                  label='Add all by country'
                />
                {checkboxState.addByCountry && (
                  <Autocomplete
                    name='location'
                    sx={{ gridColumn: 'span 2' }}
                    value={values.location}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, newValue) => {
                      setFieldValue('location', newValue || {});
                    }}
                    // freeSolo
                    id='free-solo-2-demo'
                    disableClearable
                    options={Object.keys(geoData)
                      .map(option => option)
                      .sort((a, b) => a.localeCompare(b))}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Country'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.location}
                        name='location'
                        helperText={touched.location ? errors.location : ''}
                        error={touched.location && Boolean(errors.location)}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search'
                        }}
                      />
                    )}
                  />
                )}
                {/* 
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxState.addByContinent}
                      onChange={handleCheckboxChange}
                      name='addByContinent'
                    />
                  }
                  label='Add all members by continent'
                />
                {checkboxState.addByContinent && (
                  <TextField
                    label='Continent'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.continent}
                    name='continent'
                    helperText={touched.continent ? errors.continent : ''}
                    error={touched.continent && Boolean(errors.continent)}
                  />
                )} */}
              </>
            )}

            <input
              type='file'
              name='groupCoverImage'
              accept='image/*'
              inputProps={{ 'aria-label': 'Upload image' }}
              onChange={event => {
                setFieldValue('groupCoverImage', event.target.files[0]);
              }}
              // error if touched and has error
              error={touched.groupCoverImage && Boolean(errors.groupCoverImage)}
            />
            {touched.groupCoverImage && errors.groupCoverImage && (
              <FormHelperText error>{errors.groupCoverImage}</FormHelperText>
            )}

            <Button
              variant='contained'
              type='submit'
              disabled={loading.register}
            >
              {loading.register ? (
                <CircularProgress size={24} />
              ) : (
                'Create Group'
              )}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default GroupForm;
