import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import JobCardWidget from './JobCardWidget';

const RenderJobWidget = ({ isMyJobs }) => {
  const [jobs, setJobs] = useState([]);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    getJobs();
  }, []); // Including this line as it seems to be an oversight in your previous component setup

  const getJobs = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'jobs', '');
      if (response.isSuccess) {
        if (isMyJobs) {
          const myJobs = response.jobs.filter(
            job => job.userId && job.userId._id === user._id
          );
          setJobs(myJobs);
        }
        setJobs(response.jobs);
        // dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {jobs.length > 0 ? (
        jobs.map(({ jobTitle, salary, description, businessId, userId, _id, isClosed, country, city, occupation, subOccupation }) => (
          <JobCardWidget
            key={_id} // Always use a key when mapping over arrays in React for performance optimizations
            jobTitle={jobTitle}
            salary={salary}
            description={description}
            business={businessId}
            userId={userId}
            isMyJobs={isMyJobs}
            jobId={_id}
            isClosed={isClosed}
            setJobs={setJobs}
            jobs={jobs}
            country={country}
            city={city}
            occupation={occupation}
            subOccupation={subOccupation}
          />
        ))
      ) : (
        <p>No jobs found.</p> // Displaying a message if no jobs are found
      )}
    </>
  );
};

export default RenderJobWidget;
