import socket from "configs/socket";
import { useNavigate } from "react-router-dom";
import { setConversations, setNewMessage } from "state/chat";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import { useAppDispatch, useAppSelector } from "./useRedux";

export const useChat = () => {
  const dispatch = useAppDispatch();
  const { conversations, newMessage } = useAppSelector((state) => state.chat);
  const navigate = useNavigate();

  const getGroupChats = async (groupId) => {
    const response = await axiosHelper(
      API_METHOD.GET,
      `groups/groupchats/${groupId}`
    );
    return response.messages;
  };

  const getAllConversation = async (userId) => {
    const response = await axiosHelper(
      API_METHOD.GET,
      `chat/list?id=${userId}`
    );
    if (response.isSuccess) {
      dispatch(setConversations(response.list));
    }
  };

  const openConversation = async (selfId, otherId) => {
    const res = await axiosHelper(
      API_METHOD.GET,
      `chat?id1=${selfId}&id2=${otherId}`
    );

    console.log(res);

    if (res.isSuccess) {
      const isExist = conversations.find(
        (obj) => obj._id === res.conversation._id
      );

      if (!isExist) {
        const updatedConversations = [res.conversation, ...conversations];
        dispatch(setConversations(updatedConversations));
        socket.emit("new-conversation", {
          conversation: res.conversation,
          createId: selfId,
        });
      } else {
        // update
        const updatedConversations = conversations.map((obj) => {
          if (obj._id === res.conversation._id) {
            return res.conversation;
          }
          return obj;
        });
        dispatch(setConversations(updatedConversations));
      }
      navigate(`/chat/${res.conversation._id}`);
    }
  };

  const addConversationToState = (conversation) => {
    const isExist = conversations.find((obj) => obj._id === conversation._id);
    if (!isExist) {
      const updatedConversations = [conversation, ...conversations];
      dispatch(setConversations(updatedConversations));
    }
  };

  const getConversationById = async (id) => {
    const response = await axiosHelper(
      API_METHOD.GET,
      `chat`,
      `conversation?cid=${id}`
    );
    if (response.isSuccess) {
      dispatch(setConversations([response.conversation, ...conversations]));
    }
  };

  const getConversationFromState = (conversationId) => {
    const conversation = conversations.find(
      (obj) => obj._id === conversationId
    );
    if (!conversation) getConversationById(conversationId);
    return conversation;
  };

  const sendMessage = async (
    conversationId,
    senderId,
    receiverId,
    content,
    username
  ) => {
    const response = await axiosHelper(
      API_METHOD.POST,
      `chat`,
      `send-message`,
      {
        cid: conversationId,
        uid: senderId,
        content: content,
        username: username,
      }
    );
    if (response.isSuccess) {
      const updatedConversations = conversations.map((obj) => {
        if (obj._id === conversationId) {
          return response.conversation;
        }
        return obj;
      });
      dispatch(setConversations(updatedConversations));
      dispatch(
        setNewMessage({ cid: conversationId, message: response.newMessage })
      );


      socket.emit("user-send-message", {
        conversation: response.conversation,
        message: response.newMessage,
        receiverId: receiverId,
      });

    }
  };

  const updateConversationFromState = (conversation) => {
    const updatedConversations = conversations.map((obj) => {
      if (obj._id === conversation._id) {
        return conversation;
      }
      return obj;
    });
    dispatch(setConversations(updatedConversations));
  };

  // const sendMessage = () => {
  //   const content = chatFieldRef.current.value;
  //   chatFieldRef.current.value = '';
  //   if (!content || content === '') return;
  //   setSending(true);
  //   const options = {
  //     uri: `${url.LOCAL}/api/send-message`,
  //     method: 'post',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.chattoken}`
  //     },
  //     body: JSON.stringify({
  //       cid: cid,
  //       uid: uid,
  //       content: content,
  //       username: myUsername
  //     })
  //   };

  //   request.post(options, function (err, httpResponse, body) {
  //     if (httpResponse.statusCode !== 200) {
  //     } else {
  //       const obj = JSON.parse(body);
  //       addNewMessage({
  //         conversation: obj.conversation,
  //         message: obj.newMessage
  //       });

  //       updateConversation(obj.conversation);
  //       if (timeout) clearTimeout(timeout);
  //       stoppedTyping();
  //       socket.emit('user-send-message', {
  //         conversation: obj.conversation,
  //         newMessage: obj.newMessage
  //       });
  //     }
  //     setSending(false);
  //   });
  // };

  return {
    openConversation,
    getAllConversation,
    getConversationFromState,
    sendMessage,
    conversations,
    updateConversationFromState,
    getGroupChats,
  };
};
