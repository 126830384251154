import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FundingsWidget from './FundingsWidget';
import GetFundingWidget from './GetFundingWidget';
import OfferFundingWidget from './OfferFundingWidget';
import CreateJobWidget from './CreateJobWidget';
import RenderJobWidget from './RenderJobsWidget';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const JobsWidget = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [value, setValue] = useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <Typography
        variant='h4'
        fontWeight='700'
        sx={{ mb: '1.5rem', textAlign: 'center' }}
      >
        Jobs
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: theme.palette.background.paper
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Post Job' {...a11yProps(0)} />
          <Tab label='All Jobs' {...a11yProps(1)} />
          <Tab label='My Jobs' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CreateJobWidget />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RenderJobWidget  isMyJobs={false}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RenderJobWidget  isMyJobs={true}/>
      </TabPanel>
    </Box>
  );
};

export default JobsWidget;
