import { Box, TextField, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBusinesses } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import BusinessWidget from './BusinessWidget';

const BusinessesWidget = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const businesses = useAppSelector(state => state.auth.businesses);

  useEffect(() => {
    getBusinessess();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBusinessess = async () => {
    try {
      const response = await axiosHelper(API_METHOD.GET, 'businesses', '');
      if (response.isSuccess) {
        dispatch(setBusinesses({ businesses: response.business }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (keyword) {
      const filteredBusinesses = businesses.filter(
        business =>
          business.name.name.toLowerCase().includes(keyword.toLowerCase()) ||
          (business.occupation &&
            business.occupation
              .toLowerCase()
              .includes(keyword.toLowerCase())) ||
          (business.subOccupation &&
            business.subOccupation
              .toLowerCase()
              .includes(keyword.toLowerCase()))
      );
      setFilteredBusinesses(filteredBusinesses);
    } else {
      setFilteredBusinesses(businesses);
    }
  }, [keyword, businesses]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <Typography
        variant='h4'
        fontWeight='700'
        sx={{ mb: '1.5rem', textAlign: 'center' }}
      >
        Businesses
      </Typography>
      <TextField
        fullWidth
        variant='outlined'
        placeholder='Search by name, occupation, sub-occupation'
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        sx={{
          backgroundColor: 'white'
        }}
      />

      {filteredBusinesses &&
        filteredBusinesses.length > 0 &&
        filteredBusinesses.map(business => (
          <BusinessWidget key={business._id} business={business} />
        ))}
    </Box>
  );
};

export default BusinessesWidget;
