import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '1.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.alt,
  padding: '1rem 6%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const CardContentContainer = styled('div')({
  flex: 1,
  textAlign: 'center'
});

const CardImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '150px',
  objectFit: 'contain'
});

const DividerStyled = styled(Divider)({
  margin: '2rem 0'
});

const RegisterPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const navigate = useNavigate();

  const actionSections = [
    {
      title: 'Contact us',
      description: `Promote your business to the ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns community.`,
      image:
        'https://cdn.iconscout.com/icon/free/png-256/free-contact-us-2003060-1686260.png' // Replace with the actual image URL
    },
    {
      title: 'Post a Job Offer',
      description: `Hire talented individuals from the ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns network.`,
      image: 'https://static.thenounproject.com/png/3872123-200.png' // Replace with the actual image URL
    },
    {
      title: 'Look for Work',
      description: `Explore job opportunities posted by fellow ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns.`,
      image: 'https://cdn-icons-png.flaticon.com/512/65/65053.png' // Replace with the actual image URL
    },
    {
      title: 'Offer Funding',
      description: 'Provide financial support for projects or initiatives.',
      image: 'https://cdn-icons-png.flaticon.com/512/7533/7533463.png' // Replace with the actual image URL
    },
    {
      title: 'Raise Funding',
      description: 'Seek funding for your projects or ventures.',
      image:
        'https://cdn4.vectorstock.com/i/1000x1000/76/48/raise-money-icon-outline-style-vector-34687648.jpg' // Replace with the actual image URL
    },
    {
      title: 'Organise Meetings',
      description: 'Plan and coordinate meetings or events with ease.',
      image:
        'https://www.shutterstock.com/image-vector/conference-icon-vectormeeting-vector-icondiscussion-260nw-1673901592.jpg' // Replace with the actual image URL
    },
    {
      title: 'Chat',
      description: `Connect and communicate with other ${process.env.REACT_APP_ORGANIZATION_NAME === 'Oxalum' ? 'Oxford' : 'Business School'} Alumns.`,
      image:
        'https://static.vecteezy.com/system/resources/previews/005/337/802/non_2x/icon-symbol-chat-outline-illustration-free-vector.jpg' // Replace with the actual image URL
    }
  ];

  const handleBack = () => {
    // navigate to the previous page
    navigate(-1);
  };

  return (
    <Box>
      <HeaderContainer>
        <Button variant='contained' color='primary' onClick={handleBack}>
          Back
        </Button>
        <Stack direction='column' spacing={2}>
          <Typography fontWeight='bold' fontSize='32px' color='primary'>
            {process.env.REACT_APP_ORGANIZATION_NAME}
          </Typography>
          <Typography fontSize='18px' color='primary'>
            Welcome to {process.env.REACT_APP_ORGANIZATION_NAME}, the Social Media for Oxford Alumns!
          </Typography>
          <Typography fontSize='18px' color='primary'>
            How to sign up?
          </Typography>
        </Stack>
        <div style={{ width: '64px' }} />{' '}
        {/* Placeholder for better alignment */}
      </HeaderContainer>

      <Box width={isNonMobileScreens ? '50%' : '93%'} p='2rem' m='2rem auto'>
        <Box>
          <CardContainer>
            <CardImage
              src={actionSections[0].image}
              alt={`${actionSections[0].title} Screenshot`}
            />
            <CardContentContainer>
              <Typography variant='h4' gutterBottom>
                Contact us
              </Typography>
              <Typography>
                Send us an email to{' '}
                <a href='mailto:admin@oxsaid.net'>admin@oxsaid.net</a>
              </Typography>
            </CardContentContainer>
          </CardContainer>
          <DividerStyled />
        </Box>
        <Box>
          <CardContainer>
            <CardImage
              src='https://cdn-icons-png.flaticon.com/512/5234/5234972.png'
              alt={`invite icon Screenshot`}
            />
            <CardContentContainer>
              <Typography variant='h4' gutterBottom>
                Wait for an invite
              </Typography>
            </CardContentContainer>
          </CardContainer>
                    <DividerStyled />

        </Box>
        <Box>
          <CardContainer>
            <CardImage
              src='https://t3.ftcdn.net/jpg/02/31/32/76/360_F_231327619_BAkaQuBgSdmfQyu5HEruBaEL8qtmK95L.jpg'
              alt={`refer icon Screenshot`}
            />
            <CardContentContainer>
              <Typography variant='h4' gutterBottom>
                How to refer other?
              </Typography>
                <Typography>
                  1. Go to page{' '}
                  <Link to='/home' target='_blank' rel='noopener noreferrer'>
                    {' '}
                    (click on this){' '}
                  </Link>
                </Typography>
                <Typography>
                  2. Enter two names and send your invites
                </Typography>

              <Typography
                sx={{ mt: '1rem' }}
                color='red'
                fontSize='18px'
              >
                * You only have 2 invitations, so please be sure to invite users
                that you think will find the site helpful.
              </Typography>
            </CardContentContainer>
          </CardContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterPage;
