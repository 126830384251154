import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import EventsForm from 'components/EventForm';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import RenderGroupEventWidget from './RenderGroupEventWidget';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GroupEventsWidget = ({ group, isAdmin }) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [value, setValue] = useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll'
      }}
    >
      <Typography
        variant='h4'
        fontWeight='700'
        sx={{ mb: '1.5rem', textAlign: 'center' }}
      >
        Group Events
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: theme.palette.background.paper
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {isAdmin && <Tab label='Create Event' {...a11yProps(0)} />}
          <Tab label='Group Events' {...a11yProps(isAdmin ? 1 : 0)} />
        </Tabs>
      </Box>
      {isAdmin && (
        <TabPanel value={value} index={0}>
          <EventsForm  groupId={group._id}/>
        </TabPanel>
      )}

      <TabPanel value={value} index={isAdmin ? 1 : 0}>
        <RenderGroupEventWidget groupId={group._id} />
      </TabPanel>
    </Box>
  );
};

export default GroupEventsWidget;
