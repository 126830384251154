import { useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPosts } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import PostWidget from './PostWidget';

const PostsWidget = ({ userId, isProfile = false, groupId }) => {
  const dispatch = useDispatch();
  const {posts} = useAppSelector(state => state.auth);

  const getPosts = async () => {
    try {
      let response;
      if (groupId) {
        response = await axiosHelper(
          API_METHOD.GET,
          'posts',
          `group/${groupId}`
        );
      } else {
        response = await axiosHelper(
          API_METHOD.GET,
          'posts',
          `${groupId ? groupId : ''}`
        );
      }
      dispatch(setPosts({ posts: response }));
    } catch (error) {
      console.log(error);
    }
  };

  const getUserPosts = async () => {
    const response = await axiosHelper(
      API_METHOD.GET,
      'posts',
      `${userId}/${groupId ? groupId : ''}`
    );
    dispatch(setPosts({ posts: response }));
  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      getPosts();
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {posts &&
        posts.length > 0 &&
        posts.map(
          ({
            _id,
            userId,
            firstName,
            lastName,
            description,
            location,
            picturePath,
            userPicturePath,
            likes,
            comments
          }) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              description={description}
              location={location}
              picturePath={picturePath}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
            />
          )
        )}
    </>
  );
};

export default PostsWidget;
