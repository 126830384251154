import { Button, Stack, Typography, useTheme } from '@mui/material';
import Friend from 'components/Friend';
import WidgetWrapper from 'components/WidgetWrapper';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { getDisplayNames } from 'utils/helperFunction';

const JobCardWidget = ({
  jobTitle,
  salary,
  description,
  userId,
  business,
  isMyJobs,
  jobId,
  isClosed,
  setJobs,
  jobs,
  country,
  city,
  occupation,
  subOccupation

}) => {
  const { palette } = useTheme();
  const main = palette.neutral.main;

  const handleCloseJob = async () => {
    const res = await axiosHelper(API_METHOD.PUT, 'jobs', `${jobId}/close`);
    if (res.isSuccess) {
      const updatedJobs = jobs.map(job => {
        if (job._id === jobId) {
          job.isClosed = true;
        }
        return job;
      });
      setJobs(updatedJobs);
    }
  };

  if(!userId || !business) return null;

  return (
    <WidgetWrapper m='2rem 0'>
      <Stack
        direction='row'
        spacing={2}
        display='flex'
        justifyContent='space-between'
      >
        <Friend
          friendId={userId._id}
          name={getDisplayNames(userId.firstName, userId.lastName)}
          // subtitle={location}
          userPicturePath={userId.picturePath}
        />
        {isMyJobs && !isClosed && (
          <Button variant='standard' color='primary' onClick={handleCloseJob}>
            Close Job
          </Button>
        )}

        {isClosed && (
          <Typography color='error' sx={{ mt: '1rem' }}>
            <b> Job Closed</b>
          </Typography>
        )}
      </Stack>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Job Title:</b> {jobTitle}
      </Typography>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Job Description:</b> {description}
      </Typography>

      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Country:</b> {country}
      </Typography>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>City:</b> {city}
      </Typography>
      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Occupation:</b> {occupation}
      </Typography>
      {subOccupation && (
        <Typography color={main} sx={{ mt: '1rem' }}>
          <b>Sub Occupation:</b> {subOccupation}
        </Typography>
      )}


      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Business Name:</b> {business.name.name}
      </Typography>

      <Typography color={main} sx={{ mt: '1rem' }}>
        <b>Salary:</b> {salary}
      </Typography>
    </WidgetWrapper>
  );
};

export default JobCardWidget;
