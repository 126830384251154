import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import stringConstants from 'common/constants/strings';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import * as yup from 'yup';

const eventInitialValues = (values, userId) => {
  return {
    title: values?.title || '',
    time: values?.time ? new Date(values.time) : new Date(),
    date: values?.date ? new Date(values.date) : new Date(),
    eventFormat: values?.eventFormat || 'remote',
    eventLocation: values?.eventLocation || '',
    zoomMeetingInvite: values?.zoomMeetingInvite || '',
    eventCoverImage: null,
    maxRegistrants: values?.maxRegistrants || 5,
    description: values?.description || '',
  };
};

const EventsForm = props => {
  const {
    group,
    setAddEventModal,
    addEventModal,
    setEvents,
    events,
    event,
    groupId,
    isEdit,
    getEvents,
    setOpen,
  } = props;
  const { _id } = useAppSelector(state => state.auth.user);

  const [loading, setLoading] = useState({
    page: false,
    addEvent: false
  });

  const addEventSchema = yup.object().shape({
    date: yup.date().required(stringConstants.required.date),
    time: yup.date().required(stringConstants.required.time),
    title: yup.string().required(stringConstants.required.name),
    description: yup.string().required(stringConstants.required.description),
    maxRegistrants: yup
      .string()
      .required(stringConstants.required.maxRegistrants),
    eventLocation: yup.string().when('eventFormat', {
      is: 'inPerson',
      then: yup.string().required('Event Location is required')
    }),
    eventFormat: yup.string().required('Event Format is required'),
    eventImage: yup.string(),
    zoomMeetingInvite: yup.string().when('eventFormat', {
      is: 'remote',
      then: yup.string().required('Zoom Meeting Invite is required')
    }),
    eventCoverImage: yup
      .mixed()
      .test('fileType', 'Unsupported File Format', value => {
        if (value) {
          return ['image/jpeg', 'image/png'].includes(value.type);
        }
        return true;
      })
  });

const handleFormSubmit = async (values, onSubmitProps) => {
  try {
    setLoading({ ...loading, register: true });
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('date', values.date);
    formData.append('time', values.time);
    formData.append('eventFormat', values.eventFormat);
    formData.append('eventLocation', values.eventLocation);
    formData.append('zoomMeetingInvite', values.zoomMeetingInvite);
    formData.append('description', values.description);

    formData.append('maxRegistrants', values.maxRegistrants);
    formData.append('eventCoverImage', values.eventCoverImage);
    if (groupId) {
      formData.append('groupId', groupId);
    }
    // userid
    formData.append('userId', _id);

    formData.append('eventId', event?._id || '');

    let res;
    if (isEdit && event) {
      // Update existing event
      res = await axiosHelper(API_METHOD.PUT, `events`, '', formData);
    } else {
      // Create new event
      res = await axiosHelper(API_METHOD.POST, 'events', '', formData);
    }

    onSubmitProps.resetForm();
    if (res.isSuccess) {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      // Update local events state or do any other necessary updates
      if (isEdit && event) {
        // Update local events state or do any other necessary updates
        getEvents();
        setOpen(false);
      }
    }
    setLoading({ ...loading, register: false });

  } catch (error) {
    console.error(error);
  }
};


  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleFormSubmit}
      validationSchema={addEventSchema}
      initialValues={eventInitialValues(event, _id)}
    >
      {({
        values,
        errors,
        touched,
        resetForm,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label='Title'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              placeholder='Title'
              name='title'
              helperText={touched.title ? errors.title : ''}
              error={touched.title && Boolean(errors.title)}
              type='text'
            />
            <TextField
              label='Description'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              placeholder='Description'
              name='description'
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
              type='text'
            />

            <DemoContainer components={['DatePicker', 'TimePicker']}>
              <DatePicker
                label='Date'
                value={dayjs(values.date)}
                name='date'
                onChange={value => setFieldValue('date', new Date(value))}
                helperText={touched.date ? errors.date : ''}
                error={touched.date && Boolean(errors.date)}
              />
              <TimePicker
                label='Time'
                name='time'
                value={dayjs(values.time)}
                // minTime={dayjs().startOf('hour').toDate()} // Disable previous times

                onChange={value => {
                  setFieldValue('time', new Date(value));
                }}
                helperText={touched.time ? errors.time : ''}
                error={touched.time && Boolean(errors.time)}
              />
            </DemoContainer>

            <TextField
              label='Max Registrants'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxRegistrants}
              placeholder='Max Registrants'
              name='maxRegistrants'
              helperText={touched.maxRegistrants ? errors.maxRegistrants : ''}
              error={touched.maxRegistrants && Boolean(errors.maxRegistrants)}
              type='number'
            />

            <FormControl>
              <InputLabel id='event-format-label'>Event Format</InputLabel>
              <Select
                labelId='event-format-label'
                id='event-format-select'
                value={values.eventFormat}
                onChange={e => {
                  setFieldValue('eventFormat', e.target.value);
                }}
                label='Event Format'
              >
                <MenuItem value='inPerson'>In Person</MenuItem>
                <MenuItem value='remote'>Remote</MenuItem>
              </Select>
            </FormControl>
            {values.eventFormat === 'inPerson' && (
              <TextField
                label='Event Location'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eventLocation}
                placeholder='Event Location'
                name='eventLocation'
                helperText={touched.eventLocation ? errors.eventLocation : ''}
                error={touched.eventLocation && Boolean(errors.eventLocation)}
                type='text'
              />
            )}
            {values.eventFormat === 'remote' && (
              <TextField
                label='Zoom Meeting Invite'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zoomMeetingInvite}
                placeholder='Zoom Meeting Invite'
                name='zoomMeetingInvite'
                helperText={
                  touched.zoomMeetingInvite ? errors.zoomMeetingInvite : ''
                }
                error={
                  touched.zoomMeetingInvite && Boolean(errors.zoomMeetingInvite)
                }
                type='text'
              />
            )}

            <input
              type='file'
              name='eventCoverImage'
              accept='image/*'
              inputProps={{ 'aria-label': 'Upload image' }}
              onChange={event => {
                setFieldValue('eventCoverImage', event.target.files[0]);
              }}
              // error if touched and has error
              error={touched.eventCoverImage && Boolean(errors.eventCoverImage)}
            />
            {touched.eventCoverImage && errors.eventCoverImage && (
              <FormHelperText error>{errors.eventCoverImage}</FormHelperText>
            )}

            <Button
              type='submit'
              variant='contained'
              disabled={loading.addEvent}
            >
              {loading.addEvent ? 'Loading...' : 'Submit'}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default EventsForm;
