import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";
import { getDisplayNames } from "utils/helperFunction";

const GroupMemberWidget = ({ group, removeFromGroup, addMembersToGroup }) => {
  const { _id, elum } = useAppSelector((state) => state.auth.user);

  const [users, setUsers] = useState([]);
  const [elumUsers, setElumUsers] = useState([]);
  const [value, setValue] = useState();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setElumUsers(users.filter((user) => user.elum));
  }, [users]);

  const getUsers = async () => {
    try {
      if (!_id) return;
      // setLoading({ ...loading, page: true });
      const response = await axiosHelper(API_METHOD.GET, "users", "");
      const filteredUsers = response.filter(
        (u) =>
          u._id !== _id &&
          !group.groupMembers.some((member) => member._id === u._id)
      );

      setUsers(filteredUsers);
      // setLoading({ ...loading, page: false });
    } catch (error) {
      console.log(error);
    }
  };

  if (!group) return <div>Loading...</div>;

  const { groupMembers, adminMembers } = group;
  const totalCount = groupMembers.length + adminMembers.length - 1;
  const isAdmin = group.adminMembers.some((admin) => admin._id === _id);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={800}>
          Total Members ({totalCount})
        </Typography>
      </Grid>
      {elum && isAdmin && (
        <Grid item xs={12}>
          <Stack direction="column" spacing={2} width="50%">
            <Typography variant="h4" fontWeight={800}>
              Add Members
            </Typography>
            <Autocomplete
              multiple
              id="tags-standard"
              options={elumUsers}
              getOptionLabel={(option) =>
                getDisplayNames(option.firstName, option.lastName)
              }
              onChange={(event, value) => {
                // setFieldValue('groupMembers', value);
                setValue(value);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  id={option._id}
                  sx={{
                    "& > img": { mr: 2, flexShrink: 0 },
                    cursor: "pointer",
                    pointerEvents: "all",
                  }}
                  {...props}
                >
                  <Avatar src={option.picturePath}>
                    {option.firstName[0].toUpperCase() +
                      option.lastName[0].toUpperCase()}
                  </Avatar>
                  <Typography variant="body2" color="text.primary" pl={2}>
                    {getDisplayNames(option.firstName, option.lastName)}
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Group Members"
                  placeholder="Group Members"
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => addMembersToGroup(value)}
            >
              Add Members
            </Button>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={800}>
          Admin Members ({adminMembers.length})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <WidgetWrapper my={2}>
          <Grid container spacing={3}>
            {adminMembers.map((member, index) => (
              <Grid item xs={12} key={member._id}>
                <Friend
                  key={member._id}
                  friendId={member._id}
                  name={`${member.firstName} ${member.lastName}`}
                  subtitle={member.occupation}
                  userPicturePath={member.picturePath}
                />
                <Divider />
              </Grid>
            ))}
          </Grid>
        </WidgetWrapper>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={800}>
          Group Members ({groupMembers.length - 1})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <WidgetWrapper my={2}>
          <Grid container spacing={3}>
            {groupMembers
              // filter the admin members out
              .filter(
                (member) =>
                  !adminMembers.some((admin) => admin._id === member._id)
              )
              .map((member, index) => (
                <Grid item xs={12} key={member._id}>
                  <Friend
                    key={member._id}
                    friendId={member._id}
                    name={`${member.firstName} ${member.lastName}`}
                    subtitle={member.occupation}
                    userPicturePath={member.picturePath}
                    groupId={group._id}
                    isAdmin={isAdmin}
                    removeFromGroup={removeFromGroup}
                  />
                  <Divider />
                </Grid>
              ))}
          </Grid>
        </WidgetWrapper>
      </Grid>
    </Grid>
  );
};

export default GroupMemberWidget;
