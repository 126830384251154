import {
  Box,
  Button,
  Divider,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '1.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.alt,
  padding: '1rem 6%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const CardContentContainer = styled('div')({
  flex: 1,
  textAlign: 'center'
});

const CardImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '150px',
  objectFit: 'contain'
});

const DividerStyled = styled(Divider)({
  margin: '2rem 0'
});

const AboutUsPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const navigate = useNavigate();

  const aboutUsSections = [
    {
      title: 'John Doe',
      role: 'Founder & CEO',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.`,
      image: 'https://via.placeholder.com/150',
      link: '/john-doe' // Dummy link for demonstration
    },
    {
      title: 'Jane Smith',
      role: 'Chief Technical Officer',
      description: `Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie.`,
      image: 'https://via.placeholder.com/150',
      link: '/jane-smith'
    },
    {
      title: 'Emily Johnson',
      role: 'Head of Marketing',
      description: `Aenean placerat. In vulputate urna eu arcu. Aliquam erat volutpat. Suspendisse potenti. Morbi mattis felis at nunc. Duis viverra.`,
      image: 'https://via.placeholder.com/150',
      link: '/emily-johnson'
    }
  ];

  const handleBack = () => {
    // navigate to the previous page
    navigate(-1);
  };

  return (
    <Box>
      <HeaderContainer>
        <Button variant='contained' color='primary' onClick={handleBack}>
          Back
        </Button>
        <Typography fontWeight='bold' fontSize='24px' color='primary'>
          About Us - {process.env.REACT_APP_ORGANIZATION_NAME}
        </Typography>
        <div style={{ width: '64px' }} />
        {/* Placeholder for better alignment */}
      </HeaderContainer>

      <Box width={isNonMobileScreens ? '50%' : '93%'} p='2rem' m='2rem auto'>
        {aboutUsSections.map((section, index) => (
          <Box
            key={index}
            component='a'
            href={section.link}
            style={{ textDecoration: 'none' }}
          >
            <CardContainer>
              <CardImage
                src={section.image}
                alt={`${section.title} Portrait`}
              />
              <CardContentContainer>
                <Typography variant='h5' gutterBottom fontWeight='bold' color="primary">
                  {section.title}
                </Typography>
                <Typography variant='h6' gutterBottom color='secondary'>
                  {section.role}
                </Typography>
                <Typography color="primary">{section.description}</Typography>
              </CardContentContainer>
            </CardContainer>
            {index < aboutUsSections.length - 1 && <DividerStyled />}
          </Box>
        ))}
      </Box>
      <Footer />
    </Box>
  );
};

export default AboutUsPage;
