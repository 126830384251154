import React, { useState } from "react";
import { Avatar, Button, Grid, Stack, Typography, Chip } from "@mui/material";
import CustomModal from "common/modal";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useAppSelector } from "hooks/useRedux";
import { useNavigate } from "react-router-dom";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";

const GroupBoxWidget = ({ group }) => {
  const { _id, elum } = useAppSelector((state) => state.auth.user);
  const [joinModal, setJoinModal] = useState(false);
  const [isMember, setIsMember] = useState(
    group.groupMembers.some((member) => member._id === _id)
  );

  const isAdmin = isMember
    ? group.adminMembers.some((member) => member._id === _id)
    : false;
  const navigate = useNavigate();

  const joinGroup = async () => {
    try {
      const response = await axiosHelper(API_METHOD.PUT, "groups", "join", {
        userId: _id,
        groupId: group._id,
      });
      if (response.isSuccess) {
        setJoinModal(false);
        setIsMember(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <WidgetWrapper my={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Stack direction="row" spacing={2}>
              <Avatar src={group.groupCoverImage} />
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h4" fontWeight="500">
                    {group.name}
                  </Typography>
                  <Chip
                    size="small"
                    label={group.isPrivate ? "private" : "public"}
                    color={group.isPrivate ? "error" : "primary"}
                  />
                </Stack>
                <Typography variant="caption"> {group.description}</Typography>
                <Typography variant="caption">
                  {group.groupMembers.length} Members
                </Typography>
                {isAdmin && (
                  <FlexBetween gap={2}>
                    {/* <Button variant='outlined'>Edit Group</Button>
                    <Button variant='outlined'>Delete Group</Button> */}
                  </FlexBetween>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            {group.isPrivate ? (
              elum ? (
                <Button
                  color={isMember ? "primary" : "secondary"}
                  variant="outlined"
                  onClick={() =>
                    isMember
                      ? navigate(`/group/${group._id}`)
                      : setJoinModal(true)
                  }
                >
                  {isMember ? "View Group" : "Join Group"}
                </Button>
              ) : (
                <></>
              )
            ) : (
              <Button
                variant="outlined"
                onClick={() => navigate(`/group/${group._id}`)}
              >
                View Group
              </Button>
            )}
          </Grid>
        </Grid>
      </WidgetWrapper>
      <CustomModal
        displayButtons={{ submit: "Yes", cancel: "No" }}
        handleSubmit={joinGroup}
        open={joinModal}
        setOpen={setJoinModal}
      >
        <Typography sx={{ mb: 2 }}>
          Are you sure you want to join this group?
        </Typography>
      </CustomModal>
    </>
  );
};

export default GroupBoxWidget;
