import { Box } from '@mui/material';
import JobForm from 'components/JobForm';
import WidgetWrapper from 'components/WidgetWrapper';

const CreateJobWidget = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll',
        width: '100%'
      }}
    >
      <WidgetWrapper>
        <JobForm/>
      </WidgetWrapper>
    </Box>
  );
};

export default CreateJobWidget;
