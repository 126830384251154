import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import SingleGroupWidget from "scenes/widgets/SingleGroupWidget";
import axiosHelper, { API_METHOD } from "utils/axiosHelper";

const GroupPage = () => {
  const { id } = useParams();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGroupById = async () => {
    setLoading(true);

    const res = await axiosHelper(API_METHOD.GET, "groups/group", id);
    setLoading(false);
    if (res.isSuccess) {
      setGroup(res.group);
    }
  };

  const removeFromGroup = async (userId) => {
    const response = await axiosHelper(
      API_METHOD.PUT,
      "groups",
      `${group._id}/remove`,
      { userId }
    );
    if (response.isSuccess) {
      setGroup(response.group);
      // setGroupState(response.group);
    }
  };

  const addMembersToGroup = async (value) => {
    try {
      const response = await axiosHelper(
        API_METHOD.PUT,
        "groups",
        `${group._id}/add`,
        {
          userIds: value.map((v) => v._id),
        }
      );
      if (response.isSuccess) {
        setGroup(response.group);
        // setGroupState(response.group);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGroupById();
  }, [id]);

  return (
    <Box pb={10} height="100vh">
      <Navbar />
      <Box
        width="100%"
        // padding='2rem 6%'
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="center"
      >
        <Box
          // flexBasis={isNonMobileScreens ? '42%' : undefined}
          // mt={isNonMobileScreens ? undefined : '2rem'}
          width="100%"
        >
          <SingleGroupWidget
            group={group}
            pageLoading={loading}
            removeFromGroup={removeFromGroup}
            addMembersToGroup={addMembersToGroup}
          />
          {/* <GroupWidget /> */}
        </Box>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default GroupPage;
