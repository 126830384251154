import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import Friend from 'components/Friend';
import MyEventModal from 'components/MyEventModal';
import WidgetWrapper from 'components/WidgetWrapper';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { getDisplayNames } from 'utils/helperFunction';

const EventCardWidget = ({
  date,
  title,
  description,
  time,
  userId,
  eventLocation,
  eventFormat,
  zoomMeetingInvite,
  maxRegistrants,
  eventCoverImage,
  _id,
  registrants,
  getEvents,
  groupId
}) => {
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [open, setOpen] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [buttons, setButtons] = useState({
    register: false,
    registered: false,
    isFullCapacity: false,
    isMyEvent: false
  });

  console.log(groupId, 'groupId')

  const handleInterestClick = async () => {
    // Add logic here to handle the interest action
    const res = await axiosHelper(API_METHOD.PUT, 'events', 'interested', {
      eventId: _id,
      userId: user._id
    });
    if (res.isSuccess) {
      setButtons({
        ...buttons,
        register: false,
        registered: true
      });
    }
  };

  const handleUnregisterClick = async () => {
    // Add logic here to handle the unregister action
    const res = await axiosHelper(API_METHOD.PUT, 'events', 'unregister', {
      eventId: _id,
      userId: user._id
    });
    if (res.isSuccess) {
      setButtons({
        ...buttons,
        register: true,
        registered: false
      });
    }
  };


  useEffect(() => {
    if (user && user._id && userId && userId._id) {
      const isMyEvent = userId._id === user._id;
      if (isMyEvent) {
        setButtons({
          ...buttons,
          register: false,
          registered: false,
          isMyEvent: true
        });
      } else {
        const isRegistered = registrants.some(
          registrant => registrant.userId._id === user._id
        );
        if (registrants.length >= maxRegistrants) {
          setButtons({
            ...buttons,
            register: false,
            registered: false,
            isFullCapacity: true
          });
        } else if (isRegistered) {
          setButtons({
            ...buttons,
            register: false,
            registered: true
          });
        } else {
          setButtons({
            ...buttons,
            register: true,
            registered: false
          });
        }
      }
    }
  }, [user, userId]);

  if (!userId) return null;

  return (
    <WidgetWrapper m='2rem 0'>
      {
        <MyEventModal
          open={open}
          setOpen={setOpen}
          event={{
            date,
            title,
            description,
            time,
            userId,
            eventLocation,
            eventFormat,
            zoomMeetingInvite,
            maxRegistrants,
            eventCoverImage,
            _id,
            registrants
          }}
          getEvents={getEvents}
        />
      }
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack
            direction='row'
            spacing={2}
            display='flex'
            justifyContent='space-between'
          >
            <Friend
              friendId={userId._id}
              name={getDisplayNames(userId.firstName, userId.lastName)}
              // subtitle={location}
              userPicturePath={userId.picturePath}
            />
          </Stack>
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Event Title:</b> {title}
          </Typography>
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Job Description:</b> {description}
          </Typography>

          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Date:</b> {moment(date).format('dddd, MMMM Do YYYY')}
          </Typography>
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Time:</b> {moment(time).format('h:mm A')} ({moment.tz.guess()})
          </Typography>
          {eventLocation && (
            <Typography color={main} sx={{ mt: '1rem' }}>
              <b>Event Location:</b> {eventLocation}
            </Typography>
          )}
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Event Format:</b> {eventFormat}
          </Typography>
          {zoomMeetingInvite && (
            <Typography color={main} sx={{ mt: '1rem' }}>
              <b>Zoom Meeting Invite:</b> {zoomMeetingInvite}
            </Typography>
          )}
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Max Registrants:</b> {maxRegistrants}
          </Typography>
          <Typography color={main} sx={{ mt: '1rem' }}>
            <b>Registered Participants:</b> {registrants.length}
          </Typography>
          {
            groupId && (
              <Typography color={main} sx={{ mt: '1rem' }}>
                <b>Group Event Name:</b> {groupId.name}
              </Typography>
            )
          }

          {eventCoverImage && (
            <Typography color={main} sx={{ mt: '1rem' }}>
              <b>Event Cover Image:</b>
              <img
                src={eventCoverImage}
                alt='event cover'
                style={{
                  maxWidth: '200px',
                  maxHeight: '200px',
                  width: 'auto',
                  height: 'auto',
                  objectFit: 'cover'
                }}
              />
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-end'
          }}
        >
          {buttons.isFullCapacity ? (
            <Button
              variant='contained'
              color='error'
              sx={{ mt: '1rem' }}
              disabled
            >
              Full Capacity
            </Button>
          ) : buttons.isMyEvent ? (
            <Button
              variant='contained'
              color='info'
              sx={{ mt: '1rem' }}
              onClick={() => setOpen(true)}
            >
              Show Details
            </Button>
          ) : buttons.register ? (
            <Button
              variant='contained'
              color='primary'
              onClick={handleInterestClick}
              sx={{ mt: '1rem' }}
            >
              Interested?
            </Button>
          ) : buttons.registered ? (
            <Button
              variant='contained'
              color='success'
              sx={{ mt: '1rem' }}
              onClick={handleUnregisterClick}
            >
              UnRegister
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </WidgetWrapper>
  );
};

export default EventCardWidget;
