import { Button, Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import Friend from './Friend';
import WidgetWrapper from './WidgetWrapper';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';

const EventParticipants = ({ event }) => {
  const [participants, setParticipants] = useState(
    event ? event.registrants.map(registrant => registrant.userId) : []
  );

  const removeParticipant = async (participantId) => {
    const res = await axiosHelper(API_METHOD.PUT, 'events', 'remove-participant', {
      eventId: event._id,
      userId: participantId
    });
    if (!res.isSuccess) return;
    setParticipants(
      participants.filter(participant => participant._id !== participantId)
    );
  };

  if (!event) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' fontWeight={800}>
          Participants ({participants.length})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <WidgetWrapper my={2}>
          <Grid container spacing={3}>
            {participants.map((member, index) => (
              <Grid item xs={12} key={member._id}>
                <Friend
                  friendId={member._id}
                  name={`${member.firstName} ${member.lastName}`}
                  subtitle={member.occupation}
                  userPicturePath={member.picturePath}
                />

                <Grid item xs={12} sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: '1rem',
                  mb: '1rem'
                
                }}>
                  <Button variant='outlined' onClick={() => removeParticipant(member._id)}>
                    Remove Participant
                  </Button>
                </Grid>
                <Divider />
              </Grid>
            ))}
          </Grid>
        </WidgetWrapper>
      </Grid>
    </Grid>
  );
};

export default EventParticipants;
