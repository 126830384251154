import { Box, useMediaQuery } from '@mui/material';
import Footer from 'components/Footer';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from 'scenes/navbar';
import AddBusinessWidget from 'scenes/widgets/AddBusinessWidget';
import BusinessesWidget from 'scenes/widgets/BusinessesWidget';
import MyBusinessWidget from 'scenes/widgets/MyBusinessWidget';
import { setMyBusinesses } from 'state/auth';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';

const BusinessPage = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const {user, myBusinesses} = useAppSelector(state => state.auth);
  const { _id } = user;
  const dispatch = useDispatch();
  const [selectedBusiness, setselectedBusiness] = useState(undefined);


  const getBuinessesById = async () => {
    const res = await axiosHelper(API_METHOD.GET, 'businesses', _id);
    if (res.isSuccess) {
      dispatch(
        setMyBusinesses({
          myBusinesses: res.business
        })
      );
    }
  };

  useEffect(() => {
    getBuinessesById();
  }, []);

  return (
    <Box pb={10} height="100vh">
      <Navbar />
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap='0.5rem'
        justifyContent='space-between'
      >
        <Box flexBasis={isNonMobileScreens ? '26%' : undefined}>
          <AddBusinessWidget
            selectedBusiness={selectedBusiness}
            setselectedBusiness={setselectedBusiness}
          />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? '42%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
        >
          <BusinessesWidget />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis='26%'>
            <MyBusinessWidget
              myBusinesses={myBusinesses}
              selectedBusiness={selectedBusiness}
              setselectedBusiness={setselectedBusiness}
            />
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default BusinessPage;
