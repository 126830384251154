import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import MaterialReactTable from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axiosHelper, { API_METHOD } from 'utils/axiosHelper';
import { countries } from './CountryDropdown';
import Form from './Form';

const AdminTable = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const handleCreateNewRow = values => {
  values.email = values.email.trim();
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      try {
        const res = await axiosHelper(
          API_METHOD.PUT,
          'users',
          values._id,
          values
        );

        if (res.isSuccess) {
          //send/receive api updates here, then refetch or update local table data for re-render
          setTableData([...tableData]);
          exitEditingMode(); //required to exit editing mode and close modal
        }
      } catch (error) {}
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async row => {
      try {
        const res = await axiosHelper(
          API_METHOD.DELETE,
          'users',
          row.original._id
        );
        if (res.isSuccess) {
          //send api delete request here, then refetch or update local table data for re-render
          tableData.splice(row.index, 1);
          setTableData([...tableData]);
        }
      } catch (error) {}
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    cell => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: event => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors
            });
          }
        }
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,

        size: 80
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell)
        })
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell)
        })
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email'
        })
      },
      {
        accessorKey: 'occupation',
        header: 'Occupation',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell)
        })
      },
      {
        accessorKey: 'location',
        header: 'Location',
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: countries.map(country => (
            <MenuItem key={country.code} value={country.code}>
              {country.label}
            </MenuItem>
          ))
        }
      }
    ],
    [getCommonEditTextFieldProps]
  );
  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_BASE_URL}/users/`
    // );
    const response = await axiosHelper(API_METHOD.GET, 'users', '')
    console.log(response, 'rsponse')
    // sort by createdAt
    response.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setTableData(response);
  };

  const editUser = async (id, data) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/users/${id}`,
      data
    );
  };

  const closeCreateModal = async () => {
    await getAllUsers();

    setCreateModalOpen(false);
  };

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center'
            },
            size: 120
          }
        }}
        columns={columns}
        data={tableData}
        editingMode='modal' //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
		{/*
            <Tooltip arrow placement='left' title='Edit'>
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement='right' title='Delete'>
              <IconButton color='error' onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
	    */}
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color='secondary'
            onClick={() => setCreateModalOpen(true)}
            variant='contained'
          >
            Create New Account
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        closeCreateModal={closeCreateModal}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  closeCreateModal
}) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign='center'>Create New User</DialogTitle>
      <DialogContent>
        <Form
          isLogin={false}
          isAdmin={true}
          closeCreateModal={closeCreateModal}
        />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        {/* <Button color='secondary' onClick={handleSubmit} variant='contained'>
          Create New Account
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = value => !!value.length;
const validateEmail = email =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateAge = age => age >= 18 && age <= 50;

export default AdminTable;
