// SubscriptionBox.js
import { Box, Typography, useTheme } from '@mui/material';

const SubscriptionBox = ({ title, description, buttonText, planId }) => {
  const theme = useTheme();
  return (
    <Box
      p='2rem'
      borderRadius='1.5rem'
      backgroundColor={theme.palette.background.alt}
    >
      <Typography variant='h4' color='primary' textAlign='center' mb='1rem'>
        {title}
      </Typography>
      <Typography
        variant='body1'
        color='textPrimary'
        textAlign='center'
        mb='1rem'
      >
        {description}
      </Typography>
      <div id={`paypal-button-container-${planId}`}></div>
    </Box>
  );
};

export default SubscriptionBox;
